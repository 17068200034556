import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import './HashTag.scss';

const HashTag = ({ text, type, onClick, rootUrl }) => {
  return (
    <li className={'work-boxes-tags__element'}>
      <a
        href={`${rootUrl}#${slugify(`${type} ${text}`, { lower: true })}`}
        className={'work-boxes-tags__button'}
        onClick={event => onClick(event, type, text)}
      >
        #{text}
      </a>
    </li>
  );
};

HashTag.defaultProps = {
  rootUrl: '',
};

HashTag.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  rootUrl: PropTypes.string,
};

export default HashTag;
