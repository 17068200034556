export const code = [
  `Automated security check`,
  `Manual security check`,
  `Manual code review `,
  `Automated static code review`,
  `Business analysis`,
  `Automated scalability tests`,
  `Report and presentation `,
];

export const ux = [
  `Review of business and user objectives`,
  `Review of conversion metrics and traffic data`,
  `Manual customer journey review `,
  `Usability and UI check`,
  `Report and presentation`,
];
