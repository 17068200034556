import React from 'react';
import { Heading, Paragraph, Section, SectionHeader } from 'components';
import Image from 'components/Image';
import { getCloudinaryUrl } from 'config/helpers';
import './Specializations.scss';

const Specializations = () => {
  const specializations = [
    {
      title: 'Data processing and visualization',
      description:
        'We help to organize and visualize data so it supports decision making for B2B and B2C sectors more efficiently.',
    },
    {
      title: 'Energy management platforms',
      description:
        'We build software platforms for buying and selling energy, helping providers to increase ROI in this fast-paced sector.',
    },
    {
      title: 'Faster time to market',
      description:
        'With our help your product will hit the market faster, and thanks to the roadmap we create together your app will stay ahead of the competition.',
    },
    {
      title: 'UX/UI design',
      description:
        'With the right design tools, methodologies based on design thinking, user research and a human-centered approach, we create UX/UI designs that will delight users and make their life easier.',
    },
    {
      title: 'Dedicated development teams',
      description:
        'Our digital product development teams will help you conceptualize your idea, and design and build the first version of your product. With the support of our DevOps, solution architects and product strategists, we will find the product-market fit for your product or scale it to expand your business.',
    },
    {
      title: 'Digital transformation of your business',
      description:
        'Our team will support your digital transformation efforts by co-creating meaningful products, introducing new ways of working, and shifting revenue models to be more efficient.',
    },
  ];

  const SingleSpecialization = ({ specialization }) => (
    <li key={specialization.title} className={'specializations__item'}>
      <Heading
        type={3}
        size={'big'}
        additionalClass={'specializations__item-title'}
      >
        {specialization.title}
      </Heading>
      <Paragraph additionalClass={'specializations__item-description'}>
        {specialization.description}
      </Paragraph>
    </li>
  );

  return (
    <div className={'specializations'}>
      <Image
        src={getCloudinaryUrl(
          '/img/renewable-energy-industry/circle-left.png',
          'f_auto,q_auto',
        )}
        alt={'Circle'}
        additionalClass={'specializations__circle-left'}
      />
      <Image
        src={getCloudinaryUrl(
          '/img/renewable-energy-industry/loupe.png',
          'f_auto,q_auto',
        )}
        alt={'Loupe'}
        additionalClass={'specializations__loupe'}
      />
      <Image
        src={getCloudinaryUrl(
          '/img/renewable-energy-industry/circle-right.png',
          'f_auto,q_auto',
        )}
        alt={'Circle'}
        additionalClass={'specializations__circle-right'}
      />
      <Image
        src={getCloudinaryUrl(
          '/img/renewable-energy-industry/fan-dark.png',
          'f_auto,q_auto',
        )}
        alt={'Fan'}
        additionalClass={'specializations__fan'}
      />
      <Section color={'white'}>
        <SectionHeader
          color={'mine-shaft'}
          headingType={2}
          size={'large'}
          centered
          noLine
        >
          Our main fields of{' '}
          <span className={'headline--marking headline--marking-long'}>
            expertise
          </span>{' '}
          are
        </SectionHeader>
        <ul className={'specializations__list'}>
          {specializations.map(specialization => (
            <SingleSpecialization specialization={specialization} />
          ))}
        </ul>
      </Section>
    </div>
  );
};

export default Specializations;
