import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { enableAnimations } from 'config/helpers';
import { getPreparedOffers } from 'views/career/career.utils';
import {
  Banner,
  BoldareTech,
  Different,
  Carousel,
  SumUp,
  International,
  Offers,
  Technology,
  Video,
  TalentPool,
  TalentPoolProcess,
  ContactUs,
  Locations,
  PodcastSection,
  IdeasSliderSection,
} from './subcomponents';
import './Career.scss';
import config from '../../../meta/config';

const { promotedOffersDeadline } = config.career;

class Career extends PureComponent {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { offers } = this.props;
    const preparedOffers = getPreparedOffers(offers);

    return (
      <div className={'single-page single-page--hero career'}>
        <Banner />
        <International />
        <Offers
          offers={preparedOffers}
          promotedOffersDeadline={new Date(promotedOffersDeadline)}
        />
        <TalentPool />
        <TalentPoolProcess />
        <ContactUs />
        <BoldareTech />
        <Technology />
        <Carousel />
        <Video videoUrl={'https://www.youtube.com/watch?v=GHnNlPj79E8'} />
        <PodcastSection />
        <IdeasSliderSection />
        <Different />
        <SumUp />
        <Locations />
      </div>
    );
  }
}

Career.propTypes = {
  offers: PropTypes.array.isRequired,
};

export default Career;
