import './index.scss';
import React, { useRef, useState, useEffect, useMemo } from 'react';

// Components
import TojjarSection from 'views/designThatTellsStories/subcomponents/Cases/TojjarSection';
import MowaamahSection from 'views/designThatTellsStories/subcomponents/Cases/MowaamahSection';
import FutureSection from 'views/designThatTellsStories/subcomponents/Cases/FutureSection';

const Cases = () => {
  const sectionRef = useRef();
  const [offsetTop, setOffsetTop] = useState(9999999);
  const [windowHeight, setWindowHeight] = useState(9999999);

  useEffect(() => {
    setOffsetTop(sectionRef.current.offsetTop);
    setWindowHeight(Math.ceil(window.innerHeight / 2));
  }, []);

  // Section 1
  const section1Show = useMemo(() => offsetTop + windowHeight, [
    offsetTop,
    windowHeight,
  ]);

  const section1Start = useMemo(() => section1Show + windowHeight, [
    section1Show,
    windowHeight,
  ]);

  const section1End = useMemo(() => section1Start + windowHeight, [
    section1Start,
    windowHeight,
  ]);

  // Section 2
  const section2Show = useMemo(() => section1End + windowHeight, [
    section1End,
    windowHeight,
  ]);

  const section2Start = useMemo(() => section2Show + windowHeight, [
    section2Show,
    windowHeight,
  ]);

  const section2End = useMemo(() => section2Start + windowHeight, [
    windowHeight,
    section2Start,
  ]);

  return (
    <>
      <section
        ref={sectionRef}
        className={'dsgn__cases bldr-dsgn__section dsgn--full-screen'}
      >
        <div className={'dsgn__cases-wrapper'}>
          <TojjarSection />
          <FutureSection
            animationEnd={section1End}
            animationShow={section1Show}
            animationStart={section1Start}
          />
          <MowaamahSection
            animationEnd={section2End}
            animationShow={section2Show}
            animationStart={section2Start}
          />
        </div>
      </section>
    </>
  );
};

export default Cases;
