import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Section } from 'components';

import './RelacjeInwestorskie.scss';

const SHAREHOLDERS = [
  ['Anna Zarudzka', '400 000', '40%', '800 000', '40%'],
  ['Piotr Majchrzak', '440 000', '44%', '880 000', '44%'],
  ['Paweł Majchrzak', '160 000', '16%', '160 000', '16%'],
];

const GATHERINGS = [
  {
    name: 'General Meeting of Shareholders: August 3, 2023',
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1692705126/relacje-inwestorskie/Zwyczajne_Walne_Zgromadzenie_03.08.2023.pdf',
  },
  {
    name: 'General Meeting of Shareholders: June 28, 2022',
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1692705125/relacje-inwestorskie/Nadzwyczajne_Walne_Zgromadzenie_28.06.2022.pdf',
  },
  {
    name: 'General Meeting of Shareholders: March 21, 2022',
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1692705126/relacje-inwestorskie/Zwyczajne_Walne_Zgromadzenie_21.03.2022.pdf',
  },
  {
    name: 'General Meeting of Shareholders: September 23, 2021',
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1692705127/relacje-inwestorskie/Nadzwyczajne_Walne_Zgromadznie_23.09.2021.pdf',
  },
];

const RelacjeInwestorskie = () => (
  <>
    <Section id={'struktura'} spaceless>
      <Heading type={2} size={'big'}>
        Shareholder structure
      </Heading>
      <div className={'relacje__chart-wrapper'}>
        <div className={'relacje__chart'}>
          <div>
            <img
              src={getCloudinaryUrl(
                'relacje-inwestorskie/relacje-inwestorskie-chart.svg',
                'f_auto,q_auto,h_300',
              )}
              alt={'Relacje inwestorskie'}
              loading={'lazy'}
            />
          </div>
          <div className={'relacje__chart-legend'}>
            <div
              className={
                'relacje__chart-legend-item relacje__chart-legend-item--black'
              }
            >
              Anna Zarudzka
            </div>
            <div
              className={
                'relacje__chart-legend-item relacje__chart-legend-item--yellow'
              }
            >
              Piotr Majchrzak
            </div>
            <div
              className={
                'relacje__chart-legend-item relacje__chart-legend-item--violet'
              }
            >
              Paweł Majchrzak
            </div>
          </div>
        </div>
        <div className={'relacje__table-container'}>
          <table className={'relacje__table'}>
            <tr>
              <th>SHAREHOLDER</th>
              <th>The number of shares</th>
              <th>% of total shares</th>
              <th>Number of votes</th>
              <th>% of total votes</th>
            </tr>
            {SHAREHOLDERS.map(row => (
              <tr>
                {row.map(cell => (
                  <td>{cell}</td>
                ))}
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Section>
    <Section
      id={'zgromadzenia'}
      spaceless
      additionalClass={'relacje__section--middle'}
    >
      <Heading type={2} size={'big'}>
        General Meetings of Shareholders
      </Heading>
      <ul className={'relacje__gathering'}>
        {GATHERINGS.map(gathering => (
          <li>
            <a
              href={gathering.link}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              {gathering.name}
            </a>
          </li>
        ))}
      </ul>
    </Section>
    <Section id={'sprawozdania'} spaceless>
      <Heading type={2} size={'big'}>
        Financial Statements
      </Heading>
      <ul className={'relacje__gathering'}>
        <li>
          <a
            href={
              'https://res.cloudinary.com/de4rvmslk/image/upload/v1692822997/relacje-inwestorskie/Sprawozdanie_finansowe_Boldare_SA_za_2021.pdf'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            2021 &rarr;
          </a>
        </li>
        <li>
          <a
            href={
              'https://res.cloudinary.com/de4rvmslk/image/upload/v1692705126/relacje-inwestorskie/Sprawozdanie_finansowe_Boldare_SA_za_2022r.pdf'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            2022 &rarr;
          </a>
        </li>
      </ul>
    </Section>
  </>
);

export default RelacjeInwestorskie;
