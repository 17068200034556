import React, { Component } from 'react';
import Slider from 'react-slick';
import {
  Section,
  SectionHeader,
  Heading,
  Button,
  SliderArrow,
} from 'components';
import { data } from './logic';
import './Press.scss';

class Press extends Component {
  constructor() {
    super();

    this.state = {
      sliderSettings: {
        className: 'press__slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <SliderArrow id={'press__slider-next'} />,
        prevArrow: <SliderArrow id={'press__slider-prev'} left />,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  }

  renderItems = () =>
    data.map(item => (
      <article className={'press__article'} key={`press-${item}`}>
        <div className={'press__article-link'}>
          <a
            href={item.link}
            title={item.title}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <Heading type={3} size={'big'}>
              {item.title}
            </Heading>
          </a>
          <a
            href={item.link}
            title={item.title}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <Button order={'secondary'}>Read more</Button>
          </a>
        </div>
      </article>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section additionalClass={'newsroom__press'} spaceless>
          <div className={'section press__inner'}>
            <SectionHeader
              additionalClass={'section-header__description--medium'}
              color={'black'}
              lineColor={'pigeon-post'}
              size={'large'}
              headingType={2}
            >
              Boldare in press
            </SectionHeader>
            <div className={'press__list anim-fade-in-bottom'}>
              <Slider {...sliderSettings}>{this.renderItems()}</Slider>
            </div>
          </div>
        </Section>
      </>
    );
  }
}

export default Press;
