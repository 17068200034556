export const code = [
  `Code quality`,
  `Software architecture`,
  `Coding best practices`,
  `Code security review`,
  `Non-functional requirements (maintainability, reusability, reliability, extensibility, security, performance, scalability, usability)`,
  `Object-oriented analysis and design principles (SOLID)`,
  `List of recommendations`,
];

export const ux = [
  `Business and user objectives`,
  `Metrics and customer data`,
  `Usability guidelines compliance`,
  `Design evaluation`,
  `List of recommendations`,
];
