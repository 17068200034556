import React from 'react';
import { Button, Section, SectionHeader } from 'components';
import { Link } from 'gatsby';
import { getCloudinaryUrl } from 'config/helpers';
import Image from 'components/Image';
import './Reviews.scss';

const Reviews = () => (
  <Section color={'white'} additionalClass={'reviews'}>
    <SectionHeader
      color={'mine-shaft'}
      headingType={2}
      lineColor={'yellow'}
      size={'large'}
    >
      Review
    </SectionHeader>
    <div className={'reviews__wrapper'}>
      <div className={'reviews__image'}>
        <Image
          src={getCloudinaryUrl(
            '/img/renewable-energy-industry/norbert-baumann.jpg',
            'f_auto,q_auto,w_282',
          )}
          alt={'Norbert Baumann'}
        />
      </div>
      <div className={'reviews__text'}>
        <SectionHeader
          color={'mine-shaft'}
          headingType={2}
          size={'big'}
          noLine
          additionalClass={'reviews__quote'}
        >
          We really like that we have a&nbsp;partner who deeply understands the
          challenge
        </SectionHeader>
        <div className={'reviews__name'}>
          <div className={'text--bold'}>Norbert Baumann</div>
          <div>Technical Innovation Lead, Sonnen, Germany</div>
        </div>
        <Link
          to={'https://clutch.co/profile/boldare?page=2#review-76036'}
          className={'reviews__button'}
          blank
        >
          <Button color={'white'}>See why Norbert recommends us</Button>
        </Link>
      </div>
    </div>
  </Section>
);

export default Reviews;
