import React, { useState, useEffect } from 'react';
import { NavBar, Footer } from 'components';
import Course from 'views/resources/Course';
import Gate from 'views/resources/Gate';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import Layout from 'components/layout';
import config from '../../../meta/config';

const page = pages.resources.items['crash-course'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/crash-course/`;
const canonical = `${url}`;

const RiskManagementCrashCourse = () => {
  const [isGateOpen, setIsGateOpen] = useState(false);
  const pardotLink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('cc-gate=open_id-5701122219')
    ) {
      setIsGateOpen(true);
    }
  }, []);

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} banner={false} />
      {isGateOpen ? (
        <Course />
      ) : (
        <Gate
          type={'Crash Course'}
          title={
            'How to manage risks,web products & software teams in recession'
          }
          description={`Summary of the 3-part crash course is designed to guide you through web product building, tailored for uncertain times of recession. It covers strategic setup, risk management tactics, and building agile teams.`}
          paragraphHeadings={[
            `The course features real-life cases discussed by:`,
            `During the course you will learn:`,
            `Enjoy our information-packed crash course for free!`,
          ]}
          speakers={[
            'Jeff Ko - Founder of Chartipedia',
            'Allan Wilson - Founder of Community Response Systems',
            'Issam Al Najm - CTO of Ionoview and Matic',
          ]}
          items={[
            `How to build web products using a lean startup approach and why this framework is perfect for recession times.`,
            `How to manage the risks of web product building, including issues regarding software development, market fit, budgeting, and more.`,
            `How to build distributed development teams that deliver with an agile approach - strategies and tactics.`,
          ]}
          url={pardotLink}
          formHeading={'Get your free access to the crash course summary'}
          button={'Get your access'}
          btnColor={'yellow'}
          trackingEvent={`crashCourseGateSubmit`}
          gateAddress={'cc-gate=open_id-5701122219'}
          gateCatalog={'resources'}
        />
      )}
      <Footer />
    </Layout>
  );
};

export default RiskManagementCrashCourse;
