import React from 'react';
import { Section, Heading, FullwidthImage, UnorderedList } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Team.scss';

const Team = () => {
  return (
    <>
      <Section additionalClass={'sm__team-container'}>
        <div className={'sm__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            People involved
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            Augmented team working in one year collaboration
          </Heading>
        </div>
      </Section>
      <div className={'sm__team-map'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-map.jpg',
            'f_auto,q_auto',
          )}
          alt={'Teams in Poland and Hong Kong'}
        />
        <Section additionalClass={'sm__team-wrapper'} spaceless>
          <div className={'sm__content'}>
            <div className={'sm__team-box'}>
              <Heading type={3} size={'medium-plus'}>
                Team in Poland
              </Heading>
              <Heading
                type={4}
                color={'boston-blue'}
                size={'medium-plus-small'}
              >
                6 people
              </Heading>
              <UnorderedList
                containerClass={'sm__team-list'}
                additionalClass={'text--default'}
                items={[
                  '3 front-end developers',
                  '1 graphic designer',
                  '1 product designer',
                  '1 business owner',
                ]}
              />
            </div>
            <div className={'sm__team-box'}>
              <Heading type={3} size={'medium-plus'}>
                Team in Hong Kong
              </Heading>
              <Heading
                type={4}
                color={'boston-blue'}
                size={'medium-plus-small'}
              >
                5 people
              </Heading>
              <UnorderedList
                containerClass={'sm__team-list'}
                additionalClass={'text--default'}
                items={['4 back-end developers', '1 product owner']}
              />
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default Team;
