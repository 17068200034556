import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Section, Heading } from 'components';
import './Separator.scss';

const Separator = ({ text }) => {
  return (
    <Section additionalClass={'pakt__separator-container'} spaceless>
      <div className={'pakt__content--left'}>
        <Heading
          type={3}
          size={'medium-plus'}
          color={'gray-dark'}
          additionalClass={'text--light'}
        >
          {ReactHtmlParser(text)}
        </Heading>
      </div>
      <div className={'pakt__content'} />
    </Section>
  );
};

Separator.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Separator;
