import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Link } from 'components';
import { ICON_TYPES } from 'config';
import { careerExperienceLevels } from 'views/career/career.utils';
import { classNames } from 'utils';
import './SingleOffer.scss';

const SingleOffer = React.memo(props => {
  const { id, cities, salaryRange, category, name, link, talentPool } = props;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 480);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Link
      id={id}
      to={link}
      additionalClass={classNames(
        'offer__container',
        'anim-fade-in-bottom',
        `ga-career--single-offer-${name.replace(/ /g, '-')}`,
      )}
    >
      <div className={'offer__heading'}>
        <div className={'offer__heading--wrapper'}>
          <h3 className={'offer__detail--short'}>{name}</h3>
          {!isMobile && talentPool ? (
            <div className={'offer__talent-pool'}>Talent Pool</div>
          ) : (
            <div className={'offer__talent-pool-invisible'} />
          )}
        </div>
        <div>
          {isMobile && talentPool ? (
            <div className={'offer__talent-pool--mobile'}>
              <div className={'offer__talent-pool'}>Talent Pool</div>
              <div className={'offer__category'}>{category} </div>
            </div>
          ) : (
            <div className={'offer__category'}>{category} </div>
          )}
        </div>
      </div>

      <div className={'offer__info'}>
        <div className={'offer__salary offer__detail--long'}>
          {Object.entries(salaryRange).map(item => {
            return (
              <div>
                {careerExperienceLevels[item[0]]} up to{' '}
                <b>{item[1].max} PLN</b>
              </div>
            );
          })}
        </div>
        <div className={'offer__location offer__detail--long'}>
          <div className={'offer__location-text'}>
            {cities.cities}
            {cities.isRemoteActive && (
              <span className={'offer__location--remote'}>
                {cities.isRemoteActive}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className={'offer__arrow-container'}>
        <Icon icon={ICON_TYPES.RIGHT_ARROW} size={'default'} color={'black'} />
      </div>
    </Link>
  );
});

SingleOffer.propTypes = {
  id: PropTypes.string.isRequired,
  salaryRange: PropTypes.object,
  talentPool: PropTypes.bool.isRequired,
  cities: PropTypes.shape({
    cities: PropTypes.string.isRequired,
    isRemoteActive: PropTypes.bool.isRequired,
  }),
  category: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
};

SingleOffer.defaultProps = {
  salaryRange: {},
  cities: {},
  category: null,
  name: null,
  link: null,
};

export default SingleOffer;
