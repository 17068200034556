import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { Link, IconSVG, Button } from 'components';
import { classNames, getPlatformShareUrl } from 'utils';
import './OldCaseStudyButtons.scss';

class OldCaseStudyButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSharePanelVisible: false,
    };
  }

  toggleSharePanel = () =>
    this.setState(prevState => ({
      isSharePanelVisible: !prevState.isSharePanelVisible,
    }));

  render() {
    const { additionalClass, link, title, cover } = this.props;
    const { isSharePanelVisible } = this.state;
    let url = '';

    if (typeof window !== 'undefined') {
      url = window.location.href;
    }

    return (
      <>
        <div className={'old-case-study-buttons__container'}>
          {link && (
            <Link to={link} additionalClass={`${additionalClass}__url`}>
              <Button
                id={`${additionalClass} + '-link'`}
                color={'black'}
                ariaName={'See online button'}
                additionalClass={
                  'old-case-study-buttons__button old-case-study-buttons__button--dark'
                }
              >
                See online
              </Button>
            </Link>
          )}
          <Button
            id={`${additionalClass}-share`}
            color={'light-gray'}
            ariaName={'See online button'}
            additionalClass={
              'old-case-study-buttons__button old-case-study-buttons__button--light'
            }
            onClick={this.toggleSharePanel}
          >
            Share
          </Button>
        </div>
        <div
          className={'old-case-study-buttons__share'}
          className={classNames('old-case-study-buttons__share', {
            isVisible: isSharePanelVisible,
          })}
        >
          <FacebookShareButton
            url={getPlatformShareUrl(url, 'Facebook')}
            hashtag={'#Boldare'}
          >
            <IconSVG
              name={'facebook-letter'}
              size={'big'}
              color={'black'}
              additionalClass={classNames(
                'old-case-study-buttons__share--facebook',
                {
                  [`${additionalClass}__share--facebook`]: additionalClass,
                },
              )}
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={getPlatformShareUrl(url, 'Twitter')}
            title={`${title} via @boldarecom`}
          >
            <IconSVG
              name={'twitter'}
              size={'big'}
              color={'black'}
              additionalClass={classNames(
                'old-case-study-buttons__share--twitter',
                {
                  [`${additionalClass}__share--twitter`]: additionalClass,
                },
              )}
            />
          </TwitterShareButton>
          <LinkedinShareButton
            url={getPlatformShareUrl(url, 'Linkedin')}
            title={'by @boldarecom'}
          >
            <IconSVG
              name={'linkedin-letter'}
              size={'big'}
              color={'black'}
              additionalClass={classNames(
                'old-case-study-buttons__share--linkedin',
                {
                  [`${additionalClass}__share--linkedin`]: additionalClass,
                },
              )}
            />
          </LinkedinShareButton>
        </div>
      </>
    );
  }
}

OldCaseStudyButtons.defaultProps = {
  additionalClass: '',
  cover: null,
  link: null,
  title: 'Case Study',
};

OldCaseStudyButtons.propTypes = {
  additionalClass: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.string,
  title: PropTypes.string,
};

export default OldCaseStudyButtons;
