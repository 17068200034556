import React from 'react';
import Showbiz from 'views/showbiz';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages.work.items['case-study-showbiz'];
const { navColors } = page;
const url = `${config.siteUrl}/work/case-study-showbiz/`;
const canonical = `${url}`;

const SPage = () => (
  <Layout {...{ page, canonical, url }}>
    <NavBar color={navColors} />
    <Showbiz />
  </Layout>
);

export default SPage;
