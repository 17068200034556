import Architecture from './Architecture';
import Contact from './Contact';
import Cooperation from './Cooperation';
import Design from './Design';
import Details from './Details';
import Hero from './Hero';
import Instruction from './Instruction';
import Intro from './Intro';
import OldWebsite from './OldWebsite';
import Team from './Team';
import Technology from './Technology';

export {
  Architecture,
  Contact,
  Cooperation,
  Design,
  Details,
  Hero,
  Instruction,
  Intro,
  OldWebsite,
  Team,
  Technology,
};
