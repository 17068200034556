import React from 'react';
import { Section } from 'components';
import './BoldareBoards.scss';
import { BlogBoldareBoards } from 'views/blog/subcomponents';

const BoldareBoards = () => {
  return (
    <Section additionalClass={'resources-hub__boldare-boards'} spaceless>
      <BlogBoldareBoards />
    </Section>
  );
};

export default BoldareBoards;
