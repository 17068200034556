import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { IconSVG, Link } from 'components';
import forbes from 'assets/images/about-us/forbes.svg';
import golem from 'assets/images/about-us/golem.svg';
import gq from 'assets/images/about-us/gq.svg';
import medium from 'assets/images/about-us/medium.svg';
import yahoo from 'assets/images/about-us/yahoo.svg';
import Stats from '../Stats';
import Image from '../Image';
import './SectionAwards.scss';

const SectionAwards = ({ isDesktop }) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.matchMedia({
      '(max-width: 600px)': () => {
        gsap.to('.section-awards', {
          scrollTrigger: {
            trigger: '.section-awards',
            start: '40% bottom',
            toggleClass: {
              targets: '.section-awards',
              className: 'gray',
            },
          },
        });
      },
      '(min-width: 600px)': () => {
        gsap.to('.section-awards', {
          scrollTrigger: {
            trigger: '.section-awards',
            start: '60% bottom',
            toggleClass: {
              targets: '.section-awards',
              className: 'gray',
            },
          },
        });
      },
    });
  }, []);

  return (
    <div className={'section-wrapper section-awards'}>
      <section className={'grid-layout'}>
        <h2 className={'about-header'}>The numbers speak for us</h2>
        <Stats />
        <h2 className={'about-header'}>Featured in</h2>
        <div className={'separator-full'} />
        <Image
          className={'section-awards__logo--big'}
          imageName={'logo-clutch'}
        />
        <Image
          className={'section-awards__logo--big'}
          imageName={'logo-Inc'}
        />
        <Image
          className={'section-awards__logo--big'}
          imageName={'logo-awwwards'}
        />
        <Image
          className={'section-awards__logo--big'}
          imageName={'logo-nextgen'}
        />
        <div className={'separator-full'} />
        {isDesktop && (
          <>
            <Image
              className={'section-awards__logo'}
              imageName={'logo-yahoo'}
            />
            <Link
              to={
                'https://finance.yahoo.com/news/boldare-launches-cto-unique-differentiators-140000536.html?guccounter=1'
              }
              additionalClass={'article-link'}
              blank
            >
              Boldare Launches CTO as a Service with Unique Differentiators
              that Accelerate Growth and Enrich User Experience
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <Image
              className={'section-awards__logo'}
              imageName={'logo-forbes'}
            />
            <Link
              to={
                'https://www.forbes.pl/opinie/holakracja-nowy-sposob-zarzadzania-zespolem-po-pandemii/r67e5vv'
              }
              additionalClass={'article-link'}
              blank
            >
              Holakracja. Nadchodzi świat pracy bez menedżerów
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <div className={'separator'} />
            <div className={'separator'} />
            <Image className={'section-awards__logo'} imageName={'logo-gq'} />
            <Link
              to={
                'https://www.gq-magazin.de/lifestyle/artikel/gquestionnaire-anna-zarudzka-von-boldare-beantwortet-den-gq-business-fragebogen'
              }
              additionalClass={'article-link'}
              blank
            >
              “Die beste Karriereentscheidung? Ein Business-Hippie zu sein” –
              Anna Zarudzka von Boldare beantwortet den GQ-Business-Fragebogen
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <Image
              className={'section-awards__logo'}
              imageName={'logo-medium'}
            />
            <Link
              to={
                'https://medium.com/authority-magazine/preparing-for-the-future-of-work-anna-zarudzka-of-boldare-on-the-top-five-trends-to-watch-in-the-7ef6fc10656'
              }
              additionalClass={'article-link'}
              blank
            >
              Preparing For The Future Of Work: Anna Zarudzka of Boldare On The
              Top Five Trends To Watch In The Future Of Work
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <div className={'separator'} />
            <div className={'separator'} />
            <Image
              className={'section-awards__logo'}
              imageName={'logo-golem'}
            />
            <Link
              to={
                'https://karrierewelt.golem.de/blogs/karriere-ratgeber/jaroslaw-kroczek-von-boldare-gute-bezahlung-reicht-heute-nicht-mehr-aus?utm_source=golem&utm_medium=artikel&utm_campaign=artikel'
              }
              additionalClass={'article-link'}
              blank
            >
              Jaroslaw Kroczek von Boldare: "Gute Bezahlung reicht heute nicht
              mehr aus"
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <Image className={'section-awards__logo'} imageName={'logo-t3n'} />
            <Link
              to={
                'https://t3n.de/news/silicon-polska-tech-hotspot-polen-1369122/'
              }
              additionalClass={'article-link'}
              blank
            >
              Silicon Polska – der Tech-Hotspot in der europäischen
              Nachbarschaft
              <IconSVG
                additionalClass={'arrow-svg'}
                name={'arrow-right'}
                color={'mine-shaft'}
              />
            </Link>
            <div className={'separator'} />
            <div className={'separator'} />
          </>
        )}
        {!isDesktop && (
          <div className={'articles-wrapper'}>
            <div className={'articles-wrapper__article'}>
              <img
                src={yahoo}
                className={'section-awards__logo'}
                alt={'yahoo logo'}
              />
              <Link
                to={
                  'https://finance.yahoo.com/news/boldare-launches-cto-unique-differentiators-140000536.html?guccounter=1'
                }
                additionalClass={'article-link'}
                blank
              >
                Boldare Launches CTO as a Service with Unique Differentiators
                that Accelerate Growth and Enrich User Experience
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
            <div className={'articles-wrapper__article'}>
              <img
                src={forbes}
                className={'section-awards__logo'}
                alt={'forbes logo'}
              />
              <Link
                to={
                  'https://www.forbes.pl/opinie/holakracja-nowy-sposob-zarzadzania-zespolem-po-pandemii/r67e5vv'
                }
                additionalClass={'article-link'}
                blank
              >
                Holakracja. Nadchodzi świat pracy bez menedżerów
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
            <div className={'articles-wrapper__article'}>
              <img
                src={gq}
                className={'section-awards__logo'}
                alt={'gq logo'}
              />
              <Link
                to={
                  'https://www.gq-magazin.de/lifestyle/artikel/gquestionnaire-anna-zarudzka-von-boldare-beantwortet-den-gq-business-fragebogen'
                }
                additionalClass={'article-link'}
                blank
              >
                “Die beste Karriereentscheidung? Ein Business-Hippie zu sein” –
                Anna Zarudzka von Boldare beantwortet den
                GQ-Business-Fragebogen
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
            <div className={'articles-wrapper__article'}>
              <img
                src={medium}
                className={'section-awards__logo'}
                alt={'medium logo'}
              />
              <Link
                to={
                  'https://medium.com/authority-magazine/preparing-for-the-future-of-work-anna-zarudzka-of-boldare-on-the-top-five-trends-to-watch-in-the-7ef6fc10656'
                }
                additionalClass={'article-link'}
                blank
              >
                Preparing For The Future Of Work: Anna Zarudzka of Boldare On
                The Top Five Trends To Watch In The Future Of Work
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
            <div className={'articles-wrapper__article'}>
              <img
                src={golem}
                className={'section-awards__logo'}
                alt={'golem logo'}
              />
              <Link
                to={
                  'https://karrierewelt.golem.de/blogs/karriere-ratgeber/jaroslaw-kroczek-von-boldare-gute-bezahlung-reicht-heute-nicht-mehr-aus?utm_source=golem&utm_medium=artikel&utm_campaign=artikel'
                }
                additionalClass={'article-link'}
                blank
              >
                Jaroslaw Kroczek von Boldare: "Gute Bezahlung reicht heute
                nicht mehr aus"
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
            <div className={'articles-wrapper__article'}>
              <Image
                className={'section-awards__logo'}
                imageName={'logo-t3n'}
              />
              <Link
                to={
                  'https://t3n.de/news/silicon-polska-tech-hotspot-polen-1369122/'
                }
                additionalClass={'article-link'}
                blank
              >
                Silicon Polska – der Tech-Hotspot in der europäischen
                Nachbarschaft
                <IconSVG
                  additionalClass={'arrow-svg'}
                  name={'arrow-right'}
                  color={'mine-shaft'}
                />
              </Link>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SectionAwards;

SectionAwards.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};
