import React from 'react';
import Layout from 'components/layout';
import { NavBar, SEO } from 'components';
import { pages } from 'config/pages';
import SprintRetrospectiveTool from 'views/sprintRetrospectiveTool';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../meta/config';

const page = pages['sprint-retrospective-tool'];
const { navColors, metaTitle, description, tile, slug, cover } = page;
const canonical = `${config.siteUrl}/${slug}/`;

const sprintRetrospectiveTool = () => {
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={description}
        tile={tile}
        slug={slug}
        url={canonical}
        cover={getCloudinaryUrl(cover, 'f_auto,q_auto,w_400')}
        canonical={canonical}
        cloudinary
      />
      <NavBar color={navColors} />
      <SprintRetrospectiveTool />
    </Layout>
  );
};

export default sprintRetrospectiveTool;
