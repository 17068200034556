import React, { useEffect } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import Glide from '@glidejs/glide';
import './ImageSlider.scss';

const ImageSlider = ({ id, items }) => {
  useEffect(() => {
    const slider = new Glide(`#${id}`, {
      type: 'carousel',
      autoplay: 3000,
      hoverpause: false,
      animationDuration: 800,
      animationTimingFunc: 'linear',
      perView: 1,
      startAt: 0,
      focusAt: 0,
      gap: 0,
    }).mount();
    return () => {
      slider.destroy();
    };
  }, []);

  return (
    <div id={id} className={classnames('glide', 'about-us__image-slider')}>
      <div className={'glide__track'} data-glide-el={'track'}>
        <ul className={'glide__slides'}>
          {items.map(item => (
            <li className={'glide__slide'}>
              <img
                className={`about-us__slide-image`}
                alt={item.alt}
                src={getCloudinaryUrl(
                  [item.cloudinaryDir],
                  `f_auto,q_auto,w_752`,
                )}
                loading={'lazy'}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={'glide__bullets'} data-glide-el={'controls[nav]'}>
        {items.map((item, index) => (
          <button className={'glide__bullet'} data-glide-dir={`=${index}`} />
        ))}
      </div>
    </div>
  );
};

ImageSlider.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cloudinaryDir: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ImageSlider;
