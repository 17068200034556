import React, { PureComponent } from 'react';
import { kebabCase } from 'lodash';
import { Section, Heading, Paragraph, Button, Link, Image } from 'components';
import Logo from 'assets/images/logo-signet.svg';
import LinkedIn from 'assets/images/logos/linkedin.svg';
import { getCloudinarySrcSet, getCloudinaryUrl } from 'config/helpers';
import { data, paths } from './logic.js';
import config from '../../../meta/config';
import IconSVG from '../IconSVG';
import './FooterCto.scss';

const Clutch = 'gatsby-cloudinary/Clutch_Badge.png';
const Goodfirms = 'gatsby-cloudinary/Goodfirms_Badge.png';
const Awwwards = 'gatsby-cloudinary/awwwards-badge-12_2x.png';
const GermanDesignAward = 'gatsby-cloudinary/german-design-award_2x.png';

class Desktopfooter extends PureComponent {
  renderColumn(elements) {
    const items = elements;
    this.filteredPaths = paths.filter(item => items.includes(item.name));
    return this.renderNavItems();
  }

  renderNavItems = () =>
    this.filteredPaths.map(item => {
      const { link, name, children } = item;

      return (
        <div key={item.name} className={'footer-d__menu-item'}>
          <>
            {name === 'Privacy Policy' ? (
              <Link
                id={`footer-d-nav-${kebabCase(name.toLowerCase())}`}
                to={link}
                key={`nav-item-${name}`}
                rel={'nofollow'}
              >
                <span className={'text--white footer-d__privacy-policy'}>
                  {name}
                </span>
              </Link>
            ) : (
              <Link
                id={`footer-d-nav-${kebabCase(name.toLowerCase())}`}
                to={link}
                key={`nav-item-${name}`}
              >
                <span className={'text--white'}>{name}</span>
              </Link>
            )}
          </>
        </div>
      );
    });

  renderPlaces = () =>
    data.map(item => (
      <div key={item.title} className={'footer-d__info-place'}>
        <Paragraph
          size={'medium'}
          color={'white'}
          additionalClass={'footer-d__info-place--hq text--bold'}
        >
          {item.title}
        </Paragraph>
        <Paragraph color={'white'} additionalClass={'footer-d__info-address'}>
          <Link to={item.map}>
            <span
              id={`footer-d-map-${kebabCase(item.city.toLowerCase())}`}
              className={'text--white'}
            >
              {item.street}
              <br />
            </span>
          </Link>
          {item.postal}&nbsp;{item.city}
          <br />
          {item.country}
        </Paragraph>
      </div>
    ));

  render = () => (
    <Section color={'mine-shaft'} fullwidth additionalClass={'footer-d'}>
      <div className={'footer-d__top'}>
        <div className={'footer-d__top-quarter footer-d__top-quarter--bldr'}>
          <div className={'footer-d__top-content'}>
            <Link to={'/'} additionalClass={'footer-d__top-logo'}>
              <img
                src={Logo}
                className={'footer-d__top-bldr'}
                alt={'Digital product design company Boldare'}
                loading={'lazy'}
              />
            </Link>
          </div>
        </div>
        <div
          className={'footer-d__top-quarter footer-d__top-quarter--contact'}
        >
          <div className={'footer-d__top-content'}>
            <Link
              className={'footer-d__top-email contact-mail'}
              to={'mailto:matt.hallmann@boldare.com@boldare.com'}
            >
              <Button color={'white'}>
                matt.hallmann@boldare.com &#8594;
              </Button>
            </Link>
            <a
              href={'https://www.linkedin.com/in/matthallmann/'}
              additionalClass={'footer-d__top-sm'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <img
                id={'linkedin'}
                src={LinkedIn}
                alt={'LinkedIn'}
                loading={'lazy'}
              />
            </a>
          </div>
        </div>
      </div>
      <div>
        <footer id={'footer-desktop'} className={'footer-d'}>
          {!this.props.compact && (
            <>
              <div className={'footer-d__links'}>
                <nav
                  className={'footer-d__menu'}
                  aria-label={'main navigation'}
                >
                  {typeof window !== 'undefined' && window.location && (
                    <>
                      <div className={'footer-d__menu-column'}>
                        {this.renderColumn([
                          'Services',
                          'About us',
                          'Privacy Policy',
                          'New Normal',
                        ])}
                      </div>
                      <div className={'footer-d__menu-column'}>
                        {this.renderColumn([
                          'Contact',
                          'Career',
                          'Blog',
                          'Boldare Boards',
                        ])}
                      </div>
                    </>
                  )}
                </nav>
                <div className={'footer-d__info'}>
                  <div className={'footer-d__info-places'}>
                    {this.renderPlaces()}
                  </div>
                  <div className={'footer-d__badges'}>
                    <Link
                      to={config.awwwards}
                      id={'footer-d-awwwards'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(Awwwards, 'f_auto,q_auto,w_120')}
                        className={'footer-d__logo'}
                        alt={'Awwwards Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link to={config.gda} id={'footer-d-gda'} rel={'nofollow'}>
                      <img
                        src={getCloudinaryUrl(
                          GermanDesignAward,
                          'f_auto,q_auto,w_120',
                        )}
                        className={'footer-d__logo'}
                        alt={'German Design Awards Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link
                      to={config.clutch}
                      id={'footer-d-clutch'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(Clutch, 'f_auto,q_auto,h_100')}
                        className={'footer-d__logo'}
                        alt={'Clutch Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                    <Link
                      to={config.goodfirms}
                      id={'footer-d-goodfirms'}
                      rel={'nofollow'}
                    >
                      <img
                        src={getCloudinaryUrl(
                          Goodfirms,
                          'f_auto,q_auto,h_100',
                        )}
                        className={'footer-d__logo'}
                        alt={'GoodFirms Badge'}
                        loading={'lazy'}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
          <Paragraph
            size={'small'}
            color={'white'}
            additionalClass={'footer-d__copyrights'}
          >
            &#169; {new Date().getFullYear()} Boldare. All rights reserved.
          </Paragraph>
        </footer>
      </div>
    </Section>
  );
}

export default Desktopfooter;
