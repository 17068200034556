export const pageDescriptionData = {
  header: {
    image: 'apb-community/apb-logo-white.svg',
    imageDescription: 'Community newsletter',
    description: 'Monthly digest to help you build better products',
    logo: 'logos/boldare-logo-white.svg',
    logoLabel: 'Powered by:',
  },
  description: {
    definitions: [
      {
        title: 'Monthly',
        text: 'every 4th Monday',
      },
      {
        title: 'Topical',
        text: 'each edition has its focus',
      },
      {
        title: 'Practical',
        text: 'actionable insights from experts',
      },
    ],
    items: [
      {
        title: 'Product mastery for the busy',
        description: [
          'All professional builders of digital products share one trait: <b>they are extremely busy.</b> This means they have little time for constant learning, sifting through the most valuable insights, and keeping up with news that matters.',
          'It happens we already have this kind of information, because that is our duty at Boldare <b>to stay on the top of things while getting to the bottom of them.</b> And our work can be also useful to you.',
          'Inspired by Agile Product Builders Community and with its help, each month we pick the key topic for this period and share only the practical information that we use ourselves while helping our clients build products their users want.',
          'Everyone in the community can pitch their topics and the content we want to curate, and you can have a say in this too — <b>upon signing to the newsletter you get an exclusive access to Agile Product Builders Community.</b>',
        ],
      },
      {
        title: 'What is community is about?',
        description: [
          'Agile Product Builders is a community for people to come together and share their experience around digital products. ',
          'The community mission is to connect, disrupt, challenge and excite everyone who has something to do with product building.',
        ],
      },
    ],
  },
};
