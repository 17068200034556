import Challenge from './Challenge';
import Changes from './Changes';
import Contact from './Contact';
import Features from './Features';
import Goals from './Goals';
import Intro from './Intro';
import Product from './Product';
import Sketches from './Sketches';
import Team from './Team';
import Technology from './Technology';

export {
  Challenge,
  Changes,
  Contact,
  Features,
  Goals,
  Intro,
  Product,
  Sketches,
  Team,
  Technology,
};
