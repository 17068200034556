import React, { Component } from 'react';
import Glide, { Anchors } from '@glidejs/glide/dist/glide.modular.esm';
import ReactHtmlParser from 'react-html-parser';
import { Link, Heading, Paragraph, Button } from 'components';
import ReactPlayer from 'react-player/lazy';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import './MainSlider.scss';
import { data } from './logic';

class AboutSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
      windowWidth: 0,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.glide = new Glide('.glide', {
      type: 'slideshow',
      animationDuration: 0,
      dragThreshold: false,
    });

    this.glide.on(['build.after', 'run.after'], () => {
      const video = document.querySelector(
        `.main-slider__video-${this.glide.index} video`,
      );
      this.setState({ currentSlideIndex: this.glide.index });

      if (video) {
        video.currentTime = 0;
        video.play();
      }
    });

    Event.prototype.stopPropagation = function() {};

    this.glide.mount({ Anchors });
  }

  componentWillUnmount() {
    this.glide.destroy();
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleNextSlide = () => this.glide.go('>');

  renderSlides = homepageIntroHeight => {
    const { windowWidth, currentSlideIndex } = this.state;

    return data.map((item, index) => {
      const linkId = `${item.intro.split(',')[0].toLowerCase()}`;

      return (
        <li
          className={'main-slider__slide-container glide__slide'}
          // eslint-disable-next-line react/no-array-index-key
          key={`main-slide-${index}`}
        >
          {currentSlideIndex === index && (
            <div
              style={{
                height:
                  windowWidth > 1200
                    ? `calc(100vh - ${homepageIntroHeight}px)`
                    : '',
              }}
              className={classNames('main-slider__slide-wrapper', {
                [`main-slider__slide-wrapper--${item.color}`]: item.color,
              })}
            >
              <div className={'main-slider__top'} />
              <div className={'w-container w-container--left'}>
                <div className={'main-slider__content'}>
                  <div className={'main-slider__body'}>
                    <div className={'main-slider__box'}>
                      <a href={item.link}>
                        <Heading
                          type={2}
                          additionalClass={'main-slider__box-headline'}
                        >
                          {item.headline}
                        </Heading>
                      </a>
                      <Paragraph
                        color={'gray-dark'}
                        additionalClass={'main-slider__box-content'}
                        spaced
                      >
                        {ReactHtmlParser(item.description)}
                      </Paragraph>
                      <a href={item.link} rel={'nofollow'}>
                        <Button
                          id={`main-slider-${linkId}`}
                          order={'secondary'}
                          color={'black'}
                          ariaName={'Case study button'}
                        >
                          See case study
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'main-slider__media'}>
                <Link to={item.link}>
                  {windowWidth > 767 ? (
                    <ReactPlayer
                      className={`main-slider__video-${index}`}
                      url={item.video}
                      width={
                        windowWidth >= 768 && windowWidth < 992
                          ? '100%'
                          : 'auto'
                      }
                      height={
                        windowWidth >= 768 && windowWidth < 992
                          ? 'auto'
                          : '100%'
                      }
                      playing={index === currentSlideIndex}
                      muted
                      config={{
                        file: { attributes: { preload: 'metadata' } },
                      }}
                      onEnded={this.handleNextSlide}
                    />
                  ) : (
                    <img
                      src={getCloudinaryUrl(item.coverImg, 'f_auto,q_auto')}
                      className={'image main-slider__slide'}
                      alt={''}
                      loading={'lazy'}
                    />
                  )}
                </Link>

                <div
                  className={classNames('main-slider__teaser-wrapper', {
                    [`main-slider__teaser-wrapper--${item.color}`]: item.color,
                  })}
                  onClick={this.handleNextSlide}
                  role={'presentation'}
                  id={'main-slider-next'}
                >
                  <div className={'main-slider__teaser'}>
                    Next:&nbsp;
                    <span className={'main-slider__next-headline'}>
                      {data[index + 1]
                        ? data[index + 1].headline
                        : data[0].headline}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </li>
      );
    });
  };

  render() {
    const homepageIntroHeight = 300;

    return (
      <div className={'main-slider__wrapper'}>
        <div className={'main-slider glide'}>
          <div data-glide-el={'track'} className={'glide__track'}>
            <ul className={'glide__slides'}>
              {this.renderSlides(homepageIntroHeight)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSlider;
