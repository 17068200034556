import React from 'react';
import { Section, Heading, BackgroundImage, Paragraph } from 'components';
import LogoWhite from 'assets/images/brand/logo-white.svg';
import './Team.scss';

const Team = () => {
  return (
    <Section additionalClass={'showbiz__team-container'} spaceless>
      <div className={'showbiz__team-content'}>
        <BackgroundImage
          asset={'assets/showbiz/showbiz-2teams.jpg'}
          additionalClass={'showbviz__team-background'}
        />
        <div className={'showbiz__team-logo'}>
          <img
            className={'image'}
            src={LogoWhite}
            alt={'Digital product design company Boldare'}
            loading={'lazy'}
          />
        </div>
        <Heading type={2} size={'big-spreaded'} color={'white'}>
          2&nbsp;Teams /&nbsp;1&nbsp;Office /&nbsp;1&nbsp;Scrum
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-plus'}
          color={'white'}
        >
          1&nbsp;PO +&nbsp;1&nbsp;SM +&nbsp;2&nbsp;Designers
          +&nbsp;2&nbsp;Front-end Developers +&nbsp;2&nbsp;PHP Developers
        </Paragraph>
      </div>
    </Section>
  );
};

export default Team;
