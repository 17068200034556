import inView from 'in-view';
import React, { createElement } from 'react';
import { has, startCase } from 'lodash';
import marksy from 'marksy/jsx';

import {
  AiExpertsContactCtaBanner,
  BlogBox,
  BlogLink,
  BlogQuoteAuthor,
  CodeBlock,
  DigitalTransformationCtaBanner,
  EngagementBanner,
  EngagementBannerAI,
  ConsultationBanner,
  SimpleBannerWithPerson,
  SimpleBannerWithoutPerson,
  Iframe,
  ImageCaption,
  NewNormalBanner,
  RelatedArticle,
  RelatedUniversalBox,
  RelatedUniversalBoxAlternative,
} from 'components';

import Calculator from 'views/appCostsCalculator/subcomponents/Calculator';
import AiAssistantBanner from 'views/blog/subcomponents/AiAssistantBanner';
import BlogBoldareBoards from '../../views/blog/subcomponents/BlogBoldareBoards';

import {
  WorkroomCoffee,
  WorkroomMap,
  WorkroomRestaurant,
} from '../../views/singleArticle/articles/Workroom';

import {
  CybersixgillSolution,
  CybersixgillResults,
  CybersixgillKeyImage,
} from '../../views/singleArticle/articles/Cybersixgill';

export const isResolution = breakpoint =>
  (typeof window !== 'undefined' && window.innerWidth > breakpoint) === false;

const inViewAnimationClasses = [
  '.anim-fade-in',
  '.anim-fade-in-bottom',
  '.anim-fade-in-left',
  '.anim-fade-in-right',
  '.anim-slide-in-top-in-view',
  '.anim-list-item',
  '.anim-scale-out-in-center',
];

const nofollowException = ['boldare', 'designrush'];

export const enableAnimations = () => {
  inViewAnimationClasses.forEach(animClass => {
    inView(animClass).on('enter', el => {
      el.classList.add('is-inview');
    });
  });
};

export const getImageAlt = _fileName => {
  let fileName = _fileName;

  if (typeof _fileName === 'object' && _fileName.base64) {
    fileName = _fileName.base64;
  }

  if (fileName.includes('data:image')) {
    return '';
  }

  return startCase(
    fileName
      .split('/')
      .pop()
      .replace(/\.[^/.]+$/, ''),
  );
};

export const getArticleSlug = post => {
  const { frontmatter, fields } = post.node;
  const { templateKey, settings } = frontmatter;

  const availableSlugs = {
    'article-page': 'blog',
    'case-study-page': 'work',
    'job-offer-page': 'career',
    'review-page': 'reviews',
    'keyword-page': 'services',
    'event-page': 'event',
  };

  const slugBase = (() => availableSlugs[templateKey])();

  if (!has(settings, 'slug')) {
    return fields.slug;
  }
  if (settings.slugType) {
    return `/${settings.slugType}/${settings.slug}/`;
  }
  return `/${slugBase}/${settings.slug}/`;
};

export const getArticleByName = (name, articles) => {
  const article = articles.filter(
    edge => edge.node.frontmatter.title.trim() === name.trim(),
  );

  return article.length > 0 ? article[0] : null;
};

export const getCloudinaryUrl = (asset, attrs, type = 'image') => {
  let sanitizedAsset = null;

  if (asset) {
    if (asset.includes('res.cloudinary')) {
      const substr = asset.substring(asset.indexOf('v1'));
      sanitizedAsset = substr.replace(substr.split('/')[0], '');
    } else {
      sanitizedAsset = asset;
    }
  }

  return sanitizedAsset
    ? `https://res.cloudinary.com/de4rvmslk/${type}/upload/${attrs}/${sanitizedAsset}`
    : null;
};

export const getCloudinarySrcSetArray = (asset, maxWidth = 2000) => {
  if (
    !asset ||
    asset.search('data:') !== -1 ||
    (asset.search('https://') !== -1 &&
      asset.search('res.cloudinary.com') === -1)
  )
    return [];
  const srcset = [];
  [300, 400, 600, 800, 1000, 1300, 1600, 2000].forEach(width => {
    let url =
      asset.search('res.cloudinary.com') !== -1
        ? asset.replace('w_auto', '')
        : `https://res.cloudinary.com/de4rvmslk/image/upload/q_auto,f_auto/${asset}`;

    url = url.replace(
      '/upload/',
      `/upload/w_${width > maxWidth ? maxWidth : width}/`,
    );

    srcset.push(`${url} ${width}w`);
  });
  return srcset;
};

export const getCloudinarySrcSet = (asset, maxWidth = 2000) => {
  return getCloudinarySrcSetArray(asset, maxWidth).join(', ');
};

export const toHtml = (markdown, context) => {
  const compile = marksy({
    createElement,
    components: {
      AiExpertsContactCtaBanner,
      BlogBoldareBoards,
      BlogBox,
      BlogLink,
      BlogQuoteAuthor,
      CodeBlock,
      DigitalTransformationCtaBanner,
      EngagementBanner,
      EngagementBannerAI,
      ConsultationBanner,
      SimpleBannerWithPerson,
      SimpleBannerWithoutPerson,
      Iframe,
      ImageCaption,
      NewNormalBanner,
      RelatedArticle,
      Calculator,
      RelatedUniversalBox,
      RelatedUniversalBoxAlternative,
      WorkroomCoffee,
      WorkroomMap,
      WorkroomRestaurant,
      CybersixgillSolution,
      CybersixgillResults,
      CybersixgillKeyImage,
      AiAssistantBanner,
    },
    elements: {
      img({ src, alt }) {
        // f_auto fetches WebP, if browser allows to do it. File name can remain with .jpg extension, but WebP mime type will be loaded anyway.
        return (
          <img
            src={getCloudinaryUrl(src, 'f_auto,q_auto,w_1000,c_limit')}
            alt={alt || ''}
            loading={'lazy'}
          />
        );
      },
      iframe({ id, children }) {
        return <div>{children}</div>;
      },
      a({ href, children }) {
        return (
          <a
            href={href}
            target={href.indexOf('boldare') === -1 ? '_blank' : null}
            rel={
              nofollowException.some(r => href.indexOf(r) >= 0)
                ? null
                : 'nofollow'
            }
          >
            {children}
          </a>
        );
      },
    },
  });

  return compile(markdown, null, context).tree;
};

export const estimateReadTime = text => {
  const wordsPerMinute = 200;

  const base = text.split(' ').length / wordsPerMinute;
  const minutes = Math.floor(base);
  const seconds = (base % 1) * 60;

  return seconds > 30 ? minutes + 1 : minutes;
};

export const getQueryParam = param => {
  const encodedParam = encodeURIComponent(param).replace(/[\.\+\*]/g, '\\$&');
  const regexp = new RegExp(
    `^(?:.*[&\\?]${encodedParam}(?:\\=([^&]*))?)?.*$`,
    'i',
  );
  const foundParam = window.location.search.replace(regexp, '$1');

  return decodeURIComponent(foundParam);
};

export const getUrlSearchParams = location => {
  if (typeof window !== 'undefined') {
    return new window.URLSearchParams(location.search);
  }

  return null;
};

export const findArticleByTitle = (allArticles, value) => {
  return allArticles.edges.find(
    edge => edge.node.frontmatter.title.trim() === value.trim(),
  );
};
