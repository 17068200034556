import React, { Component } from 'react';
import Slider from 'react-slick';
import { getCloudinaryUrl } from 'config/helpers';
import { Section, YouTubePlayer } from 'components';
import { Col } from 'react-flexbox-grid';
import { data } from './logic';

import './EventsHistory.scss';

class EventsHistory extends Component {
  constructor() {
    super();

    this.state = {
      sliderSettings: {
        className: 'event-history__slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: false,
        dots: false,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: true,
        slidesToShow: 3.75,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  }

  renderItems = () =>
    data.map(item =>
      item.type === 'img' ? (
        <div className={'event-history__photo'} key={`news-${item}`}>
          <img
            src={getCloudinaryUrl(item.link, 'f_auto,q_auto,w_600')}
            alt={'Boldare events'}
          />
        </div>
      ) : (
        <div className={'event-history__photo'} key={`news-${item}`}>
          <YouTubePlayer
            src={item.yt}
            light
            playing
            controls
            title={`Boldare events video`}
          />
        </div>
      ),
    );

  render() {
    const { sliderSettings } = this.state;

    return (
      <Section
        additionalClass={'event-fpmf-amsterdam__history-header'}
        color={'mine-shaft'}
        spacedTop={false}
      >
        <Col className={'event-fpmf-amsterdam__max-width'}>
          <div className={'event-history__list'}>
            <Slider {...sliderSettings}>{this.renderItems()}</Slider>
          </div>
        </Col>
      </Section>
    );
  }
}

export default EventsHistory;
