import React, { useRef } from 'react';
import {
  Section,
  Heading,
  Paragraph,
  OldCaseStudyTile,
  Image,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';
import './Intro.scss';

const Intro = () => {
  const image = getCloudinaryUrl(
    'assets/showbiz/showbiz-devices.png',
    'f_auto,q_auto',
  );
  const devices = useRef();
  useScrollAnimation([
    {
      ref: devices,
      transform: { translateY: '20%' },
    },
  ]);

  return (
    <>
      <Section additionalClass={'showbiz__intro-container'} spaceless>
        <OldCaseStudyTile
          additionalClass={'showbiz'}
          image={getCloudinaryUrl(
            'assets/showbiz/showbiz-logo-new.png',
            'f_auto,q_auto',
          )}
          cover={image}
          subtitle={'UI & UX Rebuilding'}
          description={
            'Product canvas workshop interface design, Architecture, Wireframes, Graphic Design, Implementation, PHP5, Symfony 2.6, MySQL, MariaDB, Java Script'
          }
          link={'https://www.showbizinc.it/en/'}
        />
        <div className={'showbiz__content'}>
          <Heading type={2} size={'big'}>
            ShowbizModels - fancy app for world-wide modeling agency
          </Heading>
          <Paragraph
            additionalClass={'text--light showbiz__intro-paragraph'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We created technically stable application that can be scaled. The
            application is fast and enables quick actions. With technical, UX
            and design changes, Client noticed an increase in the number of
            registered users. It is a huge advantage and brings many business
            values.
          </Paragraph>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            What
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            UX &amp; UI app redesign
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            What we’ve done is front-end and backup application. The goal was
            to keep it simple and user-friendly. We wrote a new application
            from scratch. Basing on years of experience our Partner knew
            exactly which functions are useful and used most often. The result
            of programming work is a Symfony application in the admin panel
            using the Bootstrap, so the admin’s actions are comfortable for all
            types of devices.
          </Paragraph>
        </div>
      </Section>
      <Section additionalClass={'showbiz__intro-wrapper'}>
        <img
          className={'image showbiz__intro-image'}
          src={image}
          innerRef={devices}
          alt={'Showbiz on different devices'}
          loading={'lazy'}
        />
      </Section>
    </>
  );
};

export default Intro;
