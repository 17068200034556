import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { SectionHeader, Section } from 'components';
import './DevTeam.scss';

const DevTeam = () => (
  <Section
    additionalClass={'sdo__devteam'}
    id={'sdo-devteam'}
    color={'pigeon-post'}
  >
    <Row className={'no-gap'}>
      <Col sm={12} md={6} className={'sdo__devteam-image'}>
        <img
          src={getCloudinaryUrl(
            'services/software-development-outsourcing/dev-team.jpg',
            'f_auto,q_auto,c_fill',
          )}
          className={'image sdo__devteam-img'}
          alt={'Our dedicated software development team'}
          loading={'lazy'}
        />
      </Col>
      <Col sm={12} md={6}>
        <SectionHeader
          size={'big'}
          lineColor={'red'}
          headingType={2}
          color={'mine-shaft'}
          description={`We have built our development teams with the care to achieve ultimate goal - the best development process and top-notch software quality for our customers. This is why our dedicated teams consist of experienced programmers and IT specialists who have been working together in the past resolving business problems of our partners by crafting excellent software.<br /><br />We strongly value your time and business needs that is why our teams work following the agile software development principles and the lean startup approach to deliver an increment on the fast pace. Thanks to this approach our teams can provide a first working version of your product within a few weeks.<br /><br />It wouldn't be possible without a strong focus on gaining and giving feedback which helps our teams to iterate often. Unlike other custom software development companies, our experienced scrum teams will not only code for you and build your product but also will advise you on every step of the full development cycle and beyond.`}
          descriptionColor={'mine-shaft'}
        >
          Our dedicated software development team
        </SectionHeader>
      </Col>
    </Row>
  </Section>
);

export default DevTeam;
