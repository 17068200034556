import React, { Component } from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import CountUp from 'react-countup';
import inView from 'in-view';
import './Models.scss';

class Models extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.showbiz__models').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  withCounter = () =>
    this.state.enableCounterAnimation && (
      <CountUp end={12000} delay={0.3} separator={' '} />
    );

  render() {
    return (
      <Section additionalClass={'showbiz__models'} spaceless>
        <div className={'showbiz__models-content'}>
          <Heading type={2} size={'big-spreaded'} color={'white'}>
            More than <span>{this.withCounter()}</span> models
          </Heading>
          <div className={'showbiz__models-container'}>
            <Heading type={3} afterline size={'smallest'} color={'white'}>
              From the client perspective
            </Heading>
            <Heading type={3} size={'medium-plus'} color={'white'}>
              App allows to search for models who meet the exact requirements
              of the end customer
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'white'}
            >
              Huge search allows to find the models/hostess which you’re
              looking for. For example: finding a hostess above 170cm height,
              available in Milan, speaking both Italian and English is not a
              problem anymore
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/showbiz/admin-mac.png',
              'f_auto,q_auto',
            )}
            alt={'Search for models'}
          />
        </div>
      </Section>
    );
  }
}

export default Models;
