import React, { useState, useEffect } from 'react';
import './OurAwards.scss';
import { Awards, Section, Heading } from 'components';

const OurAwards = () => {
  const [isAwwwards, setIsAwwwards] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('awwwards')
    ) {
      setIsAwwwards(true);
    }
  }, []);

  return (
    <Section additionalClass={'awwwards-lp-awards'} id={'awwwards__awards'}>
      <Heading
        additionalClass={'awwwards-lp__heading anim-fade-in-left'}
        size={'large'}
        lineColor={'yellow'}
        type={2}
      >
        {isAwwwards
          ? 'Which helped us land design awards.'
          : 'Our designs were recognized by:'}
      </Heading>
      <Awards />
    </Section>
  );
};

export default OurAwards;
