import React from 'react';
import './Subscribe.scss';
import { Paragraph, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import withSubscribe from './HOC';

const Subscribe = ({ subscribed, isLoading, refForm, handleSubmit }) => {
  return (
    <section className={'awwwards-lp-sub w-container'}>
      <img
        className={'image awwwards-lp-sub__img'}
        src={getCloudinaryUrl('img/mateusz-koscielak.jpg', 'q_100,h_100')}
        alt={'Mateusz Kościelak'}
        loading={'lazy'}
      />
      <Paragraph additionalClass={'awwwards-lp-sub__paragraph'}>
        If you need help with your next amazing product, let us know.
        <span className={'text--underline'}>
          {' '}
          We will get back to you within{' '}
          <span className={'text--bold'}>24 hours</span> or less.
        </span>
      </Paragraph>
      {subscribed ? (
        <Paragraph additionalClass={'awwwards-lp-sub__paragraph text--bold'}>
          Thank you for contacting us. Our consultant will back to you within
          24 hours.
        </Paragraph>
      ) : (
        <form
          className={'awwwards-lp-sub__form'}
          onSubmit={handleSubmit}
          ref={refForm}
        >
          <input
            className={'awwwards-lp-sub__input'}
            type={'email'}
            name={'name'}
            placeholder={'E-mail address'}
            required
          />
          <Button
            id={'awwwards-newsletter-btn'}
            type={'submit'}
            color={'white'}
            ariaName={'Subscribe button'}
            additionalClass={classNames('awwwards-lp-sub__button', {
              loading: isLoading,
            })}
            disabled={isLoading}
            loading
          >
            Send
          </Button>
        </form>
      )}
    </section>
  );
};

export default withSubscribe(Subscribe);
