import React from 'react';
import { scroller } from 'react-scroll';
import { Button, Section, Heading, SectionHeader } from 'components';
import './SumUp.scss';

const SumUp = () => (
  <Section color={'mine-shaft'} additionalClass={'career__sumup-section'}>
    <div>
      <div className={'career__sumup-circle'} />
      <Heading
        type={3}
        size={'medium'}
        color={'mine-shaft'}
        additionalClass={'headline--top headline--top-dark'}
      >
        Do we have all we need?
      </Heading>
      <SectionHeader
        additionalClass={'headline--centered'}
        color={'mine-shaft'}
        size={'medium'}
        headingType={2}
        noLine
      >
        The only element
        <br /> we lack is...{' '}
        <span className={'headline--marking headline--marking-white'}>
          YOU!
        </span>
      </SectionHeader>
      <Button
        additionalClass={'ga-career--go-to-offers'}
        onClick={() => {
          scroller.scrollTo('offers-section', {
            spy: false,
            smooth: true,
            duration: 700,
            offset: -100,
          });
        }}
        color={'white'}
        ariaName={'Job offers button'}
      >
        Join us
      </Button>
    </div>
  </Section>
);

export default SumUp;
