import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  Button,
  AbsoluteCTA,
  OrderedList,
} from 'components';

class Solution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
    };
  }

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <div className={'development-teams__solution'}>
        <Section color={'gray-light'}>
          <Row className={'no-gap anim-fade-in-bottom'}>
            <Col sm={12} md={6}>
              <SectionHeader
                size={'big'}
                color={'black'}
                lineColor={'red'}
                headingType={2}
              >
                Solution
              </SectionHeader>
            </Col>
            <Col sm={12} md={6}>
              <div className={'digital-transformation__solution-description'}>
                <Heading color={'black'} size={'medium-plus'} type={3}>
                  Our team will guide you on the digital transformation
                  journey, design your strategy, execute and along the way,
                  pass on to you all the necessary know-how.
                </Heading>
                {isFullDescriptionShown && (
                  <>
                    <Paragraph
                      additionalClass={
                        'development-teams__additional-description text--light'
                      }
                      color={'black'}
                      size={'medium'}
                    >
                      Our Digital Transformation Services cover the entire
                      transition process.
                    </Paragraph>
                    <br />
                    <OrderedList
                      additionalClass={'text--black text--light'}
                      items={[
                        '<span class="text--bold">Consulting:</span> Boldare’s cross-functional teams have the expertise to help you strategize your transformation, laying out an optimal plan for reaching your business goals.',
                        '<span class="text--bold">Execution:</span> With highly skilled software developers, designers and business analysts on board, Boldare will also help you develop new products or adjust your current one to the needs of the market.',
                        '<span class="text--bold">Knowledge Transfer:</span> Finally they will pass on to you all their knowledge and help you set up processes in-house so you can grow sustainably on your own.',
                      ]}
                    />
                  </>
                )}
                <div
                  className={'development-teams__learn-more-button'}
                  onClick={this.toggleHiddenDescription}
                >
                  <Button
                    order={'secondary'}
                    arrowColor={'red'}
                    ariaName={'Description button'}
                  >
                    {isFullDescriptionShown ? 'Show less' : 'Learn more'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Section>
        <AbsoluteCTA id={'page-digital-transformation-solution'} />
      </div>
    );
  }
}

export default Solution;
