import React from 'react';
import {
  Section,
  Paragraph,
  SectionHeader,
  BackgroundContent,
  SingleRelatedArticle,
} from 'components';
import './Types.scss';

const Types = ({ allArticles, relatedWorkWith }) => (
  <div className={'digital-transformation__types'}>
    <Section color={'mine-shaft'}>
      <SectionHeader
        size={'big'}
        color={'white'}
        lineColor={'red'}
        headingType={2}
      >
        Types of digital transformation services we offer
      </SectionHeader>
    </Section>
    <Section
      fullwidth
      spaceless
      additionalClass={'digital-transformation__types-content'}
    >
      <div className={'w-container'}>
        <div className={'digital-transformation__types-items'}>
          <div className={'digital-transformation__types-row'}>
            <div className={'digital-transformation__types-item'}>
              <BackgroundContent
                height={220}
                content={'1'}
                color={'violet'}
                opacity={0.1}
              />
              <SectionHeader size={'small'} headingType={3}>
                Products
                <br />
                and Services
              </SectionHeader>
              <Paragraph additionalClass={'text--light'}>
                The digital world offers boundless possibilities to expand into
                new geographical markets and and tackle new market segments.
                Moreover, offering web products alongside your physical
                services can help you enhance the customer experience and
                improve retention and advocacy.
              </Paragraph>
              <br />
              <Paragraph additionalClass={'text--light'}>
                For many years, our clients have turned to us to help them
                explore the digital economy. As part of our Digital
                Transformation Services, we help you translate non-digital
                products and services into seamless digital experiences. Our
                skilled development teams will guide you through the entire
                process of ideating, creating an MVP, testing, finding a
                product-market fit and scaling.
              </Paragraph>
            </div>
            <SingleRelatedArticle
              item={{
                title:
                  'Designing a simple search experience for a complex product with a luxurious interface',
                hover: {
                  icon: 'booking',
                  background: 'rodeo-dust',
                  headline: 'Non-digital to digital case study',
                },
              }}
              allArticles={allArticles}
              related={relatedWorkWith}
              additionalClass={'hide-mobile hide-tablet'}
            />
          </div>
          <div className={'digital-transformation__types-row'}>
            <div className={'digital-transformation__types-item'}>
              <BackgroundContent
                height={220}
                content={'2'}
                color={'violet'}
                opacity={0.1}
              />
              <SectionHeader size={'small'} headingType={3}>
                Processes
                <br />
                &amp; Organization
              </SectionHeader>
              <Paragraph additionalClass={'text--light'}>
                Building an in-house team and process to grow your product can
                be a challenging experience. Finding skilled engineers and
                designers, building a team, introducing standards and efficient
                methods requires know-how and years of experience. That is why
                as part of our Digital Transformation Services we help our
                clients not only build software products but also transform
                their organizations.
              </Paragraph>
              <br />
              <Paragraph additionalClass={'text--light'}>
                We can show you how to grow your team, build an organizational
                culture that supports innovation, introduce coding standards
                (e.g. CD, CI, S.O.L.I.D.), show you tools (e.g. event storming,
                impact mapping) and processes (agile and scrum, lean startup)
                to help you grow sustainably in the future.
              </Paragraph>
            </div>
            <SingleRelatedArticle
              item={{
                title:
                  'How to scale a monolithic MVP application without losing business?',
                hover: {
                  icon: 'network',
                  background: 'pigeon-post',
                  headline:
                    'Accelerated growth through change of process and organization case study',
                },
              }}
              allArticles={allArticles}
              related={relatedWorkWith}
              additionalClass={'hide-mobile hide-tablet'}
            />
          </div>
        </div>
      </div>
    </Section>
  </div>
);

export default Types;
