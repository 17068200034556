import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './CodeBlock.scss';

const CodeBlock = ({ children, color, fontColor }) => (
  <pre
    className={classNames({
      [`code-block--${color}`]: color,
      [`code-block--text-${fontColor}`]: fontColor,
    })}
  >
    <code>{children}</code>
  </pre>
);

CodeBlock.defaultProps = {
  color: '',
  fontColor: '',
};

CodeBlock.propTypes = {
  color: PropTypes.string,
  fontColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default CodeBlock;
