import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { Paragraph } from 'components';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { classNames } from 'utils';
import './LocationTile.scss';
import { useWindowWidth } from 'hooks';

const LocationTile = ({ isHiddenOnMobile, data }) => {
  const { cloudinaryDir, city, country, address, description, alt } = data;
  const isTabletBig = useWindowWidth() >= 992;

  return (
    <div
      className={classNames('location-tile', {
        'hidden-on-mobile': isHiddenOnMobile,
      })}
    >
      {cloudinaryDir && (
        <img
          className={'location-tile__image'}
          alt={alt}
          src={getCloudinaryUrl(
            [cloudinaryDir],
            isTabletBig
              ? 'f_auto,q_auto,w_416,h_345,c_fill'
              : 'f_auto,q_auto,w_992,h_600,c_fill',
          )}
        />
      )}
      <Paragraph additionalClass={'location-tile__city'} size={'medium-plus'}>
        <span className={'text--bold'}>{city}</span> | {country}
      </Paragraph>
      {address && (
        <Paragraph
          additionalClass={'location-tile__address'}
          size={'medium-constant'}
        >
          {address}
        </Paragraph>
      )}
      <Paragraph
        additionalClass={'location-tile__description'}
        size={'default'}
      >
        {description}
      </Paragraph>
      <button
        className={classNames(
          `ga-apply-location__${city}`,
          'button',
          'button--primary',
          'button--white',
          'location-tile__button',
        )}
        onClick={() => scrollTo('#job-offers')}
      >
        Apply
      </button>
    </div>
  );
};

LocationTile.defaultProps = {
  isHiddenOnMobile: false,
};

LocationTile.propTypes = {
  isHiddenOnMobile: PropTypes.bool,
  data: PropTypes.shape({
    cloudinaryDir: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    address: PropTypes.string,
    description: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

export default LocationTile;
