import React from 'react';
import PropTypes from 'prop-types';

const QuestionCount = props => {
  const { counter, total } = props;

  return (
    <div className={'pmf-survey__question-counter'}>
      <span>{counter + 1}</span>/<span>{total}</span>
    </div>
  );
};

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

QuestionCount.defaultProps = {};

export default QuestionCount;
