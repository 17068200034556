import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { getCloudinaryUrl, enableAnimations } from 'config/helpers';
import config from '../../../meta/config';
import { KeywordContent } from './subcomponents';

export class KeywordPageTemplate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { keywordPageData, allArticles } = this.props;
    const { title, hero, settings } = keywordPageData.frontmatter;
    const slug = settings.slug || keywordPageData.fields.slug;
    const canonical = `${config.siteUrl}/services/${slug}/`;

    return (
      <div className={'single-page single-page--hero single-keyword-page'}>
        <SEO
          title={settings.metaTitle || title}
          description={settings.metaDescription}
          cover={getCloudinaryUrl(hero.image, 'f_auto,q_auto,w_400')}
          slug={slug}
          canonical={canonical}
          cloudinary
        />
        <KeywordContent
          data={keywordPageData.frontmatter}
          allArticles={allArticles}
          slug={slug}
        />
      </div>
    );
  }
}

KeywordPageTemplate.propTypes = {
  keywordPageData: PropTypes.shape().isRequired,
};

export default KeywordPageTemplate;
