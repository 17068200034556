import React, { Component } from 'react';
import Slider from 'react-slick';
import { Heading, SliderArrow } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { technologies } from './logic';
import './Technologies.scss';

class Technologies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'services-slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: false,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <SliderArrow id={'news__slider-next'} />,
        prevArrow: <SliderArrow id={'news__slider-prev'} left />,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 992 });
  };

  renderItem = () =>
    technologies.map(item => (
      <div className={'services__tech-item'}>
        <img
          src={getCloudinaryUrl(item.img, `f_auto,q_auto,w_${item.width}`)}
          className={'image'}
          alt={item.name}
          loading={'lazy'}
        />
        <span>{item.name}</span>
      </div>
    ));

  render() {
    const { isMobile, sliderSettings } = this.state;

    return (
      <div id={'section-technologies'} className={'services__tech'}>
        <div className={'w-container'}>
          <Heading type={2} additionalClass={'services__tech-title'}>
            A broad technology selection,
            <br /> one unified standard service
          </Heading>
          {isMobile ? (
            <Slider
              ref={slider => {
                this.slider = slider;
              }}
              {...sliderSettings}
            >
              {this.renderItem()}
            </Slider>
          ) : (
            <div className={'services__tech-items'}>{this.renderItem()}</div>
          )}
        </div>
      </div>
    );
  }
}

export default Technologies;
