/* eslint-disable react/no-array-index-key */
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import React, { useState, Fragment } from 'react';
import { navigate } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import StickyBox from 'react-sticky-box';
import PropTypes from 'prop-types';

import { unflatLocalSearchResults, sortLocalSearchResultsDesc } from 'utils';
import {
  Button,
  ClientSideComponent,
  DateComponent,
  Section,
  Paragraph,
  Heading,
  Link,
  Share,
  DownloadPost,
  SearchBar,
  ProgressBar,
} from 'components';
import { getCloudinaryUrl, toHtml } from 'config/helpers';
import MediaTypes from 'config/constants/mediaTypes';
import PathDividerSrc from 'assets/images/blog/ico-path-divider.svg';
import { Author, FullwidthImage } from '..';
import { CalculatorBox } from '../CalculatorBox';
import { CalculatorBanner } from '../CalculatorBanner';
import { coverComponents, sectionEntryComponents } from './logic';
import { NewsletterBox } from '../NewsletterBox';

import '../../SingleArticle.scss';

const ArticleContent = ({
  data,
  windowLocation,
  author,
  authorAdditional,
  allArticles,
  allPosts: nodes,
  allBlogCategories,
  localSearchPages,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isTop, setTop] = useState(true);
  const [progress, setProgress] = useState(0);

  const mediaTypes = [
    MediaTypes.FACEBOOK,
    MediaTypes.LINKEDIN,
    MediaTypes.TWITTER,
  ];
  const { cover, coverComponent, lead, title, content, settings } = data;
  const CoverComponent = coverComponents[coverComponent];

  const localSearchPagesIndex = localSearchPages && localSearchPages.index;
  const localSearchPagesStore = localSearchPages && localSearchPages.store;

  const results =
    searchTerm &&
    useFlexSearch(searchTerm, localSearchPagesIndex, localSearchPagesStore);
  const unflatternResults = results && unflatLocalSearchResults(results);
  const sortedResults =
    unflatternResults && sortLocalSearchResultsDesc(unflatternResults);
  const allPosts = sortedResults || nodes;

  const showCalculatorBox = settings.slugType === 'work';

  // Temporary solution for hiding newsletter form. Circle Marketing decision.
  const showNewsletterBox = false;

  const setNewSearchTerm = newValue => {
    setSearchTerm(newValue);
  };

  const navigateToAllSearchResults = () =>
    navigate(`/blog?search=${searchTerm}#blog-post-list`);

  const renderFeaturedPosts = count => {
    return allPosts.edges.slice(0, count);
  };

  const filterRenderedPosts = (posts, numberOfPosts) =>
    posts &&
    posts
      .filter(
        post =>
          post.node.frontmatter.templateKey !== 'youtube-video' &&
          post.node.frontmatter.templateKey !== 'case-study-page',
      )
      .slice(-numberOfPosts)
      .sort(
        (postA, postB) =>
          new Date(postB.node.frontmatter.settings.date) -
          new Date(postA.node.frontmatter.settings.date),
      );

  const renderPosts = (posts, featuredPosts, numberOfPosts) => {
    const filteredPosts = filterRenderedPosts(posts, numberOfPosts);
    const filteredFeaturedPosts = filterRenderedPosts(
      featuredPosts,
      numberOfPosts,
    );

    return (
      <>
        {filteredPosts &&
          filteredPosts.map(post => {
            const {
              title: postTitle,
              settings: { slug },
            } = post.node.frontmatter;

            return (
              <a
                href={`/blog/${slug}`}
                target={'_blank'}
                rel={'noopener noreferrer nofollow'}
              >
                <Paragraph
                  additionalClass={'article-aside__search-post-title'}
                  key={`blog-post-${postTitle}`}
                >
                  {postTitle}
                </Paragraph>
              </a>
            );
          })}
        {filteredFeaturedPosts &&
          filteredFeaturedPosts.map(post => {
            const {
              title: postTitle,
              settings: { slug },
            } = post.node.frontmatter;

            return (
              <a
                href={`/blog/${slug}`}
                target={'_blank'}
                rel={'noopener noreferrer nofollow'}
              >
                <Paragraph
                  additionalClass={'article-aside__search-post-title'}
                  key={`blog-post-${postTitle}`}
                >
                  {postTitle}
                </Paragraph>
              </a>
            );
          })}
      </>
    );
  };

  const renderSearchResults = (searchResult, numberOfPosts) => {
    if (searchResult.length) {
      return renderPosts(searchResult, null, numberOfPosts);
    }

    const featuredPosts = renderFeaturedPosts(numberOfPosts);
    return renderPosts(searchResult, featuredPosts, null);
  };

  const renderArticleSections = (
    articleContent,
    articleMediaTypes,
    location,
    articleTitle,
  ) => {
    return content.map((item, index) => {
      let sectionClass = '';

      if (item.theme) {
        sectionClass = `article-main-container--${item.theme}`;
      }

      if (item.textOverlaps) {
        sectionClass = `article-main-container--textOverlaps`;
      }

      const EntryComponent = sectionEntryComponents[item.entryComponent];

      if (index === 0 && item.fullwidthImage && item.fullwidthImage.image) {
        return (
          <FullwidthImage
            image={getCloudinaryUrl(
              item.fullwidthImage.image,
              'f_auto,q_auto,w_1920',
            )}
            alt={item.fullwidthImage.alt}
            comment={item.fullwidthImage.comment}
            key={`image-${index}`}
          />
        );
      }

      if (index === 0) return null;

      if (index === articleContent.length - 1) {
        return (
          <Fragment key={`section-${index}`}>
            {EntryComponent && <EntryComponent />}
            <Section additionalClass={sectionClass} spaceless spacedBottom>
              <StickyBox offsetTop={120} style={{ zIndex: 90 }}>
                <div className={'article-aside__container'}>
                  <div className={'article-aside__social-media-container'}>
                    <p className={'article-aside__social-media-label'}>
                      Share this article:
                    </p>
                    <Share
                      mediaTypes={articleMediaTypes}
                      shareUrl={location}
                      title={articleTitle}
                    />
                  </div>
                  {/* <div className={'article-aside__contact-container'}>
                    <p className={'article-aside__contact-subheading'}>
                      Do you want to talk about your business?
                    </p>
                    <Link to={'/contact/#form'}>Contact Us</Link>
                  </div> */}
                </div>
              </StickyBox>
              <div className={'article-content__container'}>
                {toHtml(item.body, { allArticles })}
              </div>
              <div className={'article-sidebar'} />
            </Section>
          </Fragment>
        );
      }

      return (
        <Fragment key={`section-${index}`}>
          {EntryComponent && <EntryComponent />}
          <Section additionalClass={sectionClass}>
            <StickyBox offsetTop={100} style={{ zIndex: 90 }}>
              <div className={'article-aside__container'}>
                <div className={'article-aside__social-media-container'}>
                  <p className={'article-aside__social-media-label'}>
                    Share this article:
                  </p>
                  <Share
                    mediaTypes={articleMediaTypes}
                    shareUrl={location}
                    title={articleTitle}
                  />
                </div>
                {/* <div className={'article-aside__contact-container'}>
                  <p className={'article-aside__contact-subheading'}>
                    Do you want to talk about your business?
                  </p>
                  <Link to={'/contact/#form'}>Contact Us</Link>
                </div> */}
              </div>
            </StickyBox>
            <div className={'article-content__container'}>
              {toHtml(item.body, { allArticles })}
            </div>
            <div className={'article-sidebar'} />
          </Section>
          {item.fullwidthImage.image && (
            <FullwidthImage
              image={getCloudinaryUrl(
                item.fullwidthImage.image,
                'f_auto,q_auto,w_1920',
              )}
              comment={item.fullwidthImage.comment}
            />
          )}
        </Fragment>
      );
    });
  };

  const slugCategoriesByTitle = [];
  if (allBlogCategories) {
    allBlogCategories.edges.forEach(category => {
      const cF = category.node.frontmatter;
      const categoryTitle = cF.title;
      const categorySlug = category.node.fields.slug;
      slugCategoriesByTitle[categoryTitle] = categorySlug;
    });
  }

  const measuredRef = React.useRef(null);
  const hightRef = React.useRef();

  const handleScroll = () => {
    const scrollOffset = 100;
    setTop(window.scrollY < scrollOffset);

    const percent = (window.scrollY / hightRef.current) * 100;
    setProgress(percent);
  };

  React.useLayoutEffect(() => {
    if (measuredRef.current) {
      hightRef.current = measuredRef.current.getBoundingClientRect().height;
    }
  });

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hightRef]);

  return (
    <>
      <article className={'single-article__container'} ref={measuredRef}>
        {!isTop && (
          <div className={'single-article__progress-bar'}>
            <ProgressBar progress={progress} hideOnFull />
          </div>
        )}
        <Section additionalClass={'article-first__container'}>
          <div className={'article-sidebar'}>
            <div className={'article-sidebar__authors'}>
              <Author
                avatar={getCloudinaryUrl(author.photo, 'f_auto,q_auto,w_100')}
                name={author.title}
                job={author.job}
                link={author.slug}
                isExternalAuthor={author.isExternalAuthor}
              />
              {authorAdditional && (
                <Author
                  avatar={getCloudinaryUrl(
                    authorAdditional.photo,
                    'f_auto,q_auto,w_100',
                  )}
                  name={authorAdditional.title}
                  job={authorAdditional.job}
                  link={authorAdditional.slug}
                  additionalClass={'author--second'}
                  isExternalAuthor={author.isExternalAuthor}
                />
              )}
            </div>
            <DateComponent date={settings.date} />
            {settings.downloadPardotLink && (
              <DownloadPost settings={settings} />
            )}
          </div>
          <div className={'article-first__content'}>
            <div className={'article-title'}>
              <div className={'article-breadcrumbs'}>
                <Link to={'/'}>Home</Link>{' '}
                <img
                  className={'image'}
                  src={PathDividerSrc}
                  alt={''}
                  loading={'lazy'}
                />
                <Link to={'/blog/'}>Blog</Link>{' '}
                <img
                  className={'image'}
                  src={PathDividerSrc}
                  alt={''}
                  loading={'lazy'}
                />
                {settings.blogCategories && (
                  <Link to={slugCategoriesByTitle[settings.blogCategories]}>
                    {settings.blogCategories}
                  </Link>
                )}{' '}
                <img
                  className={'image'}
                  src={PathDividerSrc}
                  alt={''}
                  loading={'lazy'}
                />
                <span>{title}</span>
              </div>
              <Heading size={'large'} type={1}>
                {title}
              </Heading>
            </div>
            <div className={'article-authors'}>
              <div className={'article-authors__container'}>
                <Author
                  avatar={getCloudinaryUrl(
                    author.photo,
                    'f_auto,q_auto,w_100',
                  )}
                  name={author.title}
                  job={author.job}
                  link={author.slug}
                  isExternalAuthor={author.isExternalAuthor}
                />
                {authorAdditional && (
                  <Author
                    avatar={getCloudinaryUrl(
                      authorAdditional.photo,
                      'f_auto,q_auto,w_100',
                    )}
                    name={authorAdditional.title}
                    job={authorAdditional.job}
                    link={authorAdditional.slug}
                    additionalClass={'author--second'}
                    isExternalAuthor={author.isExternalAuthor}
                  />
                )}
              </div>
              <DateComponent date={settings.date} />
              {settings.downloadPardotLink && (
                <DownloadPost settings={settings} />
              )}
            </div>
            {lead && (
              <div className={'article-subtitle'}>
                {toHtml(lead, { allArticles })}
              </div>
            )}
          </div>
          <div className={'article-sidebar'} />
        </Section>

        <Section spaceless>
          {CoverComponent ? (
            <CoverComponent />
          ) : (
            <FullwidthImage
              image={getCloudinaryUrl(cover, 'f_auto,q_auto')}
              alt={title}
            />
          )}
        </Section>

        <Section additionalClass={'article-main-container'}>
          <StickyBox
            offsetTop={100}
            style={{ zIndex: 90 }}
            className={'article-aside__sticky-box'}
          >
            {showCalculatorBox && (
              <ClientSideComponent>
                <Experiment name={'cs-calculator-test-ab'}>
                  <Variant name={'cs-calculator-a'}>
                    <CalculatorBox
                      variant={'cs-calculator-a'}
                      theme={'blue'}
                    />
                  </Variant>
                  <Variant name={'cs-calculator-b'}>
                    <CalculatorBox
                      variant={'cs-calculator-b'}
                      theme={'white'}
                    />
                  </Variant>
                </Experiment>
              </ClientSideComponent>
            )}
            <div className={'article-aside__container'}>
              <div className={'article-aside__social-media-container'}>
                <p className={'article-aside__social-media-label'}>
                  Share this article:
                </p>
                <Share
                  mediaTypes={mediaTypes}
                  shareUrl={windowLocation}
                  title={title}
                />
              </div>
              {/* <div className={'article-aside__contact-container'}>
                <p className={'article-aside__contact-subheading'}>
                  Do you want to talk about your business?
                </p>
                <Link to={'/contact/#form'}>Contact Us</Link>
              </div> */}
              {settings.downloadPardotLink && (
                <DownloadPost settings={settings} />
              )}
            </div>
            <div className={'article-aside__container'}>
              <SearchBar
                additionalClass={'article-aside__search'}
                searchTerm={searchTerm}
                setSearchTerm={setNewSearchTerm}
              />
            </div>
            {searchTerm && allPosts.length ? (
              <>
                <Paragraph additionalClass={'article-aside__search-results'}>
                  We’ve found <b>{allPosts.length} results</b> matching your
                  search!
                </Paragraph>
                {renderSearchResults(allPosts, 2)}
                <Button
                  additionalClass={
                    !searchTerm.length || !allPosts.length
                      ? 'article-aside__search__button--hidden'
                      : 'article-aside__search__button'
                  }
                  ariaName={'All search results'}
                  type={'submit'}
                  transparent
                  color={'black'}
                  onClick={navigateToAllSearchResults}
                >
                  See all search results
                </Button>
              </>
            ) : null}
            {searchTerm.length && !allPosts.length ? (
              <>
                <Paragraph additionalClass={'article-aside__search-results'}>
                  We couldn’t find any results for your search :( Please try
                  another search term!
                </Paragraph>
                <Paragraph
                  additionalClass={'article-aside__search-results-second'}
                >
                  You can also read our <b>recommended articles</b> below!
                </Paragraph>
                {renderSearchResults(nodes, 2)}
              </>
            ) : null}
            {!showCalculatorBox && showNewsletterBox && (
              <div className={'article-aside__container'}>
                <NewsletterBox />
              </div>
            )}
          </StickyBox>

          <div className={'article-content__container'}>
            <div className={'article-content__toc'}>
              <Heading size={'big'} additionalClass={'no-toc'} type={2}>
                Table of contents
              </Heading>
              <div className={'article-content__toc-wrapper'} />
            </div>
            {toHtml(content[0].body, { allArticles })}
          </div>

          <div className={'article-sidebar'} />
        </Section>

        {renderArticleSections(content, mediaTypes, windowLocation, title)}

        <Section additionalClass={'article-tags__container'} spaceless>
          <div className={'article-content__container'}>
            <div className={'article__summary'}>
              <Paragraph additionalClass={'article__share'}>
                Share this article:
              </Paragraph>
              <Share
                mediaTypes={mediaTypes}
                shareUrl={windowLocation}
                title={title}
              />
            </div>
          </div>
        </Section>
        <ClientSideComponent>
          <Experiment name={'cs-calculator-test-ab'}>
            <Variant name={'cs-calculator-a'}>
              <CalculatorBanner variant={'cs-calculator-a'} />
            </Variant>
            <Variant name={'cs-calculator-b'}>
              <CalculatorBanner withArrows variant={'cs-calculator-b'} />
            </Variant>
          </Experiment>
        </ClientSideComponent>
      </article>
    </>
  );
};

ArticleContent.propTypes = {
  allArticles: PropTypes.object,
  allPosts: PropTypes.array.isRequired,
  data: PropTypes.shape({
    cover: PropTypes.string,
    coverComponent: PropTypes.string,
    lead: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.array,
    settings: PropTypes.object,
  }).isRequired,
  windowLocation: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
  authorAdditional: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  allBlogCategories: PropTypes.object.isRequired,
  localSearchPages: PropTypes.shape({
    index: PropTypes.string,
    store: PropTypes.object,
  }).isRequired,
};

ArticleContent.defaultProps = {
  authorAdditional: null,
  allArticles: null,
};

export default ArticleContent;
