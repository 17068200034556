import React from 'react';
import './Instagram.scss';
import { Link, IconSVG, Button, BackgroundImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers/index.js';
import { data } from './logic.js';

const Instagram = () => (
  <div className={'instagram__container'}>
    {data.map((item, index) => (
      <Link to={item.link} id={`career-instagram-${index}`} key={item.image}>
        <BackgroundImage
          src={getCloudinaryUrl(item.image, 'c_scale,q_auto,h_400,w_400')}
          additionalClass={'instagram__img'}
        />
        <div className={'instagram--hover'}>
          <Button transparent color={'white'} ariaName={'Instagram button'}>
            <IconSVG name={'instagram'} size={'medium'} color={'white'} />
          </Button>
        </div>
      </Link>
    ))}
  </div>
);

export default Instagram;
