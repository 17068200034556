import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { LocationTile } from '../index';
import './LocationSlider.scss';

const LocationSlider = ({ items }) => (
  <div
    className={classNames('glide__track', 'location-slider')}
    data-glide-el={'track'}
  >
    <ul className={'glide__slides'}>
      {items.map((item, index) => (
        <li
          className={classNames('glide__slide', 'location-slider__slide')}
          id={`glide__slide-${index}`}
        >
          <LocationTile data={item} />
        </li>
      ))}
    </ul>
  </div>
);

LocationSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cloudinaryDir: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      address: PropTypes.string,
      description: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LocationSlider;
