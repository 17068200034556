import React from 'react';
import { Section, Paragraph, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Changes.scss';

const Changes = () => {
  return (
    <Section additionalClass={'pakt__changes-container'}>
      <div className={'pakt__content'}>
        <Heading type={2} size={'big-spreaded'}>
          We cleared&nbsp;the&nbsp;UI for&nbsp;better&nbsp;UX
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          As we said at the beginning - „less is more”. Users just don’t want
          to wade through billions of complicated menus to get to the place
          they want. User needs to find out about pricing? Two clicks and here
          you are!
        </Paragraph>
      </div>
      <div className={'pakt__changes-images'}>
        <div className={'pakt__content--half'}>
          <div className={'pakt__changes-text'}>
            <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
              Easy product filtering
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Just a few most important options, such as: category, purpose,
              season or color. What else do you really need?
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/pakt/transparent-2-left.png',
              'f_auto,q_auto',
            )}
            alt={'App preview - filtering items'}
            additionalClass={'pakt__changes-image anim-fade-in-bottom'}
          />
          <img
            src={getCloudinaryUrl(
              'assets/pakt/transparent-2-left-circle.png',
              'f_auto,q_auto',
            )}
            alt={'Zoom of color filter'}
            className={'image pakt__changes-zoom anim-scale-out-in-center'}
            loading={'lazy'}
          />
        </div>
        <div className={'pakt__content--half'}>
          <div className={'pakt__changes-text'}>
            <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
              Clear and simple UI
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Browsing through your collection can’t get any simpler than that.
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/pakt/transparent-2-right.png',
              'f_auto,q_auto',
            )}
            alt={'App preview - browsing items'}
            additionalClass={'pakt__changes-image anim-fade-in-bottom'}
          />
          <img
            src={getCloudinaryUrl(
              'assets/pakt/transparent-2-right-circle.png',
              'f_auto,q_auto',
            )}
            alt={'Zoom of options'}
            className={'image pakt__changes-zoom anim-scale-out-in-center'}
            loading={'lazy'}
          />
        </div>
      </div>
    </Section>
  );
};

export default Changes;
