import React from 'react';
import PropTypes from 'prop-types';

import './BoxList.scss';

function BoxList({ children }) {
  return <ul className={'box-list'}>{children}</ul>;
}

BoxList.propTypes = {
  children: PropTypes.node.isRequired,
};

function BoxListItem({ boxText, children }) {
  return (
    <li>
      <div className={'box-list__item-box-text'}>{boxText}</div>
      {children}
    </li>
  );
}

BoxListItem.propTypes = {
  children: PropTypes.node.isRequired,
  boxText: PropTypes.string.isRequired,
};

BoxList.Item = BoxListItem;

export default BoxList;
