export default [
  {
    label: 'Understanding',
    paragraphs: [
      'Thanks to product discovery, product vision and product person workshops we learn about users’ needs and can understand your business idea better.',
    ],
  },
  {
    label: 'Information architecture & wireframes',
    paragraphs: [
      'We systemize and analyze gathered data to identify the issues we have to solve and focus on the core aspects of the product.',
    ],
  },
  {
    label: 'Moodboards',
    paragraphs: [
      'Two different visual propositions prepared separately by two product designers based on the same business and product assumptions. We give our clients a choice.',
    ],
  },
  {
    label: 'User interface design',
    paragraphs: [
      'Based on information architecture, wireframes, and moodboards, we design the UI of the product while maintaining accessibility standards.',
    ],
  },
  {
    label: 'Development',
    paragraphs: [
      'Product designers and software developers work together in one team to bring your design-powered digital product to life.',
    ],
  },
];
