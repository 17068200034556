export const data = [
  {
    category: 'Energy',
    name: 'Energy Industry',
    description:
      'Explore the impact of AI software development and consulting services on the energy sector:',
    icon: 'energy',
    points: [
      'Data Analytics: Leverage AI for real-time data analysis, offering actionable insights to optimize energy production and distribution.',
      'Predictive Maintenance: Utilize AI models to predict equipment failures and schedule maintenance, reducing downtime.',
      'Operational Efficiency: Streamline operations with automated processes, reducing operational costs.',
      'Energy Forecasting: Improve energy allocation with AI-powered demand forecasting.',
      'Product Development: Accelerate the creation of innovative energy solutions with GenAI’s rapid prototyping and simulation capabilities.',
      'Custom Solutions: Tailored AI applications to meet the unique needs and challenges of the energy sector.',
      'Smart Grids: Enhance grid management and distribution efficiency with AI algorithms.',
      'Renewable Energy: Optimize the use and integration of renewable energy sources through intelligent analytics and forecasting.',
    ],
  },
  {
    category: 'e-commerce',
    name: 'E-commerce Industry',
    description:
      'Discover how AI software development and consulting services benefit e-commerce companies:',
    icon: 'e-commerce',
    points: [
      "Personalized Shopping Experience: AI can analyze users' browsing patterns, purchase history, and preferences to recommend products, creating a tailored shopping experience for each customer.",
      'Chatbots and Virtual Assistants: These AI - driven tools can answer customer queries, assist in product selection, and provide instant support 24 / 7, enhancing customer service without significant manpower.',
      'Improved Search Results: Through AI algorithms, search functionalities within e - commerce platforms can be enhanced to provide more accurate and relevant results based on user intent.',
      'Price Optimization: AI can analyze market demand, competitor prices, and other external factors in real-time to dynamically adjust pricing, ensuring competitiveness while maximizing profits.',
      'Inventory Management: Predictive analysis can forecast demand, helping businesses manage their inventory more efficiently and reduce stockouts or overstock situations.',
      'Visual Recognition: Customers can search for products using images, and AI can identify and suggest similar products available in the store.',
      'Fraud Detection: AI can identify unusual patterns and behaviors, offering enhanced security measures and reducing instances of fraud.',
      'Sales Forecasting: With AI, e-commerce businesses can get more accurate sales predictions, helping them prepare in terms of stock, manpower, and logistics.',
    ],
  },
  {
    category: 'Travel',
    name: 'Travel Industry',
    description:
      'Explore how AI software development and consulting services are transforming the travel industry:',
    icon: 'travel',
    points: [
      'Personalized Customer Experiences: GenAI and LLMs enable hyper-personalization at scale. Deliver tailor-made travel recommendations, personalized itineraries, and bespoke services, enhancing customer loyalty and satisfaction.',
      'Data-Driven Decision Making: Harness the power of AI to analyze vast amounts of data, providing actionable insights that empower your business to make informed decisions, optimize operations, and enhance service quality.',
      'Automated Customer Support: Implement AI-powered virtual assistants and chatbots that offer instant, accurate responses to customer inquiries, ensuring superior service quality round the clock without escalating operational costs.',
      'Dynamic Pricing: Utilize AI to implement dynamic pricing strategies, analyzing real-time market data to adjust prices optimally, maximizing revenue, and enhancing competitiveness.',
      'Intelligent Recommendations: Leverage the analytical prowess of AI to offer intelligent recommendations, helping travelers to discover hidden gems, personalized travel experiences, and make informed choices.',
      'Sustainable Travel: Promote responsible and sustainable travel by utilizing AI to analyze and provide insights on eco-friendly options and practices, aiding travelers in making conscious choices.',
      'Enhanced Security: Implement AI-driven identification and verification processes, ensuring the safety and security of travelers’ data and transactions.',
      'Seamless Integration: Our AI solutions are designed to seamlessly integrate with your existing systems, ensuring minimal disruption and maximum enhancement in service delivery and operational efficiency.',
    ],
  },
  {
    category: 'Construction materials',
    name: 'Construction materials Industry',
    description:
      'Discover how AI software development and consulting services are revolutionizing the construction materials industry:',
    icon: 'construction',
    points: [
      'Supply Chain Optimization: AI can predict demand for specific materials based on construction trends, historical data, and other influencing factors, ensuring timely procurement and minimizing storage costs.',
      'Quality Control: Image recognition and machine learning can be used to inspect materials for defects or inconsistencies, ensuring only high-quality materials are used or shipped.',
      'Demand Forecasting: Predictive analytics can provide insights into future demand, helping companies to adjust production levels and manage inventory more effectively.',
      'Operational Efficiency: AI can optimize production processes, reducing waste, and improving the overall efficiency of material production.',
      'Logistics and Delivery: AI algorithms can plan optimal routes for material delivery, taking into account factors like traffic, weather, and construction site schedules, ensuring timely and cost-effective transport.',
      'Safety Enhancements: AI can predict potential safety risks in the manufacturing or transportation process, ensuring measures are taken to mitigate those risks.',
      'Cost Estimations: AI can provide more accurate cost estimations for producing certain materials based on various factors like raw material prices, labor costs, and energy consumption.',
      'Waste Reduction: By predicting the exact amount of materials required for specific projects and optimizing production processes, AI can significantly reduce waste.',
    ],
  },
  {
    category: 'Logistics and transportation',
    name: 'Logistics and transportation Industry',
    description:
      'Explore how AI software development and consulting services are transforming logistics and transportation:',
    icon: 'logistics',
    points: [
      'Optimized Routes: AI can analyze vast amounts of data, including traffic patterns, weather conditions, and roadwork, to determine the most efficient routes for transportation, reducing fuel costs and improving delivery times.',
      'Demand Forecasting: By analyzing historical data, AI can predict future demand for transportation and logistics services, allowing businesses to optimize resource allocation, reducing overheads and improving service delivery.',
      'Predictive Maintenance: AI can predict when a vehicle or machinery is likely to require maintenance, minimizing downtime and reducing unexpected equipment failures.',
      'Automated Warehousing: AI-driven robots can streamline warehouse operations, enhancing inventory management, and speeding up the packaging and dispatch processes.',
      'Real-time Tracking: AI-powered systems can offer real-time tracking of shipments, giving customers and businesses improved visibility and enabling proactive management of any delays or issues.',
      'Dynamic Pricing: AI can analyze market demand, competitor prices, and other external factors in real-time to adjust pricing dynamically, maximizing profitability.',
      'Enhanced Customer Service: AI chatbots and virtual assistants can provide instant responses to customer inquiries, book shipments, or address concerns, improving the customer experience.',
      'Safety Improvements: AI can assist in monitoring driver behavior, predicting potential road hazards, or autonomously operating vehicles, thereby reducing the risk of accidents and enhancing overall road safety.',
    ],
  },
];
