import React from 'react';
import Paragraph from 'components/Paragraph';
import PropTypes from 'prop-types';
import Section from 'components/Section';
import SectionHeader from 'components/Section/subcomponents/SectionHeader';

import './WorkshopInformation.scss';

const WorkshopInformation = ({
  tag,
  lineColor,
  title,
  description,
  additionalText,
}) => {
  return (
    <Section additionalClass={'workshop-information'}>
      <span className={'workshop-information__label'}>{tag}</span>
      <SectionHeader size={'big'} lineColor={lineColor} headingType={1}>
        {title}
      </SectionHeader>
      <Paragraph size={'medium'} color={'mine-shaft'} spaced>
        {description}
      </Paragraph>
      <Paragraph size={'medium'} color={'mine-shaft'} spaced>
        {additionalText}
      </Paragraph>
    </Section>
  );
};

WorkshopInformation.propTypes = {
  tag: PropTypes.string.isRequired,
  lineColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalText: PropTypes.string.isRequired,
};

export default WorkshopInformation;
