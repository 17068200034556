import React from 'react';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Technology.scss';
import Hand from 'assets/images/practitest/hand.svg';
import Pawn from 'assets/images/practitest/pawn.svg';

const Technology = () => {
  return (
    <Section additionalClass={'pt__technology-container'}>
      <Heading type={2} size={'medium-plus'}>
        You always have a choice
      </Heading>
      <Paragraph
        additionalClass={'text--light'}
        size={'medium-constant'}
        color={'mine-shaft'}
      >
        Design process, we always provide two moodboards for you to choose
        from, so you can pick what fits your brand best
      </Paragraph>
      <div className={'pt__technology-boxes'}>
        <div className={'pt__technology-box'}>
          <img
            className={'image pt__intro-image anim-fade-in-bottom'}
            src={getCloudinaryUrl(
              'assets/practitest/box-1.png',
              'f_auto,q_auto',
            )}
            alt={'Box 1'}
            loading={'lazy'}
          />
        </div>
        <div className={'pt__technology-box'}>
          <img
            className={'image pt__intro-image anim-fade-in-bottom'}
            src={getCloudinaryUrl(
              'assets/practitest/box-2.png',
              'f_auto,q_auto',
            )}
            alt={'Box 2'}
            loading={'lazy'}
          />
        </div>
      </div>
      <div
        className={'pt__technology-image anim-fade-in-right pt__hide-mobile'}
      >
        <img src={Hand} alt={`Hand`} loading={'lazy'} />
      </div>
      <div
        className={
          'pt__technology-image--pawn anim-fade-in-bottom pt__hide-mobile'
        }
      >
        <img src={Pawn} alt={`Pawn`} loading={'lazy'} />
      </div>
    </Section>
  );
};
export default Technology;
