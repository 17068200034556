import React from 'react';
import { Col, Row } from 'react-flexbox-grid';

import { Paragraph, Section, Heading, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

import './Speaker.scss';

const speakerCopy = [
  'Over 17 years of experience in business development and Agile product development',
  'Hands-on experience in creating and validating business models gained from German startup environment',
  'Fluent in applying the lean startup and product discovery approaches',
  'Always makes sure products are built to solve real user problems',
];

const Speaker = () => (
  <Section
    additionalClass={'event-fpmf_amsterdam__speaker-header'}
    id={'speaker-link'}
    color={'violet-main'}
  >
    <Row className={'event-fpmf-amsterdam__max-width'}>
      <Col
        sm={12}
        md={5}
        className={'event-fpmf_amsterdam__speaker-column-left'}
      >
        <SectionHeader
          headingType={2}
          additionalClass={'speaker__heading'}
          color={'mine-shaft'}
          noLine
        >
          Meet our <span className={'pixel-font-text'}>speaker</span>
          <img
            alt={''}
            className={'pixel-font'}
            src={getCloudinaryUrl('event/speaker.svg', 'v1669337183')}
          />
        </SectionHeader>
        <Paragraph
          color={'white'}
          additionalClass={'event-fpmf_amsterdam__speaker-paragraph'}
        >
          <ul className={'event-fpmf_amsterdam__speaker-list'}>
            {speakerCopy.map(item => (
              <li className={'event-fpmf_amsterdam__speaker-list-item'}>
                <Heading
                  type={5}
                  size={'medium'}
                  additionalClass={'event-fpmf_amsterdam__speaker-header-bold'}
                  noLine
                >
                  {item}
                </Heading>
              </li>
            ))}
          </ul>
        </Paragraph>
        <p
          className={
            'event-fpmf_amsterdam__speaker-paragraph event-fpmf_amsterdam__speaker-paragraph--border'
          }
        >
          Aneta has worked with companies such as:{' '}
          <b>
            Nestlé, Fonterra, BASF, UNICEF, Kaspersky, St Jude Medical,
            Deutsche Bank, Colgate-Palmolive.
          </b>
        </p>
      </Col>
      <Col md={2} />
      <Col
        sm={12}
        md={5}
        className={'event-fpmf_amsterdam__speaker-column-right'}
      >
        <img
          alt={'Aneta Orszewska – Boldare'}
          src={
            'https://res.cloudinary.com/de4rvmslk/image/upload/v1669260335/event/aneta_orszewska.png'
          }
        />
        <Paragraph>
          <b>
            <a
              href={'https://www.linkedin.com/in/anetaorszewska/'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              Aneta Orszewska
            </a>
          </b>
          <br />
          Product Strategist at Boldare
        </Paragraph>
      </Col>
    </Row>
  </Section>
);

export default Speaker;
