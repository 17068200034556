import React, { useEffect, useRef, useState } from 'react';

import FadiTrack from 'assets/sounds/digitalTransformationJourney/Fadi_track(x).mp3';

import './AudioPlayer.scss';
import { IconSVG } from 'components';

const AudioPlayer = () => {
  const audioPlayer = useRef();
  const progressBar = useRef();

  const [currentTime, setCurrentTime] = useState('00');
  const [duration, setDuration] = useState('00');
  const [isPlaying, setIsPlaying] = useState(false);

  function convertSecToDisplay(data) {
    const minutes = `${Math.floor(Number(data) / 60)}`.padStart(2, '0');
    const seconds = `${Math.floor(Number(data)) - minutes * 60}`.padStart(
      2,
      '0',
    );
    return `${minutes}:${seconds}`;
  }

  function toggleAudio() {
    if (audioPlayer.current.paused) {
      audioPlayer.current.play();
      setIsPlaying(true);
    } else {
      audioPlayer.current.pause();
      setIsPlaying(false);
    }
  }

  function changePlayerCurrentTime() {
    const secondsCurrent = Math.floor(audioPlayer?.current?.currentTime);
    setCurrentTime(convertSecToDisplay(secondsCurrent));

    const secondsDuration = Math.floor(audioPlayer?.current?.duration);
    setDuration(convertSecToDisplay(secondsDuration));

    progressBar.current.value = (secondsCurrent / secondsDuration) * 100;
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(secondsCurrent / secondsDuration) * 100}%`,
    );
  }

  function changeRange() {
    audioPlayer.current.currentTime =
      (Number(progressBar.current.value) * audioPlayer?.current?.duration) /
      100;
    changePlayerCurrentTime();
  }

  useEffect(() => {
    const id = setInterval(() => {
      changePlayerCurrentTime();
    }, 500);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className={'AudioPlayer-Wrapper'}>
      <audio ref={audioPlayer} src={FadiTrack} preload={'metadata'} />
      <div className={'audio-controls'}>
        <div className={'play'}>
          <IconSVG
            name={isPlaying ? 'pause' : 'play'}
            color={'white'}
            onClick={() => toggleAudio()}
          />
        </div>
        <div className={'progress-container'}>
          <input
            type={'range'}
            className={'progress--input'}
            defaultValue={0}
            ref={progressBar}
            onChange={changeRange}
            draggable={false}
          />
        </div>
        <div className={'timer'}>
          <div className={'timer-current'}>{currentTime}</div>
          <div className={'timer-split'}>/</div>
          <div className={'timer-duration'}>{duration}</div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
