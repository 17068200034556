import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { Section, Consultant } from 'components';
import ProgramContent from './ProgramContent';
import '../Resources.scss';

class Program extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-asset-page program'}>
        <Section additionalClass={'program-section--first'} spaceless>
          <ProgramContent {...this.props} />
        </Section>
        <Consultant {...this.props} />
      </div>
    );
  }
}

export default Program;
