export const data = [
  {
    number: '1',
    title: 'Work wherever you like!',
    alt:
      'Work wherever you like - Boldare - Digital Product Design Development Company',
    description:
      'You can work at home, on the beach or your favourite park in the city — but still, you can always join us at our cosy offices. We keep them open for all those who want to integrate :) Choose any of four locations (Warszawa, Gliwice, Kraków or Wrocław) and join us to talk about work or hobbies. We care for our bold bond, and this is why we’re meeting regularly to spend some time together in real life.',
    cloudinaryDir: 'remote-work-landing/boldare-scrum-development-company.png',
    sizeWidth: '368',
    className: 'slide1__image',
  },
  {
    number: '2',
    title: 'Transparency & Togetherness',
    alt:
      'Transparency & togetherness - Boldare - Digital Product Design Development Company',
    description:
      'We don\'t play remote hide-and-seek. You\'re "inn" all the time - team dailies, regular company meetings, open Slack communication, 24hr active links for spontaneous calls, ',
    cloudinaryDir: 'remote-work-landing/boldare-agile-development-company.png',
    sizeWidth: '438',
    className: 'slide2__image',
  },
  {
    number: '3',
    title: 'Knowledge Sharing',
    alt:
      'Knowledge sharing - Boldare - Digital Product Design Development Company',
    description:
      "We've got plenty of internal initiatives aimed at helping each other gather new skills and knowledge in our core specialties. Architecture Coffee for developers, Agile Lean Coffee and Scrumathons for Scrum Masters, Designers' chapters, you name it! We also care for regular in person meetings. They help our teams not only to share knowledge, but to work more efficiently and talk directly to each other about challenges they face as well.",
    cloudinaryDir:
      'remote-work-landing/boldare-digital-product-development-company.png',
    sizeWidth: '379',
    className: 'slide3__image',
  },
  {
    number: '4',
    title: 'Flexible working hours',
    alt:
      'Flexible working hours - Boldare - Digital Product Design Development Company',
    description:
      'If you need a break to walk your dog, to go to the dentist, pick up your kids - no problem. You start and finish as it suits you (we don\'t practice "9-5" or other limitations). All you need to do is make up the time with your team).',
    cloudinaryDir:
      'remote-work-landing/boldare-design-and-development-company.png',
    sizeWidth: '382',
    className: 'slide4__image',
  },
  {
    number: '5',
    title: 'Feedback & Team Spirit',
    alt:
      'Feedback workshops - Boldare - Digital Product Design Development Company',
    description:
      'Taking care of our development goals, constant feedback and a sense of inclusiveness has always been key to us. The idea of regular team meetings help us to stay connected and improve our soft skills at all times. Team integration from time to time allows meeting each other better and getting to know the most efficient ways of collaborative work.',
    cloudinaryDir:
      'remote-work-landing/boldare-mvp-development-company-workshops.png',
    sizeWidth: '395',
    className: 'slide5__image',
  },
  {
    number: '6',
    title: 'Proper equipment for working',
    alt:
      'Proper equipment for remote available - Boldare - Digital Product Design Development Company',
    description:
      'If you wish to visit any of our offices (which are located in Warszawa, Gliwice, Kraków and Worcław), expect the cosy atmosphere and all the needed stuff that will make you feel comfortable and ease your work.<br /><br />Also, if you need to upgrade your home office, our office equipment comes to the rescue. You can borrow monitors, chairs, desks, etc. that will make your place convenient to work.',
    cloudinaryDir: 'remote-work-landing/boldare-mvp-development-company.png',
    sizeWidth: '425',
    className: 'slide6__image',
  },
  {
    number: '7',
    title: 'Remote or in person recruitment?',
    alt:
      '100% remote recruitment - Boldare - Digital Product Design Development Company',
    description:
      'The choice is yours (although we would love to meet you in person!). Our Talent Selection Team led recruitment processes in both remote and stationary ways. Expect the best experience you’ve ever had during any campaign. We provide you with quick, fruitful and honest feedback - always.',
    cloudinaryDir:
      'remote-work-landing/boldare-design-and-development-company-remote.png',
    sizeWidth: '409',
    className: 'slide7__image',
  },
];
