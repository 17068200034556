import React, { Component } from 'react';
import {
  Heading,
  IconSVG,
  Paragraph,
  Section,
  SectionHeader,
} from 'components';
import { data } from './logic.js';
import './Benefits.scss';

class Benefits extends Component {
  renderItem = () =>
    data.map(item => (
      <div className={'mvp__benefits-item'}>
        <IconSVG name={`${item.icon}`} size={'huge'} color={'mine-shaft'} />
        <Heading size={'medium-plus-small'}>{item.title}</Heading>
        <Paragraph>{item.description}</Paragraph>
      </div>
    ));

  render() {
    return (
      <Section color={'pigeon-post-second'} additionalClass={'mvp__benefits'}>
        <SectionHeader
          size={'big'}
          lineColor={'red'}
          color={'black'}
          descriptionColor={'white'}
          headingType={2}
        >
          How can you benefit from an MVP?
        </SectionHeader>
        <div className={'mvp__benefits-items'}>{this.renderItem()}</div>
      </Section>
    );
  }
}

export default Benefits;
