import React, { useEffect, useRef } from 'react';

import LoudSpeaker from 'assets/images/home/hero/loudspeaker.png';
import BoldareSpokenWord from 'assets/sounds/home/hero/boldare-spoken-word.mp3';

import './Dictionary.scss';

const Dictionary = () => {
  const audio = useRef();

  useEffect(() => {
    if (window.Audio) {
      audio.current = new window.Audio(BoldareSpokenWord);
    }
  });

  return (
    <div className={'hero-dictionary'}>
      <div className={'hero-dictionary__heading'}>
        <h2 className={'hero-dictionary__header'}>Bold•dare</h2>
        bəʊldeə(r)
        <button
          className={'hero-dictionary__button'}
          onClick={() => audio.current.play()}
        >
          <img
            src={LoudSpeaker}
            alt={'loudspeaker'}
            className={'hero-dictionary__image'}
          />
        </button>
        <span className={'hide-mobile'}>noun</span>
      </div>
      <h1 className={'hero-dictionary__copy'}>
        We are Boldare, digital product creators & consultants
      </h1>
    </div>
  );
};

export default Dictionary;
