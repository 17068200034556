import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import CtoAsk from 'views/ctoAsk';
import config from '../../meta/config';

const page = pages.ctoask;
const { navColors } = page;
const url = `${config.siteUrl}`;
const canonical = `${url}`;

const CtoAskPage = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <CtoAsk />
    </Layout>
  );
};

export default CtoAskPage;
