/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { WayPointContext } from 'components';

class WayPointContainer extends Component {
  static propTypes = {
    children: PropTypes.func,
  };

  static defaultProps = { children: null };

  constructor() {
    super();

    this.state = {
      entryViews: [],
      leaveViews: [],

      reportActiveViewEntry: payload => {
        this.setState(prevState => {
          const entryViews = cloneDeep(prevState.entryViews);
          entryViews.push(payload);
          return { entryViews };
        });
      },

      reportActiveViewLeave: payload => {
        this.setState(prevState => {
          const leaveViews = cloneDeep(prevState.leaveViews);
          leaveViews.push(payload);
          return { leaveViews };
        });
      },

      clearActiveViews: () => {
        this.setState({ leaveViews: [], entryViews: [] });
      },
    };
  }

  render() {
    const { children } = this.props;

    const context = this.state;

    return (
      <WayPointContext.Provider value={context}>
        {children(context)}
      </WayPointContext.Provider>
    );
  }
}

export default WayPointContainer;
