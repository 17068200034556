import Banner from 'views/contactDe/subcomponents/Banner';
import Cities from 'views/contactDe/subcomponents/Cities';
import ContactFormDe from 'views/contactDe/subcomponents/ContactFormDe';
import GermanPartners from 'views/contactDe/subcomponents/GermanPartners';
import GermanMedia from 'views/contactDe/subcomponents/GermanMedia';
import SuperHeroes from 'views/contactDe/subcomponents/SuperHeroes';
import WhoAreWe from 'views/contactDe/subcomponents/WhoAreWe';
import ContactDeAwards from 'views/contactDe/subcomponents/ContactDeAwards';

export {
  Banner,
  Cities,
  ContactFormDe,
  GermanPartners,
  GermanMedia,
  SuperHeroes,
  WhoAreWe,
  ContactDeAwards,
};
