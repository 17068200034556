import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';

import { SliderContext } from '../../utils';

import './Photo.scss';

const Photo = ({ index }) => {
  const { slides } = useContext(SliderContext);
  const { photo, name, surname } = slides[index].client;

  return (
    <img
      className={'hero-slider__photo'}
      src={getCloudinaryUrl(photo, 'f_auto,q_auto')}
      alt={`${name} ${surname}`}
    />
  );
};

Photo.defaultProps = {
  index: 0,
};

Photo.propTypes = {
  index: PropTypes.number,
};

export default Photo;
