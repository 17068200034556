import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import {
  Banner,
  Recommended,
  Tiles,
  BoldareBoards,
  More,
} from './subcomponents';
import './ResourcesHub.scss';

class ResourcesHub extends Component {
  constructor() {
    super();

    this.state = { filtersActive: false };
  }

  componentDidMount() {
    enableAnimations();
  }

  toggleFilter = filter => {
    const { filters } = this.state;
    this.setState({
      filters: filters.map(item => ({
        ...item,
        isActive: item.name === filter,
      })),
    });
    this.handleFilterStatus();
  };

  render() {
    return (
      <div className={'single-page single-page--hero resources-hub-page'}>
        <Banner link={'resources-hub-hello'} />
        <Recommended />
        <Tiles toggleFilter={this.toggleFilter} />
        <BoldareBoards />
        <More />
      </div>
    );
  }
}

export default ResourcesHub;
