export const data = [
  {
    title: 'More interactions',
    text:
      'We put some videos and interaction elements to be sure user app understanding is bigger.',
    image: 'interactions.png',
  },
  {
    title: 'Global search',
    text:
      'This on was a big challenge, we rote completly new global search to look for information on entire site and on external forum.',
    image: 'search.png',
  },
  {
    title: 'Fresh and vivid',
    text:
      'When the whole background is gray you need some colors to make it more alive. We designed icons with vivid colors to make the whole thing more visually dynamic.',
    image: 'fresh.png',
  },
  {
    title: 'A combination of light and space',
    text:
      'We used a thin font for headlines and space between all elements in the project. This improved the reading experience for the user - important because there is a lot of information on the website, including detailed tech specs and long lists of features. Reading all that could feel frustrating. Well, not anymore.',
    image: 'combination.png',
  },
];
