import React from 'react';
import { FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';
import './PageCover.scss';

const PageCover = ({ cover }) => {
  return (
    <FullwidthImage
      image={getCloudinaryUrl(cover, 'f_auto,q_auto')}
      additionalClass={'page-cover'}
    />
  );
};

export default PageCover;

PageCover.propTypes = {
  cover: PropTypes.string.isRequired,
};
