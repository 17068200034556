import React from 'react';
import {
  Section,
  SectionHeader,
  Heading,
  Paragraph,
  Box,
  UnorderedList,
  Button,
  Link,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Technology.scss';

const Technology = () => (
  <>
    <Section
      id={'technology'}
      additionalClass={'technology'}
      color={'ebony-clay'}
    >
      <SectionHeader
        description={
          'We choose technology to suit your product, specific business goals and stage of innovation. Our stack includes:'
        }
        color={'white'}
        lineColor={'white'}
        descriptionColor={'white'}
      >
        Technology
      </SectionHeader>
    </Section>
    <Section
      color={'ebony-clay'}
      additionalClass={'technology__details'}
      fullwidth
    >
      <div className={'technology__stack'}>
        <div className={'technology__category'}>
          <Box
            additionalClass={'box--fitted technology__box anim-fade-in-bottom'}
            backgroundColor={'carrara'}
          >
            <SectionHeader
              size={'medium'}
              color={'red'}
              additionalClass={'technology__category-name'}
            >
              Web technology
            </SectionHeader>
            <UnorderedList
              additionalClass={'text--black'}
              columns={2}
              items={[
                'Java',
                'JavaScript',
                'Python',
                'PHP',
                'Node.js',
                'Angular',
                'React',
                'Django',
                'Symfony',
                'Vue.js',
                '.Net',
                'Typescript',
              ]}
            />
            <Link
              id={'discover-digital-transformation'}
              to={'/services/digital-transformation/'}
            >
              <Box
                additionalClass={'box--fitted technology__tile'}
                backgroundColor={'red'}
              >
                <div className={'technology__info'}>
                  <Heading
                    size={'medium-plus'}
                    type={3}
                    additionalClass={'technology__headline'}
                    color={'white'}
                  >
                    Digital <br />
                    transformation
                  </Heading>
                  <Paragraph
                    size={'default'}
                    color={'white'}
                    additionalClass={'text--light'}
                  >
                    Read about our Services:
                  </Paragraph>
                </div>
                <Button
                  color={'white'}
                  ariaName={'Discover button'}
                  transparent
                >
                  Discover
                </Button>
              </Box>
            </Link>
            <img
              src={getCloudinaryUrl(
                '/homepage/technology/working-team.jpg',
                'f_auto,q_auto,w_280',
              )}
              alt={'Team at work'}
              className={'image technology__image'}
              style={{
                width: '280px',
                height: '280px',
              }}
              loading={'lazy'}
            />
          </Box>
        </div>
        <div className={'technology__category'}>
          <Box
            additionalClass={'box--fitted technology__box anim-fade-in-bottom'}
            backgroundColor={'carrara'}
          >
            <SectionHeader
              size={'medium'}
              color={'pigeon-post-dark'}
              additionalClass={'technology__category-name'}
            >
              Mobile technology
            </SectionHeader>
            <UnorderedList
              additionalClass={'text--black'}
              columns={1}
              items={[
                'iOS',
                'Android',
                'Windows/UWP',
                'Xamarin',
                'React Native',
              ]}
            />
            <Link
              id={'discover-design-and-development'}
              to={'/services/product-design-and-development/'}
            >
              <Box
                additionalClass={'box--fitted technology__tile'}
                backgroundColor={'pigeon-post-dark'}
              >
                <div className={'technology__info'}>
                  <Heading
                    size={'medium-plus'}
                    type={3}
                    additionalClass={'technology__headline'}
                    color={'white'}
                  >
                    Product design <br />
                    &amp; development
                  </Heading>
                  <Paragraph
                    size={'default'}
                    color={'white'}
                    additionalClass={'text--light'}
                  >
                    Read about our Services:
                  </Paragraph>
                </div>
                <Button
                  color={'white'}
                  ariaName={'Discover button'}
                  transparent
                >
                  Discover
                </Button>
              </Box>
            </Link>
            <img
              src={getCloudinaryUrl(
                '/homepage/technology/technology-eres.jpg',
                'f_auto,q_auto,w_280',
              )}
              alt={'Application'}
              className={'image technology__image'}
              style={{
                width: '280px',
                height: '280px',
              }}
              loading={'lazy'}
            />
          </Box>
        </div>
        <div className={'technology__category'}>
          <Box
            additionalClass={'box--fitted technology__box anim-fade-in-bottom'}
            backgroundColor={'carrara'}
          >
            <SectionHeader
              size={'medium'}
              color={'violet'}
              additionalClass={'technology__category-name'}
            >
              AI Services
            </SectionHeader>
            <UnorderedList
              additionalClass={'text--black'}
              columns={2}
              items={[
                'TypeScript',
                'JavaScript',
                'Python',
                'OpenAI',
                'LangChain',
                'LLamaIndex',
                'LLM',
                'RAG',
                'Machine learning',
                'Fine-tuning',
              ]}
            />
            <Link
              id={'discover-ai-services'}
              to={'/services/ai-software-development-consulting/'}
            >
              <Box
                additionalClass={'box--fitted technology__tile'}
                backgroundColor={'violet'}
              >
                <div className={'technology__info'}>
                  <Heading
                    size={'medium-plus'}
                    type={3}
                    additionalClass={'technology__headline'}
                    color={'white'}
                  >
                    AI Services
                  </Heading>
                  <Paragraph
                    size={'default'}
                    color={'white'}
                    additionalClass={'text--light'}
                  >
                    Read about our Services:
                  </Paragraph>
                </div>
                <Button
                  color={'white'}
                  ariaName={'Discover button'}
                  transparent
                >
                  Discover
                </Button>
              </Box>
            </Link>
            <img
              src={getCloudinaryUrl(
                '/homepage/technology/blue-eye.jpg',
                'f_auto,q_auto,w_280',
              )}
              alt={'Focused eye'}
              className={'image technology__image'}
              style={{
                width: '280px',
                height: '280px',
              }}
              loading={'lazy'}
            />
          </Box>
        </div>
      </div>
    </Section>
  </>
);

export default Technology;
