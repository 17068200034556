import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { classNames } from 'utils';
import { Heading, Paragraph, Button, Link } from 'components';
import './SectionHeader.scss';

export const SIZES = {
  'extra-large': ['extra-large', 1, 'medium'],
  large: ['large', 1, 'medium'],
  big: ['large', 2, 'medium'],
  medium: ['big', 3, 'medium'],
  small: ['medium-plus', 3, 'regular'],
  'tiny-plus': ['medium', 3, 'regular'],
  tiny: ['small', 4, 'small'],
};

const setSizeClass = type => {
  return SIZES[type];
};

class SectionHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDescriptionHidden: props.hiddenDescription,
    };
  }

  render() {
    const { isDescriptionHidden } = this.state;
    const {
      additionalClass,
      buttonColor,
      centered,
      children,
      color,
      description,
      descriptionColor,
      fixed,
      headingType,
      hiddenDescription,
      lineColor,
      noLine,
      size,
      margin,
      isAuthorPage,
      isExternalAuthor,
    } = this.props;

    const lineColorClass = lineColor
      ? `section-header__headline--${lineColor}`
      : '';
    const noLineClass = noLine ? 'section-header__headline--no-line' : '';
    const headingSize = setSizeClass(size);

    return (
      <div
        className={classNames(
          'section-header',
          {
            [`section-header--${size}`]: size,
            'section-header--centered': centered,
          },
          additionalClass,
        )}
      >
        {children && (
          <div
            className={classNames(
              'section-header__headline',
              lineColorClass,
              noLineClass,
              margin,
            )}
          >
            <Heading
              color={color}
              size={headingSize[0]}
              type={headingType || headingSize[1]}
            >
              {children}
            </Heading>
          </div>
        )}
        {description && (
          <div
            className={classNames('section-header__description', {
              'anim-fade-in-bottom': !fixed,
            })}
          >
            <Paragraph
              color={descriptionColor}
              size={headingSize[2]}
              additionalClass={'text--light'}
              spaced
              nested
            >
              {typeof description === 'object'
                ? description
                : ReactHtmlParser(description)}
            </Paragraph>
            <Paragraph
              color={descriptionColor}
              size={headingSize[2]}
              additionalClass={classNames('text--light', {
                'description-hidden': isDescriptionHidden,
              })}
              spaced
            >
              {typeof hiddenDescription === 'object'
                ? hiddenDescription
                : ReactHtmlParser(hiddenDescription)}
            </Paragraph>
            {hiddenDescription && (
              <Button
                onClick={() =>
                  this.setState({
                    isDescriptionHidden: !isDescriptionHidden,
                  })
                }
                color={buttonColor}
                transparent
                additionalClass={'section-header__read-more'}
                ariaName={'Read more button'}
              >
                {!isDescriptionHidden ? 'Show less' : 'Show more'}
              </Button>
            )}
            {isAuthorPage && !isExternalAuthor && (
              <Paragraph
                additionalClass={'section-header__company'}
                color={'green'}
                size={'medium'}
              >
                at Boldare -{' '}
                <Link to={'/'} additionalClass={'section-header__link'}>
                  Product Design and Development Company
                </Link>
              </Paragraph>
            )}
          </div>
        )}
      </div>
    );
  }
}

SectionHeader.defaultProps = {
  children: null,
  description: '',
  lineColor: '',
  noLine: false,
  color: '',
  additionalClass: '',
  fixed: false,
  size: 'big',
  margin: '',
  centered: false,
  descriptionColor: 'default',
  headingType: null,
  hiddenDescription: null,
  buttonColor: 'white',
  isAuthorPage: false,
  isExternalAuthor: false,
};

SectionHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lineColor: PropTypes.string,
  noLine: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
  additionalClass: PropTypes.string,
  descriptionColor: PropTypes.string,
  fixed: PropTypes.bool,
  hiddenDescription: PropTypes.string,
  margin: PropTypes.string,
  buttonColor: PropTypes.string,
  headingType: PropTypes.oneOf([null, 1, 2, 3, 4, 5, 6]),
  isAuthorPage: PropTypes.bool,
  isExternalAuthor: PropTypes.bool,
};

export default SectionHeader;
