import React from 'react';
import { Link, Paragraph, Section, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Image from 'components/Image';
import './TransformationInPractise.scss';

const TransformationInPractise = () => (
  <Section
    color={'catskill-white'}
    additionalClass={'transformation-in-practise'}
  >
    <Image
      src={getCloudinaryUrl(
        '/img/renewable-energy-industry/circle-empty.svg',
        'f_auto,q_auto',
      )}
      alt={'Circle'}
      additionalClass={'transformation-in-practise__circle'}
    />
    <Image
      src={getCloudinaryUrl(
        '/img/renewable-energy-industry/fan.png',
        'f_auto,q_auto',
      )}
      alt={'Fan'}
      additionalClass={'transformation-in-practise__fan'}
    />
    <Image
      src={getCloudinaryUrl(
        '/img/renewable-energy-industry/solar-panels.png',
        'f_auto,q_auto',
      )}
      alt={'Solar panels'}
      additionalClass={'transformation-in-practise__solar-panels'}
    />
    <Image
      src={getCloudinaryUrl(
        '/img/renewable-energy-industry/base.jpg',
        'f_auto,q_auto',
      )}
      alt={'Yellow banckground'}
      additionalClass={'transformation-in-practise__base'}
    />
    <div className={'transformation-in-practise__wrapper'}>
      <div className={'transformation-in-practise__outer'}>
        <div className={'transformation-in-practise__outer--card'}>
          <Image
            src={getCloudinaryUrl(
              '/img/renewable-energy-industry/romuald-czlonkowski.png',
              'f_auto,q_auto',
            )}
            alt={'Romuald Członkowski'}
            additionalClass={'transformation-in-practise__video-img'}
          />
          <div className={'transformation-in-practise__card-description'}>
            <div className={'transformation-in-practise__card-name text--bold'}>
              ROMUALD CZŁONKOWSKI
            </div>
            <div className={'transformation-in-practise__card-job text--bold'}>
              Boldare AI expert
            </div>
          </div>
        </div>

        <div className={'transformation-in-practise__outer--cta'}>
          <Link to={'https://go.boldare.com/ai-for-renewable-energy-lp'}>
            <Button color={'yellow'}>Register for this webinar</Button>
          </Link>
        </div>
      </div>

      <div className={'transformation-in-practise__text'}>
        <Paragraph
          size={'big'}
          additionalClass={'transformation-in-practise__title text--bold'}
        >
          Practical AI for Renewable Energy Companies
        </Paragraph>
        <Paragraph size={'medium'}>
          Join the webinar and see how AI saves costs in the renewable energy industry by optimizing operations, predicting maintenance needs, and improving energy storage efficiency.
        </Paragraph>
        <Paragraph size={'medium'}>
          During the short webinar, AI expert Romuald Członkowski will showcase ready-to-use AI solutions specifically tailored to solve renewable energy industry challenges and advise on action plans to implement them.
        </Paragraph>
        <Paragraph size={'medium'}>
          Make renewable energy cheaper and more reliable by implementing smart data-driven systems.
        </Paragraph>
      </div>
    </div>
  </Section>
);

export default TransformationInPractise;
