export const differentData = [
  {
    id: 1,
    color: 'violet',
    name: '#system',
    title: 'Holacracy',
    description:
      'is a combination of Lean Startup and Scrum approaches. No managers. Just self-organized scrum teams.',
    link: '/blog/our-holacracy-experience/',
    img: 'career/career-system.png',
    small: true,
  },
  {
    id: 2,
    color: 'red',
    name: '#process',
    title: 'Full Cycle Product Development',
    description:
      'helps us provide tailored solutions and fit people adequately to a job they are cut out for.',
    link: '/blog/new-approach-to-product-development/',
    img: 'career/career-process.png',
  },
  {
    id: 3,
    color: 'pigeon-post',
    name: '#purpose',
    title: 'A purpose-driven approach',
    description:
      'allows us to act wisely and focus on the goal: to deliver products that generate real value to the users.',
    link: '/blog/value-proposition-canvas/',
    img: 'career/career-purpose.png',
    small: true,
  },
  {
    id: 4,
    color: 'yellow',
    name: '#impact',
    title: 'Strong decision-making culture',
    description:
      'and transparency. Our people have access to any information they need and impact how Boldare grows.',
    link: '/blog/how-radical-transparency-can-improve-business/',
    img: 'career/career-impact.png',
  },
  {
    id: 5,
    color: 'downy',
    name: '#growth',
    title: 'An individual <br/>career path',
    description:
      'and self-development opportunities to fully develop your potential and build Boldare league of leaders.',
    link: '/blog/boldareship-academy-how-we-built-our-own-league-of-leaders/',
    img: 'career/career-growth.png',
  },
  {
    id: 6,
    color: 'red',
    name: '#agility',
    title: 'Agile <br/>teams',
    description:
      'Agile development, Agile workspaces. They empower our creativity and brave us to work iteratively.',
    link: '/blog/what-is-agile-development/',
    img: 'career/career-agility.png',
  },
];
