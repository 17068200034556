import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { enableAnimations } from 'config/helpers';
import { ArticlesSection } from 'components';

import {
  Hero,
  TrustExperts,
  UniqueApproach,
  UnlockPower,
} from './subcomponents';

import './RescueFailingProject.scss';
import { getRescueFaillingProjectImageUrl } from './utils';

const OUR_WORK_DESCRIPTION = `Also, have a look at our case studies, showcasing our expertise in similar projects.
  These real-world examples demonstrate how we've navigated crises, overcame obstacles,
  and delivered projects on time without compromising quality.
  Let our proven track record instill confidence that we have what it takes to be your trusted partner.`;

const AWARDS = [
  ['awwwards', '12 times awwwards honorable mentioned'],
  ['clutch-2019', 'Top 1000 companies globally in 2019 by Clutch'],
  ['german-design-award', 'German Design Award 2021'],
  ['good-firms', 'Top software development company by GoodFirms'],
];

function RescueFailingProject({ allArticles }) {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'rescue-failing-project'}>
      <Hero />
      <ul
        className={
          'rescue-failing-project__list rescue-failing-project__slogans'
        }
      >
        <li>Test your business idea</li>
        <li>Build your digital product</li>
        <li>Grow your product</li>
        <li>Support your product</li>
        <li>Technologies</li>
      </ul>
      <UniqueApproach />
      <TrustExperts />
      <ul
        className={
          'rescue-failing-project__list rescue-failing-project__awards'
        }
      >
        {AWARDS.map(([image, description]) => (
          <li key={image}>
            <img
              src={getRescueFaillingProjectImageUrl(`awards/${image}`)}
              alt={description}
            />
          </li>
        ))}
      </ul>
      <ArticlesSection
        articles={allArticles}
        title={'Our work'}
        description={OUR_WORK_DESCRIPTION}
      />
      <UnlockPower />
    </div>
  );
}

RescueFailingProject.propTypes = {
  allArticles: PropTypes.object.isRequired,
};

export default RescueFailingProject;
