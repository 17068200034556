import React from 'react';

import { Rating } from 'components';
import { useIsMobile } from 'hooks/useIsMobile';

import './ClutchBanner.scss';
import companyData from '../../../../../meta/companyData';
import { getRecommendClientImageUrl } from '../../utils';

function ClutchBanner() {
  const {
    clutch_rate: clutchRate,
    clutch_reviews: clutchReviews,
  } = companyData;

  const isMobile = useIsMobile();

  return (
    <div className={'clutch-banner'}>
      <img
        className={'clutch-banner__logo'}
        src={getRecommendClientImageUrl('clutch-logo.png')}
        alt={'Clutch logo'}
      />

      <div className={'clutch-banner__content'}>
        <span
          className={
            'clutch-banner__content__item clutch-banner__content__header'
          }
        >
          {clutchReviews} verified Clutch reviews from global clients
        </span>
        <div className={'clutch-banner__content__separator'} />
        <span
          className={
            'clutch-banner__content__item clutch-banner__content__rating'
          }
        >
          <span className={'clutch-banner__content__rating__rate'}>
            {clutchRate.replace('.', ',')}
          </span>
          <span className={'clutch-banner__content__rating__text'}>
            Avarage referral rating
          </span>
          <Rating
            rate={clutchRate}
            color={'yellow'}
            spaceClassName={'clutch-banner__content__rating__stars'}
            size={isMobile ? 10 : 20}
          />
        </span>
      </div>

      <img
        className={'clutch-banner__badges'}
        src={getRecommendClientImageUrl('clutch-badges.png')}
        alt={'Clutch badges'}
      />
    </div>
  );
}

export default ClutchBanner;
