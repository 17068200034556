import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Paragraph, HashtagList } from 'components';
import './BoxImageCover.scss';

class BoxImageCover extends Component {
  render() {
    const { content, caption, image, tags } = this.props;

    return (
      <Box additionalClass={'box-image-cover'}>
        <img
          src={image}
          className={'image box-image-cover__image'}
          alt={'Cover'}
          loading={'lazy'}
        />
        <div className={'box-image-cover__overlay'}>
          <div className={'box-image-cover__main'}>
            <div>
              {caption && (
                <Paragraph
                  size={'small'}
                  additionalClass={'box-image-cover__caption'}
                >
                  {ReactHtmlParser(caption)}
                </Paragraph>
              )}
              <Paragraph
                size={'medium'}
                additionalClass={'box-image-cover__content'}
              >
                {ReactHtmlParser(content)}
              </Paragraph>
            </div>
            {tags && (
              <div className={'box-image-cover__tags'}>
                <HashtagList color={'gray-medium'} data={tags} />
              </div>
            )}
          </div>
        </div>
      </Box>
    );
  }
}

BoxImageCover.defaultProps = {
  caption: '',
  footer: '',
  tags: null,
};

BoxImageCover.propTypes = {
  caption: PropTypes.string,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  footer: PropTypes.string,
  tags: PropTypes.array,
};

export default BoxImageCover;
