import { Paragraph, Section } from 'components';
import './PreviousGuests.scss';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getCloudinaryUrl } from 'config/helpers';

const PreviousGuests = () => {
  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li
        className={active ? 'active ' : 'inactive'}
        onClick={() => onClick()}
      >
        {active ? (
          <span className={'dot active'} />
        ) : (
          <span className={'dot'} />
        )}
      </li>
    );
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 0 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
  };
  return (
    <Section fullWidth>
      <Row>
        <Col sm={12} md={6} />
        <Col sm={12} md={6} className={'title-previous-guests'}>
          <Paragraph
            color={'black'}
            size={'large'}
            additionalClass={'title-ask-cto'}
          >
            Previous editions' guests
          </Paragraph>
        </Col>
      </Row>
      <div>
        <Carousel
          swipeable
          draggable={false}
          showDots
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite
          autoPlay
          autoPlaySpeed={3000}
          keyBoardControl={false}
          customTransition={'all .5'}
          transitionDuration={100}
          containerClass={'carousel-container'}
          arrows={false}
          deviceType={'tablet'}
          dotListClass={'custom-dot-list-style'}
          itemClass={'carousel-item-padding-40-px'}
          customDot={<CustomDot />}
        >
          <img
            className={'image zoomin'}
            alt={'zoomin'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-zoomin.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image lubeck'}
            alt={'lubeck'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-lubeck.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image bernardini'}
            alt={'bernardini'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-bernardini.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image musement'}
            alt={'musement'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-musement.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image teoable'}
            alt={'teoable'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-teoable.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image unidays'}
            alt={'unidays'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-unidays.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image morphean'}
            alt={'morphean'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-morphean.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image kochan'}
            alt={'kochan'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-kochan.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
          <img
            className={'image robin'}
            alt={'kochan'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/askcto-logo-robin.png',
              'f_auto,q_auto',
            )}
            loading={'lazy'}
          />
        </Carousel>
      </div>
    </Section>
  );
};

export default PreviousGuests;
