export const listOfServices = [
  {
    title: 'Test your business idea',
    subtitle: "Let's answer important questions",
    categories: [
      {
        label: 'Digital Prototyping',
        url:
          'https://www.boldare.com/services/full-cycle-product-development/prototyping/',
      },
      {
        label: 'Minium Viable Product (MVP)',
        url: 'https://www.boldare.com/services/mvp-development/',
      },
      {
        label: 'UX/UI Design',
        url: 'https://www.boldare.com/ux-ui-design-consulting-services/',
      },
    ],
  },
  {
    title: `Build your digital product`,
    subtitle: `Enough features for future product development`,
    categories: [
      {
        label: `Product Design and Development`,
        url: `https://www.boldare.com/services/product-design-and-development/`,
      },
      {
        label: `Web and Mobile App Development`,
        url: `https://www.boldare.com/services/product-design-and-development/`,
      },
      {
        label: `CTO as a Service`,
        url: `https://www.boldare.com/work/cto-as-a-service-solves-the-problems-of-a-us-digital-product-company/`,
      },
    ],
  },
  {
    title: `Grow your product`,
    subtitle: `Get rid of borders if the product has growth potential`,
    categories: [
      {
        label: `Product Scaling`,
        url: `https://www.boldare.com/services/scaling-your-product-thriving-in-the-market/`,
      },
      {
        label: `Product-Market Fit`,
        url: `https://www.boldare.com/services/product-market-fit-expanding-demand/`,
      },
      {
        label: `Full Cycle Product Development`,
        url: `https://www.boldare.com/services/full-cycle-product-development/`,
      },
    ],
  },
  {
    title: `Digital transformation`,
    subtitle: `Change the face of your organization and go to digital transformation`,
    categories: [
      {
        label: `Code audit and UX Audit`,
        url: `https://www.boldare.com/services/code-audit/`,
      },
      {
        label: `Digital transformation`,
        url: `https://www.boldare.com/services/digital-transformation/`,
      },
    ],
  },
  {
    title: `AI services`,
    subtitle: `Build a new product or optimize an existing one with the help of our AI team`,
    categories: [
      {
        label: `AI Services`,
        url: `https://www.boldare.com/services/ai-software-development-consulting/`,
      },
    ],
  },
];
