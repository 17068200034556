import React from 'react';
import SingleStat from 'views/home/subcomponents/Prizes/subcomponents/Stats/SingleStat';
import { statsContent } from './logic';
import './Stats.scss';

const Stats = () => {
  return (
    <div className={'about-stats'}>
      <ul className={'about-stats-list'}>
        {statsContent.map(element => (
          <SingleStat
            key={element.id}
            isOdd={!!element.id % 2}
            decoration={element.decoration}
            rotation={element.rotation}
            text={element.text}
            link={element.link}
            isTabletBig={false}
          />
        ))}
      </ul>
    </div>
  );
};

export default Stats;
