import Link from 'components/Link';
import Paragraph from 'components/Paragraph';
import React from 'react';
import Logo from 'assets/images/logo-signet.svg';
import './WorkshopConfirmationNavbar.scss';

const WorkshopConfirmationNavbar = () => (
  <nav className={'finding-pmf-navbar'}>
    <div className={'finding-pmf-navbar__brand'}>
      <Link to={'/'} additionalClass={'finding-pmf-navbar__logo'}>
        <img
          src={Logo}
          className={'finding-pmf-navbar__logo--bldr'}
          alt={'Digital product design company Boldare'}
          loading={'lazy'}
        />
      </Link>
    </div>
  </nav>
);

export default WorkshopConfirmationNavbar;
