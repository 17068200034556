import React from 'react';

import {
  Paragraph,
  DottedList,
} from 'components';
import { Header, Section, Arrow, Planet, ArrowShort } from '../../../../components/DS';
import { servicesData } from './logic';

import './Services.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'horizontal'}
    longDescription
  />
);

const getBulletListItems = (items) => (
  items.map(item => <a href={item.link} className={'keyword-page-services__box-link'}>{item.name}</a>)
);

export const Services = () => (
  <Section
    heading={<SectionHeader title={servicesData.title} description={servicesData.description} />}
    color={'seashell'}
    noOverflow
  >
    {/* <div className={'keyword-page-services__planet-container'}>
      <Planet />
    </div> */}
    <div className={'keyword-page-services__content'}>
      {servicesData.services.map(({ title, description, items }) => (
        <div className={'keyword-page-services__box'}>
          <div className={'keyword-page-services__box-content'}>
            <div className={'keyword-page-services__box-content-text'}>
              <div className={'keyword-page-services__list-header'}>{title}</div>
              <p className={'keyword-page-services__list-description'}>{description}</p>
            </div>
            <div className={'keyword-page-services__list'}>
              {items.map(item => (
                <a href={item.link} className={'keyword-page-services__box-link'}>
                  {item.name}
                  <div className={'keyword-page-services__box-content-img'}>
                    <ArrowShort />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </Section>
);
