import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import WorkshopInformation from './WorkshopInformation';
import WorkshopForm from './WorkshopForm';

function WorkshopConfirmationForm({
  url,
  tag,
  lineColor,
  title,
  description,
  additionalText,
  formHeading,
  workshopQuestion,
  ratingLabel,
  descriptionLabel,
}) {
  return (
    <Row className={'no-gap'}>
      <Col sm={12} md={7}>
        <WorkshopInformation
          tag={tag}
          lineColor={lineColor}
          title={title}
          description={description}
          additionalText={additionalText}
        />
      </Col>
      <Col sm={12} md={5}>
        <WorkshopForm
          url={url}
          formHeading={formHeading}
          workshopQuestion={workshopQuestion}
          ratingLabel={ratingLabel}
          descriptionLabel={descriptionLabel}
        />
      </Col>
    </Row>
  );
}

WorkshopConfirmationForm.propTypes = {
  url: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  lineColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalText: PropTypes.string.isRequired,
  formHeading: PropTypes.string.isRequired,
  workshopQuestion: PropTypes.string.isRequired,
  ratingLabel: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
};

export default WorkshopConfirmationForm;
