import React from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Tool.scss';

const Tool = () => {
  return (
    <Section additionalClass={'showbiz__tool'} spaceless>
      <div className={'showbiz__tool-content'}>
        <Heading type={2} size={'big-spreaded'} color={'mine-shaft'}>
          Perfect management tool
        </Heading>
        <div className={'showbiz__tool-container'}>
          <div className={'showbiz__tool-copy'}>
            <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
              From the admin perspective
            </Heading>
            <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
              Extensive admin panel
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Easy way to create the castings, models management and creating
              events.
              <br />
              <br />
              The admin can quickly search for models according to interesting
              attributes, for example - languages, site, height or
              measurements. Thanks to receiving the requests from the Partner,
              it can quickly determine how many models are actually available
              in your area or meet specifies requirements.
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/showbiz/showbiz-monitor-right.png',
              'f_auto,q_auto',
            )}
            alt={'Admin panel'}
            additionalClass={'showbiz__tool-image--full anim-fade-in-right'}
          />
          <img
            src={getCloudinaryUrl(
              'assets/showbiz/casting-form.png',
              'f_auto,q_auto',
            )}
            alt={'Casting form'}
            className={'image showbiz__tool-image anim-fade-in-bottom'}
            loading={'lazy'}
          />
        </div>
      </div>
    </Section>
  );
};

export default Tool;
