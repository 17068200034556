import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Heading, BoxArticle, IconSVG } from '..';

class SingleRelatedArticle extends Component {
  renderRelatedArticle = () => {
    const { item, additionalClass } = this.props;

    if (item.title === '' || item.title === 'undefined') {
      return (
        <div
          className={classNames(
            'related-articles__item',
            'related-articles__item--single',
            'related-articles__item--disabled',
            additionalClass,
          )}
        >
          <div
            className={classNames('related-articles__item-content', {
              [`related-articles__item-content--${item.hover.background}`]: item
                .hover.background,
            })}
          >
            <IconSVG name={item.hover.icon} size={'large'} color={'white'} />
            <Heading size={'big'} color={'white'} type={3}>
              {item.hover.headline}
            </Heading>
          </div>
        </div>
      );
    }

    const articleData = this.props.allArticles.edges.find(
      edge => edge.node.frontmatter.title === item.title,
    );
    const { box } = articleData.node.frontmatter;

    return (
      articleData[0] !== 'undefined' && (
        <div
          className={classNames(
            'related-articles__item',
            'related-articles__item--single',
            additionalClass,
          )}
        >
          {item.hover && (
            <div
              className={classNames('related-articles__item-content', {
                [`related-articles__item-content--${item.hover.background}`]: item
                  .hover.background,
              })}
            >
              <IconSVG name={item.hover.icon} size={'large'} color={'white'} />
              <Heading size={'big'} color={'white'} type={3}>
                {item.hover.headline}
              </Heading>
            </div>
          )}
          <div
            className={classNames({
              'related-articles__item-overlay': item.hover,
            })}
          >
            <BoxArticle post={articleData} />
          </div>
        </div>
      )
    );
  };

  render() {
    return this.renderRelatedArticle();
  }
}

SingleRelatedArticle.defaultProps = {
  additionalClass: '',
};

SingleRelatedArticle.propTypes = {
  item: PropTypes.shape().isRequired,
  additionalClass: PropTypes.string,
};

export default SingleRelatedArticle;
