import React from 'react';
import PropTypes from 'prop-types';

import { ContactForm as CommonContactForm } from 'components';

console.log(CommonContactForm.V2.ResponseSendContactHeaderTwo);

function ContactForm({ id, name }) {
  return (
    <CommonContactForm.V2
      id={id}
      name={name}
      topic={'Lead Referral LP Submission'}
      pardotLink={'https://go.boldare.com/l/688533/2023-07-18/2l8vfg'}
      textareaContent={'Drop a few lines about the company you wish to refer'}
      textareaShort={'Drop a few lines about the company you wish to refer'}
      companyEmailContent={'Your email'}
      button={'SUBMIT'}
      header={() => (
        <h2 className={'bring-a-qualified-lead__heading'}>
          Contact us to join the referral program
        </h2>
      )}
      responseSendContactHeaderTwo={() => (
        <CommonContactForm.V2.ResponseSendContactHeaderTwo
          texts={[
            'We will get back to you within 24\u00A0business hours.',
            'If you need immediate assistance, call us on',
          ]}
        />
      )}
    />
  );
}

ContactForm.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ContactForm;
