export default [
  {
    icon: 'thumb-up',
    description: 'Vote on specific cards',
  },
  {
    icon: 'eye-crossed',
    description:
      'Set the number of votes and hide them before the end of voting',
  },
  {
    icon: 'sort-cards',
    description: 'Sort the votes during the retro meeting',
  },
  {
    icon: 'share',
    description: 'Share the retro board with other team members',
  },
  {
    icon: 'drag-and-drop',
    description: 'Merge cards and move them between columns',
  },
  {
    icon: 'remote-work',
    description: 'Collaborate with your team remotely',
  },
];
