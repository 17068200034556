import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Link } from 'components';
import { classNames } from 'utils';
import './ContactPerson.scss';
import { getCloudinaryUrl } from 'config/helpers';

const ContactPerson = ({ person }) => {
  if (!person) return null;

  return (
    <div className={classNames('person')}>
      {person.avatar && (
        <img
          src={getCloudinaryUrl(
            `business-crew/${person.avatar}`,
            'f_auto,q_auto,h_300',
          )}
          alt={`Author - ${person.name} ${person.position}`}
          className={'person__avatar'}
          loading={'lazy'}
        />
      )}
      <div className={'person__info'}>
        <Paragraph
          size={'small'}
          color={'white'}
          additionalClass={'person__info-position'}
        >
          {person.position.toUpperCase()}
        </Paragraph>
        <Paragraph
          size={'medium'}
          color={'white'}
          additionalClass={'person__info-name'}
        >
          {person.name}
        </Paragraph>
        <Link to={`mailto:${person.email}`}>
          <Paragraph color={'white'}>{person.email}</Paragraph>
        </Link>
      </div>
    </div>
  );
};

ContactPerson.propTypes = {
  person: PropTypes.object.isRequired,
};

export default ContactPerson;
