import React from 'react';
import PropTypes from 'prop-types'

import './HighlightShort.scss';

export const HighlightShort = ({ fill, textColor, children }) => (
  <span className={'highlight-short'} style={{color: textColor}}>
    {children}
    <svg className={'highlight-short__svg'} xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 376 97'} fill={'none'}>
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={'m92.2 84.78-27.85 2.58 2.44 8.99 182.58-16.97 13.17.3-.47-3.98 7 .15 74.26 5.08-1.37-7.18 4.98.26-.52-7 1.01-.04-.6-3.34h6.33l-1.1-7.75 12.99-.92-2-9.04-.36.03.26-.86-9.15-.88 1.59-5.9-6.91-.42 9.75-.29 2.06.2.08-.26h.38l.86-4.17.78-2.62 11.11.63 2.32-9.07-.83-.05.35-2.17-10.55.72-1.75-7.92-16.23 1.15-.68-5.02-49.36.55.25-.04-2.54-8.86-30.14 4.36.34-2.1L163.4 9.87l-24.8-1.43-2.26-.22-.02.1-2.29-.14-.81 3.2-32.55.35-72.8-4.17-2.24-.22-.03.09-2.29-.14-1.38 5.4-11.13-1.07-2.69 8.97 10.05.98-15.69.1-1.93 9.22 2.33-.01.14 1.85 4.43.23.92 7.86 10 .22v.11L0 41.91 1.62 51l6.35-.27 1.48 6.7 8.29-.6 1.02 5.71v.14h.02l.36 2 16.5-.69.18 1.57-27.74-.6 1.08 9.2 8.8.19 1.19 6.6 16.78-.7 1.58 7.15 54.43-3.86.27 1.24Z'}
        clipRule={'evenodd'}
      />
    </svg>
  </span>
);

HighlightShort.defaultProps = {
  fill: '#fff',
  textColor: '#000',
};

HighlightShort.propTypes = {
  fill: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node.isRequired
};
