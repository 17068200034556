import React from 'react';
import { BackgroundImage, Section, SectionHeader } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import './Banner.scss';
import { getCloudinaryUrl } from 'config/helpers';
import ListOfNumbers from 'views/digitalTransformationJourney/subcomponents/Banner/Numbers';
import { Carousel } from 'views/digitalTransformationJourney/subcomponents/Banner/Carousel';

export const Banner = () => {
  return (
    <Section
      additionalClass={`
        digital-transform-journey-banner
        digital-transform-journey-banner--position-relative
      `}
    >
      <BackgroundImage
        asset={'digital-transformation-journey/ekrem-osmanoglu.png'}
        alt={''}
        additionalClass={'banner__img background-img--cloudinary'}
        maxHeight={'1795'}
      />
      <div className={'digital-transform-journey-banner__content-container'}>
        <div className={'digital-transform-custom-container'}>
          <Row>
            <Col sm={12} md={5}>
              <SectionHeader
                size={'extra-large'}
                color={'white'}
                descriptionColor={'black'}
                lineColor={'white'}
                buttonColor={'black'}
                fixed
                noLine
                additionalClass={
                  'digital-transform-journey-banner__text-header'
                }
              >
                Your trusted technology partner&nbsp;in&nbsp;Saudi Arabia
              </SectionHeader>
            </Col>
          </Row>
          <div
            className={
              'digital-transform-journey-banner__images-wrapper__satellite'
            }
          >
            <img
              src={getCloudinaryUrl(
                'digital-transformation-journey/satellite-banner-v2.png',
                'f_auto,q_auto,w_auto',
              )}
              className={'image'}
              alt={''}
              loading={'lazy'}
            />
          </div>

          <div
            className={`
              digital-transform-journey-banner--position-relative
              digital-transformation-journey-banner__box-container--spacing
            `}
          >
            <Row className={`digital-transform-journey__col-wrapper`}>
              <ListOfNumbers />
            </Row>
          </div>
        </div>
      </div>
      <div
        className={`
          digital-transformation-journey-banner__carousel-container
        `}
      >
        <div
          className={`

            digital-transformation-journey-banner__carousel-wrapper
          `}
        >
          <div
            className={`
              digital-transform-journey-banner__carousel-padding-left
            `}
          >
            <Row>
              <Col xs={12}>
                <Row start={'xs'}>
                  <Carousel />
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Section>
  );
};
