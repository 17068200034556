import { Paragraph, Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './AskMatt.scss';

const AskMatt = () => {
  return (
    <Section fullWidth additionalClass={'ask-matt'}>
      <Row>
        <Col sm={12} md={5}>
          <Paragraph
            color={'white'}
            size={'large'}
            additionalClass={'ask-matt-title'}
          >
            Any questions?
            <br />
            Get in touch
          </Paragraph>
        </Col>
        <Col sm={12} md={7}>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'ask-matt-paragraph'}
          >
            <b>
              We are happy to provide you with more information regarding the
              event's details.
            </b>
          </Paragraph>
        </Col>
      </Row>
      <div className={'ask-matt-info'}>
        <img
          className={'image ask-matt-image'}
          alt={'Matt Hallmann'}
          src={getCloudinaryUrl(
            'gatsby-cloudinary/matt-hallmann-askcto.png',
            'f_auto,q_100',
          )}
          loading={'lazy'}
        />
        <div className={'ask-matt-bio'}>
          <span className={'ask-matt-bio-name'}>Matt Hallmann</span>

          <span className={'ask-matt-bio-title'}>Community Builder</span>
        </div>
      </div>
    </Section>
  );
};

export default AskMatt;
