import React from 'react';
import Layout from 'components/layout';
import { Footer, NavBar } from 'components';
import DiscussionEvent from 'views/discussionEvent';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../meta/config';

const page = pages['when-to-invest-in-ux-webinar'];
const { navColors } = page;
const url = `${config.siteUrl}/when-to-invest-in-ux-webinar/`;
const canonical = `${url}`;

const WhenToInvestInUXWebinarPage = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <DiscussionEvent formUrl={pardotlink} />
      <Footer />
    </Layout>
  );
};

export default WhenToInvestInUXWebinarPage;
