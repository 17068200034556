import React, { Component } from 'react';
import {
  Section,
  SectionHeader,
  BoxLayoutMobileSingle,
  WayPointHandler,
  BlogSlider,
  Heading,
  Paragraph,
  Button,
  Link,
} from 'components';
import './Culture.scss';
import { getCloudinarySrcSet, getCloudinaryUrl } from 'config/helpers';
import { data, sliderData } from './logic';

class Culture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  renderBoxes = () => {
    const { isMobile } = this.state;

    return data.map((item, index) => (
      <div
        className={`culture__box ${item.description && 'culture__box--bobo'} ${
          isMobile ? '' : 'anim-fade-in-bottom'
        }`}
        key={`box-culture-${index}`}
      >
        <Link to={item.link}>
          <div className={`culture__box-content`}>
            <img
              src={getCloudinaryUrl(
                item.backgroundImage,
                'f_auto,q_auto,w_500',
              )}
              loading={'lazy'}
              className={'culture__box-image'}
              alt={item.badge}
            />

            <div className={'culture__box-img'}>
              {item.image && (
                <img
                  src={getCloudinaryUrl(item.image, 'f_auto,q_auto')}
                  loading={'lazy'}
                  className={'image'}
                  alt={`${item.badge} Icon`}
                />
              )}
              <span
                className={`culture__box-badge culture__box-badge--${item.badgeColor}`}
              >
                {item.badge}
              </span>
            </div>
            <div className={'culture__box-details'}>
              <Heading color={item.color} size={'medium'} type={3}>
                {item.headline}
              </Heading>
              {item.description && (
                <Paragraph color={item.color}>{item.description}</Paragraph>
              )}
              <Button color={item.buttonColor}>{item.button}</Button>
            </div>
          </div>
        </Link>
      </div>
    ));
  };

  render() {
    return (
      <WayPointHandler path={'culture'}>
        <>
          <Section color={'gray-light'} additionalClass={'culture'}>
            <SectionHeader
              lineColor={'yellow'}
              description={
                'Life at Boldare is about knowledge-sharing and sharing cake, teamwork and team fun, openness and open bar, accountability and the ability to play guitar, JavaScript pro and PlayStation pro, holacracy and friendly ¡Hola!. Together it all makes us Boldare!'
              }
            >
              Culture
            </SectionHeader>
          </Section>
          <BlogSlider
            data={sliderData.map(slide => ({
              ...slide,
              background: slide.background,
            }))}
          />
          <Section color={'gray-light'}>
            <SectionHeader size={'big'} lineColor={'violet'}>
              Sharing knowledge
            </SectionHeader>
            <BoxLayoutMobileSingle mobileSlider>
              {this.renderBoxes()}
            </BoxLayoutMobileSingle>
          </Section>
        </>
      </WayPointHandler>
    );
  }
}

export default Culture;
