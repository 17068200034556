import React from 'react';

import { Button, Link } from 'components';
import { whoWeAre } from 'views/whoWeAre/utils';

import './Hero.scss';

const { hero: { title, description, partnersDescription, partnersLogos }} = whoWeAre;

export const Hero = () => (
  <div className={'who-we-are-page-wrapper'}>
    <div className={'who-we-are-hero'}>
      <div className={'who-we-are-hero__heading'}>
        <h1 className={'who-we-are-hero__header'}>{title}</h1>
        <p className={'who-we-are-hero__description'}>{description}</p>
        <Link
          to={`/contact`}
        >
          <Button
            id={'who-we-are-contact-btn'}
            color={'yellow'}
            ariaName={'Contact button'}
            additionalClass={'who-we-are-hero__button'}
          >
            Contact us
          </Button>
        </Link>
      </div>
      <div className={'who-we-are-hero__logos'}>
        <div className={'who-we-are-hero__logos-wrapper'}>
          {partnersDescription}
          <div className={'who-we-are-hero__logos-container'}>
            {partnersLogos.map(logo => (
              <div className={'who-we-are-hero__logo'}>
                <img src={logo.link} alt={`${logo.name}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)
