import React from 'react';
import { EventsHistory, MainContent } from './subcomponents';

import './FindingPmfBerlin.scss';

const FindingPmfBerlin = props => (
  <div className={'single-event-berlin-page'}>
    <MainContent {...props} />
    <EventsHistory />
  </div>
);

export default FindingPmfBerlin;
