export const data = [
  {
    title: 'How to deliver an e-commerce platform MVP in just 6 weeks',
    hover: {
      icon: 'cart',
      background: 'rodeo-dust',
      headline: 'E-commerce website development',
    },
  },
  {
    title: 'Visualising Energy',
    hover: {
      icon: 'customer',
      background: 'pigeon-post',
      headline: 'Customer Portal development',
    },
  },
  {
    title:
      'How to scale a monolithic MVP application without losing business?',
    hover: {
      icon: 'network',
      background: 'jordy-blue',
      headline: 'SaaS Platform development',
    },
  },
  {
    title: '',
    hover: {
      icon: 'customer-relation',
      background: 'martinique',
      headline: 'Customer Relationship Management (CRM) system development',
    },
  },
  {
    title:
      'From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand',
    hover: {
      icon: 'player',
      background: 'mine-shaft',
      headline: 'Online Radio Player development',
    },
  },
  {
    title:
      'Designing a simple search experience for a complex product with a luxurious interface',
    hover: {
      icon: 'booking',
      background: 'red',
      headline: 'Booking Platform development',
    },
  },
  {
    title: '',
    hover: {
      icon: 'payment',
      background: 'pigeon-post',
      headline: 'Payment Gateway development',
    },
  },
  {
    title: '',
    hover: {
      icon: 'brain',
      background: 'jordy-blue',
      headline: 'Learning Management System development',
    },
  },
];
