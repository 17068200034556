import React from 'react';
import { Paragraph, Heading, Section, SectionHeader } from 'components';
import './International.scss';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { getCloudinaryUrl } from 'config/helpers';

const International = () => (
  <>
    <Section
      additionalClass={'career__international-section'}
      id={'meet-boldare'}
    >
      <ParallaxProvider>
        <img
          className={
            'career__international-images career__international-images--map'
          }
          alt={'Boldare Career - international tech company'}
          src={getCloudinaryUrl(
            'career/international-map.svg',
            'f_auto,q_auto,w_1030',
          )}
        />
        <Parallax
          translateY={[0, -80]}
          speed={0}
          className={
            'career__international-parallax career__international-parallax-01 hide-mobile hide-tablet'
          }
        >
          <img
            className={
              'career__international-images career__international-images--1'
            }
            alt={'Boldare Career - international tech company'}
            src={getCloudinaryUrl(
              'career/international-imgs-1',
              'f_auto,q_auto',
            )}
          />
        </Parallax>
      </ParallaxProvider>

      <Heading
        type={3}
        size={'medium'}
        color={'mine-shaft'}
        additionalClass={'headline--top headline--top-yelow'}
      >
        Meet Boldare
      </Heading>
      <ParallaxProvider>
        <SectionHeader
          additionalClass={'headline--centered'}
          color={'mine-shaft'}
          size={'medium'}
          headingType={2}
          noLine
        >
          An international{' '}
          <span className={'headline--marking headline--marking-yellow--tech'}>
            tech&nbsp;company
          </span>{' '}
          <br />
          with people spread around Europe
          <Parallax
            translateY={['-230px', '-360px']}
            translateX={['-190px', '-190px']}
            speed={-20}
            className={
              'career__international-parallax career__international-parallax-02 hide-mobile hide-tablet'
            }
          >
            <img
              className={'career__international-images--2'}
              alt={'Boldare Career - international tech company'}
              src={getCloudinaryUrl(
                'career/devcamp_boldare_1',
                'f_auto,q_auto',
              )}
            />
          </Parallax>
          <Parallax
            translateY={['-200px', '-530px']}
            translateX={['190px', '190px']}
            speed={-10}
            className={
              'career__international-parallax career__international-parallax-03 hide-mobile hide-tablet'
            }
          >
            <img
              className={'career__international-images--3'}
              alt={'Boldare Career - international tech company'}
              src={getCloudinaryUrl(
                'career/devcamp_boldare_2',
                'f_auto,q_auto',
              )}
            />
          </Parallax>
        </SectionHeader>
      </ParallaxProvider>
      <div className={'career__international-info'}>
        <div className={'career__international-info--row'}>
          <div
            className={
              'career__international-info--column career__international-info--column-text violet'
            }
          >
            <span className={'career__international-info--title'}>
              Our places
            </span>
            <div className={'career__international-info--highlight'}>
              <span>about 200</span> specialists in 62 cities
            </div>
            <Paragraph
              color={'mine-shaft'}
              additionalClass={'career__international-info--paragraph'}
            >
              We are scattered all over Europe! Discover our offices – they are
              placed in Gliwice, Kraków, Wrocław, Warszawa. Our specialists
              work and live in Poland, Germany, Sweden, Portugal, and the
              Netherlands. You can find our people spread in Europe, in 62
              cities.
            </Paragraph>
          </div>
          <div
            className={
              'career__international-info--column career__international-images--team'
            }
          >
            <img
              alt={'Boldare Career - international tech company'}
              src={getCloudinaryUrl(
                'career/international-images-4',
                'f_auto,q_auto',
              )}
            />
            <img
              alt={'Boldare Career - international tech company'}
              src={getCloudinaryUrl(
                'career/international-images-4-mobile2x.jpg',
                'f_auto,q_auto',
              )}
            />
          </div>
        </div>
        <div className={'career__international-info--row'}>
          <div
            className={
              'career__international-info--column career__international-info--column-fullwidth'
            }
          >
            <img
              className={
                'career__international-images career__international-images--map-cities'
              }
              alt={'Boldare Career - international tech company'}
              src={getCloudinaryUrl(
                'career/international-map-cities.svg',
                'f_auto,q_auto,w_860',
              )}
            />
          </div>
          <div
            className={
              'career__international-info--column career__international-info--column-text red'
            }
          >
            <span className={'career__international-info--title'}>
              Our clients
            </span>
            <div className={'career__international-info--highlight'}>
              <span>over 300</span> products for international clients
            </div>
            <Paragraph
              color={'mine-shaft'}
              additionalClass={'career__international-info--paragraph'}
            >
              Our teams provide a world-class quality of software development
              and hi-end design. We use carefully chosen technology and
              cooperate with international clients as equal partners. Till now,
              we&apos;ve created 300+ products for 130+ clients from the US &
              Western Europe to the Middle East & Hong Kong.
            </Paragraph>
          </div>
        </div>
      </div>
    </Section>
  </>
);

export default International;
