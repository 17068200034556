import React, { Component } from 'react';
import { CodeBlock, Section, Heading, Paragraph, Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Components.scss';

class Components extends Component {
  render() {
    const boxPlace = (
      <span>
        &lt;box-place place="place"&gt;
        <br />
        &lt;/box-place&gt;
        <br />
        <br />
        place - place object from API
      </span>
    );
    const boxSpecialist = (
      <span>
        &lt;box-specialist
        <br />
        &#9;specialist="specialist"
        <br />
        &#9;condensed="false"
        <br />
        &gt;&lt;box-specialist&gt;
      </span>
    );
    const specialist = (
      <span>
        specialist - specialist object
        <br />
        condensed - boolean value
        <br />
        (true/false)
      </span>
    );
    const boxShop = (
      <span>
        &lt;box-shop&gt;&lt;/box-shop&gt;
        <br />
        <br />
        shop - place object from Shopify
      </span>
    );
    const boxBlog = (
      <span>
        &lt;box-blog
        <br />
        &#9;data="&lt;Post Object&gt;"
        <br />
        &#9;condensed="false"
        <br />
        &gt;&lt;/box-blog&gt;
      </span>
    );
    const boxBlogSmall = (
      <span>
        &lt;box-blog
        <br />
        &#9;data="&lt;Post Object&gt;"
        <br />
        &#9;condensed="true"
        <br />
        &gt;&lt;/box-blog&gt;
      </span>
    );
    const boxUser = (
      <span>
        &lt;box-user
        <br />
        &#9;data="&lt;Post Object&gt;"
        <br />
        &#9;condensed="true"
        <br />
        &gt;&lt;/box-user&gt;
      </span>
    );
    const boxReview = (
      <span>
        &lt;box-review
        <br />
        &#9;data="place"
        <br />
        &gt;&lt;/box-review>
      </span>
    );
    const rating = (
      <span>
        &lt;rating
        <br />
        &#9;value="4"
        <br />
        &#9;type="place"
        <br />
        &#9;disabled="true"
        <br />
        &gt;&lt;/rating&gt;
      </span>
    );
    const chip = (
      <span>
        &lt;chip data="data"&gt;
        <br />
        &lt;/chip&gt;
      </span>
    );

    return (
      <>
        <Section additionalClass={'sm__components-container'} spaceless>
          <div className={'sm__content'}>
            <Heading type={2} size={'big-spreaded'}>
              System dressed in reusable components
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              We dressed all of the new solutions in components. Thanks to this
              whole service is straightforward for users and they can learn it
              really fast. All of the components were designed in a way to be
              reusable for building others and are ready to appear in different
              places of the site. The key element was the component&apos;s
              usage on the front-end development level.
            </Paragraph>
            <Heading type={3} size={'medium-plus'}>
              Design meets front-end development
            </Heading>
            <Heading type={4} color={'boston-blue'} size={'medium-plus-small'}>
              Directly in kitchen - kitchen sink
            </Heading>
          </div>
        </Section>
        <Section additionalClass={'sm__components'} spaceless>
          <div
            className={
              'sm__components-box sm__components-box--big sm__components-box--pink'
            }
          >
            <CodeBlock children={boxSpecialist} />
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-bg-pink-profile.jpg',
                'f_auto,q_auto',
              )}
              alt={'Profile'}
              className={'image sm__components-image'}
              loading={'lazy'}
            />
          </div>
          <div
            className={
              'sm__components-box sm__components-box--right sm__components-box--big sm__components-box--blue'
            }
          >
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-bg-blue-place.jpg',
                'f_auto,q_auto',
              )}
              alt={'Place info'}
              className={'image sm__components-image'}
              loading={'lazy'}
            />
            <CodeBlock children={boxPlace} />
          </div>
        </Section>
        <Section additionalClass={'sm__components'} spaceless>
          <div className={'sm__components-box--large'}>
            <div
              className={
                'sm__components-box sm__components-box--small sm__components-box--pink'
              }
            >
              <CodeBlock children={specialist} />
              <img
                src={getCloudinaryUrl(
                  'assets/spa-monkeys/spamonkeys-bg-pink-condensed.jpg',
                  'f_auto,q_auto',
                )}
                alt={'Profile condensed'}
                className={'image sm__components-image--floating'}
                loading={'lazy'}
              />
            </div>
            <div
              className={
                'sm__components-box sm__components-box--big sm__components-box--green'
              }
            >
              <CodeBlock children={boxShop} />
              <img
                src={getCloudinaryUrl(
                  'assets/spa-monkeys/spamonkeys-bg-green-product.jpg',
                  'f_auto,q_auto',
                )}
                alt={'Product'}
                className={'image sm__components-image'}
                loading={'lazy'}
              />
            </div>
          </div>
          <div className={'sm__components-box--large'}>
            <div
              className={
                'sm__components-box sm__components-box--right sm__components-box--big sm__components-box--gray'
              }
            >
              <img
                src={getCloudinaryUrl(
                  'assets/spa-monkeys/spamonkeys-bg-grey-news.jpg',
                  'f_auto,q_auto',
                )}
                alt={'News tile'}
                className={'image sm__components-image'}
                loading={'lazy'}
              />
              <CodeBlock children={boxBlog} />
            </div>
            <div
              className={
                'sm__components-box sm__components-box--right sm__components-box--small sm__components-box--gray'
              }
            >
              <img
                src={getCloudinaryUrl(
                  'assets/spa-monkeys/spamonkeys-bg-grey-news-condensed.jpg',
                  'f_auto,q_auto',
                )}
                alt={'News tile condensed'}
                className={'image sm__components-image'}
                loading={'lazy'}
              />
              <CodeBlock children={boxBlogSmall} />
            </div>
          </div>
        </Section>
        <Section additionalClass={'sm__components'} spaceless>
          <div
            className={
              'sm__components-box sm__components-box--full sm__components-box--gray'
            }
          >
            <CodeBlock children={boxUser} />
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-long-profile.jpg',
                'f_auto,q_auto',
              )}
              alt={'User profile'}
              className={'image sm__components-image'}
              loading={'lazy'}
            />
          </div>
        </Section>
        <Section additionalClass={'sm__components'} spaceless>
          <div
            className={
              'sm__components-box sm__components-box--no-shadow sm__components-box--big sm__components-box--blue'
            }
          >
            <CodeBlock children={boxReview} />
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-review.png',
                'f_auto,q_auto',
              )}
              alt={'Review'}
              className={'image sm__components-image--overflow'}
              loading={'lazy'}
            />
          </div>
          <div
            className={
              'sm__components-box sm__components-box--no-shadow sm__components-box--multi sm__components-box--big sm__components-box--gray'
            }
          >
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-points.png',
                'f_auto,q_auto',
              )}
              className={'image'}
              alt={'Rating'}
              loading={'lazy'}
            />
            <CodeBlock children={rating} />
            <img
              src={getCloudinaryUrl(
                'assets/spa-monkeys/spamonkeys-avatar.png',
                'f_auto,q_auto',
              )}
              alt={'Avatar'}
              className={'image sm__components-image--small'}
              loading={'lazy'}
            />
            <CodeBlock children={chip} />
          </div>
        </Section>
      </>
    );
  }
}

export default Components;
