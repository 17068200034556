export const data = [
  {
    name: 'Anna Zarudzka',
    description:
      'Co-CEO of Boldare / leadership, digital transformation, risk management',
    linkedin: 'https://www.linkedin.com/in/annazarudzka/',
    image: 'crew/anna-zarudzka.jpg',
  },
  {
    name: 'Piotr Majchrzak',
    description: 'Co-CEO of Boldare / leadership, organization, culture',
    linkedin: 'https://www.linkedin.com/in/piotrmajchrzak/',
    image: 'crew/piotr-majchrzak.jpg',
  },
  {
    name: 'Patrycja Wala',
    description: 'Agile Transformation Guide',
    linkedin: 'https://www.linkedin.com/in/patrycja-wala/',
    image: 'img/new-normal/patrycja-wala.jpg',
  },
];
