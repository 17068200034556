import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  ImageMosaic,
  Heading,
  SectionHeader,
  Paragraph,
  Testimonial,
  Link,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const Solution = () => (
  <div className={'hack-the-wind__solution'}>
    <Section
      color={'white'}
      additionalClass={'hack-the-wind__solution-content'}
    >
      <SectionHeader headingType={2}>Solution</SectionHeader>
      <Paragraph additionalClass={'text--light'}>
        The Boldare Machine Learning team approached the problem from a
        business perspective. After a short product workshop they came up with
        an initial product canvas, however, they decided that they need a proof
        of concept. The team went straight to the target group using to their
        advantage the Wind Energy Hamburg trade show, which was taking place in
        the same building as the competition. What they found out from people
        in the industry change their idea for the product and made them pivot.
        It turned out that the real problem for the wind farm owners was lack
        of product that would seamlessly interconnect the predictive
        maintenance data within the maintenance flow. The team designed a
        product that would not only inform the owner about future malfunction
        but also allow him to react to it either by sending an inspection crew
        on site or ordering an immediate part replacement.
      </Paragraph>
    </Section>
    <Section spaceless fullwidth side={'left'}>
      <Row className={'no-gap'}>
        <Col sm={12} md={6} className={'client-review__container'}>
          <div className={'section w-container--left'}>
            <Heading
              size={'medium-plus'}
              color={'violet'}
              type={3}
              additionalClass={'client-review__testimonial-intro'}
            >
              Wind Europe Organizers
            </Heading>
            <Testimonial
              additionalClass={'text--light'}
              content={
                'Boldare proposes to unify the process of maintenance with the help of an application and AI!'
              }
              author={'@InnoEnergyIB'}
            />
          </div>
        </Col>
        <Col sm={12} md={6} className={'client-review__image-wrapper'}>
          <div className={'client-review__image'}>
            <img
              src={getCloudinaryUrl(
                'img/ml-team-in-action-2.png',
                'f_auto,q_auto',
              )}
              className={'image'}
              alt={'Hack The Wind'}
              loading={'lazy'}
            />
          </div>
        </Col>
      </Row>
    </Section>
    <Section
      spaceless
      fullwidth
      additionalClass={'cs-statistics'}
      side={'left'}
    >
      <Row className={'no-gap'}>
        <Col sm={12} md={6}>
          <div className={'cs-statistics__image'}>
            <img
              src={getCloudinaryUrl(
                'img/ml-team-in-action.png',
                'f_auto,q_auto',
              )}
              className={'image'}
              loading={'lazy'}
              alt={'Hack The Wind'}
            />
          </div>
        </Col>
        <Col sm={12} md={6} className={'cs-statistics__container'}>
          <div className={'section w-container--right cs-statistics__inner'}>
            <SectionHeader color={'white'} lineColor={'white'} size={'small'}>
              Real impact
            </SectionHeader>
            <div className={'cs-statistics__content'}>
              <Paragraph
                color={'white'}
                size={'medium'}
                additionalClass={'text--default cs-statistics__caption'}
              >
                We delivered this solution during the time frame of
              </Paragraph>
              <span className={'cs-statistics__number'}>48h</span>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
    <Section
      color={'white'}
      additionalClass={'hack-the-wind__solution-content'}
    >
      <Paragraph additionalClass={'text--light'}>
        Boldare Machine Learning team proposed individual machine learning
        models for each component within a wind turbine, that can predict
        failures up to 60 days before they occur.
      </Paragraph>
      <Paragraph additionalClass={'text--light'}>
        The results and alert are displayed within the app, showing which
        particular turbine is likely to fail, the likelihood of a breakdown
        within the next 24 hours, and information, which part will malfunction.{' '}
      </Paragraph>
    </Section>
    <Section fullwidth spaceless>
      <ImageMosaic
        images={[
          {
            image: {
              src: getCloudinaryUrl(
                'img/ml-team-group-photo.png',
                'f_auto,q_auto',
              ),
            },
          },
          {
            image: {
              src: getCloudinaryUrl(
                'img/hack-the-wind-mockups.png',
                'f_auto,q_auto',
              ),
            },
          },
        ]}
      />
    </Section>
    <Section
      color={'white'}
      additionalClass={'hack-the-wind__solution-content'}
    >
      <Paragraph additionalClass={'text--light'}>
        In the next releases the team proposed that the app could be expanded
        with inventory updates as financial reports.
      </Paragraph>
      <Link
        to={'/work/case-study-predictive-maintenance/'}
        additionalClass={'text--medium paragraph'}
        id={'htw-link-cs'}
        rel={'nofollow'}
      >
        See our case study.
      </Link>
    </Section>
  </div>
);

export default Solution;
