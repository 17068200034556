import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import {
  Components,
  Contact,
  Description,
  Intro,
  Organism,
  Services,
  Solutions,
  Team,
  Technologies,
  Work,
} from './subcomponents';
import './SpaMonkeys.scss';

class SpaMonkeys extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study sm__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys_-_case_study_boldare.jpg',
            'f_auto,q_auto',
          )}
          color={'white'}
          subtitle={'Several business and services'}
          title={'Beauty Businesses in Hong Kong'}
        />
        <Intro />
        <Services />
        <Team />
        <Work />
        <Solutions />
        <Technologies />
        <Description />
        <Components />
        <Organism />
        <Contact />
      </div>
    );
  }
}

export default SpaMonkeys;
