import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';

import './ProgressBar.scss';

export const ProgressBar = ({ progress, hideOnFull }) => {
  const hidden = hideOnFull && progress >= 100;

  return (
    <div
      className={classNames('progress-bar', {
        'progress-bar--hidden': hidden,
      })}
    >
      <div
        className={'progress-bar__fill'}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

ProgressBar.defaultProps = {
  progress: false,
  hideOnFull: false,
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  hideOnFull: PropTypes.bool,
};
