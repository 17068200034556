import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import * as Scroll from 'react-scroll';
import { Section, Heading, Icon, Paragraph } from 'components';
import './Banner.scss';
import { ICON_TYPES } from 'config';
import { getCloudinaryUrl } from 'config/helpers';

const Banner = () => {
  const SpyLink = Scroll.Link;

  return (
    <StaticQuery
      query={graphql`
        query {
          mainBannerImage: file(
            relativePath: {
              eq: "why-design-matters/Mobile-design-portfolio.jpg"
            }
          ) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div id={'design-matters'} className={'wdm__banner'}>
          <img
            className={'image wdm__banner-media'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/Mobile-design-portfolio.jpg',
              'c_crop',
            )}
            alt={''}
            loading={'lazy'}
          />
          <Section
            color={'mine-shaft'}
            spaceless
            additionalClass={'wdm__banner-section'}
          >
            <div className={'wdm__banner-container'}>
              <Heading
                color={'white'}
                size={'large'}
                type={1}
                additionalClass={'wdm__banner-title text--bold'}
              >
                <span>Why</span>
                <span>UX</span>
                <span>&amp;</span>
                <span>UI</span>
                <br />
                <span>design</span>
                <span>matters?</span>
              </Heading>
              <Heading
                color={'white'}
                size={'big'}
                type={2}
                additionalClass={'wdm__banner-subtitle'}
              >
                5 reasons why you should choose Boldare
              </Heading>
              <SpyLink
                id={'wdm__go-to-content'}
                to={'wdm__banner-content'}
                spy={false}
                smooth
                duration={500}
                className={'wdm__banner-link'}
              >
                <Icon
                  icon={ICON_TYPES.RIGHT_ARROW}
                  size={'default'}
                  color={'white'}
                />
              </SpyLink>
            </div>
            <Section
              id={'wdm__banner-content'}
              color={'mine-shaft'}
              additionalClass={'wdm__banner-content'}
            >
              <Paragraph additionalClass={'text--medium-plus text--white'}>
                Ever stopped to think about good design? And how rare it
                sometimes seems to be?
              </Paragraph>
              <Paragraph additionalClass={'text--light text--white'}>
                A well-designed digital product does more than just function,
                it does so reliably and with a UX that gives the user exactly
                what they need and want (and often, more). In a world flooded
                with cheap products that do the bare minimum, a truly
                well-designed product stands out.
              </Paragraph>
              <Paragraph
                additionalClass={
                  'text--light wmd__banner--desktop text--white'
                }
              >
                When you’re building digital products, good design matters.
                Investing in UX design during the initial development reduces
                overall costs, increases sales conversions, can boost customer
                loyalty, reduce the time to market, and increase ROI. This is
                why, at Boldare, we are intensely committed to great design.
              </Paragraph>
            </Section>
          </Section>
        </div>
      )}
    />
  );
};

export default Banner;
