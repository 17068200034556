import React from 'react';
import PropTypes from 'prop-types';
import { Link, Button } from 'components';
import { useIsMobile } from '../../hooks/useIsMobile';
import './EngagementBannerAI.scss';
import { scheduleMeetingUrl } from 'config/constants/links';

const EngagementBannerAI = ({
  title,
  url,
  buttonText,
  description,
  withArrows,
}) => {
  const isMobile = useIsMobile();
  const arrows = isMobile ? false : withArrows;
  return (
    <div className={'banner-ai'}>
      {arrows && (
        <div className={'banner-ai__arrow-left'}>
          <img
            src={
              'https://res.cloudinary.com/de4rvmslk/image/upload/v1706620500/arrow_left_black_and_white.svg'
            }
            alt={'left-arrow'}
          />
        </div>
      )}
      <div className={'banner-ai__content'}>
        <p className={'banner-ai__heading'}>{title}</p>
        <div className={'banner-ai__description'}>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        <div className={'banner-ai__button-container'}>
          <Link to={url}>
            <Button id={'engagement-banner__button'}>
              <span className={'banner-ai__button-cta'}>{buttonText}</span>
            </Button>
          </Link>
        </div>
      </div>
      {arrows && (
        <div className={'banner-ai__arrow-right'}>
          <img
            src={
              'https://res.cloudinary.com/de4rvmslk/image/upload/v1706617644/arrow_right_black_and_white.svg'
            }
            alt={'right-arrow'}
          />
        </div>
      )}
    </div>
  );
};

EngagementBannerAI.defaultProps = {
  url: scheduleMeetingUrl,
  description: '',
  withArrows: false,
};

EngagementBannerAI.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
  withArrows: PropTypes.bool,
};

export default EngagementBannerAI;
