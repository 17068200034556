import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getArticleSlug, getCloudinaryUrl } from 'config/helpers';
import {
  DateComponent,
  Heading,
  Paragraph,
  Box,
  YouTubePlayer,
  PopUp,
  FakeVideoPlayer,
  IconSVG,
} from 'components';
import { classNames, getYoutubeThumbnailUrl } from 'utils';
import './BoxArticle.scss';

const BoxArticle = ({ post, id, campaignID }) => {
  const [videoPopupVisible, setVidePopupVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 480);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { frontmatter } = post.node;
  const { title, box, templateKey, settings, service } = frontmatter;

  if (!box || !settings) {
    return null;
  }

  const coverImage =
    box?.content.coverImage || frontmatter.cover || 'img/human-eye.jpg';

  return (
    <Box
      additionalClass={classNames(
        {
          'box-case-study': settings?.category === 'Case Study',
          'box-article': settings?.category !== 'Case Study',
        },
        campaignID,
      )}
    >
      {templateKey === 'youtube-video' && (
        <>
          <div className={'box-article__header box-article__main-content'}>
            <span className={'box__badge box__badge--article text--bold'}>
              Video
            </span>
            {isMobile ? (
              <YouTubePlayer src={settings?.url} light />
            ) : (
              <FakeVideoPlayer
                title={'test'}
                handleClick={() => setVidePopupVisible(true)}
              >
                <img
                  src={getYoutubeThumbnailUrl(settings?.url)}
                  alt={title}
                  className={'image box-article__cover-image'}
                  loading={'lazy'}
                />
              </FakeVideoPlayer>
            )}
          </div>
          <div
            className={classNames(
              'box-article__content',
              'box__content--static',
              {
                'box--short': title && title.length <= 29,
                'box--long': title && title.length > 29,
              },
            )}
          >
            <div className={'box-article__top'}>
              <a
                href={`${settings?.url}`}
                id={`article-title-${id}`}
                target={'_blank'}
                className={'box-article__link'}
                rel={'noopener noreferrer nofollow'}
              >
                <Heading
                  type={3}
                  size={'medium-plus-small'}
                  additionalClass={'box-article__title'}
                >
                  {title}
                </Heading>
              </a>
              <div className={'box-article__lead'}>
                <Paragraph
                  size={'default'}
                  color={'black'}
                  additionalClass={'text--light'}
                >
                  {box?.content.tileDescription}
                </Paragraph>
              </div>
            </div>
            <div className={'box-article__bottom'}>
              <div className={'box-article__info'}>
                <Paragraph
                  size={'small'}
                  color={'black'}
                  additionalClass={
                    'box-article__category box-article__category--single'
                  }
                >
                  <DateComponent date={settings?.date} />
                  <a
                    href={`${settings?.url}`}
                    id={`arrow-${id}`}
                    target={'_blank'}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <IconSVG
                      name={'arrow-blog'}
                      color={'black'}
                      additionalClass={'box__badge--arrow'}
                    />
                  </a>
                </Paragraph>
              </div>
            </div>
          </div>
          {!isMobile && (
            <PopUp
              visible={videoPopupVisible}
              visibilityHandler={showPoup => setVidePopupVisible(showPoup)}
              additionalClass={'box-article__popup'}
            >
              <YouTubePlayer src={settings.url} playing />
            </PopUp>
          )}
        </>
      )}
      {(templateKey === 'medium-page' || templateKey === 'external-page') && (
        <>
          <div className={'box-article__header box-article__main-content'}>
            <span className={'box__badge box__badge--article text--bold'}>
              {service ? `${service}` : 'Article'}
            </span>
            <a
              href={`${settings.url}`}
              target={'_blank'}
              id={`article-img-${id}`}
              rel={'noopener noreferrer nofollow'}
            >
              <img
                src={getCloudinaryUrl(coverImage, 'f_auto,q_auto,h_320')}
                alt={title}
                className={'image box-article__cover-image'}
                loading={'lazy'}
              />
            </a>
          </div>
          <div
            className={classNames('box-article__content', {
              'box--short': title && title.length <= 29,
              'box--long': title && title.length > 29,
            })}
          >
            <div className={'box-article__top'}>
              <a
                href={`${settings.url}`}
                target={'_blank'}
                id={`article-title-${id}`}
                className={'box-article__link'}
                rel={'noopener noreferrer nofollow'}
              >
                <Heading
                  type={3}
                  size={'medium-plus-small'}
                  additionalClass={'box-article__title'}
                >
                  {title}
                </Heading>
              </a>
              <div className={'box-article__lead'}>
                <Paragraph
                  size={'default'}
                  color={'black'}
                  additionalClass={'text--light'}
                >
                  {box?.content.tileDescription}
                </Paragraph>
              </div>
            </div>
            <div className={'box-article__bottom'}>
              <div className={'box-article__info'}>
                <Paragraph
                  size={'small'}
                  color={'black'}
                  additionalClass={
                    'box-article__category box-article__category--single'
                  }
                >
                  <a
                    href={`${settings.url}`}
                    target={'_blank'}
                    id={`arrow-${id}`}
                    rel={'noopener noreferrer nofollow'}
                  >
                    <IconSVG
                      name={'arrow-blog'}
                      color={'black'}
                      additionalClass={'box__badge--arrow'}
                    />
                  </a>
                </Paragraph>
              </div>
            </div>
          </div>
        </>
      )}
      {templateKey !== 'medium-page' &&
        templateKey !== 'external-page' &&
        templateKey !== 'youtube-video' && (
          <>
            <div className={'box-article__header box-article__main-content'}>
              {settings.category === 'Case Study' ? (
                <span
                  className={'box__badge box__badge--case-study text--bold'}
                >
                  Case Study
                </span>
              ) : (
                <span className={'box__badge box__badge--article text--bold'}>
                  {settings.category === 'Video' ? 'Video' : 'Article'}
                </span>
              )}
              <a id={`article-image-${id}`} href={`${getArticleSlug(post)}`}>
                <img
                  src={getCloudinaryUrl(coverImage, 'f_auto,q_auto,h_320')}
                  alt={title}
                  className={'image box-article__cover-image'}
                  loading={'lazy'}
                />
              </a>
            </div>
            <div
              className={classNames('box-article__content', {
                'box--short': title && title.length <= 29,
                'box--long': title && title.length > 29,
              })}
            >
              <div className={'box-article__top'}>
                <a
                  id={`article-title-${id}`}
                  href={`${getArticleSlug(post)}`}
                  className={'box-article__link'}
                >
                  <Heading
                    type={3}
                    size={'medium-plus-small'}
                    additionalClass={'box-article__title'}
                  >
                    {title}
                  </Heading>
                </a>
                <div className={'box-article__lead'}>
                  <Paragraph
                    size={'default'}
                    color={'black'}
                    additionalClass={'text--light'}
                  >
                    {box?.content.tileDescription}
                  </Paragraph>
                </div>
              </div>
              <div className={'box-article__bottom'}>
                <div className={'box-article__info'}>
                  <Paragraph
                    size={'small'}
                    color={'black'}
                    additionalClass={
                      'box-article__category box-article__category--single'
                    }
                  >
                    {settings.category !== 'Case Study' && (
                      <DateComponent date={settings.date} />
                    )}
                    <a
                      id={`arrow-${id}`}
                      href={`${getArticleSlug(post)}`}
                      rel={'nofollow'}
                    >
                      <IconSVG
                        name={'arrow-blog'}
                        color={'black'}
                        additionalClass={'box__badge--arrow'}
                      />
                    </a>
                  </Paragraph>
                </div>
              </div>
            </div>
          </>
        )}
    </Box>
  );
};

BoxArticle.defaultProps = {
  color: '',
};

BoxArticle.propTypes = {
  color: PropTypes.string,
};

export default BoxArticle;
