import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

export default function LetsTalk({ className }) {
  return (
    <div
      className={`rescue-failing-project__unlock-power__lets-talk ${className}`}
    >
      <div
        className={'rescue-failing-project__unlock-power__lets-talk__inner'}
      >
        <h5>Let&apos;s get in touch</h5>
        <p>Let&apos;s talk and find out how we can help you.</p>
        <Button
          color={'white'}
          size={'large'}
          id={'rescue-failing-project--book-a-call'}
          tag={'a'}
          href={'https://calendly.com/mateusz-pachnik/30min'}
          additionalClass={
            'rescue-failing-project__unlock-power__lets-talk__button ga-business-scenarios-cta'
          }
        >
          BOOK A CALL &#8594;
        </Button>
      </div>
    </div>
  );
}

LetsTalk.propTypes = {
  className: PropTypes.string,
};

LetsTalk.defaultProps = {
  className: '',
};
