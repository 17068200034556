export const data = [
  {
    title: 'Client + Boldare',
    description: 'client  + ',
    highlights: '1 PO + DEV TEAM + SM',
  },
  {
    title: '1 common team',
    highlights: '1 PO <strong>(client)*</strong> + 1 BO + DEV TEAM',
    extra: '*Our success: the customer has become a part of the scrum team!',
  },
  {
    title: '806',
    description: 'commits',
  },
  {
    title: '191',
    description: 'closed pull requests',
  },
];
