import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './BoxLayoutImages.scss';

const BoxLayoutImages = ({ additionalClass, children }) => (
  <div className={classNames('box-layout-images', additionalClass)}>
    {children}
  </div>
);

BoxLayoutImages.defaultProps = {
  additionalClass: '',
};

BoxLayoutImages.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BoxLayoutImages;
