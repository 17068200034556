import React, { PureComponent } from 'react';
import { ContactFormServices } from 'components';
import { enableAnimations } from 'config/helpers';
import {
  Banner,
  DevTeam,
  OurStructure,
  Solution,
  OtherServices,
  Technologies,
  Outsourcing,
  Articles,
  Work,
  Clutch,
  LogoSlider,
} from './subcomponents';
import './SoftwareDevelopmentOutsourcing.scss';

class SoftwareDevelopmentOutsourcing extends PureComponent {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedOutsourcing = [
      'Software Development Outsourcing - everything you should know',
      '8 Benefits Of Outsourcing your Software Development to Poland',
      'Software development in Germany - costs, challenges, and chances',
      'This is how Boldare Development Teams process addresses your business needs',
      'Choosing a digital product development partner - the political and economic issues',
      'When two become one. The story of Boldare',
    ].map(title => ({ title }));

    return (
      <div id={'sdo-section'} className={'single-page single-page--hero sdo'}>
        <Banner link={'sdo-contact-form'} />
        <Work />
        <LogoSlider />
        <DevTeam />
        <OurStructure />
        <Clutch />
        <Solution />
        <Technologies />
        <Outsourcing link={'sdo-contact-form'} />
        <Articles allArticles={allArticles} related={relatedOutsourcing} />
        <ContactFormServices
          id={'sdo-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-10-21/3482b'}
        />
        <OtherServices />
      </div>
    );
  }
}

export default SoftwareDevelopmentOutsourcing;
