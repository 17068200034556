import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Heading,
  Paragraph,
  Section,
  SectionHeader,
  Link,
  Button,
  YouTubePlayer,
  ResourceList,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './CourseContent.scss';

class CourseContent extends Component {
  renderVideoPlug = () => {
    return (
      <div className={'webinar-res__plug'}>
        <Heading type={3} size={'medium-plus'}>
          Summary coming soon
        </Heading>
      </div>
    );
  };

  renderArticle = data => {
    return (
      <Box
        id={data.relatedArticle.id}
        backgroundColor={'white'}
        additionalClass={'course-articles__list-box'}
      >
        <div
          className={
            'course-articles__list-box-entry course-articles__list-box-entry--article'
          }
        >
          <div className={'course-articles__list-box-entry__image'}>
            <img
              src={getCloudinaryUrl(
                data.relatedArticle.photo,
                'f_auto,q_auto',
              )}
              alt={data.serviceTitle}
              className={'image course-articles__list-box-entry__cover'}
              loading={'lazy'}
            />
          </div>
          <div className={'course-articles__list-box-entry__text'}>
            <a href={data.relatedArticle.link}>
              <Heading
                additionalClass={'course-articles__list-box-entry__title'}
                type={3}
                size={'medium-plus-small'}
                color={'mine-shaft'}
              >
                {data.relatedArticle.title}
              </Heading>
            </a>
            <Paragraph
              additionalClass={'course-articles__list-box-entry__lead'}
            >
              {data.relatedArticle.lead}
            </Paragraph>
          </div>
          <div className={'course-articles__list-box-entry__badge'}>
            <Paragraph
              size={'small'}
              color={'black'}
              additionalClass={'box-case-study__category'}
            >
              <span className={'box__badge box__badge--article text--bold'}>
                {data.relatedArticle.type}
              </span>
            </Paragraph>
          </div>
        </div>
      </Box>
    );
  };

  renderDedicatedMaterial = data => {
    return (
      <>
        {data.dedicated.map(dedicatedContent => (
          <div className={'webinar-res__ded'}>
            <Heading type={3} size={'medium-plus'}>
              {dedicatedContent.dedicatedTitle}
            </Heading>
            <Paragraph size={'medium'}>
              {ReactHtmlParser(dedicatedContent.dedicatedDescription)}
            </Paragraph>
            <Link to={dedicatedContent.dedicatedUrl}>
              <Button color={'white'}>
                {dedicatedContent.dedicatedButton}
              </Button>
            </Link>
          </div>
        ))}
      </>
    );
  };

  renderSpeakerList = data => {
    return (
      <ul className={'speakers-component__speaker-list'}>
        {data.speakers.map(speaker => (
          <li className={'speakers-component__speaker-list-item'}>
            <div className={'speakers-component__speaker-list-img'}>
              <img
                className={'image'}
                alt={speaker.name}
                src={getCloudinaryUrl(speaker.avatar, 'f_auto,q_auto')}
                loading={'lazy'}
              />
            </div>
            <div className={'speakers-component__speaker-list-content'}>
              <span className={'speakers-component__speaker-list-position'}>
                {speaker.position}
              </span>
              <span className={'speakers-component__speaker-list-name'}>
                {speaker.name}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { data, coverAlt, itemsQuestions } = this.props;

    return (
      <Section
        id={`section-${data.id}`}
        additionalClass={'webinar-res__content'}
        color={data.color}
      >
        <span className={'webinar-res__content-badge'}>{data.id}/3</span>
        <SectionHeader
          color={'mine-shaft'}
          description={data.description}
          descriptionColor={'mine-shaft'}
          fixed
          noLine
          additionalClass={'section-header__description--medium'}
        >
          {data.title}
        </SectionHeader>
        {data.isFinished ? (
          <YouTubePlayer src={data.videoUrl} light />
        ) : (
          this.renderVideoPlug(data)
        )}
        <div className={'webinar-res__content-wrapper'}>
          <div className={'webinar-res__content-details'}>
            <Paragraph
              size={'medium'}
              additionalClass={'text--bold content-title'}
            >
              {data.pointsTitle}
            </Paragraph>
            <ResourceList
              items={data.points}
              {...{ itemsQuestions, coverAlt }}
            />
            {data.isSpeakers && (
              <div className={'speakers-component__content-speakers'}>
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--bold content-title'}
                >
                  Speakers:
                </Paragraph>
                {this.renderSpeakerList(data)}
              </div>
            )}
          </div>
          {(data.isDedicatedMaterial || data.isArticle) && (
            <div className={'webinar-res__content-sidebar'}>
              <Paragraph
                size={'medium'}
                additionalClass={'text--bold content-title'}
              >
                Related materials:
              </Paragraph>
              <div className={'webinar-res__content-sidebar-boxes'}>
                {data.isArticle && (
                  <div className={'webinar-res__content-sidebar-box'}>
                    {this.renderArticle(data)}
                  </div>
                )}
                {data.isDedicatedMaterial && (
                  <div className={'webinar-res__content-sidebar-box'}>
                    {this.renderDedicatedMaterial(data)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Section>
    );
  }
}

export default CourseContent;
