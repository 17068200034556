import React from 'react';
import { Button, MarkerHighlight, Section } from 'components';

import './Hero.scss';

function Hero() {
  return (
    <Section
      color={'mine-shaft'}
      additionalClass={'rescue-failing-project__hero'}
    >
      <div className={'rescue-failing-project__hero__wrapper'}>
        <h1 className={'rescue-failing-project__hero__title'}>
          Facing a crisis situation with your project?
        </h1>
        <h3 className={'rescue-failing-project__hero__subtitle'}>
          We understand the urgency and pressure you&apos;re under. We are here
          for you.
        </h3>
        <Button
          color={'yellow'}
          additionalClass={'rescue-failing-project__hero__button'}
          tag={'a'}
          href={'https://calendly.com/mateusz-pachnik/30min'}
        >
          LET&apos;S TALK
        </Button>
      </div>
    </Section>
  );
}

export default Hero;
