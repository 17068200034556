import Banner from './Banner';
import ReasonsWhy from './ReasonsWhy';
import Offer from './Offer';
import Contact from './Contact';
import Work from './Work';
import Benefits from './Benefits';
import Subscribe from './Subscribe';
import LearnMore from './LearnMore';
import OtherServices from './OtherServices';

export {
  Banner,
  ReasonsWhy,
  Offer,
  Contact,
  Work,
  Benefits,
  Subscribe,
  LearnMore,
  OtherServices,
};
