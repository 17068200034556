export const QuizState = {
  landing: 0,
  quiz: 1,
  result: 2,
};

export const Phase = {
  ready: 'ready',
  notReady: 'notReady',
};
