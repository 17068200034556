import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, Heading } from 'components';
import './Challenge.scss';

class Challenge extends Component {
  render() {
    const { subtitle, title } = this.props;

    return (
      <Section additionalClass={'pe__challenge-container'}>
        <div className={'pe__content--left'}>
          <Heading
            type={3}
            size={'medium-plus-small'}
            color={'mine-shaft'}
            additionalClass={'text--light pe__challenge-header'}
          >
            {title}
          </Heading>
        </div>
        <div className={'pe__content'}>
          <Heading
            type={2}
            size={'big-spreaded'}
            color={'white'}
            additionalClass={'pe__challenge-header--big'}
          >
            {subtitle}
          </Heading>
        </div>
      </Section>
    );
  }
}

Challenge.defaultProps = {
  subtitle: '',
  title: '',
};

Challenge.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Challenge;
