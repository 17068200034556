import React, { Component, Fragment } from 'react';
import { Section, Heading, Paragraph, BackgroundImage } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Product.scss';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.pe__product-box').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = () =>
    data.map((item, index) => {
      return (
        <Fragment key={`part-${index}`}>
          {item.image === null ? (
            <div
              className={classNames('pe__product-box', {
                'pe__product--entered': this.state.entered,
              })}
              key={`pe-product-${item.number}`}
            >
              <Heading type={3} size={'big-spreaded'} color={'mine-shaft'}>
                {item.number}
              </Heading>
              <Heading type={4} size={'medium'} color={'mine-shaft'}>
                {item.title}
              </Heading>
              <Paragraph
                additionalClass={'text--light'}
                size={'default'}
                color={'mine-shaft'}
              >
                {item.description}
              </Paragraph>
            </div>
          ) : (
            <>
              <img
                src={getCloudinaryUrl(
                  `assets/planet-escape/${item.image}`,
                  'f_auto,q_auto',
                )}
                key={item.image}
                className={classNames(
                  'image anim-fade-in-right pe__product-image',
                  {
                    'is-inview': this.state.entered,
                  },
                )}
                alt={'Planet Escape'}
                loading={'lazy'}
              />
              <div className={'pe__product-breaker'} />
            </>
          )}
        </Fragment>
      );
    });

  render() {
    return (
      <Section additionalClass={'pe__product'} spaceless>
        <div className={'pe__product-container'}>
          <BackgroundImage
            asset={'assets/planet-escape/planet-escape-photo-elephant.jpg'}
            alt={'Exotic travel'}
            additionalClass={'pe__product-background'}
          />
          <Heading
            type={2}
            size={'big-spreaded'}
            color={'white'}
            additionalClass={'pe__product-heading'}
          >
            <span>Distant &amp; exotic travels</span>
          </Heading>
          <Paragraph
            additionalClass={'pe__product-paragraph'}
            size={'medium-constant'}
            color={'white'}
          >
            Discovering the world can be exciting
            <br />
            Many directions and inspirations - all in one place
          </Paragraph>
          <div className={'pe__product-boxes'}>{this.renderBoxes()}</div>
        </div>
      </Section>
    );
  }
}

export default Product;
