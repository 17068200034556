import React from 'react';
import EventTitle from '../Title';
import EventInfo from '../Info';
import EventSpeaker from '../Speaker';

import './EventDescription.scss';

const EventDescritpion = ({ eventData }) => {
  return (
    <>
      <EventTitle eventData={eventData} />
      <div className={'event-description'}>
        <EventInfo eventData={eventData} />
        {!eventData.speakerBox && eventData.speakers && (
          <EventSpeaker speakers={eventData.speakers} />
        )}
      </div>
    </>
  );
};
export default EventDescritpion;
