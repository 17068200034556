import './index.scss';
import React from 'react';
import { motion } from 'framer-motion';

// Components
import Shapes from './Shapes';
import Subheading from '../Subheading';
import AnimatedHeading, { headingVariants } from '../AnimatedHeading';

const AnimatedResults = () => {

  return (
    <>
      <motion.div className={'dsgn-hero__animated-results'}>
        <Shapes isVisible={true} />
        <Subheading style={{ color: '#FFF' }} />
        <div className={'dsgn-hero__animated-results-heading'}>
          <AnimatedHeading isVisible={true} color={'#FFF'}>
            <motion.span variants={headingVariants}>Delivers</motion.span>{' '}
            <motion.span variants={headingVariants}>results</motion.span>
          </AnimatedHeading>
        </div>
      </motion.div>
    </>
  );
};

export default AnimatedResults;
