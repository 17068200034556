import React from 'react';

import { pushToDataLayer } from 'utils';
import {
  bannerData,
  bannerImages,
} from 'views/home/subcomponents/Prizes/logic';

import './MainBanner.scss';

const MainBanner = () => {
  const { logo, text, textSecond, link } = bannerData.clutch;

  const handleClick = () => pushToDataLayer(`prizes-b-clutch`);

  return (
    <div className={'prizes-banner'}>
      <div className={'prizes-banner__wrapper'}>
        <img
          src={bannerImages[logo]}
          alt={`${logo} company logo`}
          className={'prizes-banner__logo'}
        />
        <div className={'prizes-banner__copy'}>
          <p className={'prizes-banner__text'}>{text}</p>
          <p className={'prizes-banner__text--paragraph'}>{textSecond}</p>
        </div>
        <a
          href={link.url}
          rel={'nofollow'}
          className={
            'button button--primary button--yellow prizes-banner__link'
          }
          onClick={handleClick}
        >
          {link.text}
        </a>
      </div>
    </div>
  );
};

export default MainBanner;
