import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { classNames } from 'utils';

import './Header.scss';

export const Header = ({ title, description, type, direction, theme, longDescription, showTitleLabel }) => {
  const HeadingTag = `h${type}`;

  return (
    <div className={classNames(`who-we-are-header who-we-are-header--${direction} who-we-are-header--${theme}`, {
      'who-we-are-header--long-description': longDescription,
      'who-we-are-header--no-description': !description
    })}>
      <HeadingTag
        className={classNames('who-we-are-header__heading',
          {'who-we-are-header__heading--no-margin': !description
        })}
        type={type}
      >
        {typeof title === 'string' ? ReactHtmlParser(title) : title}
        {showTitleLabel && (
          <span className={'who-we-are-header__label'}>
            Stay tuned!
          </span>
        )}
      </HeadingTag>
      {description && (
        <p className={'who-we-are-header__description'}>
          {description}
        </p>
      )}
    </div>
  );
}

Header.defaultProps = {
  title: 'Title',
  description: null,
  type: 2,
  direction: 'horizontal',
  theme: 'dark',
  longDescription: false,
  showTitleLabel: false,
};

Header.propTypes = {
  title: PropTypes.string || PropTypes.node,
  description: PropTypes.string,
  type: PropTypes.string,
  direction: PropTypes.string,
  theme: PropTypes.string,
  longDescription: PropTypes.bool,
  showTitleLabel: PropTypes.bool,
};
