import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { IconSVG } from 'components';
import { MenuSection } from './subcomponents';

import './MegaMenu.scss';

const MegaMenu = ({ closeMenu, setMegaMenuHover, isBannerActive }) => (
  <div className={classNames('mega-menu', isBannerActive ? 'mega-menu__with-banner' : '')}>
    <button
      className={'mega-menu__back-drop'}
      label={'close mega-menu on mouse enter'}
    />
    <div
      onMouseEnter={() => {setMegaMenuHover(true)}}
      onMouseLeave={() => {setMegaMenuHover(false)}}
      className={'mega-menu__container  w-container'}
    >
      <button
        className={'mega-menu__icon hide-desktop'}
        onClick={closeMenu}
        label={'close mega-menu'}
      >
        <IconSVG name={'arrow-left'} size={'medium'} color={'white'} />
      </button>
      <MenuSection type={'services'} columns={2}/>
      <MenuSection type={'scaling'} />
      <button className={'mega-menu__close hide-mobile'}
              label={'close mega-menu'}
              onClick={closeMenu}>
        &#10005;
      </button>
    </div>
  </div>
);

MegaMenu.defaultProps = {
  setMegaMenuHover: () => {},
  closeMenu: () => {},
  isBannerActive: false,
};

MegaMenu.propTypes = {
  setMegaMenuHover: PropTypes.func,
  closeMenu: PropTypes.func,
  isBannerActive: PropTypes.bool,
};

export default MegaMenu;
