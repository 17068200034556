export const images = [
  {
    id: 1,
    url: 'referral-program-regulations/referral-program-regulamin-1.png',
  },
  {
    id: 2,
    url: 'referral-program-regulations/referral-program-regulamin-2.png',
  },
  {
    id: 3,
    url: 'referral-program-regulations/referral-program-regulamin-3.png',
  },
  {
    id: 4,
    url: 'referral-program-regulations/referral-program-regulamin-4.png',
  },
  {
    id: 5,
    url: 'referral-program-regulations/referral-program-regulamin-5.png',
  },
  {
    id: 6,
    url: 'referral-program-regulations/referral-program-regulamin-6.png',
  },
  {
    id: 7,
    url: 'referral-program-regulations/referral-program-regulamin-7.png',
  },
  {
    id: 8,
    url: 'referral-program-regulations/referral-program-regulamin-8.png',
  },
  {
    id: 9,
    url: 'referral-program-regulations/referral-program-regulamin-9.png',
  },
  {
    id: 10,
    url: 'referral-program-regulations/referral-program-regulamin-10.png',
  },
  {
    id: 11,
    url: 'referral-program-regulations/referral-program-regulamin-11.png',
  },
  {
    id: 12,
    url: 'referral-program-regulations/referral-program-regulamin-12.png',
  },
  {
    id: 13,
    url: 'referral-program-regulations/referral-program-regulamin-13.png',
  },
  {
    id: 14,
    url: 'referral-program-regulations/referral-program-regulamin-14.png',
  },
];
