export const sliderData = [
  {
    background: 'career/boldare-tech/tech-slider-4.jpg',
    content: 'Tech Lead - challenges and opportunities',
    duration: '43:34',
    link: 'https://youtu.be/6n21hpdI3q0',
  },
  {
    background: 'career/boldare-tech/tech-slider-3.jpg',
    content: 'Differences between Product and Graphic Designer',
    duration: '44:37',
    link: 'https://youtu.be/4GhWArlPzIo',
  },
  {
    background: 'career/boldare-tech/tech-slider-2.jpg',
    content: 'What does a Product Strategist do?',
    duration: '36:26',
    link: 'https://youtu.be/dwpzh1jq20o',
  },
  {
    background: 'career/boldare-tech/tech-slider-1.jpg',
    content: 'JavaScript Developer in 2022',
    duration: '30:12',
    link: 'https://youtu.be/OPs1PjaI6G0',
  },
];
