export const data = [
  {
    coverImage: 'services/prototyping/cryo.jpg',
    clientLogo: 'logos/cryo.png',
    title: 'Ideating a booking platform',
    description:
      'Cryo is one of the world’s leading cryotherapy equipment producers. They came up with an idea to build a web app that would help users to search nearby cryotherapy facilities and book appointments. The Boldare team prototyped the key features: a searchable catalog of services, a review and rating system, and a booking process. As a result of testing and the data gathered, Cryo decided to take a different path to that initially intended, saving considerable budget and time.',
    url: '/',
  },
  {
    coverImage: 'services/prototyping/blabla.jpg',
    clientLogo: 'logos/blablacar-color.png',
    title: 'Scaling a carpooling app',
    description:
      'BlaBlaCar, a carpooling platform operating in 27 countries worldwide was looking to scale their web and mobile app with new and improved features. Implementing a prototyping process, the Boldare team built 10 new digital products for BlaBlaCar, scaling their mature app without any disturbances for the users. The features enhanced the customer experience helping the company grow into a market leader and increasing their membership from 24 to 35 million users.',
    url: '/',
  },
  {
    coverImage: 'services/prototyping/case-study-boldare-web-app.jpg',
    clientLogo: 'logos/boldare.png',
    title: 'Moodboards for design testing',
    description:
      'When the Boldare co-founders started the work on boldare.com they knew the value of digital prototyping and early testing with users. The first opportunity was to present website moodboards (design inspirations) for testing with the target group, using a survey and a focus group to measure associations with the suggested brand identity and values. Apart from identifying the winning mood board, the test also pointed up a number of potential design improvements for the future.',
    url: '/',
  },
];
