export const data = [
  {
    title: 'Predictions for the Future of Work in 2022',
    link:
      'https://techround.co.uk/guides/predictions-for-the-future-of-work-in-2022-part-2/',
  },
  {
    title: 'Boldare: "Bei uns arbeiten alle, wie es ihnen gefällt"',
    link:
      'https://newworkstories.com/boldare-bei-uns-arbeiten-alle-wie-es-ihnen-gefaellt/',
  },
  {
    title: 'Nowa praca, nowa normalność - nie tylko w sektorze IT',
    link:
      'https://www.forbes.pl/opinie/praca-zdalna-nowy-model-biznesowy-kreuje-rynek-pracy-nie-tylko-w-branzy-it/jx2759v',
  },
  {
    title:
      'Boldare Launches CTO as a Service with Unique Differentiators that Accelerate Growth and Enrich User Experience',
    link:
      'https://finance.yahoo.com/news/boldare-launches-cto-unique-differentiators-140000536.html',
  },
  {
    title:
      'Preparing For The Future Of Work: Anna Zarudzka of Boldare On The Top Five Trends To Watch In The Future Of Work',
    link:
      'https://medium.com/authority-magazine/preparing-for-the-future-of-work-anna-zarudzka-of-boldare-on-the-top-five-trends-to-watch-in-the-7ef6fc10656',
  },
  {
    title: 'Holakracja. Nadchodzi świat pracy bez menedżerów',
    link:
      'https://www.forbes.pl/opinie/holakracja-nowy-sposob-zarzadzania-zespolem-po-pandemii/r67e5vv?gid=f83f29ce5fa087a92194ca36d2ba0728',
  },
  {
    title: 'Dlaczego ludzie odchodzą z pracy? Jest jeden główny powód',
    link:
      'https://businessinsider-com-pl.cdn.ampproject.org/c/s/businessinsider.com.pl/twoje-pieniadze/praca/dlaczego-ludzie-odchodza-z-pracy-powody-zmian-na-rynku-pracy/hdh36gn.amp',
  },
  {
    title:
      '“Die beste Karriereentscheidung? Ein Business-Hippie zu sein” – Anna Zarudzka von Boldare beantwortet den GQ-Business-Fragebogen',
    link:
      'https://www.gq-magazin.de/lifestyle/artikel/gquestionnaire-anna-zarudzka-von-boldare-beantwortet-den-gq-business-fragebogen/',
  },
  {
    title:
      'Jaroslaw Kroczek von Boldare: "Gute Bezahlung reicht heute nicht mehr aus"',
    link:
      'https://www.golem.de/news/jaroslaw-kroczek-von-boldare-als-kind-sass-er-lieber-am-pc-als-draussen-zu-spielen-2106-156965.html',
  },
  {
    title:
      'Silicon Polska – der Tech-Hotspot in der europäischen Nachbarschaft',
    link: 'https://t3n.de/news/silicon-polska-tech-hotspot-polen-1369122/',
  },
  {
    title: 'Niemieckie media pod wrażeniem boomu technologicznego w Polsce',
    link:
      'https://www.pap.pl/aktualnosci/news%2C847935%2Cniemieckie-media-pod-wrazeniem-boomu-technologicznego-w-polsce.html',
  },
  {
    title:
      'Telling A Tale Of Courage, Digital Transformation And Design For Boldare',
    link:
      'https://www.laika.berlin/en/blog/new-client-boldare-tell-a-tale-of-courage-digital-transformation-and-design/',
  },
  {
    title:
      '„Slack und Asana sind die ideale Combo“ – Anna Zarudzka von Boldare',
    link: 'https://t3n.de/news/anna-zarudzka-boldare-1343157/',
  },
  {
    title: 'W+M-Ratgeber: 7 Praxistipps für erfolgreiche Teamarbeit im Home',
    link:
      'https://wirtschaft-markt.de/2020/12/11/wm-ratgeber-7-praxistipps-fuer-erfolgreiche-teamarbeit-im-home/',
  },
];
