import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { scroller } from 'react-scroll';

import {
  BackgroundImage,
  Button,
  Heading,
  Section,
  SectionHeader,
} from 'components';
import ArrowBlack from 'assets/images/contact-de/arrow-black.svg';
import { getCloudinaryUrl } from 'config/helpers';

import './Banner.scss';

const Banner = () => (
  <Section
    id={'contact-nl-banner'}
    additionalClass={
      'contact-nl-banner__section contact-nl-banner--position-relative'
    }
    side={'right'}
  >
    <BackgroundImage
      asset={'contact-page/contact-banner-bg-NL.png'}
      alt={'Netherlands City'}
      additionalClass={'banner__img background-img--cloudinary'}
    />
    <Row className={'no-gap contact-nl-banner--position-relative'}>
      <Col sm={12} md={9}>
        <SectionHeader
          size={'extra-large'}
          color={'white'}
          descriptionColor={'black'}
          lineColor={'white'}
          buttonColor={'black'}
          fixed
          noLine
          additionalClass={'contact-nl-banner__text-header'}
        >
          Wij spreken
          <br />
          Nederlands!
        </SectionHeader>
        <Heading
          type={2}
          size={'medium-constant'}
          color={'white'}
          additionalClass={'contact-nl-banner__text-header-secondary'}
        >
          Wij zijn Boldare, creators van en consultants voor digitale
          producten. Wij zorgen ervoor dat jouw volgende app fantastisch werkt
          en jouw ROI verbetert. Onze passie en kracht is dat we geloven in het
          delen van kennis en ideeën, zelfontwikkeling en het teruggeven aan de
          gemeenschap.
        </Heading>
      </Col>
    </Row>
    <div className={'contact-nl-banner__contact-btn-background'}>
      <div className={'contact-nl-banner__contact-btn-wrapper'}>
        <div>
          <Button
            onClick={() => {
              scroller.scrollTo('form', {
                spy: false,
                smooth: true,
                duration: 700,
                offset: -100,
              });
            }}
            color={'white'}
            id={'contact-now-shortcut'}
            ariaName={'Contact button'}
            additionalClass={'contact-button'}
            hover
          >
            Neem contact op
          </Button>
        </div>
        <div>
          <div className={'contact-nl-banner__contact-btn-background__line'} />
        </div>
        <div>
          <div className={'contact-nl-banner__contact-btn-background__arrow'}>
            <img
              src={ArrowBlack}
              className={'image'}
              alt={'Arrow to bottom'}
              loading={'lazy'}
            />
          </div>
        </div>
      </div>
    </div>
    <div className={'contact-nl-banner__images-wrapper'}>
      <div>
        <div className={'contact-nl-banner__windmill-and-hand'}>
          <img
            src={getCloudinaryUrl(
              'contact-page/contact-banner-image-NL.png',
              'v1666608883',
            )}
            className={'image'}
            alt={'Windmill with hand'}
            loading={'lazy'}
          />
        </div>
      </div>
    </div>
  </Section>
);
export default Banner;
