import React from 'react';

import { BoxArticle } from 'components';
import { Header, Section } from '../../../../components/DS';

import './RelatedArticles.scss';

const SectionHeader = ({ title }) => (
  <Header
    title={title}
    theme={'light'}
  />
);

export const RelatedArticles = ({
  relatedArticles, allArticles
}) => {

  const renderRelatedArticles = () => (
    relatedArticles.map((item, index) => {
      const articleData =
        allArticles &&
        allArticles.edges.find(
          edge => edge.node.frontmatter.title === item.title,
        );

      return (
        articleData &&
        articleData[0] !== 'undefined' && (
          <BoxArticle
            id={`keyword-related-${index}`}
            post={articleData}
          />
        )
      );
    })
  );

  return (
    <Section
      heading={<SectionHeader title={'Learn more from our blog articles and case studies'} />}
      color={'mine-shaft'}
    >
      <div className={'keyword-related__row'}>
        {renderRelatedArticles()}
      </div>
    </Section>
  );
};
