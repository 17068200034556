import React from 'react';
import PropTypes from 'prop-types';
import { Section, BackgroundImage } from 'components';
import './SubpageHero.scss';

const SubpageHero = ({ additionalClass, background, children }) => (
  <Section additionalClass={`subpage-hero ${additionalClass}`}>
    <BackgroundImage
      src={background}
      additionalClass={'subpage-hero__background'}
    />
    <div className={'subpage-hero__content'}>{children}</div>
  </Section>
);

SubpageHero.defaultProps = {
  additionalClass: '',
  children: null,
};

SubpageHero.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  background: PropTypes.string.isRequired,
};

export default SubpageHero;
