export const topics = [
  'My web or mobile application',
  'Types of Services Boldare offers',
  'Hiring a Development Team',
  'Technologies and programming languages Boldare offers',
  'Cost of services',
  'Other',
];

export const steps = ['Select topic', 'Contact details', 'Pick the date'];
