import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Section, SectionHeader } from 'components';

import './Cities.scss';

const Cities = () => (
  <Section
    additionalClass={'contact-nl-cities__section'}
    id={'connect-nl-cities'}
    spacedTop
  >
    <Row center={'xs'} className={'contact-nl-cities--position-relative'}>
      <Col xs={12} className={'contact-nl-cities__content-wrapper-col'}>
        <div className={'contact-nl-cities__text-wrapper'}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            fixed
            noLine
            additionalClass={'contact-nl-cities__text-header'}
          >
            <span>Amsterdam of Rotterdam?</span>
            <br />
            <span>We zijn er voor je</span>
            <br />
          </SectionHeader>
          <Heading
            type={2}
            size={'medium-constant'}
            color={'black'}
            additionalClass={'contact-nl-cities__text-header-secondary'}
          >
            We leren je graag persoonlijk kennen op een plek die voor jou
            uitkomt. Als je eerst liever online contact wil is dat geen
            probleem - we werken ook gewoon hybride of online.
          </Heading>
        </div>
        <div className={'contact-nl-cities__images-wrapper'}>
          <div className={'contact-nl-cities__house-img-wrapper'}>
            <img
              src={getCloudinaryUrl(
                'contact-page/contact-cities-image-NL.png',
                'v1666618999',
              )}
              className={'image'}
              alt={'City'}
              loading={'lazy'}
            />
          </div>
        </div>
        <img
          src={getCloudinaryUrl(
            'contact-page/contact-cities-bg-NL.png',
            'v1645116164',
          )}
          className={'image'}
          alt={'City'}
          loading={'lazy'}
        />
      </Col>
      <div className={'contact-nl-cities__line'} />
    </Row>
  </Section>
);

export default Cities;
