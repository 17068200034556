import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import './Badges.scss';

export default function Badges() {
  return (
    <div className={'ses-background'}>
      <div className={'ses-background__rounded'} />
      <div className={'ses-badges'}>
        <div className={'ses-badges__logos'}>
          <img
            src={getCloudinaryUrl(
              `service-standards/ses-roles-badge.png`,
              'f_auto,q_auto',
            )}
            alt={'Service Standards Roles'}
          />
          <img
            src={getCloudinaryUrl(
              `service-standards/ses-documents-badge.png`,
              'f_auto,q_auto',
            )}
            alt={'Service Standards Documents'}
          />
          <img
            src={getCloudinaryUrl(
              `service-standards/ses-meetings-badge.png`,
              'f_auto,q_auto',
            )}
            alt={'Service Standards Meetings'}
          />
        </div>
      </div>
    </div>
  );
}
