export default {
  STANDARD: 'STANDARD',
  CASE_STUDY: 'CASE STUDY',
  BLOG: 'ARTICLE',
  TESTIMONIAL: 'TESTIMONIAL',
  HASHTAG: 'HASHTAG',
  IMAGE_CONTENT: 'IMAGE_CONTENT',
  IMAGE_COVER: 'IMAGE_COVER',
  TEXT_CONTENT: 'TEXT_CONTENT',
  DISCOVER: 'DISCOVER',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  OFFER: 'OFFER',
  HOVER: 'HOVER',
  REVIEW: 'REVIEW',
  BRIEF: 'BRIEF',
};
