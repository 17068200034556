import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import config from '../../../meta/config';

function isNotEmptyString(str) {
  return typeof str === 'string' && str.length > 0;
}

function getCannonicalURL(canonical, postURL) {
  const canonicalURL =
    typeof canonical === 'string' && canonical?.startsWith('/')
      ? `${config.siteUrl}${canonical}`
      : canonical;

  return canonicalURL || `${postURL}/`;
}

const SEO = ({
  canonical,
  cloudinary,
  cover,
  description,
  metaTitle,
  section,
  slug,
  title,
  url,
  isAmp,
  ampUrl,
  isDefault,
}) => {
  const postURL =
    url ||
    (slug && section
      ? `${config.siteUrl}/${section}/${slug}`
      : config.siteUrl);
  const realPrefix = config.pathPrefix !== '/' ? config.pathPrefix : '';
  const image = cloudinary ? cover : `${config.siteUrl}${realPrefix}${cover}`;
  const blogURL = `${config.siteUrl}${config.pathPrefix}`;

  const canonicalUrl = getCannonicalURL(canonical, postURL);

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt || '',
    },
    // [
    //   {
    //     '@context': 'http://schema.org',
    //     '@type': 'BreadcrumbList',
    //     itemListElement: [
    //       {
    //         '@type': 'ListItem',
    //         position: 1,
    //         item: {
    //           '@id': postURL,
    //           name: title,
    //           image,
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     '@context': 'http://schema.org',
    //     '@type': 'BlogPosting',
    //     url: blogURL,
    //     name: title,
    //     alternateName: config.siteTitleAlt || '',
    //     headline: title,
    //     image: {
    //       '@type': 'ImageObject',
    //       url: image,
    //     },
    //     description,
    //   },
    // ],
  ];

  return (
    <Helmet
      titleTemplate={
        isDefault
          ? 'Boldare - digital product creators and consultants'
          : '%s | Boldare - Digital Product Development & Design Company'
      }
      defaultTitle={'Boldare'}
    >
      {/* General tags */}
      <title>{title || metaTitle}</title>
      <meta name={'description'} content={description} />
      <meta name={'image'} content={image} />
      {isNotEmptyString(canonicalUrl) && (
        <link rel={'alternate'} hrefLang={'en-us'} href={`${canonicalUrl}`} />
      )}
      {isNotEmptyString(canonicalUrl) && (
        <link rel={'alternate'} hrefLang={'en-gb'} href={`${canonicalUrl}`} />
      )}
      {canonicalUrl && <link rel={'canonical'} href={`${canonicalUrl}`} />}
      {ampUrl && <link rel={'amphtml'} href={ampUrl} />}
      {/* Schema.org tags */}
      {!isAmp && (
        <script type={'application/ld+json'}>
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      )}

      {/* OpenGraph tags */}
      <meta property={'og:url'} content={postURL} />
      <meta property={'og:type'} content={'article'} />
      <meta property={'og:title'} content={title} />
      <meta property={'og:description'} content={description} />
      <meta property={'og:image'} content={image} />
      <meta property={'fb:app_id'} content={config.siteFBAppID || ''} />

      {/* Twitter Card tags */}
      <meta name={'twitter:card'} content={'summary_large_image'} />
      <meta name={'twitter:creator'} content={config.userTwitter || ''} />
      <meta name={'twitter:title'} content={title} />
      <meta name={'twitter:description'} content={description} />
      <meta name={'twitter:image'} content={image} />
    </Helmet>
  );
};

SEO.propTypes = {
  canonical: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cloudinary: PropTypes.bool,
  cover: PropTypes.string,
  description: PropTypes.string,
  metaTitle: PropTypes.string,
  section: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  isAmp: PropTypes.bool,
  ampUrl: PropTypes.string,
  isDefault: PropTypes.bool,
};

SEO.defaultProps = {
  canonical: '',
  cloudinary: false,
  cover: config.siteCover,
  description: config.siteDescription,
  metaTitle: config.siteTitle,
  section: '',
  slug: '',
  title: '',
  url: '',
  isAmp: false,
  ampUrl: '',
  isDefault: false,
};

export default SEO;
