import React from 'react';
import { graphql } from 'gatsby';
import MVP from 'views/mvp';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages.mvp;
const { navColors } = page;
const url = `${config.siteUrl}/services/mvp-development/`;
const canonical = `${url}`;

const MVPPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <MVP allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query MVPQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "MVP development - what, why & how"
              "MVP development - everything you want to know, but are afraid to ask"
              "Minimum Viable Products? It’s all about the team"
              "Product Vision Workshops – seeing clearly from the beginning"
              "What is Design Thinking"
              "Product-Market Fit – teamworking for results"
              "How Prototyping Can Bring Your Business Ideas to Life"
              "Choosing a digital product development partner - the political and economic issues"
              "How much should you pay for a digital product development?"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default MVPPage;
