/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { FooterSection } from 'views/remoteWork/components';
import {
  NavBarProducts,
  HeroSection,
  EtudesSection,
  JoinSection,
  PortfolioSection,
  IntroSection,
  ActionsSection,
  TestimonialsSection,
} from './subcomponents';
import './index.scss';

const BoldareProducts = props => {
  const { offers: jobs } = props || {};

  return (
    <div className={'boldare-products-landing single-page single-page--hero'}>
      <NavBarProducts />
      <main>
        <HeroSection />
        <IntroSection />
        <EtudesSection />
        <ActionsSection />
        <PortfolioSection />
        <TestimonialsSection />
        <JoinSection />
        <FooterSection jobs={jobs} />
      </main>
    </div>
  );
};

BoldareProducts.propTypes = {};

export default BoldareProducts;
