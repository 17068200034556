import React from 'react';

import { enableAnimations } from 'config/helpers';
import {
  Banner,
  Cities,
  ContactFormNl,
  SuperHeroes,
  WhoAreWe,
  ContactNlAwards,
} from 'views/contactNl/subcomponents';

import './ContactNl.scss';

export const ContactNl = () => {
  React.useEffect(() => {
    enableAnimations();
  });

  return (
    <div className={'single-page single-page--hero contact-nl-page'}>
      <Banner />
      <SuperHeroes />
      <ContactFormNl />
      <Cities />
      <WhoAreWe />
      <ContactNlAwards />
    </div>
  );
};
