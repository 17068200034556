import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { SliderArrow } from 'components';
import { clientsSlideshowLogo } from './index';
import './ClientsSlideshow.scss';

const clientsSettings = {
  cssEase: 'linear',
  variableWidth: true,
  arrows: true,
  nextArrow: <SliderArrow id={'slider-next'} />,
  prevArrow: <SliderArrow id={'slider-prev'} left />,
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: false,
  speed: 4000,
  infinite: true,
  autoplay: false,
};

const ClientsSlideshow = () => {
  const colorType = 'colorful';
  const imgClass = classNames({
    colorful: colorType === 'colorful',
  });

  return (
    <div className={'clients-slideshow'}>
      <div className={'clients-slideshow__carousel-container'}>
        <div className={'clients-slideshow__carousel-heading'}>
          <p>Clients who trust us:</p>
        </div>
        <div className={'clients-slideshow__logo-carousel'}>
          <Slider {...clientsSettings}>
            {Object.entries(clientsSlideshowLogo).map(([key, value]) => (
              <img key={key} src={value} alt={key} className={imgClass} />
            ))}
          </Slider>
          <a
            href={'/work/'}
            className={'clients-slideshow__button-case-studies'}
          >
            See our case studies
          </a>
        </div>
      </div>
    </div>
  );
};

export default ClientsSlideshow;
