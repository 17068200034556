import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

import { pushToDataLayer } from 'utils';
import { filters } from 'views/work/subcomponents/CaseStudies/utils';

import { FloatingButton, FiltersList, HideButton } from './subcomponents';
import './FiltersBoxB.scss';

const FiltersBox = ({
  onFilterSelect,
  navigatePage,
  activeFilter,
  isDesktop,
}) => {
  const [isFilterBoxShown, setIsFilterBoxShown] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(null);

  const handleClick = (event, label, filter) => {
    if (filter && isDesktop) {
      pushToDataLayer(
        `case-studies-b-${filter.replace(/\s+/g, '-').toLowerCase()}`,
      );
    }

    scroller.scrollTo('filters-box-b', {
      spy: false,
      smooth: true,
      duration: 500,
      offset: -145,
    });

    onFilterSelect(event, label, filter, navigatePage);
    setIsFilterBoxShown(false);
  };

  const filtersList = filters.map(item => (
    <FiltersList
      key={item}
      activeFilter={activeFilter}
      isDesktop={isDesktop}
      label={item.replace(/\s/g, '')}
      title={item}
      isOpen={openedMenu === item.replace(/\s/g, '')}
      setOpenedMenu={setOpenedMenu}
      handleClick={handleClick}
    />
  ));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenedMenu(filters[0]);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div id={'filters-box-b'} className={'filters-box-b'}>
      <FloatingButton onClick={() => setIsFilterBoxShown(true)} />
      {isFilterBoxShown && (
        <div className={'filters-box-b__mobile-container hide-desktop'}>
          <HideButton handleClick={() => setIsFilterBoxShown(false)} />
          {filtersList}
        </div>
      )}
      <div className={'filters-box-b__desktop-container'}>{filtersList}</div>
    </div>
  );
};

FiltersBox.defaultProps = {
  onFilterSelect: () => {},
  navigatePage: () => {},
  activeFilter: null,
  isDesktop: false,
};

FiltersBox.propTypes = {
  onFilterSelect: PropTypes.func,
  navigatePage: PropTypes.func,
  activeFilter: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export default FiltersBox;
