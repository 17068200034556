import React from 'react';

import {
  Paragraph,
  DottedList,
} from 'components';
import { whoWeAre } from 'views/whoWeAre/utils';
import { Arrow, Header, Planet, Section } from '../../../../components/DS';

import './Services.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'vertical'}
    theme={'light'}
  />
);

const { services: { title, description, serviceTypes } } = whoWeAre;

export const Services = () => (
  <Section
    heading={<SectionHeader title={title} description={description} />}
    color={'mine-shaft'}
    noOverflow
  >
    <div className={'who-we-are-services__planet-container'}>
      <Planet
        theme={'light'}
      />
    </div>
    <div className={'who-we-are-services__content'}>
      {serviceTypes.map(({ name, items }) => (
        <div className={'who-we-are-services__box'}>
          <div className={'who-we-are-services__box-content'}>
            <div className={'who-we-are-services__box-content-img'}>
              <Arrow
                fill={'#F2DA3A'}
              />
            </div>
            <Paragraph
              size={'medium-plus-small'}
              additionalClass={`text--bold`}
            >
              {name}
              <div className={'who-we-are-services__list'}>
                <DottedList
                  itemsList={items}
                  theme={'light'}
                />
              </div>
            </Paragraph>
          </div>
        </div>
      ))}
    </div>
  </Section>
);
