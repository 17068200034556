export const data = [
  {
    title: 'From hard work and good design, a long-term collaboration emerged',
    text:
      'We threw away “the old” and brought you everything new, built from scratch and based on our careful process.',
  },
  {
    title: 'Designing the new website from scratch',
    text:
      'Another one of our challenges was to fix the user experience by creating a completely new information architecture structure.',
  },
  {
    title:
      'User flow and user experience had to be changed for better outcomes',
    text:
      'We changed the right bits and pieces to create an easier - and therefore more satisfying - user experience.',
  },
];
