import './index.scss';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const wrapperVariants = {
  initial: {
    x: 600,
  },
  enter: {
    x: 0,
    transition: { staggerChildren: 0.16, duration: 1.5, ease: 'circOut' },
  },
  exit: {
    x: -200,
    transition: { staggerChildren: 0.16, duration: 1.5, ease: 'circIn' },
  },
};

export const headingVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 0.3, ease: 'linear' } },
  exit: { opacity: 0, transition: { duration: 0.3, ease: 'linear' } },
};

const AnimatedHeading = ({ color, isVisible, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.h2
          exit={'exit'}
          style={{
            color,
          }}
          animate={'enter'}
          initial={'initial'}
          variants={wrapperVariants}
          className={'dsgn-hero__animated-heading'}
        >
          {children}
        </motion.h2>
      )}
    </AnimatePresence>
  );
};

export default AnimatedHeading;
