import React from 'react';

import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button, Link } from 'components';
import companyData from '../../../../../meta/companyData';

import './Banner.scss';

const Banner = () => (
  <SubpageBanner
    backgroundColor={'white'}
    image={getCloudinaryUrl(
      '/gatsby-cloudinary/Boldare-office-stairs.png',
      'f_auto,q_auto,w_1300',
    )}
    contentBackground={'white'}
    additionalClass={'newsroom__banner'}
    alt={'Boldare Newsroom'}
    imgLoading={'eager'}
  >
    <SectionHeader
      size={'extra-large'}
      color={'mine-shaft'}
      lineColor={'yellow'}
      description={`Boldare is an Agile-powered company with nearly two decades of international experience in digital product development and consulting. The company helps clients redefine their industries by building with them the products their users want, in line with the company's Service Standards framework. Starting in 2004, Boldare now has ${companyData.employees}+ talents spread across Europe. They have built over ${companyData.products} digital products for clients around the globe – Western and North Europe, the United Kingdom, North America, the Middle East, Southeast Asia, and Australia.`}
      descriptionColor={'mine-shaft'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Boldare Newsroom
    </SectionHeader>
  </SubpageBanner>
);

export default Banner;
