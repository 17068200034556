import React, { Component } from 'react';
import ReferalProgramRegulations from 'views/referralProgramRegulations';
import Layout from 'components/layout';
import { FooterMini, NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

class ReferralProgramRegulationsPage extends Component {
  render() {
    const page = pages['referral-program-regulations'];
    const { navColors } = page;
    const url = `${config.siteUrl}/`;
    const canonical = `${url}/program-polecen-potencjalnych-klientow-regulamin`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <ReferalProgramRegulations />
        <FooterMini />
      </Layout>
    );
  }
}

export default ReferralProgramRegulationsPage;
