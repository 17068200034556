import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getQueryParam } from 'config/helpers';
import Logo from 'assets/images/logo-menu.svg';
import Xsolve from 'assets/images/logos/xsolve-logo.svg';
import Chilid from 'assets/images/logos/chilid-logo-simple.svg';
import { Box, Button, Link, Paragraph } from '..';
import './PopupRedirect.scss';

class PopupRedirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: '',
    };
  }

  componentDidMount() {
    this.checkRedirect();
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.popupNode) {
      if (this.popupNode.contains(e.target)) {
        return;
      }
      this.setState({ redirect: '' });
    }
  };

  checkRedirect() {
    this.setState({
      redirect: getQueryParam('from'),
    });
  }

  render() {
    const { redirect } = this.state;
    const { homepage, message } = this.props;

    const BUTTON_MESSAGE = {
      'job-offer': 'Ok, show me the offer',
      article: 'Ok, show me the article',
      blog: 'Ok, show me Blog',
      career: 'Ok, show me Career',
      work: 'Ok, show me Work',
      privacy: 'Ok, show me Privacy Policy',
      contact: 'Ok, show me Contact',
      page: 'Ok, show me the page',
    };

    if (!redirect) {
      return null;
    }

    return (
      <div className={'popup-redirect__container'}>
        <Box
          backgroundColor={'yellow'}
          additionalClass={'popup-redirect__box'}
        >
          <div
            ref={node => (this.popupNode = node)}
            className={'popup-redirect__content'}
            id={'popup-close-bg'}
          >
            <span
              className={'popup-redirect__close'}
              id={'popup-close-cross'}
              onClick={() => {
                this.setState({ redirect: '' });
              }}
            >
              &#10005;
            </span>
            {!homepage ? (
              <>
                <Paragraph
                  additionalClass={'popup-redirect__logos text--light'}
                  color={'mine-shaft'}
                >
                  {redirect === 'xsolve' ? (
                    <img
                      src={Xsolve}
                      className={
                        'image popup-redirect__logo popup-redirect__logo--xsolve'
                      }
                      alt={''}
                      loading={'lazy'}
                    />
                  ) : (
                    <img
                      src={Chilid}
                      className={
                        'image popup-redirect__logo popup-redirect__logo--chilid'
                      }
                      alt={''}
                      loading={'lazy'}
                    />
                  )}
                  {' is now '}
                  <img
                    src={Logo}
                    className={
                      'image popup-redirect__logo popup-redirect__logo--bldr'
                    }
                    alt={''}
                    loading={'lazy'}
                  />
                </Paragraph>
                <Paragraph
                  additionalClass={
                    'popup-redirect__info popup-redirect__info--space text--light'
                  }
                  color={'mine-shaft'}
                >
                  {"That's why we've redirected you to "}
                  <span className={'popup-redirect__info--bold'}>
                    boldare.com
                  </span>
                </Paragraph>
                <Button
                  color={'transparent'}
                  additionalClass={'popup-redirect__article'}
                  id={'popup-article'}
                  onClick={() => {
                    this.setState({ redirect: '' });
                  }}
                  ariaName={'Popup button'}
                >
                  {message ? BUTTON_MESSAGE[message] : 'Ok, show me the page'}
                </Button>
              </>
            ) : (
              <>
                <div className={'popup-redirect__items'}>
                  <Paragraph
                    additionalClass={'popup-redirect__info text--light'}
                    color={'mine-shaft'}
                  >
                    {"You've been redirected to "}
                    <span className={'popup-redirect__info--bold'}>
                      boldare.com
                    </span>
                    {' because'}
                  </Paragraph>
                  <Paragraph
                    additionalClass={'popup-redirect__logos text--light'}
                    color={'mine-shaft'}
                  >
                    {redirect === 'xsolve' ? (
                      <img
                        src={Xsolve}
                        className={
                          'image popup-redirect__logo popup-redirect__logo--xsolve'
                        }
                        alt={''}
                        loading={'lazy'}
                      />
                    ) : (
                      <img
                        src={Chilid}
                        className={
                          'image popup-redirect__logo popup-redirect__logo--chilid'
                        }
                        alt={''}
                        loading={'lazy'}
                      />
                    )}
                    {' is now '}
                    <img
                      src={Logo}
                      className={
                        'image popup-redirect__logo popup-redirect__logo--bldr'
                      }
                      alt={''}
                      loading={'lazy'}
                    />
                  </Paragraph>
                </div>
                <div className={'popup-redirect__items'}>
                  <Paragraph
                    additionalClass={'popup-redirect__info text--light'}
                    color={'mine-shaft'}
                  >
                    Learn more about:
                  </Paragraph>
                  <div className={'popup-redirect__buttons'}>
                    <a href={'/work/'} id={'popup-work'}>
                      <Button
                        color={'transparent'}
                        ariaName={'Learn more button'}
                      >
                        Our Work
                      </Button>
                    </a>
                    <Link to={'/#services'} id={'popup-services'}>
                      <Button
                        color={'transparent'}
                        ariaName={'Learn more button'}
                      >
                        Our Services
                      </Button>
                    </Link>
                    <Link to={'/#about'} id={'popup-work'}>
                      <Button
                        color={'transparent'}
                        ariaName={'Learn more button'}
                      >
                        About Us
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </div>
    );
  }
}

PopupRedirect.defaultProps = {
  homepage: true,
  message: '',
};

PopupRedirect.propTypes = {
  homepage: PropTypes.bool,
  message: PropTypes.string,
};

export default PopupRedirect;
