import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, SectionHeader, Paragraph, IconSVG } from 'components';
import companyData from '../../../../../meta/companyData';

const HowWeBuild = () => (
  <div className={'dtu__hwb'}>
    <Section color={'pigeon-post-second'} additionalClass={'dtu__hwb-header'}>
      <Row className={'no-gap'}>
        <Col sm={12} md={6}>
          <SectionHeader
            size={'big'}
            lineColor={'red'}
            headingType={2}
            color={'black'}
            fixed
          >
            How we build
            <br /> our teams
          </SectionHeader>
        </Col>
        <Col sm={12} md={6}>
          <Paragraph color={'black'} size={'medium'}>
            We form our teams around three principles: people who have worked
            together in the past, people who have all the skills required to
            complete the job, and people with a mixture of seniority.
          </Paragraph>
        </Col>
      </Row>
    </Section>
    <Section
      color={'pigeon-post-second'}
      additionalClass={'dtu__hwb-content'}
      fullwidth
    >
      <div className={'w-container'}>
        <Row className={'no-gap anim-fade-in-bottom dtu__hwb-list'}>
          <Col sm={12} md={4} className={'dtu__hwb-item'}>
            <IconSVG
              name={'gauge'}
              size={'large'}
              color={'pigeon-post-second'}
            />
            <SectionHeader
              color={'black'}
              size={'tiny-plus'}
              lineColor={'black'}
              type={3}
            >
              People who have worked together in the past:
            </SectionHeader>
            <Paragraph color={'black'} additionalClass={'text--light'}>
              We know how time and money are important to you and that is why
              we put great care into quickly reaching the high performance
              stage of team development. When forming a team for your product,
              we will look for at least two members who have worked together in
              the past so they can help the team skip the forming and storming
              stages and deliver value for your business quicker.
            </Paragraph>
          </Col>
          <Col sm={12} md={4} className={'dtu__hwb-item'}>
            <IconSVG
              name={'rocket'}
              size={'large'}
              color={'pigeon-post-second'}
            />
            <SectionHeader
              color={'black'}
              size={'tiny-plus'}
              lineColor={'black'}
              type={3}
            >
              Skilled, self-organizing people:
            </SectionHeader>
            <Paragraph color={'black'} additionalClass={'text--light'}>
              Across the years we’ve learnt that skilled, self-organizing teams
              are the most efficient product development teams. Our teams are
              not groups of individuals who want to complete their tasks but
              units with complementary soft and hard skills that can deliver
              what is needed to reach your business goals.
            </Paragraph>
          </Col>
          <Col sm={12} md={4} className={'dtu__hwb-item'}>
            <IconSVG
              name={'change'}
              size={'large'}
              color={'pigeon-post-second'}
            />
            <SectionHeader
              size={'tiny-plus'}
              color={'black'}
              lineColor={'black'}
              type={3}
            >
              People with a mixture of seniority:
            </SectionHeader>
            <Paragraph color={'black'} additionalClass={'text--light'}>
              Having delivered over {companyData.products} digital products
              over {companyData.years_experience} years, we know that a stellar
              product needs a team that can innovate, think fresh and at the
              same time provide stable development and quality. Forming a team
              with a balance between experienced team members and juniors
              allows us to release cutting-edge, scalable products that fulfil
              users’ needs.
            </Paragraph>
          </Col>
        </Row>
      </div>
    </Section>
  </div>
);

export default HowWeBuild;
