import React, { useRef, useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import './Banner.scss';
import { Heading, IconSVG, Button, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const scrollToAboutSection = () => {
  scroller.scrollTo('srt-about-tool__heading', {
    spy: false,
    smooth: true,
    duration: 500,
    offset: -100,
  });
};

const Banner = () => {
  const inputEl = useRef(null);
  const [boardName, setBoardName] = useState('');
  useEffect(() => inputEl.current.focus(), []);

  return (
    <section className={'srt-banner'}>
      <div className={'w-container--side-right srt-banner__header'}>
        <h1 className={'srt-banner__logo text--bold'}>
          Sprint Retrospective <span>Tool</span>
        </h1>
      </div>
      <div className={'srt-banner__content'}>
        <div className={'srt-banner__info-wrapper'}>
          <div className={'srt-banner__info w-container--side-right'}>
            <Heading additionalClass={'srt-banner__heading'} type={2}>
              Retro made faster and easier.
              <span className={'text--medium-bold'}>
                {' '}
                Improve your retrospectives with this FREE tool
              </span>
            </Heading>
            <Paragraph
              additionalClass={'srt__small-paragraph'}
              color={'black'}
            >
              Create a new board and start
            </Paragraph>
            <form
              className={'srt-banner__form'}
              onSubmit={e => e.preventDefault()}
            >
              <input
                className={'srt-banner__input'}
                type={'text'}
                placeholder={'Name your board'}
                value={boardName}
                onChange={e => {
                  if (e.target.value.length < 60) {
                    setBoardName(e.target.value);
                  }
                }}
                ref={inputEl}
                autoFocus={'autoFocus'}
              />
              <a
                href={`https://boards.boldare.com/create?boardName=${boardName}`}
                target={'_blank'}
                rel={'noreferrer nofollow'}
                id={'srt__create-board'}
              >
                <Button
                  additionalClass={'srt-banner__button'}
                  color={'yellow'}
                  disabled={!boardName}
                >
                  Create new board now
                </Button>
              </a>
            </form>
          </div>
          <div className={'srt-banner__show-more w-container--side-right'}>
            <button
              className={'srt-banner__show-more-icon pointer'}
              onClick={scrollToAboutSection}
              id={'srt__scroll-down'}
            >
              <IconSVG name={'long-arrow-down'} size={'huge'} />
            </button>
            <span className={'srt-banner__show-more-text'}>Learn more</span>
            <img
              className={'image srt-banner__desktop'}
              src={getCloudinaryUrl(
                'Desktop_Boldaretro-new.png',
                'h_430,q_100',
              )}
              alt={'Sprint Retrospective Tool - Desktop view'}
              loading={'lazy'}
            />
            <img
              className={'image srt-banner__mobile'}
              src={getCloudinaryUrl(
                'Mobile_Boldaretro-new.png',
                'h_320,q_100',
              )}
              alt={'Sprint Retrospective Tool - Mobile view'}
              loading={'lazy'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
