import React from 'react';
import PropTypes from 'prop-types';
import { Link, Heading, Button } from 'components';
import './EngagementBanner.scss';

const EngagementBanner = ({ title, url, buttonText, buttonColor }) => (
  <div className={`engagement-banner`}>
    <Link to={url} additionalClass={'engagement-banner-link'}>
      <div className={'engagement-banner-header'}>
        <Heading type={4} size={'medium'}>
          {title}
        </Heading>
      </div>
      <Button
        id={`engagement-banner-button`}
        color={buttonColor}
        additionalClass={`engagement-banner-button`}
        ariaName={'Download'}
      >
        <span className={'engagement-banner-button-cta'}>{buttonText}</span>
      </Button>
    </Link>
  </div>
);

EngagementBanner.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

export default EngagementBanner;
