import React from 'react';
import PropTypes from 'prop-types';

import ProductDesignDevelopment from 'assets/images/mega-menu/productDesignDevelopment.png';
import ProductConsulting from 'assets/images/mega-menu/productConsulting.png';
import ProductStrategist from 'assets/images/mega-menu/productStrategist.png';
import UxUiDesign from 'assets/images/mega-menu/uxUiDesign.png';
import Prototype from 'assets/images/mega-menu/prototype.png';
import Mvp from 'assets/images/mega-menu/mvp.png';
import Pmf from 'assets/images/mega-menu/pmf.png';
import Scaling from 'assets/images/mega-menu/scaling.png';
import Magnifier from 'assets/images/mega-menu/magnifier.png';

import './MenuIcon.scss';

const Images = {
  development: ProductDesignDevelopment,
  business: ProductConsulting,
  strategy: ProductStrategist,
  design: UxUiDesign,
  prototype: Prototype,
  mvp: Mvp,
  pmf: Pmf,
  scaling: Scaling,
  magnifier: Magnifier,
};

const MenuIcon = ({ type, alt }) => (
  <div className={'menu-icon hide-mobile hide-tablet'}>
    <img src={Images[type]} alt={alt} className={'menu-icon__image'} />
  </div>
);

MenuIcon.defaultProps = {
  type: '',
  alt: 'icon temporarily unavailable',
};

MenuIcon.propTypes = {
  type: PropTypes.string,
  alt: PropTypes.string,
};

export default MenuIcon;
