import { Paragraph, Button, ClientSideComponent } from 'components';
import React, { useState, useEffect, useRef } from 'react';
import { Experiment, Variant } from '@marvelapp/react-ab-test';

import './ServicesPopUp.scss';
import PopupContent from '../PopUpContent';

function ServicesPopUp() {
  const [isOpen, setIsOpen] = useState(true);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const prevIsDisplayedRef = useRef(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    const isSmallScreen = window.matchMedia('(max-width: 1200px)').matches;
    const { scrollY } = window;
    const displayPopup = isSmallScreen
      ? scrollY > 450 && scrollY < 3500
      : scrollY > 500 && scrollY < 4500;

    if (displayPopup !== prevIsDisplayedRef.current) {
      setIsDisplayed(displayPopup);
      prevIsDisplayedRef.current = displayPopup;
    }
  }

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      {isDisplayed && (
        <>
          <Button className={'popup-toggle'} onClick={handleToggle}>
            <Paragraph additionalClass={'popup-toggle__text'}>
              Get your{' '}
              <span
                className={
                  'marker-highlight-small marker-highlight-a marker-black'
                }
              >
                free
              </span>{' '}
              resources
            </Paragraph>
          </Button>
          <ClientSideComponent>
            <Experiment name={'popup-test-ab'}>
              <Variant name={'popup-a'}>
                <div className={'popup__wrapper-dark'}>
                  <PopupContent
                    handleToggle={handleToggle}
                    header={'6 Common'}
                    popupClassId={'popup-services-content-a'}
                    subheader={
                      'external product development issues and pro tips to solve them'
                    }
                    buttonHref={'/resources/product-development-challenges/'}
                    title={'Ease through the difficulties of external'}
                    highlight={'development'}
                    description={`This list will show you how to deal with the most common
                     issues regarding external product development and how to solve them.`}
                    buttonText={'Get your free list now!'}
                    popupServicesClass={'popup-services-a'}
                    state={isOpen}
                  />
                </div>
              </Variant>
              <Variant name={'popup-b'}>
                <div className={'popup__wrapper-light'}>
                  <PopupContent
                    handleToggle={handleToggle}
                    header={'Working with'}
                    popupClassId={'popup-services-content-b'}
                    subheader={
                      'a Product Development Team - step by step guide'
                    }
                    buttonHref={'/resources/product-development-team-work/'}
                    title={'What to look out for when choosing a'}
                    highlight={'Partner'}
                    description={`This step-by-step guide will walk you through from finding the right partner
                      to taking over the product with tips and suggestions to each stage.`}
                    buttonText={'Grab your free guide now!'}
                    popupServicesClass={'popup-services-b'}
                    state={isOpen}
                  />
                </div>
              </Variant>
            </Experiment>
          </ClientSideComponent>
        </>
      )}
    </>
  );
}

export default ServicesPopUp;
