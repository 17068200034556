import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import { WhoWeAre } from 'views/whoWeAre';
import config from '../../../meta/config';

const page = pages['software-development'] || {};
const navColors = page.navColors || 'white';
const url = `${config.siteUrl}`;
const canonical = `${url}`;

const SoftwareDevelopmentPage = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <WhoWeAre />
    </Layout>
  );
};

export default SoftwareDevelopmentPage;
