import React from 'react';
import PropTypes from 'prop-types';

import FilterIcon from 'assets/images/work/filter-icon.svg';
import { pushToDataLayer } from 'utils';

import './FloatingButton.scss';

const FloatingButton = ({ onClick }) => {
  const handleClick = () => {
    pushToDataLayer('case-studies-b-mobile-menu');
    onClick();
  };

  return (
    <div className={'floating-button-b'}>
      <button
        onClick={handleClick}
        aria-label={'Load more filters'}
        className={'floating-button-b__btn'}
      >
        <img
          src={FilterIcon}
          alt={'filter icon'}
          className={'floating-button-b__image'}
        />
        <span className={'floating-button-b__text'}>
          Filter by industry, stage or region
        </span>
      </button>
    </div>
  );
};

FloatingButton.defaultProps = {
  onClick: () => {},
};

FloatingButton.propTypes = {
  onClick: PropTypes.func,
};

export default FloatingButton;
