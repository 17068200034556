import React from 'react'
import PropTypes from 'prop-types';
import { Heading, Paragraph } from 'components'
import { SectionWide } from 'components/DS'
import { getCloudinaryUrl } from 'config/helpers'
import { Col, Row } from 'react-flexbox-grid'

import './SpecialistInfo.scss'

export const SpecialistInfo = ({ data }) => (
  <SectionWide color={'white'}>
    <Row className={'specialist-info'}>
      <Col sm={12} md={6} className={'specialist-info__column'}>
        <div className={'specialist-info__person'}>
          <img
            alt={data?.name}
            src={getCloudinaryUrl(data?.image, 'f_auto,q_100')}
            loading={'Lazy'}
          />
          <div>
            <Paragraph size={'regular'} color={'mine-shaft'} >
              <b>{data?.name}</b>
              <br />
              {data?.position}
            </Paragraph>
          </div>
        </div>
      </Col>
      <Col sm={12} md={6}>
        <Heading color={'mine-shaft'} size={'big'} additionalClass={'specialist-info__heading'}>
          {data?.title}
        </Heading>
        <Paragraph size={'medium'} color={'mine-shaft'} >
          {data?.description}<br /><br />
          {data?.additionalInfo}
        </Paragraph>
      </Col>
    </Row>
  </SectionWide>
);

SpecialistInfo.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    additionalInfo: PropTypes.string.isRequired,
  }).isRequired,
};
