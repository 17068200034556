export const data = [
  {
    name: 'Sass',
    image: 'sass',
    img: 'logos/logo-sass.png',
  },
  {
    name: 'CSS3',
    image: 'css',
    img: 'logos/logo-css.png',
  },
  {
    name: 'jQuery',
    image: 'jquery',
    img: 'logos/logo-jquery.png',
  },
  {
    name: 'HTML 5',
    image: 'html',
    img: 'logos/logo-html.png',
  },
];
