import NavBarProducts from './NavBarProducts';
import HeroSection from './HeroSection';
import EtudesSection from './EtudesSection';
import JoinSection from './JoinSection';
import PortfolioSection from './PortfolioSection';
import IntroSection from './IntroSection';
import ActionsSection from './ActionsSection';
import TestimonialsSection from './TestimonialsSection';

export {
  NavBarProducts,
  HeroSection,
  EtudesSection,
  JoinSection,
  PortfolioSection,
  IntroSection,
  ActionsSection,
  TestimonialsSection,
};
