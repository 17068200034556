import Banner from 'views/digitaleTransformationDe/subcomponents/Banner';
import Webinar from 'views/digitaleTransformationDe/subcomponents/Webinar';
import CaseStudies from 'views/digitaleTransformationDe/subcomponents/CaseStudies';
import ContactFormDe from 'views/digitaleTransformationDe/subcomponents/ContactFormDe';
import About from 'views/digitaleTransformationDe/subcomponents/About';
import Testimonials from 'views/digitaleTransformationDe/subcomponents/Testimonials';
import Kontakt from 'views/digitaleTransformationDe/subcomponents/Kontakt';
import Quality from 'views/digitaleTransformationDe/subcomponents/Quality';

export {
  Banner,
  Webinar,
  CaseStudies,
  ContactFormDe,
  About,
  Testimonials,
  Kontakt,
  Quality,
};
