import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import ReactHtmlParser from 'react-html-parser';
import EmailSent from 'assets/images/product-development-guide/email-sent.svg';
import { Link, Button, Paragraph, Heading } from 'components';
import { serialize, classNames } from '../../utils';
import './WebinarForm.scss';

class WebinarForm extends Component {
  constructor() {
    super();

    this.refForm = createRef();

    this.state = {
      subscribed: false,
    };
  }

  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      window.location.href.indexOf('signedIn=true') !== -1
    ) {
      this.setState({ subscribed: true });
    }
  }

  setSubscribe = () => {
    const url = window.location.href;
    const joinChar = url.indexOf('?') > -1 ? '&' : '?';

    window.location.href = `${url + joinChar}signedIn=true`;
  };

  handleSubmit = e => {
    e.preventDefault();

    const { url, trackingEvent } = this.props;
    const form = this.refForm.current;

    this.setState({ isLoading: true });

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: serialize(form),
    })
      .then(() => {
        if (typeof window !== 'undefined') {
          if (window.datalayer && trackingEvent) {
            window.dataLayer.push({
              event: trackingEvent,
            });
          }

          if (window.fbq && typeof window.fbq === 'function') {
            window.fbq('track', 'Download');
          }
        }

        if (window.location.pathname.indexOf('/webinar/') !== -1) {
          this.setSubscribe();
        } else {
          this.setState({ subscribed: true });
        }
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      type,
      button,
      formHeading,
      webinarTitle,
      webinarContent,
      webinarButton,
      itemsQuestions,
      btnColor,
      webinarFormDescription,
    } = this.props;
    const { subscribed, isLoading } = this.state;

    return (
      <div className={'webinar-form'}>
        {subscribed ? (
          <div className={'webinar-form--sent'}>
            <img
              src={EmailSent}
              className={'image webinar-form__icon-sent'}
              alt={webinarTitle}
              loading={'lazy'}
            />
            <Paragraph size={'medium'} type={2} additionalClass={'text--bold'}>
              {webinarTitle || 'Thank you for filling up the form!'}
            </Paragraph>
            <Paragraph
              size={'medium'}
              additionalClass={'text--light webinar__description'}
            >
              {ReactHtmlParser(webinarContent) ||
                `Your ${type} was sent to your e-mail. Have fun reading it!`}
            </Paragraph>
            <Link
              to={webinarButton.path || '/blog/'}
              additionalClass={'webinar-form__headline--long'}
            >
              <Button
                id={'webinar-go-home'}
                color={'white'}
                additionalClass={'webinar-form__button--home'}
                ariaName={'Form button'}
              >
                {webinarButton.label || 'Visit our blog'}
              </Button>
            </Link>
          </div>
        ) : (
          <>
            <Heading
              size={'medium'}
              type={2}
              additionalClass={'webinar-form__headline'}
            >
              {formHeading || `Get your free ${type} now`}
            </Heading>
            <Paragraph
              size={'medium'}
              additionalClass={'text--light webinar-form__description'}
            >
              {webinarFormDescription ||
                `The video will be available from 25.08.2020`}
            </Paragraph>
            <form
              className={'webinar-form__inputs'}
              onSubmit={this.handleSubmit}
              ref={this.refForm}
            >
              <input
                type={'text'}
                name={'name'}
                placeholder={'First name'}
                required
              />
              <input
                type={'text'}
                name={'lastName'}
                placeholder={'Last name'}
                required
              />
              <input
                type={'email'}
                name={'email'}
                placeholder={'E-mail address'}
                required
              />
              <input
                type={'text'}
                name={'company'}
                placeholder={'Company name'}
                required
              />
              {itemsQuestions && (
                <input
                  type={'text'}
                  name={'job'}
                  placeholder={'Job title'}
                  required
                />
              )}
              <Button
                id={'webinar-form-submit'}
                type={'submit'}
                color={btnColor}
                additionalClass={classNames('webinar-form__button', {
                  loading: isLoading,
                })}
                ariaName={'Form button'}
                disabled={isLoading}
                loading
              >
                {button}
              </Button>
            </form>
            <Paragraph additionalClass={'webinar-form__info text--light'}>
              {
                "We're committed to your privacy. Boldare uses the information you provide to us to contact you about our relevant content, products, and services."
              }
              {' Read our '}
              <Link to={'/privacy-policy/'} id={'webinar-form-privacy-policy'}>
                privacy policy.
              </Link>
            </Paragraph>
          </>
        )}
      </div>
    );
  }
}

WebinarForm.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string.isRequired,
  formHeading: PropTypes.string,
  itemsQuestions: PropTypes.string,
  webinarButton: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
  webinarContent: PropTypes.string,
  webinarFormDescription: PropTypes.string,
  webinarTitle: PropTypes.string,
  short: PropTypes.bool,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

WebinarForm.defaultProps = {
  short: false,
  formHeading: '',
  btnColor: 'white',
  itemsQuestions: false,
  webinarTitle: '',
  webinarContent: '',
  webinarButton: { path: '', label: '' },
  webinarFormDescription: '',
};

export default WebinarForm;
