import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Banner,
  Teams,
  Process,
  ClientQuotes,
  HowWeBuild,
  WorkWith,
  OtherServices,
  Contact,
} from './subcomponents';
import './DevelopmentTeams.scss';

class DevelopmentTeams extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedWorkWith = [
      'Visualising Energy',
      'How to scale a monolithic MVP application without losing business?',
      'From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand',
    ].map(title => ({ title }));

    return (
      <div id={'dtu-section'} className={'single-page single-page--hero dtu'}>
        <Banner
          cta={'Contact us'}
          link={'dt-contact-form'}
          id={'dtu-contact-banner'}
          scroll
        />
        <Teams />
        <ClientQuotes />
        <Contact
          title={'Looking for the development team?'}
          content={'Let’s find out how we can help. '}
          cta={'Contact us'}
          link={'dt-contact-form'}
          id={'dtu-contact-middle'}
          scroll
        />
        <WorkWith allArticles={allArticles} related={relatedWorkWith} />
        <HowWeBuild />
        <Process />
        <ContactFormServices
          id={'dt-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x81'}
        />
        <OtherServices />
      </div>
    );
  }
}

export default DevelopmentTeams;
