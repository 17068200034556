import React from 'react';
import {
  Section,
  SectionHeader,
  WayPointHandler,
  AbsoluteCTA,
} from 'components';
import OurOffer from './OurOffer';
import './WhatWeDo.scss';

const About = ({ nocta }) => (
  <WayPointHandler path={'services'}>
    <Section>
      <SectionHeader
        color={'white'}
        headingType={2}
        lineColor={'yellow'}
        description={
          'We help clients to design and develop influential, future-proof and secure digital products that are centered around users’ needs and fulfill clients’ business goals. Our service standards are guidelines that describe the quality of our work, how we cooperate, and what you can expect from us. These rules ensure that all our partners receive the same quality of service and the comfort of being able to focus on other aspects of their business, confident in the knowledge that their services and products are well looked after.'
        }
        descriptionColor={'white'}
        additionalClass={'what-we-do__title'}
        size={'extra-large'}
      >
        Services and standards
      </SectionHeader>
      <OurOffer />
    </Section>
    {!nocta && (
      <AbsoluteCTA linkID={'contact-services'} id={'page-home-services'} />
    )}
  </WayPointHandler>
);

export default About;
