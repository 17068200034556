import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { SliderContext } from '../../utils';
import './Caption.scss';

const Caption = ({ index }) => {
  const { slides } = useContext(SliderContext);

  const { name, surname, company } = slides[index].client;
  return (
    <figcaption className={'hero-caption'}>
      <cite className={'hero-caption__cite'}>
        <span className={'hero-caption__name'}>
          {name} {surname}
        </span>
        <span className={'hero-caption__company'}>{company}</span>
      </cite>
    </figcaption>
  );
};

Caption.defaultProps = {
  index: 0,
};

Caption.propTypes = {
  index: PropTypes.number,
};

export default Caption;
