import React from 'react';
import { Helmet } from 'react-helmet';
import HackTheWind from 'views/hackTheWind';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['hack-the-wind'];
const { navColors } = page;
const url = `${config.siteUrl}/hack-the-wind/`;
const canonical = `${url}`;

const MLPage = () => (
  <Layout {...{ page, canonical, url }}>
    <Helmet title={'Hack The Wind Hackathon'}>
      <meta
        name={'description'}
        content={
          'Hack the Wind 2018 hackathon will be tackling a key problem for wind energy industry. Check out the solution from Boldare Machine Learning Team.'
        }
      />
      <link rel={'canonical'} href={canonical} />
    </Helmet>
    <NavBar color={navColors} />
    <PopupRedirect />
    <HackTheWind />
  </Layout>
);

export default MLPage;
