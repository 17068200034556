import React from 'react';

import { Section, SectionHeader, BackgroundImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

export const Banner = () => (
  <Section additionalClass={'mvp__banner'} color={'mine-shaft'}>
    <SectionHeader
      size={'extra-large'}
      color={'white'}
      description={
        'Building an MVP is one of the best ways to create a new digital product or service or validate your business idea. Minimum viable product app development is cost and time effective, works for any industry and can be used in organizations of various sizes, from thriving startups to mature enterprises. Whether you’re aiming to build a new service or add a new feature to an existing product, an MVP is the right tool for you.'
      }
      descriptionColor={'white'}
      lineColor={'pigeon-post-second'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Minimum Viable Product (MVP) development
    </SectionHeader>
    <div className={'mvp__banner-image'}>
      <BackgroundImage
        src={getCloudinaryUrl(
          'gatsby-cloudinary/mvp-hero',
          'f_auto,q_auto,h_800',
        )}
        alt={'Focused Eye'}
        additionalClass={'mvp__banner-image-wrapper'}
      />
    </div>
  </Section>
);
