import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { IconSVG, Link } from 'components';
import './Speaker.scss';

const EventSpeaker = ({ speakers }) => {
  return (
    <div className={'event-speakers__container'}>
      {speakers.map(speaker => {
        return (
          <div className={'event-speaker'}>
            <Link to={speaker.linkedin} rel={'nofollow'}>
              <div className={'event-speaker__avatar'}>
                <img
                  src={getCloudinaryUrl(speaker.photo, 'f_auto,q_auto')}
                  alt={speaker.name}
                  loading={'lazy'}
                />
              </div>
              <div className={'event-speaker__details'}>
                <p className={'event-speaker__details--title'}>Speaker</p>
                <div
                  className={`event-speaker__details--name color--${speaker.color}`}
                >
                  {speaker.name}
                  {speaker.linkedin && (
                    <IconSVG
                      additionalClass={'event-speaker__details--linkedin'}
                      name={'linkedin'}
                      size={'big'}
                      color={speaker.color}
                    />
                  )}
                </div>
                <p
                  className={`event-speaker__details--role color--${speaker.color}`}
                >
                  {speaker.role}
                </p>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default EventSpeaker;
