import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import {
  Banner,
  Problem,
  Solution,
  Results,
  Companies,
  Webinar,
} from './subcomponents';
import './SystemicDigitalTransformation.scss';

class SystemicDigitalTransformation extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero sysdt'}>
        <Banner />
        <Problem />
        <Solution />
        <Results />
        <Companies />
        <Webinar />
      </div>
    );
  }
}

export default SystemicDigitalTransformation;
