import React, { Component } from 'react';
import { ContactFormServices } from 'components';
import { enableAnimations } from 'config/helpers';
import { SesSection } from 'components/SeS';

import { Banner, ListOfServices, Technologies } from './subcomponents';
import './Services.scss';

class Services extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div
        id={'services-page'}
        className={'single-page single-page--hero services'}
      >
        <Banner link={'services-list'} />
        <ListOfServices />
        <SesSection />
        <Technologies />
        <ContactFormServices
          id={'services-b-contact-form'}
          name={'services-b-contact-form'}
          pardotLink={'https://go.boldare.com/l/688533/2019-11-18/3m168'}
        />
      </div>
    );
  }
}

export default Services;
