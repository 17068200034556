import React from 'react';
import { Button, Section, Heading, FullwidthImage, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Contact.scss';

const Contact = () => {
  return (
    <Section additionalClass={'showbiz__contact-container'} spaceless>
      <FullwidthImage
        image={getCloudinaryUrl(
          'assets/showbiz/showbiz_ending.jpg',
          'f_auto,q_auto',
        )}
        alt={'Contact'}
      />
      <div className={'showbiz__contact-content'}>
        <Heading type={2} size={'big-spreaded'} color={'white'}>
          Are you ready
          <br />
          to start your event
          <br />
          with us?
        </Heading>
        <Link to={'/contact/#form'} additionalClass={'showbiz__contact-link'}>
          <Button
            id={'practitest__contact-link'}
            color={'white'}
            hover
            ariaName={'Contact button'}
            additionalClass={'showbiz__contact-button'}
          >
            Contact us
          </Button>
        </Link>
      </div>
    </Section>
  );
};

export default Contact;
