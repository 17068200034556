import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';

const related = [
  'Visualising Energy',
  'How to scale a monolithic MVP application without losing business?',
  'From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand',
].map(title => ({ title }));

const LearnMore = ({ allArticles }) => (
  <>
    <Section color={'gray-light'} additionalClass={'fpdc__articles'}>
      <SectionHeader
        size={'medium'}
        description={
          'Read our latest articles and explore the fascinating world of new advancements in technology and how it impacts the businesses around the world.'
        }
        fixed
        headingType={2}
        fixed
      >
        Our articles about Full Cycle Product Development
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'fpdc__articles'}
        related={related}
        background={'gray-light'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
      />
    )}
  </>
);

export default LearnMore;
