import BOX_TYPE from 'config/constants/boxTypes';
import logoChilidTealspace from 'assets/images/logos/chilid-tealspace.svg';
import { Industry, Service, Region, ProductStage, Country } from './enums.js';

export const BRIEFS = [
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Germany,
      coverImage: 'img/work-sonnen-charger.jpg',
      clientName: 'Sonnen',
      clientLogo: 'logoSonnen',
      title: 'IoT software and app for an electric car charger',
      tags: ['Java', 'ReactNative', 'MVP', 'Germany'],
      industry: [Industry.Energy],
      service: [Service.FCPD],
      subtitle: [''],
      description:
        'The client approached Boldare for a mobile app for an EV charger, powered by solar energy. The product was created from scratch by Boldare’s cross functional, agile Prototyping and MVP teams.',
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-sonnen-charger',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.UnitedKingdom,
      coverImage: 'img/work-viners.jpg',
      clientName: 'Viners',
      clientLogo: 'logoViners',
      title: 'A complex e-commerce for cutlery brand',
      tags: ['Symfony', 'JavaScript', 'Scaling', 'UnitedKingdom'],
      industry: [Industry.ECommerce],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'Viners needed to shift their sales process to digital, requiring a complex e-commerce site for their cutlery brand. We created an e-commerce process that was seamlessly integrated with the client’s warehouse and CRM to maximise customer satisfaction and improve business performance.',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-viners',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Poland,
      coverImage: 'img/work-brass-willow.jpg',
      clientName: 'Brass Willow',
      clientLogo: 'logoBrassWillow',
      title: 'Booking platform for an agile consultancy',
      tags: ['Symfony', 'JavaScript', 'MVP', 'Poland'],
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      subtitle: [''],
      description:
        'The requirement was for a booking platform for an agile consultancy aiming to improve their online sales conversion. Our team created a web app that allows users to select training, sign up, and pay, generating invoices and automating post-purchase communication.',
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-brass-willow',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Italy,
      coverImage: 'img/work-incampagna.jpg',
      clientName: 'inCampagna',
      clientLogo: 'logoInCampagna',
      title: 'E-commerce for organic food distributor',
      tags: ['Symfony', 'JavaScript', 'UX/UI', 'Italy'],
      industry: [Industry.ECommerce],
      service: [Service.DigitalTransformation, Service.DevTeam],
      subtitle: [''],
      description:
        'An organic food distributor needed to switch to e-commerce as part of their digital transformation.We created an e-commerce platform that aggregates farmers and their products, and also handles administration, warehousing, shipping and sales.',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-incampagna',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.France,
      coverImage: 'img/case-study-holaspirit-web-app.jpg',
      clientName: 'HolaSpirit',
      clientLogo: 'logoHolaSpirit',
      title: 'Scaling a HR management SaaS',
      tags: ['ReactJS', 'Redux', 'Scaling', 'France'],
      industry: [Industry.SAAS],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'The client came to Boldare for help with stabilizing and scaling their core product, a SaaS HR management tool. Our team helped to shift their product up to the next gear by upgrading the technology, improving on the UX.',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-holaspirit',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Poland,
      coverImage: 'img/case-study-apasmart-web-app.png',
      clientName: 'ApaSmart',
      clientLogo: 'logoApasmart',
      title: 'Product website for an interior design service',
      tags: ['JavaScript', 'HTML5', 'UX/UI', 'Poland'],
      industry: [Industry.SAAS],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'An interior design service needed a sales platform with a high conversion rate for their building management system. The Boldare  team created a flexible website to help them respond to their changing business needs.',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-apasmart',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.SaudiArabia,
      coverImage: 'img/work-a2l.jpg',
      clientName: 'Takamol',
      clientLogo: 'logoTakamol',
      title: 'Data visualisation platform',
      tags: ['Symfony', 'JavaScript', 'MVP', 'SaudiArabia'],
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      subtitle: [''],
      description:
        'This government-owned company approached Boldare to build a complex web app, visualizing economic and geographic data for entrepreneurs operating in the region. We provided a cross-functional team who helped to ideate, build and grow a successful product.',
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {},
    id: 'box-a2l',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.UnitedKingdom,
      coverImage: 'img/case-study-cole-mason-web-app.jpg',
      clientName: 'Cole&Mason',
      clientLogo: 'logoColeMason',
      title: 'Engaging blog for a heritage brand',
      tags: ['JavaScript', 'HTML5', 'UX/UI', 'UnitedKingdom'],
      industry: [Industry.ECommerce],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'This British client turned to us to create Seasoning School, an online recipe guide full of mouth-watering visuals. Boldare created a responsive website that smoothly leads the user from product browsing to conversion.',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-cole-mason',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Sweden,
      coverImage: 'img/case-study-heartpace-web-app.jpg',
      clientName: 'Heartpace',
      clientLogo: 'logoHeartpace',
      title: 'Website for a performance review tool',
      tags: ['KeystoneJS', 'HTML5', 'UX/UI', 'Sweden'],
      industry: [Industry.SAAS],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'A Swedish startup turned to Boldare to help them re-launch their product website. Our team  worked on a new UX, UI and overall development to create a truly human-centered online experience.',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-heartpace',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.UnitedStates,
      coverImage: 'img/work-numera-lighting.jpg',
      clientName: 'Numera Lighting',
      clientLogo: 'logoNumeraLighting',
      title: 'E-commerce for a premium lamp designer',
      tags: ['JavaScript', 'Shopify', 'UX/UI', 'USA'],
      industry: [Industry.ECommerce],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'This American business approached Boldare to create an interactive, online catalog. Using Shopify, we were able to swiftly create a high-performing, modern catalog of the client’s luxury products.',
      productStage: [ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {},
    id: 'box-numera-lighting',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.SaudiArabia,
      coverImage: 'img/case-study-rehalati-web-app.jpg',
      clientName: 'Rehalati',
      clientLogo: 'logoRehalati',
      title: 'A booking platform for Saudi travellers',
      tags: ['React', 'Redux', 'MVP', 'UAE'],
      industry: [Industry.Travel],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'The client turned to us to create a useful, modern and intuitive booking engine as a response to current needs. In response, Boldare combined human-centered design with the latest front-end technology, React.JS.',
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {},
    id: 'box-rehalati',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Germany,
      coverImage: 'img/case-study-lr-beauty-web-app.jpg',
      clientName: 'LR World',
      clientLogo: 'logoLrBeauty',
      title: 'Online experience for a cosmetics giant',
      tags: ['Symfony', 'React', 'UX/UI', 'Germany'],
      industry: [Industry.HealthAndBeauty],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'The client approached Boldare to build a new complex website that would drive their new business goals. Employing 3D graphics, Boldare designed an immersive online experience with the aim of drawing in the target audience and converting them into sales reps.',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-lr-beauty',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Switzerland,
      coverImage: 'img/work-ezycount.jpg',
      clientName: 'EZYcount',
      clientLogo: 'logoEzycount',
      title: 'Online accounting application for SMBs',
      tags: ['Symfony', 'UX/UI', 'Scaling', 'Switzerland'],
      industry: [Industry.SAAS],
      service: [Service.FCPD],
      subtitle: [''],
      description:
        'This Swiss B2B web accounting startup turned to Boldare to build a scalable web application for their accounting software. Our team stabilized the app and built new features,  training the client’s in-house team so they were able to manage the product in future without external assistance.',
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-ezycount',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Sweden,
      coverImage: 'img/case-study-digpro-web-app.jpg',
      clientName: 'Digpro',
      clientLogo: 'logoDigpro',
      title: 'Product website for a SaaS grid management tool',
      tags: ['ReactJS', 'UX/UI', 'MVP', 'Sweden'],
      industry: [Industry.Energy, Industry.SAAS],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'dpPower approached Boldare to create a high-converting sales website for their complex SaaS product. Boldare created an integrated enterprise software solution for the complete network lifecycle management of electrical networks.',
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-cryo',
  },
  {
    type: BOX_TYPE.BRIEF,
    content: {
      country: Country.Poland,
      coverImage: 'img/case-study-tealspace-web-app.jpg',
      clientName: 'tealspace by Chilid',
      clientLogo: logoChilidTealspace,
      title: 'Showcase landing page',
      tags: ['JavaScript', 'HTML5', 'UX/UI', 'Poland'],
      industry: [Industry.ITServices],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'At Boldare, we wanted to showcase our Teal-based office design. We created an award-winning landing page to present the complex ideas behind our workspace. Using state-of-the-art technology, we created light and isometric illustrations for enhanced visual clarity. ',
      productStage: [''],
      region: [Region.Europe],
    },
    settings: {},
    id: 'box-chilid-tealspace',
  },
];
