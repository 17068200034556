export const data = [
  {
    quote: `"They stood out as a company who really wanted to understand our problem and fulfill our needs in a partnership."`,
    client: 'Norbert Baumann, VP of R&D, sonnen',
    logo: 'services/your-development-partner/logo-sonnen.png',
    brand: 'Sonnen',
  },
  {
    quote: `"I provided minimal direction about expectations. They took full responsibility for assembling the solution."`,
    client: 'Neil de Souza, Project Manager, Sabco Media',
    logo: 'services/your-development-partner/logo-virgin.png',
    brand: 'Virgin Radio Oman',
  },
  {
    quote: `"Boldare delivered a successful prototype, and the internal team is now creating the MVP. They worked effectively despite challenges from the COVID-19 pandemic."`,
    client: 'Digital Lead Practitioner, Xinfu, UK',
    logo: 'services/your-development-partner/logo-xinfu.png',
    brand: 'Xinfu',
  },
  {
    quote: `"Boldare completed the entire project within the new deadline and did an excellent job. The project management team was always responsive and available when needed. They embraced the company's goals and helped to push the product to be even better."`,
    client: 'President, Community Response Systems, LLC, US',
    logo: 'services/your-development-partner/logo-team-alert.png',
    brand: 'Community Response Systems',
  },
  {
    quote: `"Feedback is positive, and Boldare’s deliverables are high-quality. They work well within an agile environment and successfully navigate a wide variety of technologies. Their communication skills, price, and convenience all contribute to a successful collaboration."`,
    client: 'Business Development, Takamol Holding, KSA',
    logo: 'services/your-development-partner/logo-takamol.png',
    brand: 'Takamol Holding',
  },
];
