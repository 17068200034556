import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from '../../../meta/config';

import './ExternalResource.scss';

const ExternalResource = ({ data }) => {
  const { title, iframe, canonical } = data.frontmatter;
  const canonicalUrl = `${config.siteUrl}/external-resources/${canonical}`;

  return (
    <>
      <Helmet>
        {canonical && <link rel={'canonical'} href={`${canonicalUrl}`} />}
      </Helmet>
      <div className={'external-resource'}>
        <iframe title={title} src={iframe.src} />
      </div>
    </>
  );
};

ExternalResource.propTypes = { data: PropTypes.shape({}).isRequired };

export default ExternalResource;
