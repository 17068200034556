import React from 'react';
import SVG from 'react-inlinesvg';
import {
  Section,
  SectionHeader,
  Paragraph,
  FullwidthImage,
  Link,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import chartTopTech from 'assets/images/digital-transformation/top-technologies-implemented.svg';
import chartTopTechInvestigated from 'assets/images/digital-transformation/technologies-investigated-by-companies.svg';
import './Technologies.scss';

const Technologies = () => (
  <>
    <Section color={'mine-shaft'}>
      <SectionHeader
        size={'big'}
        color={'white'}
        lineColor={'red'}
        headingType={2}
      >
        Top technologies implemented
      </SectionHeader>
      <div
        className={
          'digital-transformation__chart digital-transformation__chart--full'
        }
      >
        <SVG src={chartTopTech} alt={'Top technologies implemeneted'} />
      </div>
      <Link
        to={
          'http://resources.idg.com/download/white-paper/2018-digital-business'
        }
      >
        <Paragraph
          color={'white'}
          size={'small'}
          additionalClass={'text--light digital-transformation__chart-source'}
        >
          Source: IDG
        </Paragraph>
      </Link>
    </Section>
    <div className={'digital-transformation__container'}>
      <FullwidthImage
        image={getCloudinaryUrl(
          'gatsby-cloudinary/office-1.jpg',
          'f_auto,q_auto',
        )}
      />
      <div className={'w-container--left'}>
        <div
          className={
            'digital-transformation__chart digital-transformation__chart--absolute'
          }
        >
          <SectionHeader
            size={'medium'}
            lineColor={'pigeon-post'}
            headingType={2}
          >
            Top technologies currently investigated by companies:
          </SectionHeader>
          <SVG
            src={chartTopTechInvestigated}
            alt={'Top technologies investigated by companies'}
          />
          <Link
            to={
              'http://resources.idg.com/download/white-paper/2018-digital-business'
            }
          >
            <Paragraph
              color={'black'}
              size={'small'}
              additionalClass={
                'text--light digital-transformation__chart-source'
              }
            >
              Source: IDG
            </Paragraph>
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default Technologies;
