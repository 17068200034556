import React from 'react';
import { Section, SectionHeader, Paragraph, Heading } from 'components';
import './TalentPoolRecruiters.scss';

import CircleLines from 'assets/images/career/circle-lines.svg';

import SVG from 'react-inlinesvg';
import { recruiters } from './recruiters.ts';

const TalentPoolRecruiters = () => {
  return (
    <>
      <Section
        id={'talent-pool-recruiters'}
        color={'soft-cloud'}
        additionalClass={'talent-pool-recruiters'}
      >
        <div className={'talent-pool-recruiters__column--content'}>
          <SectionHeader
            additionalClass={'headline talent-pool-recruiters__headline'}
            color={'mine-shaft'}
            size={'large'}
            headingType={2}
            noLine
            centered
          >
            Meet our technical recruiters <br />
          </SectionHeader>

          <Paragraph
            size={'medium'}
            color={'mine-shaft'}
            spaced
            additionalClass={'talent-pool-recruiters__description'}
          >
            They are here to make your process even more valuable. Expect
            knowledge-sharing and chat on hot topics in your field.
          </Paragraph>
        </div>

        <div className={'talent-pool-recruiters__column--grid'}>
          {recruiters.map(person => (
            <div key={person.name} className={'talent-pool-recruiters__card'}>
              <span className={'talent-pool-recruiters__card-role'}>
                {person.role}
              </span>
              <Heading
                type={3}
                color={'mine-shaft'}
                additionalClass={'talent-pool-recruiters__card-name'}
              >
                {person.name}
              </Heading>
              <Paragraph
                additionalClass={'talent-pool-recruiters__card-description'}
              >
                {person.description}
                <span
                  className={'talent-pool-recruiters__card-description--bold'}
                >
                  &nbsp;{person.boldQuestion}
                </span>
              </Paragraph>

              <div className={'talent-pool-recruiters__card-image'}>
                <SVG
                  src={CircleLines}
                  className={'talent-pool-recruiters__card-image-background'}
                />

                <img
                  className={'talent-pool-recruiters__card-image-person'}
                  src={person.imageUrl}
                  alt={'recruiter'}
                />
              </div>
            </div>
          ))}
        </div>
      </Section>
    </>
  );
};

export default TalentPoolRecruiters;
