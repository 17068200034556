import React from 'react';
import {
  Section,
  SectionHeader,
  WayPointHandler,
  Awards,
  Paragraph,
  Link,
  Button,
  Rating,
} from 'components';
import './About.scss';
import { getCloudinaryUrl } from 'config/helpers';
import companyData from '../../../../../meta/companyData';
import { companiesData } from './logic';

const About = () => (
  <WayPointHandler path={'about'}>
    <>
      <Section additionalClass={'about__awards'} color={'violet'}>
        <SectionHeader
          description={
            'Boldare stands for quality. Our award-winning digital products are internationally recognized for beautiful interfaces, stellar user experience, our innovative approach and impeccable code quality.'
          }
          color={'white'}
          lineColor={'yellow'}
          descriptionColor={'white'}
          size={'large'}
          headingType={2}
        >
          Awards &amp; rankings
        </SectionHeader>
        <Awards theme={'light'} />
        <Section
          additionalClass={`about__companies`}
          containerClass={'awards__container'}
          spaceless
          fullwidth
        >
          <div className={'companies__items'}>
            {companiesData.map(item => (
              <div className={'companies__item'}>
                <div className={'companies__item-img'}>
                  <img
                    src={getCloudinaryUrl(item.image, 'f_auto,q_auto')}
                    alt={''}
                    loading={'lazy'}
                  />
                  {item.rate && (
                    <Rating
                      rate={companyData.clutch_rate}
                      size={20}
                      color={'yellow'}
                    />
                  )}
                </div>
                <Paragraph
                  additionalClass={'companies__item-description'}
                  color={'white'}
                  size={'medium'}
                >
                  {item.description}
                </Paragraph>
                {item.link && (
                  <Link to={item.link}>
                    <Button
                      color={'white'}
                      arrowColor={'white'}
                      order={'secondary'}
                    >
                      {item.linkText}
                    </Button>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </Section>
      </Section>
    </>
  </WayPointHandler>
);

export default About;
