import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Heading, Testimonial } from 'components';
import { classNames } from 'utils';
import './ClientReview.scss';
import { getCloudinaryUrl } from 'config/helpers';

const ClientReview = ({ data, mainColor, additionalClass }) => {
  const { testimonial, author, image, alt, coverImage } = data;

  return (
    <Section
      spaceless
      fullwidth
      additionalClass={classNames('cs-review', additionalClass)}
      side={'left'}
    >
      <Row className={'no-gap'}>
        <Col
          sm={12}
          md={6}
          className={classNames('client-review__container', {
            [`client-review__container--${mainColor}`]: mainColor,
          })}
        >
          <div className={'section w-container--left'}>
            <Heading
              size={'medium'}
              color={'white'}
              type={3}
              additionalClass={
                'text--default client-review__testimonial-intro'
              }
            >
              Clients review
            </Heading>
            <Testimonial
              content={testimonial}
              author={author}
              additionalClass={'text--light'}
            />
          </div>
        </Col>
        <Col sm={12} md={6} className={'client-review__image-wrapper'}>
          <div
            className={classNames('client-review__image', {
              'client-review__image--cover': coverImage,
            })}
          >
            <img
              src={getCloudinaryUrl(image, 'f_auto,q_auto')}
              alt={alt}
              className={'image'}
              loading={'lazy'}
            />
          </div>
        </Col>
      </Row>
    </Section>
  );
};

ClientReview.defaultProps = {
  alt: '',
  mainColor: 'yellow',
};

ClientReview.propTypes = {
  data: PropTypes.shape({
    testimonial: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string,
    mainColor: PropTypes.string,
  }).isRequired,
};

export default ClientReview;
