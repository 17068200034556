import React, { useEffect, useState } from 'react';
import { classNames } from 'utils';
import PropTypes from 'prop-types';
import { Container, TileSection } from 'components';
import chosenAnswers from 'views/appCostsCalculator/logic/chosenAnswers';
import SendResultSection from './subcomponents/SendResultSection';

import '../Calculator/Calculator.scss';
import './Result.scss';
import { Phase } from './subcomponents/Phase';
import { FreeTool } from './subcomponents/FreeTool';
import { PhaseVideo } from './subcomponents/PhaseVideo';

const phases = [
  {
    phase: 'prototype',
    phaseName: 'Prototype',
  },
  {
    phase: 'mvp',
    phaseName: 'Minimum Viable Product',
  },
  {
    phase: 'pmf',
    phaseName: 'Product-Market Fit',
  },
  {
    phase: 'scaling',
    phaseName: 'Scaling',
  },
];

const Result = ({ phase, finalAnswers, contact }) => {
  const [activePhase, setActivePhase] = useState(phase);
  const [resultPhase, setResultPhase] = useState(phase);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'app_cost_calculator_result',
        // result: quizResult,
      });
    }
  }, []);

  // const result = quizCostResults[phase]; PHASE DETAILS -> budget etc - check if its up to date

  const renderNavbarItem = item => {
    const isResultPhase = item.phase === resultPhase;

    return (
      <li
        className={classNames('app-calc-result__navbar-item', {
          'app-calc-result__navbar-item--active': item.phase === activePhase,
          'app-calc-result__navbar-item--result': isResultPhase,
        })}
      >
        {isResultPhase && (
          <p className={'app-calc-result__recommended'}>Recommended</p>
        )}
        <button
          className={'app-calc-result__navbar-button'}
          onClick={() => setActivePhase(item.phase)}
        >
          {item.phaseName}
        </button>
      </li>
    );
  };

  return (
    <div className={'app-calc-result'}>
      <Container>
        <p className={'app-calc-result__header'}>
          App <span className={'app-cost-marker-highlight-short'}>cost</span>{' '}
          calculator
        </p>
        <p className={'app-calc-result__description'}>
          Our calculator suggests an approach for building the app you have in
          mind, based on your needs as expressed in the short survey.
          <br />
          It estimates the costs, time required to build the app, and the
          potential size of the team needed to create it.{' '}
        </p>
        <ul className={'app-calc-result__navbar'}>
          {phases.map(item => renderNavbarItem(item))}
        </ul>
      </Container>
      <div className={'app-calc-result-phase-and-send-section'}>
        <Phase activePhase={activePhase} />
        <SendResultSection
          result={chosenAnswers(finalAnswers)}
          contact={contact}
        />
      </div>
      <TileSection withHeader />
      <PhaseVideo activePhase={activePhase} />
      <FreeTool imagePath={'app-costs-calculator/tablet-prototype'}/>
    </div>
  );
};

Result.propTypes = {
  phase: PropTypes.string.isRequired,
  finalAnswers: PropTypes.object.isRequired,
  contact: PropTypes.object,
};

Result.defaultProps = {
  contact: null,
}

export default Result;
