import React, { useState, useEffect } from 'react';
import './Banner.scss';
import { scroller } from 'react-scroll';
import { Paragraph, Heading, Section, IconSVG, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const HonorableMention = 'img/awwwards/awards/honorable-mentions.png';
const GDA = 'gatsby-cloudinary/german-design-award_2x.png';

const Banner = () => {
  const [isAwwwards, setIsAwwwards] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('awwwards')
    ) {
      setIsAwwwards(true);
    }
  }, []);

  return (
    <Section additionalClass={'awwwards-lp-banner'} color={'mine-shaft'}>
      <div className={'awwwards-lp-banner__rel'}>
        <Heading
          additionalClass={'awwwards-lp__heading awwwards-lp-banner__heading'}
          color={'white'}
          type={1}
        >
          {isAwwwards ? (
            <>
              <span>Looking for </span>
              <span
                className={
                  'awwwards-lp__heading-highlight awwwards-lp__heading-highlight--awwwards'
                }
              >
                awwwesome{' '}
              </span>
              <span>designers?</span>
            </>
          ) : (
            <>
              <span>Looking for </span>
              <span className={'awwwards-lp__heading-highlight'}>
                award-winning{' '}
              </span>
              <span>design?</span>
            </>
          )}
        </Heading>
        <div className={'awwwards-lp-banner__paragraph'}>
          <Paragraph
            additionalClass={''}
            size={'medium-plus-small'}
            color={'mine-shaft'}
            spaced
          >
            See how we can make a difference to your next amazing digital
            product.
          </Paragraph>
          <Button
            color={'white'}
            onClick={() => {
              scroller.scrollTo('awwwwards__contact', {
                spy: false,
                smooth: true,
                duration: 700,
                offset: 0,
              });
            }}
            role={'button'}
          >
            Contact us
          </Button>
        </div>
        <div
          className={'awwwards-lp-banner__scroll'}
          onClick={() => {
            scroller.scrollTo('awwwwards__designs', {
              spy: false,
              smooth: true,
              duration: 1000,
              offset: -80,
            });
          }}
          role={'button'}
        >
          <IconSVG
            name={'chevron-down-white'}
            size={'huge'}
            color={'white'}
            additionalClass={'awwwards-lp-banner__scroll-down'}
          />
          <IconSVG
            name={'awwwards-circle'}
            size={'huge'}
            color={'white'}
            additionalClass={'awwwards-lp-banner__scroll-circle'}
          />
          <IconSVG
            name={'awwwards-copy'}
            size={'huge'}
            color={'white'}
            additionalClass={'awwwards-lp-banner__scroll-copy'}
          />
        </div>
        <Button
          onClick={() => {
            scroller.scrollTo('awwwards__awards', {
              spy: false,
              smooth: true,
              duration: 700,
              offset: 0,
            });
          }}
          role={'button'}
          className={
            'awwwards-lp-banner__scroll awwwards-lp-banner__scroll--hm'
          }
        >
          <img
            src={getCloudinaryUrl(HonorableMention, 'f_auto,q_auto,h_120')}
            className={'image footer__logo'}
            alt={'Awwwards Honorable Mentions'}
            loading={'lazy'}
          />
        </Button>
        <Button
          onClick={() => {
            scroller.scrollTo('awwwards__awards', {
              spy: false,
              smooth: true,
              duration: 700,
              offset: 0,
            });
          }}
          role={'button'}
          className={
            'awwwards-lp-banner__scroll awwwards-lp-banner__scroll--gda'
          }
        >
          <img
            src={getCloudinaryUrl(GDA, 'f_auto,q_auto,h_120')}
            className={'image footer__logo'}
            alt={'German Design Award'}
            loading={'lazy'}
          />
        </Button>
      </div>
    </Section>
  );
};

export default Banner;
