import React from 'react';
import { Section, WayPointHandler } from 'components';
import MainSlider from './MainSlider';

import './MainBanner.scss';

const MainBanner = () => (
  <WayPointHandler path={'home'}>
    <div className={'main-banner'}>
      <Section stick spaceless>
        <MainSlider />
      </Section>
    </div>
  </WayPointHandler>
);

export default MainBanner;
