import React from 'react';
import PropTypes from 'prop-types';
import Growth from '../Growth';
import './SectionGrowth.scss';

const SectionGrowth = ({ isDesktop }) => {
  return (
    <div className={'section-wrapper section-growth'}>
      <section className={'grid-layout'}>
        <div className={'about-header'}>
          We bet on steady{' '}
          <span className={'marker-highlight marker-black'}>growth...</span>
        </div>
        <div className={'section-growth__wrapper'}>
          <div className={'section-growth__chart-number'}>
            <div className={'section-growth__number'}>30%</div>
            <div className={'section-growth__wrapper-text'}>
              growth in revenue, year on year
            </div>
          </div>
          <Growth className={'section-growth__chart'} />
        </div>
        {!isDesktop && (
          <div className={'growth-numbers'}>
            <div>2020</div>
            <div>2021</div>
            <div>2022</div>
            <div>2023</div>
          </div>
        )}
        <div className={'about-header'}>
          ...and do our best to share profits in a{' '}
          <span className={'marker-highlight marker-black'}>sustainable</span>{' '}
          manner
        </div>
        {isDesktop && (
          <>
            <div className={'section-growth__number'}>20%</div>
            <div className={'section-growth__number'}>15%</div>
            <div className={'section-growth__number'}>10%</div>
            <div className={'section-growth__number'}>20%</div>
            <div className={'section-growth__text'}>
              divided between all employees
            </div>
            <div className={'section-growth__text'}>
              for development of employee knowledge and skills
            </div>
            <div className={'section-growth__text'}>
              CSR activities that support education and equality activities
            </div>
            <div className={'section-growth__text'}>for shareholders</div>
          </>
        )}
        {!isDesktop && (
          <>
            <div className={'section-growth__number'}>20%</div>
            <div className={'section-growth__text'}>
              divided between all employees
            </div>
            <div className={'section-growth__number'}>15%</div>
            <div className={'section-growth__text'}>
              for development of employee knowledge and skills
            </div>
            <div className={'section-growth__number'}>10%</div>
            <div className={'section-growth__text'}>
              CSR activities that support education and equality activities
            </div>
            <div className={'section-growth__number'}>20%</div>
            <div className={'section-growth__text'}>for shareholders</div>
          </>
        )}
      </section>
    </div>
  );
};

export default SectionGrowth;

SectionGrowth.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};
