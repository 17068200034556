import React from 'react';

import { Row, Col } from 'react-flexbox-grid';
import { Header, Orbit, Section } from '../../../../components/DS';
import { logos } from './logic';

import './Technologies.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'horizontal'}
    longDescription
  />
);

export const Technologies = ({ data }) => (
  <Section
    heading={<SectionHeader title={data.title} description={data.description} />}
    color={'seashell'}
  >
    {/* <div className={'keyword-page-technologies__orbit-container'}>
      <div className={'keyword-page-technologies__orbit'}>
        <Orbit />
      </div>
    </div> */}
    <Row className={'keyword-page-technologies__logo-list-wrapper'}>
      {logos.map(element => (
        <div className={'keyword-page-technologies__logo-wrapper'}>
          <div className={'keyword-page-technologies__logo-img'}>
            <img src={element.link} alt={`${element.title} technologies logo`} />
          </div>
          <span className={'keyword-page-technologies__logo-title'}>{element.title}</span>
        </div>
      ))}
    </Row>
  </Section>
)
