import { flow, map, replace, join } from 'lodash/fp';
import { Industry, ProductStage, Region } from './data/enums';

export const filters = ['Industry', 'Product Stage', 'Region'];

const allFilters = [
  ...Object.values(Industry),
  ...Object.values(ProductStage),
  ...Object.values(Region),
];

const parseLabelSlug = {
  industry: 'Industry',
  productstage: 'ProductStage',
  region: 'Region',
};

const parseFilterSlug = flow(
  map(flow(replace('and', '&'), replace('productmarket', 'product/market'))),
  join(' '),
  replace('e commerce', 'e-commerce'),
);

export const parseFilterFromHash = hash => {
  if (!hash || hash.indexOf('#') === -1) {
    return null;
  }

  const parts = hash.replace('#', '').split('-');

  if (!parts || parts.length < 2) {
    return null;
  }

  const label = parseLabelSlug[parts[0]];
  const filter = parseFilterSlug(parts.slice(1));

  return { label, filter };
};

export const parseRegionFilterToHash = region =>{
  return `#region-${region.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')}`;
}

export const isItemInFilter = (items, filter) =>
  filter !== null
    ? items.find(
        itemFilter => itemFilter.toLowerCase() === filter.toLowerCase(),
      )
    : null;

export const getPrettyFilter = filter => isItemInFilter(allFilters, filter);
