import React from 'react';
import PropTypes from 'prop-types';

import ArrowDown from 'assets/images/work/arrow-down.svg';
import { classNames } from 'utils';
import { FilterLink } from 'views/work/subcomponents/CaseStudies/subcomponents/FiltersBoxB/subcomponents';

import {
  Industry,
  ProductStage,
  Region,
} from 'views/work/subcomponents/CaseStudies/data/enums';

import './FiltersList.scss';

const FiltersList = ({
  isDesktop,
  activeFilter,
  label,
  title,
  isOpen,
  setOpenedMenu,
  handleClick,
}) => {
  const isIndustry = label === 'Industry';
  const items = {
    Industry: Object.values(Industry),
    ProductStage: Object.values(ProductStage),
    Region: Object.values(Region),
  };

  const handleOpening = () => {
    if (isOpen) {
      setOpenedMenu(null);
    } else {
      setOpenedMenu(label);
    }
  };

  const titleElement = isDesktop ? (
    <button
      className={'filters-list-b__title filters-list-b__title--button'}
      onClick={handleOpening}
    >
      <span>{title}</span>
      <img
        src={ArrowDown}
        alt={`arrow ${isOpen ? 'up' : 'down'} icon`}
        className={classNames('filters-list-b__arrow', {
          'filters-list-b__arrow--rotated': isOpen,
        })}
      />
    </button>
  ) : (
    <span className={'filters-list-b__title filters-list-b__title--span'}>
      {title}
    </span>
  );

  return (
    <div
      className={classNames('filters-list-b', {
        'filters-list-b--short': !isIndustry,
      })}
    >
      {titleElement}
      <ul
        className={classNames('filters-list-b__container', {
          'filters-list-b__container--open': isOpen,
          'filters-list-b__container--open-short': isOpen && !isIndustry,
          'filters-list-b__container--closed': !isOpen,
        })}
      >
        {items[label].map(filter => (
          <FilterLink
            key={filter}
            isActive={filter === activeFilter}
            filter={filter}
            label={label}
            handleClick={handleClick}
          />
        ))}
      </ul>
    </div>
  );
};

FiltersList.defaultProps = {
  isDesktop: false,
  activeFilter: null,
  label: 'Industry',
  title: 'Industry',
  isOpen: false,
  setOpenedMenu: () => {},
  handleClick: () => {},
};

FiltersList.propTypes = {
  isDesktop: PropTypes.bool,
  activeFilter: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpenedMenu: PropTypes.func,
  handleClick: PropTypes.func,
};

export default FiltersList;
