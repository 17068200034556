import React from 'react'
import { Section } from 'components/DS'
import { getCloudinaryUrl } from 'config/helpers'
import './ExperiencePanel.scss'

export const ExperiencePanel = ({
  data: { title, description, carouselItems }
}) => (
  <Section color={'mine-shaft'}>
    <div className={'experience-panel'}>
      <div className={'experience-panel__wrapper'}>
        <h1 className={'experience-panel__header'}>
          {title}
        </h1>
        <div className={'experience-panel__description'}>
          {description}
        </div>
      </div>
      {carouselItems.map(({ item }, index) => (
        <div className={'experience-section'} key={index}>
          <img
            src={getCloudinaryUrl(item.image, 'f_auto,q_auto')}
            alt={item.imageAlt}
            className={'experience-photo'} />
          <div className={'experience-text-wrapper'}>
            <h2 className={'experience-title'}>{item.title}</h2>
            <p className={'experience-description'}>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  </Section>
)
