import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import {
  Challenge,
  Changes,
  Contact,
  Features,
  Goals,
  Intro,
  Product,
  Sketches,
  Team,
  Technology,
} from './subcomponents';
import './PlanetEscape.scss';

class PlanetEscape extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study pe__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/planet-escape/planet_escape-case_study.jpg',
            'f_auto,q_auto',
          )}
          color={'white'}
          subtitle={'Front-end app'}
          title={"Discovering the world is life's passion"}
        />
        <Intro />
        <Changes />
        <Challenge
          title={'Main challenge:'}
          subtitle={'Building a new, professional visual brand'}
        />
        <Product />
        <Features />
        <Challenge
          title={'Goals achieved:'}
          subtitle={'New image: professional & high standard of service'}
        />
        <Goals />
        <Sketches />
        <Team />
        <Technology />
        <Contact />
      </div>
    );
  }
}

export default PlanetEscape;
