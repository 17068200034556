import React from 'react';
import './Banner.scss';
import {
  BackgroundImage,
  Button,
  Heading,
  Section,
  SectionHeader,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { scroller } from 'react-scroll';
import ArrowBlack from 'assets/images/contact-de/arrow-black.svg';
import { getCloudinaryUrl } from 'config/helpers';
import StripedCircleMiddle from 'assets/images/contact-de/striped-circle-middle.svg';
import StripedCircleSmall from 'assets/images/contact-de/striped-circle-small.svg';

const Banner = () => (
  <Section
    id={'connect-de-banner'}
    additionalClass={
      'contact-de-banner__section contact-de-banner--position-relative'
    }
    side={'right'}
  >
    <BackgroundImage
      asset={'gatsby-cloudinary/boldare-contact-de-banner.png'}
      alt={'German City'}
      additionalClass={'banner__img background-img--cloudinary'}
    />
    <Row className={'no-gap contact-de-banner--position-relative'}>
      <Col sm={12} md={9}>
        <SectionHeader
          size={'extra-large'}
          color={'white'}
          descriptionColor={'black'}
          lineColor={'white'}
          buttonColor={'black'}
          fixed
          noLine
          additionalClass={'contact-de-banner__text-header'}
        >
          Ja,
          <br />
          wir sprechen
          <br />
          Deutsch!
        </SectionHeader>
        <Heading
          type={2}
          size={'medium-constant'}
          color={'white'}
          additionalClass={'contact-de-banner__text-header-secondary'}
        >
          Boldare ist ein Unternehmen für digitale Produktentwicklung und
          digitales Produktdesign. Wir sorgen dafür, dass Ihre nächste App Ihre
          Kunden begeistert und damit Ihren ROI verbessert. Kontaktieren Sie
          uns noch heute und sehen Sie, was wir für Sie tun können.
        </Heading>
      </Col>
    </Row>
    <div className={'contact-de-banner__contact-btn-background'}>
      <div className={'contact-de-banner__contact-btn-wrapper'}>
        <div>
          <Button
            onClick={() => {
              scroller.scrollTo('form', {
                spy: false,
                smooth: true,
                duration: 700,
                offset: -100,
              });
            }}
            color={'white'}
            id={'contact-now-shortcut'}
            ariaName={'Contact button'}
            additionalClass={'contact-button'}
            hover
          >
            Kontaktieren Sie uns
          </Button>
        </div>
        <div>
          <div className={'contact-de-banner__contact-btn-background__line'} />
        </div>
        <div>
          <div className={'contact-de-banner__contact-btn-background__arrow'}>
            <img
              src={ArrowBlack}
              className={'image'}
              alt={'Arrow to bottom'}
              loading={'lazy'}
            />
          </div>
        </div>
      </div>
    </div>
    <div className={'contact-de-banner__images-wrapper'}>
      <div>
        <div className={'contact-de-banner__car-and-tower'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-car-and-tower.png',
              'v1645906494',
            )}
            className={'image'}
            alt={'Car with tower'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__people-in-circle'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-people-in-circle.png',
              'v1645906494',
            )}
            className={'image'}
            alt={'People with computer'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__ellipse'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-ellipse.png',
              'v1645971584',
            )}
            className={'image'}
            alt={'Ellipse'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__hand'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-hand.png',
              'v1645906724',
            )}
            className={'image'}
            alt={'Hand'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__hand-with-phone'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-hand-with-phone.png',
              'v1645906724',
            )}
            className={'image'}
            alt={'Hand with phone'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__striped-circle-big'}>
          <img
            src={StripedCircleMiddle}
            className={'image'}
            alt={'Striped circle'}
            loading={'lazy'}
          />
        </div>
        <div className={'contact-de-banner__striped-circle-small'}>
          <img
            src={StripedCircleSmall}
            className={'image'}
            alt={'Striped circle'}
            loading={'lazy'}
          />
        </div>
        <div
          className={
            'contact-de-banner__yellow-circle contact-de-banner__yellow-circle-small'
          }
        />
        <div
          className={
            'contact-de-banner__yellow-circle contact-de-banner__yellow-circle-big'
          }
        />
      </div>
    </div>
  </Section>
);
export default Banner;
