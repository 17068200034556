import { Heading, Paragraph, Section, TitleAskCto } from 'components';
import './PreviousEditions.scss';
import React, { useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import PaginationRightIcon from 'assets/images/logos/pagination-right-icon.svg';
import PaginationLeftIcon from 'assets/images/logos/pagination-left-icon.svg';
import PaginationUpIcon from 'assets/images/logos/pagination-up-icon.svg';
import PaginationDownIcon from 'assets/images/logos/pagination-down-icon.svg';
import Carousel from 'react-multi-carousel';

const PreviousEditions = () => {
  const texts = [
    'During our 3rd edition of the CTO asks meetup, ten companies from eight different countries and industries, including digital media, e-commerce, public services and higher education, arrived at the Gliwice office to talk about product design & development.',
    'Following the first edition’s great success, we organized another event. This time we hosted CEOs and CTOs from seven European countries in Gliwice. Everyone brought a different problem and an individual perspective, but the goal was the same: talk about proven methods of outsourcing.',
    'For the first edition of CTO Asks we invited the CEOs of four Dubai-based companies to our office in Gliwice to discuss best practices in outsourcing software development and design. The meeting topics sparked many fruitful debates and led to several long-term partnerships.',
  ];
  const picturesId1 = [
    'gatsby-cloudinary/askcto-previous-1-1.png',
    'gatsby-cloudinary/askcto-previous-1-2.png',
    'gatsby-cloudinary/askcto-previous-1-3.png',
    'gatsby-cloudinary/askcto-previous-1-4.png',
    'gatsby-cloudinary/askcto-previous-1-5.png',
  ];
  const picturesId2 = [
    'gatsby-cloudinary/askcto-previous-2-1.png',
    'gatsby-cloudinary/askcto-previous-2-2.png',
    'gatsby-cloudinary/askcto-previous-2-3.png',
    'gatsby-cloudinary/askcto-previous-2-4.png',
    'gatsby-cloudinary/askcto-previous-2-5.png',
  ];
  const picturesId3 = [
    'gatsby-cloudinary/askcto-previous-3-1.png',
    'gatsby-cloudinary/askcto-previous-3-2.png',
    'gatsby-cloudinary/askcto-previous-3-3.png',
    'gatsby-cloudinary/askcto-previous-3-4.png',
    'gatsby-cloudinary/askcto-previous-3-5.png',
  ];
  const [textId, setTextId] = useState(0);
  const [activeText1, setActiveText1] = useState(true);
  const [activeText2, setActiveText2] = useState(false);
  const [activeText3, setActiveText3] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 0 },
      items: 3,
    },
  };
  const CustomButtonGroup = ({
    next,
    previous,
    goToSlide,
    carouselState,
    ...rest
  }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
      <div className={'custom-button-group'}>
        <button onClick={() => previous()}>
          {' '}
          <img
            id={'pagination-left-icon'}
            src={PaginationLeftIcon}
            alt={'pagination-left-icon'}
            loading={'lazy'}
            className={currentSlide === 0 && 'inactive-previous-gallery'}
          />
        </button>
        <button onClick={() => next()}>
          {' '}
          <img
            id={'pagination-right-icon'}
            src={PaginationRightIcon}
            alt={'pagination-right-icon'}
            loading={'lazy'}
            className={currentSlide === 2 && 'inactive-previous-gallery'}
          />
        </button>
      </div>
    );
  };
  return (
    <Section fullWidth additionalClass={'previous-editions'}>
      <div className={'previous-editions-desktop'}>
        <TitleAskCto title={'Previous editions'} text={texts[textId]} />
      </div>
      <div className={'previous-editions-mobile'}>
        <TitleAskCto title={'Previous editions'} />
      </div>
      <div>
        <div className={'previous-editions-menu-desktop'}>
          <Row>
            <Col sm={12} md={6}>
              <Heading
                additionalClass={
                  textId === 0
                    ? 'previous-editions-titles'
                    : 'previous-editions-titles inactive-title'
                }
              >
                <button onClick={() => setTextId(0)}>CTO asks #3 2018</button>
              </Heading>
              <Heading
                additionalClass={
                  textId === 1
                    ? 'previous-editions-titles'
                    : 'previous-editions-titles inactive-title'
                }
              >
                <button onClick={() => setTextId(1)}>CTO asks #2 2017</button>
              </Heading>
              <Heading
                additionalClass={
                  textId === 2
                    ? 'previous-editions-titles'
                    : 'previous-editions-titles inactive-title'
                }
              >
                <button onClick={() => setTextId(2)}>CTO asks #1 2017</button>
              </Heading>
            </Col>
            <Col sm={12} md={6} className={'previous-editions-carusel'}>
              <div className={'sticky-right-carousel'}>
                <Carousel
                  swipeable
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={false} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  keyBoardControl={false}
                  customTransition={'all .5'}
                  transitionDuration={100}
                  containerClass={'carousel-container-desktop-gallery'}
                  arrows={false}
                  deviceType={'tablet'}
                  dotListClass={'custom-dot-list-style'}
                  customButtonGroup={<CustomButtonGroup />}
                >
                  {textId === 0 &&
                    picturesId1.map(picture => {
                      return (
                        <img
                          className={'image'}
                          alt={'previous-edition-photos-center'}
                          src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                          loading={'lazy'}
                        />
                      );
                    })}
                  {textId === 1 &&
                    picturesId2.map(picture => {
                      return (
                        <img
                          className={'image'}
                          alt={'previous-edition-photos-center'}
                          src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                          loading={'lazy'}
                        />
                      );
                    })}
                  {textId === 2 &&
                    picturesId3.map(picture => {
                      return (
                        <img
                          className={'image'}
                          alt={'previous-edition-photos-center'}
                          src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                          loading={'lazy'}
                        />
                      );
                    })}
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
        <div className={'previous-editions-menu-mobile'}>
          <Heading
            additionalClass={
              activeText1
                ? 'previous-editions-titles'
                : 'previous-editions-titles inactive-title'
            }
          >
            CTO asks #3 2018
            {activeText1 ? (
              <button
                onClick={() => setActiveText1(!activeText1)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationUpIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            ) : (
              <button
                onClick={() => setActiveText1(!activeText1)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationDownIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            )}
          </Heading>
          {activeText1 && (
            <>
              <Paragraph additionalClass={'paragraph-class-active-text'}>
                {texts[0]}
              </Paragraph>
              <Carousel
                swipeable
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                keyBoardControl={false}
                customTransition={'all .5'}
                transitionDuration={100}
                containerClass={'carousel-container'}
                arrows={false}
                deviceType={'tablet'}
                dotListClass={'custom-dot-list-style'}
              >
                {picturesId1.map(picture => {
                  return (
                    <img
                      className={'image'}
                      alt={'previous-edition-photos-center'}
                      src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                      loading={'lazy'}
                    />
                  );
                })}
              </Carousel>
            </>
          )}
          <Heading
            additionalClass={
              activeText2
                ? 'previous-editions-titles'
                : 'previous-editions-titles inactive-title'
            }
          >
            CTO asks #2 2017
            {activeText2 ? (
              <button
                onClick={() => setActiveText2(!activeText2)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationUpIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            ) : (
              <button
                onClick={() => setActiveText2(!activeText2)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationDownIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            )}
          </Heading>
          {activeText2 && (
            <>
              <Paragraph additionalClass={'paragraph-class-active-text'}>
                {texts[1]}
              </Paragraph>
              <Carousel
                swipeable
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                keyBoardControl={false}
                customTransition={'all .5'}
                transitionDuration={100}
                containerClass={'carousel-container'}
                arrows={false}
                deviceType={'tablet'}
                dotListClass={'custom-dot-list-style'}
              >
                {picturesId2.map(picture => {
                  return (
                    <img
                      className={'image'}
                      alt={'previous-edition-photos-center'}
                      src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                      loading={'lazy'}
                    />
                  );
                })}
              </Carousel>
            </>
          )}
          <Heading
            additionalClass={
              activeText3
                ? 'previous-editions-titles'
                : 'previous-editions-titles inactive-title'
            }
          >
            CTO asks #1 2017
            {activeText3 ? (
              <button
                onClick={() => setActiveText3(!activeText3)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationUpIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            ) : (
              <button
                onClick={() => setActiveText3(!activeText3)}
                className={'pagination-arrow-up'}
              >
                <img
                  id={'pagination-up-icon'}
                  src={PaginationDownIcon}
                  alt={'pagination-right-icon'}
                  loading={'lazy'}
                />
              </button>
            )}
          </Heading>
          {activeText3 && (
            <>
              <Paragraph additionalClass={'paragraph-class-active-text'}>
                {texts[2]}
              </Paragraph>
              <Carousel
                swipeable
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                keyBoardControl={false}
                customTransition={'all .5'}
                transitionDuration={100}
                containerClass={'carousel-container'}
                arrows={false}
                deviceType={'tablet'}
                dotListClass={'custom-dot-list-style'}
              >
                {picturesId3.map(picture => {
                  return (
                    <img
                      className={'image'}
                      alt={'previous-edition-photos-center'}
                      src={getCloudinaryUrl(`${picture}`, 'f_auto,q_auto')}
                      loading={'lazy'}
                    />
                  );
                })}
              </Carousel>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default PreviousEditions;
