import React from 'react';
import PropTypes from 'prop-types';

const AnswerOption = props => {
  const {
    answerType,
    answerContent,
    answer,
    onAnswerSelected,
    questionIndex,
  } = props;

  const handleChange = (event) => {
    onAnswerSelected(event);
  };

  return (
    <li className={'app-cost-calc__answer-item'}>
      <input
        type={'radio'}
        className={`app-cost-calc__answer-input step-${questionIndex +
          1}-${answerType}`}
        name={'radioGroup'}
        checked={answerType === answer}
        id={answerType}
        value={answerType}
        disabled={answer}
        onChange={handleChange}
      />
      <label className={'app-cost-calc__answer-label'} htmlFor={answerType}>
        <span className={'app-cost-calc__answer-label-phase'}>
          <span className={'app-cost-calc__answer-label-content'}>
            <span className={'app-cost-calc__answer-label-variant'} />
            {answerContent}
          </span>
        </span>
      </label>
    </li>
  );
};

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  questionIndex: PropTypes.number,
};

AnswerOption.defaultProps = {
  questionIndex: 1,
};

export default AnswerOption;
