import React from 'react';
import PropTypes from 'prop-types';
import './BlogQuoteAuthor.scss';

const BlogQuoteAuthor = ({ text }) => (
  <div className={'quote-author'}>{text}</div>
);

BlogQuoteAuthor.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BlogQuoteAuthor;
