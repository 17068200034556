import React from 'react';
import PropTypes from 'prop-types';

import './ValuesList.scss';

function ValuesList({ children, className }) {
  return <dl className={`description-list ${className}`}>{children}</dl>;
}

ValuesList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ValuesList.defaultProps = {
  className: '',
};

ValuesList.Item = ({ term, value }) => (
  <>
    <dt>{term}</dt>
    <dd>{value}</dd>
  </>
);

ValuesList.Item.propTypes = {
  term: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ValuesList;
