import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import companyData from '../../../meta/companyData';
import config from '../../../meta/config';

const page = pages.resources.items['remote-work-pdf'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/remote-work-pdf/`;

const RemoteWorkPdf = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={
          'Remote product development: tips and tricks to keep it efficient'
        }
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/ebook-remote-pdf-cover.png',
          'f_auto,q_auto,w_652',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-remote-work.png',
          'f_auto,q_auto,w_600',
        )}
        paragraphHeadings={[
          `Remote work comes with many questions:`,
          `Sounds familiar?`,
        ]}
        itemsQuestions
        items={[
          'What tools should I use?',
          'How to guarantee the quality of calls? ',
          'How to report on progress or issues? ',
          'When to attend the dev team meetings?',
        ]}
        outro={`Let us answer them for you, based on our ${companyData.years_experience} years of experience of remote work with companies from around the world.`}
        url={url}
        formHeading={'Send me a free PDF'}
        button={'Send'}
        btnColor={'yellow'}
        trackingEvent={`remoteWorkPdfResource`}
        redirectTitle={'Thank you! Check your email for a link to the ebook.'}
        redirectContent={
          'Visit our blog for more ebooks, guides and more to help you grow.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default RemoteWorkPdf;
