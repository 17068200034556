import React, { Component } from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Instruction.scss';

class Instruction extends Component {
  renderBoxes = () =>
    data.map(item => (
      <div className={'pt__instruction-images'} key={item.image}>
        <div className={'pt__instructions-text'}>
          <Heading type={3} size={'medium-plus'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
        <div className={'pt__instruction-image'}>
          <FullwidthImage
            image={getCloudinaryUrl(
              `assets/practitest/${item.image}`,
              'f_auto,q_auto',
            )}
            alt={item.title}
            additionalClass={'anim-fade-in-bottom'}
          />
        </div>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'pt__instruction-container'}>
        {this.renderBoxes()}
      </Section>
    );
  }
}

export default Instruction;
