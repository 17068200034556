import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { serialize } from 'utils';

export default function withSubscribe(WrappedComponent) {
  return class BonusHOC extends Component {
    static propTypes = { children: PropTypes.node };

    static defaultProps = { children: null };

    constructor(props) {
      super(props);

      this.refForm = createRef();

      this.state = {
        subscribed: false,
      };
    }

    handleSubmit = e => {
      e.preventDefault();

      const { data } = this.props;

      const url = data.bonusFormUrl;
      const form = this.refForm.current;

      this.setState({ isLoading: true });

      fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        body: serialize(form),
      })
        .then(() => {
          if (typeof window !== 'undefined') {
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'bonusFormSubmitted',
              });
            }

            if (window.fbq && typeof window.fbq === 'function') {
              window.fbq('track', 'Sign up');
            }
          }

          this.setState({ subscribed: true });
        })
        .catch(error => console.log(error));
    };

    render() {
      const { subscribed, isLoading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          subscribed={subscribed}
          isLoading={isLoading}
          refForm={this.refForm}
          handleSubmit={this.handleSubmit}
        />
      );
    }
  };
}
