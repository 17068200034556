import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './DigitalTransformationCtaBanner.scss';

const DigitalTransformationCtaBanner = ({ id }) => (
  <div className={'dtcta-banner-content'} id={id}>
    <div className={'dtcta-banner-left'}>
      <img
        src={getCloudinaryUrl(
          'v1701353247/img/blog/dtcta.png',
          'f_auto,q_auto',
        )}
        alt={'Digital Transformation'}
        loading={'lazy'}
        className={'image'}
      />
    </div>
    <div className={'dtcta-banner-right'}>
      <Heading type={3} size={'medium-plus-small'} color={'white'}>
        Need help with digital transformation?
      </Heading>
      <Paragraph size={'medium-constant'} color={'white'}>
        Work with us to get support in strategic planning, execution, and
        expert advice for your products and services.
      </Paragraph>
      <a href={'/services/digital-transformation/'}>
        <Button
          id={`dtcta-banner-button`}
          color={'yellow'}
          additionalClass={`dtcta-banner-button`}
          ariaName={'Contact button'}
        >
          <span className={'dtcta-banner-button-cta'}>Let&apos;s talk</span>
        </Button>
      </a>
    </div>
  </div>
);

DigitalTransformationCtaBanner.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DigitalTransformationCtaBanner;
