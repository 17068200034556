import React, { Component } from 'react';
import './AwwwardsRibbon.scss';

class AwwwardsRibbon extends Component {
  render() {
    return (
      <div id={'awwwards'} className={'awwwards honorable white right'}>
        <a
          href={'https://www.awwwards.com/Boldare/portfolio'}
          rel={'noopener noreferrer'}
          target={'_blank'}
        >
          Awwwards
        </a>
      </div>
    );
  }
}

export default AwwwardsRibbon;
