export const data = [
  {
    title: 'Minimize risk',
    description:
      'Developing digital products is always burdened with risk, however digital prototyping can help you minimize it. A prototype is a tool that allows you to gather data from the market, make informed decisions and plan ahead.',
  },
  {
    title: 'Low cost',
    description:
      'Digital prototypes are quick and involve minimum work. As opposed to a fully-functioning digital product they are usually just mockups of how the product will look or work and don’t involve complex software design or development.',
  },
  {
    title: 'Fast results',
    description:
      'Unlike full versions of an app, developing a digital prototype will usually take between just 1 and 2 weeks. This means you can start testing your concept or pitch with investors quickly and gain a competitive advantage over market contenders.',
  },
  {
    title: 'Innovate',
    description:
      'Instead of following safe solutions, try something new and groundbreaking without engaging too many resources in the development process. See your concept come to life and decide if the world is ready for the next big thing.',
  },
  {
    title: 'User feedback',
    description:
      'Making assumptions about user experience or interface design can lead to complete product  failure. Prototyping digital products mitigates that risk by simulating the UX and UI and giving your users a taste of what they will experience when the product is released to market.',
  },
  {
    title: 'Test a Partnership',
    description:
      'If you are looking to build your app with an external partner what better way to test that partnership than with a prototype? You will be able to dive deep into their style of work, design process and <a href="/blog/software-development-outsourcing-everything-you-should-know/">software development</a> standards before you decide to entrust your product to them.',
  },
  {
    title: 'Plan future development',
    description:
      'When developers and designers build a prototype together they can unearth a lot of potential risks and setbacks related to future work. Additionally, they will fully understand what lies ahead of them and help them plan the first release more accurately.',
  },
];
