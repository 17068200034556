import React from 'react';

import { Row, Col } from 'react-flexbox-grid';
import { Header, Section } from '../../../../components/DS';
import { whoWeAre } from '../../utils'

import './Technology.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'vertical'}
  />
);

const { technology: { title, description, logos }} = whoWeAre;

export const Technology = () => (
  <Section
    heading={<SectionHeader title={title} description={description} />}
    color={'grey-50'}
  >
    <Row className={'technology-logo-list__wrapper'}>
      {logos.map(element => (
        <Col xs={4} md={4} lg={2} className={'technology-logo__wrapper'}>
          <div className={'technology-logo__img'}>
            <img src={element.link} alt={`${element.title} technology logo`} />
          </div>
          <span className={'technology-logo__title'}>{element.title}</span>
        </Col>
      ))}
    </Row>
  </Section>
)
