export const CountryContactPhoneNumbers = {
  'USA': {
    value: '+12122030543',
    displayValue: '+1 212 203 0543'
  },
  'Germany': {
    value: '+494042309797',
    displayValue: '+49 40 423 09797'
  },
  'Netherlands': {
    value: '+31207009337',
    displayValue: '+31 207009337'
  }
}
