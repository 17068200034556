import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import SVG from 'react-inlinesvg';
import tick from 'assets/images/software-development-outsourcing/tick.svg';
import './List.scss';

class List extends Component {
  renderItems = data =>
    data.map(item => (
      <li key={item.skill} className={'career-list__item'}>
        <div className={'career-list__item-icon'}>
          <SVG src={tick} alt={'Career list item'} />
        </div>
        <div className={'career-list__item-label'}>{item.skill}</div>
      </li>
    ));

  render() {
    const { data, title } = this.props;

    return (
      <div className={'career-list__container'}>
        <Heading size={'regular'} type={3}>
          {title}
        </Heading>
        <ul className={'career-list__content'}>{this.renderItems(data)}</ul>
      </div>
    );
  }
}

List.propTypes = {
  title: PropTypes.string,
};

List.defaultProps = {
  title: '',
};

export default List;
