import React, { useEffect, useState } from 'react';
import './Map.scss';
import { getCloudinaryUrl } from 'config/helpers';
import CountUp from 'react-countup';
import inView from 'in-view';

const Map = () => {
  const [enableCounterAnimation, setEnableCounterAnimation] = useState(false);

  useEffect(() => {
    inView('.workroom-map__counter').on('enter', () => {
      setEnableCounterAnimation(true);
    });
  }, []);

  return (
    <div className={'workroom-map'}>
      <img
        src={getCloudinaryUrl('img/Mapa_WR.svg', 'q_100')}
        className={'image'}
        alt={'Map of London'}
        loading={'lazy'}
      />
      <div className={'workroom-map__heading'}>
        <span className={'workroom-map__counter'}>
          {enableCounterAnimation && (
            <CountUp end={40000} separator={','} delay={0.3} />
          )}
        </span>
        <br />
        <span className={'workroom-map__highglight'}>foodservices</span>
        <br />
        in London
      </div>
    </div>
  );
};

export default Map;
