import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { Paragraph } from 'components';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import IconSVG from 'components/IconSVG';
import { kebabCase } from 'lodash';
import { data } from 'components/Footer/logic.js';
import { useWindowWidth } from 'hooks';
import config from '../../../../../meta/config';

import './FooterSection.scss';

const FooterSection = ({ jobs }) => {
  const renderPlaces = () =>
    data.map(item => (
      <div key={item.title} className={'footer__info-place'}>
        <Paragraph
          size={'medium'}
          additionalClass={'footer__info-place--hq text--bold'}
        >
          {item.title}
        </Paragraph>
        <Paragraph additionalClass={'footer__info-address'}>
          <a href={item.map} className={'text--light'}>
            <span id={`footer-map-${kebabCase(item.city.toLowerCase())}`}>
              {item.street}
              <br />
            </span>
          </a>
          <span className={'text--light'}>
            {item.postal}&nbsp;{item.city}
          </span>
          <br />
          <span className={'text--light'}>{item.country}</span>
        </Paragraph>
      </div>
    ));

  const isTabletBig = useWindowWidth() >= 992;

  return (
    <footer className={'f2f__footer'}>
      <div className={'f2f__footer--top'}>
        <div className={'f2f__footer--column'}>
          <a href={'/'} className={'footer__top-logo'}>
            <img
              src={getCloudinaryUrl(
                'remote-work-landing/boldare-logo.svg',
                'f_auto,q_auto,w_72',
              )}
              className={'footer__top-bldr'}
              alt={'Boldare logo'}
              loading={'lazy'}
            />
          </a>

          <div className={'f2f__footer__top--links'}>
            <div className={'f2f__footer__top-sm'}>
              <a
                className={'link'}
                href={config.facebook}
                id={'footer-facebook'}
                rel={'nofollow'}
              >
                <IconSVG
                  name={'facebook-letter'}
                  size={'medium'}
                  color={'black'}
                />
              </a>
              <a
                className={'link'}
                href={config.linkedin}
                id={'footer-linkedin'}
                rel={'nofollow'}
              >
                <IconSVG
                  name={'linkedin-letter'}
                  size={'medium'}
                  color={'black'}
                />
              </a>
              <a
                className={'link'}
                href={config.instagram}
                id={'footer-instagram'}
                rel={'nofollow'}
              >
                <IconSVG name={'instagram'} size={'medium'} color={'black'} />
              </a>
            </div>
            <div className={'f2f__footer__menu-items'}>
              <a
                href={'/privacy-policy'}
                id={'f2f-cookies'}
                className={'link text--light'}
              >
                <span className={'footer__cookies'}>Cookies</span>
              </a>
              <a
                href={'/privacy-policy'}
                id={'f2f-terms'}
                className={'link text--light'}
              >
                <span className={'footer__cookies'}>Terms & Policy</span>
              </a>
            </div>
          </div>
        </div>

        <div className={'f2f__footer--column'}>
          <Paragraph
            size={'medium'}
            additionalClass={'footer__info-jobs--hq text--bold'}
          >
            Jobs
          </Paragraph>
          <div className={'f2f__jobs-list'}>
            {jobs.map(item => (
              <a
                className={`link text--light ga-f2f--footer-${item.node.frontmatter.mainContent.name.replace(
                  / /g,
                  '-',
                )}`}
                href={item.node.frontmatter.mainContent.apply}
                rel={'nofollow'}
              >
                {item.node.frontmatter.mainContent.name}
              </a>
            ))}
          </div>
        </div>

        <div className={'f2f__footer--column'}>{renderPlaces()}</div>
      </div>
      <div className={'f2f__footer--bottom text--light'}>
        <p className={'footer__copyright'}>
          Boldare Career <span>{new Date().getFullYear()}&copy;</span>
        </p>
        {isTabletBig && (
          <div className={'footer__navigation'}>
            <button onClick={() => scrollTo('#remote-top')}>
              Go back to the top &#8593;
            </button>
          </div>
        )}
        <a href={'/career'} className={'footer__copyright'}>
          &#8592; Go to career
        </a>
      </div>
    </footer>
  );
};

FooterSection.propTypes = {
  jobs: PropTypes.array.isRequired,
};

export default FooterSection;
