import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from 'react-markdown-renderer';
import { Section, SectionHeader, VideoPlayer } from 'components';
import { classNames } from 'utils';
import './Intro.scss';

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoReady: false,
    };
  }

  render() {
    const { isVideoReady } = this.state;
    const { video, lead, title, cover, mainColor } = this.props;

    return (
      <Section color={'mine-shaft'} spaceless>
        <div
          className={classNames('intro__container', {
            [`intro__container--${mainColor}`]: mainColor,
          })}
        >
          <div className={'intro__media'}>
            {video && (
              <VideoPlayer
                containerClass={'intro__video'}
                playerClass={'intro__player'}
                src={video}
                muted
                playing
                loop
                width={'100%'}
                height={'auto'}
                onStart={() => this.setState({ isVideoReady: true })}
              />
            )}
            {!isVideoReady && (
              <img
                src={cover}
                alt={`${title} placeholder`}
                className={'image intro__placeholder'}
                loading={'lazy'}
              />
            )}
          </div>
          <SectionHeader
            description={<MarkdownRenderer markdown={lead} />}
            size={'large'}
            descriptionColor={'white'}
            lineColor={mainColor}
            color={'white'}
            additionalClass={'intro__header'}
            fixed
          >
            {title}
          </SectionHeader>
        </div>
      </Section>
    );
  }
}

Intro.defaultProps = {
  mainColor: 'yellow',
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  mainColor: PropTypes.string,
};

export default Intro;
