import React from 'react';
import { Heading } from 'components';
import { TextInteractionAnimation } from 'views/f2f/components';
import './TypesSliderSection.scss';
import { TypesSlider } from './subcomponents';
import { data } from './data';

const TypesSliderSection = () => (
  <section id={'f2f-types'} className={'f2f-section-types'}>
    <Heading size={'big'} additionalClass={'section-types__heading'} type={2}>
      <span>What are our ideas</span>
      <br />
      <span>to </span>
      <TextInteractionAnimation text={'strengthen team'} />
      <span> spirit?</span>
    </Heading>
    <TypesSlider id={'ideas-types-slider'} items={data} />
  </section>
);

export default TypesSliderSection;
