export const data = [
  {
    heading: 'Rapid Services Team',
    description:
      'This team is mostly delivering prototypes and MVP’s. Fast development, testing hypotheses, creativity and openness to change - this is their speciality!',
    alt: 'Rapid Services Team',
    cloudinaryDir: 'rapid-services-team',
    ctaTitle: 'What is their speciality?',
    ctaDescription:
      'fast development, testing hypotheses and openness to change',
  },
  {
    heading: 'Product Market Fit Team',
    description:
      'This crew knows the best way to adjust the product to the users’ needs! Their superpowers? User tests, data analysis and continuous improvement of the product!',
    alt: 'Product Market Fit Team',
    cloudinaryDir: 'scaling-team',
    ctaTitle: 'What are their superpowers?',
    ctaDescription: 'user tests & data analysis, the clients needs research',
  },
  {
    heading: 'Product Scaling Team',
    description:
      'These guys build complex, scalable products with huuuge amount of users! Vast technical knowledge, familiarity with various languages & tools are their main strengths!',
    alt: 'Scaling Product Team',
    cloudinaryDir: 'product-market-fit-team',
    ctaTitle: 'Their main strength is:',
    ctaDescription:
      'vast technical knowledge, familiarity with various languages, long term solutions',
  },
];
