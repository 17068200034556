import React from 'react';
import { Heading, IconSVG, Paragraph } from 'components';
import ReactHtmlParser from 'react-html-parser';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';
import './BenefitsSlider.scss';
import Glide from '@glidejs/glide';
import classnames from 'classnames';
import { SliderNav } from 'views/remoteWork/components';

class BenefitsSlider extends React.Component {
  constructor(props) {
    super(props);
    this.slidesContainer = React.createRef();
  }

  componentDidMount() {
    this.slider = new Glide('#benefits-slider', {
      type: 'carousel',
      animationDuration: 700,
      animationTimingFunc: 'ease-out',
      classes: {
        activeSlide: 'benefits-slider__slide--active',
        activeNav: 'slider-nav__bullet--active',
      },
      perView: 1.5,
      startAt: 0,
      focusAt: 0,
      breakpoints: {
        1540: {
          perView: 1.4,
        },
        1450: {
          perView: 1.3,
        },
        1350: {
          perView: 1.2,
        },
        1250: {
          perView: 1.1,
        },
        1160: {
          perView: 1.01,
        },
        1090: {
          perView: 0.95,
        },
        1024: {
          perView: 1.5,
        },
        600: {
          perView: 1.25,
        },
        480: {
          perView: 1.15,
        },
      },
    }).mount();

    this.slidesContainer.current.childNodes.forEach(el =>
      el.addEventListener('click', () => {
        this.slider.go(`=${el.getAttribute('data-slide-index')}`);
      }),
    );
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    const { id, items } = this.props;

    return (
      <div id={id} className={classnames('glide', 'benefits-slider')}>
        <div className={'glide__track'} data-glide-el={'track'}>
          <ul className={'glide__slides'} ref={this.slidesContainer}>
            {items.map((item, index) => (
              <li className={'glide__slide'} data-slide-index={index}>
                <div className={'benefits-slider__item'}>
                  <div className={'slide-number-wrapper'}>
                    <Paragraph
                      additionalClass={'slide-number text--bold'}
                      color={'white'}
                      size={'big'}
                    >
                      {ReactHtmlParser(item.number)}
                    </Paragraph>
                  </div>
                  <div className={'slide-text-container'}>
                    <Heading additionalClass={'slide-heading'} size={'big'}>
                      {ReactHtmlParser(item.title)}
                    </Heading>
                    <Paragraph
                      additionalClass={'slide-description'}
                      size={'medium'}
                    >
                      {ReactHtmlParser(item.description)}
                    </Paragraph>
                  </div>
                  <img
                    className={`slide-image ${item.className}`}
                    alt={item.alt}
                    src={getCloudinaryUrl(
                      [item.cloudinaryDir],
                      `f_auto,q_auto,w_${item.sizeWidth}`,
                    )}
                    loading={'lazy'}
                  />
                  <IconSVG
                    name={'arrow-right'}
                    additionalClass={'benefits-slider__slide-arrow'}
                    color={'white'}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <SliderNav additionalClass={'benefits-slider__nav'} items={items} />;
      </div>
    );
  }
}
BenefitsSlider.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      cloudinaryDir: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BenefitsSlider;
