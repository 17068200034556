import React from 'react';
import { Heading, IconSVG, Paragraph, Section } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import { listOfServices } from 'views/digitalTransformationJourney/subcomponents/Services/utils';
import { Link } from 'gatsby';

import './Services.scss';

export const Services = () => {
  return (
    <Section
      additionalClass={`
          digital-transform-journey-services__section
          digital-transform-journey-services__section-spacing
          digital-transform-journey-services--position-relative
        `}
      color={'mine-shaft'}
    >
      <div className={'digital-transform-journey-services__wrapper'}>
        <div
          className={'digital-transform-journey-services__background-box'}
        />
        <Row
          start={'sm'}
          className={`
              digital-transform-journey-services__row-title
            `}
        >
          <Row className={'no-gap '}>
            <Col sm={12} md={4}>
              <Heading
                type={4}
                size={'extra-large'}
                color={'white'}
                additionalClass={`
                  digital-transform-journey-services--move-up
                  digital-transform-journey-services--position-relative
                  digital-transform-journey-services--font-weight-bold
                `}
              >
                Services
              </Heading>
            </Col>
          </Row>
        </Row>
        <Row
          className={`
          digital-transform-journey-services--move-up
          digital-transform-journey-services--right
          digital-transform-journey-services--position-relative
          digital-transformation-journey-services--padding-right
          digital-transform-journey-services__row-list-of-services
        `}
        >
          {listOfServices.map(({ title, subtitle, categories }) => (
            <Col
              xs={12}
              md={6}
              xl={4}
              className={
                'digital-transform-journey-services__row-list-of-services-col'
              }
            >
              <Row
                bottom={'xs'}
                className={`digital-transform-journey-services--list-of-services__header-container`}
              >
                <Col xs={10} style={{ padding: 0 }}>
                  <Paragraph
                    color={'white'}
                    size={'big'}
                    additionalClass={`
                      digital-transform-journey-services--spacing
                      digital-transform-journey-services--font-weight-bold
                      digital-transform-journey-services--list-of-services__title
                    `}
                  >
                    {title}
                  </Paragraph>
                </Col>
                <Row>
                  <Col
                    xs={11}
                    className={'digital-transform-journey-services--nospacing'}
                  >
                    <Paragraph
                      color={'white'}
                      size={'medium'}
                      additionalClass={`
                      digital-transform-journey-services--font-weight-regular
                      digital-transform-journey-services--list-of-services__subtitle
                  `}
                    >
                      {subtitle}
                    </Paragraph>
                  </Col>
                </Row>
              </Row>
              <Row middle={'xs'}>
                <Col
                  xs={12}
                  className={'digital-transform-journey-services--nospacing'}
                >
                  <ul>
                    {categories.map(({ label, url }) => (
                      <li
                        className={`digital-transform-journey-services--list-of-services__item-of-categories`}
                      >
                        <Row>
                          <Col
                            xs={10}
                            className={
                              'digital-transform-journey-services--list-of-services__item-of-categories-label-col'
                            }
                          >
                            <Link to={url} target={'_blank'} />
                            <Paragraph color={'white'} size={'medium'}>
                              {label}
                            </Paragraph>
                          </Col>
                          <Col
                            xs={2}
                            className={
                              'digital-transform-journey-services--list-of-services__item-of-categories-icon_wrapper'
                            }
                          >
                            <Row>
                              <Col
                                className={`
                                digital-transform-journey-services--list-of-services__item-of-categories-icon-col
                              `}
                              >
                                <IconSVG
                                  name={'arrow-right'}
                                  size={'medium'}
                                  color={'white'}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </Section>
  );
};
