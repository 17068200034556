import React from 'react';
import PropTypes from 'prop-types';

import { ContactForm as CommonContactForm } from 'components';

function ContactForm({ id, name }) {
  return (
    <CommonContactForm.Black
      id={id}
      name={name}
      topic={'Discovery Session Submission'}
      pardotLink={'https://go.boldare.com/l/688533/2023-07-27/2l9rx7'}
      button={'SUBMIT'}
      header={() => (
        <h2
          className={
            'book-discovery-session__heading book-discovery-session__heading--white'
          }
          id={`form-${id}`}
        >
          Leave your contacts to book a meeting:
        </h2>
      )}
      responseSendContactHeader={() => (
        <>Thank you! Your discovery session request is sent.</>
      )}
      responseSendContactHeaderTwo={() => (
        <CommonContactForm.Black.ResponseSendContactHeaderTwo
          texts={[
            'We will get back to you within 24 business hours.',
            'If you need immediate assistance, call us on ',
          ]}
        />
      )}
    />
  );
}

ContactForm.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ContactForm;
