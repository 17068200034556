import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import { classNames } from 'utils';
import './AbsoluteCTA.scss';

const AbsoluteCTA = ({
  additionalClass,
  linkText,
  description,
  underlineColor,
  linkID,
  link,
  backgroundColor,
  textColor,
}) => (
  <div className={'absolute-cta__container'}>
    <div
      className={classNames(
        'absolute-cta',
        {
          [`absolute-cta--${backgroundColor}`]: backgroundColor,
        },
        additionalClass,
      )}
    >
      <Paragraph size={'medium-plus'} color={textColor}>
        <span className={'text--light'}>{description}&nbsp;</span>
        <a href={link} underline={underlineColor}>
          <span
            id={linkID}
            className={classNames('text--bold', {
              [`text--${textColor}`]: textColor,
            })}
          >
            {linkText}
          </span>
        </a>
      </Paragraph>
    </div>
  </div>
);

AbsoluteCTA.defaultProps = {
  additionalClass: '',
  linkID: '',
  linkText: 'Let’s talk',
  description: "We'd love to help with your product.",
  underlineColor: 'white',
  backgroundColor: 'yellow',
  textColor: 'black',
  link: '/contact/#form',
};

AbsoluteCTA.propTypes = {
  additionalClass: PropTypes.string,
  linkID: PropTypes.string,
  linkText: PropTypes.string,
  description: PropTypes.string,
  underlineColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  link: PropTypes.string,
};

export default AbsoluteCTA;
