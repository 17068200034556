import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import {
  Architecture,
  Contact,
  Cooperation,
  Design,
  Details,
  Hero,
  Instruction,
  Intro,
  OldWebsite,
  Team,
  Technology,
} from './subcomponents';
import './Practitest.scss';

class Practitest extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study pt__container'}>
        <Hero />
        <Intro />
        <Cooperation />
        <Architecture />
        <OldWebsite />
        <Design />
        <Technology />
        <Team />
        <Contact />
      </div>
    );
  }
}

export default Practitest;
