import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { enableAnimations } from 'config/helpers';
import { getPreparedOffers } from 'views/career/career.utils';
import { Different, Contact, Instagram } from '../career/subcomponents';
import { OffersAdvanced } from './subcomponents';
import '../career/Career.scss';
import config from '../../../meta/config';

const { promotedOffersDeadline } = config.career;

class CareerOffers extends PureComponent {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { offers } = this.props;
    const preparedOffers = getPreparedOffers(offers);

    return (
      <div className={'single-page single-page--hero career'}>
        <OffersAdvanced
          offers={preparedOffers}
          promotedOffersDeadline={new Date(promotedOffersDeadline)}
        />

        {!!offers.length && <Contact />}

        <Different />

        <Instagram />
      </div>
    );
  }
}

CareerOffers.propTypes = {
  offers: PropTypes.array.isRequired,
};

export default CareerOffers;
