import React from 'react';
import PropTypes from 'prop-types';

import './PageDescription.scss';

const PageDescription = ({ data }) => {
  return (
    <>
      <div className={'page-description-definitions'}>
        {data.definitions.map(item => (
          <div
            key={`definition-${item.title}`}
            className={'page-description-definitions__item'}
          >
            <span className={'page-description-definitions__title'}>
              {item.title}
            </span>
            <span className={'page-description-definitions__text'}>
              {item.text}
            </span>
          </div>
        ))}
      </div>
      <div className={'page-description'}>
        {data.items.map(item => (
          <div
            key={`definition-${item.title}`}
            className={'page-description__item'}
          >
            <h3 className={'page-description__title'}>{item.title}</h3>
            {item.description.map(text => (
              <p
                key={`description-${text.slice(0, 10)}`}
                className={'page-description__text'}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default PageDescription;

PageDescription.propTypes = {
  data: PropTypes.object.isRequired,
};
