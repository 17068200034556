import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { kebabCase } from 'lodash';
import {
  Box,
  BoxContent,
  BoxFooter,
  Heading,
  Paragraph,
  IconSVG,
  Button,
  BackgroundImage,
  SectionHeader,
} from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import './BlogSlider.scss';

class BlogSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'blog-slider',
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 0,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
        afterChange: current => {
          this.setState({ currentSlide: current });
        },
      },
      navSettings: {
        className: 'blog-slider-nav',
        slidesToShow: props.data.length,
        swipeToSlide: true,
        focusOnSelect: true,
        infinite: true,
      },
      slider: null,
      sliderNav: null,
      sliderNavColor: null,
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  renderSlides = () => {
    const { data, theme } = this.props;
    const colors =
      theme === 'light'
        ? {
            primary: 'white',
            secondary: 'mine-shaft',
            text: 'black',
            line: 'black',
          }
        : {
            primary: 'mine-shaft',
            secondary: 'white',
            text: 'white',
            line: 'golden-dream',
          };

    return data.map(item => (
      <div
        className={'blog-slider__slide-container w-container--side-left'}
        key={`culture-slide-${item.id}`}
      >
        <div
          className={classNames('blog-slider__slide-wrapper', {
            [`blog-slider__slide-wrapper--${item.color}`]: item.color,
          })}
        >
          <div className={'blog-slider__image'}>
            <BackgroundImage
              asset={item.background}
              additionalClass={'blog-slider__slide'}
              alt={item.caption}
            />
            {data.length > 1 && (
              <div
                className={classNames('blog-slider__navigation', {
                  [`blog-slider__navigation--${colors.secondary}`]: colors.secondary,
                })}
              >
                <div
                  id={'blog-slider-prev'}
                  role={'presentation'}
                  onClick={() => this.slider.slickPrev()}
                >
                  <IconSVG name={'arrow-left'} color={colors.primary} />
                </div>
                <div
                  id={'blog-slider-next'}
                  role={'presentation'}
                  onClick={() => this.slider.slickNext()}
                >
                  <IconSVG name={'arrow-right'} color={colors.primary} />
                </div>
              </div>
            )}
          </div>
          {item.link ? (
            <div
              className={classNames('blog-slider__item', {
                [`blog-slider__item--${colors.primary}`]: colors.primary,
              })}
            >
              <Box
                additionalClass={'blog-slider__box'}
                backgroundColor={colors.primary}
              >
                <BoxContent type={'slider'}>
                  <a
                    href={item.link}
                    id={`box-${kebabCase(item.content.toLowerCase())}`}
                    arrow
                  >
                    <Heading
                      size={'big'}
                      color={colors.text}
                      type={3}
                      additionalClass={'blog-slider__box-headline'}
                    >
                      {item.content}
                    </Heading>
                  </a>
                  <Paragraph
                    size={'medium'}
                    color={colors.text}
                    additionalClass={'text--light'}
                    spaced
                  >
                    {item.description}
                  </Paragraph>
                  {item.awards && (
                    <div className={'blog-slider__awards'}>
                      {item.awards.map(award => (
                        <img
                          src={getCloudinaryUrl(
                            award,
                            'f_auto,q_auto/img/awwwards/awards/',
                          )}
                          alt={award}
                          loading={'lazy'}
                        />
                      ))}
                    </div>
                  )}
                </BoxContent>
                <BoxFooter>
                  <a href={item.link} rel={'nofollow'}>
                    <Button
                      order={'secondary'}
                      color={colors.secondary}
                      ariaName={'Case Study'}
                    >
                      Case study
                    </Button>
                  </a>
                </BoxFooter>
              </Box>
            </div>
          ) : (
            <div
              className={classNames('blog-slider__item', {
                [`blog-slider__item--${colors.primary}`]: colors.primary,
              })}
            >
              <Box
                additionalClass={'blog-slider__box'}
                backgroundColor={colors.primary}
              >
                <BoxContent type={'slider'}>
                  <SectionHeader
                    size={'medium'}
                    color={colors.text}
                    headingType={3}
                    lineColor={colors.line}
                    additionalClass={'blog-slider__box-headline'}
                  >
                    {item.content}
                  </SectionHeader>
                  <Paragraph
                    size={'medium'}
                    color={colors.text}
                    additionalClass={'text--light'}
                    spaced
                  >
                    {item.description}
                  </Paragraph>
                  {item.awards && (
                    <div className={'blog-slider__awards'}>
                      {item.awards.map(award => (
                        <img
                          src={getCloudinaryUrl(
                            award,
                            'f_auto,q_auto/img/awwwards/awards/',
                          )}
                          alt={award}
                          loading={'lazy'}
                        />
                      ))}
                    </div>
                  )}
                </BoxContent>
              </Box>
            </div>
          )}
        </div>
      </div>
    ));
  };

  renderNav = () => {
    const { currentSlide } = this.state;
    const { data, navColor } = this.props;

    return data.map((item, idx) => (
      <div
        className={classNames('blog-slider-nav__item', 'pointer', {
          [`blog-slider-nav__item--${item.color || 'yellow'}`]:
            idx === currentSlide,
        })}
      >
        <Paragraph
          additionalClass={'blog-slider-nav__item-number'}
          color={
            (idx === currentSlide && (item.color || 'yellow')) || navColor
          }
        >
          {`${`0${idx + 1}`.slice(-2)}`}.
        </Paragraph>
        <Paragraph
          additionalClass={'blog-slider-nav__item-label'}
          color={navColor}
        >
          {item.content}
        </Paragraph>
      </div>
    ));
  };

  render() {
    const { sliderSettings, navSettings } = this.state;
    const { showNav, sliderNavColor } = this.props;

    const theme = {
      primary: sliderNavColor === 'light' ? 'white' : 'mine-shaft',
    };

    return (
      <>
        <Slider
          asNavFor={this.state.sliderNav}
          ref={slider => {
            this.slider = slider;
          }}
          {...sliderSettings}
        >
          {this.renderSlides()}
        </Slider>
        {showNav && (
          <div
            className={classNames(
              'blog-slider-nav',
              {
                [`blog-slider-nav--${theme.primary}`]: theme.primary,
              },
              'w-container',
              'hide-mobile',
            )}
          >
            <Slider
              asNavFor={this.state.slider}
              ref={slider => {
                this.sliderNav = slider;
              }}
              {...navSettings}
            >
              {this.renderNav()}
            </Slider>
          </div>
        )}
      </>
    );
  }
}

BlogSlider.defaultProps = {
  data: [],
  theme: 'light',
  showNav: false,
  navColor: 'black',
  sliderNavColor: '',
};

BlogSlider.propTypes = {
  data: PropTypes.array,
  theme: PropTypes.string,
  showNav: PropTypes.bool,
  navColor: PropTypes.string,
  sliderNavColor: PropTypes.string,
};

export default BlogSlider;
