import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section } from 'components';
import withWaypointsContainer from 'components/WayPoint/Container/HOC';
import { enableAnimations } from 'config/helpers';
import {
  Hero,
  Approach,
  Culture,
  WhatWeDo,
  Work,
  CompetitiveAdvantages,
  Prizes,
} from './subcomponents';
import { SesBanner } from '../../components/SeS/index';
import './Home.scss';

class HomePage extends Component {
  static propTypes = {
    entryViews: PropTypes.array.isRequired,
    leaveViews: PropTypes.array.isRequired,
    clearActiveViews: PropTypes.func.isRequired,
  };

  componentDidMount() {
    enableAnimations();
    window.addEventListener('beforeunload', this.sendPathBeforeUnload);
  }

  componentWillUnmount() {
    this.sendPathSequenceEvent();
    window.removeEventListener('beforeunload', this.sendPathBeforeUnload);
  }

  sendPathBeforeUnload = e => {
    if ((e.srcElement || e.target).activeElement !== document.body) {
      return;
    }
    this.sendPathSequenceEvent();
  };

  sendPathSequenceEvent() {
    if (
      typeof window === 'undefined' ||
      typeof window.dataLayer === 'undefined'
    ) {
      return;
    }

    const { entryViews, leaveViews, clearActiveViews } = this.props;

    const views = leaveViews.filter(lv => lv.timeSpent >= 2);
    if (entryViews.length) {
      views.push(entryViews[entryViews.length - 1]);
    }

    let pathSequence = '';

    views.forEach(v => {
      pathSequence = pathSequence ? `${pathSequence}=>${v.path}` : `${v.path}`;
    });

    clearActiveViews();

    if (pathSequence) {
      window.dataLayer.push({
        event: 'userPath',
        eventCategory: 'User Path Tracking',
        pathSequence,
      });
    }
  }

  render() {
    return (
      <div className={'home-content'}>
        <Hero />

        <Section color={'gray-light'} id={'work'}>
          <Work />
        </Section>

        <SesBanner pageName={'home'} />

        <Section color={'white'} id={'advantages'}>
          <CompetitiveAdvantages variant={'competitive-advantages-b'} />
        </Section>

        <Section color={'white'} id={'awards-and-prizes'} spaceless fullwidth>
          <Prizes />
        </Section>

        <Section id={'services'} color={'mine-shaft'} fullwidth spaceless>
          <WhatWeDo />
        </Section>

        <Section id={'approach'} fullwidth spaceless>
          <Approach />
        </Section>

        <Section id={'culture'} color={'gray-light'} stick spaceless>
          <Culture />
        </Section>

        <Section
          background={'gatsby-cloudinary/office-6.jpg'}
          fullwidth
          spaceless
        />
      </div>
    );
  }
}

export default withWaypointsContainer(HomePage);
