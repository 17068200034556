import React, { Component } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { Section, SectionHeader, AbsoluteCTA } from 'components';
import { data } from './logic';
import './Clients.scss';

class Clients extends Component {
  renderItems = () =>
    data.map((item, index) => (
      <div className={'work-clients__item'} key={`award-${index}`}>
        <div className={classNames('image', 'work-clients__link')}>
          {item.link ? (
            <a href={item.link} title={item.alt}>
              <img
                src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_190')}
                className={classNames('work-clients__image', {
                  'work-clients__image--vertical': item.vertical,
                  'work-clients__image--zero-brightness': item.black,
                })}
                alt={item.alt || 'Boldare clients'}
                loading={'lazy'}
              />
            </a>
          ) : (
            <img
              src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_190')}
              className={classNames('work-clients__image', {
                'work-clients__image--vertical': item.vertical,
                'work-clients__image--zero-brightness': item.black,
              })}
              alt={item.alt || 'Boldare clients'}
              loading={'lazy'}
            />
          )}
        </div>
      </div>
    ));

  render() {
    return (
      <>
        <Section additionalClass={'work-clients'} spaceless side={'right'}>
          <div className={'section work-clients__inner'}>
            <SectionHeader
              additionalClass={
                'section-header__description--medium work-banner__content'
              }
              color={'black'}
              lineColor={'yellow'}
              size={'large'}
              headingType={2}
            >
              Our Clients
            </SectionHeader>
            <div className={'work-clients__list anim-fade-in-bottom'}>
              {this.renderItems()}
            </div>
          </div>
        </Section>

        <AbsoluteCTA id={'page-work-clients'} link={'#form-work'} />
      </>
    );
  }
}

export default Clients;
