import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { getQueryParam } from 'config/helpers';
import { windowGlobal } from 'config/constants/dimensions';

export const useScrollAnimation = elements => {
  useEffect(() => {
    const callback = throttle(() => {
      elements.forEach(element => {
        const bounding = element.ref.current.getBoundingClientRect();

        if (bounding.top > 0) {
          const animationPercentage = 1 - bounding.top / window.innerHeight;
          const transformState = Object.keys(element.transform)
            .map(key => {
              const value = Number.parseFloat(element.transform[key]);
              const unit = element.transform[key].split(value)[1];
              return `${key}(${Math.floor(value * animationPercentage) +
                unit})`;
            })
            .join(' ');

          element.ref.current.style.transform = transformState;
        }
      });
    }, 10);

    document.addEventListener('scroll', callback);

    return () => document.removeEventListener('scroll', callback);
  }, []);
};

export const useValueDependOnQueryParam = (
  queryParamKey,
  defaultValue,
  valuesForParams,
) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    const queryParam = getQueryParam(queryParamKey);
    if (valuesForParams[queryParam]) {
      setValue(valuesForParams[queryParam]);
    }
  }, []);
  return value;
};

export const useWindowWidth = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    windowGlobal.innerWidth,
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(windowGlobal.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
