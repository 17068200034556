import React from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

import { pushToDataLayer } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import {
  CountryFlag,
  CoverImage,
  Description,
  HashTags,
  Heading,
} from 'components/WorkBoxes/subcomponents';

import './Brief.scss';

const Brief = ({ post, isVariantB, onFilterSelect, navigatePage }) => {
  const { type, content } = post;
  const {
    title,
    coverImage,
    country,
    description,
    industry,
    productStage,
    region,
  } = content;
  const image = getCloudinaryUrl(coverImage, 'q_auto,f_auto,h_320');
  const tags = {
    Industry: industry,
    ProductStage: productStage,
    Region: region,
  };

  const onClick = (event, label, filter) => {
    pushToDataLayer(`box-brief-${isVariantB ? 'b' : 'a'}`);

    onFilterSelect(event, label, filter, navigatePage);

    scroller.scrollTo('case-studies', {
      spy: false,
      smooth: true,
      duration: 500,
      offset: -145,
    });
  };

  return (
    <div className={'work-brief-box'}>
      <CoverImage
        image={image.src ? image.src : image}
        ratio={0.6}
        alt={`${title} - ${type} cover`}
        small
      />
      <div className={'work-brief-box__container'}>
        <CountryFlag country={country} />
        <Heading text={title} isVariantB={isVariantB} />
        <Description text={description} fullText />
        <HashTags tags={tags} onClick={onClick} />
      </div>
    </div>
  );
};

Brief.defaultProps = {
  isVariantB: false,
};

Brief.propTypes = {
  isVariantB: PropTypes.bool,
  post: PropTypes.object.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  navigatePage: PropTypes.func.isRequired,
};

export default Brief;
