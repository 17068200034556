import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';

import './Description.scss';

export default function Description() {
  return (
    <div className={'ses-description'}>
      <div className={'ses-description__image'}>
        <img
          src={getCloudinaryUrl(
            `recommend-client/referral-1.png`,
            'f_auto,q_auto',
          )}
          alt={'Team at workshop'}
        />
      </div>
      <div className={'ses-description__text'}>
        <h2 className={'heading heading--large'}>
          Service Standards - what do they cover
        </h2>
        <p className={'paragraph paragraph--medium-constant'}>
          They consist of clearly described roles and responsibilities (i.e.
          Tech Lead, Delivery Lead), meetings structures and types, and a
          matrix defining the documents created during a collaboration with a
          client (i.e. delivery plan, sprint reports).
        </p>
      </div>
    </div>
  );
}
