// YOU NEED IMPORT THAT FILE AS A COMPONENT

import mixpanel from 'mixpanel-browser';

const mixpanelTracking = mixpanel.init(
  '7a79a4f0412c63d92de9093c39af3eb4',
  { api_host: 'https://api-eu.mixpanel.com' },
  '',
);

export default mixpanelTracking;
