interface Recruiter {
  role: string;
  name: string;
  imageUrl: string;
  description: string;
  boldQuestion?: string;
}

type ListOfRecruiters = [Recruiter] | [Recruiter, Recruiter] | [Recruiter, Recruiter, Recruiter];

export const recruiters: ListOfRecruiters = [
  {
    role: 'Product designer',
    name: 'Aleksandra Czechońska',
    imageUrl: 'https://res.cloudinary.com/de4rvmslk/image/upload/v1689150120/career/recruiters/ola-czechonska.png',
    description: 'Hi, I am always interested in finding simple solutions to complicated problems. My mission is to build products that create real customer and business value. Accessibility and inclusivity are special attractive fields for me. I believe a phased approach to building products at Boldare makes the designer’s work more optimized.',
    boldQuestion: 'How about talking about it and sharing some pro tips?'
  },
  {
    role: 'Java Software Engineer',
    name: 'Maciej Król',
    imageUrl: 'https://res.cloudinary.com/de4rvmslk/image/upload/v1689150120/career/recruiters/maciej-krol.png',
    description: 'Hi, I’ve worked at Boldare as a Java Software Engineer since 2020. Currently, I am also preparing to the role of tech lead for my team. Most of my time is programming and gathering requirements for new functionalities - we scale and look for new markets for the product.',
    boldQuestion: 'Let’s chat about possibilities at Boldare and your development path!'
  },
  {
    role: 'Senior Software Developer',
    name: 'Józef Flakus',
    imageUrl: 'https://res.cloudinary.com/de4rvmslk/image/upload/v1689150120/career/recruiters/jozef-flakus.png',
    description: 'I am a Senior Software Developer in the JavaScript/Node.js area. I also work as a technology solution architect and design technology solutions for clients. Together with my team, we prepare a plan of action, decide how to solve a specific client’s issues, and build new increments. Pair programming, code review, and collaborative problem-solving at brainstorms are standard at Boldare.',
    boldQuestion: 'Would you like to learn more?'
  }
];
