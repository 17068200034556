import React from 'react';
import { Section, SectionHeader, Paragraph, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { Author } from 'views/singleArticle/subcomponents';
import { classNames } from 'utils';
import withSubscribe from './HOC';
import './Subscribe.scss';

const Subscribe = ({ subscribed, isLoading, refForm, handleSubmit }) => (
  <Section color={'yellow'} additionalClass={'machine-learning__subscribe'}>
    <div>
      <SectionHeader
        size={'medium'}
        headingType={3}
        lineColor={'white'}
        description={'Leave us your mail and find out how we can help you.'}
        additionalClass={'section-header__description--medium'}
      >
        Get in touch with our prototyping consultant.
      </SectionHeader>
      {subscribed ? (
        <Paragraph size={'medium'} additionalClass={'text--bold'}>
          Thank you for contacting us. Our consultant will back to you within
          24 hours.
        </Paragraph>
      ) : (
        <form
          className={'newsletter-small__form'}
          onSubmit={handleSubmit}
          ref={refForm}
        >
          <input
            type={'email'}
            name={'email'}
            placeholder={'E-mail address'}
            required
          />
          <Button
            id={`machine-learning-subscribe`}
            additionalClass={classNames('newsletter-submit-small', {
              loading: isLoading,
            })}
            type={'submit'}
            color={'yellow'}
            ariaName={'Subscribe button'}
            disabled={isLoading}
            loading
          >
            Subscribe
          </Button>
        </form>
      )}
    </div>
    <Author
      avatar={getCloudinaryUrl(
        'event/ula_augustyniak.png',
        'f_auto,q_auto,h_300',
      )}
      name={'Urszula Augustyniak'}
      job={'Product Designer'}
      link={'ula-augustyniak'}
    />
  </Section>
);

export default withSubscribe(Subscribe);
