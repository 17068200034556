import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import NewWorkBlog from 'views/blog/new-work';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

export default class NewWorkPage extends PureComponent {
  render() {
    const page = pages.blog.items['new-work'];
    const { navColors } = page;
    const canonical = `${config.siteUrl}/blog/new-work/`;

    const { data } = this.props;

    const posts = data.posts.edges;
    const authors = data.authors.edges;

    const filters = data.filters.edges.map(item => ({
      name: item.node.frontmatter.title,
      order: item.node.frontmatter.order,
    }));

    return (
      <Layout {...{ page, canonical }}>
        <Helmet title={'New Work Blog'}>
          <meta name={'description'} content={''} />
          <link rel={'canonical'} href={canonical} />
        </Helmet>
        <NavBar color={navColors} />
        <NewWorkBlog posts={posts} authors={authors} filters={filters} />
      </Layout>
    );
  }
}

NewWorkPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({ edges: PropTypes.array }),
    authors: PropTypes.shape({ edges: PropTypes.array }),
    filters: PropTypes.shape({ edges: PropTypes.array }),
  }).isRequired,
};

export const pageQuery = graphql`
  query NewWorkPage {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: {
            in: [
              "article-page"
              "case-study-page"
              "medium-page"
              "external-page"
              "youtube-video"
            ]
          }
          settings: { category: { ne: "Case Study" } }
          tags: { eq: "new work" }
        }
      }
    ) {
      ...markdownFields
    }

    authors: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "author-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    filters: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          settings: { category: { ne: "Case Study" } }
          isNewWork: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
          }
        }
      }
    }
  }
`;
