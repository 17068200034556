export const data = [
  {
    cloudinaryDir: 'remote-work-landing/sonnen-case-study-by-boldare.png',
    alt: 'Case Study by Boldare - Sonnen',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-boards-case-study-by-boldare.png',
    alt: 'Case Study by Boldare - Boldare Boards',
  },
  {
    cloudinaryDir: 'remote-work-landing/wework-case-study-by-boldare.png',
    alt: 'Case Study by Boldare - WeWork',
  },
  {
    cloudinaryDir: 'remote-work-landing/chartipedia-case-study-by-boldare.png',
    alt: 'Case Study by Boldare - Chartipedia Landing Page',
  },
];
