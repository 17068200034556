import React, { useState } from 'react';
import Slider from 'react-slick';
import './Feedback.scss';
import { Paragraph, SliderArrow, Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import data from './logic';

const sliderSettings = {
  className: 'srt-feedback__single',
  dotsClass: 'slick-dots slick-pagination',
  arrows: true,
  dots: true,
  autoplay: false,
  adaptiveHeight: false,
  infinite: true,
  draggable: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderArrow id={'srt-feedback-next'} iconSize={'large'} />,
  prevArrow: <SliderArrow id={'srt-feedback-prev'} left iconSize={'large'} />,
};

const Feedback = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <section className={'srt-feedback w-container'}>
      <img
        className={'image srt-feedback__img'}
        src={getCloudinaryUrl(data[currentSlide].img, 'f_auto,q_auto')}
        alt={'Feedback slide'}
        loading={'lazy'}
      />
      <Slider
        {...sliderSettings}
        afterChange={current => setCurrentSlide(current)}
      >
        {data.map(item => (
          <div className={'srt-feedback__content'}>
            <div className={'srt-feedback__name text--light'}>
              <span className={'text--bold'}>{item.name}</span> | {item.role}
            </div>
            <Paragraph
              additionalClass={'srt-feedback__paragraph text--light'}
              size={'medium'}
              color={'black'}
            >
              {item.feedback}
            </Paragraph>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Feedback;
