import React, { Component } from 'react';
import { truncate } from 'lodash';
import MarkdownRenderer from 'react-markdown-renderer';
import { Col } from 'react-flexbox-grid';
import { Heading, Link, Paragraph, IconSVG } from 'components';
import {
  getArticleSlug,
  estimateReadTime,
  getCloudinaryUrl,
} from 'config/helpers';
import { classNames } from 'utils';
import './SinglePostTileLead.scss';

class SinglePostTileLead extends Component {
  handleCategoryClick = (event, category) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.toggleFilter(category);
  };

  render() {
    const { post, authors, theme, backgroundColor } = this.props;
    const { frontmatter } = post.node;
    const { title, settings, author, lead, content } = frontmatter;
    const slug = getArticleSlug(post);

    const authorData = authors.filter(
      edge => edge.node.frontmatter.title === author,
    );

    const articlePureContent = content
      .reduce((a, b) => a.body || a + b.body, '')
      .replace(/[^a-zA-Z ]/g, ' ');

    const readTime = estimateReadTime(articlePureContent);

    return (
      <Col sm={12} md={6} lg={4}>
        <Link to={slug} id={`single-post-${slug}`} rel={'nofollow'}>
          <div
            className={classNames('single-post-tile-lead ', {
              [`single-post-tile-lead--${backgroundColor}`]: backgroundColor,
              [`single-post-tile-lead--${theme}`]: theme,
            })}
          >
            <div>
              <button
                onClick={e => this.handleCategoryClick(e, settings.category)}
                aria-label={'Settings category'}
              >
                <Paragraph
                  size={'small'}
                  color={theme === 'black' ? 'white' : 'pigeon-post'}
                  additionalClass={
                    'single-post-tile-lead__category text--bold pointer'
                  }
                >
                  {settings.category}
                </Paragraph>
              </button>
              <Paragraph
                size={'small'}
                additionalClass={'single-post-tile__time'}
                color={'white'}
              >
                {readTime} min read
              </Paragraph>
              <IconSVG name={'time'} color={'white'} />
              <Heading color={'white'} type={3} size={'medium'}>
                {title}
              </Heading>
              <Paragraph
                color={'white'}
                additionalClass={'single-post-tile-lead__lead text--light'}
                nested
              >
                <MarkdownRenderer markdown={truncate(lead, { length: 200 })} />
              </Paragraph>
            </div>
            {authorData.length > 0 && (
              <div className={'single-post-tile-lead__author'}>
                <img
                  src={getCloudinaryUrl(
                    authorData[0]?.node.frontmatter.photo,
                    'f_auto,q_auto,w_100',
                  )}
                  alt={`${authorData[0]?.node.frontmatter.title} avatar`}
                  className={'image'}
                  loading={'lazy'}
                />
                <Paragraph color={'white'} size={'small'}>
                  {authorData[0]?.node.frontmatter.title}
                  <br />
                  <span className={'text--light'}>
                    {authorData[0]?.node.frontmatter.job}
                  </span>
                </Paragraph>
              </div>
            )}
          </div>
        </Link>
      </Col>
    );
  }
}

SinglePostTileLead.defaultProps = {
  theme: 'white',
  backgroundColor: 'white',
};

export default SinglePostTileLead;
