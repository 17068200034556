import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFlexSearch } from 'react-use-flexsearch';

import {
  classNames,
  unflatLocalSearchResults,
  sortLocalSearchResultsDesc,
} from 'utils';

import {
  Section,
  Button,
  Paragraph,
  LoadMorePagination,
  BoxArticle,
  SearchBar,
} from 'components';
import { BlogWebinarNewNormal } from '..';

import './PostList.scss';

const PostList = ({
  posts: nodes,
  toggleFilter,
  newWork,
  newNormalBanner,
  campaignID,
  localSearchPages,
}) => {
  /*
  **********************
  FILTERS ON BLOG PAGE TEMPORARLY DISABLED -> functions for filters logic are currently moved to ./utils.js
  *********************
  */
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSearchTerm(
        new URLSearchParams(window.location.search).get('search') || '',
      );
    }
  }, []);

  const localSearchPagesIndex = localSearchPages && localSearchPages.index;
  const localSearchPagesStore = localSearchPages && localSearchPages.store;

  const results =
    searchTerm &&
    useFlexSearch(searchTerm, localSearchPagesIndex, localSearchPagesStore);
  const unflatternResults = results && unflatLocalSearchResults(results);
  const sortedResults =
    unflatternResults && sortLocalSearchResultsDesc(unflatternResults);
  const posts = sortedResults || nodes;

  const setNewSearchTerm = value => {
    const newValue = value;
    setSearchTerm(newValue);
  };

  const renderFeaturedPosts = (count, allPosts) => {
    return allPosts.slice(0, count);
  };

  const renderPosts = (allPosts, featuredPosts) => {
    return (
      <>
        {allPosts &&
          allPosts.map((post, key) => (
            <>
              {!newWork && !newNormalBanner ? (
                <BoxArticle
                  key={`blog-post-${post.node.frontmatter.title}`}
                  post={post}
                  toggleFilter={toggleFilter}
                  campaignID={`${campaignID}`}
                />
              ) : (
                <>
                  {key === 2 && newNormalBanner && <BlogWebinarNewNormal />}
                  <BoxArticle
                    key={`blog-post-${post.node.frontmatter.title}`}
                    post={post}
                    toggleFilter={toggleFilter}
                    campaignID={`${campaignID}`}
                  />
                </>
              )}
            </>
          ))}
        {featuredPosts &&
          featuredPosts.map(post => (
            <BoxArticle
              key={`blog-post-${post.node.frontmatter.title}`}
              post={post}
              toggleFilter={toggleFilter}
              campaignID={`${campaignID}`}
            />
          ))}
      </>
    );
  };

  const renderSearchResults = (searchResult, allPosts) => {
    if (searchResult.length) {
      return renderPosts(searchResult);
    }

    const featuredPosts = renderFeaturedPosts(3, allPosts);
    return renderPosts(searchResult, featuredPosts);
  };

  return (
    <Section
      id={'blog-post-list'}
      additionalClass={classNames('post-list', campaignID)}
    >
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setNewSearchTerm}
        hiddenButton
      />
      {searchTerm.length && posts.length ? (
        <Paragraph
          size={'medium'}
          additionalClass={'post-list__search-results'}
        >
          We’ve found <b>{posts.length}</b> results matching your search!
        </Paragraph>
      ) : null}
      {searchTerm.length && !posts.length ? (
        <>
          <Paragraph
            size={'medium'}
            additionalClass={'post-list__search-results'}
          >
            We couldn’t find any results for your search :( Please try another
            search term!
          </Paragraph>
          <Paragraph additionalClass={'post-list__search-results-second'}>
            You can also read our <b>recommended articles</b> below!
          </Paragraph>
        </>
      ) : null}
      <LoadMorePagination index={'page'} loadCount={9} data={posts}>
        {({ items, loadMore }) => (
          <>
            {/* <div className={'post-list__filters-list'}>
                {this.renderPostFilters(navigatePage)}
              </div> */}
            <div className={'post-list__grid'}>
              {renderSearchResults(
                // eslint-disable-next-line no-nested-ternary
                (newWork || newNormalBanner) && loadMore
                  ? newWork
                    ? items.slice(0, -1)
                    : items.slice(0, -1)
                  : items,
                nodes,
              )}
            </div>
            <div className={'post-list__button-wrapper'}>
              {loadMore && (
                <div className={'post-list__button'}>
                  <Button
                    id={'load-more-blog-posts-button'}
                    to={loadMore.path}
                    transparent
                    color={'black'}
                    onClick={loadMore.action}
                    ariaName={'Load more button'}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </LoadMorePagination>
    </Section>
  );
};

PostList.propTypes = {
  posts: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  filtersActive: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  campaignID: PropTypes.string,
  newWork: PropTypes.bool,
  newNormalBanner: PropTypes.bool,
  localSearchPages: PropTypes.shape({
    index: PropTypes.string,
    store: PropTypes.object,
  }).isRequired,
};

PostList.defaultProps = {
  campaignID: '',
  newWork: false,
  newNormalBanner: false,
};

export default PostList;
