import { SectionHeader, YouTubePlayer } from 'components';
import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { TextInteractionAnimation } from 'views/f2f/components';
import './FeelOurSpiritSection.scss';

const FeelOurSpiritSection = () => {
  return (
    <section id={'f2f-spirit'} className={'f2f-section-spirit'}>
      <SectionHeader
        size={'extra-large'}
        color={'black'}
        headingType={2}
        noLine
        noBottomMargin
        additionalClass={'section-spirit__heading'}
      >
        <span>Feel our</span>
        <br />
        <TextInteractionAnimation text={'team spirit!'} />
      </SectionHeader>
      <div className={'section-spirit__video'}>
        {/* todo we can add "light" parameter so that the yt files are not downloaded until the user clicks play but then the user
          has to click play twice so it's bad for UX for now LazyLoad should be enough */}
        <YouTubePlayer
          src={'https://www.youtube.com/watch?v=5KEvr_TCcUM'}
          light
        />
      </div>
      <img
        src={getCloudinaryUrl(
          'f2f-landing/f2f-landing-hand',
          'f_auto,q_auto,w_310',
        )}
        alt={'Boldare - Digital Product Design Development Company'}
        className={'image__hand'}
        width={310}
        height={505}
        loading={'lazy'}
      />
    </section>
  );
};

export default FeelOurSpiritSection;
