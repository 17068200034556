import React, { useState, useEffect } from 'react';

import { Awards, MainBanner, Stats, Footer } from './subcomponents';
import './Prizes.scss';

const PrizesContent = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    if (window) {
      setIsTablet(window.innerWidth > 768);
    }
  }, []);

  const content = (() => {
    return (
      <>
        <MainBanner />
        <Stats isTablet={isTablet} />
        <Awards isTablet={isTablet} />
        <Footer />
      </>
    );
  })();

  return (
    <div className={'prizes w-container'}>
      <img
        src={
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1701253752/homepage/clutch_illustration.png'
        }
        alt={'decoration collage'}
        className={'prizes-banner__collage'}
      />
      {content}
    </div>
  );
};

const Prizes = () => {
  // Forces rerendering of CSS classes when changing A / B version
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return <PrizesContent key={`${loading}-awards`} />;
};

export default Prizes;
