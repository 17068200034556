import { Component } from 'react';
import PropTypes from 'prop-types';

import { getUrlSearchParams } from 'config/helpers';

import withLocation from 'components/LocationHandler';

class LoadMorePagination extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    index: PropTypes.string.isRequired,
    loadCount: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    const page = this.getLocationPage();

    this.state = {
      initialPage: page,
      page,
    };
  }

  getPagePath(page) {
    const { index, location } = this.props;

    if (typeof window !== 'undefined') {
      const params = getUrlSearchParams(location);
      params.set(index, page);

      const path = `${location.origin}${
        location.pathname
      }?${params.toString()}`;

      return path;
    }

    return null;
  }

  setLocationPage(page) {
    const path = this.getPagePath(page);

    if (path) {
      window.history.replaceState({ path }, '', path);
    }
  }

  getLocationPage() {
    const { index, location } = this.props;

    if (typeof window !== 'undefined') {
      const param = getUrlSearchParams(location).get(index);

      if (param) {
        return parseInt(param, 10);
      }
    }

    return 1;
  }

  getItemsCount(page) {
    const { loadCount } = this.props;

    return page * loadCount;
  }

  getPaginatedData() {
    const { data, loadCount } = this.props;
    const { initialPage, page } = this.state;

    const initialCount = this.getItemsCount(initialPage) - loadCount;
    const currentCount = this.getItemsCount(page);

    const items = data.filter(
      (item, i) => i >= initialCount && i < currentCount,
    );
    const totalCount = data.length;

    return { items, totalCount };
  }

  isLoadMoreAvailable = totalCount =>
    // eslint-disable-next-line react/destructuring-assignment
    this.getItemsCount(this.state.page) < totalCount;

  // eslint-disable-next-line react/destructuring-assignment
  isLoadLessAvailable = () => this.state.page > 1;

  loadMore = e => {
    e.preventDefault();

    const { page } = this.state;
    this.navigatePage(page + 1);
  };

  loadLess = e => {
    e.preventDefault();

    const { page } = this.state;
    this.navigatePage(page - 1);
  };

  navigatePage = page => {
    const { page: currentPage } = this.state;

    if (currentPage === page) {
      return;
    }

    this.setState({
      page,
    });

    // this.setLocationPage(page);
  };

  render() {
    const { page } = this.state;
    const { children } = this.props;

    const { items, totalCount } = this.getPaginatedData();

    const pagination = {
      items,
      totalCount,
      loadMore: this.isLoadMoreAvailable(totalCount)
        ? { action: this.loadMore, path: this.getPagePath(page + 1) }
        : null,
      loadLess: this.isLoadLessAvailable()
        ? { action: this.loadLess, path: this.getPagePath(page - 1) }
        : null,
      navigatePage: this.navigatePage,
    };

    return children(pagination);
  }
}

export default withLocation(LoadMorePagination);
