import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['product-development-challenges'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/product-development-challenges/`;

const ProductDevelopmentChallangesPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={'6 Most Common Product Development Challenges'}
        cover={getCloudinaryUrl(
          'resources/six-product-challenges.png',
          'f_auto,q_auto',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/six-product-challenges.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Product development challenges'}
        description={
          'Download the document if you are planning to outsource your product development or are currently working with an external partner. The document provides practical advice on how to avoid or solve the most common issues that arise during external product development.'
        }
        items={[
          'Gain insights into the common issues faced during product development and learn how to solve them',
          'Learn about good practices to follow when choosing a partner for your product development',
          'Get tips from experts with nearly two decades of experience in product development',
        ]}
        formHeading={'Get your free ebook now:'}
        url={url}
        button={'Send me the ebook'}
        btnColor={'violet'}
        trackingEvent={'ebookSixCommonProductChallenges'}
      />
    </Layout>
  );
};

export default ProductDevelopmentChallangesPage;
