import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import './BackgroundImage.scss';
import { classNames } from 'utils';
import {
  getCloudinarySrcSet,
  getCloudinaryUrl,
  getImageAlt,
} from 'config/helpers';

const BackgroundImage = ({
  src,
  alt,
  style,
  additionalClass,
  asset,
  maxWidth,
  maxHeight,
  loading,
}) => {
  const imgSrc = !src ? asset : src;

  return typeof imgSrc === 'object' ? (
    <Img
      fluid={imgSrc}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        ...style,
      }}
      imgStyle={{
        objectPosition: 'center top',
      }}
      alt={alt || getImageAlt(imgSrc.originalName || imgSrc.src)}
      title={alt || getImageAlt(imgSrc.originalName || imgSrc.src)}
      className={classNames(
        'background-image',
        'background-image--optimized',
        additionalClass,
      )}
    />
  ) : (
    <div
      style={style}
      className={classNames(
        'background-image',
        'background-image--basic',
        additionalClass,
      )}
    >
      <img
        src={
          src ||
          getCloudinaryUrl(
            asset,
            `f_auto,q_auto${maxWidth ? `,w_${maxWidth}` : ''}${
              maxHeight ? `,h_${maxHeight}` : ''
            },c_scale`,
          )
        }
        loading={loading}
        srcSet={getCloudinarySrcSet(asset, maxWidth)}
        alt={alt}
      />
    </div>
  );
};

BackgroundImage.defaultProps = {
  src: '',
  alt: '',
  style: {},
  additionalClass: '',
  asset: '',
  loading: 'lazy',
  maxWidth: 'auto',
  maxHeight: 'auto',
};

BackgroundImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  style: PropTypes.shape(),
  additionalClass: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  asset: PropTypes.string,
  loading: PropTypes.string,
};

export default BackgroundImage;
