import React, { Component } from 'react';
import { Heading, IconSVG, Section, SectionHeader } from 'components';
import { data } from './logic.js';
import './More.scss';

class More extends Component {
  handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: item,
      });
    }
  };

  renderItem = () =>
    data.map(item => (
      <div className={'more__item'}>
        <a
          className={'more__item-link'}
          href={item.link}
          rel={
            item.icon === 'resources-yt'
              ? 'nofollow noreferrer'
              : 'dofollow noreferrer'
          }
          target={item.icon === 'resources-yt' ? '_blank' : '_self'}
          onClick={() => this.handleClick('resources-hub__more')}
        >
          <IconSVG name={`${item.icon}`} size={'huge'} color={'white'} />
          <Heading color={'white'} size={'medium-plus-small'}>
            {item.title}
          </Heading>
        </a>
      </div>
    ));

  render() {
    return (
      <Section color={'soft-cloud'} additionalClass={'resources-hub__more'}>
        <SectionHeader
          size={'big'}
          lineColor={'pigeon-post'}
          color={'black'}
          descriptionColor={'black'}
          description={
            'Explore other channels to stay up to date with our work.'
          }
          headingType={2}
        >
          And there’s more!
        </SectionHeader>
        <div className={'more__items'}>{this.renderItem()}</div>
      </Section>
    );
  }
}

export default More;
