import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Heading, Paragraph } from 'components';
import '../../Process.scss';

const Benefits = () => (
  <>
    <Heading type={3} size={'big'} additionalClass={'wdm__process-heading'}>
      The benefits of our design
      <br />
      process for you
    </Heading>
    <Paragraph
      size={'medium'}
      additionalClass={'text--light wdm__process-paragraph'}
    >
      Underpinning this proven 5-step approach to digital design is a focus on
      the most important people: your users. They are the unifying principle
      for any product that we design and that philosophy creates very practical
      benefits for you, the client.
    </Paragraph>
    <Row
      className={'no-gap anim-fade-in-bottom wdm__process-tiles hide-mobile'}
    >
      <Col sm={12} md={4}>
        <div className={'wdm__process-tile'}>
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Full control
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Right from the start, you are involved in, and have final control
            over, the design process. The client’s Product Owner is a member of
            the project team, with full transparency and insight. You can
            adjust the direction of the project as and when the needs or aims
            change. The result of this flexibility is a better quality product.
          </Paragraph>
        </div>
      </Col>
      <Col sm={12} md={4}>
        <div className={'wdm__process-tile'}>
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Money and time savings
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Projects evolve and products change. By producing prototypes and/or
            MVPs in rapid increments, we test versions of the product and
            change it in response to feedback. The process is quick, flexible,
            and results in a product well-aligned to both client and user
            requirements and needs. This continual honing of the product saves
            the need for costly and time-consuming changes or re-designs later
            on.
          </Paragraph>
        </div>
      </Col>
      <Col sm={12} md={4}>
        <div className={'wdm__process-tile'}>
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            An easy-scaling product
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Right from the start of your product design, we look at how to
            scale your product for maximum market fit and share. At Boldare,
            the process of making rapid changes in response to user feedback is
            built into your product’s DNA, resulting in better market
            performance and a longer product lifespan.
          </Paragraph>
        </div>
      </Col>
    </Row>
  </>
);

export default Benefits;
