import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, SectionHeader, Paragraph, YouTubePlayer } from 'components';
import './Videos.scss';

const Videos = () => {
  return (
    <Section additionalClass={'gitex-videos'} color={'carrara'}>
      <SectionHeader
        size={'extra-large'}
        color={'mine-shaft'}
        lineColor={'yellow'}
        fixed
        additionalClass={'section-header__description--medium'}
      >
        Video coverage and summary of GITEX 2019 in Dubai
      </SectionHeader>
      <Row className={'videos__row'}>
        <Col xs={12} md={6} className={'videos__col'}>
          <Paragraph
            size={'medium'}
            color={'black'}
            additionalClass={'gitex-videos__paragraph'}
          >
            <a
              href={'https://twitter.com/techkarolina'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              Karolina Kołodziej
            </a>
            , CMO at Boldare, spoke with the most influential tech leaders from
            across the Middle East region.
            <br />
            <br />
            Watch a series of video interviews and find out what they are
            forecasting for the future.
          </Paragraph>
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=1lurHO3UWRs'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=HyVeq8UgRj4'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=7bNKiybp17c'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=NGkfHINupcs'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=pCl9SJHzh08'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=4u0p9wm1JJ8'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=s24X9-9oS3E'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=YJQuNjoQaKM'}
            light
          />
        </Col>
        <Col xs={12} md={6} className={'videos__col vid'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=LDvVyF3uiCA'}
            light
          />
        </Col>
      </Row>
    </Section>
  );
};

export default Videos;
