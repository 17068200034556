import React from 'react';
import Image from '../Image';
import './CircleImages.scss';

const CircleImages = () => {
  const angles = [];
  const numberOfImages = 46;
  const circleOffset = 40;

  for (let i = 1; i <= numberOfImages; i++) {
    const radius = 600;
    const angle = (1 / numberOfImages) * i * Math.PI * 2;
    const x = Math.cos(angle) * radius + 100;
    const y = Math.sin(angle) * radius + 100;
    const styles = { top: radius - y, left: radius - x + circleOffset };
    const imageName = `circle-${i}.png`;
    angles.push({ styles, imageName });
  }

  return (
    <div className={'circle-images'}>
      <div className={'circle-images--blur'}>
        {angles.map(({ imageName, styles }) => {
          return (
            <Image
              className={'circle-images__image'}
              imageName={imageName}
              key={imageName}
              styles={styles}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CircleImages;
