import React from 'react';
import { data } from './data';
import Slider from './subcomponents/Slider';
import './TestimonialsSection.scss';

const TestimonialsSection = () => {
  return (
    <section
      className={'products-testimonials section-box'}
      data-color={'#f1d624'}
      id={'testimonials-section'}
    >
      <div className={'padding__container'}>
        <Slider id={'products-testimonials__slider'} items={data} />
      </div>
    </section>
  );
};

export default TestimonialsSection;
