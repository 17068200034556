import React from 'react';
import PropTypes from 'prop-types';

import { Rating } from 'components';
import companyData from '../../../../../meta/companyData';
import { getRecommendClientImageUrl } from '../../utils';

import './ClutchTop.scss';

export default function ClutchTop({ className }) {
  const { clutch_rate: clutchRate } = companyData;

  return (
    <div className={`bring-a-qualified-lead__hero__clutch-top ${className}`}>
      <img
        src={getRecommendClientImageUrl('clutch2023.png')}
        className={'image'}
        alt={'Clutch recommendation badge 2023'}
        loading={'lazy'}
      />

      <span className={'bring-a-qualified-lead__hero__clutch-top__rate'}>
        {clutchRate.replace('.', ',')}
      </span>
      <span
        className={
          'bring-a-qualified-lead__hero__clutch-top__rate-description'
        }
      >
        Avarage review rating
      </span>

      <Rating
        rate={clutchRate}
        size={40}
        color={'yellow'}
        spaceClassName={''}
      />
    </div>
  );
}

ClutchTop.defaultProps = {
  className: '',
};

ClutchTop.propTypes = {
  className: PropTypes.string,
};
