import './index.scss';
import React, { useState } from 'react';
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from 'framer-motion';

// Components
import Loading from './Loading';
import Subheading from '../Subheading';
import AnimatedHeading, { headingVariants } from '../AnimatedHeading';

const AnimatedStories = ({ end, show, start }) => {
  const { scrollY } = useViewportScroll();
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  const clipPath = useTransform(
    scrollY,
    [start, end],
    ['circle(18% at 100% 50%)', 'circle(100% at 50% 50%)'],
  );

  scrollY.onChange(progress => {
    if (isSectionVisible && progress < show) {
      setIsSectionVisible(false);
    } else if (!isSectionVisible && progress >= show) {
      setIsSectionVisible(true);
    }
  });

  scrollY.onChange(progress => {
    if (isTextVisible && progress < end) {
      setIsTextVisible(false);
    } else if (!isTextVisible && progress >= end) {
      setIsTextVisible(true);
    }
  });

  return (
    <AnimatePresence>
      {isSectionVisible && (
        <motion.div
          exit={{ x: '18%' }}
          style={{ clipPath }}
          animate={{ x: '0%' }}
          initial={{ x: '18%' }}
          className={'dsgn-hero__animated-stories'}
          transition={{ duration: 0.3, ease: 'linear' }}
        >
          <Subheading style={{ color: '#242424' }} />
          <div className={'dsgn-hero__animated-stories-heading'}>
            <AnimatedHeading isVisible={isTextVisible} color={'#242424'}>
              <motion.span variants={headingVariants}>Tells</motion.span>{' '}
              <motion.span variants={headingVariants}>stories</motion.span>
            </AnimatedHeading>
            <Loading isVisible={isTextVisible} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimatedStories;
