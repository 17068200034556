import React from 'react';
import { FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import AiAssistantBanner from 'views/blog/subcomponents/AiAssistantBanner';
import './Cover.scss';

const Cover = () => {
  return (
    <div className={'assistant-cover'}>
      <FullwidthImage
        image={getCloudinaryUrl(
          '/img/Boldare_AI_Assistant_-_NestJS_library.png',
          'f_auto,q_auto',
        )}
        alt={
          'Kickstart your first  AI-based product quicker with our open-source library!'
        }
      />
      <div className={'assistant-cover__banner'}>
        <AiAssistantBanner />
      </div>
    </div>
  );
};

export default Cover;
