export const circles = {
  fundacja: {
    name: 'Boldare Foundation',
    teamPurpose:
      'This is a circle dedicated to the Boldare Foundation, ensuring that the children of Bolders (and others) have easy access to democratic education.',
    leadlink: 'Anna Zarudzka',
    avatar: 'holaspirit/anna_zarudzka.png',
    breadcrumbs: 'Boldare >',
    leadlinkRole: 'Co-CEO',
  },
  businessGrowth: {
    name: 'Strategic Business Growth',
    teamPurpose:
      'This circle focuses on Boldare’s long-term growth by identifying and executing new business opportunities, analyzing trends, formulating strategies, assessing risks, managing relationships, and reporting performance to enhance business development approaches.',
    leadlink: '',
    avatar: 'holaspirit/avatar.png',
    breadcrumbs: 'Boldare >',
    leadlinkRole: '',
  },
  boldare: {
    name: 'Boldare',
    teamPurpose:
      'Empowered organizations through exceptional digital product design & development.',
    leadlink: 'Piotr Majchrzak',
    avatar: 'holaspirit/piotr_majchrzak.png',
    leadlinkRole: 'Co-CEO',
  },
  gcc: {
    name: 'The General Company Circle (GCC)',
    teamPurpose:
      'Contains the company’s value-generating functions, those activities that contribute directly to the business goals.',
    leadlink: 'Anna Zarudzka',
    avatar: 'holaspirit/anna_zarudzka.png',
    breadcrumbs: 'Boldare >',
    leadlinkRole: 'Co-CEO',
  },
  talentAcquisition: {
    name: 'Talent Acqisition',
    teamPurpose:
      "This circle focuses on attracting and onboarding talents who align with and contribute to Boldare's strategy.",
    leadlink: 'Katarzyna Szałajko-Paszkiewicz',
    avatar: 'holaspirit/katarzyna_szalajko.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Talent Acquisition Lead',
  },
  business: {
    name: 'Business',
    teamPurpose:
      "This circle drives Boldare's growth, emphasizing new client acquisition and high retention. It sources clients aligned with Boldare's strategic goals, offers data-driven decision-making support, and fosters a collaborative business environment.",
    leadlink: 'Sławomir Zagórski',
    avatar: 'holaspirit/slawomir_zagorski.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Business Lead',
  },
  finance: {
    name: 'Finance & Accounting',
    teamPurpose:
      'This circle leverages its expertise in finance and accounting to empower Boldare in data-driven decision-making, maximizing profits, and pursuing long-term financial stability.',
    leadlink: 'Piotr Majchrzak',
    avatar: 'holaspirit/piotr_majchrzak.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Co-CEO',
  },
  talentDevelopment: {
    name: 'Talent Development',
    teamPurpose:
      "This circle emphasizes maximizing human potential in line with Boldare's vision, overseeing strategies throughout the employee lifecycle while ensuring HR practices align with business objectives and legal requirements.",
    leadlink: 'Ewelina Tomiak',
    avatar: 'holaspirit/ewelina_tomiak.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Talent Development Lead',
  },
  wroclaw: {
    name: 'Wrocław Local',
    teamPurpose:
      "This circle bolsters Boldare's identity and camaraderie in the Wrocław community, promoting local culture and enabling direct interactions in a safe, creative setting. They efficiently tackle local issues and are known for their love of spicy Indian food. Their efforts nurture a strong connection to both Wrocław and Boldare.",
    leadlink: 'Szymon Kopa',
    avatar: 'holaspirit/szymon_kopa.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'PHP Developer',
  },
  eventy: {
    name: 'Big Fat Boldare Events',
    teamPurpose:
      "This circle facilitates biannual real-life gatherings for the entire Boldare community to deepen relationships, inspire one another, and feel special. They're known for organizing exceptional parties that other companies envy.",
    leadlink: 'Olga Zabielska-Prokopów',
    avatar: 'holaspirit/olga_zabielska.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Finance & Administration Lead',
  },
  legal: {
    name: 'Distributed Legal',
    teamPurpose:
      'This circle formulates the vision and development strategy for the legal department at Boldare.',
    leadlink: '',
    avatar: 'holaspirit/avatar.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: '',
  },
  ses: {
    name: 'Service Delivery Standards',
    teamPurpose:
      "This circle emphasizes maintaining consistency in service delivery and digital product development, a crucial factor in Boldare's competitive edge.",
    leadlink: 'Anna Zarudzka',
    avatar: 'holaspirit/anna_zarudzka.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Co-CEO',
  },
  krakow: {
    name: 'Kraków Local',
    teamPurpose:
      'This circle fosters a sense of belonging within Boldare and the Krakow community by promoting collective meetings and office collaborations. It encourages knowledge exchange, inspiration, and creativity to enhance both community and organizational growth.',
    leadlink: 'Tomasz Murzyn',
    avatar: 'holaspirit/tomasz_murzyn.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Scrum Master',
  },
  gliwice: {
    name: 'Gliwice Local',
    teamPurpose:
      'This circle aims to enhance the sense of community and knowledge-sharing within Boldare and the Gliwice community. It promotes collective meetings and in-office collaborations in Gliwice to encourage mutual support and strengthen bonds.',
    leadlink: 'Olga Halama',
    avatar: 'holaspirit/olga_halama.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Q&A Engineer',
  },
  changeTeam: {
    name: 'Change Team Boldare Roadmap 2023',
    teamPurpose:
      'This circle ensures that every change planned for the 2023 roadmap will be implemented properly.',
    leadlink: 'Olga Zabielska-Prokopów',
    avatar: 'holaspirit/olga_zabielska.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Finance & Administration Lead',
  },
  jointStock: {
    name: 'Joint stock company services',
    teamPurpose:
      'This circle focuses on enhancing efficiency in the joint-stock company and its subsidiaries, emphasizing legal compliance and investor trust, in partnership with the Compliance Officer.',
    leadlink: 'Olga Zabielska-Prokopów',
    avatar: 'holaspirit/olga_zabielska.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Finance & Administration Lead',
  },
  operations: {
    name: 'Operations',
    leadlink: 'Olga Zabielska-Prokopów',
    avatar: 'holaspirit/olga_zabielska.png',
    breadcrumbs: 'Boldare > General company circle >',
    teamPurpose:
      "The circle responsible for Boldare's administrative service is top-notch, emphasizing innovation and safety, allowing every operational group to focus on its core purpose.",
    leadlinkRole: 'Finance & Administration Lead',
  },
  delivery: {
    name: 'Delivery',
    teamPurpose:
      'This circle aids client growth via comprehensive product development, including strategy, service quality, business relationships, and cross-departmental collaboration.',
    leadlink: 'Beata Sumera-Górska',
    avatar: 'holaspirit/beata_sumera.png',
    breadcrumbs: 'Boldare > General company circle >',
    leadlinkRole: 'Head of Delivery',
  },
  warszawa: {
    name: 'Warszawa Local',
    avatar: 'holaspirit/ilo_gorska.png',
    leadlink: 'Ilo Gorska',
    breadcrumbs: 'Boldare > General company circle >',
    teamPurpose:
      'This circle nurtures a learning atmosphere and positive Boldare image within the Warsaw coworking space, enhancing brand visibility and employee attraction. It emphasizes community belonging and supports shared events to strengthen relationships and identity among members.',
    leadlinkRole: 'PHP Developer',
  },
};
