import React, { Component } from 'react';
import Contact from 'views/contact';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

class ContactPage extends Component {
  render() {
    const page = pages.contact;
    const { navColors } = page;
    const url = `${config.siteUrl}/contact/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} bannerHidden />
        <PopupRedirect message={'contact'} />
        <Contact />
      </Layout>
    );
  }
}

export default ContactPage;
