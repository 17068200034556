import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDisqusComments from 'react-disqus-comments';
import config from '../../../meta/config';

class Disqus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toasts: [],
    };
  }

  handleNewComment = () => {
    const toasts = this.state.toasts.slice();
    toasts.push({ text: 'New comment available!' });
    this.setState({ toasts });
  };

  render() {
    const { fields, frontmatter } = this.props.data.markdownRemark;

    if (!config.disqusShortname) {
      return null;
    }

    const url = config.siteUrl + config.pathPrefix + fields.slug;
    return (
      <ReactDisqusComments
        shortname={config.disqusShortname}
        identifier={frontmatter.title}
        title={frontmatter.title}
        url={url}
        category_id={frontmatter.title}
        onNewComment={this.handleNewComment}
      />
    );
  }
}

Disqus.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Disqus;
