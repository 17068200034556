import React from 'react';
import { Section, SectionHeader, Link, Button } from 'components';
import './Contact.scss';

const Contact = () => (
  <Section color={'yellow'} additionalClass={'prototyping__contact'}>
    <div>
      <SectionHeader
        size={'medium'}
        headingType={3}
        lineColor={'white'}
        description={
          'Need to prototype digital product? Let’s find out how we can help.'
        }
        additionalClass={'section-header__description--medium'}
        centered
      >
        Get in touch!
      </SectionHeader>

      <Link
        to={'/contact/#form'}
        additionalClass={'prototyping__contact-link'}
      >
        <Button
          id={'prototyping-contact'}
          additionalClass={'contact-button'}
          color={'yellow'}
          ariaName={'Contact button'}
        >
          Contact us
        </Button>
      </Link>
    </div>
  </Section>
);

export default Contact;
