import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';

const Question = props => {
  const { content, questionHelperText } = props;
  return (
    <div className={'app-cost-calc__question-container'}>
      <Heading
        additionalClass={'app-cost-calc__question-content'}
        type={2}
        size={'medium-plus'}
      >
        {content}{' '}
        <span className={'app-cost-calc__question-content-helperText'}>
          {questionHelperText}
        </span>
      </Heading>
    </div>
  );
};

Question.propTypes = {
  content: PropTypes.string.isRequired,
  questionHelperText: PropTypes.string,
};

Question.defaultProps = {
  questionHelperText: '',
};

export default Question;
