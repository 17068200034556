import React, { Component } from 'react';
import { Section, Heading } from 'components';
import './Challenge.scss';

class Challenge extends Component {
  render() {
    return (
      <Section additionalClass={'showbiz__challenge-container'}>
        <div className={'showbiz__content--left'}>
          <Heading
            type={3}
            size={'medium-plus-small'}
            color={'mine-shaft'}
            additionalClass={
              'text--light showbiz__header showbiz__challenge-header'
            }
          >
            Main Challenge:
          </Heading>
        </div>
        <div className={'showbiz__content'}>
          <Heading
            type={2}
            size={'big-spreaded'}
            color={'white'}
            additionalClass={'showbiz__challenge-header--big'}
          >
            Simplify usability for both: the admin &amp; the user
          </Heading>
        </div>
      </Section>
    );
  }
}

export default Challenge;
