export const data = [
  {
    order: '01',
    title: 'Test your business idea',
    id: 'home-services-test-yout-business-idea',
    bullets: [
      {
        name: 'Digital Prototyping',
        link: '/services/full-cycle-product-development/prototyping/',
      },
      {
        name: 'Minimum Viable Product (MVP) Development',
        link: '/services/mvp-development/',
      },
      {
        name: 'UX/UI Design',
        link: '/ux-ui-design-consulting-services/',
      },
    ],
  },
  {
    order: '02',
    title: 'Build your digital product',
    id: 'home-services-build-your-digital-product',
    bullets: [
      {
        name: 'Product Design and Development',
        link: '/services/product-design-and-development/',
      },
      {
        name: 'Full Cycle Product Development',
        link: '/services/full-cycle-product-development/',
      },
    ],
  },
  {
    order: '03',
    title: 'Grow your product',
    id: 'home-services-grow-your-product',
    bullets: [
      {
        name: 'Product Scaling',
        link: '/services/scaling-your-product-thriving-in-the-market/',
      },
      {
        name: 'Product-Market Fit',
        link: '/services/product-market-fit-expanding-demand/',
      },
      {
        name: 'AI Software Development and Consulting',
        link: '/services/ai-software-development-consulting/',
      },
      {
        name: 'Digital Transformation',
        link: '/services/digital-transformation/',
      },
    ],
  },
  {
    order: '04',
    title: 'Support for your product',
    id: 'home-services-support-for-your-product',
    bullets: [
      {
        name: 'Code Audit and UX Audit',
        link: '/services/code-audit/',
      },
      {
        name: 'Development Teams',
        link: '/services/development-teams/',
      },
    ],
  },
];
