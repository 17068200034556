import React from 'react';
import { FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Cover.scss';

const EventCover = ({ cover }) => {
  return (
    <FullwidthImage
      image={getCloudinaryUrl(cover, 'f_auto,q_auto')}
      additionalClass={'event-cover'}
    />
  );
};

export default EventCover;
