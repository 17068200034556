const configCMS = require('./config.json');
const blogConfigCMS = require('./blogConfig.json');
const career = require('./career.json');
const topBar = require('./topBar.json');
const topBarV2 = require('./topBarV2.json'); // same as topBar but with different values

const enabledTopBars = [topBar, topBarV2].filter(topBarsItems => topBarsItems.display);

module.exports = {
  // GENERAL
  siteUrl: 'https://www.boldare.com',
  pathPrefix: '/',
  siteLogo: '/assets/logo-menu.svg', // Logo used for SEO and manifest.
  topBars: enabledTopBars,
  // CMS
  siteTitle: configCMS.siteTitle || 'Boldare',
  siteTitleAlt: configCMS.siteTitleAlt || 'Boldare',
  siteDescription:
    configCMS.siteDescription ||
    'The Award-winning digital product design and development company with nearly two decades of experience in global markets.',
  siteCover: configCMS.siteCover || '',
  // BLOG
  blog: blogConfigCMS,
  career,
  // THEME
  themeColor: '#f1d624',
  backgroundColor: '#ffffff',
  // APPLICATIONS
  // googleTagManagerID: 'GTM-NJN9MVR',
  disqusShortname: 'boldare',
  siteFBAppID: '',
  facebook: 'https://www.facebook.com/boldarecom/',
  linkedin: 'https://www.linkedin.com/company/boldarecom/',
  twitter: 'https://twitter.com/boldarecom',
  youtube: 'https://www.youtube.com/channel/UCOTEkbD0MSAmBzGFNrnRaxQ',
  instagram: 'https://www.instagram.com/boldarecom',
  awwwards: 'https://www.awwwards.com/boldare',
  clutch: 'https://clutch.co/profile/boldare',
  dribbble: 'https://dribbble.com/Boldare',
  slideshare: 'https://www.slideshare.net/Boldare',
  goodfirms: 'https://www.goodfirms.co/company/boldare',
  behance: 'https://www.behance.net/boldarecom',
  gda: 'https://www.boldare.com/blog/we-won-german-design-awards-2021/',
};
