export const servicesData = {
  title: 'Services',
  description:
    'We build digital products in quick build-measure-learn iterations that help us validate product assumptions with real users to learn, tweak or pivot. Come to us for:',
  services: [
    {
      title: 'Test your business idea',
      description: `Let's answer important questions`,
      items: [
        {
          name: 'Digital Prototyping',
          link: '/services/full-cycle-product-development/prototyping/',
        },
        {
          name: 'Minimum Viable Product (MVP)',
          link: '/services/mvp-development/',
        },
        {
          name: 'UX/UI Design',
          link: '/ux-ui-design-consulting-services/',
        },
      ],
    },
    {
      title: 'Build your digital product',
      description: 'Enough features for future product development.',
      items: [
        {
          name: 'Product Design and Development',
          link: '/services/product-design-and-development/',
        },
        {
          name: 'Full Cycle Product Development',
          link: '/services/full-cycle-product-development/',
        },
        {
          name: 'Development Teams',
          link: '/services/development-teams/',
        },
      ],
    },
    {
      title: 'Grow your product',
      description: 'Get rid of borders if the product has growth potential',
      items: [
        {
          name: 'Product Scaling',
          link: '/services/scaling-your-product-thriving-in-the-market/',
        },
        {
          name: 'Product-Market Fit',
          link: '/services/product-market-fit-expanding-demand/',
        },
        {
          name: 'Digital Transformation',
          link: '/services/digital-transformation/',
        },
      ],
    },
    // {
    //   title: 'Support for your product',
    //   items: [
    //     {
    //       name: 'Code Audit and UX Audit',
    //       link: '/services/code-audit/'
    //     },
    //     {
    //       name: 'Development Teams',
    //       link: '/services/development-teams/'
    //     },
    //   ],
    // },
  ],
};
