import React from 'react';
import Image from '../../Image';
import './SectionHolaspiritPopup.scss';

const SectionHolaspiritPopup = props => {
  const { circleData } = props;
  return (
    <div className={'section-holaspirit-popup'}>
      <div className={'section-holaspirit-popup__wrapper'}>
        <p className={'section-holaspirit-popup__wrapper--breadcrumbs'}>
          {circleData.breadcrumbs}
        </p>
        <h2>{circleData.name}</h2>
      </div>
      <div className={'section-holaspirit-popup__wrapper'}>
        <h3 className={'heading heading--medium-plus-small'}>
          Team’s purpose
        </h3>
        <p className={'text--default text--light'}>
          Purpose describes how a team contributes to the wider goals of the
          organization
        </p>
        <p className={'section-holaspirit-popup__wrapper--purpose'}>
          {circleData.teamPurpose}
        </p>
      </div>
      <div className={'section-holaspirit-popup__wrapper'}>
        <h3 className={'heading heading--medium-plus-small'}>Lead Link</h3>
        <p className={'text--default text--light'}>
          A person whose role includes the circle’s overall strategy (ensuring
          a common alignment of purpose within the circle) and resource
          allocation.
        </p>
        <div className={'section-holaspirit-popup__wrapper--avatar'}>
          <Image imageName={circleData.avatar} />
        </div>
        <div>
          <p className={'paragraph--medium-plus-small'}>
            {circleData.leadlink}
          </p>
          <p className={'text--default text--uppercase'}>
            {circleData.leadlinkRole}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionHolaspiritPopup;
