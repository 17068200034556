import React from 'react';
import { Heading, Section, SectionHeader, SubpageBanner } from 'components';
import { getCloudinarySrcSet, getCloudinaryUrl } from 'config/helpers';
import './Banner.scss';
import { Col, Row } from 'react-flexbox-grid';

const Banner = ({ isEventVariant }) =>
  !isEventVariant ? (
    <Section additionalClass={'career-page__banner'} side={'right'}>
      <Row className={'no-gap career-page__banner-row'}>
        <Col sm={12} md={5} className={'career-page__banner-image'}>
          <img
            src={getCloudinaryUrl(
              'img/office_meeting',
              'f_auto,q_auto,h_1000',
            )}
            srcSet={getCloudinarySrcSet('img/office_meeting', 'f_auto,q_auto')}
            alt={'Office meeting'}
            style={{
              objectPosition: 'top center',
            }}
            className={'image'}
            loading={'lazy'}
          />
        </Col>
        <Col sm={12} md={7}>
          <div className={'career-page__banner-career'}>
            <Heading
              type={3}
              size={'medium'}
              color={'mine-shaft'}
              additionalClass={'headline--top headline--top-violet'}
            >
              Career
            </Heading>
            <SectionHeader
              size={'extra-large'}
              color={'mine-shaft'}
              descriptionColor={'black'}
              buttonColor={'black'}
              fixed
              noLine
              additionalClass={'section-header__description--medium'}
            >
              Hi,{' '}
              <span className={'headline--marking headline--marking-violet'}>
                Bold
              </span>{' '}
              and passionate!
            </SectionHeader>
            <Heading
              type={2}
              size={'medium'}
              color={'mine-shaft'}
              additionalClass={'career-page__banner-lead'}
            >
              Want to work without managers in transparent, self-organized
              scrum teams? Join us!
            </Heading>
            <span className={'link text--mine-shaft text--center'}>
              Learn more &rarr;
            </span>
          </div>
        </Col>
      </Row>
      <Col sm={12} md={6} className={'career-page__banner-image-mobile'}>
        <img
          src={getCloudinaryUrl('img/office_meeting', 'f_auto,q_auto,h_1000')}
          srcSet={getCloudinarySrcSet('img/office_meeting', 'f_auto,q_auto')}
          alt={'Office meeting'}
          style={{
            objectPosition: 'top center',
          }}
          className={'image'}
          loading={'lazy'}
        />
      </Col>
    </Section>
  ) : (
    <SubpageBanner
      additionalClass={'career__banner career__banner--event'}
      backgroundColor={'pigeon-post'}
      contentBackground={'transparent'}
    >
      <SectionHeader
        additionalClass={'section-header--event'}
        size={'big'}
        lineColor={'violet'}
        color={'black'}
        fixed
      >
        <span>Welcome to Boldare.</span>
        <br />
        <span>We &lt;3 meet.js too!</span>
      </SectionHeader>
      <div className={'career__banner__button-container'}>
        <a href={'#game'} className={'button button--primary button--white'}>
          Play the game
        </a>
        <a href={'#offers'} className={'button button--primary button--white'}>
          Check out our job offers
        </a>
      </div>
    </SubpageBanner>
  );

export default Banner;
