import React from 'react';

// Assets
import { ReactComponent as Man } from './man_heading_icon.svg';
import { ReactComponent as Woman } from './woman_heading_icon.svg';

const Heading = () => {
  return (
    <div className={'dsgn__testimonials-heading'}>
      <div className={'dsgn__testimonials-heading-content bldr-dsgn__heading'}>
        <h2>Clients are our biggest advocates</h2>
        <p>We design, develop, and deliver. Here’s the proof.</p>
      </div>
      <div className={'dsgn__testimonials-heading-images'}>
        <div className={'dsgn__testimonials-heading-image'}><Man /></div>
        <div className={'dsgn__testimonials-heading-image'}><Woman /></div>
      </div>
    </div>
  );
};

export default Heading;
