export const technologies = [
  { name: 'Java', img: 'services/logos/java-logo.png', width: 107 },
  { name: 'Spring', img: 'services/logos/spring-logo.png', width: 83 },
  { name: 'PHP', img: 'services/logos/php-logo.png', width: 81 },
  { name: 'Symfony', img: 'services/logos/symfony-logo.png', width: 56 },
  { name: '.NET', img: 'services/logos/dotnet-logo.png', width: 55 },
  { name: 'Python', img: 'services/logos/python-logo.png', width: 58 },
  { name: 'Django', img: 'services/logos/django-logo.png', width: 77 },
  { name: 'JavaScript', img: 'services/logos/javaScript-logo.png', width: 57 },
  { name: 'TypeScript', img: 'services/logos/typescript-logo.png', width: 57 },
  { name: 'Vue.js', img: 'services/logos/vuejs-logo.png', width: 58 },
  { name: 'Angular', img: 'services/logos/angular-logo.png', width: 115 },
  { name: 'React', img: 'services/logos/react-logo.png', width: 75 },
  { name: 'Node.js', img: 'services/logos/nodejs-logo.png', width: 80 },
  { name: 'Nest.js', img: 'services/logos/nest-logo.png', width: 49 },
  { name: 'Marble.js', img: 'services/logos/marble-logo.png', width: 96 },
  { name: 'React Native', img: 'services/logos/react-logo.png', width: 75 },
  { name: 'iOS', img: 'services/logos/ios-logo.png', width: 68 },
  { name: 'Android', img: 'services/logos/android-logo.png', width: 148 },
  { name: 'AWS', img: 'services/logos/aws-logo.png', width: 68 },
  { name: 'Firebase', img: 'services/logos/firebase-logo.png', width: 116 },
  {
    name: 'Kubernetes',
    img: 'services/logos/kubernetes-logo.png',
    width: 105,
  },
  { name: 'Terraform', img: 'services/logos/terraform-logo.png', width: 51 },
  { name: 'Docker', img: 'services/logos/docker-logo.png', width: 63 },
];
