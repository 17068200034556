export const quizQuestions = [
  {
    question: 'What is the status of your product?',
    answers: [
      {
        type: 'prototype',
        content: 'It doesn’t exist yet',
      },
      {
        type: 'mvp',
        content: 'In the process of development but not live yet',
      },
      {
        type: 'pmf',
        content: 'At an early stage with first users',
      },
      {
        type: 'scaling',
        content: 'It’s a mature product',
      },
    ],
  },
  {
    question: 'What are your time to market expectations?',
    answers: [
      {
        type: 'prototype',
        content: 'As soon as possible',
      },
      {
        type: 'mvp',
        content: '2 - 3 months',
      },
      {
        type: 'pmf',
        content: '4 - 6 months',
      },
      {
        type: 'scaling',
        content: 'I’m looking for a long-term partner',
      },
    ],
  },
  {
    question: 'What is your budget?',
    answers: [
      {
        type: 'prototype',
        content: '10 000 - 20 000 EUR',
      },
      {
        type: 'mvp',
        content: '20 000 - 60 000 EUR',
      },
      {
        type: 'pmf',
        content: '60 000 - 100 000 EUR',
      },
      {
        type: 'scaling',
        content: '> 100 000 EUR',
      },
    ],
  },
  {
    question: 'What is your business goal?',
    questionHelperText: 'Choose the closest one:',
    answers: [
      {
        type: 'prototype',
        content: 'To obtain funds, pitch a concept or validate the idea ',
      },
      {
        type: 'mvp',
        content:
          'To deliver the first version of the product as soon as possible and get feedback from users',
      },
      {
        type: 'pmf',
        content:
          'To adjust the product to users’ needs, provide users with new functionalities or improve the user experience',
      },
      {
        type: 'scaling',
        content:
          'To prepare the product for rapid growth (i.e. in terms of growing user base, stability); improving product reliability; optimize the product’s use and value',
      },
    ],
  },
  {
    question: 'What is the current biggest challenge?',
    questionHelperText: 'Choose the closest one:',
    answers: [
      {
        type: 'prototype',
        content:
          'Uncertainty - i.e. regarding scope, necessary budget, team, business model',
      },
      {
        type: 'mvp',
        content: 'Lack of time - a close deadline, big market pressure',
      },
      {
        type: 'pmf',
        content:
          'Missing functionalities - the need to meet target audience expectations',
      },
      {
        type: 'scaling',
        content:
          'Improving product reliability, expanding product without affecting current performance',
      },
    ],
  },
];

export const quizResults = {
  prototype: {
    type: 'prototype',
    fullName: 'Prototype',
    resultContent: `A prototype is an interactive, clickable visualization of the product you would like to build. It needs no coding, or only a little bit. It's a great tool to show the app idea to the stakeholders, and it can be used to pitch the app idea to potential investors.
    <br /><br />
    If you just started seeking support from investors or want to quickly validate your app idea, prototyping is the right choice for you.`,
    resultLink:
      'https://www.boldare.com/blog/digital-product-prototyping-whats-it-all-about/',
    resultImage:
      'generator/how-much-does-it-cost-to-develop-an-app_prototype.png',
    resultData: ['2-3 members', '2-3 weeks', '12 000 - 18 000 EUR'],
    resultFormHandler: '	https://go.boldare.com/l/688533/2021-01-28/k5tzj',
  },
  mvp: {
    type: 'mvp',
    fullName: 'MVP',
    resultContent: `The MVP idea allows us to build and release a fully functional app quickly, within just 4 to 8 weeks. It can be used to test the product hypothesis with minimal budget and resources or to get a working product to market as quickly as possible. An MVP can help avoid spending a big budget on a product that users don’t want.
    <br /><br />
    If you already have a secured but limited budget and you want to release your app quickly, then an MVP is a great solution for you.`,
    resultLink:
      'https://www.boldare.com/blog/mvp-development-everything-you-want-to-know/',
    resultImage: 'generator/how-much-does-it-cost-to-develop-an-app_mvp.png',
    resultData: ['3-5 team members', '6-12 weeks', '39,200 - 78,400 EUR'],
    resultFormHandler: 'https://go.boldare.com/l/688533/2021-01-28/k5tzg',
  },
  pmf: {
    type: 'pmf',
    fullName: 'Product-Market Fit',
    resultContent: `The product-market fit phase is focused on the further development of an MVP or already existing application. It’s all about iteratively releasing features that fulfill the most important business needs of the target users. Development length and costs vary depending on the complexity and the team composition.
    <br /><br />
    This is the service that you are looking for if you need a team to work on an existing app, adding features that are in demand by users.`,
    resultLink:
      'https://www.boldare.com/blog/product-market-fit-team-for-results/',
    resultImage:
      'generator/how-much-does-it-cost-to-develop-an-app_product-market-fit.png',
    resultData: [
      '3-5 team members',
      'Usually 3 months divided into monthly releases',
      '45 000 EUR / 1 release',
    ],
    resultFormHandler: 'https://go.boldare.com/l/688533/2021-01-28/k5v16',
  },
  scaling: {
    type: 'scaling',
    fullName: 'Scaling',
    resultContent: `Scaling is a process focused on building and maintaining the growth of a product in response to an increasing user base. It involves improving the application through a series of iterations, each adding a new element to the product. Each iteration is followed by user testing to validate the effectiveness of the change.
    <br /><br />
    If you want to handle more users or enter new markets, then scaling will help you to align your app to the new requirements.
    `,
    resultLink:
      'https://www.boldare.com/services/scaling-your-product-thriving-in-the-market/',
    resultImage:
      'generator/how-much-does-it-cost-to-develop-an-app_scaling.png',
    resultData: [
      '3-5 team members',
      'Usually quarterly based releases',
      '135,000 EUR / 1 release',
    ],
    resultFormHandler: 'http://go.boldare.com/l/688533/2021-01-28/k4jjs',
  },
  default: {
    type: 'Hmmm...',
    fullName: 'Hmmm...',
    resultContent: `Sorry, but it looks like we need more information to help you with the right answer. Contact us if you want to get a more accurate estimate.
    <br /><br />
    We will take a look at the result of the test and will be back to you shortly. And don’t worry - there are no strings attached!`,
    resultLink: '',
    resultImage:
      'generator/how-much-does-it-cost-to-develop-an-app_software-development.png',
    resultData: [],
  },
};
