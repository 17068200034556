import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from '..';

const ImageCaption = ({ content }) => (
  <>
    <div className={'image-caption-widget'}>
      <Paragraph
        size={'small'}
        additionalClass={'image-caption-widget__content'}
      >
        {content}
      </Paragraph>
    </div>
  </>
);

ImageCaption.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ImageCaption;
