import React from 'react';
import PropTypes from 'prop-types';
import { Input, IconSVG } from 'components';
import { classNames } from 'utils';
import './SearchBar.scss';

const SearchBar = ({ searchTerm, setSearchTerm, additionalClass }) => {
  return (
    <div className={classNames('search', additionalClass)}>
      <Input
        name={'search'}
        id={'search'}
        additionalClass={'search__input'}
        label={'Search for an article'}
        value={searchTerm}
        onChange={setSearchTerm}
      >
        <IconSVG
          additionalClass={classNames(
            'search__icon',
            searchTerm ? 'search__icon-close' : 'search__icon',
          )}
          name={searchTerm ? 'close' : 'search'}
          color={searchTerm ? 'black' : ''}
          onClick={() => setSearchTerm('')}
        />
      </Input>
    </div>
  );
};

SearchBar.defaultProps = {
  additionalClass: '',
};

SearchBar.propTypes = {
  additionalClass: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default SearchBar;
