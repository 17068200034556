import React from 'react';
import { Section, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import companyData from '../../../../../meta/companyData';
import './Experts.scss';

const Experts = () => (
  <Section additionalClass={'connect-page__experts'} spaceless>
    <FullwidthImage
      image={getCloudinaryUrl(
        'gatsby-cloudinary/our-experts-in-the-office.jpg',
        'f_auto,q_auto,w_1420',
      )}
    />
    <div className={'connect-page__experts-box'}>
      <div className={'backdrop'} />
      <Heading type={3} color={'white'} size={'medium-plus'}>
        <span className={'connect-page__experts-number'}>
          {companyData.employees}+
        </span>
        <br />
        experts ready to answer your needs
      </Heading>
    </div>
  </Section>
);

export default Experts;
