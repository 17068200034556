import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['product-development-team-work'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/product-development-team-work/`;

const ProductDevelopmentTeamworkPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={
          'Working with a product development team - a step by step guide'
        }
        cover={getCloudinaryUrl(
          'resources/product-development-team.png',
          'f_auto,q_auto',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/product-development-team.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Product development team'}
        description={
          "If you're planning to work with an external development team to build your next digital product, our guide will be an essential resource for you. It will help you with:"
        }
        items={[
          'Selecting a product development partner who will understand your pains and needs',
          'Preventing costly mistakes with red flags list',
          'Gaining expert insights and advice based on two decades of our experience in the product development field',
        ]}
        formHeading={'Get your free ebook now:'}
        url={url}
        button={'Send me the ebook'}
        btnColor={'pigeon-post'}
        trackingEvent={'ebookProductDevelopmentTeamwork'}
      />
    </Layout>
  );
};

export default ProductDevelopmentTeamworkPage;
