import React from 'react';
import PropTypes from 'prop-types';
import { IconSVG } from 'components';
import { classNames } from 'utils';
import './SliderArrow.scss';

const SliderArrow = ({
  className,
  onClick,
  left,
  id,
  defaultClasses,
  color,
  ...other
}) => {
  return (
    <div
      className={classNames('slide-arrow', {
        className: defaultClasses,
        'slide-prev': left,
        'slide-next': !left,
      })}
      onClick={onClick}
      role={'presentation'}
      id={id}
    >
      <IconSVG name={left ? 'arrow-left' : 'arrow-right'} color={color} />
    </div>
  );
};

SliderArrow.propTypes = {
  left: PropTypes.bool,
  color: PropTypes.string,
  defaultClasses: PropTypes.bool,
};

SliderArrow.defaultProps = {
  left: false,
  color: 'black',
  defaultClasses: false,
};

export default SliderArrow;
