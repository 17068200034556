import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { chooseWidget, getWidgetProps, getFormInitState } from './logic';

const Fieldset = ({ fields, onChange, fieldsetState = {} }) => {
  return (
    <>
      {fields.map(field => {
        if (field.type) {
          const Widget = chooseWidget(field.type);
          return (
            <Widget {...getWidgetProps(field, fieldsetState, onChange)} />
          );
        }
        if (field.fields) {
          return (
            <Fieldset
              key={`fieldset--${field.key}`}
              fields={field.fields}
              onChange={value =>
                onChange({ ...fieldsetState, [field.key]: value })
              }
              fieldsetState={fieldsetState[field.key]}
            />
          );
        }
      })}
    </>
  );
};

const Form = ({
  additionalClass,
  fields,
  onChange,
  onSubmit,
  children,
  submitting,
}) => {
  const [state, setState] = useState(getFormInitState(fields));
  useEffect(() => onChange(state), state);

  return (
    <form
      className={additionalClass}
      onSubmit={e => {
        e.preventDefault();
        onSubmit(state);
      }}
    >
      <Fieldset
        fields={fields}
        onChange={value => setState(value)}
        fieldsetState={state}
      />
      <div className={'form__children'}>{children}</div>
      <div className={'form__submit'}>
        <Button
          type={'submit'}
          color={'yellow'}
          additionalClass={submitting ? 'loading' : ''}
          loading={submitting}
        >
          Apply
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      additionalClass: PropTypes.string,
      label: PropTypes.string,
      fields: PropTypes.array,
      value: PropTypes.node,
      values: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      ),
      maxFileSize: PropTypes.number,
      required: PropTypes.bool,
      supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.oneOf([
        'checkbox',
        'checkboxGroup',
        'file',
        'textarea',
        'text',
        'email',
        'number',
      ]),
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  submitting: PropTypes.bool,
};

Form.defaultProps = {
  additionalClass: '',
  onChange: () => {},
  children: null,
  submitting: false,
};

export default React.memo(Form);
