import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Paragraph } from 'components';
import './TitleAskCto.scss';
import PropTypes from 'prop-types';

const TitleAskCto = ({
  title,
  text,
  secondTitle,
  secondText,
  white,
  line,
}) => {
  return (
    <Row>
      <Col sm={12} md={5}>
        <Paragraph
          color={white ? 'white' : 'black'}
          size={'large'}
          additionalClass={line ? 'title-ask-cto' : 'title-ask-cto-noline'}
        >
          {title}
        </Paragraph>
        {secondTitle && (
          <Paragraph
            color={white ? 'white' : 'black'}
            size={'large'}
            additionalClass={'title-ask-cto'}
          >
            {' '}
            <br />
            {secondTitle}
          </Paragraph>
        )}
      </Col>
      <Col sm={12} md={7}>
        {text && (
          <Paragraph
            color={white ? 'white' : 'black'}
            size={'medium'}
            additionalClass={
              line
                ? 'title-ask-cto-paragraph'
                : 'title-ask-cto-paragraph-noline'
            }
          >
            <b>{text}</b>
            {secondText && (
              <>
                <br />
                <br />
                {secondText}
              </>
            )}
          </Paragraph>
        )}
      </Col>
    </Row>
  );
};

TitleAskCto.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  secondTitle: PropTypes.string,
  secondText: PropTypes.string,
  white: PropTypes.bool,
  line: PropTypes.bool,
};

TitleAskCto.defaultProps = {
  text: null,
  secondTitle: null,
  secondText: null,
  white: false,
  line: true,
};

export default TitleAskCto;
