import React from 'react';
import { Button } from 'components';
import './Contact.scss';

export default function Contact() {
  return (
    <div className={'ses-container'}>
      <div className={'ses-container__bg'}>
        <div className={'ses-title'}>
          <h2 className={'heading heading--medium-plus'}>
            Interested in finding out how you may benefit from Service
            Standards?
          </h2>
          <a href={'/contact'}>
            <Button id={`ses-contact`} color={'black'} ariaName={'Contact'}>
              Let's talk
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
