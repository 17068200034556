import React from 'react';
import { Section, Paragraph, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Offer.scss';

const Offer = () => (
  <Section color={'mine-shaft'} additionalClass={'prototyping__offer'}>
    <div className={'prototyping__offer-row'}>
      <div className={'prototyping__offer-col'}>
        <SectionHeader
          size={'big'}
          color={'white'}
          lineColor={'cornflower'}
          fixed
        >
          Offer
        </SectionHeader>
      </div>
      <div className={'prototyping__offer-col'}>
        <Paragraph color={'white'} size={'medium'}>
          From ideation to digital prototype delivery our prototyping teams
          deliver value for your business. Our services cover every aspect of
          digital prototyping: ideation, product design and development and
          consulting.
        </Paragraph>
      </div>
    </div>
    <div className={'prototyping__offer-row'}>
      <div className={'prototyping__offer-col'}>
        <img
          src={getCloudinaryUrl(
            '/services/prototyping/photo-left.jpg',
            'f_auto,q_auto,w_720',
          )}
          className={'image'}
          alt={'Prototyping offer'}
          style={{ width: '100%' }}
          loading={'lazy'}
        />
      </div>
      <div className={'prototyping__offer-col'}>
        <Section
          color={'white'}
          additionalClass={'prototyping__offer-statistics'}
        >
          <Paragraph
            size={'big'}
            additionalClass={'prototyping__offer-statistic--key text--bold'}
          >
            Delivery:
          </Paragraph>
          <Paragraph
            size={'medium-plus-small'}
            additionalClass={'prototyping__offer-statistic'}
          >
            1-2 weeks
          </Paragraph>
          <Paragraph
            size={'big'}
            additionalClass={'prototyping__offer-statistic--key text--bold'}
          >
            Processes:
          </Paragraph>
          <Paragraph
            size={'medium-plus-small'}
            additionalClass={'prototyping__offer-statistic'}
          >
            Design Sprints, Design Thinking, <br />
            Agile/Scrum
          </Paragraph>
          <Paragraph
            size={'big'}
            additionalClass={'prototyping__offer-statistic--key text--bold'}
          >
            Outputs:
          </Paragraph>
          <Paragraph size={'medium-plus-small'}>
            a clickable prototype ready for testing
          </Paragraph>
        </Section>
      </div>
    </div>
    <div className={'prototyping__offer-row'} style={{ marginTop: '60px' }}>
      <div className={'prototyping__offer-col'}>
        <Paragraph
          color={'white'}
          size={'medium'}
          nested
          additionalClass={'prototyping__offer-description'}
        >
          Boldare’s prototyping team will guide you through the entire digital
          prototyping process. As part of our services, we will carry out an
          in-depth analysis of the potential user’s pain points and help you
          choose the one for digital prototyping. Together with you, we will
          define the potential solutions and pick the most viable one to design
          and build an app prototype. Finally, you will get a testable digital
          prototype perfectly suited to your business needs. Our services are
          always tailored to you specific requirements and every prototype is
          custom made for the client by a dedicated agile development team.
        </Paragraph>
      </div>
      <div className={'prototyping__offer-col'}>
        <img
          src={getCloudinaryUrl(
            '/services/prototyping/photo-right.jpg',
            'f_auto,q_auto,w_720',
          )}
          alt={'Prototyping offer'}
          className={'image'}
          loading={'lazy'}
        />
      </div>
    </div>
  </Section>
);

export default Offer;
