import './index.scss';
import Slider from 'react-slick';
import React, { useState, useRef } from 'react';

// Data
import { CHALLENGES } from './challengesList';

// Components
import ChallengeBox from './ChallengeBox';
import ChallengesNav from './ChallengesNav';
import { ReactComponent as Shape1 } from './shape-1.svg';
import { ReactComponent as Shape2 } from './shape-2.svg';

const Challenges = () => {
  const [counter, setCounter] = useState(2);

  const sliderRef = useRef();

  const goToPrev = () => {
    sliderRef.current.slickPrev();
    setCounter(counter - 1);
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
    setCounter(counter + 1);
  };

  const challengeSettings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 2,
    draggable: false,
    slidesToScroll: 1,
    autoplaySpeed: 30000,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className={
        'bldr-dsgn__border-box bldr-dsgn__section dsgn--full-screen border-off'
      }
    >
      <div className={'bldr-dsgn__border-box bldr-dsgn__heading-challenges'}>
        <div className={'bldr-dsgn__heading'}>
          <h2>Faced with challenges?</h2>
          <p>Discover how we tackle them through design.</p>
        </div>
        <div className={'bldr-dsgn__heading-challenges-shapes'}>
          <div className={'bldr-dsgn__heading-challenges-shapes-image'}><Shape1 /></div>
          <div className={'bldr-dsgn__heading-challenges-shapes-image'}><Shape2 /></div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div className={'dsgn-hero__challenges-wrapper'}>
          <Slider {...challengeSettings} ref={sliderRef}>
            {CHALLENGES.map(challenge => (
              <ChallengeBox
                tags={challenge.tags}
                solution={challenge.solution}
                challenge={challenge.challenge}
                Frame={challenge.frame}
                authorName={challenge.authorName}
                authorPhoto={challenge.authorPhoto}
                authorPosition={challenge.authorPosition}
              />
            ))}
          </Slider>
        </div>
      </div>
      <ChallengesNav nextAction={goToNext} prevAction={goToPrev} />
    </div>
  );
};

export default Challenges;
