import React, { Component } from 'react';
import { Banner, Bonus, CourseContent } from './subcomponents';
import './Course.scss';
import {
  firstSection,
  secondSection,
  thirdSection,
  bonus,
} from './crash-course-logic.js';

class Course extends Component {
  render() {
    return (
      <div className={'single-page single-page--hero webinar-res'}>
        <Banner />
        <Bonus data={bonus} />
        <CourseContent data={firstSection} />
        <CourseContent data={secondSection} />
        <CourseContent data={thirdSection} />
      </div>
    );
  }
}

export default Course;
