import React, { useState } from 'react';
import { Section, Heading, Paragraph, Container, IconSVG } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import ReactHtmlParser from 'react-html-parser';
import { data } from './data';
import './DiscoverAI.scss';

const DiscoverAI = () => {
  const [activeItem, setActiveItem] = useState(0);

  const clickHandler = index => {
    if (activeItem === index) {
      setActiveItem(-1);
      return;
    }
    setActiveItem(index);
  };

  const boldTwoWords = sentence => {
    let y = sentence.split(' ').slice(0, 2);
    y = y.toString();
    y = y.replace(',', ' ');
    const substr = `<b>${y}</b>`;
    const x = sentence.replace(y, substr);
    return x;
  };

  return (
    <Section
      id={'asdc-discover'}
      color={'mine-shaft'}
      additionalClass={'discoverai-section'}
    >
      <Heading size={'large'} type={2} color={'white'}>
        Discover the potential of AI software development services across
        various industries
      </Heading>
      <Paragraph size={'medium-plus-small'} color={'white'}>
        Discover how AI services can be tailored to address challenges across
        various industries, simplifying problem-solving and enhancing
        efficiency
      </Paragraph>
      <Container additionalClass={'discoverai-container'}>
        <div className={'tabs'}>
          {data.map((item, index) => (
            <button
              className={classNames(
                'button',
                activeItem === index
                  ? 'button--violet tabs--active'
                  : 'button--white button--primary',
              )}
              onClick={() => clickHandler(index)}
              id={`ai-discover-${item.category}-button`}
            >
              <div className={'tabs__item'}>
                <IconSVG
                  name={item.icon}
                  size={'big'}
                  color={activeItem === index ? 'white' : 'black'}
                  additionalClass={'tabs__item--icon'}
                />
                {item.category.toUpperCase()}
              </div>
            </button>
          ))}
        </div>
        {data.map((item, index) => (
          <div
            className={classNames(
              'tabs__content',
              activeItem === index ? 'active' : '',
            )}
          >
            <Heading
              size={'big'}
              type={3}
              color={'black'}
              additionalClass={'discoverai__header'}
            >
              <IconSVG name={item.icon} size={'large'} color={'black'} />
              {item.name}
            </Heading>
            <Paragraph
              size={'medium-plus-small'}
              color={'black'}
              additionalClass={'text--bold'}
            >
              {item.description}
            </Paragraph>
            <div className={'list'}>
              <ul className={'dotted-list__items'}>
                {item.points.map(pointItem => (
                  <li>{ReactHtmlParser(boldTwoWords(pointItem))}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Container>
      <div className={'discoverai__image'}>
        <img
          src={getCloudinaryUrl(
            'assets/ai-software/microscope.png',
            'f_auto,q_auto,w_auto',
          )}
          alt={'business-consultant'}
        />
      </div>
    </Section>
  );
};

export default DiscoverAI;
