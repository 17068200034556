import Cookies from 'js-cookie';
import queryString from 'query-string';
import { navigate } from 'gatsby';

export const generateHash = () =>
  Math.random()
    .toString(36)
    .substring(2, 10);

export const serialize = form => {
  let field;

  let l;

  const s = [];

  if (typeof form === 'object' && form.nodeName === 'FORM') {
    const len = form.elements.length;
    for (let i = 0; i < len; i++) {
      field = form.elements[i];
      if (
        field.name &&
        !field.disabled &&
        field.type !== 'file' &&
        field.type !== 'reset' &&
        field.type !== 'submit' &&
        field.type !== 'button'
      ) {
        if (field.type === 'select-multiple') {
          l = form.elements[i].options.length;
          for (let j = 0; j < l; j++) {
            if (field.options[j].selected) {
              s[s.length] = `${encodeURIComponent(
                field.name,
              )}=${encodeURIComponent(field.options[j].value)}`;
            }
          }
        } else if (
          (field.type !== 'checkbox' && field.type !== 'radio') ||
          field.checked
        ) {
          s[s.length] = `${encodeURIComponent(
            field.name,
          )}=${encodeURIComponent(field.value)}`;
        }
      }
    }
  }
  return s.join('&').replace(/%20/g, '+');
};

// Basic classNames usage example:
// <div className={classNames('classname-that-should-always-appear', {
//  'conditionally-applied-classname': whenThisValueIsTruthyTheClassNameIsAddedToTheElement,
// })}>
// ------------------------------------------------------------------------------------------------
// You can also create classes dynamically based on value passed in some prop/variable like so:
// <div className={classNames('classname-that-should-always-appear', {
//  [`col--${columnWidth}`]: columnWidth,
// })}>
// ------------------------------------------------------------------------------------------------
// You can also pass an array if you feel like it ¯\_(ツ)_/¯
// <div className={classNames(['some-classname', {
//   'conditionally-applied-classname': whenThisValueIsTruthyTheClassNameIsAddedToTheElement,
//   [`col--${columnWidth}`]: columnWidth,
// }, 'some-other-classname'])}>
export const classNames = (...args) => {
  let classes = [];

  args
    .filter(Boolean) // filter out falsy values
    .forEach(arg => {
      const argType = typeof arg;

      if (Array.isArray(arg) && arg.length) {
        classes = [...classes, ...classNames(...arg)];
        return;
      }

      if (argType === 'object') {
        // iterate over received object and check if class name conditionals are truthy
        Object.entries(arg).forEach(item => {
          const [key, value] = item;

          if (Object.hasOwnProperty.call(arg, key) && value) {
            classes.push(key);
          }
        });
        return;
      }

      if (argType === 'string' || argType === 'number') {
        classes.push(arg);
      }
    });

  // remove duplicates and join all classes into a single string
  return Array.from(new Set(classes)).join(' ');
};

export const getYoutubeThumbnailUrl = imgSrc => {
  const youtubeVideoId = imgSrc.split('v=')[1];
  return `https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`;
};

export const getPlatformShareUrl = (baseUrl, platform) => {
  return `${baseUrl}?utm_source=${platform}&utm_medium=social`;
};

export const navigateToId = id => {
  window.location.replace(`#${id}`);
  navigate(`${window.location.pathname}#${id}`, { replace: true });
};

export const addQueryParam = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  navigate(`${window.location.pathname}?${searchParams.toString()}`, {
    replace: true,
  });
};

const COOKIE_NAME = 'gatsby-theme-password-protect';

export const setSessionPassword = passwordCandidate => {
  Cookies.set(COOKIE_NAME, passwordCandidate);
};

export const getSessionPassword = () => {
  return Cookies.get(COOKIE_NAME);
};

export const getQueryPassword = location => {
  const { secret } = queryString.parse(location.search);
  return secret;
};

export const isProtectedPage = ({ pathname }, pagePaths, partialMatching) => {
  const isProtected = pagePaths.find(path => {
    if (partialMatching) {
      return path.startsWith(pathname);
    }

    return path === pathname;
  });

  return isProtected;
};

export const countDown = deadlineDate => {
  const now = new Date().getTime();
  const distance = deadlineDate.getTime() - now;

  if (distance < 0) {
    return null;
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  return `${days} d ${hours} h ${minutes} m`;
};

export const getPathLevels = () =>
  window.location.pathname.split('/').filter(Boolean);

export const getParenthPath = () =>
  `${getPathLevels()
    .slice(0, -1)
    .join('/')}/`;

export const getRandomInt = (min, max) => {
  const minCeiling = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeiling) + minCeiling); // The maximum is exclusive and the minimum is inclusive
};

export const unflatLocalSearchResults = results =>
  results.map(post => {
    const {
      id,
      title,
      author,
      authorAdditional,
      body,
      coverImage,
      tileDescription,
      cover,
      lead,
      slug,
      tags,
      templateKey,
      category,
      additionalCategories,
      url,
      date,
      additionalSlug,
    } = post;
    return {
      node: {
        id,
        fields: { slug },
        frontmatter: {
          author,
          authorAdditional,
          body: { content: { body } },
          box: { content: { coverImage, tileDescription } },
          cover,
          lead,
          title,
          tags,
          templateKey,
          settings: {
            category,
            additionalCategories,
            url,
            date,
            slug: additionalSlug,
          },
        },
      },
    };
  });

export const sortLocalSearchResultsDesc = results =>
  results.sort(
    (
      {
        node: {
          frontmatter: {
            settings: { date: dateA },
          },
        },
      },
      {
        node: {
          frontmatter: {
            settings: { date: dateB },
          },
        },
      },
    ) => new Date(dateB) - new Date(dateA),
  );

export const pushToDataLayer = item => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: item,
    });
  }
};
