import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { AnimatedText } from '..';
import { SliderContext } from '../../utils';

import './Quote.scss';

const Quote = ({ index }) => {
  const { slides } = useContext(SliderContext);

  const { firstText, underlineText, secondText } = slides[index].text;
  const container = {
    visible: {
      transition: {
        staggerChildren: 0.02,
      },
    },
  };

  return (
    <motion.div
      initial={'hidden'}
      animate={'visible'}
      variants={container}
      className={'hero-quote__container'}
    >
      <blockquote className={'hero-quote'}>
        <div className={'hero-quote__header'}>
          <AnimatedText text={firstText} key={`${firstText}${index}`} />
          <AnimatedText
            text={underlineText}
            watered
            key={`${underlineText}${index}`}
          />
          <AnimatedText text={secondText} key={`${secondText}${index}`} />
        </div>
      </blockquote>
    </motion.div>
  );
};

Quote.defaultProps = {
  index: 0,
};

Quote.propTypes = {
  index: PropTypes.number,
};

export default Quote;
