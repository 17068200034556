import React, { Component } from 'react';
import { Section, Heading, Paragraph, Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Sketches.scss';

class Sketches extends Component {
  renderSection = () =>
    data.map((item, index) => (
      <div className={`pe__sketches-container`} key={`section-${index + 1}`}>
        <div className={'pe__sketches-informations'}>
          <Heading type={2} size={'medium-plus'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.description}
          </Paragraph>
        </div>
        <div className={'pe__sketches-images'}>
          {this.renderImages(item.images)}
        </div>
      </div>
    ));

  renderImages = data =>
    data.map(image => (
      <img
        key={image}
        src={getCloudinaryUrl(
          `assets/planet-escape/${image}`,
          'f_auto,q_auto',
        )}
        alt={'Planet Escape'}
        className={'image'}
        loading={'lazy'}
      />
    ));

  render() {
    return (
      <Section additionalClass={'pe__sketches'} spaceless>
        {this.renderSection()}
      </Section>
    );
  }
}

export default Sketches;
