import React from 'react';
import PropTypes from 'prop-types';
import { SimpleBanner } from './SimpleBanner';

export const SimpleBannerWithoutPerson = ({
  title,
  titleColor,
  backgroundColor,
  textColor,
  text,
  contactPersonName,
  contactPersonRole,
  contactPersonImage,
  personImageBackgroundMask,
  buttonText,
  buttonLink,
  buttonId,
  buttonBackgroundColor,
  buttonTextColor,
}) => {
  return (
    <SimpleBanner
      title={title}
      titleColor={titleColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      text={text}
      bannerWithPerson={false}
      contactPersonName={contactPersonName}
      contactPersonRole={contactPersonRole}
      contactPersonImage={contactPersonImage}
      personImageBackgroundMask={personImageBackgroundMask}
      buttonText={buttonText}
      buttonLink={buttonLink}
      buttonId={buttonId}
      buttonBackgroundColor={buttonBackgroundColor}
      buttonTextColor={buttonTextColor}
    />
  );
};

SimpleBannerWithoutPerson.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  bannerWithPerson: PropTypes.bool.isRequired,
  contactPersonName: PropTypes.string,
  contactPersonRole: PropTypes.string,
  contactPersonImage: PropTypes.string,
  personImageBackgroundMask: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

SimpleBannerWithoutPerson.defaultProps = {
  titleColor: 'black',
  backgroundColor: 'grey',
  textColor: 'black',
  contactPersonName: null,
  contactPersonRole: null,
  contactPersonImage: null,
  personImageBackgroundMask: null,
  buttonId: 'simple-banner-button',
  buttonBackgroundColor: 'red',
  buttonTextColor: 'black',
};
