import React from 'react';
import { Section, Link, SectionHeader, HashtagList } from 'components';
import { classNames } from 'utils';
import './CaseStudyBanner.scss';

const CaseStudyBanner = ({
  link,
  color,
  textColor,
  image,
  logo,
  title,
  tags,
  additionalClass,
  client,
}) => (
  <Section
    color={color}
    additionalClass={`case-study-banner ${additionalClass}`}
  >
    <div className={'case-study-banner__content'}>
      {logo && (
        <image
          src={logo}
          alt={client}
          className={'image case-study-banner__logo'}
        />
      )}
      <Link to={link}>
        <SectionHeader
          size={'medium'}
          color={textColor}
          lineColor={textColor}
          additionalClass={'case-study-banner__headline'}
          type={2}
        >
          {title}
        </SectionHeader>
      </Link>
      {tags && (
        <div className={'case-study-banner__tags'}>
          <HashtagList color={textColor || 'black'} data={tags} />
        </div>
      )}
    </div>
    {image && (
      <div
        className={classNames('case-study-banner__image-wrapper', {
          [`case-study-banner__image-wrapper--${color}`]: color,
        })}
      >
        <img
          src={image}
          alt={title}
          className={'image case-study-banner__image'}
          loading={'lazy'}
        />
      </div>
    )}
  </Section>
);

export default CaseStudyBanner;
