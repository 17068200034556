import React, { Component } from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import BookDiscoverySession from 'views/bookDiscoverySession';

import config from '../../meta/config';

class BookADiscoverySession extends Component {
  render() {
    const page = pages['book-a-discovery-session'];
    const { navColors } = page;
    const canonical = `${config.siteUrl}/book-a-discovery-session/`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} bannerHidden />
        <BookDiscoverySession />
      </Layout>
    );
  }
}

export default BookADiscoverySession;
