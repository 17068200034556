/* eslint-disable */

import React from 'react';
import { transformProxies } from './helpers';
import { getCloudinaryUrl } from 'config/helpers';

import '../css/normalize.css';
import '../css/webflow.css';
import '../css/agile-product-builders.webflow.scss';

import '../js/index.js';

class IndexView extends React.Component {
  componentDidMount() {
    const scripts = [
      {
        loading: fetch(
          'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6492dfc45f24df56cadb0c2d',
        ).then(body => body.text()),
        isAsync: false,
      },
      {
        loading: fetch('js/webflow.js').then(body => body.text()),
        isAsync: false,
      },
    ];

    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '6492dfc45f24df56cadb0c5f';
    htmlEl.dataset['wfSite'] = '6492dfc45f24df56cadb0c2d';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then(active => {
        const loading = active.loading.then(script => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      }),
    );
  }

  render() {
    const proxies =
      IndexView.Controller !== IndexView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/agile-product-builders.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body">
            <div
              data-collapse="medium"
              data-animation="default"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="af-class-navigation w-nav"
            >
              <div className="af-class-navigation-wrap">
                <a
                  href="index.html"
                  aria-current="page"
                  className="af-class-logo-link w-nav-brand w--current"
                >
                  <img
                    src={getCloudinaryUrl(
                      `apb-community/APB---FULL-LOGO.png`,
                      'f_auto,q_auto,w_auto',
                    )}
                    loading="lazy"
                    alt
                  />
                </a>
                <div className="af-class-menu">
                  <nav
                    role="navigation"
                    className="af-class-navigation-items w-nav-menu"
                  >
                    <img
                      src={getCloudinaryUrl(
                        `apb-community/Vector.png`,
                        'f_auto,q_auto,w_auto',
                      )}
                      loading="lazy"
                      alt
                      className="af-class-image"
                    />
                  </nav>
                  <div className="af-class-menu-button w-nav-button" />
                </div>
              </div>
            </div>
            <div className="af-class-frame-1">
              <div className="af-class-content">
                <div className="af-class-text">
                  <div className="af-class-text-2">
                    WHAT’S COOKIN’, PRODUCT BUILDERS?
                  </div>
                  <div className="af-class-text-3">
                    Join a community connecting and enabling digital product
                    builders
                  </div>
                  <div className="af-class-text-4">
                    We are a community supporting those who design, create or
                    build digital products on the one hand and those who need
                    these products on the other.
                  </div>
                </div>
                <a
                  href="https://join.slack.com/t/agileproductbuilders/shared_invite/zt-1nmpe92sw-pfCIGXqSWEn4UKXwIF22yQ"
                  target="_blank"
                  className="af-class-button-2"
                >
                  <div className="af-class-text-5">
                    JOIN&nbsp;US&nbsp;ON&nbsp;SLACK
                  </div>
                  <img
                    src={getCloudinaryUrl(
                      `apb-community/Vectors-Wrapper.svg`,
                      'f_auto,q_auto,w_100',
                    )}
                    loading="lazy"
                    width={24}
                    height={24}
                    alt
                    className="af-class-vectors-wrapper"
                  />
                </a>
                <p className={'af-membership'}>
                  Membership includes a community monthly newsletter.
                  <br />
                  Unsubscribe anytime.
                </p>
              </div>
            </div>

            <section className="af-class-team-slider af-class-wf-section">
              <div className="af-class-container-2">
                <h2 className="af-class-centered-heading">Community events</h2>
                <p className="af-class-centered-subheading">
                  Our aim is to create a space where people from both sides of
                  the product-building spectrum can freely interact.
                </p>
                <div
                  data-delay={4000}
                  data-animation="slide"
                  className="af-class-team-slider-wrapper w-slider"
                  data-autoplay="false"
                  data-easing="ease"
                  data-hide-arrows="false"
                  data-disable-swipe="false"
                  data-autoplay-limit={0}
                  data-nav-spacing={12}
                  data-duration={500}
                  data-infinite="false"
                >
                  {/* TEMPORARY - Hide meet and greet} */}
                  {/*<div className="w-slider-mask">
                    <div className="af-class-team-slide-wrapper w-slide">
                      <div className="af-class-team-block"><img src={getCloudinaryUrl(
                      `apb-community/Event1.png`, 'f_auto,q_auto,w_auto'
                      )} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 45vw, (max-width: 991px) 43vw, 28vw" alt className="af-class-team-member-image-two" />
                        <div className="af-class-team-block-info">
                          <p className="af-class-team-member-text af-class-date">Date: 21.09.2023</p>
                          <h3 className="af-class-team-member-name-two">Meet and Greet</h3>
                          <p className="af-class-team-member-text">Engage with the community and spark new conversations.</p>
                          <a href="https://us06web.zoom.us/meeting/register/tZwof-Gorz0uGde0NMZ-TbUsA75NZK6xfGmG" target="_blank" className="af-class-text-link-arrow w-inline-block">
                            <div className="af-class-text-block">JOIN&nbsp;US</div>
                            <div className="af-class-arrow-embed w-embed"><svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.72 15L16.3472 10.357C16.7732 9.92932 16.7732 9.23603 16.3472 8.80962L11.72 4.16667L10.1776 5.71508L12.9425 8.4889H4.16669V10.6774H12.9425L10.1776 13.4522L11.72 15Z" fill="currentColor" />
                              </svg></div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="af-class-team-slider-arrow w-slider-arrow-left">
                    <div className="w-icon-slider-left" />
                  </div>
                  <div className="af-class-team-slider-arrow w-slider-arrow-right">
                    <div className="w-icon-slider-right" />
                  </div>
                  <div className="af-class-team-slider-nav w-slider-nav w-slider-nav-invert w-round" />
                </div>
              </div>
            </section>
            <div className="af-class-section">
              <div className="af-class-container" />
            </div>
            <section className="af-class-footer-subscribe af-class-wf-section">
              <div className="af-class-container-3">
                <div className="af-class-footer-divider-two" />
                <div className="af-class-footer-bottom">
                  <div className="af-class-footer-copyright">
                    © 2022 Agile Product&nbsp;Builders. All rights reserved
                  </div>
                  <div className="af-class-footer-legal-block">
                    <a
                      href="https://www.boldare.com/privacy-policy/"
                      target="_blank"
                      className="af-class-footer-legal-link"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </span>
      </span>
    );
  }
}

export default IndexView;

/* eslint-enable */
