import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FullwidthImage, Section, Heading, Paragraph } from 'components';
import CountUp from 'react-countup';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import './Combinations.scss';

class Combinations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.oneida__combinations-content').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  render() {
    const { image, description, subtitle, title, number } = this.props;
    const { enableCounterAnimation } = this.state;
    const withCounter = number =>
      enableCounterAnimation && (
        <span>
          <CountUp end={number} delay={0.3} />
        </span>
      );

    return (
      <Section additionalClass={'oneida__combinations-container'}>
        <div className={'oneida__combinations-content'}>
          <div className={'oneida__content'}>
            {number ? (
              <Heading
                type={2}
                size={'extra-large'}
                color={'white'}
                additionalClass={'oneida__combinations-counter'}
              >
                {title}
                {withCounter(number)}
              </Heading>
            ) : (
              <Heading type={2} size={'big-spreaded'} color={'white'}>
                {title}
              </Heading>
            )}
            {subtitle && (
              <Heading
                type={4}
                size={'medium-constant'}
                additionalClass={'text--default'}
                color={'white'}
              >
                {subtitle}
              </Heading>
            )}
            <Paragraph
              size={'medium-constant'}
              color={'white'}
              additionalClass={'text--light'}
            >
              {description}
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(`assets/oneida/${image}`, 'f_auto,q_auto')}
            alt={'Mobile app'}
          />
        </div>
      </Section>
    );
  }
}

Combinations.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
};

export default Combinations;
