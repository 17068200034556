import React, { Component } from 'react';
import { classNames } from 'utils';
import { ContactFormServices } from 'components';
import { Banner, Bonus, WebinarContent } from './subcomponents';
import './Webinar.scss';

class Webinar extends Component {
  render() {
    const {
      banner,
      firstSection,
      secondSection,
      thirdSection,
      fourthSection,
      bonus,
    } = this.props;

    return (
      <div
        className={classNames('single-page single-page--hero webinar-res', {
          'webinar-res--dark': banner.theme === 'dark',
        })}
      >
        {banner && <Banner data={banner} />}
        {/* <Bonus data={bonus} /> */}
        {firstSection && <WebinarContent data={firstSection} />}
        {secondSection && <WebinarContent data={secondSection} />}
        {thirdSection && <WebinarContent data={thirdSection} />}
        {fourthSection && <WebinarContent data={fourthSection} />}
        <ContactFormServices
          id={'code-audit-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2020-08-09/9fytr'}
          codeAudit
        />
      </div>
    );
  }
}

export default Webinar;
