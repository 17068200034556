import React from 'react';
import { Heading } from 'components';
import { TextInteractionAnimation } from 'views/remoteWork/components';
import './BenefitsSliderSection.scss';
import { BenefitsSlider } from './subcomponents';
import { data } from './data';

const BenefitsSliderSection = () => (
  <section className={'section-benefits'} id={'work-on-remote'}>
    <Heading
      size={'big'}
      additionalClass={'section-benefits__heading'}
      type={2}
    >
      What’s it like to be part
      <br />
      <span>of the </span>
      <TextInteractionAnimation text={'#BoldareTeam'} />
      <span> on remote?</span>
    </Heading>
    <BenefitsSlider id={'benefits-slider'} items={data} />
  </section>
);

export default BenefitsSliderSection;
