import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import SVG from 'react-inlinesvg';
import { Section, Heading, SectionTitle, Paragraph, Button } from 'components';
import chart from 'assets/images/fpdc/chart-lean.svg';
import { StageTile } from '..';

class Stages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
    };
  }

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <div className={'fpdc__stages'}>
        <Section color={'red'}>
          <Row className={'no-gap anim-fade-in-bottom'}>
            <Col sm={12} md={6}>
              <SectionTitle size={'big'} color={'white'}>
                Solution
              </SectionTitle>
            </Col>
            <Col sm={12} md={6}>
              <div className={'fpdc__stages-desc'}>
                <Heading color={'white'} size={'medium-plus'} type={2}>
                  At Boldare we use the lean startup approach and scrum
                  framework to deliver digital products that generate value to
                  companies and users.
                </Heading>
                <Paragraph
                  additionalClass={'text--light'}
                  size={'medium'}
                  color={'white'}
                >
                  Our agile development teams aim to take your web product to
                  market as quickly as possible, test it with real users,
                  iterate, find the perfect product-market fit for your
                  business and finally, develop a scalable version.
                </Paragraph>
                {isFullDescriptionShown && (
                  <Paragraph
                    additionalClass={
                      'fpdc__additional-description text--light'
                    }
                    color={'white'}
                    size={'medium'}
                  >
                    Whether you are looking to grow an e-commerce, SaaS
                    platform, enterprise solution or a mobile app, the lean
                    startup approach will help you deliver the product that
                    users want and find the right business model for growth.
                    <br /> <br />
                    The scrum framework ensures that we deliver real value with
                    each sprint (usually two weeks) and that every increment we
                    show you matches your expectations. Additionally, you can
                    control the budget and change the scope of work with every
                    sprint.
                  </Paragraph>
                )}
                <div
                  className={'fpdc__learn-more-button'}
                  onClick={this.toggleHiddenDescription}
                >
                  <Button
                    order={'secondary'}
                    color={'white'}
                    arrowColor={'black'}
                    ariaName={'Description button'}
                  >
                    {isFullDescriptionShown ? 'Show less' : 'Learn more'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Section>
        <Section fullwidth spaceless additionalClass={'fpdc__stages-parts'}>
          <div className={'w-container fpdc__stages-inner'}>
            <div className={'fpdc__stages-content'}>
              <Row className={'no-gap anim-fade-in-bottom'}>
                <Col sm={12} md={6}>
                  <StageTile
                    icon={'prototype'}
                    title={'Product Prototyping'}
                    description={
                      'A prototype allows you to validate the basic assumptions of a product with minimum cost and effort.'
                    }
                    team={'Prototyping Team'}
                    teamDescription={
                      'This development team analyzes your concept and business goals, helps you to ideate the product, designs and develops prototypes, and gets early feedback to prepare for an MVP. They use a variety of methods such as design thinking and design sprints.'
                    }
                    spaced
                  />
                </Col>
                <Col sm={12} md={6}>
                  <StageTile
                    icon={'mvp'}
                    title={'Minimum Viable Product (MVP) Development'}
                    description={
                      'A minimum viable product (MVP) is the first version of your product, presented early to customers to help you test the product with real users and get valuable feedback for further improvements or pivots.'
                    }
                    team={'MVP Team'}
                    teamDescription={
                      'This development team helps you choose core features for the first version of your product, designs the product and develops the software for the MVP. The MVP will also help you decide what to test and how to validate your business idea.'
                    }
                    spaced
                  />
                </Col>
              </Row>
            </div>
            <div className={'fpdc__stages-content'}>
              <Row className={'no-gap'}>
                <Col sm={12} md={12} className={'fpdc__chart-container'}>
                  <div className={'fpdc__chart'}>
                    <SVG src={chart} alt={'Lean Startup Stages'} />
                  </div>
                </Col>
              </Row>
            </div>
            <div className={'fpdc__stages-content'}>
              <Row className={'no-gap anim-fade-in-bottom'}>
                <Col sm={12} md={6}>
                  <StageTile
                    icon={'fit'}
                    title={'Product-Market Fit'}
                    description={
                      'At this stage you test, analyze and iterate the product in cycles to achieve a perfect fit for the market.'
                    }
                    team={'Product-Market Fit Team'}
                    teamDescription={
                      'This development team designs and carries out product tests, analyzes the results and draws conclusions that will serve as a basis for subsequent iterations. With designers and web developers on board, they also build new product features and test them with users.'
                    }
                  />
                </Col>
                <Col sm={12} md={6}>
                  <StageTile
                    icon={'scale'}
                    title={'Product Scaling'}
                    description={
                      'After validating the product hypothesis and finding the product-market fit, it is time to scale up and build a product that can grow with your users.'
                    }
                    team={'Scaling & Maturity Team'}
                    teamDescription={
                      'This development team will lay out a plan for refactoring and build a scalable and secure system. They will also help you to set up processes in your company that will allow your business and product to grow sustainably in the future.'
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default Stages;
