import React, { useRef, useState } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';
import './TextInteractionAnimation.scss';
import { getRandomInt } from 'utils';

const TextInteractionAnimation = ({ text }) => {
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);
  const animationElementRef = useRef();

  const handleOnClick = () => {
    if (isAnimationInProgress === false) {
      const startPosition = getRandomInt(30, 70);
      const endPosition =
        startPosition > 50 ? getRandomInt(-30, 10) : getRandomInt(10, 30);

      animationElementRef.current.style.setProperty(
        '--animation-element__vertical-start-position',
        `${startPosition}vh`,
      );
      animationElementRef.current.style.setProperty(
        '--animation-element__vertical-end-position',
        `${endPosition}vh`,
      );
      setIsAnimationInProgress(true);
    }
  };

  return (
    <>
      <button className={'f2f-underlined'} onClick={handleOnClick}>
        {text}
      </button>
      <img
        ref={animationElementRef}
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-product-design-development-company-satellite.png',
          'f_auto,q_auto,w_134',
        )}
        alt={''}
        className={classnames('animation-element', {
          'animation-element--in-progress': isAnimationInProgress,
        })}
        onAnimationEnd={() => setIsAnimationInProgress(false)}
        width={134}
        height={164}
        loading={'lazy'}
      />
    </>
  );
};

TextInteractionAnimation.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextInteractionAnimation;
