import React from 'react';
import { graphql } from 'gatsby';
import MachineLearning from 'views/machineLearning';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['machine-learning'];
const { navColors } = page;
const url = `${config.siteUrl}/services/machine-learning/`;
const canonical = `${url}`;

const MLPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <MachineLearning allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query MLQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Speeding up e-commerce content moderation with Machine Learning based on Amazon Web Services"
              "How to create predictive maintenance software for wind turbines using machine learning algorithms"
              "Building digital products based on machine learning - the cost perspective"
              "Machine learning - obstacles and limitations"
              "How machine learning is boosting businesses"
              "How to start a neural network with JavaScript in 5 minutes"
              "What exactly can you do with Python?"
              "Predictive maintenance for wind turbines - an interview with Boldare’s machine learning engineers"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default MLPage;
