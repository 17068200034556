export const numbersConfiguration = [
  {
    number: '8',
    description: `years of experience in the Kingdom of Saudi Arabia`,
  },
  {
    number: '20+',
    description: `clients from Middle Eastern countries`,
  },
  {
    number: '20',
    description: `years of global experience`,
  },
  {
    number: '25',
    description: `design awards and still counting`,
  },
  {
    number: '4.8/5',
    description: `average Clutch rating`,
  },
];
