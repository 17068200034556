import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import { BoxArticle, Link, NavBar, Section, SectionHeader } from 'components';
import config from '../../meta/config';

const CategoryPage = ({ data, pageContext }) => {
  const { markdownRemark, posts, categories } = data;
  const {
    title,
    description,
    slug,
    metaDescription,
  } = markdownRemark.frontmatter;
  const page = pages.category;
  const canonical = `${config.siteUrl}/categories/${slug}/`;

  return (
    <Layout page={page} pageContext={pageContext} canonical={canonical}>
      <Helmet title={`${title} - Blog Posts & Articles & Tips`}>
        <meta name={'description'} content={metaDescription} />
      </Helmet>
      <NavBar color={page.navColors} />
      <div className={'blog_container blog__category'}>
        <div
          id={'blog-post-list'}
          className={'section section--transparent post-list blog-post-list'}
        >
          <Section spaceless>
            <SectionHeader
              additionalClass={
                'section-header__description--medium category-page__hero margin-bottom--0'
              }
              description={description}
              lineColor={'yellow'}
              size={'extra-large'}
              fixed
            >
              {title}
            </SectionHeader>
          </Section>
          <div className={'w-container'}>
            <div className={'post-list__grid grid--no-ads'}>
              {posts.edges &&
                posts.edges.map(post => (
                  <BoxArticle
                    key={`blog-post-${post.node.frontmatter.title}`}
                    post={post}
                  />
                ))}
            </div>
          </div>
          <Section spaceless>
            <SectionHeader
              additionalClass={
                'section-header__description--medium work__hero margin-bottom--0'
              }
              description={'Check out more interesting topics'}
              lineColor={'yellow'}
              size={'extra-large'}
              fixed
            >
              Other content
            </SectionHeader>
            <div>
              {categories.edges &&
                categories.edges.map(category => {
                  const cF = category.node.frontmatter;
                  const categoryTitle = cF.title;
                  const categorySlug = category.node.fields.slug;
                  return categoryTitle !== title ? (
                    <Link
                      to={`${categorySlug}`}
                      additionalClass={'link--with-border'}
                    >
                      {categoryTitle}
                    </Link>
                  ) : (
                    ''
                  );
                })}
            </div>
          </Section>
        </div>
      </div>
    </Layout>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    posts: PropTypes.object,
    categories: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default CategoryPage;

export const pageQuery = graphql`
  query categoryById($id: String!, $categoryTitle: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        isBlogCategory
        title
        description
        metaDescription
        slug
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: {
            in: ["article-page", "case-study-page", "youtube-video"]
          }
          settings: { blogCategories: { eq: $categoryTitle } }
        }
      }
    ) {
      ...markdownFields
    }

    categories: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { in: ["category-page"] }
          isBlogCategory: { eq: true }
        }
      }
    ) {
      ...markdownFields
    }
  }
`;
