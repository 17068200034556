import React from 'react';
import { Heading, Paragraph, Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Image from 'components/Image';
import { Link } from 'gatsby';
import './Projects.scss';

const Projects = () => {
  const projects = [
    {
      image: getCloudinaryUrl(
        '/img/sonnen_case_study_by_Boldare.png',
        'f_auto,q_auto',
      ),
      badge: 'Case study',
      title:
        'Digital transformation for sonnen - a renewable energy service provider',
      description:
        'sonnen is a renewable energy service provider with its headquarters in Wildpoldsried, Germany. Thanks to sonnen solutions, its customers can produce, store and share their own electricity. The balanced energy exchange between households connected to the sonnen network allows its customers to be 100% independent from traditional suppliers.',
      url: '/work/case-study-sonnen-digital-transformation/',
    },
    {
      image: getCloudinaryUrl(
        '/homepage/main-banner/sonnen-banner.jpg',
        'f_auto,q_auto',
      ),
      badge: 'Case study',
      title: 'sonnen customer portal – UX, UI & software development',
      description:
        'sonnen’s digital transformation is one of the most interesting cases in Boldare portfolio. What exactly does it mean? A complex process of reshaping an organization’s services, products, and culture. It’s not about digitalization at all! So… what steps did we take to tailor solutions to sonnen’s needs?',
      url: '/work/case-study-sonnen/',
    },
    {
      image: getCloudinaryUrl(
        '/img/Sonnen_-_case_study_of_digital_transformation_for_a_green_energy_industry_leader_from_Germany.jpg',
        'f_auto,q_auto',
      ),
      badge: 'Case study',
      title: 'sonnenCharger App – app development',
      description:
        "The app allows sonnen's customers to charge their car with green energy. We started with the creation of a prototype, testing with potential users during an industry-related event. The MVP was then delivered within 3 months.",
      url:
        'https://www.behance.net/gallery/116380521/Sonnen-Charger?locale=en_US',
    },
  ];

  const SingleProject = ({ project }) => (
    <li key={project.title} className={'projects__item'}>
      <Link to={project.url} className={'projects__item-url'}>
        <Image
          src={project.image}
          alt={project.title}
          additionalClass={'projects__item-image'}
        />
        <span className={'projects__item-badge text--bold'}>
          {project.badge}
        </span>
        <Heading
          type={3}
          size={'medium'}
          additionalClass={'projects__item-title'}
        >
          {project.title}
        </Heading>
        <Paragraph additionalClass={'projects__item-description text--light'}>
          {project.description}
        </Paragraph>
      </Link>
    </li>
  );

  return (
    <Section>
      <SectionHeader
        additionalClass={'projects__heading'}
        color={'mine-shaft'}
        headingType={2}
        size={'large'}
        centered
        noLine
      >
        See how we have <span className={'headline--marking'}>helped</span> our
        clients in the renewable energy industry
      </SectionHeader>
      <ul className={'projects__list'}>
        {projects.map(project => (
          <SingleProject project={project} />
        ))}
      </ul>
    </Section>
  );
};

export default Projects;
