import App from './App';
import Challenge from './Challenge';
import Comparison from './Comparison';
import Contact from './Contact';
import Design from './Design';
import Intro from './Intro';
import Models from './Models';
import Technology from './Technology';
import Team from './Team';
import Tool from './Tool';
import Users from './Users';

export {
  App,
  Challenge,
  Comparison,
  Contact,
  Design,
  Intro,
  Models,
  Team,
  Technology,
  Tool,
  Users,
};
