import React from 'react';
import { ArrowList, Section, SectionHeader } from 'components';

import './UniqueApproach.scss';

function UniqueApproach() {
  return (
    <Section additionalClass={'rescue-failing-project__unique-approach'}>
      <SectionHeader
        headingType={2}
        size={'large'}
        noLine
        additionalClass={'rescue-failing-project__unique-approach__header'}
        margin={'rescue-failing-project__unique-approach__header'}
      >
        Our approach to deliver results on time
      </SectionHeader>
      <div className={'rescue-failing-project__unique-approach__wrapper'}>
        <div>
          <p>
            Our process begins with a kickoff workshop, where we collaborate
            closely with you to understand your project requirements and goals.
            We then employ hypothesis cards to drive focused discussions and
            identify the most critical aspects of your project.
          </p>
          <p>
            Our secret weapon is rapid prototyping. We break your project down
            into very short-term goals, even increments as short as a few
            hours. This agile approach allows us to test and iterate quickly,
            ensuring that we deliver under pressure and meet your accelerated
            timeline. If needed, we leverage AI solutions to enhance
            development speed and efficiency, pushing the boundaries of
            what&apos;s possible.
          </p>
        </div>
        <ArrowList
          list={[
            'Kick-off workshop',
            'Hypothesis cards',
            'Rapid prototyping',
            'AI powered software development',
          ]}
        />
      </div>
    </Section>
  );
}

export default UniqueApproach;
