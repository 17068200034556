import React from 'react';
import { Heading, Paragraph, Link, Button } from 'components';
import './BlogWebinarNewNormal.scss';

const BlogWebinarNewNormal = ({ id }) => (
  <div className={'box box-article box-nn-webinar'} id={id}>
    <Link to={'/webinar/accelerate-digitization-in-your-company/'}>
      <div className={'blog-nn-webinar'}>
        <div className={'nn-webinar-details'}>
          <Paragraph size={'medium'} additionalClass={'text--bold'}>
            Recorded Webinar
          </Paragraph>
          <Heading type={3} size={'big'} color={'black'}>
            <span>How to accelerate digitization</span>
            <span>of products & services</span>
            <span>in your company?</span>
          </Heading>
          <Heading type={4} size={'medium'} color={'black'}>
            A case study Sonnen & Boldare
          </Heading>
          <span className={'nn-webinar-details-span'} />
          <Button color={'yellow'}>Get access &#8594;</Button>
        </div>
      </div>
    </Link>
  </div>
);

export default BlogWebinarNewNormal;
