import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Link, Paragraph } from '..';
import './RelatedUniversalBox.scss';

const RelatedUniversalBox = ({ title, url, image, type }) => (
  <>
    <div
      className={'related-universal-widget related-universal-widget--spaced'}
    >
      <Paragraph
        size={'small'}
        additionalClass={'related-universal-widget__header text--bold'}
      >
        {`You might be also interested in the ${type}:`}
      </Paragraph>
      <Link additionalClass={'related-universal-widget__link'} to={url}>
        <div className={'box__wrapper--carrara related-universal'}>
          <div className={'related-article__left'}>
            {image && (
              <img
                src={getCloudinaryUrl(`${image}`, 'f_auto,q_auto,h_300')}
                alt={title}
                className={'image related-article__image'}
                loading={'lazy'}
              />
            )}
          </div>
          <div className={'related-article__right'}>
            <Paragraph
              size={'small'}
              color={'red'}
              additionalClass={'related-article__category text--bold'}
            >
              {type}
            </Paragraph>
            <Heading
              type={3}
              size={'medium'}
              additionalClass={'related-article__title no-toc'}
            >
              {title}
            </Heading>
          </div>
        </div>
      </Link>
    </div>
  </>
);

RelatedUniversalBox.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RelatedUniversalBox;
