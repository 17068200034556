import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { NavBar, PopupRedirect } from 'components';
import SingleCaseStudy from '../views/singleCaseStudy';
import Layout from '../components/layout';
import { pages } from '../config/pages';

const CaseStudyPage = ({ data }) => {
  const {
    markdownRemark: caseStudyData,
    allMarkdownRemark: articlesList,
  } = data;
  const page = pages.default;
  const { navColors } = page;

  const caseStudyList = articlesList.edges.filter(
    edge => edge.node.frontmatter.templateKey === 'case-study-page',
  );

  return (
    <Layout page={page}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <SingleCaseStudy
        caseStudy={caseStudyData}
        articlesList={articlesList}
        caseStudyList={caseStudyList}
      />
    </Layout>
  );
};

CaseStudyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }).isRequired,
};

export default CaseStudyPage;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        settings {
          date
          metaDescription
          metaTitle
          slug
          slugType
          canonical
        }
        title
        mainVideo
        cover
        lead
        mainColor
        mainContent {
          workshopImage
          workshopImageAlt
          problem
          client
          results
          resultsSecond
          solutionFirst
          solutionSecond
          solutionThird
          solutionFinalImage
          sideImage
          review {
            testimonial
            author
            image
            alt
            coverImage
          }
          statistics {
            alt
            caption
            counter
            image
          }
          productImages {
            image {
              src
              alt
            }
          }
          processImages {
            image {
              src
              alt
            }
          }
          related {
            title
          }
        }
        intro {
          caption
          cost
          deliverables
          description
          timespan
          name
          logo
          product
          review {
            testimonial
            author
          }
          media {
            headline
            image
            testimonial
            author
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cover
            settings {
              slug
              slugType
              type
              category
            }
            author
            box {
              content {
                title
                tileDescription
                tags
                coverImage
              }
              settings {
                tileColor
                textColor
                link
              }
              type
            }
          }
        }
      }
    }
  }
`;
