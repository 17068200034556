import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader } from 'components';
import './Banner.scss';

const SpyLink = Scroll.Link;

const Banner = ({ cta, link, id }) => (
  <SubpageBanner
    backgroundColor={'pigeon-post'}
    image={getCloudinaryUrl(
      'services/software-development-outsourcing/scrum-team.jpg',
      'f_auto,q_auto',
    )}
    contentBackground={'spring-wood'}
    additionalClass={'sdo__banner'}
    alt={'Development team'}
  >
    <SectionHeader
      size={'extra-large'}
      color={'mine-shaft'}
      lineColor={'red'}
      description={
        'Boldare has been established to help your company solve business problems through software development. However, we believe that there is no quality software without an exceptional team behind it.<br /><br />This is why our dedicated teams consist of highly experienced developers ready to focus on your project and provide top-notch development to realize your business vision.'
      }
      descriptionColor={'black'}
      buttonColor={'black'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Software Development Outsourcing
    </SectionHeader>
    <SpyLink
      id={id}
      to={link}
      spy={false}
      smooth
      duration={500}
      className={'link'}
    >
      <span
        className={'button button--primary button--pigeon-post'}
        id={'sdo__contact-top'}
      >
        Hire our team
      </span>
    </SpyLink>
  </SubpageBanner>
);

export default Banner;
