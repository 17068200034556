import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Container, Checkbox } from 'components';
import { classNames } from 'utils';
import { useWindowWidth } from 'hooks';
import { navigate } from 'gatsby';
import { pardotLink } from 'config/constants/pardotFormHandlers';
import ClientsSlideshow from '../ClientsSlideshow/ClientsSlideshow';

import './SendResultSection.scss';

const SendResultSection = ({ result, contact }) => {
  const [form, setForm] = useState({
    name: contact.name,
    email: contact.email,
    message: result,
    wantToSignNDA: false,
    wantToDiscoverAI: false,
  });

  const [isCheckedNDA, setIsCheckedNDA] = useState(false);
  const [isCheckedAI, setIsCheckedAI] = useState(false);

  const isMinimumDesktopSmall = useWindowWidth() >= 1024;

  const handleChange = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCheckboxNDA = () => {
    setIsCheckedNDA(prevChecked => !prevChecked);
    setForm(prevForm => ({
      ...prevForm,
      wantToSignNDA: !prevForm.wantToSignNDA,
    }));
  };

  const handleCheckboxAI = () => {
    setIsCheckedAI(prevChecked => !prevChecked);
    setForm(prevForm => ({
      ...prevForm,
      wantToDiscoverAI: !prevForm.wantToDiscoverAI,
    }));
  };

  const handleSubmitForm = e => {
    e.preventDefault();

    const body = new URLSearchParams(form).toString();
    fetch(pardotLink.appCostCalculatorSendResult, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        navigate('/resources/app-costs-calculator-thank-you');
      })
      .catch(error => console.log(error));
  };

  return (
    <div className={'app-cost-send-result'}>
      <Container>
        <div className={'app-cost-send-result__text-content'}>
          <div className={'app-cost-send-result__heading'}>
            Get a detailed estimate
          </div>
          <div className={'app-cost-send-result__description'}>
            Send us a message and we will assist you in defining business goals
            and scope to make sure you stay within your budget and time frame.
          </div>
        </div>
        <div className={'app-cost-send-result__form-container'}>
          <form
            className={'app-cost-send-result__form'}
            method={'post'}
            onSubmit={handleSubmitForm}
          >
            <div className={'app-cost-send-result__input-container'}>
              <input
                className={'app-cost-send-result__input'}
                type={'text'}
                name={'name'}
                id={'result-form-name'}
                onChange={handleChange}
                value={form.name}
                required
              />
              <label
                className={'app-cost-send-result__label'}
                htmlFor={'result-form-name'}
              >
                First name
              </label>
            </div>
            <div className={'app-cost-send-result__input-container'}>
              <input
                type={'email'}
                name={'email'}
                id={'result-form-email'}
                className={classNames('app-cost-send-result__input', {
                  'input--filled': form.email,
                })}
                onChange={handleChange}
                value={form.email}
                required
              />
              <label
                className={'app-cost-send-result__label'}
                htmlFor={'result-form-email'}
              >
                Company e-mail
              </label>
            </div>
            <div className={'app-cost-send-result__textarea-container'}>
              <textarea
                name={'message'}
                id={'result-form-textarea'}
                className={'app-cost-send-result__textarea'}
                onChange={handleChange}
                required
              >
                {'Your project description \n\n'}
                {result.map(answer => `${answer}\n`)}
                {'\nHere you can add more information about your product:\n'}
              </textarea>
              <label
                className={'app-cost-send-result__label'}
                htmlFor={'result-form-textarea'}
              >
                Project description
              </label>
            </div>
            <div className={'app-cost-send-result__checkbox-container'}>
              <Checkbox
                checked={isCheckedNDA}
                onChange={handleCheckboxNDA}
                name={'wantToSignNDA'}
                value={'wantToSignNDA'}
                label={'I want to sign an NDA.'}
                additionalClass={'app-cost-send-result__checkbox'}
              />
              <Checkbox
                checked={isCheckedAI}
                onChange={handleCheckboxAI}
                name={'wantToDiscoverAI'}
                value={'wantToDiscoverAI'}
                label={
                  'I want to discuss my idea with an AI expert to discover potential benefits.'
                }
                additionalClass={'app-cost-send-result__checkbox'}
              />
            </div>
            <p className={'app-cost-send-result__footnote'}>
              Your data is safe with us. We take your privacy seriously and we
              will never share your data with anyone. Read our{' '}
              <a
                href={'/privacy-policy/'}
                className={'app-cost-send-result__footnote-link'}
              >
                Privacy Policy
              </a>{' '}
              to learn more.
            </p>

            <Button
              id={'quiz-send'}
              additionalClass={'app-cost-send-result__button'}
              type={'submit'}
              color={'yellow'}
              ariaName={'Send button'}
            >
              Ask for detailed estimate
            </Button>
          </form>
        </div>
        {!isMinimumDesktopSmall && (
          <div className={'app-cost-result-phase__client-slideshow'}>
            <ClientsSlideshow />
          </div>
        )}
      </Container>
    </div>
  );
};

SendResultSection.propTypes = {
  result: PropTypes.arrayOf(PropTypes.string).isRequired,
  contact: PropTypes.object,
};

SendResultSection.defaultProps = {
  contact: {name: '', email: ''},
};

export default SendResultSection;
