import React from 'react';
import RenewableEnergyIndustry from 'views/renewableEnergyIndustry';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['renewable-energy-industry'];
const { navColors } = page;
const url = `${config.siteUrl}/renewable-energy-industry`;
const canonical = `${url}`;

const RenewableEnergyIndustryPage = () => (
  <Layout {...{ page, canonical, url }}>
    <NavBar color={navColors} bannerHidden />
    <RenewableEnergyIndustry />
  </Layout>
);

export default RenewableEnergyIndustryPage;
