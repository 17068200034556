module.exports = {
  // COMPANY
  years_experience: '18',
  products: '300',
  employees: '200',
  clients: '111',
  released_products: '300+',
  technologies: '20+',
  product_strategists: '7',
  women_leaders: '50%',
  offices: '6',

  // NUMBERS
  awwwards: '16',
  awwwards_mobile: '2',
  clutch_rate: '4.8',
  clutch_reviews: '53',
  indigo: '2',
};
