import React from 'react';
import { useInView } from 'react-intersection-observer';
import { HeroSlider } from 'components';

import { opinions } from 'data/clientsOptions';
import QuoteMark from 'assets/images/home/hero/quote.svg';

import './Slider.scss';

function Slider() {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section
      className={'recommend-client__slider w-container'}
      ref={ref}
      id={'slider'}
    >
      <div className={'recommend-client__slider__wrapper'}>
        <img
          src={QuoteMark}
          className={'recommend-client__slider__quote-mark'}
          alt={'quotation mark'}
        />
        <HeroSlider
          inView={inView}
          key={inView}
          slides={opinions}
          options={{ withPhoto: true }}
        />
      </div>
    </section>
  );
}

export default Slider;
