import Banner from 'views/contactNl/subcomponents/Banner';
import Cities from 'views/contactNl/subcomponents/Cities';
import ContactFormNl from 'views/contactNl/subcomponents/ContactFormNl';
import SuperHeroes from 'views/contactNl/subcomponents/SuperHeroes';
import WhoAreWe from 'views/contactNl/subcomponents/WhoAreWe';
import ContactNlAwards from 'views/contactNl/subcomponents/ContactNlAwards';

export {
  Banner,
  Cities,
  ContactFormNl,
  SuperHeroes,
  WhoAreWe,
  ContactNlAwards,
};
