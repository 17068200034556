import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import '../FormControl.scss';
import './Dropdown.scss';

const Dropdown = ({
  additionalClass,
  values,
  name,
  onChange,
  value: activeValue,
}) => {
  const [open, setOpen] = useState(false);
  const activeLabel = activeValue
    ? values.find(v => v.value === activeValue).label
    : '';

  return (
    <div
      className={classNames('dropdown', additionalClass, {
        'dropdown--open': open,
      })}
    >
      <div>
        <button
          className={'form-control__input dropdown__option dropdown__toggle'}
          onClick={() => setOpen(!open)}
          type={'button'}
        >
          &nbsp;
        </button>
        <span className={'form-control__label'}>{activeLabel || 'City'}</span>
        <ul
          className={classNames('dropdown__options')}
          id={'custom-select-list'}
        >
          {values.map(({ value, label }) => (
            <li
              key={`dropdown-${name}-${value}`}
              className={classNames('dropdown__option', {
                'dropdown__option--active': value === activeValue,
              })}
              onClick={() => {
                onChange(value);
                setOpen(false);
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
};

Dropdown.defaultProps = {
  additionalClass: '',
  value: null,
  values: [],
};

export default React.memo(Dropdown);
