import React, { useEffect } from 'react';
import Glide from '@glidejs/glide';
import { Col, Row } from 'react-flexbox-grid';
import { AudioPlayer, IconSVG, Paragraph, Heading } from 'components';
import { slidersInformation } from 'views/digitalTransformationJourney/subcomponents/Banner/Carousel/utils';
import { getCloudinaryUrl } from 'config/helpers';

import './Carousel.scss';

const getSliders = () =>
  slidersInformation.map(
    ({ title, description, person, companyIcon, recording }) => {
      return (
        <div>
          <Heading
            size={'large'}
            color={'white'}
            descriptionColor={'black'}
            lineColor={'white'}
            headingType={'2'}
          >
            Quality backed by our partners
          </Heading>
          <li
            className={`
      digital-tran_journey-carousel__slide glide__slide
      `}
          >
            <Row
              center={'xs'}
              className={'digital-tran_journey-carousel-slide__wrapper'}
            >
              <Col xs={8} sm={8} xl={10}>
                <Row>
                  <Col xs={12}>
                    <Paragraph
                      color={'white'}
                      size={'big '}
                      additionalClass={
                        'digital-tran_journey-carousel-slide__quotation-mark'
                      }
                      spaced
                    >
                      &bdquo;
                    </Paragraph>
                    <Paragraph
                      color={'white'}
                      size={'big'}
                      additionalClass={
                        '' +
                        `digital-tran_journey-carousel--weight-bold
                     digital-tran_journey-carousel-slide__title
                `
                      }
                      spaced
                    >
                      {title}
                    </Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Paragraph
                      color={'white'}
                      additionalClass={
                        'digital-tran_journey-carousel-slide__description'
                      }
                      size={'medium'}
                    >
                      {description}
                    </Paragraph>
                  </Col>
                </Row>
                <Row top={'xs'}>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className={`digital-tran_journey-carousel-slide__person-image-container-col`}
                  >
                    {person.image && (
                      <div
                        className={
                          'image digital-tran_journey-carousel-slide__person-image-container'
                        }
                      >
                        <img
                          src={person.image}
                          className={
                            'image digital-tran_journey-carousel-slide__person-image'
                          }
                          alt={''}
                          loading={'lazy'}
                        />
                      </div>
                    )}
                    <div>
                      <Paragraph
                        color={'white'}
                        size={'small'}
                        additionalClass={`
                      digital-tran_journey-carousel-slider__personal-name
                    `}
                      >
                        {person.name}
                      </Paragraph>
                      <Paragraph
                        color={'white'}
                        additionalClass={''}
                        size={'small'}
                      >
                        {person.position}
                      </Paragraph>
                      <Paragraph
                        color={'white'}
                        additionalClass={''}
                        size={'small'}
                      >
                        {person.company}
                      </Paragraph>
                      <Paragraph
                        color={'white'}
                        additionalClass={''}
                        size={'small'}
                      >
                        {person.country}
                      </Paragraph>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} lg={4}>
                    <img
                      src={companyIcon}
                      className={'digital-tran_journey-carousel-slide__image'}
                      alt={''}
                      loading={'lazy'}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={4}>
                    <Paragraph
                      color={'white'}
                      additionalClass={
                        'digital-tran_journey-carousel-slide__recording-description'
                      }
                      size={'extra-small'}
                    >
                      {recording?.description}
                    </Paragraph>
                    {recording && (
                      <AudioPlayer
                        className={
                          'digital-tran_journey-carousel-slide__recording-player'
                        }
                        src={getCloudinaryUrl(
                          'digital-transformation-journey/Fadi_track_x.mp3',
                          'f_auto,q_auto,w_auto',
                        )}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </li>
        </div>
      );
    },
  );

export const Carousel = () => {
  useEffect(() => {
    const glideObject = new Glide('.digital-tran_journey-carouse', {
      type: 'carousel',
      animationDuration: 2000,
      focusAt: '1',
      autoplay: 0,
      startAt: 1,
      perView: 1,
    });

    if (document.querySelector('.digital-tran_journey-carouse')) {
      glideObject.mount();
    }
  }, []);

  return (
    <div className={`digital-tran_journey-carousel_container`}>
      <div className={'glide digital-tran_journey-carouse'}>
        <div className={'glide__track'} data-glide-el={'track'}>
          <ul className={'glide__slides'}>{getSliders()}</ul>
        </div>
        <div
          className={`
            glide__arrows
          `}
          data-glide-el={'controls'}
        >
          <button
            className={'glide__arrow glide__arrow--prev'}
            data-glide-dir={'<'}
          >
            <IconSVG name={'arrow-left'} size={'big'} color={'white'} />
          </button>
          <button
            className={'glide__arrow glide__arrow--next'}
            data-glide-dir={'>'}
          >
            <IconSVG name={'arrow-right'} size={'big'} color={'white'} />
          </button>
        </div>
      </div>
    </div>
  );
};
