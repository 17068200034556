import React, { Component } from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import CountUp from 'react-countup';
import inView from 'in-view';
import { data } from './logic.js';
import './Users.scss';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.showbiz__users').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  renderSteps = () =>
    data.map((item, index) => (
      <div className={'showbiz__users-step anim-fade-in-bottom'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            `assets/showbiz/${item.image}`,
            'f_auto,q_auto',
          )}
          alt={item.title}
        />
        <div className={'showbiz__users-copy'}>
          <Heading type={3} size={'medium-constant'} color={'mine-shaft'}>
            <span>{index + 1}&nbsp;</span>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
      </div>
    ));

  withCounter = () =>
    this.state.enableCounterAnimation && (
      <CountUp end={29834} delay={0.3} separator={' '} prefix={'+'} />
    );

  render() {
    return (
      <Section additionalClass={'showbiz__users'} spaceless>
        <div className={'showbiz__users-content'}>
          <Heading type={2} size={'big-spreaded'} color={'mine-shaft'}>
            {this.withCounter()} new users
          </Heading>
          <div className={'showbiz__users-container'}>
            <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
              From the user perspective
            </Heading>
            <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
              Become a model in a second!
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              New US &amp; design of the application and information pages. The
              most important thing was to build a user control panel, so that
              the registration and updates of data were simple.
            </Paragraph>
          </div>
          <div className={'showbiz__users-steps'}>{this.renderSteps()}</div>
        </div>
      </Section>
    );
  }
}

export default Users;
