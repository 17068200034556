export const data = [
  {
    intro: 'Nearly',
    value: '40%',
    description:
      '40% of C-level executives claim that digital transformation and technological change will have the greatest impact on the decision-making process in their company.',
    source: '(source: Harvard Business Review Analytics Services Report)',
  },
  {
    intro: '',
    value: '4/10',
    description:
      'believe that their company is at risk from other businesses that are ahead with digital transformation',
    source: '(source: Harvard Business Review Analytics Services Report)',
  },
  {
    intro: 'Close to',
    value: '50%',
    description:
      'claim that they will fall behind their competitors if they lag with digital transformation',
    source: '(source: Harvard Business Review Analytics Services Report)',
  },
];
