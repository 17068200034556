import React from 'react';
import Slider from 'react-slick';

import { Header, Section } from '../../../../components/DS';
import { whoWeAre } from '../../utils'
import { CustomerTile } from './CustomerTile';

import './Reviews.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'vertical'}
    theme={'light'}
  />
);

const { reviews: { title, description, customer }} = whoWeAre;

export const Reviews = () => {

  const sliderSettings = {
    className: 'event-history__slider',
    dotsClass: 'slick-dots slick-pagination',
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 6000,
    infinite: true,
    draggable: true,
    slidesToShow: 2.75,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <Section
      heading={<SectionHeader title={title} description={description} />}
      color={'violet-main'}
      noOverflow
    >
      <Slider {...sliderSettings}>
        {
          customer.map(element => (<CustomerTile customer={element} />))
        }
      </Slider>
    </Section>
)}
