import React from 'react';
import { isEmpty } from 'lodash';
import { Section, RelatedArticles } from 'components';
import { Intro } from '..';
import './ReviewContent.scss';

const ReviewContent = ({ data, articlesList }) => {
  const { mainContent } = data;
  const { related } = mainContent;

  return (
    <div className={'review-content'}>
      <Intro data={data || ''} />
      {!(isEmpty(articlesList) || isEmpty(related)) && (
        <RelatedArticles
          id={'review-related'}
          related={related}
          allArticles={articlesList}
        />
      )}
    </div>
  );
};

export default ReviewContent;
