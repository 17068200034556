import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import {
  Section,
  SectionHeader,
  Heading,
  Image,
  Paragraph,
  Button,
} from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import './Banner.scss';

const SpyLink = Scroll.Link;

class Banner extends Component {
  renderSpeakerList = data => {
    return (
      <ul className={'speakers-component__speaker-list'}>
        {data.speakers.map(speaker => (
          <li className={'speakers-component__speaker-list-item'}>
            <div className={'speakers-component__speaker-list-img'}>
              <img
                className={'image'}
                alt={speaker.name}
                src={getCloudinaryUrl(speaker.avatar, 'f_auto,q_auto')}
                loading={'lazy'}
              />
            </div>
            <div className={'speakers-component__speaker-list-content'}>
              <span className={'speakers-component__speaker-list-position'}>
                {speaker.position}
              </span>
              <span className={'speakers-component__speaker-list-name'}>
                {speaker.name}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <div className={'webinar-page__hero'}>
        <Section
          additionalClass={classNames('webinar-page__banner', {
            'webinar-page__banner--dark': data.theme === 'dark',
          })}
          color={data.theme === 'dark' ? 'mine-shaft' : 'pigeon-post'}
        >
          <span className={'webinar-page__banner-badge'}>{data.type}</span>
          <SectionHeader
            color={'white'}
            fixed
            noLine
            headingType={1}
            additionalClass={'section-header__description--medium'}
            lineColor={data.lineColor || false}
          >
            {data.title.map(titleContent => (
              <>
                <span>{titleContent}</span>
              </>
            ))}
          </SectionHeader>
          {data.subtitle && (
            <Heading
              type={3}
              size={'medium-plus-small'}
              additionalClass={'webinar-page__banner-subtitle'}
            >
              {data.subtitle}
            </Heading>
          )}
          <Paragraph
            size={'medium'}
            additionalClass={'webinar-page__banner-paragraph'}
          >
            {data.description[0]}
          </Paragraph>
          <Paragraph
            size={'medium'}
            additionalClass={'webinar-page__banner-paragraph'}
          >
            {data.description[1]}
          </Paragraph>
          <Paragraph
            size={'medium'}
            additionalClass={
              'webinar-page__banner-paragraph webinar-page__banner-paragraph--last'
            }
          >
            {data.description[2]}
          </Paragraph>
          <div className={'webinar-page__buttons'}>
            <SpyLink
              id={'webinar-page-video-link'}
              to={'section-1'}
              spy={false}
              smooth
              duration={500}
              className={'link'}
            >
              <Button
                id={'webinar-page-watch-button'}
                color={'yellow'}
                ariaName={'Watch webinar button'}
              >
                Watch webinar
              </Button>
            </SpyLink>
          </div>
          <div className={'webinar-page__banner-image'}>
            <img
              src={getCloudinaryUrl(
                data.img,
                `q_auto,f_auto,w_${data.imgWidth || 600}`,
              )}
              alt={data.type}
              loading={'lazy'}
            />
          </div>
        </Section>
        {data.speakers && (
          <Section
            additionalClass={'webinar-page__speakers'}
            color={'carrara'}
            spaceless
          >
            <Heading
              type={3}
              size={'medium-plus-small'}
              additionalClass={'webinar-page__speakers-heading'}
            >
              Speakers
            </Heading>
            {this.renderSpeakerList(data)}
          </Section>
        )}
      </div>
    );
  }
}

export default Banner;
