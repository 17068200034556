import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { Caption, Cta, Quote, Photo } from '..';
import { SliderContext } from '../../utils';

import './Slide.scss';

const Slide = ({ index }) => {
  const { options } = useContext(SliderContext);

  return (
    <figure className={'hero-slide'}>
      <Quote index={index} />
      {options.withPhoto && (
        <motion.div
          key={`photo-${index}`}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <Photo index={index} />
        </motion.div>
      )}
      <motion.div
        key={`caption-${index}`}
        className={'hero-slide__container'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <Caption index={index} />
        <Cta index={index} />
      </motion.div>
    </figure>
  );
};

Slide.defaultProps = {
  index: 0,
  variant: 'hero-a',
};

Slide.propTypes = {
  index: PropTypes.number,
  variant: PropTypes.string,
};

export default Slide;
