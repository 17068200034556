import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { Section } from 'components';
import EventContent from './EventContent';
import './Event.scss';

class Event extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-event-page event'}>
        <Section additionalClass={'event-section--first'} spaceless>
          <EventContent {...this.props} />
        </Section>
      </div>
    );
  }
}

export default Event;
