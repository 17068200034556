import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Button, Heading, MarkerHighlight, Section } from 'components';

import Author from '../Author';

import './BottomBanner.scss';

function BottomBanner() {
  return (
    <Section
      color={'yellow'}
      spaceless
      containerClass={'book-discovery-session__bottom-banner'}
    >
      <Heading
        size={'large'}
        additionalClass={'book-discovery-session__bottom-banner-header'}
      >
        Book <MarkerHighlight color={'main-violet'}>a free</MarkerHighlight>{' '}
        discovery session with us!
      </Heading>
      <figure className={'book-discovery-session__bottom-banner-person'}>
        <Author.Photo />
        <div className={'book-discovery-session__bottom-banner-details'}>
          <Author.Details />
          <Button
            color={'black'}
            size={'large'}
            id={'book-discovery-session__scroll-to-form'}
            additionalClass={
              'book-discovery-session__bottom-banner-details-button'
            }
            onClick={() => scrollTo('#form')}
          >
            BOOK NOW
          </Button>
        </div>
      </figure>
    </Section>
  );
}

export default BottomBanner;
