import React from 'react';
import { Section, Heading, Paragraph, OldCaseStudyTile } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Intro.scss';

const Intro = () => {
  return (
    <Section additionalClass={'sm__intro-container'}>
      <OldCaseStudyTile
        additionalClass={'spa-monkeys'}
        image={getCloudinaryUrl('logos/spamonkeys-logo.png', 'f_auto,q_auto')}
        cover={getCloudinaryUrl(
          'assets/spa-monkeys/spamonkeys-rwd-bg-width.png',
          'f_auto,q_auto',
        )}
        subtitle={'Several business and services'}
        description={
          'Wireframes, Moodboard, Graphic Design, Frontend Development, App Flow, App Architecture, Implementation, AngularJS, Functional Programming, Shopify'
        }
      />
      <div className={'sm__content'}>
        <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
          What we've done?
        </Heading>
        <Heading type={2} size={'big-spreaded'}>
          We created a complex set of web business tools
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          Spa Monkeys delivers complete solution in spa, beauty and wellness
          industry in Hong Kong. It was achieved with a help of business tools
          such as online shop and places catalogue joint with blog, main
          marketing tool. Our role was to gradually implement those
          comprehensive solutions in a way to guarantee the highest comfort for
          users and the best place for business owners.
        </Paragraph>
      </div>
    </Section>
  );
};

export default Intro;
