import React from 'react';

import { serialize } from 'utils';

export const usePardotForm = (formRef, pardotFormUrl) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [succeeded, setSucceeded] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    const form = formRef.current;

    return fetch(pardotFormUrl, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: serialize(form),
    })
      .then(() => {
        setIsLoading(false);
        setSucceeded(true);
      })
      .catch(error => {
        console.log(error);
        setFailed(true);
      });
  };

  return { handleSubmit, isLoading, succeeded, failed };
};
