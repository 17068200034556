import React from 'react';
import './Coffee.scss';
import { getCloudinaryUrl } from 'config/helpers';

const Coffee = () => {
  return (
    <div className={'workroom-coffee'}>
      <img
        className={'image workroom-coffee__image'}
        src={getCloudinaryUrl('img/WR_Coffee_Icon.svg', 'q_100')}
        alt={'Coffee cup'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-coffee__image'}
        src={getCloudinaryUrl('img/WR_Coffee_Icon.svg', 'q_100')}
        alt={'Coffee cup'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-coffee__image'}
        src={getCloudinaryUrl('img/WR_Coffee_Icon.svg', 'q_100')}
        alt={'Coffee cup'}
        loading={'lazy'}
      />
    </div>
  );
};

export default Coffee;
