export const data = [
  {
    image: 'img/press/GQ-logo.png',
  },
  {
    image: 'img/press/Golem_Karrierewelt-logo.png',
  },
  {
    image: 'img/press/t3n-logo.png',
  },
  {
    image: 'img/press/Business_Insider-logo.png',
  },
  {
    image: 'img/press/Forbes-logo.png',
  },
];
