import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, Paragraph, Button, IconSVG, Heading } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Slider from 'react-slick';
import { classNames } from 'utils';
import { sliderData } from './logic';
import './BoldareTech.scss';

class BoldareTech extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'career-tech-slider',
        dotsClass: 'slick-dots slick-pagination career-tech-slider-dots',
        dots: false,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 500,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  renderSlides = () => {
    const colors = {
      primary: 'mine-shaft',
      secondary: 'white',
      text: 'white',
      line: 'golden-dream',
    };

    return sliderData.map((item, index) => (
      <div
        className={'career-tech-slider__slide-container'}
        key={`culture-slide-${item.id}`}
      >
        <div
          className={classNames('career-tech-slider__slide-wrapper', {
            [`career-tech-slider__slide-wrapper--${item.color}`]: item.color,
          })}
        >
          <div className={'career-tech-slider__image'}>
            <a href={item.link} target={'_blank'} rel={'noreferrer'}>
              <Button
                additionalClass={'career-video__button'}
                color={'white'}
                transparent
                onClick={this.showVideo}
              >
                <span className={'career-video__play'} />
              </Button>
              <img
                className={'career-tech-slider__slide'}
                alt={item.content}
                src={getCloudinaryUrl(
                  [item.background],
                  'f_auto,q_auto,w_710',
                )}
              />
              <div className={'career-video__index'}>
                <span>
                  {index + 1}/{sliderData.length}
                </span>
              </div>
            </a>
            <div className={'career-tech-slider__title'}>
              <Heading type={3}>{item.content}</Heading>
              <span>{item.duration}</span>
            </div>
          </div>
          {sliderData.length > 1 && (
            <div
              className={classNames('career-tech-slider__navigation', {
                [`career-tech-slider__navigation--${colors.secondary}`]: colors.secondary,
              })}
            >
              <div
                id={'career-tech-slider-prev'}
                role={'presentation'}
                onClick={() => this.slider.slickPrev()}
              >
                <IconSVG name={'arrow-left'} color={colors.primary} />
              </div>
              <div
                id={'career-tech-slider-next'}
                role={'presentation'}
                onClick={() => this.slider.slickNext()}
              >
                <IconSVG name={'arrow-right'} color={colors.primary} />
              </div>
            </div>
          )}
        </div>
      </div>
    ));
  };

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section
          id={'career-tech'}
          color={'violet'}
          additionalClass={'career-tech'}
        >
          <img
            className={'career-vec career-vec-1'}
            alt={''}
            src={getCloudinaryUrl('career/vector-1.svg', 'f_auto,q_auto')}
          />
          <img
            className={'career-vec career-vec-rectangle-left'}
            alt={''}
            src={getCloudinaryUrl(
              'career/rectangle-left.svg',
              'f_auto,q_auto',
            )}
          />
          <img
            className={'career-vec career-vec-rectangle-right'}
            alt={''}
            src={getCloudinaryUrl(
              'career/rectangle-right.svg',
              'f_auto,q_auto',
            )}
          />
          <img
            className={'career-vec career-vec-spaceship'}
            alt={''}
            src={getCloudinaryUrl('career/spaceship.svg', 'f_auto,q_auto')}
          />
          <img
            className={'career-vec career-vec-4'}
            alt={''}
            src={getCloudinaryUrl(
              'career/tech-bar-bottom.svg',
              'f_auto,q_auto',
            )}
          />
          <img
            className={'career-vec career-vec-3'}
            alt={''}
            src={getCloudinaryUrl('career/vector-3.svg', 'f_auto,q_auto')}
          />
          <img
            className={'career-vec career-vec-5'}
            alt={''}
            src={getCloudinaryUrl('career/tech-bar-top.svg', 'f_auto,q_auto')}
          />
          <img
            className={'career-vec career-vec-2'}
            alt={''}
            src={getCloudinaryUrl('career/vector2.svg', 'f_auto,q_auto')}
          />
          <div className={'career-tech__main'}>
            <div
              className={'career-tech__column career-tech__column--content'}
            >
              <Heading
                type={4}
                size={'large'}
                color={'white'}
                additionalClass={'career-tech__title'}
                lineColor={'white'}
              >
                Boldare Tech
              </Heading>
              <Heading
                type={2}
                size={'large'}
                color={'white'}
                additionalClass={'career-tech__heading'}
              >
                Technology <br />
                is our domain
              </Heading>
              <Paragraph color={'white'}>
                BoldareTech is a podcast taking you on a journey through the
                topics of technology and digital products. Experts from various
                fields share their professional stories and talk about the
                challenges they had to overcome.
                <br />
                <br />
                Are you into creating digital products? Need some inspiration
                or expert advice? This podcast is for you! And if you wish to
                discover more intriguing videos, visit our YouTube channel.{' '}
              </Paragraph>
              <a
                href={
                  'https://www.youtube.com/playlist?list=PLdvko3YEuQr8w2jxhutOhLWnodXIFbkn2'
                }
                className={'button button--primary button--white'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                More videos
              </a>
            </div>
            <div className={'career-tech__column career-tech__column--slider'}>
              <Slider
                ref={slider => {
                  this.slider = slider;
                }}
                {...sliderSettings}
              >
                {this.renderSlides()}
              </Slider>
            </div>
          </div>
          <div className={'career-tech-grow'}>
            <Heading
              type={2}
              size={'large'}
              color={'white'}
              additionalClass={'career-tech__heading'}
            >
              Grow with us
            </Heading>
            <Paragraph color={'white'}>
              Have you reached the senior level and are slowly touching the
              glass ceiling? Don&apos;t worry, join Boldare! You&apos;ll find
              dedicated career paths that allow self-development for senior
              experts. Discover the roles of tech leads and delivery leads.
              Meet the value of developing strategic thinking, business
              orientation and decision-making.
            </Paragraph>
            <a
              href={'https://www.boldare.com/blog/tech-lead/'}
              className={'button button--primary button--white'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              Learn more about tech leads
            </a>
          </div>
        </Section>
      </>
    );
  }
}

BoldareTech.defaultProps = {
  data: [],
};

BoldareTech.propTypes = {
  data: PropTypes.array,
};

export default BoldareTech;
