export const data = [
  {
    number: '2',
    title: 'website + blog',
    description: "we've built siblings product",
    image: null,
  },
  {
    image: 'planet-escape-device-mobile.png',
  },
  {
    number: '4/30',
    title: 'huge database',
    description: 'unique offers referenced on 4 continents and 30 countries',
    image: null,
  },
  {
    number: '9',
    title: 'categories',
    description: 'each category connected with the offer',
    image: null,
  },
  {
    number: '12',
    title: 'months',
    description: 'special destination prepared for the proper period',
    image: null,
  },
];
