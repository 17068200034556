import React from 'react';

import './ArrowShort.scss';

export const ArrowShort = () => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'16'}
    height={'16'}
    fill={'none'}
    className={'arrow-short'}
  >
    <path
      d={'M8 0 6.59 1.41 12.17 7H0v2h12.17l-5.58 5.59L8 16l8-8-8-8Z'}
    />
  </svg>
);
