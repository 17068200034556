import React, { useState, useEffect } from 'react';
import './AboutUs.scss';
import { Section, Heading, Paragraph, IconSVG, Link } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import data from './logic';

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState(data.items[0]);
  const [isAwwwards, setIsAwwwards] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('awwwards')
    ) {
      setIsAwwwards(true);
    }
  }, []);

  return (
    <Section additionalClass={'awwwards-lp-about'} color={'mine-shaft'}>
      <Heading
        additionalClass={'awwwards-lp__heading'}
        size={'large'}
        color={'white'}
        lineColor={'yellow'}
        type={2}
      >
        {isAwwwards ? data.heading : 'Our five pillars of effective design:'}
      </Heading>
      <div className={'awwwards-lp-about__content'}>
        <ul className={'awwwards-lp-about__tabs anim-fade-in-left'}>
          {data.items.map(item => (
            <li
              className={classNames('pointer awwwards-lp-about__tab', {
                active: activeTab.id === item.id,
              })}
              key={item.key}
              onClick={() => setActiveTab(item)}
              id={`nav-${item.id}`}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className={'awwwards-lp-about__tab-description'}>
          <Heading
            additionalClass={
              'awwwards-lp-about__tab-heading anim-fade-in-bottom'
            }
            color={'white'}
            size={'medium-plus'}
            type={3}
          >
            {activeTab.label}
          </Heading>
          <Paragraph
            additionalClass={'awwwards-lp__paragraph anim-fade-in-bottom'}
            color={'white'}
          >
            {activeTab.description}
          </Paragraph>
          <Link
            to={activeTab.link}
            additionalClass={'awwwards-lp-about__tab-link'}
          >
            {activeTab.buttonText}
            <IconSVG name={'arrow-right'} color={'yellow'} />
          </Link>
        </div>
        <div className={'awwwards-lp-about__link'}>
          <img
            className={'image awwwards-lp-about__img'}
            src={getCloudinaryUrl(activeTab.image.src, 'f_auto,q_auto,h_150')}
            alt={activeTab.image.alt}
            loading={'lazy'}
          />
        </div>
      </div>

      <div className={'awwwards-lp-about__content-vertical'}>
        <ul className={'awwwards-lp-about__tabs'}>
          {data.items.map(item => (
            <li
              className={classNames('awwwards-lp-about__tab pointer', {
                active: activeTab.id === item.id,
              })}
              key={item.key}
              onClick={() => setActiveTab(item)}
              id={`nav-${item.key}`}
            >
              <Heading
                additionalClass={'awwwards-lp-about__tab-heading'}
                color={'white'}
                size={'big'}
                type={3}
              >
                {item.label}
              </Heading>
              <div className={'awwwards-lp-about__tab-paragraph'}>
                <img
                  className={'image awwwards-lp-about__img'}
                  src={getCloudinaryUrl(item.image.src, 'f_auto,q_auto,h_100')}
                  alt={item.image.alt}
                  loading={'lazy'}
                />
                <Paragraph
                  additionalClass={'awwwards-lp__paragraph'}
                  color={'white'}
                >
                  {item.description}
                </Paragraph>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Section>
  );
};

export default AboutUs;
