import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';
import companyData from '../../../meta/companyData';

const page = pages.resources.items['new-normal-checklist'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/new-normal-checklist/`;
const canonical = `${url}`;

const NewNormalChecklist = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'checklist'}
        title={'Are you ready for the New Normal?'}
        lineColor={'red'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/new-normal-checklist-cover.png',
          'f_auto,q_auto,w_652',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-new-normal-checklist.png',
          'f_auto,q_auto,w_600',
        )}
        paragraphHeadings={[
          `Are you ready for the New Normal? We have prepared a dedicated 11-point checklist with additional tips and bonuses to help you answer this question. Get practical knowledge in the following areas:`,
        ]}
        items={[
          `Leadership during the New Normal`,
          `Distributed organizations and teams`,
          `Processes and Tools`,
        ]}
        outro={`Take advantage of Boldare's ${companyData.years_experience} years of practical knowledge in operating as a decentralized organization with distributed teams and processes! Download this free, practical checklist and get ready for the New Normal.`}
        url={pardotlink}
        formHeading={'Get your free <i>New Normal</i> checklist now'}
        button={'Send me checklist'}
        btnColor={'red'}
        trackingEvent={`NewNormalChecklistResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free checklist is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default NewNormalChecklist;
