const data = [
  {
    square: true,
    date: '9:00 a. m.',
    topic: 'Introduction',
    speaker: 'K. Kolodziej',
    position: 'CMO',
  },
  {
    square: false,
    date: '9:20 a. m.',
    topic: 'Keep up with a VUCA world with Systemic Digital Transformation',
    speaker: 'A. Zarudzka',
    position: 'co-CEO',
  },
  {
    square: false,
    date: '10:30 a. m.',
    topic: '5 Problems of Software Development You Have to Solve',
    speaker: 'P. Majchrzak',
    position: 'co-CEO',
  },
  {
    square: false,
    date: '11:30 a. m.',
    topic: 'Agile office tour',
    speaker: 'K. Kolodziej',
    position: 'CMO',
  },
  {
    square: true,
    date: '12:30 p. m.',
    topic: 'Lunch',
    speaker: '-',
  },
  {
    square: false,
    date: '1:30 p. m.',
    topic:
      'How to build web products using a lean startup approach and why this framework is perfect for recession times',
    speaker: 'A. Orszewska',
    position: 'New Products Guide',
  },
  {
    square: false,
    date: '2:30 p. m.',
    topic: 'Real case studies of digital transformation',
    speaker: 'TBA',
  },
  {
    square: false,
    date: '4:00 p. m.',
    topic:
      'How to build psychological safety for more efficient and agile teamwork?',
    speaker: 'TBA',
  },
  {
    square: true,
    date: '5:00 p. m.',
    topic: 'Summary',
    speaker: 'K. Kolodziej',
    position: 'CMO',
  },
  {
    square: true,
    date: '7:00 p. m.',
    topic: 'Supper',
    speaker: '-',
  },
];
export default data;
