import React from 'react';
import { Section, Heading, Paragraph } from 'components';
import { useWindowWidth } from 'hooks';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './data';
import './PodcastSection.scss';

const PodcastSection = () => {
  return (
    <Section color={'mine-shaft'} additionalClass={'podcast-section'}>
      <div className={'podcast-section__text-container'}>
        <Heading type={2} additionalClass={'podcast-section__title'}>
          We want to <br />
          meet you in person
        </Heading>
        <Paragraph
          additionalClass={'podcast-section__description'}
          color={'white'}
        >
          Forget about being alone and bored in front of the computer! At
          Boldare, we regularly meet with the team and the client to spend time
          together, get to know each other better, and make our efforts more
          productive. We strongly value our team bond and relations with
          clients.&nbsp;
        </Paragraph>
        <a
          href={'https://boldare.click/podcast-pracownia'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classNames(
            'button',
            'button--transparent-white',
            'podcast-section__button',
            'ga-f2f--podcast',
          )}
        >
          Learn more about F2F work
        </a>
      </div>
      <div id={data.id}>
        <img
          className={`about-us__slide-image`}
          alt={data.alt}
          src={getCloudinaryUrl(data.cloudinaryDir, `f_auto,q_auto,w_752`)}
          loading={'lazy'}
        />
      </div>
    </Section>
  );
};

export default PodcastSection;
