import React, { useState, useRef, useLayoutEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Loading = ({ isVisible }) => {
  const isFirst = useRef(true);
  const [animate, setAnimate] = useState(true);

  useLayoutEffect(() => {
    isFirst.current = !isVisible;
  }, [isVisible]);

  return (
    <AnimatePresence onExitComplete={() => setAnimate(true)}>
      {isVisible && animate && (
        <div className={'dsgn-hero__animated-stories-loading'}>
          <motion.svg
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: isFirst.current ? 1 : 0.2,
                ease: 'linear',
                duration: 0.4,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                ease: 'linear',
                duration: 0.15,
              },
            }}
            className={'dsgn-hero__animated-stories-dot'}
            viewBox={'0 0 24 24'}
          >
            <circle cx={12} cy={12} r={12} fill={'#242424'} />
          </motion.svg>
          <motion.svg
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: isFirst.current ? 1.3 : 0.5,
                ease: 'linear',
                duration: 0.4,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                ease: 'linear',
                duration: 0.15,
              },
            }}
            className={'dsgn-hero__animated-stories-dot'}
            viewBox={'0 0 24 24'}
          >
            <circle cx={12} cy={12} r={12} fill={'#242424'} />
          </motion.svg>
          <motion.svg
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: isFirst.current ? 1.6 : 0.8,
                ease: 'linear',
                duration: 0.4,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                ease: 'linear',
                duration: 0.15,
              },
            }}
            className={'dsgn-hero__animated-stories-dot'}
            viewBox={'0 0 24 24'}
            onAnimationComplete={() => setAnimate(false)}
          >
            <circle cx={12} cy={12} r={12} fill={'#242424'} />
          </motion.svg>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Loading;
