import React from 'react';
import './Webinar.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Section, SectionHeader } from 'components';

const Webinar = () => (
  <Section
    additionalClass={'digtra-de-webinar__section'}
    id={'digtra-de-webinar'}
    spaceless
  >
    <div className={'digtra-de-webinar__wrapper'}>
      <div className={'digtra-de-webinar__content-wrapper'}>
        <div className={'digtra-de-webinar__text-wrapper'}>
          <SectionHeader
            size={'large'}
            color={'white'}
            headingType={2}
            fixed
            noLine
            additionalClass={'digtra-de-webinar__text-header'}
          >
            Digitale Transformation in der Praxis kennenlernen?
          </SectionHeader>
          <Heading
            type={3}
            size={'large'}
            color={'white'}
            additionalClass={'digtra-de-webinar__text-header-secondary'}
          >
            Erfahren Sie in unserem Webinar wie digitale Transformation
            funktioniert und welche Vorteile sie der
            Erneuerbaren-Energien-Industrie bringt.
          </Heading>
        </div>
        <div className={'digtra-de-webinar__btn-wrapper'}>
          <a
            href={'https://www.youtube.com/watch?v=lt9lDqzPJqw'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'digtra-de-webinar__btn-wrapper-link'}
          >
            <img
              src={getCloudinaryUrl(
                'de/digitale-transformation/webinar-video',
                'f_auto,q_auto',
              )}
              className={'image digtra-de-webinar__btn'}
              alt={'Webinar background'}
              loading={'lazy'}
            />
          </a>
        </div>
      </div>
      <img
        src={getCloudinaryUrl(
          'de/digitale-transformation/webinar.jpg',
          'f_auto,q_auto',
        )}
        className={'image digtra-de-webinar__background'}
        alt={'Webinar background'}
        loading={'lazy'}
      />
    </div>
    <div className={'digtra-de-webinar__line'} />
  </Section>
);

export default Webinar;
