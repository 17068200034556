export const whoWeAre = {
  hero: {
    title: 'Boldare - digital product creators & consultants',
    description: 'We help our partners build products their users want.',
    partnersDescription: 'Our partners are known world wide:',
    partnersLogos: [
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722059/design-rush/customer-logos/leaseweb_logo.png',
        name: 'leaseweb',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1705593770/design-rush/customer-logos/Decathlon_Logo_2.png',
        name: 'Decathlon',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722059/design-rush/customer-logos/Corel_logo.png',
        name: 'Corel',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/Bosch_logo.png',
        name: 'BOSCH',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722116/design-rush/customer-logos/BNP_logo.png',
        name: 'BNP',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/logo-prisma.png',
        name: 'Prisma',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/sonnen-logo.png',
        name: 'sonnen',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/LOGO_TUI.png',
        name: 'TUI',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/takamol-logo.png',
        name: 'takamol',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675722058/design-rush/customer-logos/BlaBlaCar_logo.png',
        name: 'BlaBlaCar',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1705593770/design-rush/customer-logos/planet_escape_logo.png',
        name: 'Planet Escape',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1705593770/design-rush/customer-logos/maxio_logo.png',
        name: 'Maxio',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1705593770/design-rush/customer-logos/humanitas_logo.png',
        name: 'Humanitas',
      },
    ],
  },
  services: {
    title: 'Services',
    description:
      'We build digital products in quick build-measure-learn iterations that help us validate product assumptions with real users to learn, tweak or pivot. Come to us for:',
    serviceTypes: [
      {
        name: 'Building your product',
        items: ['mobile apps', 'websites', 'web apps'],
      },
      {
        name: 'Consulting services',
        items: ['CTO as a service', 'product strategy', 'Advisory Board'],
      },
      {
        name: 'Support for your products',
        items: [
          'code audit',
          'UX audit',
          'dedicated or augmented development teams',
        ],
      },
      {
        name: 'Design services',
        items: ['user experience', 'user interface', 'web design'],
      },
    ],
  },
  reviews: {
    title: 'Testimonials',
    description: 'Our partners value us for:',
    customer: [
      {
        name: 'Norbert Baumann',
        companyName: 'sonnen',
        companyLogo:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686327/design-rush/customer-review/sonnen-logo_2.png',
        caseStudy:
          'https://www.boldare.com/work/case-study-sonnen-digital-transformation/',
        comment:
          '“We gained a partner that is not only doing what we asked them to do but is also thinking and giving feedback.”',
        face:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/NorbertBaumann.png',
      },
      {
        name: 'Fadi Mozuannar',
        companyName: 'Takamol',
        companyLogo:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686570/design-rush/customer-review/takamol_logo.png',
        caseStudy: 'https://www.boldare.com/work/case-study-tojjar/',
        comment:
          '“Boldare was able to support us whenever we needed it. They were very flexible.”',
        face:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686570/design-rush/customer-review/FadiMozuannarFace.png',
      },
      {
        name: 'Randy Carpenter',
        companyName: 'Interstate Data',
        companyLogo:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/Interstatedata_logo.png',
        caseStudy:
          'https://www.boldare.com/blog/our-approach-to-building-product-development-team-for-you/',
        comment:
          '"They don’t just build a product; their team adds value throughout the whole process."',
        face:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/RandyCarpenterFace.png',
      },
      {
        name: 'Dave Cook',
        companyName: 'Xinfu',
        companyLogo:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675865643/design-rush/customer-review/Beyond-Unity-Logo.png',
        caseStudy:
          'https://www.boldare.com/blog/digital-transformation-of-education/',
        comment:
          '"The speed with which they understood the challenge was impressive."',
        face:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/DaveCookFace.png',
      },
      {
        name: 'Allan Wilson',
        companyName: 'TeamAlert',
        companyLogo:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/team_alert_logo.png',
        caseStudy:
          'https://www.boldare.com/work/teamalert-transition-from-mvp-to-pmf/',
        comment:
          '"I was really impressed with how much they cared about our product."',
        face:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675686326/design-rush/customer-review/AlanWilsonFace.png',
      },
    ],
  },
  awards: {
    title: 'Awards',
    description:
      'Products that we built are recognized with prestigious awards, such as:',
  },
  benefits: {
    title: 'Value',
    description: 'By working with us you get:',
    list: [
      {
        icon:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675687311/design-rush/saturn.svg',
        centent: 'Team of tech champions',
      },
      {
        icon:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675687311/design-rush/start.svg',
        centent: 'Business consulting by default',
      },
      {
        icon:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675687311/design-rush/circle.svg',
        centent: 'Partners in your development efforts',
      },
    ],
  },
  technology: {
    title: 'Technologies',
    description: 'Technologies we work with:',
    logos: [
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/java-logo.svg',
        title: 'Java',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680991/design-rush/technology-logos/spring-logo.svg',
        title: 'Spring',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/php-logo.svg',
        title: 'PHP',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/kubernetes-logo.svg',
        title: 'Kubernetes',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/dotnet-logo.svg',
        title: '.Net',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/python-logo.svg',
        title: 'Python',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/django-logo.svg',
        title: 'Django',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/javascript-logo.svg',
        title: 'JavaScript',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/typescript-logo.svg',
        title: 'TypeScript',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/vue-logo.svg',
        title: 'Vue.js',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/angular-logo.svg',
        title: 'Angular',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/react-logo.svg',
        title: 'React',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680988/design-rush/technology-logos/node-logo.svg',
        title: 'Node.js',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680988/design-rush/technology-logos/nest-logo.svg',
        title: 'Nest.js',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/android-logo.svg',
        title: 'Android',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/react-native-logo.svg',
        title: 'React Native',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/ios-logo.svg',
        title: 'iOS',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/terraform-logo.svg',
        title: 'Terraform',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/aws-logo.svg',
        title: 'AWS',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/firebase-logo.svg',
        title: 'Firebase',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/symfony-logo.svg',
        title: 'Symfony',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/marble-logo.svg',
        title: 'Marble.js',
      },
      {
        link:
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/docker-logo.svg',
        title: 'Docker',
      },
    ],
  },
  contact: {
    title: 'Contact',
    description:
      'Spend your budget on ideas that work and digital products that your users want - just like our partners from around the world.',
    details: `Write to us at <a href='mailto:hello@boldare.com'>hello@boldare.com</a> for a free consultation.`,
  },
};
