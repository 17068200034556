import React from 'react';
import PropTypes from 'prop-types';
import { IconSVG } from 'components';
import { countDown } from 'utils';
import './CountDown.scss';

const CountDown = ({ deadline }) => (
  <div className={'career-count-down'}>
    Application deadline{' '}
    <IconSVG
      name={'awwwards-clock'}
      color={'white'}
      size={'huge'}
      additionalClass={'pulsate'}
    />{' '}
    <div className={'career-count-down__deadline'}>{countDown(deadline)}</div>
  </div>
);

CountDown.propTypes = {
  deadline: PropTypes.instanceOf(Date).isRequired,
};

export default CountDown;
