export const sliderData = [
  {
    avatar: 'de/digitale-transformation/Malte_Kalkoffen',
    content:
      'Wir waren beeindruckt von ihrem Streben nach eine dauerhafte Beziehung aufzubauen und eine nachhaltige Lösung zu liefern.',
    name: 'Malte Kalkoffen',
    title: 'CEO Endios',
  },
  {
    avatar: 'de/digitale-transformation/Norbert_Baumann',
    content:
      'Boldare hat sich als ein Unternehmen hervorgetan, das unser Problem wirklich versteht und unsere Bedürfnisse erfüllen kann.',
    name: 'Norbert Baumann',
    title: 'Technical Innovation Lead sonnen',
  },
  {
    avatar: 'de/digitale-transformation/Nicolas_Renon',
    content:
      'Ich war erfreut fast ein Jahr mit Boldare zusammenzuarbeiten. Boldare war von Anfang an bis zum letzten Tag sehr professionell.',
    name: 'Nicolas Renon',
    title: 'Lead Engineer BlaBlaCar',
  },
  {
    avatar: 'de/digitale-transformation/Cathy_Cao',
    content:
      'Wir entschieden uns für eine Zusammenarbeit, da uns Boldares Herangehensweise an Prozesse begeistert hat. (…)',
    name: 'Cathy Cao',
    title: 'Director of Digital Olive Tree Holdings',
  },
];
