import { Paragraph } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import './Info.scss';
import { toHtml } from 'config/helpers';

const EventInfo = ({ eventData }) => {
  return (
    <>
      <div className={'event-info'}>
        {eventData.duration && (
          <div className={'event-info__column'}>
            <h3 className={'heading event-info__column--key'}>Duration</h3>
            <p className={'event-info__column--value'}>{eventData.duration}</p>
          </div>
        )}
        {eventData.group && (
          <div className={'event-info__column'}>
            <h3 className={'heading event-info__column--key'}>Group</h3>
            <p className={'event-info__column--value'}>{eventData.group}</p>
          </div>
        )}
        {eventData.qaSession && (
          <div className={'event-info__column'}>
            <h3 className={'heading event-info__column--key'}>
              Q&amp;A Session
            </h3>
            <p className={'event-info__column--value'}>
              {eventData.qaSession}
            </p>
          </div>
        )}
        {eventData.form && (
          <div className={'event-info__column'}>
            <h3 className={'heading event-info__column--key'}>Form</h3>
            <p className={'event-info__column--value'}>{eventData.form}</p>
          </div>
        )}
      </div>
      {eventData.customCopy && (
        <div className={'event-info__text'}>
          <p>
            This meetup will inspire you to take user-centricity from an
            undefined concept to an actionable product strategy. Implement the
            techniques from the talks and align your business goals with the
            user value creation.
          </p>
          <p>
            Take part in a meetup where expert product practitioners from
            Boldare meet with knowledge of the Growth Tribe and guide you and
            your business into a new stage of accelerated growth in users and
            revenue.
          </p>
          <p>
            Listen to Oskar Kwitek, a Product Strategist at Boldare, who with
            years of experience under his belt, will explain why Product Led
            Growth is a great new way to boost your businesses growth and will
            show you how to create your product strategy that will take you to
            new heights when it comes to gaining new users.
          </p>
          <p>
            {`Join Tarek Reslan, Lead Growth & Marketing Trainer & Coach at
            Growth Tribe, for a workshop titled Mastering User Insights:
            Usability Testing, Value Activation, and Persona Development
            Workshop. This session is designed to equip you with the tools
            needed to gain a deeper understanding of your users and tailor your
            strategies to align with your customers' needs.`}
          </p>
          <ul className={'dotted-list__items'}>
            <p className={'text--bold text--uppercase'}>Agenda:</p>
            <li>18:00 - registration</li>
            <li>18:25 - Welcome talk by Boldare and Growth Tribe</li>
            <li>18:30 - Talks by Boldare and Growth Tribe</li>
            <li>20:00 - Networking & drinks</li>
          </ul>
          <ul>
            <p className={'text--bold text--uppercase'}>Topic of the talks:</p>
            <li>
              <span className={'text--bold'}>
                Strategizing for Success: Building a Strong Foundation for
                Product-Led Growth
              </span>{' '}
              by Oskar Kwitek, Product Strategist at Boldare
            </li>
            <li>
              <span className={'text--bold'}>
                Mastering User Insights: Usability Testing, Value Activation,
                and Persona Development workshops
              </span>{' '}
              by Tarek Reslan, Lead Growth & Marketing Trainer & Coach at
              Growth Tribe
            </li>
          </ul>
          <ul className={'dotted-list__items'}>
            <p className={'text--bold text--uppercase'}>
              For entrepreneurs and innovators, who:
            </p>
            <li> make informed decisions about their business</li>
            <li>
              want their product to find product-market fit without delay
            </li>
            <li>
              look for a way to boost their business number of customers and
              revenue
            </li>
          </ul>
        </div>
      )}
      {!eventData.customCopy && (
        <div className={'event-info__text'}>
          {eventData.description?.map(item => {
            return <Paragraph nested>{toHtml(item || '')}</Paragraph>;
          })}
          <ul className={'dotted-list__items'}>
            {eventData.agendaItems?.map(item => {
              return <li>{item}</li>;
            })}
          </ul>
          {eventData.summary && (
            <Paragraph nested>{toHtml(eventData.summary || '')}</Paragraph>
          )}
        </div>
      )}
    </>
  );
};

EventInfo.propTypes = {
  eventData: PropTypes.shape({
    duration: PropTypes.string,
    group: PropTypes.string,
    qaSession: PropTypes.string,
    form: PropTypes.string,
    customCopy: PropTypes.bool,
    description: PropTypes.arrayOf(PropTypes.string),
    agendaItems: PropTypes.arrayOf(PropTypes.string),
    summary: PropTypes.string,
  }).isRequired,
};

export default EventInfo;
