import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { ReviewContent } from './subcomponents';
import './SingleReview.scss';

export class ReviewTemplate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { review, articlesList } = this.props;
    const { title, settings } = review.frontmatter;
    const slug = settings.slug || review.fields.slug;

    return (
      <div className={'single-case-study'}>
        <SEO
          title={settings.metaTitle || title}
          description={settings.metaDescription}
          cover={getCloudinaryUrl(settings.cover, 'f_auto,q_auto,w_400')}
          slug={slug}
          section={settings.type || 'work'}
          canonical={settings.canonical}
          cloudinary
        />
        <ReviewContent data={review.frontmatter} articlesList={articlesList} />
      </div>
    );
  }
}

ReviewTemplate.propTypes = {
  review: PropTypes.shape().isRequired,
  articlesList: PropTypes.shape().isRequired,
};

export default ReviewTemplate;
