export const data = [
  {
    title: 'Backend Developers',
    description:
      'Backend developers use their extensive knowledge and years of practice in Java, PHP, NodeJS, and Symfony to provide the best quality result. They are responsible for in-depth coding of apps and systems to ensure a flawless digital product.',
  },
  {
    title: 'Frontend Developers',
    description: `Frontend developers make sure that the client's product works and looks as intended. To assure the best quality, our programmers use JavaScript, AngularJS, and ReactJS and related frameworks and libraries.`,
  },
  {
    title: 'Product Designers',
    description:
      'Graphic Designers are an integral part of our dedicated teams. Thanks to their exceptional skills, they are regular winners in contests such as Awwwards and the Indigo Design Awards. Our teams offer comprehensive experience and integral processes covering all stages of software development.',
  },
  {
    title: 'Quality Assurance Engineers',
    description:
      'Quality Assurance Engineers are an essential part of our teams. Thanks to their expertise we can assure the highest quality of code regarding security and stability. QAs design and conduct all sorts of necessary tests to make sure that the software we develop is flawless.',
  },
  {
    title: 'Scrum Master',
    description:
      'The Scrum Master is here to ensure that our dedicated teams are well-balanced and consist of people ready to cooperate to achieve your business goals. The Scrum Master is also the guardian of the process, compliant with the Agile Manifesto, which ensures the regular delivery of increments at a rapid pace.',
  },
  {
    title: 'Product Owner',
    description:
      'The Product Owner makes sure that all business requirements are correctly transmitted to the team. They are responsible for the correct understanding of business goals by the team as well as for proper prioritization of the product backlog.',
  },
];
