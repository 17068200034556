import React, { Component } from 'react';
import inView from 'in-view';
import { classNames } from 'utils';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Changes.scss';

class Changes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.pe__changes-image').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  render() {
    return (
      <>
        <Section additionalClass={'pe__changes-container'}>
          <div className={'pe__content'}>
            <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
              What
            </Heading>
            <Heading type={2} size={'big-spreaded'}>
              We change the travel agency into the different dimension
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              We helped the travel agency to change the image. Now they can
              help their customers to move into a different dimension of
              reality. They realize their dreams of exotic travel thanks to
              huge offers and also Premium package. This all gives us great
              satisfaction.
            </Paragraph>
          </div>
        </Section>
        <Section additionalClass={'pe__changes-images'} spaceless>
          <div className={'pe__content--left'}>
            <Paragraph
              additionalClass={'text--light'}
              size={'small-plus'}
              color={'gray-dark'}
            >
              To change the old image
            </Paragraph>
            <Heading
              type={3}
              size={'medium'}
              color={'mine-shaft'}
              additionalClass={'text--medium-bold pe__changes-header'}
            >
              Create new image
              <br />
              with UX &amp; UI app rebuild
            </Heading>
          </div>
          <div className={'pe__changes-image'}>
            <FullwidthImage
              image={getCloudinaryUrl(
                'assets/planet-escape/planet-escape-site.jpg',
                'f_auto,q_auto',
              )}
              alt={'Old vs new look'}
              additionalClass={classNames('anim-fade-in-bottom', {
                'in-view': this.state.entered,
              })}
            />
          </div>
        </Section>
      </>
    );
  }
}

export default Changes;
