import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import '../FormControl.scss';

const Input = ({
  additionalClass,
  children,
  hint,
  type,
  id,
  name,
  onChange,
  label,
  value,
  required,
}) => {
  return (
    <div className={classNames('form-control', additionalClass)}>
      <input
        className={'form-control__input'}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={e => onChange(e.target.value)}
        required={required}
        autoComplete={'off'}
      />
      <label
        className={classNames('form-control__label', {
          'form-control__label--float': value,
        })}
        htmlFor={id}
      >
        {label}
      </label>
      {children}
      {hint && <p className={'form-control__hint'}>{hint}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  hint: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  additionalClass: '',
  type: 'text',
  hint: null,
  required: false,
  value: '',
  children: null,
};

export default React.memo(Input);
