import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { classNames } from 'utils';
import { Box, Heading, BoxCaseStudyHover, Paragraph } from 'components';
import { BOX_TYPES } from 'config';
import './BoxCaseStudy.scss';

class BoxCaseStudy extends Component {
  render() {
    const { additionalClass, data, id, isTargetBlank } = this.props;
    const { content, settings, type } = data;
    const { coverImage, title, subtitle, description } = content;
    const { link } = settings;

    return (
      <Box
        id={id}
        backgroundColor={'white'}
        additionalClass={classNames(
          {
            'box-case-study': type === BOX_TYPES.CASE_STUDY,
            'box-brief': type === BOX_TYPES.BRIEF,
          },
          additionalClass,
        )}
      >
        <div className={'box-case-study__header box-case-study__main-content'}>
          <span
            className={classNames(
              'box__badge',
              {
                'box__badge--case-study': type === BOX_TYPES.CASE_STUDY,
                'box__badge--brief': type === BOX_TYPES.BRIEF,
              },
              'text--bold',
            )}
          >
            {ReactHtmlParser(type)}
          </span>
          <a
            id={`cs-img-${id}`}
            href={link}
            className={'box-article__link'}
            target={isTargetBlank ? '_blank' : '_self'}
            rel={'noreferrer'}
          >
            <img
              src={coverImage.src ? coverImage.src : coverImage}
              alt={`${title} - case study cover`}
              className={
                'image box-case-study__cover-image box-case-study__cover-image--large'
              }
              loading={'lazy'}
            />
          </a>
        </div>
        <div
          className={classNames(
            'box-case-study__content',
            'box-case-study__main-content',
            {
              'box-case-study__main-content--hover': type === BOX_TYPES.HOVER,
              'box--short': title && title.length < 29,
              'box--long': title && title.length >= 29,
              'box--no-description': !description,
            },
          )}
        >
          <div className={'box-case-study__top'}>
            {type !== BOX_TYPES.BRIEF ? (
              <a
                id={`cs-title-${id}`}
                href={link}
                className={'box-article__link'}
                target={isTargetBlank ? '_blank' : '_self'}
                rel={'noreferrer'}
              >
                <Heading
                  type={3}
                  size={'medium-plus-small'}
                  color={'black'}
                  additionalClass={'box-case-study__title'}
                >
                  {ReactHtmlParser(title)}
                </Heading>
              </a>
            ) : (
              <Heading
                type={3}
                size={'medium-plus-small'}
                color={'black'}
                additionalClass={'box-case-study__title'}
              >
                {ReactHtmlParser(title)}
              </Heading>
            )}
            {description && (
              <div className={'box-article__lead'}>
                <Paragraph
                  size={'default'}
                  color={'black'}
                  additionalClass={'text--light'}
                >
                  {description}
                </Paragraph>
              </div>
            )}
          </div>
          <div className={'box-case-study__bottom'}>
            <div className={'box-case-study__info'} />
          </div>
        </div>
        <div
          className={classNames('box-case-study__hover-content', {
            'box-case-study__hover-content--hover': type === BOX_TYPES.HOVER,
          })}
        >
          <BoxCaseStudyHover
            header={subtitle}
            content={description}
            hover={type === BOX_TYPES.HOVER}
          />
        </div>
      </Box>
    );
  }
}

BoxCaseStudy.defaultProps = {
  additionalClass: '',
  isTargetBlank: false,
};

BoxCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
  additionalClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  isTargetBlank: PropTypes.bool,
};

export default BoxCaseStudy;
