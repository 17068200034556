export const resourceCardData = [
  {
    id: 1,
    color: 'yellow',
    header: 'Knowledge base',
    description:
      'Browse through our guides, eBooks, and other insightful assets.',
    img: '/app-costs-calculator/career-impact-1.png',
    link: '/resources/',
  },
  {
    id: 2,
    color: 'red',
    header: 'Case studies',
    description: "Read stories of clients we've helped create amazing things.",
    img: '/app-costs-calculator/career-process-1.png',
    link: '/work/',
  },
  {
    id: 3,
    color: 'violet',
    header: 'Blog',
    description: 'Visit our blog where our experts share their knowledge',
    img: '/app-costs-calculator/career-system-1.png',
    link: '/blog/',
  },
  {
    id: 4,
    color: 'red',
    header: 'Community',
    description:
      'Sign up for the Agile Product Builders Community to learn and share',
    img: '/app-costs-calculator/career-double-1.png',
    link: '/agile-product-builders/',
  },
];
