import './Contact.scss';
import React, { useState, useEffect } from 'react';
import { ContactForm } from 'components';
import patternSix from 'assets/images/design-that-tells-stories/pattern_06.svg';
import patternTwo from 'assets/images/design-that-tells-stories/pattern_02.svg';
import patternFive from 'assets/images/design-that-tells-stories/pattern_05.svg';
import patternFour from 'assets/images/design-that-tells-stories/pattern_04.svg';
import patternThree from 'assets/images/design-that-tells-stories/pattern_03.svg';
import patternSeven from 'assets/images/design-that-tells-stories/pattern_07.svg';
import patternEight from 'assets/images/design-that-tells-stories/pattern_08.svg';

const Contact = () => {
  const [isContactSend, setIsContactSend] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you&connectionId=')
    ) {
      setIsContactSend(true);
    }
  }, []);

  return (
    <div
      id={'bldr__contact'}
      className={'bldr-dsgn__section bldr-dsgn__border-box'}
    >
      <div className={'bldr-dsgn__row'}>
        <div className={'bldr-dsgn__border-box bldr-dsgn__row'}>
          <img
            src={patternTwo}
            alt={'PATTERN 02'}
            className={'bldr-dsgn__image'}
          />
        </div>
        <div className={'bldr-dsgn__border-box bldr-dsgn__row'}>
          <img
            src={patternThree}
            alt={'PATTERN 03'}
            className={'bldr-dsgn__image'}
          />
        </div>
        <div
          className={'bldr-dsgn__border-box bldr-dsgn__row'}
          style={{ borderTop: 'none' }}
        >
          <img
            src={patternFour}
            alt={'PATTERN 04'}
            className={'bldr-dsgn__image'}
          />
        </div>
        <div className={'bldr-dsgn__border-box bldr-dsgn__row'}>
          <img
            src={patternFive}
            alt={'PATTERN 05'}
            className={'bldr-dsgn__image'}
          />
        </div>
      </div>
      <div className={'bldr-dsgn__row bldr-dsgn__row--grid'}>
        <div className={'bldr-dsgn__column'}>
          <div
            className={
              'bldr-dsgn__border-box bldr-dsgn-contact__form bldr-dsgn__heading'
            }
          >
            {isContactSend ? (
              <div className={'bldr-dsgn-contact__form--send'}>
                <h2>That sounds great!</h2>
                <p>
                  We will get back to you within 24 business hours so you can
                  tell us more.
                </p>
              </div>
            ) : (
              <div className={'bldr-dsgn-contact__form--initial'}>
                <h2>Now, tell us YOUR story...</h2>
                <ContactForm />
                <p>
                  Your data are safe with us. We take your privacy seriously
                  and we will never share your data with anyone. Read on our{' '}
                  <a
                    href={'https://www.boldare.com/privacy-policy/'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Privacy Policy
                  </a>{' '}
                  to learn more.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={'bldr-dsgn__column bldr-dsgn__contact-images-column'}>
          <div
            className={'bldr-dsgn__border-box'}
            style={{ borderBottom: 'none' }}
          >
            <img
              src={patternSix}
              alt={'PATTERN 06'}
              className={'bldr-dsgn__image'}
            />
          </div>
          <div
            className={'bldr-dsgn__border-box'}
            style={{ borderTop: 'none' }}
          >
            <img
              src={patternSeven}
              alt={'PATTERN 07'}
              className={'bldr-dsgn__image'}
            />
          </div>
          <div className={'bldr-dsgn__border-box'}>
            <img
              src={patternEight}
              alt={'PATTERN 08'}
              className={'bldr-dsgn__image'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
