import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';
import { Section, SectionHeader } from 'components';

import './Banner.scss';

const Banner = ({ activeFilter, isVariantB = false, isDesktop }) => (
  <Section
    id={'case-studies-section'}
    color={'mine-shaft'}
    additionalClass={classNames('work__hero', {
      'work__hero--sticky': isVariantB,
    })}
    spaceless
  >
    <SectionHeader
      additionalClass={
        'section-header__description--medium work__hero-content'
      }
      color={isVariantB && isDesktop ? 'black' : 'white'}
      noLine
      size={'extra-large'}
      margin={'work__hero--nomargin'}
    >
      {isVariantB && isDesktop && activeFilter ? activeFilter : 'Case Studies'}
    </SectionHeader>
  </Section>
);

Banner.defaultProps = {
  activeFilter: null,
  isVariantB: false,
  isDesktop: false,
};

Banner.propTypes = {
  activeFilter: PropTypes.string,
  isVariantB: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default Banner;
