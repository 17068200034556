import React from 'react';
import PropTypes from 'prop-types';
import './BoxHeader.scss';

const BoxHeader = ({ children }) => (
  <div className={'box__header'}>{children}</div>
);

BoxHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BoxHeader;
