import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';

import './Decoration.scss';

const Decoration = () => {
  const tile1 = getCloudinaryUrl(
    'discussion-event/tile-1-png.png',
    'f_auto,q_auto',
  );
  const tile2 = getCloudinaryUrl(
    'discussion-event/tile-2-png.png',
    'f_auto,q_auto',
  );
  const tile3 = getCloudinaryUrl(
    'discussion-event/tile-3-png.png',
    'f_auto,q_auto',
  );
  const tile4 = getCloudinaryUrl(
    'discussion-event/tile-4-png.png',
    'f_auto,q_auto',
  );

  return (
    <div className={'decoration-container'}>
      <img src={tile1} alt={'tile-1'} className={'tile'} />
      <img src={tile2} alt={'tile-2'} className={'tile'} />
      <img src={tile3} alt={'tile-3'} className={'tile'} />
      <img src={tile4} alt={'tile-4'} className={'tile'} />
    </div>
  );
};

export default Decoration;
