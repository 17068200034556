import React, { useMemo } from 'react';
import {
  SectionHeader,
  BoxCTA,
  WayPointHandler,
  Button,
  Link,
} from 'components';
import './Work.scss';
import { useCaseStudies } from 'views/work/subcomponents/CaseStudies/hooks/useCaseStudies';
import { CASE_STUDIES } from 'views/work/subcomponents/CaseStudies/data';
import BOX_TYPE from 'config/constants/boxTypes';
import { CaseStudy } from 'components/WorkBoxes';
import { classNames } from 'utils';

const CASE_STUDY_COUNT = 5;

const Work = () => {
  // useCaseStudies handles sorting and ordering internally
  // data is sorted according to pinned/priority fields on individual case studies
  const { data, userRegion } = useCaseStudies({
    data: CASE_STUDIES,
    boxPattern: [BOX_TYPE.CASE_STUDY],
    articles: [],
  });

  // pick first 5 case studies to display
  const caseStudies = useMemo(() => data.slice(0, CASE_STUDY_COUNT), [data]);

  return (
    <WayPointHandler path={'work'}>
      <SectionHeader
        description={`At Boldare, we go beyond just discussing great products - we create them with our clients. Explore our case studies to see how we've helped launch outstanding solutions on the market, all while upholding our high quality service standards.`}
      >
        Our Work
      </SectionHeader>
      <div className={classNames('case-studies-grid', userRegion ? 'case-studies-grid--loaded' : '')}>
        {caseStudies.map(caseStudy => (
          <CaseStudy
            noScroll
            rootUrl={'work/'}
            key={`case-study-${caseStudy.id}`}
            post={caseStudy}
          />
        ))}
        <BoxCTA name={'app-costs-calculator'}/>
      </div>
      <Link to={'/work/'} additionalClass={'work__explore'}>
        <Button
          id={'homepage-work'}
          color={'transparent'}
          ariaName={'Explore more button'}
        >
          Explore more
        </Button>
      </Link>
    </WayPointHandler>
  );
};

export default Work;
