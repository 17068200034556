import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes, { array } from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { toHtml, getCloudinaryUrl } from 'config/helpers';
import {
  Section,
  SectionHeader,
  Heading,
  Paragraph,
  Rating,
  Testimonial,
} from 'components';
import { Score } from '..';
import './Intro.scss';

const Intro = ({ data }) => {
  const { title } = data;
  const {
    logo,
    caption,
    rating,
    category,
    size,
    timespan,
    clientSize,
    location,
    review,
  } = data.intro;
  const {
    background,
    opportunity,
    solution,
    results,
    cost,
    quality,
    nps,
    scheduling,
  } = data.mainContent;
  const { author, testimonial } = review || [];

  return (
    <StaticQuery
      query={graphql`
        query {
          image: file(relativePath: { eq: "awards/partner-clutch-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Section>
          <Row className={'no-gap intro__top'}>
            <Col md={12} lg={8} className={'intro__main'}>
              <div className={'intro__inner'}>
                <Heading
                  type={1}
                  size={'large'}
                  additionalClass={'intro__heading'}
                >
                  {title || ''}
                </Heading>
                <div className={'intro__image-wrapper'}>
                  <img
                    src={
                      logo ? getCloudinaryUrl(logo, 'f_auto,q_auto,h_100') : ''
                    }
                    className={'image intro__image'}
                    alt={title}
                    loading={'lazy'}
                  />
                </div>
                <div className={'intro__rating'}>
                  <Paragraph size={'medium'}>{rating || '0.0'}</Paragraph>
                  {rating && <Rating rate={rating} size={20} red />}
                </div>
                <Paragraph
                  size={'default'}
                  additionalClass={'intro__clutch'}
                  nested
                >
                  {'This review comes from '}
                  <img
                    src={getCloudinaryUrl(
                      'gatsby-cloudinary/partner-clutch-logo.png',
                      'f_auto,q_auto,h_100',
                    )}
                    alt={'Clutch logo'}
                    className={'image'}
                    loading={'lazy'}
                  />
                </Paragraph>
              </div>
            </Col>
            <Col md={12} lg={4} className={'intro__sidebar'}>
              <div className={'intro__inner'}>
                <Heading
                  size={'medium'}
                  type={3}
                  additionalClass={'text--default intro__testimonial-intro'}
                >
                  Clients review on Clutch
                </Heading>
                <Testimonial
                  author={author || ''}
                  content={testimonial || ''}
                  additionalClass={'text--default'}
                />
              </div>
            </Col>
          </Row>
          <Row className={'no-gap intro__bottom'}>
            <Col md={12} lg={8} className={'intro__main'}>
              <div className={'intro__inner intro__line'}>
                <SectionHeader
                  size={'small'}
                  lineColor={'black'}
                  headingType={2}
                >
                  Background
                </SectionHeader>
                <div className={'review__section'}>
                  {background ? toHtml(background) : ''}
                </div>
              </div>
              <div className={'intro__inner intro__line'}>
                <SectionHeader
                  size={'small'}
                  lineColor={'black'}
                  headingType={2}
                >
                  Opportunity / Challenge
                </SectionHeader>
                <div className={'review__section'}>
                  {opportunity ? toHtml(opportunity) : ''}
                </div>
              </div>
              <div className={'intro__inner intro__line'}>
                <SectionHeader
                  size={'small'}
                  lineColor={'black'}
                  headingType={2}
                >
                  Solution
                </SectionHeader>
                <div className={'review__section'}>
                  {solution ? toHtml(solution) : ''}
                </div>
              </div>
              <div className={'intro__inner intro__line'}>
                <SectionHeader
                  size={'small'}
                  lineColor={'black'}
                  headingType={2}
                >
                  Results &amp; Feedback
                </SectionHeader>
                <div className={'review__section'}>
                  {results ? toHtml(results) : ''}
                </div>
              </div>
              <div className={'intro__inner'}>
                <SectionHeader
                  size={'small'}
                  lineColor={'black'}
                  headingType={2}
                >
                  Summary
                </SectionHeader>
                <div className={'intro__scores'}>
                  {scheduling && (
                    <Score
                      data={scheduling}
                      title={'Scheduling'}
                      subtitle={'On time / deadlines'}
                    />
                  )}
                  {cost && (
                    <Score
                      data={cost}
                      title={'Cost'}
                      subtitle={'Value / within estimates'}
                    />
                  )}
                  {quality && (
                    <Score
                      data={quality}
                      title={'Quality'}
                      subtitle={'Service & deliverables'}
                    />
                  )}
                  {nps && (
                    <Score
                      data={nps}
                      title={'NPS'}
                      subtitle={'Willing to refer'}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col
              md={12}
              lg={4}
              className={'intro__sidebar intro__sidebar--list'}
            >
              <div className={'intro__inner'}>
                {category && (
                  <div className={'intro__item'}>
                    <Paragraph
                      size={'small'}
                      additionalClass={'intro__item-title'}
                    >
                      Project category
                    </Paragraph>
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default intro__item-desc'}
                    >
                      {category}
                    </Paragraph>
                  </div>
                )}
                {size && (
                  <div className={'intro__item'}>
                    <Paragraph
                      size={'small'}
                      additionalClass={'intro__item-title'}
                    >
                      Project size
                    </Paragraph>
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default intro__item-desc'}
                    >
                      {size}
                    </Paragraph>
                  </div>
                )}
                {timespan && (
                  <div className={'intro__item'}>
                    <Paragraph
                      size={'small'}
                      additionalClass={'intro__item-title'}
                    >
                      Project length
                    </Paragraph>
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default intro__item-desc'}
                    >
                      {timespan}
                    </Paragraph>
                  </div>
                )}
                {clientSize && (
                  <div className={'intro__item'}>
                    <Paragraph
                      size={'small'}
                      additionalClass={'intro__item-title'}
                    >
                      Client size
                    </Paragraph>
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default intro__item-desc'}
                    >
                      {clientSize}
                    </Paragraph>
                  </div>
                )}
                {location && (
                  <div className={'intro__item'}>
                    <Paragraph
                      size={'small'}
                      additionalClass={'intro__item-title'}
                    >
                      Location
                    </Paragraph>
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default intro__item-desc'}
                    >
                      {location}
                    </Paragraph>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Section>
      )}
    />
  );
};

Intro.propTypes = {
  data: PropTypes.shape({}),
};

export default Intro;
