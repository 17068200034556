import React from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Technologies.scss';

const Technologies = () => {
  return (
    <>
      <Section additionalClass={'sm__technologies-container'} spaceless>
        <div className={'sm__content'}>
          <Heading type={2} size={'big-spreaded'}>
            Global and Local custom search systems
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            In both of the search systems we used dedicated, original
            solutions. We made them with use of the newest technologies such as
            AngularJS using functional programming at the same time. Dedicated
            solutions help users to quickly find what they are looking for.
          </Paragraph>
        </div>
        <div className={' sm__technologies-content'}>
          <div className={'sm__content--center'}>
            <FullwidthImage
              image={getCloudinaryUrl(
                `assets/spa-monkeys/spamonkeys-angular.jpg`,
                'f_auto,q_auto',
              )}
              alt={'Technolgies'}
            />
          </div>
          <div className={'sm__content--right'}>
            <Heading type={3} size={'medium-plus'}>
              Our technologies
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'default'}
              color={'mine-shaft'}
            >
              We used AngularJS with functional programming, which is a
              declarative paradigm. It means that programming is done with
              expressions or declarations instead of statements.
            </Paragraph>
          </div>
        </div>
      </Section>
      <div className={'sm__technologies-architecture'}>
        <Section additionalClass={'sm__technologies-title'} spaceless>
          <Heading type={3} size={'medium-plus'}>
            Never get zero
          </Heading>
          <Heading type={4} color={'boston-blue'} size={'medium-plus-small'}>
            Custom log and search flow
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'default'}
            color={'mine-shaft'}
          >
            We have created complete content search logic thanks to which user
            get very precise results and will never get zero.
          </Paragraph>
        </Section>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-architecture-2.jpg',
            'f_auto,q_auto',
          )}
          alt={'System architecture'}
        />
      </div>
    </>
  );
};

export default Technologies;
