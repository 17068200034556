import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Accordion } from 'react-accessible-accordion';
import ReactHtmlParser from 'react-html-parser';
import Glide from '@glidejs/glide';
import {
  Paragraph,
  Section,
  SectionHeader,
  Accordion as AccordionCustom,
} from 'components';
import { classNames } from 'utils';
import { data } from './logic.js';
import './Benefits.scss';

class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.glide = new Glide('.benefits__slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      animationDuration: 300,
      gap: 0,
      dragThreshold: false,
      autoplay: 3000,
      classes: {
        activeNav: 'active',
      },
    });

    this.glide.on(['build.after', 'run.after'], () => {
      this.setState({ currentSlide: this.glide.index });
    });

    this.glide.mount();
  }

  renderItem = () =>
    data.map(item => (
      <li className={'benefits__item glide__slide'}>
        <SectionHeader
          size={'medium'}
          color={'white'}
          descriptionColor={'white'}
          headingType={3}
          description={item.description}
          fixed
        />
      </li>
    ));

  renderTitle = () =>
    data.map((item, index) => (
      <li
        className={classNames('benefits__title', 'glide__bullet', {
          active: this.state.currentSlide === index,
        })}
        onClick={() => this.glide.go(`=${index}`)}
        role={'presentation'}
      >
        <Paragraph color={'cornflower'} size={'medium-plus'}>
          {item.title}
        </Paragraph>
      </li>
    ));

  renderAccordion = () =>
    data.map((item, index) => (
      <>
        <AccordionCustom
          additionalClass={'accordion__slider-item hide-tablet hide-desktop'}
          id={'prototyping-team'}
          backgroundColor={'mine-shaft'}
          headline={item.title}
          description={ReactHtmlParser(item.description)}
          lineColor={'red'}
          fontColors={['white', 'white', 'white']}
          index={index + 1}
          expandFirst
        />
      </>
    ));

  renderNavBullets = () =>
    data.map((item, index) => (
      <button
        className={'benefits__bullet glide__bullet'}
        data-glide-dir={`=${index}`}
        aria-label={'Slider bullet'}
      />
    ));

  render() {
    return (
      <Section color={'mine-shaft'}>
        <SectionHeader
          size={'big'}
          lineColor={'cornflower'}
          color={'white'}
          descriptionColor={'white'}
          headingType={2}
          fixed
        >
          Benefits
        </SectionHeader>
        <Accordion>{this.renderAccordion()}</Accordion>
        <div className={'benefits__slider glide hide-mobile'}>
          <Row>
            <Col sm={12} md={6}>
              <ol className={'benefits__list-nav'}>
                {this.glide && this.renderTitle()}
              </ol>
            </Col>
            <Col sm={12} md={6} className={'hide-mobile'}>
              <div data-glide-el={'track'} className={'glide__track'}>
                <ul className={'glide__slides'}>{this.renderItem()}</ul>
              </div>
              <div
                className={'benefits__nav glide__bullets'}
                data-glide-el={'controls[nav]'}
              >
                {this.renderNavBullets()}
              </div>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }
}

export default Benefits;
