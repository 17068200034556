import React from 'react';

import { enableAnimations } from 'config/helpers';
import { Landing, Survey, Result } from './subcomponents';
import { QuizState, Phase } from './logic/quizInterface';

import './PMFSurvey.scss';

// pmf-survey__answer-item

const PMFSurvey = () => {
  const [currentState, setCurrentState] = React.useState(QuizState.landing);
  const [currentPhase, setPhase] = React.useState(Phase.prototype);
  const [finalAnswers, setFinalAnswers] = React.useState({});

  React.useEffect(() => {
    enableAnimations();
  });

  const CurrentComponent = () => {
    switch (currentState) {
      case QuizState.landing:
        return (
          <Landing setNextState={() => setCurrentState(QuizState.quiz)} />
        );
      case QuizState.quiz:
        return (
          <Survey
            setNextState={() => setCurrentState(QuizState.result)}
            setResult={setPhase}
            setFinalAnswers={setFinalAnswers}
          />
        );
      case QuizState.result:
        return <Result phase={currentPhase} finalAnswers={finalAnswers} />;
      default:
        return <Landing />;
    }
  };

  return (
    <div
      className={
        'single-page single-page--hero pmf-survey pmf-survey--full-height'
      }
    >
      <CurrentComponent />
    </div>
  );
};

export default PMFSurvey;
