export const data = [
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-warszawa.jpg',
    cityId: 'warsaw',
    city: 'Warszawa',
    country: 'Poland',
    address: 'WeWork ul. Krucza 24',
    description:
      'Our 2nd location opened 6 years ago. We now operate from a coworking space in the very center of Warsaw, 10 minutes from the central railway station. The team based in Warsaw counts 30+ designers, developers, scrum masters, marketing & business people.',
    alt: 'Work at Boldare - Warsaw office, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-gliwice-office.jpg',
    cityId: 'gliwice',
    city: 'Gliwice',
    country: 'Poland',
    address: 'Zwycięstwa 52',
    description:
      "Our main HQ is based in Gliwice - a historic med school from 1927 renovated and adapted to be an agile office (as we like to call it) that we designed ourselves. If you're living in Silesia, that's your spot to visit and meet some of 80+ people working in the region.",
    alt: 'Work at Boldare - Gliwice office, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-wroclaw.jpg',
    cityId: 'wroclaw',
    city: 'Wrocław',
    country: 'Poland',
    address: 'Wyspa Słodowa 7',
    description:
      'Wyspa Słodowa is our sweet spot now! Did you know Concordia Design (our office building) was just recognized as 1 of the 15 best buildings in the world in 2020? - a prestigious international ranking. \n' +
      "Lovely, skilled people, the largest green wall in Poland, the great vibes of the city of Wrocław - that's what you'll find there!",
    alt: 'Work at Boldare - Wrocław office, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-krakow.jpg',
    cityId: 'krakow',
    city: 'Kraków',
    country: 'Poland',
    address: 'Kurniki 9',
    description:
      "Another coworking space located right near the main railway station in the city center. That's a massive asset when visiting your teammates across Poland, isn't it? In Kraków we have the biggest density of designers per square meter. Also Paulina, our Talent Selection Specialist, is the good angel of our Cracow office.  Want to meet her and the team?",
    alt: 'Work at Boldare - Kraków office, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-gdansk.jpg',
    cityId: 'gdansk',
    city: 'Gdańsk',
    country: 'Poland',
    address: '',
    description:
      'Claudia says, "Hi!" from the beautiful city of Gdansk every morning during the daily! She\'s a designer sharing her skills and spirit with our marketing team and commercial projects for Boldare\'s international clients. Say "Hi!" back to Claudia! If you\'re a designer deeply in love with UX - she\'s the one to talk to :)',
    alt: 'Remote work at Boldare - Gdańsk, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-lodz.jpg',
    cityId: 'lodz',
    city: 'Łódź',
    country: 'Poland',
    address: '',
    description:
      'One of our content writers, Paweł, is based in the lovely city of Łódź. He works from his home office splitting his time between being a dad, husband and a brilliant expert on content marketing. Say "Hi!" to Paweł and take a look at what can you expect when joining the #BoldareTeam as a remote employee.',
    alt: 'Remote work at Boldare - Łódź, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-kolobrzeg.jpg',
    cityId: 'kolobrzeg',
    city: 'Kołobrzeg',
    country: 'Poland',
    address: '',
    description:
      "Another seaside location is occupied by our devops engineer, Michał - one of the longest-serving members of the #BoldareTeam (his 10th anniversary was quite an event for us all). You'll possibly meet him during your recruitment process if you're applying for devops (psssst... We're hiring for this position!)",
    alt: 'Remote work at Boldare - Kołobrzeg, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-hudiksvall.jpg',
    cityId: 'hudiksvall',
    city: 'Hudiksvall',
    country: 'Sweden',
    address: '',
    description:
      'There is a house in... Hudiksvall. They call it Krysia\'s home. Does being so far away from the rest of the team affect the workflow of our Junior Scrum Master? Not really, as she says, "Thanks to the spirit and true agile culture of the company". You can work wherever you are and the remoteness won\'t make any difference. Want to try it out yourself?',
    alt: 'Remote work at Boldare - Hudiksvall, Sweden',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-hamburg.jpg',
    cityId: 'hamburg',
    city: 'Hamburg',
    country: 'Germany',
    address: '',
    description:
      'Michael cooperates with Boldare from Hamburg. He supports us in expanding our presence in Germany (we’re so glad for this!). “People say that Hamburg is the gate to the world, and that’s how I see it! It is by far the most beautiful city in Germany.” — claims Michael, and we advise you all to go and check for yourself!',
    alt: 'Remote work at Boldare - Hamburg, Germany',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-katowice.jpg',
    cityId: 'katowice',
    city: 'Katowice',
    country: 'Poland',
    address: '',
    description: `Magda works at Boldare as a QA Engineer and has an eagle eye on every glitch or defect. She lives in Katowice and spends her work time at a cosy home office. “For those who think that there are only mines and steel mills in Katowice, I would like to say that it's not true! There are many fascinating places, pubs and very nice people here”. – believes Magda.`,
    alt: 'Remote work at Boldare - Katowice, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-amsterdam.jpg',
    cityId: 'amsterdam',
    city: 'Amsterdam',
    country: 'Netherlands',
    address: '',
    description:
      'Matt works on creating deep relationships with current and former clients of Boldare. The Boldare Community is one of the things he loves! He is currently based in Amsterdam, but works from all around the world. “Amsterdam is a great, liveable city. If you love water, you will love Amsterdam” – comments Matt.',
    alt: 'Remote work at Boldare - Amsterdam, Netherlands',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-torun.jpg',
    cityId: 'torun',
    city: 'Toruń',
    country: 'Poland',
    address: '',
    description:
      'Adrian produces high-quality code solutions from his home office. He lives in Toruń, a city placed in the north of Poland. He moved here a short time ago and believes that "Toruń is stunning and beautiful". People working at Boldare often change their locations, and we think it’s cool!',
    alt: 'Remote work at Boldare - Toruń, Poland',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-vienna.jpg',
    cityId: 'vienna',
    city: 'Vienna',
    country: 'Austria',
    address: '',
    description: `Please meet our guard of the client's business needs, Marta. As a QA Business Analyst, Marta takes care of the quality from the moment of analyzing the requirements till the new functionalities go to production. She lives and works in Vienna – one of her favourite places. "Vienna is full of historical monuments, cosy cafés and wineries. It's a truly charming city!" — assures Marta.`,
    alt: 'Remote work at Boldare - Vienna, Austria',
  },
  {
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-ferrel2.jpg',
    cityId: 'ferrel',
    city: 'Ferrel',
    country: 'Portugal',
    address: '',
    description: `Anna loves working at Boldare, and she also loves surfing after it! As a Business Developer, she helps our potential clients to confirm their opinion that Boldare is the type of partner they are looking for. Based in Ferrel in Portugal and super close to the beach, she can catch the waves of the Atlantic every day and enjoy the coast life as well.`,
    alt: 'Remote work at Boldare - Ferrel, Portugal',
  },
];
