import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import PropTypes from 'prop-types';
import StickyBox from 'react-sticky-box';
import { Row, Col } from 'react-flexbox-grid';
import { toHtml, getCloudinaryUrl } from 'config/helpers';

import {
  SectionHeader,
  Paragraph,
  Button,
  ResourceForm,
  ResourceList,
} from 'components';
import { SignUpForm } from 'components/Newsletter/SignUpForm';

import './AssetContent.scss';

const SpyLink = Scroll.Link;
class AssetContent extends Component {
  constructor() {
    super();

    this.state = {
      subscribed: false,
      isMobile: false,
      isTablet: false,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (
      typeof window !== 'undefined' &&
      window.location.href.indexOf('downloadedResource=true') !== -1
    ) {
      this.setState({ subscribed: true });
    }
  }

  componentDidUpdate() {
    if (
      typeof window !== 'undefined' &&
      window.location.href.indexOf('downloadedResource=true') !== -1
    ) {
      Scroll.scroller.scrollTo('___gatsby', {
        smooth: true,
        duration: 1000,
        offset: 0,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSubscribe = () => {
    const url = window.location.href;
    const joinChar = url.indexOf('?') > -1 ? '&' : '?';

    window.location.href = `${url + joinChar}downloadedResource=true`;
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
    this.setState({ isTablet: window.innerWidth < 1024 });
  };

  render() {
    const {
      btnColor,
      button,
      cover,
      coverMobile,
      coverAlt,
      description,
      formHeading,
      intro,
      itemsQuestions,
      items,
      lineColor,
      outro,
      paragraphHeadings,
      redirectButton,
      redirectContent,
      redirectTitle,
      title,
      trackingEvent,
      type,
      url,
      isMarketingCheckbox,
    } = this.props;

    const { subscribed, isMobile, isTablet } = this.state;

    return (
      <>
        <Row className={'no-gap'}>
          <Col
            md={12}
            lg={7}
            className={`
              asset__main
              ${
                isMarketingCheckbox && subscribed && !isTablet
                  ? 'asset__sidebar--marketing-checkbox'
                  : ''
              }
            `}
          >
            {!subscribed ? (
              <>
                <div className={'asset__inner asset__top'}>
                  {type && <p className={'asset__article-category'}>{type}</p>}
                  {title && (
                    <SectionHeader
                      type={1}
                      size={'large'}
                      additionalClass={'asset__heading'}
                      lineColor={lineColor}
                    >
                      {title}
                    </SectionHeader>
                  )}
                </div>
                <div className={'asset__content asset__inner'}>
                  {cover && !isMobile && (
                    <div className={'asset__image-wrapper'}>
                      <img
                        src={cover}
                        className={'image asset__image'}
                        alt={coverAlt || 'Resource cover'}
                        loading={'lazy'}
                      />
                    </div>
                  )}
                  {coverMobile && isMobile && (
                    <div className={'asset__image-wrapper'}>
                      <img
                        src={coverMobile}
                        className={'image asset__image--mobile'}
                        alt={coverAlt || 'Resource cover'}
                        loading={'lazy'}
                      />
                    </div>
                  )}
                  {isMobile && (
                    <div className={'asset__button--mobile'}>
                      <SpyLink
                        to={'asset__form--mobile'}
                        spy={false}
                        smooth
                        duration={500}
                        offset={-70}
                        className={'link link--centered'}
                        id={'asset__form-spy-button--mobile'}
                      >
                        <Button
                          color={btnColor}
                          id={'asset__form-button--mobile'}
                        >
                          {button}
                        </Button>
                      </SpyLink>
                    </div>
                  )}
                  {intro && (
                    <Paragraph
                      size={'medium'}
                      additionalClass={'text--default asset__description'}
                    >
                      {intro}
                      <br />
                      <br />
                    </Paragraph>
                  )}
                  {description && (
                    <Paragraph
                      size={'medium'}
                      additionalClass={'text--bold asset__description'}
                      nested
                    >
                      {toHtml(description)}
                      <br />
                    </Paragraph>
                  )}
                  {items && Array.isArray(items) && items.length && (
                    <div className={'asset__list-container'}>
                      {paragraphHeadings && (
                        <Paragraph
                          size={'medium'}
                          additionalClass={'text--bold asset__description'}
                        >
                          {paragraphHeadings[0]}
                        </Paragraph>
                      )}
                      <ResourceList {...{ items, itemsQuestions, coverAlt }} />
                    </div>
                  )}
                  {outro && (
                    <>
                      {paragraphHeadings && (
                        <Paragraph
                          size={'medium'}
                          additionalClass={'text--bold asset__description'}
                        >
                          {paragraphHeadings[1]}
                        </Paragraph>
                      )}
                      <Paragraph
                        size={'medium'}
                        additionalClass={'text--default asset__description'}
                      >
                        {outro}
                      </Paragraph>
                    </>
                  )}
                </div>

                <div
                  id={'asset__form--mobile'}
                  className={'asset__sidebar asset__sidebar--mobile'}
                >
                  <div className={'asset__inner'}>
                    <ResourceForm
                      {...{
                        btnColor,
                        button,
                        formHeading,
                        redirectButton,
                        redirectContent,
                        redirectTitle,
                        trackingEvent,
                        type,
                        url,
                        isMarketingCheckbox,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                id={'asset__subscribed'}
                className={
                  'asset__list-container asset__list-container--subscribed'
                }
              >
                <div className={'asset__sidebar asset__sidebar--mobile'}>
                  <div className={'asset__inner'}>
                    <ResourceForm
                      {...{
                        btnColor,
                        button,
                        formHeading,
                        redirectButton,
                        redirectContent,
                        redirectTitle,
                        trackingEvent,
                        type,
                        url,
                        isMarketingCheckbox,
                      }}
                    />
                  </div>
                </div>
                {isMarketingCheckbox ? (
                  <div>
                    <img
                      src={getCloudinaryUrl(
                        'resources/boldare-workbook-downloaded-resource.png',
                        'f_auto,q_auto,w_auto',
                      )}
                      alt={'Boldare Workbok'}
                      className={'image asset__img-marketing-checkbox'}
                      loading={'lazy'}
                    />
                  </div>
                ) : (
                  <SignUpForm />
                )}
              </div>
            )}
          </Col>
          <Col
            md={12}
            lg={5}
            className={`
              asset__sidebar asset__sidebar--desktop
              ${
                isMarketingCheckbox && subscribed && !isTablet
                  ? 'asset__sidebar--marketing-checkbox'
                  : ''
              }
            `}
          >
            <StickyBox offsetTop={120} style={{ zIndex: 100 }}>
              <div className={'asset__inner'}>
                <ResourceForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    redirectButton,
                    redirectContent,
                    redirectTitle,
                    trackingEvent,
                    type,
                    url,
                    isMarketingCheckbox,
                  }}
                />
              </div>
            </StickyBox>
          </Col>
        </Row>
      </>
    );
  }
}

AssetContent.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  coverMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  coverAlt: PropTypes.string,
  description: PropTypes.string,
  formHeading: PropTypes.string,
  intro: PropTypes.string,
  items: PropTypes.array,
  itemsQuestions: PropTypes.bool,
  lineColor: PropTypes.string,
  outro: PropTypes.string,
  paragraphHeadings: PropTypes.string,
  redirectButton: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  }),
  redirectContent: PropTypes.string,
  redirectTitle: PropTypes.string,
  title: PropTypes.string,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isMarketingCheckbox: PropTypes.bool,
};

AssetContent.defaultProps = {
  btnColor: '',
  button: '',
  cover: null,
  coverMobile: null,
  coverAlt: '',
  description: '',
  formHeading: '',
  intro: '',
  items: [],
  itemsQuestions: false,
  lineColor: 'yellow',
  outro: '',
  paragraphHeadings: '',
  redirectButton: { path: '', label: '' },
  redirectContent: '',
  redirectTitle: '',
  title: '',
  isMarketingCheckbox: false,
};

export default AssetContent;
