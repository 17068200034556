export const companiesData = [
  {
    description: `Dit zorgt ervoor dat we meer dan 42 geverifieerde reviews van klanten over de hele wereld op Clutch hebben`,
    image: 'img/boldare-awards/clutch.png',
    link: 'https://clutch.co/profile/boldare',
    linkText: 'Lees hier de ervaringen van onze klanten',
    rate: true,
  },
  {
    description: `Een van Europa’s snelst groeiende bedrijven in 2018 voor XSolve (en van de bedrijven die aan de basis van Boldare stonden)`,
    image: 'img/boldare-awards/500inc.png',
    rate: false,
  },
];
