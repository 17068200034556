import BOX_TYPE from 'config/constants/boxTypes';
import { Industry, Service, ProductStage, Region } from './enums.js';

export const REVIEWS = [
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Kaspien',
      industry: [Industry.ECommerce],
      service: [Service.PDD],
      subtitle:
        'What is amazing about Boldare is the combination of their experience, expertise, and amazing personalities.',
      description: 'Sean Jones, Director of Engineering, Kaspien, Inc.',
      productStage: [ProductStage.Scaling],
      region: [Region.NorthAmerica],
    },
    settings: {
      link:
        'https://clutch.co/profile/boldare?service_provided=E-Commerce+Development#review-1932299',
    },
    id: 'box-review-kaspien',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '4.5',
      title: 'Tauron Polska Energia',
      industry: [Industry.Energy],
      service: [Service.PDD],
      subtitle:
        'Boldare has shown themselves to be very flexible in the technologies they use.',
      description:
        'Krzysztof Osinski, Head of Planning & Development, Tauron Polska energia',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/reviews/tauron-polska-energia/',
    },
    id: 'box-review-tauron',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Eres Yachting',
      industry: [Industry.Travel],
      service: [Service.PDD],
      subtitle:
        "The feedback from customers and everyone else we've shown the website to has been that the site is exceptional.",
      description: 'Marketing Manager',
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/reviews/eres-yachting/',
    },
    id: 'box-review-eres',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'EZYcount',
      industry: [Industry.SAAS],
      service: [Service.DevTeam],
      subtitle:
        'Boldare was able to guide us, and they understood quickly what kind of technical challenges we had – sometimes better than our own people.',
      description: 'Marketing Manager',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/reviews/ezycount/',
    },
    id: 'box-review-ezycount',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Virgin Radio Oman',
      industry: [Industry.Media],
      service: [Service.FCPD],
      subtitle:
        'I provided minimal direction about expectations. They took full responsibility for assembling the solution.',
      description: 'Neil de Souza, Project Manager, Sabco Media',
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/reviews/virgin-radio-oman/',
    },
    id: 'box-review-virgin',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '4.5',
      title: 'Holiday Taxis',
      industry: [Industry.Travel],
      service: [Service.PDD],
      subtitle:
        'Boldare can easily complement your own team, increase productivity, and improve missing skill sets. We really enjoyed working with them.',
      description: 'Head of IT, Holiday Taxis',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/reviews/holiday-taxis/',
    },
    id: 'box-review-taxi',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Brass Willow',
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      subtitle:
        'Their productivity, compared to other companies we’ve used as consultants, is about 50% higher.',
      description: 'Michal Fopp, Product Owner, Brass Willow',
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/reviews/brasswillow/',
    },
    id: 'box-review-brasswillow',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Lamden',
      industry: [Industry.ITServices],
      service: [Service.DevTeam],
      subtitle:
        'They put a lot of thought and strategy into their process. The discovery and brainstorming sessions were very helpful.',
      description: 'Stuart Farmer, CEO, Lamden',
      productStage: [ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/reviews/lamden/',
    },
    id: 'box-review-lamden',
  },
  {
    type: BOX_TYPE.REVIEW,
    content: {
      rating: '5.0',
      title: 'Numera Lighting',
      industry: [Industry.ECommerce],
      service: [Service.DevTeam],
      subtitle:
        'They demonstrated a true desire to understand and support our business objectives, and this translated into the quality of work they produced.',
      description: 'Marketing Manager',
      productStage: [ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/reviews/numera-lighting/',
    },
    id: 'box-review-numera',
  },
];
