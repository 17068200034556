export const content = {
  prototype: {
    header: 'Prototype',
    description:
      'A prototype will help you decide if the product idea is worth pursuing. With a digital prototype, you can focus on validating business hypotheses with potential customers by presenting an early concept for products that may not yet exist.',
    table: [
      {
        heading: 'Timeline',
        value: '2-3 weeks',
      },
      {
        heading: 'Budget',
        value: '10.500-15.750 €',
      },
      {
        heading: 'Team size',
        value: '2-3 team members',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=GAQJWZcwRrg',
  },
  mvp: {
    header: 'Minimum Viable Product',
    description:
      "An MVP is a version of a digital product with just enough functionality for testing and feedback, informing its future development. The Minimum Viable Product enables companies to collect data directly from real users, addressing the question: 'Is this concept investment-worthy?",
    table: [
      {
        heading: 'Timeline',
        value: '4-6 weeks',
      },
      {
        heading: 'Budget',
        value: '42.000-63.000 €',
      },
      {
        heading: 'Team size',
        value: '3-5 team members',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=qZP2cZPP6DQ',
  },
  pmf: {
    header: 'Product-Market Fit',
    description:
      "Product-market fit emphasizes product improvements and business model optimization. The team's primary goal is to align the product with users' needs and preferences. It marks a crucial phase in product development, where the product successfully meets its target users' requirements, resulting in enhanced satisfaction, loyalty, and growth.",
    table: [
      {
        heading: 'Timeline',
        value: 'Usually 3 months divided into monthly releases',
      },
      {
        heading: 'Budget',
        value: '45.000 € / 1 release',
      },
      {
        heading: 'Team size',
        value: '3-5 team members',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=PuulJs_0Fuk',
  },
  scaling: {
    header: 'Scaling',
    description: "Scaling is the phase of a digital product’s growth initiated after achieving suitable product-market fit and validating the business model. It involves expanding operations, customer base, and market presence efficiently to capitalize on proven demand and drive accelerated growth.",
    table: [
      {
        heading: 'Timeline',
        value: 'Usually quarterly based releases',
      },
      {
        heading: 'Budget',
        value: '135.000 € / 1 release',
      },
      {
        heading: 'Team size',
        value: '3-5 team members',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=BaJidXSWv2U',
  },
};
