import React from 'react';
import PropTypes from 'prop-types';

import { filters } from 'views/work/subcomponents/CaseStudies/utils';

import HashTag from './HashTag';
import './HashTags.scss';

const HashTags = ({ tags, onClick, rootUrl }) => {
  return (
    <ul className={'work-boxes-tags'}>
      {filters.map(filter => {
        const index = filter.replace(/\s/g, '');
        return tags[index].map(tag => {
          const shouldRender = tag && tag.length !== 0;
          return (
            shouldRender && (
              <HashTag
                key={tag}
                type={index}
                text={tag}
                onClick={onClick}
                rootUrl={rootUrl}
              />
            )
          );
        });
      })}
    </ul>
  );
};

HashTags.defaultProps = {
  rootUrl: '',
};

HashTags.propTypes = {
  tags: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  rootUrl: PropTypes.string,
};

export default HashTags;
