import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import './FakeVideoPlayer.scss';

const FakeVideoPlayer = props => {
  const { handleClick, children } = props;

  return (
    <div className={'fake-video-player'}>
      {children}
      <div className={'fake-video-player__container'}>
        <Button
          transparent
          color={'white'}
          onClick={handleClick}
          ariaName={'Play button'}
        >
          <span className={'fake-video-player__play-icon'} />
        </Button>
      </div>
    </div>
  );
};

FakeVideoPlayer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(FakeVideoPlayer);
