import React from 'react';
import { Heading, Link, Paragraph, IconSVG } from 'components';
import './BoxProfile.scss';
import { classNames } from 'utils';

const text = {
  meeting: {
    en: 'Schedule a meeting',
    nl: 'Schedule a meeting',
  },
  connect: {
    en: 'Connect with me',
    nl: 'Neem contact op',
  },
};

const BoxProfile = ({
  backgroundColor,
  calendly,
  textColor,
  photo,
  name,
  job,
  facebook,
  quora,
  linkedin,
  twitter,
  email,
  phone,
  flag,
  additionalClass,
  lang,
}) => (
  <div className={classNames('box-profile', additionalClass)}>
    <div className={'box-profile__top'}>
      <img
        style={{
          objectPosition: 'top center',
        }}
        src={photo}
        className={'image'}
        alt={`${name}`}
        loading={'lazy'}
      />
    </div>
    <div
      className={classNames('box-profile__bottom', {
        [`box-profile__bottom--${backgroundColor}`]: backgroundColor,
      })}
    >
      <div className={'box-profile__name-wrapper'}>
        <Heading type={3} color={textColor} size={'medium'}>
          {name}
        </Heading>
        <Paragraph color={textColor} additionalClass={'text--default'}>
          {job}
        </Paragraph>
      </div>
      {(phone ||
        facebook ||
        linkedin ||
        twitter ||
        quora ||
        email ||
        calendly) && (
        <div className={'box-profile__bottom-wrapper'}>
          <div
            className={classNames('box-profile__bottom__line', {
              [`box-profile__bottom__line--${textColor}`]: textColor,
            })}
          />
          {phone && flag && (
            <div className={'box-profile__bottom-phone'}>
              <Paragraph color={textColor} size={'small'}>
                <img src={flag} className={'mini-flag'} loading={'lazy'} />
                {phone}
              </Paragraph>
            </div>
          )}
          <div
            className={classNames('box-profile__connect', phone ?? 'space')}
          >
            {calendly ? (
              <Paragraph color={textColor} additionalClass={'text--bold'}>
                <Link
                  additionalClass={`text--${textColor} calendly-link`}
                  to={calendly}
                >
                  {text.meeting[lang]}
                </Link>
              </Paragraph>
            ) : (
              <Paragraph color={textColor} additionalClass={'text--bold'}>
                {text.connect[lang]}
              </Paragraph>
            )}
            <div className={'box-profile__social-media'}>
              {facebook && (
                <Link
                  to={facebook}
                  additionalClass={'sm-profile facebook-profile'}
                >
                  <IconSVG name={'facebook'} color={textColor} />
                </Link>
              )}
              {linkedin && (
                <Link
                  to={linkedin}
                  additionalClass={'sm-profile linkedin-profile'}
                >
                  <IconSVG name={'contact-linkedin'} color={textColor} />
                </Link>
              )}
              {twitter && (
                <Link
                  to={twitter}
                  additionalClass={'sm-profile twitter-profile'}
                >
                  <IconSVG name={'twitter'} color={textColor} />
                </Link>
              )}
              {quora && (
                <Link to={quora} additionalClass={'sm-profile quora-profile'}>
                  <IconSVG name={'quora'} color={textColor} />
                </Link>
              )}
              {email && (
                <Link to={email} additionalClass={'sm-profile email-profile'}>
                  <IconSVG name={'contact-envelope'} color={textColor} />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

BoxProfile.defaultProps = {
  additionalClass: '',
  backgroundColor: 'red',
  textColor: 'white',
  lang: 'en',
};

export default BoxProfile;
