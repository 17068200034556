import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Divider.scss';

const Divider = ({ size }) => (
  <div
    className={classNames('divider', {
      [`divider--${size}`]: size,
    })}
  />
);

Divider.defaultProps = {
  size: 'regular',
};

Divider.propTypes = {
  size: PropTypes.string,
};

export default Divider;
