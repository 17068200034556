import React from 'react';
import PropType from 'prop-types';
import { Button, Link, Container } from 'components';
import Stamp from 'assets/images/ses/badge-png.png';
import Ribbon from 'assets/images/ses/ribbon-png.png';

import './Banner.scss';

function Banner({ pageName }) {
  return (
    <section className={'ses-banner'}>
      <Container additionalClass={'ses-banner__container'}>
        <div className={'ses-banner__stamp'}>
          <img
            src={Stamp}
            alt={`Service Standards Stamp`}
            className={'ses-banner__stamp-image'}
            loading={'lazy'}
          />
          <img
            src={Ribbon}
            alt={`Service Standards stamp ribbon`}
            className={'ses-banner__stamp-ribbon'}
            loading={'lazy'}
          />
        </div>
        <p className={'ses-banner__content'}>
          Our Service Standards
          <br /> – turning complexity into simplicity
        </p>
        <Link
          to={
            '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/'
          }
          id={`${pageName}__ses-banner-button`}
          className={'ses-banner__button'}
        >
          <Button color={'white'}>Learn more</Button>
        </Link>
      </Container>
    </section>
  );
}

Banner.propTypes = {
  pageName: PropType.string.isRequired,
};

export default Banner;
