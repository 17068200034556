import React from 'react';
import { Button, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './YoutubeSection.scss';
import { pages } from 'config/pages';

const YoutubeSection = () => {
  const { youtubeUrl } = pages.f2f;

  return (
    <section id={'f2f-youtube'} className={'f2f-youtube-section'}>
      <div className={'youtube-section__tile'}>
        <div className={'youtube-section__text-container'}>
          <Heading
            additionalClass={'youtube-section__heading'}
            size={'big'}
            type={3}
          >
            Discover more about us on the #Boldare{' '}
            <span className={'red'}>YouTube channel</span>
          </Heading>
          <Paragraph
            additionalClass={'youtube-section__description'}
            size={'medium'}
            color={'white'}
          >
            Learn more about working and living in a holacratic company. This
            series of talks will help you understand the values that drive us
            every day!
          </Paragraph>
          <a
            className={'link youtube-section__button'}
            id={'ga-f2f--open-youtube'}
            href={youtubeUrl}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Button
              color={'red'}
              borderColor={'white'}
              additionalClass={'ga-f2f--check-our-playlist'}
            >
              Check out our YouTube
            </Button>
          </a>
        </div>
        <img
          className={'youtube-section__image'}
          alt={"Boldare's youtube playlist"}
          src={getCloudinaryUrl(
            'f2f-landing/f2f-landing-yt-cover',
            'f_auto,q_auto,w_533',
          )}
          loading={'lazy'}
        />
      </div>
    </section>
  );
};

export default YoutubeSection;
