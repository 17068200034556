import { BOX_TYPES } from 'config';

export const data = [
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      coverImage: 'img/cs-sonnen.jpg',
      clientName: 'Sonnen',
      title: 'Visualising Energy - Minimum Viable Product (MVP) of web app',
    },
    settings: {
      link: '/work/case-study-sonnen/',
    },
    id: 'box-sonnen',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      coverImage: 'img/Virgin-Radio-Oman-MVP-screen.jpg',
      clientName: 'Virgin Radio Oman',
      title: 'How to build web & mobile Minimum Viable Product (MVP)',
    },
    settings: {
      link: '/work/case-study-virgin-radio-oman/',
    },
    id: 'box-virgin-radio-oman',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      coverImage: 'img/work-polco.jpg',
      clientName: 'Polco',
      title: 'Case study of how we developed voting platform',
    },
    settings: {
      link: '/work/case-study-polco/',
    },
    id: 'box-Polco',
  },
];
