import React, { Component } from 'react';
import Img from 'gatsby-image';
import { IconSVG } from 'components';
import PropTypes from 'prop-types';
import { getCloudinarySrcSet, getImageAlt } from 'config/helpers';
import { classNames } from 'utils';
import './Image.scss';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isImageLoaded: false,
    };
  }

  componentDidMount() {
    // Due to service worker issue, first time images could not trigger onLoad function
    setTimeout(this.triggerEmergencyPlaceholderHide, 3000);
  }

  triggerEmergencyPlaceholderHide = () => {
    this.setState({ isImageLoaded: true });
  };

  handleImageOnLoad = () => {
    this.setState({ isImageLoaded: true });
  };

  renderSimpleImage = () => {
    const {
      src,
      alt,
      additionalClass,
      placeholder,
      innerRef,
      mobileHidden,
    } = this.props;
    const { isImageLoaded } = this.state;

    return !placeholder ? (
      <picture>
        {mobileHidden && (
          <source
            srcSet={
              'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
            }
            media={'(max-width: 768px)'}
          />
        )}
        <img
          src={src}
          className={classNames('image', additionalClass)}
          srcSet={getCloudinarySrcSet(src, 2000)}
          alt={alt || getImageAlt(src)}
          title={alt || getImageAlt(src)}
          ref={innerRef}
          loading={'lazy'}
        />
      </picture>
    ) : (
      <div className={'image__wrapper image--normal'}>
        <img
          src={src}
          className={classNames('image', additionalClass, {
            'image--loaded': isImageLoaded,
          })}
          srcSet={getCloudinarySrcSet(src, 2000)}
          alt={alt || getImageAlt(src)}
          title={alt || getImageAlt(src)}
          onLoad={this.handleImageOnLoad}
          loading={'lazy'}
        />
        {!isImageLoaded && (
          <div className={'image__placeholder'}>
            <IconSVG name={'image-loading'} color={'gray'} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { src, alt, additionalClass, style, type } = this.props;

    return typeof src === 'object' ? (
      <Img
        {...{ [type]: src }}
        imgStyle={
          style ||
          (type === 'fluid'
            ? {
                width: '100%',
                height: '100%',
              }
            : {})
        }
        alt={alt || getImageAlt(src.originalName || src.src)}
        title={alt || getImageAlt(src.originalName || src.src)}
        className={classNames('image', 'image--optimized', additionalClass)}
        outerWrapperClassName={'image__wrapper--optimized'}
      />
    ) : (
      this.renderSimpleImage()
    );
  }
}

Image.defaultProps = {
  additionalClass: '',
  alt: '',
  placeholder: false,
  style: null,
  type: 'fluid',
  innerRef: null,
  mobileHidden: false,
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string,
  placeholder: PropTypes.bool,
  additionalClass: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  innerRef: PropTypes.object,
  mobileHidden: PropTypes.bool,
};

export default Image;
