import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';
import { classNames } from 'utils';
import './Link.scss';

class Link extends Component {
  render() {
    const {
      blank,
      children,
      to,
      additionalClass,
      underline,
      arrow,
      ...other
    } = this.props;
    const isInternal = /^\/(?!\/)/.test(to);

    let isExternal = true;
    if (typeof window !== 'undefined' && window.location) {
      isExternal = new RegExp(
        `^((f|ht)tps?:)?//(?!${window.location.host})`,
      ).test(to);
    }

    if ((to === '/' || isInternal) && !blank) {
      return (
        <GatsbyLink
          className={classNames(
            'link',
            {
              'link--anim-arrow': arrow,
              link__underline: underline,
              [`link__underline--${underline}`]: underline,
            },
            additionalClass,
          )}
          to={to}
          {...other}
        >
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a
        className={classNames(
          'link',
          {
            'link--anim-arrow': arrow,
            link__underline: underline,
            [`link__underline--${underline}`]: underline,
          },
          additionalClass,
        )}
        href={to}
        rel={isExternal ? 'noopener noreferrer' : ''}
        target={isExternal || blank ? '_blank' : '_self'}
        {...other}
      >
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  underline: PropTypes.string,
  arrow: PropTypes.bool,
  blank: PropTypes.bool,
};

Link.defaultProps = {
  additionalClass: '',
  underline: '',
  arrow: false,
  blank: false,
};

export default Link;
