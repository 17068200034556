import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Heading, Paragraph } from 'components';
import { classNames, getYoutubeThumbnailUrl } from 'utils';
import './YouTubePlayer.scss';

class YouTubePlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoStarted: props.playing,
    };
  }

  render() {
    const { isVideoStarted } = this.state;
    const {
      containerClass,
      playerClass,
      src,
      width,
      height,
      description,
      title,
      titleColor,
      light,
      onStart,
      overlayed,
      ...other
    } = this.props;

    return typeof window === 'undefined' ? null : (
      <div
        className={classNames('youtube-player__container', containerClass, {
          overlayed: (overlayed && !isVideoStarted) || overlayed,
        })}
      >
        {title && !isVideoStarted && (
          <div className={'youtube-player__content'}>
            <Heading
              type={3}
              size={'medium'}
              color={titleColor}
              additionalClass={'youtube-player__title'}
            >
              {title}
            </Heading>
            <Paragraph
              size={'small'}
              color={titleColor}
              additionalClass={'youtube-player__description'}
            >
              {description}
            </Paragraph>
          </div>
        )}
        <ReactPlayer
          className={classNames('youtube-player', playerClass)}
          url={src}
          width={width}
          height={height}
          config={{
            youtube: {
              playerVars: { showinfo: 0, modestbranding: 0 },
            },
          }}
          onClick={() => {
            this.setState({ isVideoStarted: true });
            onStart();
          }}
          light={light && getYoutubeThumbnailUrl(src)}
          {...other}
        />
      </div>
    );
  }
}

YouTubePlayer.defaultProps = {
  title: '',
  description: '',
  titleColor: 'white',
  containerClass: '',
  playerClass: '',
  width: '100%',
  height: '100%',
  onStart: () => {},
  overlayed: false,
};

YouTubePlayer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  titleColor: PropTypes.string,
  containerClass: PropTypes.string,
  playerClass: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onStart: PropTypes.func,
  overlayed: PropTypes.bool,
};

export default YouTubePlayer;
