import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { NavBar } from 'components';
import Layout from '../components/layout';
import SingleKeywordPage from '../views/singleKeywordPage';
import { pages } from '../config/pages';

const KeywordPage = ({ data }) => {
  const { markdownRemark: keywordPageData, posts: allArticles } = data;

  const page = pages.services;
  const { navColors } = page;

  return (
    <Layout page={page}>
      <NavBar color={navColors} />
      <SingleKeywordPage
        keywordPageData={keywordPageData}
        allArticles={allArticles}
      />
    </Layout>
  );
};

KeywordPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    posts: PropTypes.object,
  }).isRequired,
};

export default KeywordPage;

export const pageQuery = graphql`
  query KeywordByID($id: String!) {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: { frontmatter: { templateKey: { eq: "article-page" } } }
    ) {
      ...markdownFields
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        settings {
          metaTitle
          metaDescription
          slug
        }
        hero {
          title
          description
          cta
          buttonColor
          image
          imageAlt
        }
        experience {
          title
          description
          carouselItems {
            item {
              title
              description
              link
              linkText
              image
              imageAlt
            }
          }
        }
        services {
          title
          description
          servicesItems {
            item
          }
        }
        benefits {
          title
          description
          benefitsItems {
            item {
              title
              description
            }
          }
        }
        technologies {
          title
          description
        }
        related {
          title
        }
        specialists {
          name
          position
          image
          title
          description
          additionalInfo
        }
      }
    }
  }
`;
