import React, { Component } from 'react';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import ContactDe from 'views/contactDe';
import config from '../../meta/config';

class ContactDePage extends Component {
  render() {
    const page = pages.contactDe;
    const { navColors } = page;
    const url = `${config.siteUrl}/kontakt/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <PopupRedirect message={'kontakt'} />
        <ContactDe />
      </Layout>
    );
  }
}

export default ContactDePage;
