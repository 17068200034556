import React, { useEffect, useState } from 'react';
import LogoWhite from 'assets/images/brand/logo-boldare-products.svg';
import { IconSVG } from 'components';
import { classNames } from 'utils';
import { pages } from 'config/pages';
import './NavBarProducts.scss';

const NavBarProducts = () => {
  const [isNavBlack, setIsNavBlack] = useState(false);
  const { instagramUrl, facebookUrl, linkedinUrl } = pages.products;

  useEffect(() => {
    const handleScroll = () => {
      const scrollOffset = 600;
      if (window.scrollY > scrollOffset) {
        setIsNavBlack(true);
      } else {
        setIsNavBlack(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);

  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `products-nav-${item}`,
      });
    }
  };

  return (
    <>
      <div
        className={classNames(`navbar-products navbar-products--main`, {
          'navbar-products--scroll': isNavBlack,
        })}
      >
        <header className={'navbar-products__brand w-container'}>
          <div className={'navbar-products__logos '}>
            <a
              href={'/'}
              className={'navbar-products__logo-container'}
              id={'navbar-logo'}
            >
              <img
                src={LogoWhite}
                className={'image navbar-products__logo--img hide-mobile'}
                alt={'Digital product design company Boldare'}
                loading={'lazy'}
              />
            </a>
          </div>
          <nav
            id={'navMenu'}
            className={classNames('navbar-products__menu')}
            aria-label={'main navigation'}
          >
            <ul className={'navbar-products__menu-ul'}>
              <li className={classNames('navbar-products__menu-item')}>
                <a
                  href={facebookUrl}
                  id={'footer-facebook'}
                  rel={'noreferrer'}
                  target={'_blank'}
                  onClick={() => {
                    handleClick('facebook');
                  }}
                >
                  <IconSVG name={'fb-light'} size={'medium'} color={'black'} />
                </a>
                <a
                  href={instagramUrl}
                  id={'footer-instagram'}
                  rel={'noreferrer'}
                  target={'_blank'}
                  onClick={() => {
                    handleClick('instagram');
                  }}
                >
                  <IconSVG name={'ig-light'} size={'medium'} color={'black'} />
                </a>
                <a
                  href={linkedinUrl}
                  id={'footer-linkedin'}
                  rel={'noreferrer'}
                  target={'_blank'}
                  onClick={() => {
                    handleClick('linkedin');
                  }}
                >
                  <IconSVG name={'li-light'} size={'medium'} color={'black'} />
                </a>
              </li>
              <a
                href={'/career/'}
                className={
                  'products-btn products-btn--primary products-btn--yellow'
                }
                id={''}
                rel={'noreferrer'}
                target={'_blank'}
                onClick={() => {
                  handleClick('career');
                }}
              >
                Go to career
              </a>
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
};

export default NavBarProducts;
