import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import { graphql } from 'gatsby';
import RescueFailingProjectView from 'views/rescueFailingProject';

import config from '../../meta/config';

const RescueFailingProject = ({ data }) => {
  const page = pages['rescue-failing-project'];
  const { navColors } = page;
  const canonical = `${config.siteUrl}/rescue-failing-project/`;

  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <RescueFailingProjectView allArticles={allArticles} />
    </Layout>
  );
};

RescueFailingProject.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query RescueFailingProjectQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          settings: {
            slug: {
              in: [
                "case-study-predictive-maintenance"
                "case-study-sonnen-digital-transformation"
                "developing-gas-capacity-management-platform-case-study"
              ]
            }
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default RescueFailingProject;
