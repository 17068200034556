import Components from './Components';
import Contact from './Contact';
import Description from './Description';
import Intro from './Intro';
import Organism from './Organism';
import Services from './Services';
import Solutions from './Solutions';
import Team from './Team';
import Technologies from './Technologies';
import Work from './Work';

export {
  Components,
  Contact,
  Description,
  Intro,
  Organism,
  Services,
  Solutions,
  Team,
  Technologies,
  Work,
};
