import React from 'react';
import { Banner } from 'views/digitalTransformationJourney/subcomponents/Banner';
import { WorkForPartners } from 'views/digitalTransformationJourney/subcomponents/WorkForPartners';
import { Vision2030 } from 'views/digitalTransformationJourney/subcomponents/Vision2030';
import { ProjectCostCalculator } from 'views/digitalTransformationJourney/subcomponents/ProjectCostCalculator';
import { KsaContactForm } from 'views/digitalTransformationJourney/subcomponents/KsaContactForm';
import { Services } from './subcomponents/Services';

import './DigitalTransformationJourne.scss';

export const DigitalTransformationJourney = ({ allArticles, authors }) => {
  return (
    <div
      id={'digital-transform-journey'}
      className={`
        single-page single-page--hero
        digital-transformation-journey__container
      `}
    >
      <Banner />
      <Vision2030 />
      <Services />
      <WorkForPartners authors={authors} allArticles={allArticles} />
      <KsaContactForm />
      <ProjectCostCalculator />
    </div>
  );
};
