export const data = [
  {
    image: 'career/instagram/career-insta-1',
    link: 'https://www.instagram.com/p/CdLED66oJGJ/',
  },
  {
    image: 'career/instagram/career-insta-2',
    link: 'https://www.instagram.com/p/CaCaZJ4oaZh/',
  },
  {
    image: 'career/instagram/career-insta-3',
    link: 'https://www.instagram.com/p/Ca67s8Fu2yt/',
  },
  {
    image: 'career/instagram/career-insta-4',
    link: 'https://www.instagram.com/p/CcU6hihukgC/',
  },
  {
    image: 'career/instagram/career-insta-5',
    link: 'https://www.instagram.com/p/Cedv7NHo6P6/',
  },
  {
    image: 'career/instagram/career-insta-6',
    link: 'https://www.instagram.com/p/CdIgeyhIC9w/',
  },
  {
    image: 'career/instagram/career-insta-7',
    link: 'https://www.instagram.com/p/CckvWYpIgu0/',
  },
  {
    image: 'career/instagram/career-insta-8',
    link: 'https://www.instagram.com/p/CeLvKbzoABw/',
  },
  {
    image: 'career/instagram/career-insta-9',
    link: 'https://www.instagram.com/p/CZrLc5doeD9/',
  },
  {
    image: 'career/instagram/career-insta-10',
    link: 'https://www.instagram.com/p/CaE4XQBIRPo/',
  },
];
