import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import { SliderNav } from 'views/remoteWork/components';
import Glide from '@glidejs/glide';
import './DesktopSlider.scss';
import { getCloudinaryUrl } from 'config/helpers';
import classnames from 'classnames';

const DesktopSlider = ({ items, id }) => {
  useEffect(() => {
    const options = {
      type: 'carousel',
      animationDuration: 700,
      animationTimingFunc: 'ease-out',
      classes: {
        activeNav: 'slider-nav__bullet--active',
      },
      startAt: 0,
      focusAt: 0,
    };
    const slider = new Glide(`#${id}`, options);
    slider.mount();

    return () => {
      slider.destroy();
    };
  }, []);

  return (
    <div className={classnames('glide', 'desktop-slider')} id={id}>
      <div className={'glide__track'} data-glide-el={'track'}>
        <ul className={'glide__slides'}>
          {items.map((item, index) => (
            <li
              className={classnames('glide__slide', 'desktop-slider__slide')}
              id={`glide__slide-${index}`}
            >
              <div className={'desktop-slider__image-container'}>
                <img
                  className={'desktop-slider__image'}
                  alt={item.name}
                  src={getCloudinaryUrl(
                    item.cloudinaryDir,
                    'f_auto,q_auto,w_265',
                  )}
                  loading={'lazy'}
                />
              </div>
              <div className={'desktop-slider__text-container'}>
                <Paragraph additionalClass={'text--bold'} size={'medium-plus'}>
                  {item.quote}
                </Paragraph>
                <Paragraph
                  additionalClass={'desktop-slider__name text--bold'}
                  size={'medium-constant'}
                >
                  {item.name}
                </Paragraph>
                <Paragraph
                  additionalClass={'desktop-slider__position'}
                  size={'small-plus'}
                >
                  <span>{item.position}</span>
                </Paragraph>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <SliderNav additionalClass={'desktop-slider__nav'} items={items} />
    </div>
  );
};

DesktopSlider.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
      cloudinaryDir: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DesktopSlider;
