import React, { Component } from 'react';
import {
  Section,
  Paragraph,
  Heading,
  OldCaseStudyButtons,
  OldCaseStudyTile,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Intro.scss';

class Intro extends Component {
  renderList = () =>
    data.map(item => (
      <li className={'corel__intro-list-item'}>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          {item}
        </Paragraph>
      </li>
    ));

  render() {
    const preview = getCloudinaryUrl(
      'assets/corel/corel_rwd.jpg',
      'f_auto,q_auto',
    );

    return (
      <Section additionalClass={'corel__intro-container'}>
        <OldCaseStudyTile
          additionalClass={'corel'}
          image={getCloudinaryUrl('logos/corel_kolor.png', 'f_auto,q_auto')}
          subtitle={'Reesponsive interface re-design'}
          title={'Corel Discovery Center'}
          description={
            'Interface Design, Architecture, Wireframes, Graphic Design, Content Management'
          }
          alternative
        />
        <div className={'corel__content'}>
          <Heading type={1} size={'big'}>
            Discover new ideas with Corel
          </Heading>
          <Paragraph
            additionalClass={'text--light corel__intro-paragraph'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            A brand new idea, UI &amp; UX design of the Discovery Centre –
            Corel.com section dedicated to knowledge hunters. This project is
            part of an overall strategy for rebuilding the Corel.com website to
            enhance its usability and improve conversion. We supported our
            client in the modelling of a new brand idea, with emphasis on
            creating an online space for knowledge exchange by the active
            community. We decided to expose an extremely wide range of tools,
            tutorials, articles, and hints which, in an earlier version of the
            UI, were largely invisible. Information architecture re-building
            features an exceptionally broad base of knowledge and, above all, a
            changing user experience.
          </Paragraph>
          <ol className={'corel__intro-list'}>{this.renderList()}</ol>
          <OldCaseStudyButtons additionalClass={'corel'} cover={preview} />
        </div>
      </Section>
    );
  }
}
export default Intro;
