import { useEffect, useState } from 'react';

export const useUserGeolocation = () => {
  const [userCountryName, setUserCountryName] = useState(undefined);
  const [userContinent, setUserContinent] = useState(undefined);

  useEffect(() => {
    const fetchGeolocation = async () => {
      const response = await fetch('/.netlify/functions/detect-user-location');
      const data = await response.json();
      setUserCountryName(data?.location?.countryName ?? null);
      setUserContinent(data?.location?.continent ?? null);
    };

    fetchGeolocation();
  }, []);

  return {userCountryName, userContinent};
};
