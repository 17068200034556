import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import AppCostsCalculatorThankYou from 'views/appCostsCalculatorThankYou';
import config from '../../../meta/config';

const page = pages.resources.items['app-costs-calculator-thank-you'];
const canonical = `${config.siteUrl}/resources/app-costs-calculator-thank-you/`;

const AppCostsCalculatorThankYouPage = () => {
  const { navColors } = page;

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <AppCostsCalculatorThankYou />
    </Layout>
  );
};

export default AppCostsCalculatorThankYouPage;
