import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Heading.scss';

const Heading = ({
  additionalClass,
  afterline,
  children,
  decorator,
  type,
  color,
  size,
  lineColor,
  bgColor,
  fontWeight,
}) => {
  const HeadingTag = `h${type}`;

  return (
    <HeadingTag
      className={classNames(
        'heading',
        {
          [`heading--${size}`]: size,
          [`heading--font-${fontWeight}`]: fontWeight,
          [`heading__${decorator}`]: decorator,
          [`heading__fill--${bgColor}`]: bgColor,
          [`text--${color}`]: color,
          [`heading__headline heading__headline--${lineColor}`]: lineColor,
          [`heading__afterline heading__afterline--${color}`]: afterline,
        },
        additionalClass,
      )}
    >
      {children}
    </HeadingTag>
  );
};

Heading.defaultProps = {
  additionalClass: '',
  afterline: false,
  lineColor: '',
  decorator: '',
  size: 'default',
  color: '',
  type: 3,
  bgColor: '',
  fontWeight: '',
};

Heading.propTypes = {
  additionalClass: PropTypes.string,
  afterline: PropTypes.bool,
  lineColor: PropTypes.string,
  decorator: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bgColor: PropTypes.string,
  fontWeight: PropTypes.string,
};

export default Heading;
