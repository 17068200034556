import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';

import { EventForm } from 'components';

import {
  EventDescription,
  EventCover,
  BottomBar,
  BottomLogos,
} from './subcomponents';

import './EventRegistrationPage.scss';

const EventRegistrationPage = ({ url, button, eventData, formHeading }) => {
  return (
    <div className={`event__background--${eventData.pageColor}`}>
      <EventCover cover={eventData.cover} />
      <div className={'event-registration'}>
        <div className={'event-registration__description'}>
          <EventDescription eventData={eventData} />
        </div>
        <div>
          <div className={'event-registration__container'}>
            <div className={'event-registration__container--form'}>
              <EventForm
                {...{
                  url,
                  button: button.text?.toUpperCase(),
                  btnColor: button.color,
                  formHeading,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomLogos />
      {eventData.customTemplate && (
        <>
          <BottomBar customSectionLogos={eventData.customSectionLogos} />
          <img
            src={getCloudinaryUrl('img/K11.png', 'f_auto,q_auto')}
            alt={'Spaceman'}
            loading={'lazy'}
            className={'event__background--image'}
          />
        </>
      )}
    </div>
  );
};

export default EventRegistrationPage;
