import React from 'react';
import { Section, SectionHeader, Paragraph, Button } from 'components';
import { classNames } from 'utils';
import withSubscribe from './HOC';
import './Subscribe.scss';

const Subscribe = ({ subscribed, isLoading, refForm, handleSubmit }) => (
  <Section color={'yellow'} additionalClass={'mvp__subscribe'}>
    <div>
      <SectionHeader
        size={'medium'}
        headingType={2}
        lineColor={'white'}
        description={
          'Anna Jaskiewicz, our Business Developer, will be back to you within next 24 hours'
        }
        additionalClass={'section-header__description--medium'}
      >
        Need help with your MVP idea?
      </SectionHeader>
      {subscribed ? (
        <Paragraph size={'medium'} additionalClass={'text--bold'}>
          Thank you for contacting us. Our consultant will back to you within
          24 hours.
        </Paragraph>
      ) : (
        <form
          className={'newsletter-small__form'}
          onSubmit={handleSubmit}
          ref={refForm}
        >
          <input
            type={'email'}
            name={'email'}
            placeholder={'E-mail address'}
            required
          />
          <Button
            id={`mvp-subscribe`}
            additionalClass={classNames('mvp-submit-small', {
              loading: isLoading,
            })}
            type={'submit'}
            color={'yellow'}
            ariaName={'Subscribe button'}
            disabled={isLoading}
            loading
          >
            Subscribe
          </Button>
        </form>
      )}
    </div>
  </Section>
);

export default withSubscribe(Subscribe);
