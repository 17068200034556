import Banner from './Banner';
import DevTeam from './DevTeam';
import OurStructure from './OurStructure';
import Solution from './Solution';
import OtherServices from './OtherServices';
import Technologies from './Technologies';
import Outsourcing from './Outsourcing';
import Articles from './Articles';
import Work from './Work';
import Clutch from './Clutch';
import LogoSlider from './LogoSlider';

export {
  Banner,
  DevTeam,
  OurStructure,
  Solution,
  OtherServices,
  Technologies,
  Outsourcing,
  Articles,
  Work,
  Clutch,
  LogoSlider,
};
