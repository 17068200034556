import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { classNames } from 'utils';
import './VideoPlayer.scss';

class VideoPlayer extends Component {
  render() {
    const {
      containerClass,
      playerClass,
      src,
      width,
      height,
      ...other
    } = this.props;

    return typeof window === 'undefined' ? null : (
      <div className={classNames('video-player__container', containerClass)}>
        <ReactPlayer
          className={classNames('video-player', playerClass)}
          url={src}
          width={width}
          height={height}
          {...other}
        />
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  containerClass: '',
  playerClass: '',
  width: '100%',
  height: '100%',
};

VideoPlayer.propTypes = {
  containerClass: PropTypes.string,
  playerClass: PropTypes.string,
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape()),
  ]).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default VideoPlayer;
