export const sliderData = [
  {
    background: 'homepage/about-us/mobiles.png',
    content: 'Why UX and UI design matters to us',
    description:
      'In a world flooded with poorly designed products that do the bare minimum, a human-centred product stands out.',
    color: 'violet',
    link: '/work/why-design-matters/',
  },
  {
    background: 'homepage/about-us/about-us-founders.png',
    content: 'When two become one',
    description:
      'Boldare is the result of a natural merge of two sister companies XSolve and Chilid',
    color: 'yellow',
    link: '/blog/the-story-of-boldare/',
  },
  {
    background: 'homepage/about-us/boldare-wireframe-mock.png',
    content: 'How to test a business idea',
    description:
      'Find out how we created Boldare leveraging lean startup thinking',
    color: 'red',
    link: '/work/case-study-boldare/',
  },
];

export const companiesData = [
  {
    description: `More than 42 verified reviews on Clutch from Clients from around the world`,
    image: 'img/boldare-awards/clutch.png',
    link: 'https://clutch.co/profile/boldare',
    linkText: 'Read reviews from our Clients',
    rate: true,
  },
  {
    description: `Europe’s Fastest-Growing Company 2018 for XSolve (one of Boldare’s founding companies)`,
    image: 'img/boldare-awards/500inc.png',
    rate: false,
  },
];
