import React from 'react';
import { graphql } from 'gatsby';
import Products from 'views/products';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const ProductsPage = data => {
  const page = pages.products;
  const url = `${config.siteUrl}/products/`;
  const canonical = `${url}`;
  const { edges: offers } = data.data.allMarkdownRemark || {};

  return (
    <Layout {...{ page, canonical }}>
      <Products offers={offers} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProductsPage {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        frontmatter: {
          templateKey: { eq: "job-offer-page" }
          mainContent: { activeOffer: { eq: true } }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
      edges {
        node {
          frontmatter {
            mainContent {
              activeOffer
              name
              cities
              contractType {
                contract
                salaries {
                  level
                  min
                  max
                }
              }
              apply
              banner
              technical {
                skill
              }
              additional {
                skill
              }
              specifics {
                specific
              }
              photo
              steps {
                number
                step
                details
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductsPage;
