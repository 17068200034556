import React from 'react';

import './DiscussionEventMobileLogos.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { IconSVG } from 'components';
import { Link } from 'gatsby';

const DiscussionEventMobileLogos = () => {
  return (
    <div className={'discussion-event-mobile-logos-container'}>
      <Link to={'https://www.boldare.com/'} target={'_blank'}>
        <img
          src={getCloudinaryUrl('logos/boldare', 'f_auto,q_auto')}
          className={'logos-boldare'}
          loading={'lazy'}
          alt={'Boldare'}
        />
      </Link>
      <IconSVG name={'close'} size={'large'} color={'black'} />
      <Link to={'https://www.takamol.com.sa/'} target={'_blank'}>
        <img
          src={getCloudinaryUrl(
            'discussion-event/takamol-logo.png',
            'f_auto,q_auto',
          )}
          className={'logos-takamol'}
          loading={'lazy'}
          alt={'Takamol'}
        />
      </Link>
    </div>
  );
};

export default DiscussionEventMobileLogos;
