import React, { Component } from 'react';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import IconSVG from 'components/IconSVG';
import { data } from './logic';
import './Experts.scss';

class Experts extends Component {
  renderExpertList = () => {
    return data.map(expert => (
      <li className={'nn__experts-author'}>
        <a href={expert.linkedin} rel={'nofollow'}>
          <div className={'nn__experts-author-img'}>
            <img
              src={getCloudinaryUrl(expert.image, 'f_auto,q_auto,w_390')}
              alt={expert.name}
              className={'image'}
              loading={'lazy'}
            />
          </div>
          <div className={'nn__experts-author-content'}>
            <div className={'nn__experts-author-content--main'}>
              <span className={'nn__experts-author-name'}>{expert.name}</span>
              <IconSVG
                name={'linkedin-letter'}
                size={'medium'}
                color={'white'}
              />
            </div>
            <span className={'nn__experts-author-description'}>
              {expert.description}
            </span>
          </div>
        </a>
      </li>
    ));
  };

  render() {
    return (
      <Section color={'gray-light'} additionalClass={'nn__experts'}>
        <SectionHeader size={'big'} lineColor={'red'} fixed>
          Let’s talk about New Normal
        </SectionHeader>
        <ul className={'nn__experts-authors'}>
          {this.renderExpertList(data)}
        </ul>
      </Section>
    );
  }
}

export default Experts;
