import React from 'react';
import { Heading } from 'components';
import BldrAcronym from 'assets/images/brand/logo-acronym-bldr.svg';
import './IntroSection.scss';

const IntroSection = () => {
  return (
    <section className={'products-intro section-box'} id={'intro-section'}>
      <div className={'padding__container'}>
        <Heading
          additionalClass={'intro-heading'}
          size={'big'}
          color={'mine-shaft'}
        >
          At Boldare, <span>we think about people</span> and the values they
          get from our products. When it comes to cooperating with clients,
          picking out the best solutions and{' '}
          <span>delivering effective digital products,</span> we always keep
          that value in mind. Welcome to Boldare Products - here we’re going to
          explain our approach to society and the world.
        </Heading>
      </div>
      <div className={'box'} />
      <div className={'additional-planet__container'}>
        <span
          className={'additional-planet additional-planet--large hide-mobile'}
        />
        <span
          className={'additional-planet additional-planet--small hide-mobile'}
        />
        <img className={'image'} src={BldrAcronym} alt={'Boldare'} />
      </div>
    </section>
  );
};

export default IntroSection;
