import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Overlay } from 'components';

import './PopupWindow.scss';

const PopupWindow = ({ children, additionalClass, onClose }) => (
  <Overlay additionalClass={'popup-window-overlay'}>
    <div className={classNames('popup-window', additionalClass)}>
      <>
        <button
          type={'button'}
          className={'popup-window__close'}
          onClick={onClose}
          aria-label={'Close Popup'}
        >
          &#10005;
        </button>
        {children}
      </>
    </div>
  </Overlay>
);

PopupWindow.propTypes = {
  children: PropTypes.node,
  additionalClass: PropTypes.string,
  onClose: PropTypes.func,
};

PopupWindow.defaultProps = {
  children: null,
  additionalClass: null,
  onClose: null,
};

export default PopupWindow;
