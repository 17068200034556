import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { IconSVG } from 'components';

import './SingleStat.scss';

const SingleStat = ({ rotation, decoration, isOdd, text, link, isTablet }) => {
  const boxVariants = isTablet
    ? {
        opacity: 0,
        y: `50px`,
      }
    : { opacity: 1, y: '0' };

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <motion.li
      className={'prizes-stat'}
      ref={ref}
      animate={inView ? { opacity: 1, y: '0' } : boxVariants}
      transition={{ duration: 0.4, delay: isOdd ? 0 : 0.2 }}
    >
      <div
        style={{ transform: `rotate(${rotation})` }}
        className={'prizes-stat__decoration'}
      >
        {decoration}
      </div>
      <div className={'prizes-stat__content'}>
        <p className={'prizes-stat__text'}>{text}</p>
        {link && (
          <a href={link.href} rel={'nofollow'} className={'prizes-stat__link'}>
            <span className={'prizes-stat__span'}> {link.text}</span>
            <div className={'prizes-stat__icon'}>
              <IconSVG
                name={'arrow-right'}
                color={'mine-shaft'}
                size={'small'}
              />
            </div>
          </a>
        )}
      </div>
    </motion.li>
  );
};

SingleStat.defaultProps = {
  link: null,
};

SingleStat.propTypes = {
  rotation: PropTypes.string.isRequired,
  decoration: PropTypes.string.isRequired,
  isOdd: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  isTablet: PropTypes.bool.isRequired,
  link: PropTypes.object,
};

export default SingleStat;
