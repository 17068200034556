import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';
import { data } from './logic.js';
import companyData from '../../../../../meta/companyData';

const AcrossIndustries = ({ allArticles }) => (
  <>
    <Section color={'mine-shaft'}>
      <SectionHeader
        size={'big'}
        color={'white'}
        lineColor={'red'}
        descriptionColor={'white'}
        description={`We have developed over ${companyData.products} products across industries such as automotive, energy, finance, tavel, pharmaceutical, media, healthcare, education, B2B services, beauty, fashion, e-commerce, and many more. Here are some examples of our products:`}
        headingType={2}
      >
        Full Cycle Product Development across industries
      </SectionHeader>
    </Section>
    <Section fullwidth spaceless additionalClass={'fpdc__industries'}>
      {!(isEmpty(allArticles) || isEmpty(data)) && (
        <RelatedArticles
          id={'fpdc__industries'}
          related={data}
          allArticles={allArticles}
          sectionTitle={false}
          background={'transparent'}
          bulletColor={'red'}
          fixed
        />
      )}
    </Section>
  </>
);

export default AcrossIndustries;
