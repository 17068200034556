import React, { Component } from 'react';
import { FullwidthImage, Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import CountUp from 'react-countup';
import inView from 'in-view';
import { data } from './logic.js';
import './Technology.scss';

class Technology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.showbiz__technology-tiles').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  withCounter = item =>
    this.state.enableCounterAnimation && (
      <span>
        <CountUp
          end={item.number}
          delay={0.3}
          separator={' '}
          suffix={item.suffix || null}
        />
      </span>
    );

  renderTiles = () =>
    data.map((item, index) => (
      <div
        className={'showbiz__technology-tile anim-fade-in-bottom'}
        key={`tile--${index}`}
      >
        <Heading type={2} size={'large'}>
          {this.withCounter(item)}
        </Heading>
        <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
          {item.text}
        </Heading>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'showbiz__technology'}>
        <div className={'showbiz__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Technology
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            In continuous integration we ran 145 tests cases that performed 832
            assertions
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            The portal contains part of CMS, where you can edit the content
            visible on the website and edit the blog section. We decided to use
            WordPress and make integration between WP and Symfony with help of
            custom API. Displaying paginated list of application, e.g. a list
            of models, we had to join to query that the model address
            information, attachments, statuses, etc. Component (whiteoctober /
            Pagerfanta) This list was divided into pages and generated
            sub-optimal query in a MySQL database which lasted several tens of
            seconds. We used MariaDB database, which have improved performance
            joints and subqueries.
          </Paragraph>
        </div>
        <div className={'showbiz__technology-images'}>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/showbiz/showbiz_technology_left1.jpg',
              'f_auto,q_auto',
            )}
            alt={'Symfony'}
          />
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/showbiz/showbiz_technology_right1.jpg',
              'f_auto,q_auto',
            )}
            alt={'MariaDB'}
          />
        </div>
        <div className={'showbiz__technology-tiles'}>{this.renderTiles()}</div>
      </Section>
    );
  }
}

export default Technology;
