import React from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

import { classNames, pushToDataLayer } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import {
  CountryFlag,
  CoverImage,
  MainTag,
  Heading,
  Description,
  HashTags,
  Link,
} from 'components/WorkBoxes/subcomponents';

import './CaseStudy.scss';

const CaseStudy = ({
  post,
  isVariantB,
  onFilterSelect,
  navigatePage,
  noScroll,
  rootUrl,
}) => {
  const { type, content, settings } = post;
  const {
    title,
    coverImage,
    country,
    description,
    industry,
    productStage,
    region,
  } = content;
  const image = getCloudinaryUrl(coverImage, 'q_auto,f_auto,h_320');
  const tags = {
    Industry: industry ?? [],
    ProductStage: productStage ?? [],
    Region: region ?? [],
  };

  const flattenDescription =
    typeof description === 'string' ? description : description[0];

  const onClick = (event, label, filter) => {
    pushToDataLayer(`box-case-study-${isVariantB ? 'b' : 'a'}`);
    onFilterSelect && onFilterSelect(event, label, filter, navigatePage);
    !noScroll &&
      scroller.scrollTo('case-studies-section', {
        spy: false,
        smooth: true,
        duration: 500,
        offset: -145,
      });
  };

  return (
    <div className={'work-casestudy-box'}>
      <div className={'work-casestudy-box__container'}>
        <Link href={settings.link} />
        <MainTag type={type} />
        <CoverImage
          image={image?.src ? image.src : image}
          alt={`${title} - ${type} cover`}
          isZoomed={false}
        />
        <div
          className={classNames('work-casestudy-box__content', {
            'work-casestudy-box__content--no-text': isVariantB,
          })}
        >
          <CountryFlag country={country} />
          <Heading text={title} isVariantB={isVariantB} />
          {!isVariantB && <Description text={flattenDescription} />}
          <HashTags tags={tags} onClick={onClick} rootUrl={rootUrl} />
        </div>
      </div>
    </div>
  );
};

CaseStudy.defaultProps = {
  isVariantB: false,
  rootUrl: '',
};

CaseStudy.propTypes = {
  isVariantB: PropTypes.bool,
  post: PropTypes.object.isRequired,
  onFilterSelect: PropTypes.func,
  navigatePage: PropTypes.func,
  rootUrl: PropTypes.string,
};

export default CaseStudy;
