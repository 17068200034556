export default {
  image: '/resources/tablet-prototyping.png',
  title: 'Pro tips and hacks for product owners on topics such as:',
  features: [
    'Collaboration & communication tools',
    'Product leadership skills',
    'Team communication flow',
  ],
  link: '/resources/product-owner-checklist/',
};
