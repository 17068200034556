/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, Section } from 'components';
import sonnenCharger from 'assets/videos/sonnen_charger_moodboard.mp4';
import { Banner, BoxAsset } from './subcomponents';
import './index.scss';

const section1AboutBoldare = [
  {
    title: 'Boldare’s company deck (PPTX)',
    description: '',
    link: '/downloadable/companydeck/Boldare_Company_Deck_2021.pptx',
  },
  {
    title: 'Boldare’s company deck (PDF)',
    description: '',
    link: '/downloadable/companydeck/Boldare_Company_Deck_2021.pdf',
  },
];

const section1CaseStudies = [
  {
    title: 'Agnitio ESB',
    description: (
      <>
        Agnitio is a life science company that provides pharmaceutical
        closed-loop marketing SaaS. In this case study, we describe how our
        team connected the many various apps within the Rainmaker platform
        using the Mule ESB.
        <br />
        <br />
        Technology stack: NodeJS, PHP, Microsoft IIS, SPA, mobile app API, data
        warehouse, Mule ESB
        <br />
        Country: Denmark
        <br />
        Industries: life sciences, health care, pharma, medical, life sciences
        marketing.
        <br />
        <br />
      </>
    ),
    link:
      '/downloadable/casestudies/Agnito - Connecting many applications using ESB.pdf',
  },
  {
    title: 'Agnitio AWS',
    description: (
      <>
        Agnitio is a life science company that provides pharmaceutical
        closed-loop marketing SaaS. In order to provide end users with constant
        and reliable access to the Rainmaker platform, we designed the
        architecture, and implemented, tested, integrated and deployed the AWS
        solution.
        <br />
        <br />
        Technology stack: AWS infrastructure
        <br />
        Country: Denmark
        <br />
        Industries: life sciences, health care, pharma, medical, life sciences
        marketing
        <br />
        <br />
      </>
    ),
    link:
      '/downloadable/casestudies/Agnito - scaling application using AWS infrastructure.pdf',
  },
  {
    title: 'Leaseweb',
    description: (
      <>
        Leaseweb is a global cloud computing and web services company. Here, we
        describe the case of Leaseweb’s CIS (Central Installation System) - one
        of the critical development projects we provided during our six-year
        partnership. It allowed customers to order servers with customizable
        operating systems and setup using an online service.
        <br />
        <br />
        Technology stack: Symfony, SOAP and REST web API, DHCP, PXE, TFTP
        <br />
        Country: The Netherlands
        <br />
        Industries: hosting, cloud
        <br />
        <br />
      </>
    ),
    link: '/downloadable/casestudies/Leaseweb - Automating deployments.pdf',
  },
  {
    title: 'Tauron ESB',
    description: (
      <>
        Tauron is an energy holding company, one of the biggest electricity
        providers in Poland, serving more than 5.5 million customers. In this
        case study, we describe how Boldare designed and implemented an
        enterprise service bus solution that helps monitor the status of the
        electricity network maintained by the company.
        <br />
        <br />
        Technology stack: Mule ESB, Oracle 11g database, Java SE 6, JPA,
        Enterprise JavaBeans 3.1, JDBC, Oracle Spatial, JMS, Spring 3.x, JUnit
        & Arquillian, Apache CXF,
        <br />
        Country: Poland
        <br />
        Industries: energy
        <br />
        <br />
      </>
    ),
    link:
      '/downloadable/casestudies/Tauron - Connecting multiple applications using ESB.pdf',
  },
  {
    title: 'Tauron Trabio',
    description: (
      <>
        Tauron is an energy holding company, one of the biggest electricity
        providers in Poland, serving more than 5.5 million customers. Here, we
        describe how we implemented an outage management system using an ESB
        solution. As a result of our implementation, we helped reduce the
        duration of power outages in households and businesses, covering 6
        million people, and made it easier to efficiently use the data from the
        14 various systems the company has in use.
        <br />
        <br />
        Technology stack: Mule ESB (enterprise service bus), Java, JBoss,
        Hibernate, Spring Framework, Java Persistence API, Oracle Database,
        Oracle Spatial, PrimeFaces (a UI framework for JAVA EE)
        <br />
        Country: Poland
        <br />
        Industries: energy
        <br />
        <br />
      </>
    ),
    link:
      '/downloadable/casestudies/Tauron - TrabiO Outage Management System.pdf',
  },
];

const section1Partnerships = [
  {
    title: 'The Agnitio and Boldare Partnership',
    description: (
      <>
        Agnitio is a life science company that provides pharmaceutical
        closed-loop marketing SaaS. We worked together between 2013 and 2018.
        Up to 15 people were working on the Agnitio Rainmaker platform at the
        project’s peak: a full scrum development team, including iOS and
        Windows developers, PHP and QA engineers, Java and JavaScript
        developers, and a Scrum Master were involved. We helped them with
        scaling and product-market fit efforts.
        <br />
        <br />
        Technology stack: PHP, JavaScript, Node.js, AWS, Objective-C, Java,
        .NET
        <br />
        Country: Denmark
        <br />
        Industries: life sciences, health care, pharma, medical, life sciences
        marketing
        <br />
        <br />
      </>
    ),
    link: '/downloadable/partnerships/The Agnitio and Boldare Partnership.pdf',
  },
  {
    title: 'The BlaBlaCar and Boldare Partnership',
    description: (
      <>
        BlaBlaCar is a unicorn startup based in France, offering a carpooling
        platform. Between 2016 and 2018, we customized their apps for each of
        the 27 countries in which they were operating and built several web
        services for brand and marketing purposes. As a result, the membership
        numbers grew from 25 to 35 million users between 2016 and 2018. We
        helped BlaBlaCar with scaling their business into new markets.
        <br />
        <br />
        Technology stack: PHP 5.5, Symfony 2.7, API, MySQL, Elasticsearch,
        Redis, Logstash, Kibana, Android, iOS.
        <br />
        Country: France
        <br />
        Industries: transport, ride sharing
        <br />
        <br />
      </>
    ),
    link:
      '/downloadable/partnerships/The BlaBlaCar and Boldare Partnership.pdf',
  },
  {
    title: 'The Sonnen and Boldare Partnership',
    description: (
      <>
        Sonnen is an energy industry company. Since 2019, Sonnen has been a
        part of Royal Dutch Shell PLC. Our collaboration started in 2018 and to
        date we have created six digital products for them. Our team members
        involved in the collaboration increased from an initial five people to
        the 49 currently working on the project. We have also helped Sonnen
        with digital transformation, implementing many of their online
        solutions.
        <br />
        <br />
        Technology stack: AWS, GitLab Pipelines, MongoDB, Datadog, Sentry,
        Kubernetes, DynamoDB, Cloud Formation, PostgreSQL, Zipkin, Grafana,
        Prometheus, ELK Stack
        <br />
        Country: Germany
        <br />
        Industries: energy, renewables
        <br />
        <br />
      </>
    ),
    link: '/downloadable/partnerships/The Sonnen and Boldare Partnership.pdf',
  },
];

const section2Prototypes = [
  {
    title: 'Sonnen Charger',
    description: (
      <>
        Client: Sonnen, an energy industry company, Germany
        <br />
        Goal: Charging status plays a key role in the application. Therefore an
        animation was used to help the user quickly check the current status.
        Two animated moodboards were prepared to present ideas for the app’s
        visual side and user experience.
        <br />
        Description: animated moodboard for the application’s look and feel
        (movie)
        <br />
        <br />
      </>
    ),
    link: sonnenCharger,
  },
  {
    title: 'Sonnen Set Up Phase',
    description: (
      <>
        Client: Sonnen, an energy industry company, Germany
        <br />
        Goal: Validate the improved usability and intuitiveness of setup phase
        functionalities.
        <br />
        1. Identify the usability of the new setup phase feature (check the
        user experience, user pain points, look & feel).
        <br />
        2. Identify the intuitiveness of the setup phase.
        <br />
        3. Gather information about users’ expectations, needs, and
        satisfaction.
        <br />
        Hypothesis:
        <br />
        1. We believe that users can distinguish which tasks belong to them and
        which belong to the partner.
        <br />
        2. We believe that users know how to check finished tasks.
        <br />
        3. We believe that users know they have to save checked tasks before
        they change the page.
        <br />
        Description: clickable prototype - user flow (app)
        <br />
        <br />
      </>
    ),
    link: '/landing/assets/prototype-sonnen-cg/index.html',
  },
  {
    title: 'Sonnen Hello Demo Mode',
    description: (
      <>
        Client: Sonnen, an energy industry company, Germany
        <br />
        Goal: Prototype of new functionality – portal demo mode for customers
        without an account. The concept was presented by the PO to other
        company departments.
        <br />
        <br />
      </>
    ),
    link: '/landing/assets/prototype-sonnen/index.html',
  },
  {
    title: 'Inceptua',
    description: (
      <>
        Client: Inceptua, a medical company, Luxembourg
        <br />
        Description: Clickable dashboard for a medical app.
        <br />
        Goal: The prototype was created to demonstrate a form-building feature
        to potential users.
        <br />
        <br />
      </>
    ),
    link: '/landing/assets/prototype-inceptua/index.html',
  },
  {
    title: 'Takamol Access to Information',
    description: (
      <>
        Client: Takamol, government company, The Kingdom of Saudi Arabia
        <br />
        Description: Dashboard combining maps and analytics.
        <br />
        <br />
      </>
    ),
    link: 'https://framer.cloud/HapQj/13/index.html',
  },
  {
    title: 'Windfarm',
    description: (
      <>
        Client: EDF Renewables, renewables sector company, France
        <br />
        Goal: The software should mainly focus on predicting failure and simply
        informing the user of a forthcoming wind turbine malfunction. The
        software prototype not only predicts the breakdown but also
        logistically plans the entire process to prevent the failure from
        happening, increasing savings and cutting downtime
        <br />
        <br />
      </>
    ),
    link: 'https://windfarm.netlify.com/',
  },
];

const Assets = () => {
  return (
    <div className={'bc-landing single-page single-page--hero'}>
      <Banner title={'Explore our assets'} />
      <Section id={'about'} additionalClass={'work__content'}>
        <Heading
          additionalClass={'heading-top'}
          color={'black'}
          size={'large'}
          type={'2'}
        >
          About Boldare
        </Heading>
        {section1AboutBoldare.map(asset => (
          <BoxAsset
            id={asset.title}
            title={`${asset.title}`}
            description={asset.description}
            link={asset.link}
          />
        ))}
      </Section>
      <Section additionalClass={'work__content'}>
        <Heading color={'black'} size={'large'} type={'2'}>
          Our case studies (PDF files)
        </Heading>
        <div id={'scaling'}>
          <Heading color={'black'} size={'big'} type={'3'}>
            Product market-fit, scaling cases:
          </Heading>
          {section1CaseStudies.map(asset => (
            <BoxAsset
              id={asset.title}
              title={`${asset.title}`}
              description={asset.description}
              link={asset.link}
            />
          ))}
        </div>
        <div id={'partnership'}>
          <Heading
            additionalClass={'heading-top'}
            color={'black'}
            size={'big'}
            type={'3'}
          >
            Long-term cooperation, partnership, full cycle product development,
            scaling, scaled scrum, agile transformation
          </Heading>
          {section1Partnerships.map(asset => (
            <BoxAsset
              id={asset.title}
              title={`${asset.title}`}
              description={asset.description}
              link={asset.link}
            />
          ))}
          <Paragraph
            additionalClass={'otherCaseStudies'}
            size={'regular'}
            color={'black'}
          >
            All work of the above is described in our{' '}
            <a className={'csLink'} href={'/work/'} rel={'nofollow'}>
              case study section
            </a>
            .
          </Paragraph>
        </div>
      </Section>
      <Section id={'prototypes'} additionalClass={'work__content'}>
        <Heading color={'black'} size={'large'} type={'2'}>
          Product design & development
        </Heading>
        <Heading color={'black'} size={'big'} type={'3'}>
          Prototyping
        </Heading>
        {section2Prototypes.map(asset => (
          <BoxAsset
            id={asset.title}
            title={`${asset.title}`}
            description={asset.description}
            link={asset.link}
            type={'preview'}
          />
        ))}
      </Section>
    </div>
  );
};

Assets.propTypes = {
  assetsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Assets;
