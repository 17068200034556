export const data = [
  {
    image: '1',
    title: 'Global search',
    subtitle: 'Find everything',
    description:
      'One of the very first solutions was global search that allows user to seek through all of the content from any place on the site.',
  },
  {
    image: '2',
    title: 'Cross-linking',
    subtitle: 'Between 50 sections',
    description:
      'We made a system of connections thanks to which user is never left without further pathway and is also involved in service.',
  },
  {
    image: '3',
    title: 'Local search',
    subtitle: 'in hundreds of services',
    description:
      'Next step was preparation of local search for places catalogue to help users easily find any place they want.',
  },
];
