import React from 'react';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Banner.scss';

const Banner = () => (
  <Section color={'violet'} spaceless>
    <div className={'htw__banner-container'}>
      <div className={'htw__banner-media'}>
        <img
          src={getCloudinaryUrl(
            'gatsby-cloudinary/wind-turbines.png',
            'f_auto,q_auto,w_auto',
          )}
          alt={'Hack the Wind'}
          className={'image htw__banner-placeholder'}
          loading={'lazy'}
        />
      </div>
      <SectionHeader
        description={
          'With the goal of resolving serious problems in the wind energy industry, Boldare’s team of five developers and a product designer took part in a prestigious hackathon. For 48 hours, they worked relentlessly to provide a machine learning-based product to predict wind turbine failures.'
        }
        descriptionColor={'white'}
        size={'large'}
        lineColor={'red'}
        color={'white'}
        additionalClass={'htw__banner-header'}
        fixed
      >
        Boldare Machine Learning Team in the final 3 of Hack the Wind
      </SectionHeader>
    </div>
  </Section>
);

export default Banner;
