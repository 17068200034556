import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import { DigitalTransformationJourney } from 'views/digitalTransformationJourney';
import { graphql } from 'gatsby';
import config from '../../meta/config';

const page = pages['digital-transformation-journey'];
const { navColors } = page;
const url = `${config.siteUrl}/digital-transformation-journey/`;
const canonical = `${url}`;

const DigitalTransformationJourneyPage = ({ data }) => {
  const { posts, authors } = data || {};

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} />
      <DigitalTransformationJourney
        allArticles={posts.edges}
        authors={authors.edges}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query DigitalTransformationJourneyPage {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    authors: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "author-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }
  }
`;

export default DigitalTransformationJourneyPage;
