import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['product-discovery-workshops'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/product-discovery-workshops/`;

const PdwLandingPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'tools'}
        title={'10 tools that help kick-start product development fast'}
        cover={getCloudinaryUrl(
          'app-costs-calculator/pdw-tools.png',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'app-costs-calculator/pdw-tools.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Machine Learning for Business Practical Guide'}
        description={`If you want to see what tools we use to build an environment that can fuel change
          in your business or you are looking for tools to build such an environment on your own,
          here they are - no matter if you are starting a new product or planning a new release.
          \n\nDownload templates of 10 free different tools:          `}
        items={[
          'Product Statement',
          'Strategy Canvas',
          'Product Goals',
          'Business Model Canvas',
          'User Personas',
          'Value Proposition Canvas',
          'User Flow',
          'User Stories',
          'User Story Mapping',
          'Roadmap',
        ]}
        url={url}
        button={'Yes, I want one!'}
        trackingEvent={`ebookSignupMachineLearningForBusiness`}
      />
    </Layout>
  );
};

export default PdwLandingPage;
