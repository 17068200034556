import { Container, YouTubePlayer } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import { content } from '../Phase/logic';

import './PhaseVideo.scss';

export const PhaseVideo = ({ activePhase }) => {
  const textContent = content[activePhase];

  return (
    <div className={'app-cost-result-phase-video__container'}>
      <Container>
        <div className={'app-cost-result-phase-video__player'}>
          <YouTubePlayer
            src={textContent.videoUrl}
            light
            playing
            controls
            title={`${textContent.header} phase video`}
          />
        </div>
      </Container>
    </div>
  );
};

PhaseVideo.propTypes = {
  activePhase: PropTypes.string,
};

PhaseVideo.defaultProps = {
  activePhase: '',
};
