import React from 'react';
import { classNames } from 'utils';
import { Heading } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { TextInteractionAnimation } from 'views/remoteWork/components';
import { pages } from 'config/pages';
import './SocialSection.scss';

const SocialSection = () => {
  const { instagramUrl, facebookUrl } = pages.remoteWork;

  return (
    <section className={'section-social'}>
      <Heading
        size={'big'}
        additionalClass={'section-social__heading'}
        type={2}
      >
        <TextInteractionAnimation text={'Need more? Go social!'} />
      </Heading>
      <div className={'section--social__list'}>
        <a
          href={instagramUrl}
          className={'link text--bold ga-remote-work--check-instagram'}
          rel={'nofollow'}
        >
          <p>
            Instagram <br />
            <span className={'text--light'}>@boldarecom</span>
          </p>
          <img
            src={getCloudinaryUrl(
              'remote-work-landing/boldare-instagram-icon',
              'f_auto,w_53',
            )}
            alt={'Boldare on Instagram'}
            className={classNames('social__icon', 'social__icon--instagram')}
            width={53}
            height={52}
            loading={'lazy'}
          />
          <img
            src={getCloudinaryUrl(
              'remote-work-landing/boldare-instagram-photo',
              'f_auto,w_189',
            )}
            alt={'Boldare on Instagram'}
            className={classNames('social__bg', 'social__bg--instagram')}
            width={189}
            height={337}
            loading={'lazy'}
          />
        </a>
        <a
          href={facebookUrl}
          className={'link text--bold ga-remote-work--check-facebook'}
          rel={'nofollow'}
        >
          <p>
            Facebook <br />
            <span className={'text--light'}>/boldarecom</span>
          </p>
          <img
            src={getCloudinaryUrl(
              'remote-work-landing/boldare-facebook-icon',
              'f_auto,w_28',
            )}
            alt={'Boldare on Facebook'}
            className={classNames('social__icon', 'social__icon--fb')}
            width={28}
            height={49}
            loading={'lazy'}
          />
          <img
            src={getCloudinaryUrl(
              'remote-work-landing/boldare-facebook-background',
              'f_auto,w_364',
            )}
            alt={'Boldare on Facebook'}
            className={classNames('social__bg', 'social__bg--fb')}
            width={364}
            height={337}
            loading={'lazy'}
          />
        </a>
      </div>
    </section>
  );
};

export default SocialSection;
