import React from 'react';
import { graphql } from 'gatsby';
import DevelopmentTeams from 'views/developmentTeams';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['development-teams'];
const { navColors } = page;
const url = `${config.siteUrl}/services/development-teams/`;
const canonical = `${url}`;

const DTPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <DevelopmentTeams allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query DTQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Visualising Energy"
              "How to scale a monolithic MVP application without losing business?"
              "From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default DTPage;
