import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Paragraph, SliderArrow } from 'components';
import './NewsSlider.scss';
import { data } from './logic';

class NewsSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'news-slider',
        arrows: true,
        dots: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        draggable: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow id={'news-slider-next'} />,
        prevArrow: <SliderArrow id={'news-slider-prev'} left />,
      },
    };
  }

  renderSlides = loadedData =>
    data.map(item => (
      <div
        className={'news-slider__slide-wrapper'}
        key={`news-slide-${item.id}`}
      >
        <div className={'news-slider__slide'}>
          <div className={'news-slider__item'}>
            <div className={'news-slider__header'}>
              <img
                src={loadedData[item.logo].childImageSharp.fixed}
                className={'image news-slider__logo'}
                alt={''}
                loading={'lazy'}
              />
            </div>
            <Paragraph
              color={'white'}
              size={'medium-plus'}
              additionalClass={'news-slider__content'}
            >
              <q>{item.content}</q>
            </Paragraph>
          </div>
        </div>
      </div>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            logoBBC: file(relativePath: { eq: "logos/BBC-logo.png" }) {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            logoKhaleejTimes: file(relativePath: { eq: "logos/khaleej.png" }) {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            logoIncArabia: file(
              relativePath: { eq: "logos/inc-arabia-logo.png" }
            ) {
              childImageSharp {
                fixed(width: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={loadedData => (
          <Slider
            ref={slider => {
              this.slider = slider;
            }}
            {...sliderSettings}
          >
            {this.renderSlides(loadedData)}
          </Slider>
        )}
      />
    );
  }
}

NewsSlider.defaultProps = {
  data: null,
};

NewsSlider.propTypes = {
  data: PropTypes.array,
};

export default NewsSlider;
