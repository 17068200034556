import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Section, Heading, Paragraph } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { data } from './logic.js';
import './Team.scss';

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.pe__team-box').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = () =>
    data.map((item, index) => {
      return (
        <div
          className={classNames('pe__team-box', {
            'pe__team-box--entered': this.state.entered,
            'pe__team-box--square': !item.highlights,
          })}
          key={`pe-team-box-${index}`}
        >
          {item.highlights && (
            <Heading type={2} size={'default'} color={'white'}>
              {index + 1} release
            </Heading>
          )}
          <Heading type={3} size={'big-spreaded'} color={'white'}>
            {item.title}
          </Heading>
          <Paragraph size={'medium'} color={'white'}>
            {item.description}
            {item.highlights && (
              <span className={'text--light'}>
                {ReactHtmlParser(item.highlights)}
              </span>
            )}
          </Paragraph>
          {item.extra && (
            <Paragraph size={'small-plus'} color={'white'}>
              {item.extra}
            </Paragraph>
          )}
        </div>
      );
    });

  render() {
    return (
      <Section additionalClass={'pe__team'} spaceless>
        {this.renderBoxes()}
      </Section>
    );
  }
}

export default Team;
