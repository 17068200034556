import React from 'react';
import { Heading, Paragraph } from 'components';
import slugify from 'slugify';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { StepOne, StepTwo, StepThree, StepFour } from './logic';
import './ListOfServices.scss';

const handleClick = item => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: item,
    });
  }
};

const renderItem = dataset =>
  dataset.map(item => (
    <div
      id={`section-${slugify(`${item.title}`, { lower: true })}`}
      className={classNames('services__list-step', {
        'services__list-step--reverse': item.isRight === true,
      })}
    >
      <div className={'services__list-step-copy'}>
        <Heading additionalClass={'services__list-step__title'} type={2}>
          {item.title}
        </Heading>
        <span className={'services__list-step__counter'}>{item.id}</span>
        <Paragraph additionalClass={'services__list-step__description'}>
          {item.description}
        </Paragraph>
        <div className={'services__list-step__links'}>
          {item.services.map(link => (
            <a
              href={link.link}
              className={'services__list-step__link'}
              onClick={() => handleClick(link.name)}
            >
              {link.name}
              <span>&#8594;</span>
            </a>
          ))}
        </div>
      </div>
      <div className={'services__list-step-image'}>
        <img
          alt={item.title}
          src={getCloudinaryUrl(item.image, 'f_auto,q_auto,h_350')}
          className={'image'}
          loading={'lazy'}
        />
      </div>
    </div>
  ));

const ListOfServices = () => (
  <div id={'services-list'} className={'services__list'}>
    <div className={'w-container'}>
      <div className={'services__list-items'}>
        {renderItem(StepOne)}
        {renderItem(StepTwo)}
        {renderItem(StepThree)}
        {renderItem(StepFour)}
      </div>
    </div>
  </div>
);

export default ListOfServices;
