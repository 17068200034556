import React from 'react';
import { Section, IconSVG, SectionHeader } from 'components';
import './TalentPoolBenefits.scss';

const TalentPoolBenefits = ({ content = [] }) => {
  return (
    <>
      <Section
        id={'talent-pool-benefits'}
        color={'soft-cloud'}
        additionalClass={'talent-pool-benefits'}
      >
        <div className={'talent-pool-benefits__column--content'}>
          <SectionHeader
            additionalClass={'headline talent-pool-benefits__headline'}
            color={'mine-shaft'}
            size={'large'}
            headingType={2}
            noLine
          >
            Discover the benefits of Boldare <br />
            <span className={'headline--talent headline--talent--violet'}>
              Talent Pool:
            </span>
          </SectionHeader>
        </div>
        <div className={'talent-pool-benefits__icon'}>
          <div className={'talent-pool-benefits__icon__content'}>
            {content.map(item => {
              return (
                <div
                  className={'talent-pool-benefits__item-wrapper'}
                  key={item.icon}
                >
                  <div>
                    <IconSVG name={item.icon} size={'big'} />
                  </div>
                  <span size={'small'}>{item.desc}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </>
  );
};

export default TalentPoolBenefits;
