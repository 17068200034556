import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Section,
  SectionHeader,
  Heading,
  Paragraph,
  Button,
  Link,
  PopUp,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { data } from './logic';
import './MediaKit.scss';
import { useStaticQuery, graphql } from 'gatsby';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Carousel from 'react-multi-carousel';

const MediaKit = () => {
  const [zipName, setZipName] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);

  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "uploads" } }) {
          nodes {
            id
            name
            publicURL
            extension
            size
            relativeDirectory
            relativePath
          }
        }
      }
    `,
  );
  const allFiles = query.allFile?.nodes;

  const documentUrl = allFiles.find(element => element.name === 'document')
    ?.publicURL;
  const handleDownload = async files => {
    const zip = new JSZip();
    const fetchPromises = files.map(file => {
      const fileURL = file.publicURL;
      return fetch(fileURL)
        .then(response => response.blob())
        .then(blob => zip.file(`${file.name}.${file.extension}`, blob));
    });
    await Promise.all(fetchPromises);

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, zipName);
    });
  };
  const handleCheckboxChange = file => {
    if (selectedFiles.find(f => f.publicURL === file.publicURL)) {
      setSelectedFiles(prevSelected =>
        prevSelected.filter(f => f.publicURL !== file.publicURL),
      );
    } else {
      setSelectedFiles(prevSelected => [...prevSelected, file]);
    }
  };
  const handlePopUpOpening = folderName => {
    setZipName(folderName);
    setFilteredFiles(
      allFiles?.filter(file => file.relativeDirectory.includes(folderName)),
    );
    setPopUpOpen(true);
  };

  const renderItems = () =>
    data.map((item, index) =>
      index === 5 ? (
        <article
          className={'mediakit__box mediakit__box--secondary'}
          key={`mediakit-${item}`}
        >
          <Heading type={3} size={'big'}>
            {item.title}
          </Heading>
          <Paragraph size={'medium'}>{item.description}</Paragraph>
          <Link
            additionalClass={'mediakit__article-link'}
            to={item.link}
            title={item.title}
          >
            <Button order={'secondary'}>{item.download}</Button>
          </Link>
        </article>
      ) : (
        <article
          className={'mediakit__box mediakit__box--regular'}
          key={`mediakit-${item}`}
        >
          <div className={'mediakit__box-content'}>
            <img
              src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_600')}
              alt={item.title}
              className={classNames('image', 'mediakit__box-img', {
                'mediakit__box-img--center': item.imagePos === 'center',
                'mediakit__box-img--top': item.imagePos === 'top',
                'mediakit__box-img--bottom': item.imagePos === 'bottom',
              })}
              loading={'lazy'}
            />
            <Heading type={3} size={'big'}>
              {item.title}
            </Heading>
            <Paragraph>{item.description}</Paragraph>
          </div>

          <Button
            onClick={() => handlePopUpOpening(item.dir)}
            color={'yellow'}
          >
            Download now &darr;
          </Button>
        </article>
      ),
    );
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      <Section additionalClass={'newsroom__mediakit'} spaceless>
        <div className={'section mediakit__inner'}>
          <SectionHeader
            additionalClass={'section-header__description--medium'}
            description={
              'Documents, photos, videos, logos - everything you need for the article'
            }
            color={'black'}
            lineColor={'yellow'}
            size={'large'}
            headingType={2}
          >
            Media Kit
          </SectionHeader>
          <PopUp
            visible={popUpOpen}
            visibilityHandler={showPoup => setPopUpOpen(showPoup)}
          >
            <div className={'download_pop-up'}>
              <h1>Select files to download</h1>
              <Carousel
                swipeable
                draggable={false}
                showDots
                responsive={responsive}
                infinite
                keyBoardControl={false}
                customTransition={'all .5'}
                transitionDuration={100}
                containerClass={'carousel-files'}
                arrows
                dotListClass={'custom-dot-list-style'}
              >
                {filteredFiles.map(file => (
                  <div className={'carousel-files__item'}>
                    <div className={'carousel-files__item_image_container'}>
                      <img
                        className={'carousel-files__item_image'}
                        alt={file.name}
                        src={
                          ['png', 'jpg', 'jpeg'].includes(file.extension)
                            ? file.publicURL
                            : documentUrl
                        }
                      />
                    </div>
                    <div className={'carousel-files__item_description'}>
                      <div className={'carousel-files__item_size'}>
                        <p className={'carousel-files__item_paragraph'}>
                          {' '}
                          Selected:{' '}
                        </p>
                        <Checkbox
                          checked={selectedFiles.find(
                            f => f.publicURL === file.publicURL,
                          )}
                          value={file}
                          name={file.publicURL}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div>
                        {' '}
                        <p>Filename: </p> <span>{file.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className={'download_pop-up__footer'}>
                <Button
                  onClick={() => handleDownload(selectedFiles)}
                  color={'black'}
                >
                  Download Selected
                </Button>
                <Button
                  onClick={() => handleDownload(filteredFiles)}
                  color={'yellow'}
                >
                  Download All
                </Button>
              </div>
            </div>
          </PopUp>
          <div className={'mediakit__list anim-fade-in-bottom'}>
            {renderItems()}
          </div>
        </div>
      </Section>
    </>
  );
};

export default MediaKit;
