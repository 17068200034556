import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import './BottomLogos.scss';

const BottomLogos = () => {
  return (
    <div className={'event-logos'}>
      <img
        src={getCloudinaryUrl('logos/event-organiser', 'f_auto,q_auto')}
        alt={'Event organiser'}
        loading={'lazy'}
        className={'event-logos__item'}
      />
      <img
        src={getCloudinaryUrl('logos/event-partner', 'f_auto,q_auto')}
        alt={'Event partner'}
        loading={'lazy'}
      />
    </div>
  );
};

export default BottomLogos;
