import React from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { Paragraph } from 'components';
import { useWindowWidth } from 'hooks';
import config from '../../../../../../../meta/config';
import IconSVG from '../../../../../../components/IconSVG';
import './SocialBar.scss';

const SocialBar = ({ additionalClass }) => {
  const isTabletBig = useWindowWidth() >= 992;

  return (
    <div className={classnames('social-bar', additionalClass)}>
      <Paragraph
        additionalClass={classnames('social-bar__description', 'text--bold')}
      >
        {isTabletBig ? 'Watch more projects on our profiles:' : 'Watch more:'}
      </Paragraph>
      <div className={'social-icons-container'}>
        <a
          className={'link ga-f2f--about-us-dribbble'}
          href={config.dribbble}
          rel={'nofollow'}
        >
          <IconSVG
            name={'dribbble'}
            size={isTabletBig ? 'big' : 'small-plus'}
            color={'black'}
          />
        </a>
        <a
          className={'link ga-f2f--about-us-behance'}
          href={config.behance}
          rel={'nofollow'}
        >
          <IconSVG
            name={'behance'}
            size={isTabletBig ? 'big' : 'small-plus'}
            color={'black'}
          />
        </a>
        <a
          className={'link ga-f2f--about-us-instagram'}
          href={config.instagram}
          rel={'nofollow'}
        >
          <IconSVG
            name={'instagram'}
            size={isTabletBig ? 'big' : 'small-plus'}
            color={'black'}
          />
        </a>
        <a
          className={'link ga-f2f--about-us-facebook'}
          href={config.facebook}
          rel={'nofollow'}
        >
          <IconSVG
            name={'facebook-thinner'}
            size={isTabletBig ? 'big' : 'small-plus'}
            color={'black'}
          />
        </a>
      </div>
    </div>
  );
};

SocialBar.defaultProps = {
  additionalClass: '',
};

SocialBar.propTypes = {
  additionalClass: PropTypes.string,
};

export default SocialBar;
