import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Heading, Section, SectionHeader, Icon, Paragraph } from 'components';
import { ICON_TYPES } from 'config';
import { enableAnimations } from 'config/helpers/index.js';
import {
  careerCategories,
  getOfferCategory,
  getOfferCitiesLabels,
  getOfferLink,
  getOfferName,
  getOfferSalaryRange,
  getTalentPool,
  groupOffersByPromotedFlag,
} from 'views/career/career.utils';
import { CountDown, SingleOffer } from '..';
import './Offers.scss';

class Offers extends Component {
  componentDidUpdate() {
    enableAnimations();
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  }

  renderOffers = offers => {
    return [
      ...offers.map((offer, id) => (
        <SingleOffer
          key={`offer-${offer.node.id}`}
          offer={offer}
          id={id}
          salaryRange={getOfferSalaryRange(offer)}
          cities={getOfferCitiesLabels(offer)}
          name={getOfferName(offer)}
          category={careerCategories[getOfferCategory(offer)]}
          link={getOfferLink(offer)}
          talentPool={getTalentPool(offer)}
        />
      )),
    ];
  };

  renderOffersRows = filtered => {
    const { promotedOffersDeadline } = this.props;
    const isPromotedOffersDeadlineEnabled = false;
    const offers = groupOffersByPromotedFlag(filtered);

    return (
      <div id={'offers'} className={'offers__container'}>
        {filtered.length > 0 ? (
          <>
            {offers.promoted.length > 0 && (
              <Row className={'no-gap offers__container-row'}>
                {isPromotedOffersDeadlineEnabled && (
                  <div className={'offers__recommended'}>
                    <CountDown deadline={promotedOffersDeadline} />
                  </div>
                )}
                {this.renderOffers(offers.promoted)}
              </Row>
            )}
            <a href={'/career/offers/'} className={'offers__load-more-button'}>
              Check all offers
              <Icon
                additionalClass={'offers__load-more-button-arrow'}
                icon={ICON_TYPES.RIGHT_ARROW}
                size={'default'}
                color={'black'}
              />
            </a>
          </>
        ) : (
          <div className={'offers__noOffers'}>
            <div className={'offers__contact-content'}>
              <SectionHeader
                color={'mine-shaft'}
                size={'medium'}
                headingType={2}
                noLine
              >
                No job offers available at this time
              </SectionHeader>
              <Paragraph color={'mine-shaft'} size={'medium'}>
                No worries just drop us a line!
              </Paragraph>
              <div className={'offers__contact-links'}>
                <a
                  href={
                    'https://boldare.breezy.hr/p/a691cfa5814101-contact-us'
                  }
                  className={'button button--primary button--yellow'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { offers } = this.props;

    return (
      <Section id={'offers-section'} additionalClass={'offers'}>
        <div className={'w-container offers__heading-wrapper'}>
          <Heading
            type={3}
            size={'medium'}
            color={'mine-shaft'}
            additionalClass={'headline--top headline--top-yelow'}
          >
            Boldare Offers
          </Heading>
          <SectionHeader
            additionalClass={'headline--centered'}
            color={'mine-shaft'}
            size={'medium'}
            headingType={2}
            noLine
          >
            Find your{' '}
            <span
              className={'headline--marking headline--marking-yellow--dream'}
            >
              dream&nbsp;job
            </span>
          </SectionHeader>
        </div>
        {this.renderOffersRows(offers)}
      </Section>
    );
  }
}

Offers.defaultProps = {
  initiallyVisibleNotPromotedOffersCount: 2,
};

Offers.propTypes = {
  initiallyVisibleNotPromotedOffersCount: PropTypes.number,
  offers: PropTypes.array.isRequired,
  promotedOffersDeadline: PropTypes.instanceOf(Date).isRequired,
};

export default Offers;
