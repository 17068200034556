export const prototypeArticles = [
  {
    title: 'MVP development - what, why & how',
    url: '/blog/mvp-what-why-how/',
    img: '/img/rallo.png',
  },
  {
    title: 'How to build a Minimum Viable Product – a step by step guide',
    url: '/blog/how-to-build-mvp-minimum-viable-product/',
    img: '/img/Minimum_Viable_Product_-_how_to_build.png',
  },
  {
    title: 'How we helped TeamAlert transition from MVP to Product-Market Fit',
    url: '/work/teamalert-transition-from-mvp-to-pmf/',
    img: '/img/TeamAlert-from-MVP-to-product-market-fit.jpg',
  },
];

export const mvpArticles = [
  {
    title: 'How to create a prototype in a single sprint? A real-life example',
    url: '/blog/prototype-in-a-single-sprint/',
    img: '/img/How_to_create_a_prototype_in_a_single_sprint_3_v2.jpg',
  },
  {
    title: 'What is a proof of concept in digital product development?',
    url: '/blog/what-is-proof-concept/',
    img: '/img/proof_of_concept_ver2.png',
  },
  {
    title: 'The first version of your app: Prototype or MVP?',
    url: '/blog/first-version-of-your-app-mvp-vs-prototype/',
    img: '/img/first-version-of-your-app.png',
  },
];
