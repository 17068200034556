import React from 'react';
import { Section } from 'components';
import BigNumberOrderedList from '../../../../components/BigNumberOrderedList';

import './Benefits.scss';

export default function Benefits() {
  return (
    <Section containerClass={'recommend-client__benfits'} spacedTop spaceless>
      <h2
        className={
          'recommend-client__heading recommend-client__heading--more-spaced'
        }
      >
        What benefits await you?
      </h2>
      <BigNumberOrderedList className={'recommend-client__benfits__list'}>
        <BigNumberOrderedList.Item
          subtextClassName={'recommend-client__benfits__list__subtext'}
          subtext={
            'Get rewarded with commissions of 10% for successful customer conversions'
          }
        >
          Get 10% fee
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={'recommend-client__benfits__list__subtext'}
          subtext={
            'Offer your clients added value through a diverse array of services'
          }
        >
          Boost the value of your company
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={'recommend-client__benfits__list__subtext'}
          subtext={
            'Maximize your business opportunities through strategic network growth.'
          }
        >
          Expand your circle of contacts
        </BigNumberOrderedList.Item>
      </BigNumberOrderedList>
    </Section>
  );
}
