import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import {
  Heading,
  Paragraph,
  Section,
  SectionHeader,
  YouTubePlayer,
} from 'components';
import { Numbers } from 'views/newsroom/subcomponents';
import companyData from '../../../../../meta/companyData';
import dataToDisplay from './logic';

import './WhoAreWe.scss';

const WhoAreWe = () => (
  <Section
    additionalClass={'contact-nl-who-are-we__section'}
    id={'connect-nl-cities'}
    spacedTop
  >
    <div className={'contact-nl-who-are-we__background-color-box'} />
    <div>
      <Row>
        <Col xs={12}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            fixed
            noLine
            additionalClass={'contact-nl-who-are-we__text-header'}
          >
            Boldare - wie zijn wij?
          </SectionHeader>
          <Row>
            <Col sm={12} md={7} lg={6}>
              <div
                className={
                  'contact-nl-who-are-we__text-header-secondary-wrapper'
                }
              >
                <div
                  className={'contact-nl-who-are-__vertical-line hide-mobile'}
                />
                <Heading
                  type={2}
                  size={'medium-constant'}
                  color={'black'}
                  additionalClass={
                    'contact-nl-who-are-we__text-header-secondary contact-nl-who-are-we__text-header-secondary-top'
                  }
                >
                  Hoi! Wij ontwikkelen en ondersteunen digitale producten. Hoe
                  wij werken wordt bepaald door de omgeving waarin we ons
                  begeven.
                </Heading>
                <Heading
                  type={2}
                  size={'medium-constant'}
                  color={'black'}
                  additionalClass={
                    'contact-nl-who-are-we__text-header-secondary contact-nl-who-are-we__text-header-secondary-top'
                  }
                >
                  Sinds het begin van Boldare in 2004, zijn we uitgegroeid tot
                  een internationaal bedrijf met meer dan 200 werknemers op 6
                  verschillende locaties, waaronder Amsterdam, Hamburg en
                  Warschau. Inmiddels zijn wij uitgegroeid tot de benchmark
                  voor vele anderen.
                </Heading>
                <Heading
                  type={2}
                  size={'medium-constant'}
                  color={'black'}
                  additionalClass={
                    'contact-nl-who-are-we__text-header-secondary'
                  }
                >
                  Waarom? We begrijpen dat we om baanbrekende producten te
                  ontwikkelen, de omgeving moeten creëren waarin dit mogelijk
                  is. We concentreren ons daarom erop, dingen anders te doen,
                  zodat we verandering bij anderen kunnen ondersteunen.
                </Heading>
              </div>
            </Col>
            <Col
              sm={12}
              md={5}
              lg={6}
              className={'contact-nl-who-are-we__employees-number-text-col'}
            >
              <div>
                <Heading
                  size={'extra-large'}
                  color={'violet'}
                  type={4}
                  additionalClass={
                    'contact-nl-who-are-we__employees-number-text'
                  }
                >
                  {companyData.employees}+
                </Heading>
                <Paragraph
                  color={'violet'}
                  size={'medium-plus'}
                  additionalClass={
                    'contact-nl-who-are-we__employees-number-title-text'
                  }
                >
                  IT-specialisten
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row end={'md'}>
        <Col sm={12} md={10} className={'contact-nl-who-are-we__video-col'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=6lM8ziimbgw'}
            light
            playing
            controls
          />
        </Col>
      </Row>
      <Numbers dataToDisplay={dataToDisplay} />
    </div>
  </Section>
);

export default WhoAreWe;
