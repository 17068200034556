import React from 'react';
import { Button, Section, Heading, FullwidthImage, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Contact.scss';

const Contact = () => {
  return (
    <Section additionalClass={'sm__contact-container'} spaceless>
      <FullwidthImage
        image={getCloudinaryUrl(
          'assets/spa-monkeys/spamonkeys-contact-us.jpg',
          'f_auto,q_auto',
        )}
        alt={'SPA'}
      />
      <div className={'sm__contact-content'}>
        <Heading type={2} size={'big-spreaded'} color={'white'}>
          Create your SPA
          <br />
          with us!
        </Heading>
        <Link to={'/contact/#form'} additionalClass={'sm__contact-link'}>
          <Button
            id={'sm__contact-link'}
            color={'white'}
            hover
            ariaName={'Contact button'}
            additionalClass={'sm__contact-button'}
          >
            Contact
          </Button>
        </Link>
      </div>
    </Section>
  );
};

export default Contact;
