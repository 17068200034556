import React from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Work.scss';

const Work = () => {
  return (
    <>
      <Section additionalClass={'sm__work-container'}>
        <div className={'sm__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            How it works?
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            Complex and scalable system
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            Building process of such a complex service require comprehensive
            dependencies in its architecture. Thanks to global thinking we were
            able to achieve this goal by making dependencies with over 50
            different elements. The greatest challenge was to build pathways
            for users that were easy to navigate.
          </Paragraph>
        </div>
      </Section>
      <div className={'sm__work-architecture'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-architecture.png',
            'f_auto,q_auto',
          )}
          alt={'System architecture'}
        />
      </div>
    </>
  );
};

export default Work;
