import React from 'react';
import { Button, Paragraph, Heading, Image } from 'components';
import './SimpleBanner.scss';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';

const colorMap = {
  red: 'red-main',
  black: 'mine-shaft',
  white: 'white',
  carrara: 'carrara',
  violet: 'violet-main',
  yellow: 'sandstorm',
  grey: 'pigeon-post-light',
  green: 'downy',
};

const colorCode = {
  red: '#f68282',
  black: '#242424',
  white: '#ffffff',
  carrara: '#f8f8f5',
  violet: '#7563e7',
  yellow: '#f2da3a',
  grey: '#b2cbde',
  green: '#5acbbc',
};

export const SimpleBanner = ({
  title,
  titleColor,
  backgroundColor,
  textColor,
  text,
  bannerWithPerson,
  contactPersonName,
  contactPersonRole,
  contactPersonImage,
  personImageBackgroundMask,
  buttonText,
  buttonLink,
  buttonId,
  buttonBackgroundColor,
  buttonTextColor,
}) => {

  const bannerBackgroundColor = {
    backgroundColor: colorCode[backgroundColor],
  };

  const buttonWrapperClass = bannerWithPerson
    ? 'simple-banner__link-wrapper'
    : 'simple-banner__button-left';

  return (
    <div className={'simple-banner__container'} style={bannerBackgroundColor}>
      <div className={'simple-banner'}>
        <Heading color={colorMap[titleColor]} size={'big'}>
          {title}
        </Heading>
        <Paragraph
          color={colorMap[textColor]}
          size={'medium-constant'}
          additionalClass={'simple-banner__text'}
        >
          {text}
        </Paragraph>
        <div className={'simple-banner__bottom-container'}>
          {bannerWithPerson && (
            <div className={'simple-banner__item-wrapper'}>
              <div className={'simple-banner__item'}>
                <figure className={'simple-banner__item-avatar'}>
                  <Image
                    src={contactPersonImage}
                    width={'172'}
                    height={'172'}
                    alt={contactPersonName}
                  />
                </figure>
                <img
                  className={'simple-banner__item-avatar-mask'}
                  src={getCloudinaryUrl(
                    personImageBackgroundMask,
                    'f_auto,q_auto,w_auto',
                  )}
                  alt={'mask'}
                  loading={'lazy'}
                />
                <div className={'simple-banner__details'}>
                  <Heading
                    color={colorMap[textColor]}
                    size={'medium-plus-small'}
                  >
                    {contactPersonName}
                  </Heading>
                  <Paragraph
                    className={'simple-banner__role'}
                    color={colorMap[textColor]}
                  >
                    {contactPersonRole}
                  </Paragraph>
                </div>
              </div>
            </div>
          )}
          <div className={buttonWrapperClass}>
            <a href={buttonLink}>
              <Button
                color={colorMap[buttonBackgroundColor]}
                id={buttonId}
                additionalClass={
                  'simple-banner__button simple-banner__paragraph--uppercase'
                }
                style={{ color: colorCode[buttonTextColor] }}               >
                {buttonText}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleBanner.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  bannerWithPerson: PropTypes.bool,
  contactPersonName: PropTypes.string,
  contactPersonRole: PropTypes.string,
  contactPersonImage: PropTypes.string,
  personImageBackgroundMask: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};


SimpleBanner.defaultProps = {
  titleColor: 'black',
  backgroundColor: 'grey',
  textColor: 'black',
  bannerWithPerson: false,
  contactPersonName: null,
  contactPersonRole: null,
  contactPersonImage: null,
  personImageBackgroundMask: null,
  buttonId: 'simple-banner-button',
  buttonBackgroundColor: 'red',
  buttonTextColor: 'black',
};
