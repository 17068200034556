import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Heading, SectionTitle, Paragraph, Button } from 'components';

class Problem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
    };
  }

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <Section color={'gray-light'}>
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionTitle size={'big'} color={'black'} lineColor={'violet'}>
              Problem
            </SectionTitle>
          </Col>
          <Col sm={12} md={6}>
            <div className={'fpdc__problem-description'}>
              <Heading color={'black'} size={'medium-plus'} type={2}>
                How to build a product that users want and not waste your
                budget in the process?
              </Heading>
              <Paragraph
                additionalClass={'text--light'}
                color={'black'}
                size={'medium'}
              >
                Over 40% of digital products fail due to a lack of
                product-market fit. Your product needs to find loyal users and
                grow with the needs of your customers.
              </Paragraph>
              {isFullDescriptionShown && (
                <Paragraph
                  additionalClass={'fpdc__additional-description text--light'}
                  color={'black'}
                  size={'medium'}
                >
                  Additionally, in a global economy you have to release your
                  product quickly and find unique differentiators that will
                  allow you to conquer the market.
                  <br />
                  <br />
                  Finally, the figures need to add up which means you are
                  looking to deliver the product within your budget but also
                  retain the flexibility to adjust the scope of work if your
                  company funds change.
                </Paragraph>
              )}
              <div
                className={'fpdc__learn-more-button'}
                onClick={this.toggleHiddenDescription}
              >
                <Button
                  order={'secondary'}
                  arrowColor={'violet'}
                  ariaName={'Description button'}
                >
                  {isFullDescriptionShown ? 'Show less' : 'Learn more'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    );
  }
}

export default Problem;
