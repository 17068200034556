import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { Awards, Section, SectionHeader } from 'components';
import {
  Banner,
  Numbers,
  News,
  Press,
  MediaKit,
  CommunicationTeam,
} from './subcomponents';
import './Newsroom.scss';

class Newsroom extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero newsroom-page'}>
        <Banner link={'newsroom-hello'} />
        <Numbers />
        <News />
        <Press />
        <MediaKit />
        <Section>
          <SectionHeader
            lineColor={'red'}
            additionalClass={'section-header__description--medium'}
            description={'Accolades for our design-powered digital products.'}
            color={'black'}
            size={'large'}
            headingType={2}
          >
            Awards
          </SectionHeader>
          <Awards />
        </Section>
        <CommunicationTeam />
      </div>
    );
  }
}

export default Newsroom;
