import { Section, Heading, Paragraph, Image } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import Vision2030Logo from 'assets/images/digital-transformation-journey/vision2030/vision2030-logo.png';
import GitexLogo from 'assets/images/digital-transformation-journey/vision2030/gitex-logo.png';
import React from 'react';
import './Vision2030.scss';

export const Vision2030 = () => {
  return (
    <Section
      additionalClass={'vision-section'}
      containerClass={'vision-section-container'}
    >
      <div>
        <Row style={{ height: 574 }}>
          <Col xs={12} md={6} style={{ padding: '30px 0' }}>
            <Heading type={4} size={'large'}>
              We support Saudi companies in Vision 2030 plan
            </Heading>
            <Paragraph
              size={'medium'}
              additionalClass={'vision-typography-description'}
            >
              We take great pride in offering technological and business
              support to companies striving towards the Vision 2030 plan. It is
              our hope that our contributions will significantly aid in the
              success of this transformative initiative.
            </Paragraph>
            <img src={Vision2030Logo} alt={'Vision2030'} />
          </Col>
          <Col xs={12} md={6}>
            <div className={'vision-image-container tall'}>
              <Image src={'digital-transformation-journey/photo1.jpg'} />
            </div>
          </Col>
        </Row>
        <Row className={'vision-photo-row'}>
          <Col xs={12} md={4} style={{ padding: 0 }}>
            <div className={'vision-image-container'}>
              <Image src={'digital-transformation-journey/photo2.jpg'} />
            </div>
          </Col>
          <Col xs={12} md={4} style={{ padding: '0 30px' }}>
            <div className={'vision-image-container'}>
              <Image src={'digital-transformation-journey/photo3.jpg'} />
            </div>
          </Col>
          <Col xs={12} md={4} style={{ padding: 0 }}>
            <div className={'vision-image-container'}>
              <Image src={'digital-transformation-journey/photo4.jpg'} />
            </div>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center', width: '100%' }}>
          {' '}
          <Col xs={12} md={3}>
            <img src={GitexLogo} alt={'Gitex'} />
          </Col>
          <Col xs={12} md={9}>
            <div className={'vision-memorandum-banner'}>
              <Heading type={4} size={'medium'} color={'#242424'}>
                We proudly partner with Takamol Holding
              </Heading>
              <Paragraph additionalClass={'vision-gitex-paragraph'}>
                At GITEX GLOBAL 2023, we signed a{' '}
                <span className={'highlight'}>
                  Memorandum of Understanding
                </span>{' '}
                to deepen our collaboration building on our strong relationship
                since 2016
              </Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    </Section>
  );
};
