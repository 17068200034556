import React, { Component } from 'react';
import { FullwidthImage, ContactFormServices } from 'components';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import {
  Banner,
  What,
  Why,
  Technologies,
  Problem,
  Solution,
  Types,
  Benefits,
  ProcessAndTools,
  OurArticles,
  OtherServices,
} from './subcomponents';
import './DigitalTransformation.scss';

class DigitalTransformation extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedOurArticles = [
      'Visualising Energy',
      'How to scale a monolithic MVP application without losing business?',
      'From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand',
      'Designing a simple search experience for a complex product with a luxurious interface',
    ].map(title => ({ title }));

    return (
      <div className={'single-page single-page--hero digital-transformation'}>
        <Banner />
        <What />
        <Why />
        <Technologies />
        <Problem />
        <FullwidthImage
          image={getCloudinaryUrl(
            'gatsby-cloudinary/development-team-discuss-performance.jpg',
            'f_auto,q_auto',
          )}
        />
        <Solution />
        <Types allArticles={allArticles} related={relatedOurArticles} />
        <Benefits />
        <ProcessAndTools />
        <OurArticles allArticles={allArticles} related={relatedOurArticles} />
        <ContactFormServices
          id={'dt-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x87'}
        />
        <OtherServices />
      </div>
    );
  }
}

export default DigitalTransformation;
