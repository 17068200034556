import React, { Component } from 'react';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import IconSVG from 'components/IconSVG';
import { data } from './logic';
import './CommunicationTeam.scss';

class CommunicationTeam extends Component {
  renderExpertList = () => {
    return data.map(member => (
      <li className={'communication__people'}>
        <div className={'communication__people-img'}>
          <img
            src={getCloudinaryUrl(member.image, 'f_auto,q_90,w_500')}
            alt={member.name}
            className={'image'}
            loading={'lazy'}
          />
        </div>
        <div className={'communication__people-content'}>
          <div className={'communication__people-content--main'}>
            <span className={'communication__people-name'}>{member.name}</span>
            <div className={'communication__people-links'}>
              <a
                href={member.linkedin}
                title={'LinkedIn'}
                className={'link--li'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <IconSVG
                  name={'linkedin-letter'}
                  size={'medium'}
                  color={'white'}
                />
              </a>
            </div>
          </div>
          <span className={'communication__people-description'}>
            {member.description}
          </span>
          <a
            href={`mailto:${member.mail}`}
            title={'Send me a message'}
            className={'communication__people-description'}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            {member.mail}
          </a>
        </div>
      </li>
    ));
  };

  render() {
    return (
      <Section color={'white'} additionalClass={'newsroom__communication'}>
        <SectionHeader
          description={'Our PR team is here for you.'}
          color={'black'}
          lineColor={'yellow'}
          size={'large'}
          headingType={2}
          fixed
        >
          Boldare
          <br />
          Communications Team
        </SectionHeader>
        <ul className={'communication__group'}>
          {this.renderExpertList(data)}
        </ul>
      </Section>
    );
  }
}

export default CommunicationTeam;
