import './index.scss';
import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import Hero from './subcomponents/Hero';
import Intro from './subcomponents/Intro';
import Cases from './subcomponents/Cases';
import CasesSequel from './subcomponents/CasesSequel';
import Awards from './subcomponents/Awards';
import Contact from './subcomponents/Contact';
import Challenges from './subcomponents/Challenges';
import Testimonials from './subcomponents/Testimonials';

const DesignThatTellsStories = () => {
  useEffect(() => {
    if (window.location.href.includes('?thank-you&connectionId=')) {
      scroller.scrollTo('bldr__contact', {
        duration: 800,
        delay: 1000,
        smooth: true,
      });
    }
  }, []);

  return (
    <div className={'single-page single-page--hero bldr-dsgn'}>
      <Hero />
      <Intro />
      <Cases />
      <CasesSequel />
      <Awards />
      <Testimonials />
      <Challenges />
      <Contact />
    </div>
  );
};

export default DesignThatTellsStories;
