import React from 'react';
import * as Scroll from 'react-scroll';
import { Section, SectionHeader, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Banner.scss';

const SpyLink = Scroll.Link;

const Banner = () => (
  <div className={'webinar-res__hero'}>
    <Section additionalClass={'webinar-res__banner'} color={'pigeon-post'}>
      <span className={'webinar-res__banner-badge'}>crash course</span>
      <SectionHeader
        color={'mine-shaft'}
        description={`Take our 3-part crash course on how to build web products in a time of post-COVID-19 recession. Learn about tailored strategies for software development, how to reduce business risks, and how to create agile development teams that deliver. The course includes videos, articles, and additional resources - you choose which topic interests you the most.`}
        descriptionColor={'mine-shaft'}
        fixed
        noLine
        additionalClass={'section-header__description--medium'}
      >
        <span>Managing risks,</span>
        <span className={'no-padding'}>web products</span>
        <span>& dev teams in crisis</span>
      </SectionHeader>
      <div className={'webinar-res__buttons'}>
        <SpyLink
          id={'webinar-res-video-link'}
          to={'section-1'}
          spy={false}
          smooth
          duration={500}
          className={'link'}
        >
          <Button
            id={'webinar-res-watch-button'}
            color={'yellow'}
            ariaName={'Lesson button'}
          >
            Lesson 1
          </Button>
        </SpyLink>
        <SpyLink
          id={'webinar-res-video-link'}
          to={'section-2'}
          spy={false}
          smooth
          duration={500}
          className={'link'}
        >
          <Button
            id={'webinar-res-watch-button'}
            color={'yellow'}
            ariaName={'Lesson button'}
          >
            Lesson 2
          </Button>
        </SpyLink>
        <SpyLink
          id={'webinar-res-video-link'}
          to={'section-3'}
          spy={false}
          smooth
          duration={500}
          className={'link'}
        >
          <Button
            id={'webinar-res-watch-button'}
            color={'yellow'}
            ariaName={'Lesson button'}
          >
            Lesson 3
          </Button>
        </SpyLink>
      </div>
    </Section>
    <div className={'webinar-res__banner-image'}>
      <img
        className={'image'}
        src={getCloudinaryUrl(
          'img/crash-course-cover.svg',
          'q_auto,f_auto,w_600',
        )}
        alt={'Risk Management Crash Course'}
        loading={'lazy'}
      />
    </div>
  </div>
);

export default Banner;
