import React from 'react';
import PropTypes from 'prop-types';

const chooseVersion = (testId, numberOfVersions) => {
  let selectedVersion = Math.floor(Math.random() * numberOfVersions);
  let storageVersion = localStorage.getItem(testId);

  if (storageVersion !== null && !isNaN(storageVersion)) {
    storageVersion = parseInt(storageVersion, 10);
    if (storageVersion >= 0 && storageVersion < numberOfVersions) {
      selectedVersion = storageVersion;
    }
  }

  return selectedVersion;
};

const ABTest = ({ children, id }) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  const selectedVersion = chooseVersion(id, children.length);
  localStorage.setItem(id, selectedVersion);

  return children[selectedVersion];
};

ABTest.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  id: PropTypes.string.isRequired,
};

export default React.memo(ABTest);
