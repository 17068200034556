import React from 'react';
import PropTypes from 'prop-types';

import Stamp from 'assets/images/ses/badge-png.png';
import config from '../../../../../meta/config';

import './ServiceStandardsSection.scss';

const ServiceStandardsSection = () => {
  return (
    <div className={'ses-section'}>
      <img
        src={Stamp}
        alt={`Service Standards Stamp`}
        className={'ses-section__badge'}
        loading={'lazy'}
      />
      <h2 className={'ses-section__header'}>
        <span>Our Service Standards</span>
      </h2>
      <p className={'ses-section__description'}>
        Turning complexity <br /> into simplicity
      </p>
      <a
        href={`${config.siteUrl}/service-standards/`}
        className={'button button--primary button--white ses-section__cta'}
      >
        Learn more
      </a>
    </div>
  );
};

ServiceStandardsSection.defaultProps = {
  type: '',
};

ServiceStandardsSection.propTypes = {
  type: PropTypes.string,
};

export default ServiceStandardsSection;
