import React from 'react';
import { graphql } from 'gatsby';
import About from 'views/about';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages.about;
const { navColors } = page;
const url = `${config.siteUrl}/about/`;
const canonical = `${url}`;

const AboutPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical }}>
      <NavBar
        additionalClass={'navbar--scrolled'}
        color={navColors}
        bannerHidden
      />
      <PopupRedirect message={'about'} />
      <About allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query AboutQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "How to deliver an e-commerce platform MVP in just 6 weeks"
              "Transforming US politics with a voting platform for concerned citizens"
              "Visualising Energy"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default AboutPage;
