import React, { Component } from 'react';
import { Section, SectionHeader, BlogSlider } from 'components';
import './Experience.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { sliderData } from './logic';

class Experience extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  render() {
    return (
      <div className={'gitex-experience section section--white'}>
        <Section color={'white'} additionalClass={'experience'}>
          <SectionHeader lineColor={'yellow'}>
            Our clients
            <br />
            and experience
            <br />
            in the Middle East
          </SectionHeader>
        </Section>
        <BlogSlider
          theme={'dark'}
          data={sliderData.map(slide => ({
            ...slide,
            background: getCloudinaryUrl(
              slide.background,
              'f_auto,q_auto,w_1900',
            ),
          }))}
        />
      </div>
    );
  }
}

export default Experience;
