import React, { useEffect, useState } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import './Popup.scss';

const Popup = () => {
  const [isPopupEnabled, setIsPopupEnabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('faceToFacePopupEnabled') !== 'false') {
      setTimeout(() => {
        setIsPopupEnabled(true);
      }, 6000);
    }
  }, []);

  const setPopupVisibility = isEnabled => {
    localStorage.setItem('faceToFacePopupEnabled', isEnabled.toString());
    setIsPopupEnabled(isEnabled);
  };

  return (
    isPopupEnabled && (
      <div className={'f2f-popup'}>
        <a
          href={'/career/face-to-face'}
          target={'_blank'}
          rel={'noreferrer'}
          className={'ga-career--popup-check-offers'}
        >
          <img
            alt={'Boldare - Digital Product Design Development Company'}
            className={'banner--f2f__bg'}
            src={getCloudinaryUrl(
              'f2f-landing/f2f-popup-banner.png',
              'c_scale,q_auto',
            )}
            loading={'lazy'}
          />
        </a>
        <button
          className={'f2f-popup__close'}
          onClick={() => setPopupVisibility(false)}
        >
          x
        </button>
      </div>
    )
  );
};

export default Popup;
