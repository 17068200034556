import { Banner } from './Banner';
import Benefits from './Benefits';
import Types from './Types';
import Contact from './Contact';
import Offer from './Offer';
import WhatWeDid from './WhatWeDid';
import Subscribe from './Subscribe';
import OtherServices from './OtherServices';
import LearnMore from './LearnMore';

export {
  Banner,
  Benefits,
  Types,
  Contact,
  Offer,
  WhatWeDid,
  Subscribe,
  OtherServices,
  LearnMore,
};
