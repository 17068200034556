export const data = [
  {
    title: `Why and how UX matters for your business?`,
    link: `https://www.boldare.com/blog/why-and-how-ux-matters-for-your-business/`,
  },
  {
    title: `What is Design Thinking?`,
    link: `https://www.boldare.com/blog/what-is-design-thinking/`,
  },
  {
    title: `What are design sprints?`,
    link: `https://www.boldare.com/blog/what-are-design-sprints/`,
  },
  {
    title: `People don't buy products. People buy meanings`,
    link: `https://www.boldare.com/blog/design-driven-innovation/`,
  },
];
