import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Section, SectionHeader } from 'components';
import './Contact.scss';

const careerContactAddress = 'career@boldare.com';

const Contact = () => (
  <Section additionalClass={'career__contact-section'}>
    <div className={'fakebox'} />
    <div className={'career__contact-content'}>
      <SectionHeader
        color={'mine-shaft'}
        size={'medium'}
        headingType={2}
        noLine
      >
        Haven&apos;t found what you&apos;re looking for?
      </SectionHeader>
      <Paragraph color={'mine-shaft'} size={'medium'}>
        No worries just drop us a line!
      </Paragraph>
      <div className={'career__contact-links'}>
        <a
          href={'https://boldare.breezy.hr/p/a691cfa5814101-contact-us'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Contact us
        </a>
        <a
          href={`mailto:${careerContactAddress}?subject=Interesuje mnie rekrutacja do Boldare - mam pytanie`}
          className={'link'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {careerContactAddress}
        </a>
      </div>
    </div>
  </Section>
);

Contact.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
  scroll: PropTypes.bool,
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  headerTextColor: PropTypes.string,
  paragraphTextColor: PropTypes.string,
  noLine: PropTypes.bool,
  isParagraphBold: PropTypes.bool,
  contactWithLink: PropTypes.bool,
};

Contact.defaultProps = {
  background: '',
  title: '',
  content: '',
  cta: '',
  link: '',
  scroll: false,
  id: '',
  backgroundColor: 'pigeon-post',
  headerTextColor: 'white',
  paragraphTextColor: 'black',
  noLine: false,
  isParagraphBold: false,
  contactWithLink: false,
};

export default Contact;
