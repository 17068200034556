const apbCommunityNewsletterData = {
  page: {
    color: 'white',
  },
  form: {
    heading: 'Sign Up for Monthly Insights',
    thanksDescription:
      'You have sucessfully subscribed to the Agile Product Builders Community newsletter!',
  },
};

export { apbCommunityNewsletterData };
