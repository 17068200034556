import React, { useState } from 'react';
import './TileSection.scss';
import { classNames } from 'utils';
import PropTypes from 'prop-types';
import { tileProperties } from './logic';
import Tile from './subcomponents/Tile';
import TileSectionHeader from './subcomponents/TileSectionHeader';

function TileSection({ withHeader }) {
  const [showTiles, setShowTile] = useState(false);

  return (
    <div className={'tiles-section'}>
      {withHeader && (
        <TileSectionHeader showTiles={showTiles} setShowTile={setShowTile} />
      )}
      <div
        className={classNames('tiles-section__slicer', {
          'tiles-section__slicer--short': showTiles || !withHeader,
        })}
      />
      {(showTiles || !withHeader) && (
        <div className={'tiles-section__tile'}>
          {tileProperties.map(tile => (
            <Tile key={tile.id} {...tile} />
          ))}
        </div>
      )}
    </div>
  );
}

TileSection.propTypes = {
  withHeader: PropTypes.bool,
};
TileSection.defaultProps = {
  withHeader: false,
};

export default TileSection;
