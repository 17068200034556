import React from 'react';
import './Solution.scss';
import { FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const CybersixgillSolution = () => {
  return (
    <div>
      <div className={'cybersixgill-solution'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'cybersixgill/solution_cover.png',
            'f_auto,q_auto',
          )}
          alt={'Solution cover'}
        />
      </div>
    </div>
  );
};

export default CybersixgillSolution;
