import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './BackgroundContent.scss';

class BackgroundContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: props.height,
    };
  }

  componentDidMount() {
    const height =
      this.props.height > 0 ? this.props.height : this.divElement.clientHeight;
    this.setState({ height });
  }

  render() {
    const { height } = this.state;
    const { color, content, additionalClass, opacity } = this.props;

    return (
      <div
        ref={divElement => (this.divElement = divElement)}
        style={{
          fontSize: height,
          lineHeight: `${height + 20}px`,
          opacity,
        }}
        className={classNames(
          'background-content',
          'text--bold',
          {
            [`text--${color}`]: color,
          },
          additionalClass,
        )}
      >
        {content}
      </div>
    );
  }
}

BackgroundContent.defaultProps = {
  additionalClass: '',
  color: 'black',
  opacity: 0.2,
  height: 0,
};

BackgroundContent.propTypes = {
  additionalClass: PropTypes.string,
  content: PropTypes.string.isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
  height: PropTypes.number,
};

export default BackgroundContent;
