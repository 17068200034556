import React from 'react';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  FullwidthImage,
  ImageMosaic,
  Paragraph,
  Link,
  Button,
  RelatedArticles,
} from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl, toHtml } from 'config/helpers';
import { Intro, Summary, Fame, ClientReview, Statistics } from '..';
import '../../SingleCaseStudy.scss';

const CaseStudyContent = ({ data, articlesList }) => {
  const {
    mainVideo,
    cover,
    lead,
    title,
    mainColor,
    intro: summary,
    mainContent,
    settings,
  } = data;
  const { media, awards } = summary;
  const {
    review,
    workshopImage,
    workshopImageAlt,
    statistics,
    solutionFinalImage,
    related,
  } = mainContent;
  const isStatisticsSection = statistics && !isEmpty(statistics.image);

  return (
    <div className={'case-study-content'}>
      <Intro
        video={getCloudinaryUrl(mainVideo, 'f_auto,q_auto', 'video') || ''}
        cover={cover ? getCloudinaryUrl(cover, 'f_auto,q_auto') : ''}
        lead={lead || ''}
        title={title || ''}
        mainColor={mainColor || ''}
      />
      {summary && <Summary data={summary} mainColor={mainColor || ''} />}

      {media && <Fame media={media} />}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.client ? toHtml(mainContent.client) : ''}
            </div>
          </Col>
          <Col sm={12} md={4} className={'case-study-content__side-image'}>
            <img
              src={
                mainContent.sideImage
                  ? getCloudinaryUrl(mainContent.sideImage, 'f_auto,q_auto')
                  : ''
              }
              className={'image'}
              alt={`${title || ''} - Boldare in Media`}
              loading={'lazy'}
            />
          </Col>
        </Row>
      </Section>
      {awards && <Fame awards />}
      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.problem ? toHtml(mainContent.problem) : ''}
            </div>
          </Col>
        </Row>
      </Section>

      {workshopImage && (
        <FullwidthImage
          image={getCloudinaryUrl(workshopImage, 'f_auto,q_auto,w_1920')}
          alt={workshopImageAlt || `${title} - Boldare Team`}
          side={'right'}
          additionalClass={'case-study-content__section--spaced'}
        />
      )}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.solutionFirst
                ? toHtml(mainContent.solutionFirst)
                : ''}
            </div>
          </Col>
        </Row>
      </Section>

      {mainContent.processImages && !isEmpty(mainContent.processImages) && (
        <ImageMosaic
          images={mainContent.processImages.map(({ image }) => ({
            image: {
              ...image,
              src: getCloudinaryUrl(image.src, 'f_auto,q_auto'),
            },
          }))}
          additionalClass={'case-study-content__section--spaced'}
        />
      )}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.solutionSecond
                ? toHtml(mainContent.solutionSecond)
                : ''}
            </div>
          </Col>
        </Row>
      </Section>

      {mainContent.productImages && !isEmpty(mainContent.productImages) && (
        <ImageMosaic
          images={mainContent.productImages.map(({ image }) => ({
            image: {
              ...image,
              src: getCloudinaryUrl(image.src, 'f_auto,q_auto'),
            },
          }))}
          additionalClass={'case-study-content__section--spaced'}
        />
      )}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.solutionThird
                ? toHtml(mainContent.solutionThird)
                : ''}
            </div>
          </Col>
        </Row>
      </Section>

      {solutionFinalImage && (
        <FullwidthImage
          image={getCloudinaryUrl(solutionFinalImage, 'f_auto,q_auto,w_1920')}
          alt={`${title} - Boldare Solution`}
          side={'right'}
          additionalClass={'case-study-content__section--spaced'}
        />
      )}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.results ? toHtml(mainContent.results) : ''}
            </div>
          </Col>
        </Row>
      </Section>

      {review && !isEmpty(review.image) && (
        <ClientReview
          additionalClass={classNames({
            'case-study-content__section--spaced': !isStatisticsSection,
          })}
          data={review}
          mainColor={mainColor}
        />
      )}

      {isStatisticsSection && (
        <Statistics
          data={statistics}
          mainColor={mainColor}
          additionalClass={'case-study-content__section--spaced'}
        />
      )}

      <Section additionalClass={'case-study-content__section--spaced'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={8}>
            <div className={'case-study-content__section'}>
              {mainContent.resultsSecond
                ? toHtml(mainContent.resultsSecond)
                : ''}
            </div>
          </Col>
        </Row>
      </Section>

      <Section
        additionalClass={
          'case-study-content__cta case-study-content__section--spaced'
        }
      >
        <div className={'case-study-content__contact anim-fade-in-bottom'}>
          <Link to={'/contact/#form'}>
            <Paragraph
              size={'big'}
              color={'yellow'}
              additionalClass={'text--bold'}
            >
              Let&apos;s talk
            </Paragraph>
            <Paragraph
              color={'white'}
              size={'medium'}
              additionalClass={'contact__content'}
            >
              We&apos;d love to help with your product. Get in touch.
            </Paragraph>
            <Button
              id={`contact-cs-${settings.slug}`}
              additionalClass={'contact-button'}
              transparent
              color={'white'}
              ariaName={'Contact button'}
            >
              Contact
            </Button>
          </Link>
        </div>
      </Section>

      {!(isEmpty(articlesList) || isEmpty(related)) && (
        <RelatedArticles
          id={'cs-related'}
          related={related}
          allArticles={articlesList}
        />
      )}
    </div>
  );
};

export default CaseStudyContent;
