import React from 'react';

import { ContactFormBiz, Link } from 'components';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';
import { topics } from './logic';

const titleContactProps = 'Hoe kunnen we je helpen?';
const descriptionContactProps =
  'Vul ons contactformulier in en onze experts nemen zo snel mogelijk contact met je op.';
const descriptionTwoContactProps = 'Ik heb interesse in:';
const privateDataInfoContactProps = () => (
  <>
    Jouw data is veilig bij ons. We nemen jouw privacy serieus en delen geen
    informatie met derden. Lees onze{' '}
    <Link to={'/privacy-policy/'} id={'contact-biz-privacy-policy'}>
      Privacy Policy
    </Link>{' '}
    voor meer informatie.
  </>
);

const responseButtonTextContactProps = 'Maak kennis met ons team';

const responseSendContactHeaderContactProps = () => (
  <>
    Dank je wel!
    <br />
    We hebben je bericht ontvangen!
  </>
);

const responseSendContactHeaderTwoContactProps = () => (
  <>
    Onze experts zullen naar jouw idee kijken en zich binnen 24&nbsp; uur bij
    je melden.
    <br />
    Als we je in de tussentijd kunnen helpen, dan kun je ons bereiken op{' '}
    <a href={`tel:${CountryContactPhoneNumbers.Netherlands.value}`} additionalClass={'link__underline'}>
      {CountryContactPhoneNumbers.Netherlands.displayValue}
    </a>
  </>
);

const topicsDataContactProps = {
  topics,
};

const ContactFormNl = () => (
  <ContactFormBiz
    title={titleContactProps}
    description={descriptionContactProps}
    descriptionTwo={descriptionTwoContactProps}
    privateDataInfo={privateDataInfoContactProps}
    topicsData={topicsDataContactProps}
    button={'Verstuur'}
    phoneText={'Telefoon:'}
    emailText={'E-mail:'}
    firstNameContent={'Naam'}
    companyEmailContent={'E-mailadres'}
    textareaContent={'Projectbeschrijving'}
    id={'form'}
    lang={'nl'}
    name={'contact-form-biz'}
    pardotLink={'https://go.boldare.com/l/688533/2022-10-28/2cfl3t'}
    meetButton
    responseButtonText={responseButtonTextContactProps}
    responseSendContactHeader={responseSendContactHeaderContactProps}
    responseSendContactHeaderTwo={responseSendContactHeaderTwoContactProps}
  />
);

export default ContactFormNl;
