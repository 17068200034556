import React, { useEffect, useRef, useState } from 'react';
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import './About.scss';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LoaderComponent from 'components/Loader';
import {
  SectionHello,
  SectionArrows,
  SectionDisrupters,
  SectionAwards,
  SectionEnvironment,
  SectionFounders,
  SectionGrowth,
  SectionHolaspirit,
  SectionWeight,
} from './subcomponents';

function About() {
  const aboutWrapper = useRef(null);

  const [isDesktop, setIsDesktop] = useState(false);
  const [loading, setLoading] = useState(true);
  gsap.registerPlugin(ScrollTrigger);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 600);
  };

  useEffect(() => {
    handleResize();
    setLoading(false);
  }, []);

  useEffect(() => {
    gsap.utils.toArray('.pinned-section').forEach(panel => {
      ScrollTrigger.create({
        trigger: panel,
        start: 'top top',
        pin: true,
      });
    });

    if (isDesktop) {
      gsap.to(aboutWrapper.current, {
        scrollTrigger: {
          trigger: aboutWrapper.current,
          start: '2% top', // 7.5% for version a, 2% for version b
          toggleClass: {
            targets: aboutWrapper.current,
            className: 'yellow',
          },
        },
      });
    }

    ScrollTrigger.refresh();
  }, [isDesktop]);

  return (
    <>
      {loading ? (
        <div className={'work-page__loader'}>
          <LoaderComponent isActive absolute />
        </div>
      ) : (
        <Experiment name={'about-us-ab'}>
          <Variant name={'about-us-a'}>
            <div className={'about-wrapper'} ref={aboutWrapper}>
              <SectionHello isDesktop={isDesktop} variant={'about-us-a'} />
              <SectionArrows isDesktop={isDesktop} variant={'about-us-a'} />
              <SectionDisrupters isDesktop={isDesktop} />
              <SectionWeight isDesktop={isDesktop} variant={'about-us-a'} />
              <SectionEnvironment
                isDesktop={isDesktop}
                variant={'about-us-a'}
              />
              <SectionAwards isDesktop={isDesktop} />
              <SectionGrowth isDesktop={isDesktop} />
              <SectionFounders isDesktop={isDesktop} />
              <SectionHolaspirit />
            </div>
          </Variant>
          <Variant name={'about-us-b'}>
            <div className={'about-wrapper'} ref={aboutWrapper}>
              <SectionHello isDesktop={isDesktop} variant={'about-us-b'} />
              <SectionArrows isDesktop={isDesktop} variant={'about-us-b'} />
              <SectionDisrupters isDesktop={isDesktop} />
              <SectionWeight isDesktop={isDesktop} variant={'about-us-b'} />
              <SectionEnvironment
                isDesktop={isDesktop}
                variant={'about-us-b'}
              />
              <SectionAwards isDesktop={isDesktop} />
              <SectionGrowth isDesktop={isDesktop} />
              <SectionFounders isDesktop={isDesktop} />
              <SectionHolaspirit />
            </div>
          </Variant>
        </Experiment>
      )}
    </>
  );
}

export default About;
