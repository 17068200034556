import React from 'react';
import PropTypes from 'prop-types';

const AnswerOption = props => {
  const { answerType, answerContent, answer, onAnswerSelected } = props;

  return (
    <li className={'pmf-survey__answer-item'}>
      <input
        type={'radio'}
        className={'pmf-survey__answer-input'}
        name={'radioGroup'}
        checked={answerType === answer}
        id={answerType}
        value={answerType}
        disabled={answer}
        onChange={onAnswerSelected}
      />
      <label className={'pmf-survey__answer-label'} htmlFor={answerType}>
        <span className={'pmf-survey__answer-label-content'}>
          <span className={'pmf-survey__answer-label-variant'} />
          {answerContent}
        </span>
      </label>
    </li>
  );
};

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
};

AnswerOption.defaultProps = {};

export default AnswerOption;
