import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import { pages } from 'config/pages';
import EventFpmfAmsterdam from 'views/eventFpmfAmsterdam';
import config from '../../meta/config';

const page = pages['finding-pmf-amsterdam'];
const { navColors } = page;
const url = `${config.siteUrl}/finding-pmf-amsterdam/`;
const canonical = `${url}`;

const EventFindingPmfPageAmsterdam = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} banner={false} />
      <EventFpmfAmsterdam />
      <Footer />
    </Layout>
  );
};

export default EventFindingPmfPageAmsterdam;
