import React from 'react';
import './GermanMedia.scss';
import {
  BoxCaseStudy,
  Button,
  Link,
  LoadMorePagination,
  Paragraph,
  Section,
  SectionHeader,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { articleData } from './logic';

const renderBox = posts => {
  return posts.map(post => {
    return (
      <div
        className={
          'contact-de-german-media__ref-media-box-wrapper contact-de-german-media--position-relative'
        }
      >
        <BoxCaseStudy
          isTargetBlank
          data={{
            ...post,
            content: {
              ...post.content,
              coverImage: getCloudinaryUrl(
                post.content.coverImage,
                'v1645487837',
              ),
            },
          }}
          additionalClass={'contact-de-german-media__ref-media-box'}
          id={post.id}
          key={`case-study-${post.id}`}
        />
        <div className={'contact-de-german-media__media-logo'}>
          <img
            src={post.content.mediaLogo.icon}
            className={'image'}
            alt={post.content.mediaLogo.alt}
            loading={'lazy'}
          />
        </div>
      </div>
    );
  });
};

const renderCaseStudies = items => (
  <div className={'contact-de-german-media__articles-wrapper'}>
    {renderBox(items)}
  </div>
);

const GermanMedia = () => {
  return (
    <LoadMorePagination
      index={'case-studies'}
      loadCount={3}
      data={articleData}
    >
      {({ items, loadMore }) => (
        <Section
          additionalClass={
            'contact-de-german-media__section contact-de-german-media--position-relative'
          }
          id={'contact-de-german-media'}
        >
          <Row className={'contact-de-german-media__text-header-row'}>
            <Col
              xs={12}
              md={8}
              className={'contact-de-german-media__text-header-col'}
            >
              <SectionHeader
                size={'large'}
                color={'mine-shaft'}
                lineColor={'violet'}
                descriptionColor={'black'}
                fixed
                noLine
                additionalClass={'contact-de-german-media__text-header'}
              >
                Boldare in <br />
                deutschen Medien:
              </SectionHeader>
            </Col>
            <Col
              xs={12}
              md={4}
              className={'contact-de-german-media__text-header-two-col'}
            >
              <Paragraph
                color={'mine-shaft'}
                size={'medium-constant'}
                additionalClass={'contact-de-german-media__text-header-two'}
              >
                Lesen Sie über uns und unsere Arbeit in deutschen Medien
              </Paragraph>
            </Col>
          </Row>
          {renderCaseStudies(items)}
          <div className={'contact-de-german-media__load-article-btn-wrapper'}>
            <div
              className={'contact-de-german-media__lead-article-btn__line'}
            />
            {loadMore && (
              <Button
                tag={Link}
                to={loadMore.path}
                color={'white'}
                onClick={loadMore.action}
                ariaName={'Load more button'}
              >
                Mehr laden
              </Button>
            )}
          </div>
        </Section>
      )}
    </LoadMorePagination>
  );
};

export default GermanMedia;
