export const data = [
  {
    icon: 'rocket',
    title: 'Starting a new business',
    description:
      'We will help you to create and develop a product that will reflect your business goals.',
  },
  {
    icon: 'internet',
    title: 'Adding new features',
    description:
      'Need to develop a new feature or service for an existing business? We are here to help, we specialize in custom software development.',
  },
  {
    icon: 'online-shop',
    title: 'Digital Transformation',
    description:
      'We have the know-how, experience and resources to boost the digital transformation process in your organization.',
  },
  {
    icon: 'project-management',
    title: 'Idea validation',
    description:
      'We will work closely with your team to define the most suitable business goals, and test the initial hypothesis with a developed product.',
  },
];
