import React from 'react';

import { Button, Checkbox } from 'components';
import { classNames } from 'utils';
import { usePardotForm } from 'hooks/index';

import './NewsletterBox.scss';

export const NewsletterBox = () => {
  const formRef = React.useRef();
  const [email, setEmail] = React.useState(''); // @TODO find a way to handle filled email label
  const [isOptedIn, setIsOptedIn] = React.useState(false); // @TODO find a way to handle filled checkbox

  const { handleSubmit, isLoading, succeeded, failed } = usePardotForm(
    formRef,
    'https://go.boldare.com/l/688533/2022-12-06/2f65lf'
  );

  return (
    <div className={'newsletter-box'}>
      {!succeeded ? (
        <>
          <div className={'newsletter-box__text'}>
            <p className={'newsletter-box__heading'}>
              Sign up now!
            </p>
            <p className={'newsletter-box__description'}>
              And get best articles directly to your email
            </p>
          </div>
          <form
            className={'newsletter-box__content'}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className={'newsletter-box__inputs'}>
              <div className={'newsletter-box__input-container'}>
                <input
                  className={'newsletter-box__input'}
                  id={'newsletter-box-name'}
                  type={'text'}
                  name={'firstName'}
                  required
                />
                <label
                  className={'newsletter-box__label'}
                  htmlFor={'newsletter-box-name'}
                >
                  First name
                </label>
              </div>
              <div className={'newsletter-box__input-container'}>
                <input
                  id={'newsletter-box-email'}
                  className={classNames('newsletter-box__input', {
                    'newsletter-box__input-filled': email.length
                  })}
                  type={'email'}
                  name={'email'}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <label
                  className={'newsletter-box__label'}
                  htmlFor={'newsletter-box-email'}
                >
                  E-mail address
                </label>
              </div>
              <Checkbox
                additionalClass={'newsletter-box__checkbox'}
                checked={isOptedIn}
                label={'I agree to receive communications from Boldare. Communications may include updates on upcoming events, useful business insights and promotional materials about our services.'}
                name={'optedForMarketing'}
                value={'optedForMarketing'}
                onChange={() => setIsOptedIn(!isOptedIn)}
                required
              />
            </div>
            {!isLoading ? (
              <Button
                additionalClass={'newsletter-box__button ga-newsletter-box-button'}
                id={'newsletter-box-button'}
                type={'submit'}
                color={'yellow'}
                ariaName={'Newsletter sign up'}
              >
                Get a newsletter
              </Button>
            ) : (
              <div className={'newsletter-box__loader'} />
            )}
          </form>
        </>
      ) : (
        <p className={'newsletter-box__greetings'}>Thank you for sign up!</p>
      )}
    </div>
  )
};
