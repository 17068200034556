import polcoMp4 from 'assets/videos/polco.mp4';
import polcoWebm from 'assets/videos/polco.webm';
import theworkroomMp4 from 'assets/videos/the-workroom.mp4';
import theworkroomWebm from 'assets/videos/the-workroom.webm';
import sonnenMp4 from 'assets/videos/sonnen.mp4';
import sonnenWebm from 'assets/videos/sonnen.webm';

export const data = [
  {
    video: [
      { src: polcoWebm, type: 'video/webm' },
      { src: polcoMp4, type: 'video/mp4' },
    ],
    image: 'polcoImg',
    intro: 'Polco, USA',
    coverImg: 'homepage/main-banner/sonnen-banner.jpg',
    headline: 'Voting platform for American startup',
    description:
      'Finding a product development team that can guide you from a prototype to a scalable app.',
    color: 'pigeon-post',
    link: '/work/case-study-polco/',
  },
  {
    video: [
      { src: theworkroomWebm, type: 'video/webm' },
      { src: theworkroomMp4, type: 'video/mp4' },
    ],
    image: 'theworkroomImg',
    intro: 'London, UK',
    coverImg: 'homepage/main-banner/polco-placeholder.jpg',
    headline: 'Changing London’s coworking scene',
    description:
      'Delivering a core web product when your in&#8209;house development team is busy.',
    color: 'pigeon-post',
    link: '/work/the-workroom-case-story/',
  },
  {
    video: [
      { src: sonnenWebm, type: 'video/webm' },
      { src: sonnenMp4, type: 'video/mp4' },
    ],
    image: 'sonnenImg',
    intro: 'Sonnen, Germany',
    coverImg: 'homepage/main-banner/theworkroom-placeholder.png',
    headline: 'Web app visualizing energy',
    description:
      'Delivering a core web product when your in&#8209;house development team is busy.',
    color: 'pigeon-post',
    link: '/work/case-study-sonnen/',
  },
];
