import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Container } from 'components';
import { classNames } from 'utils';

import './Contact.scss';
import chosenAnswers from 'views/appCostsCalculator/logic/chosenAnswers';
import { pardotLink } from 'config/constants/pardotFormHandlers';

const Contact = ({setNextState, setContact, phase, finalAnswers}) => {
  const [form, setForm] = useState({
    message: `Phase: ${phase}, \nAnswers: ${chosenAnswers(finalAnswers).map(answer => `\n${answer}`)}`,
    agreedPrivacyPolicy: false,
  });

  const [isCheckedProcessing, setIsCheckedProcessing] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }

  const handleCheckboxProcessing = () => {
    setIsCheckedProcessing(prevChecked => !prevChecked);
    setForm(prevForm => ({
      ...prevForm,
      agreedPrivacyPolicy: !prevForm.agreedPrivacyPolicy,
    }));

  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const body = new URLSearchParams(form).toString();
    try {
      await fetch(pardotLink.appCostCalculatorLeadCapture, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      });
      setContact(form);
      setNextState();
    }
    catch(error) {
        console.log(error)
        setFailed(true);
        setTimeout(() => { setFailed(false); }, 5000);
      }
  };

  return (
    <div className={'see-results-contact-form'}>
      <Container>
        <div className={'see-results-contact-form__text-content'}>
          <div className={'see-results-contact-form__heading'}>
            Almost there!
          </div>
          <div className={'see-results-contact-form__description'}>
            Just one more step - fill out the form to get all the details and costs
          </div>
        </div>
        <form
          className={'see-results-contact-form__form'}
          method={'post'}
          onSubmit={!failed ? handleSubmitForm: (e) => e.preventDefault()}
        >
          <div className={'see-results-contact-form__input-container'}>
            <input
              className={'see-results-contact-form__input'}
              type={'text'}
              name={'name'}
              id={'contact-form-first-name'}
              onChange={handleChange}
              required
            />
            <label
              className={'see-results-contact-form__label'}
              htmlFor={'contact-form-first-name'}
            >
              Name
            </label>
          </div>
          <div className={'see-results-contact-form__input-container'}>
            <input
              type={'email'}
              name={'email'}
              id={'contact-form-email'}
              className={classNames('see-results-contact-form__input', {
                'input--filled': form.email,
              })}
              onChange={handleChange}
              required
            />
            <label
              className={'see-results-contact-form__label'}
              htmlFor={'contact-form-email'}
            >
              Email Address
            </label>
          </div>
          <div className={'see-results-contact-form__input-container'}>
            <input
              className={'see-results-contact-form__input'}
              type={'text'}
              name={'company'}
              id={'contact-form-company-name'}
              onChange={handleChange}
              required
            />
            <label
              className={'see-results-contact-form__label'}
              htmlFor={'contact-form-company-name'}
            >
              Company name
            </label>
          </div>
          <div className={'see-results-contact-form__checkbox-container'}>
            <Checkbox
              checked={isCheckedProcessing}
              onChange={handleCheckboxProcessing}
              name={'agreedPrivacyPolicy'}
              value={'agreedPrivacyPolicy'}
              label={'I agree to the processing of my personal data in accordance with Boldare’s Privacy Policy'}
              additionalClass={'see-results-contact-form__checkbox'}
              required
            />
          </div>
          {!failed ?
            <Button
              type={'submit'}
              color={'yellow'}
              additionalClass={'see-results-contact-form__button'}
            >
              See the results
            </Button> :
            <Button
              disabed
              type={'submit'}
              color={'red'}
              additionalClass={'see-results-contact-form__button'}
            >
              Something went wrong. Please try again later.
            </Button>
          }

          <p className={'see-results-contact-form__footnote'}>
            Your data is safe with us. We take your privacy seriously and we
            will never share your data with anyone. Read our{' '}
            <a
              href={'/privacy-policy/'}
              className={'see-results-contact-form__footnote-link'}
            >
              Privacy Policy
            </a>{' '}
            to learn more.
          </p>
        </form>
      </Container>
    </div>
  );
}

Contact.propTypes = {
  setNextState: PropTypes.func,
  setContact: PropTypes.func,
  phase: PropTypes.string.isRequired,
  finalAnswers: PropTypes.object.isRequired,
};

Contact.defaultProps = {
  setNextState: null,
  setContact: null,
};

export default Contact;
