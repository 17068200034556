import React from 'react';
import PropTypes from 'prop-types';
import {
  findArticleByTitle,
  getArticleSlug,
  getCloudinaryUrl,
} from 'config/helpers';
import { Heading, Link, Paragraph } from '..';

import './RelatedArticle.scss';

const getArticlePreviewProps = () => ({
  category: 'Read also',
  cover: '/img/human-eye.jpg',
  slug: '/article-slug',
});

const getArticleProps = articleEdge => ({
  category: articleEdge.node.frontmatter.settings.category,
  cover: articleEdge.node.frontmatter.cover,
  slug: getArticleSlug(articleEdge),
});

const RelatedArticle = props => {
  const { context: { allArticles } = {}, title } = props;
  const isPreview = !allArticles;
  const articleEdge = isPreview
    ? null
    : findArticleByTitle(allArticles, title);
  const { category, cover, slug } = articleEdge
    ? getArticleProps(articleEdge)
    : getArticlePreviewProps();

  return (
    <div className={'related-article-widget related-article-widget--spaced'}>
      <Paragraph
        size={'small'}
        additionalClass={'related-article-widget__header text--bold'}
      >
        You might be also interested in the article:
      </Paragraph>
      <Link additionalClass={'related-article-widget__link'} to={slug}>
        <div className={'box__wrapper--carrara related-article'}>
          <div className={'related-article__left'}>
            <img
              src={getCloudinaryUrl(cover, 'f_auto,q_auto,h_300')}
              alt={title}
              className={'image related-article__image'}
              loading={'lazy'}
            />
          </div>
          <div className={'related-article__right'}>
            <Paragraph
              size={'small'}
              color={'red'}
              additionalClass={'related-article__category text--bold'}
            >
              {category}
            </Paragraph>
            <Heading
              type={3}
              size={'medium'}
              additionalClass={'related-article__title no-toc'}
            >
              {title}
            </Heading>
          </div>
        </div>
      </Link>
    </div>
  );
};

RelatedArticle.defaultProps = {
  context: {},
};

RelatedArticle.propTypes = {
  context: PropTypes.shape({
    allArticles: PropTypes.any,
  }),
  title: PropTypes.string.isRequired,
};

export default RelatedArticle;
