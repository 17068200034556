export const services = [
  {
    name: 'youtube',
    attributes: {
      frameborder: 0,
      allow:
        'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      allowfullscreen: true,
    },
  },
];
