export const imagesData = [
  {
    name: 'Symfony',
    alt: 'Symfony logo',
    img: 'logos/logo-symfony.png',
  },
  {
    name: 'Java',
    alt: 'Java logo',
    img: 'logos/logo-java.png',
  },
  {
    name: 'Angular',
    alt: 'Angular logo',
    img: 'logos/logo-angular.png',
  },
  {
    name: 'Python',
    alt: 'Python logo',
    img: 'logos/logo-python.png',
  },
  {
    name: 'Php',
    alt: 'PHP logo',
    img: 'logos/logo-php.png',
  },
  {
    name: 'Android',
    alt: 'Android logo',
    img: 'logos/logo-android.png',
  },
  {
    name: 'Spring',
    alt: 'Spring logo',
    img: 'logos/logo-spring.png',
  },
  {
    name: 'Css',
    alt: 'CSS logo',
    img: 'logos/logo-css.png',
  },
  {
    name: 'Ios',
    alt: 'iOS logo',
    img: 'logos/logo-ios.png',
  },
  {
    name: 'React',
    alt: 'React logo',
    img: 'logos/logo-react.png',
  },
  {
    name: 'NodeJS',
    alt: 'NodeJS logo',
    img: 'logos/logo-node.png',
  },
  {
    name: 'Html',
    alt: 'HTML logo',
    img: 'logos/logo-html.png',
  },
];
