import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';

import './ResourceCard.scss';

const ResourceCard = ({ header, description, img, color, link }) => {
  return (
    <a href={link} target={'_blank'} rel={'noreferrer'}>
      <>
        <div className={'app-cost-thank-you-resource-card'}>
          <div
            className={`app-cost-thank-you-resource-card__slider--${color}`}
          ></div>
          <div>
            <div className={'app-cost-thank-you-resource-card__text'}>
              <h3 className={'app-cost-thank-you-resource-card__text--header'}>
                {header}
              </h3>
              <span
                className={
                  'app-cost-thank-you-resource-card__text--description'
                }
              >
                {description}
              </span>
            </div>
          </div>

          <div className={'app-cost-thank-you-resource-card__img'}>
            <img
              src={getCloudinaryUrl(img, 'f_auto,q_auto')}
              className={'image'}
              alt={header}
              loading={'lazy'}
            />
          </div>
        </div>
      </>
    </a>
  );
};

ResourceCard.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
};

export default ResourceCard;
