import React, { Component } from 'react';
import {
  Section,
  Heading,
  SectionHeader,
  Button,
  YouTubePlayer,
} from 'components';
import Slider from 'react-slick';
import { getCloudinaryUrl } from 'config/helpers';

class Video extends Component {
  constructor() {
    super();

    this.state = {
      shouldVideoAppear: false,
      isMobile: false,
      sliderSettings: {
        className: 'career-page-slider',
        dotsClass: 'slick-dots slick-pagination career-page-slider-dots',
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 500,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  showVideo = (shouldVideoAppear = true) => {
    this.setState({ shouldVideoAppear });
  };

  render() {
    const { shouldVideoAppear, sliderSettings } = this.state;

    return (
      <Section
        additionalClass={'career-video'}
        color={'mine-shaft'}
        id={'teamwork'}
      >
        <img
          className={'career-video--hands career-video--hands-top'}
          alt={'Boldare Career - international tech company'}
          src={getCloudinaryUrl(
            'career/career-hand-set.png',
            'f_auto,q_auto,w_185',
          )}
        />
        <img
          className={'career-video--maps career-video--maps-1'}
          alt={'Boldare Career - international tech company'}
          src={getCloudinaryUrl(
            'career/career-video-map.svg',
            'f_auto,q_auto',
          )}
        />
        <img
          className={'career-video--maps career-video--maps-2'}
          alt={'Boldare Career - international tech company'}
          src={getCloudinaryUrl(
            'career/career-video-map-2.svg',
            'f_auto,q_auto',
          )}
        />
        <Heading
          type={3}
          size={'medium'}
          color={'white'}
          additionalClass={'headline--top headline--top-blue'}
        >
          Boldare Teamwork
        </Heading>
        <SectionHeader
          additionalClass={'headline--centered'}
          color={'white'}
          size={'medium'}
          headingType={2}
          noLine
        >
          Why do we love <br />
          <span className={'headline--marking headline--marking-blue'}>
            face-to-face
          </span>{' '}
          mode?
        </SectionHeader>
        <div
          className={`w-container career-video__yt ${
            shouldVideoAppear ? '' : 'career-video--autoplay'
          }`}
        >
          <img
            className={'career-video--hands career-video--hands-vid'}
            alt={'Boldare Career - international tech company'}
            src={getCloudinaryUrl(
              'career/career-hand-set-2.png',
              'f_auto,q_auto,w_347',
            )}
          />
          <Slider
            ref={slider => {
              this.slider = slider;
            }}
            {...sliderSettings}
          >
            <div>
              {!shouldVideoAppear && (
                <Button
                  additionalClass={'career-video__button'}
                  color={'white'}
                  transparent
                  onClick={this.showVideo}
                >
                  <span className={'career-video__play'} />
                </Button>
              )}
              <YouTubePlayer
                title={"Why join Boldare? Cause it's awesome to work with us!"}
                src={'https://www.youtube.com/watch?v=GHnNlPj79E8'}
                overlayed={!shouldVideoAppear}
                controls
                loop
                volume={shouldVideoAppear ? 1 : 0}
              />
            </div>
            <div>
              {!shouldVideoAppear && (
                <Button
                  additionalClass={'career-video__button'}
                  color={'white'}
                  transparent
                  onClick={this.showVideo}
                >
                  <span className={'career-video__play'} />
                </Button>
              )}
              <YouTubePlayer
                title={"Why join Boldare? Cause it's awesome to work with us!"}
                src={'https://www.youtube.com/watch?v=NAlGWVhYVZQ'}
                overlayed={!shouldVideoAppear}
                controls
                loop
                volume={shouldVideoAppear ? 1 : 0}
              />
            </div>
          </Slider>
        </div>
      </Section>
    );
  }
}

export default Video;
