import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Section, Button, Icon, SectionHeader } from 'components';
import { ICON_TYPES } from 'config';
import MailBox from 'assets/images/mailbox.svg';
import { generateHash, classNames, navigateToId } from '../../utils';
import { topics, steps } from './logic.js';

import './ContactMultiStepForm.scss';

class ContactMultiStepForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      form: {
        name: '',
        lastName: '',
        email: '',
        company: '',
        topicFilter: null,
      },
    };
  }

  componentDidMount() {
    const { id } = this.props;

    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      this.setState({ step: 2 });
      navigateToId(id);
    }
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const { form } = this.state;
    const { pardotLink } = this.props;
    const body = Object.keys(form)
      .map(key => `${key}=${form[key]}`)
      .join('&');

    this.setState({ isLoading: true });

    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you&connectionId=${generateHash()}`;
        }
      })
      .catch(error => console.log(error));
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  toggleTopicFilterView = () => {
    this.setState(prevState => ({
      isTopicActive: !prevState.isTopicActive,
    }));
  };

  updateFilters(label, filter) {
    const keysByLabel = {
      Topic: 'topicFilter',
    };

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [keysByLabel[label]]: filter,
      },
    }));
  }

  checkResults(label, filter) {
    this.updateFilters(label, filter);
  }

  renderTopicFilters = (label, data) => {
    const { topicFilter } = this.state;

    const filters = data.map(filter => (
      <li
        key={`filter-${filter}`}
        className={classNames('filters__wrapper-item', {
          'filters__wrapper-item--active': topicFilter === filter,
        })}
        onClick={() => {
          this.checkResults(label, filter);
        }}
      >
        {filter}
      </li>
    ));

    return (
      <ul className={'contact-form-multistep__filters-list'}>{filters}</ul>
    );
  };

  renderSteps = data => {
    const { step } = this.state;

    const steps = data.map((stepIndex, index) => (
      <li
        key={`filter-${stepIndex}`}
        className={classNames('stepper__item', {
          'stepper__item--complete': index < step,
          'stepper__item--active': index === step,
        })}
      >
        <span className={'stepper__count'}>{index + 1}</span>
        <span className={'stepper__label'}>{stepIndex}</span>
      </li>
    ));

    return <ol className={'stepper'}>{steps}</ol>;
  };

  render() {
    const {
      headingStep1,
      headingStep2,
      headingStep3,
      name,
      thankYouCopy,
      id,
    } = this.props;

    const { step, form, isLoading } = this.state;

    return (
      <Section
        id={id}
        color={'white'}
        additionalClass={'contact-form-multistep'}
      >
        <div className={'contact-form-multistep__stepper'}>
          {this.renderSteps(steps)}
        </div>

        <div className={'contact-form-multistep__content'}>
          {step === 0 && (
            <div className={'contact-form-multistep__step1'}>
              <SectionHeader size={'big'} lineColor={'red'} fixed>
                {headingStep1}
              </SectionHeader>
              <div className={'contact-form-multistep__filters'}>
                <div
                  className={classNames('filters__wrapper', {
                    opened: this.state.isTopicActive,
                  })}
                  onClick={this.toggleTopicFilterView}
                >
                  <Paragraph>
                    {form.topicFilter ||
                      'Select a topic you want to discuss during a call'}
                  </Paragraph>
                  <Icon
                    icon={ICON_TYPES.RIGHT_ARROW}
                    size={'default'}
                    color={'black'}
                  />
                  {this.renderTopicFilters('Topic', topics)}
                </div>
              </div>
              <Button
                id={`contact-form-multistep-${name}-next`}
                additionalClass={classNames('multistep-button', {
                  inactive: form.topicFilter === null,
                })}
                color={'yellow'}
                ariaName={'Next button'}
                onClick={this.nextStep}
                disabled={form.topicFilter === null}
              >
                Next
              </Button>
            </div>
          )}
          {step === 1 && (
            <div className={'contact-form-multistep__step2'}>
              <SectionHeader size={'big'} lineColor={'red'} fixed>
                {headingStep2}
              </SectionHeader>
              <form
                name={name}
                className={classNames('contact-form-multistep__form', {
                  'contact-form-multistep__form--hidden': step === 2,
                })}
                method={'post'}
                onSubmit={this.handleSubmitForm}
              >
                <div className={'contact-form-multistep__form-data'}>
                  <input
                    type={'text'}
                    name={'name'}
                    placeholder={'First name'}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type={'text'}
                    name={'lastName'}
                    placeholder={'Last name'}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type={'email'}
                    name={'email'}
                    placeholder={'E-mail address'}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type={'text'}
                    name={'company'}
                    placeholder={'Company'}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <Button
                  id={`contact-form-multistep-${name}-submit`}
                  additionalClass={classNames(
                    'multistep-button',
                    'multistep-button--submit',
                    {
                      loading: isLoading,
                    },
                  )}
                  type={'submit'}
                  value={'submit'}
                  color={'yellow'}
                  ariaName={'Send message button'}
                  disabled={isLoading}
                  loading
                >
                  Send request
                </Button>
              </form>
            </div>
          )}
          {step === 2 && (
            <div
              className={
                'contact-form-multistep__step2 contact-form-multistep__success'
              }
            >
              <div className={'contact-form-multistep__success-info'}>
                <SectionHeader size={'big'} lineColor={'red'} fixed>
                  {headingStep3}
                </SectionHeader>
                <Paragraph size={'medium'}>{thankYouCopy}</Paragraph>
              </div>
              <div className={'contact-form-multistep__success-image'}>
                <img
                  src={MailBox}
                  className={'image footer__logo'}
                  alt={'Thank you!'}
                  loading={'lazy'}
                />
              </div>
            </div>
          )}
        </div>
      </Section>
    );
  }
}

ContactMultiStepForm.propTypes = {
  headingStep1: PropTypes.string.isRequired,
  headingStep2: PropTypes.string.isRequired,
  headingStep3: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thankYouCopy: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ContactMultiStepForm.defaultProps = {
  thankYouCopy:
    'Thank you! We’ve just sent you an email with more information about next steps and a link to schedule a call.',
};

export default ContactMultiStepForm;
