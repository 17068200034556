import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { ArrowShort, Orbit, SectionWide } from 'components/DS';
import { HighlightShort, Heading, Section } from 'components';
import { CLIENTS } from './clientsList';
import { ClientTile } from './subcomponents/ClientTile';
import { SesBanner } from '../../components/SeS/index';

import './Clients.scss';

export const Clients = () => (
  <div className={'clients'}>
    <SectionWide color={'pigeon-post-light'} noOverflow>
      <div className={'clients__orbit-container'}>
        <Orbit theme={'light'} additionalOrbit />
      </div>
      <div className={'clients__heading'}>
        <Heading type={1} size={'large'} additionalClass={'clients__title'}>
          <HighlightShort fill={'#7563E7'} textColor={'white'}>
            Clients
          </HighlightShort>{' '}
          whose missions we proudly share
          <br />
        </Heading>
        <div className={'clients__link-wrapper'}>
          <a href={'/work'} className={'clients__link'}>
            SEE CASE STUDIES
            <div className={'clients__link-arrow'}>
              <ArrowShort />
            </div>
          </a>
        </div>
      </div>
    </SectionWide>
    <SectionWide
      additionalClass={'clients__tiles-section'}
      color={'pigeon-post-light'}
    >
      <Row className={'clients__logos-row'}>
        {CLIENTS.map(({ name, photo, description }) => (
          <Col
            className={'clients__logos-column'}
            xs={12}
            sm={6}
            md={4}
            xl={3}
          >
            <ClientTile name={name} photo={photo} description={description} />
          </Col>
        ))}
      </Row>
    </SectionWide>
    <Section fullwidth color={'pigeon-post-light'}>
      <SesBanner pageName={'clients'} />
    </Section>
  </div>
);
