import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { Section, SectionHeader, UnorderedList } from 'components';
import './Benefits.scss';

const Benefits = () => (
  <div className={'digital-transformation__benefits'}>
    <Section
      color={'white'}
      additionalClass={'digital-transformation__benefits-headline'}
    >
      <SectionHeader
        size={'big'}
        lineColor={'red'}
        description={
          'Digital transformation will have a great impact on your business. Here are some examples of benefits you will experience:'
        }
        headingType={2}
      >
        Benefits of Digital Transformation
      </SectionHeader>
    </Section>
    <Section
      color={'white'}
      spaceless
      fullwidth
      side={'left'}
      additionalClass={'digital-transformation__benefits-description'}
    >
      <Row className={'no-gap'}>
        <Col sm={12} md={6} lg={8}>
          <div className={'digital-transformation__benefits-image'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/office-3.jpg',
                'f_auto,q_auto,w_1280',
              )}
              className={'image'}
              alt={'Benefits of Digital Transformation'}
              loading={'lazy'}
            />
          </div>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={4}
          className={'digital-transformation__benefits-container'}
        >
          <div
            className={
              'section w-container--right digital-transformation__benefits-inner'
            }
          >
            <UnorderedList
              additionalClass={'text--black text--bold'}
              items={[
                'Better ROI by delivering personalized solutions based on customer intelligence.',
                'Increase revenue by accessing clients world-wide',
                'Improve retention and advocacy by delivering proactive customer services that exceed customer expectations',
                'Reduced waste by amplifying efficiency and boost revenue from operations',
                'Empower employees and increase workforce productivity and drive innovation',
                'Reduce costs and risks with informed decision-making',
                'Form closer and more productive partnerships with increased collaboration',
              ]}
            />
          </div>
        </Col>
      </Row>
    </Section>
  </div>
);

export default Benefits;
