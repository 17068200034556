import { Button, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import React, { useState } from 'react';
import LinkedIn from 'assets/images/logos/linkedin.svg';
import './Speaker.scss';

// eslint-disable-next-line react/prop-types
function Speaker({ img, firstName, lastName, title, bio, linkedin, active }) {
  const [showBio, setShowBio] = useState(false);
  return (
    <div className={'showcase-speaker'}>
      <div className={'showcase-image-center'}>
        <img
          className={
            active ? 'image showcase-image' : 'image showcase-image blur'
          }
          alt={firstName}
          src={getCloudinaryUrl(`${img}`, 'f_auto,q_60')}
          loading={'lazy'}
        />
      </div>
      <div
        className={
          active ? 'showcase-speaker-info' : 'showcase-speaker-info blur'
        }
      >
        <span className={'showcase-speaker-name'}>{firstName}</span>
        <br />
        <span className={'showcase-speaker-name'}>{lastName}</span>
        <br />
        <span className={'showcase-speaker-title'}>{title}</span>
        {showBio && (
          <div className={'showcase-speaker-mobile'}>
            {' '}
            <Paragraph
              color={'black'}
              size={'small'}
              additionalClass={'showcase-speaker-bio'}
            >
              {bio}
            </Paragraph>
            {active && (
              <a href={linkedin} target={'_blank'} rel={'noreferrer'}>
                <img
                  id={'linkedin-showcase'}
                  src={LinkedIn}
                  alt={'LinkedIn'}
                  loading={'lazy'}
                />
              </a>
            )}
          </div>
        )}
        <div className={'showcase-speaker-fullscreen-bio'}>
          {' '}
          <Paragraph
            color={'black'}
            size={'small'}
            additionalClass={'showcase-speaker-bio'}
          >
            {bio}
          </Paragraph>
          {active && (
            <a
              href={linkedin}
              target={'_blank'}
              className={'showcase-button-linkedin'}
              rel={'noreferrer'}
            >
              <img
                id={'linkedin-showcase'}
                src={LinkedIn}
                alt={'LinkedIn'}
                loading={'lazy'}
              />
            </a>
          )}
        </div>
        <div className={'showbio-button showcase-image-center'}>
          {!showBio ? (
            <Button
              color={'white'}
              onClick={() => {
                setShowBio(true);
              }}
              disabled={!active}
            >
              Show info
            </Button>
          ) : (
            <Button
              color={'white'}
              onClick={() => {
                setShowBio(false);
              }}
              disabled={!active}
            >
              Hide info
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Speaker;
