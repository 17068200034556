import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Heading, Paragraph, BackgroundImage, Button } from 'components';
import './BoxHashtag.scss';

class BoxHashtag extends Component {
  render() {
    const {
      background,
      colors,
      tag,
      backgroundColor,
      additionalClass,
      description,
      link,
      headingType,
      id,
      name,
    } = this.props;

    return (
      <Box
        id={id}
        backgroundColor={backgroundColor}
        additionalClass={`box-hashtag ${additionalClass}`}
      >
        <div className={'box-hashtag__content'}>
          <Paragraph
            size={'big'}
            color={colors[0]}
            additionalClass={'box-hashtag__hash text--bold'}
          >
            #
          </Paragraph>
          <Heading
            size={'big'}
            color={colors[1]}
            type={headingType}
            additionalClass={'box-hashtag__tag'}
          >
            {ReactHtmlParser(tag)}
          </Heading>
          {description && (
            <Paragraph
              color={colors[2]}
              additionalClass={'text--light'}
              spaced
            >
              {ReactHtmlParser(description)}
            </Paragraph>
          )}
        </div>
        {background && (
          <BackgroundImage
            src={background}
            additionalClass={'box-hashtag__background'}
            alt={name}
          />
        )}
        <div className={'box-hashtag__footer'}>
          {link && (
            <a href={link}>
              <Button
                order={'secondary'}
                color={colors[2]}
                ariaName={'Discover'}
              >
                Discover
              </Button>
            </a>
          )}
        </div>
      </Box>
    );
  }
}

BoxHashtag.defaultProps = {
  additionalClass: '',
  background: '',
  backgroundColor: '',
  description: '',
  colors: ['black', 'black', 'black'],
  link: '',
  id: '',
  name: '',
  headingType: 3,
};

BoxHashtag.propTypes = {
  additionalClass: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backgroundColor: PropTypes.string,
  colors: PropTypes.array,
  link: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  tag: PropTypes.string.isRequired,
  headingType: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  name: PropTypes.string,
};

export default BoxHashtag;
