import React, { useRef } from 'react';
import { Section, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';
import Rim from 'assets/images/practitest/rim.svg';
import Arrow from 'assets/images/practitest/white-arrow.svg';
import './Intro.scss';

const Intro = () => {
  const websites = useRef();
  useScrollAnimation([
    {
      ref: websites,
      transform: { translateY: '10%' },
    },
  ]);

  return (
    <>
      <Section additionalClass={'pt__intro-container'}>
        <Paragraph
          size={'medium-plus-small'}
          additionalClass={`text--bold`}
          color={'mine-shaft'}
        >
          At Boldare, we love working with clients from all over the world –
          different territories, different markets, different needs...
        </Paragraph>
        <div className={'pt__intro-rim-container'}>
          <Paragraph
            size={'medium-plus-small'}
            color={'mine-shaft'}
            additionalClass={'pt__intro-rim'}
          >
            <img
              src={Rim}
              alt={`Rim`}
              loading={'lazy'}
              className={'pt__hide-mobile'}
            />
            This project connected us to Israel for the first time. It began
            with a single goal – update a website – and became a case of mutual
            admiration and long-term collaboration.
          </Paragraph>
        </div>
        <Paragraph size={'medium-plus-small'} color={'mine-shaft'}>
          This ongoing cooperation began with a need for dedicated designers
          and developers to augment PractiTest’s manpower. It began with
          building a new website and has grown to include UX improvements,
          application development, and added value for PractiTest customers.
        </Paragraph>
        <div className={'pt__intro-arrow pt__hide-mobile'}>
          <img
            src={Arrow}
            alt={`Arrow`}
            loading={'lazy'}
            className={'anim-fade-in-left'}
          />
        </div>
      </Section>
      <Section additionalClass={'pt__intro-websites'}>
        <div className={'pt__intro-boxes'}>
          <div className={'pt__intro-box'}>
            <img
              className={'image pt__intro-image anim-fade-in-bottom'}
              src={getCloudinaryUrl(
                'assets/practitest/practitest-screenshot.png',
                'f_auto,q_auto',
              )}
              alt={'Old website'}
              loading={'lazy'}
            />
          </div>
          <div className={'pt__intro-box'}>
            <img
              className={'image pt__intro-image anim-fade-in-bottom'}
              src={getCloudinaryUrl(
                'assets/practitest/boldare-crew.png',
                'f_auto,q_auto',
              )}
              alt={'New website'}
              loading={'lazy'}
            />
          </div>
        </div>
      </Section>
    </>
  );
};

export default Intro;
