import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import Arrow from 'assets/images/practitest/yellow-arrow.svg';
import { getCloudinaryUrl } from 'config/helpers';
import './OldWebsite.scss';

class OldWebsite extends Component {
  render() {
    return (
      <Section additionalClass={'pt__old-website-container'} spaceless>
        <div className={'pt__old-website-boxes'}>
          <img
            src={getCloudinaryUrl(
              'assets/practitest/clients-1.png',
              'f_auto,q_auto',
            )}
            alt={'Clients'}
            className={'pt__old-website-box anim-fade-in-bottom'}
          />
          <img
            src={getCloudinaryUrl(
              'assets/practitest/clients-2.png',
              'f_auto,q_auto',
            )}
            alt={'Clients'}
            className={'pt__old-website-box anim-fade-in-bottom'}
          />
        </div>
        <div className={'pt__old-website__content'}>
          <Heading type={2} size={'large'}>
            Our client gets a new look
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            The biggest demand on us was to create a completely new appearance
            for an existing product website, including all of its features. We
            took all the existing data and content and crafted a new experience
            where the app is presented as an easy-to-learn and easy-to-master
            product.
          </Paragraph>
          <div className={'pt__details-wrapper'}>
            <div className={'pt__details-container'}>
              <div className={'pt__details-box'}>
                <div className={'pt__details-arrow'}>
                  <img
                    src={Arrow}
                    alt={`Arrow`}
                    loading={'lazy'}
                    className={'anim-fade-in-left'}
                  />
                </div>
                <Heading type={3} size={'medium'}>
                  Data visualisation with
                  <br />a mission of explanation
                </Heading>
                <Paragraph
                  additionalClass={'text--light'}
                  size={'medium-constant'}
                  color={'mine-shaft'}
                >
                  The old website was almost all just plain text. We had to add
                  some space and hierarchy to almost everything in order to
                  provide the users with comfortable viewing flow.
                </Paragraph>
              </div>
              <div className={'pt__details-box'}>
                <div className={'pt__details-arrow'}>
                  <img
                    src={Arrow}
                    alt={`Arrow`}
                    loading={'lazy'}
                    className={'anim-fade-in-left'}
                  />
                </div>
                <Heading type={3} size={'medium'}>
                  No hardware branding
                </Heading>
                <Paragraph
                  additionalClass={'text--light'}
                  size={'medium-constant'}
                  color={'mine-shaft'}
                >
                  As PractiTest is a multi-OS platform, they don’t want to be
                  connected with specific hardware brands. We had to use
                  different models to the ones from Cupertino’s company.
                </Paragraph>
              </div>
            </div>
            <div className={'pt__details-image  pt__hide-mobile'}>
              <img
                src={getCloudinaryUrl(
                  'assets/practitest/clients-3.png',
                  'f_auto,q_auto',
                )}
                alt={'Clients'}
                className={'pt__old-website-box anim-fade-in-right'}
              />
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default OldWebsite;
