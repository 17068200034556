import React, { useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { graphql } from 'gatsby';
import { pages } from 'config/pages';
import F2F from 'views/f2f';
import { NavBar } from 'components';
import Layout from 'components/layout';
import config from '../../../meta/config';

const page = pages.f2f;
const { navColors, navPaths } = page;
const canonical = `${config.siteUrl}/career/face-to-face/`;
const F2FPage = data => {
  const { edges: offers } = data.data.allMarkdownRemark || {};

  useEffect(() => {
    Array.from(
      document.querySelectorAll(`.navigation-bar__menu-link`),
    ).forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        // TO DO: refactor, use for example https://www.npmjs.com/package/react-router-hash-link
        const target = e.target.hash;
        scrollTo(target, 'center');
      });
    });
  }, []);

  return (
    <Layout
      {...{
        page,
        canonical,
      }}
    >
      <NavBar
        additionalClass={'menu--f2f'}
        isLogoBig
        color={navColors}
        paths={navPaths}
        button={{
          link: '#f2f-job-offers',
          id: 'ga-f2f--join-us',
          label: 'Join us!',
          className: 'navbar--text-center text--bold',
        }}
        isContactAdded={false}
        bannerHidden
      />
      <F2F offers={offers} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query F2FPage {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        frontmatter: {
          templateKey: { eq: "job-offer-page" }
          mainContent: { activeOffer: { eq: true } }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
      edges {
        node {
          frontmatter {
            mainContent {
              activeOffer
              name
              cities
              contractType {
                contract
                salaries {
                  level
                  min
                  max
                }
              }
              apply
              banner
              technical {
                skill
              }
              additional {
                skill
              }
              specifics {
                specific
              }
              photo
              steps {
                number
                step
                details
              }
            }
          }
        }
      }
    }
  }
`;

export default F2FPage;
