export const data = [
  {
    title: 'Self-organizing, dedicated development teams',
    description:
      'Our development teams consist of highly skilled team-players who have collaborated with each other in the past so they can reach high performance levels quickly.<br /><br />Moreover, they have all the skills necessary to complete the work on your product: frontend, backend, graphic and product design, UX/UI, business analysis, QA, user research, strategy and more. Our teams are dedicated to your product which means that for the duration of development they will live and breathe your vision, working solely to  fulfill your business goals.',
  },
  {
    title: 'Speed to market and product-market fit',
    description:
      'We use the lean startup approach to help you quickly delivery your web, mobile or software product to market, often in as little as 4-6 weeks. After you release the minimum viable product (MVP) we can help you to look for a product-market fit and make sure that you digital product is exactly what users want.<br /><br />The lean startup approach, with its core build-measure-learn principle, helps you to save time and money and build a scalable business model.',
  },
  {
    title: 'Iterative, incremental delivery with agile and lean startup',
    description:
      'We develop web products and software using an agile approach. We use the popular agile framework called Scrum, which allows us to plan work in 1 or 2 week sprints. After each sprint, you receive a working increment of your software. Scrum allows you to change the scope of work with every sprint which means you can adjust the work to your budget, scale or downsize the team or change the direction of your product development.',
  },
  {
    title: 'Quality software and human-centered digital product design',
    description:
      'We treat quality very seriously and stick to the highest coding standards, as well as carrying out quality practices such code reviews, manual and automated tests and security checks. We put equal attention on the quality of our designs, following the latest trends in UX and UI and fully testing the concepts. But what makes our software and digital products truly special is that they are focused on users. The collaboration in cross-functional teams between developers and designers as well as QAs, and business analysts allows us to create products that are human-centered and fulfill your business goals.',
  },
  {
    title: 'Constant communication between team and client',
    description:
      'We believe that communication is the pillar or successful collaboration and thus you have around the clock access and contact with your team, without any proxies such as project managers. We use a variety of communication tools such as Slack, Basecamp, and Google Meet so you and your development team are just a few clicks away from each other. We also encourage face-to-face contact and we’re delighted when our clients visit us at the office or if we take a trip to their business HQ.',
  },
];
