import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { Button } from 'components';

import './KeywordHero.scss';

export const KeywordHero = ({ data, onButtonClick }) => {
  const backgroundImage = getCloudinaryUrl(data.image, `f_auto,q_auto,w_auto`);

  return (
    <div className={'keyword-hero'}>
      <Row className={'keyword-hero__container'}>
        <Col sm={12} md={6} xl={7} className={'keyword-hero__text-wrapper'}>
          <h1 className={'keyword-hero__header'}>
            {data.title}
          </h1>
          <p className={'keyword-hero__description'}>
            {data.description}
          </p>
          <Button
            id={'keyword-page-contact-btn'}
            color={data.buttonColor}
            ariaName={'Contact button'}
            additionalClass={'keyword-hero__button contact-button'}
            onClick={onButtonClick}
          >
            {data.cta} &rarr;
          </Button>
        </Col>
        <Col sm={0} md={6} xl={5} className={'keyword-hero__image-container'}>
          <img
            src={backgroundImage}
            alt={'HeroPhoto'}
            className={'keyword-hero__image'}
          />
        </Col>
      </Row>
    </div>
  );
};
