import { createContext } from 'react';
import PropTypes from 'prop-types';

export const slideShape = {
  index: PropTypes.number.isRequired,
  text: PropTypes.shape({
    firstText: PropTypes.string.isRequired,
    underlineText: PropTypes.string.isRequired,
    secondText: PropTypes.string.isRequired,
  }),
  url: PropTypes.string,
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
};

export const optionsShape = {
  withPhoto: PropTypes.bool,
  slideTime: PropTypes.number,
};

export const defaultOptions = {
  withPhoto: false,
  slideTime: 7000,
};

export const SliderContext = createContext({
  slides: [],
  options: { withPhoto: false },
});
