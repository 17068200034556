import React from 'react';
import PropTypes from 'prop-types';

const ChallengeBox = ({
  tags,
  Frame,
  solution,
  challenge,
  authorName,
  authorPhoto,
  authorPosition,
}) => (
  <div className={'bldr-dsgn__challenge'}>
    <div className={'bldr-dsgn__challenge-icon'}>
      <Frame />
    </div>
    <div className={'bldr-dsgn__challenge-content-wrapper'}>
      <div className={'bldr-dsgn__challenge-content'}>
        <h6 className={'bldr-dsgn__challenge-content-client'}>
          Client’s challenge
        </h6>
        <div className={'bldr-dsgn__challenge-content-challenge-wrapper'}>
          <p
            className={
              'bldr-dsgn__challenge-content-challenge bldr-dsgn__challenge-content-challenge--visible'
            }
          >
            {challenge}
          </p>
        </div>
        <h6 className={'bldr-dsgn__challenge-content-solution'}>
          Our solution
        </h6>
        <ul className={'bldr-dsgn__tags'}>
          {tags.map(tag => (
            <li>{tag}</li>
          ))}
        </ul>
        <p className={'bldr-dsgn__solution'}>{solution}</p>
      </div>
      <div className={'bldr-dsgn__row bldr-dsgn__author'}>
        <img src={authorPhoto} alt={authorName} />
        <div className={'bldr-dsgn__column'}>
          <h5>{authorName}</h5>
          <p>{authorPosition}</p>
        </div>
      </div>
    </div>
  </div>
);

ChallengeBox.propTypes = {
  tags: PropTypes.array.isRequired,
  // Frame: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
  challenge: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorPhoto: PropTypes.string.isRequired,
  authorPosition: PropTypes.string.isRequired,
};

export default ChallengeBox;
