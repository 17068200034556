import React from 'react';
import { Heading, Link, Paragraph, Button } from 'components';
import './BlogNewWork.scss';

const BlogNewWork = () => (
  <div className={'box box-article box-newwork'}>
    <div className={'blog-new-work'}>
      <div className={'new-work-details'}>
        <Heading type={3} size={'medium-plus'}>
          What is the future of work?
        </Heading>
        <Paragraph size={'medium'}>
          Explore our library of materials dedicated to the New Work concept
        </Paragraph>
      </div>
      <Link to={'/blog/new-work/'}>
        <Button color={'transparent'}>
          Learn more about New Work &#8594;
        </Button>
      </Link>
    </div>
  </div>
);

export default BlogNewWork;
