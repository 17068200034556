import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic';
import './Awards.scss';

class Awards extends Component {
  renderItems = () =>
    data.map((item, index) => (
      <div className={'awards__item'} key={`awards-${index}`}>
        <a href={item.link}>
          <img
            src={getCloudinaryUrl(item.image, 'w_288')}
            className={'awards__image'}
            loading={'lazy'}
            alt={'Boldare Awards'}
          />
        </a>
      </div>
    ));

  render() {
    const { theme } = this.props;

    return (
      <Section
        additionalClass={`awards awards--${theme}`}
        containerClass={'awards__container'}
        spaceless
        fullwidth
      >
        <div className={'awards__list anim-fade-in-bottom'}>
          {this.renderItems(data)}
        </div>
      </Section>
    );
  }
}

Awards.defaultProps = {
  theme: 'dark',
};

Awards.propTypes = {
  theme: PropTypes.string,
};

export default Awards;
