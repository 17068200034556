import { SectionHeader } from 'components';
import { classNames } from 'utils';
import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import './HeroSection.scss';

const HeroSection = () => {
  return (
    <section className={'section-hero'}>
      <div className={'section-hero__hashtags'}>Work any way you like!</div>
      <SectionHeader
        additionalClass={'section-hero__heading'}
        size={'large'}
        lineColor={'yellow'}
        color={'black'}
        descriptionColor={'black'}
        headingType={1}
        description={
          'Welcome to the place where stationary and remote complement each other and help us to stay together. You can work at home, on the beach or your favourite park in the city — but still, you can always join us at our cosy offices in Warszawa, Gliwice, Kraków, or Wrocław. We care for our bold bond, and this is why we’re meeting regularly to spend some time together in real life.'
        }
        fixed
      >
        <span>Stationary or 99% remote mode?</span>
        <span className={'heading--smalltext text--default'}>
          The choice is yours.
        </span>
      </SectionHeader>
      <button
        onClick={() => scrollTo('#job-offers')}
        id={'ga-remote-work--join-our-remote-team'}
        className={classNames(
          'button',
          'button--primary',
          'button--yellow',
          'section-hero__button',
          'text--bold',
        )}
      >
        Join our team!
      </button>
    </section>
  );
};

export default HeroSection;
