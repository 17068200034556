import React, { useState } from 'react';
import './About.scss';
import {
  Heading,
  Button,
  Section,
  SectionHeader,
  Paragraph,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';

const About = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Section
        additionalClass={'digtra-de-about__section'}
        color={'white'}
        id={'digtra-de-about'}
        spacedTop
        side={'right'}
      >
        <div className={'digtra-de-about__background-color-box'} />
        <Row>
          <Col xs={12}>
            <Row>
              <Col sm={12} md={7} lg={6}>
                <SectionHeader
                  size={'large'}
                  color={'mine-shaft'}
                  lineColor={'violet'}
                  descriptionColor={'black'}
                  fixed
                  headingType={2}
                  noLine
                  additionalClass={'digtra-de-about__text-header'}
                >
                  Was ist die digitale Transformation in Deutschland?
                </SectionHeader>
                <div
                  className={'digtra-de-about__text-header-secondary-wrapper'}
                >
                  <Heading
                    type={3}
                    size={'medium-constant'}
                    color={'black'}
                    additionalClass={'digtra-de-about__text-header-secondary'}
                  >
                    Die digitale Transformation ist ein komplexer Prozess der
                    Umgestaltung der Dienstleistungen, der Produkte und der
                    Kultur eines Unternehmens. Es geht dabei nicht um
                    Digitalisierung um ihrer selbst Willen. In den meisten
                    Fällen geht es darum, das Produktportfolio eines
                    Unternehmens umzugestalten, neue Perspektiven zu lernen und
                    dadurch das gesamte Geschäft zu verändern um es dadurch zu
                    verbessern.
                  </Heading>
                </div>
              </Col>
              <Col sm={12} md={5} lg={6} className={'digtra-de-about__image'}>
                <div>
                  <img
                    src={getCloudinaryUrl(
                      'de/digitale-transformation/about-banner.png',
                      'f_auto,q_auto,w_810',
                    )}
                    className={'image'}
                    alt={'About'}
                    loading={'lazy'}
                  />
                </div>
              </Col>
            </Row>
            {showMore && (
              <Row>
                <Col sm={12} md={6}>
                  <div>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Die Digitale Transformation ist zweigleisig. Um zu
                      erklären, was damit gemeint ist, bedarf es zweier
                      englischer Begriffe.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Im Englischen wird zwischen Digitization und
                      Digitalization unterschieden. Die Deutsche Übersetzung
                      ist für beide Begriffe „Digitalisierung.”
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Digitization ist sehr simpel. Bei Digitization ersetze
                      ich einen analogen Prozess mit einer digitalen Lösung.
                      Bestes Beispiel hierfür ist das Ersetzen eines
                      Callcenters für Kunden, Beschwerden, Bestellungen etc.
                      durch einen KI-Chatbot.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Was früher Mitarbeiter in einem Call Centre erledigt
                      haben, erledigt jetzt ein Chatbot mittels KI. Die
                      Schulung von Call-Center-Mitarbeitern wird damit obsolet
                      - Es bedarf lediglich der Schaffung eines Wissenspools,
                      auf welchen die Software des Chatbots zugreifen kann. Der
                      Kunde kommuniziert dann mit dem Chat-Bot. Sofern der
                      Chat-Bot an seine Grenzen gerät übernimmt ein Mitarbeiter
                      aus der jeweils zur Problemlösung benötigten Abteilung.
                      Sie kennen es alle. Das ist Digitization.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Digitalization hingegen ist deutlich komplexer. Hier
                      werden völlig neue Technologien effektiv verwendet, um
                      das Unternehmen upzudaten, es zu straffen, es effektiver
                      zu gestalten, um ganze Produkte und Service zu
                      digitalisieren und/oder um die Customer Experience über
                      verschiedene Kanäle zu steigern.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Im Zuge der digitalen Transformation werden sogar ganze
                      Ertragsmodelle verändert.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Kurz: Digitalization ist die Umwandlung Ihres
                      Unternehmens in ein digitales Unternehmen, um es dadurch
                      zukunftsfähig zu machen.
                    </Paragraph>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Die Entscheidung für eine digitale Transformation muss
                      auf einem solidem, für jeden verständlichem Fundament
                      stehen. Bei dieser Entscheidung darf nicht mit der Tür
                      ins Haus gefallen werden. Diese Entscheidung, der
                      Prozess, die Meilensteine - all dies muss für jeden
                      Teilnehmer nachvollziehbar sein. In jeder kleinen
                      Veränderung müssen sie den Mehrwert sehen. Es reicht
                      nicht aus beispielsweise zu sagen, dass jetzt täglich
                      Dailys gemacht werden. Es reicht auch nicht dies damit zu
                      begründen, dass so täglich alle auf den gleichen Stand
                      gebracht werden. Warum müssen alle auf dem gleichen Stand
                      sein? Diese Frage steht im Fokus. Ein solcher Prozess
                      kann sehr lange dauern.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Ebenfalls ist es wichtig zu wissen, dass nicht alles
                      transformiert werden muss. Das Unternehmen muss so agil
                      aufgestellt werden, dass bestehende, gut funktionierende
                      Prozesse oder Abteilungen so integriert werden, dass die
                      digital transformierten Teile des Unternehmens diese
                      weder stören noch beeinflussen.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Analysieren sie drei Dinge beim Treffen der Entscheidung
                      für oder gegen die digitale Transformation:
                      <br />
                      1. Hat ihr Unternehmen/ ihr Business Potential für eine
                      digitale Transformation?
                      <br />
                      2. Wo liegt dieses Potenzial?
                      <br />
                      3. Kann man mit der digitalen Transformation etwas
                      verbessern?
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Nur, wenn sie klar definierbare und umsetzbare
                      Verbesserungs-Potenziale für eine digitale Transformation
                      finden sollten Sie mit diesem Prozess beginnen.
                    </Paragraph>
                    <Paragraph
                      additionalClass={
                        'text--demibold digtra-de-about__text-header-paragraph'
                      }
                    >
                      Gerne begleiten wir Sie von den ersten Überlegungen an!
                      Kommen Sie dafür einfach auf Michael Pachter, unseren
                      Head of Sales in Deutschland zu!
                    </Paragraph>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Section>
      {!showMore && (
        <Section spaceless additionalClass={'digtra-de-about__btn'}>
          <div className={'digtra-de__load-article-btn-wrapper w-container'}>
            <div className={'digtra-de__lead-article-btn__line'} />
            <Button
              onClick={() => {
                setShowMore(true);
              }}
              color={'yellow'}
              ariaName={'Lesen Sie mehr darüber'}
            >
              Lesen Sie mehr darüber
            </Button>
          </div>
        </Section>
      )}
    </>
  );
};

export default About;
