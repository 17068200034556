import React from 'react';

import { Button, DottedList } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';
import './PDWBanner.scss';

export const PDWBanner = ({ imagePath }) => (
  <div className={'app-cost-pdwbanner'}>
    <div className={'app-cost-pdwbanner__img'}>
      <img
        src={getCloudinaryUrl(imagePath, 'f_auto,q_auto')}
        alt={'Product Discovery Workshop'}
        loading={'lazy'}
      />
    </div>
    <div className={'app-cost-pdwbanner__list'}>
      <p className={'app-cost-pdwbanner__heading'}>
        <b>
          With these 10 tools, you&apos;ll be well prepared for further steps
          like development or pitching your product to investors.
        </b>
      </p>
      <DottedList
        heading={'This is a great set of tools for:'}
        itemsList={[
          'Organizations planning to release new products/features',
          'Companies looking for an investor/internal pitch',
          'Startups keen on rapidly launching an MVP on the market',
        ]}
      />
      <a
        href={'/resources/product-discovery-workshops'}
        id={'ga-app-cost-pdwbanner-button'}
      >
        <Button
          additionalClass={'app-cost-pdwbanner__button'}
          type={'submit'}
          color={'pigeon-post'}
          ariaName={'Check resources'}
        >
          Check our resources
        </Button>
      </a>
    </div>
  </div>
);

PDWBanner.propTypes = {
  imagePath: PropTypes.string,
};
