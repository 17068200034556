import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { NavBar } from 'components';
import WebinarGate from 'views/webinar';
import Webinar from 'views/webinar/Webinar';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import {
  banner,
  firstSection,
} from '../../views/resources/Webinar/agile-for-your-company-logic.js';
import config from '../../../meta/config';

const page = pages.webinar.items['agile-for-your-company'];
const url = `${config.siteUrl}/webinar/agile-for-your-company/`;
const canonical = `${url}`;

const AccelerateDigitizationInYourCompanyWebinarPage = () => {
  const [isGateOpen, setIsGateOpen] = useState(false);
  const pardotLink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('webinar-gate=open_id-grAuye@2ed!')
    ) {
      setIsGateOpen(true);
    }
  }, []);

  return (
    <Layout {...{ page, canonical, url }}>
      <Helmet />
      <NavBar
        color={isGateOpen ? ['white', 'mine-shaft'] : ['black', 'white']}
        bannerHidden
      />
      {isGateOpen ? (
        <Webinar {...{ banner, firstSection }} />
      ) : (
        <WebinarGate
          type={'Webinar'}
          title={'Agile for your company. How to implement successfully'}
          titleSize={'large'}
          lineColor={'yellow'}
          description={`Watch a recorded video of a webinar during which our experts discussed <strong>agile transformation</strong> case studies based on two perspectives: <strong>agile practitioners</strong> (Boldare software development team) and <strong>agile coaches</strong> (Brass Willow).`}
          speakers={[
            {
              avatar: 'img/webinar-agile/avatar-kate-hobler.png',
              position: 'Professional Scrum Trainer at Brass Willow',
              name: 'Kate Hobler',
            },
            {
              avatar: 'img/webinar-agile/avatar-dorota-jablonska.png',
              position: 'Scrum Master at Boldare',
              name: 'Dorota Jabłońska',
            },
            {
              avatar: 'img/webinar-agile/avatar-artur-belka.png',
              position: 'Customer Success Guide at Boldare',
              name: 'Artur Belka',
            },
          ]}
          listTitle={`You will learn:`}
          learnItems={[
            `How to overcome problems with accelerating software development delivery;`,
            `How to shorten the time-to-market for software applications by up to 50%;`,
            `How to save at least 30% of your budget on software development.`,
          ]}
          formHeading={`Get a free access to the video`}
          btnColor={'yellow'}
          button={'Sign up'}
          url={pardotLink}
          trackingEvent={`AgileWebinar`}
          gateAddress={'webinar-gate=open_id-grAuye@2ed!'}
          gateCatalog={'webinar'}
        />
      )}
    </Layout>
  );
};

export default AccelerateDigitizationInYourCompanyWebinarPage;
