import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';
import companyData from '../../../meta/companyData';

const page = pages.resources.items['what-after-mvp-checklist'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/what-after-mvp-checklist/`;
const canonical = `${url}`;

const WhatAfterMVPChecklist = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'checklist'}
        title={'What next after MVP?'}
        lineColor={'pigeon-post'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/what-after-mvp-checklist-cover.png',
          'f_auto,q_auto,w_652',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-after-mvp.png',
          'f_auto,q_auto,w_600',
        )}
        paragraphHeadings={[
          `Packed with must-ask questions and top tips this practical guide in form of checklist will help you to identify key answers in the following areas:`,
        ]}
        items={[
          `How to make sure that your application is ready for the market`,
          `How to check if new features will answer users’ needs`,
          `How to check if your in-house team is ready to grow your app`,
          `How to manage remote and dispersed development teams`,
          `What tools can be useful`,
        ]}
        outro={`Take advantage of our ${companyData.years_experience} years of experience and download for free this practical guide which will help you determine if your application is ready to grow beyond MVP.`}
        url={pardotlink}
        formHeading={'Get your free <i>What next after MVP?</i> checklist now'}
        button={'Send me checklist'}
        btnColor={'pigeon-post'}
        trackingEvent={`WhatAfterMVPChecklistResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free checklist is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default WhatAfterMVPChecklist;
