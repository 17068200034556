import { Section, TitleAskCto } from 'components';
import './MeetSpeakers.scss';
import React from 'react';
import { Row } from 'react-flexbox-grid';
import ShowCase from './ShowCase';

const MeetSpeakers = () => {
  return (
    <Section fullWidth additionalClass={'meet-speakers-section'}>
      <TitleAskCto
        title={'Meet the speakers'}
        text={'We present the speakers of the 4. edition of CTO asks'}
      />
      <Row>
        <ShowCase />
      </Row>
    </Section>
  );
};

export default MeetSpeakers;
