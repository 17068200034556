import React from 'react';
import PropType from 'prop-types';
import { Link, Container } from 'components';
import Stamp from 'assets/images/ses/badge-png.png';

import './Section.scss';

function Section({ pageName }) {
  return (
    <section className={'ses-section'}>
      <Container additionalClass={'ses-section__wrapper'}>
        <div className={'ses-section__container'}>
          <div className={'ses-section__content'}>
            <h2>
              Our Service Standards
              <br />– turning complexity into simplicity
            </h2>
            <p>
              With our Service Standards, we transform complexity into
              simplicity and provide assistance in changing bold concepts into
              impactful products. With these in place, we maintain a consistent
              commitment to exceptional client experience, making us a valuable
              partner in bringing your vision to life.
            </p>
            <Link
              to={
                '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/'
              }
              id={`${pageName}__ses-section-button`}
              className={'ses-section__button'}
            >
              <span>Learn about our Service Standards</span>
              <span>&rarr;</span>
            </Link>
          </div>
          <img
            src={Stamp}
            alt={`Service Standards Stamp`}
            className={'ses-section__image'}
            loading={'lazy'}
          />
        </div>
      </Container>
    </section>
  );
}

Section.propTypes = {
  pageName: PropType.string.isRequired,
};

export default Section;
