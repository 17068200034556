import React from 'react';
import './Kontakt.scss';
import { getCloudinaryUrl } from 'config/helpers';
import German from 'assets/images/contact/german-flag.svg';
import {
  Heading,
  Button,
  Section,
  SectionHeader,
  Link,
  Paragraph,
  IconSVG,
} from 'components';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';

const Kontakt = () => (
  <Section
    additionalClass={'digtra-de-kontakt__section'}
    id={'digtra-de-kontakt'}
  >
    <SectionHeader
      size={'large'}
      color={'mine-shaft'}
      headingType={2}
      fixed
      noLine
      additionalClass={'digtra-de-kontakt__text-header'}
    >
      Kontaktieren Sie Michael
    </SectionHeader>
    <div className={'digtra-de-kontakt__wrapper'}>
      <div className={'digtra-de-kontakt__content-wrapper'}>
        <div className={'digtra-de-kontakt__text-wrapper'}>
          <Heading
            type={3}
            size={'large'}
            color={'mine-shaft'}
            additionalClass={'digtra-de-kontakt__text-header-secondary'}
          >
            <span>Michael Pachter</span> verantwortet unsere Unternehmungen im
            DACH-Raum und betreut unsere dortigen Kunden. Kontaktieren Sie ihn
            für einen ersten, unverbindlichen Gedanken-Austausch!
          </Heading>
          <div className={'digtra-de-kontakt__text-header-contact'}>
            <Link
              to={`tel:${CountryContactPhoneNumbers.Germany.value}`}
              className={'contact-form-biz__data--phone'}
            >
              <Paragraph
                id={'contact-form-biz-german'}
                size={'medium-plus-small'}
                additionalClass={'text--bold'}
              >
                <img
                  src={German}
                  alt={'German Flag'}
                  className={'mini-flag'}
                  loading={'lazy'}
                />
                {CountryContactPhoneNumbers.Germany.displayValue}
              </Paragraph>
            </Link>
            <Button color={'white'}>
              <a href={'mailto:michael.pachter@boldare.com'}>E-Mail-Kontakt</a>
            </Button>
          </div>
        </div>
        <div className={'digtra-de-kontakt__imgs-wrapper'}>
          <img
            alt={'Michael Pachter'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/boldare-contact-de-super-hero-pacher.png',
              'f_auto,q_auto,w_387',
            )}
            className={'img-michael'}
          />
          <img
            alt={'Hand with phone'}
            src={getCloudinaryUrl(
              'gatsby-cloudinary/contact-de-hand-with-phone.png',
              'f_auto,q_auto,w_419',
            )}
            className={'img-hand'}
          />
        </div>
      </div>
    </div>
  </Section>
);

export default Kontakt;
