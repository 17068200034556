import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Link, Button } from '..';
import './RelatedUniversalBoxAlternative.scss';

const RelatedUniversalBoxAlternative = ({ title, url, image, button }) => (
  <>
    <div
      className={
        'related-universal-widget-alt related-universal-widget-alt--spaced'
      }
    >
      <Link additionalClass={'related-universal-widget-alt__link'} to={url}>
        <div className={'related-universal'}>
          <div className={'related-article__left'}>
            {image && (
              <img
                src={getCloudinaryUrl(`${image}`, 'f_auto,q_auto,h_300')}
                alt={title}
                className={'image related-article__image'}
                loading={'lazy'}
              />
            )}
          </div>
          <div className={'related-article__right'}>
            <Heading
              type={3}
              size={'medium'}
              additionalClass={'related-universal-widget-alt__header'}
            >
              {title}
            </Heading>
            <Button
              color={'transparent'}
              additionalClass={
                'related-article__button related-universal-widget-alt__button'
              }
            >
              {button}
            </Button>
          </div>
        </div>
      </Link>
    </div>
  </>
);

RelatedUniversalBoxAlternative.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RelatedUniversalBoxAlternative;
