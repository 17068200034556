import React from 'react';
import PropTypes from 'prop-types';
import './BoxFooter.scss';
import { classNames } from 'utils';

const BoxFooter = ({ children, additionalClass }) => (
  <div className={classNames('box__footer', additionalClass)}>{children}</div>
);

BoxFooter.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BoxFooter.defaultProps = {
  additionalClass: '',
};

export default BoxFooter;
