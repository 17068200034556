import React from 'react';
import * as Scroll from 'react-scroll';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { Section, SectionHeader } from 'components';

import './Banner.scss';

const SpyLink = Scroll.Link;

const Banner = () => {
  return (
    <Section
      additionalClass={'event-fpmf-amsterdam__banner'}
      color={'mine-shaft'}
    >
      <Col className={'event-fpmf-amsterdam__max-width'}>
        <div className={'event-fpmf-amsterdam__banner-inner'}>
          <div className={'wrapper'}>
            <div className={'cloud'}>
              <img
                alt={''}
                className={'cloud-1'}
                src={getCloudinaryUrl('/event/clouds-png-23422_4.png', 'q_50')}
              />
            </div>
            <img
              alt={''}
              className={'tv-screen'}
              src={getCloudinaryUrl(
                'event/Webinar1.png',
                'f_auto,q_auto,w_1000',
              )}
            />
          </div>
        </div>
        <Row
          className={
            'no-gap about-page__banner-row eventfpmf-amsterdam-banner-row-padding'
          }
        >
          <div className={'event__inner event__top'}>
            <SectionHeader
              type={2}
              size={'medium'}
              additionalClass={'event__heading'}
              noLine
            >
              <p>Find new users & revenue streams for your digital product</p>
            </SectionHeader>
            <SectionHeader
              type={1}
              size={'large'}
              noLine
              additionalClass={'event__heading--main'}
            >
              <img
                alt={''}
                className={'event__signup--pixel'}
                src={getCloudinaryUrl(
                  'event/Sign_up.svg',
                  'f_auto,q_auto,w_1000',
                )}
              />
              <span className={'pixel-font-text'}>Sign up</span> to watch the
              recording
            </SectionHeader>
            <div className={'event__heading--details'}>
              <p>Travel back in time and visit our meetup in Amsterdam</p>

              <SpyLink
                id={'scroll-down-to-see-more'}
                to={'form-link'}
                spy={false}
                smooth
                duration={500}
                className={'link'}
                rel={'nofollow'}
              >
                <span
                  className={'button button--primary button--white'}
                  id={'contact-now-shortcut'}
                >
                  Get the recording &rarr;
                </span>
              </SpyLink>
            </div>
          </div>
        </Row>
      </Col>
    </Section>
  );
};

export default Banner;
