import React from 'react';

import './CtoAsk.scss';

import {
  AboutBoldare,
  Agenda,
  AskMatt,
  Banner,
  MeetSpeakers,
  PreviousEditions,
  PreviousGuests,
  Why,
} from './subcomponents';

const CtoAsk = () => (
  <div className={'ctoask-page'}>
    <Banner />
    <Why id={'why-link-component'} />
    <Agenda />
    <PreviousEditions />
    <PreviousGuests />
    <AboutBoldare />
    <AskMatt />
  </div>
);

export default CtoAsk;
