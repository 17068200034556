import React from 'react';
import { graphql } from 'gatsby';
import Prototyping from 'views/prototyping';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../../meta/config';

const page = pages['prototyping-digital-products'];
const { navColors } = page;
const url = `${config.siteUrl}/services/full-cycle-product-development/prototyping/`;
const canonical = `${url}`;

const PrototypingPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <Prototyping allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PrototypingQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Digital Product Prototyping – what’s it all about?"
              "Digital Product Prototyping – it’s a team effort"
              "What are design sprints?"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default PrototypingPage;
