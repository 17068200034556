import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';

import './CoverImage.scss';

const CoverImage = ({ image, alt, small }) => (
  <div
    className={classNames('work-boxes-cover', {
      'work-boxes-cover--small': small,
    })}
  >
    <img
      className={'image work-boxes-cover__image'}
      src={image}
      alt={alt}
      loading={'lazy'}
    />
  </div>
);

CoverImage.defaultProps = {
  small: false,
};

CoverImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

export default CoverImage;
