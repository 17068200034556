import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import ReactHtmlParser from 'react-html-parser';
import { Paragraph } from 'components';
import './TitleEventFpmf.scss';
import PropTypes from 'prop-types';

const TitleEventFpmf = ({
  title,
  text,
  secondTitle,
  secondText,
  white,
  equal,
}) => {
  return (
    <Row>
      <Col sm={12} md={equal ? 6 : 5}>
        <Paragraph
          color={white ? 'white' : 'black'}
          size={'large'}
          additionalClass={'title-event-fpmf'}
        >
          {ReactHtmlParser(title)}
        </Paragraph>
        {secondTitle && (
          <Paragraph
            color={white ? 'white' : 'black'}
            size={'large'}
            additionalClass={'title-event-fpmf'}
          >
            {' '}
            <br />
            {secondTitle}
          </Paragraph>
        )}
      </Col>
      <Col sm={12} md={equal ? 6 : 7}>
        {text && (
          <Paragraph
            color={white ? 'white' : 'black'}
            size={'medium'}
            additionalClass={'title-event-fpmf-paragraph'}
          >
            {text}
            {secondText && (
              <>
                <br />
                <br />
                {secondText}
              </>
            )}
          </Paragraph>
        )}
      </Col>
    </Row>
  );
};

TitleEventFpmf.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  secondTitle: PropTypes.string,
  secondText: PropTypes.string,
  white: PropTypes.bool,
  equal: PropTypes.bool,
};

TitleEventFpmf.defaultProps = {
  text: null,
  secondTitle: null,
  secondText: null,
  white: false,
  equal: false,
};

export default TitleEventFpmf;
