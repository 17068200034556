export const data = [
  {
    number: '1',
    title: 'Friendly Agile Offices',
    alt:
      'Friendly Agile Offices - Boldare - Digital Product Design Development Company',
    description:
      'You can work from home, but still, from time to time, our teams meet face-to-face at Boldare offices or travel together to meet our clients. We have four locations (Warszawa, Gliwice, Kraków, or Wrocław) where we can meet and talk about work or hobbies. Caring for our bold bond and relations with clients, we’re meeting regularly to spend time together in real life.',
    cloudinaryDir: 'f2f-landing/f2f-landing-ideas-1.png',
    sizeWidth: '344',
    className: 'slide1__image',
  },
  {
    number: '2',
    title: 'Trips to clients',
    alt:
      'Trips to our clients - Boldare - Digital Product Design Development Company',
    description: `Meeting our partners in real life is an essential element of our work. It's a chance to spend some valuable time together, understand each other better, boost relationships, and set foot on a path to growth. What is more, these visits are a genuinely fun experience – it’s a perfect occasion to engage with other cultures and taste the local food. With some clients we meet once a month, with others every six months. The frequency varies, but we care for all team members to participate in onsite meetings.`,
    cloudinaryDir: 'f2f-landing/f2f-landing-ideas-22.png',
    sizeWidth: '313',
    className: 'slide2__image',
  },
  {
    number: '3',
    title: 'Creative workshops',
    alt:
      'Creative workshops - Boldare - Digital Product Design Development Company',
    description:
      "The smartest way to merge work and fun is… a creative workshop, of course! During such meetings, we work out crazy ideas and prudent strategies. But! There is always a space for socializing and getting to know each other better. It's the perfect time to have fun after hours with your team and discover Polish cities as well.",
    cloudinaryDir: 'f2f-landing/f2f-landing-ideas-3.png',
    sizeWidth: '294',
    className: 'slide3__image',
  },
  {
    number: '4',
    title: 'Celebrations',
    alt: 'Celebrations - Boldare - Digital Product Design Development Company',
    description:
      'We love celebrating! Wishes, gifts, and cake must be prepared every time an anniversary comes. Celebrations are a great way to show gratitude and bring together the company’s community. Also, there is always space to rehash old times.',
    cloudinaryDir: 'f2f-landing/f2f-landing-ideas-4.png',
    sizeWidth: '404',
    className: 'slide4__image',
  },
  {
    number: '5',
    title: 'Offsites & Retreats',
    alt:
      'Offsites & Retreats - Boldare - Digital Product Design Development Company',
    description:
      'Offsites and retreats allow a whole Scrum team to talk face-to-face about the results they’ve already achieved. Members share their insights and give honest feedback to each other. It boosts members’ skills and helps them to come up with fresh business ideas. It also makes people feel that their work is meaningful, and strengthens teamwork.',
    cloudinaryDir: 'f2f-landing/f2f-landing-ideas-5.png',
    sizeWidth: '315',
    className: 'slide5__image',
  },
];
