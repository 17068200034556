export const consultationBannerData = {
  'consultation-banner-ai': {
    bannerId: 'consultation-banner-ai',
    title: 'AI Consultation',
    titleColor: 'white',
    text:
      'If your business is grappling with AI implementation challenges or seeking to leverage AI for growth, share details about your project and let’s explore them together.',
    textColor: 'white',
    contactPersonName: 'Romuald Członkowski',
    contactPersonRole: 'Head of AI Solutions',
    contactPersonImage: '/romuald_cz.jpg',
    buttonText: 'book a call',
    buttonLink: 'https://calendly.com/romuald-czlonkowski/30min',
    buttonId: 'consultation-banner-ai-cta',
    rightCornerGraphic: 'consultation-banner/brain_whit_mask.svg',
    personImageBackgroundMask: 'consultation-banner/mask_violet.svg',
    backgroundImage:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1711121973/consultation-banner/grey-background-ai.svg',
  },
  'consultation-banner-design': {
    bannerId: 'consultation-banner-design',
    title: 'UX & design consultation for your app',
    titleColor: 'white',
    text:
      'Let’s see how you can enhance your business through better user experience!',
    textColor: 'black',
    contactPersonName: 'Łukasz Walszewski',
    contactPersonRole: 'UX Designer',
    contactPersonImage: '/lukasz_waliszewski.png',
    buttonText: 'book a call',
    buttonLink: 'https://calendly.com/lukasz-walszewski',
    buttonId: 'consultation-banner-design-cta',
    rightCornerGraphic: 'consultation-banner/planet-violet.svg',
    personImageBackgroundMask: 'consultation-banner/mask_violet.svg',
    backgroundImage:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1711551674/consultation-banner/blue-background-design.jpg',
  },
  'consultation-banner-business': {
    bannerId: 'consultation-banner-business',
    addtionalTitle: 'Business consultation!',
    title: "Bring us your idea, we'll help you find the way forward!",
    titleColor: 'black',
    text:
      "Let's dive into your idea or existing product and explore your vision and objectives.",
    textColor: 'black',
    contactPersonName: 'Michal Trzpis',
    contactPersonRole: 'Business Consultant',
    contactPersonImage: '/michal_trzpis.png',
    buttonText: 'book a call',
    buttonLink: 'https://calendly.com/michal-trzpis/qa-meet',
    buttonId: 'consultation-banner-business-cta',
    rightCornerGraphic: 'consultation-banner/planet-violet.svg',
    personImageBackgroundMask: 'consultation-banner/mask_violet.svg',
    backgroundImage:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1711551674/consultation-banner/background_gray.jpg',
  },
  'consultation-banner-product': {
    bannerId: 'consultation-banner-product',
    title: 'Ready to Transform Your Product?',
    titleColor: 'white',
    text:
      "Schedule a consultation with our Product Strategist and unlock your product's full potential.",
    textColor: 'black',
    contactPersonName: 'Oskar Kwitek',
    contactPersonRole: 'Product Strategist',
    contactPersonImage: '/oskar_kwitek.png',
    buttonText: 'book a call',
    buttonLink:
      'https://calendly.com/oskar-kwitek-boldare/30min?month=2024-03',
    buttonId: 'consultation-banner-business-cta',
    rightCornerGraphic: 'consultation-banner/planet-violet.svg',
    personImageBackgroundMask: 'consultation-banner/mask_violet.svg',
    backgroundImage:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1711551674/consultation-banner/background_yellow.jpg',
  },
};
