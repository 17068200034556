import React, { Component } from 'react';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import CountUp from 'react-countup';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import './Client.scss';

class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.pakt__client-container').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  render() {
    const { enableCounterAnimation } = this.state;
    const withCounter = number =>
      enableCounterAnimation && (
        <span>
          <CountUp end={number} delay={0.3} separator={' '} />
        </span>
      );

    return (
      <>
        <Section additionalClass={'pakt__client-container'} spaceless>
          <div className={'pakt__content'}>
            <Heading type={2} size={'big-spreaded'}>
              Client from Hong Kong
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Hong Kong have one of the highest population density per 1 km in
              the world. Competition is enormous in this kind of place, it
              makes our client looking for the most innovative and reliable
              solutions available. And we delivered.
            </Paragraph>
          </div>
        </Section>
        <Section additionalClass={'pakt__client-images'}>
          <div className={'pakt__content--left'}>
            <Paragraph
              additionalClass={'text--light'}
              size={'small-plus'}
              color={'gray-dark'}
            >
              Did You know?
            </Paragraph>
            <Heading
              type={3}
              size={'big-spreaded'}
              color={'mine-shaft'}
              additionalClass={'pakt__client-header'}
            >
              {withCounter(200000)}
            </Heading>
            <Heading
              type={3}
              size={'default'}
              color={'mine-shaft'}
              additionalClass={'text--default pakt__client-header'}
            >
              citizens per 1 km2
              <br />
              is Hong Kong's population density
            </Heading>
          </div>
          <div className={'pakt__content'}>
            <FullwidthImage
              image={getCloudinaryUrl(
                'assets/pakt/pic-1.jpg',
                'f_auto,q_auto',
              )}
              alt={'Old vs new look'}
              additionalClass={'anim-fade-in-bottom'}
            />
          </div>
        </Section>
      </>
    );
  }
}

export default Client;
