import React from 'react';
import PropTypes from 'prop-types';
import './Number.scss';

const Number = ({ number }) => {
  return <span className={'number'}>{number}</span>;
};

export default Number;

Number.propTypes = {
  number: PropTypes.number.isRequired,
};
