import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Heading from 'components/Heading';

const clientsSettings = {
  dots: false,
  speed: 4000,
  infinite: true,
  autoplay: true,
  slidesToShow: 4,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToScroll: 1,
  variableWidth: true,
};

const SliderElement = ({ logos, header }) => {
  return (
    <div className={'logo-carousel-container'}>
      {header && <Heading size={'large'}>{header}</Heading>}
      <div className={'logo-carousel'}>
        <Slider {...clientsSettings}>
          {Object.entries(logos).map(([key, value]) => (
            <img key={key} src={value} alt={key} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

SliderElement.propTypes = {
  logos: PropTypes.object.isRequired,
  header: PropTypes.string,
};

SliderElement.defaultProps = {
  header: null,
};

export default SliderElement;
