import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'components';
import './FullwidthImage.scss';

const FullwidthImage = ({ additionalClass, alt, comment, image, side }) => (
  <>
    {image && (
      <Section spaceless stick side={side} additionalClass={additionalClass}>
        <img
          src={image}
          alt={alt}
          className={'fullwidth-image__image'}
          loading={'lazy'}
        />
      </Section>
    )}
    {comment && (
      <Section spaceless>
        <div className={'article-content__container'}>
          <div className={'fullwidth-image__comment'}>{comment}</div>
        </div>
      </Section>
    )}
  </>
);

FullwidthImage.defaultProps = {
  additionalClass: '',
  alt: '',
  comment: '',
  side: '',
};

FullwidthImage.propTypes = {
  additionalClass: PropTypes.string,
  alt: PropTypes.string,
  comment: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  side: PropTypes.string,
};

export default FullwidthImage;
