import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Banner,
  Benefits,
  Types,
  Contact,
  Offer,
  WhatWeDid,
  LearnMore,
  OtherServices,
} from './subcomponents';
import './MVP.scss';

class MVP extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedLearn = [
      'MVP development - what, why & how',
      'MVP development - everything you want to know, but are afraid to ask',
      'Minimum Viable Products? It’s all about the team',
      'Product Vision Workshops – seeing clearly from the beginning',
      'What is Design Thinking',
      'Product-Market Fit – teamworking for results',
      'How Prototyping Can Bring Your Business Ideas to Life',
      'Choosing a digital product development partner - the political and economic issues',
      'How much should you pay for a digital product development?',
    ].map(title => ({ title }));

    return (
      <div className={'single-page single-page--hero mvp'}>
        <Banner />
        <Benefits />
        <Types />
        <Contact
          title={'Contact us to estimate your product'}
          cta={'CONTACT US'}
          link={'mvp-contact-form'}
          id={'mvp-contact-middle'}
          scroll
        />
        <WhatWeDid />
        <Offer />
        <ContactFormServices
          id={'mvp-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-07-24/pklz'}
        />
        <LearnMore allArticles={allArticles} related={relatedLearn} />
        <OtherServices />
      </div>
    );
  }
}

export default MVP;
