import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Section } from '..';
import './SubpageBanner.scss';

const SubpageBanner = ({
  additionalClass,
  backgroundColor,
  contentBackground,
  image,
  children,
  alt,
  id,
  imgLoading,
}) => (
  <Section
    color={backgroundColor}
    additionalClass={classNames('subpage-banner', additionalClass)}
    id={id}
    fullwidth
  >
    {image ? (
      <div
        className={
          'subpage-banner__image-container w-container w-container--stick w-container--side-right'
        }
      >
        <div className={'subpage-banner__image'}>
          <img
            src={image}
            style={{
              objectPosition: 'top center',
            }}
            className={'image'}
            alt={alt}
            loading={imgLoading}
          />
        </div>
      </div>
    ) : null}
    <div
      className={
        'subpage-banner__content-container w-container w-container--side-left'
      }
    >
      <div
        className={classNames('w-container--left', 'subpage-banner__content', {
          [`subpage-banner__content--${contentBackground}`]: contentBackground,
        })}
      >
        {children}
      </div>
    </div>
  </Section>
);

SubpageBanner.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  backgroundColor: PropTypes.string,
  contentBackground: PropTypes.string,
  alt: PropTypes.string,
  id: PropTypes.string,
  imgLoading: PropTypes.string,
};

SubpageBanner.defaultProps = {
  alt: '',
  additionalClass: '',
  children: null,
  backgroundColor: 'white',
  contentBackground: 'mine-shaft',
  image: null,
  id: '',
  imgLoading: 'lazy',
};

export default SubpageBanner;
