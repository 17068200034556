/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import { Section, SectionHeader } from 'components';

import './Banner.scss';

const Banner = ({ title, description }) => {
  return (
    <Section color={'mine-shaft'} additionalClass={`work__hero`} spaceless>
      <SectionHeader
        additionalClass={
          'section-header__description--medium work__hero-content'
        }
        color={'white'}
        description={description}
        descriptionColor={'white'}
        lineColor={'yellow'}
        size={'extra-large'}
      >
        {title}
      </SectionHeader>
      <div className={'banner-navigation'}>
        <a href={'#about'} className={'banner-navigation__item'}>
          About Boldare
        </a>
        <a href={'#scaling'} className={'banner-navigation__item'}>
          Our case studies (Product market-fit, scaling cases)
        </a>
        <a href={'#partnership'} className={'banner-navigation__item'}>
          Our case studies: (Long-term cooperation, partnership, full cycle
          product development, scaling, scaled scrum, agile transformation)
        </a>
        <a href={'#prototypes'} className={'banner-navigation__item'}>
          Product design & development: prototyping
        </a>
      </div>
    </Section>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
Banner.defaultProps = {
  title: null,
  description: null,
};

export default Banner;
