import React from 'react';
import { Button, Container, Paragraph, Heading, Image } from 'components';
import './style.scss';
import { data } from './data';

const StepsSection = () => {
  return (
    <Container additionalClass={'steps-section__container'}>
      <div className={'steps-section'}>
        <Heading color={'white'} size={'big'}>
          {data.title}
        </Heading>
        <Paragraph color={'white'} size={'medium-plus-small'}>
          {data.description}
        </Paragraph>
        <div className={'steps-section__bottom-container'}>
          {data.steps.map((item, index) => (
            <div className={'steps-section__item'}>
              <span className={'steps-section__item-number'}>{index + 1}</span>
              <Heading color={'white'} size={'medium-plus'}>
                {item.title}
              </Heading>
              <Paragraph color={'white'} size={'medium-constant'}>
                {item.description}
              </Paragraph>
            </div>
          ))}
        </div>
        <figure className={'steps-section__image'}>
          <Image src={data.image} />
        </figure>
      </div>
    </Container>
  );
};

export default StepsSection;
