import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import {
  Choice,
  Combinations,
  Contact,
  Design,
  Features,
  Intro,
  Technology,
} from './subcomponents';
import './Oneida.scss';

class Oneida extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study oneida__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/oneida/tabletop-oneida-main.jpg',
            'f_auto,q_auto',
          )}
          color={'white'}
          subtitle={'Various web products'}
          title={'A short story about the partnership and values'}
        />
        <Intro />
        <Technology />
        <Combinations
          image={'transparent-2.png'}
          description={
            'With SPA solution we were able to build a responsive website that includes a wide variety of products to choose from, all of them dynamically shown in one place.'
          }
          subtitle={'of instant product combinations'}
          number={1792}
          title={'64x28='}
        />
        <Design />
        <Choice />
        <Combinations
          image={'pic-5.jpg'}
          description={
            'Choice of right color palette was the key element to make whole design consistent.'
          }
          title={'The devil’s in the detail'}
        />
        <Features />
        <Contact />
      </div>
    );
  }
}

export default Oneida;
