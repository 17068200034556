import React, { Component } from 'react';
import { ContactFormServices } from 'components';
import { enableAnimations } from '../../config/helpers';
import { Banner, Boxes, HowWeDoIt, Intro, LearnMore } from './subcomponents';
import './Clutch.scss';

class Clutch extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedLearn = [
      'How much does it cost to develop an app?',
      'How to choose a software development company?',
      'Starting app development efficiently - how to do it?',
    ].map(title => ({ title }));

    return (
      <div className={'single-page clutch'} id={'ydp-page'}>
        <Banner />
        <Intro />
        <Boxes />
        <HowWeDoIt />
        <LearnMore allArticles={allArticles} related={relatedLearn} />
        <ContactFormServices
          id={'ydp-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2020-09-15/bkjd1'}
        />
      </div>
    );
  }
}

export default Clutch;
