import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph } from 'components';
import './Score.scss';

const Score = ({ data, title, subtitle }) => (
  <div className={'score__box'}>
    <Heading type={3} size={'medium'} additionalClass={'score__title'}>
      <span>{data.rating}&nbsp;</span>
      {title}
    </Heading>
    <Paragraph additionalClass={'score__subtitle text--light'}>
      {subtitle}
    </Paragraph>
    {data.description && (
      <Paragraph
        additionalClass={'score__description text--light'}
        size={'small-plus'}
      >
        {data.description}
      </Paragraph>
    )}
  </div>
);

Score.propTypes = {
  data: PropTypes.shape({}),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Score;
