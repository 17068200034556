import React from 'react';
import PropTypes from 'prop-types'

import './ClientTile.scss';

export const ClientTile = ({ name, photo, description }) => (
  <div className={'client-tile'}>
    <div className={'client-tile__logo'}>
      <img
        src={photo}
        alt={'Client Tile'}
      />
    </div>
    <div className={'client-tile__info'}>
      <div>{description}</div>
    </div>
  </div>
);

ClientTile.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};
