import React from 'react';
import PropTypes from 'prop-types';
import './SliderNav.scss';
import { IconSVG } from 'components';
import classnames from 'classnames';

const SliderNav = ({ items, arrowsColor, additionalClass }) => (
  <div
    className={classnames('glide__arrows', 'slider-nav', {
      [additionalClass]: additionalClass,
    })}
    data-glide-el={'controls'}
  >
    <button
      className={
        'glide__arrow glide__arrow--left slider-nav__arrow slider-nav__arrow--left'
      }
      data-glide-dir={'<'}
    >
      <IconSVG name={'arrow-left'} color={arrowsColor} />
    </button>
    <div
      className={'glide__bullets slider-nav__bullets'}
      data-glide-el={'controls[nav]'}
    >
      {items.map((item, index) => (
        <button
          className={'glide__bullet slider-nav__bullet'}
          data-glide-dir={`=${index}`}
        >
          <div className={'glide__bullet-item'} />
        </button>
      ))}
    </div>
    <button
      className={
        'glide__arrow glide__arrow--right slider-nav__arrow slider-nav__arrow--right'
      }
      data-glide-dir={'>'}
    >
      <IconSVG name={'arrow-right'} color={arrowsColor} />
    </button>
  </div>
);

SliderNav.defaultProps = {
  additionalClass: '',
};

SliderNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  arrowsColor: PropTypes.string,
  additionalClass: PropTypes.string,
};

SliderNav.defaultProps = {
  arrowsColor: 'mine-shaft',
};

export default SliderNav;
