import React, { Component } from 'react';
import {
  Heading,
  IconSVG,
  Section,
  SectionHeader,
  Paragraph,
} from 'components';
import { classNames } from 'utils';
import { data } from './logic.js';
import './Types.scss';

class Types extends Component {
  constructor() {
    super();

    this.state = {
      slide: 0,
    };
  }

  renderItem = () =>
    data.map((item, index) => (
      <div
        className={classNames('mvp__types-item', {
          'mvp__types-item--active': index === this.state.slide,
        })}
        onClick={() => this.showTile(index)}
        key={index}
      >
        <div className={'image-box'}>
          <IconSVG name={`${item.icon}`} size={'huge'} color={'mine-shaft'} />
        </div>
        <Heading size={'medium'}>{item.title}</Heading>
      </div>
    ));

  renderParagraph = () =>
    data.map((item, index) => (
      <>
        {index === this.state.slide && (
          <div>
            <Heading color={'black'} size={'medium-plus-small'}>
              {item.title}
            </Heading>
            <Paragraph color={'black'} size={'medium'}>
              {item.description}
            </Paragraph>
          </div>
        )}
      </>
    ));

  showTile(number) {
    this.setState({ slide: number });
  }

  render() {
    return (
      <Section color={'gray-light'} additionalClass={'mvp__types'}>
        <SectionHeader
          size={'big'}
          lineColor={'pigeon-post-second'}
          color={'black'}
          descriptionColor={'white'}
          headingType={2}
        >
          What is your business goal for the MVP?
        </SectionHeader>
        <div className={'mvp__types-items'}>{this.renderItem()}</div>
        <div className={'mvp__types-description'}>
          {this.renderParagraph()}
        </div>
      </Section>
    );
  }
}

export default Types;
