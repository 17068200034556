export const data = [
  {
    title: 'An extensive compendium of knowledge and inspiration',
    subtitle: 'Variety of the possibilities and the bucket of knowledge',
    description:
      'We put emphasis on authenticity and getting to know the local culture. On the website there are many travel destinations. To clarify and help in navigation, we created special categories for them.',
    images: [
      {
        link: 'planet-escape-collage-small.jpg',
        classes: 'pe__features-image-1 anim-fade-in-bottom',
      },
      {
        link: 'planet-escape-collage.jpg',
        classes: 'pe__features-image-2 anim-fade-in-bottom',
      },
    ],
  },
  {
    title: 'Private paradise in the exclusive offers',
    subtitle: 'The pure essence of luxury at its best',
    description:
      'Exclusive offer for the most demanding customers who like to travel a very high standard. You will find here unique hotels in the world, which in itself could be a destination. All of them have their very nature, they are intimate, beautifully situated, indecorated with great taste.',
    images: [
      {
        link: 'planet-escape-beach-1.jpg',
        classes: 'pe__features-image-3 anim-fade-in-left',
      },
      {
        link: 'white-ipad.png',
        classes: 'pe__features-image-4 anim-fade-in-right',
      },
    ],
  },
  {
    title: 'Unlimited number of the best destination',
    subtitle: 'With intuitive search engine listings',
    description:
      "Search is the heart of this site. We made sure that the user always find the best deal. That's why there is always result. We focused much on FILTRATION and it worked out. There is always positive result, without reloading the page.",
    background: 'beach-2.jpg',
    images: [
      {
        link: 'black-ipad.png',
        classes: 'pe__features-image-5 anim-fade-in-right',
      },
      {
        link: 'circle.png',
        classes: 'pe__features-image-6 anim-fade-in-right',
      },
    ],
  },
  {
    title: 'All about travel dreams in one inspiring blog',
    subtitle: 'Travel inspiration, advice and reports of journeys',
    description:
      'We created the second product. On the blog you can find the all interesting tips, inspirations and stories. You can explore places on map and find the related description on the map (all information are connected with the location). Furthermore, you can also navigate by categories and tags.',
    background: 'planet-escape-map.jpg',
    images: [
      {
        link: 'planet-escape-blue-rectangle.png',
        classes: 'pe__features-image-7 anim-fade-in-bottom',
      },
    ],
  },
];
