import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, Box, Button } from 'components';
import './BoxAsset.scss';

const BoxAsset = ({ id, title, link, description, type = 'open' }) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!isExpanded);

  return (
    <Box
      id={id}
      backgroundColor={'gray-light'}
      additionalClass={'review-box__wrapper'}
    >
      <div className={'review-box__content'}>
        <Heading
          type={4}
          size={'medium-plus-small'}
          color={'black'}
          additionalClass={'review-box__title'}
        >
          {title}
        </Heading>
        <Paragraph
          additionalClass={'review-box__author'}
          size={'regular'}
          color={'black'}
        >
          {description}
        </Paragraph>
        {type === 'open' && (
          <div>
            <div className={'download-btn'}>
              <Button
                tag={'a'}
                href={link}
                target={'_blank'}
                download={title}
                transparent
                color={'black'}
                ariaName={'Download'}
              >
                Download
              </Button>
            </div>
          </div>
        )}

        {type === 'preview' && (
          <div className={'preview-btns-wrapper'}>
            <Button
              tag={'a'}
              style={{ backgroundColor: '#f1d624' }}
              additionalClass={'preview-btn'}
              href={link}
              target={'_blank'}
              transparent
              color={'black'}
            >
              Open in a new tab
            </Button>
            <Button
              transparent
              color={'black'}
              additionalClass={'preview-btn'}
              onClick={toggleExpanded}
            >
              {isExpanded ? 'Hide' : 'Expand'}
            </Button>
          </div>
        )}
        {isExpanded && type === 'preview' && (
          <div>
            <iframe
              title={title}
              src={link}
              width={'100%'}
              className={'preview-iframe'}
            />
          </div>
        )}
      </div>
    </Box>
  );
};

BoxAsset.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
};

export default BoxAsset;
