export const consultingServicesData = [
  {
    decoration: '1',
    rotation: '5deg',
    description:
      'Product strategy development through the entire product life cycle',
  },
  {
    decoration: '2',
    rotation: '-5deg',
    description: 'Product Discover Process and custom workshops',
  },
  {
    decoration: '3',
    rotation: '5deg',
    description:
      'Product Strategist, Solution Architects, PO as a service - our experts on demand',
  },
  {
    decoration: '4',
    rotation: '-5deg',
    description:
      'Technology consulting - architecture and audits before or after the development',
  },
];
