import React from 'react';

import { getCloudinaryUrl } from 'config/helpers';
import { Section } from '../../../../components/DS';

import { whoWeAre } from '../../utils'

import './Contact.scss';

export const Contact = () => (
  <Section
    color={'violet-main'}
    noOverflow
  >
    <div className={'who-we-are-contact'}>
      <div className={'who-we-are-contact__map'}>
        <img
          alt={'Boldare - international tech company'}
          src={getCloudinaryUrl(
            'design-rush/map.svg',
            'f_auto,q_auto',
          )}
        />
      </div>
      <div className={'who-we-are-contact__text'}>
        {whoWeAre.contact.description}
        <br/>
        <br/>
        Write to us at <a href={'mailto:hello@boldare.com'}>hello@boldare.com</a> for a free consultation.
      </div>
    </div>
  </Section>
)
