export const data = [
  {
    title: 'Sales & Customer Service',
    columns: 2,
    items: [
      'Customer experience',
      'Personalisation',
      'Churn and retention',
      'Sentiment analysis',
    ],
  },
  {
    title: 'Marketing & Products',
    columns: 2,
    items: [
      'Data-driven growth',
      'Pricing and promotional models',
      'Automated product quality assurance',
    ],
  },
  {
    title: 'Operations & HR',
    columns: 2,
    items: [
      'Process automation',
      'Production management',
      'Performance management',
    ],
  },
  {
    title: 'Strategy & Risk',
    columns: 2,
    items: [
      'Financial forecasting and investment allocation',
      'Fraud detection',
      'Credit risk assessment',
    ],
  },
];
