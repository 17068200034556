import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Paragraph, Heading } from 'components';
import './BoxImageContent.scss';

const BoxImageContent = ({ additionalClass, item }) => {
  const {
    image,
    caption,
    headline,
    description,
    link,
    backgroundColor,
    id,
  } = item;

  return (
    <Box
      id={id}
      backgroundColor={backgroundColor}
      additionalClass={`box-image-content ${additionalClass}`}
      clickable
    >
      <a href={link}>
        <img
          src={image}
          className={'image box-image-content__image'}
          alt={caption}
          loading={'lazy'}
        />
      </a>
      <div className={'box-image-content__main'}>
        <div className={'box-image-content__top'}>
          <a href={link}>
            <Heading
              type={3}
              size={'medium-plus-small'}
              color={'black'}
              additionalClass={'text--bold box-image-content__title'}
            >
              {ReactHtmlParser(headline)}
            </Heading>
          </a>
          {description && (
            <Paragraph
              size={'default'}
              color={'black'}
              additionalClass={'box-image-content__description text--light'}
            >
              {ReactHtmlParser(description)}
            </Paragraph>
          )}
        </div>
        <div className={'box-image-content__bottom'}>
          {caption && (
            <Paragraph
              size={'small'}
              additionalClass={'text--bold box-image-content__caption'}
            >
              {ReactHtmlParser(caption)}
            </Paragraph>
          )}
        </div>
      </div>
    </Box>
  );
};

BoxImageContent.defaultProps = {
  additionalClass: '',
};

BoxImageContent.propTypes = {
  item: PropTypes.shape().isRequired,
  additionalClass: PropTypes.string,
};

export default BoxImageContent;
