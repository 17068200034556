import React from 'react';
import { Link } from 'components';
import companyData from '../../../../../meta/companyData';

const customerSatisfactionDescription = (
  <>
    As many as 80% of our new projects come from client recommendations.
    Wondering why? Well, according to our former and current partners, we are,
    “friendly, professional and considerate,” we, “genuinely care,” and our
    designs are, “amazing, thoughtful and clean.” For more in-depth reviews
    from our customers, visit our{' '}
    <Link to={'https://go.boldare.com/clutch.co-boldare'} underline={'yellow'}>
      Clutch.co
    </Link>{' '}
    profile.
  </>
);

export default {
  heading: 'Why we are so awwwesome?',
  items: [
    {
      label: 'Design process',
      id: 'our-design-process',
      description:
        'From the very first idea, through the product discovery workshop and wireframing, complete designs and smart testing, our process is tailored to making eye-catching products with a data-driven UX/UI twist. No needless steps - we aim straight for delivery.',
      link: 'https://www.boldare.com/services/product-design-and-development/',
      buttonText: 'Learn more',
      image: {
        src: 'img/awwwards/Design.svg',
        alt: 'Our design process',
      },
    },
    {
      label: 'Agile mindset',
      id: 'agile-mindset',
      description:
        'Thanks to our agile approach, we deliver our products faster, adjusting them incrementally and effectively. This way, we make sure that every single, tiny change is a change for the better and each day drives us further toward the final goal - a meaningful, enjoyable digital product design.',
      link: 'https://www.boldare.com/blog/agile-vs-waterfall/',
      buttonText: 'Learn more',
      image: {
        src: 'img/awwwards/Agile_Mindset.svg',
        alt: 'Agile Mindset',
      },
    },
    {
      label: 'Experience',
      id: 'experience',
      description: `We’ve released more than two hundred carefully tailored digital products over more than ${companyData.years_experience} years, working with various industries and clients around the world. From a best-selling e-commerce platform in the Middle East, through the German renewables sector, to a French-based global startup, regardless of your idea, we will handle it. Like a boss.`,
      link: 'https://www.boldare.com/work/',
      buttonText: 'Learn more',
      image: {
        src: 'img/awwwards/Experience.svg',
        alt: 'Experience',
      },
    },
    {
      label: 'Open minds',
      id: 'open-minds',
      description:
        'You can’t learn how to think “out of the box” - you’ve either got it, or you haven’t. When it comes to design and engineering, this attitude is just crucial. Especially when creating designs that can amaze people and solve problems at the same time. When it comes to this, there are no holds barred and you can count on us.',
      link: 'https://www.boldare.com/about/',
      buttonText: 'Learn more',
      image: {
        src: 'img/awwwards/Open.svg',
        alt: 'Open minds',
      },
    },
    {
      label: 'Customer satisfaction',
      id: 'customer-satisfaction',
      description: customerSatisfactionDescription,
      link: 'https://clutch.co/profile/boldare',
      buttonText: 'Learn more',
      image: {
        src: 'img/Logo_Clutch.png',
        alt: 'Clutch Logo',
      },
    },
  ],
};
