import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import cookie from 'react-cookies';
import WhyDesignMatters from 'views/whyDesignMatters';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages.work.items['why-design-matters'];
const { navColors } = page;
const url = `${config.siteUrl}/work/why-design-matters/`;
const canonical = `${url}`;

const WDMPage2 = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <WhyDesignMatters allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query WDMPage2 {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Visualising Energy"
              "How to scale a monolithic MVP application without losing business?"
              "From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default WDMPage2;
