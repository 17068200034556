import React from 'react';
import ArticleSlider from '../ArticleSlider';

const Result = () => {
  return (
    <div className={'pmf-survey__result pmf-survey__result-not-ready'}>
      <div className={'pmf-survey__text-content'}>
        <h1 className={'pmf-survey__header'}>
          It seems that your product is{' '}
          <span className={'pmf-survey__marker-highlight-long'}>
            not ready
          </span>{' '}
          for product-market fit yet.
        </h1>
        <p className={'pmf-survey__result-info'}>
          It’s possible that you are currently in one of the earlier phases,
          such as prototyping or MVP. If you want to find out more about these
          phases, read one of our articles below:
        </p>

        <p className={'pmf-survey__result-contact'}>
          If you feel that you would like to exchange ideas with experts with
          nearly 20 years of experience in digital product development, make
          sure to
          <a
            className={'pmf-survey__result-link'}
            href={'/#'}
            rel={'nofollow noreferrer'}
            target={'_blank'}
          >
            &nbsp;
            <span className={'pmf-survey__result-link-contact'}>
              contact us
            </span>
            <span className={'pmf-survey__result-link-icon'}>
              &nbsp;&#8594;
            </span>
          </a>
        </p>
      </div>

      <div className={'pmf-survey__slider'}>
        <ArticleSlider />
      </div>
    </div>
  );
};

export default Result;
