import React from 'react';
import { Section, Paragraph, Heading } from 'components';
import './Membership.scss';
import Statistic from '../Statistic';

const Membership = () => {
  return (
    <Section additionalClass={'pakt__membership-container'}>
      <div className={'pakt__content'}>
        <Heading type={2} size={'big-spreaded'}>
          Increase Membership Rate
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          Attractive membership choices benefit in a huge increase of
          membership rate. We believe we don't have to explain that higher
          membership rate translates strictly to the conversion rate.
        </Paragraph>
      </div>
      <Statistic
        title={'Simple tutorial design'}
        text={
          'Our tutorial will point you just to the right places in the blink of an eye with short and uncomplicated sentences.'
        }
        image={'pic-5'}
        subtitle={'Managed outcome'}
        number={164}
        suffix={'%'}
        info={'new registered users'}
      />
    </Section>
  );
};

export default Membership;
