import React, { useState } from 'react';
import { Heading } from 'components';
import './ActionsSection.scss';

const ActionsSection = () => {
  const [isVisible, setVisible] = useState(false);

  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `products-actions-${item}`,
      });
    }
  };

  return (
    <section className={'products-actions section-box'} id={'actions-section'}>
      <div className={'padding__container'}>
        <Heading
          additionalClass={`actions-heading visible--${isVisible}`}
          size={'big'}
          color={'mine-shaft'}
        >
          {!isVisible && (
            <span className={'header-part'}>
              We work on a{' '}
              <span className={'header-highlight'}>
                wide range of different products
              </span>{' '}
              and some of them make a direct impact on society or the
              environment...
            </span>
          )}
          {isVisible && (
            <>
              <span className={'header-part'}>
                We work on a{' '}
                <span className={'header-highlight'}>
                  wide range of different products
                </span>{' '}
                and some of them make a direct impact on society or the
                environment. Aside from this, every Boldare product offers real
                value to the user. We also feel proud of our work because of
                other aspects like intriguing technical solutions, maverick
                design or our well-coordinated teams. Being involved in
                products that are close to our hearts make us happy.
              </span>
              <span className={'header-part'}>
                <span className={'header-highlight'}>
                  We take bold actions.
                </span>{' '}
                We bear in mind that opportunities to improve the world are
                limitless.
              </span>
              How about improving things with us?
              <a href={'/career/'} rel={'noreferrer'} target={'_blank'}>
                <button
                  type={'button'}
                  className={
                    'products-btn products-btn--primary products-btn--yellow'
                  }
                  aria-label={'button'}
                  onClick={() => {
                    handleClick('career-click');
                  }}
                >
                  Go to career
                </button>
              </a>
            </>
          )}
          {!isVisible && (
            <button
              onClick={() => setVisible(true)}
              className={'products-btn products-btn--primary-border'}
            >
              Show more
            </button>
          )}
        </Heading>
      </div>
      <div className={'box__container'}>
        <div className={'box box--bigger'} />
        <div className={'box'} />
      </div>
      <div className={'additional-planet__container hide-mobile'}>
        <span className={'additional-planet additional-planet--large'} />
        <span className={'additional-planet additional-planet--small'} />
      </div>
    </section>
  );
};

export default ActionsSection;
