import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Heading, Paragraph, Button } from 'components';
import { classNames } from 'utils';
import './SectionTitle.scss';

class SectionTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDescriptionVisible: !props.hiddenDescription,
    };
  }

  setSizeClass = type => {
    const sizes = {
      large: ['extra-large', 'medium'],
      big: ['large', 'medium'],
      medium: ['big', 'medium'],
      small: ['medium-plus', 'regular'],
      'tiny-plus': ['medium', 'regular'],
      tiny: ['small', 'small'],
    };
    return sizes[type];
  };

  render() {
    const { isDescriptionVisible } = this.state;
    const {
      children,
      color,
      description,
      lineColor,
      noLine,
      descriptionColor,
      size,
      additionalClass,
      fixed,
      hiddenDescription,
      buttonColor,
    } = this.props;
    const lineColorClass = lineColor
      ? `section-title__headline--${lineColor}`
      : '';
    const noLineClass = noLine ? 'section-header__headline--no-line' : '';
    const textSize = this.setSizeClass(size);

    return (
      <div
        className={classNames(
          'section-title',
          {
            [`section-title--${size}`]: size,
          },
          additionalClass,
        )}
      >
        <div
          className={classNames(
            'section-title__headline',
            lineColorClass,
            noLineClass,
          )}
        >
          <Paragraph
            color={color}
            size={textSize[0]}
            additionalClass={'text--bold'}
          >
            {children}
          </Paragraph>
        </div>
        {description && (
          <div
            className={classNames('section-title__description', {
              'anim-fade-in-bottom': !fixed,
            })}
          >
            <Paragraph
              color={descriptionColor}
              size={textSize[1]}
              additionalClass={'text--light'}
              spaced
            >
              {typeof description === 'object'
                ? description
                : ReactHtmlParser(description)}
            </Paragraph>
            {isDescriptionVisible && (
              <Paragraph
                color={descriptionColor}
                size={textSize[1]}
                additionalClass={'text--light'}
                spaced
              >
                {typeof hiddenDescription === 'object'
                  ? hiddenDescription
                  : ReactHtmlParser(hiddenDescription)}
              </Paragraph>
            )}
            {hiddenDescription && (
              <Button
                onClick={() =>
                  this.setState({
                    isDescriptionVisible: !isDescriptionVisible,
                  })
                }
                color={buttonColor}
                transparent
                additionalClass={'section-title__read-more'}
                ariaName={'Read more button'}
              >
                {isDescriptionVisible ? 'Show less' : 'Show more'}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

SectionTitle.defaultProps = {
  description: '',
  lineColor: '',
  noLine: false,
  color: '',
  additionalClass: '',
  fixed: false,
  size: 'big',
  descriptionColor: 'default',
  headingType: null,
  hiddenDescription: null,
  buttonColor: 'white',
};

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lineColor: PropTypes.string,
  noLine: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  additionalClass: PropTypes.string,
  descriptionColor: PropTypes.string,
  fixed: PropTypes.bool,
  hiddenDescription: PropTypes.string,
  buttonColor: PropTypes.string,
  headingType: PropTypes.oneOf([null, 1, 2, 3, 4, 5, 6]),
};

export default SectionTitle;
