import React, { Component } from 'react';
import { Section, Paragraph, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Content.scss';

class Content extends Component {
  renderImages = images =>
    images.map(image => (
      <FullwidthImage
        image={getCloudinaryUrl(`assets/corel/${image}`, 'f_auto,q_auto')}
        additionalClass={'corel__content-image anim-fade-in-bottom'}
      />
    ));

  renderSection = () =>
    data.map(item => (
      <div className={'corel__content-part'}>
        <div className={'corel__content'}>
          <Heading type={2} size={'big'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light corel__content-paragraph'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
        <div className={'corel__content-images'}>
          {this.renderImages(item.images)}
        </div>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'corel__content-container'}>
        {this.renderSection()}
      </Section>
    );
  }
}

export default Content;
