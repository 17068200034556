import React from 'react';
import { Section, SectionHeader, RelatedArticles } from 'components';
import { isEmpty } from 'lodash';

const related = [
  'What should every decision maker know about the Generative AI revolution?',
  'ChatGPT implementation: key takeaways from our internal projects',
  'Will ChatGPT replace the Google Search Engine?',
].map(title => ({ title }));

const LearnMore = ({ allArticles }) => (
  <>
    <Section color={'white'} additionalClass={'asdc__learn-more'}>
      <SectionHeader size={'medium'} fixed centered noLine>
        Read about our experience with AI product development
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'pdad-learn-more-articles'}
        related={related}
        background={'white'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
        additionalClass={'asdc__learn-more-articles'}
      />
    )}
  </>
);

export default LearnMore;
