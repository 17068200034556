import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';

const page = pages.resources.items['product-market-fit-workbook'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/product-market-fit-workbook/`;

const ProductMarketFitWorkbook = () => {
  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'workbook'}
        title={`Product-Market Fit Workbook`}
        formHeading={'Get your workbook'}
        cover={getCloudinaryUrl(
          'resources/boldare-workbook-main.png',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/boldare-workbook-main.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Development Partner Guide'}
        description={`Is your digital product ready for the product-market fit (PMF) phase?
Check it out by answering the 14 questions we&apos;ve prepared for you!

These questions are meant to dig deeper into the nuances of a digital product’s development and give you an insight into what next steps you should take next.
Assess your application&apos;s strategic development in areas such as:`}
        items={[
          'digital product metrics & data analysis',
          'business model',
          'target market & its needs',
          'key business hypotheses to be tested',
        ]}
        url={page.formHandlers.campaigns.remarketing}
        button={'Get your free workbook now'}
        btnColor={'yellow'}
        trackingEvent={``}
        isMarketingCheckbox
      />
    </Layout>
  );
};

export default ProductMarketFitWorkbook;
