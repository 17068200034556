import React from 'react';
import PropTypes from 'prop-types';

import { IconSVG } from 'components';
import { MenuIcon } from 'components/MegaMenu/subcomponents';

import './MenuLink.scss';

const MenuLink = ({ url, title, description, icon }) => (
  <li className={'menu-link'}>
    <a href={url} className={'menu-link__anchor'}>
      {icon && (<MenuIcon type={icon} alt={`${title} icon`} />)}
      <span className={'menu-link__text'}>
        <span className={'menu-link__title'}>{title}</span>
        <span className={'menu-link__description hide-mobile hide-tablet'}>
          {description}
        </span>
      </span>
      <div className={'menu-link__icon'}>
        <IconSVG name={'arrow-right-2'} size={'small-plus'} color={'white'} />
      </div>
    </a>
  </li>
);

MenuLink.defaultProps = {
  url: '',
  title: '',
  description: '',
  icon: '',
};

MenuLink.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
};

export default MenuLink;
