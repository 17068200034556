export const data = [
  {
    icon: 'agreement',
    description:
      'Two different and flexible proposals for the solution and pricing',
  },
  {
    icon: 'help',
    description: 'A working piece of code each sprint',
  },
  {
    icon: 'charity',
    description: 'Proactive and accessible customer care',
  },
  {
    icon: 'sticky-note',
    description: 'Product discovery workshops',
  },
  {
    icon: 'speech-bubble',
    description: 'Transparent and responsive communication',
  },
  {
    icon: 'connection',
    description: 'Agile teams',
  },
  {
    icon: 'innovation',
    description: 'Continuous knowledge transfer',
  },
];
