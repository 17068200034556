import React, { Component } from 'react';
import {
  FullwidthImage,
  Section,
  Paragraph,
  OldCaseStudyButtons,
  OldCaseStudyTile,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Intro.scss';

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transform: '',
    };
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const itemTranslate = `${window.scrollY / 100}%`;

    this.setState({
      transform: itemTranslate,
    });
  };

  render() {
    const { transform } = this.state;
    const preview = getCloudinaryUrl(
      'assets/planet-escape/planet-escape-devices.png',
      'f_auto,q_auto',
    );

    return (
      <>
        <Section additionalClass={'pe__intro-container'}>
          <OldCaseStudyTile
            additionalClass={'planet-escape'}
            image={getCloudinaryUrl(
              'logos/planet-escape-logo.png',
              'f_auto,q_auto',
            )}
            subtitle={'Responsive trip catalogue'}
            title={'Planet Escape'}
            description={
              'Interface Design Architecture, Wireframes, Graphic Design, Implementation, PHP5, HTML5, Symfony 2.6'
            }
            alternative
          />
          <div className={'pe__content'}>
            <Paragraph size={'medium-constant'} color={'mine-shaft'}>
              Tailor-made travel to the exotic far. We believe that a
              successful journey can be created only by specialists who are
              themselves deeply know the offered place.
            </Paragraph>
            <br />
            <Paragraph
              additionalClass={'text--light pe__intro-paragraph'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              We created the app for the travelers. We believe that a
              successful journey can be created only by specialists who are
              themselves deeply know the offered place. We change the image of
              the travel company into the very huge basket of the interesting
              destinations and offers to travel. Enjoy with us.
            </Paragraph>
            <OldCaseStudyButtons
              additionalClass={'planet-escape'}
              link={'http://planetescape.pl/'}
              cover={preview}
            />
          </div>
        </Section>
        <Section additionalClass={'pe__intro-parallax'}>
          <div style={{ transform: `translateY(${transform})` }}>
            <FullwidthImage image={preview} alt={'Planet Escape on devices'} />
          </div>
        </Section>
      </>
    );
  }
}

export default Intro;
