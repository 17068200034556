import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';

import './Planet.scss';

export const Planet = ({ theme, planetColor }) => (
  <>
    <div className={'planet'}>
      <img
        src={getCloudinaryUrl(
          `design-rush/planet-${planetColor}.png`,
          'f_auto,q_auto,w_auto'
        )}
        fetchpriority={'high'}
        alt={'Planet'}
      />
    </div>
    <div className={'planet__orbit-container'}>
      <div className={`planet__orbit planet__orbit--${theme}`}>
        <img
          src={getCloudinaryUrl(
            'design-rush/orbit.svg',
            'f_auto,q_auto,w_auto'
          )}
          alt={'Orbit'}
          loading={'lazy'}
        />
      </div>
      <div className={'planet__moon-container'}>
        <div className={'planet__moon'} />
      </div>
    </div>
  </>
)
Planet.defaultProps = {
  theme: 'dark',
  planetColor: 'blue'
};
Planet.propTypes = {
  theme: PropTypes.string,
  planetColor: PropTypes.string,
};
