import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Section, NavBar, PopupRedirect } from 'components';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['privacy-policy'];
const { navColors } = page;
const canonical = `${config.siteUrl}/privacy-policy/`;

const PrivacyPolicyPage = () => (
  <Layout {...{ page, canonical }}>
    <div className={'single-page privacy-policy'}>
      <Helmet title={'Privacy Policy – Privacy & Terms'}>
        <link rel={'canonical'} href={canonical} />
      </Helmet>
      <NavBar color={navColors} />
      <PopupRedirect message={'privacy'} />
      <Section spaceless>
        <Heading type={1} size={'big'}>
          Privacy Policy of boldare.com
        </Heading>
        <br />
        <ol className={'ordered-list ordered-list--roman main'}>
          <li>
            <Heading type={2} size={'medium'}>
              Introduction
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  We are committed to safeguarding the privacy of our website
                  visitors. By giving you this policy we&rsquo;re explain how
                  we maintain and treat your personal information.{' '}
                </p>
              </li>
              <li>
                <p>
                  By using our website and agreeing to this policy, you consent
                  to our use of cookies in accordance with the terms of this
                  policy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              What information do we collect about you?
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  We&rsquo;re collecting or we may collect, store and use
                  following kinds of personal information:
                </p>
              </li>
              <ol className={'ordered-list ordered-list--letters spaced'}>
                <li>
                  <p>
                    device and browser information including: IP address,
                    cookie string data, &nbsp;operating system and (in the case
                    of mobile devices) your device type and mobile {"device's"}{' '}
                    unique identifier such as the Apple IDFA or Android ID;{' '}
                  </p>
                </li>
                <li>
                  <p>
                    information about you and you&rsquo;re personal data
                    &nbsp;when registering with our website such as your name,
                    email address, company name, address, phone number,
                    location ; information about you visits to and use of this
                    website including geographical location, browser type and
                    version, operating system, referral source, length of
                    visit, page views and website navigation paths with using
                    tools defined in section VIII;{' '}
                  </p>
                </li>
                <li>
                  <p>
                    information that you provide to us for the purpose of
                    subscribing to our email notifications and/or newsletters
                    (including your name and email address);
                  </p>
                </li>
                <li>
                  <p>
                    information contained in or relating to any communication
                    that you send to us or send through our website (including
                    metadata associated with the communication);
                  </p>
                </li>
                <li>
                  <p>information necessary to recruitment process; </p>
                </li>
                <li>
                  <p>
                    any other personal information that you decided to send to
                    us.{' '}
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  By sending personal information of another person, you must
                  obtain that person's consent to both the disclosure and the
                  processing of that personal information in accordance with
                  this policy.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Why we need your personal Information?
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  We collect information about you to; fulfill your request
                  e.g. if you fill out a &lsquo;request a callback&rsquo; or
                  &lsquo;download a ebook&rsquo; form we use this information
                  to contact you directly, process your business inquiry,
                  process your job application, request for project, to email
                  you about new content on website, to email you about new blog
                  article, to email you about new ebook, to email you about
                  events which we organise.{' '}
                </p>
              </li>
              <li>
                <p>
                  We may use your contact information to advertise our service
                  to you on third-party websites such as Google, LinkedIn,
                  Facebook and Twitter. We may also contact you directly to
                  follow up on the service and user experience we provide. We
                  use your information collected from the website to
                  personalize your repeat visits to our website.
                </p>
              </li>
              <li>
                <p>
                  By providing your personal data you agree to the sharing of
                  your data with third parties (partners and sponsors) so that
                  they may contact you. If BOLDARE S.A. is ever sold to another
                  company your contact information will be passed on to this
                  new party.
                </p>
              </li>
              <li>
                <p>
                  We will take cautionary measure to ensure we do not collect
                  any personal data from you we do not need in order to provide
                  and oversee the above services to you.
                </p>
              </li>
              <li>
                <p>We may also use your personal information to:</p>
              </li>
              <ol className={'ordered-list ordered-list--letters spaced'}>
                <li>
                  <p>personalise our website for you;</p>
                </li>
                <li>
                  <p>
                    send you invoices and payment reminders to collect payments
                    from you;{' '}
                  </p>
                </li>
                <li>
                  <p>send you other non-marketing communications;</p>
                </li>
                <li>
                  <p>keep our website secure and prevent fraud.</p>
                </li>
              </ol>
              <li>
                <p>
                  If you have consented to receive marketing, you may opt out
                  at a later date. You have a right at any time to stop us from
                  contacting you for marketing purposes or giving your
                  information to other parties. If you no longer wish to be
                  contacted for marketing purposes please email us at{' '}
                  <a href={'mailto:marketing@boldare.com'}>
                    marketing@boldare.com
                  </a>{' '}
                  or unsubscribe from our next email.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Data sharing and disclosing personal information
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>We may disclose information about you:</p>
              </li>
              <ol className={'ordered-list ordered-list--letters spaced'}>
                <li>
                  <p>
                    With our service providers: We contract with companies who
                    help with parts of our business operations (e.g., for
                    example, website and data hosting, fraud prevention,
                    viewability reporting, data hygiene, marketing, and email
                    delivery), as well as billing, collections, tech, customer
                    and operational support;{' '}
                  </p>
                </li>
                <li>
                  <p>
                    With our subsidiaries and related companies: But they will
                    only process your data for the purposes already explained
                    in this Privacy Notice. Our subsidiaries and related
                    companies are: <strong>XSolve Sp. z o.o.</strong>,
                    registration number 0000308949,
                    <strong> Solution Expert Sp. z o.o.</strong>, registration
                    number 0000618844,<strong> Chilid Sp. z o.o.</strong>,
                    registration number 0000511773.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    In connection with legal proceedings: When we are under a
                    legal obligation to do so, for example to comply with a
                    binding order of a court, or where disclosure is necessary
                    to exercise, establish or defend the legal rights of
                    BOLDARE S.A. or any other third party.
                  </p>
                </li>
                <li>
                  <p>
                    To Comply with legal process: To satisfy in good faith any
                    applicable law, legal process, or proper governmental
                    request, such as to respond to a subpoena (whether civil or
                    criminal) or similar process.
                  </p>
                </li>
                <li>
                  <p>
                    To Investigate Wrongdoing and Protect Ourselves or Third
                    Parties: To enforce our Terms of Service or other policies
                    or investigate any potential violation of those Terms and
                    policies, any potential violation of the law, or to protect
                    ourselves, our customers, or any third party from any
                    potential harm (whether tangible or intangible).
                  </p>
                </li>
                <li>
                  <p>
                    In connection with a sale of our business: If a third party
                    acquires some or all of our business or assets, we may
                    disclose your information in connection with the sale
                    (including during due diligence in preparation for the
                    sale).
                  </p>
                </li>
                <li>
                  <p>
                    with any of our employees, officers, insurers, professional
                    advisers, agents, suppliers or subcontractors insofar as
                    reasonably necessary for the purposes set out in this
                    policy.
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  We also share hashed email addresses (or other pseudonymous
                  identifiers associated with those hashes), technical data
                  that we collect about your browsing habits and your device
                  (such as data relating to our cookies, tracking pixels and
                  similar technologies) with other advertising companies in the
                  digital advertising ecosystem. This enables them and us to
                  better target ads to yo
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              How long will we store your data?
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  If BOLDARE S.A. provides any paid for services to you, we are
                  required under Polish tax law to keep your basic personal
                  data (name, address, contact details) for a minimum of 5
                  years after which time it will be destroyed. Your information
                  we use for marketing purposes will be kept with us until you
                  notify us that you no longer wish to receive this
                  information.
                </p>
              </li>
              <li>
                <p>
                  We will not, without your consent including section IV point
                  1 supply your personal information to any third party.{' '}
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              What are your rights?
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>You have the:</p>
              </li>
              <ol className={'ordered-list ordered-list--letters spaced'}>
                <li>
                  <p>the right to access;</p>
                </li>
                <li>
                  <p>the right to rectification;</p>
                </li>
                <li>
                  <p>the right to erasure;</p>
                </li>
                <li>
                  <p>the right to restrict processing;</p>
                </li>
                <li>
                  <p>the right to object to processing;</p>
                </li>
                <li>
                  <p>the right to data portability;</p>
                </li>
                <li>
                  <p>the right to complain to a supervisory authority; and</p>
                </li>
                <li>
                  <p>the right to withdraw consent.</p>
                </li>
              </ol>
              <li>
                <p>
                  If you would like use one of the rights from point, please
                  email or write to us at the following address:{' '}
                  <a href={'mailto:ado@boldare.com'}>ado@boldare.com</a>{' '}
                  Address: Zwycięska 52, 44-100 Gliwice, Poland with request of
                  using particular right.{' '}
                </p>
              </li>
              <li>
                <p>
                  We want to make sure that your personal information is
                  accurate and up to date. You may ask us to correct or remove
                  information you think is inaccurate.
                </p>
              </li>
              <li>
                <p>
                  If you are not satisfied with our response or believe we are
                  processing your personal data not in accordance with the law
                  you can complain to the GIODO Data Protection{' '}
                  <a href={'https://www.giodo.gov.pl/'}>
                    https://www.giodo.gov.pl/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  We may withhold personal information that you request to the
                  extent permitted by law.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Cookies, HotJar and Mouseflow
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  Cookies are text files placed on your computer to collect
                  standard internet log information and visitor behavior
                  information. This information is used to track visitor use of
                  the website and to compile statistical reports on website
                  activity. For further information visit{' '}
                  <a href={'http://www.aboutcookies.org'}>
                    www.aboutcookies.org
                  </a>{' '}
                  or{' '}
                  <a href={'http://www.allaboutcookies.org'}>
                    www.allaboutcookies.org
                  </a>
                  .{' '}
                </p>
              </li>
              <li>
                <p>
                  You can set your browser not to accept cookies and the above
                  websites tell you how to remove cookies from your browser.
                  However, in a few cases, some of our website features may not
                  function as a result.
                </p>
              </li>
              <li>
                <p>
                  We&rsquo;re using also Mouseflow{' '}
                  <a href={'https://mouseflow.com/'}>https://mouseflow.com/</a>{' '}
                  and Hotjar{' '}
                  <a href={'https://www.hotjar.com/'}>
                    https://www.hotjar.com/
                  </a>{' '}
                  &nbsp;to track your activity at our website (for example:
                  which pages you visited and when, what items were clicked on
                  a page, how much time was spent on a page, whether you
                  downloaded).{' '}
                </p>
              </li>
              <li>
                <p>
                  We’re using the Usercentrics widget{' '}
                  <a href={'https://usercentrics.com/'}>
                    https://usercentrics.com/
                  </a>{' '}
                  to manage your cookie settings, which you can change anytime
                  in{' '}
                  <a
                    href={'#'}
                    onClick={() => UC_UI.showSecondLayer()}
                    style={{ fontWeight: 'bold' }}
                  >
                    Privacy Settings
                  </a>
                  .
                </p>
              </li>
            </ol>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Google Analytics
            </Heading>
            <p>
              Google Analytics is a web analysis service provided by Google
              Inc. (&ldquo;Google&rdquo;). Google utilizes the Data collected
              to track and examine the use of this Application, to prepare
              reports on its activities and share them with other Google
              services. Google may use the Data collected to contextualize and
              personalize the ads of its own advertising network. Personal Data
              collected: Cookie and Usage data
            </p>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Changes to this privacy policy
            </Heading>
            <p>
              We keep our privacy policy under regular review and we will place
              any updates on this web page. This privacy policy was last
              updated on 21st May 2018.
            </p>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Other websites
            </Heading>
            <p>
              Our website contains links to other websites. This privacy policy
              only applies to this website so when you link to other websites
              you should read their own privacy policies.
            </p>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              Security
            </Heading>
            <p>
              We apply technical, administrative and organizational security
              measures to protect the data we collect against accidental or
              unlawful destruction and loss, alteration, unauthorized
              disclosure or access, in particular where the processing involves
              the transmission of data over a network, and against other
              unlawful forms of processing.
            </p>
          </li>
          <li>
            <Heading type={2} size={'medium'}>
              How to contact us and our details{' '}
            </Heading>
            <ol className={'ordered-list spaced'}>
              <li>
                <p>
                  Please contact us if you have any question s about our
                  privacy policy or information we hold about you: email:{' '}
                  <a href={'mailto:ado@boldare.com'}>ado@boldare.com</a>{' '}
                  &nbsp;Address: Zwycięska 52, 44-100 Gliwice, Poland{' '}
                </p>
              </li>
              <li>
                <p>This website is owned by BOLDARE S.A.</p>
              </li>
              <li>
                <p>
                  We are registered in Poland in the register of entrepreneurs
                  of the Regional Court in Gliwice Economic department X of the
                  National Court Register under KRS number 0000914518, NIP:
                  6312698829, the REGON: 389585559, and our registered office
                  is at Zwycięska 52, 44-100 Gliwice, Poland.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </Section>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
