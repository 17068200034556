import React from 'react';
import { Section, Paragraph, Heading, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './TalentPool.scss';

const TalentPool = () => {
  return (
    <Section
      id={'talent-pool'}
      color={'soft-cloud'}
      additionalClass={'talent-pool'}
    >
      <div className={'talent-pool__column talent-pool__column--image'}>
        <img
          src={getCloudinaryUrl(
            '/img/team-meeting-in-Boldare-square.jpg',
            'f_auto,q_auto',
          )}
          alt={'Boldare Team Meeting'}
          loading={'lazy'}
        />
      </div>
      <div className={'talent-pool__column talent-pool__column--content'}>
        <Heading
          type={4}
          size={'large'}
          color={'black'}
          additionalClass={'talent-pool__title'}
          lineColor={'violet'}
        >
          Boldare Opportunities
        </Heading>
        <SectionHeader
          additionalClass={'headline'}
          color={'mine-shaft'}
          size={'small'}
          headingType={2}
          noLine
        >
          Join our{' '}
          <span className={'headline--marking headline--marking-violet'}>
            Talent Pool
          </span>
        </SectionHeader>
        <Paragraph color={'black'}>
          Not looking for a job but want to have a plan B? <br />
          Join Boldare Talent Pool. If you are successful and an open position
          appears, you will be the first person to whom we make an offer.
          <br />
          <br />
          <b>By joining our talent pool, you will reach the benefits:</b>
        </Paragraph>

        <ul className={'talent-pool__benefits-list'}>
          <li>
            <img
              src={'../assets/images/icons/icon-stars.svg'}
              alt={'icon-stars'}
            />
            <Paragraph>Get a priority to receive a job offer</Paragraph>
          </li>
          <li>
            <img
              src={'../assets/images/icons/icon-shield-star.svg'}
              alt={'icon-shield'}
            />
            <Paragraph>
              Check your seniority level. Will we assume it is the same as with
              your current company?
            </Paragraph>
          </li>
          <li>
            <img
              src={'../assets/images/icons/icon-group.svg'}
              alt={'icon-group'}
            />
            <Paragraph>
              Get to know what&apos;s going on in the labor market and what you
              can expect during recruitment processes now
            </Paragraph>
          </li>
        </ul>
      </div>

      <img
        src={getCloudinaryUrl(
          '/career/circle-with-lines.svg',
          'f_auto,q_auto',
        )}
        alt={'Circle with lines'}
        loading={'lazy'}
        className={'talent-pool__circle-img'}
      />
    </Section>
  );
};

export default TalentPool;
