import teamAlert from 'assets/images/products/portfolio-teamalert.png';
import sonnen from 'assets/images/products/portfolio-sonnen.png';
import mowaamah from 'assets/images/products/portfolio-mowaamah.png';
import tttr from 'assets/images/products/portfolio-tttr.png';

export const data = [
  {
    type: 'sonnen',
    name: 'sonnen',
    description:
      "We created three digital products with sonnen, and all have a real and beneficial impact on the environment. <b>My Sonnen</b> delivers a solution for all sonnenbatterie users - it allows them to manage the battery and visualize the flow of energy. <b>Partner Portal</b> supports the process of selling batteries by providing clear management and building effective offers. And <b>Sonnen Charger</b> is an app that helps using car chargers wisely. All applications enable users to utilize renewable energy sources, and that's why we are proud of them.",
    img: sonnen,
    counter: '01',
    caseStudy: {
      title: `Development support for a solar technology giant`,
      description: `Meet our “sonnen story”! It relates to our experience with a client who provides solar and battery technology to hundreds of homes, allowing people to produce their own electricity. During four years of cooperation, we’ve supported sonnen in creating and developing various digital products that have a beneficial impact on the environment.`,
      post: `
      <p class="paragraph">Our journey started in 2017 when a client from Germany sought support to upgrade their development process in building a platform for customers. We helped sonnen to produce a customer portal to keep clients engaged even after purchase and to provide them with all essential information about sonnen products as well.</p>

      <h5 class="heading">MVP that needed improvement</h5>

      <p class="paragraph">Before we started to work on this product, the customer portal had only basic features and was very problematic in operation. Sonnen wanted to spread the web app's wings and make it more user-friendly. What is more, the loading times of the previous solution were quite high, and that’s why our client needed from us the guarantee of a stable, live and easy-running solution with reduced page loading times. The main aim was to make sonnen’s customers eager to show the portal to their neighbors and friends.</p>

      <h5 class="heading">Transparent and agile style of developing</h5>

      <p class="paragraph">We gathered a scrum development team that contained three frontend developers, a DevOps Specialist, a Scrum Master and a designer. Our team’s skills ensured that we were qualified to fulfil our client’s goals. Throughout the whole development process, sonnen’s in-house backend developer and designer worked with us.

      <p class="paragraph">Collaboration between Boldare and sonnen has been transparent and agile from the very beginning. We cared (and still do!) about permanent communication on Slack, daily meetings and face-to-face appointments at each other’s headquarters. In this way, we helped sonnen to launch a new MVP within two months. The first release of the customer portal took place in December. The response from customer satisfaction surveys was astonishingly positive - with an average score of 4 out of 5 or higher.

      <h5 class="heading">New milestones for the development team</h5>

      <p class="paragraph">After this success, sonnen decided to work with us on the further development of the customer portal and other flagship projects as well. Also at that time, sonnen faced a new challenge: it needed to scale its software development teams from 40 to more than 100 people and chose Boldare to ask for help. Our practical use of Agile in product development and management convinced sonnen that we would be the perfect match for carrying out the company's transformation. After analysis and a long and detail-oriented focus group, Boldare and sonnen’s stakeholders pointed out that the biggest need for the company was to lower complexity and boost their user experience. We also noticed that product development could become more agile and, as a result, bear more fruit. We decided to carry out sonnen’s digital transformation.</p>

      <h5 class="heading">Setting a new plan</h5>

      <p class="paragraph">Our plan for sonnen’s digital transformation was clear and consisted of two fundamentals:

      <ul>
      <li>upgrading the software platform that would enhance all sales processes,</li>
      <li>transferring sonnen’s structure to Agile.</li>
      </ul>

      <p class="paragraph">We cut to the chase and started to implement our proposals and scrum approach. The very first step for sonnen was taking on board an external Agile coach. In the first half of 2019, we conducted the first workshop about scaling and a company needs analysis. In this way, we managed to establish the crucial pain points and - as a result of our actions - a special team was created, responsible for the whole transformation to an agile system.</p>

      <p class="paragraph">With a lot of workshops, supported by agile methodologies, we managed to support many different product development projects. By 2021, we had created/upgraded:</p>

      <ul>
      <li>Customer Portal - the solution for all sonnenBatterie users that helps to manage the batteries.</li>
      <li>An app that supports sonnenCharger - which charges electric vehicles.</li>
      <li>An internal platform that supports cooperation with sonnen partners, by providing clear management of documents and building effective offers for future clients.</li>
      </ul>

      <h5 class="heading">Learning by practice</h5>

      <p class="paragraph">The pay-off for sonnen was the fact that the above-mentioned digital products were all built together - by Boldare and sonnen teams working in partnership. This way, we could work harmoniously with Agile principles and support sonnen employees with something more than only theoretical knowledge. We plumped for sharing our know-how with a series of workshops that helped sonnen people to understand the whole transformation process and become active members of it. In a series of practical meetings, we conducted:</p>

      <ul>
      <li>A scaling workshop</li>
      <li>A kick-off workshop for all people committed to the development process</li>
      <li>Nexus Integration Team workshops</li>
      <li>Event storming workshops</li>
      <li>Roadmap planning - for development teams</li>
      <li>Cross-team retrospectives - to keep an eye on potential problems.</li>
      </ul>

      <h5 class="heading">Long-term cooperation</h5>

      <p class="paragraph">Beginning with analysis, through practical proposals, to learning in practice, we helped sonnen’s development department become self-organizing and agile. What were the (significant) side effects? The quality of sonnen’s customer experience has increased, and their internal projects are better optimized. Now, our client has its own scrum teams that develop their products. Nonetheless, Boldare is still involved in the company's day to day business, as a software development service provider and scrum consultant.</p>
      `,
    },
  },
  {
    type: 'mowaamah',
    name: 'Mowaamah',
    description:
      "Meet <b>Mowaamah</b> - a digital solution that makes people's lives with disabilities easier. It's focused especially on sight and hearing impairments. How does it help? The most important feature of the app is the possibility of connecting with a sign language translator via live video call. This application also enables people with disabilities to live more actively – they can find numerous discounts for disability-friendly spots quickly and easily. <b>Mowaamah</b> supports people with disabilities to engage with society more fully.",
    img: mowaamah,
    counter: '02',
    caseStudy: {
      title: `How to build an accessible app for people with disabilities?`,
      description: `Here’s the story of a unique app that we improved for one of our partners. The app is designed to help people with various disabilities and support them in everyday activities. Read on to find out what challenges we faced and what solutions brought us closer to success.`,
      post: `
      <p class="paragraph">Honestly, we fell in love with this project at first sight because of its mission. The app is called “Mowaamah” which in Arabic means convenience, and it strives towards being a support for people with various disabilities.</p>

      <p class="paragraph">Our client, the private contractor of the Ministry of Labor (KSA), approached us with an existing app and a plan to improve it. The main application’s goal was not only to <strong>support people with disabilities</strong>, helping to live more actively, but also to educate other people about disabilities. The client wanted everyone in Saudi Arabia to understand that people with disabilities are an important part of society.</p>

      <h5 class="heading" id="how-does-mowaamah-work?">How does Mowaamah work?</h5>

      <p class="paragraph">The most important feature of the app is the possibility to connect through a <strong>live video call</strong> with a volunteer or a sign language translator. Also, the app aims to help disabled people to find offers, discounts and welcoming places in their home cities.</p>

      <p class="paragraph">The main problem of this key feature was the quality — both, the client and users, didn’t feel satisfied with the current solution. Feedback after video connection testing was that translators were not receiving calls promptly, and it was hard for them to answer calls and help people on time. Also, the quality of the video calls needed to be improved.</p>

      <h5 class="heading" id="how-does-mowaamah-work?-improvements:-first-things-first">Improvements: first things first</h5>

      <p class="paragraph">One key improvement was that the app should show the status of a call, including the information on how many people are ahead of the caller or how much time he or she needs to wait to talk to a translator.  This way, users could organize their time according to the status of a call, and decide if they want to stay on line. </p>

      <p class="paragraph">Our client pointed out that video calls via Mowaamah need to have two options: “Be my eyes” which means a <strong>call to a volunteer</strong>, and the <strong>Sign Language Translator</strong>.</p>

      <p class="paragraph">The time and budget that our client outlined indicated that the right way to proceed was to build an <strong>MVP (Minimum Viable Product)</strong> from scratch. </p>

      <h5 class="heading" id="how-does-mowaamah-work?-what-is-the-mvp?">What is the MVP?</h5>

      <p class="paragraph">MVP is the second stage of the <a href="https://www.boldare.com/blog/phase-by-phase-benefits-of-full-cycle-product-development/">Full Cycle Product Development process</a>, which we use in Boldare to build products (we cut our work process into various phases). In a nutshell, MVP is an early version of a product presented to customers. It includes only a minimum number of core features.  The main aim of it is to test the product with real users and get valuable feedback for further improvements or pivots. </p>

      <p class="paragraph">Our main goals were to build two crucial features of MVP app and do our best to transform the existing app into an efficient and practical solution. And this is how our journey with Mowaamah got started.</p>

      <h5 class="heading" id="how-does-mowaamah-work?-workshops-—-our-secret-sauce-for-effective-kick-off">Workshops — our secret sauce for effective kick-off</h5>

      <p class="paragraph">Our first step towards an appropriate solution was setting out what exactly we need to work efficiently. To do this, we tailored <strong>workshops with our Partner.</strong></p>

      <p class="paragraph">During the first day of a three-day-long workshop, together with client’s stakeholders, we managed to determine: the target group, the app’s key functionalities, how the product should stand out, the business goals, competition, revenue streams and channels.</p>

      <p class="paragraph">On the second day of the workshop, we thought about how we want to work, which tools and frameworks will fit our needs, and what is crucial, including what exactly <strong>the Definition of Done</strong> means to us. We cooperated, listened to each other and worked out the development process, DoD (Definition of Done) and backlog.</p>

      <p class="paragraph">The last day of the workshop brought us more specific metrics, like user goals, release goals, customer journeys, must-have and nice-to-have features, priorities, and estimations. This three-day workshop put the wind in our sails. With a clear goal, and a ready set of information and tools, we were ready to make Mowaamah better! </p>

      <h5 class="heading" id="how-does-mowaamah-work?-development-process:-estimate,-develop-and-release">Development process: estimate, develop and release</h5>

      <p class="paragraph">Technically, our Agile work process is based on three essential stages: <strong>estimate, develop and release</strong>. The above-mentioned workshop covered the first stage, estimating and preparing a detailed backlog. Our next goal was to develop the two main functionalities: upgrading a video call to volunteer or sign language translator, and enabling easy access to disability-friendly places. </p>

      <p class="paragraph">To provide our users with simple and intuitive support, the risk of mistakes needed to be mitigated to the very minimum. That is why we built Mowaamah differently than other apps on the market and redesigned it from the dashboard up. We decided to choose only one crucial feature to be shown on the main screen to keep users focused. However, all additional functionalities are still available in the menu, but in contrast to most of today’s apps, the main screen contains just one element. Also, the design and colour palette had to be unique to deliver clear and easy to read texts.</p>

      <p class="paragraph"><img src="https://res.cloudinary.com/de4rvmslk/image/upload/f_auto,q_auto,w_1000,c_limit//img/Frame_1.png" alt="" loading="lazy"></p>

      <p class="paragraph">We are aware that balancing business goals and user needs always raises some doubts. Throughout the process, we tried to take into account our client’s expectations and the needs of people with disabilities at the same time. As a result, we prepared <strong>a video provider in Twilio</strong> that helps users to solve problems of everyday life and the official issues (like preparing official letters to departments or offices) as well. Now, it plays the role of “the user’s eyes” and supports them in their daily life. </p>

      <p class="paragraph">After creating the app, we had to validate and check its accessibility by running tests with a group of people with various disabilities. The development process and a great deal of work and testing eventually bore fruit: our client’s and the testing group’s satisfaction. </p>

      <h5 class="heading" id="how-does-mowaamah-work?-challenges-and-opportunities">Challenges and opportunities</h5>

      <p class="paragraph">“The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty” — someone smart once said. With that reasoning, we perceived every challenge as a possibility to tweak the Mowaamah app a little more.</p>

      <p class="paragraph">One of the most challenging parts of our work was to deliver consecutive components of the MVP, keeping in mind that it should consist only of minimum viable features, while constantly caring for the user needs at the same time. At every step, we tried to establish those needs accurately and take them into account with the utmost empathy. Continuously, we had to remember that our app should not only be aesthetic but, above all, it has to <strong>cohere with assistive technologies</strong>, like screen readers or voice-overs. For example, the application should support iPhone or Android screen readers to help people with visual impairment navigate through the menus. We used the backend technologies: PHP 8 / Symfony 5 to provide all necessary features. In apps like this, accessibility is the key objective, more important than the visual side of the product. This crucial principle motivated our 9-person team throughout the whole <strong>development process</strong> that lasted about 3 and a half months.</p>

      <h5 class="heading" id="how-does-mowaamah-work?-that-feeling-when-your-work-is-meaningful!">That feeling when your work is meaningful!</h5>

      <p class="paragraph">Our hearts swell with pride when we see how efficiently the Mowaamah app works. We had the chance to create a helpful tool and support people in need — the mission aspect of this application is the most valuable for us. Also, the goal of calibrating the product to the needs of a unique target group was a truly teaching experience. </p>

      <p class="paragraph">What else? Mowaamah stands out in the Saudi Arabia market, because it connects users with different disabilities (visual, auditory, physical and cognitive) and makes their life easier. We managed to care for various sets of requirements in a single application.</p>

      <h5 class="heading" id="how-does-mowaamah-work?-and…-that’s-the-end?">And… that’s the end?</h5>

      <p class="paragraph">No! Now, with the initial mission complete, we’re planning further features. It’s time to validate user needs and adjust business goals with our <a href="https://www.boldare.com/blog/the-role-of-product-strategist/">Product Strategist</a>. In the next stage, we would like to focus on the necessary functionalities of the app, like job offers, upgrading the list of disability-friendly places or the option to change colours. Our goal is to create an app that will support people with disabilities in various life areas, making us feel proud.</p>

      <p class="paragraph"><strong>Learn more about our mission at Boldare. Check some of <a href="https://boldare.com/products?utm_source=Refferal&amp;amp;utm_medium=boldare.com%252Fblog%252F&amp;amp;utm_campaign=mowaamaharticle">the products that we work on</a> and are proud of!</strong></p>
      `,
    },
  },
  {
    type: 'teamAlert',
    name: 'Team Alert',
    description:
      "<b>Team Alert</b> is a type of panic button that allows you to call for help in various situations. You can use it when your coffee machine is broken, but also if you are in some kind of life-threatening situation, like a robbery. This application not only helps people to solve everyday problems but improves public safety as well. We believe that it may even save someone's life one day. Over 3,000 companies have already purchased a subscription to this solution.",
    img: teamAlert,
    counter: '03',
    caseStudy: {
      title: `How to find the golden mean between simplicity and expanded functionality in an emergency app?`,
      description: `Meet our recipe for emergency assistance:  the “Team Alert” panic button. What improvements did we make to ensure it is well-developed and user-friendly? And how did we travel from MVP to PMF? Read the story.`,
      post: `
      <p class="paragraph">Generally speaking, a panic button is a solution that allows you to call for help in emergency situations, like fire or robbery. Team Alert goes one step further and provides support in daily life difficulties as well — not every alert is in response to the same level of danger; Team Alert might be also used in cases like spilt acid in a laboratory, or a device failure.</p>

      <p class="paragraph">How does it work? Very simply. The person who needs help pushes the button and, as a result, the system sends notifications to the particular people and services that should be called. Team Alert is available in both physical and digital versions. You can equip yourself with a panic button or just use the app on a phone or laptop.</p>

      <p class="paragraph">What is important, our client has conceived this product mainly for institutional bodies like schools, prisons, hospitals and medical facilities.</p>

      <h5 class="heading">How does Team Alert differ?</h5>

      <p class="paragraph">As we already mentioned, contrary to a classic panic button, Team Alert helps not only in life-threatening situations but also supports users in technical or everyday obstacles. What else makes Team Alert unusual? <strong>The wide range of supplied configurations.</strong> Most panic buttons just send notifications to all the resources they can reach, responding the same to every emergency or use. While working on Team Alert, we wanted to upgrade this area and give the person in need more options. Keep reading and check out the results!</p>

      <ul>
      <li>
      The user can choose the type of alert that he or she sets off. Depending on the type of alarm selected, Team Alert calls the necessary specific contacts. It includes an administrator role in the user’s organization to manage specific connections and assign them to particular situations and circumstances. The administrator plans who should be notified in case of emergency or technical failure, etc.
      </li>
      <li>
      An additional feature of Team Alert is the chat option that comes with each alarm. The app automatically sends essential information or instructions to the chosen group of recipients. But it can also offer more assistance, for example, warning people who might be in danger because of the existing situation. It’s worth mentioning that the person who calls for help has the opportunity to inform others about the location of an attacker or provide a more specific description of the situation.
      </li>
      <li>
      We emphasized customization and provided Team Alert administrators with a variety of actions and channels to choose from. The controller can choose the type of notification, including automatic voice calls, text messages, emails, or push notifications. The application also offers its services in all modern languages. There is a manager area that allows the user to configure the group of receivers and the type of channel. The admin can decide who exactly will receive an email and who should be informed via a phone call. Our goal was to simplify the app’s use and make it as efficient as possible.
      </li>
      <li>
      Using Team Alert means sending files as well. Users can attach necessary instructions and images, or use default messages and answers that the administrator had previously uploaded to the application.
      </li>
      </ul>

      <h5 class="heading">Customization on the front line</h5>

      <p class="paragraph">Are you curious about how we worked with our client and developed this solution? Keep reading for more details.</p>

      <p class="paragraph">When we met with our client for the first time, they already had a panic button application. However, the client wasn’t satisfied with their current product and needed a partner who could develop the whole system and implement new features, paying special attention to customization. The software of the previous solution needed to be renewed and simplified. The challenge that we faced was to improve quality and transfer the software to the AWS cloud, as well. Faced with this challenge, our six-person team decided to create a product from scratch and prepare an MVP.</p>

      <h5 class="heading">What is an MVP?</h5>

      <p class="paragraph">An MVP (Minimum Viable Product) is the second stage of the Full Cycle Product Development process which we use in Boldare to build products (we split our work processes into various phases). Long story short, an MVP is an early version of a product presented to customers. It includes only the minimum number of core features. The main aim of it is to test the product with real users and get valuable feedback for further improvements or pivots.</p>

      <p class="paragraph">Our first step towards the tailored MVP solution was setting out what exactly we needed to work efficiently, what features needed to be improved and implemented, and what were the needs of the target group. To cover all these elements, we decided to <strong>conduct workshops</strong> with our partner.</p>

      <h5 class="heading">Discovering the product</h5>

      <p class="paragraph">The two days of workshops were intensive and required lots of analysis and metrics to lead. We gathered together in a larger group. Who attended? The CEO and customer service representative from Team Alert, and the whole team handling the project from Boldare.</p>

      <p class="paragraph">Product Discovery workshops are our way of finding the most effective and successful solution for a client. The overall setup of a development project with a client can be a complex and intricate process, and these two days of work and discussions allow us to determine many of the details before going ahead. In this case, we worked out the target group, the product and business goals, user goals, product benefits, and release goals. We also described the customer journeys, the app’s functionalities and lines of customization, priorities, and estimations. Last (but not least!), we also planned the first sprint, which consisted of the product backlog, the definition of done and overall sprint goal.</p>

      <p class="paragraph">Cooperation between Team Alert and Boldare was based on lots of conversations, listening to each other, and creating tailored solutions at every stage of building the product. Agile work brought us a quite big dose of trust from our client, and it made the whole process even more flexible and effective. The Team Alert crew used the Boldare team’s knowledge and expertise, treating us as business consultants and experts. As a result, our client got a fresh perspective on the product and was able to see new possibilities.</p>

      <h5 class="heading">All roads lead to improvement</h5>

      <p class="paragraph">We mainly focused on a strategy of continuous improvement, implementing more and more new features in Python, React, Xamarin and .Net Electron. The iterations included new app languages, voice calls, default messages, and predefined responses to alerts. We admit that it was challenging to prepare such an advanced and extensive tool and keep it as simple as possible at the same time. Throughout the whole development, we kept in mind that Team Alert, above all, needs to be user-friendly.</p>

      <p class="paragraph">All of our actions smoothly led us from the <strong>MVP (Minimum Viable Product)</strong> to the <strong>PMF (Product-market fit)</strong> phase.</p>

      <h5 class="heading">What is PMF?</h5>

      <p class="paragraph">PMF is the next phase of Full Cycle Product Development — our process for building products that we mentioned earlier. At this point in development, we create the full-value product. With designers and web developers on board, the Product-Market Fit team builds new product features and tests them with app users. The PMF stage is more advanced in comparison to the MVP and, among the ohers, focuses on improvements.</p>

      <p class="paragraph">We guided our client from MVP to PMF by means of a series of workshops. The whole process, facilitated by Product Strategist,  started with a Business Model Canvas workshop (which helped us look at the whole product environment, value proposition and customer segments). Afterwards, we spent some time on Customer Segments workshop where together with our client’s team we analysed sales data and went through a focused series of questions about each segment. This allowed us to notice trends we had not seen before and draw conclusions which segments might be the one we should focus our efforts on.  Next, we explored these identified segments deeper, established new personas, identified the new persona’s problems and created specific value propositions to be tested with them. The value proposition in this case simply means the value that the product promises to provide to the particular target group, in its specific conditions and circumstances. The last but one step in this process was in-depth interviews with the personas to confirm a list of hypotheses that we created. We drew specific product insights from these interviews, validated our hypotheses, and learned an awful lot directly from the users. This is the basis for further product development — not guesswork, but validated insights from the market.</p>

      <h5 class="heading">Hard work bears fruit</h5>

      <p class="paragraph">Speaking about testing and iterations, our experience with Team Alert was an opportunity for further learning. After preparing the value propositions, we focused on internal testing and created a product road map. With another step forward, we picked out the essential features that needed to be implemented to find new markets for Team Alert to grow. We continue to take a dual approach: constantly working on new improvements, and also focusing on the effectiveness of a product.</p>

      <p class="paragraph">What was the most challenging part of our work? Probably balancing the simplicity of the system against the need for a wide range of functionalities at the same time. Delivering this compromise wasn’t easy but brought us great satisfaction. Also, it was a great opportunity to build a product that actually meets real needs and contributes to greater safety in society. Team Alert has to be reliable and unfailing, because it’s an answer to the real needs of citizens.</p>
      `,
    },
  },
  {
    type: 'tttr',
    name: 'Collaboration with Tech To The Rescue',
    description:
      "Is it meaningful to help organizations spread their social impact? We believe it is. That's why Boldare has joined the group of Tech To The Rescue partners. TTTR is a voluntary movement of tech companies to help social impact organizations implement digital solutions to scale up their influence. Find out how our people fulfil their values by acting for charity in the IT sector.",
    img: tttr,
    counter: '04',
    caseStudy: {
      title: `How to support social impact organizations in Boldare?`,
      description: `Is it meaningful to you to help organizations spread their social impact? Do you want to join an organization that builds products pro bono? At Boldare, you would hit home! Find out how our people fulfil their values by acting for charity in the IT sector.`,
      post: `
      <p class="paragraph">Recently, Boldare has joined the group of Tech To The Rescue partners. TTTR is a voluntary movement of tech companies to help social impact organizations implement digital solutions to scale up their influence. They believe there are not enough technology resources in the social world, but we can unlock them if companies contribute to pro bono projects. Does it speak to you? Boldare people chime in with this idea!</p>

      <p class="paragraph">At Boldare, we speak our truth loud and proud and are not afraid to act bravely. Helping social impact organizations in a pro bono way requires courage, future-oriented thinking, and a big heart. That’s why we’ve jointly decided to support TTTR and contribute to the upcoming market shift.</p>

      <p class="paragraph">Boldare gathers people who think about the future and want to create solutions that solve real problems. They wish to help others and use their technical resources to enact meaningful change. That’s the reason we build products with a great dose of empathy and boost the social impact of organizations whose aims are close to our values and worldview. If building products with a mission is essential for you, you would be a perfect fit to Boldare. Keep reading and get to know our pro bono journey!</p>

      <h5 class="heading">What is “Tech To The Rescue” movement?</h5>

      <p class="paragraph">Let’s start from a little amount of data. Currently, TTTR has about 35 projects in 12 countries. Their work has a chance to impact 900,000 people around the world. They filter social impact organizations and choose those who have minimal technical competencies but have the time to invest in the desired product. Next, the movement’s partners decide which institutions they wish to collaborate with.</p>

      <p class="paragraph">We can say that TTTR makes a kind of bridge between two seemingly alien worlds and makes it possible for future-oriented tech companies to have a real impact on society. So, once Boldare had joined TTTR’s network partners, we could choose the organization that we would like to support, and then start to cooperate with them. TTTR has prepared a special backlog for Boldare and allow us to look through the available products to work on. In the first place, Boldare people decided to help the Dziewuchy Dziewuchom Foundation.</p>

      <p class="paragraph">At Tech To The Rescue, we make it possible for a dialogue between tech companies and non-profit organizations to begin. This should lead to a new market standard that will build a bridge over a technological gap in the social good sector. Innovation is needed to scale up positive social and ecological impacts. Our platform is the first step towards it. We believe that building GOOD TECH is no longer enough. Building TECH solutions for GOOD that can address the most pressing problems of today’s world is what will distinguish industry Leaders from the rest. — says Daniel di Giusto, Co-founder & Head of Community at TTTR.</p>

      <h5 class="heading">How does Boldare team support the Dziewuchy Dziewuchom Foundation?</h5>
      <p class="paragraph">The essential need of the Dziewuchy Dziewuchom Foundation was for a platform that could become a solid base. The needed solution should allow the Foundation to manage content. A platform will also contain a necessary module for fundraising. Boldare team met the expectations and wanted to help this social impact organization to spread their forces.</p>

    <p class="paragraph">So far, Boldare’s team has conducted a Product Discovery workshop with Dziewuchy Dziewuchom. The main goal of this two-day-long event was to establish both the business and product goals and assess the hypothesis that required validating. At Boldare, this is our usual way of preparing and gathering information before building a product.</p>

    <p class="paragraph">Through the workshop, we managed to determine the target group, the platform’s key functionalities, and the business goals. We also had time to decide how we want to work, which tools and frameworks will fit our needs, and what is crucial for the product.</p>

    <p class="paragraph">It is worth saying that the Product Discovery workshop was held stationary. We’ve considered it crucial to gather all involved and build a friendly, chamber and open atmosphere. During the workshop, the Boldare team acted as a group of consultants. Above all, we carefully listened to our contractors to deeply conceive their specified needs. While choosing the best technology, we took into account the financial aspect and all the circumstances that Dziewuchy Dziewuchom has described to us. We fit the offered solution to their problem to deliver a valuable and usable product in future.</p>

    <p class="paragraph">The two-day-long Product Discovery workshop was an interesting and inspirational experience for the members of the Dziewuchy Dziewuchom Foundation. All the way through, we felt that we were in good hands. Boldare provided a team of professionals that recognized and diagnosed our needs perfectly. What is more, Boldare people are extremely open and caring. We’ve noticed that we speak the same language from the very beginning. Inclusivity and equality are our mutual values. The trust - built by Boldare - helped us to go through the verification of our ideas and visions. These two days also let us look at the Foundation from a distance and think about the future. We’re honestly grateful for this expertise. - comments Anna Berdowska, Social Media and Fundraising Specialist in Dziewuchy Dziewuchom Foundation.</p>

    <p class="paragraph">Among the workshop, the Boldare team prepared for Dziewuchy Dziewuchom two design systems with other mood boards to select. Speaking about the interesting facts, we have also conducted a webinar about digital products’ accessibility. We felt that, just like we do, our contractor cares about inclusivity. Thus, we decided to raise this subject and expand it in the next steps of the development process.</p>

    <p class="paragraph">Now, after the Product Discovery workshop, we’re working on proposing the best technical solutions that will answer all the Foundation’s needs. We need to act agile throughout the whole product-building process. It eases implementation of further improvements at the later stages of creating the solution.</p>

    <h5 class="heading">We share values and it builds motivation!</h5>
    <p class="paragraph">We found the common ground with the Foundation that allowed us to act together effectively. Things that we especially appreciate in our cooperation are the professional approach, openness to new ideas and processes and organizational maturity of the Foundation. Thanks to the open dialogue, we managed to implement a non-obvious solution. We designated the product owner on the Boldare side. Usually, a product owner is a role that assumes the client’s representative. Sometimes, when the client’s team has a lack of time or capacity, we offer such an alternative solution. In such cases, mutual trust is essential.</p>

    <p class="paragraph">I am always excited about building products that have a real impact on reality and society. At Boldare, we realize products in the special cycle of stages that we called Full Cycle Product Development. I believe both social impact and unique building process make our work even more precious — for us and the client. — mentions Karla Sutil, Process Guide in Boldare.</p>

    <p class="paragraph">Members of the Boldare team that took part in the Product Discovery workshop with Dziewuchy Dziewuchom speak coherently: “the great thing about our crew is that we share common values”. It was their decision to join this particular team and work on the specific pro bono product. As a result, they cooperate with people with a similar point of view, which spreads their wings and builds motivation.</p>

    <p class="paragraph">What is crucial, some members of this crew came from our bench. For the record, you should know that the bench at Boldare is not scary at all! Even if you face a short downtime period, you will still have an opportunity to work on an intriguing pro bono projects. Stagnation? Developers at Boldare don’t know what it is.</p>

    <h5 class="heading">Acting for charity in the IT sector</h5>
    <p class="paragraph">We believe that helping great ideas to scale up is valuable. If we have the chance to act for a charity or boost the social impact of foundations or movements, we engage and contribute to them. Apart from joining TTTR and helping Dziewuchy Dziewuchom, what other CSR projects have we participated in the last year?</p>

    <ul>
    <li>
    Supporting the “Kosmos dla Dziewczynek” Foundation by sending one hundred books to libraries. “Nie obraź się, ale” was written by Kasia Mecinski, and it talks about pressure among women.
    </li>
    <li>
    Promoting the campaign “Ubrania do oddania” that supports a second life for clothes.
    </li>
    <li>
    Helping students from Politechnika Wrocławska to build a sports app.
    </li>
    <li>
    Sharing our workspace with students to enable them to create underwater drones.
    </li>
    <li>
    Mentoring at Best Design Week – an event that helps students to start their design adventure.
    </li>
    <li>
    Auctioning off our scrum workshops at WOŚP 2021.
    </li>
    <li>
    Supporting Szlachetna Paczka.
    </li>
    <li>
    Sharing knowledge via various workshops, webinars, podcasts, conferences, and interviews.
    </li>
    </ul>

    <p class="paragraph"><b>Would you like to get to know Boldare better? Are you curious about our products and values? Meet Boldare’s products!</b></p>
      `,
    },
  },
];
