import React from 'react';
import { Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { useWindowWidth } from 'hooks';
import { data } from './data';
import { SocialBar } from './subcomponents';
import './AboutUsSection.scss';

const AboutUsSection = () => {
  const isTabletBig = useWindowWidth() >= 992;

  return (
    <section id={'f2f-about'} className={'f2f-section-about'}>
      <div className={'about-podcast__text-container'}>
        <Paragraph
          additionalClass={'about-podcast__upper-title text--bold'}
          color={'pigeon-post'}
        >
          We are Boldare!
        </Paragraph>
        <Heading type={2} additionalClass={'about-podcast__title'}>
          We want to <br />
          meet you in person
        </Heading>
        <Paragraph
          additionalClass={'about-podcast__description'}
          color={'white'}
        >
          Forget about being alone and bored in front of the computer! At
          Boldare, we regularly meet in real life to spend time together and
          get to know each other better. We are people, not avatars.
          {isTabletBig && (
            <>
              <br />
              <br />
            </>
          )}
          Working face-to-face has evolved – we know that, and bet on
          alternative solutions that emphasize our team spirit. We want to have
          something more than “just a job”. Listen to the podcast about our f2f
          work ideas.
        </Paragraph>
        <a
          href={'https://boldare.click/podcast-pracownia'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classNames(
            'button',
            'button--transparent-white',
            'about-podcast__button',
            'ga-f2f--podcast',
          )}
        >
          Hear about F2F work
        </a>
      </div>
      <div
        id={data.id}
        className={classNames('glide', 'about-us__image-slider')}
      >
        <ul className={'glide__slides'}>
          <li className={'glide__slide'}>
            <img
              className={`about-us__slide-image`}
              alt={data.alt}
              src={getCloudinaryUrl(data.cloudinaryDir, `f_auto,q_auto,w_752`)}
              loading={'lazy'}
            />
          </li>
        </ul>
      </div>
      <SocialBar additionalClass={'about-podcast__social-bar'} />
    </section>
  );
};

export default AboutUsSection;
