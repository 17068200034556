import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';
import { Section, SectionHeader } from 'components';
import './EditorIntro.scss';
import { getArticleByName } from 'config/helpers';
import { SinglePostTile, SinglePostTileTopic, FeatureBanner } from '..';
import config from '../../../../../meta/config';

class EditorInfo extends Component {
  render() {
    const { posts, authors, toggleFilter } = this.props;
    const { editorsPick } = config.blog;
    const { bottomLeftTile, bottomCenterTile, bottomRightTile } = editorsPick;

    const bottomLeftArticle = getArticleByName(bottomLeftTile, posts);
    const bottomCenterArticle = getArticleByName(bottomCenterTile, posts);
    const bottomRightArticle = getArticleByName(bottomRightTile, posts);

    return (
      <div className={'editor-intro'}>
        <Section
          color={'mine-shaft'}
          additionalClass={'editor-intro__wrapper'}
        >
          <SectionHeader
            lineColor={'yellow'}
            size={'big'}
            color={'white'}
            description={`Boldare's insights on digital product design and development`}
            descriptionColor={'white'}
            fixed
            headingType={1}
          >
            Digital Shift
          </SectionHeader>
          <Row className={'no-gap editor-intro__row'}>
            <SinglePostTileTopic />

            <FeatureBanner
              link={'https://www.boldare.com/apb-community-newsletter'}
              cover={'apb-community/apb-feature-post.png'}
              title={'Get the monthly digest that helps build better products'}
              buttonIcon={'arrow-right'}
              buttonColor={'white'}
              buttonText={'Sign up '}
            />

            <SinglePostTile
              post={bottomLeftArticle}
              authors={authors}
              toggleFilter={toggleFilter}
              theme={'black'}
            />

            <SinglePostTile
              post={bottomCenterArticle}
              authors={authors}
              toggleFilter={toggleFilter}
              theme={'black'}
            />

            <SinglePostTile
              post={bottomRightArticle}
              authors={authors}
              toggleFilter={toggleFilter}
              theme={'black'}
            />
          </Row>
        </Section>
      </div>
    );
  }
}

export default EditorInfo;
