import React from 'react'
import { Planet, Section } from 'components/DS'
import SingleStat from 'views/home/subcomponents/Prizes/subcomponents/Stats/SingleStat'
import { consultingServicesData } from './logic'

import './ConsultingServices.scss'

export const ConsultingServices = ({ data }) => {
  return(
  <Section color={'white'}>
    <div className={'consulting-services__planet-container'}>
      <Planet
        theme={'dark'}
      />
    </div>
    <div className={'consulting-services'}>
      <div>
        <h1 className={'consulting-services__header'}>
          {data.title ?? 'Product development consulting services'}
        </h1>
        <p className={'consulting-services__description'}>
          {data.description ?? 
          "Putting your actual and future users in the heart of what we do is our greatest focus. Choosing Boldare as your digital development partner, you get not only an excellent team of consultants but also the support from over 200 product development practitioners, including: Product Strategists, Solution Architects, DevOps, QA Specialists, Business Analysts, Scrum Masters, and more."}
        </p>
      </div>
      <ul className={'consulting-services__section'}>
        {data ? 
          <>
            {data.servicesItems?.map(({ item }, index) => (
              <div className={'consulting-services__cards'} key={index}>
                <SingleStat
                  key={index}
                  decoration={index + 1}
                  rotation={index % 2 ? '5deg' : '-5deg'}
                  text={item}
                />
              </div>
            ))}
          </>
          :
          <>
            {consultingServicesData.map(({ decoration, rotation, description }, index) => (
              <div className={'consulting-services__cards'} key={index}>
                <SingleStat
                  key={index}
                  decoration={decoration}
                  rotation={rotation}
                  text={description}
                />
              </div>
            ))}
          </>
        }
      </ul>
    </div>
  </Section>
  );
}
