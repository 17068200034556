import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from 'components';

import './TileSectionHeader.scss';

function TileSectionHeader({ showTiles, setShowTile }) {
  return (
    <div className={`tile-section-header`}>
      <div className={`tile-section-header__wrapper`}>
        <Heading
          level={2}
          weight={'bold'}
          color={'black'}
          size={'medium-plus'}
          additionalClass={'tile-section-header__text'}
        >
          {`${"Didn't find the information you were looking for?"}`}
        </Heading>
        {!showTiles && (
          <Button
            order={'primary'}
            color={'pigeon-post'}
            ariaName={'Box link'}
            onClick={() => setShowTile(true)}
          >
            Click here
          </Button>
        )}
      </div>
    </div>
  );
}

TileSectionHeader.propTypes = {
  showTiles: PropTypes.bool.isRequired,
  setShowTile: PropTypes.func.isRequired,
};

export default TileSectionHeader;
