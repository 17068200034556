export const tileProperties = [
  {
    id: 1,
    title: 'Get the AI into play!',
    desc:
      "Discover our AI-backed services and see how this technology can improve your product or optimize its costs. Don't have any specific ideas? Leave it to us, and we'll come up with some viable solutions!",
    button: 'Learn more',
    link: '/services/ai-software-development-consulting/',
    color: 'violet',
    image: 'app-costs-calculator/satellite',
  },
  {
    id: 2,
    title: 'Clarify your idea!',
    desc:
      'Book our free Discovery Session to receive a scope proposal, product development timeline, technology recommendations, optional C4 visualizations, and budget estimates to efficiently guide your next steps.',
    button: 'Book now',
    link: '/book-a-discovery-session/',
    color: 'red',
    image: 'app-costs-calculator/desk-women',
  },
];
