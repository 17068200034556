import React from 'react';
import PropTypes from 'prop-types';

import { Section } from 'components';
import { getRecommendClientImageUrl } from 'views/recommendClient/utils';

import './TargetGroup.scss';

const LIST = [
  {
    icon: 'product-design-and-development',
    title: 'Make sure where to pivot',
  },
  {
    icon: 'product-strategist',
    title: 'Receive help in building a fail-proof strategy',
  },
  {
    icon: 'cto-as-service',
    title: 'Validate the investing opportunity',
  },
  {
    icon: 'ux-ui-design',
    title: 'Get a clear development plan without expensive consultants',
  },
];

function ListItem({ icon, title }) {
  return (
    <li className={'book-discovery-session__target-group__list-item'}>
      <img src={getRecommendClientImageUrl(`${icon}.png`)} alt={title} />
      <h5 className={'book-discovery-session__target-group__list-item__title'}>
        {title}
      </h5>
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function TargetGroup() {
  return (
    <>
      <Section color={'white'}>
        <h2
          className={
            'book-discovery-session__heading book-discovery-session__target-group__heading'
          }
        >
          Must-have consultation for professionals who&nbsp;want&nbsp;to:
        </h2>
        <div className={'book-discovery-session__target-group__content'}>
          <ul>
            {LIST.map(({ icon, title, text }) => (
              <ListItem key={icon} icon={icon} title={title} text={text} />
            ))}
          </ul>
        </div>
      </Section>
    </>
  );
}

export default TargetGroup;
