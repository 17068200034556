export const data = [
  {
    logo: 'logoBBC',
    content:
      'The popularity and success of BlaBlaCar today is likely to make any investor who turned them down 10 years ago cry into their cornflakes.',
  },
  {
    logo: 'logoKhaleejTimes',
    content:
      'Working closely with its partners and customers allows Matic to develop state-of-the-art technology.',
  },
  {
    logo: 'logoIncArabia',
    content:
      'Saudi Government Rolls Out New Service To Boost Small Entrepreneurs',
  },
];
