import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { IconSVG } from 'components';
import Speaker from './Speaker/Speaker';

import './DiscussionEventInfo.scss';

const DiscussionEventInfo = () => {
  const ahmedRawassImage = getCloudinaryUrl('ahmed-rawass', 'f_auto,q_auto');
  const zaruImage = getCloudinaryUrl('discussion-event/zaru', 'f_auto,q_auto');

  return (
    <div className={'discussion-event-info-container'}>
      <div className={'discussion-event-info-banner'}>Online discussion</div>
      <p className={'discussion-event-info-header'}>
        When to Invest in UX Strategy for Your Company
      </p>
      <p className={'discussion-event-info-subheader'}>
        3 cases from Takamol Holding, Saudi innovative solutions leader
      </p>
      <div className={'discussion-event-info-localization'}>
        <IconSVG name={'map-pin'} size={'large'} color={'black'} />
        <p className={`heading heading--big text--`}>Webinar on demand</p>
      </div>

      <div className={'discussion-event-info-speakers'}>
        <Speaker
          image={zaruImage}
          fullName={'Anna Zarudzka'}
          position={'co-CEO'}
          company={'Boldare'}
          linkedIn={'https://www.linkedin.com/in/annazarudzka/'}
        />
        <Speaker
          image={ahmedRawassImage}
          fullName={'Ahmed Rawass'}
          position={'Senior Manager UX'}
          company={'Takamol'}
          linkedIn={'https://www.linkedin.com/in/arawass'}
        />
      </div>
    </div>
  );
};

export default DiscussionEventInfo;
