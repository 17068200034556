import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Heading, HeroSlider } from 'components';

import QuoteMark from './images/quote.svg';
import { opinions } from './data.js';

import './Slider.scss';

function Slider() {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section className={'reviews-slider w-container'} ref={ref} id={'slider'}>
      <Heading
        size={'large'}
        color={'white'}
        additionalClass={'reviews-slider__heading'}
      >
        Reality check: what clients think about us
      </Heading>
      <div className={'reviews-slider__wrapper'}>
        <img
          src={QuoteMark}
          className={'reviews-slider__quote-mark'}
          alt={'quotation mark'}
        />
        <HeroSlider inView={inView} key={inView} slides={opinions} />
      </div>
    </section>
  );
}

export default Slider;
