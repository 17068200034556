import React from 'react';
import { Section, SectionHeader, WayPointHandler } from 'components';
import { Methodology } from './Methodology';
import Technology from './Technology';
import './Approach.scss';

const Approach = () => (
  <WayPointHandler path={'approach'}>
    <>
      <Section additionalClass={'approach__main'} color={'white'}>
        <SectionHeader
          lineColor={'red'}
          descriptionColor={'black'}
          description={
            'Boldare takes a holistic approach to product development. When creating products, we always take into consideration the following factors: business goals, market and user needs, features and unique differentiators. We use proven processes and methods that help us deliver human-centered products that drive value.'
          }
        >
          Approach
        </SectionHeader>
        <Methodology />
      </Section>
      <Technology />
    </>
  </WayPointHandler>
);

export default Approach;
