import React from 'react';
import './ContactFormDe.scss';
import { ContactFormBiz, Link } from 'components';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';
import { scheduleMeetingUrl } from 'config/constants/links';
import { topics } from './logic';

const titleContactProps = 'Wie können wir Ihnen helfen?';
const descriptionContactProps =
  'Füllen Sie einfach unser Kontaktformular aus. Unsere Experten kommen dann umgehend auf Sie zu.';
const descriptionTwoContactProps = 'Ich bin interessiert an:';
const privateDataInfoContactProps = () => (
  <>
    Ihre Daten sind bei uns sicher. Wir nehmen Ihre Privatsphäre ernst und
    werden Ihre Daten nicht an Dritte weitergeben.{' '}
    <Link to={'/privacy-policy/'} id={'contact-biz-privacy-policy'}>
      Datenschutzrichtlinie
    </Link>{' '}
    um mehr zu erfahren.
  </>
);

const responseButtonTextContactProps = 'Treffen Sie unser Team';

const responseSendContactHeaderContactProps = () => (
  <>
    Vielen Dank!
    <br />
    Ihre Nachricht wurde übermittelt!
  </>
);

const responseSendContactHeaderTwoContactProps = () => (
  <>
    Unsere Experten sichten Ihre Idee und kommen innerhalb der kommenden
    24&nbsp;Stunden auf Sie zu.
    <br />
    Wenn Sie Hilfe benötigen, rufen Sie uns unter:{' '}
    <a href={`tel:${CountryContactPhoneNumbers.USA.value}`} additionalClass={'link__underline'}>
      {CountryContactPhoneNumbers.USA.displayValue}
    </a>{' '}
    oder vereinbaren&nbsp;Sie einen&nbsp;Termin mit uns
    <a
      href={scheduleMeetingUrl}
      additionalClass={'link__underline'}
    >
      {' '}
      hier
    </a>
    !
  </>
);

const topicsDataContactProps = {
  topics,
};

const ContactFormDe = () => (
  <ContactFormBiz
    title={titleContactProps}
    description={descriptionContactProps}
    descriptionTwo={descriptionTwoContactProps}
    privateDataInfo={privateDataInfoContactProps}
    topicsData={topicsDataContactProps}
    button={'Senden'}
    phoneText={'Telefon:'}
    emailText={'Mail:'}
    firstNameContent={'Vorname'}
    companyEmailContent={'Firmen E-Mail-Adresse'}
    textareaContent={'Projektbeschreibung'}
    id={'form'}
    lang={'de'}
    name={'contact-form-biz'}
    pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x5r'}
    meetButton
    responseButtonText={responseButtonTextContactProps}
    responseSendContactHeader={responseSendContactHeaderContactProps}
    responseSendContactHeaderTwo={responseSendContactHeaderTwoContactProps}
  />
);

export default ContactFormDe;
