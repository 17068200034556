import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Program from 'views/resources/Program';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import diagram from 'assets/images/software-development-outsourcing/outsourcing_diagram.svg';
import config from '../../../meta/config';

const page = pages.resources.items['outsourcing-step-by-step'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/outsourcing-step-by-step/`;

const OutsourcingStepByStep = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Program
        title={[
          <span className={'program__headin'}>
            Learn how to take advantage of software
          </span>,
          <span className={'program__heading--bold'}>
            development outsourcing
          </span>,
        ]}
        lead={`Do you feel overwhelmed with all the information about software development outsourcing?`}
        diagram={diagram}
        items={[
          'Choosing the best software development provider',
          'Why price matters',
          `Why agile methodologies are better than waterfall`,
          '“Safe” countries for such collaborations',
          'How scrum helps to build better apps',
          'Practical examples of successful collaborations in our case studies',
        ]}
        description={[
          `Sign up for our free program and learn about:`,
          `About the program`,
          `How do you deal with different pricings, methodologies and other factors? There are many things to pay attention to. Don’t worry, we can help sort it out for you!`,
          `Our Software outsourcing step by step program is dedicated to answering all of these questions and more for anyone looking for an external partner or wanting to learn how to collaborate successfully.`,
          `We send our messages each week and you can unsubscribe at any time. Materials include articles and videos.`,
        ]}
        url={url}
        type={'program'}
        formHeading={'Sign up for a free program'}
        button={'Send'}
        btnColor={'yellow'}
        trackingEvent={`ebookSignupDevelopmentPartnerChecklist`}
        consultName={'Matt Koscielak'}
        consultPosition={'New Business'}
        consultPhoto={'matt_koscielak.png'}
        consultColor={'yellow'}
        redirectTitle={'Thank you for signing up for the program!'}
        redirectContent={
          'You will find the first message in your e-mail box soon. We hope you will enjoy the program!'
        }
        redirectButton={{ label: 'Check our case studies', path: '/work/' }}
      />
    </Layout>
  );
};

export default OutsourcingStepByStep;
