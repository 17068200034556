import WorkroomCoffee from './Coffee';
import WorkroomCouch from './Couch';
import WorkroomCover from './Cover';
import WorkroomRestaurant from './Restaurant';
import WorkroomMap from './Map';
import WorkroomWork from './Work';

export {
  WorkroomCoffee,
  WorkroomCouch,
  WorkroomCover,
  WorkroomMap,
  WorkroomRestaurant,
  WorkroomWork,
};
