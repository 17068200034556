import React, { useRef } from 'react';
import { useScrollAnimation } from 'hooks';

export default function withScrollAnimation(Component) {
  return function WrappedComponent(props) {
    const image = useRef();

    useScrollAnimation([
      {
        ref: image,
        transform: { translateX: '-100px', translateY: '-100px' },
      },
    ]);

    return <Component {...props} image={image} />;
  };
}
