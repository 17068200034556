import React, { useRef, useEffect, useState } from 'react';
import './Couch.scss';
import { FullwidthImage, Heading, Button, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';
import inView from 'in-view';
import { classNames } from 'utils';

const Couch = () => {
  const secondPhone = useRef();
  const [entered, setEntered] = useState(false);

  useScrollAnimation([
    {
      ref: secondPhone,
      transform: { translateX: '120%' },
    },
  ]);

  useEffect(() => {
    inView('.workroom-couch').on('enter', () => {
      setEntered(true);
    });
  }, []);

  return (
    <div className={'workroom-couch'}>
      <div
        className={classNames('workroom-couch__banner', {
          'workroom-couch__banner--entered': entered,
        })}
      >
        <img
          className={'image workroom-couch__banner-img'}
          src={getCloudinaryUrl(
            'img/awwwards_composition.png',
            'f_auto,q_auto,h_200',
          )}
          alt={'Our design composition'}
        />
        <Heading
          additionalClass={'workroom-couch__heading'}
          lineColor={'white'}
          size={'regular'}
        >
          Looking for exceptional designers?
        </Heading>
        <Link
          to={'/ux-ui-design-consulting-services/'}
          id={'workroom-banner-anchor'}
        >
          <Button
            id={'workroom-banner-btn'}
            ariaName={'Meet our team'}
            color={'white'}
          >
            Meet our team
          </Button>
        </Link>
      </div>
      <FullwidthImage
        additionalClass={'workroom-couch__image'}
        image={getCloudinaryUrl('img/WR_Photography_3.png', 'f_auto,q_auto')}
        alt={'Work table in coffee shop'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-couch__phone'}
        src={getCloudinaryUrl('img/WR_iP_6.png', 'f_auto,q_auto')}
        alt={'Workroom app'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-couch__phone'}
        src={getCloudinaryUrl('img/WR_iP_7.png', 'f_auto,q_auto')}
        alt={'Workroom app'}
        innerRef={secondPhone}
        loading={'lazy'}
      />
    </div>
  );
};

export default Couch;
