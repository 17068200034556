import { Container } from 'components';
import React from 'react';
import { PDWBanner } from '../PDWBanner';
import PropTypes from 'prop-types';

import './FreeTool.scss';

export const FreeTool = ({ imagePath }) => (
  <div className={'app-cost-free-tool'}>
    <Container>
      <p className={'app-cost-free-tool__tag'}>
        <b>Free tools</b>
      </p>
      <p className={'app-cost-free-tool__header'}>
        Start your successful product development
      </p>
      <p className={'app-cost-free-tool__description'}>
        Do you have an idea for a product but just don&apos;t know where to
        begin?
        <br />
        Get started with 10 tools that we use during product discovery
        workshops and learn how to turn your ideas into a&nbsp;business!
      </p>
      <PDWBanner imagePath={imagePath} />
    </Container>
  </div>
);

FreeTool.propTypes = {
  imagePath: PropTypes.string,
};
