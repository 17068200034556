import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SingleReview from 'views/singleReview';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';

const ReviewPage = ({ data }) => {
  const { markdownRemark: reviewData, allMarkdownRemark: articlesList } = data;
  const page = pages.article;
  const { navColors } = page;

  return (
    <Layout page={page} cloudinary>
      <NavBar color={navColors} />
      <SingleReview review={reviewData} articlesList={articlesList} />
    </Layout>
  );
};

ReviewPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }).isRequired,
};

export default ReviewPage;

export const pageQuery = graphql`
  query ReviewByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        settings {
          date
          metaDescription
          metaTitle
          slug
          canonical
          cover
          type
        }
        title
        mainContent {
          background
          opportunity
          solution
          results
          scheduling {
            rating
            description
          }
          cost {
            rating
            description
          }
          quality {
            rating
            description
          }
          nps {
            rating
            description
          }
          related {
            title
          }
        }
        intro {
          logo
          caption
          rating
          url
          category
          size
          timespan
          industry
          clientSize
          location
          review {
            testimonial
            author
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cover
            settings {
              slug
              type
              category
            }
            author
            box {
              content {
                title
                tags
                clientLogo
                coverImage
              }
              settings {
                tileColor
                textColor
                link
              }
              type
            }
          }
        }
      }
    }
  }
`;
