import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['product-discovery-workshop'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/product-discovery-workshop/`;

const PdwHandbookPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={
          'Product Discovery Workshop - the key to your product’s success'
        }
        cover={getCloudinaryUrl(
          'resources/product-discovery-workshop-cover.png',
          'f_auto,q_auto',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/product-discovery-workshop.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Product Discovery Workshop'}
        description={
          'Product discovery workshop is exactly what can help us make sure we’re building the right product. At the end of the day, what is the point of delivering the product if it’s not used by your users?'
        }
        items={[
          'All you need to define, explain, and advocate for product discovery within your organization',
          'Product discovery workshop flow and tools, all ready to use',
          'Benefit from the best practices we have learned during our nearly two decades of experience conducting Product Discovery Workshops',
          'Resources and links to expand your Product Discovery knowledge',
        ]}
        formHeading={'Get your free handbook now:'}
        url={url}
        button={'Send me the handbook'}
        btnColor={'violet'}
      />
    </Layout>
  );
};

export default PdwHandbookPage;
