import React from 'react';

import './EventFpmfAmsterdam.scss';

import {
  About,
  Banner,
  History,
  Questions,
  Speaker,
  Why,
  Form,
  Bonus,
} from './subcomponents';

const EventFpmfAmsterdam = () => (
  <div className={'eventfpmf-amsterdam-page'}>
    <Banner />
    <Why />
    <Speaker />
    <Form url={'https://go.boldare.com/l/688533/2022-11-10/2d65ft'} />
    <History />
    <Questions />
    <Bonus />
    <About />
  </div>
);

export default EventFpmfAmsterdam;
