import React from 'react';
import PropTypes from 'prop-types';
import { services } from './logic';
import './Iframe.scss';

const getAttributes = url => {
  const service =
    url && services.find(_service => url.includes(_service.name));

  return service ? service.attributes : null;
};

const Iframe = ({ width, height, url }) => {
  const attributes = getAttributes(url);

  return (
    <div className={'iframe'}>
      <iframe
        src={url}
        width={width}
        height={height}
        title={'Iframe'}
        {...attributes}
      />
    </div>
  );
};

Iframe.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  url: PropTypes.string.isRequired,
};

Iframe.defaultProps = {
  width: '',
  height: '',
};

export default Iframe;
