import React, { Component } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { Row, Col } from 'react-flexbox-grid';
import Glide from '@glidejs/glide';
import {
  Paragraph,
  Section,
  Heading,
  SectionHeader,
  IconSVG,
} from 'components';
import { classNames } from 'utils';
import { data } from './logic.js';
import './HowWeWork.scss';

class HowWeWork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.glide = new Glide('.how-we-work__slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      animationDuration: 300,
      gap: 0,
      dragThreshold: false,
      autoplay: 3000,
      classes: {
        activeNav: 'active',
      },
    });

    this.glide.on(['build.after', 'run.after'], () => {
      this.setState({ currentSlide: this.glide.index });
    });

    this.glide.mount();
  }

  renderItem = () =>
    data.map((item, index) => (
      <div
        key={`item_${item.title + index}`}
        className={'how-we-work__item glide__slide'}
      >
        <img
          src={getCloudinaryUrl(item.img, 'f_auto,q_auto')}
          alt={item.title}
          className={'image how-we-work__image'}
          loading={'lazy'}
        />
        <div className={'how-we-work__slide'}>
          <IconSVG name={item.icon} size={'large'} color={'black'} />
          <Heading type={3} size={'medium-plus-small'} color={'black'}>
            {item.title}
          </Heading>
          <Paragraph color={'black'} size={'medium'}>
            {item.description}
          </Paragraph>
        </div>
      </div>
    ));

  renderTitle = () =>
    data.map((item, index) => (
      <li
        key={`tile_${item.title + index}`}
        className={classNames('how-we-work__title', 'glide__bullet', {
          active: this.state.currentSlide === index,
        })}
        onClick={() => this.glide.go(`=${index}`)}
        role={'presentation'}
      >
        <Paragraph color={'red'} size={'medium'}>
          {item.title}
        </Paragraph>
      </li>
    ));

  render() {
    return (
      <Section color={'gray-lighter'}>
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionHeader
              size={'big'}
              color={'blacks'}
              headingType={2}
              lineColor={'red'}
            >
              Our process
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            <div className={'machine-learning__process-description'}>
              <Paragraph color={'black'} size={'medium'}>
                We will be with you throughout the Full Cycle Product
                Development process:
              </Paragraph>
            </div>
          </Col>
        </Row>
        <div className={'how-we-work__slider glide'}>
          <ul className={'how-we-work__list-nav'}>
            {this.glide && this.renderTitle()}
          </ul>
          <div data-glide-el={'track'} className={'glide__track'}>
            <div className={'glide__slides'}>{this.renderItem()}</div>
          </div>
          <div
            className={'related-articles__nav glide__arrows'}
            data-glide-el={'controls'}
          >
            <button
              className={
                'related-articles__arrow glide__arrow glide__arrow--left'
              }
              id={'ml-prev'}
              data-glide-dir={'<'}
              aria-label={'Slider control button'}
            >
              <IconSVG name={'arrow-left'} color={this.props.bulletColor} />
            </button>
            <button
              className={
                'related-articles__arrow glide__arrow glide__arrow--right'
              }
              id={'ml-next'}
              data-glide-dir={'>'}
              aria-label={'Slider control button'}
            >
              <IconSVG name={'arrow-right'} color={this.props.bulletColor} />
            </button>
          </div>
        </div>
      </Section>
    );
  }
}

export default HowWeWork;
