import React from 'react';
import { Helmet } from 'react-helmet';
import { NavBar } from 'components';
import RelacjeInwestorskie from 'views/relacjeInwestorskie';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['investor-relations'];
const { navColors } = page;
const canonical = `${config.siteUrl}/investor-relations/`;

const RelacjeInwestorskiePage = () => (
  <Layout {...{ page, canonical }}>
    <div className={'single-page relacje-inwestorskie'}>
      <Helmet title={'Relacje inwestorskie'}>
        <link rel={'canonical'} href={canonical} />
      </Helmet>
      <NavBar color={navColors} />
      <RelacjeInwestorskie />
    </div>
  </Layout>
);

export default RelacjeInwestorskiePage;
