import React, { useState } from 'react';
import {
  AnimatePresence,
  motion,
  useViewportScroll,
  useTransform,
} from 'framer-motion';

// Assets
import { ReactComponent as Man } from './man_icon.svg';

const wrapperVariants = {
  initial: {
    x: 200,
  },
  enter: {
    x: 0,
    transition: { duration: 0.8, ease: 'circOut' },
  },
  exit: {
    x: -200,
    transition: { duration: 0.8, ease: 'circIn' },
  },
};

const elementVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 0.5, ease: 'linear' } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: 'linear' } },
};

const Testimonial = ({
  quote,
  author,
  letterStyle,
  companyName,
  animationEnd,
  animationShow,
  animationStart,
  authorPosition,
  companyCountry,
  backgroundColor,
}) => {
  const { scrollY } = useViewportScroll();
  const clipPath = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['circle(18% at 100% 50%)', 'circle(100% at 50% 50%)'],
  );
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(
    animationShow === 0,
  );

  scrollY.onChange(progress => {
    if (isTextVisible && progress < animationEnd) {
      setIsTextVisible(false);
    } else if (!isTextVisible && progress >= animationEnd) {
      setIsTextVisible(true);
    }
  });

  scrollY.onChange(progress => {
    if (isSectionVisible && progress < animationShow) {
      setIsSectionVisible(false);
    } else if (!isSectionVisible && progress >= animationShow) {
      setIsSectionVisible(true);
    }
  });

  return (
    <AnimatePresence>
      {isSectionVisible && (
        <motion.div
          exit={{ x: '18%' }}
          animate={{ x: '0%' }}
          initial={{ x: '18%' }}
          className={'dsgn__testimonial'}
          style={{ backgroundColor, clipPath }}
          transition={{ duration: 0.3, ease: 'linear' }}
        >
          <AnimatePresence>
            {isTextVisible && (
              <motion.div
                exit={'exit'}
                animate={'enter'}
                initial={'initial'}
                variants={wrapperVariants}
                className={'dsgn__testimonial-wrapper'}
              >
                <motion.div
                  className={'dsgn__testimonial-quote'}
                  variants={elementVariants}
                >
                  <p className={letterStyle}>{quote}</p>
                  <div className={'dsgn__testimonial-quote-author'}>
                    <Man />
                    <div>
                      <span className={'dsgn__testimonial-quote-author-name'}>
                        {author}
                      </span>
                      <span
                        className={'dsgn__testimonial-quote-author-position'}
                      >
                        {authorPosition}
                      </span>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className={'dsgn__testimonial-company'}
                  variants={elementVariants}
                >
                  <span>{companyName}</span>
                  <span>|</span>
                  <span>{companyCountry}</span>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Testimonial;
