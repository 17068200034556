import React from 'react';

import { getCloudinaryUrl } from 'config/helpers';
import { Image } from 'components';

import './Banner.scss';

const Banner = () => (
  <div className={'app-cost-thank-you-banner w-container'}>
    <div className={'app-cost-thank-you-banner__text-content'}>
      <h1 className={'app-cost-thank-you-banner__header'}>
        <span className={'app-cost-thank-you-banner__highlight'}>
          Thank you{' '}
        </span>{' '}
        for using our calculator!
      </h1>
      <p className={'app-cost-thank-you-banner__description'}>
        We have sent you a confirmation email. However, you don&apos;t need to
        do anything at this moment. Just sit back and relax, and our team will
        contact you within the next 24 business hours to discuss and better
        understand your needs.
      </p>
    </div>
    <div className={'app-cost-thank-you-banner__img'}>
      <Image
        src={getCloudinaryUrl(
          '/app-costs-calculator/challenges-rotated.png',
          'f_auto,q_auto',
        )}
        className={'image box-image-content__image'}
        alt={'Challenges'}
      />
    </div>
  </div>
);

export default Banner;
