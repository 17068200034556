export const data = [
  {
    number: 15000,
    text: 'addresses viewed by Google API',
  },
  {
    number: 20000,
    text: 'migrations of models’ profiles',
  },
  {
    number: 100,
    suffix: ' GB',
    text: 'migrations of multimedia',
  },
  {
    number: 700,
    text: 'times in the test environment',
  },
];
