import React from 'react';
import PropTypes from 'prop-types';

import { AwwwardsLogo } from 'views/singleArticle/AwwwardsLogo';
import { Button, Icon } from 'components';
import { ICON_TYPES } from 'config';
import config from '../../../../../meta/config';

import './AwwwardsBar.scss';

export const AwwwardsBar = ({ variant }) => {
  const isVariantA = variant === 'awwwards-bar-a';

  return (
    <div
      className={`awwwards-bar w-container ${
        isVariantA ? 'awwwards-bar--violet' : 'awwwards-bar--yellow'
      }`}
    >
      <div className={'awwwards-bar___logo-wrapper'}>
        <AwwwardsLogo fill={isVariantA ? 'white' : '#242424'} />
      </div>
      <div className={'awwwards-bar__text'}>
        We have won{' '}
        <span className={'awwwards-bar__marker-highlight marker-black'}>
          16 Honorable Mentions
        </span>
      </div>
      <div className={'awwwards-bar___button-wrapper'}>
        <a
          href={config.awwwards}
          target={'_blank'}
          rel={'noreferrer'}
          id={`ga-${variant}-${isVariantA ? 'violet' : 'yellow'}`}
          className={`awwwwards-bar__button-link`}
        >
          <Button color={'white'} size={'small'}>
            <div className={'awwwards-bar__button'}>
              <div className={'awwwards-bar__button-text'}>
                See our designs
              </div>
              <div className={'awwwards-bar__button-arrow'}>
                <Icon
                  icon={ICON_TYPES.RIGHT_ARROW}
                  size={'small'}
                  color={'black'}
                />
              </div>
            </div>
          </Button>
        </a>
      </div>
    </div>
  );
};

AwwwardsBar.defaultProps = {
  variant: 'awwwards-bar-a',
};

AwwwardsBar.propTypes = {
  variant: PropTypes.string,
};
