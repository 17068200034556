import React, { Component } from 'react';
import { Heading, Section, Paragraph, IconSVG } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import data from './logic';
import './DesignProcess.scss';

class DesignProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterBoxShown: false,
    };
  }

  toggleFilterBoxView = () =>
    this.setState(prevState => ({
      isFilterBoxShown: !prevState.isFilterBoxShown,
    }));

  render() {
    const { isFilterBoxShown } = this.state;

    return (
      <Section additionalClass={'awwwards-lp-process'} color={'mine-shaft'}>
        <div className={'awwwards-lp-process__top'}>
          <Heading
            color={'white'}
            type={'2'}
            size={'large'}
            additionalClass={'anim-fade-in-left'}
          >
            Discover our design-centered process
          </Heading>
          <button
            onClick={this.toggleFilterBoxView}
            className={classNames('awwwards-lp-process__button', {
              open: isFilterBoxShown,
              close: !isFilterBoxShown,
            })}
          >
            <div>
              <IconSVG
                name={'chevron-down'}
                size={'big'}
                color={'mine-shaft'}
              />
            </div>
            <span>Click to expand section</span>
          </button>
        </div>
        {isFilterBoxShown && (
          <ol className={'awwwards-lp-process__steps'}>
            {data.map(item => (
              <li className={'awwwards-lp-process__step'} key={item.label}>
                <Heading
                  additionalClass={'awwwards-lp-process__step-heading'}
                  size={'medium-plus'}
                  type={3}
                >
                  {item.label}
                </Heading>
                {item.paragraphs.map(paragraph => (
                  <Paragraph
                    additionalClass={
                      'awwwards-lp__paragraph awwwards-lp-process__step-paragraph'
                    }
                    color={'white'}
                  >
                    {paragraph}
                  </Paragraph>
                ))}
              </li>
            ))}
            <li className={'awwwards-lp-process__icon'}>
              <img
                src={getCloudinaryUrl('img/Rakieta_All.svg', 'h_100,q_100')}
                alt={'Rocket icon'}
                className={'image'}
                loading={'lazy'}
              />
            </li>
          </ol>
        )}
      </Section>
    );
  }
}

export default DesignProcess;
