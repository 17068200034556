import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { JobOfferContent } from './subcomponents';
import './JobOffer.scss';

export class JobOfferTemplate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { offer } = this.props;
    const { title, settings } = offer.frontmatter;
    const slug = settings.slug || offer.fields.slug;
    return (
      <div className={'single-job-offer'}>
        <SEO
          title={settings.metaTitle || title}
          description={settings.metaDescription}
          cover={getCloudinaryUrl(settings.cover, 'f_auto,q_auto,w_400')}
          slug={slug}
          section={'career'}
          canonical={settings.canonical}
          cloudinary
        />
        <JobOfferContent data={offer.frontmatter} />
      </div>
    );
  }
}

JobOfferTemplate.propTypes = {
  offer: PropTypes.shape().isRequired,
};

export default JobOfferTemplate;
