import React, { Component } from 'react';
import { Heading, Section } from 'components';
import { OrbitCircleLines } from 'components/DS/OrbitCircleLines';
import Slider from 'react-slick';
import Step from './Step';
import './TalentPoolProcess.scss';

const steps = [
  {
    number: '01',
    step: 'Apply',
    details:
      'It’s going to be verified by one of our recruiters, who will take care of you during the whole process and answer all your questions.',
  },
  {
    number: '02',
    step: "Let's talk!",
    details:
      'We just wanna catch you for a short video call to ask basic questions and learn more about your experience.',
  },
  {
    number: '03',
    step: "Let's meet!",
    details:
      'If our expectations are aligned, you’ll be invited to an online interview. It’s the perfect time to get to know each other better and check if we match each other. If you are successful, we will invite you to join our Talent Pool.',
  },
  {
    number: '04',
    step: 'You’re in!',
    details:
      'You’ve become the member of our talent pool. When the open position appears, you will be the first person to whom we make an offer, and we can start working together!',
  },
];

class TalentPoolProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'talent-pool-process__mobile-slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: false,
        dots: true,
        autoplay: true,
        speed: 200,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 768,
    });
  };

  renderSlider(data) {
    const { sliderSettings } = this.state;
    const slides = [];

    data.map(item =>
      slides.push(
        <Step
          key={item.number}
          number={item.number}
          step={item.step}
          details={item.details}
        />,
      ),
    );

    return <Slider {...sliderSettings}>{slides}</Slider>;
  }

  renderSteps = data =>
    data.map(item => (
      <Step
        key={item.number}
        number={item.number}
        step={item.step}
        details={item.details}
      />
    ));

  render() {
    const { isMobile } = this.state;

    return (
      <div className={'talent-pool-process'}>
        <Section
          color={'soft-cloud'}
          additionalClass={'talent-pool-process__container'}
        >
          <div className={'talent-pool-process__container'}>
            <div className={'talent-pool-process__banner'}>
              <Heading
                additionalClass={'text--bold talent-pool-process__heading'}
                color={'black'}
                type={3}
                size={'big'}
                lineColor={'violet'}
              >
                Discover
                <span className={'text--violet'}> Talent Pool </span>
                process
              </Heading>
              <div className={'talent-pool-process__banner-content'}>
                <div className={'talent-pool-process__banner-steps'}>
                  {isMobile ? (
                    <div className={'talent-pool-process__carousel'}>
                      {this.renderSlider(steps, 'steps')}
                    </div>
                  ) : (
                    <>{this.renderSteps(steps)}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Section>
        <div className={'talent-pool-process__planet'}>
          <OrbitCircleLines theme={'dark'} circleColor={'red'} />
        </div>
      </div>
    );
  }
}

export default TalentPoolProcess;
