import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Heading from 'components/Heading';

const clientsSettings = {
  dots: false,
  speed: 4000,
  infinite: true,
  autoplay: true,
  slidesToShow: 4,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToScroll: 1,
  variableWidth: true,
};

const ClientsSlider = ({ logos, colorType, withHeader }) => {
  const imgClass = classNames({
    monochromatic: colorType === 'monochromatic',
    colorful: colorType === 'colorful',
  });
  return (
    <div className={'dsgn__testimonials-carousel-container'}>
      {withHeader ? <Heading size={'large'}>Some of the companies that trusted us</Heading> : null}
      <div className={'dsgn__testimonials-carousel'}>
        <Slider {...clientsSettings}>
          {Object.entries(logos).map(([key, value]) => (
            <img key={key} src={value} alt={key} className={imgClass} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

ClientsSlider.propTypes = {
  logos: PropTypes.object.isRequired,
  colorType: PropTypes.string,
  withHeader: PropTypes.bool,
};

ClientsSlider.defaultProps = {
  colorType: 'monochromatic',
  withHeader: false,
};

export default ClientsSlider;
