import { Section } from 'components';
import React from 'react';
import Stamp from 'assets/images/ses/badge-png.png';

import './Hero.scss';

export default function Hero() {
  return (
    <div className={'ses-hero'}>
      <Section>
        <h1 className={'heading text--white heading--large-plus'}>
          Our
          <span
            className={
              'marker-highlight marker-highlight--yellow ses-hero__marker'
            }
          >
            Service Standards
          </span>
        </h1>
        <div className={'ses-hero__description'}>
          <p className={'ses-hero__description--bold'}>
            Our Service Standards is a framework designed to set a unified bar
            of excellence for every product we co-create.
          </p>
          <p>
            Thanks to these standards, we maintain a unified level of service,
            quality, and the client experience across all types and
            complexities of projects, making us a valuable partner in bringing
            your vision to life.
          </p>
        </div>
      </Section>
      <Section>
        <img
          src={Stamp}
          alt={`Service Standards Stamp`}
          className={'ses-hero__badge'}
          loading={'lazy'}
        />
      </Section>
    </div>
  );
}
