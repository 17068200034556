import React, { useEffect } from 'react';
import { enableAnimations } from 'config/helpers';

import Form from './subcomponents/Form';
import Hero from './subcomponents/Hero';
import Companies from './subcomponents/Companies';
import Benefits from './subcomponents/Benefits';
import Numbers from './subcomponents/Numbers';
import BottomForm from './subcomponents/BottomForm';
import ServicesAndTech from './subcomponents/ServicesAndTech';
import WhyWorthRecommendation from './subcomponents/WhyWorthRecommendation';
import Slider from './subcomponents/Slider';

import './BringAQualifiedLead.scss';

function BringQualifiedLead() {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'single-page recommend-client'}>
      <Hero />
      <Form />
      <Companies />
      <Benefits />
      <Numbers />
      <WhyWorthRecommendation />
      <ServicesAndTech />
      <Slider />
      <BottomForm />
    </div>
  );
}

export default BringQualifiedLead;
