import React from 'react';
import Layout from 'components/layout';
import { NavBar, FooterMini } from 'components';
import { pages } from 'config/pages';
import EventRegistrationPage from 'views/eventRegistrationPage';
import config from '../../meta/config';
import { events } from '../data/eventsData';

const page = pages['scale-your-AI-solution-meetup'];
const canonical = `${config.siteUrl}/scale-your-AI-solution-meetup/`;

const WorkshopConfirmationPage = () => {
  const { navColors, button, siteCover } = page;
  const formHandler = page.formHandlers.default;
  const eventData = events['scale-your-AI-solution-meetup'];

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <EventRegistrationPage
        button={button}
        url={formHandler}
        eventData={eventData}
        cover={siteCover}
        formHeading={'Registration is closed<br />Get the event recording'}
      />
      <FooterMini />
    </Layout>
  );
};

export default WorkshopConfirmationPage;
