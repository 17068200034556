import React from 'react';
import PropTypes from 'prop-types';
import { SectionWide } from 'components/DS';
import { IconSVG } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './BottomBar.scss';

const Logo = ({ logo }) => {
  return (
    <img
      src={getCloudinaryUrl(logo.src, 'f_auto,q_auto')}
      alt={logo.alt}
      loading={'lazy'}
    />
  );
};

Logo.propTypes = {
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

const BottomBar = ({ customSectionLogos }) => {
  return (
    <>
      <SectionWide>
        <div className={'bottom-bar__logos'}>
          {customSectionLogos?.map((logo, index) => {
            return (
              <>
                {index !== 0 && (
                  <IconSVG name={'close'} color={'black'} size={'large'} />
                )}
                {logo.url ? (
                  <a
                    href={logo.url}
                    target={'_blank'}
                    rel={'noreferrer'}
                    aria-label={logo.alt}
                  >
                    <Logo logo={logo} />
                  </a>
                ) : (
                  <Logo logo={logo} />
                )}
              </>
            );
          })}
        </div>
      </SectionWide>
      <SectionWide
        color={'yellow'}
        backgroundImage={getCloudinaryUrl(
          'img/amsterdam_bg.png',
          'f_auto,q_auto',
        )}
      >
        <div className={'bottom'}>
          <img
            src={getCloudinaryUrl('img/spaceman.png', 'f_auto,q_auto')}
            alt={'Spaceman'}
            loading={'lazy'}
            className={'bottom-image'}
          />
        </div>
      </SectionWide>
    </>
  );
};

BottomBar.propTypes = {
  customSectionLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BottomBar;
