export const sliderData = [
  {
    background: 'img/case-study-ionoview-web-app.jpg',
    content: 'IonoView, United Arab Emirates',
    description:
      'Scaling a perfectly working app is like open-heart surgery. This is why we are especially proud that we managed to help Matic with the development of their SaaS platform, IonoView',
    color: 'yellow',
    link: '/work/case-study-ionoview/',
  },
  {
    background: 'img/why-design-matters/01_Virgin/main.jpg',
    content: 'Virgin Radio, Oman',
    description:
      'How long is it supposed to take to develop and release a fully working web and mobile app to customers? With our help, the MVP of Virgin Radio Oman hit the market within just 4 weeks.',
    color: 'yellow',
    link: '/work/case-study-virgin-radio-oman/',
  },
  {
    background:
      'img/takamol-tojjar-case-study-web-and-mobile-app-views-designed-by-boldare.jpg',
    content: 'Takamol, Kingdom of Saudi Arabia',
    description:
      'Build a functional e-commerce platform in 6 weeks? Definitely challenging but not impossible for an agile and experienced team like Boldare. Learn how we collaborated with a government-owned company to release this custom web marketplace.',
    color: 'yellow',
    link: '/work/case-study-tojjar/',
  },
];
