import React from 'react';
import { IconSVG } from 'components';
import PropTypes from 'prop-types';

import './Speaker.scss';
import { Link } from 'gatsby';

const Speaker = ({ image, fullName, position, company, linkedIn }) => {
  return (
    <div className={'speaker-container'}>
      <div className={'speaker-content'}>
        <div className={'speaker-image-wrapper'}>
          <img
            src={image}
            className={'speaker-image'}
            loading={'lazy'}
            alt={`Speaker ${fullName}`}
          />
        </div>
        <div className={'speaker-info'}>
          <p className={'speaker-header'}>Speaker</p>
          <p className={'speaker-full-name'}>{fullName}</p>
          <p className={'speaker-position-and-company'}>
            {position} | {company}
          </p>
          {linkedIn && (
            <Link to={linkedIn} target={'_blank'}>
              <IconSVG
                additionalClass={'speaker-social-icon'}
                name={'linkedin'}
                size={'small'}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

Speaker.propTypes = {
  image: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  linkedIn: PropTypes.string,
};

Speaker.defaultProps = {
  linkedIn: null,
};

export default Speaker;
