import React from 'react';
import { Heading, Paragraph, Link, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './BlogBoldareBoards.scss';

const BlogBoldareBoards = ({ id }) => (
  <div className={'box box-article box-bobo'} id={id}>
    <Link to={'https://boards.boldare.com/'}>
      <div className={'blog-boldare-boards'}>
        <div className={'boldare-boards-content'}>
          <div className={'boldare-boards-details'}>
            <span>Free online tool</span>
            <Heading type={3} size={'medium-plus'} color={'white'}>
              Rock your meetings with Boldare Boards!
            </Heading>
            <Paragraph size={'medium'} color={'white'}>
              A perfect online app for
              <br />
              your remote teams.
            </Paragraph>
            <img
              className={'image boldare-boards-img--mobile'}
              src={getCloudinaryUrl(
                '/gatsby-cloudinary/bobo-app-mobile.png',
                'f_auto,q_auto',
              )}
              alt={'Rock your meetings with Boldare Boards!'}
              loading={'lazy'}
            />
            <Button color={'yellow'}>Use Boldare Boards now &#8594;</Button>
          </div>
          <img
            className={'image boldare-boards-img'}
            src={getCloudinaryUrl(
              '/gatsby-cloudinary/bobo-computer.png',
              'f_auto,q_auto,w_506',
            )}
            alt={'Rock your meetings with Boldare Boards!'}
            loading={'lazy'}
          />
        </div>
      </div>
    </Link>
  </div>
);

export default BlogBoldareBoards;
