import React from 'react';
import PropTypes from 'prop-types';
import DiscussionEventContent from './subcomponents/DiscussionEventContent/DiscussionEventContent';
import DiscussionEventForm from './subcomponents/DiscussionEventForm/DiscussionEventForm';
import DiscussionEventInfo from './subcomponents/DiscussionEventInfo/DiscussionEventInfo';
import DiscussionEventMobileLogos from './subcomponents/DiscussionEventMobileLogos/DiscussionEventMobileLogos';

import './DiscussionEvent.scss';

const DiscussionEvent = ({ formUrl }) => (
  <div className={'discussion-event-container'}>
    <div className={'discussion-event-left-section'}>
      <DiscussionEventInfo />
      <DiscussionEventContent />
    </div>
    <div className={'discussion-event-right-section'}>
      <DiscussionEventForm url={formUrl} />
      <DiscussionEventMobileLogos />
    </div>
  </div>
);

DiscussionEvent.propTypes = {
  formUrl: PropTypes.string.isRequired,
};

export default DiscussionEvent;
