import { useCaseStudies } from 'views/work/subcomponents/CaseStudies/hooks/useCaseStudies';
import { CASE_STUDIES_ENTRIES } from 'views/work/subcomponents/CaseStudies/data';
import BOX_TYPE from 'config/constants/boxTypes';
import React, { useCallback, useEffect } from 'react';
import { parseFilterFromHash } from 'views/work/subcomponents/CaseStudies/utils';
import { useSplitTest } from 'views/work/subcomponents/CaseStudies/hooks/useSplitTest';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Button, Link, LoadMorePagination, ReviewBox } from 'components';
import { BOX_TYPES } from 'config';
import { Article, Brief, CaseStudy } from 'components/WorkBoxes';
import { getArticleSlug } from 'config/helpers';
import './CaseStudies.scss';

const WorkLoader = ({ loading, isVariantB, children }) => {
  return loading ? (
    <div
      className={classNames(
        'digital-transformation-journey-work-for-partners-case-studies__loader',
        {
          'digital-transformation-journey-work-for-partners-case-studies__loader--sticky': isVariantB,
        },
      )}
    >
      <span
        className={
          'digital-transformation-journey-work-for-partners-case-studies__loader-circle'
        }
      />
    </div>
  ) : (
    children
  );
};

export const CaseStudies = ({
  allArticles,
  authors,
  isVariantB,
  isFakeLoading,
  onActiveFilterChange,
}) => {
  const { data, updateFilters } = useCaseStudies({
    data: CASE_STUDIES_ENTRIES,
    articles: allArticles,
    onFilterChange: onActiveFilterChange,
    boxPattern: [
      // represents how different box types will be arranged on the list
      // (ordering is handled internally based on priorities)
      // 3 x case study
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      // then 3 x case study
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      // then case study, brief, review
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.BRIEF,
      BOX_TYPE.REVIEW,
      // then brief, blog, case study
      BOX_TYPE.BRIEF,
      BOX_TYPE.BLOG,
      BOX_TYPE.CASE_STUDY,
      // and then it repeats
    ],
  });

  const handleFilterSelected = useCallback(
    (e, label, filter, navigatePage) => {
      e.preventDefault();
      window.history.replaceState({}, '', label === null ? '' : e.target.href);
      navigatePage(1);
      updateFilters(label, filter);
    },
  );

  useEffect(() => {
    updateFilters('Region', 'Middle East & Africa');
  }, []);

  useEffect(() => {
    // load filter from path on first render
    const parsedFilter = parseFilterFromHash(window.location.hash);
    if (parsedFilter) updateFilters(parsedFilter.label, parsedFilter.filter);
  }, []);

  const triggerRef = useSplitTest({
    event: `${isVariantB ? 'case-studies-b' : 'case-studies-a'}-display`,
    key: 'wasCaseStudiesSeen',
  });

  return (
    <LoadMorePagination
      index={'case-studies'}
      loadCount={9}
      data={data.slice(0, 6)}
    >
      {({ items, loadMore, navigatePage }) => (
        <div
          ref={triggerRef}
          className={classNames(
            'digital-transformation-journey-work-for-partners-case-studies__case-studies',
            {
              'digital-transformation-journey-work-for-partners-case-studies__case-studies--columns': isVariantB,
            },
          )}
        >
          <WorkLoader loading={isFakeLoading} isVariantB={isVariantB}>
            <div
              id={
                'digital-transformation-journey-work-for-partners-case-studies__content'
              }
              className={classNames(
                'digital-transformation-journey-work-for-partners-case-studies__container',
                {
                  'digital-transformation-journey-work-for-partners-case-studies__container--sticky': isVariantB,
                },
              )}
            >
              <div
                id={'case-studies'}
                className={classNames(
                  'digital-transformation-journey-work-for-partners-case-studies__grid-boxes',
                  {
                    'digital-transformation-journey-work-for-partners-case-studies__grid-boxes--sticky': isVariantB,
                  },
                )}
              >
                {items.length > 0 &&
                  items.map(post => (
                    <>
                      {post.type === BOX_TYPES.BLOG && (
                        <Article
                          key={`blog-post-${post.id}`}
                          type={post.type}
                          post={post.articleData}
                          link={getArticleSlug(post.articleData)}
                          authors={authors}
                          isVariantB={isVariantB}
                        />
                      )}
                      {post.type === BOX_TYPES.CASE_STUDY &&
                        post.content.country !== 'Israel' && (
                          <CaseStudy
                            key={`case-study-${post.id}`}
                            post={post}
                            isVariantB={isVariantB}
                            onFilterSelect={handleFilterSelected}
                            navigatePage={navigatePage}
                          />
                        )}
                      {post.type === BOX_TYPES.REVIEW && (
                        <ReviewBox data={post} key={`review-${post.id}`} />
                      )}
                      {post.type === BOX_TYPES.BRIEF && (
                        <Brief
                          key={`brief-${post.id}`}
                          post={post}
                          isVariantB={isVariantB}
                          onFilterSelect={handleFilterSelected}
                          navigatePage={navigatePage}
                        />
                      )}
                    </>
                  ))}
              </div>
            </div>
            <div
              className={
                'digital-transformation-journey-work-for-partners-case-studies__button-wrapper'
              }
            >
              {loadMore && (
                <div
                  className={
                    'digital-transformation-journey-work-for-partners-case-studies__button'
                  }
                >
                  <Button
                    tag={Link}
                    to={loadMore.path}
                    color={'yellow'}
                    onClick={loadMore.action}
                    ariaName={'Load more button'}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          </WorkLoader>
        </div>
      )}
    </LoadMorePagination>
  );
};

CaseStudies.defaultProps = {
  allArticles: [],
  authors: [],
  isVariantB: false,
  activeFilter: null,
  isFakeLoading: false,
  isDesktop: false,
  onActiveFilterChange: () => {},
};

CaseStudies.propTypes = {
  allArticles: PropTypes.array,
  authors: PropTypes.array,
  isVariantB: PropTypes.bool,
  activeFilter: PropTypes.string,
  isFakeLoading: PropTypes.bool,
  isDesktop: PropTypes.bool,
  onActiveFilterChange: PropTypes.func,
};
