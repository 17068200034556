import { Button, DottedList, Heading, Image, Section } from 'components';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import './ProjectCostCalculator.scss';
import { navigate } from 'gatsby';

export const ProjectCostCalculator = () => {
  return (
    <Section
      color={'gray-light'}
      additionalClass={`
        digital-transformation-journey-project-cost-calculator__section
      `}
    >
      <div
        className={
          'digital-transformation-journey-project-cost-calculator__container'
        }
      >
        <Row
          bottom={'xs'}
          between={'xs'}
          className={`
            digital-transformation-journey-project-cost-calculator__header-row
           `}
        >
          <Col
            xs={12}
            xl={6}
            className={`digital-transformation-journey-project-cost-calculator__header-col`}
          >
            <div>
              <Heading
                type={4}
                size={'large'}
                color={'black'}
                additionalClass={`digital-transformation-journey-project-cost-calculator__header-bold`}
              >
                Project cost calculator
              </Heading>
            </div>
            <DottedList
              heading={
                'Answer five short questions and get a free estimate right away:'
              }
              itemsList={[
                'A rough estimation of your product',
                'The number of specialists on a team',
                'How long it will take to launch the product',
              ]}
            />
            <div>
              <Button
                id={'app-cost-button'}
                color={'yellow'}
                onClick={() => navigate('/resources/app-costs-calculator')}
                additionalClass={`
                  digital-transformation-journey-project-cost-calculator__btn
                `}
              >
                Get an estimate
              </Button>
            </div>
          </Col>
          <Col xs={12} xl={6}>
            <Row bottom={'xs'} end={'xl'} center={'xs'}>
              <Col lg={8} xl={11}>
                <Image
                  src={getCloudinaryUrl(
                    '/digital-transformation-journey/jakafazawariacie.png',
                    'f_auto,q_auto,w_600',
                  )}
                  className={'image box-image-content__image'}
                  alt={'Phases graph'}
                  additionalClass={`digital-transformation-journey-project-cost-calculator__img`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Section>
  );
};
