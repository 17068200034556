import tuiLogo from 'assets/images/design-that-tells-stories/tui_logo.svg';
import corelLogo from 'assets/images/design-that-tells-stories/corel_logo.svg';
import boschLogo from 'assets/images/design-that-tells-stories/bosch_logo.svg';
import prismaLogo from 'assets/images/design-that-tells-stories/prisma_logo.svg';
import sonnenLogo from 'assets/images/design-that-tells-stories/sonnen_logo.svg';
import blablacarLogo from 'assets/images/design-that-tells-stories/blablacar_logo.svg';
import decathlonLogo from 'assets/images/design-that-tells-stories/decathlon_logo.svg';
import maxioLogo from 'assets/images/design-that-tells-stories/maxio_logo.png';
import planetEscape from 'assets/images/design-that-tells-stories/planet_escape_logo.png';
import humanitasLogo from 'assets/images/design-that-tells-stories/humanitas-logo.svg';
import dtiqLogo from '../clients/logos/dtiq-logo.svg';

const softwareDevelopmentClientLogo = {
  tuiLogo,
  dtiqLogo,
  maxioLogo,
  blablacarLogo,
  boschLogo,
  prismaLogo,
  sonnenLogo,
  corelLogo,
  decathlonLogo,
  planetEscape,
  humanitasLogo,
};

export default softwareDevelopmentClientLogo;
