export const data = [
  {
    title: 'Prototyping team',
    title_short: 'Prototyping team',
    description:
      'The prototyping team will get to know your business goals and help you conceptualize the product by designing and developing low-fidelity or high-fidelity prototypes. They will test the prototypes with users to check basic assumptions about the product and business which will help at the MVP stage. They will advise you on the best course of further product development.',
    id: 'prototyping-team',
  },
  {
    title: 'Minimum Viable Product (MVP) Team',
    title_short: 'MVP Team',
    description:
      'Getting the product to market as quickly as possible to gather feedback from users, helping to improve the product or pivot.',
    id: 'mvp-team',
  },
  {
    title: 'Product-Market Fit Team',
    title_short: 'Product-Market Fit Team',
    description:
      'The Product-Market Fit team plans and executes product tests, analyzes the results and draws conclusions, and applies them to product iterations or pivots. The team’s designers and developers will build new product features and test them with users. They will help you to grow an in-house culture of testing and making data-driven product decisions.',
    id: 'product-market',
  },
  {
    title: 'Scale & Maturity Team',
    title_short: 'Scale & Maturity Team',
    description:
      'The Scale & Maturity development team will engineer the product for scalability and stability. Additionally, they will advise you on how to set up processes in your company that will allow you to grow your product sustainably in the future, avoiding pitfalls such as accumulating technical debt.',
    id: 'scale-team',
  },
];
