import React from 'react';
import { Section, Button, ResourceList, Paragraph, Heading } from 'components';
import './Recommended.scss';
import { getCloudinaryUrl, getCloudinarySrcSet } from 'config/helpers';
import data from './logic';

const handleClick = item => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: item,
    });
  }
};

const Recommended = () => {
  return (
    <Section
      id={'resources-hub-hello'}
      additionalClass={'resources-hub__recommended'}
    >
      <Heading
        type={2}
        size={'large'}
        color={'mine-shaft'}
        additionalClass={'resources-hub__banner-popular'}
      >
        For starters
      </Heading>
      <img
        src={getCloudinaryUrl(
          'resources/mask-group_2x.png',
          'f_auto,q_auto,h_248',
        )}
        alt={''}
        className={'resources-hub__banner-img'}
        loading={'lazy'}
      />
      <div className={'recommended__container'}>
        <div className={'recommended__image'}>
          <a
            className={'tiles__article-link'}
            href={data.link}
            title={data.title}
            rel={'dofollow'}
            onClick={() => handleClick('resources-hub__recommended')}
          >
            <img
              src={getCloudinaryUrl(data.image, 'f_auto,q_auto,h_500')}
              srcSert={getCloudinarySrcSet(data.image, 'f_auto,q_auto,h_500')}
              alt={data.title}
              className={'image recommended__image-img'}
              loading={'lazy'}
            />
          </a>
        </div>
        <div className={'recommended__content text--white'}>
          <Paragraph
            size={'medium-plus'}
            color={'white'}
            additionalClass={'text--bold'}
          >
            {data.title}
          </Paragraph>
          <ResourceList items={data.features} />
          <a
            className={'recommended__content-link'}
            href={data.link}
            title={data.title}
            rel={'nofollow'}
            onClick={() => handleClick('resources-hub__recommended')}
          >
            <Button color={'white'} hover>
              Download for free
            </Button>
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Recommended;
