import React from 'react';
import cn from 'classnames';
import { Col } from 'react-flexbox-grid';
import { Heading, Paragraph, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../../../meta/config';
import './SinglePostTileTopic.scss';

const SinglePostTileTopic = () => {
  const { mainBanner } = config.blog;

  const bannerClass = cn('editor-intro__banner', {
    'text-to-center': mainBanner.textAlign === 'center',
    'text-to-right': mainBanner.textAlign === 'right',
  });

  return (
    <Col md={12} lg={8} className={bannerClass}>
      <Link to={mainBanner.url} id={mainBanner.id} arrow>
        <div className={'editor-intro__banner-intro'}>
          <span className={'editor-intro__banner-badge'}>
            Topic of the month
          </span>
          <Heading
            color={mainBanner.textColor}
            size={'medium'}
            type={2}
            additionalClass={'text--bold editor-intro__background-headline'}
            lineColor={'red'}
          >
            <span
              className={'text--background'}
              style={{ background: mainBanner.textBgColor }}
            >
              {mainBanner.title}
            </span>
          </Heading>
          <Paragraph color={mainBanner.textColor}>
            {mainBanner.subtitle}
          </Paragraph>
          <span
            className={`button button--primary button--${mainBanner.buttonColor}`}
          >
            {mainBanner.buttonText}
          </span>
        </div>
        <div className={'editor-intro__banner-image-background'}>
          <img
            src={getCloudinaryUrl(`${mainBanner.image}`, 'f_auto,q_auto')}
            alt={`${mainBanner.title} ${mainBanner.subtitle} article`}
            className={'image editor-intro__banner-back'}
            loading={'lazy'}
          />
        </div>
      </Link>
    </Col>
  );
};

export default SinglePostTileTopic;
