import React from 'react';
import PropTypes from 'prop-types';
import Question from '../Question/index';
import QuestionCount from '../QuestionCount/index';
import AnswerOption from '../AnswerOption/index';

const Quiz = props => {
  const {
    answer,
    answerOptions,
    onAnswerSelected,
    question,
    questionHelperText,
    questionIndex,
    questionTotal,
  } = props;

  const renderAnswerOptions = key => (
    <AnswerOption
      key={key.content}
      answerContent={key.content}
      answerType={key.type}
      answer={answer}
      questionIndex={questionIndex}
      onAnswerSelected={onAnswerSelected}
    />
  );
  return (
    <div key={questionIndex} className={'app-cost-calc__content'}>
      <div className={'app-cost-calc__question'}>
        <QuestionCount counter={questionIndex} total={questionTotal} />
        <Question content={question} questionHelperText={questionHelperText}/>
      </div>
      <ul className={'app-cost-calc__answer-list'}>
        {answerOptions.map(renderAnswerOptions)}
      </ul>
    </div>
  );
};

Quiz.propTypes = {
  answer: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  questionHelperText: PropTypes.string,
};

Quiz.defaultProps = {
  questionHelperText: '',
};

export default Quiz;
