import { AssistantCover } from '../../articles/Assistant';
import { CybersixgillSolution } from '../../articles/Cybersixgill';
import {
  WorkroomCouch,
  WorkroomCover,
  WorkroomWork,
} from '../../articles/Workroom';

export const coverComponents = {
  workroomCover: WorkroomCover,
  assistantCover: AssistantCover,
};

export const sectionEntryComponents = {
  workroomCouch: WorkroomCouch,
  workroomWork: WorkroomWork,
  cybersixgillSolution: CybersixgillSolution,
};
