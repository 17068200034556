import React, { Component } from 'react';
import {
  enableAnimations,
  getCloudinarySrcSet,
  getCloudinaryUrl,
} from 'config/helpers';
import { BackgroundImage } from 'components';
import {
  AboutUsSection,
  TypesSliderSection,
  FaqSection,
  FooterSection,
  HeroSection,
  JobOpeningsSection,
  LocationsSection,
  FeelOurSpiritSection,
  SocialSection,
  YoutubeSection,
  TeammatesSection,
} from 'views/f2f/components';
import './F2F.scss';

class F2F extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { offers: jobs } = this.props || {};

    return (
      <>
        <div className={'f2f'} id={'f2f-top'}>
          {/* todo: performance improvement: for mobile/tablet use only cropped versions of this img */}
          <BackgroundImage
            src={getCloudinaryUrl(
              'remote-work-landing/boldare-offices-map.svg',
              'f_auto,q_auto:low,w_2668',
            )}
            additionalClass={'f2f__bg--map'}
            alt={'Boldare - Digital Product Design Development Company'}
            loading={'auto'}
          />
          {/* todo add srcset to images on 1st paint */}
          <div className={'f2f__container'}>
            <div className={'f2f__content'}>
              <img
                src={getCloudinaryUrl(
                  'remote-work-landing/boldare-product-design-development-company-satellite.png',
                  'f_auto,q_auto,w_134',
                )}
                alt={'Boldare - Digital Product Design Development Company'}
                className={'image__satellite2'}
                width={134}
                height={164}
                loading={'lazy'}
              />
              <HeroSection />
              <FeelOurSpiritSection />
              <AboutUsSection />
              <TypesSliderSection />
              <JobOpeningsSection jobs={jobs} />
              <LocationsSection />
              <TeammatesSection />
              <YoutubeSection />
              <FaqSection />
              <SocialSection />
            </div>
          </div>
        </div>
        <FooterSection jobs={jobs} />
        <img
          src={getCloudinaryUrl(
            'f2f-landing/f2f-landing-hero-bg',
            'f_auto,q_auto,w_976',
          )}
          alt={'Boldare - Digital Product Design Development Company'}
          className={'f2f-image__bg'}
          width={976}
          height={657}
          loading={'lazy'}
        />
      </>
    );
  }
}

export default F2F;
