import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import NewNormal from 'views/newNormal';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['new-normal'];
const { navColors } = page;
const url = `${config.siteUrl}/new-normal/`;
const canonical = `${url}`;

const NewNormalPage = ({ data }) => {
  const posts = data.posts.edges;
  const authors = data.authors.edges;

  const filters = data.filters.edges.map(item => ({
    name: item.node.frontmatter.title,
    order: item.node.frontmatter.order,
  }));

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect message={'about'} />
      <NewNormal posts={posts} authors={authors} filters={filters} />
    </Layout>
  );
};

NewNormalPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({ edges: PropTypes.array }),
    authors: PropTypes.shape({ edges: PropTypes.array }),
    filters: PropTypes.shape({ edges: PropTypes.array }),
  }).isRequired,
};

export const pageQuery = graphql`
  query NewNormalPage {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: {
            in: [
              "article-page"
              "case-study-page"
              "medium-page"
              "external-page"
              "youtube-video"
            ]
          }
          settings: { category: { ne: "Case Study" } }
          tags: { eq: "new normal" }
        }
      }
    ) {
      ...markdownFields
    }

    authors: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "author-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    filters: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          settings: { category: { ne: "Case Study" } }
          isNewNormal: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
          }
        }
      }
    }
  }
`;

export default NewNormalPage;
