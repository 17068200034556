export const data = [
  {
    title: 'Simple Step by Step walktrough',
    text:
      'Information architecture in this case was a result of choosing solutions for users needs (age group 35+), their cultural habits and functional capabilities.',
    image: 'elipsis-1-device',
    zoom: 'elipsis-1',
  },
  {
    title: 'Sharing as easy as snap-of-a-finger',
    text: 'An easy and intuitive way to share the content.',
    image: 'elipsis-2-device',
    zoom: 'elipsis-2',
  },
];
