import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox={'0 0 127 108'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <mask id={'path-1-inside-1_1378_6126'}>
        <path
          clipRule={'evenodd'}
          d={
            'M76.8366 12.2791L117.446 62.4271L117.374 62.4853L117.426 62.5502L68.7619 101.958L60.0085 91.1485L89.6932 67.1103L8.70412 58.598L10.1569 44.7757L92.2402 53.4029L66.0271 21.0325L76.8366 12.2791Z'
          }
        />
      </mask>
      <path
        clipRule={'evenodd'}
        d={
          'M76.8366 12.2791L117.446 62.4271L117.374 62.4853L117.426 62.5502L68.7619 101.958L60.0085 91.1485L89.6932 67.1103L8.70412 58.598L10.1569 44.7757L92.2402 53.4029L66.0271 21.0325L76.8366 12.2791Z'
        }
        fill={'#242424'}
      />
      <path
        d={
          'M117.446 62.4271L119.334 64.7586L121.665 62.8706L119.777 60.5392L117.446 62.4271ZM76.8366 12.2791L79.168 10.3911L77.2801 8.05967L74.9486 9.94763L76.8366 12.2791ZM117.374 62.4853L115.486 60.1538L113.154 62.0418L115.042 64.3733L117.374 62.4853ZM117.426 62.5502L119.314 64.8816L121.646 62.9936L119.758 60.6622L117.426 62.5502ZM68.7619 101.958L66.4304 103.846L68.3184 106.177L70.6498 104.289L68.7619 101.958ZM60.0085 91.1485L58.1205 88.817L55.7891 90.705L57.677 93.0364L60.0085 91.1485ZM89.6932 67.1103L91.5811 69.4417L97.2097 64.8838L90.0067 64.1267L89.6932 67.1103ZM8.70412 58.598L5.72056 58.2844L5.40697 61.268L8.39054 61.5815L8.70412 58.598ZM10.1569 44.7757L10.4705 41.7921L7.48693 41.4785L7.17334 44.4621L10.1569 44.7757ZM92.2402 53.4029L91.9266 56.3865L99.1295 57.1436L94.5716 51.515L92.2402 53.4029ZM66.0271 21.0325L64.1391 18.701L61.8077 20.589L63.6956 22.9204L66.0271 21.0325ZM119.777 60.5392L79.168 10.3911L74.5052 14.167L115.114 64.3151L119.777 60.5392ZM119.262 64.8167L119.334 64.7586L115.558 60.0957L115.486 60.1538L119.262 64.8167ZM115.042 64.3733L115.095 64.4382L119.758 60.6622L119.705 60.5972L115.042 64.3733ZM115.538 60.2187L66.8739 99.6266L70.6498 104.289L119.314 64.8816L115.538 60.2187ZM71.0933 100.07L62.3399 89.2605L57.677 93.0364L66.4304 103.846L71.0933 100.07ZM61.8964 93.4799L91.5811 69.4417L87.8052 64.7788L58.1205 88.817L61.8964 93.4799ZM8.39054 61.5815L89.3796 70.0938L90.0067 64.1267L9.01771 55.6144L8.39054 61.5815ZM7.17334 44.4621L5.72056 58.2844L11.6877 58.9116L13.1405 45.0892L7.17334 44.4621ZM92.5537 50.4194L10.4705 41.7921L9.84332 47.7592L91.9266 56.3865L92.5537 50.4194ZM63.6956 22.9204L89.9087 55.2909L94.5716 51.515L68.3585 19.1445L63.6956 22.9204ZM74.9486 9.94763L64.1391 18.701L67.915 23.3639L78.7246 14.6105L74.9486 9.94763Z'
        }
        fill={'#242424'}
        mask={'url(#path-1-inside-1_1378_6126)'}
      />
      <mask id={'path-3-inside-2_1378_6126'}>
        <path
          clipRule={'evenodd'}
          d={
            'M81.6094 8.41419L122.218 58.5622L122.152 58.6165L122.207 58.6851L73.5425 98.0929L64.7891 87.2833L94.6908 63.0694L4.41433 53.5809L5.86712 39.7586L96.8362 49.3198L70.7999 17.1676L81.6094 8.41419Z'
          }
        />
      </mask>
      <path
        clipRule={'evenodd'}
        d={
          'M81.6094 8.41419L122.218 58.5622L122.152 58.6165L122.207 58.6851L73.5425 98.0929L64.7891 87.2833L94.6908 63.0694L4.41433 53.5809L5.86712 39.7586L96.8362 49.3198L70.7999 17.1676L81.6094 8.41419Z'
        }
      />
      <path
        d={
          'M122.218 58.5622L124.106 60.8937L126.438 59.0057L124.55 56.6743L122.218 58.5622ZM81.6094 8.41419L83.9408 6.52623L82.0529 4.19479L79.7214 6.08275L81.6094 8.41419ZM122.152 58.6165L120.264 56.285L117.932 58.173L119.82 60.5045L122.152 58.6165ZM122.207 58.6851L124.095 61.0165L126.427 59.1285L124.538 56.797L122.207 58.6851ZM73.5425 98.0929L71.211 99.9808L73.099 102.312L75.4304 100.424L73.5425 98.0929ZM64.7891 87.2833L62.9011 84.9519L60.5697 86.8399L62.4576 89.1713L64.7891 87.2833ZM94.6908 63.0694L96.5788 65.4008L102.207 60.8429L95.0044 60.0858L94.6908 63.0694ZM4.41433 53.5809L1.43077 53.2673L1.11718 56.2509L4.10075 56.5645L4.41433 53.5809ZM5.86712 39.7586L6.1807 36.775L3.19714 36.4614L2.88355 39.445L5.86712 39.7586ZM96.8362 49.3198L96.5226 52.3034L103.726 53.0605L99.1677 47.4319L96.8362 49.3198ZM70.7999 17.1676L68.9119 14.8361L66.5805 16.7241L68.4684 19.0555L70.7999 17.1676ZM124.55 56.6743L83.9408 6.52623L79.2779 10.3022L119.887 60.4502L124.55 56.6743ZM124.039 60.9479L124.106 60.8937L120.331 56.2308L120.264 56.285L124.039 60.9479ZM119.82 60.5045L119.876 60.5731L124.538 56.797L124.483 56.7284L119.82 60.5045ZM120.319 56.3536L71.6545 95.7614L75.4304 100.424L124.095 61.0165L120.319 56.3536ZM75.8739 96.2049L67.1205 85.3954L62.4576 89.1713L71.211 99.9808L75.8739 96.2049ZM66.677 89.6148L96.5788 65.4008L92.8029 60.7379L62.9011 84.9519L66.677 89.6148ZM4.10075 56.5645L94.3773 66.0529L95.0044 60.0858L4.72792 50.5973L4.10075 56.5645ZM2.88355 39.445L1.43077 53.2673L7.3979 53.8945L8.85068 40.0722L2.88355 39.445ZM97.1498 46.3363L6.1807 36.775L5.55353 42.7422L96.5226 52.3034L97.1498 46.3363ZM68.4684 19.0555L94.5048 51.2078L99.1677 47.4319L73.1313 15.2796L68.4684 19.0555ZM79.7214 6.08275L68.9119 14.8361L72.6878 19.499L83.4974 10.7456L79.7214 6.08275Z'
        }
        fill={'#242424'}
        mask={'url(#path-3-inside-2_1378_6126)'}
      />
    </svg>
  );
};

export default Arrow;

Arrow.defaultProps = {
  className: '',
};

Arrow.propTypes = {
  className: PropTypes.string,
};
