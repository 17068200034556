import React, { Component } from 'react';
import {
  Section,
  SectionHeader,
  WayPointHandler,
  SliderWithCounter,
  Heading,
  Paragraph,
} from 'components';
import './WorksSlider.scss';
import { sliderData } from './logic';

class WorksSlider extends Component {
  render() {
    return (
      <WayPointHandler path={'ai-services'}>
        <Section color={'white'} additionalClass={'ai-services'}>
          <Heading
            color={'black'}
            size={'large'}
            additionalClass={'ai-services__heading'}
          >
            Case studies of our AI-Powered products
          </Heading>
          <Paragraph
            color={'black'}
            size={'medium-constant'}
            additionalClass={'ai-services__paragraph'}
          >
            Find out how our AI services improved our clients' digital
            products, helping their businesses grow and outpace their
            competition. See how even small AI improvements can make big
            differences in your company.
          </Paragraph>
        </Section>
        <SliderWithCounter
          data={sliderData.map(slide => ({
            ...slide,
            background: slide.background,
          }))}
        />
      </WayPointHandler>
    );
  }
}

export default WorksSlider;
