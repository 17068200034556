import React from 'react';

import { Row, Col } from 'react-flexbox-grid';
import { Header, Orbit, Section } from '../../../../components/DS';
import { whoWeAre } from '../../utils';

import './Benefits.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'vertical'}
    theme={'light'}
  />
);

const { benefits: { title, description }} = whoWeAre;

export const Benefits = () => (
  <Section
    heading={<SectionHeader title={title} description={description} />}
    color={'mine-shaft'}
    noOverflow
  >
    <div className={'who-we-are-benefits__orbit-container'}>
      <Orbit
        theme={'light'}
      />
    </div>
    <Row className={'who-we-are__list-wrapper'}>
      {
        whoWeAre.benefits.list.map(element => (
          <Col xs={12} md={4} className={'who-we-are__list-item'}>
            <div className={'icon-wrapper'}>
              <img src={element.icon} alt={'icon'} />
            </div>
            <p className={'description'}>{element.centent}</p>
          </Col>
        ))
      }
    </Row>
  </Section>
)
