import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader } from 'components';
import './Banner.scss';

const SpyLink = Scroll.Link;

const handleClick = item => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: item,
    });
  }
};

const Banner = ({ id, link }) => (
  <>
    <SubpageBanner
      backgroundColor={'mine-shaft'}
      additionalClass={'resources-hub__banner'}
      alt={'New Normal in Practice'}
      contentBackground={'mine-shaft'}
      image={getCloudinaryUrl(
        '/resources/collage-resources.png',
        'f_auto,q_auto,w_800',
      )}
    >
      <SectionHeader
        additionalClass={
          'section-header__description--medium resources-hub__banner-content'
        }
        color={'white'}
        description={`This is where we share our vast knowledge about digital products, design and development, Agile, scrum and the New Normal. Find out more in our carefully prepared ebooks, webinars, and more.`}
        descriptionColor={'white'}
        fixed
        lineColor={'yellow'}
        size={'extra-large'}
      >
        Our knowledge is yours now!
      </SectionHeader>
      <SpyLink
        id={id}
        to={link}
        spy={false}
        offset={-50}
        smooth
        duration={500}
        className={'link'}
        rel={'nofollow'}
        onClick={() => handleClick('resources-hub__banner')}
      >
        <span
          className={'button button--primary button--yellow'}
          id={'explore-resources'}
        >
          Explore resources
        </span>
      </SpyLink>
    </SubpageBanner>
  </>
);

export default Banner;
