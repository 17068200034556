import React from 'react';
import './GermanPartners.scss';
import { Section, SectionHeader } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic';

const renderItems = () =>
  data.map((item, index) => (
    <div
      className={'contact-de-german-partners__clients__item'}
      key={`award-${index}`}
    >
      <div
        className={classNames(
          'image',
          'contact-de-german-partners__clients__link',
        )}
      >
        {item.link ? (
          <a href={item.link} title={item.alt}>
            <img
              src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_190')}
              className={classNames(
                'image contact-de-german-partners__clients__img',
                {
                  'contact-de-german-partners__clients__img--vertical':
                    item.vertical,
                  'contact-de-german-partners__clients__img--zero-brightness':
                    item.black,
                },
              )}
              alt={item.alt || 'Boldare clients'}
              loading={'lazy'}
            />
          </a>
        ) : (
          <img
            src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_190')}
            className={classNames('contact-de-german-partners__clients__img', {
              'contact-de-german-partners__clients__img--vertical':
                item.vertical,
              'contact-de-german-partners__clients__img--zero-brightness':
                item.black,
            })}
            alt={item.alt || 'Boldare clients'}
            loading={'lazy'}
          />
        )}
      </div>
    </div>
  ));

const GermanPartners = () => (
  <Section
    additionalClass={'contact-de-german-partners__section'}
    spaceless
    side={'right'}
  >
    <SectionHeader
      additionalClass={'contact-de-german-partners__text-header'}
      color={'mine-shaft'}
      size={'large'}
      headingType={2}
      noLine
    >
      Unsere Partner:
    </SectionHeader>
    <div className={'contact-de-german-partners__clients-wrapper'}>
      {renderItems()}
    </div>
  </Section>
);

export default GermanPartners;
