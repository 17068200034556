import React from 'react';
import PropTypes from 'prop-types';

import './DateComponent.scss';

const DateComponent = ({ date }) => {
  const dateToDisplay = date ? new Date(date) : new Date();
  const dateComponent = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
  }).format(dateToDisplay);

  return (
    <time className={'article-date'} dateTime={dateComponent}>
      {dateComponent}
    </time>
  );
};

DateComponent.defaultProps = {
  additionalClass: '',
  date: '',
};

DateComponent.propTypes = {
  additionalClass: PropTypes.string,
  date: PropTypes.string,
};

export default DateComponent;
