import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { Section, Heading, SectionHeader, Paragraph, Image } from 'components';

const Problem = () => (
  <>
    <Section color={'mine-shaft'}>
      <Row className={'no-gap anim-fade-in-bottom'}>
        <Col sm={12} md={6}>
          <SectionHeader
            size={'small'}
            color={'white'}
            lineColor={'white'}
            headingType={2}
          >
            Problem
          </SectionHeader>
        </Col>
        <Col sm={12} md={6}>
          <div className={'hack-the-wind__problem'}>
            <Heading
              size={'medium'}
              color={'yellow'}
              type={3}
              additionalClass={'text--bold'}
            >
              CHALLENGE 1
            </Heading>
            <Paragraph
              size={'medium'}
              color={'white'}
              additionalClass={'text--light'}
            >
              Sponsored by: EDPR
            </Paragraph>
            <Heading
              size={'medium'}
              color={'white'}
              type={3}
              additionalClass={'text--bold'}
            >
              Wind Turbine Fault Prediction
            </Heading>
            <Paragraph
              size={'medium'}
              color={'white'}
              additionalClass={'text--light'}
            >
              Predicting components failure in wind turbines is key for the
              wind energy industry. It decreases time and costs related to
              maintenance and cuts the disruptions to energy production to a
              minimum. It has also an impact on life-span of components and
              wind turbines as a whole. This enables wind energy suppliers to
              stabilize their costs and optimize and level the energy
              production.
            </Paragraph>
            <Paragraph
              size={'medium'}
              color={'white'}
              additionalClass={'text--light'}
            >
              The teams taking part in the Hack the Wind received a year-worth
              of data regarding failures of 5 components of a five wind
              turbines from a real wind farm. The winners have to detect the
              greatest number of failures by building up machine learning
              models for effective errors prediction and thus made the highest
              maintenance cost savings, which in case of wind turbines, are
              counted in millions of Euro. Finally, the teams were asked to to
              present the solution in form of a product that is not only useful
              but also user-centered, and thus the design and technical
              solution work in harmony.
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Section>
    <Section color={'gray-light'} fullwidth spaceless>
      <Row className={'no-gap anim-fade-in-bottom'}>
        <Col
          sm={12}
          md={6}
          className={'hack-the-wind__problem-solution-image'}
        >
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/ml-team-in-helicopter.png',
              'f_auto,q_auto,w_960',
            )}
            className={'image'}
            alt={'Hack The Wind'}
            loading={'lazy'}
          />
        </Col>
        <Col sm={12} md={6} className={'hack-the-wind__problem-solution'}>
          <div className={'hack-the-wind__problem-solution-inner'}>
            <Paragraph
              color={'black'}
              size={'medium-plus'}
              additionalClass={'text--bold'}
            >
              We were in TOP 3 in Hack the Wind Hackathon
            </Paragraph>
            <Paragraph
              color={'black'}
              additionalClass={'text--light middle-paragraph'}
            >
              For 48 hours, Boldare Machine Learning team worked relentlessly
              to provide a machine learning-based product to predict wind
              turbine failures. You can read the summary of their approach to
              the problem bellow.
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Section>
  </>
);

export default Problem;
