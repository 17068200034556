import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Accordion } from 'react-accessible-accordion';
import ReactHtmlParser from 'react-html-parser';
import Glide from '@glidejs/glide';
import {
  Paragraph,
  Section,
  SectionHeader,
  Accordion as AccordionCustom,
} from 'components';
import { classNames } from 'utils';
import { data } from './logic.js';
import './HowWeBuild.scss';

class HowWeWork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.glide = new Glide('.how-we-build__slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      animationDuration: 300,
      gap: 0,
      dragThreshold: false,
      autoplay: 3000,
      classes: {
        activeNav: 'active',
      },
    });

    this.glide.on(['build.after', 'run.after'], () => {
      this.setState({ currentSlide: this.glide.index });
    });

    this.glide.mount();
  }

  renderItem = () =>
    data.map(item => (
      <li key={item.title} className={'how-we-build__item glide__slide'}>
        <SectionHeader
          size={'small'}
          headingType={3}
          description={ReactHtmlParser(item.description)}
          fixed
        >
          {item.title}
        </SectionHeader>
      </li>
    ));

  renderTitle = () =>
    data.map((item, index) => (
      <li
        key={`title_${item.title + index}`}
        className={classNames('how-we-build__title', 'glide__bullet', {
          active: this.state.currentSlide === index,
        })}
        onClick={() => this.glide.go(`=${index}`)}
        role={'presentation'}
      >
        <Paragraph color={'white'} size={'medium'}>
          {item.title}
        </Paragraph>
      </li>
    ));

  renderAccordion = () =>
    data.map((item, index) => (
      <Fragment key={`acordion_${item.title + index}`}>
        <AccordionCustom
          additionalClass={'accordion__slider-item hide-tablet hide-desktop'}
          id={'prototyping-team'}
          backgroundColor={'gray-light'}
          headline={item.title}
          description={ReactHtmlParser(item.description)}
          lineColor={'black'}
          fontColors={['black', 'black', 'black']}
          index={index + 1}
          expandFirst
        />
      </Fragment>
    ));

  renderNavBullets = () =>
    data.map((item, index) => (
      <button
        key={`button_${item.title + index}`}
        className={'how-we-build__bullet glide__bullet'}
        data-glide-dir={`=${index}`}
        aria-label={'Slider bullet'}
      />
    ));

  render() {
    return (
      <Section color={'gray-light'}>
        <SectionHeader
          size={'big'}
          lineColor={'violet'}
          color={'black'}
          descriptionColor={'white'}
          headingType={2}
        >
          How we build your
          <br />
          product
        </SectionHeader>
        <Accordion>{this.renderAccordion()}</Accordion>
        <div className={'how-we-build__slider glide hide-mobile'}>
          <Row>
            <Col sm={12} md={6}>
              <ol className={'how-we-build__list-nav'}>
                {this.glide && this.renderTitle()}
              </ol>
            </Col>
            <Col sm={12} md={6} className={'hide-mobile'}>
              <div data-glide-el={'track'} className={'glide__track'}>
                <ul className={'glide__slides'}>{this.renderItem()}</ul>
              </div>
              <div
                className={'how-we-build__nav glide__bullets'}
                data-glide-el={'controls[nav]'}
              >
                {this.renderNavBullets()}
              </div>
            </Col>
          </Row>
        </div>
      </Section>
    );
  }
}

export default HowWeWork;
