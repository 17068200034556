import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Button, Section } from 'components';
import { useIsMobile } from 'hooks/useIsMobile';
import { getBookDiscoverySessionImageUrl } from '../../utils';

import ClutchTop from '../ClutchTop';
import { SECTION_ID } from '../Form';

import './Hero.scss';

export default function Hero() {
  const isMobile = useIsMobile();

  return (
    <Section
      side={'right'}
      color={'mine-shaft'}
      spaceless
      containerClass={'book-discovery-session__hero'}
    >
      <div className={'book-discovery-session__hero--info'}>
        <ClutchTop
          className={'hide-mobile-important hide-tablet-big-important'}
        />
        <h1 className={'book-discovery-session__hero__title first'}>
          Discover your best idea
        </h1>
        <h3 className={'book-discovery-session__hero__subtitle first'}>
          Consult your business idea with Boldare, a top software development &
          consulting partner
        </h3>
        <Button
          color={'yellow'}
          size={'large'}
          onClick={() =>
            scrollTo(isMobile ? `#form-${SECTION_ID}` : `#${SECTION_ID}`)
          }
        >
          BOOK A FREE DISCOVERY SESSION
        </Button>
      </div>

      {isMobile ? (
        <img
          className={'book-discovery-session__hero__image'}
          src={getBookDiscoverySessionImageUrl('hero-mobile.png')}
          width={'474'}
          height={'225'}
          loading={'lazy'}
          alt={'Team'}
        />
      ) : (
        <div className={'book-discovery-session__hero__image-wrapper'}>
          <img
            className={'book-discovery-session__hero__image'}
            src={getBookDiscoverySessionImageUrl('hero.png')}
            width={'760'}
            height={'1016'}
            loading={'lazy'}
            alt={'Team'}
          />
        </div>
      )}
    </Section>
  );
}
