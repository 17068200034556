import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Comparison.scss';

class Comparison extends Component {
  renderBoxes = () =>
    data.map((item, index) => {
      return (
        <div
          className={`showbiz__comparison-wrapper showbiz__comparison-wrapper--${index}`}
          key={`part-${index}`}
        >
          <div className={'showbiz__comparison-logo'}>
            <img
              src={getCloudinaryUrl(
                `assets/showbiz/${item.logo}`,
                'f_auto,q_auto',
              )}
              key={item.logo}
              className={'image anim-fade-in'}
              alt={item.year}
              loading={'lazy'}
            />
          </div>
          <div
            className={'showbiz__comparison-box'}
            key={`showbiz__comparison-${item.number}`}
          >
            <Heading type={2} size={'large'} color={'mine-shaft'}>
              {item.year}
            </Heading>
            {this.renderDetails(item.details)}
          </div>
        </div>
      );
    });

  renderDetails = details =>
    details.map((item, index) => {
      return (
        <div
          key={`details-${index}`}
          className={'showbiz__comparison-details'}
        >
          <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
      );
    });

  render() {
    return (
      <Section additionalClass={'showbiz__comparison'} spaceless>
        <div className={'showbiz__comparison-container'}>
          <img
            src={getCloudinaryUrl(
              'assets/showbiz/showbiz-device2.png',
              'f_auto,q_auto',
            )}
            alt={''}
            key={'Versions compared'}
            className={'image anim-fade-in-bottom showbiz__comparison-image'}
            loading={'lazy'}
          />
          {this.renderBoxes()}
        </div>
      </Section>
    );
  }
}

export default Comparison;
