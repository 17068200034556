import config from '../../../meta/config';
import companyData from '../../../meta/companyData';

export const pages = {
  'generator-propozycji': {
    footer: true,
    navColors: ['black', 'mine-shaft'],
  },
  blog: {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    title: 'Blog about Web & Mobile Development & UX Design',
    metaTitle: 'Blog about Web & Mobile Development & UX Design',
    siteContent:
      'Read our blog to learn about the advantages of agile software development based on scrum and digital transformation in practice.',
    items: {
      'new-work': {
        footer: true,
        navColors: ['black', 'mine-shaft'],
        siteContent: '',
        siteCover: '',
      },
    },
  },
  category: {
    footer: true,
    navColors: ['black'],
  },
  author: {
    footer: true,
    navColors: ['black'],
  },
  article: {
    footer: true,
    navColors: ['black'],
  },
  event: {
    footer: true,
    navColors: ['black'],
  },
  'hack-the-wind': {
    footer: true,
    navColors: ['white', 'violet', 'black'],
    metaTitle: 'Boldare Machine Learning Team in the final 3 of Hack the Wind',
    siteContent:
      'With the goal of resolving serious problems in the wind energy industry, Boldare’s team of five developers and a product designer took part in a prestigious hackathon. For 48 hours, they worked relentlessly to provide a machine learning-based product to predict wind turbine failures.',
    siteCover: 'img/cover_hack-the-wind.png',
  },
  'systemic-digital-transformation': {
    footer: true,
    navColors: ['black', 'pigeon-post'],
    metaTitle: 'Systemic Digital Transformation for travel industry - webinar',
    siteCover: 'img/cover_systemic-digital-transformation.jpg',
  },
  work: {
    footer: true,
    navColors: ['mine-shaft', 'white', 'violet'],
    title: 'Case Studies of our Clients - Portfolio',
    metaTitle: 'Case Studies of our Clients - Portfolio',
    siteContent: `Meet Our Clients Case Studies to see what we did for them. Explore our experience and see yourself why you can trust us!`,
    siteCover: 'img/cover_work.jpg',
    items: {
      'case-study-corel': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: Corel Corporation,',
        metaTitle: 'Case study: Corel Corporation, graphics software company',
        siteContent:
          'See how we created an online space for the knowledge exchange dedicated to vivid Corel community members.',
        siteCover: 'img/case-study-corel.png',
      },
      'case-study-oneida': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: Oneida',
        metaTitle: 'Web application for Oneida, a dinnerware retailer',
        siteContent:
          'See a case study of a virtual tabletop, that allows to mix and match Oneida dinnerware and cutlery ranges.',
        siteCover: 'img/work-oneida.jpg',
      },
      'case-study-practitest': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: PractiTest',
        metaTitle: 'New website for a PractiTest SaaS',
        siteContent:
          'Discover how our design team redesigned, improved UX/UI, and refreshed the website of Israeli SaaS company, PractiTest.',
        siteCover: 'img/work-practitest.jpg',
      },
      'case-study-pakt': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: Pakt',
        metaTitle:
          'UX and UI improvements for Pakt, a virtual wardrobe web app',
        siteContent:
          'See the case study of Pakt, a web platform that allows users to store their clothes in a safe place and create a personal, virtual wardrobe',
        siteCover: 'img/case-study-pakt-web-app.jpg',
      },
      'case-study-planet-escape': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: Planet Escape',
        metaTitle: 'Case study: Planet Escape, a travel agency',
        siteContent:
          'See how we transformed the travel agency PlanetTravel website into a beautiful and efficient online platform thanks to UX and UI improvements.',
        siteCover: 'img/cover-planet-escape.jpg',
      },
      'case-study-showbiz': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case study: Showbiz',
        metaTitle: 'Case study: Web app redesign for Italian model agency',
        siteContent:
          'From moodboards to complete redesign - see how we improved the existing web app of model agency by refreshing design, enhancing UX and UI, and improving its architecture.',
        siteCover: 'img/showbiz-cover.png',
      },
      'case-study-spa-monkeys': {
        footer: true,
        navColors: ['mine-shaft', 'white'],
        title: 'Case Study: Spa Monkeys',
        metaTitle: 'Case study: SpaMonkey, an online marketplace',
        siteContent:
          'See how we helped the SpaMonkey, an online marketplace based in Hong Kong, to deliver a new version of their beauty and wellness platform to their users.',
        siteCover: 'img/cover-spa-monkeys.jpg',
      },
      'why-design-matters': {
        footer: true,
        navColors: ['white', 'mine-shaft'],
        title: 'Why Design Matters',
        metaTitle: 'UX and UI Design Services | User Experience Consulting',
        siteContent:
          'When you’re building digital products, good design is important. Read our article about UX & UI design to find 5 reasons why really design matters.',
        siteCover: 'img/cover_work_why-design-matters.png',
      },
    },
  },
  products: {
    footer: false,
    navColors: ['black'],
    siteContent: `Let's make products you can be proud of!`,
    metaTitle: `Boldare Products – Let's make products you can be proud of!`,
    siteCover: '/products/products-thumb.jpg',
    instagramUrl: 'https://www.instagram.com/boldarecom',
    facebookUrl: 'https://www.facebook.com/boldarecom/',
    linkedinUrl: 'https://www.linkedin.com/company/boldarecom/',
  },
  'privacy-policy': {
    footer: true,
    siteContent: 'Information we collect and how we use it.',
    metaTitle: 'Privacy Policy – Privacy & Terms',
    siteCover: 'img/cover_privacy-policy.jpg',
  },
  'development-teams': {
    footer: true,
    navColors: ['white', 'red'],
    siteCover: 'img/cover_development-teams.png',
    title: 'Dedicated Development Team',
    metaTitle: 'Dedicated Development Team',
    siteContent:
      'Hire a team of dedicated developers from a product design and development company with great global experience!',
  },
  'software-development-outsourcing': {
    footer: true,
    navColors: ['white', 'red'],
    siteCover: 'img/cover_development-teams.png',
    title: 'Backend & Frontend Development Outsourcing Team',
    metaTitle: 'Backend & Frontend Development Outsourcing Team',
    siteContent:
      'Software development Outsorcing can solve problems which your business may face. Hire our team of experienced Backend & Frontend Developers, Product Designers, Quality Assurance Engineers, Scrum Masters and Product Owners!',
  },
  'machine-learning': {
    footer: true,
    navColors: ['black', 'white'],
    siteCover: 'img/cover_machine-learning.png',
    title: 'Machine Learning Services & Consulting',
    metaTitle: 'Machine Learning Services & Consulting',
    siteContent:
      'Machine Learning services brings variety of new opportunities. Explore our solutions and schedule a call with one ouf our experts to consult and see how can we help you with your app or idea.',
  },
  career: {
    footer: true,
    navColors: ['black', 'pigeon-post'],
    metaTitle: 'Career & Job offers - Join Boldare Team',
    siteContent: `Take your chances and join #BoldareTeam! The place where ${companyData.employees}+ creative minds get along well - work with passion to build stunning digital products!`,
    siteCover: 'img/cover_career_2.0.png',
    items: {
      meetjs: {
        footer: true,
        compactFooter: true,
        navColors: ['black', 'pigeon-post'],
        siteContent:
          'Here, you’re not “just” a developer or a designer. Grow your career with Boldare!',
        siteCover: 'img/cover_career_2.0.png',
      },
      nodejs: {
        footer: true,
        compactFooter: true,
        metaTitle: 'Chcesz się rozwijać jako Node.js developer?',
        siteContent:
          'Dołącz do jednego z największych projektów w BOLDARE. Nasz zespół czeka na Ciebie!',
        siteCover: 'img/nodejs/cover.png',
      },
      apply: {
        footer: true,
        compactFooter: true,
        navColors: ['black', 'pigeon-post'],
        metaTitle: 'Careers at Boldare | Find your desired role',
        siteContent:
          'Here, you’re not “just” a developer or a designer. Grow your career with Boldare!',
        siteCover: 'img/cover_career_2.0.png',
      },
    },
  },
  careerOffers: {
    footer: true,
    navColors: ['black', 'pigeon-post'],
    metaTitle: 'Career & Job offers - Join Boldare Team',
    siteContent: `Take your chances and join #BoldareTeam! The place where ${companyData.employees}+ creative minds get along well - work with passion to build stunning digital products!`,
    siteCover: 'img/cover_career_2.0.png',
  },
  'digital-transformation': {
    footer: true,
    navColors: ['black', 'red'],
    siteCover: 'img/cover_digital-transformation.png',
    title: 'Digital Transformation Services Company',
    metaTitle: 'Digital Transformation Services Company',
    siteContent:
      'Digital transformation can be a solution to the problems you face. See how we can help to digitize your business and thrive in the world ruled by software!',
  },
  'digitale-transformation': {
    footer: true,
    navColors: ['black', 'red'],
    siteCover: 'de/digitale-transformation/cover.jpg',
    title: 'Digitale Transformation',
    metaTitle: 'Digitale Transformation',
    siteContent: `Boldare begleitet Sie mit mehr als 200 Experten von der ersten Überlegung, über die Konzeptionierung bis hin zur konkreten Umsetzung der digitalen Transformation inklusive der Entwicklung digitaler Produkte innerhalb Ihres Unternehmens.`,
  },
  mvp: {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    siteCover: 'img/mvp.png',
    title: 'MVP Development Services & Company - Minimum Viable Product APP',
    metaTitle:
      'MVP Development Services & Company - Minimum Viable Product APP',
    siteContent:
      'Discover how to use MVP development to validate business idea, develop new feature, product or service or expand existing ones.',
  },
  'code-audit': {
    footer: true,
    navColors: ['mine-shaft', 'white'],
    siteCover: 'img/LP_Code_Audit_social_media_cover.png',
    title: 'Code Audit & UX Auditing Process',
    metaTitle: 'Code Audit & UX Auditing Process',
    siteContent:
      'Boldare offers custom code audits and UX audits tailored to your business needs. Learn about our service and how you benefit from auditing with Boldare.',
  },
  services: {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    siteCover: config.siteCover,
    title: 'Services - How Can We Help You?',
    metaTitle: 'Services - How Can We Help You?',
    siteContent:
      'We offer a wide range of software development services, agile and scrum powered teams, digital transformation and machine learning services.',
  },
  f2f: {
    footer: false,
    navColors: ['black', 'white', 'black'],
    navPaths: [
      {
        name: 'Teamwork ideas',
        link: '#f2f-types',
      },
      {
        name: 'Workation',
        link: '#f2f-about',
      },
      {
        name: 'Our locations',
        link: '#f2f-locations',
      },
      {
        name: 'FAQ',
        link: '#f2f-faq',
      },
    ],
    siteCover: 'f2f-landing/og_f2f.png',
    title: 'Boldare — a place full of people, not avatars',
    metaTitle: 'Boldare — a place full of people, not avatars',
    siteContent:
      'Meet Boldare’s ideas for alternative face-to-face work that emphasize our team spirit.',
    youtubeUrl: 'https://www.youtube.com/c/Boldarecom',
    instagramUrl: 'https://www.instagram.com/boldarecom',
    facebookUrl: 'https://www.facebook.com/boldarecom/',
  },
  'full-cycle-product-development': {
    footer: true,
    navColors: ['black', 'gray-light', 'violet'],
    siteCover: 'img/cover_full-cycle-product-development.png',
    title: 'Full Cycle Product Development Company',
    metaTitle: 'Full Cycle Product Development Company',
    siteContent:
      'We help companies at each stage of the product development cycle: from ideating with a prototype, testing a product with an MVP, to finding a product-market fit and scaling.',
  },
  'product-design-and-development': {
    footer: true,
    navColors: ['black', 'gray-light', 'violet'],
    siteCover: 'img/cover_product-design-and-development.png',
    title: 'Product Design and Development Services & Process',
    metaTitle: 'Product Design and Development Services & Process',
    siteContent:
      'See how we can use our efficient agile-powered processes to build great software to bring the maximum value to you and your customers!',
  },
  'ai-software-development-consulting': {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    siteCover:
      'services/ai-software-development-consulting/Preview_AI_Services.png',
    title: 'AI product development & consulting services',
    metaTitle: 'AI product development & consulting services',
    siteContent:
      'Explore our AI product development & consulting services on our landing page, designed to enhance your project with innovative AI solutions for improved design, user experience, and operational efficiency.',
  },
  clients: {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Clients whose missions we proudly share',
    siteContent:
      'We don’t just work with clients, we join them in their missions. Our partnerships are built on shared values and mutual goals. Discover how we can align with your mission.',
    siteCover: 'img/cover_clients.png',
  },
  contact: {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Contact Us  - Boldare Gliwice, Warsaw, Wroclaw, Cracow',
    siteContent:
      'We are digital thinkers who build great digital products for you. Know us better or just contact one of our Customer Success Guides straight away.',
    siteCover: 'img/cover_contact.jpg',
  },
  contactDe: {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Kontaktieren Sie uns oder besuchen Sie unser Büro in Hamburg',
    siteContent:
      'Boldare ist ein Unternehmen für digitale Produktentwicklung und digitales Produktdesign. Wir sorgen dafür, dass Ihre nächste App Ihre Kunden begeistert und damit Ihren ROI verbessert. Kontaktieren Sie uns noch heute und sehen Sie, was wir für Sie tun können.',
    siteCover: 'img/contact_de.jpg',
  },
  contactNl: {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Neem contact met ons op of bezoek ons kantoor in Amsterdam',
    siteContent:
      'Boldare - digitale productontwikkeling en consulting. Wij helpen onze klanten door de digitale producten te ontwikkelen, designen en bouwen die hun gebruikers willen.',
    siteCover: 'contact-page/contact-cover-NL.jpg',
  },
  about: {
    footer: true,
    navColors: ['black', 'white', 'mine-shaft'],
    metaTitle: 'About Us - Benefits of Working With Boldare',
    siteContent:
      'Looking for an experienced partner to build your next digital product? See which qualities make us a great choice.',
    siteCover: 'img/cover_contact.jpg',
  },
  newsroom: {
    footer: true,
    navColors: ['mine-shaft', 'white', 'violet'],
    metaTitle: 'Boldare Newsroom',
    siteContent:
      'Boldare is an agile-powered software development company helping its partners achieve their business goals and support their digital transformation efforts.',
    siteCover: 'img/boldare-newsroom-cover.png',
  },
  'your-development-partner': {
    footer: true,
    navColors: ['black', 'white'],
    metaTitle: 'Are you looking for a reliable software development partner?',
    siteContent:
      'Discover how we can improve your business, by helping you with delivering the best digital product to your target audiences within a suitable timeframe and budget.',
    siteCover: 'img/cover_your-development-partner.jpg',
  },
  'sprint-retrospective-tool': {
    footer: true,
    navColors: ['black'],
    metaTitle: 'Sprint Retrospective Tool by Boldare',
    siteContent:
      'Retro made faster and easier. Improve your retrospectives with this FREE tool',
    description:
      'Sprint Retrospective Tool by Boldare is a free online app for conducting scrum retrospectives. You can vote on specific cards, sort votes, share the retro board, and many more.',
    slug: 'sprint-retrospective-tool',
    cover: 'boldaretro_share.png',
  },
  'gitex-2019': {
    footer: true,
    navColors: ['white'],
    siteCover: '', // @TODO
    metaTitle: 'Boldare at GITEX 2019',
    siteContent:
      'We are an award-winning web development and graphic design company that provides services to the Middle East. Join us and see our video coverage from the event!',
  },
  resources: {
    footer: true,
    metaTitle:
      'Our Knowledge Resources - E-books, Webinars, Open Sources, Apps',
    navColors: ['white', 'mine-shaft'],
    siteContent:
      'We share our knowledge! Explore this page to learn more about topics like digital products, design and development, Agile, scrum and the New Normal.',
    siteCover: 'resources/resources-social-media-cover.png',
    items: {
      'app-costs-calculator': {
        footer: true,
        navColors: ['white', 'mine-shaft'],
        siteCover: 'app-costs-calculator/app-costs-calculator-cover.png',
        title: 'App Costs Calculator - Estimate App Development Costs',
        metaTitle: 'App Costs Calculator - Estimate App Development Costs',
        siteContent:
          'Do you want to build a new app or improve an existing one? Check out our app development cost calculator for a proposed budget range and timeframe.',
      },
      'app-costs-calculator-thank-you': {
        footer: true,
        navColors: ['white', 'mine-shaft'],
        siteCover: '',
        title: 'App Costs Calculator - Thank You',
        metaTitle: 'App Costs Calculator - Thank You',
        siteContent:
          'Do you want to build a new app or improve an existing one? Check out our app development cost calculator for a proposed budget range and timeframe.',
      },
      'product-discovery-workshops': {
        navColors: ['black'],
        metaTitle: '10 tools that help kick-start product development fast',
        siteContent: '10 tools that help kick-start product development fast',
        siteCover: 'app-costs-calculator/pdw-tools.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-01-05/2gw578',
        },
      },
      'full-cycle-product-development-quiz': {
        footer: false,
        navColors: ['white', 'mine-shaft', 'yellow'],
        siteCover: 'img/cover_full-cycle-product-development.png',
        title: 'Full Cycle Product Development Quiz',
        metaTitle: 'Full Cycle Product Development Quiz',
      },
      'development-partner-guide': {
        navColors: ['black'],
        metaTitle: 'Development Partner Guide',
        siteContent:
          'Download our ebook for answers to the most common challenges facing C-level Executives when choosing a digital product development partner.',
        siteCover: 'img/cover_resources_development-partner-guide.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2019-02-19/lnm',
          campaigns: {
            remarketing: 'https://go.boldare.com/l/688533/2020-01-23/4tyzl',
          },
        },
      },
      'product-market-fit-workbook': {
        navColors: ['black'],
        metaTitle: 'Product market fit workbook',
        siteContent:
          'Download link to workbook, answer 14 questions and check if your product is ready for PMF',
        siteCover: 'resources/boldare-workbook-downloaded-resource.png',
        formHandlers: {
          campaigns: {
            remarketing: 'https://go.boldare.com/l/688533/2022-12-14/2fqlff',
          },
        },
      },
      'development-partner-checklist': {
        navColors: ['black'],
        metaTitle: '33 things to ask your digital product development partner',
        siteContent:
          'An ultimate checklist to guide you through the web development partner interviews covering all key areas of collaboration. Find answers to help you make the right decision.',
        siteCover: 'img/cover_resources_development-partner-checklist.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2019-03-20/36c9',
          campaigns: {
            'your-development-partner':
              'https://go.boldare.com/l/688533/2020-01-09/4jv86',
            remarketing: 'https://go.boldare.com/l/688533/2020-01-23/4tylv',
          },
        },
      },
      'web-mobile-apps-prototyping': {
        navColors: ['black'],
        metaTitle:
          'Do you know how to use prototyping to validate your app’s business idea?',
        siteContent:
          'Download our free ebook and learn how to use design and business knowledge to validate your business idea using web and mobile apps prototyping.',
        siteCover: 'img/cover_resources_web-mobile-apps-prototyping.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2019-04-23/53sk',
          campaigns: {
            remarketing: 'https://go.boldare.com/l/688533/2020-01-23/4tyx4',
          },
        },
      },
      'machine-learning-for-business': {
        navColors: ['black'],
        metaTitle: 'Do you know how machine learning can boost your business?',
        siteContent:
          'Download our free ebook and learn how you can use machine learning n real-life cases, how much it can cost and what are limitations of ML.',
        siteCover: 'img/cover_resources_machine-learning-for-business.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2019-04-23/53sm',
          campaigns: {
            'machine-learning-content':
              'https://go.boldare.com/l/688533/2020-01-09/4jtqj',
            remarketing: 'https://go.boldare.com/l/688533/2020-01-23/4tyr6',
          },
        },
      },
      'ultimate-scrum-master-toolkit': {
        navColors: ['black'],
        metaTitle: 'The Ultimate Scrum Master Toolkit" - get your ebook',
        siteContent:
          'Download our free ebook to learn how to become the ace of Scrum and reach business goals with your teams and clients while building digital products!',
        siteCover: 'img/cover_resources_ultimate-scrum-master-toolkit.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2019-07-25/q3m2',
          campaigns: {
            remarketing: 'https://go.boldare.com/l/688533/2020-01-23/4tz8n',
          },
        },
      },
      'outsourcing-step-by-step': {
        navColors: ['black'],
        metaTitle: 'Want to know more about software outsourcing?',
        siteContent:
          'Then sign up for our free email program to learn more about the challenges and obstacles of software development outsourcing.',
        siteCover: 'img/cover_outsourcing-step-by-step.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-01-15/4ns3j',
          campaigns: {
            'agile-scrum-content':
              'https://go.boldare.com/l/688533/2020-01-29/4yjxs',
          },
        },
      },
      'remote-work-pdf': {
        navColors: ['black'],
        metaTitle:
          'Remote product development: tips and tricks to keep it efficient',
        siteContent:
          'Download our free PDF to learn how to keep remote collaboration efficient!',
        siteCover: 'img/cover_ebook-remote-work-pdf.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-03-30/5fv94',
        },
      },
      'product-designer-checklist': {
        navColors: ['black'],
        metaTitle: '36 things to ask your product design partner',
        siteContent: `Take advantage of our ${companyData.years_experience} years of experience and download an ultimate checklist for interviewing product design companies.`,
        siteCover: 'img/cover_product-designer-checklist.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-04-24/5tsq8',
          campaigns: {
            'case-studies-content':
              'https://go.boldare.com/l/688533/2020-04-27/5wkrn',
          },
        },
      },
      'code-audit-checklist': {
        navColors: ['black'],
        metaTitle: '23 things to ask a code and UX audit company: a checklist',
        siteContent: `Take advantage of our ${companyData.years_experience} years of experience and download an ultimate interviewing checklist for companies conducting a business-centric code and UX audit.`,
        siteCover: 'img/cover_audit-ux-checklist.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-07-20/8t5l7',
        },
      },
      'crash-course': {
        navColors: ['black'],
        metaTitle: 'Crash Course: managing risks, web products and dev teams',
        siteContent:
          'Discover a three-part crash course on how to design web products and service strategies, develop risk management tactics, and manage distributed dev teams',
        siteCover: 'img/crash_course_social_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-05-28/6rvcf',
        },
      },
      'what-after-mvp-checklist': {
        navColors: ['black'],
        metaTitle:
          'What next after MVP? - a practical guide for CEOs and CTOs',
        siteContent: `Download a practical guide for CEOs and CTOs in the form of a checklist, which helps determine if your application is ready to grow beyond MVP.`,
        siteCover:
          'gatsby-cloudinary/What_after_mvp_checklist_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-10-07/ckmpd',
        },
      },
      'new-normal-checklist': {
        navColors: ['black'],
        metaTitle:
          'Prepare your business for the New Normal - the checklist for CEOs',
        siteContent: `Download a checklist for CEOs which helps you answer the question: is your company ready for the New Normal? Check your leadership, organization, processes and tools.`,
        siteCover:
          'gatsby-cloudinary/new_normal_checklist_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-10-15/ct5tx',
        },
      },
      'product-owner-checklist': {
        navColors: ['black'],
        metaTitle: '24 Tools & Tips for remote collaboration - a checklist',
        siteContent: `Download our free checklist and discover the tools and best practices to use when working with remote, external product development teams.`,
        siteCover:
          'gatsby-cloudinary/product_owner_checklist_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2021-02-16/lrqdv',
        },
      },
      'product-discovery-handbook': {
        navColors: ['black'],
        metaTitle:
          'Discover your best digital product idea: Product Discovery Handbook',
        siteContent: `Ready to improve your digital product concept? Download your self-guide handbook with 22 crucial questions to understand your idea's market potential.`,
        siteCover:
          'resources/Handbook_Discover_Your_Best_Digital_Product_Idea_-_whole_tablet.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-11-07/2lltl1',
        },
      },
      'product-strategy-workbook': {
        navColors: ['black'],
        metaTitle: 'Learn How to Create a Winning Product Strategy Workbook',
        siteContent: `Unlock the secrets to building a successful product strategy with our easy-to-follow workbook, “How to create product strategy. Workbook” Perfect for anyone dealing with products daily. Dive deep into proven methodologies and techniques to craft compelling product strategies that drive success. Start your journey today!`,
        siteCover: 'resources/Cover_How_to_create_product_strategy.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2024-02-16/2lw65j',
        },
      },
      'how-to-implement-and-monetize-AI': {
        navColors: ['black'],
        metaTitle:
          'How to implement and monetize AI in your industry. Practical use cases.',
        siteContent: `Unlock the secrets to building a successful product strategy with our easy-to-follow workbook, “How to create product strategy. Workbook” Perfect for anyone dealing with products daily. Dive deep into proven methodologies and techniques to craft compelling product strategies that drive success. Start your journey today!`,
        siteCover: 'resources/Cover_How_to_implement_and_monetize_AI.png',
        formHandlers: {
          default: 'http://go.boldare.com/l/688533/2024-04-08/2m2wxj',
        },
      },
      'product-development-team-work': {
        navColors: ['black'],
        metaTitle:
          'Working with a product development team - a step by step guide',
        siteContent: `Download our free checklist and discover the tools and best practices to use when working with remote, external product development teams.`,
        siteCover:
          'gatsby-cloudinary/product_development_team_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-04-19/2kfk48',
        },
      },
      'product-development-challenges': {
        navColors: ['black'],
        metaTitle: '6 Most Common Product Development Challenges',
        siteContent: `Download our free checklist and discover the tools and best practices to use when working with remote, external product development teams.`,
        siteCover:
          'gatsby-cloudinary/six_product_challenges_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-04-19/2kfjcr',
        },
      },
      'product-discovery-workshop': {
        navColors: ['black'],
        metaTitle:
          'Product Discovery Workshop - the key to your product’s success',
        siteContent: `Product discovery workshop is exactly what can help us make sure we’re building the right product. At the end of the day, what is the point of delivering the product if it’s not used by your users?`,
        siteCover: 'resources/product-discovery-workshop-cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-08-29/2ld2q1',
        },
      },
      'pmf-survey': {
        navColors: ['black', 'white', 'mine-shaft'],
        title: 'Product-Market Fit Survey - is your product ready?',
        metaTitle: 'Product-Market Fit Survey - is your product ready?',
        footer: true,
      },
    },
  },
  'prototyping-digital-products': {
    footer: true,
    navColors: ['black', 'light-blue'],
    siteCover: 'img/cover_full-cycle-product-development_prototyping_.jpg',
    title: 'APP Prototyping - Mobile & Web App Prototype',
    metaTitle: 'APP Prototyping - Mobile & Web App Prototype',
    siteContent:
      'We can help you with the creation of a digital prototype to challenge your business idea, pitch investors or build a brand new app.',
  },
  webinar: {
    items: {
      'code-audit': {
        footer: true,
        navColors: ['black'],
        metaTitle:
          'What next after a code audit? Pre-recorded webinar for CTOs and CEOs',
        siteContent:
          'This pre-recorded webinar is designed to help CTOs and CEOs to maximize the results of a code audit and focuses on business-centered approach of auditing web and mobile apps.',
        siteCover:
          'img/webinar-code-audit/LP_Prerecored_webinar_social_media_cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-08-04/9b7lw',
        },
      },
      'accelerate-digitization-in-your-company': {
        footer: true,
        metaTitle: 'Webinar: Accelerate digitization in your company',
        siteContent:
          'A recorded video of a webinar with Michael Konder, Vice President of Digital Solutions at Sonnen discussing how to boost your business results in 2021.',
        siteCover:
          'img/webinar-sonnen/accelerate-digitization-in-your-company-cover.jpg',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2020-12-17/fypr5',
        },
      },
      'agile-for-your-company': {
        footer: true,
        metaTitle:
          'Webinar: Agile for your company. How to implement successfully',
        siteContent:
          'Get access to a webinar video during which our experts discussed agile transformation case studies based on two perspectives: agile practitioners and agile coaches.',
        siteCover: 'img/webinar-agile/agile-for-your-company-cover.png',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2021-03-30/p6vm8',
        },
      },
    },
  },
  '404': {
    footer: true,
    navColors: ['black', 'white'],
  },
  'your-awwwesome-designers': {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    siteCover: 'img/awwwards_cover.png',
    metaTitle: 'UX/UI Design Services Company',
    siteContent:
      'See why our design teams are so spectacularly good and how our unique UX / UI design process helps us to create outstanding products.',
  },
  'new-normal': {
    footer: true,
    navColors: ['mine-shaft', 'white'],
    siteCover: 'img/new-normal/new-normal-page-cover.png',
    title: 'New Normal - We Help you Transition',
    metaTitle: 'New Normal - We Help you Transition',
    siteContent:
      'In the post-COVID-19 time, the businesses and work itself need to adjust to the new reality. Learn in practice how you can prepare your company for a new normal.',
  },
  'renewable-energy-industry': {
    footer: true,
    navColors: ['mine-shaft', 'white'],
    siteCover:
      'img/renewable-energy-industry/renewable-energy-industry-cover.jpg',
    title: 'Boldare – the energy companies’ development partner',
    metaTitle: 'Boldare – the energy companies’ development partner',
    siteContent:
      'We help renewable energy companies to build the ROI-improving web and mobile apps their users want.',
  },
  'rescue-failing-project': {
    footer: true,
    navColors: ['mine-shaft', 'white'],
  },
  'finding-pmf-amsterdam': {
    navColors: ['white', 'mine-shaft', 'red'],
    metaTitle:
      'How to find new users & revenue streams for your digital product? | Finding PMF',
    siteContent: `Meetup Recording and Materials | Amsterdam 2022`,
    siteCover: 'event/finding-pmf-cover.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2022-11-10/2d65ft',
    },
  },
  'event-zaslepka': {
    navColors: ['white', 'mine-shaft', 'red'],
    metaTitle:
      'How to find new users & revenue streams for your digital product?',
    siteContent: `Join us live in Amsterdam or online worldwide | 14.12.2022 (Wednesday) | 7 pm CET  | Reserve your spot!`,
    siteCover: 'event/finding-pmf-cover.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2022-11-10/2d65ft',
    },
  },
  'finding-pmf': {
    navColors: ['white', 'mine-shaft', 'red'],
    metaTitle: 'Finding Product-Market Fit: Berlin',
    siteContent:
      'Tools for maximizing your app market potential | 28.06 | live meetup | talks, workshops & networking',
    siteCover: 'event/berlin/mini-cover-berlin.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-01-25/2hdq1v',
    },
    items: {
      'workshop-confirmation-form': {
        footer: false,
        navColors: ['white', 'mine-shaft'],
        siteCover: 'event/berlin/mini-cover-berlin.png',
        title: 'Finding Product-Market Fit: Berlin',
        metaTitle: 'Finding Product-Market Fit: Berlin',
        siteContent:
          'Tools for maximizing your app market potential | 28.06 | live meetup | talks, workshops & networking',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-06-05/2l51yx',
        },
      },
    },
  },
  'when-to-invest-in-ux-webinar': {
    navColors: ['black', 'white', 'red'],
    metaTitle:
      'Making Informed Decisions: When to Invest in UX for Business Growth.',
    siteContent:
      'UX ROI Unveiled. Practical Insights of UX on user engagement and overall business success. | 09.01.2024 | live webinar | talks & workshops',
    siteCover: 'discussion-event/cover.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2024-02-15/2lw2f4',
    },
  },
  'cookies-test': {
    navColors: ['white', 'mine-shaft', 'red'],
    metaTitle: 'Cookies test',
    siteContent: `Test cookies with us`,
    siteCover: 'event/finding-pmf-cover.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2022-11-10/2d65ft',
    },
  },
  'event-fpmf': {
    footer: true,
    navColors: ['white', 'mine-shaft', 'red'],
    metaTitle:
      'How to find new users & revenue streams for your digital product?',
    siteContent: `Join us live in Amsterdam or online worldwide | 14.12.2022 (Wednesday) | 7 pm CET  | Reserve your spot!`,
    siteCover: 'event/finding-pmf-cover.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2022-11-10/2d65ft',
    },
  },
  assets: {
    footer: true,
    navColors: ['black', 'mine-shaft'],
  },
  default: {
    footer: true,
    compactFooter: false,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Boldare - digital product creators and consultants',
    siteContent: config.siteDescription,
    siteCover: config.siteCover,
    isDefault: true,
  },
  ctoask: {
    footerCto: true,
    navColors: ['white', 'mine-shaft', 'violet'],
    metaTitle: config.siteTitle,
    siteContent: config.siteDescription,
    siteCover: 'img/cover_cto-asks.png',
  },
  'digital-transformation-journey': {
    footer: true,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Your guide on the digital transformation journey',
    siteContent: ``,
    siteCover: '',
  },
  'agile-product-builders': {
    footer: false,
    navColors: ['black', 'white', 'violet'],
    metaTitle: 'Agile Product Builders Community',
    siteContent: ``,
    siteCover: '',
  },
  'recommend-a-client': {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    metaTitle: 'Recommend a client',
    siteContent:
      'Earn a bonus for every successful recommendation of a new client you bring to us.',
    siteCover: '',
  },
  'book-a-discovery-session': {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    metaTitle: 'Book a discovery session',
    siteContent:
      'Validate your business idea with Boldare — top software development & consulting partner.',
    siteCover: '',
  },
  'bring-a-qualified-lead': {
    footer: true,
    navColors: ['white', 'mine-shaft'],
    metaTitle: 'Bring a qualified lead',
    siteContent: 'Bring us a qualified lead and get €2200',
    siteCover: '',
  },
  'ux-ui-design-consulting-services': {
    footer: true,
    navColors: ['black', 'pigeon-post'],
    metaTitle: 'UX/UI Design that Delivers Results',
    siteContent: `Discover how Boldare's designs achieve exceptional outcomes, innovation, and engaging narratives through our design services. Explore our award-winning portfolio and experience design that resonates with users' and business needs.`,
    siteCover: '',
  },
  'service-standards': {
    footer: true,
    navColors: ['black', 'white'],
    metaTitle: 'Our Service Standards',
    siteContent: 'Turning complexity into simplicity',
  },
  'investor-relations': {
    footer: true,
    navColors: ['mine-shaft'],
    metaTitle: 'Investor relations',
    siteContent: 'Investor relations',
    siteCover: '',
  },
  'transform-gaps-into-opportunities-workshop': {
    footer: false,
    navColors: ['black'],
    siteCover: 'img/workshops_cover.png',
    title:
      'Transform gaps into opportunities: optimize your business outcomes using service blueprints',
    metaTitle:
      'Transform gaps into opportunities: optimize your business outcomes using service blueprints',
    siteContent: 'Boldare in-person workshop in Berlin',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-09-07/2ldthq',
    },
    button: {
      text: 'Book your slot',
      color: 'yellow',
    },
    items: {
      'event-confirmation-form': {
        footer: false,
        navColors: ['white', 'mine-shaft'],
        siteCover: 'img/workshops_cover.png',
        title:
          'Transform gaps into opportunities: optimize your business outcomes using service blueprints',
        metaTitle:
          'Transform gaps into opportunities: optimize your business outcomes using service blueprints',
        siteContent: 'Boldare in-person workshop in Berlin',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-06-05/2l51yx',
        },
      },
    },
  },
  'bold-growth-meetup': {
    footer: false,
    navColors: ['black'],
    title: 'Bold growth meetup',
    metaTitle:
      "Bold growth meetup: Accelerate your business's growth with product strategy and user-centric design",
    siteContent:
      "Accelerate your business's growth with product strategy and user-centric design",
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-09-14/2lfgx1',
    },
    button: {
      text: 'Book your slot',
      color: 'yellow',
    },
    items: {
      'event-confirmation-form': {
        footer: false,
        navColors: ['white', 'mine-shaft'],
        siteCover: 'img/workshops_cover.png',
        title: 'Bold growth meetup',
        metaTitle:
          "Bold growth meetup: Accelerate your business's growth with product strategy and user-centric design",
        siteContent:
          "Accelerate your business's growth with product strategy and user-centric design",
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-06-05/2l51yx',
        },
      },
    },
  },
  'practical-ai-application': {
    footer: false,
    navColors: ['black'],
    siteCover: 'img/ai-webinar_cover.png',
    title: 'Practical application of AI in materials trading​ industry',
    metaTitle: 'Practical application of AI in materials trading​ industry',
    siteContent: 'Practical application of AI in materials trading​ industry',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-09-28/2lgtst',
    },
    button: {
      text: 'Watch on demand',
      color: 'yellow',
    },
  },
  'webinar-improve-dev-team-communication': {
    footer: false,
    navColors: ['black'],
    siteCover: 'img/biz-dev.png',
    title:
      'Less coding, more talking: Save money by investing in communication',
    metaTitle:
      'Less coding, more talking: Save money by investing in communication',
    siteContent: '',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-10-19/2lk1bj',
    },
    button: {
      text: 'Register',
      color: 'yellow',
    },
  },
  'scale-your-AI-solution-meetup': {
    footer: false,
    navColors: ['black'],
    title: 'Scale Your AI Solution Faster with Rapid Prototyping',
    metaTitle:
      'Business breakfast and lighting talk | 23.11 | Powered by Boldare & K.I.E.Z',
    siteContent:
      'Business breakfast and lighting talk | 23.11 | Powered by Boldare & K.I.E.Z',
    siteCover: 'event/KIEZ_LP_cover_2.png',
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2023-11-03/2lljrq',
    },
    button: {
      text: 'Sign up for the recording',
      color: 'yellow',
    },
    items: {
      'event-confirmation-form': {
        footer: false,
        navColors: ['white', 'mine-shaft'],
        siteCover: 'event/KIEZ_LP_cover_2.png',
        title: 'Scale Your AI Solution Faster with Rapid Prototyping',
        metaTitle:
          'Business breakfast and lighting talk | 23.11 | Powered by Boldare & K.I.E.Z',
        siteContent:
          'Business breakfast and lighting talk | 23.11 | Powered by Boldare & K.I.E.Z',
        formHandlers: {
          default: 'https://go.boldare.com/l/688533/2023-11-03/2lljrq',
        },
      },
    },
  },
  'apb-community-newsletter': {
    footer: false,
    navColors: ['black'],
    siteCover: 'apb-community/Header.png',
    title: 'Agile Product Builders Community Newsletter',
    metaTitle: 'Agile Product Builders Community Newsletter',
    siteContent:
      "Sign Up for Boldare's Agile Product Builders Community & Newsletter for expert insights and practical advice. Elevate your digital products with our exclusive, monthly curated content. Connect, discuss and build together.",
    formHandlers: {
      default: 'https://go.boldare.com/l/688533/2024-01-28/2ltdd5',
    },
    button: {
      text: 'Sign up',
      color: 'black',
    },
  },
  'referral-program-regulations': {
    footer: false,
    navColors: ['black'],
    siteCover: '',
    title: 'Referral program regulations',
  },
};
