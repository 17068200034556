import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

import './Tile.scss';

function Tile({ id, title, desc, button, link, color, image }) {
  return (
    <a href={link} target={'_blank'} rel={'noreferrer'}>
      <div className={`tiles-section-tile tile-different__item--${color}`}>
        <div
          className={`tiles-section-tile__slider tiles-section-tile__slider--${id}`}
        />
        <img
          src={getCloudinaryUrl(image, 'f_auto,q_auto')}
          className={`tiles-section-tile__image tiles-section-tile__image--${id}`}
          alt={title}
          loading={'lazy'}
        />
        <h1 className={'tiles-section-tile__header'}>{title}</h1>
        <p className={'tiles-section-tile__desc'}>{desc}</p>
        <a
          className={'tiles-section-tile__button'}
          href={link}
          rel={'nofollow'}
        >
          <Button order={'secondary'} color={'white'} ariaName={'Box link'}>
            {button}
          </Button>
        </a>
      </div>
    </a>
  );
}

Tile.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  button: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
};

export default Tile;
