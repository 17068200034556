import React, { Component } from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Features.scss';

class Features extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.oneida__features-tiles').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = () =>
    data.map(item => (
      <div
        className={classNames('oneida__features-box', {
          'oneida__features-box--entered': this.state.entered,
        })}
        key={`oneida__features-${item.zoom}`}
      >
        <div className={'oneida__features-column anim-fade-in-left'}>
          <Heading type={3} size={'big'} color={'mine-shaft'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
        <div className={'oneida__features-column'}>
          <FullwidthImage
            image={getCloudinaryUrl(
              `assets/oneida/${item.image}.png`,
              'f_auto,q_auto',
            )}
            alt={'Device'}
            additionalClass={'oneida__features-image anim-fade-in-right'}
          />
          <img
            src={getCloudinaryUrl(
              `assets/oneida/${item.zoom}.png`,
              'f_auto,q_auto',
            )}
            alt={'Device'}
            className={'image oneida__features-zoom anim-scale-out-in-center'}
            loading={'lazy'}
          />
        </div>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'oneida__features-container'}>
        <div className={'oneida__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Special features
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            Tailor-made functionality
            <br />
            for a target user
          </Heading>
        </div>
        <div className={'oneida__features-tiles'}>{this.renderBoxes()}</div>
      </Section>
    );
  }
}

export default Features;
