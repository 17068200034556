import './index.scss';
import React from 'react';
import { ReactComponent as PatternMobile } from './pattern_mobile.svg';
import { ReactComponent as PatternDesktop } from './pattern_desktop.svg';

const Intro = () => {
  return (
    <>
      <div className={'bldr-dsgn__section hero-description bldr-dsgn__row'}>
        <div className={'bldr-dsgn__border-box bldr-dsgn__description-image'}>
          <div className={'bldr-dsgn__description-image--desktop'}>
            <PatternDesktop />
          </div>
          <div className={'bldr-dsgn__description-image--mobile'}>
            <PatternMobile />
          </div>
        </div>
        <div
          className={
            'bldr-dsgn__border-box bldr-dsgn__intro vertically-centered'
          }
        >
          Design gives life to digital products. It helps users follow your
          story. Step by step, it reveals your goals and main characters. With
          our designers, we’ll highlight what matters in your tale. With us,
          your story will be told and remembered.
        </div>
      </div>
      <div className={'intro-text dsgn--full-screen'}>
        <p>
          Some of the stories we have told so far
        </p>
      </div>
    </>
  );
};

export default Intro;
