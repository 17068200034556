import React from 'react';
import PMFSurvey from 'views/pmfSurvey';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const surveyPage = pages.resources.items['pmf-survey'];
const { navColors } = surveyPage;
const url = `${config.siteUrl}/resources/pmf-survey/`;
const canonical = `${url}`;

const PMFSurveyPage = () => {
  const page = {
    ...surveyPage,
  };

  return (
    <Layout {...{ page, canonical }}>
      <NavBar additionalClass={'navbar--light'} color={navColors} />
      <PMFSurvey />
    </Layout>
  );
};

export default PMFSurveyPage;
