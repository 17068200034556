import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import './Step.scss';

const Step = ({ number, step, details }) => (
  <div className={'talent-pool-process__banner-step'} key={number}>
    <span className={'talent-pool-process__banner-step-number'}>{number}</span>
    <Heading
      additionalClass={'talent-pool-process__banner-step-heading'}
      size={'medium'}
      type={3}
    >
      {step}
    </Heading>
    <div
      className={'talent-pool-process__banner-step-details'}
      dangerouslySetInnerHTML={{ __html: details }}
    />
  </div>
);

Step.defaultProps = {
  number: '',
  step: '',
  details: '',
};

Step.propTypes = {
  number: PropTypes.string,
  step: PropTypes.string,
  details: PropTypes.string,
};

export default Step;
