import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { pushToDataLayer } from 'utils';
import Weight from '../Weight';
import './SectionWeight.scss';

const SectionWeight = ({ isDesktop, variant }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const isWindow = typeof window !== 'undefined';
      let wasWeightSectionSeen;

      if (isWindow) {
        wasWeightSectionSeen = window.sessionStorage.getItem(
          'wasWeightSectionSeen',
        );

        if (!wasWeightSectionSeen) {
          window.sessionStorage.setItem('wasWeightSectionSeen', true);
          pushToDataLayer(`${variant}-section-weight`);
        }
      }
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`section-wrapper section-weight ${
        isDesktop ? 'pinned-section' : ''
      }`}
    >
      <section className={'grid-layout'}>
        {variant === 'about-us-a' && (
          <>
            <p className={'about-header'}>
              Unlike others, we've invested as much in a future-proof delivery{' '}
              <span className={'marker-highlight marker-highlight-a'}>
                environment
              </span>{' '}
              as we have in building our business & services
            </p>
            <p className={'about-text'}>
              With a combined focus on both we are able to win big, and drive
              six-figure results for our clients.
            </p>
          </>
        )}
        {variant === 'about-us-b' && (
          <p className={'about-header'}>
            We have <span className={'marker-highlight'}>devoted</span> most of
            our strategic focus to building an environment that can fuel the
            change in your business
          </p>
        )}
      </section>
      <Weight className={'section-weight__weight'} />
    </div>
  );
};

export default SectionWeight;

SectionWeight.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
