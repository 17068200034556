export const data = [
  {
    name: 'Fastr',
    image: 'fastr_logo.png',
    width: 'h_47',
  },
  {
    name: 'Youcat',
    image: 'youcat_logo.png',
    black: true,
    width: 'h_47',
  },
  {
    name: 'Nexit',
    image: 'nexit_logo-color.jpg',
    width: 'h_47',
  },
  {
    name: 'LegalClose',
    image: 'legalclose_logo.png',
    black: true,
    width: 'h_47',
  },
];
