import React from 'react';
import { ReactComponent as Point2 } from './point-2.svg';
import { ReactComponent as Point3 } from './point-3.svg';
import { ReactComponent as TakamolBg } from './takamol-case.svg';
import { ReactComponent as Dribbble } from './dribbble_icon.svg';
import { ReactComponent as Behance } from './behance_icon.svg';
import { ReactComponent as ArrowTopRight } from '../../assets/icons/arrow_top_right.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';
import './index.scss';

const CasesSequel = () => {
  return (
    <>
      <div
        className={
          'dsgn__cases-seq bldr-dsgn__section--border dsgn--full-screen'
        }
      >
        <div className={'bldr-dsgn__section'}>
          <div
            className={
              'dsgn-cases-seq__section-content dsgn-cases-seq__section-content--step'
            }
          >
            <div className={'dsgn-cases-seq__steps'}>
              <div className={'dsgn-cases-seq__steps-point'}>
                <Point2 />
              </div>
              <div className={'dsgn-cases-seq__steps-description'}>
                <h4>Top-notch UI for tangible results</h4>
                <p>
                  Thanks to well-designed interfaces, users can easily solve
                  their problems. A UI that caters to their needs may go
                  unnoticed by them but significantly supports the business and
                  product goals of the application. Here are some examples of
                  how we do it.
                </p>
              </div>
            </div>
            <div className={'dsgn-cases-seq__case'}>
              <div className={'dsgn-cases-seq__case-content'}>
                <TakamolBg />
              </div>
              <div className={'dsgn-cases-seq__case-description'}>
                <div className={'dsgn-cases-seq__case-description-content'}>
                  <p>
                    We implemented a design system for Takamol to standardize
                    design elements, enhancing efficiency in product
                    development and reducing repetitive work for developers and
                    designers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={'dsgn-cases-seq__section-content'}>
            <div className={'dsgn-cases-seq__case'}>
              <div className={'dsgn-cases-seq__case-content'}>
                <img
                  src={
                    'https://res.cloudinary.com/de4rvmslk/image/upload/v1699526596/bdbb/sonnen-case.jpg'
                  }
                  alt={'Sonnen Charger'}
                />
              </div>
              <div
                className={
                  'dsgn-cases-seq__case-description dsgn-cases-seq__case-description--right'
                }
              >
                <div className={'dsgn-cases-seq__case-description-content'}>
                  <p>
                    The SonnenCharger app enables Sonnen&apos;s individual
                    customers to manage their electric vehicle charging. We
                    developed the MVP in 3 months, which involved building a
                    prototype, conducting tests with users at an industry
                    event, and designing the UX & UI.
                  </p>
                </div>
                <div className={'dsgn-cases-seq__case-description-cta'}>
                  <a
                    href={
                      'https://www.behance.net/gallery/116380521/Sonnen-Charger'
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    See more <ArrowTopRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'dsgn-cases-seq__section-content dsgn-cases-seq__section-content--right'
            }
          >
            <div className={'dsgn-cases-seq__case'}>
              <div className={'dsgn-cases-seq__case-content'}>
                <img
                  src={
                    'https://res.cloudinary.com/de4rvmslk/image/upload/v1700129462/bdbb/Youcat-cover.jpg'
                  }
                  alt={'YOUCAT'}
                />
              </div>
              <div className={'dsgn-cases-seq__case-description'}>
                <div className={'dsgn-cases-seq__case-description-content'}>
                  <p>
                    Over two years, we refined the app to increase new user
                    sign-ups and encourage repeat usage, leveraging
                    Build-Measure-Learn cycles to integrate user feedback into
                    feature development. This approach has resulted in
                    favorable user feedback and ratings.
                  </p>
                </div>
                <div className={'dsgn-cases-seq__case-description-cta'}>
                  <a
                    href={
                      'https://www.boldare.com/work/case-study-youcat-daily-app/'
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    See more <ArrowTopRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'bldr-dsgn__section bldr-dsgn__section--designs'}>
        <div
          className={
            'dsgn-cases-seq__section-content dsgn-cases-seq__section-content--step'
          }
        >
          <div className={'dsgn-cases-seq__steps'}>
            <div className={'dsgn-cases-seq__steps-point'}>
              <Point3 />
            </div>
            <div className={'dsgn-cases-seq__steps-description'}>
              <h4>Award-winning designs</h4>
              <p>
                Above all, everyone loves beautiful designs, and your clients
                are no exception. Whether it&apos;s your corporate website or a
                marketing campaign for a brand new product, we know how to
                captivate your audience. Take a look at our award-winning
                products and see for yourself.
              </p>
            </div>
          </div>
          <div
            className={
              'dsgn-cases-seq__case dsgn-cases-seq__case--no-margin-top'
            }
          >
            <div className={'dsgn-cases-seq__case-content'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1699526949/bdbb/very-case.png'
                }
                alt={'Very Peri'}
              />
            </div>
            <div
              className={
                'dsgn-cases-seq__case-description dsgn-cases-seq__case-description--projects'
              }
            >
              <div
                className={
                  'dsgn-cases-seq__case-description-content dsgn-cases-seq__case-description-content--nourl'
                }
              >
                <p>
                  Let yourself be swept away by the purple madness and see why
                  we fell in love with Very Peri, Pantone&apos;s Color of the
                  Year. Explore how to experience it beyond sight, including
                  through sound, scent, and touch.
                </p>
              </div>
              <div className={'dsgn-cases-seq__case-description-cta'}>
                <a
                  href={'https://veryperi.me/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  See more <ArrowTopRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={'dsgn-cases-seq__section-content'}>
          <div
            className={
              'dsgn-cases-seq__case dsgn-cases-seq__case--no-margin-top'
            }
          >
            <div className={'dsgn-cases-seq__case-content'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1699526948/bdbb/akzidenz-case.png'
                }
                alt={'Akzidenz Grotesk'}
              />
            </div>
            <div
              className={
                'dsgn-cases-seq__case-description dsgn-cases-seq__case-description--projects'
              }
            >
              <div
                className={
                  'dsgn-cases-seq__case-description-content dsgn-cases-seq__case-description-content--nourl'
                }
              >
                <p>
                  Our homage to Akzidenz-Grotesk, one of the most influential
                  typefaces in history. Discover its origins, creators, and its
                  notable applications through the years.
                </p>
              </div>
              <div className={'dsgn-cases-seq__case-description-cta'}>
                <a
                  href={'https://akzidenzgrotesk.pro/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  See more <ArrowTopRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'dsgn-cases-seq__section-content dsgn-cases-seq__section-content--right'
          }
        >
          <div
            className={
              'dsgn-cases-seq__case dsgn-cases-seq__case--no-margin-top'
            }
          >
            <div className={'dsgn-cases-seq__case-content'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1699526948/bdbb/boards-case.png'
                }
                alt={'Boldare Boards'}
              />
            </div>
            <div
              className={
                'dsgn-cases-seq__case-description dsgn-cases-seq__case-description--projects'
              }
            >
              <div
                className={
                  'dsgn-cases-seq__case-description-content dsgn-cases-seq__case-description-content--nourl'
                }
              >
                <p>
                  Here&apos;s something to play with: a landing page for our
                  collaboration app, designed for remote teams to engage in
                  sprint retrospectives, brainstorms, workshops, and various
                  other online meetings.
                </p>
              </div>
              <div className={'dsgn-cases-seq__case-description-cta'}>
                <a
                  href={'https://boards.boldare.com/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  See more <ArrowTopRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'dsgn-cases-seq__section-content dsgn-cases-seq__section-content--right'
          }
        >
          <div className={'dsgn-cases-seq__case'}>
            <div className={'dsgn-cases-seq__case-content'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1699526949/bdbb/conceptualizm-case.png'
                }
                alt={'History of Collage'}
              />
            </div>
            <div className={'dsgn-cases-seq__case-description'}>
              <div
                className={
                  'dsgn-cases-seq__case-description-content dsgn-cases-seq__case-description-content--nourl'
                }
              >
                <p>
                  Dive into the history of collage, a cherished art form that
                  combines diverse graphic styles. Use the page to craft your
                  very own collage.
                </p>
              </div>
              <div className={'dsgn-cases-seq__case-description-cta'}>
                <a
                  href={'https://historyofcollage.site/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  See more <ArrowTopRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'bldr-dsgn__section'}>
        <div className={'dsgn-cases-seq__more'}>
          <div className={'main'}>
            <a href={'https://www.boldare.com/work/'}>
              View other success stories <ArrowRight />
            </a>
          </div>
          <div className={'double'}>
            <div className={'secondary dribbble'}>
              <a
                href={'https://dribbble.com/Boldare'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <span>
                  <Dribbble />
                </span>
                <span>
                  Dribbble <ArrowTopRight />
                </span>
              </a>
            </div>
            <div className={'secondary behance'}>
              <a
                href={'https://www.behance.net/boldarecom'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <span>
                  <Behance />
                </span>
                <span>
                  Behance <ArrowTopRight />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CasesSequel;
