import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const AwardBox = ({ name, number, logo, color, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      className={'bldr-dsgn__award-box'}
      animate={
        inView
          ? { scale: 1 }
          : {
              scale: 0,
            }
      }
      transition={{ stiffness: 260, damping: 20 }}
    >
      <img src={logo} alt={name} className={className} />
      <div
        className={`bldr-dsgn__semicircle bldr-dsgn__semicircle--${color}`}
      >
        x{number}
      </div>
      <div className={'bldr-dsgn__award-box--border'} />
    </motion.div>
  );
};

AwardBox.defaultProps = {
  className: '',
  color: 'blue',
};

AwardBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default AwardBox;
