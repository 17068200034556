import React from 'react';
import { Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { imagesData } from './logic';
import './Technology.scss';

const Technology = () => {
  const renderImages = () =>
    imagesData.map(image => (
      <div key={`logo-${image.name}`} className={'career-technology__icon'}>
        <img
          src={getCloudinaryUrl(image.img, 'f_auto,q_auto')}
          alt={image.alt}
          style={{ objectFit: 'contain' }}
          className={'image'}
          loading={'lazy'}
        />
      </div>
    ));

  return (
    <Section color={'seashell'} additionalClass={'career-technology'}>
      <div className={'career-technology__icons'}>{renderImages()}</div>
    </Section>
  );
};

export default Technology;
