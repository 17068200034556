export const data = [
  {
    number: 60,
    prefix: '+',
    suffix: '%',
    text: 'registered models',
  },
  {
    number: 688,
    text: 'models in the database',
  },
  {
    number: 56,
    text: 'actors in the database',
  },
  {
    number: 4807,
    text: 'new faces in the database',
  },
];
