import { CASE_STUDIES } from './CASE_STUDIES.js';
import { REVIEWS } from './REVIEWS.js';
import { BRIEFS } from './BRIEFS.js';
import { BLOGS } from './BLOGS.js';

export { CASE_STUDIES, REVIEWS, BRIEFS, BLOGS };
export const CASE_STUDIES_ENTRIES = [
  ...CASE_STUDIES,
  ...REVIEWS,
  ...BRIEFS,
  ...BLOGS,
];

// temporary - reviews, briefs, blogs would be moved to CMS
export const CASE_STUDIES_ITEMS = [...REVIEWS, ...BRIEFS, ...BLOGS];
