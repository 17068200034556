import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader } from 'components';
import companyData from '../../../../../meta/companyData';

const Banner = () => (
  <SubpageBanner
    backgroundColor={'gray-light'}
    image={getCloudinaryUrl(
      'gatsby-cloudinary/Boldare-office-room.jpg',
      'f_auto,q_auto,w_1400',
    )}
    contentBackground={'violet'}
    additionalClass={'fpdc__banner'}
    alt={'Workshops'}
  >
    <SectionHeader
      size={'large'}
      color={'white'}
      lineColor={'black'}
      description={
        'Boldare helps companies at each stage of the product development cycle: from ideating with a prototype, testing a product with an MVP, to finding a product-market fit and finally, scaling.'
      }
      hiddenDescription={`<br />Our agile product design and software development teams will advise on the best strategy, build the product, test it with users, improve it, and pass on to you all their know-how so you can grow sustainably.<br /><br />We have built over ${companyData.products} digital products for almost 90 public, non-profit and business organisations around the world. Our aim is always to take the product to market as quickly as possible and iteratively improve it until we achieve all business goals, fulfil market & user needs, and develop unique differentiators and features.`}
      descriptionColor={'white'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Full Cycle Product <br />
      Development
    </SectionHeader>
  </SubpageBanner>
);

export default Banner;
