import React from 'react';
import PropTypes from 'prop-types';
import './FeatureBanner.scss';
import { Col } from 'react-flexbox-grid';
import { Link, Paragraph, Heading, IconSVG, Button } from 'components';
import ReactHtmlParser from 'react-html-parser';
import { getCloudinaryUrl } from 'config/helpers';

const FeatureBanner = ({
  link,
  cover,
  title,
  time,
  badgeText,
  buttonColor,
  buttonText,
  buttonIcon,
}) => (
  <Col sm={12} md={6} lg={4}>
    <Link to={link}>
      <div
        className={
          'single-post-tile single-post-tile--black blogfeature-banner'
        }
      >
        <div className={'single-post-tile__top'}>
          <img
            src={getCloudinaryUrl(cover, 'f_auto,q_auto,h_300')}
            alt={badgeText}
            className={'image'}
            loading={'lazy'}
          />
        </div>
        <div className={'single-post-tile__bottom'}>
          <div
            className={'single-post-tile__info single-post-tile__info--black'}
          >
            <Paragraph
              size={'small'}
              color={'black'}
              additionalClass={'single-post-tile__category text--bold'}
            >
              {badgeText}
            </Paragraph>
            <Paragraph
              size={'small'}
              additionalClass={'single-post-tile__time'}
            >
              {time}
            </Paragraph>
          </div>
          <div className={'single-post-tile__content'}>
            <Heading type={3} size={'medium'}>
              {ReactHtmlParser(title)}
            </Heading>
          </div>
          <Button
            additionalClass={'blogfeature-banner__button'}
            color={buttonColor}
          >
            {buttonText}
            {buttonIcon && <IconSVG name={buttonIcon} size={'small'} />}
          </Button>
        </div>
      </div>
    </Link>
  </Col>
);

FeatureBanner.propTypes = {
  link: PropTypes.string,
  cover: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  badgeText: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
};

FeatureBanner.defaultProps = {
  link: '',
  cover: '',
  title: '',
  time: '',
  badgeText: '',
  buttonColor: '',
  buttonText: '',
  buttonIcon: '',
};

export default FeatureBanner;
