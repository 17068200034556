import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

import './Section.scss';

export const Section = ({ heading, color, id, children, noOverflow, backgroundImage }) => (
  <div
    className={classNames(`who-we-are-section who-we-are-section--${color}`, {
      'who-we-are-section--no-overflow': noOverflow
    })}
    >
    <div
      className={'who-we-are-section__content'}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
      }}
    >
      {heading}
      {children}
    </div>
  </div>
);

Section.defaultProps = {
  heading: null,
  color: 'white',
  noOverflow: false,
  backgroundImage: '',
};

Section.propTypes = {
  heading: PropTypes.node,
  color: PropTypes.string,
  noOverflow: PropTypes.bool,
  backgroundImage: PropTypes.string,
};
