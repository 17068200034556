import React from 'react';
import Arrow from '../Arrow';

const Arrows = ({ count = 4 }) => {
  const arrows = [];
  for (let i = 0; i < count; i++) {
    arrows.push(<Arrow key={i} className={'arrow'} />);
  }
  return arrows;
};

export default Arrows;
