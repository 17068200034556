import React from 'react';
import Layout from 'components/layout';
import { NavBar, FooterMini } from 'components';
import { pages } from 'config/pages';
import EventRegistrationPage from 'views/eventRegistrationPage';
import config from '../../meta/config';
import { events } from '../data/eventsData';

const page = pages['transform-gaps-into-opportunities-workshop'];
const canonical = `${config.siteUrl}/transform-gaps-into-opportunities-workshop/`;

const WorkshopConfirmationPage = () => {
  const { navColors, button, siteCover } = page;
  const formHandler = page.formHandlers.default;
  const eventData = events['transform-gaps-into-opportunities-workshop'];

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <EventRegistrationPage
        button={button}
        url={formHandler}
        eventData={eventData}
        cover={siteCover}
      />
      <FooterMini />
    </Layout>
  );
};

export default WorkshopConfirmationPage;
