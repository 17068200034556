import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import './DigitaleTransformationDe.scss';
import {
  Banner,
  About,
  Webinar,
  CaseStudies,
  ContactFormDe,
  Testimonials,
  Kontakt,
  Quality,
} from 'views/digitaleTransformationDe/subcomponents';

class DigitaleTransformationDe extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero connect-de-page'}>
        <Banner />
        <About />
        <Webinar />
        <CaseStudies />
        <Testimonials />
        <Kontakt />
        <Quality />
        <ContactFormDe />
      </div>
    );
  }
}

export default DigitaleTransformationDe;
