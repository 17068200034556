import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Dot } from '..';
import { SliderContext } from '../../utils';

import './Dots.scss';

const Dots = ({ index, setIndex }) => {
  const { slides } = useContext(SliderContext);

  return (
    <div className={'slider-dots'}>
      {slides.map(opinion => {
        return (
          <Dot
            key={opinion.id}
            handleClick={() => setIndex(opinion.index)}
            isActive={opinion.index === index}
          />
        );
      })}
    </div>
  );
};

Dots.defaultProps = {
  index: 0,
  setIndex: () => {},
};

Dots.propTypes = {
  index: PropTypes.number,
  setIndex: PropTypes.func,
};

export default Dots;
