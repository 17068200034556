import React from 'react';
import PropTypes from 'prop-types';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { Footer, SEO, FooterCto } from '.';
import 'styles/main.scss';

const environment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
const isProduction = environment.includes('production');

const Layout = ({ children, page, canonical, url, pageContext }) => {
  let pathBase = null;
  if (typeof window !== 'undefined' && window.location) {
    const { pathname } = window.location;
    pathBase = pathname.split('/')[1];
  }

  const seoCanonical = canonical || page || pages.default;

  const {
    footer,
    compactFooter,
    metaTitle,
    siteContent,
    siteCover,
    title,
    footerCto,
    isDefault,
  } = page || pages.default;
  return (
    <>
      {isProduction && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }

            // Set default consent status
            gtag("consent", "default", {
                ad_user_data: "denied",
                ad_personalization: "denied",
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2000
            });

            // Enable ads data redaction by default
            gtag("set", "ads_data_redaction", true);

            // Trigger GTM load event
            (function(w,d,s,l,i) {
                w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NJN9MVR');
          `,
          }}
          async
        />
      )}
      <div>
        <SEO
          title={title}
          metaTitle={metaTitle}
          description={siteContent}
          cover={getCloudinaryUrl(siteCover, 'f_auto,q_auto')}
          slug={pathBase}
          canonical={seoCanonical}
          url={url}
          ampUrl={pageContext?.ampUrl}
          cloudinary
          isDefault={isDefault}
        />
        <div>{children}</div>
        {footer && <Footer compact={compactFooter} />}
        {footerCto && <FooterCto />}
        {/* <CookieInfo /> */}
      </div>
    </>
  );
};

Layout.defaultProps = {
  page: '',
  pageContext: '',
  canonical: '',
  cloudinary: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.object,
  canonical: PropTypes.string,
  cloudinary: PropTypes.bool,
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Layout;
