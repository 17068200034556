import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { pushToDataLayer } from 'utils';

import './SingleListItem.scss';

const SingleListItem = ({ index, header, text, variant }) => {
  const [showMore, setShowMore] = useState(false);
  const firstPartMobile = text.slice(0, 100);
  const secondPartMobile = text.slice(100);
  const firstPartDesktop = text.slice(0, 180);
  const secondPartDesktop = text.slice(180);
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const handleClick = () => {
    pushToDataLayer(`${variant}-${header.replace(/\s+/g, '-').toLowerCase()}`);
    setShowMore(true);
  };

  return (
    <motion.li
      className={'single-list-item'}
      ref={ref}
      animate={
        inView
          ? { opacity: 1, y: '0' }
          : {
              opacity: 0,
              y: `50px`,
            }
      }
      transition={{ duration: 0.4, delay: `0.${index}` }}
    >
      <span className={'single-list-item__decoration-number'}>{index}</span>
      <h3 className={'single-list-item__heading'}>{header}</h3>
      <p className={'single-list-item__text single-list-item__text--mobile'}>
        {firstPartMobile}
        {!showMore && <>...</>}
        {!showMore && (
          <button onClick={handleClick} className={'single-list-item__button'}>
            Read more
          </button>
        )}
        {showMore && secondPartMobile}
      </p>
      <p className={'single-list-item__text single-list-item__text--tablet'}>
        {firstPartDesktop}
        {!showMore && <>...</>}
        {!showMore && (
          <button onClick={handleClick} className={'single-list-item__button'}>
            Read more
          </button>
        )}
        {showMore && secondPartDesktop}
      </p>
    </motion.li>
  );
};

SingleListItem.defaultProps = {
  index: 1,
  header: '',
  text: '',
  variant: 'competitive-advantages-a',
};

SingleListItem.propTypes = {
  index: PropTypes.number,
  header: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};

export default SingleListItem;
