import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Slider from 'react-slick';
import {
  Section,
  SectionHeader,
  Paragraph,
  Heading,
  AbsoluteCTA,
  SliderArrow,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Why.scss';
import { data } from './logic';

class Why extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'digital-transformation__slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        nextArrow: <SliderArrow id={'digital-transformation-slider-next'} />,
        prevArrow: (
          <SliderArrow id={'digital-transformation-slider-prev'} left />
        ),
      },
    };
  }

  renderSlides = () =>
    data.map((item, index) => (
      <div
        className={'digital-transformation__statistics-slider'}
        key={`dt-stats-slide-${index}`}
      >
        {item.intro && (
          <Paragraph size={'medium-plus'} additionalClass={'text--bold'}>
            {item.intro}
          </Paragraph>
        )}
        <Paragraph size={'extra-large'} additionalClass={'text--bold'}>
          {item.value}
        </Paragraph>
        <Heading type={4}>{item.description}</Heading>
        <Paragraph
          size={'small'}
          additionalClass={'digital-transformation__source text--light'}
        >
          {item.source}
        </Paragraph>
      </div>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <Section color={'white'} fullwidth spaceless>
        <div
          className={
            'w-container--side-left digital-transformation__left-container'
          }
        >
          <Section
            background={
              'gatsby-cloudinary/digital-transformation-why-background.png'
            }
            fullwidth
            spaceless
          >
            <div
              className={
                'w-container digital-transformation__header-container'
              }
            >
              <SectionHeader
                size={'big'}
                color={'white'}
                lineColor={'white'}
                headingType={2}
              >
                Is Digital Transformation really critical?
              </SectionHeader>
            </div>
            <div className={'digital-transformation__slider-container'}>
              <Slider {...sliderSettings}>{this.renderSlides()}</Slider>
            </div>
          </Section>
        </div>
        <Section color={'white'}>
          <SectionHeader
            size={'medium'}
            color={'black'}
            lineColor={'violet'}
            headingType={3}
          >
            How are other business coping with Digital Transformation?
          </SectionHeader>
          <Row className={'no-gap digital-transformation__statistics'}>
            <Col sm={12} md={3}>
              <Heading
                size={'large'}
                color={'violet'}
                type={4}
                additionalClass={'text--bold'}
              >
                81%
              </Heading>
              <Paragraph color={'violet'} additionalClass={'text--light'}>
                of companies say they plan to boost their spending on
                technology over the next two years as they fight to remain
                competitive.
              </Paragraph>
            </Col>
            <Col sm={12} md={3}>
              <Heading
                size={'large'}
                color={'violet'}
                type={4}
                additionalClass={'text--bold'}
              >
                89%
              </Heading>
              <Paragraph color={'violet'} additionalClass={'text--light'}>
                of organizations have plans for digital transformation or have
                already embraced a digital-first business strategy
              </Paragraph>
            </Col>
            <Col sm={12} md={3}>
              <Heading
                size={'large'}
                color={'violet'}
                type={4}
                additionalClass={'text--bold'}
              >
                9/10
              </Heading>
              <Paragraph color={'violet'} additionalClass={'text--light'}>
                business use third-party providers to help them with digital
                transformation
              </Paragraph>
            </Col>
            <Col sm={12} md={3}>
              <Heading
                size={'large'}
                color={'violet'}
                type={4}
                additionalClass={'text--bold'}
              >
                40%
              </Heading>
              <Paragraph color={'violet'} additionalClass={'text--light'}>
                of companies claim that third-party vendors have greater
                expertise, resources and capabilities in carrying out digital
                transformation
              </Paragraph>
            </Col>
          </Row>
        </Section>
        <AbsoluteCTA id={'contact-digital-transformation-statistics'} />
      </Section>
    );
  }
}

export default Why;
