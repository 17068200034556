import React from 'react';

import {
  Awards,
  Button,
  Link,
  Paragraph,
  Rating,
  Section,
  SectionHeader,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { companiesData } from './logic';
import companyData from '../../../../../meta/companyData';

import './ContactNlAwards.scss';

const ContactNlAwards = () => (
  <Section
    additionalClass={'contact-nl-awards__section'}
    color={'violet'}
    id={'contact-nl-awards'}
  >
    <SectionHeader
      description={
        'Boldare staat voor kwaliteit. Onze prijswinnende digitale producten worden internationaal erkend vanwege hun aantrekkelijke interfaces, uitstekende user experience, innovatieve aanpak en onberispelijke kwaliteit van code.'
      }
      color={'white'}
      lineColor={'yellow'}
      descriptionColor={'white'}
      size={'large'}
      headingType={2}
      additionalClass={'contact-nl-awards__text-header'}
    >
      Awards
    </SectionHeader>
    <Awards theme={'light'} />
    <Section
      additionalClass={`contact-nl-awards__companies-section`}
      containerClass={'contact-nl-awards--position-relative'}
      spaceless
      fullwidth
    >
      <div className={'contact-nl-awards__companies-section__items'}>
        {companiesData.map(item => (
          <div className={'contact-nl-awards__companies-section__item'}>
            <div className={'contact-nl-awards__companies-section__item-img'}>
              <img
                src={getCloudinaryUrl(item.image, 'f_auto,q_auto')}
                alt={''}
                loading={'lazy'}
              />
              {item.rate && (
                <Rating
                  rate={companyData.clutch_rate}
                  size={20}
                  color={'yellow'}
                />
              )}
            </div>
            <Paragraph
              additionalClass={
                'contact-nl-awards__companies-section__item-description'
              }
              color={'white'}
              size={'medium'}
            >
              {item.description}
            </Paragraph>
            {item.link && (
              <Link to={item.link}>
                <Button
                  color={'white'}
                  arrowColor={'white'}
                  order={'secondary'}
                >
                  {item.linkText}
                </Button>
              </Link>
            )}
          </div>
        ))}
      </div>
    </Section>
  </Section>
);

export default ContactNlAwards;
