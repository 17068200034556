import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Section, SectionHeader, BackgroundImage } from 'components';

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        mainBannerImage: file(relativePath: { eq: "prototyping/banner.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 939) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Section additionalClass={'prototyping__banner'} color={'gray-lighter'}>
        <SectionHeader
          size={'large'}
          color={'black'}
          description={
            'Whether you are planning to build a new digital product, looking to redesign your app or pitching your concept to investors, we can help you minimize the risk of market failure with digital prototyping services.'
          }
          hiddenDescription={
            '<br />Prototyping is a step in a design process that allows you to visualize and test your business concept quickly and within a low budget before you build a full version of the product. Within 7 to 14 days you will be able to showcase your software concept to stakeholders, carry out testing, and gather feedback and data to make an informed decision about the future of your digital product. Digital prototyping is an initial stage in the full cycle product development process and will pave the path to Minimum Viable Product development. '
          }
          descriptionColor={'black'}
          fixed
          additionalClass={'section-header__description--medium'}
          buttonColor={'black'}
        >
          Prototyping <br />
          Digital Products
        </SectionHeader>
        <div className={'prototyping__banner-image'}>
          <BackgroundImage
            src={data.mainBannerImage.childImageSharp.fluid}
            alt={'Prototyping Digital Products'}
          />
        </div>
      </Section>
    )}
  />
);

export default Banner;
