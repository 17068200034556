import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Paragraph } from 'components';
import './BoxTextCover.scss';

class BoxTextCover extends Component {
  render() {
    const { content, header, image, footer } = this.props;

    return (
      <Box additionalClass={'box-text-cover'}>
        <img
          src={image}
          className={'image box-text-cover__image'}
          alt={''}
          loading={'lazy'}
        />
        <div className={'box-text-cover__overlay'}>
          <div className={'box-text-cover__main'}>
            {header && (
              <Paragraph
                size={'small'}
                additionalClass={'box-text-cover__header'}
              >
                {ReactHtmlParser(header)}
              </Paragraph>
            )}
            <Paragraph
              size={'medium'}
              additionalClass={'box-text-cover__content text--light'}
            >
              {ReactHtmlParser(content)}
            </Paragraph>
            {footer && (
              <Paragraph
                size={'small'}
                additionalClass={'text--bold box-text-cover__footer'}
              >
                {footer}
              </Paragraph>
            )}
          </div>
        </div>
      </Box>
    );
  }
}

BoxTextCover.defaultProps = {
  footer: '',
  header: '',
};

BoxTextCover.propTypes = {
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  footer: PropTypes.string,
  header: PropTypes.string,
};

export default BoxTextCover;
