import React from 'react';
import './Bonus.scss';
import { Section, Heading, Paragraph, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import withSubscribe from './HOC';

const Bonus = ({ data, subscribed, isLoading, refForm, handleSubmit }) => {
  return (
    <Section color={'yellow'} additionalClass={'webinar-res__bonus'}>
      <div className={'webinar-res__bonus-content'}>
        {subscribed ? (
          <Heading type={3} size={'medium-plus'}>
            Thank you for contacting us.
            <br />
            We will be back to you, shortly
          </Heading>
        ) : (
          <>
            <div className={'bonus-details'}>
              <Heading type={3} size={'medium-plus'}>
                {data.bonusHeading}
              </Heading>
              {data.bonusDescription && (
                <Paragraph size={'medium'}>{data.bonusDescription}</Paragraph>
              )}
            </div>
            <form
              className={'bonus-form'}
              onSubmit={handleSubmit}
              ref={refForm}
            >
              <input
                className={''}
                type={'email'}
                name={'email'}
                placeholder={'Company e-mail'}
                required
              />
              <Button
                id={''}
                type={'submit'}
                color={'yellow'}
                ariaName={'Submit form'}
                additionalClass={classNames('webinar-res__bonus-button', {
                  loading: isLoading,
                })}
                disabled={isLoading}
                loading
              >
                Claim discount
              </Button>
            </form>
            <div className={'bonus-image'}>
              <img
                src={getCloudinaryUrl(
                  'img/crash-course-city.svg',
                  'q_auto,f_auto,h_134',
                )}
                className={'image'}
                alt={'Risk Management Crash Course'}
                loading={'lazy'}
              />
            </div>
          </>
        )}
      </div>
    </Section>
  );
};

export default withSubscribe(Bonus);
