import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { SliderArrow } from 'components';
import { classNames } from 'utils';
import './BoxLayoutMobileSingle.scss';

class BoxLayoutMobileSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'box-layout-mobile-single',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow id={'box-mobile-next'} />,
        prevArrow: <SliderArrow id={'box-mobile-prev'} left />,
      },
    };
  }

  render() {
    const { sliderSettings } = this.state;
    const { additionalClass, children, mobileSlider } = this.props;

    return (
      <>
        {mobileSlider ? (
          <>
            <div
              className={classNames(
                'box-layout-mobile-single',
                'hide-mobile',
                additionalClass,
              )}
            >
              {children}
            </div>
            <div className={'hide-desktop hide-tablet'}>
              <Slider {...sliderSettings}>{children}</Slider>
            </div>
          </>
        ) : (
          <div
            className={classNames('box-layout-mobile-single', additionalClass)}
          >
            {children}
          </div>
        )}
      </>
    );
  }
}

BoxLayoutMobileSingle.defaultProps = {
  additionalClass: '',
};

BoxLayoutMobileSingle.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BoxLayoutMobileSingle;
