import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, Link, Paragraph, Heading, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './MachineLearningServices.scss';

class MachineLearningServices extends Component {
  getCurrentPostCount = (currentSlug, allPosts) =>
    allPosts.findIndex(item => {
      const slug = item.node.frontmatter.settings.slug
        ? item.node.frontmatter.settings.slug
        : item.node.fields.slug;
      return slug === currentSlug;
    });

  render() {
    return (
      <Link to={'/machine-learning/'} arrow>
        <Section
          additionalClass={'htw__ml--next'}
          background={getCloudinaryUrl(
            'gatsby-cloudinary/developers-working-on-machine-learning-algoritm.png',
            'f_auto,q_auto,w_auto',
          )}
          spaceless
        >
          <div className={'htw__ml--inner'}>
            <Heading type={2} additionalClass={'htw__ml--title'}>
              Machine Learning Services
            </Heading>
            <Paragraph color={'white'} additionalClass={'htw__ml--caption'}>
              Our experts will help you turn your big data into valuable source
              of information and insight, automate your decision-making
              processes and help you predict the future.
            </Paragraph>
            <Link to={'/machine-learning/'}>
              <Button
                order={'secondary'}
                color={'white'}
                id={'hack-the-wind-machine-learning'}
                ariaName={'Learn more button'}
              >
                Learn more
              </Button>
            </Link>
          </div>
        </Section>
      </Link>
    );
  }
}

MachineLearningServices.defaultProps = {
  allPosts: [],
  nextArticle: null,
  previousArticle: null,
};

MachineLearningServices.propTypes = {
  allPosts: PropTypes.array,
  currentSlug: PropTypes.string.isRequired,
  nextArticle: PropTypes.shape(),
  previousArticle: PropTypes.shape(),
};

export default MachineLearningServices;
