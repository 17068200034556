import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeader, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './NewNormalBanner.scss';

const NewNormalBanner = ({ id }) => (
  <div className={'newnormal-banner'}>
    <a href={'/new-normal/'} className={'newnormal-banner-link'} id={id}>
      <span className={'newnormal-banner-tick'} />
      <div className={'newnormal-banner-content'}>
        <div className={'newnormal-banner-left'}>
          <span className={'newnormal-banner-badge'}>dedicated space</span>
          <SectionHeader
            type={4}
            size={'medium'}
            lineColor={'red'}
            description={
              'Learn how to embrace a New Normal for your organization in the post-COVID-19 era.'
            }
            descriptionColor={'mine-shaft'}
            fixed
          >
            New Normal in Practice
          </SectionHeader>
          <Button
            id={`newnormal-banner-button`}
            color={'pigeon-post'}
            additionalClass={`newnormal-banner-button`}
            ariaName={'Download'}
          >
            <span className={'newnormal-banner-button-cta'}>
              Explore New Normal
            </span>
          </Button>
        </div>
        <div className={'newnormal-banner-right'}>
          <div className={'newnormal-banner-images'}>
            <img
              src={getCloudinaryUrl(
                'v1599809895/img/new-normal/group-img-2.png',
                'f_auto,q_auto',
              )}
              alt={'Boldare New Normal'}
              loading={'lazy'}
              className={'image'}
            />
            <img
              src={getCloudinaryUrl(
                'v1599809895/img/new-normal/group-img-1.png',
                'f_auto,q_auto',
              )}
              alt={'Boldare New Normal'}
              loading={'lazy'}
              className={'image'}
            />
          </div>
        </div>
      </div>
    </a>
  </div>
);

NewNormalBanner.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NewNormalBanner;
