import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  Button,
  UnorderedList,
} from 'components';

class Problem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
    };
  }

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <Section color={'red'}>
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionHeader
              size={'big'}
              color={'white'}
              lineColor={'gray'}
              headingType={2}
            >
              Problem
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            <div className={'digital-transformation__problem-description'}>
              <Heading color={'white'} size={'medium-plus'} type={3}>
                I want to guide my business through a successful digital
                transformation and continue to provide an excellent customer
                experience.
              </Heading>
              {isFullDescriptionShown && (
                <>
                  <Paragraph
                    additionalClass={
                      'digital-transformation__additional-description text--light'
                    }
                    color={'white'}
                    size={'medium'}
                  >
                    Companies around the world struggle to move their business
                    to a digital-first model. Most frequently they deal with
                    similar issues:
                  </Paragraph>
                  <br />
                  <UnorderedList
                    additionalClass={'text--white text--light'}
                    items={[
                      'legacy systems',
                      'lack of digital strategy',
                      'skills and talent gap within business and IT departments',
                      'lack of know-how among higher management on how to proceed',
                      'lack of both agility and a pro-risk culture',
                    ]}
                  />
                  <Paragraph
                    additionalClass={
                      'digital-transformation__additional-description text--light'
                    }
                    color={'white'}
                    size={'medium'}
                  >
                    However, digital transformation is no longer a choice, it
                    is a necessity. Organizations who do not embrace new
                    technologies as part of their business will shortly see a
                    dramatic fall in profits and inevitable failure.
                  </Paragraph>
                </>
              )}
              <div
                className={'digital-transformation__learn-more-button'}
                onClick={this.toggleHiddenDescription}
              >
                <Button
                  order={'secondary'}
                  color={'white'}
                  arrowColor={'gray-medium'}
                  ariaName={'Description button'}
                >
                  {isFullDescriptionShown ? 'Show less' : 'Learn more'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    );
  }
}

export default Problem;
