import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './PopUp.scss';

class PopUp extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.popupNode) {
      if (this.popupNode.contains(e.target)) {
        return;
      }
      this.props.visibilityHandler(false);
    }
  };

  render() {
    const { additionalClass, id, children, visible } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className={'popup__container'} id={id}>
        <div
          ref={node => (this.popupNode = node)}
          className={classNames('popup__content', additionalClass)}
        >
          <span
            className={'popup__close'}
            id={'popup-close-cross'}
            onClick={() => {
              this.props.visibilityHandler(false);
            }}
          >
            &#10005;
          </span>
          {children}
        </div>
      </div>
    );
  }
}

PopUp.defaultProps = {
  additionalClass: null,
  id: null,
  children: null,
  visible: false,
};

PopUp.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string,
  visible: PropTypes.bool,
};

export default PopUp;
