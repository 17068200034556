import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import {
  Changes,
  Client,
  Colaboration,
  Contact,
  Conversion,
  Intro,
  Product,
  Separator,
  Membership,
  Redesign,
  Technology,
} from './subcomponents';
import './Pakt.scss';

class Pakt extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study pakt__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/pakt/pakt-main.jpg',
            'f_auto,q_auto',
          )}
          color={'white'}
          subtitle={'Pakt app interface redesign'}
          title={'The Idea of Virtual Wardrobe'}
        />
        <Intro />
        <Client />
        <Separator text={'Business goals<br/>to achieve'} />
        <Conversion />
        <Product />
        <Membership />
        <Separator text={'Actions'} />
        <Redesign />
        <Changes />
        <Separator text={'Technology'} />
        <Colaboration />
        <Technology />
        <Contact />
      </div>
    );
  }
}

export default Pakt;
