import React from 'react';
import { Section, Paragraph, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Redesign.scss';

const Redesign = () => {
  return (
    <Section additionalClass={'pakt__redesign-container'} spaceless>
      <div className={'pakt__content'}>
        <Heading type={2} size={'big-spreaded'}>
          We did some redesign
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          We replaced unreadable photos with straightforward graphics to keep
          the whole design consistent and transparent.
        </Paragraph>
      </div>
      <div className={'pakt__redesign-images'}>
        <div className={'pakt__content--half anim-fade-in-bottom'}>
          <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
            From unclear photos...
          </Heading>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/pakt/pic-6-left.jpg',
              'f_auto,q_auto',
            )}
            alt={'Preview'}
          />
        </div>
        <div
          className={
            'pakt__content--half pakt__redesign-image--delay anim-fade-in-bottom'
          }
        >
          <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
            ...to clear simplicity
          </Heading>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/pakt/pic-6-right.jpg',
              'f_auto,q_auto',
            )}
            alt={'Preview'}
            additionalClass={'pakt__redesign-image'}
          />
        </div>
      </div>
    </Section>
  );
};

export default Redesign;
