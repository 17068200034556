import React from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { NavBar, FooterMini } from 'components';
import { pages } from 'config/pages';
import EventRegistrationPage from 'views/eventRegistrationPage';
import Layout from '../components/layout';
import config from '../../meta/config';

const EventPage = ({ data }) => {
  const { markdownRemark } = data;
  const {
    settings,
    form_settings: formSettings,
    event_information: eventInformation,
    title,
    subTitle,
  } = markdownRemark.frontmatter;

  if (settings.activeEvent === false && typeof window !== 'undefined') {
    navigate('/');
  }

  const page = {
    ...pages.event,
    metaTitle: settings.metaTitle,
    siteContent: settings.metaDescription,
  };

  const canonical = `${config.siteUrl}/event/${settings.slug}/`;

  const eventDate = new Date(eventInformation.date);
  const eventTime = eventDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  const eventDateFormatted = eventDate.toLocaleDateString([], {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const eventData = {
    title,
    subtitle: subTitle,
    address: eventInformation.address,
    addressSecondLine: eventInformation.address_second_line,
    addressLink: eventInformation.address_link,
    date: eventDateFormatted,
    time: eventTime,
    group: eventInformation.group_size,
    qaSession: eventInformation.qa_duration,
    duration: eventInformation.duration,
    speakerBox: eventInformation.show_speaker_box,
    speakers: eventInformation.speakers,
    description: [eventInformation.mainContent],
    agendaItems: [],
    sumamry: '',
    pageColor: settings.page_color,
    boxColor: settings.box_color,
    cover: settings.siteCover,
    customTemplate: settings.custom_template,
    customSectionLogos: settings.custom_section_logos,
    metaTitle: settings.metaTitle,
    metaDescription: settings.metaDescription,
  };

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={['black']} bannerHidden />
      <EventRegistrationPage
        formHeading={formSettings.form_title}
        button={{
          text: formSettings.form_button_text,
          color: formSettings.form_button_color,
        }}
        url={formSettings.form_link}
        eventData={eventData}
      />
      <FooterMini />
    </Layout>
  );
};

EventPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default EventPage;

export const pageQuery = graphql`
  query EventPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        event_information {
          address
          address_link
          address_second_line
          date
          duration
          group_size
          mainContent
          qa_duration
          show_speaker_box
          speakers {
            name
            photo
            role
          }
        }
        form_settings {
          form_button_color
          form_button_text
          form_link
          form_title
        }
        templateKey
        subTitle
        settings {
          metaTitle
          page_color
          metaDescription
          custom_template
          custom_section_logos {
            src
            alt
            url
          }
          canonical
          box_color
          siteCover
          slug
          slugType
        }
        title
      }
    }
  }
`;
