export const quizQuestions = [
  {
    question: 'Does your digital product have paying users?',
    answers: [
      {
        type: 'notReady-none',
        content: 'None at all',
      },
      {
        type: 'notReady-monetary',
        content:
          'There is no monetary payment, but clients are paying in a different way',
      },
      {
        type: 'ready-some',
        content: 'Yes, some, (between 10 and 500)',
      },
      {
        type: 'ready-lots',
        content: 'Yes, lots (500 or more)',
      },
    ],
  },
  {
    question: 'Is your product collecting feedback and user data?',
    answers: [
      {
        type: 'ready',
        content: 'Yes, it collects feedback and user data',
      },
      {
        type: 'notReady-data',
        content: 'Only user data',
      },
      {
        type: 'notReady-feedback',
        content: 'Only feedback',
      },
      {
        type: 'notReady-none',
        content: 'None of the above',
      },
    ],
  },
  {
    question: 'Do you collect that data, analyze it and draw conclusions?',
    answers: [
      {
        type: 'ready',
        content: 'Yes, regularly',
      },
      {
        type: 'notReady-sometime',
        content: 'Sometimes',
      },
      {
        type: 'notReady-none',
        content: 'Never',
      },
      {
        type: 'notReady-apply',
        content: 'Does not apply',
      },
    ],
  },
  {
    question: 'Have you defined your niche and target market?',
    answers: [
      {
        type: 'notReady-niche',
        content:
          'I have defined my target market, but I’m not sure about my niche',
      },
      {
        type: 'notReady-target',
        content:
          'I have defined my niche, but I’m still working on a target market',
      },
      {
        type: 'ready',
        content: 'I have defined my niche and target market',
      },
      {
        type: 'notReady-none',
        content: 'None of the above',
      },
    ],
  },
  {
    question:
      'Choose the sentence that describes your progress on niche and target market:',
    answers: [
      {
        type: 'notReady-niche',
        content:
          'I have defined my niche and target market but have not defined how to make money from them',
      },
      {
        type: 'ready',
        content:
          'I have defined my niche and target market and I have defined how to make money from them',
      },
      {
        type: 'notReady-none',
        content: 'None of the above',
      },
      {
        type: 'notReady-witchcraft',
        content: 'I don’t believe in witchcraft',
      },
    ],
  },
];
