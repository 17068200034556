import React, { useEffect } from 'react';
import { enableAnimations } from 'config/helpers';
import './SprintRetrospectiveTool.scss';
import {
  AboutTool,
  Banner,
  Feedback,
  Features,
  RetrospectivePractices,
} from './subcomponents';

const SprintRetrospectiveTool = () => {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'single-page single-page--hero section-srt'}>
      <Banner />
      <AboutTool />
      <Features />
      <RetrospectivePractices />
      <Feedback />
    </div>
  );
};

export default SprintRetrospectiveTool;
