import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import Clutch from 'views/clutch';
import config from '../../../meta/config';

const page = pages['your-development-partner'];
const { navColors } = page;
const url = `${config.siteUrl}/services/your-development-partner/`;
const canonical = `${url}`;

const YDPPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} phoneNumber />
      <Clutch allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query YDPPageQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "How much does it cost to develop an app?"
              "How to choose a software development company?"
              "Starting app development efficiently - how to do it?"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default YDPPage;
