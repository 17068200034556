import React, { Component } from 'react';
import { Location } from '@reach/router';

export default function withLocation(WrappedComponent) {
  return class LocationHandler extends Component {
    render() {
      return (
        <Location>
          {({ location }) => (
            <WrappedComponent location={location} {...this.props} />
          )}
        </Location>
      );
    }
  };
}
