import { IconSVG, Link } from 'components';
import React from 'react';
import EventSpeaker from '../Speaker';
import './Title.scss';

const EventTitle = ({ eventData }) => {
  const color = eventData.boxColor === 'white' ? 'black' : 'white';
  return (
    <>
      <div className={`event-title event-title__box--${eventData.boxColor}`}>
        {!eventData.customTemplate && (
          <h1 className={'heading heading--large'}>{eventData.title}</h1>
        )}
        <h2 className={'heading heading--big'}>{eventData.subtitle}</h2>
        <div className={'event-title__info'}>
          <div className={'event-title__info--column'}>
            <Link
              to={eventData.addressLink}
              className={'event-title__info--row'}
            >
              <IconSVG name={'map-pin'} size={'large'} color={color} />
              <p className={`heading heading--big text--${color}`}>
                {eventData.address}
              </p>
            </Link>
            <Link
              to={eventData.addressLink}
              className={`event-title__info--secondLine text--${color}`}
            >
              {eventData.addressSecondLine}
            </Link>
          </div>
          <div className={'event-title__info--column'}>
            {eventData.date && (
              <div className={'event-title__info--row'}>
                <IconSVG name={'calendar'} size={'large'} color={color} />
                <p className={`heading heading--big text--${color}`}>
                  {eventData.date}
                </p>
              </div>
            )}
            {eventData.time && (
              <p className={`event-title__info--secondLine text--${color}`}>
                {eventData.time}
              </p>
            )}
          </div>
          {eventData.speakerBox && eventData.speakers && (
            <EventSpeaker speakers={eventData.speakers} />
          )}
        </div>
      </div>
      {eventData.customTemplate && eventData.title && (
        <div className={'event-tag'}>
          <h1 className={'heading heading--medium-plus'}>{eventData.title}</h1>
        </div>
      )}
    </>
  );
};
export default EventTitle;
