import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Glide from '@glidejs/glide';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Section, Heading, BoxArticle, BoxCTA, IconSVG } from '..';
import './RelatedArticles.scss';

class RelatedArticles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideCounter: props.contact ? 2 : 3,
    };
  }

  componentDidMount() {
    const { slideCounter } = this.state;
    const { id, related } = this.props;
    const { length: itemsCounter } = related;

    this.glide = new Glide(`#${id}`, {
      bound: true,
      type: 'slider',
      focusAt: 0,
      perView: itemsCounter < slideCounter ? itemsCounter : slideCounter,
      animationDuration: 400,
      gap: 40,
      dragThreshold: false,
      perTouch: slideCounter,
      breakpoints: {
        992: {
          perView: slideCounter - 1,
          gap: 20,
          autoplay: itemsCounter > slideCounter - 1 ? 3000 : false,
        },
        768: {
          perView: slideCounter - 2,
          gap: 0,
          autoplay: itemsCounter > slideCounter - 2 ? 3000 : false,
        },
      },
      classes: {
        activeNav: 'active',
      },
    });

    if (document.querySelector('.related-articles__row')) {
      this.glide.mount();
    }
  }

  renderRelatedArticles = () =>
    this.props.related.map((item, index) => {
      if (item.title === 'CONTACT') {
        return (
          <li
            key={item.title + index}
            className={'related-articles__item glide__slide'}
          >
            <BoxCTA name={this.props.id} fixed />
          </li>
        );
      }

      if (item.title === '' || item.title === 'undefined') {
        return (
          <li
            key={item.title + index}
            className={
              'related-articles__item related-articles__item--disabled glide__slide'
            }
          >
            {item.hover && (
              <div
                className={classNames('related-articles__item-content', {
                  [`related-articles__item-content--${item.hover.background}`]: item
                    .hover.background,
                })}
              >
                <IconSVG
                  name={item.hover.icon}
                  size={'large'}
                  color={'white'}
                />
                <Heading size={'big'} color={'white'} type={3}>
                  {item.hover.headline}
                </Heading>
              </div>
            )}
          </li>
        );
      }

      const { allArticles } = this.props;
      const articleData =
        allArticles &&
        allArticles.edges.find(
          edge => edge.node.frontmatter.title === item.title,
        );
      return (
        articleData &&
        articleData[0] !== 'undefined' && (
          <li
            key={item.title + index}
            className={'related-articles__item glide__slide'}
          >
            {item.hover && (
              <div
                className={classNames('related-articles__item-content', {
                  [`related-articles__item-content--${item.hover.background}`]: item
                    .hover.background,
                })}
              >
                <IconSVG
                  name={item.hover.icon}
                  size={'large'}
                  color={'white'}
                />
                <Heading size={'big'} color={'white'} type={3}>
                  {item.hover.headline}
                </Heading>
              </div>
            )}
            <div
              className={classNames({
                'related-articles__item-overlay': item.hover,
              })}
            >
              <BoxArticle
                id={`art-box-${this.props.id}-${index}`}
                post={articleData}
              />
            </div>
          </li>
        )
      );
    });

  renderNavBullets = () =>
    this.props.related.map((item, index) => (
      <button
        key={`button_${index}`}
        className={classNames(
          'related-articles__bullet',
          {
            [`related-articles__bullet--${this.props.bulletColor}`]: this.props
              .bulletColor,
          },
          'glide__bullet',
        )}
        data-glide-dir={`=${index}`}
        aria-label={'Slider button'}
      />
    ));

  render() {
    const {
      id,
      sectionTitle,
      fixed,
      children,
      background,
      contact,
      related,
      additionalClass,
    } = this.props;
    const { length: itemsCounter } = related;

    return (
      <Section
        color={background}
        additionalClass={`related-articles ${additionalClass}`}
      >
        {sectionTitle && (
          <Heading
            size={'big'}
            type={2}
            additionalClass={'related-articles__headline'}
          >
            {sectionTitle}
          </Heading>
        )}
        {children}
        <Row className={'no-gap'}>
          <Col sm={12} md={contact ? 6 : 12} lg={contact ? 8 : 12}>
            <div
              id={id}
              className={classNames('related-articles__row', 'glide', {
                'related-articles__row--indent': contact,
                'anim-fade-in-bottom': !fixed,
              })}
            >
              <div data-glide-el={'track'} className={'glide__track'}>
                <ul className={'glide__slides'}>
                  {this.renderRelatedArticles()}
                </ul>
              </div>
              <div
                className={classNames(
                  'related-articles__nav',
                  'glide__arrows',
                  {
                    'hide-desktop': itemsCounter < 4,
                  },
                )}
                data-glide-el={'controls'}
              >
                <button
                  className={
                    'related-articles__arrow glide__arrow glide__arrow--left'
                  }
                  id={`${id}-prev`}
                  data-glide-dir={'<'}
                  aria-label={'Slider control button'}
                >
                  <IconSVG
                    name={'arrow-left'}
                    color={this.props.bulletColor}
                  />
                </button>
                <button
                  className={
                    'related-articles__arrow glide__arrow glide__arrow--right'
                  }
                  id={`${id}-next`}
                  data-glide-dir={'>'}
                  aria-label={'Slider control button'}
                >
                  <IconSVG
                    name={'arrow-right'}
                    color={this.props.bulletColor}
                  />
                </button>
              </div>
              <div
                className={classNames(
                  'related-articles__bullets',
                  'glide__bullets',
                  {
                    'hide-desktop': itemsCounter < 4,
                  },
                )}
                data-glide-el={'controls[nav]'}
              >
                {this.renderNavBullets()}
              </div>
            </div>
          </Col>
          {contact && (
            <Col sm={12} md={6} lg={4}>
              <BoxCTA additionalClass={'related-articles__cta'} fixed />
            </Col>
          )}
        </Row>
      </Section>
    );
  }
}

RelatedArticles.defaultProps = {
  sectionTitle: 'Related Articles',
  fixed: false,
  contact: false,
  children: null,
  background: 'gray-light',
  bulletColor: 'yellow',
  additionalClass: null,
};

RelatedArticles.propTypes = {
  id: PropTypes.string.isRequired,
  allArticles: PropTypes.shape({ edges: PropTypes.array }).isRequired,
  bulletColor: PropTypes.string,
  related: PropTypes.array.isRequired,
  fixed: PropTypes.bool,
  contact: PropTypes.bool,
  background: PropTypes.string,
  sectionTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  additionalClass: PropTypes.string,
};

export default RelatedArticles;
