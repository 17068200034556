import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { scroller } from 'react-scroll';
import {
  BackgroundImage,
  Heading,
  SectionHeader,
  Section,
  IconSVG,
  Tag,
} from 'components';
import { getCloudinaryUrl, toHtml } from 'config/helpers';
import Slider from 'react-slick';
import {
  careerCities,
  careerContractTypes,
  careerExperienceLevels,
  getActiveCityFromLocalStorage,
} from 'views/career/career.utils';
import { classNames } from 'utils';
import TalentPoolBenefits from 'views/career/subcomponents/TalentPoolBenefits';
import { List, Perk, Step } from '..';
import config from '../../../../../meta/config';
import './JobOfferContent.scss';
import { dataBenefits, dataOpportunities } from './logic.js';
import Paragraph from '../../../../components/Paragraph/index';

const {
  career: { displayCustomForm },
} = config;

const talentPoolBenefitList = [
  {
    icon: 'star-with-shadow',
    desc: `Get a priority to receive a job offer`,
  },
  {
    icon: 'supervisor-account',
    desc: `Get to know what's going on in the labor market and what you can expect during recruitment processes now`,
  },
  {
    icon: 'local-police',
    desc: `Check your seniority level. Will we assume it is the same as with your current company?`,
  },
];

class JobOfferContent extends Component {
  constructor(props) {
    super(props);
    const { contractType } = this.props.data.mainContent;
    const activeContract = contractType ? contractType[0] : null;

    this.state = {
      isDescriptionHidden: props.hiddenDescription,
      isSalaryDescriptionHidden: props.hiddenSalaryDescription,
      isTop: true,
      scrollPosition: 0,
      activeContract,
      isMobile: false,
      isTablet: false,
      sliderSettings: {
        className: 'job-offer__mobile-slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: false,
        dots: true,
        autoplay: true,
        speed: 200,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      apply: '/',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    const { apply, contractType = [] } = this.props.data.mainContent;
    this.handleResize();

    const activeCityCandidateFromLocalStorage = getActiveCityFromLocalStorage();
    const activeCityFromLocalStorage = contractType.find(
      contract => contract.contract === activeCityCandidateFromLocalStorage,
    );

    if (activeCityFromLocalStorage) {
      this.setState({
        activeContract: activeCityFromLocalStorage,
      });
    }

    this.setState({
      apply: displayCustomForm
        ? `${window.location.pathname}/apply/`.replace('//', '/')
        : apply,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 768,
      isTablet: window.innerWidth < 1025,
    });
  };

  handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: item,
      });
    }
  };

  handleScroll = () => {
    const scrollOffset = document.getElementById('job-offer__section--first')
      .clientHeight;

    const { show } = this.props;

    if (show) this.setState({ isTop: window.scrollY < scrollOffset - 80 });
    else if (!show)
      this.setState(prevState => ({
        isTop:
          window.scrollY === 0 || window.scrollY > prevState.scrollPosition,
        scrollPosition: window.scrollY,
      }));
  };

  renderSlider(data, type) {
    const { sliderSettings } = this.state;
    const slides = [];

    switch (type) {
      case 'perks':
        const slidesNumber = data.length / 3;
        for (let i = 0; i < slidesNumber; i++) {
          const elements = i * 3;
          slides.push(
            <div className={'job-offer__slide'}>
              {data.slice(elements, elements + 3).map(item => (
                <Perk
                  key={item.id}
                  icon={item.icon}
                  title={item.title}
                  text={item.text}
                />
              ))}
            </div>,
          );
        }
        break;
      case 'steps':
        data.map(item =>
          slides.push(
            <Step
              key={item.number}
              number={item.number}
              step={item.step}
              details={item.details}
            />,
          ),
        );
        break;
      default:
        break;
    }

    return <Slider {...sliderSettings}>{slides}</Slider>;
  }

  renderItems = data =>
    data.map((item, index) => (
      <li key={index} className={'job-offer__role'}>
        {item.specific ? toHtml(item.specific) : ''}
      </li>
    ));

  renderPerks = (data, color) =>
    data.map(item => (
      <Perk key={item.id} icon={item.icon} title={item.title} color={color} />
    ));

  renderSteps = data =>
    data.map(item => (
      <Step
        key={item.number}
        number={item.number}
        step={item.step}
        details={item.details}
      />
    ));

  renderFCDPSection = data => (
    <Section
      additionalClass={classNames('job-offer__section--fcpd', {
        'rapid-services': data === 'rapidServices',
        pmf: data === 'productMarketFit',
        'product-scaling': data === 'productScaling',
      })}
    >
      <div className={'job-offer__content'}>
        {data === 'rapidServices' && (
          <SectionHeader
            size={'medium'}
            headingType={2}
            color={'mine-shaft'}
            lineColor={'white'}
            description={`This crew knows the best how to launch the first functioning version of an app onto the market. What are PSF's superpowers? Creative and out-of-the-box way of thinking.<br /><br />Ready to reach the secret knowledge and get to know which phase of product development fits you the most?`}
            descriptionColor={'mine-shaft'}
          >
            This job is part of Problem-Solution Fit!
          </SectionHeader>
        )}
        {data === 'productMarketFit' && (
          <SectionHeader
            size={'medium'}
            headingType={2}
            color={'white'}
            lineColor={'white'}
            description={
              'This crew knows the best way to adjust the product to the users’ needs! Their superpowers? User tests, data analysis and continuous improvement of the product!'
            }
            descriptionColor={'white'}
          >
            This job is part of Product Market Fit
          </SectionHeader>
        )}
        {data === 'productScaling' && (
          <SectionHeader
            size={'medium'}
            headingType={2}
            color={'mine-shaft'}
            lineColor={'white'}
            description={
              'This crew knows the best way to adjust the product to the users’ needs! Their superpowers? User tests, data analysis and continuous improvement of the product!'
            }
            descriptionColor={'mine-shaft'}
          >
            This job is part of Product Scaling
          </SectionHeader>
        )}
        <div className={'job-offer__buttons'}>
          <a
            href={'https://bldr.typeform.com/to/hNW0ElM9'}
            className={
              'button button--primary button--yellow job-offer__salary-btn--big'
            }
            target={'_blank'}
            rel={'noreferrer'}
          >
            Take a Quiz!
          </a>
          <a
            href={'/blog/new-approach-to-product-development/'}
            className={'link text--demibold text--white'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Read full article about it &rarr;
          </a>
        </div>
      </div>
      <div className={'job-offer__fcpd-img'}>
        {data === 'rapidServices' && (
          <img
            src={getCloudinaryUrl('rapid-services-team', 'f_auto,q_auto')}
            alt={'Rapid Services Team'}
          />
        )}
        {data === 'productMarketFit' && (
          <img
            src={getCloudinaryUrl('scaling-team', 'f_auto,q_auto')}
            alt={'Product Market Fit Team'}
          />
        )}
        {data === 'productScaling' && (
          <img
            src={getCloudinaryUrl('product-market-fit-team', 'f_auto,q_auto')}
            alt={'Scaling Product Team'}
          />
        )}
      </div>
    </Section>
  );

  render() {
    const {
      activeContract,
      apply,
      isDescriptionHidden,
      isSalaryDescriptionHidden,
      isMobile,
      isTop,
    } = this.state;
    const { data, hiddenDescription, hiddenSalaryDescription } = this.props;
    const { mainContent, settings } = data;
    const {
      customIntroduction,
      productStage,
      photo,
      cities,
      contractType = [],
    } = mainContent;
    const { category } = settings;
    return (
      <div className={'single-job-offer__container'}>
        <div className={'single-job-offer__container--mobile'}>
          <div
            className={classNames('job-offer__apply', {
              'job-offer__apply--sticky': !isTop,
            })}
          >
            <div className={'w-container'}>
              <div className={'job-offer__apply-title'}>
                {mainContent.talentPool ? (
                  <span className={'job-offer__apply-span'}>
                    Be the first to join
                  </span>
                ) : (
                  <span className={'job-offer__apply-span'}>
                    We are hiring!
                  </span>
                )}
                <Heading
                  additionalClass={classNames('job-offer__apply-position', {
                    'job-offer__apply-position--small':
                      mainContent.name.length > 27,
                  })}
                  type={3}
                >
                  {mainContent.name ? mainContent.name : ''}
                </Heading>
              </div>
              <ul className={'job-offer__salary'}>
                {activeContract &&
                  activeContract.salaries.map(salary => (
                    <>
                      <li key={`salary-${salary.level}`}>
                        <Heading size={'small'} type={4}>
                          <span>
                            {careerExperienceLevels[salary.level]}
                            {activeContract.contract === 'b2b' ? (
                              <>{' (B2B):'}</>
                            ) : (
                              <>:</>
                            )}
                          </span>
                          <br />
                          {`${salary.min} - ${salary.max}`}
                          {activeContract.contract === 'b2b' ? (
                            <>{' PLN net'}</>
                          ) : (
                            <>{' PLN gross'}</>
                          )}
                        </Heading>
                      </li>
                      {mainContent.name === 'Relationship Builder (US)' && (
                        <li key={`salary-us`}>
                          <Heading size={'small'} type={4}>
                            <span>Senior (B2B): </span>
                            <br />
                            90000 - 120000 USD (per year)
                          </Heading>
                        </li>
                      )}
                    </>
                  ))}
              </ul>
              <a
                href={apply}
                className={
                  'button button--primary button--yellow job-offer__salary-btn--big ga-apply-offer'
                }
                target={'_blank'}
                rel={'noreferrer'}
                onClick={() => {
                  this.handleClick('career-apply-now');
                }}
              >
                Apply
              </a>
            </div>
          </div>

          <Section
            additionalClass={'job-offer__section--first'}
            id={'job-offer__section--first'}
          >
            <a
              href={'/career/offers/'}
              className={'link text--demibold text--black'}
            >
              &larr; Back to offers
            </a>
            <div className={'job-offer__hero'}>
              <div className={'job-offer__title'}>
                {!mainContent.talentPool && (
                  <span className={'job-offer__title-span'}>
                    We are hiring!
                  </span>
                )}
                <Heading
                  additionalClass={'job-offer__title-position'}
                  size={'large'}
                  type={1}
                >
                  {mainContent.name ? mainContent.name : ''}
                </Heading>
                {mainContent.talentPool ? (
                  <>
                    <div className={'job-offer__talent-pool-tag'}>
                      TALENT POOL
                    </div>
                    <Paragraph
                      size={14}
                      additionalClass={'job-offer__talent-pool-paragraph'}
                    >
                      This is a process for{' '}
                      <strong>future opportunities</strong>. Pass it
                      successfully and become the first to join us when an open
                      position appears!
                    </Paragraph>
                    <button
                      onClick={() => {
                        scroller.scrollTo('job-offer__image--short', {
                          spy: false,
                          smooth: true,
                          duration: 500,
                          offset: -100,
                        });
                      }}
                      className={'link text--demibold text--black'}
                    >
                      Learn more about Talent Pool &rarr;
                    </button>
                    <div className={'job-offer__talent-pool-items'}>
                      <div className={'job-offer__talent-pool-item'}>
                        <IconSVG name={'career-office'} />
                        <Paragraph
                          additionalClass={'job-offer__talent-pool-item-text'}
                        >
                          {customIntroduction.description ||
                            `Work remotely or from our offices in Warszawa,
                          Kraków, Wrocław and Gliwice.`}
                        </Paragraph>
                      </div>
                      <div className={'job-offer__talent-pool-item'}>
                        <IconSVG name={'people-chat'} />
                        <Paragraph
                          additionalClass={'job-offer__talent-pool-item-text'}
                        >
                          {customIntroduction.descriptionTwo ||
                            `Build top digital products with interdisciplinary
                          teams and clients around the globe.`}
                        </Paragraph>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Heading additionalClass={'job-offer__title-exp'} type={3}>
                      We’ve been expecting you! <br />
                      Ready to take a leap?
                    </Heading>
                    <ul className={'job-offer__title-cities'}>
                      {cities.map(city => (
                        <li className={'job-offer__title-city'} key={city}>
                          {careerCities[city]}
                        </li>
                      ))}
                    </ul>
                    {customIntroduction.visible ? (
                      <div className={'job-offer__title-content'}>
                        {customIntroduction.heading && (
                          <p className={'text--default'}>
                            {customIntroduction.heading}
                          </p>
                        )}
                        {customIntroduction.industries && (
                          <ul className={'text--default'}>
                            {customIntroduction.industries.map(industry => (
                              <li>{industry.industry}</li>
                            ))}
                          </ul>
                        )}
                        {customIntroduction.description && (
                          <p className={'text--default'}>
                            {customIntroduction.description}
                          </p>
                        )}
                        {customIntroduction.descriptionTwo && (
                          <p className={'text--default'}>
                            {customIntroduction.descriptionTwo}
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className={'job-offer__title-content'}>
                        <p className={'text--default'}>
                          If you feel like you’re a perfect match to a team
                          that designs and develops digital products for
                          forward-thinking industries like:
                        </p>
                        <ul className={'text--default'}>
                          <li>renewable energy</li>
                          <li>sharing economy services</li>
                          <li>automotive or mobile marketing</li>
                        </ul>
                        <p className={'text--white'}>
                          (all around the globe!)
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className={'job-offer__details-wrapper'}>
                <div className={'job-offer__details'}>
                  <div className={'job-offer__details-image'}>
                    <img
                      src={getCloudinaryUrl(photo, 'f_auto,q_auto')}
                      alt={'Full Cycle Product Development'}
                    />
                  </div>
                  <ul className={'job-offer__contract-types'}>
                    {contractType.map(contract => (
                      <li
                        key={contract.contract}
                        className={
                          contract.contract === 'mandate'
                            ? 'mandate-contract'
                            : ''
                        }
                      >
                        <Tag
                          active={activeContract === contract}
                          onClick={
                            contract.contract !== 'mandate'
                              ? () =>
                                  this.setState({ activeContract: contract })
                              : undefined
                          }
                        >
                          {careerContractTypes[contract.contract]}
                        </Tag>
                      </li>
                    ))}
                  </ul>
                  <div className={'job-offer__details-salaries'}>
                    <ul className={'job-offer__salary'}>
                      {activeContract &&
                        activeContract.salaries.map(salary => (
                          <>
                            <li key={`salary-${salary.level}`}>
                              <Heading size={'regular'} type={3}>
                                <span>
                                  {careerExperienceLevels[salary.level]}:{' '}
                                </span>
                                {salary.min === salary.max
                                  ? `${salary.max}`
                                  : `${salary.min} - ${salary.max}`}
                                {activeContract.contract === 'b2b' ? (
                                  <>{' PLN net'}</>
                                ) : (
                                  <>{' PLN gross'}</>
                                )}
                              </Heading>
                            </li>
                            {mainContent.name ===
                              'Relationship Builder (US)' && (
                              <li key={`salary-us`}>
                                <Heading size={'regular'} type={3}>
                                  <span>Senior: </span>
                                  90000 - 120000 USD (per year)
                                </Heading>
                              </li>
                            )}
                          </>
                        ))}
                    </ul>
                    <a
                      href={apply}
                      className={
                        'button button--primary button--yellow job-offer__salary-btn--big ga-apply-offer'
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                      onClick={() => {
                        this.handleClick('career-apply-now');
                      }}
                    >
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <div className={'job-offer__container'}>
            <div className={'space'} />
            <Section additionalClass={'job-offer__section--second'}>
              <div className={'job-offer__heading'}>
                <SectionHeader
                  size={'medium'}
                  headingType={2}
                  lineColor={'yellow'}
                >
                  What do you need to get this position?
                </SectionHeader>
              </div>
              <div
                className={classNames('job-offer__requirements', {
                  'description-hidden': isDescriptionHidden,
                })}
              >
                {mainContent.technical ? (
                  <List data={mainContent.technical} title={'Technically'} />
                ) : (
                  <div />
                )}
                {mainContent.additional?.length > 0 ? (
                  <List
                    data={mainContent.additional}
                    title={'Nice-to-haves'}
                  />
                ) : (
                  <div />
                )}
              </div>
              <div className={'job-offer__readmore'}>
                {hiddenDescription && (
                  <button
                    onClick={() =>
                      this.setState({
                        isDescriptionHidden: !isDescriptionHidden,
                      })
                    }
                    className={'link'}
                  >
                    {!isDescriptionHidden ? (
                      <span className={'text--demibold'}>
                        Show less &uarr;
                      </span>
                    ) : (
                      <span className={'text--demibold'}>
                        Read more &darr;
                      </span>
                    )}
                  </button>
                )}
              </div>
            </Section>
            <BackgroundImage
              asset={
                'https://res.cloudinary.com/de4rvmslk/image/upload/v1591772840/Boldare_office_in_Gliwice_-_holocracy_in_practice.jpg'
              }
              additionalClass={`job-offer__image ${
                mainContent.talentPool ? 'job-offer__image--short' : ''
              }`}
              id={'talentPool'}
            />

            <div
              className={`job-offer__banner ${
                mainContent.talentPool ? 'job-offer__banner--talent-pool' : ''
              }`}
            >
              <Heading
                additionalClass={'text--bold'}
                color={'mine-shaft'}
                type={3}
                size={'big'}
                noLine
                bgColor={mainContent.talentPool ? 'titan-white' : null}
              >
                {mainContent.talentPool
                  ? 'Discover Talent Pool process'
                  : 'Fast recruitment'}
              </Heading>
              <div className={'job-offer__banner-content'}>
                <div className={'job-offer__banner-steps'}>
                  {isMobile || mainContent.steps.length > 5 ? (
                    <div className={'job-offer__carousel'}>
                      {this.renderSlider(mainContent.steps, 'steps')}
                    </div>
                  ) : (
                    <>{this.renderSteps(mainContent.steps)}</>
                  )}
                </div>
              </div>
            </div>
            {mainContent.talentPool && (
              <>
                <TalentPoolBenefits content={talentPoolBenefitList} />
                <div className={'space'} />
              </>
            )}

            {productStage.productStageVisibility && (
              <>{this.renderFCDPSection(productStage.productStageType)}</>
            )}

            <Section additionalClass={'job-offer__section--joining'}>
              <div className={'job-offer__heading'}>
                <SectionHeader
                  size={'medium'}
                  headingType={2}
                  lineColor={'yellow'}
                >
                  Joining us as {mainContent.name} means:
                </SectionHeader>
              </div>
              <div
                className={classNames('job-offer__content', {
                  'description-hidden': isSalaryDescriptionHidden,
                })}
              >
                <div className={'job-offer__content--single'}>
                  {mainContent.specifics.map(item => (
                    <div className={'paragraph'}>{toHtml(item.specific)}</div>
                  ))}
                </div>
              </div>
              <div className={'job-offer__readmore'}>
                {hiddenSalaryDescription && (
                  <button
                    onClick={() =>
                      this.setState({
                        isSalaryDescriptionHidden: !isSalaryDescriptionHidden,
                      })
                    }
                    className={'link'}
                  >
                    {!isSalaryDescriptionHidden ? (
                      <span className={'text--demibold'}>
                        Show less &uarr;
                      </span>
                    ) : (
                      <span className={'text--demibold'}>
                        Read more &darr;
                      </span>
                    )}
                  </button>
                )}
              </div>
            </Section>

            <Section additionalClass={'job-offer__section--fifth'}>
              <div className={'job-offer__heading'}>
                <SectionHeader
                  size={'medium'}
                  headingType={2}
                  lineColor={'red'}
                >
                  Perks
                  <br />
                  &amp; benefits
                </SectionHeader>
                <div
                  className={
                    'job-offer__underline job-offer__underline--short'
                  }
                />
              </div>
              <div className={'job-offer__perks'}>
                <div className={'job-offer__perks--flex'}>
                  {this.renderPerks(dataBenefits)}
                </div>
              </div>
            </Section>

            <div
              className={'job-offer__container job-offer__container--mobile'}
            >
              <Section
                additionalClass={'job-offer__section--fourth'}
                color={'white'}
              >
                <div className={'job-offer__heading'}>
                  <SectionHeader
                    size={'medium'}
                    headingType={2}
                    lineColor={'violet'}
                  >
                    Development opportunities in Boldare are{' '}
                    <span className={'text--violet'}>Career path</span>
                  </SectionHeader>
                </div>
                <div className={'job-offer__perks'}>
                  <div className={'job-offer__perks--flex'}>
                    {this.renderPerks(dataOpportunities)}
                  </div>
                </div>
              </Section>
            </div>
            <Section
              additionalClass={'job-offer__section--holacracy'}
              color={'white'}
            >
              <div className={'job-offer__content'}>
                <div
                  className={
                    'job-offer__content--left job-offer__content--hola'
                  }
                >
                  <SectionHeader
                    size={'medium'}
                    headingType={2}
                    color={'mine-shaft'}
                    lineColor={'yellow'}
                  >
                    What does working in Boldare taste like?
                  </SectionHeader>
                  <Heading type={4}>Holacracy - how it works?</Heading>
                  <Paragraph additionalClass={'paragraph--first'}>
                    We work in a flat structure based on a holacratic system
                    (no managers, dispersed leadership and autonomy in
                    decision-making, with full transparency). It gives us a lot
                    of freedom and development opportunities, but at the same
                    time it requires a lot of flexibility, maturity and
                    responsibility for our performance.
                  </Paragraph>
                  <a
                    href={
                      'https://www.youtube.com/watch?v=1ZQL8zUTcek&pp=sAQA'
                    }
                    className={'link text--demibold text--red'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Watch video about holacracy &rarr;
                  </a>
                  <Heading type={4}>Feedback always & everywhere</Heading>
                  <Paragraph>
                    We are always ready and open to share our thoughts! We do
                    it in our daily work, workshops, 1:1 sessions, etc. This is
                    one of the best tools in our professional growth. We also
                    often share feedback in a team forum, because it’s great to
                    learn from each other’s experiences.
                  </Paragraph>
                  <Heading type={4}>Transparency in our DNA</Heading>
                  <Paragraph>
                    EVERYBODY in Boldare has full access to data, including
                    knowledge bases, project descriptions, metrics, statistics,
                    strategies, even financial data, etc. We almost always
                    communicate with each other via open channels to
                    effectively spread information. This is what you need to
                    make conscious, independent decisions in your role.
                  </Paragraph>
                </div>
                <div className={'job-offer__content--right'}>
                  <div className={'job-offer__holacracy-img'}>
                    <img
                      src={getCloudinaryUrl(
                        'career/hola-2.png',
                        'f_auto,q_auto',
                      )}
                      alt={'Full Cycle Product Development'}
                    />
                  </div>
                </div>
              </div>
              <div
                className={'job-offer__content job-offer__holacracy-percent'}
              >
                <IconSVG
                  name={'career-percent-white'}
                  color={'white'}
                  size={'large'}
                  additionalClass={'hide-mobile'}
                />
                <span>20% of annual profit shared among all employees!</span>
              </div>
            </Section>
          </div>
        </div>
        <Section additionalClass={'job-offer__section--seventh'}>
          <div className={'job-offer__contact'}>
            <a href={'/career/offers/'} className={'link text--demibold'}>
              &larr; Go back to career
            </a>
          </div>
        </Section>
      </div>
    );
  }
}

JobOfferContent.defaultProps = {
  hiddenDescription: true,
  hiddenSalaryDescription: true,
  show: true,
};

SectionHeader.propTypes = {
  hiddenDescription: PropTypes.bool,
  hiddenSalaryDescription: PropTypes.bool,
  show: PropTypes.bool,
};

export default JobOfferContent;
