export const StepOne = [
  {
    id: '1',
    isRight: false,
    title: 'Test your business idea',
    description:
      'Mitigate business risks by validating your idea using digital prototypes or a minimum viable product (MVP) application. Let us help you with the design and UX, user testing and software development. It’s the first step to building meaningful and powerful digital products that your users really need.',
    services: [
      {
        name: 'Digital Prototyping',
        link: '/services/full-cycle-product-development/prototyping/',
      },
      {
        name: 'Minimum Viable Product (MVP) Development',
        link: '/services/mvp-development/',
      },
      {
        name: 'UX/UI Design',
        link: '/ux-ui-design-consulting-services/',
      },
    ],
    image: 'services/services-1.png',
  },
];

export const StepTwo = [
  {
    id: '2',
    isRight: true,
    title: 'Build your digital product',
    description:
      'We treat your business as seriously as ours. We will support you at every step of the software development process, helping to create a bold vision and setting ambitious yet achievable goals. Using the Agile approach and scrum framework, we build digital products in iterations, making it possible to change plans and pivot at any time, if needed.',
    services: [
      {
        name: 'Product Design and Development',
        link: '/services/product-design-and-development/',
      },
      {
        name: 'Full Cycle Product Development',
        link: '/services/full-cycle-product-development/',
      },
    ],
    image: 'services/services-2.png',
  },
];

export const StepThree = [
  {
    id: '3',
    isRight: false,
    title: 'Grow your product',
    description:
      'When working on mature products, there’s no “one size fits all” solution. Before moving further with the software development process, we will research both your needs and your users’, and inspect your product to make sure we can help with overcoming the business challenges you face. Our approach is “build, measure, learn, repeat”. This way we can provide maximum business value through the web and mobile applications we build.',
    services: [
      {
        name: 'Product Scaling',
        link: '/services/scaling-your-product-thriving-in-the-market/',
      },
      {
        name: 'Product-Market Fit',
        link: '/services/product-market-fit-expanding-demand/',
      },
      {
        name: 'AI Services',
        link: '/services/ai-software-development-consulting/',
      },
      {
        name: 'Digital Transformation',
        link: '/services/digital-transformation/',
      },
    ],
    image: 'services/services-3.png',
  },
];

export const StepFour = [
  {
    id: '4',
    isRight: true,
    title: 'Support for your product',
    description:
      'Improving existing software requires outstanding experience and surgical precision from an involved team. At Boldare, we are not afraid to undertake complex products that require in-depth diagnosis and life-saving modernizations. Whether it’s monolithic application scaling, legacy or technical debt issues, we will take care of your product, so you can focus on your business.',
    services: [
      { name: 'Code Audit and UX Audit', link: '/services/code-audit/' },
      { name: 'Development Teams', link: '/services/development-teams/' },
    ],
    image: 'services/services-4.png',
  },
];
