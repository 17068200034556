import React, { Component } from 'react';
import { Accordion } from 'react-accessible-accordion';
import Slider from 'react-slick';
import { Col, Row } from 'react-flexbox-grid';
import { kebabCase } from 'lodash';
import { classNames } from 'utils';
import {
  Accordion as AccordionCustom,
  Link,
  Section,
  Heading,
  Paragraph,
  Container,
  SliderArrow,
} from 'components';
import { TILES } from '../../config';
import './Reasons.scss';

class Reasons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'wdm__reasons-slider',
        speed: 1000,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: (
          <SliderArrow
            id={'wdm__slider-next'}
            color={'white'}
            defaultClasses
          />
        ),
        prevArrow: (
          <SliderArrow
            id={'wdm__slider-prev'}
            color={'white'}
            defaultClasses
            left
          />
        ),
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 480 });
  };

  renderItems = () =>
    TILES.map(item => {
      const {
        title,
        subtitle,
        numbers,
        text,
        source,
        color,
        background,
      } = item;
      const { isMobile } = this.state;

      const tile = (
        <div className={'wdm__reasons-tile'}>
          <Paragraph
            additionalClass={`text--${color} wdm__reasons-numbers ${
              numbers.length > 4 ? 'wdm__reasons-numbers--long' : ''
            }`}
          >
            {numbers}
          </Paragraph>
          <Paragraph
            additionalClass={`text--${color} text--light wdm__reasons-text`}
          >
            {text}
          </Paragraph>
          <Link
            id={`wdm__reasons-${kebabCase(title.toLowerCase())}`}
            to={source}
            additionalClass={`text--${color} wdm__reasons-link`}
            underline={'white'}
          >
            <span>(source)</span>
          </Link>
        </div>
      );

      return isMobile ? (
        <AccordionCustom
          id={title}
          backgroundColor={background}
          headline={title}
          headingType={2}
          lead={subtitle}
          lineColor={color}
          fontColors={[color, color, color]}
          lessButton
        >
          {tile}
        </AccordionCustom>
      ) : (
        <div
          className={classNames('wdm__reasons-slide', {
            [`wdm__background--${background}`]: background,
          })}
        >
          <Heading
            color={color}
            size={'big'}
            type={2}
            additionalClass={'wdm__reasons-tile'}
          >
            {title}
          </Heading>
          <Paragraph additionalClass={'wdm__reasons-paragraph'}>
            {subtitle}
          </Paragraph>
          {tile}
        </div>
      );
    });

  render() {
    const { isMobile, sliderSettings } = this.state;

    return (
      <div id={'matters-at-boldare'}>
        <Section additionalClass={'wdm__reasons-section'} fullwidth>
          <Container>
            <Row>
              <Col sm={12} md={4}>
                <Heading
                  type={2}
                  size={'large'}
                  additionalClass={'wdm__reasons-title'}
                >
                  5 reasons why design matters at Boldare
                </Heading>
              </Col>
              <Col sm={12} md={6} mdOffset={2}>
                <p
                  className={
                    'paragraph--regular text--default wdm__reasons-description'
                  }
                >
                  A well-designed UX are not just a ‘nice to have’, they bring
                  essential benefits to your business. At Boldare, we take
                  pride in our design process and the products that result.
                  Read on to discover why our approach to digital design is
                  your best route to a stand-out product.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
        {isMobile ? (
          <div className={'wdm__reasons-tiles'}>
            <Accordion>
              {typeof window !== 'undefined' &&
                window.location &&
                this.renderItems()}
            </Accordion>
          </div>
        ) : (
          <Section additionalClass={'wdm__reasons-section-slider'} spaceless>
            <div className={'wdm__reasons-slider-wrapper'}>
              <Slider
                ref={slider => {
                  this.slider = slider;
                }}
                {...sliderSettings}
              >
                {typeof window !== 'undefined' &&
                  window.location &&
                  this.renderItems()}
              </Slider>
            </div>
          </Section>
        )}
      </div>
    );
  }
}

export default Reasons;
