import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, Link, Paragraph, Heading } from 'components';
import { getArticleSlug, getCloudinaryUrl } from 'config/helpers';
import './Navigation.scss';

class Navigation extends Component {
  getCurrentPostCount = (currentSlug, allPosts) =>
    allPosts.findIndex(item => {
      const slug = item.node.frontmatter.settings.slug
        ? item.node.frontmatter.settings.slug
        : item.node.fields.slug;
      return slug === currentSlug;
    });

  render() {
    const { currentSlug, allPosts } = this.props;
    const currentPostCounter = this.getCurrentPostCount(currentSlug, allPosts);
    const nextArticle =
      allPosts[currentPostCounter + 1] &&
      allPosts[currentPostCounter + 1].node !== 'undefined'
        ? allPosts[currentPostCounter + 1]
        : '';
    const previousArticle = currentPostCounter
      ? allPosts[currentPostCounter - 1]
      : '';

    return (
      <>
        <Section spaceless stick>
          {nextArticle && (
            <Link to={getArticleSlug(nextArticle)}>
              <Section
                additionalClass={'navigation__next'}
                background={getCloudinaryUrl(
                  nextArticle.node.frontmatter.cover,
                  'f_auto,q_auto,w_1920',
                )}
                spaceless
              >
                <div className={'navigation__inner'}>
                  <Paragraph
                    color={'white'}
                    additionalClass={'navigation__caption'}
                  >
                    NEXT
                  </Paragraph>
                  <Heading type={2} additionalClass={'navigation__title'}>
                    {nextArticle.node.frontmatter.title}
                  </Heading>
                </div>
              </Section>
            </Link>
          )}
          {previousArticle && (
            <Link to={getArticleSlug(previousArticle)}>
              <Section
                color={'pigeon-post'}
                additionalClass={'navigation__previous'}
                spaceless
              >
                <div className={'navigation__inner'}>
                  <Paragraph
                    color={'white'}
                    additionalClass={
                      'navigation__caption navigation__caption--small'
                    }
                  >
                    PREVIOUS
                  </Paragraph>
                  <Heading
                    type={2}
                    additionalClass={
                      'navigation__title navigation__title--small'
                    }
                  >
                    {previousArticle.node.frontmatter.title}
                  </Heading>
                </div>
              </Section>
            </Link>
          )}
        </Section>
      </>
    );
  }
}

Navigation.defaultProps = {
  allPosts: [],
  nextArticle: null,
  previousArticle: null,
};

Navigation.propTypes = {
  allPosts: PropTypes.array,
  currentSlug: PropTypes.string.isRequired,
  nextArticle: PropTypes.shape(),
  previousArticle: PropTypes.shape(),
};

export default Navigation;
