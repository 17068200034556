import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { classNames } from 'utils';
import CountUp from 'react-countup';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import './Design.scss';
import withScrollAnimation from './HOC';

class Design extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.oneida__design-image--first').on('enter', () => {
      this.setState({ enteredFirst: true });
    });
    inView('.oneida__design-container').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  render() {
    const { image } = this.props;
    const { enableCounterAnimation, enteredFirst } = this.state;
    const withCounter = number =>
      enableCounterAnimation && (
        <span>
          <CountUp end={number} delay={0.3} />
        </span>
      );

    return (
      <Section additionalClass={'oneida__design-container'}>
        <div className={'oneida__content oneida__design-intro'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Design process
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            {withCounter(30)}% of project scope spent on architecture and
            wireframing
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We always put user in the first place and that’s the way we design
            our products. Our scope helps user to understand the whole site and
            makes it easy to navigate. It is very important for us to not let
            user get lost on the main page.
          </Paragraph>
        </div>
        <FullwidthImage
          image={getCloudinaryUrl('assets/oneida/pic-2.jpg', 'f_auto,q_auto')}
          alt={'Architecture'}
          additionalClass={classNames('oneida__design-image--first', {
            'oneida__design-image--entered': enteredFirst,
          })}
        />
        <div className={'oneida__design-info'}>
          <div className={'oneida__content'}>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              We worked on three technologies - JavaScript, AngularJS and HTML5
              to provide fluid user experience.
            </Paragraph>
          </div>
          <FullwidthImage
            image={getCloudinaryUrl(
              'assets/oneida/tabletop-process-1.jpg',
              'f_auto,q_auto',
            )}
            alt={'Architecture'}
            additionalClass={
              'oneida__design-image--second anim-fade-in-bottom'
            }
          />
          <img
            src={getCloudinaryUrl(
              'assets/oneida/tabletop-process-2.png',
              'f_auto,q_auto',
            )}
            alt={'App'}
            className={'image oneida__design-image--third'}
            innerRef={image}
            loading={'lazy'}
          />
        </div>
      </Section>
    );
  }
}

Design.propTypes = {
  image: PropTypes.object.isRequired,
};

export default withScrollAnimation(Design);
