import React from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';
import { classNames } from 'utils';

const Checkbox = ({
  additionalClass,
  size,
  label,
  name,
  onChange,
  value,
  disabled,
  required,
  checked,
  tag,
}) => {
  return (
    <div className={classNames('checkbox__wrapper', additionalClass)}>
      <label
        className={classNames('checkbox', {
          'checkbox--checked': checked,
          'checkbox__input--disabled': disabled,
          'checkbox--tag': tag,
          'checkbox--required': required,
        })}
      >
        <div className={'checkbox__input'}>
          <input
            type={'checkbox'}
            style={{ width: size, height: size }}
            name={name}
            checked={checked}
            value={value}
            onChange={() => onChange(value)}
            disabled={disabled}
            required={required}
          />
          <span />
        </div>
        <p className={'checkbox__label'}>{label}</p>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  size: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  tag: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  additionalClass: '',
  checked: false,
  children: null,
  disabled: false,
  label: '',
  name: null,
  tag: false,
  required: false,
  size: '1em',
};

export default Checkbox;
