import TuiLogo from '../../../clients/logos/tui-logo.svg';
import PrismaLogo from '../../../clients/logos/prisma-logo.svg';
import TakamolLogo from '../../../clients/logos/takamol-logo.svg';
import MaxeonLogo from '../../../clients/logos/maxeon-logo.svg';

export const COMPANIES = [
  {
    name: 'Maxeon',
    photo: MaxeonLogo,
    description:
      "Maxeon, a global solar innovation leader with 1000+ patents, serves customers in over 100 countries. As their partner, we've consulted on and developed top-notch solutions for their customers.",
  },
  {
    name: 'Prisma',
    photo: PrismaLogo,
    description:
      "We're a long-term partner to PRISMA, a leading European gas trading platform with the mission to secure Europe’s energy supply and enable a green transition for a sustainable future.",
  },
  {
    name: 'Takamol',
    photo: TakamolLogo,
    description:
      "We're a strategic digital partner to Takamol Holding, a Saudi Arabian government company. Working in close partnership we develop web products that drive true change in the Saudi labor market.",
  },
  {
    name: 'TUI',
    photo: TuiLogo,
    description:
      'Our strategic partnership with TUI Musement, a global leader in local tours operation in 80 countries worldwide, was aimed to enter a new market segment and enrich travel experiences.',
  },
];
