import React from 'react';
import PropTypes from 'prop-types';
import { IconSVG, Paragraph, Heading, Link } from 'components';
import ReactHtmlParser from 'react-html-parser';
import { classNames } from 'utils';
import './Testimonial.scss';

const Testimonial = ({
  additionalClass,
  color,
  content,
  author,
  icon,
  headingSize,
  source,
  id,
}) => {
  const iconClass = icon ? '' : 'testimonial--no-icon';

  return (
    <div className={classNames('testimonial', iconClass)}>
      <IconSVG name={'quote'} size={'medium'} color={color} />
      <Heading
        size={headingSize}
        color={color}
        additionalClass={classNames('testimonial__content', additionalClass)}
      >
        {ReactHtmlParser(content)}
      </Heading>
      {author && (
        <Paragraph size={'small-plus'} color={color}>
          {author}
        </Paragraph>
      )}
      {source && (
        <Link
          id={id}
          to={source}
          additionalClass={classNames(
            {
              [`text--${color}`]: color,
            },
            'testimonial__link',
          )}
          underline={color}
        >
          <span>(source)</span>
        </Link>
      )}
    </div>
  );
};

Testimonial.defaultProps = {
  additionalClass: '',
  color: '',
  headingSize: 'medium',
  icon: true,
};

Testimonial.propTypes = {
  additionalClass: PropTypes.string,
  content: PropTypes.string.isRequired,
  author: PropTypes.string,
  color: PropTypes.string,
  headingSize: PropTypes.string,
  icon: PropTypes.bool,
};

export default Testimonial;
