import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Tocbot from 'tocbot';
import { SEO, RelatedArticles } from 'components';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { ArticleContent, Navigation } from './subcomponents';
import './SingleArticle.scss';

const ArticleTemplate = ({
  post,
  postList,
  author,
  authorAdditional,
  allArticles,
  allBlogCategories,
  localSearchPages,
  canonicalPath,
}) => {
  const [location, setLocation] = useState('');
  const { cover, title, settings, tileDescription } = post.frontmatter;
  const slug = settings.slug || post.fields.slug;
  const toggleTocClass = (toc, tocElements) => {
    return tocElements.length < 2
      ? toc.classList.add('disabled')
      : toc.classList.add('remove');
  };

  useEffect(() => {
    enableAnimations();
    const toc = document.querySelector('.article-content__toc');
    const tocElements = [];
    setLocation(window.location.href);

    Tocbot.init({
      tocSelector: '.article-content__toc-wrapper',
      contentSelector: '.single-article__container',
      ignoreSelector: '.no-toc',
      headingSelector: 'h2',
      headingsOffset: 120,
      scrollSmoothOffset: -120,
      scrollSmooth: true,
      scrollSmoothDuration: 1000,
      collapseDepth: 6,
      orderedList: true,
      headingObjectCallback: obj => {
        tocElements.push(obj);
        return obj;
      },
    });

    toggleTocClass(toc, tocElements);

    return () => {
      Tocbot.destroy();
    };
  }, []);

  return (
    <div className={'single-article'}>
      <SEO
        title={settings.metaTitle || title}
        description={settings.metaDescription}
        tile={tileDescription}
        cover={getCloudinaryUrl(cover, 'f_auto,q_auto,w_400')}
        slug={slug}
        section={'blog'}
        canonical={canonicalPath ?? settings.canonical}
        cloudinary
      />
      <ArticleContent
        windowLocation={location}
        data={post.frontmatter}
        author={author}
        authorAdditional={authorAdditional}
        allPosts={postList}
        allArticles={allArticles}
        allBlogCategories={allBlogCategories}
        localSearchPages={localSearchPages}
      />
      {!(isEmpty(allArticles) || isEmpty(settings.related)) && (
        <RelatedArticles
          id={'cs-related'}
          related={settings.related}
          allArticles={allArticles}
        />
      )}
      {post.frontmatter.specialArticle === false && (
        <Navigation currentSlug={slug} allPosts={postList} />
      )}
    </div>
  );
};

ArticleTemplate.propTypes = {
  post: PropTypes.shape().isRequired,
  postList: PropTypes.array.isRequired,
  allArticles: PropTypes.object,
  author: PropTypes.shape().isRequired,
  authorAdditional: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  allBlogCategories: PropTypes.object.isRequired,
  localSearchPages: PropTypes.shape({
    index: PropTypes.string,
    store: PropTypes.object,
  }).isRequired,
  canonicalPath: PropTypes.string,
};

ArticleTemplate.defaultProps = {
  authorAdditional: null,
  allArticles: null,
  canonicalPath: null,
};

export default ArticleTemplate;
