import React, { Component } from 'react';
import inView from 'in-view';
import { classNames } from 'utils';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Technology.scss';

class Technology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.pe__technology-tiles').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderTiles = () =>
    data.map(item => (
      <div
        className={classNames('pe__technology-box', {
          'pe__technology-box--entered': this.state.entered,
        })}
        key={`pe__technology-${item.image}`}
      >
        <img
          src={getCloudinaryUrl(item.img, 'f_auto,q_auto')}
          alt={item.name}
          className={'image pe__technology-logotype'}
          loading={'lazy'}
        />
        <Heading type={3} size={'medium'} color={'mine-shaft'}>
          {item.name}
        </Heading>
      </div>
    ));

  render() {
    return (
      <>
        <Section additionalClass={'pe__technology-container'}>
          <div className={'pe__content'}>
            <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
              Technology
            </Heading>
            <Heading type={2} size={'big-spreaded'}>
              We've built complexed search travel offers
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Main Square goal was to show the diversity of tenders. Next to
              the "ordinary" search engine we created the interactive maps. Now
              the users can find the recommended location connected with the
              seasons. Thank that the users can choose the perfect destination!
            </Paragraph>
          </div>
        </Section>
        <Section additionalClass={'pe__technology-images'} spaceless>
          <div className={'pe__content--left'}>
            <img
              src={getCloudinaryUrl(
                'logos/planet-escape-d3.png',
                'f_auto,q_auto',
              )}
              alt={'D3.js logo'}
              className={'image pe__technology-logo'}
              loading={'lazy'}
            />
            <Paragraph
              additionalClass={'pe__technology-subtitle'}
              size={'medium-constant'}
              color={'white'}
            >
              Javascript library
            </Paragraph>
            <Paragraph
              additionalClass={'pe__technology-description'}
              size={'medium-constant'}
              color={'white'}
            >
              For manipulating documents based on data
            </Paragraph>
            <div className={'pe__technology-line'} />
            <Paragraph
              additionalClass={'pe__technology-information'}
              size={'small-plus'}
              color={'white'}
            >
              - it helps us to create the interactive maps
              <br />- maps helps to find the best direction to travel
            </Paragraph>
          </div>
          <div className={'pe__technology-image'}>
            <FullwidthImage
              image={getCloudinaryUrl(
                'assets/planet-escape/planet-escape-map2.png',
                'f_auto,q_auto',
              )}
              alt={'Architecture'}
              additionalClass={classNames('anim-fade-in-right', {
                'in-view': this.state.entered,
              })}
            />
          </div>
        </Section>
        <Section additionalClass={'pe__technology-tiles'} spaceless>
          {this.renderTiles()}
        </Section>
      </>
    );
  }
}

export default Technology;
