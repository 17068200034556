export const data = [
  {
    title: 'How-it-works page',
    text:
      'Understandable infographics leads to immediate discern in the main possibilities available on our website.',
    image: 'pic-4-left',
  },
  {
    title: 'Simple tutorial design',
    text:
      'Our tutorial will point you just to the right places in the blink of an eye with short and uncomplicated sentences.',
    image: 'pic-4-right',
  },
];
