import React, { Component } from 'react';
import { Section, Heading, Link, Button } from 'components';
import { data } from './logic';
import './Related.scss';

class Related extends Component {
  renderItems = () =>
    data.map(item => (
      <Link
        to={item.link}
        title={item.title}
        additionalClass={'awwwards-lp-related__article'}
      >
        <div className={'awwwards-lp-related__item'}>
          <Heading
            additionalClass={'awwwards-lp-related__heading'}
            color={'white'}
            type={'2'}
            size={'medium-plus'}
          >
            {item.title}
          </Heading>
          <Button
            order={'secondary'}
            color={'white'}
            additionalClass={'awwwards-lp-related__read'}
          >
            Read article
          </Button>
        </div>
      </Link>
    ));

  render() {
    return (
      <Section
        additionalClass={'awwwards-lp-related'}
        containerClass={'awwwards-lp-related__container'}
        color={'mine-shaft'}
      >
        <Heading
          color={'white'}
          type={'2'}
          size={'large'}
          lineColor={'yellow'}
          additionalClass={'anim-fade-in-left awwwards-lp__heading'}
        >
          Related Articles
        </Heading>
        <div className={'awwwards-lp-related__list anim-fade-in-bottom'}>
          {this.renderItems(data)}
        </div>
      </Section>
    );
  }
}

export default Related;
