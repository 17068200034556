import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BOX_TYPES } from 'config';
import { getArticleSlug } from 'config/helpers';
import { classNames } from 'utils';
import { Article, Brief, CaseStudy } from 'components/WorkBoxes';
import {
  Button,
  Link,
  LoadMorePagination,
  Section,
  ReviewBox,
} from 'components';
import { FiltersBoxB } from 'views/work/subcomponents/CaseStudies/subcomponents';

import './CaseStudies.scss';
import BOX_TYPE from 'config/constants/boxTypes';
import { CASE_STUDIES_ITEMS } from './data';

import { parseFilterFromHash, parseRegionFilterToHash } from './utils';
import { useCaseStudies } from './hooks/useCaseStudies';
import { useSplitTest } from './hooks/useSplitTest';

const WorkLoader = ({ loading, isVariantB, children }) => {
  return loading ? (
    <div
      className={classNames('work__loader', {
        'work__loader--sticky': isVariantB,
      })}
    >
      <span className={'work__loader-circle'} />
    </div>
  ) : (
    children
  );
};

const CaseStudies = ({
  allArticles,
  caseStudies,
  authors,
  isVariantB,
  activeFilter,
  isFakeLoading,
  isDesktop,
  onActiveFilterChange,
}) => {
  const { data, updateFilters, userRegion } = useCaseStudies({
    data: [...caseStudies, ...CASE_STUDIES_ITEMS],
    articles: allArticles,
    onFilterChange: onActiveFilterChange,
    boxPattern: [
      // represents how different box types will be arranged on the list
      // (ordering is handled internally based on priorities)
      // 3 x case study
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      // then 3 x case study
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.CASE_STUDY,
      // then case study, brief, review
      BOX_TYPE.CASE_STUDY,
      BOX_TYPE.BRIEF,
      BOX_TYPE.REVIEW,
      // then brief, blog, case study
      BOX_TYPE.BRIEF,
      BOX_TYPE.BLOG,
      BOX_TYPE.CASE_STUDY,
      // and then it repeats
    ],
  });

  const handleFilterSelected = useCallback(
    (e, label, filter, navigatePage) => {
      e.preventDefault();
      window.history.replaceState({}, '', label === null ? '' : e.target.href);
      navigatePage(1);
      updateFilters(label, filter);
    },
  );

  const handleFilterUpdate = (filter) => {
    if (filter) {
      updateFilters(filter.label, filter.filter);
    }
  };

  useEffect(() => {
    // load filter from path on first render
    let parsedFilter = parseFilterFromHash(window.location.hash);

    handleFilterUpdate(parsedFilter);

    if (!parsedFilter && userRegion) {
      window.history.replaceState({}, '', parseRegionFilterToHash(userRegion));
      parsedFilter = parseFilterFromHash(window.location.hash);
      handleFilterUpdate(parsedFilter);
    }
  }, [userRegion]);

  const triggerRef = useSplitTest({
    event: `${isVariantB ? 'case-studies-b' : 'case-studies-a'}-display`,
    key: 'wasCaseStudiesSeen',
  });

  return (
    <LoadMorePagination index={'case-studies'} loadCount={9} data={data}>
      {({ items, loadMore, navigatePage }) => (
        <div
          ref={triggerRef}
          className={'work__case-studies work__case-studies--columns'}
        >
          <section className={'work__filters'}>
            <FiltersBoxB
              key={'variant-b'}
              onFilterSelect={handleFilterSelected}
              navigatePage={navigatePage}
              activeFilter={activeFilter}
              isDesktop={isDesktop}
            />
          </section>
          <Section
            id={'work__content'}
            additionalClass={'work__content work__content--side'}
            spaceless
          >
            <WorkLoader loading={isFakeLoading || !userRegion} isVariantB={isVariantB}>
              <div className={'work__container work__container--sticky'}>
                <div
                  id={'case-studies'}
                  className={'work__grid-boxes work__grid-boxes--sticky'}
                >
                  {items.length > 0 &&
                    items.map(post => (
                      <>
                        {post.type === BOX_TYPES.BLOG && (
                          <Article
                            key={`blog-post-${post.id}`}
                            type={post.type}
                            post={post.articleData}
                            link={getArticleSlug(post.articleData)}
                            authors={authors}
                          />
                        )}
                        {post.type === BOX_TYPES.CASE_STUDY && (
                          <CaseStudy
                            key={`case-study-${post.id}`}
                            post={post}
                            onFilterSelect={handleFilterSelected}
                            navigatePage={navigatePage}
                          />
                        )}
                        {post.type === BOX_TYPES.REVIEW && (
                          <ReviewBox data={post} key={`review-${post.id}`} />
                        )}
                        {post.type === BOX_TYPES.BRIEF && (
                          <Brief
                            key={`brief-${post.id}`}
                            post={post}
                            onFilterSelect={handleFilterSelected}
                            navigatePage={navigatePage}
                          />
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div className={'work__button-wrapper'}>
                {loadMore && (
                  <div className={'work__button'}>
                    <Button
                      tag={Link}
                      to={loadMore.path}
                      color={'yellow'}
                      onClick={loadMore.action}
                      ariaName={'Load more button'}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </WorkLoader>
          </Section>
        </div>
      )}
    </LoadMorePagination>
  );
};

CaseStudies.defaultProps = {
  allArticles: [],
  authors: [],
  caseStudies: [],
  isVariantB: false,
  activeFilter: null,
  isFakeLoading: false,
  isDesktop: false,
  onActiveFilterChange: () => {},
};

CaseStudies.propTypes = {
  allArticles: PropTypes.array,
  caseStudies: PropTypes.array,
  authors: PropTypes.array,
  isVariantB: PropTypes.bool,
  activeFilter: PropTypes.string,
  isFakeLoading: PropTypes.bool,
  isDesktop: PropTypes.bool,
  onActiveFilterChange: PropTypes.func,
};

export default CaseStudies;
