import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Architecture.scss';

class Architecture extends Component {
  render() {
    return (
      <Section additionalClass={'pt__architecture-container'}>
        <Heading type={2} size={'large'}>
          Every{' '}
          <span
            className={'marker-highlight marker-highlight--yellow pt__marker'}
          >
            good relationship
          </span>{' '}
          starts with getting to know each other (and with good architecture
          and wireframes!)
        </Heading>
        <div
          className={'pt__architecture-container__content pt__column-mobile'}
        >
          <img
            className={'anim-fade-in-left'}
            src={getCloudinaryUrl(
              'assets/practitest/practitest-architecture.png',
              'f_auto,q_auto',
            )}
            alt={'Architecture'}
            loading={'lazy'}
          />
          <div>
            <Paragraph
              size={'medium-plus'}
              color={'mine-shaft'}
              additionalClass={'text--bold paragraph'}
            >
              To make sure that our product design meets real business needs,
              we work according to our independently created process. It starts
              with a product discovery workshop, where the product vision is
              set out and deeply analyzed.
            </Paragraph>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              This provides a great foundation for the information architecture
              and wireframes, including all user flows and paths. One could
              call it multi-step verification of the client’s needs and
              actually that would be more than right, with one additional point
              - it is a real way to check user needs, based on previous
              experience, data, and knowledge gathered by our client over many
              years of running their business.
            </Paragraph>
          </div>
        </div>
      </Section>
    );
  }
}

export default Architecture;
