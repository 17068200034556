import Prizes from './Prizes';
import Hero from './Hero';
import MainBanner from './MainBanner';
import FeaturedIn from './FeaturedIn';
import NewsSlider from './NewsSlider';
import Work from './Work';
import Culture from './Culture';
import About from './About';
import WhatWeDo from './WhatWeDo';
import Approach from './Approach';
import CompetitiveAdvantages from './CompetitiveAdvantages';

export {
  Prizes,
  Hero,
  MainBanner,
  NewsSlider,
  FeaturedIn,
  WhatWeDo,
  Work,
  Culture,
  About,
  Approach,
  CompetitiveAdvantages,
};
