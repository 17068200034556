import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import * as Scroll from 'react-scroll';
import slugify from 'slugify';
import { SectionHeader, Section } from 'components';
import { Planet, Satelite } from 'components/DS';
import { classNames } from 'utils';
import { service } from './logic';

import './Banner.scss';

const SpyLink = Scroll.Link;

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true,
      scrollPosition: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: item,
      });
    }
  };

  handleScroll = () => {
    const scrollOffset = document.getElementById('services-hero').clientHeight;

    const { show } = this.props;

    if (show) this.setState({ isTop: window.scrollY < scrollOffset - 80 });
    else if (!show)
      this.setState(prevState => ({
        isTop:
          window.scrollY === 0 || window.scrollY > prevState.scrollPosition,
        scrollPosition: window.scrollY,
      }));
  };

  renderFilters = items => {
    const navHeight = 150;

    const filters = items.map(filter => (
      <SpyLink
        id={`filter-${slugify(`${filter}`, { lower: true })}`}
        to={`section-${slugify(`${filter}`, { lower: true })}`}
        spy
        smooth
        duration={500}
        offset={-navHeight}
        className={'link services__filters-item text--white'}
        activeClass={'services__filters-item--active'}
        onClick={() => this.handleClick(filter)}
      >
        {filter}
      </SpyLink>
    ));

    return <div className={'services__filters-list'}>{filters}</div>;
  };

  render() {
    const { isTop } = this.state;
    const { id, link } = this.props;

    return (
      <div id={'services-hero'}>
        <Section
          color={'mine-shaft'}
          additionalClass={'services__banner'}
          noOverflow
        >
          <div className={'services__banner-planet'}>
            <Planet theme={'light'} planetColor={'red'} />
          </div>
          <div className={'services__banner-subtitle'}>
            <h1>OUR SERVICES</h1>
          </div>
          <SectionHeader color={'white'} centered noLine size={'large'}>
            We fuel the change in your{' '}
            <span className={'marker-highlight marker-highlight-a'}>
              business
            </span>{' '}
          </SectionHeader>
          <div className={'services__banner-satelite'}>
            <Satelite theme={'light'} sateliteColor={'red'} />
          </div>
          <div className={'services__banner__scroll'}>
            <div className={'services__banner__scroll-line'} />
            <div className={'services__banner__scroll-text'}>SCROLL DOWN</div>
            <div className={'services__banner__scroll-line'} />
            <div className={'services__banner__scroll-rectangle'} />
          </div>
        </Section>
        <div className={'services__filters-bar'}>
          <div
            className={classNames('services__filters', {
              'services__filters--sticky': !isTop,
            })}
          >
            <div className={'w-container'}>{this.renderFilters(service)}</div>
          </div>
        </div>
      </div>
    );
  }
}

Banner.defaultProps = {
  show: true,
};

Banner.propTypes = {
  show: PropTypes.bool,
};

export default Banner;
