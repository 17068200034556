import React, { Component } from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Choice.scss';

class Choice extends Component {
  render() {
    return (
      <Section additionalClass={'oneida__choice'} spaceless>
        <div className={'oneida__choice-container'}>
          <div className={'oneida__content'}>
            <Heading type={2} size={'big-spreaded'}>
              Our Client always
              <br />
              has a variety choice
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              Preparing several different moodboards was helpful for our client
              to make the right decision while choosing the most suitable
              design.
            </Paragraph>
          </div>
          <FullwidthImage
            alt={"Application's view"}
            image={getCloudinaryUrl(
              'assets/oneida/transparent-3.png',
              'f_auto,q_auto',
            )}
            additionalClass={'anim-fade-in-bottom'}
          />
        </div>
      </Section>
    );
  }
}

export default Choice;
