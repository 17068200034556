import Banner from './Banner';
import BannerF2F from './BannerF2F';
import BoldareTech from './BoldareTech';
import Different from './Different';
import Carousel from './Carousel';
import Contact from './Contact';
import CountDown from './CountDown';
import FCPDSection from './FCPDSection';
import Instagram from './Instagram';
import International from './International';
import Offers from './Offers';
import Popup from './Popup';
import SingleOffer from './SingleOffer';
import Technology from './Technology';
import SumUp from './SumUp';
import Video from './Video';
import TalentPool from './TalentPool';
import TalentPoolBenefits from './TalentPoolBenefits';
import TalentPoolProcess from './TalentPoolProcess';
import TalentPoolRecruiters from './TalentPoolRecruiters';
import ContactUs from './ContactUs';
import Locations from './Locations';
import PodcastSection from './PodcastSection';
import IdeasSliderSection from './IdeasSlider';

export {
  Banner,
  BannerF2F,
  BoldareTech,
  Different,
  Carousel,
  Contact,
  SumUp,
  CountDown,
  FCPDSection,
  Instagram,
  International,
  Offers,
  Popup,
  SingleOffer,
  Technology,
  Video,
  TalentPool,
  TalentPoolBenefits,
  TalentPoolProcess,
  TalentPoolRecruiters,
  ContactUs,
  Locations,
  PodcastSection,
  IdeasSliderSection,
};
