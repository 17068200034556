import React from 'react';
import Layout from 'components/layout';
import { NavBar, FooterMini } from 'components';
import { pages } from 'config/pages';
import ApbCommunityNewsletter from 'views/apbCommunityNewsletter';
import config from '../../meta/config';
import { apbCommunityNewsletterData } from '../data/apbCommunityNewsletterData';

const page = pages['apb-community-newsletter'];
const canonical = `${config.siteUrl}/apb-community-newsletter/`;

const ApbCommunityNewsletterPage = () => {
  const { navColors, button, siteCover } = page;
  const formHandler = page.formHandlers.default;

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <ApbCommunityNewsletter
        button={button}
        url={formHandler}
        pageData={apbCommunityNewsletterData}
        cover={siteCover}
      />
      <FooterMini />
    </Layout>
  );
};

export default ApbCommunityNewsletterPage;
