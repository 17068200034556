import React from 'react';
import * as Scroll from 'react-scroll';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { SectionHeader, Heading, Paragraph, Section } from 'components';
import './Outsourcing.scss';

const SpyLink = Scroll.Link;

const Outsourcing = ({ id, link }) => (
  <Section additionalClass={'sdo__outsourcing'} color={'pigeon-post'}>
    <Row className={'no-gap'}>
      <Col sm={12} md={6} className={'sdo__outsourcing-col--left'}>
        <SectionHeader
          size={'big'}
          lineColor={'yellow'}
          headingType={2}
          color={'mine-shaft'}
          description={
            '<a href="/blog/software-development-outsourcing-everything-you-should-know/">Outsourcing software development</a> can solve numerous problems which your business may face. The most crucial are:'
          }
          descriptionColor={'mine-shaft'}
        >
          Why you should
          <br />
          outsource your
          <br />
          software development?
        </SectionHeader>
        <Heading color={'mine-shaft'} size={'medium'} type={3}>
          Lack of an experienced in-house team
        </Heading>
        <Paragraph
          color={'mine-shaft'}
          size={'medium'}
          additionalClass={'text--light'}
        >
          When your project requires specific know-how and your in-house teams
          are struggling, you may consider outsourcing the project to an
          external company which can provide more experience and expertise. Our
          teams know how to deliver sophisticated solutions to our partners.
          One example is our cooperation with BlaBlaCar, in which this French
          unicorn had to scale their app fast to meet market demand but hadn't
          the programmers with necessary experience. Boldare helped BlaBlaCar
          to achieve their business goal.
        </Paragraph>
        <Heading color={'mine-shaft'} size={'medium'} type={3}>
          Time
        </Heading>
        <Paragraph
          color={'mine-shaft'}
          size={'medium'}
          additionalClass={'text--light'}
        >
          Time is another factor to consider. When your project has to be
          delivered quickly, finding a reliable partner might be the right
          decision to make. Boldare has a strong track record of working on
          time-sensitive projects. We delivered an MVP of an e-commerce
          platform for Takamol in just six weeks.
        </Paragraph>
      </Col>
      <Col sm={12} md={6} className={'sdo__outsourcing-col--right'}>
        <div className={'sdo__outsourcing-col--right-wrapper'}>
          <img
            src={getCloudinaryUrl(
              'services/software-development-outsourcing/meeting-team.jpg',
              'f_auto,q_auto',
            )}
            className={'image sdo__outsourcing-img'}
            alt={'Time'}
            loading={'lazy'}
          />
        </div>
        <Heading color={'mine-shaft'} size={'medium'} type={3}>
          Cost
        </Heading>
        <Paragraph
          color={'mine-shaft'}
          size={'medium'}
          additionalClass={'text--light'}
        >
          Another sensitive matter is the cost related to such projects.
          Recruiting and training new developers, especially for short-term one
          projects, is expensive and time-consuming. Hiring an external
          provider already set up and ready to work can help you meet your
          budget. Especially when you consider that hourly rates for offshore
          outsourcing are considerably lower than for nearshore (
          <a href={'/blog/how-much-does-it-cost-to-develop-app/'}>
            https://www.boldare.com/blog/how-much-does-it-cost-to-develop-app/
          </a>
          ).
        </Paragraph>
        <SpyLink
          id={id}
          to={link}
          spy={false}
          smooth
          duration={500}
          className={'link'}
        >
          <span
            className={'button button--primary button--yellow'}
            id={'sdo__contact-outsourcing'}
          >
            Hire our team
          </span>
        </SpyLink>
      </Col>
    </Row>
  </Section>
);

export default Outsourcing;
