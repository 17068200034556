import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import '../FormControl.scss';

const Textarea = ({
  additionalClass,
  hint,
  type,
  id,
  name,
  onChange,
  label,
  required,
  value,
}) => {
  return (
    <div className={classNames('form-control', additionalClass)}>
      <textarea
        className={'form-control__input'}
        type={type}
        name={name}
        id={id}
        onChange={e => onChange(e.target.value)}
        value={value}
        required={required}
      />
      <label
        className={classNames('form-control__label', {
          'form-control__label--float': value,
        })}
        htmlFor={id}
      >
        {label}
      </label>
      {hint && <p className={'form-control__hint'}>{hint}</p>}
    </div>
  );
};

Textarea.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  hint: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
};

Textarea.defaultProps = {
  additionalClass: '',
  hint: null,
  value: '',
  required: false,
};

export default React.memo(Textarea);
