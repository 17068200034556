export const clients = [
  {
    image: 'Blablacar',
    img: 'logos/blablacar-color.png',
  },
  {
    image: 'Sonnen',
    img: 'logos/sonnen-logo.png',
  },
  {
    image: 'Takamol',
    img: 'logos/takamol-black.png',
    big: true,
  },
  {
    image: 'Polco',
    img: 'logos/polco-white.png',
    black: true,
  },
  {
    image: 'Ionoview',
    img: 'logos/iono-white.png',
    black: true,
  },
  {
    image: 'Holaspirit',
    img: 'logos/holaspirit-b_w.png',
  },
  {
    image: 'Cryo',
    img: 'logos/cryo.png',
    big: true,
  },
  {
    image: 'SpaMonkeys',
    img: 'logos/spa-monkeys.png',
  },
  {
    image: 'Fastr',
    img: 'logos/fastr.png',
    big: true,
  },
  {
    image: 'BrassWillow',
    img: 'logos/bw-logo.png',
  },
];
