import React from 'react';
import './MobileFooter.scss';
import { Button, Link } from 'components';
import IconSVG from '../IconSVG';
import config from '../../../meta/config';

const MobileFooter = () => {
  return (
    <div className={'footer-link-mobile'}>
      <Link to={'mailto:matt.hallmann@boldare.com@boldare.com'}>
        <Button color={'white'}>matt.hallmann@boldare.com &#8594;</Button>
      </Link>
      <a
        href={'https://www.linkedin.com/in/matthallmann/'}
        additionalClass={'footer-d__top-sm'}
        target={'_blank'}
        id={'footer-linkedin'}
        rel={'noreferrer'}
      >
        <IconSVG name={'linkedin'} size={'large'} color={'black'} />
      </a>
    </div>
  );
};

export default MobileFooter;
