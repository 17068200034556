import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import Career from 'views/career';
import { pages } from 'config/pages';
import config from '../../meta/config';

const { eventBanner } = config.career;

class CareerPage extends Component {
  render() {
    const page = pages.career;
    const { navColors } = page;
    const url = `${config.siteUrl}/career/`;
    const canonical = `${url}`;
    const navBarParams = { color: navColors };

    const { edges: offers } = this.props.data.allMarkdownRemark || {};

    if (eventBanner && eventBanner.display) {
      navBarParams.banner = {
        ...eventBanner,
      };
    }

    const paths = [
      { name: 'Meet Boldare', sectionId: 'meet-boldare' },
      { name: 'Offers', sectionId: 'offers' },
      { name: 'Talent pool', sectionId: 'talent-pool' },
      { name: 'Tech', sectionId: 'career-tech' },
      { name: 'Products', sectionId: 'career-match' },
      { name: 'Teamwork', sectionId: 'teamwork' },
      { name: 'Advantages', sectionId: 'advantages' },
    ];

    return (
      <Layout {...{ page, canonical }}>
        <NavBar
          {...navBarParams}
          additionalClass={'menu--career'}
          cookieBar={'sticky-career'}
          bannerHidden
          button={{
            link: '/career/offers/',
            id: 'ga-career--join-us',
            label: 'Join us',
            className: 'navbar--text-center',
          }}
          paths={paths}
        />
        <PopupRedirect message={'career'} />
        <Career offers={offers} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CareerPage {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        frontmatter: {
          templateKey: { eq: "job-offer-page" }
          mainContent: { activeOffer: { eq: true } }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
      edges {
        node {
          frontmatter {
            mainContent {
              activeOffer
              talentPool
              name
              photo
              cities
              contractType {
                contract
                salaries {
                  level
                  min
                  max
                }
              }
              banner
              technical {
                skill
              }
              additional {
                skill
              }
              specifics {
                specific
              }
              steps {
                number
                step
                details
              }
            }
          }
        }
      }
    }
  }
`;

export default CareerPage;
