import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import CountUp from 'react-countup';
import inView from 'in-view';
import { data } from './logic.js';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.showbiz__app-tiles').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  withCounter = item =>
    this.state.enableCounterAnimation && (
      <span>
        <CountUp
          end={item.number}
          delay={0.3}
          separator={' '}
          prefix={item.prefix || null}
          suffix={item.suffix || null}
        />
      </span>
    );

  renderTiles = () =>
    data.map((item, index) => (
      <div
        className={'showbiz__app-tile anim-fade-in-bottom'}
        key={`tile--${index}`}
      >
        <Heading type={2} size={'large'}>
          {this.withCounter(item)}
        </Heading>
        <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
          {item.text}
        </Heading>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'showbiz__app'} spaceless>
        <div className={'showbiz__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Functional approach
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            We built new app - for the admin and the user
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We designed and wrote a new application from scratch. From the
            user’s perspective - we made a new design of both the application
            and information pages. The most important thing was to build a user
            control panel, so that the registration and updates of data
            (especially photos) were simple and allowed downloading data and
            photos from Facebook. Obviously, the new design is responsive so
            the site looks modern and elegant.
          </Paragraph>
        </div>
        <div className={'showbiz__app-tiles'}>{this.renderTiles()}</div>
      </Section>
    );
  }
}

export default App;
