import React from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { NavBar, PopupRedirect } from 'components';
import SingleOffer from '../views/singleJobOffer';
import Layout from '../components/layout';
import { pages } from '../config/pages';
import config from '../../meta/config';

const { eventBanner } = config.career;

const JobOfferPage = ({ data }) => {
  const { markdownRemark: offerData } = data;
  const { activeOffer } = offerData.frontmatter.mainContent;
  const page = pages.career;
  const { navColors } = page;

  if (activeOffer === false && typeof window !== 'undefined') {
    navigate('/career/');
  }

  const navBarParams = { color: navColors };
  if (eventBanner && eventBanner.display) {
    navBarParams.banner = {
      ...eventBanner,
    };
  }

  return (
    <Layout page={page}>
      <NavBar bannerHidden {...navBarParams} />
      <PopupRedirect message={'job-offer'} />
      <SingleOffer offer={offerData} />
    </Layout>
  );
};

JobOfferPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default JobOfferPage;

export const pageQuery = graphql`
  query JobOfferByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        lead
        settings {
          date
          metaDescription
          metaTitle
          slug
          canonical
          category
          cover
        }
        mainContent {
          customIntroduction {
            visible
            heading
            industries {
              industry
            }
            description
            descriptionTwo
          }
          name
          cities
          contractType {
            contract
            salaries {
              level
              min
              max
            }
          }
          apply
          activeOffer
          talentPool
          technical {
            skill
          }
          additional {
            skill
          }
          specifics {
            specific
          }
          productStage {
            productStageVisibility
            productStageType
          }
          photo
          steps {
            number
            step
            details
          }
        }
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___title] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            settings {
              date
              metaDescription
              metaTitle
              slug
              canonical
              category
              cover
            }
            mainContent {
              customIntroduction {
                visible
                heading
                industries {
                  industry
                }
                description
                descriptionTwo
              }
              name
              cities
              contractType {
                contract
                salaries {
                  level
                  min
                  max
                }
              }
              apply
              technical {
                skill
              }
              additional {
                skill
              }
              specifics {
                specific
              }
              photo
              steps {
                number
                step
                details
              }
            }
          }
        }
      }
    }
  }
`;
