import React from 'react';
import PropTypes from 'prop-types';
import * as Scroll from 'react-scroll';
import { Section, SectionHeader, Paragraph, Button } from 'components';
import './Contact.scss';

const SpyLink = Scroll.Link;

const Contact = ({ background, title, content, cta, link, id }) => (
  <Section
    color={'yellow'}
    additionalClass={'dtu__contact--middle section--centered'}
    background={background}
  >
    <SectionHeader
      additionalClass={'headline--centered'}
      color={'black'}
      size={'big'}
      lineColor={'white'}
      headingType={2}
    >
      {title}
    </SectionHeader>
    <Paragraph
      additionalClass={'text--light paragraph--text-centered'}
      color={'black'}
      size={'medium'}
    >
      {content}
    </Paragraph>
    <SpyLink
      id={id}
      to={link}
      spy={false}
      smooth
      duration={500}
      className={'link link--centered'}
    >
      <Button
        id={'dtu-contact-btn'}
        color={'transparent'}
        ariaName={'Contact button'}
        additionalClass={'contact-button'}
      >
        {cta}
      </Button>
    </SpyLink>
  </Section>
);

Contact.defaultProps = {
  background: '',
  title: '',
  content: '',
  cta: '',
  link: '',
  scroll: false,
  id: '',
};

Contact.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
  scroll: PropTypes.bool,
  id: PropTypes.string,
};

export default Contact;
