import { BOX_TYPES } from 'config';

export const data = [
  {
    type: BOX_TYPES.DISCOVER,
    headline: 'Working increment every 2 weeks',
    description:
      'Change course any time during development and control your budget all the way.',
    link: '/blog/agile-process-for-digital-product-development/',
    id: 'working-increment',
  },
  {
    type: BOX_TYPES.IMAGE_CONTENT,
    // image: 'lean',
    image: '/gatsby-cloudinary/team-room.jpg',
    backgroundColor: 'gray-light',
    caption: 'Lean Startup',
    headline: 'Test a product hypothesis with minimal cost and effort',
    link: '/blog/lean-process-for-better-product/',
    id: 'box-lean-startup',
  },
  {
    type: BOX_TYPES.IMAGE_CONTENT,
    image: '/gatsby-cloudinary/team-meeting-preparation.jpg',
    backgroundColor: 'gray-light',
    caption: 'Impact mapping',
    headline:
      'Build a product that makes an impact and measure its business value',
    link: '/blog/build-product-that-make-impact/',
    id: 'box-impact-mapping',
  },
  {
    type: BOX_TYPES.IMAGE_CONTENT,
    image: '/gatsby-cloudinary/room-with-glass-walls.jpg',
    backgroundColor: 'gray-light',
    caption: 'Event storming',
    headline: 'One vision, one goal, one product.',
    description:
      'Fool-proof method of achieving cohesion between business and development teams.',
    link: '/blog/event-storming-guide/',
    id: 'box-event-storming',
  },
  {
    type: BOX_TYPES.IMAGE_CONTENT,
    image: '/gatsby-cloudinary/serivce-standards.jpg',
    backgroundColor: 'gray-light',
    caption: 'service standards',
    headline: "Boldare's Service Standards in a nutshell",
    description: '',
    link:
      '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/',
    id: 'box-service-standards',
  },
];
