import React from 'react';
import { Helmet } from 'react-helmet';
import NotFound from 'views/404';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['404'];
const { navColors } = page;
const canonical = `${config.siteUrl}/404/`;

const NotFoundPage = () => (
  <Layout {...{ page, canonical }}>
    <Helmet title={'404'}>
      <link rel={'canonical'} href={canonical} />
    </Helmet>
    <NavBar color={navColors} />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
