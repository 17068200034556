import React from 'react';
import PropTypes from 'prop-types';

import './HighlightNumber.scss';

export const HighlightNumber = ({ fill, textColor, children }) => (
  <span className={'highlight-number'} style={{ color: textColor }}>
    {children}
    <svg
      width={'54'}
      height={'54'}
      className={'highlight-number__svg'}
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 52 54'}
      fill={'none'}
    >
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M47.594 31.9188L6.34468 21.9952L7.27109 17.1411L48.5204 27.0646L47.594 31.9188Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M49.1321 35.1559L7.88277 25.2324L8.80918 20.3783L50.0585 30.3018L49.1321 35.1559Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M47.9905 26.2148L6.45414 33.8498L6.14622 28.8645L47.6826 21.2295L47.9905 26.2148Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M42.666 31.6092L1.19741 39.8436L0.846926 34.863L42.3155 26.6287L42.666 31.6092Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M51.3662 17.9532L10.3922 29.6475L9.79547 24.7067L50.7694 13.0124L51.3662 17.9532Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M39.0803 28.9495L9.33728 18.3842L10.8786 13.8139L40.6216 24.3792L39.0803 28.9495Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M44.5632 14.7752L13.9762 19.8281L14.0537 14.8387L44.6408 9.78581L44.5632 14.7752Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M43.868 23.2362L13.9538 13.5222L15.4176 8.90906L45.3317 18.623L43.868 23.2362Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M44.637 39.2362L14.7228 29.5222L16.1866 24.9091L46.1008 34.623L44.637 39.2362Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M41.7234 37.202L10.8944 36.5622L11.5155 31.6376L42.3445 32.2774L41.7234 37.202Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M38.6473 43.202L7.81827 42.5622L8.43933 37.6376L39.2683 38.2774L38.6473 43.202Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M41.4545 42.381L10.8872 37.13L11.9402 32.3311L42.5075 37.5821L41.4545 42.381Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M36.1312 47.0036L14.6741 40.1986L15.6029 37.0596L37.06 43.8646L36.1312 47.0036Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M32.0176 41.447L10.2087 45.9826L10.1297 42.6208L31.9386 38.0852L32.0176 41.447Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M32.6534 35.4702L10.8444 40.0058L10.7654 36.644L32.5744 32.1084L32.6534 35.4702Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M44.5632 17.7752L13.9762 22.8281L14.0537 17.8387L44.6408 12.7858L44.5632 17.7752Z'
        }
        clipRule={'evenodd'}
      />
      <path
        fill={fill}
        fillRule={'evenodd'}
        d={
          'M50.1313 26.0438L4.07773 31.0598L4.33803 23.9562L50.3916 18.9403L50.1313 26.0438Z'
        }
        clipRule={'evenodd'}
      />
    </svg>
  </span>
);

HighlightNumber.defaultProps = {
  fill: '#fff',
  textColor: '#000',
};

HighlightNumber.propTypes = {
  fill: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};
