import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResultReady, ResultNotReady } from './subcomponents';

import '../Survey/Survey.scss';
import './Result.scss';

const getResultComponent = phase =>
  phase === 'ready' ? <ResultReady /> : <ResultNotReady />;

const Result = ({ phase }) => {
  const [resultPhase] = React.useState(phase);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'pmf_survey_result',
      });
    }
  }, []);

  return (
    <div className={'pmf-survey__result w-container'}>
      {getResultComponent(resultPhase)}
    </div>
  );
};

Result.propTypes = {
  phase: PropTypes.string.isRequired,
};

export default Result;
