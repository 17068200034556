import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col } from 'react-flexbox-grid';
import Slider from 'react-slick';
import {
  Paragraph,
  SectionHeader,
  Section,
  IconSVG,
  SliderArrow,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic';
import './ClientQuotes.scss';

class NewsSlider extends Component {
  constructor() {
    super();

    this.state = {
      sliderSettings: {
        className: 'client-quotes',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow id={'client-qotes-next'} />,
        prevArrow: <SliderArrow id={'client-qotes-prev'} left />,
      },
    };
  }

  renderSlides = () =>
    data.map(item => (
      <div
        className={'dtu__client-quotes-slide-wrapper'}
        key={`news-slide-${item}`}
      >
        <div className={'dtu__client-quotes-slide'}>
          <div className={'dtu__client-quotes-item'}>
            <div className={'dtu__client-quotes-header'}>
              <IconSVG name={'quote'} size={'medium'} color={'mine-shaft'} />
              <img
                src={getCloudinaryUrl([item.image], 'f_auto,q_auto,w_60')}
                className={'image dtu__client-quotes-logo'}
                alt={item.author}
                loading={'lazy'}
              />
              <Paragraph
                color={'mine-shaft'}
                additionalClass={'dtu__client-quotes-author'}
              >
                {ReactHtmlParser(item.author)}
              </Paragraph>
            </div>
            <Paragraph
              color={'mine-shaft'}
              additionalClass={'dtu__client-quotes-content'}
              size={'medium'}
            >
              {ReactHtmlParser(item.quote)}
            </Paragraph>
          </div>
        </div>
      </div>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <Section
        color={'pigeon-post-second'}
        additionalClass={'dtu__client-quotes-section'}
      >
        <Row className={'no-gap'}>
          <Col sm={12} md={6}>
            <div className={'dtu__problem'}>
              <SectionHeader
                color={'mine-shaft'}
                size={'big'}
                lineColor={'black'}
                fixed
                headingType={2}
              >
                Clients about
                <br />
                our teams
              </SectionHeader>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <Slider {...sliderSettings}>{this.renderSlides()}</Slider>
          </Col>
        </Row>
      </Section>
    );
  }
}

NewsSlider.defaultProps = {
  data: null,
};

NewsSlider.propTypes = {
  data: PropTypes.array,
};

export default NewsSlider;
