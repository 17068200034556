import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { getCloudinaryUrl } from 'config/helpers';

import { footerElements } from 'views/home/subcomponents/Prizes/logic';

import './Footer.scss';

const Footer = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const elements = footerElements;

  const createListElement = (icon, alt, text) => {
    return (
      <motion.li
        key={icon}
        className={'prizes-footer__element'}
        ref={ref}
        animate={inView ? { opacity: 1, y: '0' } : { opacity: 0, y: `50px` }}
        transition={{ duration: 0.4 }}
      >
        <img
          src={getCloudinaryUrl(icon, 'w_288')}
          alt={alt}
          className={'prizes-footer__img'}
        />
        <p className={'prizes-footer__text'}>{text}</p>
      </motion.li>
    );
  };

  return (
    <div className={'prizes-footer'}>
      <div className={'prizes-footer__wrapper'}>
        <ul className={'prizes-footer__list'}>
          {elements.map(({ icon, text }) => {
            return createListElement(icon, `${icon} company logo`, text);
          })}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
