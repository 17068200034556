import React from 'react';
import PropTypes from 'prop-types';
import { Container, BackgroundImage } from 'components';
import { classNames } from 'utils';
import './Section.scss';

const Section = ({
  additionalClass,
  containerClass,
  background,
  children,
  color,
  fullwidth,
  spaceless,
  stick,
  side,
  spacedTop,
  spacedBottom,
  smaller,
  id,
  alt,
  backgroundMaxWidth,
}) => (
  <div
    id={id}
    className={classNames(
      'section',
      {
        'section--background': background,
        'section--spaceless': spaceless,
        'section--spaced-top': spacedTop,
        'section--spaced-bottom': spacedBottom,
        'section--smaller': smaller,
        [`section--${color}`]: color,
      },
      additionalClass,
      id,
    )}
  >
    {background && (
      <BackgroundImage
        asset={background}
        alt={alt}
        additionalClass={'section__background-image'}
      />
    )}
    <Container
      fullwidth={fullwidth}
      stick={stick}
      side={side}
      additionalClass={containerClass}
    >
      {children}
    </Container>
  </div>
);

Section.propTypes = {
  additionalClass: PropTypes.string,
  containerClass: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullwidth: PropTypes.bool,
  stick: PropTypes.bool,
  spaceless: PropTypes.bool,
  spacedTop: PropTypes.bool,
  spacedBottom: PropTypes.bool,
  id: PropTypes.string,
  alt: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  side: PropTypes.string,
  smaller: PropTypes.bool,
  backgroundMaxWidth: PropTypes.number,
};

Section.defaultProps = {
  additionalClass: '',
  containerClass: '',
  color: 'transparent',
  fullwidth: false,
  stick: false,
  side: '',
  spaceless: false,
  id: '',
  alt: '',
  background: '',
  children: null,
  spacedTop: false,
  spacedBottom: false,
  smaller: false,
  backgroundMaxWidth: null,
};

export default Section;
