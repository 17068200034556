import React, { useState } from 'react';
import { classNames } from 'utils';
import './Slider.scss';

const EtudesSection = () => {
  const types = ['1st Story', '2nd Story'];

  const [isActive, setActive] = useState(types[0]);

  return (
    <div className={'slider__container'}>
      <div className={'video__container'}>
        {isActive === types[0] && (
          <div className={'video__container-item'}>
            <iframe
              src={'https://www.youtube-nocookie.com/embed/IDv2lvbfQT8'}
              title={`Let's make products you can be proud of.`}
              allow={'fullscreen'}
            />
          </div>
        )}
        {isActive === types[1] && (
          <div className={'video__container-item'}>
            <iframe
              src={'https://www.youtube-nocookie.com/embed/qdCYK-BaeLs'}
              title={`Let's make products you can be proud of.`}
              allow={'fullscreen'}
            />
          </div>
        )}
      </div>
      <div className={'video__container-btn-group'}>
        {types.map(type => (
          <button
            className={classNames(
              `products-btn products-btn--primary products-btn--yellow`,
              {
                'in-active': isActive !== type,
              },
            )}
            onClick={() => setActive(type)}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EtudesSection;
