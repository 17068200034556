import React, { useEffect } from 'react';
import { enableAnimations } from 'config/helpers';

import Hero from './subcomponents/Hero';
import SessionOutcomes from './subcomponents/SessionOutcomes';
import TargetGroup from './subcomponents/TargetGroup';
import Form from './subcomponents/Form';
import Companies from './subcomponents/Companies';
import Numbers from './subcomponents/Numbers';
import WhyWorthRecommendation from './subcomponents/WhyWorthRecommendation';
import JoinSession from './subcomponents/JoinSession';
import SessionDeliverables from './subcomponents/SessionDeliverables';
import BottomBanner from './subcomponents/BottomBanner';

import './BookDiscoverySession.scss';

function BookDiscoverySession() {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'single-page recommend-client'}>
      <Hero />
      <TargetGroup />
      <SessionOutcomes />
      <SessionDeliverables />
      <JoinSession />
      <Form />
      <Companies />
      <WhyWorthRecommendation />
      <Numbers />
      <BottomBanner />
    </div>
  );
}

export default BookDiscoverySession;
