export default [
  // TILES
  {
    title: 'Reduce Overall Costs',
    subtitle:
      'For every dollar it costs to fix a problem during design, fixing the same problem after release costs ten to a hundred times that.',
    numbers: '50%',
    text:
      'Investing in UX during a project’s concept phase reduces product development cycles by 33 to 50%',
    source:
      'https://www.interaction-design.org/literature/article/improve-customer-experience-with-ux-investments-that-increase-roi',
    color: 'white',
    background: 'pigeon-post',
  },
  {
    title: 'Increase Conversions',
    subtitle:
      'A better-designed product stands out to would-be purchasers. Measure the difference by comparing before and after using metrics such as conversion rate or average revenue per user (ARPU).',
    numbers: '400%',
    text: 'A better UX design could increase the conversion by 400%',
    source:
      'https://www.forrester.com/report/The+Six+Steps+For+Justifying+Better+UX/-/E-RES117708',
    color: 'white',
    background: 'rodeo-dust',
  },
  {
    title: 'Long-term customer loyalty',
    subtitle:
      'Understanding user needs and delivering a solution shaped for a memorable experience is an approach which helps to generate loyalty, keeping your users emotionally connected with your brand.',
    numbers: '94%',
    text:
      'Design-related issues were listed by 94% of users as the main reason when approving or rejecting a product',
    source: 'https://www.crazyegg.com/blog/does-web-design-matter/',
    color: 'white',
    background: 'red',
  },
  {
    title: 'Reduce time-to-market',
    subtitle:
      'A design process based on an understanding of both user needs and UX best practices will save you time because there’s less to revise or correct during development and your product will be ready to go live faster.',
    numbers: '50%',
    text:
      'The time needed by developers to re-work or ‘fix’ a product can be reduced up to 50% by great UX design',
    source:
      'https://www.experiencedynamics.com/blog/2014/07/making-strong-business-case-roi-ux-infographic',
    color: 'white',
    background: 'martinique',
  },
  {
    title: 'Better return on investment',
    subtitle:
      'Your product’s ease of use and the number of the steps required to use it have a direct impact on sales and conversion rates. That’s why a user-friendly interface that fulfills a customers’ needs is more likely to bring you a better ROI.',
    numbers: '10,000%',
    text:
      'The return on every 1$ invested in UX design, according to Forrester Research',
    source:
      'https://www.forrester.com/report/The+Six+Steps+For+Justifying+Better+UX/-/E-RES117708',
    color: 'white',
    background: 'violet',
  },
];
