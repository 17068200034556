import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import { Contact, Content, Intro } from './subcomponents';
import './Corel.scss';

class Corel extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study corel__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/corel/corel_main.jpg',
            'f_auto,q_auto',
          )}
        />
        <Intro />
        <Content />
        <Contact />
      </div>
    );
  }
}

export default Corel;
