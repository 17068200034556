import React from 'react';
import PropTypes from 'prop-types';
import { EventForm } from 'components';
import { pageDescriptionData } from './subcomponents/PageDescription/logic';
import {
  PageCover,
  PageDescriptionHeader,
  PageDescription,
} from './subcomponents';

import './ApbCommunityNewsletter.scss';

const ApbCommunityNewsletter = ({ url, button, pageData, cover }) => {
  const { page, form } = pageData;
  const {
    image,
    imageDescription,
    description,
    logo,
    logoLabel,
  } = pageDescriptionData.header;

  return (
    <div className={`newsletter__background--${page.color}`}>
      <PageCover cover={cover} />
      <div className={'newsletter-signup'}>
        <div>
          <PageDescriptionHeader
            image={image}
            imageDescription={imageDescription}
            description={description}
            logo={logo}
            logoLabel={logoLabel}
          />
          <div className={'newsletter-signup__description'}>
            <PageDescription data={pageDescriptionData.description} />
          </div>
        </div>
        <div className={'newsletter-signup__container'}>
          <div className={'newsletter-signup__container--form'}>
            <EventForm
              {...{
                checkboxLabelWhiteColor: false,
                url,
                button: button.text?.toUpperCase(),
                btnColor: button.color,
                formHeading: form.heading,
                thanksDescription: form.thanksDescription,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApbCommunityNewsletter;

ApbCommunityNewsletter.propTypes = {
  url: PropTypes.string.isRequired,
  button: PropTypes.object.isRequired,
  pageData: PropTypes.object.isRequired,
  cover: PropTypes.string.isRequired,
};
