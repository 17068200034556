import React from 'react';
import PropTypes from 'prop-types';

import DateRange from 'assets/images/work/date_range.svg';

import './Date.scss';

const Date = ({ date }) => (
  <div className={'work-boxes-date'}>
    <img
      src={DateRange}
      alt={'calendar icon'}
      className={'work-boxes-date__icon'}
    />
    <time className={'work-boxes-date__time'}>{date}</time>
  </div>
);

Date.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Date;
