import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { Link, Button, Paragraph, Heading } from 'components';
import ReactHtmlParser from 'react-html-parser';
import { serialize, classNames } from '../../utils';
import './GateForm.scss';

class GateForm extends Component {
  constructor() {
    super();

    this.refForm = createRef();

    this.state = {
      isLoading: false,
    };
  }

  setGateOpen = () => {
    const { gateAddress } = this.props;
    const url = window.location.href;
    const joinChar = url.indexOf('?') > -1 ? '&' : '?';

    window.location.href = `${url + joinChar}${gateAddress}`;
  };

  handleSubmit = e => {
    e.preventDefault();

    const { url, trackingEvent, gateCatalog } = this.props;
    const form = this.refForm.current;

    this.setState({ isLoading: true });

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: serialize(form),
    })
      .then(() => {
        if (typeof window !== 'undefined') {
          if (window.datalayer && trackingEvent) {
            window.dataLayer.push({
              event: trackingEvent,
            });
          }

          if (window.fbq && typeof window.fbq === 'function') {
            window.fbq('track', 'GateOpened');
          }
        }

        if (window.location.pathname.indexOf(`/${gateCatalog}/`) !== -1) {
          this.setGateOpen();
        }
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      type,
      button,
      formHeading,
      webinarTitle,
      webinarContent,
      webinarButton,
      itemsQuestions,
      btnColor,
      webinarFormDescription,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <div className={'gate-form'}>
        <>
          <Heading
            size={'medium'}
            type={2}
            additionalClass={'gate-form__headline'}
          >
            {ReactHtmlParser(formHeading) || `Get your free ${type} now`}
          </Heading>
          {webinarFormDescription && (
            <Paragraph
              size={'medium'}
              additionalClass={'text--light gate-form__description'}
            >
              {webinarFormDescription}
            </Paragraph>
          )}
          <form
            className={'gate-form__inputs'}
            onSubmit={this.handleSubmit}
            ref={this.refForm}
          >
            <input
              type={'text'}
              name={'name'}
              placeholder={'First name'}
              required
            />
            <input
              type={'text'}
              name={'lastName'}
              placeholder={'Last name'}
              required
            />
            <input
              type={'email'}
              name={'email'}
              placeholder={'E-mail address'}
              required
            />
            <input
              type={'text'}
              name={'company'}
              placeholder={'Company name'}
              required
            />
            <Button
              id={'gate-form-submit'}
              type={'submit'}
              color={btnColor}
              additionalClass={classNames('gate-form__button', {
                loading: isLoading,
              })}
              ariaName={'Form button'}
              disabled={isLoading}
              loading
            >
              {button}
            </Button>
          </form>
          <Paragraph additionalClass={'gate-form__info text--light'}>
            {
              "We're committed to your privacy. Boldare uses the information you provide to us to contact you about our relevant content, products, and services."
            }
            {' Read our '}
            <Link to={'/privacy-policy/'} id={'gate-form-privacy-policy'}>
              privacy policy.
            </Link>
          </Paragraph>
        </>
      </div>
    );
  }
}

GateForm.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string.isRequired,
  formHeading: PropTypes.string,
  short: PropTypes.bool,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

GateForm.defaultProps = {
  short: false,
  formHeading: '',
  btnColor: 'white',
};

export default GateForm;
