import React from 'react';
import * as Scroll from 'react-scroll';
import { Button, Container, Paragraph, Heading, Image } from 'components';
import './style.scss';

const peopleData = [
  {
    avatar: `img/Olivier_Halupczok.jpg`,
    name: `Olivier Halupczok`,
    position: `Generative AI Engineer`,
  },
  {
    avatar: `kamil-szydlowski.png`,
    name: 'Kamil Szydłowski',
    position: 'Full Stack Developer',
  },
];

const SpyLink = Scroll.Link;

const PeopleBanner = ({ id, link }) => {
  return (
    <Container additionalClass={'people-banner__container'}>
      <div className={'people-banner'}>
        <Heading color={'white'} size={'big'}>
          Bring us your idea - we&apos;ll bring the solution
        </Heading>
        <div className={'people-banner__bottom-container'}>
          {peopleData.map(item => (
            <div className={'people-banner__item'}>
              <figure className={'people-banner__item-avatar'}>
                <Image
                  src={item.avatar}
                  width={'172'}
                  height={'172'}
                  alt={item.name}
                />
              </figure>
              <Heading color={'white'} size={'medium-plus-small'}>
                {item.name}
              </Heading>
              <Paragraph color={'white'}>{item.position}</Paragraph>
            </div>
          ))}
        </div>
        <div className={'people-banner__link-wrapper'}>
          <SpyLink
            id={id}
            to={link}
            spy={false}
            smooth
            duration={500}
            offset={-100}
            className={'link'}
          >
            <Button
              id={'people-banner-button'}
              color={'yellow'}
              ariaName={`Let's talk`}
              additionalClass={
                'people-banner__button people-banner__paragraph--uppercase'
              }
            >
              Let`s talk
            </Button>
          </SpyLink>
        </div>
      </div>
    </Container>
  );
};

export default PeopleBanner;
