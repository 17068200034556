import React from 'react';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Hero.scss';

const Hero = () => {
  return (
    <Section
      color={'mine-shaft'}
      spaceless
      fullwidth
      containerClass={'pt-hero__container'}
    >
      <div className={'pt-hero__header'}>
        <img
          src={getCloudinaryUrl(
            'assets/practitest/logo_white.png',
            'f_auto,q_auto',
          )}
          alt={'Practitest logo'}
          loading={'lazy'}
          className={'pt-hero__header--logo'}
        />
        <SectionHeader
          color={'white'}
          headingType={1}
          size={'large'}
          noLine
          hiddenDescription
        >
          A remote web design team dedicated to the client’s needs
        </SectionHeader>
      </div>
      <img
        className={'pt-hero__image pt__hide-mobile'}
        src={getCloudinaryUrl(
          'assets/practitest/practi-fox-narrow.png',
          'f_auto,q_auto',
        )}
        alt={'Fox'}
        loading={'lazy'}
      />
    </Section>
  );
};

export default Hero;
