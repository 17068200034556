import React from 'react';
import PropTypes from 'prop-types';

import './ArrowList.scss';

function ArrowList({ list }) {
  return (
    <ul className={'arrow-list'}>
      {list.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
}

ArrowList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArrowList;
