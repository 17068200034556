import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button } from 'components';
import companyData from '../../../../../meta/companyData';
import './Banner.scss';

const SpyLink = Scroll.Link;

const Banner = ({ id, link }) => (
  <>
    <SubpageBanner
      backgroundColor={'white'}
      image={getCloudinaryUrl(
        'img/new-normal-cover-photo.png',
        'f_auto,q_auto,w_1000',
      )}
      contentBackground={'pigeon-post'}
      additionalClass={'nn__banner'}
      alt={'New Normal in Practice'}
    >
      <SectionHeader
        size={'extra-large'}
        color={'mine-shaft'}
        lineColor={'red'}
        description={`In the post-COVID-19 era, companies are looking for ways to reconfigure their operations and meet radically changed demands for products and services. Leadership, organization, culture, and work itself need to adjust to the new reality.<br /><br />To help you transition to the "new normal," we are developing this dedicated space with resources based on our ${companyData.years_experience} years of practice operating as an agile and distributed organization.`}
        descriptionColor={'mine-shaft'}
        fixed
        additionalClass={'section-header__description--medium'}
      >
        New Normal <br />
        in Practice
      </SectionHeader>
      <SpyLink
        id={id}
        to={link}
        spy={false}
        smooth
        duration={500}
        offset={-100}
        className={'link'}
      >
        <Button
          id={'nn-contact-btn'}
          color={'yellow'}
          ariaName={'Contact button'}
          additionalClass={'contact-button'}
        >
          Explore New Normal
        </Button>
      </SpyLink>
    </SubpageBanner>
  </>
);

export default Banner;
