import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Heading, Paragraph, BackgroundImage, Button } from 'components';
import './BoxDiscover.scss';

class BoxDiscover extends Component {
  render() {
    const {
      background,
      backgroundColor,
      headline,
      description,
      color,
      buttonText,
      link,
      additionalClass,
      externalLink,
      id,
    } = this.props;

    return (
      <Box
        id={id}
        additionalClass={`box-discover ${additionalClass}`}
        backgroundColor={backgroundColor}
      >
        <div className={'box-discover__content'}>
          <a href={link}>
            <Heading color={color} size={'big'} type={3}>
              {ReactHtmlParser(headline)}
            </Heading>
          </a>
          <Paragraph
            color={color}
            additionalClass={'text--light box-discover__description'}
            spaced
          >
            {description}
          </Paragraph>
        </div>
        {background && (
          <BackgroundImage
            src={background}
            additionalClass={'box-discover__background'}
          />
        )}
        <div className={'box-discover__footer'}>
          {externalLink ? (
            <a href={link} rel={'nofollow'}>
              <Button color={color} transparent ariaName={'Box link'}>
                {buttonText}
              </Button>
            </a>
          ) : (
            <a href={link} rel={'nofollow'}>
              <Button order={'secondary'} color={color} ariaName={'Box link'}>
                {buttonText}
              </Button>
            </a>
          )}
        </div>
      </Box>
    );
  }
}

BoxDiscover.defaultProps = {
  buttonText: 'Read more',
  background: '',
  description: '',
  additionalClass: '',
  color: 'black',
  backgroundColor: '',
  id: '',
  link: '',
  externalLink: false,
};

BoxDiscover.propTypes = {
  buttonText: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  additionalClass: PropTypes.string,
  color: PropTypes.string,
  headline: PropTypes.string.isRequired,
  id: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  externalLink: PropTypes.bool,
};

export default BoxDiscover;
