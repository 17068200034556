import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button } from 'components';
import './Slack.scss';

const Slack = () => (
  <>
    <SubpageBanner
      backgroundColor={'white'}
      image={getCloudinaryUrl(
        'gatsby-cloudinary/code-audit-banner.jpg',
        'f_auto,q_auto,w_1400',
      )}
      contentBackground={'pigeon-post'}
      additionalClass={'nn__slack'}
      alt={'Code audit & UX audit'}
    >
      <SectionHeader
        size={'large'}
        color={'mine-shaft'}
        lineColor={'red'}
        description={`Knowledge is forged through practice. We want to meet with you and discuss the problems and cases you might have in the "new normal" reality. This is why we’ve created a dedicated Slack channel - a space where we can exchange ideas and experiences. Join us today!`}
        descriptionColor={'mine-shaft'}
        fixed
        additionalClass={'section-header__description--medium'}
      >
        Join our community on Slack!
      </SectionHeader>
      <Button
        id={'nn-contact-btn'}
        color={'yellow'}
        ariaName={'Contact button'}
        additionalClass={'contact-button'}
      >
        Join our Slack today!
      </Button>
    </SubpageBanner>
  </>
);

export default Slack;
