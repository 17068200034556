import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { Section } from 'components';
import AssetContent from './AssetContent';
import '../Resources.scss';

class Asset extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-asset-page asset'}>
        <Section additionalClass={'asset-section--first'} spaceless>
          <AssetContent {...this.props} />
        </Section>
      </div>
    );
  }
}

export default Asset;
