import Changes from './Changes';
import Client from './Client';
import Colaboration from './Colaboration';
import Contact from './Contact';
import Conversion from './Conversion';
import Intro from './Intro';
import Membership from './Membership';
import Product from './Product';
import Redesign from './Redesign';
import Separator from './Separator';
import Statistic from './Statistic';
import Technology from './Technology';

export {
  Changes,
  Client,
  Colaboration,
  Contact,
  Conversion,
  Intro,
  Membership,
  Product,
  Redesign,
  Separator,
  Statistic,
  Technology,
};
