import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import './HashtagList.scss';

class HashtagList extends Component {
  renderTags = tags =>
    tags.map(item => {
      const { tagSize, color, tagClass } = this.props;

      return (
        <li className={'hashtag-list__item'} key={`tag-${item}`}>
          <Paragraph
            size={tagSize}
            additionalClass={`text--${color} text--default ${tagClass}`}
          >
            #{item}
          </Paragraph>
        </li>
      );
    });

  render() {
    const { data } = this.props;

    return <ul className={'hashtag-list'}>{this.renderTags(data)}</ul>;
  }
}

HashtagList.defaultProps = {
  tagSize: 'small',
  tagClass: '',
  color: 'default',
};

HashtagList.propTypes = {
  data: PropTypes.array.isRequired,
  tagSize: PropTypes.string,
  tagClass: PropTypes.string,
  color: PropTypes.string,
};

export default HashtagList;
