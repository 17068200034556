import React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from 'components';

import { statsContent } from 'views/home/subcomponents/Prizes/logic';

import SingleStat from './SingleStat';
import './Stats.scss';

const Stats = ({ isTablet }) => {
  const content = statsContent;
  return (
    <div className={'prizes-stats'}>
      <SectionTitle size={'big'} color={'black'} lineColor={'pigeon-post'}>
        Numbers speak for&nbsp;us
      </SectionTitle>
      <ul className={'prizes-stats__list'}>
        {content.map(element => (
          <SingleStat
            key={element.id}
            isOdd={element.id % 2}
            decoration={element.decoration}
            rotation={element.rotation}
            text={element.text}
            link={element.link}
            isTablet={isTablet}
          />
        ))}
      </ul>
      <SectionTitle
        size={'big'}
        color={'black'}
        lineColor={'pigeon-post'}
        additionalClass={'title--second'}
      >
        Our design awards and business highlights
      </SectionTitle>
    </div>
  );
};

Stats.propTypes = {
  isTablet: PropTypes.isRequired,
};

export default Stats;
