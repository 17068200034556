import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { SectionHeader, Section, Paragraph, Link, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './About.scss';

const About = () => (
  <>
    <Section
      additionalClass={'event-fpmf-amsterdam__about'}
      color={'mine-shaft'}
    >
      <Row className={'event-fpmf-amsterdam__max-width'}>
        <Col sm={12} md={6} className={'event-fpmf__image'}>
          <div className={'event-fpmf-amsterdam__img'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/Boldare-office-stairs.png',
                'f_auto,q_auto',
              )}
              alt={'About Boldare'}
            />
          </div>
        </Col>
        <Col
          sm={12}
          md={6}
          className={'event-fpmf-amsterdam__about-description'}
        >
          <SectionHeader
            headingType={2}
            additionalClass={'event-fpmf-amsterdam__about-heading'}
            color={'white'}
            noLine
          >
            <img
              alt={''}
              className={'pixel-font'}
              src={getCloudinaryUrl('event/about.svg', 'v1669337183')}
            />
            <span className={'pixel-font-text'}>About</span> Boldare
          </SectionHeader>
          <Paragraph
            color={'white'}
            additionalClass={'event-fpmf-amsterdam__about-paragraph'}
          >
            Boldare is an Agile-powered company with 17 years of experience in
            the international software development market. The company has
            built over 300 digital products for clients around the globe.
          </Paragraph>

          <Link
            to={'/about'}
            additionalClass={'event-fpmf-amsterdam__about-cta'}
          >
            <Button color={'transparent-white'}>Read more &rarr;</Button>
          </Link>
        </Col>
      </Row>
    </Section>
  </>
);

export default About;
