import React from 'react';
import { Button, Paragraph, SectionHeader, Section, Rating } from 'components';
import * as Scroll from 'react-scroll';
import { scroller } from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import companyData from '../../../../../meta/companyData';
import './Banner.scss';

const Banner = () => {
  const SpyLink = Scroll.Link;

  return (
    <Section additionalClass={'ydp-banner'} color={'violet'}>
      <div className={'ydp-banner__content'}>
        <SectionHeader
          additionalClass={'text--bold'}
          color={'black'}
          headingType={1}
          lineColor={'violet'}
          size={'extra-large'}
        >
          Looking for a development partner?
        </SectionHeader>
        <Paragraph size={'medium'} additionalClass={'ydp-banner__description'}>
          To build successful software you need a development team who
          understands your business needs. Our experts will identify a custom
          solution to fit your idea, timeline, and budget, then design and
          build the application and help you test it with real users. As a
          result, you will gain a modern digital product loved by users that
          benefits your business.
        </Paragraph>
        <div className={'ydp-banner__buttons'}>
          <Button
            id={'ydp-banner'}
            color={'white'}
            ariaName={'Clutch contact button'}
            additionalClass={'contact-button'}
            onClick={() => {
              scroller.scrollTo('ydp-contact-form', {
                spy: false,
                smooth: true,
                duration: 500,
                offset: -100,
              });
            }}
          >
            Let&apos;s start our journey today!
          </Button>
        </div>
      </div>
      <div className={'ydp-banner__box'}>
        <img
          src={getCloudinaryUrl(
            'gatsby-cloudinary/badge-clutch-software.png',
            'f_auto,q_auto,w_250',
          )}
          alt={'Top Software Developers - Badge'}
          className={'image ydp-banner__logo'}
          loading={'lazy'}
        />
        <div className={'ydp-banner__ratings'}>
          <Paragraph
            color={'white'}
            size={'big'}
            additionalClass={'text--bold'}
          >
            {companyData.clutch_rate}
          </Paragraph>
          <Rating rate={companyData.clutch_rate} size={35} color={'white'} />
        </div>
        <div className={'ydp-banner__reviews letter-spacing'}>
          <SpyLink
            id={'ydp-reviews'}
            to={'ydp-testimonials'}
            spy={false}
            smooth
            duration={500}
          >
            <Button
              id={'go-to-clutch'}
              order={'secondary'}
              color={'white'}
              ariaName={'Read reviews'}
            >
              Read reviews
            </Button>
          </SpyLink>
        </div>
      </div>
    </Section>
  );
};

export default Banner;
