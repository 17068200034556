import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Button, SubpageBanner, SectionHeader, PopUp } from 'components';
import './Banner.scss';

class Banner extends Component {
  constructor() {
    super();

    this.state = {
      visibility: false,
    };
  }

  handleData = data => {
    this.showPopUp(data);
  };

  showPopUp = (visibility = true) => {
    this.setState({ visibility });
  };

  render() {
    const { visibility } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            mainBannerImage: file(relativePath: { eq: "sysdt/hero.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <SubpageBanner
              backgroundColor={'pigeon-post'}
              image={data.mainBannerImage.childImageSharp.fluid}
            >
              <SectionHeader
                additionalClass={
                  'section-header__description--medium sysdt__banner-desc'
                }
                color={'white'}
                description={
                  'The travel industry is facing numerous challenges and keeping pace with digitalization is definitely one of them. While leading companies are implementing technologies like AI, harnessing big data or machine learning, only a handful is happy with the results. The problem lies in a lack of organizational readiness for digitalization, and Systemic Digital Transformation might be the solution you are looking for.'
                }
                descriptionColor={'white'}
                fixed
                lineColor={'red'}
                size={'large'}
              >
                Transforming Travel: webinar on Systemic Digital Transformation
              </SectionHeader>
              <Button
                color={'white'}
                onClick={this.showPopUp}
                id={'sysdt-registration-button--Hero'}
                transparent
                ariaName={'Sign up button'}
              >
                Sign up for webinar
              </Button>
            </SubpageBanner>

            <PopUp
              visible={visibility}
              id={'sysdt-registration-popup--Hero'}
              visibilityHandler={this.handleData}
              additionalClass={'sysdt__popup'}
            >
              <iframe
                frameBorder={'0'}
                src={
                  'https://app.livestorm.co/p/a70f6293-c046-4048-9677-19e9fcca328d/form'
                }
              />
            </PopUp>
          </>
        )}
      />
    );
  }
}

export default Banner;
