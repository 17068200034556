import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';

const WorkWith = ({ allArticles, related }) => (
  <>
    <Section color={'white'} additionalClass={'dtu__learn-more'}>
      <SectionHeader
        size={'big'}
        description={
          'Read our latest articles and explore the fascinating world of new advancements in technology and how it impacts the businesses around the world.'
        }
        descriptionColor={'black'}
        smaller
        fixed
      >
        Work of our teams
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'development-teams-learn-more-articles'}
        related={related}
        background={'white'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
      />
    )}
  </>
);

export default WorkWith;
