import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Section,
  SectionHeader,
  Paragraph,
  Box,
  BoxContent,
  BackgroundImage,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Slider from 'react-slick';
import { classNames } from 'utils';
import { sliderData } from './logic';
import './Carousel.scss';

class Carousel extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'career-page-slider',
        dotsClass: 'slick-dots slick-pagination career-page-slider-dots',
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 500,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  renderSlides = () => {
    const colors = {
      primary: 'mine-shaft',
      secondary: 'white',
      text: 'white',
      line: 'golden-dream',
    };

    return sliderData.map(item => (
      <div
        className={'career-page-slider__slide-container w-container'}
        key={`culture-slide-${item.id}`}
      >
        <div
          className={classNames('career-page-slider__slide-wrapper', {
            [`career-page-slider__slide-wrapper--${item.color}`]: item.color,
          })}
        >
          <div className={'career-page-slider__image'}>
            <BackgroundImage
              asset={item.background}
              additionalClass={'career-page-slider__slide'}
              alt={item.caption}
            />
          </div>
          <div
            className={classNames('career-page-slider__item', {
              [`career-page-slider__item--${colors.primary}`]: colors.primary,
            })}
          >
            <Box
              additionalClass={'career-page-slider__box'}
              backgroundColor={colors.primary}
            >
              <BoxContent type={'slider'}>
                <SectionHeader
                  size={'medium'}
                  color={colors.text}
                  headingType={3}
                  lineColor={colors.line}
                  additionalClass={'career-page-slider__box-headline'}
                >
                  {item.content}
                </SectionHeader>
                <Paragraph
                  size={'medium'}
                  color={colors.text}
                  additionalClass={'text--light'}
                  spaced
                >
                  {item.description}
                </Paragraph>
                {item.awards && (
                  <div className={'career-page-slider__awards'}>
                    {item.awards.map(award => (
                      <img
                        src={getCloudinaryUrl(
                          award,
                          'f_auto,q_auto/img/awwwards/awards/',
                        )}
                        alt={award}
                        loading={'lazy'}
                      />
                    ))}
                  </div>
                )}
              </BoxContent>
            </Box>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section
          id={'career-match'}
          color={'white'}
          additionalClass={'career-match'}
        >
          <Heading
            type={3}
            size={'medium'}
            color={'mine-shaft'}
            additionalClass={'headline--top headline--top-yelow'}
          >
            Boldare Products
          </Heading>
          <SectionHeader
            additionalClass={'headline--centered'}
            color={'mine-shaft'}
            size={'medium'}
            headingType={2}
            noLine
          >
            <span
              className={
                'headline--marking headline--marking-yellow--usability'
              }
            >
              Usability{' '}
            </span>
            is our goal
          </SectionHeader>
        </Section>
        <Slider
          ref={slider => {
            this.slider = slider;
          }}
          {...sliderSettings}
        >
          {this.renderSlides()}
        </Slider>
        <div className={'career-match-products w-container--side-right'}>
          <div className={'career-match-products__wrapper'}>
            <div className={'career-match-products__content'}>
              <div className={'career-match-products__title'}>
                <p className={'career-match-products__paragraph'}>
                  Want more projects?
                </p>
                <div className={'career-match-products__head'}>
                  <Heading
                    additionalClass={'career-match-products__heading'}
                    color={'mine-shaft'}
                  >
                    Check out how we build{' '}
                    <span
                      className={'headline--marking headline--marking-red'}
                    >
                      usable
                    </span>{' '}
                    products
                  </Heading>
                </div>
              </div>
              <div className={'career-match-products__cta'}>
                <img
                  src={getCloudinaryUrl('career/arrow.png', 'v1656982353')}
                  alt={'arrow'}
                  loading={'lazy'}
                />
                <a
                  href={'/products/'}
                  className={'button button--primary button--red'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  More products
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Carousel.defaultProps = {
  data: [],
};

Carousel.propTypes = {
  data: PropTypes.array,
};

export default Carousel;
