import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  HashtagList,
  Heading,
  Paragraph,
  BackgroundImage,
  Button,
} from 'components';
import './BoxStandard.scss';

class BoxStandard extends Component {
  render() {
    const { additionalClass, data } = this.props;
    const {
      image,
      background,
      backgroundColor,
      content,
      caption,
      tags,
      color,
      link,
      discover,
    } = data;

    return (
      <Box
        link={link}
        additionalClass={`box-standard ${additionalClass}`}
        backgroundColor={backgroundColor || ''}
      >
        <div className={'box-standard__content'}>
          <div className={'box-standard__image-wrapper'}>
            <img
              src={image}
              alt={''}
              className={'image box-standard__image'}
              loading={'lazy'}
            />
          </div>
          <Heading
            color={color}
            type={4}
            size={'medium'}
            additionalClass={`${
              caption
                ? 'box-standard__testimonial'
                : 'box-standard__description'
            }`}
          >
            {ReactHtmlParser(content)}
          </Heading>
          {caption && (
            <Paragraph color={'color'} size={'small'}>
              {ReactHtmlParser(caption)}
            </Paragraph>
          )}
        </div>
        {background && (
          <BackgroundImage
            src={background}
            additionalClass={'box-standard__background'}
          />
        )}
        <div className={'box-standard__footer'}>
          {tags && (
            <div className={'box-image-content__tags'}>
              <HashtagList color={color} data={tags} />
            </div>
          )}
          {discover && (
            <Button order={'secondary'} color={'white'} ariaName={'Discover'}>
              Discover
            </Button>
          )}
        </div>
      </Box>
    );
  }
}

BoxStandard.defaultProps = {
  additionalClass: '',
};

BoxStandard.propTypes = {
  data: PropTypes.shape().isRequired,
  additionalClass: PropTypes.string,
};

export default BoxStandard;
