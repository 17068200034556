export const data = [
  {
    icon: 'network',
    title: '5G / Smart City / IoT',
    link: '/blog/internet-of-things-three-numbers-you-should-know/',
    color: 'violet',
  },
  {
    icon: 'prototype',
    title: 'AI',
    link: '/blog/machine-learning-digital-product-costs/',
    color: 'pigeon-post',
  },
  {
    icon: 'cube-two',
    title: 'FinTech',
    link: '/reviews/ezycount/',
    color: 'red',
  },
  {
    icon: 'market-fit',
    title: 'Digital Transformation',
    link: '/blog/culture-shift-practical-key-to-digital-transformation/',
    color: 'red',
  },
  {
    icon: 'cube',
    title: 'Agility',
    link: '/blog/how-do-you-make-first-product-discovery-workshops/',
    color: 'violet',
  },
  {
    icon: 'pdd',
    title: 'Retail Tech',
    link: '/blog/design-driven-innovation/',
    color: 'pigeon-post',
  },
];
