const validateInputField = (fieldName, form, errors) => {
  let isValid = true;
  let errorMessage = '';

  if (fieldName === 'name' && !form.name.trim()) {
    errorMessage = 'First name is required';
    isValid = false;
  }

  if (fieldName === 'email') {
    if (!form.email.trim()) {
      errorMessage = 'Company email is required';
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
      errorMessage = 'Email format is invalid';
      isValid = false;
    }
  }

  if (fieldName === 'message' && !form.message.trim()) {
    errorMessage = 'Description is required';
    isValid = false;
  }
  return { isValid, updatedErrors: { ...errors, [fieldName]: errorMessage } };
};

export default validateInputField;
