import React, { Component } from 'react';
import { Section, SectionHeader, AccordionQandA } from 'components';
import { data } from './logic';

class QuestionsAnswers extends Component {
  render() {
    return (
      <>
        <Section color={'white'} additionalClass={'nn__experts'}>
          <SectionHeader
            size={'big'}
            description={`This is a popular question people ask me. How do you know if I am mastering the art of Deliberate Attraction? Is there an advanced course I can take? My answer is always the same, “The application of this material into your daily life IS the advanced course!”`}
            descriptionColor={'black'}
            lineColor={'red'}
            fixed
          >
            Questions and answers
          </SectionHeader>
          <div>
            <AccordionQandA data={data} />
          </div>
        </Section>
      </>
    );
  }
}

export default QuestionsAnswers;
