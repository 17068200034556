import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Approach,
  Banner,
  Process,
  WhatWeUse,
  HowWeWork,
  MeetTeam,
  OtherServices,
  LearnMore,
  Subscribe,
} from './subcomponents';
import './MachineLearning.scss';

class MachineLearning extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedLearn = [
      'Building digital products based on machine learning - the cost perspective',
      'Machine learning - obstacles and limitations',
      'How machine learning is boosting businesses',
      'How to start a neural network with JavaScript in 5 minutes',
      'What exactly can you do with Python?',
    ].map(title => ({ title }));
    const relatedWork = [
      'Predictive maintenance for wind turbines - an interview with Boldare’s machine learning engineers',
      'Speeding up e-commerce content moderation with Machine Learning based on Amazon Web Services',
      'How to create predictive maintenance software for wind turbines using machine learning algorithms',
    ].map(title => ({ title }));

    return (
      <div className={'single-page single-page--hero machine-learning'}>
        <Banner />
        <Subscribe />
        <Process />
        <WhatWeUse />
        <Approach />
        <HowWeWork />
        <LearnMore
          allArticles={allArticles}
          related={relatedWork}
          heading={'Our work'}
          color={'white'}
          description={
            'Explore our case studies and past work employing machine learning and advanced data analysis:'
          }
        />
        <MeetTeam />
        <ContactFormServices
          id={'ml-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x8t'}
        />
        <LearnMore
          allArticles={allArticles}
          related={relatedLearn}
          heading={'Learn more'}
          color={'white'}
          description={
            'Read our latest articles about machine learning, AI and analytics, and explore how they can impact your business.'
          }
        />
        <OtherServices />
      </div>
    );
  }
}

export default MachineLearning;
