export const data = [
  {
    link: '/event/amsterdam/42946313750_30f6ebdf40_o_2.png',
    type: 'img',
  },
  {
    link: '/event/amsterdam/Meetup_YT_cover_1.png',
    type: 'video',
    yt: 'https://www.youtube.com/watch?v=fMcpijIVCPc',
  },
  {
    link: '/event/amsterdam/44721197981_253e4b4d32_o_1.png',
    type: 'img',
  },
  {
    link: '/event/amsterdam/30886123798_7b00474079_o_2.png',
    type: 'img',
  },
  {
    link: '/event/amsterdam/48178784196_6eb09fee30_o_4.png',
    type: 'img',
  },
];
