import ae from 'assets/images/country-flags/ae.svg';
import ca from 'assets/images/country-flags/ca.svg';
import ch from 'assets/images/country-flags/ch.svg';
import de from 'assets/images/country-flags/de.svg';
import fr from 'assets/images/country-flags/fr.svg';
import gb from 'assets/images/country-flags/gb.svg';
import hk from 'assets/images/country-flags/hk.svg';
import il from 'assets/images/country-flags/il.svg';
import it from 'assets/images/country-flags/it.svg';
import ir from 'assets/images/country-flags/ir.svg';
import om from 'assets/images/country-flags/om.svg';
import pl from 'assets/images/country-flags/pl.svg';
import sa from 'assets/images/country-flags/sa.svg';
import se from 'assets/images/country-flags/se.svg';
import us from 'assets/images/country-flags/us.svg';
import nl from 'assets/images/country-flags/nl.svg';
import eu from 'assets/images/country-flags/eu.svg';
import fn from 'assets/images/country-flags/fn.svg';

const flags = {
  europe: eu,
  'united-arab-emirates': ae,
  canada: ca,
  switzerland: ch,
  germany: de,
  france: fr,
  'united-kingdom': gb,
  'hong-kong': hk,
  israel: il,
  italy: it,
  ireland: ir,
  oman: om,
  poland: pl,
  'saudi-arabia': sa,
  sweden: se,
  'united-states': us,
  'the-netherlands': nl,
  'finland': fn,
};

export default flags;
