import React, { Component } from 'react';
import Slider from 'react-slick';
import { Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { prototypeArticles, mvpArticles } from './articles';

import './ArticleSlider.scss';

export default class ArticleSlider extends Component {
  constructor() {
    super();

    this.state = {
      currentSlideIndex: 0,
      sliderHeaderText: 'Prototype',
      settings: {
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        afterChange: current => {
          this.onSlideChange(current);
        },
      },
    };
  }

  onSlideChange(nextSlideIndex) {
    this.setState({
      currentSlideIndex: nextSlideIndex,
    });

    const headingText = nextSlideIndex === 0 ? 'Prototype' : 'MVP';

    this.setState({
      sliderHeaderText: headingText,
    });
  }

  render() {
    const { settings, sliderHeaderText } = this.state;

    return (
      <div className={'article-slider'}>
        <div className={'article-slider__header'}>
          <h2 className={'article-slider__title'}>{sliderHeaderText}</h2>

          <button
            className={'article-slider__trigger'}
            onClick={() => this.slider.slickNext()}
          >
            <Image
              src={getCloudinaryUrl(
                '/pmf-survey/arrow.png',
                'f_auto,q_auto,w_60',
              )}
              alt={'Next slide icon'}
              loading={'lazy'}
            />
          </button>
        </div>

        <Slider
          ref={slider => {
            this.slider = slider;
          }}
          {...settings}
        >
          <div className={'article-slider__slide'}>
            {prototypeArticles.map(article => (
              <a
                href={article.url}
                className={'article-slider__article'}
                key={article.title}
              >
                <div className={'article-slider__article-img'}>
                  <Image
                    src={getCloudinaryUrl(
                      `${article.img}`,
                      'f_auto,q_auto,w_500',
                    )}
                    alt={'Article image'}
                    loading={'lazy'}
                  />
                </div>

                <div className={'article-slider__article-content'}>
                  <h3>{article.title}</h3>
                </div>
              </a>
            ))}
          </div>

          <div className={'article-slider__slide'}>
            {mvpArticles.map(article => (
              <a
                href={article.url}
                className={'article-slider__article'}
                key={article.title}
              >
                <div className={'article-slider__article-img'}>
                  <Image
                    src={getCloudinaryUrl(
                      `${article.img}`,
                      'f_auto,q_auto,w_500',
                    )}
                    alt={'Article image'}
                    loading={'lazy'}
                  />
                </div>

                <div className={'article-slider__article-content'}>
                  <h3>{article.title}</h3>
                </div>
              </a>
            ))}
          </div>
        </Slider>
      </div>
    );
  }
}
