import companyData from '../../../../../meta/companyData';

export const statsContent = [
  {
    id: 1,
    decoration: '7',
    rotation: '-6deg',
    text:
      'Product strategists with over 20 various product-related certificates',
  },
  {
    id: 2,
    decoration: '6',
    rotation: '12deg',
    text: 'Interdisciplinary experts in the role of distributed CTO',
  },
  {
    id: 3,
    decoration: companyData.technologies,
    rotation: '-16deg',
    text: 'Technologies in-house',
  },
  {
    id: 4,
    decoration: '1',
    rotation: '-3deg',
    text: 'Facilitator in every team',
  },
  {
    id: 5,
    decoration: '1',
    rotation: '-6deg',
    text: 'Service standard for all clients',
  },
  {
    id: 6,
    decoration: `${companyData.products}+`,
    rotation: '5deg',
    text: 'Released products',
  },
  {
    id: 7,
    decoration: '50%',
    rotation: '5deg',
    text: 'Of leaders are women',
  },
  {
    id: 8,
    decoration: '17',
    rotation: '-6deg',
    text: 'Scrum masters, holding 30+ various certifications',
  },
  {
    id: 9,
    decoration: '62',
    rotation: '-6deg',
    text: 'Frameworks and tools in use, depending on the needs of the product',
  },
  {
    id: 10,
    decoration: '10+',
    rotation: '12deg',
    text:
      'Different workshop types dedicated to product and business development',
  },
  {
    id: 11,
    decoration: '1',
    rotation: '5deg',
    text: 'Scrum master in every product development team',
  },
  {
    id: 12,
    decoration: '10+',
    rotation: '2deg',
    text: 'Self-organized chapters - communities focused on problem solving',
  },
];
