import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  BackgroundContent,
} from 'components';
import './Solution.scss';

const Solution = () => (
  <div className={'sdo__solution'}>
    <Section color={'mine-shaft'}>
      <Row className={'no-gap anim-fade-in-bottom'}>
        <Col sm={12} lg={6}>
          <SectionHeader
            size={'big'}
            color={'white'}
            lineColor={'red'}
            headingType={2}
          >
            Solutions <br />
            and methodology
          </SectionHeader>
        </Col>
        <Col sm={12} lg={6}>
          <div className={'sdo__solution-description'}>
            <Heading color={'white'} size={'medium'} type={3}>
              Our teams are committed to delivering the best software possible.
              To achieve that they use well-proven methods and highly efficient
              tools to deliver the full development process which includes:
            </Heading>
          </div>
        </Col>
      </Row>
    </Section>
    <Section fullwidth spaceless additionalClass={'sdo__solution-content'}>
      <div className={'w-container'}>
        <div className={'sdo__solution-items'}>
          <Row className={'no-gap anim-fade-in-bottom'}>
            <Col sm={6} md={4}>
              <div className={'sdo__solution-item'}>
                <BackgroundContent
                  height={280}
                  content={'1'}
                  color={'red'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Business process
                </SectionHeader>
                <Paragraph>
                  To fully understand your needs we use the lean startup
                  framework as a canvas, impact mapping, and event storming
                  workshops as tools which help us to know your business
                  better.
                </Paragraph>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'sdo__solution-item'}>
                <BackgroundContent
                  height={280}
                  content={'2'}
                  color={'red'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Quality process
                </SectionHeader>
                <Paragraph>
                  We want to deliver the best software and this is why we pay
                  extra attention to quality assurance. Code reviews, tests, a
                  definition of done, continuous integration, continuous
                  delivery - those are our standards.
                </Paragraph>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'sdo__solution-item'}>
                <BackgroundContent
                  height={280}
                  content={'3'}
                  color={'red'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Communication process
                </SectionHeader>
                <Paragraph>
                  At Boldare we support full transparency, and we provide
                  adequate tools like Slack and Confluence. We also believe
                  that there is no reason for a proxy between the Product Owner
                  and the dedicated team, and that is why we don't have Project
                  Managers.
                </Paragraph>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'sdo__solution-item'}>
                <BackgroundContent
                  height={280}
                  content={'4'}
                  color={'red'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Development process
                </SectionHeader>
                <Paragraph>
                  In the process of delivering software which will solve your
                  problems, we use mood boards, lean product vision workshops
                  and other methods and tools which assure a positive outcome.
                </Paragraph>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'sdo__solution-item'}>
                <BackgroundContent
                  height={280}
                  content={'5'}
                  color={'red'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Management process
                </SectionHeader>
                <Paragraph>
                  To make sure that everything is on track with your project we
                  use Scrum and Kanban to track progress and deliver
                  increments.{' '}
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Section>
  </div>
);

export default Solution;
