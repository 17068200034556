import React from 'react';
import './Cities.scss';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import { Heading, Section, SectionHeader } from 'components';

const Cities = () => (
  <Section
    additionalClass={'contact-de-cities__section'}
    id={'connect-de-cities'}
    spacedTop
  >
    <Row center={'xs'} className={'contact-de-cities--position-relative'}>
      <Col xs={12} className={'contact-de-cities__content-wrapper-col'}>
        <div className={'contact-de-cities__text-wrapper'}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            fixed
            noLine
            additionalClass={'contact-de-cities__text-header'}
          >
            <span>Hamburg oder Berlin?</span>
            <br />
            <span>Wir sind für Sie da</span>
            <br />
          </SectionHeader>
          <Heading
            type={2}
            size={'medium-constant'}
            color={'black'}
            additionalClass={'contact-de-cities__text-header-secondary'}
          >
            Wir freuen uns auf ein persönliches Treffen, wo immer Sie es
            wünschen. Wenn Sie zuerst online Kontakt aufnehmen möchten - keine
            Sorge, wir arbeiten auch fließend mit hybriden Verfahren.
          </Heading>
        </div>
        <div className={'contact-de-cities__images-wrapper'}>
          <div className={'contact-de-cities__gate-img-wrapper'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/boldare-contact-de-super-hero-gate.png',
                'v1645116158',
              )}
              className={'image'}
              alt={'City'}
              loading={'lazy'}
            />
          </div>
          <div className={'contact-de-cities__german-country-img-wrapper'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/boldare-contact-de-german-country-frame.png',
                'v1645115576',
              )}
              className={'image'}
              alt={'City'}
              loading={'lazy'}
            />
          </div>
          <div className={'contact-de-cities__hand-with-cup-img-wrapper'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/contact-de-hand-with-cup.png',
                'v1645116164',
              )}
              className={'image'}
              alt={'City'}
              loading={'lazy'}
            />
          </div>
          <div className={'contact-de-cities__satellite-img-wrapper'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/contact-de-satellite.png',
                'v1645906798',
              )}
              className={'image'}
              alt={'City'}
              loading={'lazy'}
            />
          </div>
          <div className={'contact-de-cities__circle'} />
        </div>
        <img
          src={getCloudinaryUrl(
            'gatsby-cloudinary/boldare-contact-de-super-hero-city.png',
            'v1645116164',
          )}
          className={'image'}
          alt={'City'}
          loading={'lazy'}
        />
      </Col>
      <div className={'contact-de-cities__line'} />
    </Row>
  </Section>
);

export default Cities;
