import React, { Component } from 'react';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import DigitaleTransformationDe from 'views/digitaleTransformationDe';
import config from '../../meta/config';

class DigitaleTransformationDePage extends Component {
  render() {
    const page = pages['digitale-transformation'];
    const { navColors } = page;
    const url = `${config.siteUrl}/digitale-transformation/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <PopupRedirect message={'digitale-transformation'} />
        <DigitaleTransformationDe />
      </Layout>
    );
  }
}

export default DigitaleTransformationDePage;
