export const data = [
  {
    title: 'Customer comes first',
    description:
      'We tailor each solution to your specific business needs. Our experienced consultants will help you distill your goals and objectives and help you choose the right path to get there.',
  },
  {
    title: 'CRISP-DM',
    description:
      'We follow Cross-industry Standard Process for Data Mining, giving you safety and quality assurance for the machine learning products we deliver.',
  },
  {
    title: 'Agile',
    description:
      'We build software in the scrum framework giving you a working increment of your machine learning product with every iteration (usually 1-2 weeks).',
  },
  {
    title: 'Lean startup Approach',
    description:
      'We minimize the risk of failure by building digital products in four stages: prototype, MVP, product-market fit and scaling (see Our Process).',
  },
];
