import React from 'react';
import PropTypes from 'prop-types';

import './Description.scss';

const Description = ({ text, fullText }) => {
  const maxLength = 96;
  const slicedText = text.slice(0, maxLength);
  const conditionalDots = slicedText.length === maxLength && '...';

  return (
    <p className={'work-boxes-description'}>
      {fullText ? (
        text
      ) : (
        <>
          {slicedText}
          {conditionalDots}
        </>
      )}
    </p>
  );
};

Description.defaultProps = {
  fullText: false,
};

Description.propTypes = {
  text: PropTypes.string.isRequired,
  fullText: PropTypes.bool,
};

export default Description;
