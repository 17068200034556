import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, FullwidthImage } from 'components';
import ReactHtmlParser from 'react-html-parser';
import CountUp from 'react-countup';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import './Statistic.scss';

class Statistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableCounterAnimation: false,
    };
  }

  componentDidMount() {
    inView('.pakt__statistic-box').on('enter', () => {
      this.setState({ enableCounterAnimation: true });
    });
  }

  withCounter = (number, suffix) =>
    this.state.enableCounterAnimation && (
      <span>
        <CountUp end={number} delay={0.3} suffix={suffix} />
      </span>
    );

  render() {
    const { text, info, suffix, image, subtitle, title, number } = this.props;

    return (
      <div className={'pakt__statistic-box'}>
        <div className={'pakt__content--left anim-fade-in-left'}>
          <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
            {title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'default'}
            color={'mine-shaft'}
          >
            {text}
          </Paragraph>
        </div>
        <div className={'pakt__content--half'}>
          <FullwidthImage
            image={getCloudinaryUrl(
              `assets/pakt/${image}.jpg`,
              'f_auto,q_auto',
            )}
            alt={'Preview'}
            additionalClass={'pakt__statistic-image anim-fade-in-bottom'}
          />
        </div>
        <div className={'pakt__content--left anim-fade-in-right'}>
          <Paragraph
            additionalClass={'pakt__statistic-header text--light'}
            size={'small-plus'}
            color={'gray-dark'}
          >
            {subtitle}
          </Paragraph>
          <Heading
            type={3}
            size={'big-spreaded'}
            color={'mine-shaft'}
            additionalClass={'pakt__statistic-header'}
          >
            {this.withCounter(number, suffix)}
          </Heading>
          <Heading
            type={3}
            size={'default'}
            color={'mine-shaft'}
            additionalClass={'text--default pakt__statistic-header'}
          >
            {ReactHtmlParser(info)}
          </Heading>
        </div>
      </div>
    );
  }
}

Statistic.PropTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  suffix: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default Statistic;
