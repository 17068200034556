/* eslint-disable react/jsx-no-target-blank */
import React, { Component, createRef } from 'react';
import {
  Section,
  SectionHeader,
  Heading,
  Paragraph,
  Button,
  Link,
  LoadMorePagination,
} from 'components';
import { getCloudinaryUrl, getCloudinarySrcSet } from 'config/helpers';
import { classNames } from 'utils';
import { scroller } from 'react-scroll';
import memoize from 'memoize-one';
import { flow, curry } from 'lodash/fp';
import { assetType, data } from './logic';
import { isItemInFilter } from './utils';
import './Tiles.scss';

class Tiles extends Component {
  filterTiles = memoize(
    curry((assetTypeFilter, _data) =>
      _data.filter(item => {
        const { assetType: itemAssetType } = item;

        if (assetTypeFilter !== null) {
          return isItemInFilter(itemAssetType, assetTypeFilter);
        }

        return item;
      }),
    ),
  );

  constructor(props) {
    super(props);

    this.refAssetTypeFilter = createRef();

    this.data = data;

    this.state = {
      assetTypeFilter: null,
    };
  }

  handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: item,
      });
    }
  };

  getCalculatedTiles = () => {
    const { assetTypeFilter } = this.state;

    const calculateData = flow(this.filterTiles(assetTypeFilter));

    return calculateData(this.data);
  };

  handleFilterSelect = (e, label, filter, navigatePage) => {
    e.preventDefault();
    window.history.replaceState({}, '', e.target.href);

    this.checkResults(label, filter, navigatePage);

    scroller.scrollTo('tiles__content', {
      spy: false,
      smooth: true,
      duration: 500,
      offset: -200,
    });
  };

  checkResults(label, newFilter, navigatePage) {
    navigatePage(1);

    this.updateFilters(label, newFilter);
  }

  updateFilters(label, newFilter) {
    const keysByLabel = {
      AssetType: 'assetTypeFilter',
    };

    this.setState({
      assetTypeFilter: null,
      [keysByLabel[label]]: newFilter,
      isActive: newFilter,
    });
  }

  renderResourcesFilters(label, items, navigatePage) {
    const { isActive } = this.state;

    const filters = items.map((filter, index) => (
      <>
        {index === 0 && (
          <button
            className={classNames('post-list__filter-button', {
              'post-list__filter-button--active':
                isActive === null || isActive === undefined,
            })}
            id={`filter-All`}
            key={`filter-All`}
            onClick={e => {
              this.handleFilterSelect(e, null, null, navigatePage);
            }}
          >
            All types ({data.length})
          </button>
        )}
        <button
          id={`filter-${filter}`}
          className={classNames('post-list__filter-button', {
            'post-list__filter-button--active': isActive === filter,
          })}
          key={`filter-${filter}`}
          onClick={e => {
            this.handleFilterSelect(e, label, filter, navigatePage);
          }}
        >
          {filter} ({data.filter(item => item.assetType === filter).length})
        </button>
      </>
    ));

    return <div className={'tiles__filters-list'}>{filters}</div>;
  }

  renderTiles = filtered => (
    <>
      <div className={'tiles__list anim-fade-in-bottom'} id={'tiles__content'}>
        {filtered.length > 0 && this.renderItems(filtered)}
      </div>
    </>
  );

  renderItems = items =>
    items.map(item => (
      <article className={'tiles__box tiles__box--regular'}>
        <div className={'tiles__box-content'}>
          <span
            className={`box__badge box__badge--article box__badge--${item.badge} text--bold`}
          >
            {item.badge}
          </span>
          <div
            className={classNames('tiles__box-img-wrapper', {
              [`tiles__box-img-wrapper--${item.imageBg}`]: item.imageBg,
            })}
          >
            <a
              className={'tiles__article-link'}
              href={item.link}
              title={item.title}
              onClick={() => this.handleClick('tiles__article-link')}
              target={'_blank'}
              rel={
                item.description
                  ? 'nofollow noreferrer'
                  : 'dofollow noreferrer'
              }
            >
              <img
                src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_600')}
                srcSet={getCloudinarySrcSet(item.image, 'f_auto,q_auto')}
                alt={item.title}
                className={classNames('image tiles__box-img', {
                  'tiles__box-img--center': item.imagePos === 'center',
                  'tiles__box-img--top': item.imagePos === 'top',
                  'tiles__box-img--bottom': item.imagePos === 'bottom',
                })}
                loading={'lazy'}
              />
            </a>
          </div>
          <a
            className={'tiles__article-link'}
            href={item.link}
            title={item.title}
            onClick={() => this.handleClick('tiles__article-link')}
            target={'_blank'}
            rel={
              item.description ? 'nofollow noreferrer' : 'dofollow noreferrer'
            }
          >
            <Heading type={3} size={'medium-plus-small'}>
              {item.title}
            </Heading>
          </a>
          <Paragraph>
            {item.tags ? (
              <>Learn about:{item.tags.map(tag => ` ${tag}`)}</>
            ) : (
              <>{item.description}</>
            )}
          </Paragraph>
          <a
            className={'tiles__article-link'}
            href={item.link}
            title={item.title}
            onClick={() => this.handleClick('tiles__article-link')}
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            <Button color={'white'} hover>
              Learn more &rarr;
            </Button>
          </a>
        </div>
      </article>
    ));

  render() {
    return (
      <LoadMorePagination
        index={'tiles'}
        loadCount={9}
        data={this.getCalculatedTiles()}
      >
        {({ items, loadMore, navigatePage }) => (
          <Section additionalClass={'resources-hub__tiles'}>
            <div className={'section tiles__inner'}>
              <SectionHeader
                additionalClass={'section-header__description--medium'}
                color={'black'}
                lineColor={'pigeon-post'}
                size={'large'}
                headingType={2}
              >
                Our knowledge-packed materials
              </SectionHeader>
              <div className={'tiles__filters-box'}>
                <div className={'tiles__filters-wrapper'}>
                  <div
                    ref={this.refAssetTypeFilter}
                    className={'filter-assetType'}
                  >
                    {this.renderResourcesFilters(
                      'AssetType',
                      assetType,
                      navigatePage,
                    )}
                  </div>
                </div>
              </div>

              {this.renderTiles(items)}

              <div className={'tiles__button-wrapper'}>
                {loadMore && (
                  <div className={'tiles__button'}>
                    <Button
                      tag={Link}
                      to={loadMore.path}
                      color={'yellow'}
                      onClick={loadMore.action}
                      ariaName={'Load more button'}
                    >
                      Load more resources
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Section>
        )}
      </LoadMorePagination>
    );
  }
}

export default Tiles;
