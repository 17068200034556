import React, { PureComponent } from 'react';
import { ContactFormFullwidth } from 'components';
import { enableAnimations } from 'config/helpers';
import { Videos, Technologies, Experience } from './subcomponents';
import './Gitex.scss';

class Gitex extends PureComponent {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero gitex'}>
        <Videos />
        <Technologies />
        <Experience />
        <ContactFormFullwidth
          name={'gitex-contact-form'}
          id={'gitex__contact--end'}
          pardotLink={'https://go.boldare.com/l/688533/2019-10-07/2w5ls'}
        />
      </div>
    );
  }
}

export default Gitex;
