import React from 'react';
import PropTypes from 'prop-types';

import { getCloudinaryUrl } from 'config/helpers';

import './Author.scss';

const Author = ({ authorData }) => {
  return (
    <div className={'work-boxes-author'}>
      <img
        src={getCloudinaryUrl(
          authorData[0]?.node.frontmatter.photo,
          'f_auto,q_auto,w_100',
        )}
        className={'work-boxes-author__image'}
        alt={`${authorData[0]?.node.frontmatter.title} avatar`}
        loading={'lazy'}
      />
      <div className={'work-boxes-author__data'}>
        <p className={'work-boxes-author__name'}>
          {authorData[0]?.node.frontmatter.title}
        </p>
        <p className={'work-boxes-author__role'}>
          {authorData[0]?.node.frontmatter.job}
        </p>
      </div>
    </div>
  );
};

Author.propTypes = {
  authorData: PropTypes.array.isRequired,
};

export default Author;
