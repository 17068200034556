import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { constants } from 'os';
import { serialize } from '../../../../../utils';

export default function withSubscribe(WrappedComponent) {
  return class SubscribeHOC extends Component {
    static propTypes = { children: PropTypes.node };

    static defaultProps = { children: null };

    constructor() {
      super();

      this.refForm = createRef();

      this.state = {
        subscribed: false,
      };
    }

    handleSubmit = e => {
      e.preventDefault();

      const url = 'https://go.boldare.com/l/688533/2019-07-24/pjmz';
      const form = this.refForm.current;

      this.setState({ isLoading: true });

      fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        body: serialize(form),
      })
        .then(() => {
          if (
            typeof window !== 'undefined' &&
            window.fbq &&
            typeof window.fbq === 'function'
          ) {
            window.fbq('track', 'Sign up');
          }
          this.setState({ subscribed: true });
        })
        .catch(error => console.log(error));
    };

    render() {
      const { subscribed, isLoading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          subscribed={subscribed}
          isLoading={isLoading}
          refForm={this.refForm}
          handleSubmit={this.handleSubmit}
        />
      );
    }
  };
}
