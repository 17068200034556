import React from 'react';
import PropTypes from 'prop-types';
import './Rating.scss';
import IconSVG from 'components/IconSVG';

const Rating = ({ rate, maxRate, color, size, spaceClassName }) => {
  const percentageOfLastStar = rate - Math.floor(rate);
  const iconWrapper = icon => (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        marginRight: `${size / 4}px`,
      }}
    >
      {icon}
    </div>
  );
  return (
    <div className={`rating-scale rating-scale--${color} ${spaceClassName}`}>
      {[...Array(Math.floor(rate))].map(() =>
        iconWrapper(
          <IconSVG
            additionalClass={'rating-scale__star-filled'}
            name={'star-rate'}
            key={`ic-${rate}`}
          />,
        ),
      )}
      {percentageOfLastStar > 0 && (
        <div className={'rating-scale__percentage-fill'}>
          {iconWrapper(<IconSVG name={'star-rate'} />)}
          <div
            className={'rating-scale__percentage-fill-icon'}
            style={{ width: `${size * percentageOfLastStar}px`, height: size }}
          >
            {iconWrapper(
              <IconSVG
                additionalClass={'rating-scale__star-filled'}
                name={'star-rate'}
              />,
            )}
          </div>
        </div>
      )}
      {[...Array(maxRate - Math.ceil(rate))].map(() =>
        iconWrapper(<IconSVG name={'star-rate'} />),
      )}
    </div>
  );
};

Rating.defaultProps = {
  rate: 0,
  maxRate: 5,
  color: 'red',
  size: 15,
  spaceClassName: 'rating-scale--space',
};

Rating.propTypes = {
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRate: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.number,
  spaceClassName: PropTypes.string,
};

export default Rating;
