import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader } from 'components';

const Banner = () => (
  <SubpageBanner
    backgroundColor={'red'}
    image={getCloudinaryUrl(
      'gatsby-cloudinary/Meeting-in-Boldare.png',
      'f_auto,q_auto,w_1400',
    )}
    contentBackground={'mine-shaft'}
    additionalClass={'digital-transformation__banner'}
    alt={'People having discussion about charts'}
  >
    <SectionHeader
      size={'large'}
      color={'white'}
      lineColor={'red'}
      description={
        'Adapting to the digital economy is critical for companies around the world. However the shift from traditional business models means more than just releasing a new website, mobile app or e-commerce platform.'
      }
      descriptionColor={'white'}
      hiddenDescription={
        '<br />Digital Transformation involves changes to the core of your business, your processes and even company culture. To be fit for the digital world, organizations must know how to be agile, they must constantly innovate and learn. Boldare’s Digital Transformation Services include strategizing, execution and passing on knowledge so you can advance not only your product but your entire business.'
      }
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Digital Transformation <br />
      Services
    </SectionHeader>
  </SubpageBanner>
);

export default Banner;
