export const data = [
  {
    image: 'shopping',
    title: 'Product shop',
    description:
      'We created online shop from scratch basing on Shopify platform which is a great marketing tool that helps to achieve sales goals and is easy to navigate for users.',
    img: 'spamonkeys/spamonkeys-shopping-cart',
  },
  {
    image: 'book',
    title: 'Local services catalogue',
    description:
      'Access to the services directory app related to spa, beauty and wellness in Hong Kong with their reviews, both written by the users and employees of Spa Monkeys.',
    img: 'spamonkeys/spamonkeys-house.png',
  },
  {
    image: 'house',
    title: 'Homepage',
    description:
      'The most important place on the site. We designed new, responsive homepage that is eye-catching and useful at the same time.',
    img: 'spamonkeys/spamonkeys-book.png',
  },
  {
    image: 'blog',
    title: 'Blog',
    description:
      'Great place where you can find interesting articles related to the main site topic.',
    img: 'spamonkeys/spamonkeys-blog.png',
  },
];
