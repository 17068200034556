import React from 'react';
import { Section } from 'components';
import BigNumberOrderedList from '../../../../components/BigNumberOrderedList';

import './Benefits.scss';

export default function Benefits() {
  return (
    <Section
      containerClass={'bring-a-qualified-lead__benfits'}
      spacedTop
      spaceless
    >
      <h2
        className={
          'bring-a-qualified-lead__heading bring-a-qualified-lead__heading--more-spaced'
        }
      >
        What benefits await you?
      </h2>
      <BigNumberOrderedList
        className={'bring-a-qualified-lead__benfits__list'}
      >
        <BigNumberOrderedList.Item
          subtextClassName={'bring-a-qualified-lead__benfits__list__subtext'}
          subtext={'Get rewarded with a $2200 fee for each qualified lead.'}
        >
          Get €2200 fee
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={'bring-a-qualified-lead__benfits__list__subtext'}
          subtext={
            'Offer your clients added value through a diverse array of services'
          }
        >
          Boost the value of your company
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={'bring-a-qualified-lead__benfits__list__subtext'}
          subtext={
            'Maximize your business opportunities through strategic network growth.'
          }
        >
          Expand your circle of contacts
        </BigNumberOrderedList.Item>
      </BigNumberOrderedList>
    </Section>
  );
}
