import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import { classNames } from 'utils';
import './Author.scss';

const Author = ({
  additionalClass,
  avatar,
  name,
  job,
  link,
  isExternalAuthor,
}) => (
  <div className={classNames('author', additionalClass)}>
    <img
      src={avatar}
      alt={`Author - ${name} ${job}`}
      className={'author__avatar'}
      loading={'lazy'}
    />
    {link ? (
      <a href={`/author/${link}/`} className={'link author__name'}>
        {name}
      </a>
    ) : (
      <span className={'link author__name'}>{name}</span>
    )}
    <Paragraph additionalClass={'author__job'}>{job}</Paragraph>
    {!isExternalAuthor && (
      <div className={'author__company'}>
        at Boldare -{' '}
        <a href={'/'} className={'author__link'}>
          Product Design and Development Company
        </a>
      </div>
    )}
  </div>
);

Author.propTypes = {
  additionalClass: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  link: PropTypes.string,
  isExternalAuthor: PropTypes.bool,
};

Author.defaultProps = {
  additionalClass: '',
  link: '',
  isExternalAuthor: false,
};

export default Author;
