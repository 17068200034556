import React from 'react';
import './Quality.scss';
import {
  Section,
  Paragraph,
  SectionHeader,
  BackgroundContent,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';

const Quality = () => (
  <>
    <Section
      additionalClass={'digtra-de-quality__section'}
      color={'white'}
      id={'digtra-de-quality'}
      spacedTop
    >
      <div className={'digtra-de-quality__background-color-box'} />
      <Row>
        <Col xs={12}>
          <Row>
            <Col sm={12} md={7} lg={7}>
              <SectionHeader
                size={'large'}
                color={'mine-shaft'}
                lineColor={'grey'}
                descriptionColor={'black'}
                fixed
                headingType={2}
                noLine
                additionalClass={'digtra-de-quality__text-header'}
              >
                Unsere Qualitätsgarantie
              </SectionHeader>
              <div
                className={'digtra-de-quality__text-header-secondary-wrapper'}
              >
                <div
                  className={'digtra-de-who-are-__vertical-line hide-mobile'}
                />
                <Paragraph
                  additionalClass={'digtra-de-quality__text-header-secondary'}
                >
                  Höchste Qualität - wie funktioniert das? Hersteller
                  „haptischer” Produkte können Ihre Qualität beispielsweise
                  über die Verarbeitung und die Wahl verschiedener Materialien
                  darstellen.
                </Paragraph>
                <Paragraph
                  additionalClass={'digtra-de-quality__text-header-secondary'}
                >
                  Doch wie kann eine hohe Qualität in der Entwicklung digitaler
                  Produkte und dessen Design garantiert werden? Oder aber auch
                  in der Unterstützung bei der digitalen Transformation eines
                  Unternehmens? Bei Boldare nutzen wir hierfür sieben
                  Qualitätsmerkmale:
                </Paragraph>
              </div>
            </Col>
            <Col sm={12} md={5} lg={5} className={'digtra-de-quality__image'}>
              <div>
                <img
                  src={getCloudinaryUrl(
                    'img/new-normal-cover-photo.png',
                    'f_auto,q_auto,w_440',
                  )}
                  className={'image'}
                  alt={'Quality'}
                  loading={'lazy'}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={'no-gap'}>
        <Col sm={6} md={4}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'1'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Rekrutierung von Experten
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Boldare beschäftigt europaweit über 200 Mitarbeiter. Beim
              Recruiting jenes Mitarbeiters stellt das Unternehmen höchste
              Ansprüche an die Bewerber und prüft diese. Hard- und Soft-Skils
              werden mittels zahlreicher Instrument untersucht. Unserem
              Unternehmen garantiert dies eine erfolgreiche Zusammenarbeit.
            </Paragraph>
          </div>
        </Col>
        <Col sm={6} md={4}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'2'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Kommunikation & Transparenz
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Boldare ist eine holokratische Organisation, welche auf den
              Einsatz von Managern verzichtet und sich stattdessen
              selbstorganisiert. Daher gibt es auch keine Eins-zu-Eins
              Kommunikationen sondern kontinuierliche Absprachen im gesamten
              Team. Dies verhindert Informationsverluste und steigert damit
              automatisch die Qualität der Projekte
            </Paragraph>
          </div>
        </Col>
        <Col sm={6} md={4}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'3'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Ganzes Team kein Body Leasing
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Boldare besetzt Projekte nicht mit einzelnen Mitarbeiter, welche
              fremden Unternehmen „zur Verfügung” gestellt werden. Wir bieten
              unseren Kunden ganze Projektteams, welche individuell auf den
              Projektbedarf unseres Kunden zusammengestellt werden.
            </Paragraph>
          </div>
        </Col>
        <Col sm={6} md={4}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'4'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Gut geplante Sprints/Prozesse
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Boldare nutzt den Scrum-Prozess. Dies ermöglicht eine sukzessive
              Projektentwicklung mit flexibler Anpassung an aufkommende
              Bedürfnisse eines Projekts. Somit werden teure Fehlerkosten durch
              nachträgliche Anpassungen eines Projekts vermieden.
            </Paragraph>
          </div>
        </Col>
        <Col sm={6} md={4}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'5'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Entwicklung ganzheitlicher Produkte
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Wir sind in der Lage eine ganzheitliche Produktentwicklung
              anbieten zu können. Dabei vereinen wir die Stärken jedes
              einzelnen Mitarbeiters und somit des gesamten Unternehmens. Statt
              nur einzelne Bereiche eines Projektes bspw. aus dem Front- oder
              Backend-Bereich anzubieten, entwicklen wir ein Produkt für den
              Kunden aus einer Hand.
            </Paragraph>
          </div>
        </Col>
        <Col sm={6} md={4} className={'digtra-de-quality-item-wrapper'}>
          <div className={'digtra-de-quality-item'}>
            <BackgroundContent
              height={220}
              content={'6'}
              color={'grey'}
              opacity={0.1}
            />
            <SectionHeader
              size={'tiny-plus'}
              headingType={3}
              lineColor={'pigeon-post'}
            >
              Ständige Testings & Reviews
            </SectionHeader>
            <Paragraph additionalClass={'text--black'}>
              Zusammenhängend mit dem Scrum-Prozess finden regelmäßige Testings
              und Reviews statt, um die einzelnen Sprints der
              Projektentwicklung so effizient und fehlerfrei wie möglich zu
              gestalten.
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Section>
  </>
);

export default Quality;
