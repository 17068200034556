import { SectionHeader, YouTubePlayer } from 'components';
import React from 'react';
import { TextInteractionAnimation } from 'views/remoteWork/components';
import './MeetOurTeamSection.scss';

const MeetOurTeamSection = () => {
  return (
    <section className={'section-meet'}>
      <SectionHeader
        size={'extra-large'}
        color={'black'}
        headingType={2}
        noLine
        noBottomMargin
        additionalClass={'section-meet__heading'}
      >
        <TextInteractionAnimation text={'Meet'} />
        <span> our </span>
        <br />
        <TextInteractionAnimation text={'team!'} />
      </SectionHeader>
      <div className={'section-meet__video'}>
        {/* todo we can add "light" parameter so that the yt files are not downloaded until the user clicks play but then the user
          has to click play twice so it's bad for UX for now LazyLoad should be enough */}
        <YouTubePlayer
          src={'https://www.youtube.com/watch?v=GHnNlPj79E8'}
          // light
        />
      </div>
    </section>
  );
};

export default MeetOurTeamSection;
