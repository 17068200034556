import React, { Component } from 'react';

import { Clients } from 'views/clients';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

class ClientsPage extends Component {
  render() {
    const page = pages.clients;
    const { navColors } = page;
    const url = `${config.siteUrl}/clients/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <Clients />
      </Layout>
    );
  }
}

export default ClientsPage;
