export const data = [
  {
    title: 'Problem identification',
    description:
      'We identify your problem and select the development team with the necessary skills to help you achieve your business goals.<br /><br />The team gets to know the product vision during workshops and proposes a bespoke solution that will evolve thanks to a lean startup approach.',
  },
  {
    title: 'Ideation',
    description:
      'The team starts prototyping, helping you test your assumptions, refine your ideas and turn them into a tangible object, all within the Scrum framework.<br /><br />The team already begins testing the product-market fit, getting your prototype to real users and collecting feedback for a minimum viable product (MVP).',
  },
  {
    title: 'Product development',
    description:
      'The Scrum team develops an MVP according to the build-measure-learn principle and looks for a product-market fit to fulfil your business goals.<br /><br />You receive a working increment of the product with every sprint (1-2 weeks) so you can easily track progress, release often and change direction when necessary.<br /><br />You have the full control over scope and budget at all time thanks to an agile approach to software development.<br /><br />The team, with agile coach on board, helps you to develop processes in-house so you can sustainably grow the product on your own in the future.',
  },
  {
    title: 'Sustainable growth',
    description:
      'You have a scalable product that fits the market well and a business model that is sustainable.<br /><br />Your company and in-house team has the processes and competences to grow your business further.',
  },
];
