import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import './Share.scss';
import facebookLogo from 'assets/images/logos/facebook-logo.svg';
import twitterLogo from 'assets/images/logos/twitter-logo.svg';
import linkedinLogo from 'assets/images/logos/linkedin-logo.svg';
import MediaTypes from 'config/constants/mediaTypes';
import { getPlatformShareUrl } from 'utils';

const Share = ({ mediaTypes, shareUrl, title }) => (
  <div className={'share'}>
    {mediaTypes.find(m => m === MediaTypes.FACEBOOK) && (
      <FacebookShareButton
        url={getPlatformShareUrl(shareUrl, 'Facebook')}
        hashtag={'#Boldare'}
      >
        <img src={facebookLogo} alt={'Boldare facebook'} loading={'lazy'} />
      </FacebookShareButton>
    )}
    {mediaTypes.find(m => m === MediaTypes.LINKEDIN) && (
      <LinkedinShareButton
        url={getPlatformShareUrl(shareUrl, 'Linkedin')}
        title={'by @boldarecom'}
      >
        <img src={linkedinLogo} alt={'Boldare linkedin'} loading={'lazy'} />
      </LinkedinShareButton>
    )}
    {mediaTypes.find(m => m === MediaTypes.TWITTER) && (
      <TwitterShareButton
        url={getPlatformShareUrl(shareUrl, 'Twitter')}
        title={`${title} via @boldarecom`}
      >
        <img src={twitterLogo} alt={'Boldare twitter'} loading={'lazy'} />
      </TwitterShareButton>
    )}
  </div>
);

Share.defaultProps = {
  mediaTypes: [],
};

Share.propTypes = {
  mediaTypes: PropTypes.array,
  shareUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Share;
