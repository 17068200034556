import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { NavBar } from 'components';
import CareerOffers from 'views/careerOffers';
import { pages } from 'config/pages';
import config from '../../../meta/config';

class CareerOffersPage extends Component {
  render() {
    const page = pages.careerOffers;
    const { navColors } = page;
    const url = `${config.siteUrl}/career/offers/`;
    const canonical = `${url}`;
    const navBarParams = { color: navColors };

    const { edges: offers } = this.props.data.allMarkdownRemark || {};

    return (
      <Layout {...{ page, canonical }}>
        <NavBar
          {...navBarParams}
          additionalClass={'menu--career'}
          cookieBar={'sticky-career'}
          bannerHidden
          button={{
            link: '/',
            id: 'ga-career--join-us',
            label: 'All offers',
            className: 'navbar--text-center',
          }}
        />
        <CareerOffers offers={offers} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CareerOffersPage {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        frontmatter: {
          templateKey: { eq: "job-offer-page" }
          mainContent: { activeOffer: { eq: true } }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
      edges {
        node {
          frontmatter {
            mainContent {
              activeOffer
              talentPool
              name
              photo
              cities
              contractType {
                contract
                salaries {
                  level
                  min
                  max
                }
              }
              banner
              technical {
                skill
              }
              additional {
                skill
              }
              specifics {
                specific
              }
              steps {
                number
                step
                details
              }
            }
          }
        }
      }
    }
  }
`;

export default CareerOffersPage;
