import './index.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';

// Components
import AnimatedStories from './Components/AnimatedStories';

import AnimatedResults from './Components/AnimatedResults';

const Hero = () => {
  const sectionRef = useRef();
  const [offsetTop, setOffsetTop] = useState(9999999);
  const [windowHeight, setWindowHeight] = useState(9999999);

  const resultsStart = useMemo(() => offsetTop + windowHeight, [
    offsetTop,
    windowHeight,
  ]);
  const storiesShow = useMemo(() => resultsStart + windowHeight, [
    resultsStart,
    windowHeight,
  ]);
  const storiesStart = useMemo(() => storiesShow + windowHeight, [
    storiesShow,
    windowHeight,
  ]);
  const storiesEnd = useMemo(() => storiesStart + windowHeight, [
    storiesStart,
    windowHeight,
  ]);

  useEffect(() => {
    setOffsetTop(sectionRef.current.offsetTop);
    setWindowHeight(Math.ceil(window.innerHeight / 2));
  }, []);

  return (
    <div
      ref={sectionRef}
      className={'dsgn-hero__animation-wrapper dsgn--full-screen'}
    >
      <div className={'dsgn-hero__animation-content'}>
        <AnimatedResults />
        <AnimatedStories
          end={storiesEnd}
          show={storiesShow}
          start={storiesStart}
        />
      </div>
    </div>
  );
};

export default Hero;
