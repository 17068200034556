import React from 'react';

import WaypointContext from 'components/WayPoint/Context/context';

export default function withWaypoints(Component) {
  return function WrappedComponent(props) {
    return (
      <WaypointContext.Consumer>
        {context => <Component {...props} {...context} />}
      </WaypointContext.Consumer>
    );
  };
}
