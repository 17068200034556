import React from 'react';
import './Features.scss';
import { Heading, IconSVG, Paragraph } from 'components';
import data from './logic';

const Functionality = () => {
  return (
    <section className={'srt-functionality w-container'}>
      <Heading
        additionalClass={'srt-functionality__heading'}
        size={'big'}
        type={2}
      >
        Key features of the Sprint Retrospective Tool
      </Heading>
      <Paragraph additionalClass={'srt__small-paragraph'}>
        With this tool you can:
      </Paragraph>

      <div className={'srt-functionality__items'}>
        {data.map(item => (
          <div className={'srt-functionality__item'}>
            <IconSVG
              additionalClass={'srt-functionality__icon'}
              name={`${item.icon}`}
            />
            <Paragraph
              additionalClass={'srt__paragraph anim-fade-in-left'}
              color={'black'}
            >
              {item.description}
            </Paragraph>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Functionality;
