import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  IconSVG,
  HashtagList,
} from 'components';

const TechnologyStack = () => (
  <Section color={'mine-shaft'} additionalClass={'pdad__technology'}>
    <SectionHeader
      size={'big'}
      lineColor={'red'}
      headingType={2}
      color={'white'}
      description={
        'Boldare’s dedicated teams work with both frontend and backend technologies and meet the highest standards of the <a href="/blog/software-development-outsourcing-everything-you-should-know/">software development</a> process. Our developers will recommend the best technologies and programming languages to suit your business needs.'
      }
      descriptionColor={'white'}
    >
      Technology stack
    </SectionHeader>
    <Row className={'no-gap anim-fade-in-bottom pdad__technology-list'}>
      <Col sm={12} md={4} className={'pdad__technology-item'}>
        <IconSVG name={'eye'} size={'large'} color={'white'} />
        <Heading color={'white'} size={'medium-plus'} type={3}>
          Frontend technologies
        </Heading>
        <Paragraph color={'white'} additionalClass={'text--light'}>
          Our teams include both frontend developers and graphic designers. The
          resulting synergy means high quality development of responsive web
          apps that can be used on any device. Our products have received
          numerous frontend and design awards - an acknowledgement of our
          unique approach to team working.
        </Paragraph>
        <HashtagList
          color={'white'}
          data={[
            'Angular',
            'React',
            'Javascript',
            'TypeScript',
            'Vue.js',
            '.Net',
          ]}
          tagSize={'small'}
          tagClass={'pdad__technology-tag text--medium'}
        />
      </Col>
      <Col sm={12} md={4} className={'pdad__technology-item'}>
        <IconSVG name={'network'} size={'large'} color={'white'} />
        <Heading color={'white'} size={'medium-plus'} type={3}>
          Backend technologies
        </Heading>
        <Paragraph color={'white'} additionalClass={'text--light'}>
          Our backend teams work on the behind-the-scenes elements of your app
          or API, the foundations that support the frontend interface or
          website. What’s more, our teams can ensure seamless integration of
          the product with any third party systems; e.g. payment gateways or
          two-factor authentication.
        </Paragraph>
        <HashtagList
          color={'white'}
          data={[
            'PHP',
            'JAVA',
            'JavaScript',
            'Symfony',
            'NodeJS',
            'Django',
            'Python',
            'Angular',
            'React',
            '.Net',
          ]}
          tagSize={'small'}
          tagClass={'pdad__technology-tag text--medium'}
        />
      </Col>
      <Col sm={12} md={4} className={'pdad__technology-item'}>
        <IconSVG name={'brain'} size={'large'} color={'white'} />
        <Heading size={'medium-plus'} color={'white'} type={3}>
          Mobile technologies
        </Heading>
        <Paragraph color={'white'} additionalClass={'text--light'}>
          Our highly skilled mobile developers can deliver both native mobile
          apps and hybrid mobile apps across all platforms. We will propose the
          programming language suited to your business strategy and target
          audience needs.
        </Paragraph>
        <HashtagList
          color={'white'}
          data={[
            'iOS (Objective-C)',
            'Android (JAVA)',
            'Windows/UWP',
            'Xamarin',
            'React Native',
          ]}
          tagSize={'small'}
          tagClass={'pdad__technology-tag text--medium'}
        />
      </Col>
    </Row>
  </Section>
);

export default TechnologyStack;
