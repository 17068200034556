import React from 'react';
import PropTypes from 'prop-types';

import Planet from 'assets/images/mega-menu/planet.png';
import People from 'assets/images/mega-menu/people.png';

import './TeamsSection.scss';

const TeamsSection = () => {
  const ListElement = (title, description) => (
    <li className={'teams-section__list-element'}>
      <h3 className={'teams-section__title'}>{title}</h3>
      <p className={'teams-section__description'}>{description}</p>
    </li>
  );

  return (
    <div className={'teams-section'}>
      <img
        src={Planet}
        alt={'Earth from space.'}
        className={'teams-section__image top'}
      />
      <img
        src={People}
        alt={'Four men in shirts and ties.'}
        className={'teams-section__image bottom'}
      />
      <h2 className={'teams-section__header'}>
        <span>
          Our interdisciplinary, Agile scrum teams come in two flavors:
        </span>
      </h2>
      <ul className={'teams-section__list'}>
        {ListElement(
          'Dedicated Teams',
          'Established, experienced teams at the service of your business goals',
        )}
        {ListElement(
          'Team Augmentation',
          'Let your product take advantage of our experts - Devops, Solution Architects, Product Strategists, and more',
        )}
      </ul>
      <a
        href={'https://www.boldare.com/services/development-teams/'}
        className={'button button--primary button--white teams-section__cta'}
      >
        Learn more
      </a>
    </div>
  );
};

TeamsSection.defaultProps = {
  type: '',
};

TeamsSection.propTypes = {
  type: PropTypes.string,
};

export default TeamsSection;
