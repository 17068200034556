import React from 'react';
import Arrows from 'views/about/subcomponents/Arrows';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Orbit } from 'components/DS';

import './PopUpContent.scss';

const PopupContent = ({
  state,
  handleToggle,
  header,
  subheader,
  buttonHref,
  title,
  description,
  highlight,
  buttonText,
  popupClassId,
  popupServicesClass,
}) => (
  <div className={`popup__container-theme`}>
    <div
      id={popupClassId}
      className={`popup ${state ? 'popup-open' : 'popup-close'}`}
    >
      <button className={'close-button'} onClick={handleToggle}>
        &#10005;
      </button>
      <div className={'orbit-container'}>
        <Orbit theme={'light'} hideMoon />
      </div>
      <div className={'popup-content'}>
        <div className={'popup-left'}>
          <div className={'rectangle-area'}>
            <div className={'arrows-wrapper'}>
              <Arrows count={3} />
            </div>
            <h1>{header}</h1>
            <p>{subheader}</p>
          </div>
        </div>
        <div className={'popup-right'}>
          <h2>
            <span>{title}</span>&nbsp;&nbsp;&nbsp;
            <span className={'marker-highlight marker-black'}>
              {highlight}
            </span>
          </h2>
          <p>{description}</p>
          <a
            href={buttonHref}
            className={classNames(
              'button',
              'button--primary',
              'button--white',
              'button--border-black',
              'popup-button',
              popupServicesClass,
            )}
          >
            {buttonText} &rarr;
          </a>
        </div>
      </div>
    </div>
  </div>
);

PopupContent.propTypes = {
  state: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  popupClassId: PropTypes.string.isRequired,
  popupServicesClass: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  buttonHref: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default PopupContent;
