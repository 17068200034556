import React from 'react';

import './Resources.scss';
import ResourceCard from './subcomponents/ResourceCard';
import { resourceCardData } from './logic';

const Resources = () => {
  return (
    <>
      <div className={'app-cost-thank-you-resources w-container'}>
        <h3 className={'app-cost-thank-you-resources__header'}>
          In the meantime, explore our universe and see:
        </h3>
        <div className={'app-cost-thank-you-resources__content'}>
          {resourceCardData &&
            resourceCardData.map(item => (
              <ResourceCard
                key={item.id}
                header={item.header}
                description={item.description}
                img={item.img}
                color={item.color}
                link={item.link}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default Resources;
