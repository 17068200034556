import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Services.scss';

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.sm__services-container').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = () =>
    data.map(item => (
      <div
        className={classNames('sm__services-box', {
          'sm__services-box--entered': this.state.entered,
        })}
        key={`sm-services-${item.image}`}
      >
        <Heading type={3} size={'small-plus'} color={'mine-shaft'}>
          {item.title}
        </Heading>
        <div className={'sm__services-image'}>
          <img
            src={getCloudinaryUrl(item.img, 'f_auto,q_auto')}
            alt={item.title}
            className={'image'}
            loading={'lazy'}
          />
        </div>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          {item.description}
        </Paragraph>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'sm__services-container'} spaceless>
        {this.renderBoxes()}
      </Section>
    );
  }
}

export default Services;
