import React, { Component, Fragment } from 'react';
import {
  BoxLayoutSymetrical,
  SectionHeader,
  BoxCaseStudy,
  Section,
} from 'components';
import Slider from 'react-slick';
import { BOX_TYPES } from 'config';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic';
import { clients } from './clients';
import './WhatWeDid.scss';
import companyData from '../../../../../meta/companyData';

class WhatWeDid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        className: 'whatwedid-clients__list',
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false,
      },
    };
  }

  renderStories = () =>
    data.map((item, index) => (
      <>
        {item.type === BOX_TYPES.CASE_STUDY && (
          <BoxCaseStudy
            data={{
              ...item,
              content: {
                ...item.content,
                coverImage: getCloudinaryUrl(
                  item.content.coverImage,
                  'q_auto,f_auto,h_320',
                ),
              },
            }}
            id={item.id}
            additionalClass={'anim-fade-in-bottom'}
          />
        )}
      </>
    ));

  renderItems = () =>
    clients.map((item, index) => (
      <div className={'whatwedid-clients__item'} key={`award-${index}`}>
        <div
          className={`image whatwedid-clients__image ${
            item.black ? 'whatwedid-clients__image--zero-brightness' : ''
          } ${item.big ? 'whatwedid-clients__image--big' : ''}`}
        >
          <img
            src={getCloudinaryUrl(item.img, 'f_auto,q_auto,w_200')}
            className={'image'}
            alt={item.image}
            loading={'lazy'}
          />
        </div>
      </div>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section color={'white'} additionalClass={'mvp__what-we-did'}>
          <SectionHeader
            additionalClass={'mvp__what-we-did-header'}
            description={`Throughout the ${companyData.years_experience} years of Boldare, we have successfully released over 80 MVPs for clients from all over the world, including France, Germany, Saudi Arabia, UAE, UK and USA. We use the most popular programming languages, including JavaScript, Python, and Node.js. Every designated team is tailored to your needs and usually consists of  developers, designers and a scrum master. Below, you will find the most recent examples of delivered MVPs:`}
            lineColor={'yellow'}
          >
            What’s our experience with MVPs?
          </SectionHeader>
          <BoxLayoutSymetrical>{this.renderStories()}</BoxLayoutSymetrical>
        </Section>
        <Slider {...sliderSettings}>{this.renderItems()}</Slider>
      </>
    );
  }
}

export default WhatWeDid;
