import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import {
  Heading,
  Icon,
  Paragraph,
  Section,
  SectionHeader,
  Tag,
} from 'components';
import { enableAnimations } from 'config/helpers/index.js';
import { ICON_TYPES } from 'config';
import {
  CAREER_STATE_KEY,
  careerCategories,
  countOffersByCategory,
  countOffersByCities,
  filterOffers,
  getOfferCategory,
  getOfferCitiesLabels,
  getOfferLink,
  getOfferName,
  getOfferSalaryRange,
  groupOffersByPromotedFlag,
  getTalentPool,
} from 'views/career/career.utils';
import { CountDown, SingleOffer } from 'views/career/subcomponents';
import './OffersAdvanced.scss';

const keysByLabel = {
  City: 'cityFilter',
  Category: 'categoryFilter',
};

class OffersAdvanced extends Component {
  constructor(props) {
    super(props);

    this.refCityFilter = createRef();
    this.refCategoryFilter = createRef();

    this.state = {
      cityFilter: [],
      categoryFilter: [],
      allOffersVisible: true,
    };
  }

  componentDidMount() {
    const localStorageState =
      typeof window !== 'undefined'
        ? JSON.parse(window.localStorage.getItem(CAREER_STATE_KEY))
        : null;
    if (localStorageState) {
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    enableAnimations();
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  }

  getCalculatedOffers = () => {
    const { categoryFilter, cityFilter } = this.state;
    const { offers } = this.props;

    return filterOffers(offers, categoryFilter, cityFilter);
  };

  renderOffers = offers => {
    return [
      ...offers.map((offer, id) => (
        <SingleOffer
          key={`offer-${offer.node.id}`}
          offer={offer}
          id={id}
          salaryRange={getOfferSalaryRange(offer)}
          cities={getOfferCitiesLabels(offer)}
          name={getOfferName(offer)}
          category={careerCategories[getOfferCategory(offer)]}
          link={getOfferLink(offer)}
          talentPool={getTalentPool(offer)}
        />
      )),
    ];
  };

  renderOffersRows = filtered => {
    const {
      promotedOffersDeadline,
      initiallyVisibleNotPromotedOffersCount,
    } = this.props;
    const { allOffersVisible } = this.state;
    const isSingleOfferVisible = false;
    const isPromotedOffersDeadlineEnabled = false;
    const offers = groupOffersByPromotedFlag(filtered);
    return (
      <>
        <div className={'off-adv__tagsContainer'}>
          {this.renderOffersFilters('Category', careerCategories)}
        </div>
        <div id={'off-adv'} className={'offers__container'}>
          {filtered.length > 0 ? (
            <>
              {offers.promoted.length > 0 && (
                <Row className={'no-gap offers__container-row'}>
                  {isPromotedOffersDeadlineEnabled && (
                    <div className={'offers__recommended'}>
                      <CountDown deadline={promotedOffersDeadline} />
                    </div>
                  )}
                  {this.renderOffers(offers.promoted)}
                </Row>
              )}

              {/* YOU CAN LOAD BANNER HERE */}
              {/* <BannerF2F /> */}

              <Row
                className={'no-gap offers__container-row post-list__offers'}
              >
                {this.renderOffers(
                  allOffersVisible
                    ? offers.notPromoted
                    : offers.notPromoted.slice(
                        0,
                        initiallyVisibleNotPromotedOffersCount,
                      ),
                )}

                {allOffersVisible || (
                  <button
                    className={'off-adv__load-more-button'}
                    onClick={() => {
                      this.setState(prevState => {
                        const state = {
                          ...prevState,
                          allOffersVisible: true,
                        };
                        window.localStorage.setItem(
                          CAREER_STATE_KEY,
                          JSON.stringify(state),
                        );
                        return state;
                      });
                    }}
                  >
                    Load more job offers
                    <Icon
                      additionalClass={'off-adv__load-more-button-arrow'}
                      icon={ICON_TYPES.RIGHT_ARROW}
                      size={'default'}
                      color={'black'}
                    />
                  </button>
                )}
              </Row>
            </>
          ) : (
            <div className={'off-adv__noOffers'}>
              <div className={'offers__contact-content'}>
                <SectionHeader
                  color={'mine-shaft'}
                  size={'medium'}
                  headingType={2}
                  noLine
                >
                  No job offers available at this time?
                </SectionHeader>
                <Paragraph color={'mine-shaft'} size={'medium'}>
                  No worries just drop us a line!
                </Paragraph>
                <div className={'offers__contact-links'}>
                  <a
                    href={
                      'https://boldare.breezy.hr/p/a691cfa5814101-contact-us'
                    }
                    className={'button button--primary button--yellow'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Contact us
                  </a>
                  <a
                    href={'/career'}
                    className={'link'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Go back to Career
                  </a>
                </div>
              </div>
            </div>
          )}
          {isSingleOfferVisible && (
            <Row>
              <SingleOffer id={'offer-ad'} isAd />
            </Row>
          )}
        </div>
      </>
    );
  };

  updateFilters(label, filter) {
    const key = [keysByLabel[label]];

    this.setState(prevState => {
      const state = prevState[key].includes(filter)
        ? {
            ...prevState,
            [key]: prevState[key].filter(value => value !== filter),
          }
        : {
            ...prevState,
            [key]: filter ? [...prevState[key], filter] : [],
          };
      window.localStorage.setItem(CAREER_STATE_KEY, JSON.stringify(state));
      return state;
    });
  }

  checkResults(label, newFilter) {
    this.updateFilters(label, newFilter);
  }

  countCategoryOffers(category) {
    const { cityFilter } = this.state;
    const { offers } = this.props;
    return countOffersByCategory(offers, category, cityFilter);
  }

  countCitiesOffers(chosenCities) {
    const { offers } = this.props;
    return countOffersByCities(offers, chosenCities);
  }

  renderOffersTag = ({ onClick, label, isActive, count }) => {
    return (
      <Tag active={isActive} onClick={onClick}>
        <span className={'off-adv__tag-box'} />
        {label}{' '}
        <span
          className={`off-adv__tag-counter ${
            isActive ? 'off-adv__tag-counter--active' : ''
          }`}
        >
          ({count})
        </span>
      </Tag>
    );
  };

  renderOffersFilters(label, data) {
    const { cityFilter, categoryFilter } = this.state;
    const { offers } = this.props;
    const isCity = label === 'City';
    const isAllActive =
      (isCity && !cityFilter.length) || (!isCity && !categoryFilter.length);
    const countAll = isCity
      ? offers.length
      : this.countCitiesOffers(cityFilter);

    const filters = Object.keys(data).map(key => {
      const isActive =
        cityFilter.includes(key) || categoryFilter.includes(key);
      const count = isCity
        ? this.countCitiesOffers([key])
        : this.countCategoryOffers(key);
      return (
        <div key={`filter-${key}`}>
          {this.renderOffersTag({
            onClick: () => {
              this.checkResults(label, key);
            },
            isActive,
            count,
            label: data[key],
          })}
        </div>
      );
    });

    return (
      <>
        <Heading additionalClass={'off-adv__filters-heading'}>
          Category:
        </Heading>
        <div>
          {this.renderOffersTag({
            onClick: () => {
              this.checkResults(label, null);
            },
            isActive: isAllActive,
            count: countAll,
            label: 'All',
          })}
        </div>
        {filters}
      </>
    );
  }

  render() {
    const items = this.getCalculatedOffers();

    return (
      <Section
        id={'off-adv-section'}
        additionalClass={`off-adv ${!items.length && 'off-adv--empty'}`}
      >
        <div className={'w-container off-adv__heading-wrapper'}>
          <Heading
            type={3}
            size={'medium'}
            color={'mine-shaft'}
            additionalClass={'headline--top headline--top-yelow'}
          >
            Boldare Offers
          </Heading>
          <SectionHeader
            additionalClass={'headline--centered'}
            color={'mine-shaft'}
            size={'medium'}
            headingType={2}
            noLine
          >
            Find your{' '}
            <span
              className={'headline--marking headline--marking-yellow--dream'}
            >
              dream&nbsp;job
            </span>
          </SectionHeader>
        </div>
        <div className={'off-adv__panel'}>{this.renderOffersRows(items)}</div>
      </Section>
    );
  }
}

OffersAdvanced.defaultProps = {
  initiallyVisibleNotPromotedOffersCount: 2,
};

OffersAdvanced.propTypes = {
  initiallyVisibleNotPromotedOffersCount: PropTypes.number,
  offers: PropTypes.array.isRequired,
  promotedOffersDeadline: PropTypes.instanceOf(Date).isRequired,
};

export default OffersAdvanced;
