export const data = [
  {
    eventKey: 'about',
    image: 'img/team-in-meeting.jpg',
    imagePos: 'center',
    title: `About Boldare`,
    description: `Brief description of who we are and what we do in the form of docs, photos and logos, for your convenience. Download a .zip pack and get to know us better.`,
    size: '35 MB',
    dir: 'About Boldare',
  },
  {
    eventKey: 'executives',
    image: 'gatsby-cloudinary/about-us-founders-normal.png',
    imagePos: 'center',
    title: `Executives`,
    description: `Photos and bios of our leadership team.`,
    size: '26 MB',
    dir: 'Boldare-Executives-NEW',
  },
  {
    eventKey: 'boldares',
    image: 'img/morning-in-cafe.jpg',
    imagePos: 'bottom',
    title: `Boldares`,
    description: `A collection of photos of people creating our culture and helping our customers achieve their business goals; extra shots of our creativity-supporting offices.`,
    size: '91 MB',
    dir: 'People and Workspace',
  },
  {
    eventKey: 'events',
    image: 'gatsby-cloudinary/holacracy_meetup.jpg',
    imagePos: 'top',
    title: `Events`,
    description: `Photos from Boldare events: internal and external.`,
    size: '7 MB',
    dir: 'Events',
  },
  {
    eventKey: 'logos',
    image: 'gatsby-cloudinary/boldare_bg_logo.jpg',
    imagePos: 'center',
    title: `Boldare logos`,
    description: `A logo pack for media coverage.`,
    size: '7 MB',
    dir: 'BOLDARE_BRAND',
  },
  {
    eventKey: 'youtube',
    title: `Check out our videos on YouTube`,
    description: `Case studies, events, testimonials - discover Boldare on video.`,
    link: 'https://www.youtube.com/boldarecom/',
    download: 'Visit us on YouTube',
  },
];
