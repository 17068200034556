import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import StickyBox from 'react-sticky-box';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col } from 'react-flexbox-grid';
import {
  SectionHeader,
  Paragraph,
  Heading,
  ResourceList,
  GateForm,
} from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import './WebinarGateContent.scss';

class WebinarGateContent extends Component {
  renderSpeakerList = speakers => {
    return (
      <ul className={'speakers-component__speaker-list'}>
        {speakers.map(speaker => (
          <li className={'speakers-component__speaker-list-item'}>
            <div className={'speakers-component__speaker-list-img'}>
              <img
                className={'image'}
                alt={speaker.name}
                src={getCloudinaryUrl(speaker.avatar, 'f_auto,q_auto')}
                loading={'lazy'}
              />
            </div>
            <div className={'speakers-component__speaker-list-content'}>
              <span className={'speakers-component__speaker-list-name'}>
                {speaker.name}
              </span>
              <span className={'speakers-component__speaker-list-position'}>
                {speaker.position}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      btnColor,
      button,
      description,
      formHeading,
      icon,
      learnItems,
      lineColor,
      outro,
      premiere,
      title,
      titleSize,
      listTitle,
      topImage,
      trackingEvent,
      type,
      subtitle,
      speakers,
      url,
      webinarButton,
      webinarContent,
      webinarTitle,
      webinarFormDescription,
      gateAddress,
      gateCatalog,
    } = this.props;

    return (
      <>
        <Row className={'no-gap'}>
          <Col md={12} lg={7} className={'webinar__main'}>
            {icon && (
              <SVG
                src={icon}
                alt={'Code Audit Webinar'}
                className={'multimedia-icon'}
              />
            )}
            {topImage && (
              <img
                src={getCloudinaryUrl(topImage, 'f_auto,q_auto,w_340')}
                additionalClass={'multimedia-image'}
                loading={'lazy'}
              />
            )}
            <div className={'webinar__inner webinar__top'}>
              {type && (
                <p
                  className={classNames('webinar__article-category', {
                    'webinar__article-category--yellow':
                      lineColor === 'yellow',
                  })}
                >
                  {type}
                </p>
              )}
              {premiere && (
                <p
                  className={
                    'webinar__article-category webinar__article-category--premiere'
                  }
                >
                  Premiere: {premiere}
                </p>
              )}
              {title && (
                <SectionHeader
                  type={1}
                  size={titleSize || 'extra-large'}
                  additionalClass={'webinar__heading'}
                  lineColor={lineColor}
                >
                  {ReactHtmlParser(title)}
                </SectionHeader>
              )}
              {subtitle && (
                <Heading
                  type={3}
                  size={'medium'}
                  additionalClass={'accordion__title'}
                >
                  {subtitle}
                </Heading>
              )}
              {description && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'webinar__description'}
                >
                  {ReactHtmlParser(description)}
                </Paragraph>
              )}
            </div>
            <div className={'webinar__sidebar webinar__sidebar--mobile'}>
              <div className={'webinar__inner'}>
                <GateForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    webinarButton,
                    webinarContent,
                    webinarTitle,
                    trackingEvent,
                    type,
                    url,
                    gateAddress,
                    gateCatalog,
                    webinarFormDescription,
                  }}
                />
              </div>
            </div>
            <div className={'webinar__inner'}>
              {speakers && Array.isArray(speakers) && speakers.length && (
                <div className={'speakers-component__content-speakers'}>
                  <Heading
                    type={3}
                    size={'medium'}
                    additionalClass={'accordion__title'}
                  >
                    Meet the speakers:
                  </Heading>
                  {this.renderSpeakerList(speakers)}
                </div>
              )}

              {listTitle && (
                <SectionHeader
                  headingType={2}
                  size={'large'}
                  additionalClass={'webinar__heading'}
                  lineColor={lineColor}
                >
                  {listTitle}
                </SectionHeader>
              )}

              {learnItems && <ResourceList {...{ items: learnItems }} />}

              {outro && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'webinar__description text--bold'}
                >
                  {outro}
                </Paragraph>
              )}
            </div>
          </Col>
          <Col
            md={12}
            lg={5}
            className={'webinar__sidebar webinar__sidebar--desktop'}
          >
            <StickyBox offsetTop={100} style={{ zIndex: 100 }}>
              <div className={'webinar__inner'}>
                <GateForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    webinarButton,
                    webinarContent,
                    webinarTitle,
                    trackingEvent,
                    type,
                    url,
                    gateAddress,
                    gateCatalog,
                    webinarFormDescription,
                  }}
                />
              </div>
            </StickyBox>
          </Col>
        </Row>
      </>
    );
  }
}

WebinarGateContent.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  description: PropTypes.string,
  formHeading: PropTypes.string,
  icon: PropTypes.string,
  topImage: PropTypes.string,
  learnItems: PropTypes.array,
  listTitle: PropTypes.string,
  lineColor: PropTypes.string,
  outro: PropTypes.string,
  premiere: PropTypes.string,
  speakers: PropTypes.array,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  webinarContent: PropTypes.string,
  webinarTitle: PropTypes.string,
  webinarButton: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
};

WebinarGateContent.defaultProps = {
  btnColor: '',
  button: '',
  description: '',
  formHeading: '',
  icon: '',
  learnItems: [],
  lineColor: 'red',
  listTitle: '',
  outro: '',
  premiere: '',
  speakers: [],
  subtitle: '',
  title: '',
  topImage: null,
  webinarButton: { path: '', label: '' },
  webinarContent: '',
  webinarTitle: '',
};

export default WebinarGateContent;
