import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section } from 'components';
import { ServiceTile } from 'views/fullProductDevelopmentCycle/subcomponents';

const OtherServices = () => (
  <Section
    additionalClass={'digital-transformation__service-tile'}
    fullwidth
    spaceless
  >
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Full Product <br />Development Cycle'}
          description={
            'Boldare’s seasoned teams can assist at each stage of innovation. With experts on board who can advise on the best course of action, Boldare will guide you through your digital product journey.'
          }
          textColor={'white'}
          backgroundColor={'mine-shaft'}
          link={'/services/full-cycle-product-development/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Development <br />Teams'}
          description={
            'Boldare’s skilled development teams can help you reach your business goals and guide you through the Lean Startup approach.'
          }
          link={'/services/development-teams/'}
          backgroundColor={'red'}
        />
      </Col>
    </Row>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'AI Services'}
          description={
            'Together with Boldare’s experts discover how AI services can be tailored to address challenges across various industries, simplifying problem-solving and enhancing efficiency.'
          }
          backgroundColor={'gray-light'}
          link={'/services/ai-software-development-consulting/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Product Design<br />and Development'}
          description={
            'Boldare’s experienced specialists will look after every aspect of your product, from design and development, to DevOps, tech stack consulting and usability testing.'
          }
          backgroundColor={'pigeon-post'}
          link={'/services/product-design-and-development/'}
        />
      </Col>
    </Row>
  </Section>
);

export default OtherServices;
