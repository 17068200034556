import React from 'react';
import PropTypes from 'prop-types';
import Scroll from '../../Scroll';
import '../SectionHello.scss';
import CircleImages from '../../CircleImages';

const SectionHelloMobile = ({ variant }) => {
  if (variant === 'about-us-a') {
    return (
      <>
        <div className={'section-wrapper section-hello'}>
          <section className={'grid-layout'}>
            <CircleImages />
            <p className={'about-subtitle'}>
              Hello. We are Boldare - digital product creators & consultants
            </p>
            <h2 className={'about-header'}>
              We exist to help our clients{' '}
              <span className={'marker-highlight marker-highlight-a'}>
                redefine
              </span>{' '}
              their industries by building with them the products their users
              want
            </h2>
            <Scroll className={'scroll-down'} />
          </section>
        </div>
        <div className={'grid-layout section-info'}>
          <h2 className={'about-header section-info__header'}>
            How do we win? We’ve created a pioneering{' '}
            <span className={'marker-highlight marker-black'}>
              environment
            </span>{' '}
            that jet-fuels the way we deliver our second-to-none services and
            products. Let us show you how it works!
          </h2>
          <h2 className={'about-header section-info__header'}>
            Created in 2004, we have{' '}
            <span className={'marker-highlight-small marker-black'}>
              grown
            </span>{' '}
            into a team of 200+ team with 6 offices across Europe.
          </h2>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={'section-wrapper section-hello'}>
        <section className={'grid-layout'}>
          <CircleImages />
          <h2 className={'about-subtitle'}>We are Boldare</h2>
          <h2 className={'about-header'}>
            How our services work is shaped by the{' '}
            <span className={'marker-highlight'}>environment</span> we have
            created
          </h2>
          <p className={'about-text'}>
            This is an invitation to explore that environment and see how it
            can impact your business.
          </p>
          <Scroll className={'scroll-down'} />
        </section>
      </div>
      <div className={'grid-layout section-info'}>
        <h2 className={'about-header section-info__header'}>
          Started in <span className={'marker-highlight-small'}>2004,</span>{' '}
          Boldare is now 200+ people working in 6 offices, including Hamburg,
          Amsterdam and Warsaw.
        </h2>
        <h2 className={'about-header section-info__header'}>
          Today we are the{' '}
          <span className={'marker-highlight marker-black'}>benchmark</span>{' '}
          others use. How?
        </h2>
      </div>
    </>
  );
};

export default SectionHelloMobile;

SectionHelloMobile.propTypes = {
  variant: PropTypes.string.isRequired,
};
