export const data = [
  {
    question: 'How do you work - remotely or from an office?',
    answer:
      '#BoldareTeam members work both remotely and stationary. Those who want to use one of our offices are warmly welcome (according to the pandemic situation, we strictly adhere to the recommended precautions and restrictions). If you prefer to stay home - you’ve got it. But don’t forget about your teammates! We like each other, and team meetings in the office from time to time are an important tradition in Boldare. ' +
      'Teams also have the option of organizing workshops with clients in their offices, if they decide to do so.',
  },
  {
    question: 'If I joined Boldare, how often do I meet f2f with my team?',
    answer: `Working on 99% remote in Boldare doesn't mean that you will never meet your team in real life - and we believe it's a huge benefit! Each team in our organization has its own rules, and all team members decide how often they want to meet f2f to spend some time together. Some teams adore workation and travel together to beautiful places once in a quarter. Others have in common one day of collaborative work per month. There are also squads that are used to conduct stationary workshops in our offices (Warszawa, Gliwice, Kraków, Wrocław). The clue is: we won't tell you how often you will meet your teammates in person, it depends on you and your colleagues, but you bet your life - it will fit your wants!`,
  },
  {
    question: 'How do you deal with remote working?',
    answer:
      'We have been operating as a distributed organization for several years now, so we are used to working in a remote environment. \n' +
      '\n\n' +
      'We have offices in four locations (Warszawa, Kraków, Wrocław, Gliwice), and we also work with people who live ' +
      'permanently elsewhere; e.g. in Masuria, Gdańsk, Łódź or even Sweden! And everyone gets along well thanks to ' +
      'how we organize our work, the team "rituals" we follow and the communication and work tools we use (e.g. ' +
      'Slack, G-Suite, Asana). We also work with foreign clients, whom we do not see on a daily basis, but the ' +
      'collaboration is smooth thanks to structured meetings and working in scrum.',
  },
  {
    question: 'How is the atmosphere at work?',
    answer: `Our kind of cultural mantra is: “We don’t fake our vibes”. That’s what our culture is based upon. We meet each other in person as often as possible and keep in contact through various forms of remote engagement like short slots before the daily, magic questions asked by our scrum masters, online lunches and coffees, etc. We also care for team meetings in real life that give us more feedback and help to achieve goals efficiently. Likewise, we have also introduced remote versions of our knowledge-sharing initiatives, among the others: #architecture-coffee for developers, #agile-lean-coffee for non-devs, #chapter-designers, #holameetings, #englishbreakfast or #scrumathon.`,
  },
  {
    question:
      'Are you looking for new employees only to join a particular project (and what happens when the project ends)?',
    answer:
      'We are currently looking for skilled specialists for the projects that are just underway, as well as for ' +
      'those currently ongoing due to the teams’ constant scaling.  However, in recruitment we follow a general rule ' +
      'that we are looking for new people to join the company in general, not one a project basis, when needed. It ' +
      'means after one project is completed, we allocate our developers, analysts, testers, designers or scrum masters ' +
      'to another (most likely upcoming) project to let everybody grow and face new challenges.',
  },
  {
    question:
      'According to the pandemic - have there been any layoffs or salary cuts so far?',
    answer:
      "No, we have not laid anyone off due to the crisis, nor have we cut anyone's salary. #BoldareTeam members’ " +
      'job security and their sense of safety has been always our priority, and now it appears to be even more relevant. ' +
      'Not only do we act to protect Boldare people from any negative consequences of the pandemic, but we are also ' +
      'undergoing a company-wide process of raising wages.',
  },
  {
    question:
      'How about your business stability - do you acquire new customers?',
    answer: `Yes, we are starting new projects with new customers, and we are also continuing to work with our existing customers. During the whole of 2020, we’ve welcomed over 20 new clients on board (and we are constantly working to keep more customers coming). 2021 is promising too, we have started 13 new collaborations so far.`,
  },
];
