import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { AnnouncementBanner, Link, Button } from '..';
import sharpArrowRightIcon from '../../assets/images/arrow-list/sharp-arrow-right.svg';
import './TopBanner.scss';

const TopBanner = ({
  event,
  joinText,
  onClose,
  type,
  buttonColor,
  desktopButtonLabel,
  date,
  mobileDescription,
  description,
  subDescription,
  url,
  mobileButtonLabel,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 480);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const text = isMobile ? mobileDescription : description;
  // eslint-disable-next-line no-nested-ternary
  const formattedText = text === 'AI Riyadh meetup | 28.02' ? text.replace(/(AI Riyadh meetup | 28.02)/g, '<strong>$1</strong>')
    : isMobile ? text.replace(/(Improving Digital Platform on a Budget with AI)/g, '<strong>$1</strong>')
    : text.replace(/(AI)/g, '<strong>$1</strong>');

  return (
    <div
      className={classNames('top-banner-wrapper', {
        'top-banner-wrapper--webinar': type === 'webinar',
      })}
    >
      <AnnouncementBanner
        additionalClass={classNames('top-banner')}
        onClose={onClose}
      >
        {/* <img
          className={'top-banner__image'}
          src={
            'https://res.cloudinary.com/de4rvmslk/image/upload/v1686752011/apb-community/apb-logo-white.svg'
          }
          alt={'Live Meetup'}
        /> */}
        {/* <div className={'top-banner__date'}>{date}</div> */}
        {/* <div className={'top-banner__separator'} /> */}
        <div className={'top-banner__description'}>
          <div
            className={'top-banner__description'}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </div>

        <div className={'top-banner__subDescription'}>{subDescription}</div>
        <div className={'top-banner-join'}>
          {url && (
            <Link
              id={`top-banner-webinar`}
              additionalClass={'top-banner-join__button newsletter-subscribe'}
              to={url}
            >
              <Button
                color={buttonColor}
                id={'banner-top-cta-button'}
                ariaName={'Subscribe button'}
              >
                {isMobile && (
                  <div className={'banner-cta-button__container'}>
                    <div className={'banner-cta-button__label'}>
                      {mobileButtonLabel}
                    </div>
                    <img
                      className={'banner-cta-button__icon'}
                      src={sharpArrowRightIcon}
                      alt={'Sharp Arrow Right Icon'}
                    />
                  </div>
                )}
                {!isMobile && desktopButtonLabel}
              </Button>
            </Link>
          )}
        </div>
      </AnnouncementBanner>
    </div>
  );
};

TopBanner.propTypes = {
  desktopButtonLabel: PropTypes.string,
  mobileButtonLabel: PropTypes.string,
  event: PropTypes.object,
  joinText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
};

TopBanner.defaultProps = {
  mobileButtonLabel: 'Read',
  desktopButtonLabel: 'Sign up',
  buttonColor: 'white',
  event: null,
  joinText: null,
  type: null,
};

export default TopBanner;
