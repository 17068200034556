import React from 'react';
import PropTypes from 'prop-types';

import { BOX_TYPES } from 'config';
import { classNames } from 'utils';

import './MainTag.scss';

const MainTag = ({ type }) => {
  const isArticle = type === BOX_TYPES.BLOG;

  return (
    <div
      className={classNames('work-boxes-tag', {
        'work-boxes-tag--article': isArticle,
        'work-boxes-tag--study': !isArticle,
      })}
    >
      {type}
    </div>
  );
};

MainTag.propTypes = { type: PropTypes.string.isRequired };

export default MainTag;
