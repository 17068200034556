import React, { useEffect } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import Glide from '@glidejs/glide';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './SliderAsNav.scss';

const SliderAsNav = ({
  items,
  componentToBeNavigated,
  sliderToBeNavigatedOptions,
  sliderAsNavId,
  sliderToBeNavigatedId,
}) => {
  const options = {
    type: 'carousel',
    animationDuration: 700,
    animationTimingFunc: 'ease-out',
    perView: 4.5,
    swipeThreshold: 30,
    dragThreshold: 30,
    bound: true,
    classes: {
      activeSlide: 'slider-as-nav__slide--active',
    },
    startAt: 0,
    focusAt: 0,
    breakpoints: {
      340: {
        perView: 3.7,
      },
      480: {
        perView: 4,
      },
    },
  };

  useEffect(() => {
    const sliderAsNav = new Glide(`#${sliderAsNavId}`, options);
    const sliderToBeNavigated = new Glide(
      `#${sliderToBeNavigatedId}`,
      sliderToBeNavigatedOptions,
    );

    sliderAsNav.mount();
    sliderToBeNavigated.mount();

    sliderAsNav.on('run', function() {
      sliderToBeNavigated.go(`=${sliderAsNav.index}`);
    });

    sliderToBeNavigated.on('swipe.end', function() {
      sliderAsNav.go(`=${sliderToBeNavigated.index}`);
    });

    sliderToBeNavigated.on('move.after', function() {
      sliderAsNav.go(`=${sliderToBeNavigated.index}`);
    });

    Array.from(
      document.querySelectorAll(`#${sliderAsNavId} .slider-as-nav__image`),
    ).forEach(el => {
      el.addEventListener('click', e => {
        sliderAsNav.go(`=${e.target.dataset.index}`);
        sliderToBeNavigated.go(`=${e.target.dataset.index}`);
      });
    });

    return () => {
      sliderAsNav.destroy();
      sliderToBeNavigated.destroy();
    };
  }, []);

  return (
    <>
      <div className={'slider-as-nav__container'}>
        <div className={'glide slider-as-nav'} id={sliderAsNavId}>
          <div className={'glide__track'} data-glide-el={'track'}>
            <ul className={'glide__slides'}>
              {items.map((item, index) => (
                <li
                  className={classnames(
                    'glide__slide',
                    'slider-as-nav__slide',
                  )}
                  id={`glide__slide-${index}`}
                >
                  <img
                    className={'slider-as-nav__image'}
                    alt={item.alt}
                    src={getCloudinaryUrl(
                      item.cloudinaryDir,
                      'f_auto,q_auto,w_160,h_160',
                    )}
                    data-index={index}
                    loading={'lazy'}
                    width={64}
                    height={64}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id={sliderToBeNavigatedId} className={'glide'}>
        {componentToBeNavigated}
      </div>
    </>
  );
};

SliderAsNav.defaultProps = {
  sliderToBeNavigatedOptions: {},
};

SliderAsNav.propTypes = {
  sliderAsNavId: PropTypes.string.isRequired,
  sliderToBeNavigatedId: PropTypes.string.isRequired,
  sliderToBeNavigatedOptions: PropTypes.object,
  componentToBeNavigated: PropTypes.element.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cloudinaryDir: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SliderAsNav;
