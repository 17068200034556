import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Section,
  SectionHeader,
  Heading,
  Paragraph,
  Link,
  Button,
} from 'components';
import SVG from 'react-inlinesvg';
import tick from 'assets/images/software-development-outsourcing/tick.svg';
import { code, ux } from './logic.js';
import './Scopes.scss';

const Scopes = () => (
  <>
    <Section color={'white'} additionalClass={'code-audit__scopes'}>
      <div className={'code-audit__scopes-content'}>
        <SectionHeader
          size={'large'}
          color={'mine-shaft'}
          lineColor={'yellow'}
          description={
            'To make sure that your digital product is spotless, we will run a code audit and UX audit to help you avoid a technical debt in the future, or overcome a current one. You choose what fits your needs most. Don’t know what to choose? Check out what our service includes:'
          }
          descriptionColor={'mine-shaft'}
          headingType={2}
          fixed
          additionalClass={'section-header__description--medium'}
        >
          Code audit or UX audit? <br />
          Do both
        </SectionHeader>

        <div className={'scopes__list'}>
          <ul className={'scopes__list-column'}>
            <Heading size={'big'} additionalClass={'scopes__list-heading'}>
              Code audit scope:
            </Heading>
            {code.map(item => (
              <li className={'scopes__list-item'}>
                <div className={'scopes__list-item-icon'}>
                  <SVG src={tick} alt={'List item'} />
                </div>
                <div className={'scopes__list-item-text'}>
                  {ReactHtmlParser(item)}
                </div>
              </li>
            ))}
          </ul>
          <ul className={'scopes__list-column'}>
            <Heading size={'big'} additionalClass={'scopes__list-heading'}>
              UX audit scope:
            </Heading>
            {ux.map(item => (
              <li className={'scopes__list-item'}>
                <div className={'scopes__list-item-icon'}>
                  <SVG src={tick} alt={'List item'} />
                </div>
                <div className={'scopes__list-item-text'}>
                  {ReactHtmlParser(item)}
                </div>
              </li>
            ))}
          </ul>
          <div className={'scopes__list-technologies'}>
            <Heading
              size={'medium-plus-small'}
              additionalClass={'technologies__heading'}
            >
              We audit the following technologies:
            </Heading>
            <Paragraph size={'medium'}>
              Java, JavaScript, Python, PHP, Node.js, Angular, React, Django,
              Symfony, AWS.
            </Paragraph>
          </div>
        </div>
      </div>
    </Section>
    <Section
      color={'white'}
      additionalClass={'code-audit__scopes bottom-part'}
    >
      <Link
        additionalClass={'scopes__details'}
        to={'/blog/6-insights-you-can-demand-from-code-and-UX-audit/'}
      >
        <Button
          color={'transparent'}
          additionalClass={'scopes__details-button'}
          ariaName={'Register button'}
        >
          <Paragraph additionalClass={'text--bold'} size={'medium'}>
            Need more details?
          </Paragraph>
          <Paragraph size={'medium'}>
            Read more about code auditing on our blog ->
          </Paragraph>
        </Button>
      </Link>
    </Section>
  </>
);

export default Scopes;
