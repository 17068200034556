import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import SystemicDigitalTransformation from 'views/systemicDigitalTransformation';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import Layout from 'components/layout';
import config from '../../../meta/config';

class SysDTPage extends Component {
  render() {
    const page = pages['systemic-digital-transformation'];
    const { navColors } = page;
    const canonical = `${config.siteUrl}/services/systemic-digital-transformation/`;

    const { data } = this.props;
    const { allArticles } = data || {};

    return (
      <Layout {...{ page, canonical }}>
        <Helmet
          title={
            'Systemic Digital Transformation for travel industry - webinar'
          }
        >
          <meta
            name={'description'}
            content={
              'See practical tips on how to grow with your travel business thanks to Systemic Digital Transformation.'
            }
          />
          <link rel={'canonical'} href={canonical} />
        </Helmet>
        <NavBar color={navColors} />
        <PopupRedirect />
        <SystemicDigitalTransformation allArticles={allArticles} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query SysDTQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: { frontmatter: { title: { in: ["", "", ""] } } }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default SysDTPage;
