import React from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Description.scss';

const Description = () => {
  return (
    <>
      <Section additionalClass={'sm__description-container'} spaceless>
        <div className={'sm__content'}>
          <Heading type={3} size={'medium-plus'}>
            Global Search in action
          </Heading>
          <Heading type={4} color={'boston-blue'} size={'medium-plus-small'}>
            Displayed results in less than 5ms
          </Heading>
        </div>
      </Section>
      <Section additionalClass={'sm__description-screenshot'} spaceless>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-screenshot.jpg',
            'f_auto,q_auto',
          )}
          alt={'System architecture'}
        />
        <Paragraph
          additionalClass={'text--light sm__description sm__description-1'}
          size={'default'}
          color={'mine-shaft'}
        >
          Data from blog based on Wordpress
        </Paragraph>
        <Paragraph
          additionalClass={'text--light sm__description sm__description-2'}
          size={'default'}
          color={'mine-shaft'}
        >
          Fetched from Shopify API
        </Paragraph>
        <Paragraph
          additionalClass={'text--light sm__description sm__description-3'}
          size={'default'}
          color={'mine-shaft'}
        >
          Data from REST API
        </Paragraph>
      </Section>
      <Section additionalClass={'sm__description-container'} spaceless>
        <div className={'sm__content'}>
          <Heading type={3} size={'medium-plus'}>
            Simplicity of the Local Search
          </Heading>
          <Heading type={4} color={'boston-blue'} size={'medium-plus-small'}>
            Just 3 steps to find a perfect service
          </Heading>
        </div>
      </Section>
      <Section additionalClass={'sm__description-screenshot'} spaceless>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-screenshot-2.jpg',
            'f_auto,q_auto',
          )}
          alt={'Search system'}
        />
        <Paragraph
          additionalClass={'text--light sm__description sm__description-4'}
          size={'default'}
          color={'mine-shaft'}
        >
          Accessible from any position of the catalogue
        </Paragraph>
      </Section>
      <Section additionalClass={'sm__description-screenshot'} spaceless>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-screenshot-3.jpg',
            'f_auto,q_auto',
          )}
          alt={'Search results'}
        />
        <Paragraph
          additionalClass={'text--light sm__description sm__description-5'}
          size={'default'}
          color={'mine-shaft'}
        >
          Results displayed within seconds
        </Paragraph>
      </Section>
    </>
  );
};

export default Description;
