import React, { useEffect } from 'react';
import './Awwwards.scss';
import { ContactFormServices } from 'components';
import { enableAnimations } from 'config/helpers';
import {
  AboutUs,
  Banner,
  DesignProcess,
  OurAwards,
  OurDesigns,
  Related,
} from './subcomponents';

const Awwwards = () => {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <section className={'awwwards-lp'}>
      <Banner />
      <OurDesigns />
      <OurAwards />
      <AboutUs />
      <DesignProcess />
      <Related />
      <ContactFormServices
        id={'awwwwards__contact'}
        name={'awwwards-contact-form'}
        pardotLink={'https://go.boldare.com/l/688533/2019-12-03/3vfls'}
        awwwardsForm
      />
    </section>
  );
};

export default Awwwards;
