import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import withWaypoints from 'components/WayPoint/Context/HOC';

class WayPointHandler extends Component {
  constructor(props) {
    super(props);

    this.startTime = Date.now();
  }

  handleEnter = () => {
    const { path, reportActiveViewEntry } = this.props;
    this.startTime = Date.now();

    reportActiveViewEntry({ path });
  };

  handleLeave = () => {
    const elapsedTime = Math.round((Date.now() - this.startTime) / 1000);
    const { path, reportActiveViewLeave } = this.props;

    reportActiveViewLeave({
      path,
      timeSpent: elapsedTime,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <Waypoint onLeave={this.handleLeave} onEnter={this.handleEnter}>
        <div>{children}</div>
      </Waypoint>
    );
  }
}

WayPointHandler.defaultProps = { children: null };

WayPointHandler.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  reportActiveViewEntry: PropTypes.func.isRequired,
  reportActiveViewLeave: PropTypes.func.isRequired,
};

export default withWaypoints(WayPointHandler);
