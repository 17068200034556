import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

import './AnnouncementBanner.scss';

const AnnouncementBanner = ({ children, additionalClass, onClose, style }) => {
  return (
    <>
      <div className={classNames('announcement-banner')} style={style}>
        <div className={classNames('w-container', additionalClass)}>
          {children}
        </div>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          className={'announcement-banner--close'}
          onClick={onClose}
          aria-label={'Close'}
        >
          &#10005;
        </button>
      </div>
    </>
  );
};

AnnouncementBanner.propTypes = {
  children: PropTypes.node,
  additionalClass: PropTypes.string,
  onClose: PropTypes.func,
  testType: PropTypes.string,
  style: PropTypes.object,
};

AnnouncementBanner.defaultProps = {
  children: null,
  additionalClass: null,
  onClose: null,
  testType: null,
  style: {},
};

export default AnnouncementBanner;

export const AnnouncementBannerSpace = () => (
  <div className={'announcement-banner-space'} />
);
