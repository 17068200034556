import React from 'react';
import { Section, Paragraph, Link, Button } from 'components';
import './Contact.scss';

const Contact = () => {
  return (
    <Section additionalClass={'corel__contact'}>
      <div className={'corel__contact-container anim-fade-in-bottom'}>
        <Link to={'/contact/#form'}>
          <Paragraph
            size={'big'}
            color={'yellow'}
            additionalClass={'text--bold'}
          >
            Let's talk
          </Paragraph>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'corel__contact-content'}
          >
            We'd love to help with your product. Get in touch.
          </Paragraph>
          <Button
            id={`contact-case-study-corel`}
            additionalClass={'corel__contact-button'}
            transparent
            color={'white'}
            ariaName={'Contact button'}
          >
            Contact
          </Button>
        </Link>
      </div>
    </Section>
  );
};

export default Contact;
