import TakamolIcon from 'assets/images/digital-transformation-journey/carousel/takamol-logo-white.png';
import UnifonicIcon from 'assets/images/digital-transformation-journey/clients/unifonic-logo-new.svg';
import FadiMozuannar from 'assets/images/digital-transformation-journey/carousel/fadi-mozuannar.svg';
import MaticIcon from 'assets/images/digital-transformation-journey/clients/matic-horizontal-en-blue.png';
import KaspienIcon from 'assets/images/digital-transformation-journey/clients/Kasp-Logo.svg';

export const slidersInformation = [
  {
    title: `... adaptability and development expertise`,
    description: `Boldare was able to support us whenever we needed it.
    They were very flexible in this respect if we needed additional resources or wanted to expand the team for a short period.
    The second part was expertise in development,
    such as providing an agile methodology in general, good teamwork, and integrating you into the team. It was very effective.`,
    person: {
      name: 'Fadi Mozuannar',
      position: 'Senior Manager',
      company: 'Takamol Holding',
      country: 'Kingdom of Saudi Arabia',
      image: FadiMozuannar,
    },
    companyIcon: TakamolIcon,
    recording: {
      description: `Listen, what our client is valuing more in cooperation with BOLDARE?`,
      url: ``,
    },
  },
  {
    title: `They were always thinking about the success of the project.`,
    person: {
      name: 'Ahmed Rawass',
      position: 'Senior UX Manager',
      company: 'Takamol Holding',
      country: 'Kingdom of Saudi Arabia',
    },
    companyIcon: TakamolIcon,
  },
  {
    title: `They cared about our end customers, which is hard to find worldwide.`,
    person: {
      name: 'Ahmad Alhusainy',
      position: 'Director of Software Engineering',
      company: 'Unifonic',
      country: 'Kingdom of Saudi Arabia',
    },
    companyIcon: UnifonicIcon,
  },
  {
    title: `I’ve never known a vendor as helpful as they are.`,
    person: {
      name: 'Abdulaziz Ababtain',
      position: 'Business Development',
      company: 'Takamol Holding',
      country: 'Kingdom of Saudi Arabia',
    },
    companyIcon: TakamolIcon,
  },
  {
    title: `The team is professional enough to execute any tech project.`,
    person: {
      name: 'Issam Najm',
      position: 'CTO',
      company: 'Matic Services',
      country: 'United Arab Emirates',
    },
    companyIcon: MaticIcon,
  },
  {
    title: `What is amazing about Boldare is the combination of their experience, expertise, and amazing personalities.`,
    person: {
      name: 'Sean Jones',
      position: 'Director of Engineering',
      company: 'Kaspien, Inc.',
      country: 'United States of America',
    },
    companyIcon: KaspienIcon,
  },
];
