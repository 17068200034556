import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { toHtml, getCloudinaryUrl } from 'config/helpers';
import { Section, Heading, Paragraph, Testimonial } from 'components';
import { classNames } from 'utils';
import './Summary.scss';

const Summary = ({ data, mainColor }) => {
  const {
    caption,
    name,
    description,
    review,
    product,
    deliverables,
    awards,
    media,
    timespan,
    cost,
    logo,
  } = data;
  const { author, testimonial } = review || [];

  return (
    <>
      <Section spaceless>
        <Row className={'no-gap summary__top'}>
          <Col md={12} lg={8} className={'summary__main'}>
            <div className={'summary__inner'}>
              <div className={'summary__image-wrapper'}>
                <img
                  src={getCloudinaryUrl(logo, 'f_auto,q_100')}
                  className={'image summary__image'}
                  alt={''}
                  loading={'lazy'}
                />
              </div>
              <Heading
                type={2}
                size={'large'}
                additionalClass={'summary__heading'}
              >
                {name || ''}
              </Heading>
              <Paragraph>{caption || ''}</Paragraph>
            </div>
          </Col>
          <Col
            md={12}
            lg={4}
            className={classNames('summary__sidebar', {
              [`summary__sidebar--${mainColor}`]: mainColor,
            })}
          >
            <div className={'summary__inner'}>
              <Heading
                size={'medium'}
                type={3}
                additionalClass={'text--default summary__testimonial-intro'}
              >
                Client review
              </Heading>
              <Testimonial
                author={author || ''}
                content={testimonial || ''}
                additionalClass={'text--light'}
              />
            </div>
          </Col>
        </Row>
        <Row className={'no-gap summary__bottom'}>
          <Col md={12} lg={8} className={'summary__main'}>
            <div className={'summary__inner case-study-content__section'}>
              {description ? toHtml(description) : ''}
            </div>
          </Col>
          <Col
            md={12}
            lg={4}
            className={classNames(
              'summary__sidebar',
              'summary__sidebar--list',
              {
                [`summary__sidebar--${mainColor}`]: mainColor,
              },
            )}
          >
            <div className={'summary__inner'}>
              <div className={'summary__item'}>
                <Paragraph
                  size={'small'}
                  additionalClass={'summary__item-title'}
                >
                  Product
                </Paragraph>
                <Paragraph
                  size={'default'}
                  additionalClass={'text--default summary__item-desc'}
                >
                  {product || ''}
                </Paragraph>
              </div>
              <div className={'summary__item'}>
                <Paragraph
                  size={'small'}
                  additionalClass={'summary__item-title'}
                >
                  Deliverables
                </Paragraph>
                <Paragraph
                  size={'default'}
                  additionalClass={'text--default summary__item-desc'}
                >
                  {deliverables || ''}
                </Paragraph>
              </div>
              <div className={'summary__item'}>
                <Paragraph
                  size={'small'}
                  additionalClass={'summary__item-title'}
                >
                  Timespan
                </Paragraph>
                <Paragraph
                  size={'default'}
                  additionalClass={'text--default summary__item-desc'}
                >
                  {timespan || ''}
                </Paragraph>
              </div>
              {cost && (
                <div className={'summary__item'}>
                  <Paragraph
                    size={'small'}
                    additionalClass={'summary__item-title'}
                  >
                    Cost
                  </Paragraph>
                  <Paragraph
                    size={'default'}
                    additionalClass={'text--default summary__item-desc'}
                  >
                    {cost || ''}
                  </Paragraph>
                </div>
              )}
              {awards && (
                <div className={'summary__item'}>
                  <Paragraph
                    size={'small'}
                    additionalClass={'summary__item-title'}
                  >
                    Awards
                  </Paragraph>
                  {awards.map(item => (
                    <Paragraph
                      size={'default'}
                      additionalClass={'text--default summary__item-desc'}
                    >
                      {item.image}
                    </Paragraph>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
};

Summary.defaultProps = {
  mainColor: 'yellow',
};

Summary.propTypes = {
  data: PropTypes.shape({}),
  mainColor: PropTypes.string,
};

export default Summary;
