import React from 'react';
import { graphql } from 'gatsby';
import SoftwareDevelopmentOutsourcing from 'views/softwareDevelopmentOutsourcing';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['software-development-outsourcing'];
const { navColors } = page;
const url = `${config.siteUrl}/services/software-development-outsourcing/`;
const canonical = `${url}`;

const SDOPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <SoftwareDevelopmentOutsourcing allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SDOQuery {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Software Development Outsourcing - everything you should know"
              "8 Benefits Of Outsourcing your Software Development to Poland"
              "Software development in Germany - costs, challenges, and chances"
              "This is how Boldare Development Teams process addresses your business needs"
              "Choosing a digital product development partner - the political and economic issues"
              "When two become one. The story of Boldare"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default SDOPage;
