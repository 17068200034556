import React from 'react';
import PropTypes from 'prop-types';

const TimedContent = ({ date, before, after }) => {
  if (Date.now() < date) {
    return before;
  }
  return after;
};

TimedContent.propTypes = {
  date: PropTypes.number.isRequired,
  before: PropTypes.node.isRequired,
  after: PropTypes.node.isRequired,
};

export default TimedContent;
