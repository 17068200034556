import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UserGeolocationContext } from 'context';
import BOX_TYPE from 'config/constants/boxTypes';
import { getPrettyFilter } from '../utils';
import {
  arrangeEntries,
  filterEntries,
  sortEntries,
  createBlogArticleMap,
  getCaseStudiesUserRegion,
} from './useCaseStudies.helpers';

const FILTER_LABEL_KEY_MAP = {
  Industry: 'industry',
  ProductStage: 'productStage',
  Region: 'region',
};

const getInitialFilters = (userRegion) => {
  return {
    industry: null,
    productStage: null,
    region: userRegion,
  };
}

export const useCaseStudies = ({
  data,
  onFilterChange,
  articles,
  boxPattern,
}) => {
  const { userCountryName, userContinent } = useContext(UserGeolocationContext);
  // region is not defined until detection is complete
  const userRegion = useMemo(() => (userCountryName === undefined && userContinent === undefined)
    ? null
    : getCaseStudiesUserRegion(userCountryName, userContinent), [userCountryName, userContinent])

  const initialFilters = useMemo(() => getInitialFilters(userRegion), [userRegion]);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const updateFilters = useCallback(
    (label, filter) => {
      // with each update, reset all other filters
      setFilters({
        ...initialFilters,
        [FILTER_LABEL_KEY_MAP[label]]: filter,
      });
      onFilterChange && onFilterChange(getPrettyFilter(filter));
    },
    [setFilters, onFilterChange, getPrettyFilter],
  );

  // map blog post boxes to their article data only once
  const BLOG_ARTICLE_MAP = useMemo(
    () => createBlogArticleMap(data, articles),
    [articles, data],
  );

  // sort entries only once
  const sortedEntries = useMemo(() => sortEntries(data), [data]);

  // dynamically computed based on filters, articles and boxPattern
  const dataOut = useMemo(() => {
    // apply filters
    const filteredEntries = filterEntries(sortedEntries, filters);
    // arrange according to given pattern
    const arrangedEntries = arrangeEntries(filteredEntries, boxPattern);
    // add article data to box payloads
    return arrangedEntries.map(entry =>
      entry.type !== BOX_TYPE.BLOG
        ? entry
        : {
            ...entry,
            articleData: BLOG_ARTICLE_MAP.get(entry.id),
          },
    );
  }, [filters, boxPattern, BLOG_ARTICLE_MAP, userRegion]);

  return { data: dataOut, filters, updateFilters, userRegion };
};
