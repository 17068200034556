import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph } from 'components';
import { toHtml } from 'config/helpers';
import './Step.scss';

const Step = ({ number, step, details }) => (
  <div className={'job-offer__banner-step'} key={number}>
    <span className={'job-offer__banner-step-number'}>{number}</span>
    <Heading
      additionalClass={'job-offer__banner-step-heading'}
      size={'medium'}
      type={3}
    >
      {step}
    </Heading>
    <Paragraph
      additionalClass={'job-offer__banner-step-paragraph'}
      color={'mine-shaft'}
      nested
    >
      {toHtml(details)}
    </Paragraph>
  </div>
);

Step.defaultProps = {
  number: '',
  step: '',
  details: '',
};

Step.propTypes = {
  number: PropTypes.string,
  step: PropTypes.string,
  details: PropTypes.string,
};

export default Step;
