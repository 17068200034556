import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'utils';

import './MarkerHighlight.scss';

function MarkerHighlight({
  children,
  color,
  size,
  className: additionalClassName,
}) {
  const className = cn('marker-highlight', {
    [`marker-highlight--${color}`]: color,
    [`marker-highlight--${size}`]: size,
    [additionalClassName]: additionalClassName,
  });

  return <span className={className}>{children}</span>;
}

MarkerHighlight.propTypes = {
  color: PropTypes.oneOf(['black', 'violet']),
  size: PropTypes.oneOf(['small', 'long']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

MarkerHighlight.defaultProps = {
  color: undefined,
  size: undefined,
  className: undefined,
};

export default MarkerHighlight;
