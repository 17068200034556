import companyData from '../../../meta/companyData';

export default [
  {
    number: 1,
    content: 'service standard',
    id: 'one',
  },
  {
    header: 'almost',
    number: Number(companyData.years_experience) + 2,
    content: 'years of experience',
    id: 'two',
  },
  {
    header: 'over',
    number: companyData.clients,
    content: 'clients globally',
    id: 'three',
  },
  {
    header: 'over',
    number: companyData.products,
    content: 'digital products',
    id: 'four',
  },
  {
    header: 'over',
    number: companyData.employees,
    content: 'skilled problem-solvers on board',
    id: 'five',
  },
];
