import './index.scss';
import React, { useState } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

// Components
import AnimatedHeading, { headingVariants } from '../AnimatedHeading';

const AnimatedResultsOverlay = ({ end, show, start }) => {
  const { scrollY } = useViewportScroll();
  const [isVisible, setIsVisible] = useState(false);
  const opacity = useTransform(scrollY, [start, end], [1, 0]);

  scrollY.onChange(progress => {
    if (isVisible && progress < show) {
      setIsVisible(false);
    } else if (!isVisible && progress >= show) {
      setIsVisible(true);
    }
  });

  return (
    <motion.div
      style={{ opacity }}
      className={'dsgn-hero__animated-results-heading'}
    >
      <AnimatedHeading isVisible={isVisible} color={'#FFF'}>
        <motion.span variants={headingVariants}>Delivers</motion.span>{' '}
        <motion.span variants={headingVariants}>results</motion.span>
      </AnimatedHeading>
    </motion.div>
  );
};

export default AnimatedResultsOverlay;
