export const companiesData = [
  {
    description: `All dies beschert uns mehr als 42 verifizierte Bewertungen auf Clutch von Kunden aus der ganzen Welt`,
    image: 'img/boldare-awards/clutch.png',
    link: 'https://clutch.co/profile/boldare',
    linkText: 'Lesen Sie die Bewertungen von unseren Kunden',
    rate: true,
  },
  {
    description: `Europe’s Fastest-Growing Company 2018 für XSolve (eines der Gründungsunternehmen von Boldare)`,
    image: 'img/boldare-awards/500inc.png',
    rate: false,
  },
];
