import React, { Component } from 'react';
import Slider from 'react-slick';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';

class Work extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'prototyping__slider',
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 0,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
        adaptiveHeight: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 992 });
  };

  renderTiles = () =>
    data.map(item => (
      <div className={'prototyping__work-item'} key={item.title}>
        <img
          src={getCloudinaryUrl(item.clientLogo, 'f_auto,q_auto,w_120')}
          alt={item.title}
          className={'image prototyping__work-item-logo'}
          style={{ objectFit: 'contain', objectPosition: 'left top' }}
          loading={'lazy'}
        />
        <SectionHeader size={'medium'} headingType={3}>
          {item.title}
        </SectionHeader>
        <div className={'prototyping__work-item-description'}>
          {item.description}
        </div>
        <div className={'prototyping__work-item-cover'}>
          <img
            src={getCloudinaryUrl(item.coverImage, 'f_auto,q_auto,w_520')}
            className={'image'}
            alt={item.title}
            style={{
              objectFit: 'contain',
              objectPosition: 'right center',
            }}
            loading={'lazy'}
          />
        </div>
      </div>
    ));

  render() {
    return (
      <div className={'prototyping__work'}>
        <Section
          color={'gray-light'}
          additionalClass={'prototyping__work-header'}
        >
          <SectionHeader
            size={'big'}
            color={'black'}
            headingType={2}
            lineColor={'cornflower'}
          >
            Our work
          </SectionHeader>
        </Section>
        <Section fullwidth additionalClass={'prototyping__work-content'}>
          <div className={'w-container'}>
            {this.state.isMobile ? (
              <Slider
                ref={slider => {
                  this.slider = slider;
                }}
                {...this.state.sliderSettings}
              >
                {this.renderTiles(data)}
              </Slider>
            ) : (
              <div className={'prototyping__work-items'}>
                {this.renderTiles(data)}
              </div>
            )}
          </div>
        </Section>
      </div>
    );
  }
}

export default Work;
