import React , { useState } from 'react';
import PropTypes from 'prop-types';

import { Paragraph, Section } from 'components';
import Quiz from './subcomponents/Quiz';
import { quizQuestions } from '../../logic/quizQuestions';

import './Calculator.scss';

const Calculator = ({ setResult, setNextState, setFinalAnswers }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [answers, setAnswers] = useState({});

  const setUserAnswer = newAnswer => {
    setAnswers({
      ...answers,
      [questionIndex]: newAnswer,
    });
    setAnswer(newAnswer);
  };

  const setNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    setAnswer('');
  };

  const setPreviousQuestion = () => {
    setQuestionIndex(questionIndex - 1);
    setAnswer(answers[questionIndex - 1] || '');
    setAnswer('');
  };

  const getResults = () => {
    let score = {};
    Object.values(answers).forEach(result => {
      score = { ...score, [result]: (score[result] || 0) + 1 };
    });

    const answersKeys = Object.keys(score);
    const answersCountValues = answersKeys.map(key => score[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    setFinalAnswers(answers);

    return answersKeys.filter(key => score[key] === maxAnswerCount);
  };

  const setResults = res => {
    setResult(res[0].toLowerCase());
  };

  const handleAnswerSelected = event => {
    setUserAnswer(event.currentTarget.value);
  };

  React.useEffect(() => {
    if (Object.keys(answers).length === 0) return;

    if (questionIndex + 1 < quizQuestions.length) {
      setTimeout(() => setNextQuestion(), 200);
    } else {
      setTimeout(() => {
        setResults(getResults());
        setNextState();
      }, 1000);
    }
  }, [answers]);

  return (
    <Section color={'mine-shaft'}>
      <div className={'app-cost-calc text--white'}>
        <div className={'app-cost-calc__content'}>
          <div className={'app-cost-calc__heading'}>
            <Paragraph color={'white'} size={'default'}>
              App Cost Calculator
            </Paragraph>
          </div>

          <Quiz
            answer={answer}
            answerOptions={quizQuestions[questionIndex].answers}
            questionIndex={questionIndex}
            question={quizQuestions[questionIndex].question}
            questionHelperText={
              quizQuestions[questionIndex].questionHelperText
            }
            questionTotal={quizQuestions.length}
            onAnswerSelected={handleAnswerSelected}
          />
          {questionIndex === 0 ? null : (
            <button
              className={"app-cost-calc__button--previous"}
              onClick={setPreviousQuestion}
            >
              Previous question
            </button>
          )}
        </div>
      </div>
    </Section>
  );
};

Calculator.propTypes = {
  setNextState: PropTypes.func,
  setResult: PropTypes.func,
  setFinalAnswers: PropTypes.func,
};

Calculator.defaultProps = {
  setNextState: null,
  setResult: null,
  setFinalAnswers: null,
};

export default Calculator;
