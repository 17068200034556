export const languages = {
  en: 'en',
  de: 'de',
  nl: 'nl',
};

export const topics = [
  { filterName: 'Hiring a Team', icon: 'contact-dev' },
  { filterName: 'Team Augmentation', icon: 'contact-outsourcing' },
  {
    filterName: 'Building or Consulting a Product',
    icon: 'contact-ml',
  },
  {
    filterName: 'AI Product Development',
    icon: 'contact-transformation',
  },
  { filterName: 'Something else', icon: 'contact-other' },
];
