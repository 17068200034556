import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Paragraph, SectionHeader } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import './Statistics.scss';

const Statistics = ({ data, mainColor, additionalClass }) => {
  const { caption, counter, image, alt } = data;

  return (
    <Section
      spaceless
      fullwidth
      additionalClass={classNames('cs-statistics', additionalClass)}
      side={'left'}
    >
      <Row className={'no-gap'}>
        <Col sm={12} md={6}>
          <div className={'cs-statistics__image'}>
            <img
              src={getCloudinaryUrl(image, 'f_auto,q_auto')}
              alt={alt}
              className={'image'}
              loading={'lazy'}
            />
          </div>
        </Col>
        <Col sm={12} md={6} className={'cs-statistics__container'}>
          <div className={'section w-container--right cs-statistics__inner'}>
            <SectionHeader
              color={'gray-medium'}
              lineColor={'gray-medium'}
              size={'small'}
            >
              Real impact
            </SectionHeader>
            <div className={'cs-statistics__content'}>
              <span
                className={classNames('cs-statistics__number', {
                  [`cs-statistics__number--${mainColor}`]: mainColor,
                })}
              >
                {counter}
              </span>
              <Paragraph
                color={'white'}
                size={'medium'}
                additionalClass={'text--default cs-statistics__caption'}
              >
                {caption}
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default Statistics;
