export const assetType = ['E-books', 'Webinars', 'Our apps', 'Open sources'];

export const data = [
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'when-to-invest-in-ux-webinar',
    image: 'discussion-event/ux_strategy_webinar_black_without_logo.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `When to Invest in UX Strategy for Your Company`,
    tags: [
      'The role of User Experience Strategy as a crucial asset for C-level executives in business.',
    ],
    link: 'https://www.boldare.com/when-to-invest-in-ux-webinar/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'how-to-implement-and-monetize-AI',
    image: 'resources/AI_examples_e-book.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: 'How to implement and monetize AI in your industry.',
    tags: ['AI', 'monetize', 'strategy'],
    link: '/resources/how-to-implement-and-monetize-AI/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-strategy-workbook',
    image: 'resources/Cover_How_to_create_product_strategy.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: 'Product Strategy Workbook',
    tags: ['product', 'strategy'],
    link: '/resources/product-strategy-workbook/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-discovery-handbook',
    image: 'resources/Handbook_Discover_Your_Best_Digital_Product_Idea.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: 'Product Discovery Handbook',
    tags: ['product,', 'discovery workshops,', 'strategy'],
    link: '/resources/product-discovery-handbook/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'practical-ai-application',
    image: 'resources/xz8it7smgkoimme9mf13.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `Practical application of AI in materials trading industry`,
    tags: [
      'Gain straightforward, actionable insights on AI and learn how to apply it to your benefit',
    ],
    link: 'https://www.boldare.com/practical-ai-application/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-discovery-workshop',
    image: 'resources/product-discovery-workshops-cover.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: `Product Discovery Workshop - the key to your product’s success`,
    tags: ['product,', 'workshop,', 'discovery workshops'],
    link: '/resources/product-discovery-workshop/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-development-challenges',
    image: 'resources/six-product-challenges-cover.png',
    imageBg: 'soft-cloud',
    imagePos: 'bottom',
    title: `6 Most Common Product Development Challenges`,
    tags: ['product,', 'strategy,', 'challenges'],
    link: '/resources/product-development-challenges/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-development-team-work',
    image: 'resources/product-development-team-cover.png',
    imageBg: 'soft-cloud',
    imagePos: 'bottom',
    title: `Working with a product development team`,
    tags: ['teamwork,', 'management,', 'communication'],
    link: '/resources/product-development-team-work/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-owner-checklist',
    image: 'resources/cover-24-tips-remote.png',
    imageBg: 'violet',
    imagePos: 'bottom',
    title: `Mastering remote collaboration`,
    tags: ['tools,', 'best practice,', 'useful methodologies'],
    link: '/resources/product-owner-checklist/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'machine-learning-for-business',
    image: 'resources/cover-ml.png',
    imageBg: 'soft-cloud',
    imagePos: 'bottom',
    title: `Machine Learning for Business`,
    tags: ['costs,', 'limitations,', 'real-life cases'],
    link: '/resources/machine-learning-for-business/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'web-mobile-apps-prototyping',
    image: 'resources/cover-prototyping.png',
    imageBg: 'red',
    imagePos: 'bottom',
    title: `Apps prototyping`,
    tags: ['technologies,', 'tools,', 'design thinking'],
    link: '/resources/web-mobile-apps-prototyping/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'development-partner-checklist',
    image: 'resources/cover-33-things-checklist.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `Must-ask questions for development companies`,
    tags: ['development costs,', 'methodologies,', 'risks'],
    link: '/resources/development-partner-checklist/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'ultimate-scrum-master-toolkit',
    image: 'resources/cover-sm-toolkit.png',
    imageBg: 'violet',
    imagePos: 'bottom',
    title: `Scrum Master Toolkit`,
    tags: ['discovery workshops,', 'event storming,', 'frameworks'],
    link: '/resources/ultimate-scrum-master-toolkit/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'development-partner-guide',
    image: 'resources/cover-ydp.png',
    imageBg: 'yellow',
    imagePos: 'bottom',
    title: `Choosing a development partner`,
    tags: ['costs,', 'mistakes to avoid,', 'questions to ask'],
    link: '/resources/development-partner-guide/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'crash-course',
    image: 'resources/cover-crash-course.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `Software development risks`,
    tags: ['risk management tactics'],
    link: '/resources/crash-course/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'finding-pmf',
    image: 'event/finding-pmf-cover.png',
    imageBg: 'cinderella',
    imagePos: 'bottom',
    title: `Finding PMF meetup`,
    tags: [
      `what product-market fit is and why achieving it is key to building a product's position in the market`,
    ],
    link: 'https://go.boldare.com/register_to_event_website_resources_',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'product-designer-checklist',
    image: 'resources/cover-36-things-checklist.png',
    imageBg: 'red',
    imagePos: 'bottom',
    title: `Must-ask questions for product design companies`,
    tags: ['tools,', 'workflows,', 'methodologies'],
    link: '/resources/product-designer-checklist/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'accelerate-digitization-in-your-company',
    image: 'resources/cover-webinar-sonnen.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: `Accelerating digital transformation`,
    tags: ['CTO’s point of view,', 'real-life examples'],
    link: '/webinar/accelerate-digitization-in-your-company/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'what-after-mvp-checklist',
    image: 'resources/cover-after-mvp.png',
    imageBg: 'soft-cloud',
    imagePos: 'bottom',
    title: `What next after an MVP?`,
    tags: ['product-market fit phase,', 'best practice'],
    link: '/resources/what-after-mvp-checklist/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'code-audit-checklist',
    image: 'resources/cover-23-things-ux-audit.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `Must-ask questions for code audit companies`,
    tags: ['methodologies,', 'tools'],
    link: '/resources/code-audit-checklist/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'code-audit',
    image: 'resources/cover-pre-recorded-webinar.png',
    imageBg: 'red',
    imagePos: 'bottom',
    title: `What next after a code audit?`,
    tags: ['sustainability,', 'tools,', 'backlog prioritization'],
    link: '/webinar/code-audit/',
  },
  {
    assetType: 'Webinars',
    badge: 'webinar',
    eventKey: 'agile-for-your-company',
    image: 'resources/cover-agile-webinar.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: `Agile for your company. How to implement successfully`,
    tags: ['accelerating software development delivery,', 'agile'],
    link: '/webinar/agile-for-your-company/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'remote-work-pdf',
    image: 'resources/cover-remote-work.png',
    imageBg: 'pigeon-post',
    imagePos: 'bottom',
    title: `Remote efficiency`,
    tags: ['team rituals,', 'reporting,', 'efficient communication'],
    link: '/resources/remote-work-pdf/',
  },
  {
    assetType: 'E-books',
    badge: 'e-book',
    eventKey: 'new-normal-checklist',
    image: 'resources/cover-new-normal-checklist.png',
    imageBg: 'pigeon-red',
    imagePos: 'bottom',
    title: `New Normal checklist for CEOs`,
    tags: ['processes,', 'tools &', 'New Normal leadership'],
    link: '/resources/new-normal-checklist/',
  },
  {
    assetType: 'Our apps',
    badge: 'free app',
    eventKey: 'boldare-boards',
    image: 'resources/cover-boldare-boards.png',
    imageBg: 'violet',
    imagePos: 'bottom',
    title: `Boldare Boards`,
    description: 'The perfect online app for all kinds of remote teams.',
    link: 'https://boards.boldare.com/',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-openai-assistant',
    image: 'resources/cover-default.png',
    imageBg: 'gray',
    imagePos: 'center',
    title: `OpenAI Assistant API`,
    description: `A NestJS library for building efficient, scalable, and quick solutions based on the OpenAI Assistant API.`,
    link: 'https://github.com/boldare/openai-assistant',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-marble',
    image: 'resources/cover-default.png',
    imageBg: 'gray',
    imagePos: 'center',
    title: `Marble.js`,
    description: `Marble.js is a functional reactive Node.js framework for building server-side applications, created by Józef Flakus, Boldare's JavaScript developer`,
    link: 'https://github.com/marblejs/marble/',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-terraform',
    image: 'resources/cover-default.png',
    imageBg: 'gray',
    imagePos: 'center',
    title: `Terraform modules`,
    description: `This is a collection of our Terraform modules that allows us to improve and facilitate the creation of infrastructures in Terraform.`,
    link: 'https://github.com/boldare/terraform-modules/',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-maska-polka',
    image: 'resources/cover-maska-polka.png',
    imageBg: 'red',
    imagePos: 'center',
    title: `Maska Polka`,
    description: `A website we created to support small business owners who decided to sew masks during the COVID-19 outbreak pro bono publico.`,
    link: 'https://github.com/boldare/maskapolka/',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-swift-api',
    image: 'resources/cover-default.png',
    imageBg: 'gray',
    imagePos: 'center',
    title: `Swift API`,
    description: `Swift API is a very light, fast, easy to update and ready to use “out of the box” CocoaPod library.`,
    link: 'https://github.com/boldare/swift-api/',
  },
  {
    assetType: 'Open sources',
    badge: 'open source',
    eventKey: 'os-tech-boldare',
    image: 'resources/cover-tech-boldare.png',
    imageBg: 'gray',
    imagePos: 'center',
    title: `Tech.Boldare`,
    description: `Development tips & tricks, made by devs for devs.`,
    link: 'https://www.boldare.com/tech-blog/',
  },
  {
    assetType: 'Our apps',
    badge: 'free app',
    eventKey: 'app-costs-calculator',
    image: 'resources/cover-app-costs-calculator.png',
    imageBg: 'mine-shaft',
    imagePos: 'bottom',
    title: `App Costs Calculator`,
    description:
      'Do you want to build a new app or improve an existing one? Check out our app development cost calculator for a proposed budget range and timeframe.',
    link: '/resources/app-costs-calculator/',
  },
];
