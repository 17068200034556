import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Heading,
  Paragraph,
  Section,
  SectionHeader,
  Link,
  Button,
  YouTubePlayer,
  ResourceList,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';

import './WebinarContent.scss';

const SpyLink = Scroll.Link;

class WebinarContent extends Component {
  renderArticle = data => {
    return (
      <div className={'webinar-page__content-related-boxes'}>
        {data.relatedArticle.map(article => (
          <Box
            id={article.id}
            backgroundColor={'white'}
            additionalClass={'webinar-articles__list-box'}
          >
            <div
              className={
                'webinar-articles__list-box-entry webinar-articles__list-box-entry--article'
              }
            >
              <div className={'webinar-articles__list-box-entry__image'}>
                <img
                  src={getCloudinaryUrl(article.photo, 'f_auto,q_auto')}
                  alt={article.serviceTitle}
                  className={'image webinar-articles__list-box-entry__cover'}
                  loading={'lazy'}
                />
              </div>
              <div className={'webinar-articles__list-box-entry__text'}>
                <a href={article.link}>
                  <Heading
                    additionalClass={'webinar-articles__list-box-entry__title'}
                    type={3}
                    size={'medium-plus-small'}
                    color={'mine-shaft'}
                  >
                    {article.title}
                  </Heading>
                </a>
                <Paragraph
                  additionalClass={'webinar-articles__list-box-entry__lead'}
                >
                  {article.lead}
                </Paragraph>
              </div>
              <div className={'webinar-articles__list-box-entry__badge'}>
                <Paragraph
                  size={'small'}
                  color={'black'}
                  additionalClass={'box-case-study__category'}
                >
                  <span
                    className={'box__badge box__badge--article text--bold'}
                  >
                    {article.type}
                  </span>
                </Paragraph>
              </div>
            </div>
          </Box>
        ))}

        {data.dedicated && (
          <div className={'webinar-page__ded webinar-articles__list-box'}>
            <div>
              <Heading type={3} size={'medium-plus'}>
                {data.dedicated.dedicatedTitle}
              </Heading>
              <Paragraph size={'medium'}>
                {ReactHtmlParser(data.dedicated.dedicatedDescription)}
              </Paragraph>
            </div>
            <Link to={data.dedicated.dedicatedUrl}>
              <Button color={'white'}>{data.dedicated.dedicatedButton}</Button>
            </Link>
          </div>
        )}
      </div>
    );
  };

  renderDedicatedMaterial = data => {
    return (
      <>
        {data.dedicated.map(dedicatedContent => (
          <div className={'webinar-res__ded'}>
            <Heading type={3} size={'medium-plus'}>
              {dedicatedContent.dedicatedTitle}
            </Heading>
            <Paragraph size={'medium'}>
              {ReactHtmlParser(dedicatedContent.dedicatedDescription)}
            </Paragraph>
            <Link to={dedicatedContent.dedicatedUrl}>
              <Button color={'white'}>
                {dedicatedContent.dedicatedButton}
              </Button>
            </Link>
          </div>
        ))}
      </>
    );
  };

  renderAskSpeakers = data => {
    return (
      <div className={'webinar-page__content-ask'}>
        <Heading type={3} size={'medium-plus'}>
          Ask speakers!
        </Heading>
        <Paragraph size={'medium'}>
          If you want to learn more about agile implementation and its
          benefits, ask our speakers! Email them or connect on LinkedIn to
          chat.{' '}
        </Paragraph>
        <ul className={'ask__list'}>
          {data.askSpeakers.map(speaker => (
            <li className={'ask__item'}>
              <div className={'ask__item-img'}>
                <img
                  src={getCloudinaryUrl(speaker.avatar, 'f_auto,q_auto')}
                  alt={speaker.name}
                  loading={'lazy'}
                />
              </div>
              <h4 className={'ask__item-name text--bold'}>{speaker.name}</h4>
              <div className={'ask__item-content'}>
                <a href={`mailto:${speaker.email}`}>Email</a> |{' '}
                <a
                  href={speaker.linkedin}
                  rel={'noreferrer nofollow'}
                  target={'_blank'}
                >
                  LinkedIn
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  renderSpeakerList = data => {
    return (
      <ul className={'speakers-component__speaker-list'}>
        {data.speakers.map(speaker => (
          <li className={'speakers-component__speaker-list-item'}>
            <div className={'speakers-component__speaker-list-img'}>
              <img
                className={'image'}
                alt={speaker.name}
                src={getCloudinaryUrl(speaker.avatar, 'f_auto,q_auto')}
                loading={'lazy'}
              />
            </div>
            <div className={'speakers-component__speaker-list-content'}>
              <span className={'speakers-component__speaker-list-name'}>
                {speaker.name}
              </span>
              <span className={'speakers-component__speaker-list-position'}>
                {speaker.position}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { data, coverAlt, itemsQuestions } = this.props;

    return (
      <>
        <Section
          id={`section-${data.id}`}
          additionalClass={'webinar-page__content'}
          color={data.color}
        >
          {!data.isSingle && (
            <>
              <span className={'webinar-page__content-badge'}>
                {data.id}/4
              </span>
              <SectionHeader
                color={'mine-shaft'}
                description={data.description}
                descriptionColor={'mine-shaft'}
                fixed
                noLine
                additionalClass={'section-header__description--medium'}
              >
                {data.title}
              </SectionHeader>
            </>
          )}
          <YouTubePlayer src={data.videoUrl} light />
          {!data.isSingle ? (
            <div className={'webinar-page__content-wrapper'}>
              <div className={'webinar-page__content-details'}>
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--bold content-title'}
                >
                  {data.pointsTitle}
                </Paragraph>
                <ResourceList
                  items={data.points}
                  {...{ itemsQuestions, coverAlt }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={'webinar-res__content-wrapper'}>
                <div className={'webinar-res__content-details'}>
                  {data.isSpeakers && (
                    <div
                      className={
                        'speakers-component__content-speakers speakers-component__content-speakers--first'
                      }
                    >
                      <Paragraph
                        size={'medium'}
                        additionalClass={'text--bold content-title'}
                      >
                        Speakers:
                      </Paragraph>
                      {this.renderSpeakerList(data)}
                    </div>
                  )}
                  <Paragraph
                    size={'medium'}
                    additionalClass={'text--regular content-title'}
                  >
                    {ReactHtmlParser(data.pointsDescription)}
                  </Paragraph>
                  <Paragraph
                    size={'medium'}
                    additionalClass={'text--bold content-title'}
                  >
                    {data.pointsTitle}
                  </Paragraph>
                  <ResourceList
                    items={data.points}
                    {...{ itemsQuestions, coverAlt }}
                  />
                </div>
                {(data.isDedicatedMaterial ||
                  data.isArticle ||
                  data.isAskSpeakers) && (
                  <div className={'webinar-res__content-sidebar'}>
                    {!data.isAskSpeakers && (
                      <Paragraph
                        size={'medium'}
                        additionalClass={'text--bold content-title'}
                      >
                        Related materials:
                      </Paragraph>
                    )}
                    <div className={'webinar-res__content-sidebar-boxes'}>
                      {data.isArticle && (
                        <div className={'webinar-res__content-sidebar-box'}>
                          {this.renderArticle(data)}
                        </div>
                      )}
                      {data.isDedicatedMaterial && (
                        <div className={'webinar-res__content-sidebar-box'}>
                          {this.renderDedicatedMaterial(data)}
                        </div>
                      )}
                      {data.isAskSpeakers && (
                        <div className={'webinar-res__content-sidebar-box'}>
                          {this.renderAskSpeakers(data)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Section>
        {data.isRelated && (
          <Section
            color={'white'}
            additionalClass={'webinar-page__content-related'}
          >
            <Paragraph
              size={'medium'}
              additionalClass={'text--bold content-title'}
            >
              Related materials:
            </Paragraph>
            {this.renderArticle(data)}
          </Section>
        )}
        {data.isAnchor && (
          <Section
            additionalClass={'webinar-page__content-anchor'}
            color={'yellow'}
          >
            <SectionHeader
              size={'medium'}
              additionalClass={'text--bold content-anchor__title'}
              description={`You are in good hands!`}
              descriptionColor={'mine-shaft'}
            >
              Do you need help with a code audit?
            </SectionHeader>
            <SpyLink
              id={'webinar-page-video-link'}
              to={'code-audit-contact-form'}
              spy={false}
              smooth
              duration={500}
              className={'link'}
            >
              <Button
                id={'webinar-page-watch-button'}
                color={'yellow'}
                ariaName={'Watch webinar button'}
              >
                Contact Us
              </Button>
            </SpyLink>
            <div className={'content-anchor__shape'} />
          </Section>
        )}
      </>
    );
  }
}

export default WebinarContent;
