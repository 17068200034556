import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section, SectionHeader, Paragraph, IconSVG } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Slider from 'react-slick';
import doubleQuotationMark from 'assets/images/products/double-quotation-mark.svg';
import { sliderData } from './logic';
import './Testimonials.scss';

class Testimonials extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'digtra-de-testimonials__slider',
        dotsClass:
          'slick-dots slick-pagination digtra-de-testimonials__slider-dots',
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 500,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  renderSlides = () => {
    return sliderData.map(item => (
      <div
        className={'digtra-de-testimonials__slides'}
        key={`culture-slide-${item.id}`}
      >
        <div className={'digtra-de-testimonials__slides-wrapper'}>
          <div className={'digtra-de-testimonials__slides-item'}>
            <SectionHeader
              size={'medium'}
              color={'mine-shaft'}
              headingType={3}
              noLine
            >
              {item.content}
            </SectionHeader>
            <div className={'digtra-de-testimonials__slides-stars'}>
              <IconSVG
                name={'star-rate'}
                size={'big'}
                additionalClass={'star-rate'}
              />
              <IconSVG
                name={'star-rate'}
                size={'big'}
                additionalClass={'star-rate'}
              />
              <IconSVG
                name={'star-rate'}
                size={'big'}
                additionalClass={'star-rate'}
              />
              <IconSVG
                name={'star-rate'}
                size={'big'}
                additionalClass={'star-rate'}
              />
              <IconSVG
                name={'star-rate'}
                size={'big'}
                additionalClass={'star-rate'}
              />
            </div>
          </div>
          <div className={'digtra-de-testimonials__slides-person'}>
            <img
              src={getCloudinaryUrl(`${item.avatar}`, 'f_auto,q_auto')}
              className={'digtra-de-testimonials__slides-img'}
              alt={item.name}
            />
            <div className={'digtra-de-testimonials__slides-person-wrap'}>
              <Paragraph
                size={'medium'}
                color={'mine-shaft'}
                additionalClass={'digtra-de-testimonials__slides-name'}
                spaced
              >
                {item.name}
              </Paragraph>
              <Paragraph
                size={'medium'}
                color={'mine-shaft'}
                additionalClass={'digtra-de-testimonials__slides-title'}
                spaced
              >
                {item.title}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const { sliderSettings } = this.state;

    return (
      <Section
        id={'digtra-de-testimonials'}
        color={'white'}
        additionalClass={'digtra-de-testimonials__section'}
      >
        <SectionHeader size={'large'} headingType={2} noLine>
          Referenzen von unseren Kunden
        </SectionHeader>
        <div className={'digtra-de-testimonials--background'} />
        <img
          src={doubleQuotationMark}
          alt={'Double quotation mark'}
          className={'digtra-de-testimonials--background-img'}
        />
        <Slider
          ref={slider => {
            this.slider = slider;
          }}
          {...sliderSettings}
        >
          {this.renderSlides()}
        </Slider>
      </Section>
    );
  }
}

Testimonials.defaultProps = {
  data: [],
};

Testimonials.propTypes = {
  data: PropTypes.array,
};

export default Testimonials;
