import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Banner,
  ReasonsWhy,
  Offer,
  Work,
  Benefits,
  Subscribe,
  LearnMore,
  OtherServices,
} from './subcomponents';
import './Prototyping.scss';

class Prototyping extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const relatedLearn = [
      'Digital Product Prototyping – what’s it all about?',
      'Digital Product Prototyping – it’s a team effort',
      'What are design sprints?',
    ].map(title => ({ title }));

    return (
      <div className={'single-page single-page--hero prototyping'}>
        <Banner />
        <ReasonsWhy />
        <Offer />
        <Work />
        <Benefits />
        <Subscribe />
        <LearnMore
          allArticles={allArticles}
          related={relatedLearn}
          heading={'Learn more about prototyping digital products'}
          color={'white'}
          description={
            'Digital prototyping is a rapid but complex design process. Explore how we approach this product development stage at Boldare.'
          }
        />
        <ContactFormServices
          id={'prototyping-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2020-11-03/dhd3x'}
        />
        <OtherServices />
      </div>
    );
  }
}

export default Prototyping;
