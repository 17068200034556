const opinions = [
  {
    index: 0,
    text: {
      firstText: 'We really like that we have a',
      underlineText: 'partner',
      secondText: 'who deeply understands the challenge',
    },
    url: '/work/case-study-sonnen-digital-transformation/',
    client: {
      name: 'Norbert',
      surname: 'Baumann',
      company: 'Technical Innovation Lead, Sonnen, Germany',
      photo: 'clients-photos/norbert-baumann.png',
    },
  },

  {
    index: 1,
    text: {
      firstText: 'They don’t just build a product; their',
      underlineText: 'team',
      secondText: 'adds value throughout the whole process',
    },
    client: {
      name: 'Randy',
      surname: 'Carpenter',
      company: 'CEO at Interstate Data, United States',
      photo: 'clients-photos/randy-carpenter.png',
    },
  },

  {
    index: 2,
    text: {
      firstText: 'The speed with which they understood the',
      underlineText: 'challenge',
      secondText: 'was impressive',
    },
    client: {
      name: 'Dave',
      surname: 'Cook',
      company: 'Digital Lead Practitioner, Xinfu, United Kingdom',
      photo: 'clients-photos/dave-cook.png',
    },
  },

  {
    index: 3,
    text: {
      firstText: 'Their team became all-in and',
      underlineText: 'proactive',
      secondText: 'when working with us, which is not what many companies do',
    },
    client: {
      name: 'Noa',
      surname: 'Segol',
      company: 'Marketing Director, PractiTest, Israel',
      photo: 'clients-photos/noa-segol.png',
    },
  },
  {
    index: 4,
    text: {
      firstText:
        'A dedicated team, they provided valuable insights to encourage product improvements',
      underlineText: 'beyond',
      secondText: 'their scope of work',
    },
    client: {
      name: 'Giorgia',
      surname: 'Somma',
      company: 'Business Development Manager, Blu5 Labs Ltd, Malta',
      photo: 'clients-photos/giorgia-somma.png',
    },
  },
];

export { opinions };
