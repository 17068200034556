import React from 'react';
import PropTypes from 'prop-types';

import { Button, YouTubePlayer } from 'components';

import './Video.scss';

const Video = ({ videoUrl }) => {
  const [videoVisible, setVideoVisible] = React.useState(false);
  const showVideo = () => {
    setVideoVisible(true);
  };

  return (
    <div
      className={`w-container career-video ${
        videoVisible ? '' : 'career-video--autoplay'
      }`}
    >
      <YouTubePlayer
        title={"Why join Boldare? Cause it's awesome to work with us!"}
        src={videoUrl}
        overlayed={!videoVisible}
        playing
        controls
        loop
        volume={videoVisible ? 1 : 0}
      />
      {!videoVisible && (
        <Button
          additionalClass={'career-video__button'}
          color={'white'}
          transparent
          onClick={showVideo}
        >
          <span className={'career-video__play'} />
        </Button>
      )}
    </div>
  );
};

Video.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

export default Video;
