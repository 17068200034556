import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button, Paragraph } from 'components';

const SpyLink = Scroll.Link;

const Banner = ({ cta, link, id }) => (
  <SubpageBanner
    backgroundColor={'mine-shaft'}
    image={getCloudinaryUrl(
      'gatsby-cloudinary/Meeting-in-Boldare-office-room.jpg',
      'f_auto,q_auto,w_1000',
    )}
    contentBackground={'spring-wood'}
    additionalClass={'dtu__banner'}
    alt={'Development team'}
  >
    <SectionHeader
      size={'extra-large'}
      color={'mine-shaft'}
      lineColor={'red'}
      description={
        'Boldare’s digital product development teams will help you conceptualize your idea, design and build the first version of your product, find a product-market fit, and develop a scalable web product.<br /><br />Our agile development team will take you through the full cycle of product development using a lean startup approach. Thanks to the lean methodology our teams can develop a product within as little as 4 weeks so you can enter the market quickly and start testing with real users.'
      }
      descriptionColor={'black'}
      buttonColor={'black'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Development <br />
      Teams
    </SectionHeader>
    <div className={'contact-box'}>
      <Paragraph size={'medium'} additionalClass={'contact-box__paragraph'}>
        Do you need development team? <br />
        Let’s find out how we can help.
      </Paragraph>

      <SpyLink
        id={id}
        to={link}
        spy={false}
        smooth
        duration={1000}
        className={'link link--centered'}
      >
        <Button
          id={'dtu-banner-btn'}
          color={'transparent'}
          ariaName={'Contact button'}
          additionalClass={'contact-button'}
        >
          {cta}
        </Button>
      </SpyLink>
    </div>
  </SubpageBanner>
);

export default Banner;
