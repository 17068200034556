import React from 'react';
import PropTypes from 'prop-types';

import { getCloudinaryUrl } from 'config/helpers';
import { Button, DottedList, Image, SectionHeader } from 'components';

import './Banner.scss';

const Banner = ({ setNextState }) => (
  <div className={'app-cost-banner w-container'}>
    <div className={'app-cost-banner__text-content'}>
      <h1 className={'app-cost-banner__header'}>
        App <span className={'app-cost-marker-highlight-short'}>cost</span>{' '}
        calculator
      </h1>
      <div className={'app-cost-banner__list'}>
        <DottedList
          heading={
            'Answer five short questions and get a free estimate right away:'
          }
          itemsList={[
            'A rough estimation of your product',
            'The number of specialists on a team',
            'How long it will take to launch the product',
          ]}
          theme={'light'}
        />
      </div>
      <Button
        id={'app-cost-button'}
        color={'yellow'}
        onClick={setNextState}
        additionalClass={'app-cost-banner__button'}
      >
        Estimate your app costs
      </Button>
    </div>
    <div className={'app-cost-banner__img'}>
      <Image
        src={getCloudinaryUrl(
          '/app-costs-calculator/phases-graph.png',
          'f_auto,q_auto,w_800',
        )}
        className={'image box-image-content__image'}
        alt={'App development cost estimator'}
      />
    </div>
    <div className={'app-cost-banner__content-section'}>
      <div className={'section'}>
        <SectionHeader
          color={'white'}
          lineColor={'yellow'}
          size={'medium'}
          headingType={2}
        >
          What Is an App Cost Calculator?
        </SectionHeader>
        <p>
          Our cost calculator is a brief survey that collects essential
          information about your business needs and proposes a budget,
          timeline, and team size required to develp such an app.
        </p>
      </div>
      <div className={'section'}>
        <SectionHeader
          color={'white'}
          lineColor={'red'}
          size={'medium'}
          headingType={2}
        >
          How does app cost estimator work?
        </SectionHeader>
        <p>
          Our cost calculator uses over 20 years of experience to translate
          your business needs into a specific product type, budget, and team
          size, rather than simply summing up the costs of various features.
          This ensures the app will align with your business goals.
        </p>
      </div>
    </div>
    <div className={'app-cost-banner__content-section'}>
      <div className={'section'}>
        <SectionHeader
          color={'white'}
          lineColor={'pigeon-post'}
          size={'medium'}
          headingType={2}
        >
          What makes this a good app cost calculator?
        </SectionHeader>
        <p>
          We believe it offers more value than similar tools by concentrating
          on understanding your situation and needs. It is technologically
          agnostic, aiming to find the best solution for you rather than
          confining the product to particular coding languages or frameworks.
        </p>
      </div>
      <div className={'section'}>
        <SectionHeader
          color={'white'}
          lineColor={'royal-blue'}
          size={'medium'}
          headingType={2}
        >
          How can the app cost calculator help you?
        </SectionHeader>
        <p>
          Our calculator helps define the potential budget and timeline, giving
          you a good idea of what it will take to build your product.
        </p>
      </div>
    </div>
  </div>
);

Banner.propTypes = {
  setNextState: PropTypes.func,
};

Banner.defaultProps = {
  setNextState: null,
};

export default Banner;
