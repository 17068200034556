import { Paragraph, Section, SectionHeader, Link, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './Questions.scss';

const Questions = () => {
  return (
    <Section
      fullWidth
      color={'violet-main'}
      additionalClass={'event-fpmf__questions'}
    >
      <Row className={'event-fpmf-amsterdam__max-width'}>
        <Col sm={12} md={7} className={'event-fpmf__how-description'}>
          <SectionHeader
            headingType={2}
            additionalClass={'event-fpmf__questions-heading'}
            color={'mine-shaft'}
            noLine
          >
            Got <span className={'pixel-font-text'}>questions</span>
            <img
              alt={''}
              className={'pixel-font'}
              src={getCloudinaryUrl('event/questions.svg', 'v1669337183')}
            />
            ? <br />
            Get in touch with Matt <br />
            for more details.
          </SectionHeader>
        </Col>
      </Row>
      <Row className={'event-fpmf-amsterdam__max-width'}>
        <Col sm={12} md={7} className={'event-fpmf__how-description'}>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'event-fpmf__questions-paragraph text--bold'}
          >
            Our host, Matt Hallman is happy to tell you more about&nbsp;the
            meetup or anything else.
          </Paragraph>
          <div className={'event-fpmf__questions-speaker'}>
            <img
              className={'image event-fpmf__questions-image'}
              alt={'Matt Hallmann'}
              src={getCloudinaryUrl(
                'event/matt_hallmann2.png',
                'f_auto,q_100,w_280,h_280',
              )}
              loading={'lazy'}
            />
            <div>
              <Paragraph color={'white'}>
                <b>Matt Hallmann</b>
                <br />
                Business Development
              </Paragraph>
              <Link to={'mailto:matt.hallmann@boldare.com'}>
                <Button color={'white'}>Contact with me</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col sm={12} md={5}>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'event-fpmf__questions-paragraph'}
          >
            For more than 10 years, Matt has been working for multiple
            international start-ups and scale-ups. His focus has been on the
            intersection of marketing, sales and operations to help
            organisations accelerate their growth.
            <br />
            <br />
            Matt has been actively using self organisation, Holacracy and GTD
            since 2014. This has enabled him to structure growth paths for
            others, to achieve innovative solutions.
          </Paragraph>
        </Col>
      </Row>
    </Section>
  );
};

export default Questions;
