import React, { Component } from 'react';
import Services from 'views/services';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

class ServicesPage extends Component {
  render() {
    const page = pages.services;
    const { navColors } = page;
    const url = `${config.siteUrl}/services/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} phoneNumber />
        <Services />
        <PopupRedirect />
      </Layout>
    );
  }
}

export default ServicesPage;
