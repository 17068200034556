import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Slide, Dots } from './subcomponents';
import {
  slideShape,
  SliderContext,
  optionsShape,
  defaultOptions,
} from './utils';

const Slider = ({ inView, slides, options }) => {
  const [index, setIndex] = useState(0);

  const mergedOptions = { ...defaultOptions, ...options };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inView && index < slides.length - 1) {
        setIndex(p => p + 1);
      } else {
        setIndex(0);
      }
    }, mergedOptions.slideTime);

    return () => clearTimeout(timeout);
  });

  return (
    <div>
      <SliderContext.Provider value={{ slides, options: mergedOptions }}>
        <Slide index={index} key={index} />
        <Dots index={index} setIndex={setIndex} key={inView} />
      </SliderContext.Provider>
    </div>
  );
};

Slider.defaultProps = {
  inView: 0,
  options: defaultOptions,
};

Slider.propTypes = {
  inView: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.shape(slideShape)).isRequired,
  options: PropTypes.shape(optionsShape),
};

export default Slider;
