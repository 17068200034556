export const languages = {
  en: 'en',
  de: 'de',
  nl: 'nl',
};

export const topics = [
  { filterName: 'Project outsourcing', icon: 'contact-outsourcing' },
  { filterName: 'Hiring dev team', icon: 'contact-dev' },
  { filterName: 'AI Services', icon: 'contact-ml' },
  { filterName: 'Digital transformation', icon: 'contact-transformation' },
  { filterName: 'Something else', icon: 'contact-other' },
];

export const topicsAwwwards = [
  { filterName: 'Outstanding design', icon: 'contact-design' },
  { filterName: 'UI/UX for my app', icon: 'contact-uiux' },
  { filterName: 'Project outsourcing', icon: 'contact-outsourcing' },
  { filterName: 'Marketing consulting', icon: 'contact-ml' },
  { filterName: 'Something else', icon: 'contact-other' },
];

export const topicsFcpd = [
  { filterName: 'Prototype', icon: 'prototype' },
  { filterName: 'MVP', icon: 'mvp' },
  { filterName: 'Product Market Fit', icon: 'fit' },
  { filterName: 'Scaling', icon: 'scale' },
  { filterName: 'Something else', icon: 'contact-other' },
];

export const topicsCodeAudit = [
  { filterName: 'Code audit', icon: 'contact-outsourcing' },
  { filterName: 'UX audit', icon: 'contact-dev' },
  { filterName: 'Custom audit', icon: 'contact-transformation' },
  { filterName: 'Something else', icon: 'contact-other' },
];

export const topicsNewNormal = [
  { filterName: 'Leadership and organization', icon: 'contact-outsourcing' },
  { filterName: 'Agility and remote work', icon: 'contact-dev' },
  { filterName: 'Teams and culture', icon: 'contact-transformation' },
  { filterName: 'Something else', icon: 'contact-other' },
];
