import React from 'react';
import PropTypes from 'prop-types';
import { Section, BackgroundImage, Heading } from 'components';
import './OldCaseStudyBanner.scss';

const OldCaseStudyBanner = ({
  additionalClass,
  image,
  color,
  subtitle,
  title,
}) => (
  <div className={'old-case-study-banner'}>
    <BackgroundImage
      additionalClass={'old-case-study-banner__image'}
      src={image}
    />
    <Section
      additionalClass={`old-case-study-banner__title ${additionalClass}`}
    >
      <div className={'old-case-study-banner__content'}>
        {title && (
          <Heading type={1} color={color} size={'big-spreaded'}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Heading type={2} color={color} afterline size={'smallest'}>
            {subtitle}
          </Heading>
        )}
      </div>
    </Section>
  </div>
);

OldCaseStudyBanner.defaultProps = {
  additionalClass: '',
  color: 'mine-shaft',
  image: null,
  subtitle: null,
  title: null,
};

OldCaseStudyBanner.propTypes = {
  additionalClass: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default OldCaseStudyBanner;
