import React from 'react';
import { Button, Paragraph, Heading, Image } from 'components';
import './style.scss';
import { getCloudinaryUrl } from 'config/helpers';
import PropTypes from 'prop-types';
import { consultationBannerData } from './logic';

const ConsultationBanner = ({ id }) => {
  const bannerData = consultationBannerData[id];

  const bannerStyle = {
    backgroundImage: `url(${bannerData.backgroundImage})`,
  };

  if (!bannerData) {
    return <div>Error: Banner data not found for ID {id}</div>;
  }
  return (
    <div
      className={'consultation-banner__container'}
      style={bannerStyle}
      id={id}
    >
      <div className={'consultation-banner__corner-brain'}>
        <img
          src={getCloudinaryUrl(
            bannerData.rightCornerGraphic,
            'f_auto,q_auto,w_auto,h_210',
          )}
          alt={'right corner graphic'}
        />
      </div>
      <div className={'consultation-banner'}>
        {id === 'consultation-banner-business' && (
          <Paragraph
            color={'white'}
            additionalClass={'consultation-banner__subheading'}
          >
            {bannerData.addtionalTitle}
          </Paragraph>
        )}
        <Heading color={bannerData.titleColor} size={'big'}>
          {bannerData.title}{' '}
        </Heading>
        <Paragraph
          color={bannerData.textColor}
          size={'medium-constant'}
          additionalClass={'consultation-banner__text'}
        >
          {bannerData.text}
        </Paragraph>
        <div className={'consultation-banner__bottom-container'}>
          <div className={'consultation-banner__item-wrapper'}>
            <div className={'consultation-banner__item'}>
              <figure className={'consultation-banner__item-avatar'}>
                <Image
                  src={bannerData.contactPersonImage}
                  width={'172'}
                  height={'172'}
                  alt={bannerData.contactPersonName}
                />
              </figure>
              <img
                className={'consultation-banner__item-avatar-mask'}
                src={getCloudinaryUrl(
                  bannerData.personImageBackgroundMask,
                  'f_auto,q_auto,w_auto',
                )}
                alt={'mask'}
                loading={'lazy'}
              />
              <div className={'consultation-banner__details'}>
                <Heading
                  color={bannerData.textColor}
                  size={'medium-plus-small'}
                >
                  {bannerData.contactPersonName}
                </Heading>
                <Paragraph
                  className={'consultation-banner__role'}
                  color={bannerData.textColor}
                >
                  {bannerData.contactPersonRole}
                </Paragraph>
              </div>
            </div>
          </div>
          <div className={'consultation-banner__link-wrapper'}>
            <a href={bannerData.buttonLink}>
              <Button
                id={bannerData.buttonId}
                additionalClass={
                  'consultation-banner__button consultation-banner__paragraph--uppercase'
                }
              >
                {bannerData.buttonText}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ConsultationBanner.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConsultationBanner;
