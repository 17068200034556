import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Glide from '@glidejs/glide';
import {
  Section,
  SectionHeader,
  BoxProfile,
  Paragraph,
  Heading,
  AbsoluteCTA,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Team.scss';

class Team extends Component {
  static MEMBERS = [
    {
      name: 'Tomasz Bąk',
      job: 'Machine Learning Developer',
      photo: 'gatsby-cloudinary/tomasz-bak.png',
    },
    {
      name: 'Paweł Krynicki',
      job: 'Machine Learning Developer',
      photo: 'gatsby-cloudinary/pawel-krynicki.png',
    },
    {
      name: 'Maciej Papież',
      job: 'Machine Learning DevOps',
      photo: 'gatsby-cloudinary/maciej-papiez.png',
    },
    {
      name: 'Sławomir Zagórski',
      job: 'Machine Learning Developer',
      photo: 'gatsby-cloudinary/slawomir-zagorski.png',
    },
    {
      name: 'Damian Kozar',
      job: 'Frontend Developer',
      photo: 'gatsby-cloudinary/damian-kozar.png',
    },
    {
      name: 'Paweł Capaja',
      job: 'Product Designer',
      photo: 'gatsby-cloudinary/pawel-capaja.png',
    },
  ];

  componentDidMount() {
    this.glide = new Glide('#ml-team-slider', {
      type: 'carousel',
      startAt: 0,
      perView: 2,
      animationDuration: 400,
      gap: 20,
      dragThreshold: false,
      autoplay: 3000,
      perTouch: 1,
      classes: {
        activeNav: 'active',
      },
      breakpoints: {
        600: {
          perView: 1,
          gap: 0,
        },
      },
    });

    this.glide.mount();
  }

  renderTeamMembers = () =>
    Team.MEMBERS.map(item => (
      <li className={'glide__slide'}>
        <BoxProfile
          backgroundColor={'violet'}
          photo={getCloudinaryUrl(item.photo, 'f_auto,q_auto,w_auto')}
          name={item.name}
          job={item.job}
          additionalClass={'hack-the-wind__team-profile--right'}
        />
      </li>
    ));

  renderNavBullets = () =>
    Team.MEMBERS.map((item, index) => (
      <button
        key={`member-${index}`}
        className={
          'related-articles__bullet related-articles__bullet--violet glide__bullet'
        }
        data-glide-dir={`=${index}`}
        aria-label={'Slider button'}
      />
    ));

  render() {
    return (
      <div className={'hack-the-wind__team-container'}>
        <Section
          color={'pigeon-post'}
          additionalClass={
            'hide-mobile hide-tablet hack-the-wind__team hack-the-wind__team--desktop'
          }
        >
          <SectionHeader
            size={'big'}
            lineColor={'violet'}
            fixed
            additionalClass={'section-header__description--medium'}
          >
            Boldare Machine Learning Team
          </SectionHeader>
          <Row className={'no-gap lifted'}>
            <Col
              sm={12}
              md={6}
              className={'hack-the-wind__team-description--centered'}
            >
              <Paragraph
                size={'medium'}
                additionalClass={
                  'hack-the-wind__team-description text--default'
                }
              >
                The Boldare team has a year and a half’s experience in machine
                learning. On a day-to-day basis, they consult our clients on
                the best solutions for their business needs as well as develop
                and implement artificial intelligence solutions.
              </Paragraph>
            </Col>
            <Col sm={12} md={6}>
              <Row className={'no-gap hack-the-wind__team-profile-list'}>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[0].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[0].name}
                    job={Team.MEMBERS[0].job}
                    additionalClass={'hack-the-wind__team-profile--left'}
                  />
                </Col>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[1].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[1].name}
                    job={Team.MEMBERS[1].job}
                    additionalClass={'hack-the-wind__team-profile--right'}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row className={'no-gap hack-the-wind__team-profile-list'}>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[2].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[2].name}
                    job={Team.MEMBERS[2].job}
                    additionalClass={'hack-the-wind__team-profile--left'}
                  />
                </Col>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[3].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[3].name}
                    job={Team.MEMBERS[3].job}
                    additionalClass={'hack-the-wind__team-profile--right'}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <div className={'hack-the-wind__team-testimonial'}>
                <Heading color={'black'} size={'medium-plus'} type={3}>
                  AI, Machine Learning and Deep Neural Networks can help you
                  with a number of problems
                </Heading>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              className={'hack-the-wind__team-description--centered'}
            >
              <Paragraph
                size={'medium'}
                additionalClass={'hack-the-wind__team-hackathon text--default'}
              >
                The team has really special qualities: they are analytical,
                business goals-oriented, lean, agile, cross-functional and
                knowledge-hungry.{' '}
              </Paragraph>
            </Col>
            <Col sm={12} md={6}>
              <Row className={'no-gap hack-the-wind__team-profile-list'}>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[4].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[4].name}
                    job={Team.MEMBERS[4].job}
                    additionalClass={'hack-the-wind__team-profile--left'}
                  />
                </Col>
                <Col sm={6} className={'hack-the-wind__team-profile'}>
                  <BoxProfile
                    backgroundColor={'violet'}
                    photo={getCloudinaryUrl(
                      [Team.MEMBERS[5].photo],
                      'f_auto,q_auto',
                    )}
                    name={Team.MEMBERS[5].name}
                    job={Team.MEMBERS[5].job}
                    additionalClass={'hack-the-wind__team-profile--right'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
        <Section
          color={'pigeon-post'}
          additionalClass={'hide-desktop hack-the-wind__team'}
        >
          <SectionHeader
            size={'big'}
            lineColor={'violet'}
            fixed
            additionalClass={'section-header__description--medium'}
          >
            Boldare Machine Learning Team
          </SectionHeader>
          <Paragraph
            size={'medium'}
            additionalClass={'hack-the-wind__team-description text--default'}
          >
            The Boldare team has a year and a half’s experience in machine
            learning. On a day-to-day basis, they consult our clients on the
            best solutions for their business needs as well as develop and
            implement artificial intelligence solutions.
            <br /> <br /> The team has really special qualities: they are
            analytical, business goals-oriented, lean, agile, cross-functional
            and knowledge-hungry.
          </Paragraph>
          <div
            id={'ml-team-slider'}
            className={'hack-the-wind__team-slider glide'}
          >
            <div data-glide-el={'track'} className={'glide__track'}>
              <ul className={'glide__slides'}>{this.renderTeamMembers()}</ul>
            </div>
            <div className={'glide__bullets'} data-glide-el={'controls[nav]'}>
              {this.renderNavBullets()}
            </div>
          </div>
        </Section>
        <AbsoluteCTA linkID={'hack-the-wind-team'} />
      </div>
    );
  }
}

export default Team;
