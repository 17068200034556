import React from 'react';
import { anchorate } from 'anchorate';
import 'intersection-observer';
import { UserGeolocationContextProvider } from 'context';

export const onRouteUpdate = ({ location }) => {
  if (window.piTracker) window.piTracker(location.href);
  anchorate();
};

export const onClientEntry = () => {
  window.mouseflowPath = '/soft-launch';
};

export const wrapRootElement = ({ element }) => {
  return (
    <UserGeolocationContextProvider>
      {element}
    </UserGeolocationContextProvider>
  )
}
