import React from 'react';
import DesignThatTellsStories from 'views/designThatTellsStories';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['ux-ui-design-consulting-services'];
const { navColors } = page;
const url = `${config.siteUrl}/ux-ui-design-consulting-services/`;
const canonical = `${url}`;

const DesignPage = () => (
  <Layout {...{ page, canonical }}>
    <NavBar color={navColors} bannerHidden />
    <DesignThatTellsStories />
  </Layout>
);

export default DesignPage;
