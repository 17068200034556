const competitiveAdvantagesA = {
  header: 'Our competitive advantages',
  text:
    'It’s hard to argue with facts: 80% of our customers return to us for help with new digital products. Here’s why:',
  advantages: [
    {
      index: 1,
      header: 'Lean Startup Process',
      text:
        'We use a model that lets our partners focus on the goals that are important here and now to grow their business. It allows them to work smarter, not harder, using the build-measure-learn approach, feedback loops and validated learning as some of the main tools. For us, there is no one-size-fits-all solution for product design and development. Depending on the maturity of your product, we will recommend one of four phases to fulfill your needs: prototype, MVP, product-market fit or scaling.',
    },
    {
      index: 2,
      header: 'Interdisciplinarity 3.0',
      text:
        'We build Agile teams on steroids - cross-functional, self-organizing, creative problem-solvers, taking ownership from A to Z and backed by an organizational ecosystem of advisory chapters and strategic experts.',
    },
    {
      index: 3,
      header: 'The same service standards for all clients',
      text:
        'Service standards are rules that show the level of service every client can expect from us, no matter how big they are or how much their contract is worth. Our service standards are designed to provide consistent quality of service for every project. They are a guarantee that your investment will bring value to your business and your budget will be spent efficiently.',
    },
    {
      index: 4,
      header: 'You always get more than expected',
      text:
        'When working with Boldare, you always get more than a dedicated development team. Your product development team will always be backed by our behind the scenes crew, including Devops, solution architects, product strategists and new product guides. When you partner with Boldare you get the knowledge, access and experience of the entire organization.',
    },
    {
      index: 5,
      header: 'Business consulting by default',
      text:
        'Our services go way beyond the developer-days featured on the invoice. Behind the scenes there is a group of people supporting our teams to do their best job. These include communities of experts, personal growth guides, strategy advisors, client growth guides, product consultants, Agile coaches, and many more. Their combined efforts make our teams stand out from the crowd.',
    },
    {
      index: 6,
      header: 'Radical transparency',
      text:
        'Thanks to radical transparency, your in-house team will be learning throughout the entire co-creation process, so at the end of the day they will be able to continue to develop the product without our help. This continuous knowledge transfer is a guarantee that the final product will be handed over without unnecessary stages of knowledge and documentation transfer. Thanks to radical transparency all ups and downs are equally visible, making it easier to find solutions and forge obstacles into opportunities.',
    },
  ],
};

const competitiveAdvantagesB = {
  header: 'Our competitive advantages',
  text:
    'It’s hard to argue with facts: 80% of our customers return to us for help with new digital products. Here’s why:',
  advantages: [
    {
      index: 1,
      header: 'Our team is yours',
      text:
        'Our high-performance teams are not remote assistants. They are a group of goal-focused individuals with specialized expertise and complementary skills who collaborate, innovate and produce consistently superior results. Our teams will align their work to your strategy and business goals keeping in mind your success. Boldare teams will treat your product as their own. You will experience this from the first meeting, as we work without project managers or other proxies.  ',
    },
    {
      index: 2,
      header: 'Business mindset everywhere',
      text:
        'At Boldare, the developer thinks about the product and not just the code; the designer considers the users, not the beautiful solution; and the QA engineer focuses on the return on investment, not just errors. Every product decision we make is business driven.',
    },
    {
      index: 3,
      header: 'The same service standards for all clients',
      text:
        'Service standards are rules that show the level of service every client can expect from us, no matter how big they are or how much their contract is worth. Our service standards are designed to provide consistent quality of service for every project. They are a guarantee that your investment will bring value to your business and your budget will be spent efficiently.',
    },
    {
      index: 4,
      header: 'Tech champions in your team',
      text:
        'Our engineers stand out as T-shaped experts in technology which means they are comfortable working on products with a versatile tech stack. Every single talent we hire is familiar with various testing methods. During our recruitment process we look for people who can use their personal talents on products at various maturity stages and with various business goals. Thanks to our vibrant, internal dev communities, our partners can benefit from the knowledge transfer to their own teams. In addition, their soft skills are at uncommonly high levels, making them amazingly easy to communicate with.',
    },
    {
      index: 5,
      header: 'Committed from the first call',
      text:
        'While others focus on selling their developers, we focus on understanding your needs. That’s why during our first call, you will be able to discuss your idea with our developer and business analyst. Our solution architects are committed to offering you two distinct initial product solutions that respond to your business needs. Starting our process so early and believing in trust more than in a piece of paper, we usually kick off product development even before putting our signatures on the official contract.',
    },
    {
      index: 6,
      header: 'We believe in partnerships',
      text:
        'Most of our client partnerships last for years because we know how to grow and change with our clients. We understand the necessity of change as a key progress factor and we happily embrace it.',
    },
  ],
};

export { competitiveAdvantagesA, competitiveAdvantagesB };
