const events = {
  'transform-gaps-into-opportunities-workshop': {
    cover: 'img/workshops_cover.png',
    speakers: [
      {
        name: 'Jagoda Podrucka',
        photo: 'img/jagoda-podrucka.png',
        role: 'Product Designer at Boldare',
        linkedin: 'https://www.linkedin.com/in/jagoda-ux-service-design/',
      },
    ],
    title: 'Transform gaps into opportunities:',
    subtitle: 'optimize your business outcomes using service blueprints',
    description: [
      "When the customer experience is threatened by the complexity of your services, it's time to take action. Service blueprints help map critical touchpoints and identify bottlenecks that affect the client's experience. By spotting and addressing these weak links, you can enhance profits and retain customers for longer periods. This interactive workshop will get into the service design blueprints and customer experience (CX) design, exploring how they can improve your business outcomes.",
    ],
    agendaItems: [
      'Understand the essence of CX design and its role in connecting people, systems, and tools to deliver a holistic service experience.',
      'Learn the significance of designing for both internal and external touchpoints, ensuring a seamless business interaction.',
      'Embrace the power of co-creation by fostering collaboration among team members, stakeholders, users, and potential consumers.',
      'Grasp the importance of focusing on the overarching value of the service, continuously testing its value, and addressing user needs.',
      'Navigate the journey of crafting an impeccable service design that prioritizes customer experience, from strategizing to simulating the final experience.',
    ],
    summary:
      'By the end of the workshop, participants will have all tools to find gaps in their business processes and optimize them for growth.',
    address: 'Mindspace',
    addressSecondLine: 'Zimmerstraße, Berlin',
    addressLink: 'https://goo.gl/maps/Avx8FqLbg8Mzgnf98',
    date: 'September 21st',
    time: '17:00',
    duration: '2.5 hours',
    group: 'Max. 12 people',
    form: 'In-person workshop',
    speakerBox: false,
  },
  'bold-growth-meetup': {
    cover: 'img/workshops_cover.png',
    speakers: [
      {
        name: 'Oskar Kwitek',
        photo: 'img/oskar-kwitek.png',
        role: 'Product Strategist at Boldare',
        linkedin: 'https://www.linkedin.com/in/oskar-kwitek',
      },
      {
        name: 'Tarek Reslan',
        photo: 'img/tarek.png',
        role: 'Lead Growth & Marketing, Trainer & Coach',
      },
    ],
    title: 'Bold Growth Meetup',
    subtitle:
      "Accelerate your business's growth with product strategy and user-centric design",
    description: [
      "This meetup will inspire you to take user-centricity from an undefined concept to an actionable product strategy. Implement the techniques from the talks and align your business goals with the user value creation. Take part in a meetup where expert product practitioners from Boldare meet with knowledge of the Growth Tribe and guide you and your business into a new stage of accelerated growth in users and revenue. Listen to Oskar Kwitek, a Product Strategist at Boldare, who with years of experience under his belt, will explain why Product Led Growth is a great new way to boost your businesses growth and will show you how to create your product strategy that will take you to new heights when it comes to gaining new users. Join Tarek Reslan, Lead Growth & Marketing Trainer & Coach at Growth Tribe, for a workshop titled Mastering User Insights: Usability Testing, Value Activation, and Persona Development Workshop. This session is designed to equip you with the tools needed to gain a deeper understanding of your users and tailor your strategies to align with your customers' needs.",
    ],
    agendaItems: [
      '18:00 - registration',
      '18:25 - Welcome talk by Boldare and Growth Tribe',
      '18:30 - Talks by Boldare and Growth Tribe',
      '20:00 - Networking & drinks',
    ],
    summary:
      'By the end of the workshop, participants will have all tools to find gaps in their business processes and optimize them for growth.',
    address: 'Growth Tribe',
    addressSecondLine: 'Amsterdam',
    addressLink: 'https://maps.app.goo.gl/tRAweRQcW6kCJBfm8 ',
    date: 'September 26st, 2023',
    time: 'at 18:00',
    duration: '3 hours',
    group: 'Max. 50 people',
    pageColor: 'yellow',
    boxColor: 'white',
    tag: true,
    speakerBox: true,
    customTemplate: true,
    customCopy: true,
    customSectionLogos: [
      {
        src: 'logos/boldare_primary.png',
        alt: 'Boldare logo',
      },
      {
        src: 'logos/growthtribe_black.png',
        alt: 'GrowthTribe logo',
      },
    ],
  },
  'practical-ai-application': {
    cover: 'img/ai-webinar_cover.png',
    speakers: [
      {
        name: 'Romuald Członkowski',
        photo: 'img/romo.png',
        role: 'Head of AI Solutions at Boldare',
        color: 'white',
        linkedin: 'https://www.linkedin.com/in/czlonkowski',
      },
    ],
    title: 'Practical application of AI in materials trading​ industry',
    subtitle: '',
    description: [
      `There’s much talk about AI changing our lives, but the real, widespread, practical impact on day-to-day business is still emerging. This is your opportunity to be among the pioneers to implement it, reap the rewards, and gain a competitive edge.

That’s why we organized our October 25th webinar to learn how Artificial Intelligence (AI) can enhance efficiency in materials trading for companies. This webinar was prepared for business leaders seeking practical, comprehensible insights on utilizing AI to:`,
    ],
    agendaItems: [
      'Increase productivity',
      'Reduce costs',
      'Improve customer experience',
    ],
    summary: `During the event, our Head of AI Solutions, Romuald Członkowski,  shared the latest AI insights at that time, discussed our experience with building AI solutions. Most importantly he gave specific tips for the trading materials industry, drawing on our practical experience assisting clients in bulk materials trading and cargo management.

** Watch at your convenience!** Everyone who joins will get an exclusive opportunity for a consultation with the speaker and discuss their company's AI challenges.`,
    address: 'Webinar on demand',
    pageColor: 'white',
    boxColor: 'black',
    tag: false,
    customTemplate: false,
    customCopy: false,
    speakerBox: true,
  },
  'webinar-improve-dev-team-communication': {
    cover: 'img/biz-dev.png',
    speakers: [
      {
        name: 'Sebastian Miklaszewski',
        photo: 'img/s_miklaszewski.png',
        role: 'Software Developer',
        color: 'white',
        linkedin:
          'https://www.linkedin.com/in/sebastian-miklaszewski-1b9a83191/ ',
      },
    ],
    title:
      'Less coding, more talking: Save money by investing in communication',
    subtitle: '',
    description: [
      'Miscommunication can cost medium-sized companies an average of $420,000 annually. One of the most visible problems with communication occurs around software development, where businesses encounter complex topics. Any small misstep in information exchange can cost you hours of loss of productivity from your high-cost talent.',
      'From almost two decades of experience in product development, we have observed that there is no specific role or side at fault when it comes to communication between business and development teams. It is a systemic issue, or more precisely, a lack of framework. And we have one in mind that will do wonders for your budget and timelines – Event Storming.',
      'The webinar, led by our software developer Sebastian Miklaszewski, focuses on the counter-intuitive role of communication within development teams, discusses stereotypes. He will use his experience to show tangible examples of the costs of miscommunication and why do we see Event Storming as a solution, while going over the practical aspects of conducting such sessions with your team.',
      'Sebastian is a passionate PHP Developer and Aspiring Software Architect. He likes sharing unique perspectives on development, speaking at industry meetups.',
    ],
    summary: '',
    address: 'Online',
    date: 'November 15th, 2023',
    time: '3pm CET',
    duration: '35 mins',
    pageColor: 'white',
    boxColor: 'black',
    tag: false,
    customTemplate: false,
    customCopy: false,
    speakerBox: true,
    form: 'Webinar',
    qaSession: 'At the end of the webinar',
  },
  'scale-your-AI-solution-meetup': {
    cover: 'event/KIEZ_LP_cover_2.png',
    speakers: [
      {
        name: 'Oskar Kwitek',
        photo: 'img/Oskar_Kwitek.png',
        role: 'Product Strategist at Boldare',
        color: 'black',
      },
    ],
    // title: 'Bold Growth Meetup',
    subtitle: 'Scale Your AI Solution Faster with Rapid Prototyping',
    description: [
      'Got any questions regarding the event? Write to us at events@boldare.com.',
      'Learn how to grow your AI startup quickly using rapid prototyping in a talk by product expert Oskar Kwitek. In this session, Oskar Kwitek will guide you through the essentials of rapid prototyping and its role in scaling AI solutions. ',
      `You’ll learn to:
- pinpoint and understand your audience,
- generate ideas that capture attention,
- use prototyping to test these ideas effectively.
      `,
      'The talk will cover different tools and technologies, providing practical advice on picking the ones that match your project’s needs.',
      'Oskar will discuss quick iteration—making changes and improvements rapidly, using feedback to make your product better as you go along. He’ll also show you how to move from a small-scale prototype to a full-sized, market-ready solution while keeping things efficient and manageable.',
      'Real examples from Boldare will show you how others have successfully used these methods. You’ll see how these lessons apply to your work, helping you to scale up your AI solution with confidence.',
      `**Agenda**
- **10.00** - business breakfast & networking
- **11.00** - Keynote | Scale Your AI Solution Faster with Rapid Prototyping by Oskar Kwitek, Product Strategist at Boldare`,
    ],
    agendaItems: [],
    summary: `**For whom**
- For AI startups looking to learn from experience
- For companies working on and with AI solutions
- Small & medium companies looking to integrate their solution with AI
- Big company departments or branches who look to implement AI to improve their productivity
      `,
    address: 'Merantix AI campus',
    addressSecondLine: 'Berlin',
    addressLink: 'https://maps.app.goo.gl/XBZzyDX7Lxh93w6NA',
    date: 'November 23rd, 2023',
    time: 'at 10:00',
    duration: '60 min',
    // group: 'Max. 50 people',
    pageColor: 'yellow',
    boxColor: 'white',
    tag: false,
    speakerBox: true,
    customTemplate: true,
    customCopy: false,
    customSectionLogos: [
      {
        src: 'logos/boldare_primary.png',
        alt: 'Boldare logo',
      },
      {
        src: 'logos/KIEZ_Logo_RGB_Black.png',
        alt: 'Kiez logo',
        url: 'https://www.kiez.ai/',
      },
      {
        src: 'logos/Merantix_AI_Campus_Berlin_Logo_Black_1.png',
        alt: 'Merantix AI Campus Berlin logo',
        url: 'https://www.merantix-aicampus.com/',
      },
    ],
    registrationClosed: true,
  },
};

export { events };
