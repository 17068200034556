import React from 'react';
import { Paragraph, Section } from 'components';
import './Why.scss';
import { Col, Row } from 'react-flexbox-grid';
import Chat from 'assets/images/finding-pmf-amsterdam/find-pmf-ams-chat.svg';
import Tools from 'assets/images/finding-pmf-amsterdam/find-pmf-tools.svg';
import Gift from 'assets/images/finding-pmf-amsterdam/find-pmf-ams-gift.svg';
import Idea from 'assets/images/finding-pmf-amsterdam/find-pmf-ams-idea.svg';
import TitleEventFpmf from '../TitleEventFpmf/index';

const Why = () => {
  return (
    <Section
      additionalClass={'event-fpmf-amsterdam__why'}
      color={'mine-shaft'}
    >
      <Col className={'event-fpmf-amsterdam__max-width '}>
        <TitleEventFpmf
          title={
            'That talk alone is enough to get you started with Product-Market Fit!'
          }
          text={`The meetup took place on December 14th, 2022, and focused
            on product-market fit, our experience with value proposition
            canvas and TeamAlert's PMF case.`}
          secondText={
            'The audience was super enthusiastic about the topic, you can spot that in the Q&A at the end.'
          }
          white
        />
        <Row>
          <Col
            sm={12}
            md={6}
            className={'event-fpmf-amsterdam__why-column-left'}
          >
            <Paragraph
              color={'white'}
              additionalClass={'event-fpmf-amsterdam__why-paragraph'}
            >
              <img
                id={'challenge'}
                src={Chat}
                alt={'challenge'}
                loading={'lazy'}
              />
              Product-Market Fit (PMF) happens when your product is tailored to
              your customers' needs
            </Paragraph>
            <Paragraph
              color={'white'}
              additionalClass={'event-fpmf-amsterdam__why-paragraph'}
            >
              <img id={'tools'} src={Tools} alt={'tools'} loading={'lazy'} />
              Learn tools and methods which help your business reach PMF
            </Paragraph>
          </Col>
          <Col
            sm={12}
            md={6}
            className={'event-fpmf-amsterdam__why-column-right'}
          >
            <Paragraph
              color={'white'}
              additionalClass={'event-fpmf-amsterdam__why-paragraph'}
            >
              <img id={'food'} src={Idea} alt={'food'} loading={'lazy'} />
              What challenges to expect before your product is ready to access
              new streams of revenue
            </Paragraph>
            <Paragraph
              color={'white'}
              additionalClass={'event-fpmf-amsterdam__why-paragraph'}
            >
              <img id={'host'} src={Gift} alt={'host'} loading={'lazy'} />
              Bonus after registration – PMF workbook that will cover your
              product-market fit needs
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Section>
  );
};

export default Why;
