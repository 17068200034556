/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { classNames } from 'utils';

import './AnimatedText.scss';

const item = {
  hidden: {
    y: '200%',
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.35 },
  },
  visible: {
    y: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.3 },
  },
};

const splitWords = text => {
  const splittedWords = text.split(' ');
  const words = [];

  splittedWords.forEach(word => {
    const letters = [];
    [...word].forEach(letter => {
      letters.push(`${letter}`);
    });
    words.push(letters);
  });

  return words;
};

const AnimatedText = ({ text, watered }) => {
  const words = splitWords(text);

  if (!watered) {
    words.map(word => {
      return word.push('\u00A0');
    });
  }

  return (
    <>
      {words.map((word, i) => {
        return (
          <span key={i} className={'hero-animated'}>
            {words[i].flat().map((element, index) => {
              return (
                <span
                  className={classNames('hero-animated__container', {
                    'hero-animated__container--watered': watered,
                  })}
                  key={index}
                >
                  <motion.span
                    variants={item}
                    className={classNames('hero-animated__letter', {
                      watered,
                    })}
                  >
                    {element}
                  </motion.span>
                </span>
              );
            })}
          </span>
        );
      })}
    </>
  );
};

export default AnimatedText;

AnimatedText.defaultProps = {
  text: '',
  watered: false,
};

AnimatedText.propTypes = {
  text: PropTypes.string,
  watered: PropTypes.bool,
};
