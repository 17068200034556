import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { serialize } from 'utils';

export default function withSubscribe(WrappedComponent) {
  return class SubscribeHOC extends Component {
    static propTypes = {
      children: PropTypes.node,
      pardotLink: PropTypes.object,
      settings: PropTypes.string,
    };

    static defaultProps = { children: null };

    constructor() {
      super();

      this.refForm = createRef();

      this.state = {
        subscribed: false,
      };
    }

    handleSubmit = e => {
      e.preventDefault();

      const { settings } = this.props;

      const url = settings.downloadPardotLink;
      const form = this.refForm.current;

      this.setState({ isLoading: true });

      fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        body: serialize(form),
      })
        .then(() => {
          if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
              event: `article-download-form-sent`,
            });
          }
          this.setState({ subscribed: true });

          setTimeout(
            () => document.getElementById('download-link--disabled').click(),
            2000,
          );
        })
        .catch(error => console.log(error));
    };

    render() {
      const { subscribed, isLoading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          subscribed={subscribed}
          isLoading={isLoading}
          refForm={this.refForm}
          handleSubmit={this.handleSubmit}
        />
      );
    }
  };
}
