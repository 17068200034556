import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';

const related = [
  'Visualising Energy',
  'How to scale a monolithic MVP application without losing business?',
  'From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand',
].map(title => ({ title }));

const OurArticles = ({ allArticles, related }) => (
  <>
    <Section
      color={'gray-light'}
      additionalClass={'digital-transformation__articles'}
    >
      <SectionHeader
        size={'medium'}
        description={
          'Explore our articles about tools and process we use as part of our Digital Transformation Services.'
        }
        fixed
      >
        Digital Transformation Insights and Resources
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'digital-transformation__articles'}
        related={related}
        background={'gray-light'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
      />
    )}
  </>
);

export default OurArticles;
