import awwwardsLogo from 'assets/images/design-that-tells-stories/awwwards_logo.png';
import cssLogo from 'assets/images/design-that-tells-stories/css_logo.png';
import designLogo from 'assets/images/design-that-tells-stories/design_logo.png';
import germanLogo from 'assets/images/design-that-tells-stories/german_logo.png';
import indigoLogo from 'assets/images/design-that-tells-stories/indigo_logo.png';
import lovieLogo from 'assets/images/design-that-tells-stories/lovie_logo.png';
import nextgenLogo from 'assets/images/design-that-tells-stories/nextgen_logo.png';
import webbyLogo from 'assets/images/design-that-tells-stories/webby_logo.png';

export const AWARDS = [
  {
    name: 'Awwwards',
    logo: awwwardsLogo,
    number: 16,
    color: 'red',
  },
  {
    name: 'The Lovie Awards',
    logo: lovieLogo,
    number: 2,
    color: 'yellow',
    className: 'bldr-dsgn__award-square',
  },
  {
    name: 'The Webby Awards',
    logo: webbyLogo,
    number: 1,
    className: 'bldr-dsgn__award-square',
  },
  {
    name: 'German Design Award Winner',
    logo: germanLogo,
    number: 1,
    className: 'bldr-dsgn__award-square',
  },
  {
    name: 'CSS Winner',
    logo: cssLogo,
    number: 4,
    color: 'turquoise',
  },
  {
    name: 'Indigo Design Award',
    logo: indigoLogo,
    number: 2,
    color: 'yellow',
  },
  {
    name: 'Nextgen Enterprise Awards',
    logo: nextgenLogo,
    number: 2,
    color: 'yellow',
  },
  {
    name: 'CSS Design Awards',
    logo: designLogo,
    number: 1,
  },
];
