import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';

const page = pages.resources.items['how-to-implement-and-monetize-AI'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/product-strategy-workbook/`;
const canonical = `${url}`;

const MonetizeAiStrategyWorkbook = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={
          'How to implement and monetize AI in your industry. Practical use cases.'
        }
        lineColor={'red'}
        cover={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto')}
        coverMobile={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto,w_600')}
        description={`Still not sure how to use AI based solutions in your business?`}
        items={[
          'According to a survey by PricewaterhouseCoopers (PwC), 72% of business leaders consider AI a "business advantage".',
          'A survey conducted by MIT Sloan Management Review and The Boston Consulting Group found that only 20% of respondents said their organizations have a clear vision for how AI could impact their business.',
          'A study by Capgemini found that while 83% of organizations view AI as a strategic opportunity, only 13% have deployed AI systems at scale.',
          'While 90% of decision-makers are waiting and observing the GenAI trend—become one of the 10% and benefit from it!',
        ]}
        outro={`Check what AI solutions you can implement in your business.
        Download an e-book with practical examples for as many as a dozen industries.`}
        url={pardotlink}
        formHeading={'Get your free workbook now'}
        button={'Send me workbook'}
        btnColor={'pigeon-post'}
        trackingEvent={`ProductStrategyWorkbookResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free workbook is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default MonetizeAiStrategyWorkbook;
