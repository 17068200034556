import React from 'react';
import './RetrospectivePractices.scss';
import { Button, Heading, Paragraph, Link, IconSVG } from 'components';

// TODO TG
const RetrospectivePractices = () => {
  return (
    <section className={'srt-retro-practices w-container'}>
      <div className={'srt-know-how'}>
        <span className={'srt-know-how__feedback-number text--bold'}>5</span>
        <div className={'srt-know-how__icon'}>
          <IconSVG name={'wheel'} size={'large'} />
        </div>
        <div className={'srt-know-how__description'}>
          <div className={'srt-know-how__title srt__small-plus-paragraph'}>
            Boldare know-how
          </div>
          <div
            className={
              'srt-know-how__subtitle text--bold anim-slide-in-top-in-view'
            }
          >
            Good retrospective practices by Boldare’s Scrum Masters
          </div>
        </div>
      </div>
      <div className={'srt-blog-banner anim-slide-in-top-in-view'}>
        <Heading
          additionalClass={'srt-blog-banner__heading'}
          type={3}
          color={'white'}
        >
          Digital shift
        </Heading>
        <Paragraph
          additionalClass={'srt__paragraph'}
          color={'white'}
          size={'medium'}
        >
          Explore our blog and discover articles which may inspire you
        </Paragraph>
        <Link to={'/blog'} id={'srt__blog-link'}>
          <Button color={'white'}>Visit our blog</Button>
        </Link>
      </div>
      <div className={'w-container srt-retro-practices__heding-wrapper'}>
        <Heading
          additionalClass={
            'srt-retro-practices__heading srt__small-plus-paragraph w-container'
          }
        >
          How can a retrospective help your team?
        </Heading>
      </div>
    </section>
  );
};

export default RetrospectivePractices;
