import React, { Component } from 'react';
import { ContactFormFullwidth } from 'components';
import { enableAnimations } from 'config/helpers';
import {
  Banner,
  Summary,
  Team,
  Problem,
  MachineLearningServices,
  Solution,
} from './subcomponents';
import './HackTheWind.scss';

class HackTheWind extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study hack-the-wind'}>
        <Banner />
        <Summary />
        <Team />
        <Problem />
        <Solution />
        <ContactFormFullwidth
          name={'hack-the-wind-contact-form'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x8p'}
          id={'hack-the-wind__contact-end'}
        />
        <MachineLearningServices />
      </div>
    );
  }
}

export default HackTheWind;
