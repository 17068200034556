import React from 'react';
import PropTypes from 'prop-types';

import { IconSVG } from 'components';

import './HideButton.scss';

const HideButton = ({ handleClick }) => (
  <button
    className={'hide-button-a'}
    onClick={handleClick}
    label={'close filter-box'}
  >
    <IconSVG name={'cross'} size={'medium'} color={'white'} />
  </button>
);

HideButton.defaultProps = {
  handleClick: () => {},
};

HideButton.propTypes = {
  handleClick: PropTypes.func,
};

export default HideButton;
