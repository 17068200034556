import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { enableAnimations } from 'config/helpers';
import SVG from 'react-inlinesvg';
import tick from 'assets/images/software-development-outsourcing/tick.svg';
import './ResourceList.scss';

class ResourceList extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { items, itemsQuestions, coverAlt } = this.props;

    return (
      <ul className={'resources__list'}>
        {items.map(item => (
          <li className={'resources__list-item'}>
            <div className={'resources__list-item-icon'}>
              {itemsQuestions ? (
                <span
                  className={'text--bold resources__list-item-icon--question'}
                >
                  ?
                </span>
              ) : (
                <SVG src={tick} alt={coverAlt || 'List item'} />
              )}
            </div>
            <div className={'resources__list-item-text'}>
              {ReactHtmlParser(item)}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

ResourceList.propTypes = {
  coverAlt: PropTypes.string,
  itemsQuestions: PropTypes.bool,
  items: PropTypes.array,
  tick: PropTypes.string,
};

ResourceList.defaultProps = {
  coverAlt: '',
  itemsQuestions: false,
  items: [],
  tick: '',
};

export default ResourceList;
