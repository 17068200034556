import React from 'react';
import Layout from 'components/layout';
import { Footer, NavBar } from 'components';
import FindingPmfBerlin from 'views/finding-pmf-berlin';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../meta/config';

const page = pages['finding-pmf'];
const { navColors } = page;
const url = `${config.siteUrl}/finding-pmf/`;
const canonical = `${url}`;

const FindingPmfBerlin2023 = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} banner={false} />
      <FindingPmfBerlin
        imageDesktop={getCloudinaryUrl(
          'event/berlin/collage_hero.png',
          'f_auto,q_auto',
        )}
        url={pardotlink}
      />
      <Footer />
    </Layout>
  );
};

export default FindingPmfBerlin2023;
