import React from 'react';
import PropTypes from 'prop-types';
import './Box.scss';
import { classNames } from 'utils';

const Box = ({ additionalClass, backgroundColor, children, link, id }) => (
  <div className={classNames('box', additionalClass)}>
    <div
      id={id}
      className={classNames('box__wrapper', {
        [`box__wrapper--${backgroundColor}`]: backgroundColor,
      })}
    >
      {children}
    </div>
  </div>
);

Box.propTypes = {
  additionalClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Box.defaultProps = {
  additionalClass: '',
  backgroundColor: 'white',
  children: '',
  id: '',
};

export default Box;
