import { BOX_TYPES } from 'config';
import GolemLogo from 'assets/images/contact-de/media-logo/golem-logo.svg';
import GQLogo from 'assets/images/contact-de/media-logo/gq-logo.svg';
import WirtschaftLogo from 'assets/images/contact-de/media-logo/wirtschaft-logo.svg';
import TThreeNLogo from 'assets/images/contact-de/media-logo/t-three-n-logo.svg';

export const articleData = [
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      clientName: '',
      mediaLogo: {
        icon: GQLogo,
        alt: 'GQ media logo',
      },
      coverImage: 'gatsby-cloudinary/media_ein_business_hippie_zu.png',
      title: '“Die beste Karriereentscheidung? Ein Business-Hippie zu sein”',
      description: ``,
      tags: [''],
      industry: [''],
      service: [''],
      productStage: [''],
      region: [''],
    },
    settings: {
      link:
        'https://www.gq-magazin.de/lifestyle/artikel/gquestionnaire-anna-zarudzka-von-boldare-beantwortet-den-gq-business-fragebogen/',
    },
    id: '1',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      clientName: '',
      coverImage: '/gatsby-cloudinary/media_ratgeber.png',
      mediaLogo: {
        icon: WirtschaftLogo,
        alt: 'Wirtschaft media logo',
      },
      title:
        '“W+M-Ratgeber: 7 Praxistipps für erfolgreiche Teamarbeit im Home”',
      description: ``,
      tags: [''],
      industry: [''],
      service: [''],
      productStage: [''],
      region: [''],
    },
    settings: {
      link:
        'https://wirtschaft-markt.de/2020/12/11/wm-ratgeber-7-praxistipps-fuer-erfolgreiche-teamarbeit-im-home/',
    },
    id: '2',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      clientName: '',
      coverImage: 'gatsby-cloudinary/media_kroczek_von_boldare.png',
      mediaLogo: {
        icon: GolemLogo,
        alt: 'Golem media logo',
      },
      title:
        'Jaroslaw Kroczek von Boldare: "Gute Bezahlung reicht heute nicht mehr aus"',
      description: ``,
      tags: [''],
      industry: [''],
      service: [''],
      productStage: [''],
      region: [''],
    },
    settings: {
      link:
        'https://www.golem.de/news/jaroslaw-kroczek-von-boldare-als-kind-sass-er-lieber-am-pc-als-draussen-zu-spielen-2106-156965.html',
    },
    id: '3',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      clientName: '',
      coverImage: '/gatsby-cloudinary/media-silicon-polska-der-tech.png',
      mediaLogo: {
        icon: TThreeNLogo,
        alt: 'T3N media logo',
      },
      title:
        'Silicon Polska – der Tech-Hotspot in der europäischen Nachbarschaft',
      description: ``,
      tags: [''],
      industry: [''],
      service: [''],
      productStage: [''],
      region: [''],
    },
    settings: {
      link: 'https://t3n.de/news/silicon-polska-tech-hotspot-polen-1369122/',
    },
    id: '4',
  },
  {
    type: BOX_TYPES.CASE_STUDY,
    content: {
      clientName: '',
      coverImage: '/gatsby-cloudinary//media-slack-und-asana-sind.png',
      mediaLogo: {
        icon: TThreeNLogo,
        alt: 'T3N media logo',
      },
      title:
        '„Slack und Asana sind die ideale Combo“ – Anna Zarudzka von Boldare',
      description: ``,
      tags: [''],
      industry: [''],
      service: [''],
      productStage: [''],
      region: [''],
    },
    settings: {
      link: 'https://t3n.de/news/anna-zarudzka-boldare-1343157/',
    },
    id: '5',
  },
];
