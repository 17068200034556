import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components';
import './TextSlider.scss';
import classnames from 'classnames';

const TextSlider = ({ items }) => (
  <div
    className={classnames('glide__track', 'text-slider')}
    data-glide-el={'track'}
  >
    <ul className={'glide__slides'}>
      {items.map((item, index) => (
        <li
          className={classnames('glide__slide', 'text-slider__slide')}
          id={`glide__slide-${index}`}
        >
          <Paragraph
            additionalClass={'text-slider__quote text--bold'}
            size={'big'}
          >
            {item.quote}
          </Paragraph>
          <Paragraph
            additionalClass={'text-slider__name text--bold'}
            size={'medium-constant'}
          >
            {item.name}
          </Paragraph>
          <Paragraph
            additionalClass={'text-slider__position'}
            size={'small-plus'}
          >
            {item.position}
          </Paragraph>
        </li>
      ))}
    </ul>
  </div>
);

TextSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TextSlider;
