import { Heading, IconSVG } from 'components';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import data from './data';
import './Table.scss';

const Table = () => {
  return (
    <div>
      <Row className={'table-agenda table-header'}>
        <Col xs={1} />
        <Col xs={1}>
          <Heading type={1} color={'white'} size={'medium'}>
            <IconSVG name={'clock'} size={'medium'} color={'white'} />
          </Heading>
        </Col>
        <Col xs={1} />
        <Col xs={5}>
          <Heading type={1} color={'white'} size={'medium'}>
            Topic
          </Heading>
        </Col>
        <Col xs={4}>
          <Heading type={1} color={'white'} size={'medium'}>
            Speaker
          </Heading>
        </Col>
      </Row>
      {data.map(row => {
        return (
          <Row key={row.topic} className={'table-agenda table-row'}>
            <Col xs={1}>
              {row.square ? (
                <div className={'square square-colored'} />
              ) : (
                <div className={'square'} />
              )}
            </Col>

            <Col xs={1}>
              <Heading
                type={1}
                color={'black'}
                size={'small'}
                additionalClass={'table-row-text'}
              >
                {row.date}
              </Heading>
            </Col>
            <Col xs={1} />
            <Col xs={5}>
              <Heading
                type={1}
                color={'black'}
                size={'small'}
                additionalClass={'table-row-text'}
              >
                {row.topic}
              </Heading>
            </Col>
            <Col xs={4} className={'speaker-column'}>
              <Heading
                type={1}
                color={'black'}
                size={'small'}
                additionalClass={'table-row-text'}
              >
                {row.speaker}
              </Heading>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default Table;
