import React from 'react';
import { Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const Result = () => {
  return (
    <div className={'pmf-survey__result'}>
      <div className={'pmf-survey__text-content'}>
        <h1 className={'pmf-survey__header'}>
          It seems your product might{' '}
          <span className={'pmf-survey__marker-highlight-long'}>be ready</span>{' '}
          for product-market fit.
        </h1>
        <p className={'pmf-survey__result-info'}>
          These 5 questions are only the beginning: there are still nuances of
          your product, market, and target group to be considered. To help you
          better tackle these challenges, we have prepared a second part to our
          Product-Market Fit survey: a free PDF called Product-Market Fit
          Workbook: is your product ready?
        </p>

        <a
          className={'pmf-survey__result-link'}
          href={
            'https://res.cloudinary.com/de4rvmslk/image/upload/v1671143079/pmf-survey/PMF_Workbook.pdf'
          }
          rel={'nofollow noreferrer'}
          target={'_blank'}
        >
          <span className={'pmf-survey__result-link-contact'}>
            You can download it here
          </span>
          <span className={'pmf-survey__result-link-icon'}>&nbsp;&#8594;</span>
        </a>
      </div>

      <div className={'pmf-survey__img'}>
        <Image
          src={getCloudinaryUrl(
            '/pmf-survey/ellipse2.png',
            'f_auto,q_auto,w_629',
          )}
          additionalClass={'pmf-survey__result-img-ellipse'}
          alt={'Ellipse'}
          loading={'lazy'}
        />

        <Image
          src={getCloudinaryUrl(
            '/pmf-survey/person.png',
            'f_auto,q_auto,w_719',
          )}
          additionalClass={'pmf-survey__result-img-person'}
          alt={'Ellipse'}
          loading={'lazy'}
        />
      </div>
    </div>
  );
};

export default Result;
