import React, { Component } from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Product.scss';

class Product extends Component {
  renderBoxes = () =>
    data.map((item, index) => (
      <div className={'pakt__product-box'} key={`pakt-product-${index}`}>
        <Heading
          type={3}
          size={'medium-plus'}
          color={'mine-shaft'}
          additionalClass={`anim-fade-in-bottom pakt__product-${index}`}
        >
          {item.title}
        </Heading>
        <Paragraph
          additionalClass={`text--light anim-fade-in-bottom pakt__product-${index}`}
          size={'default'}
          color={'mine-shaft'}
        >
          {item.text}
        </Paragraph>
        <FullwidthImage
          image={getCloudinaryUrl(
            `assets/pakt/${item.image}.jpg`,
            'f_auto,q_auto',
          )}
          alt={'Preview'}
          additionalClass={`pakt__product-image anim-fade-in-bottom pakt__product-${index}`}
        />
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'pakt__product-container'}>
        <div className={'pakt__content'}>
          <Heading type={2} size={'big-spreaded'}>
            Explaining Product to the user
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            Don’t let new user to get lost in new environment. We wanted to
            make users initiation with our website smooth and painless.
          </Paragraph>
        </div>
        <div className={'pakt__product-tiles'}>{this.renderBoxes()}</div>
      </Section>
    );
  }
}

export default Product;
