import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { data } from './logic.js';
import './Conversion.scss';
import Statistic from '../Statistic';

class Conversion extends Component {
  renderBoxes = () =>
    data.map(item => (
      <Statistic
        title={item.title}
        text={item.text}
        image={item.image}
        subtitle={item.subtitle}
        number={item.number}
        suffix={item.suffix}
        info={item.info}
        key={`pakt-conversion-${item.image}`}
      />
    ));

  render() {
    return (
      <Section additionalClass={'pakt__conversion-container'} spaceless>
        <div className={'pakt__content'}>
          <Heading type={2} size={'big-spreaded'}>
            Increase Conversion Rate
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            Simplifying the whole process rewarded incredibly fast. Quick and
            easy sign up form and intelligible interface pay back with
            considerable increase of conversion rate during the first 3 months.
          </Paragraph>
        </div>
        <div className={'pakt__conversion-tiles'}>{this.renderBoxes()}</div>
      </Section>
    );
  }
}

export default Conversion;
