const speakers = [
  {
    active: true,
    img: 'gatsby-cloudinary/anna-zarudzka-askcto.png',
    first_name: 'Anna',
    last_name: 'Zarudzka',
    title: 'co-CEO & founder at Boldare',
    bio:
      'Anna Zarudzka is a serial entrepreneur and co-CEO at Boldare, a digital product design and development company. She took the IT & Services sector by storm 12 years ago, establishing web design agency Chilid, working for brands such as Virgin Radio, Bla Bla Car and Tui. In 2019 Chilid merged with XSolve to create Boldare.',
    linkedin: 'https://www.linkedin.com/in/annazarudzka/',
  },
  {
    active: true,
    img: 'gatsby-cloudinary/karolina-kolodziej-askcto.png',
    first_name: 'Karolina',
    last_name: 'Kołodziej',
    title: 'CMO',
    bio:
      "With over a decade of experience in digital marketing and public relations working with startups, governmental and non-governmental bodies, Karolina oversees Boldare's marketing and media relations strategy. She also leads clients' engagement efforts as part of the Business teams.",
    linkedin: 'https://www.linkedin.com/in/karolina-kolodziej-9a717941/',
  },
  {
    active: true,
    img: 'gatsby-cloudinary/piotr-majchrzak-askcto.png',
    first_name: 'Piotr',
    last_name: 'Majchrzak',
    title: 'co-CEO & founder at Boldare',
    bio:
      'Piotr Majchrzak is an entrepreneur with a software development background, with numerous companies in his business portfolio. For almost 14 years, he led XSolve - the custom software development company he founded in 2004. With a portfolio of more than a hundred successfully delivered digital products for clients from around the world, Majchrzak co-founded Boldare by merging XSolve with its sister company, Chilid.',
    linkedin: 'https://www.linkedin.com/in/piotrmajchrzak/',
  },
  {
    active: true,
    img: 'gatsby-cloudinary/aneta-orszewska-askcto.jpg',
    first_name: 'Aneta',
    last_name: 'Orszewska',
    title: 'New Products Guide',
    bio:
      'Aneta has over 15 years of experience in business development and agile product development in various roles connected with business growth and sales, agile product development and business agility. For several years she was working in a start-up environment in Germany where she gained knowledge and hands-on experience in creating and validating business models, using lean start-up and product discovery approach, making sure the products to be built solve real user problems.',
    linkedin: 'https://www.linkedin.com/in/anetaorszewska/',
  },
  {
    active: false,
    img: 'gatsby-cloudinary/kamil-mizera-newsroom.jpg',
    first_name: 'Jan',
    last_name: 'Kowalski',
    title: 'Scrum Master',
    bio:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popular',
    linkedin: '',
  },
  {
    active: false,
    img: 'gatsby-cloudinary/pawel-kanski-newsroom.jpg',
    first_name: 'Szymon',
    last_name: 'Sadowski',
    title: 'Developer',
    bio:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popular',
    linkedin: '',
  },
];
export default speakers;
