import React from 'react';
import { Section, Paragraph, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Colaboration.scss';

const Colaboration = () => {
  return (
    <Section additionalClass={'pakt__colaboration-container'} spaceless>
      <div className={'pakt__content'}>
        <Heading type={2} size={'big-spreaded'}>
          Worldwide Team Colaboration
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          Our team consists of group of enthusiasts from Hong Kong, Poland and
          Netherlands. Our processes dedicated for the remote work, regular
          meetings, video conferences, tools, apps and trust between simply
          open people guarantees us the success.
        </Paragraph>
      </div>
      <div className={'pakt__colaboration-image'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/pakt/cs-pakt-map.png',
            'f_auto,q_auto',
          )}
          alt={'World map of colaboration'}
          additionalClass={'anim-fade-in'}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/pakt/cs-pakt-map-red.png',
            'f_auto,q_auto',
          )}
          alt={'World map of colaboration - legend'}
          additionalClass={'pakt__colaboration-legend anim-fade-in-left'}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/pakt/cs-pakt-map-pins.png',
            'f_auto,q_auto',
          )}
          alt={'World map of colaboration - locations'}
          additionalClass={'pakt__colaboration-pins anim-scale-out-in-center'}
        />
      </div>
    </Section>
  );
};

export default Colaboration;
