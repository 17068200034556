import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { SliderContext } from '../../utils';

import './Cta.scss';

const Cta = ({ index }) => {
  const { slides } = useContext(SliderContext);

  const {
    url,
    client: { name },
  } = slides[index];

  return (
    <a
      href={url || '/work/'}
      className={'hero-cta button button--primary button--yellow'}
    >
      {url ? `See why ${name} recommends us` : 'Explore case studies'}
    </a>
  );
};

Cta.defaultProps = {
  index: 0,
};

Cta.propTypes = {
  index: PropTypes.number,
};

export default Cta;
