import React from 'react';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import {
  Paragraph,
  Section,
  SectionHeader,
  RelatedArticles,
} from 'components';
import './LearnMore.scss';

const LearnMore = ({ allArticles, related, heading, description, color }) => (
  <>
    <Section color={color} additionalClass={'learn-more__articles'}>
      <Row className={'no-gap'}>
        <Col sm={12} md={6}>
          <SectionHeader size={'medium'} lineColor={'red'} fixed>
            {heading}
          </SectionHeader>
        </Col>
        <Col sm={12} md={6}>
          {description && (
            <Paragraph color={'black'} size={'medium'}>
              {description}
            </Paragraph>
          )}
        </Col>
      </Row>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={`${heading.replace(/\s+/g, '-').toLowerCase()}__articles`}
        related={related}
        background={color}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
      />
    )}
  </>
);

export default LearnMore;
