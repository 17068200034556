import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import PropTypes from 'prop-types';
import StickyBox from 'react-sticky-box';
import ReactHtmlParser from 'react-html-parser';
import { Row, Col } from 'react-flexbox-grid';
import { Heading, SectionHeader, Button, EventForm } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './EventContent.scss';

const SpyLink = Scroll.Link;
class EventContent extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      opacity: 1,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  handleScroll = () => {
    const scrollTop = window.scrollY;
    const itemOpacity = Math.round((1 - scrollTop * 0.0012) * 100) / 100;

    this.setState({
      opacity: itemOpacity,
    });
  };

  render() {
    const {
      btnColor,
      button,
      formHeading,
      image,
      imageAlt,
      items,
      date,
      start,
      series,
      itemsHeadings,
      subtitle,
      title,
      url,
    } = this.props;

    const { isMobile, opacity } = this.state;

    return (
      <>
        <Row className={'no-gap'}>
          <Col md={12} lg={7} className={'event__main'}>
            <>
              <div className={'event__inner event__top'}>
                {title && (
                  <SectionHeader
                    type={1}
                    size={'large'}
                    additionalClass={'event__heading'}
                    noLine
                  >
                    {ReactHtmlParser(title)}
                  </SectionHeader>
                )}
                {subtitle && (
                  <Heading
                    type={3}
                    size={'medium-plus'}
                    additionalClass={'event__subheading'}
                    noLine
                  >
                    {ReactHtmlParser(subtitle)}
                  </Heading>
                )}
                <div className={'event__info'}>
                  <div className={'event__info-col'}>
                    <div className={'event__info-content'}>
                      Date: <span>{date}</span>
                    </div>
                    <div className={'event__info-content'}>
                      Start: <span>{start}</span>
                    </div>
                  </div>
                  <div className={'event__info-col'}>
                    <div className={'event__info-content'}>
                      Series: <span>{series}</span>
                    </div>
                  </div>
                </div>
                {image && !isMobile && (
                  <div className={'event__image-wrapper'}>
                    <img
                      src={image}
                      className={'image event__image'}
                      alt={imageAlt || 'Event cover'}
                      loading={'lazy'}
                      style={{ opacity }}
                    />
                  </div>
                )}
              </div>

              <div className={'event__content event__inner'}>
                {isMobile && (
                  <div className={'event__button--mobile'}>
                    <SpyLink
                      to={'event__form--mobile'}
                      spy={false}
                      smooth
                      duration={500}
                      offset={-70}
                      className={'link link--centered'}
                      id={'event__form-spy-button--mobile'}
                    >
                      <Button
                        color={btnColor}
                        id={'event__form-button--mobile'}
                      >
                        {button}
                      </Button>
                    </SpyLink>
                  </div>
                )}
                {items && Array.isArray(items) && items.length && (
                  <div className={'event__list-container'}>
                    {itemsHeadings && (
                      <Heading
                        type={3}
                        size={'medium-plus'}
                        additionalClass={'event__subheading'}
                        noLine
                      >
                        {ReactHtmlParser(itemsHeadings)}
                      </Heading>
                    )}
                    <ul className={'event__list-items'}>
                      {items.map(item => (
                        <li className={'event__list-item'}>
                          <div>{ReactHtmlParser(item)}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className={'event__soon'}>
                <div className={'event__soon-wrapper'}>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                </div>
                <div className={'event__soon-wrapper'}>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                  <span>More info soon!</span>
                  <span>
                    <img
                      src={getCloudinaryUrl(
                        'event/more_info.png',
                        'f_auto,q_auto',
                      )}
                      alt={''}
                      loading={'lazy'}
                    />
                  </span>
                </div>
              </div>

              <div
                id={'event__form--mobile'}
                className={'event__sidebar event__sidebar--mobile'}
              >
                <div className={'event__inner'}>
                  <EventForm
                    {...{
                      btnColor,
                      button,
                      formHeading,
                      url,
                    }}
                  />
                </div>
              </div>
            </>
          </Col>
          <Col
            md={12}
            lg={5}
            className={'event__sidebar event__sidebar--desktop'}
          >
            <StickyBox offsetTop={120} style={{ zIndex: 100 }}>
              <div className={'event__inner'}>
                <EventForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    url,
                  }}
                />
              </div>
            </StickyBox>
          </Col>
        </Row>
      </>
    );
  }
}

EventContent.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageAlt: PropTypes.string,
  description: PropTypes.string,
  formHeading: PropTypes.string,
  intro: PropTypes.string,
  items: PropTypes.array,
  itemsQuestions: PropTypes.bool,
  lineColor: PropTypes.string,
  outro: PropTypes.string,
  itemsHeadings: PropTypes.string,
  title: PropTypes.string,
  trackingEvent: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  date: PropTypes.string,
  start: PropTypes.string,
  series: PropTypes.string,
  subtitle: PropTypes.string,
};

EventContent.defaultProps = {
  btnColor: '',
  button: '',
  image: null,
  imageAlt: '',
  description: '',
  formHeading: '',
  intro: '',
  items: [],
  itemsQuestions: false,
  lineColor: 'yellow',
  outro: '',
  itemsHeadings: '',
  title: '',
  date: '',
  start: '',
  series: '',
  subtitle: '',
};

export default EventContent;
