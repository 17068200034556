import React, { Component } from 'react';
import { Col } from 'react-flexbox-grid';
import { Heading, Link, Paragraph, IconSVG } from 'components';
import {
  getArticleSlug,
  estimateReadTime,
  getCloudinaryUrl,
} from 'config/helpers';
import './SinglePostTile.scss';
import { classNames } from 'utils';

class SinglePostTile extends Component {
  handleCategoryClick = (event, category) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.toggleFilter(category);
  };

  render() {
    const { post, authors, theme } = this.props;
    const { frontmatter } = post.node;
    const { title, cover, settings, author, content } = frontmatter;
    const authorData = authors.filter(
      edge => edge.node.frontmatter.title === author,
    );

    const articlePureContent = content
      .reduce((a, b) => a.body || a + b.body, '')
      .replace(/[^a-zA-Z ]/g, ' ');

    const readTime = estimateReadTime(articlePureContent);

    return (
      <Col sm={12} md={6} lg={4}>
        <Link to={getArticleSlug(post)}>
          <div
            className={classNames('single-post-tile', {
              [`single-post-tile--${theme}`]: theme,
            })}
          >
            <div className={'single-post-tile__top'}>
              <img
                src={getCloudinaryUrl(cover, 'f_auto,q_auto,h_320')}
                alt={title}
                className={'image'}
                loading={'lazy'}
              />
            </div>
            <div className={'single-post-tile__bottom'}>
              <div
                className={classNames('single-post-tile__info', {
                  'single-post-tile__info--black': theme === 'black',
                })}
              >
                <button
                  onClick={e => this.handleCategoryClick(e, settings.category)}
                  aria-label={'Settings category'}
                >
                  <Paragraph
                    size={'small'}
                    color={theme === 'black' ? 'black' : 'red'}
                    additionalClass={
                      'single-post-tile__category single-post-tile__category-badge text--bold'
                    }
                  >
                    {settings.category}
                  </Paragraph>
                </button>
                <Paragraph
                  size={'small'}
                  additionalClass={'single-post-tile__time'}
                >
                  {readTime} min read
                </Paragraph>
                <IconSVG name={'time'} color={'black'} />
              </div>
              <div className={'single-post-tile__content'}>
                <Heading type={3} size={'medium'}>
                  {title}
                </Heading>
                {authorData.length > 0 && (
                  <div className={'single-post-tile__author'}>
                    <img
                      src={getCloudinaryUrl(
                        authorData[0]?.node.frontmatter.photo,
                        'f_auto,q_auto,w_100',
                      )}
                      alt={`${authorData[0]?.node.frontmatter.title} avatar`}
                      loading={'lazy'}
                      className={'image'}
                    />
                    <Paragraph size={'small'}>
                      {authorData[0]?.node.frontmatter.title}
                      <br />
                      <span className={'text--light'}>
                        {authorData[0]?.node.frontmatter.job}
                      </span>
                    </Paragraph>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      </Col>
    );
  }
}

SinglePostTile.defaultProps = {
  theme: 'white',
};

export default SinglePostTile;
