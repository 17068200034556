import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section } from 'components';
import { ServiceTile } from 'views/fullProductDevelopmentCycle/subcomponents';

const OtherServices = () => (
  <Section additionalClass={'fpdc__service-tile'} fullwidth spaceless>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Full Product <br />Development Cycle'}
          description={
            'Boldare’s seasoned teams can assist at each stage of innovation. With experts on board who can advise on the best course of action, Boldare will guide you through your digital product journey.'
          }
          textColor={'white'}
          backgroundColor={'mine-shaft'}
          link={'/services/full-cycle-product-development/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Development <br />Teams'}
          description={
            'Boldare’s skilled development teams can help you reach your business goals and guide you through the Lean Startup approach.'
          }
          textColor={'white'}
          backgroundColor={'red'}
          link={'/services/development-teams/'}
        />
      </Col>
    </Row>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Product Design<br />and Development'}
          description={
            'Boldare’s experienced specialists will look after every aspect of your product, from design and development, to DevOps, tech stack consulting and usability testing.'
          }
          backgroundColor={'gray-light'}
          link={'/services/product-design-and-development/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Digital <br />Transformation'}
          description={
            'Whether you’re looking to disrupt your department or entire industry Boldare teams will show you how you can make it happen.'
          }
          backgroundColor={'pigeon-post'}
          link={'/services/digital-transformation/'}
        />
      </Col>
    </Row>
  </Section>
);

export default OtherServices;
