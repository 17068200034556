export const banner = {
  type: 'pre-recorded webinar',
  title: [`What after`, `code audit?`],
  subtitle: 'Business-centered approach for CTOs & CEOs',
  description: [
    'Make the most of a code audit for your business.',
    'Discover a pre-recorded webinar focusing on a business-centered approach to code audits. The webinar has been designed for CTOs and CEOs to maximize the results of a code audit for their business.',
    'The webinar has been divided into 4 separate recordings which focus on different aspects of a code audit. The topics are discussed by our experts and are based on real-life cases.',
  ],
  img: 'img/code-audit-web-cover.svg',
  speakers: [
    {
      avatar: 'img/webinar-code-audit/Ania_Landing_Avatar.jpg',
      position: 'Senior Software Developer',
      name: 'Anna Bobola',
    },
    {
      avatar: 'img/webinar-code-audit/Dawid_Landing_Avatar.jpg',
      position: 'Senior Software Developer',
      name: 'Dawid Yerginyan',
    },
    {
      avatar: 'img/webinar-code-audit/Rosiu_Landing_Avatar.jpg',
      position: 'Senior Software Developer',
      name: 'Mateusz Rosiek',
    },
  ],
};

export const firstSection = {
  id: '1',
  color: 'white',
  isBonus: false,
  isRelated: false,
  title: 'Sustainability is tricky',
  description: `Why does sustainability matter in the context of your application and what main areas do you need to consider?`,
  videoUrl: 'https://youtube.com/watch?v=avIp2k3j5io',
  pointsTitle: 'This video’s topics are:',
  points: [
    `<b>Security</b> - as an important part of any application.`,
    `<b>Scalability</b> - to be sure that your success will not turn into a failure.`,
    `<b>Usability</b> - to avoid your users losing confidence or enthusiasm.`,
    `<b>Maintainability</b> - to be able to add new features.`,
  ],
};

export const secondSection = {
  id: '2',
  color: 'carrara',
  isBonus: false,
  isRelated: false,
  isAnchor: true,
  title: 'Risk management',
  description: `How to prepare a well prioritized product backlog based on the audit results?`,
  videoUrl: 'https://youtube.com/watch?v=nSQAwsQsh5A',
  pointsTitle: 'This video’s topics are:',
  points: [
    `How to make raw estimations to gauge your (previously hidden) costs.`,
    `How to decide on what may bring the highest value for your business.`,
    `How to keep a balance during backlog prioritization.`,
  ],
};

export const thirdSection = {
  id: '3',
  color: 'white',
  isBonus: true,
  isRelated: false,
  videoUrl: 'https://www.youtube.com/watch?v=i4QLJNbbkOc',
  title: 'Dealing with technological debt',
  description: `Why is it important to keep your dependencies up to date and how to find the proper way of refactoring.`,
  pointsTitle: 'This video’s topics are:',
  points: [
    `Unsupported versions of libraries as a risk to security and maintainability`,
    `Automated tests and metrics that allow you to proceed smoothly`,
    `Refactoring in small steps, starting from the most changed parts of the application`,
  ],
};

export const fourthSection = {
  id: '4',
  color: 'carrara',
  isBonus: true,
  isRelated: true,
  videoUrl: 'https://www.youtube.com/watch?v=SszH9IcJ3ns',
  title: 'Maximizing code audit results - success stories',
  description: `Various “what next after a code audit” scenarios based on real-life case stories`,
  pointsTitle: 'This video’s topics are:',
  points: [
    `Practical outcomes from code audit of LMS platform for a customer from the USA`,
    `Practical outcomes from code audit of reporting system for a customer from the USA`,
    `Practical outcomes from code audit of web portal  and mobile application for a customer from Germany`,
  ],
  relatedArticle: [
    {
      type: 'ARTICLE',
      title: '6 business insights you should demand from a code and UX audit',
      lead: `A code audit is one of the tools that can be used to check the
      quality of your web or mobile application technology-wise. The goal is to
      carry out an objective assessment and unearth all code or UX problems that
      might be lurking within, including breaches of UX and programming
      conventions and security issues. However, not every code audit is the same
      and not every code audit will meet your needs. Some auditing companies
      focus only on technology, but completely ignore the business aspects. This
      article describes what you should be getting from a comprehensive code
      audit and how to know a great auditing service when you see it.`,
      id: 'article-6-insights-you-can-demand-from-code-and-UX-audit',
      link: '/blog/6-insights-you-can-demand-from-code-and-UX-audit/',
      photo: 'Code_and_UX_audit.png',
    },
    {
      type: 'ARTICLE',
      title: 'When should you carry out a code audit?',
      lead: `Auditing the code and UX of your app, website or any digital
      product might be crucial for your business. Strategically speaking, there
      are key stages in the development and life cycle of any digital product
      when auditing the code (and more widely) is more of a priority. This
      article identifies those key stages and tells you what you should be
      expecting from an audit by way of outcomes. Furthermore, you can discover
      why a code audit should offer significantly more than a simple code review
      of the product.`,
      id: 'article-when-should-you-carry-out-a-code-audit',
      link: '/blog/when-should-you-carry-out-a-code-audit/',
      photo: 'Code_and_UX_audit_-_when_do_you_need_to_conduct_it.png',
    },
  ],
  dedicated: {
    dedicatedTitle: 'Free Checklist',
    dedicatedDescription: '23 things to ask a code and UX audit company.',
    dedicatedButton: 'Get free checklist',
    dedicatedUrl: '/resources/code-audit-checklist',
  },
};
