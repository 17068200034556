import React, { Component } from 'react';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { OldCaseStudyBanner } from 'components';
import {
  App,
  Challenge,
  Comparison,
  Contact,
  Design,
  Intro,
  Models,
  Team,
  Technology,
  Tool,
  Users,
} from './subcomponents';
import './Showbiz.scss';

class Showbiz extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-case-study showbiz__container'}>
        <OldCaseStudyBanner
          image={getCloudinaryUrl(
            'assets/showbiz/showbiz_main_banner.jpg',
            'f_auto,q_auto',
          )}
          color={'white'}
          subtitle={'UI & UX Rebuilding'}
          title={
            'Italian model agency focused on providing models and hostesses'
          }
        />
        <Intro />
        <Challenge />
        <Comparison />
        <Design />
        <App />
        <Tool />
        <Users />
        <Models />
        <Team />
        <Technology />
        <Contact />
      </div>
    );
  }
}

export default Showbiz;
