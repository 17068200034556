import React from 'react';
import { Button, Link, Section, SectionHeader } from 'components';
import './Banner.scss';
import background from 'assets/videos/background_video_renewable_lp.mp4';

const Banner = () => (
  <div className={'banner__wrapper'}>
    <video
      controls={false}
      src={background}
      className={'banner__video-background'}
      autoPlay
      preload={'auto'}
      loop
      muted
      playsinline
    >
      <source src={background} type={'video/mp4'} />
    </video>
    <Section additionalClass={'banner__text'}>
      <SectionHeader
        color={'mine-shaft'}
        headingType={1}
        size={'large'}
        description={
          'With Agile DNA and Lean Startup in our bloodstream, we spend the client’s money only on what benefits their business'
        }
        lineColor={'mine-shaft'}
      >
        We help renewable <span className={'headline--marking'}>energy</span>{' '}
        companies to build the ROI-improving products their users want
      </SectionHeader>
      <Link to={'#renewable-energy-industry-contact-form'}>
        <Button color={'yellow'}>Book a free consultation</Button>
      </Link>
    </Section>
  </div>
);

export default Banner;
