import React from 'react';

import { TileSection } from 'components';
import { Banner, Resources } from './subcomponents';
import { FreeTool } from '../appCostsCalculator/subcomponents/Result/subcomponents/FreeTool';
import './AppCostsCalculatorThankYou.scss';

const AppCostsCalculatorThankYou = () => {
  return (
    <div className={'app-cost-thank-you'}>
      <Banner />
      <Resources />
      <TileSection />
      <FreeTool imagePath={'app-costs-calculator/tablet-prototype'} />
    </div>
  );
};

export default AppCostsCalculatorThankYou;
