import React from 'react';

import { Awards as AwardsContent  } from 'views/home/subcomponents/Prizes/subcomponents';
import { Header, Section } from '../../../../components/DS';
import { whoWeAre } from '../../utils';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    direction={'vertical'}
  />
);

const { awards: { title, description }} = whoWeAre;

export const Awards = () => (
  <Section
    heading={<SectionHeader title={title} description={description} />}
    color={'grey-50'}
  >
    <AwardsContent />
  </Section>
)
