import React, { Fragment } from 'react';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import LogoWhite from 'assets/images/brand/logo-boldare-products.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { SectionHeader, Heading, Paragraph, IconSVG } from 'components';

import './PortfolioContent.scss';

const containerVariants = {
  exit: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      stiffness: 100,
    },
  },
};

const imageVariants = {
  exit: {
    scale: 1.2,
    translateX: '-45%',
    translateY: '-50%',
  },
  show: {
    scale: 1.0,
    translateX: '-50%',
    translateY: '-50%',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const PortfolioContent = ({ items, id }) => {
  const [slide, setSlide] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const slideIndex = wrap(0, items.length, slide);
  const paginate = newDirection => {
    setSlide(slide + newDirection);
  };

  const handleModal = () => {
    setModalOpen(true);
    document.body.classList.add('no-scroll');
    document
      .getElementsByClassName('navbar-products__brand')[0]
      .classList.add('modal');
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove('no-scroll');
    document
      .getElementsByClassName('navbar-products__brand')[0]
      .classList.remove('modal');
  };

  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `products-portfolio-${item}`,
      });
    }
  };

  return (
    <div
      className={classnames('products-portfolio__content', 'glide', 'slider')}
      id={id}
    >
      <AnimatePresence initial={false}>
        {items
          .filter((item, index) => index === slideIndex)
          .map(item => (
            <Fragment key={`item-${item}`}>
              <motion.div
                initial={'exit'}
                animate={'show'}
                exit={'exit'}
                key={`name-${item}`}
                className={'products-portfolio__content-name'}
              >
                <Heading
                  color={'white'}
                  size={'large'}
                  additionalClass={item.type}
                >
                  <span
                    onClick={() => {
                      handleModal();
                      handleClick(item.type);
                    }}
                  >
                    {item.name}
                  </span>
                </Heading>
                <motion.div className={'slider-products__nav'}>
                  <button
                    className={
                      'glide__arrow glide__arrow--left slider-products-nav__arrow slider-products-nav__arrow--left'
                    }
                    data-glide-dir={'<'}
                    onClick={() => paginate(-1)}
                  >
                    <IconSVG
                      name={'arrow-left-light'}
                      color={'white'}
                      size={'medium'}
                    />
                  </button>
                  <button
                    onClick={() => {
                      handleModal();
                      handleClick(item.type);
                    }}
                    className={
                      'products-btn products-btn--primary products-btn--trans'
                    }
                  >
                    Read more
                  </button>
                  <button
                    className={
                      'glide__arrow glide__arrow--right slider-products-nav__arrow slider-products-nav__arrow--right'
                    }
                    data-glide-dir={'>'}
                    onClick={() => paginate(1)}
                  >
                    <IconSVG
                      name={'arrow-right-light'}
                      color={'white'}
                      size={'medium'}
                    />
                  </button>
                </motion.div>
                <div
                  className={
                    'products-portfolio__content-counter text--white hide-mobile'
                  }
                >
                  <span>{item.counter}</span> / 04
                </div>
                <a
                  className={
                    'link products-portfolio__content-link text--yellow text--light hide-mobile'
                  }
                  href={'/work/'}
                >
                  <span>See more of our projects</span>
                </a>
              </motion.div>
              <div className={'products-portfolio__content-img'}>
                <motion.img
                  variants={imageVariants}
                  initial={'exit'}
                  animate={'show'}
                  exit={'exit'}
                  key={item.img}
                  className={'products-portfolio__content-img'}
                  src={item.img}
                  alt={item.name}
                />
              </div>
              <motion.div
                variants={containerVariants}
                initial={'exit'}
                animate={'show'}
                exit={'exit'}
                key={item.name}
                className={'products-portfolio__content-description'}
              >
                <Paragraph color={'white'} additionalClass={'text--light'}>
                  <b
                    className={
                      'products-portfolio__content-description--heading'
                    }
                  >
                    Description:
                  </b>
                  {ReactHtmlParser(item.description)}
                  <button
                    onClick={() => {
                      handleModal();
                      handleClick(item.type);
                    }}
                    className={
                      'products-btn products-btn--primary products-btn--trans'
                    }
                  >
                    Read more
                  </button>
                </Paragraph>
              </motion.div>

              {isModalOpen && (
                <motion.div key={`modal-${item}`} className={'modal'}>
                  <div className={'navbar-products'}>
                    <header className={'navbar-products__brand'}>
                      <div className={'navbar-products__logos'}>
                        <a
                          href={'/'}
                          className={'navbar-products__logo-container'}
                          id={'navbar-products-logo'}
                        >
                          <img
                            src={LogoWhite}
                            className={
                              'image navbar-products__logo--img hide-mobile hide-tablet'
                            }
                            alt={'Digital product design company Boldare'}
                            loading={'lazy'}
                          />
                        </a>
                      </div>
                      <nav
                        id={'navMenu'}
                        className={'navbar-products__menu'}
                        aria-label={'main navigation'}
                      >
                        <button
                          className={'modal__close'}
                          onClick={() => closeModal()}
                        >
                          <IconSVG
                            name={'close'}
                            color={'white'}
                            size={'medium'}
                          />
                        </button>
                      </nav>
                    </header>
                  </div>
                  <div className={'modal__wrapper'}>
                    <div className={'modal__content'}>
                      <div
                        data-title={item.name}
                        className={
                          'section-header__headline-label section-header__headline-label--cornflower'
                        }
                      >
                        {item.name}
                      </div>
                      <SectionHeader
                        additionalClass={'section-about__heading'}
                        color={'mine-shaft'}
                        headingType={2}
                        size={'large'}
                        heading={'We think about our work'}
                        noLine
                      >
                        {item.caseStudy.title}
                      </SectionHeader>
                      <Paragraph
                        color={'black'}
                        size={'medium-plus-small'}
                        additionalClass={
                          'modal__content-description text--bold'
                        }
                      >
                        {item.caseStudy.description}
                      </Paragraph>
                      <div className={'modal__body'}>
                        {ReactHtmlParser(item.caseStudy.post)}
                      </div>

                      <motion.div className={'slider-products__nav'}>
                        <button
                          className={
                            'glide__arrow glide__arrow--left slider-products-nav__arrow slider-products-nav__arrow--left'
                          }
                          data-glide-dir={'<'}
                          onClick={() => paginate(-1)}
                        >
                          <IconSVG
                            name={'arrow-left-light'}
                            color={'white'}
                            size={'medium'}
                          />
                        </button>
                        <button
                          className={
                            'glide__arrow glide__arrow--right slider-products-nav__arrow slider-products-nav__arrow--right'
                          }
                          data-glide-dir={'>'}
                          onClick={() => paginate(1)}
                        >
                          <IconSVG
                            name={'arrow-right-light'}
                            color={'white'}
                            size={'medium'}
                          />
                        </button>
                      </motion.div>
                    </div>
                  </div>
                </motion.div>
              )}
            </Fragment>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default PortfolioContent;
