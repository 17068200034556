import React from 'react';
import { BackgroundImage, Heading } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { DesktopSlider, TextSlider } from './subcomponents';
import { SliderAsNav, TextInteractionAnimation } from '../index';
import { data } from './data';
import './TeammatesSection.scss';

const TeammatesSection = () => {
  return (
    <section className={'teammates-section'}>
      <BackgroundImage
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-product-design-development-company-teams.png',
          'f_auto,w_773',
        )}
        alt={'Working at Boldare'}
        additionalClass={'teammates-section__background-image'}
      />
      <Heading
        additionalClass={'teammates-section__heading'}
        type={2}
        size={'extra-large'}
      >
        <p>
          What our
          <br />
          teammates
          <br />
          <TextInteractionAnimation text={'say'} />
        </p>
      </Heading>
      {/* DesktopSlider is displayed on desktop and SliderAsNav on mobile. Due to GlideJS issues
      their visibility couldn't be easily conditioned here based on window width */}
      <DesktopSlider id={'teammates-section__desktop-slider'} items={data} />
      <SliderAsNav
        items={data}
        sliderAsNavId={'teammates-section__slider-as-nav'}
        sliderToBeNavigatedId={'teammates-section__slider-to-be-navigated'}
        sliderToBeNavigatedOptions={{
          type: 'carousel',
          animationDuration: 700,
          animationTimingFunc: 'ease-out',
          gap: 40,
          perView: 1,
          startAt: 0,
          focusAt: 0,
        }}
        componentToBeNavigated={<TextSlider items={data} />}
      />
    </section>
  );
};

export default TeammatesSection;
