import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, IconSVG } from 'components';
import './Perk.scss';

const Perk = ({ icon, title }) => (
  <div className={'perk__container'}>
    <div className={'perk__icon'}>
      <IconSVG name={icon} size={'big'} color={'transparent'} />
    </div>
    <Paragraph size={'medium'} additionalClass={'perk__text'}>
      <strong>{title}</strong>
    </Paragraph>
  </div>
);

Perk.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default Perk;
