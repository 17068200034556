import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Paragraph,
  Link,
  ContactForm,
  Heading,
  Button,
  SectionHeader,
} from 'components';
import './BlogContact.scss';

const BlogContact = () => (
  <Section spaceless fullwidth additionalClass={'blog-contact'}>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <Section
          color={'pigeon-post'}
          fullwidth
          additionalClass={'blog-contact__cta'}
        >
          <div className={'w-container--left'}>
            <Heading size={'big'} type={2}>
              Check out the concept of new work company
            </Heading>
            <Link to={'/blog/new-work/'}>
              <Button color={'black'} ariaName={'New work button'} transparent>
                New Work
              </Button>
            </Link>
          </div>
        </Section>
      </Col>
      <Col sm={12} md={6}>
        <Section
          color={'yellow'}
          fullwidth
          additionalClass={'blog-contact__form'}
        >
          <div className={'w-container--right'}>
            <SectionHeader lineColor={'black'} size={'medium'} fixed>
              Contact
            </SectionHeader>
            <>
              <Paragraph>
                <span className={'text--bold'}>
                  Tell us about your project
                </span>
                , we will get in touch soon!
              </Paragraph>
              <ContactForm
                name={'blog-contact'}
                pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x71'}
              />
            </>
          </div>
        </Section>
      </Col>
    </Row>
  </Section>
);

export default BlogContact;
