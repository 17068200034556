import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {
  FullwidthImage,
  Section,
  Heading,
  Paragraph,
  OldCaseStudyTile,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Intro.scss';

const Intro = () => {
  return (
    <>
      <Section additionalClass={'oneida__intro-container'}>
        <OldCaseStudyTile
          additionalClass={'oneida'}
          image={getCloudinaryUrl('logos/oneida_logo.png', 'f_auto,q_auto')}
          cover={getCloudinaryUrl('assets/oneida/pic-4.jpg', 'f_auto,q_auto')}
          description={'Wireframes, Moodboard, Graphic Design, Development'}
        />
        <div className={'oneida__content'}>
          <Heading type={2} size={'big'}>
            Modern and effective tool suited for specific needs.
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            Single page application is a great way to make modern and effective
            tool suited for specific needs. It was our first thought while
            designing a virtual tabletop application for our client.
          </Paragraph>
        </div>
      </Section>
      <Section additionalClass={'oneida__intro-image'}>
        <FullwidthImage
          image={getCloudinaryUrl('assets/oneida/pic-1.jpg', 'f_auto,q_auto')}
          alt={'Oneida since 1880'}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/oneida/transparent-1.png',
            'f_auto,q_auto',
          )}
          alt={'Desktop and mobile apps'}
          additionalClass={'oneida__intro-apps'}
        />
      </Section>
    </>
  );
};

export default Intro;
