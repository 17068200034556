import './index.scss';
import React, { useEffect, useState, useRef, useMemo } from 'react';

// Components
import Heading from './Heading';
import Testimonial from './Testimonial';
import ClientsSlider from './ClientsSlider';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow_right.svg';
import { ReactComponent as ArrowTopRight } from '../../assets/icons/arrow_top_right.svg';
import { ReactComponent as Clutch } from '../../assets/icons/clutch.svg';
import  { designThatTellStoriesClientLogo } from './Logos/index';

const Testimonials = () => {
  const sectionRef = useRef();
  const [offsetTop, setOffsetTop] = useState(9999999);
  const [windowHeight, setWindowHeight] = useState(9999999);

  // Testimonial 1
  const testimonial1End = useMemo(() => offsetTop, [offsetTop]);

  // Testimonial 2
  const testimonial2Show = useMemo(() => testimonial1End + windowHeight, [
    testimonial1End,
    windowHeight,
  ]);

  const testimonial2Start = useMemo(() => testimonial2Show + windowHeight, [
    testimonial2Show,
    windowHeight,
  ]);

  const testimonial2End = useMemo(() => testimonial2Start + windowHeight, [
    testimonial2Start,
    windowHeight,
  ]);

  // Testimonial 3
  const testimonial3Show = useMemo(() => testimonial2End + windowHeight, [
    testimonial2End,
    windowHeight,
  ]);

  const testimonial3Start = useMemo(() => testimonial3Show + windowHeight, [
    testimonial3Show,
    windowHeight,
  ]);

  const testimonial3End = useMemo(() => testimonial3Start + windowHeight, [
    testimonial3Start,
    windowHeight,
  ]);

  // Testimonial 4
  const testimonial4Show = useMemo(() => testimonial3End + windowHeight, [
    testimonial3End,
    windowHeight,
  ]);

  const testimonial4Start = useMemo(() => testimonial4Show + windowHeight, [
    testimonial4Show,
    windowHeight,
  ]);

  const testimonial4End = useMemo(() => testimonial4Start + windowHeight, [
    testimonial4Start,
    windowHeight,
  ]);

  useEffect(() => {
    setOffsetTop(sectionRef.current.offsetTop);
    setWindowHeight(Math.ceil(window.innerHeight / 2));
  }, []);

  const colorType = 'monochromatic';

  return (
    <>
      <div className={'dsgn__info-wrapper dsgn--full-screen'}>
        <Heading />
        <ClientsSlider logos={designThatTellStoriesClientLogo} colorType={colorType} withHeader={false}/>
      </div>
      <section
        ref={sectionRef}
        className={'dsgn__testimonials bldr-dsgn__section dsgn--full-screen'}
      >
        <div className={'dsgn__testimonials-wrapper'}>
          <Testimonial
            letterStyle={'dsgn__testimonial-quote--white'}
            companyCountry={'KSA'}
            companyName={'Unifonic'}
            author={'Ahmad Alhusainy'}
            authorPosition={'Director of Software Engineering'}
            quote={
              'They cared about our end customers, which is hard to find worldwide.'
            }
            animationShow={0}
            animationStart={undefined}
            backgroundColor={'#B2CBDE'}
            animationEnd={testimonial1End}
          />
          <Testimonial
            letterStyle={'dsgn__testimonial-quote--green'}
            companyCountry={'USA'}
            author={'Allan Wilson'}
            companyName={'TeamAlert'}
            authorPosition={'President'}
            quote={
              'I was really impressed with how much they cared about our product.'
            }
            animationShow={testimonial2Show}
            backgroundColor={'#FFFFFF'}
            animationStart={testimonial2Start}
            animationEnd={testimonial2End}
          />
          <Testimonial
            letterStyle={'dsgn__testimonial-quote--yellow-light'}
            companyCountry={'KSA'}
            author={'Abdulaziz Ababtain'}
            companyName={'Takamol Holding'}
            authorPosition={'Business Development'}
            quote={'I have never known a vendor as helpful as they are.'}
            backgroundColor={'#5ACBBC'}
            animationShow={testimonial3Show}
            animationStart={testimonial3Start}
            animationEnd={testimonial3End}
          />
          <Testimonial
            quote={
              'They don’t just build a product; their team adds value throughout the whole process.'
            }
            letterStyle={'dsgn__testimonial-quote--yellow'}
            authorPosition={'CEO'}
            companyCountry={'USA'}
            author={'Randy Carpenter'}
            backgroundColor={'#F1F1F1'}
            companyName={'Interstate Data'}
            animationShow={testimonial4Show}
            animationStart={testimonial4Start}
            animationEnd={testimonial4End}
          />
        </div>
      </section>
      <div className={'bldr-dsgn__section'}>
        <div className={'dsgn-cases-seq__more'} style={{ marginTop: '0' }}>
          <div className={'main'}>
            <a
              href={'https://clutch.co/profile/boldare#reviews'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              Hear what our partners have to say <ArrowRight />
            </a>
          </div>
          <div className={'double'}>
            <div className={'secondary clutch-btn'}>
              <a
                href={'https://clutch.co/profile/boldare'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <span>
                  <Clutch />
                </span>
                <span>
                  Read more on Clutch <ArrowTopRight />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
