import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Work from 'views/work';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

function getArticleContent(article, allArticles) {
  const articleNode = allArticles.find(({ node }) => {
    return node.frontmatter.settings.slug === article;
  });

  if (articleNode) {
    const {
      content: {
        title: boxTitle,
        tileDescription,
        coverImage: contentCoverImage,
      },
      coverImage,
    } = articleNode.node.frontmatter.box;

    const { title, lead, cover } = articleNode.node.frontmatter;

    return {
      title: boxTitle || title,
      description: tileDescription || lead,
      coverImage: contentCoverImage || coverImage || cover,
    };
  }

  const page = pages.work.items[article];

  if (page) {
    const { metaTitle, siteContent, siteCover } = page;

    return {
      title: metaTitle,
      description: siteContent,
      coverImage: siteCover,
    };
  }
}

function mapCaseStudy(caseStudy, allArticles) {
  const {
    node: { frontmatter },
  } = caseStudy;

  const {
    type,
    pinned,
    priority,
    article,
    articleURL,
    product_stage: productStage,
    ...content
  } = frontmatter;

  const link = articleURL ?? `/work/${article}`;

  const articleContent = getArticleContent(article, allArticles);

  if (articleContent) {
    const { coverImage, description, title } = articleContent;

    return {
      type,
      pinned,
      priority,
      content: {
        ...content,
        title,
        description,
        coverImage,
        productStage,
      },
      settings: {
        link,
      },
      id: link,
    };
  }

  return null;
}

class WorkPage extends Component {
  render() {
    const page = pages.work;
    const { navColors } = page;
    const url = `${config.siteUrl}/work/`;
    const canonical = `${url}`;

    const { posts, authors, caseStudies } = this.props.data;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <PopupRedirect message={'work'} />
        <Work
          allArticles={posts.edges}
          authors={authors.edges}
          caseStudies={caseStudies.edges
            .map(cs => mapCaseStudy(cs, posts.edges))
            .filter(Boolean)}
        />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query WorkPage {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { in: ["article-page", "case-study-page"] }
        }
      }
    ) {
      ...markdownFields
    }

    authors: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "author-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    caseStudies: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "works->case-study" } } }
    ) {
      edges {
        node {
          frontmatter {
            type
            title
            article
            articleURL
            pinned
            priority
            country
            clientName
            clientLogo
            industry
            cs_service
            tags
            product_stage
            region
          }
        }
      }
    }
  }
`;

export default WorkPage;
