export const logos = [
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/java-logo.svg',
    title: 'Java',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680991/design-rush/technology-logos/spring-logo.svg',
    title: 'Spring',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/php-logo.svg',
    title: 'PHP',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/kubernetes-logo.svg',
    title: 'Kubernetes',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/dotnet-logo.svg',
    title: '.Net',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/python-logo.svg',
    title: 'Python',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680990/design-rush/technology-logos/django-logo.svg',
    title: 'Django',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/javascript-logo.svg',
    title: 'JavaScript',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/typescript-logo.svg',
    title: 'TypeScript',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/vue-logo.svg',
    title: 'Vue.js',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/angular-logo.svg',
    title: 'Angular',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680989/design-rush/technology-logos/react-logo.svg',
    title: 'React',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680988/design-rush/technology-logos/node-logo.svg',
    title: 'Node.js',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680988/design-rush/technology-logos/nest-logo.svg',
    title: 'Nest.js',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/android-logo.svg',
    title: 'Android',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/react-native-logo.svg',
    title: 'React Native',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/ios-logo.svg',
    title: 'iOS',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680987/design-rush/technology-logos/terraform-logo.svg',
    title: 'Terraform',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/aws-logo.svg',
    title: 'AWS',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/firebase-logo.svg',
    title: 'Firebase',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/symfony-logo.svg',
    title: 'Symfony',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/marble-logo.svg',
    title: 'Marble.js',
  },
  {
    link:
      'https://res.cloudinary.com/de4rvmslk/image/upload/v1675680986/design-rush/technology-logos/docker-logo.svg',
    title: 'Docker',
  },
];
