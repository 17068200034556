import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import './CountryDropdown.scss';

const CountryDropdown = ({ data, imageScrollRef }) => {
  const [expandedCountry, setExpandedCountry] = useState(data[0].country);
  const [selectedCityImage, setSelectedCityImage] = useState(data[0]);
  const [addressesVisible, setAddressesVisible] = useState('visible');
  const [imageClass, setImageClass] = useState('visible');

  const renderPlaces = country => {
    const cities = data.filter(item => item.country === country);

    return cities.map(item => (
      <div
        role={'button'}
        tabIndex={'0'}
        key={item.title}
        className={`contact-offices__info-place ${addressesVisible}`}
        onClick={() => setSelectedCityImage(item)}
        onKeyDown={() => setSelectedCityImage(item)}
      >
        <p className={`contact-offices__info-place--hq text--bold`}>
          {item.title}
        </p>
        <p className={'contact-offices__info-address'}>
          <a href={item.map} target={'_blank'} rel={'noopener noreferrer'}>
            <span
              id={`contact-offices-map-${kebabCase(item.city.toLowerCase())}`}
              className={'text--white'}
            >
              {item.street}
              <br />
            </span>
          </a>
          {item.postal}&nbsp;{item.city}
          <br />
          {item.country}
        </p>
      </div>
    ));
  };

  const handleCountryClick = country => {
    if (imageScrollRef.current) {
      imageScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const firstCity = data.find(item => item.country === country);
    if (firstCity) {
      setImageClass('hidden');
      setTimeout(() => {
        setSelectedCityImage(firstCity);
        setImageClass('visible');
      }, 300);
    }

    if (expandedCountry === country) {
      setExpandedCountry(null);
    } else {
      setExpandedCountry(country);
      setSelectedCityImage(data.find(item => item.country === country));
    }

    setAddressesVisible('hidden');
    setTimeout(() => {
      setAddressesVisible('visible');
    }, 300);
  };

  const uniqueCountries = [...new Set(data.map(item => item.country))];

  return (
    <div className={'country-dropdown'}>
      <div className={`image-container ${imageClass}`}>
        <img src={selectedCityImage.image} alt={`${selectedCityImage.city}`} />
      </div>
      <div className={'country-buttons'}>
        {uniqueCountries.map(country => (
          <div key={country}>
            <button
              onClick={() => handleCountryClick(country)}
              className={expandedCountry === country ? 'active' : ''}
            >
              {country}
            </button>
            {expandedCountry === country && (
              <div
                className={`country-places ${
                  expandedCountry ? 'slide-down' : ''
                }`}
              >
                {renderPlaces(country)}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryDropdown;

CountryDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  imageScrollRef: PropTypes.object.isRequired,
};
