import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { IconSVG } from 'components';
import { classNames } from 'utils';
import './UnorderedList.scss';

class UnorderedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.showCount,
    };
  }

  expandList = () => {
    this.setState({ limit: 100 });
  };

  renderLimitedList = () => {
    const { limit } = this.state;
    const { items, additionalClass } = this.props;

    return items
      .filter((item, index) => index < limit)
      .map((item, index) => (
        <li className={classNames(additionalClass)} key={`list-item-${index}`}>
          {item}
        </li>
      ));
  };

  render() {
    const { limit } = this.state;
    const {
      additionalClass,
      containerClass,
      items,
      columns,
      icon,
      iconColor,
      listStyleDisc,
    } = this.props;

    return (
      <ul
        className={classNames(
          'unordered-list',
          {
            [`unordered-list--column-${columns}`]: columns,
            [`unordered-list--disc`]: listStyleDisc,
          },
          containerClass,
        )}
        onMouseEnter={this.expandList}
      >
        {items.map((item, index) => (
          <li
            className={classNames(additionalClass, {
              hidden: index > limit,
            })}
            key={`list-item-${index}`}
          >
            {icon ? (
              <>
                <IconSVG
                  name={item[1]}
                  size={'small-plus'}
                  color={iconColor}
                />
                {ReactHtmlParser(item[0])}
              </>
            ) : (
              ReactHtmlParser(item)
            )}
          </li>
        ))}
      </ul>
    );
  }
}

UnorderedList.defaultProps = {
  additionalClass: '',
  containerClass: '',
  items: null,
  columns: 1,
  showCount: 100,
  icon: false,
  iconColor: 'black',
  listStyleDisc: true,
};

UnorderedList.propTypes = {
  additionalClass: PropTypes.string,
  containerClass: PropTypes.string,
  columns: PropTypes.number,
  items: PropTypes.array,
  showCount: PropTypes.number,
  icon: PropTypes.bool,
  iconColor: PropTypes.string,
  listStyleDisc: PropTypes.bool,
};

export default UnorderedList;
