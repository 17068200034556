import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { data } from './logic.js';
import './Team.scss';

class Team extends Component {
  renderBoxes = () =>
    data.map((item, index) => (
      <div className={'pt__team-box'} key={item.image}>
        <div className={'pt__team-text'}>
          <Heading type={3} size={'medium-plus'}>
            {item.title}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            {item.text}
          </Paragraph>
        </div>
        <div className={'pt__team-image'}>
          <span className={'pt__team-image--number'}>{index + 1}</span>
        </div>
      </div>
    ));

  render() {
    return (
      <>
        <Section additionalClass={'pt__team-container'} spaceless>
          <div className={'pt__team-container--content'}>
            <Heading type={2} size={'large'}>
              From a new website to a&nbsp;long-term, dedicated remote team
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              After going live, our customer was satisfied enough to think
              about the next steps for product evolution and wanted to include
              Boldare in those plans. That is how we started our work on
              improving the app itself and not only that, many new features
              were added...
            </Paragraph>
          </div>
        </Section>
        <Section additionalClass={'pt__team-images'} spaceless>
          {this.renderBoxes()}
        </Section>
      </>
    );
  }
}

export default Team;
