import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import {
  SectionHeader,
  Heading,
  Paragraph,
  Section,
  IconSVG,
  HashtagList,
} from 'components';
import './Technologies.scss';

const Technologies = () => (
  <Section additionalClass={'sdo__technology'} color={'mine-shaft'}>
    <Row className={'no-gap'}>
      <Col sm={12} md={6} className={'sdo__technology-image'}>
        <img
          src={getCloudinaryUrl(
            'services/software-development-outsourcing/technologies-team.jpg',
            'f_auto,q_auto',
          )}
          className={'image sdo__technology-img'}
          alt={'The technologies we are using'}
          loading={'lazy'}
        />
      </Col>
      <Col sm={12} md={6}>
        <SectionHeader
          size={'big'}
          lineColor={'red'}
          headingType={2}
          color={'white'}
          description={
            'The dedicated teams that you can hire are comfortable working with both frontend and backend technologies and meet the highest standards of the development process. Teams consist of first-class developers providing top-notch products to highly efficient timescales. We’ve built dedicated software for a variety of business industries including finance, energy, e-commerce, pharmaceutical, hosting, automotive, television, marketing, education, publishing, power tools, and dinnerware.'
          }
          descriptionColor={'white'}
        >
          The technologies
          <br />
          we are using
        </SectionHeader>
      </Col>
    </Row>
    <div className={'w-container sdo__technology-technologies'}>
      <Row className={'no-gap sdo__technology-items'}>
        <Col sm={12} md={6} className={'sdo__technology-item'}>
          <IconSVG name={'network'} size={'large'} color={'white'} />
          <Heading color={'white'} size={'medium'} type={3}>
            Backend
          </Heading>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'text--light'}
          >
            You can hire a team to develop the backend of your application or
            API which will support the frontend interfaces of your app or
            website regarding content management. It will also help to
            integrate your application with third party systems such as
            two-factor authentication, or payment gateways.
          </Paragraph>
          <HashtagList
            color={'white'}
            data={['PHP', 'JAVA', 'JavaScript', 'Symfony', 'NodeJS', 'Python']}
            tagSize={'medium'}
            tagClass={'sdo__technology-tag text--medium'}
          />
        </Col>
        <Col sm={12} md={6} className={'sdo__technology-item'}>
          <IconSVG name={'code'} size={'large'} color={'white'} />
          <Heading color={'white'} size={'medium'} type={3}>
            Frontend
          </Heading>
          <Paragraph
            color={'white'}
            size={'medium'}
            additionalClass={'text--light'}
          >
            Thanks to the synergy between the Front-End Developers and Graphic
            Designers our teams design and develop dedicated responsive web
            apps that work across devices. Our unique approach not only works
            but also has been recognized in the form of design & frontend
            awards, which is tremendously motivating.
          </Paragraph>
          <HashtagList
            color={'white'}
            data={['JavaScript', 'Angular JS', 'React JS']}
            tagSize={'medium'}
            tagClass={'sdo__technology-tag text--medium'}
          />
        </Col>
      </Row>
    </div>
  </Section>
);

export default Technologies;
