import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Box, Heading, Paragraph } from 'components';
import './OurOffer.scss';
import { data } from './logic.js';

const handleClick = item => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: item,
    });
  }
};

const renderBullets = bullets =>
  bullets.map(item => {
    return (
      <li className={'our-offer__bullets-item text--white'}>
        <a href={item.link} onClick={() => handleClick(item.name)}>
          {item.name}
        </a>
      </li>
    );
  });

const renderOffers = () =>
  data.map(item => {
    const { order, title, id, bullets } = item;

    return (
      <div className={'our-offer__box'} key={order}>
        <Box
          key={`offer-${id}`}
          backgroundColor={'transparent'}
          additionalClass={'anim-fade-in-bottom'}
        >
          <div className={'our-offer__item'}>
            <div className={'our-offer__tile'}>
              <div className={'our-offer__info'}>
                <Heading
                  type={3}
                  size={'big'}
                  color={'white'}
                  additionalClass={'our-offer__headline'}
                >
                  {ReactHtmlParser(title)}
                </Heading>
                <Paragraph
                  color={'white'}
                  additionalClass={'our-offer__number'}
                  size={'medium-plus-small'}
                >
                  {order}
                </Paragraph>
              </div>
              <ul
                className={
                  'unordered-list unordered-list--column-1 unordered-list--disc our-offer__bullets'
                }
              >
                {renderBullets(bullets)}
              </ul>
            </div>
          </div>
        </Box>
      </div>
    );
  });

const OurOffer = () => (
  <div className={'our-offer__container'}>
    {renderOffers()}{' '}
    <a
      className={'link footer__top-link'}
      id={'footer-career'}
      href={'/services/'}
    >
      <button
        type={'button'}
        className={'button button--primary button--transparent-yellow'}
        aria-label={'button'}
      >
        View our Services →
      </button>
    </a>
  </div>
);

export default OurOffer;
