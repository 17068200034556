export const data = [
  {
    title: 'We have launched our Service Standards',
    blog:
      '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/',
  },
  {
    title: 'DevCamp returns at full speed!',
    link: '/blog/devcamp-2023/',
  },
  {
    title: 'Boldare’s co-CEO rocks the stage at Impact’23',
    link: '/blog/anna-zarudzka-at-impact/',
  },
  {
    title: 'Boldare awarded with #TechForGood 2022 Badge',
    link: '/blog/tttr-badges-2022/',
  },
  {
    title:
      'We won the grand prize in the Puls Biznesu “Siła Przyciągania” contest!',
    link: '/blog/puls-biznesu-grand-prize/',
  },
  {
    title: 'We’ve established the Boldare Foundation',
    link: '/blog/boldare-foundation/',
  },
  {
    title:
      'We’ve hosted a live meetup in Amsterdam to share our experience with product-market fit',
    link: '/blog/live-meetup-in-amsterdam-about-product-market-fit/ ',
  },
  {
    title: 'Meet DevCamp: a development-oriented festival born at Boldare',
    link: '/blog/devcamp-festival/',
  },
  {
    title: 'Boldare’s new office in Hamburg',
    link: '/blog/new-office-in-hamburg/',
  },
  {
    title: 'Boldare goes to the Netherlands',
    link: '/blog/office-in-amsterdam/',
  },
  // @TODO Enable tech-radar
  // {
  //   title: 'Boldare’s Tech Radar is live',
  //   link: '/blog/boldare-launches-tech-radar/',
  // },
  {
    title: 'Boldare stands with Ukraine',
    link: '/blog/boldare-stands-with-ukraine/ ',
  },
  {
    title: 'Good things come in pairs - so do awards for our products!',
    link: '/blog/new-design-awards-for-boldare/',
  },
  {
    title: 'Clutch names Boldare as Leading Web Development Firm for 2021',
    link: '/blog/we-are-leading-web-development-firm-for-2021/',
  },
  {
    title: 'We’ve been cited as an Honoree in the 2021 Webby Awards!',
    link: '/blog/2021-webby-honoree-award-for-boldare/',
  },
  {
    title: 'Boldare honoured with a NextGen Enterprise Award!',
    link: '/blog/boldare-won-nextgen-enterprise-award/',
  },
  {
    title: 'We won Gold in the Lovie Awards!',
    link: '/blog/we-won-gold-in-the-lovie-awards/',
  },
];
