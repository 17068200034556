import React from 'react';

import { Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { Header, Section } from '../../../../components/DS';

import './Benefits.scss';

const SectionHeader = ({ title, description }) => (
  <Header
    title={title}
    description={description}
    theme={'light'}
    longDescription
  />
);

export const Benefits = ({
  data: { title, description, benefitsItems }
}) => {
  return (
    <div className={'keyword-page-benefits'}>
      <Section
        heading={<SectionHeader title={title} description={description} />}
        color={'mine-shaft'}
      >
        <div className={'keyword-page-benefits__content'}>
          {benefitsItems.map(({ item }, index) => (
            <div
              id={`section-${item.title}`}
              className={'keyword-page-benefits-step'}
            >
              <div className={'keyword-page-benefits-step-copy'}>
                <div className={'keyword-page-benefits-step-copy__heading'}>
                  <span className={'keyword-page-benefits-step__counter'}>
                    {index + 1}
                  </span>
                  <Heading
                    additionalClass={'keyword-page-benefits-step__title'}
                    type={2}
                  >
                    {item.title}
                  </Heading>
                </div>
                <Paragraph
                  additionalClass={'keyword-page-benefits-step__description'}
                >
                  {item.description}
                </Paragraph>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <div className={'keyword-page-benefits__content-image'}>
        <img
          src={getCloudinaryUrl(
            'services/seo-pages/seo_benefits.png',
            'f_auto,q_auto',
          )}
          alt={'benefits'}
          loading={'lazy'}
        />
      </div>
    </div>
  );
};
