import React from 'react';
import PropTypes from 'prop-types';

import flags from './logic';
import './CountryFlag.scss';

const CountryFlag = ({ country }) => (
  <div className={'work-boxes-flag'}>
    <img
      src={flags[`${country.replace(/\s+/g, '-').toLowerCase()}`]}
      alt={`${country} flag`}
      className={'work-boxes-flag__image'}
    />
  </div>
);

CountryFlag.defaultProps = {
  country: 'france',
};

CountryFlag.propTypes = {
  country: PropTypes.string,
};

export default CountryFlag;
