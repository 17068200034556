import React from 'react';
import {
  Container,
  Heading,
  Section,
  Paragraph,
  Link,
  IconSVG,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Clutch.scss';

const getStars = () => {
  const content = [];
  for (let i = 0; i < 4; i++) {
    content.push(
      <svg
        xmlns={'http://www.w3.org/2000/svg'}
        width={'25'}
        height={'24'}
        viewBox={'0 0 25 24'}
        fill={'none'}
      >
        <g clipPath={'url(#clip0_5404_5384)'}>
          <path
            d={
              'M12.7148 1L15.8677 8.66043L24.1275 9.2918L17.8163 14.6576L19.7683 22.7082L12.7148 18.364L5.66142 22.7082L7.61338 14.6576L1.30217 9.2918L9.56196 8.66043L12.7148 1Z'
            }
            fill={'#F2DA3A'}
          />
        </g>
        <defs>
          <clipPath id={'clip0_5404_5384'}>
            <rect
              width={'24'}
              height={'24'}
              fill={'white'}
              transform={'translate(0.714844)'}
            />
          </clipPath>
        </defs>
      </svg>,
    );
  }

  content.push(
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      width={'25'}
      height={'24'}
      viewBox={'0 0 25 24'}
      fill={'none'}
    >
      <g clipPath={'url(#clip0_5404_5392)'}>
        <path
          d={
            'M12.7148 2.3137L15.4054 8.85073L15.5226 9.13551L15.8296 9.15898L22.8781 9.69775L17.4924 14.2766L17.2578 14.4761L17.3304 14.7754L18.9961 21.6454L12.9771 17.9383L12.7148 17.7768L12.4526 17.9383L6.4336 21.6454L8.0993 14.7754L8.17186 14.4761L7.93724 14.2766L2.55157 9.69775L9.60007 9.15898L9.90713 9.13551L10.0243 8.85073L12.7148 2.3137Z'
          }
          stroke={'#F2DA3A'}
        />
        <path
          d={
            'M12.7135 1L15.8663 8.66043L17.568 8.90648L18.7148 9.2918V11.5V13.5L17.568 14.9065L18.7148 21L12.7135 18.364L5.66004 22.7082L7.61199 14.6576L1.30078 9.2918L9.56058 8.66043L12.7135 1Z'
          }
          fill={'#F2DA3A'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_5404_5392'}>
          <rect
            width={'24'}
            height={'24'}
            fill={'white'}
            transform={'translate(0.714844)'}
          />
        </clipPath>
      </defs>
    </svg>,
  );
  return content;
};

const Clutch = () => {
  return (
    <Section additionalClass={'clutch-section'}>
      <Heading size={'large'}>Curious about our reliability?</Heading>
      <Container additionalClass={'clutch-section__container'}>
        <div className={'clutch-section__container--logo'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/clutch-logo.png',
              'f_auto,q_auto,w_auto',
            )}
            alt={'clutch-logo'}
          />
        </div>
        <div>
          <Paragraph
            size={'medium-plus-small'}
            color={'white'}
            additionalClass={'clutch-section__container--text'}
          >
            53 Clutch reviews from verified clients worldwide
          </Paragraph>
          <Paragraph
            size={'medium-plus-small'}
            color={'white'}
            additionalClass={'clutch-section__container--text'}
          >
            <span>4.8</span> average referral rating
            <div>{getStars()}</div>
          </Paragraph>
          <Paragraph
            size={'medium'}
            color={'white'}
            additionalClass={'clutch-link'}
          >
            <Link
              to={'https://clutch.co/profile/boldare'}
              arrow
              id={'ai-clutch-section-link'}
            >
              Read our outstanding reviews on Clutch
              <IconSVG name={'arrow-right'} color={'white'} />
            </Link>
          </Paragraph>
        </div>
        <div className={'clutch-section__container--image'}>
          <img
            src={getCloudinaryUrl(
              'assets/ai-software/clutch-badges.png',
              'f_auto,q_auto,w_auto',
            )}
            alt={'clutch-badges'}
          />
        </div>
      </Container>
      <Paragraph size={'medium'} color={'white'}>
        Our client retention rate says it all: 80% choose to work with us again
        on their new digital projects.
      </Paragraph>
    </Section>
  );
};

export default Clutch;
