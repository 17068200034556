import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoaderComponent from 'components/Loader';
import { enableAnimations } from 'config/helpers';
import {
  Awards,
  ContactFormServices,
  Section,
  SectionHeader,
} from 'components';

import { Banner, CaseStudies, Clients } from './subcomponents';
import './Work.scss';

const Work = ({ allArticles, authors, caseStudies }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  // Gatsby loads one version from A/B as default, loader prevents site from jumping.
  const [loading, setLoading] = useState(true);
  // It pretends to load while loading filters
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  // Forces rerendering of CSS classes when changing A / B version

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 1199);
  };

  useEffect(() => {
    handleResize();
    setLoading(false);
  }, []);

  useEffect(() => {
    enableAnimations();
  }, []);

  const onActiveFilterChange = async newFilter => {
    setIsFakeLoading(true);
    setActiveFilter(newFilter);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 400);
  };

  return (
    <div className={'single-page single-page--hero work-page'}>
      {loading ? (
        <div className={'work-page__loader'}>
          <LoaderComponent isActive absolute />
        </div>
      ) : (
        <>
          <Banner
            activeFilter={activeFilter}
            isVariantB
            isDesktop={isDesktop}
            key={`${loading}-header-b`}
          />
          <CaseStudies
            allArticles={allArticles}
            caseStudies={caseStudies}
            authors={authors}
            onActiveFilterChange={onActiveFilterChange}
            isVariantB
            activeFilter={activeFilter}
            isFakeLoading={isFakeLoading}
            isDesktop={isDesktop}
            key={`${loading}-studies-b`}
          />
        </>
      )}
      <Clients />
      <Section>
        <SectionHeader lineColor={'violet'}>Our awards</SectionHeader>
        <Awards />
      </Section>
      <ContactFormServices
        id={'form-work'}
        name={'contact-form-biz'}
        pardotLink={'https://go.boldare.com/l/688533/2020-05-14/6cxbp'}
      />
    </div>
  );
};

Work.defaultProps = {
  allArticles: [],
  authors: [],
  caseStudies: [],
};

Work.propTypes = {
  allArticles: PropTypes.array,
  authors: PropTypes.array,
  caseStudies: PropTypes.array,
};

export default Work;
