import { Col } from 'react-flexbox-grid';
import { Paragraph } from 'components';
import React from 'react';
import './Numbers.scss';
import { numbersConfiguration } from './utils';

const ListOfNumbers = () =>
  numbersConfiguration.map(({ number, description }) => (
    <Col
      className={'digital-transform-journey__col-number'}
      sm={12}
      md={5}
      xl={2}
    >
      <Paragraph
        color={'white'}
        size={'big '}
        additionalClass={'digital-transform-journey__number-header'}
      >
        {number}
      </Paragraph>
      <Paragraph
        color={'white'}
        size={'medium'}
        additionalClass={'digital-transform-journey__text-description'}
      >
        {description}
      </Paragraph>
    </Col>
  ));

export default ListOfNumbers;
