import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './ButtonGroup.scss';

const ButtonGroup = ({ additionalClass, children }) => (
  <div className={classNames('button-group', additionalClass)}>{children}</div>
);

ButtonGroup.defaultProps = {
  additionalClass: '',
};

ButtonGroup.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonGroup;
