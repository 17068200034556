import React from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox } from 'components';
import { classNames } from 'utils';
import { usePardotForm } from '../../../hooks/index';

import './SignUpForm.scss';

export const SignUpForm = ({ variant }) => {
  const formRef = React.useRef();
  const [email, setEmail] = React.useState(''); // @TODO find a way to handle filled email label
  const [isOptedIn, setIsOptedIn] = React.useState(false); // @TODO find a way to handle filled checkbox

  const { handleSubmit, isLoading, succeeded, failed } = usePardotForm(
    formRef,
    'https://go.boldare.com/l/688533/2022-12-06/2f65lf'
  );

  const color = (variant === 'newsletter-a') ? 'yellow' : 'red';

  return (
    <div className={`box box-article sign-up-form sign-up-form--${color}`}>
      {!succeeded ? (
        <>
          <div className={'sign-up-form__text'}>
            <p className={'sign-up-form__heading'}>
              Sign up now!
            </p>
            <p className={'sign-up-form__description'}>
              And get best articles directly to your email
            </p>
          </div>
          <form
            className={'sign-up-form__content'}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className={'sign-up-form__inputs'}>
              <div className={'sign-up-form__input-container'}>
                <input
                  className={'sign-up-form__input'}
                  id={'sign-up-form-name'}
                  type={'text'}
                  name={'firstName'}
                  required
                />
                <label
                  className={'sign-up-form__label'}
                  htmlFor={'sign-up-form-name'}
                >
                  First name
                </label>
              </div>
              <div className={'sign-up-form__input-container'}>
                <input
                  id={'sign-up-form-email'}
                  className={classNames('sign-up-form__input', {
                    'sign-up-form__input-filled': email.length
                  })}
                  type={'email'}
                  name={'email'}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <label
                  className={'sign-up-form__label'}
                  htmlFor={'sign-up-form-email'}
                >
                  E-mail address
                </label>
              </div>
              <Checkbox
                additionalClass={'sign-up-form__checkbox'}
                checked={isOptedIn}
                label={'I agree to receive communications from Boldare. Communications may include updates on upcoming events, useful business insights and promotional materials about our services.'}
                name={'optedForMarketing'}
                value={'optedForMarketing'}
                onChange={() => setIsOptedIn(!isOptedIn)}
                required
              />
            </div>
            {!isLoading ? (
              <Button
                additionalClass={'sign-up-form__button ga-sign-up-form-button'}
                id={`sign-up-form-button-${variant}-${color}`}
                type={'submit'}
                color={'white'}
                ariaName={'Newsletter sign up'}
              >
                Get a newsletter
              </Button>
            ) : (
              <div className={'sign-up-form__loader'} />
            )}
          </form>
        </>
      ) : (
        <p className={'sign-up-form__greetings'}>Thank you for sign up!</p>
      )}
    </div>
  )
};

SignUpForm.propTypes = {
  variant: PropTypes.string.isRequired,
};
