import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Heading, SectionHeader, UnorderedList } from 'components';
import './Results.scss';

const Results = () => (
  <Section color={'pigeon-post'} additionalClass={'sysdt__results-section'}>
    <Row className={'no-gap anim-fade-in-bottom'}>
      <Col sm={12} md={6}>
        <SectionHeader
          size={'big'}
          color={'black'}
          lineColor={'white'}
          headingType={2}
        >
          Results
        </SectionHeader>
      </Col>
      <Col sm={12} md={6}>
        <div className={'sysdt__results-description'}>
          <Heading color={'black'} size={'medium-plus'} type={3}>
            Systemic Digital Transformation encodes digitalization into the
            company's DNA:
          </Heading>
          <UnorderedList
            additionalClass={'text--black text--light'}
            items={[
              'It decreases the time-to-market ratio.',
              'It creates agile-powered processes that increase overall efficiency and openness to change.',
              'It enhances digital culture and keeps the knowledge inside the organization evenly spread across departments: from IT to business.',
              'It lets you processes the digital shift without losing your focus on growth.',
            ]}
          />
        </div>
      </Col>
    </Row>
  </Section>
);

Results.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Results;
