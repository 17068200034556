export const dataOpportunities = [
  {
    id: 1,
    icon: 'career-ind-car',
    title: 'Individual career path',
    text: 'You get our support in the development endeavours of your choice',
  },
  {
    id: 2,
    icon: 'career-integrations',
    title: 'Wellbeing',
    text:
      'We care about #BoldareTeam members as a community - team retreats and other team building activities',
  },
  {
    id: 3,
    icon: 'career-open-source',
    title: 'Open Source and R&D projects',
    text: 'e.g. Marble.js, #architecture-coffee',
  },
  {
    id: 4,
    icon: 'career-open-ev',
    title: 'Open events and meetups',
    text: 'Front Club, Silesia Dribbble Meetup, No Exceptions Meetup',
  },
  {
    id: 5,
    icon: 'career-path-jobs',
    title: 'Holacracy',
    text:
      'We empower our people to decide and act without proxies. Go, make things happen!',
  },
  {
    id: 6,
    icon: 'career-int-work',
    title: 'Iterative workflow',
    text:
      'We are working on goals, we test & learn, avoid blockers and deliver quickly. And it works fine!',
  },
];

export const dataBenefits = [
  {
    id: 1,
    icon: 'career-office',
    title: 'Super comfy offices we designed ourselves',
  },
  {
    id: 2,
    icon: 'career-chillout',
    title: 'Chillout spaces. Cafe, silent room, chaos room',
  },
  {
    id: 3,
    icon: 'career-flexible-work',
    title: 'Flexible work conditions',
  },
  {
    id: 4,
    icon: 'career-team-budget',
    title: 'Teams development budgets',
  },
  {
    id: 5,
    icon: 'career-remote-work',
    title: 'Hybrid or remote mode (depends on your role)',
  },
  {
    id: 6,
    icon: 'career-percent',
    title: '20% of annual profit shared among all employees',
  },
];
