export const data = [
  {
    name: 'Mateusz Grzesiukiewicz',
    position: 'JavaScript Developer',
    quote:
      '“The idea of a workation is well-known at Boldare - our teams have used it for several years. We enjoy the sun and beaches in Spain or Portugal (without spending vacation leave!) and hang out together. It strengthens our teamwork, boosts motivation, and works as a vitamin shot.”',
    cloudinaryDir: 'f2f-landing/boldare-f2f--grzeusz',
    alt: 'Working at Boldare - Mateusz',
  },
  {
    name: 'Maciej Król',
    position: 'Java Developer',
    quote:
      "“We regularly visit our international clients face-to-face to boost our relationship and get to know each other better. It's the perfect occasion to engage with other cultures and taste the local cuisine. There are always pleasant coffee breaks, bike tours and time to share your hobby with teammates.”",
    cloudinaryDir: 'f2f-landing/boldare-f2f--maciej',
    alt: 'Working at Boldare - Maciej',
  },
  {
    name: 'Natalia Zglińska',
    position: 'Employer Branding Specialist',
    quote: `“Face-to-face meetings are a perfect opportunity to organize some team workshops. It fosters creativity and gives a space for chit-chat with people you haven't seen in weeks. There is a time to both work out some great ideas and spend a pleasant evening together – full of conversation, tasty food, and laughs.”`,
    cloudinaryDir: 'f2f-landing/boldare-f2f--natalia',
    alt: 'Working at Boldare - Natalia',
  },
  {
    name: 'Karla Sutil',
    position: 'Process Guide',
    quote:
      '“I love participating in face-to-face workshops and offsites with the team. I feel like we have more time to understand each other better. Every meeting with the team gives us a lot of new energy that we can use during remote work!”',
    cloudinaryDir: 'f2f-landing/boldare-f2f--karla',
    alt: 'Working at Boldare - Karla',
  },
  {
    name: 'Mateusz Rosiek',
    position: 'Software Architect / Senior PHP developer',
    quote: `“Celebrating my 10th anniversary at Boldare was a unique time for me. I had a chance to talk in real life with colleagues with whom I worked together for years. There was a space for recalling some good old memories and relaxing. The whole happening made me feel that my work is meaningful, and I thought: “It's great to work in a company that appreciates my engagement.”`,
    cloudinaryDir: 'f2f-landing/boldare-f2f--mateusz',
    alt: 'Working at Boldare - Mateusz',
  },
];
