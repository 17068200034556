import React from 'react';
import CodeAudit from 'views/codeAudit';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['code-audit'];
const { navColors } = page;
const url = `${config.siteUrl}/services/code-audit/`;
const canonical = `${url}`;

const CodeAuditPage = () => (
  <Layout {...{ page, canonical, url }}>
    <NavBar color={navColors} />
    <PopupRedirect />
    <CodeAudit />
  </Layout>
);

export default CodeAuditPage;
