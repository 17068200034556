export const banner = {
  theme: 'dark',
  type: 'Webinar',
  title: [`Agile for your company. `, `How to implement `, `successfully`],
  img: 'img/webinar-agile/webinar_banner_agile.png',
  imgWidth: '1000',
  description: [
    'How to implement the agile methodology in your company to reduce costs and speed-up software development? Learn from the agile transformation case studies.',
  ],
};

export const firstSection = {
  id: 1,
  color: 'white',
  isSingle: true,
  isFinished: true,
  isSpeakers: true,
  isArticle: false,
  isAskSpeakers: true,
  isDedicatedMaterial: false,
  isRelated: true,
  isBonus: false,
  title:
    'Crisis strategies for building software, web products and online services',
  description: `Learn how you can benefit from implementing the lean startup approach to building web products, and reduce the risks for your business in a crisis. The video includes a real-life case discussed by Jeff Ko - Co-founder of Chartipedia.`,
  videoUrl: 'https://youtube.com/watch?v=1vq7A2fTwYA',
  pointsDescription:
    'Watch a recorded video of a webinar during which our experts discussed agile transformation case studies based on two perspectives: agile practitioners (Boldare software development team) and agile coaches (Brass Willow).',
  pointsTitle: 'You will learn:',
  points: [
    `How to overcome problems with accelerating software development delivery;`,
    `How to shorten the time-to-market for software applications by up to 50%;`,
    `How to save at least 30% of your budget on software development.`,
  ],
  speakers: [
    {
      avatar: 'img/webinar-agile/avatar-kate-hobler.png',
      position: 'Professional Scrum Trainer at Brass Willow',
      name: 'Kate Hobler',
    },
    {
      avatar: 'img/webinar-agile/avatar-dorota-jablonska.png',
      position: 'Scrum Master at Boldare',
      name: 'Dorota Jabłońska',
    },
    {
      avatar: 'img/webinar-agile/avatar-artur-belka.png',
      position: 'Customer Success Guide at Boldare',
      name: 'Artur Belka',
    },
  ],
  askSpeakers: [
    {
      avatar: 'img/webinar-agile/avatar-dorota-jablonska.png',
      name: 'Dorota Jabłońska',
      email: 'dorota.jablonska@boldare.com',
      linkedin: 'https://www.linkedin.com/in/dorota-jablonska/',
    },
    {
      avatar: 'img/webinar-agile/avatar-artur-belka.png',
      name: 'Artur Belka',
      email: 'artur.belka@boldare.com',
      linkedin: 'https://www.linkedin.com/in/artur-belka-5b162572/',
    },
  ],
  relatedArticle: [
    {
      type: 'ARTICLE',
      title:
        'Kanban vs Scrum: which one is better for your product development?',
      lead: `At today’s pace of change, the complexity and dynamics of the business environment forces a fresh approach to creating products that meet customer expectations. In this context, agile, user-centered approaches, including scrum and kanban, are even more applicable and important. In this article, you will learn about both of these approaches, their similarities, differences and the ways you can use them when working on your digital products.`,
      id: 'article-kanban-scrum-learn-the-difference',
      link: '/blog/kanban-scrum-learn-the-difference/',
      photo: 'img/kanban_vs_scrum_comparison.png',
    },
    {
      type: 'ARTICLE',
      title: 'Budgeting in agile software development - how it’s done?',
      lead: `Agile working is all about innovation, especially in software development. Innovation means change, often lots of it, including changes to your software project along the way. To manage that project, what’s needed are approaches that are both flexible and focused. And that includes your approach to budget management. Agile budgeting is a way of managing the investment in your project that responds to change – changing user requirements, changing business needs, changing circumstances. It’s budget management that can pivot with the project.`,
      id: 'article-budgeting-in-agile-software-development',
      link: '/blog/budgeting-in-agile-software-development/',
      photo: 'img/agile_budgeting_planning.jpg',
    },
    {
      type: 'ARTICLE',
      title: 'The great dilemma. Agile or waterfall?',
      lead: `You came up with this mind-blowing product idea which you’re certain is going to revolutionize the market. Now all you have to do is turn that idea into reality. Easy! No, not really – achieving reality requires a lot of decision making!`,
      id: 'article-agile-vs-waterfall',
      link: '/blog/agile-vs-waterfall/',
      photo: 'MVP_development_in_practice.jpg',
    },
  ],
};
