import React from 'react';
import { Section } from 'components';
import Computer from '../../icons/Computer.svg';
import Home from '../../icons/Home.svg';

import './JoinSession.scss';

const LIST = [
  {
    title: 'your office location',
    icon: Home,
  },
  {
    title: 'online',
    icon: Computer,
  },
];

const ListItem = ({ title, icon }) => (
  <div className={'book-discovery-session__list-item'}>
    <div className={'book-discovery-session__icon-wrapper'}>
      <img src={icon} alt={title} />
    </div>
    <span className={'book-discovery-session__list-text'}>{title}</span>
  </div>
);

export default function JoinSession() {
  return (
    <Section
      color={'pigeon-post-light'}
      spaceless
      additionalClass={'book-discovery-session__join-session'}
    >
      <h2 className={'book-discovery-session__join-session-heading'}>
        Join discovery session at your convenience!
      </h2>
      <div className={'book-discovery-session__list'}>
        {LIST.map(item => (
          <ListItem key={item.title} {...item} />
        ))}
      </div>
    </Section>
  );
}
