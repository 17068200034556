import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, SectionHeader, Paragraph, Heading } from 'components';
import { classNames } from 'utils';
import { data } from './logic.js';
import companyData from '../../../../../meta/companyData';
import './Approach.scss';

class Approach extends Component {
  constructor() {
    super();

    this.state = {
      slide: 0,
    };
  }

  renderTiles = () =>
    data.map((item, index) => (
      <div
        className={classNames('machine-learning__step', {
          'machine-learning__step--active': index === this.state.slide,
        })}
        id={`machine-learning-step-${index}`}
        onClick={() => this.showTile(index)}
      >
        <Heading type={3} size={'medium-plus-small'} color={'white'}>
          {item.title}
        </Heading>
        {index === this.state.slide && (
          <Paragraph color={'white'} size={'medium'}>
            {item.description}
          </Paragraph>
        )}
      </div>
    ));

  showTile(number) {
    this.setState({ slide: number });
  }

  render() {
    return (
      <Section color={'mine-shaft'}>
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionHeader
              size={'big'}
              color={'white'}
              headingType={2}
              lineColor={'red'}
            >
              Our approach
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            <div className={'machine-learning__process-description'}>
              <Paragraph color={'white'} size={'medium'}>
                Our approach has been shaped across{' '}
                {companyData.years_experience} years of software development
                practice, putting customers at the center of everything we do.
              </Paragraph>
            </div>
          </Col>
        </Row>
        <div className={'machine-learning__roadmap'}>{this.renderTiles()}</div>
      </Section>
    );
  }
}

export default Approach;
