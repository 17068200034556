import { Paragraph, Section, TitleAskCto } from 'components';
import React, { useEffect, useState } from 'react';
import './Why.scss';
import { Col, Row } from 'react-flexbox-grid';
import HandShake from 'assets/images/logos/askcto-handshake.svg';
import Building from 'assets/images/logos/askcto-building.svg';
import Tickets from 'assets/images/logos/askcto-tickets.svg';
import Sprocket from 'assets/images/logos/askcto-sprocket.svg';

const Why = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    // <div className={'why-header'} id={'why-link'}>
    <Section
      additionalClass={'why-header'}
      side={'right'}
      id={'why-link'}
      fullwidth
    >
      <TitleAskCto
        title={'Why should you join us?'}
        text={
          'In the COVID-19 era, companies are looking for ways to reconfigure their products and services. Services, products, leadership and organizational culture, as well as  work itself need to adjust to the new reality.'
        }
        secondText={
          'That’s what we’ll be discussing during the CTO asks with a select group of guests. We will share our knowledge of transforming businesses, teams, products & services digitally and create a space for exchange of ideas and thoughts.'
        }
      />
      <Row>
        <Col sm={12} md={5} className={'why-column-left'}>
          <Paragraph
            color={'black'}
            size={'small'}
            additionalClass={'why-paragraph'}
          >
            <img
              id={'sprocket'}
              src={Sprocket}
              alt={'sprocket'}
              loading={'lazy'}
            />
            we will present best practices related to building digital
            products, leading a digital transformation & operating in the New
            Normal
          </Paragraph>
          <Paragraph
            color={'black'}
            size={'small'}
            additionalClass={'why-paragraph'}
          >
            <img
              id={'tickets'}
              src={Tickets}
              alt={'tickets'}
              loading={'lazy'}
            />
            it’s almost free – all costs, except the hotel, are covered by
            Boldare
          </Paragraph>
        </Col>
        <Col md={1} />
        <Col sm={12} md={5} className={'why-column-right'}>
          <Paragraph
            color={'black'}
            size={'small'}
            additionalClass={'why-paragraph'}
          >
            <img
              id={'handshake'}
              src={HandShake}
              alt={'handshake'}
              loading={'lazy'}
            />
            it’s a chance to network with other CEOs and CTOs from a variety of
            industries and countries
          </Paragraph>
          <Paragraph
            color={'black'}
            size={'small'}
            additionalClass={'why-paragraph'}
          >
            <img
              id={'building'}
              src={Building}
              alt={'building'}
              loading={'lazy'}
            />
            it’s a live event in our HQ in Gliwice, Poland
          </Paragraph>
        </Col>
      </Row>
    </Section>
  );
};

export default Why;
