import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Button, Heading, Link, Section, Paragraph } from 'components';
import './MainContent.scss';

const MainContent = ({ imageDesktop, url }) => (
  <Section additionalClass={'berlin-event-section__banner'} spaceless>
    <Row className={'no-gap'}>
      <Col md={12} lg={12} xl={12} className={'berlin-event-content__main'}>
        <>
          <div className={'berlin-event-section__banner-inner'}>
            <div className={'berlin-event-content__content'}>
              <Heading
                type={2}
                size={'large'}
                additionalClass={'berlin-event-content__heading'}
                noLine
              >
                The Finding Product-Market Fit:{' '}
                <span className={'berlin-event-content__heading-wrapper'}>
                  <img
                    className={'berlin-event-content__pixel-font'}
                    src={
                      'https://res.cloudinary.com/de4rvmslk/image/upload/v1674565717/event/berlin3.svg'
                    }
                    alt={'Berlin Font'}
                  />{' '}
                  <span className={'berlin-event-content__volume'}>Vol.2</span>
                </span>{' '}
                <br />
                event is over!
              </Heading>
              <Heading
                type={3}
                size={'large'}
                additionalClass={'berlin-event-content__subheading'}
                noLine
              >
                Interested in joining the next event? Sign up and be the first
                to know.
              </Heading>
              <Link
                additionalClass={'berlin-event-content__event-button'}
                to={'https://form.typeform.com/to/au3Q3Ak9'}
              >
                <Button
                  additionalClass={'event-button__text'}
                  color={'violet'}
                >
                  JOIN THE ALERT LIST &rarr;
                </Button>
              </Link>
            </div>
          </div>
        </>
      </Col>
    </Row>
  </Section>
);

export default MainContent;
