import Banner from './Banner';
import Specializations from './Specializations';
import Benefits from './Benefits';
import Projects from './Projects';
import OurCompetitiveAdvantages from './OurCompetitiveAdvantages';
import Reviews from './Reviews';
import TransformationInPractise from './TransformationInPractise';

export {
  Banner,
  Specializations,
  Benefits,
  Projects,
  OurCompetitiveAdvantages,
  Reviews,
  TransformationInPractise,
};
