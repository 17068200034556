export const data = [
  {
    icon: 'clock',
    title: 'Outrun your competitors',
    description:
      'An MVP lets you to release a fully functional app quickly, with a time-to-market of between 4 and 8 weeks.',
  },
  {
    icon: 'bullseye',
    title: 'Get priceless feedback',
    description:
      'An MVP allows you to easily collect feedback and ideas from real users, helping to improve the product and making future development easier.',
  },
  {
    icon: 'project-management',
    title: 'Validate your idea',
    description:
      'An MVP enables you to test your business hypothesis and save time and money potentially allocated to a product based on incomplete data.',
  },
  {
    icon: 'save-money',
    title: 'Build the future on facts',
    description:
      'An MVP helps you create a feasible plan for future improvements and an app that reflects users’ needs.',
  },
  {
    icon: 'team',
    title: 'Transfer the knowledge',
    description:
      'Working on an MVP with an external development team is a great chance for a hands-on experience of the lean startup approach and to dive into the agile and scrum frameworks.',
  },
];
