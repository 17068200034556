import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigateToId } from 'utils';
import { EditorIntro, PostList } from './subcomponents';
import AiAssistantBanner from './subcomponents/AiAssistantBanner';

const Blog = ({ posts, authors, filters: propsFilters, localSearchPages }) => {
  const [filters, setFilters] = useState(
    propsFilters
      .sort((a, b) => a.order - b.order)
      .map(filter => ({ name: filter.name, isActive: false })),
  );
  const [filtersActive, setFiltersActive] = useState(false);

  const handleFilterStatus = () =>
    setFiltersActive(
      prevState => prevState.propsFilters.filter(item => item.isActive).length,
    );

  const toggleFilter = filter => {
    setFilters(
      propsFilters.map(item => ({
        ...item,
        isActive: item.name === filter,
      })),
    );

    handleFilterStatus();
    navigateToId('blog-post-list');
  };

  return (
    <div className={'blog__container'}>
      <EditorIntro
        posts={posts}
        authors={authors}
        toggleFilter={toggleFilter}
      />
      <PostList
        posts={posts}
        newWork
        localSearchPages={localSearchPages}
        toggleFilter={toggleFilter}
      />
    </div>
  );
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
  localSearchPages: PropTypes.shape({
    index: PropTypes.string,
    store: PropTypes.object,
  }).isRequired,
};

export default Blog;
