import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Form/Checkbox';
import { classNames } from 'utils';
import './CheckboxGroup.scss';

const CheckboxGroup = ({
  additionalClass,
  fullwidth,
  values,
  name,
  onChange,
  value: checkedValues,
  singleAnswer,
}) => {
  const onChangeCallback = useCallback(
    clickedValue => {
      const filteredValues = checkedValues.filter(v => v !== clickedValue);
      if (
        checkedValues.length &&
        filteredValues.length !== checkedValues.length
      ) {
        onChange(filteredValues);
      } else {
        onChange(
          singleAnswer ? [clickedValue] : [...checkedValues, clickedValue],
        );
      }
    },
    [checkedValues, singleAnswer, onChange],
  );

  return (
    <div
      className={classNames('checkbox-group', additionalClass, {
        'checkbox-group--fullwidth': fullwidth,
      })}
    >
      {values.map(({ value, label }) => {
        const checked = checkedValues.includes(value);
        return (
          <Checkbox
            key={`${name}--${value}`}
            name={name}
            onChange={onChangeCallback}
            checked={checked}
            value={value}
            label={label}
            tag
          />
        );
      })}
    </div>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  fullwidth: PropTypes.bool,
  singleAnswer: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  additionalClass: '',
  fullwidth: true,
  value: [],
  singleAnswer: true,
};

export default React.memo(CheckboxGroup);
