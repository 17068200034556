import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import ExternalResource from 'views/externalResource';

const ExternalResourcePage = ({ data }) => {
  const { markdownRemark } = data;

  return <ExternalResource data={markdownRemark} />;
};

ExternalResourcePage.propTypes = {
  data: PropTypes.shape({
    resourceData: PropTypes.object,
  }).isRequired,
};

export default ExternalResourcePage;

export const pageQuery = graphql`
  query ExternalResourceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        canonical
        iframe {
          src
        }
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___settings___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            templateKey
            title
            canonical
            iframe {
              src
            }
          }
        }
      }
    }
  }
`;
