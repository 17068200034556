import React, { useState, useEffect } from 'react';

import { enableAnimations } from 'config/helpers';
import { Helmet } from 'react-helmet';
import { Phase, QuizState } from './logic/quizInterface';
import { Banner, Calculator, Contact, Result } from './subcomponents';

import './AppCostsCalculator.scss';

const AppCostsCalculator = () => {
  const [currentState, setCurrentState] = useState(QuizState.landing);
  const [currentPhase, setPhase] = useState(Phase.prototype);
  const [finalAnswers, setFinalAnswers] = useState({});
  const [contact, setContact] = useState({});

  useEffect(() => {
    enableAnimations();
  });

  const CurrentComponent = () => {
    switch (currentState) {
      case QuizState.landing:
        return <Banner setNextState={() => setCurrentState(QuizState.quiz)} />;
      case QuizState.quiz:
        return (
          <Calculator
            setNextState={() => setCurrentState(QuizState.contact)}
            setResult={setPhase}
            setFinalAnswers={setFinalAnswers}
          />
        );
      case QuizState.contact:
        return (
          <Contact
            setNextState={() => setCurrentState(QuizState.result)}
            setContact={setContact}
            phase={currentPhase}
            finalAnswers={finalAnswers}
          />
        )
      case QuizState.result:
        return (
          <Result
            phase={currentPhase}
            finalAnswers={finalAnswers}
            contact={contact}
            // onResetQuiz={this.resetQuiz}
          />
        );
      default:
        return <Banner />;
    }
  };

  return (
    <div className={'single-page single-page--hero app-cost-page'}>
      <Helmet>
        <title>App Cost Calculator: Estimate Your App Development Cost</title>
        <meta
          name={'description'}
          content={
            'The app cost calculator is an online tool that offers a rough estimate of your app development costs. Answer 5 simple questions and get a cost breakdown!'
          }
        />
      </Helmet>
      <CurrentComponent />
    </div>
  );
};

export default AppCostsCalculator;
