import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { enableAnimations, getCloudinaryUrl } from 'config/helpers';
import { CaseStudyContent } from './subcomponents';
import { Navigation } from '../singleArticle/subcomponents';
import './SingleCaseStudy.scss';

export class ArticleTemplate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { caseStudy, articlesList, caseStudyList } = this.props;
    const {
      cover,
      title,
      tileDescription,
      mainColor,
      settings,
    } = caseStudy.frontmatter;
    const slug = settings.slug || caseStudy.fields.slug;

    return (
      <div className={'single-case-study'}>
        <SEO
          title={settings.metaTitle || title}
          description={settings.metaDescription}
          tile={tileDescription}
          cover={getCloudinaryUrl(cover, 'f_auto,q_auto,w_400')}
          slug={slug}
          section={'work'}
          canonical={settings.canonical}
          cloudinary
        />
        <CaseStudyContent
          data={caseStudy.frontmatter}
          articlesList={articlesList}
        />

        <Navigation currentSlug={slug} allPosts={caseStudyList} />
      </div>
    );
  }
}

ArticleTemplate.propTypes = {
  caseStudy: PropTypes.shape().isRequired,
  caseStudyList: PropTypes.array.isRequired,
  articlesList: PropTypes.shape().isRequired,
};

export default ArticleTemplate;
