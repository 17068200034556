import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import './BannerF2F.scss';

const BannerF2F = () => {
  return (
    <a
      className={'banner--face-to-face'}
      href={'/career/face-to-face/'}
      target={'_blank'}
      rel={'noreferrer'}
    >
      <img
        alt={'Boldare - Digital Product Design Development Company'}
        className={'banner--face-to-face__img hide-tablet hide-desktop'}
        src={getCloudinaryUrl(
          'f2f-landing/f2f-popup-banner.png',
          'c_scale,q_auto',
        )}
        loading={'lazy'}
      />
      <img
        alt={'Boldare - Digital Product Design Development Company'}
        className={'banner--face-to-face__img hide-mobile'}
        src={getCloudinaryUrl(
          'f2f-landing/f2f-popup-banner-big2.png',
          'c_scale,q_auto/v1651154467',
        )}
        loading={'lazy'}
      />
    </a>
  );
};

export default BannerF2F;
