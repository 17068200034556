const calcResultsDimensions = () => {
  const shape = document.querySelector(
    '#gatsby-focus-wrapper > div > div:nth-child(1) > div.single-page.single-page--hero.bldr-dsgn > div.dsgn-hero__animation-wrapper > div > div.dsgn-hero__bricks > div:nth-child(84)',
  );
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const { top, left, width, height } = shape.getBoundingClientRect();
  const topPercents = `${((top + 4) / windowHeight) * 100}%`;
  const leftPercents = `${((left + 4) / windowWidth) * 100}%`;
  const rightPercents = `${((left + width - 4) / windowWidth) * 100}%`;
  const bottomPercents = `${((top + height - 4) / windowHeight) * 100}%`;

  return `polygon(${leftPercents} ${topPercents}, ${rightPercents} ${topPercents}, ${rightPercents} ${bottomPercents}, ${leftPercents} ${bottomPercents})`;
};

export { calcResultsDimensions };
