import React, { Component } from 'react';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import { ContactNl } from 'views/contactNl';
import config from '../../meta/config';

class ContactNlPage extends Component {
  render() {
    const page = pages.contactNl;
    const { navColors } = page;
    const url = `${config.siteUrl}/contact-nl/`;
    const canonical = `${url}`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <PopupRedirect message={'contact-nl'} />
        <ContactNl />
      </Layout>
    );
  }
}

export default ContactNlPage;
