import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';

import './Heading.scss';

const Heading = ({ text, isVersionB }) => (
  <h2
    className={classNames('work-boxes-heading', {
      'work-boxes-heading--big': isVersionB,
    })}
  >
    {text}
  </h2>
);

Heading.defaultProps = {
  isVersionB: false,
};

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  isVersionB: PropTypes.bool,
};

export default Heading;
