import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Icon } from 'components';
import { ICON_TYPES } from 'config';
import { classNames } from 'utils';
import './Button.scss';

const Button = ({
  size,
  tag: Tag,
  additionalClass,
  children,
  color,
  arrowColor,
  order,
  transparent,
  type,
  ariaName,
  underline,
  borderColor,
  loading,
  hover,
  ...other
}) => {
  const orderClass = order ? `button--${order}` : null;
  const buttonProps = {
    className: classNames(
      'button',
      orderClass,
      {
        'button--underline': underline,
        [`button--${color}`]: !transparent && color,
        [`button--hover-${color}`]: hover,
        [`button--transparent-${color}`]: transparent && color,
        [`button--border-${borderColor}`]: borderColor,
        [`button--size-${size}`]: size,
      },
      additionalClass,
    ),
    ...other,
  };

  const button = Tag ? (
    <Tag {...buttonProps}>{children}</Tag>
  ) : (
    <button type={type} {...buttonProps} aria-label={ariaName}>
      {loading ? (
        <span className={'button--loading'}>{children}</span>
      ) : (
        children
      )}
    </button>
  );

  return (
    <>
      {order === 'secondary' ? (
        <div className={classNames(orderClass, additionalClass)}>
          <Paragraph color={color} additionalClass={'text--bold'}>
            {children}
          </Paragraph>
          <Icon
            icon={ICON_TYPES.RIGHT_ARROW}
            size={'medium'}
            color={arrowColor || color}
          />
        </div>
      ) : (
        button
      )}
    </>
  );
};

Button.propTypes = {
  size: PropTypes.string,
  tag: PropTypes.elementType,
  additionalClass: PropTypes.string,
  borderColor: PropTypes.string,
  order: PropTypes.string,
  color: PropTypes.string,
  arrowColor: PropTypes.string,
  type: PropTypes.string,
  ariaName: PropTypes.string,
  transparent: PropTypes.bool,
  underline: PropTypes.bool,
  hover: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Button.defaultProps = {
  size: '',
  tag: null,
  additionalClass: '',
  order: 'primary',
  color: '',
  arrowColor: '',
  borderColor: '',
  type: 'button',
  ariaName: 'button',
  transparent: false,
  underline: false,
  hover: false,
  loading: false,
};

export default Button;
