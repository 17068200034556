import React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from 'components';
import { awardsContentA } from 'views/home/subcomponents/Prizes/logic';

import SingleAward from './SingleAward';
import './Awards.scss';

const Awards = ({ isTablet }) => {
  const content = awardsContentA;
  const topList = content.top.map(element => (
    <SingleAward
      key={element.type}
      type={element.type}
      isBig
      link={element.link}
      isTablet={isTablet}
    />
  ));
  const bottomList = content.bottom.map(({ type }) => (
    <SingleAward key={type} type={type} isTablet={isTablet} />
  ));

  return (
    <div className={'prizes-awards'}>
      <ul className={'prizes-awards__mobile-list'}>
        {topList}
        {bottomList}
      </ul>
      <ul className={'prizes-awards__top-list'}>{topList}</ul>
      <ul className={'prizes-awards__bottom-list'}>{bottomList}</ul>
    </div>
  );
};

Awards.propTypes = {
  isTablet: PropTypes.bool.isRequired,
};

export default Awards;
