import { Heading, Paragraph, Section } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import React, { useEffect, useState } from 'react';
import './WorkForPartners.scss';
import LoaderComponent from 'components/Loader';
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { enableAnimations } from 'config/helpers';
import { CaseStudies } from 'views/digitalTransformationJourney/subcomponents/WorkForPartners/subComponents/CaseStudies';
import Button from 'components/Button';
import { navigate } from 'gatsby';
import Elm from 'assets/images/digital-transformation-journey/clients/elm.jpg';
import Matic from 'assets/images/digital-transformation-journey/clients/matic.png';
import Takamol from 'assets/images/digital-transformation-journey/clients/takamol.png';
import Unifonic from 'assets/images/digital-transformation-journey/clients/unifonic-logo-new-dark.svg';
import Virgin from 'assets/images/digital-transformation-journey/clients/virgin.jpg';
import Zatca from 'assets/images/digital-transformation-journey/clients/zatca.png';

export const WorkForPartners = ({ allArticles, authors }) => {
  const [loading, setLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState('Energy');
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 1199);
  };

  useEffect(() => {
    handleResize();

    enableAnimations();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [activeFilter]);

  const onActiveFilterChange = async newFilter => {
    setIsFakeLoading(true);
    setActiveFilter(newFilter);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 400);
  };

  return (
    <Section
      additionalClass={`digital-transformation-journey-work-for-partners__section`}
    >
      <div
        className={
          'digital-transformation-journey-work-for-partners--padding-right'
        }
      >
        <Row center={'xs'} start={'md'}>
          <Row center={'xs'} start={'md'}>
            <Col xs={12}>
              <Heading type={4} additionalClass={'clients-heading'}>
                Clients from Middle East whose missions we proudly share:
              </Heading>
            </Col>
          </Row>
          <Row className={'clients-logos-wrapper'}>
            <Col xs={12} md={4} lg={2} additionalClass={'client-logo'}>
              <img src={Elm} alt={'Elm'} />
            </Col>
            <Col xs={12} md={4} lg={2} additionalClass={'client-logo'}>
              <img src={Unifonic} alt={'Unifonic'} />
            </Col>
            <Col xs={12} md={4} lg={2} additionalClass={'client-logo'}>
              <img src={Matic} alt={'Matic'} />
            </Col>
            <Col xs={12} md={4} lg={2} additionalClass={'client-logo'}>
              <img src={Takamol} alt={'Takamol'} />
            </Col>
            <Col xs={12} md={4} lg={2} additionalClass={'client-logo'}>
              <img src={Virgin} alt={'Virgin'} />
            </Col>
          </Row>
          <Col sm={12} md={4} xl={4}>
            <Row center={'xs'} start={'md'}>
              <Col xs={12}>
                <Heading
                  type={4}
                  size={'large'}
                  color={'black'}
                  additionalClass={
                    'digital-transformation-journey-work-for-partners__title'
                  }
                >
                  Work done for our partners
                </Heading>
              </Col>
            </Row>
          </Col>
          <Col sm={10} md={7} xl={8}>
            <Paragraph
              additionalClass={`
                digital-transform-journey-work-for-partners__subtitle
            `}
              size={'medium'}
              color={'black'}
            >
              At Boldare, we don’t just talk about great products. We make them
              with our clients. Read our case studies and find out how we’ve
              helped them bring great products to market:
            </Paragraph>
          </Col>
        </Row>
        {loading ? (
          <div className={'work-page__loader'}>
            <LoaderComponent isActive absolute />
          </div>
        ) : (
          <Experiment name={'case-studies-test-ab'}>
            <Variant name={'case-studies-a'}>
              <CaseStudies
                allArticles={[]}
                authors={[]}
                onActiveFilterChange={onActiveFilterChange}
                activeFilter={activeFilter}
                isFakeLoading={isFakeLoading}
                isDesktop={isDesktop}
                key={`${loading}-studies-a`}
              />
            </Variant>
            <Variant name={'case-studies-b'}>
              <CaseStudies
                allArticles={allArticles}
                authors={authors}
                onActiveFilterChange={onActiveFilterChange}
                isVariantB
                activeFilter={activeFilter}
                isFakeLoading={isFakeLoading}
                isDesktop={isDesktop}
                key={`${loading}-studies-b`}
              />
            </Variant>
          </Experiment>
        )}
        <Row start={'xs'} end={'md'}>
          <Col md={1} xl={8} />
          <Col
            sm={12}
            md={6}
            xl={4}
            className={
              'digital-transformation-journey-work-for-partners__btn-col'
            }
          >
            <Button
              additionalClass={`digital-transformation-journey-work-for-partners__btn`}
              color={'pigeon-post'}
              size={'large'}
              onClick={() => navigate('/work')}
            >
              Explore More Case Studies &#8594;
            </Button>
          </Col>
        </Row>
      </div>
    </Section>
  );
};
