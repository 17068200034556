import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useInView } from 'react-intersection-observer';
import { pushToDataLayer } from 'utils';
import PropTypes from 'prop-types';
import Arrows from '../Arrows';
import './SectionArrows.scss';

const SectionArrows = ({ isDesktop, variant }) => {
  const [isSafari, setIsSafari] = useState(false);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    setIsSafari(
      navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome'),
    );
  }, []);

  useEffect(() => {
    if (isDesktop) {
      const hideImages = gsap.timeline();
      hideImages.to('.circle-images', {
        display: 'none',
      });
      const first2Timeline = gsap.timeline();
      first2Timeline.to('.first-2', {
        display: 'none',
        opacity: '0',
        ease: 'linear',
      });
      const second2Timeline = gsap.timeline();
      second2Timeline
        .from('.show-next-2', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.show-next-2', {
          duration: 3,
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        });
      const arrowsFirst = gsap.timeline();
      arrowsFirst.to('.arrows-slow.rotate', {
        animationPlayState: 'paused',
      });
      const reverseArrows = gsap.timeline();
      reverseArrows.to('.rotate .arrow', {
        rotation: '+=180',
        fill: '#f2da3a',
      });
      const arrowsSecond = gsap.timeline();
      arrowsSecond.to('.arrows-slow.rotate', {
        animationPlayState: 'running',
        animationDirection: 'reverse',
      });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.section-arrows',
            start: 'top top',
            end: '+=250%',
            pin: isDesktop,
            scrub: true,
          },
        })
        .add(first2Timeline, '+= 0.1')
        .add(arrowsFirst, '<')
        .add(reverseArrows)
        .add(arrowsSecond, '+=0.1')
        .add(second2Timeline, '<')
        .add(hideImages);
    } else {
      const hideImages = gsap.timeline();
      hideImages.to('.circle-images', {
        display: 'none',
      });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.section-arrows',
            start: 'top top',
            end: '+=250%',
            pin: isDesktop,
            scrub: true,
          },
        })
        .add(hideImages);
    }

    ScrollTrigger.refresh();
  }, [isDesktop]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const isWindow = typeof window !== 'undefined';
      let wasArrowsSectionSeen;

      if (isWindow) {
        wasArrowsSectionSeen = window.sessionStorage.getItem(
          'wasArrowsSectionSeen',
        );

        if (!wasArrowsSectionSeen) {
          window.sessionStorage.setItem('wasArrowsSectionSeen', true);
          pushToDataLayer(`${variant}-section-arrows`);
        }
      }
    }
  }, [inView]);

  if (isDesktop) {
    return (
      <div
        className={
          'section-wrapper section-arrows pinned-section disable-transform'
        }
        ref={ref}
      >
        <section className={'grid-layout'}>
          {variant === 'about-us-a' && (
            <>
              <p className={'about-header first-2'}>
                From the start, we wanted to{' '}
                <span className={'marker-highlight-small'}>disrupt</span>{' '}
                product development services, be light years ahead of our
                competition...
              </p>
              <p className={'about-header show-next-2'}>
                {' '}
                so we needed to do things{' '}
                <span className={'marker-highlight marker-black'}>
                  differently
                </span>
              </p>
            </>
          )}
          {variant === 'about-us-b' && (
            <>
              <p className={'about-header first-2'}>
                We know that to{' '}
                <span className={'marker-highlight-small'}>disrupt</span> the
                business...
              </p>
              <p className={'about-header show-next-2'}>
                ...we must make things{' '}
                <span className={'marker-highlight marker-black'}>
                  differently
                </span>
              </p>
            </>
          )}
          <div className={'arrows-container'}>
            <div className={'arrows-fast'}>
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
            </div>
            <div className={`arrows-slow ${!isSafari && 'rotate'}`}>
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
            </div>
            <div className={'arrows'}>
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
            </div>
            <div className={'arrows-fast'}>
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
              <Arrows />
            </div>
          </div>
        </section>
      </div>
    );
  }
  return (
    <div className={'section-wrapper section-arrows'}>
      <section className={'grid-layout'}>
        {variant === 'about-us-a' && (
          <p className={'about-header'}>
            From the start, we wanted to{' '}
            <span className={'marker-highlight-small'}>disrupt</span> product
            development services, be light years ahead of our competition so we
            needed to do things
            <span className={'marker-highlight marker-black'}>
              differently
            </span>
          </p>
        )}
        {variant === 'about-us-b' && (
          <p className={'about-header'}>
            We know that to{' '}
            <span className={'marker-highlight-small'}>disrupt</span> the
            business we must make things{' '}
            <span className={'marker-highlight marker-black'}>
              differently
            </span>
          </p>
        )}
      </section>
      <div className={'arrows-container'}>
        <div className={'arrows-fast'}>
          <Arrows />
          <Arrows />
        </div>
        <div className={'arrows-slow'}>
          <Arrows />
          <Arrows />
        </div>
        <div className={'arrows-fast'}>
          <Arrows />
          <Arrows />
        </div>
        <div className={'arrows-slow'}>
          <Arrows />
          <Arrows />
        </div>
      </div>
    </div>
  );
};

export default SectionArrows;

SectionArrows.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
