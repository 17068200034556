import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Paragraph, Heading, Button, Link } from 'components';
import { classNames } from 'utils';
import './ServiceTile.scss';

const renderTile = ({
  title,
  caption,
  description,
  backgroundColor,
  textColor,
  link,
  alignedLeft,
  additionalClass,
}) => {
  return (
    <div
      className={classNames(
        'service-tile',
        {
          'service-tile--left w-container--left': alignedLeft,
          'service-tile--right w-container--right': !alignedLeft,
          [`service-tile--${backgroundColor}`]: backgroundColor,
        },
        additionalClass,
      )}
    >
      <div className={'service-tile__content anim-fade-in-bottom'}>
        {caption && (
          <Paragraph
            color={textColor}
            additionalClass={'service-tile__caption'}
          >
            {caption}
          </Paragraph>
        )}
        {link ? (
          <a href={link}>
            <Heading
              size={'big'}
              type={2}
              color={textColor}
              additionalClass={'service-tile__header'}
            >
              {ReactHtmlParser(title)}
            </Heading>
          </a>
        ) : (
          <Heading
            size={'big'}
            type={2}
            color={textColor}
            additionalClass={'service-tile__header'}
          >
            {ReactHtmlParser(title)}
          </Heading>
        )}
        <Paragraph
          color={textColor}
          additionalClass={'text--light service-tile__description'}
        >
          {description}
        </Paragraph>
        {link && (
          <a href={link} rel={'nofollow'}>
            <Button
              order={'secondary'}
              color={textColor}
              ariaName={'Discover button'}
            >
              Discover
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

const ServiceTile = props => <>{renderTile(props)}</>;

renderTile.defaultProps = {
  backgroundColor: 'white',
  textColor: 'black',
  additionalClass: '',
  alignedLeft: false,
  link: '',
};

renderTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  link: PropTypes.string,
  alignedLeft: PropTypes.bool,
  additionalClass: PropTypes.string,
};

export default ServiceTile;
