import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';

import './BlogLink.scss';

const BlogLink = ({ url, image, style, target, text }) => (
  <a
    href={url}
    className={classNames({
      [`cms-link--${style}`]: style,
      'cms-link--image': image,
    })}
    target={target ? '_blank' : '_self'}
    rel={'noreferrer'}
  >
    {image ? (
      <img
        src={getCloudinaryUrl(image, 'f_auto,q_auto')}
        alt={text}
        loading={'lazy'}
      />
    ) : (
      text
    )}
  </a>
);

BlogLink.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  target: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default BlogLink;
