import React, { useState } from 'react';
import './StarRating.scss'; // make sure to import your styles

const StarRating = ({ handleRating }) => {
  const [hoverValue, setHoverValue] = useState(undefined);
  const [selectedValue, setSelectedValue] = useState(0);

  const handleClick = value => {
    setSelectedValue(value);
    handleRating(value);
  };

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  return (
    <div className={'star-rating'}>
      {[1, 2, 3, 4, 5].map(i => (
        <div
          key={i}
          className={`star${
            i <= (hoverValue || selectedValue) ? ' selected' : ''
          }`}
          onClick={() => handleClick(i)}
          onMouseOver={() => handleMouseOver(i)}
          onMouseLeave={handleMouseLeave}
        >
          ★
        </div>
      ))}
    </div>
  );
};

export default StarRating;
