import { SectionWide } from 'components/DS/SectionWide/SectionWide';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ClientTile } from 'views/clients/subcomponents/ClientTile/ClientTile';
import { getCloudinaryUrl } from 'config/helpers';
import { COMPANIES } from './companiesList';

import './Companies.scss';

export default function Companies() {
  return (
    <SectionWide color={'main-blue'}>
      <img
        className={'ses-companies__badge'}
        src={getCloudinaryUrl(
          `service-standards/ses-badge.png`,
          'f_auto,q_auto',
        )}
        alt={'Service Standards Meetings'}
      />
      <div className={'ses-companies'}>
        <h1 className={'heading heading--large text--black'}>
          <span
            className={
              'marker-highlight marker-highlight--blue ses-companies__marker'
            }
          >
            Companies
          </span>
          <br />
          <span className={'ses-heading__translate'}>
            that trust our Service Standards
          </span>
        </h1>
        <p>
          Our services shape Boldare, not the other way around. The entire
          company, including our structure, culture, and processes, is designed
          to best meet our clients' business goals and create valuable products
          for them.
        </p>
      </div>
      <Row className={'clients__logos-row'}>
        {COMPANIES.map(({ name, photo, description }) => (
          <Col
            className={'clients__logos-column'}
            xs={12}
            sm={6}
            md={4}
            xl={3}
          >
            <ClientTile name={name} photo={photo} description={description} />
          </Col>
        ))}
      </Row>
    </SectionWide>
  );
}
