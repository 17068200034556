import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

import './Overlay.scss';

const Overlay = ({ children, additionalClass }) => (
  <div className={classNames('overlay', additionalClass)}>{children}</div>
);

Overlay.propTypes = {
  children: PropTypes.node,
  additionalClass: PropTypes.string,
};

Overlay.defaultProps = { children: null, additionalClass: null };

export default Overlay;
