export const data = [
  {
    backgroundImage: 'apb-community/apb-community-newsletter-home-banner.svg',
    badge: 'Community newsletter',
    badgeColor: 'black',
    button: 'Sign up to the newsletter →',
    buttonColor: 'black-and-white',
    color: 'black',
    description:
      'Monthly digest made of practical insights to help you build better products',
    headline: 'Learn from Agile Product Builders',
    image: '',
    link: 'https://www.boldare.com/apb-community-newsletter/',
  },
  {
    backgroundImage: 'img/ai-expert-advice.png',
    badge: 'ai expert advice',
    badgeColor: 'black',
    button: 'Read the interview →',
    buttonColor: 'white',
    color: 'black',
    headline: 'What should every decision maker know about the Generative AI?',
    image: '',
    link:
      'https://www.boldare.com/blog/what-decision-makers-need-to-know-generative-ai-revolution/',
  },
  {
    backgroundImage: 'img/keynote.png',
    badge: 'keynote recording',
    badgeColor: 'black',
    button: 'Watch the recording →',
    buttonColor: 'secondary-white',
    color: 'white',
    headline: 'Testing Strategies In Customer-Centric Product Development',
    image: '',
    link: 'https://go.boldare.com/l/688533/2024-01-28/2ltdby',
  },
];

export const sliderData = [
  {
    background: 'img/teal-space.jpg',
    caption: 'Future of the workplace',
    content: 'How to create a teal space',
    description:
      '5 tips for employee-oriented companies who want to create a space supporting transparency, communication, teamwork and creativity',
    color: 'pigeon-post',
    link: '/blog/teal-space-for-creativity-and-productivity/',
  },
  {
    background: 'img/meetup.jpg',
    caption: 'Organizational change',
    content: '5 Signs You’re Ready for Holacracy',
    description:
      "Boldare's co-founder explains the qualities, which enable companies to naturally transition into a teal organization. ",
    color: 'yellow',
    link: '/blog/5-signs-you-are-ready-for-holacracy/',
  },
];
