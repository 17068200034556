import React, { useState, useEffect, useRef } from 'react';
import HighlightShape from './HighlightShape.js';
import './WordAnimation.scss';

const words = ['product', 'challenge', 'ideas'];

const WordAnimation = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [showWord, setShowWord] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowWord(false);
      setTimeout(() => {
        setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length);
        setShowWord(true);
      }, 500);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={'animated-words'}>
      <div
        className={`animated-words__word-container ${showWord ? 'show' : ''}`}
      >
        <div className={'animated-words__svg-container'}>
          <HighlightShape />
        </div>
        <span className={'animated-words__word'}>
          {words[currentWordIndex]}
        </span>
      </div>
    </div>
  );
};

export default WordAnimation;
