export const data = [
  {
    title: 'Our team is your team',
    text:
      'Simple as that - our teams are entirely focused on delivering value and solving your challenges. They treat your product as their own, having the same goals as you. From the very first meeting, to the final release, we are the team, together. ',
  },
  {
    title: 'Your business is in our minds',
    text:
      'Our developer cares about the whole product, not just the code. Our designer thinks about the users more than just making things look nice. The QA engineer looks at the benefits, not just finding mistakes. Every choice we make is business driven.',
  },
  {
    title: 'We live by the code',
    text:
      'We implemented our own Service Standards, which are a set of rules and best practices that we follow in every collaboration. This is our guarantee that you will get the best possible customer experience and the most effective business results.',
  },
  {
    title: 'Technology is our thing',
    text:
      'We first look at the problem you want to fix. Then, we pick the best tech for your product and blend it with the best suited AI-based technology. Each team includes a Tech Lead, an all- round role that focuses on integrating technology solutions into business contexts.',
  },
  {
    title: 'Commitment from the first contact',
    text:
      'While others focus on selling their developers, we focus on understanding your needs. That’s why during our first call, you will discuss your idea with our developer and business analyst. Expect to be challenged!',
  },
  {
    title: 'We value partnerships',
    text:
      'We play with open cards and practice radical transparency. We do this because we care about long-term collaborations, and these require honesty. If you think the same way, then we have a match!',
  },
];
