export const data = [
  {
    icon: 'resources-yt',
    title: 'Visit our YouTube channel for more videos →',
    link: 'https://youtube.com/boldarecom/',
  },
  {
    icon: 'resources-blog',
    title: 'Visit our blog for in-depth article →',
    link: '/blog/',
  },
  {
    icon: 'resources-meeting',
    title: 'Learn more about the New Normal →',
    link: '/new-normal/',
  },
];
