export const data = [
  {
    title: 'Lean startup mindset',
    description:
      'Our development teams live and breathe the lean startup approach. All our products, including internal ones, are delivered according to this philosophy. We also take part in regular training sessions and workshops to expand our knowledge about lean startup and product-building.',
  },
  {
    title: 'Contact and first proposal',
    description:
      'After you contact Boldare, we will get in touch to discuss your business needs. We will ask you to share as much information about your product idea as possible so we can start preparing a bespoke solution suited to your needs, and pick team members who will help you build the product. Following that, within 3 - 5 working days you will receive our team’s proposal outlining a preliminary approach and the solution we propose for your product.',
  },
  {
    title: 'Product Workshops with the team',
    description:
      '<p>At this stage, you will meet the entire product team in person at the product workshops. Depending on your specific case we will propose a variety of methods to get to know your product vision, define the strategy and product-building tactics; e.g.</p> <ul class="unordered-list"> <li>Business Canvas</li> <li>Product Canvas</li> <li>User Story Mapping</li> <li>Impact Mapping</li> <li>Event Storming</li> </ul>',
  },
  {
    title: 'Final proposal and release plan',
    description:
      'As a result of these workshops, the Boldare team will advise you on the best approach to deliver the product within the lean startup approach. Our teams work within the scrum framework so the scope of the work is flexible and depends on your needs throughout the entire development process.',
  },
];
