import React from 'react';
import { Paragraph, Heading, Section, SectionHeader } from 'components';
import { data } from './logic';
import './ExceptionalValue.scss';

const renderBoxes = () =>
  data.map((item, index) => (
    <div className={'pt__team-box'} key={item.image}>
      <div className={'pt__team-text'}>
        <Heading type={3} size={'medium-plus'}>
          {item.title}
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          {item.text}
        </Paragraph>
      </div>
      <div className={'pt__team-image'}>
        <span className={'pt__team-image--number'}>{index + 1}</span>
      </div>
    </div>
  ));

const ExceptionalValue = () => {
  return (
    <Section containerClass={'exceptional-value-section'}>
      <SectionHeader size={'large'} lineColor={'violet'} fixed>
        See why clients like you find exceptional value with us
      </SectionHeader>
      <Paragraph size={'medium-plus-small'}>
        <span className={'text--bold'}>Long story short:</span> we consistently
        deliver and deeply value our relationships with other companies.
        Whether we are working on an innovative AI product or a beautiful and
        functional design, our value is based on the same pillars
      </Paragraph>
      <div className={'pt__team-images exceptional-value-section__boxes'}>
        {renderBoxes()}
      </div>
    </Section>
  );
};

export default ExceptionalValue;
