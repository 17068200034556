import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';

const Question = props => {
  const { content } = props;

  return (
    <Heading
      additionalClass={'pmf-survey__question-content'}
      type={2}
      size={'medium-plus'}
    >
      {content}
    </Heading>
  );
};

Question.propTypes = {
  content: PropTypes.string.isRequired,
};

Question.defaultProps = {};

export default Question;
