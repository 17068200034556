import React, { Component } from 'react';
import {
  CaseStudyBanner,
  FullwidthImage,
  ContactFormServices,
} from 'components';
import { getCloudinaryUrl, enableAnimations } from 'config/helpers';
import {
  Banner,
  Problem,
  Stages,
  CollaborationProcess,
  AcrossIndustries,
  OurArticles,
  OtherServices,
} from './subcomponents';
import './FullProductDevelopmentCycle.scss';

class FullProductDevelopmentCycle extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;

    return (
      <div className={'single-page single-page--hero fpdc'}>
        <Banner />
        <Problem />
        <Stages />
        <CaseStudyBanner
          link={'/work/case-study-polco/'}
          color={'green'}
          textColor={'white'}
          image={getCloudinaryUrl(
            'gatsby-cloudinary/polco-app-mockup-mood.png',
            'f_auto,q_auto,w_1150',
          )}
          logo={getCloudinaryUrl(
            'gatsby-cloudinary/polco-white.png',
            'f_auto,q_auto,w_150',
          )}
          client={'Polco'}
          title={
            'How Polco.us used the lean startup approach to deliver a voting platform.'
          }
          tags={['javascript', 'lean', 'user-centered']}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'gatsby-cloudinary/two-woman-having-online-meeting-with-client.jpg',
            'f_auto,q_auto',
          )}
        />
        <CollaborationProcess />
        <AcrossIndustries allArticles={allArticles} />
        <ContactFormServices
          id={'fcpd-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x8f'}
        />
        <OurArticles allArticles={allArticles} />
        <OtherServices />
      </div>
    );
  }
}

export default FullProductDevelopmentCycle;
