import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Paragraph, Heading, Link } from 'components';
import './BoxCaseStudyHover.scss';

class BoxCaseStudyHover extends Component {
  render() {
    const { header, content, hover } = this.props;

    return (
      <Box backgroundColor={'mine-shaft'} additionalClass={'box-cs-hover'}>
        {header && (
          <Heading
            type={3}
            size={'medium'}
            color={'white'}
            additionalClass={'box-cs-hover__header'}
          >
            {ReactHtmlParser(header)}
          </Heading>
        )}
        {content && (
          <Paragraph
            size={'regular'}
            color={'white'}
            additionalClass={'box-cs-hover__content'}
          >
            {ReactHtmlParser(content)}
          </Paragraph>
        )}
        {hover && (
          <Paragraph additionalClass={'box-cs-hover__cta'}>
            <Link
              to={'/contact/'}
              className={'box-cs-hover__link contact-button'}
              id={'box-cs-hover'}
            >
              <span className={'text--light'}>
                {"We'd love to help with your product. "}
              </span>
              <span id={'box-cs-hover__cta'} className={'text--bold'}>
                {"Let's"}&nbsp;{'talk'}
              </span>
            </Link>
          </Paragraph>
        )}
      </Box>
    );
  }
}

BoxCaseStudyHover.defaultProps = {
  header: [],
  content: '',
  hover: false,
};

BoxCaseStudyHover.propTypes = {
  header: PropTypes.array,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
    PropTypes.string,
  ]),
  hover: PropTypes.bool,
};

export default BoxCaseStudyHover;
