import companyData from '../../../../../meta/companyData';

export default [
  {
    number: companyData.technologies,
    content: 'incompany technologieën',
    id: 'one',
  },
  {
    number: companyData.product_strategists,
    content: 'product strategists met meer dan 20 relevante certificeringen',
    id: 'two',
  },
  {
    number: companyData.released_products,
    content: 'ontwikkelde producten',
    id: 'three',
  },
  {
    number: companyData.women_leaders,
    content: 'van ons leadership bestaat uit vrouwen',
    id: 'four',
  },
];
