import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { BoxProfile, Heading, Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import StripedCircleBig from 'assets/images/contact-de/striped-circle-big.svg';
import StripedCircleSmall from 'assets/images/contact-de/striped-circle-small.svg';
import Netherlands from 'assets/images/contact/netherlands-flag.svg';

import './SuperHeroes.scss';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';

const SuperHeroes = () => (
  <Section
    additionalClass={'contact-nl-super-hero__section'}
    id={'connect-nl-super-heroes'}
  >
    <Row
      center={'md'}
      start={'lg'}
      className={
        'contact-nl-super-hero--position-relative contact-nl-super-hero__text-row'
      }
    >
      <div className={'contact-nl-super-hero__small-circle-wrapper'}>
        <div
          className={
            'contact-nl-super-hero__circle__line contact-nl-super-hero__small-circle__line--position'
          }
        />
        <div>
          <img
            src={StripedCircleSmall}
            className={'image'}
            alt={'Striped circle'}
            loading={'lazy'}
          />
        </div>
      </div>
      <Col>
        <Col md={12} lg={6}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            buttonColor={'black'}
            fixed
            noLine
            additionalClass={'contact-nl-super-hero__text-header'}
          >
            Kom gerust langs!
          </SectionHeader>
        </Col>
        <Col
          sm={12}
          lg={7}
          className={'contact-nl-super-hero__text-header-secondary-col'}
        >
          <Heading
            type={2}
            size={'medium-constant'}
            color={'black'}
            additionalClass={'contact-nl-super-hero__text-header-secondary'}
          >
            Op deze nieuwe locatie vind je onze collega Matt Hallmann, die
            verantwoordelijk is voor onze internationale groei. Matt ontmoet je
            graag op ons kantoor in Amsterdam en beantwoord alle vragen over
            wat wij voor je kunnen betekenen.
          </Heading>
        </Col>
      </Col>
    </Row>
    <Row
      center={'md'}
      end={'lg'}
      className={'no-gap contact-nl-super-hero--position-relative'}
    >
      <Col
        sm={12}
        md={6}
        lg={7}
        className={
          '' +
          'contact-nl-super-hero--position-relative ' +
          'contact-nl-super-hero__img-col-big'
        }
      >
        <div className={'contact-nl-super-hero__big-circle-wrapper'}>
          <div
            className={
              'contact-nl-super-hero__circle__line contact-nl-super-hero__big-circle__line--position'
            }
          />
          <div className={'contact-nl-super-hero__big-circle--position'}>
            <img
              src={StripedCircleBig}
              className={'image'}
              alt={'Striped circle'}
              loading={'lazy'}
            />
          </div>
          <div>
            <img
              src={getCloudinaryUrl(
                'contact-page/contact-heroes-map-NL',
                'w_572',
              )}
              className={'image'}
              alt={'Amsterdam map'}
              loading={'lazy'}
            />
          </div>
        </div>
      </Col>
      <Col
        sm={12}
        md={6}
        lg={5}
        className={
          '' +
          'contact-nl-super-hero--position-relative ' +
          'contact-nl-super-hero__img-col '
        }
      >
        <BoxProfile
          photo={getCloudinaryUrl(
            'gatsby-cloudinary/boldare-contact-de-super-hero-matt-hallmann.png',
            'v1645992258',
          )}
          name={'Matt Hallmann'}
          job={'Business Development'}
          linkedin={'https://www.linkedin.com/in/matthallmann/'}
          email={'mailto:matt.hallmann@boldare.com'}
          phone={CountryContactPhoneNumbers.Netherlands.displayValue}
          flag={Netherlands}
          additionalClass={
            'contact-nl-super-hero__profile-higher contact-nl-super-hero__profile'
          }
          lang={'nl'}
        />
      </Col>
    </Row>
  </Section>
);

export default SuperHeroes;
