import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  SectionHeader,
  UnorderedList,
  Paragraph,
  Heading,
  IconSVG,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './What.scss';

const What = () => (
  <>
    <Section
      color={'red'}
      additionalClass={'digital-transformation__mapping'}
      spacedTop
    >
      <SectionHeader
        size={'big'}
        color={'white'}
        lineColor={'violet'}
        headingType={2}
      >
        Mapping out Digital Transformation
      </SectionHeader>
    </Section>
    <Section
      color={'white'}
      spaceless
      fullwidth
      side={'right'}
      additionalClass={'digital-transformation__mapping-description'}
    >
      <Row className={'no-gap'}>
        <Col
          sm={12}
          md={6}
          lg={4}
          className={'digital-transformation__mapping-container'}
        >
          <div
            className={
              'section w-container--right digital-transformation__mapping-inner'
            }
          >
            <SectionHeader size={'small'} lineColor={'black'} headingType={3}>
              What is business Digital Transformation?
            </SectionHeader>
            <Paragraph additionalClass={'text--light'} spaced>
              Companies around the world are changing the way they operate
              thanks to the use of digital technology. Digital Transformation
              can include many aspects of your business such as:
            </Paragraph>
            <UnorderedList
              additionalClass={'text--black text--default'}
              items={[
                'digitizing your core product or service',
                'improving the customer experience across channels',
                'changing the business model and shifting the revenue model',
                'improving supply chains and distribution networks',
                'optimizing internal operations',
              ]}
            />
            <Paragraph additionalClass={'text--light'} spaced>
              However, a digital transformation goes far beyond the mere use of
              technology, it involves a shift of mindset towards becoming an
              innovation-driven, agile organization that moves quickly, learns
              constantly and adapts to changing circumstances and customer
              needs.
            </Paragraph>
          </div>
        </Col>
        <Col sm={12} md={6} lg={8}>
          <div className={'digital-transformation__mapping-image'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/internal-meeting-with-presentation.jpg',
                'f_auto,q_auto',
              )}
              className={'image'}
              alt={'What is business Digital Transformation?'}
              loading={'lazy'}
            />
          </div>
        </Col>
      </Row>
    </Section>
    <Section color={'white'}>
      <IconSVG name={'quote'} size={'medium'} color={'black'} />
      <Heading
        color={'red'}
        size={'medium-plus'}
        type={3}
        additionalClass={'digital-transformation__testimonial'}
      >
        More than 80% of companies say the biggest risk associated with
        digitization is not embracing it.
      </Heading>
      <Paragraph
        color={'black'}
        size={'small'}
        additionalClass={'text--light'}
      >
        (Source: Harvard Business Review Analytics Services Report)
      </Paragraph>
    </Section>
  </>
);

export default What;
