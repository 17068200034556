import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Shapes
import { ReactComponent as Donut } from './donut.svg';
import { ReactComponent as Squares } from './squares.svg';
import { ReactComponent as Rectangles } from './rectangle.svg';
import { ReactComponent as Semicircle } from './semicircles.svg';

const AnimatedShapes = ({ isVisible }) => {
  return (
    <motion.div className={'dsgn-hero__animated-results-shapes'}>
      <AnimatePresence>
        {isVisible && (
          <>
            <motion.div
              animate={{
                x: 0,
                y: 0,
                scale: 1,
                transition: { ease: 'circOut', duration: 1.2 },
              }}
              initial={{ x: 4000, y: -4000, scale: 15 }}
              className={'dsgn-hero__animated-results-semicircle'}
              exit={{
                x: 4000,
                y: -4000,
                scale: 15,
                transition: { duration: 1.2, ease: 'circIn' },
              }}
            >
              <Semicircle />
            </motion.div>
            <motion.div
              animate={{
                y: 0,
                scale: 1,
                transition: { duration: 1.2, ease: 'circOut' },
              }}
              exit={{
                y: 4000,
                scale: 15,
                transition: { duration: 1.2, ease: 'circIn' },
              }}
              initial={{ y: 4000, scale: 15 }}
              className={'dsgn-hero__animated-results-donut'}
            >
              <Donut />
            </motion.div>
            <motion.div
              animate={{
                y: 0,
                scale: 1,
                transition: { duration: 1.2, ease: 'circOut' },
              }}
              exit={{
                y: -4000,
                scale: 15,
                transition: { duration: 1.2, ease: 'circIn' },
              }}
              initial={{ y: -4000, scale: 15 }}
              className={'dsgn-hero__animated-results-squares'}
            >
              <Squares />
            </motion.div>
            <motion.div
              animate={{
                y: 0,
                scale: 1,
                transition: { duration: 1.2, ease: 'circOut' },
              }}
              exit={{
                y: 4000,
                scale: 15,
                transition: { duration: 1.2, ease: 'circIn' },
              }}
              initial={{ y: 4000, scale: 15 }}
              className={'dsgn-hero__animated-results-rectangle'}
            >
              <Rectangles />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AnimatedShapes;
