import React, { useEffect, useState } from 'react';
import './MobileFooter.scss';
import DesktopFooter from './desktopFooter';
import MobileFooter from './mobileFooter';

const FooterAskCto = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  // if (windowSize.width > 700)
  return <>{windowSize.width > 799 ? <DesktopFooter /> : <MobileFooter />}</>;
};

export default FooterAskCto;
