// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agile-product-builders-js": () => import("./../../../src/pages/agile-product-builders.js" /* webpackChunkName: "component---src-pages-agile-product-builders-js" */),
  "component---src-pages-apb-community-newsletter-js": () => import("./../../../src/pages/apb-community-newsletter.js" /* webpackChunkName: "component---src-pages-apb-community-newsletter-js" */),
  "component---src-pages-assets-index-js": () => import("./../../../src/pages/assets/index.js" /* webpackChunkName: "component---src-pages-assets-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-new-work-js": () => import("./../../../src/pages/blog/new-work.js" /* webpackChunkName: "component---src-pages-blog-new-work-js" */),
  "component---src-pages-bold-growth-meetup-event-confirmation-form-js": () => import("./../../../src/pages/bold-growth-meetup/event-confirmation-form.js" /* webpackChunkName: "component---src-pages-bold-growth-meetup-event-confirmation-form-js" */),
  "component---src-pages-bold-growth-meetup-js": () => import("./../../../src/pages/bold-growth-meetup.js" /* webpackChunkName: "component---src-pages-bold-growth-meetup-js" */),
  "component---src-pages-book-a-discovery-session-js": () => import("./../../../src/pages/book-a-discovery-session.js" /* webpackChunkName: "component---src-pages-book-a-discovery-session-js" */),
  "component---src-pages-bring-a-qualified-lead-js": () => import("./../../../src/pages/bring-a-qualified-lead.js" /* webpackChunkName: "component---src-pages-bring-a-qualified-lead-js" */),
  "component---src-pages-career-face-to-face-js": () => import("./../../../src/pages/career/face-to-face.js" /* webpackChunkName: "component---src-pages-career-face-to-face-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-career-offers-js": () => import("./../../../src/pages/career/offers.js" /* webpackChunkName: "component---src-pages-career-offers-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-nl-js": () => import("./../../../src/pages/contact-nl.js" /* webpackChunkName: "component---src-pages-contact-nl-js" */),
  "component---src-pages-cto-asks-js": () => import("./../../../src/pages/CTO-asks.js" /* webpackChunkName: "component---src-pages-cto-asks-js" */),
  "component---src-pages-digitale-transformation-js": () => import("./../../../src/pages/digitale-transformation.js" /* webpackChunkName: "component---src-pages-digitale-transformation-js" */),
  "component---src-pages-event-zaslepka-js": () => import("./../../../src/pages/event-zaslepka.js" /* webpackChunkName: "component---src-pages-event-zaslepka-js" */),
  "component---src-pages-finding-pmf-amsterdam-js": () => import("./../../../src/pages/finding-pmf-amsterdam.js" /* webpackChunkName: "component---src-pages-finding-pmf-amsterdam-js" */),
  "component---src-pages-finding-pmf-js": () => import("./../../../src/pages/finding-pmf.js" /* webpackChunkName: "component---src-pages-finding-pmf-js" */),
  "component---src-pages-finding-pmf-workshop-confirmation-form-js": () => import("./../../../src/pages/finding-pmf/workshop-confirmation-form.js" /* webpackChunkName: "component---src-pages-finding-pmf-workshop-confirmation-form-js" */),
  "component---src-pages-gitex-2019-js": () => import("./../../../src/pages/gitex-2019.js" /* webpackChunkName: "component---src-pages-gitex-2019-js" */),
  "component---src-pages-hack-the-wind-js": () => import("./../../../src/pages/hack-the-wind.js" /* webpackChunkName: "component---src-pages-hack-the-wind-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-new-normal-js": () => import("./../../../src/pages/new-normal.js" /* webpackChunkName: "component---src-pages-new-normal-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-practical-ai-application-js": () => import("./../../../src/pages/practical-ai-application.js" /* webpackChunkName: "component---src-pages-practical-ai-application-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-program-polecen-potencjalnych-klientow-regulamin-js": () => import("./../../../src/pages/program-polecen-potencjalnych-klientow-regulamin.js" /* webpackChunkName: "component---src-pages-program-polecen-potencjalnych-klientow-regulamin-js" */),
  "component---src-pages-recommend-a-client-js": () => import("./../../../src/pages/recommend-a-client.js" /* webpackChunkName: "component---src-pages-recommend-a-client-js" */),
  "component---src-pages-renewable-energy-industry-js": () => import("./../../../src/pages/renewable-energy-industry.js" /* webpackChunkName: "component---src-pages-renewable-energy-industry-js" */),
  "component---src-pages-rescue-failing-project-js": () => import("./../../../src/pages/rescue-failing-project.js" /* webpackChunkName: "component---src-pages-rescue-failing-project-js" */),
  "component---src-pages-resources-app-costs-calculator-js": () => import("./../../../src/pages/resources/app-costs-calculator.js" /* webpackChunkName: "component---src-pages-resources-app-costs-calculator-js" */),
  "component---src-pages-resources-app-costs-calculator-thank-you-js": () => import("./../../../src/pages/resources/app-costs-calculator-thank-you.js" /* webpackChunkName: "component---src-pages-resources-app-costs-calculator-thank-you-js" */),
  "component---src-pages-resources-code-audit-checklist-js": () => import("./../../../src/pages/resources/code-audit-checklist.js" /* webpackChunkName: "component---src-pages-resources-code-audit-checklist-js" */),
  "component---src-pages-resources-crash-course-js": () => import("./../../../src/pages/resources/crash-course.js" /* webpackChunkName: "component---src-pages-resources-crash-course-js" */),
  "component---src-pages-resources-development-partner-checklist-js": () => import("./../../../src/pages/resources/development-partner-checklist.js" /* webpackChunkName: "component---src-pages-resources-development-partner-checklist-js" */),
  "component---src-pages-resources-development-partner-guide-js": () => import("./../../../src/pages/resources/development-partner-guide.js" /* webpackChunkName: "component---src-pages-resources-development-partner-guide-js" */),
  "component---src-pages-resources-how-to-implement-and-monetize-ai-js": () => import("./../../../src/pages/resources/how-to-implement-and-monetize-AI.js" /* webpackChunkName: "component---src-pages-resources-how-to-implement-and-monetize-ai-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-machine-learning-for-business-js": () => import("./../../../src/pages/resources/machine-learning-for-business.js" /* webpackChunkName: "component---src-pages-resources-machine-learning-for-business-js" */),
  "component---src-pages-resources-new-normal-checklist-js": () => import("./../../../src/pages/resources/new-normal-checklist.js" /* webpackChunkName: "component---src-pages-resources-new-normal-checklist-js" */),
  "component---src-pages-resources-outsourcing-step-by-step-js": () => import("./../../../src/pages/resources/outsourcing-step-by-step.js" /* webpackChunkName: "component---src-pages-resources-outsourcing-step-by-step-js" */),
  "component---src-pages-resources-pmf-survey-js": () => import("./../../../src/pages/resources/pmf-survey.js" /* webpackChunkName: "component---src-pages-resources-pmf-survey-js" */),
  "component---src-pages-resources-product-designer-checklist-js": () => import("./../../../src/pages/resources/product-designer-checklist.js" /* webpackChunkName: "component---src-pages-resources-product-designer-checklist-js" */),
  "component---src-pages-resources-product-development-challenges-js": () => import("./../../../src/pages/resources/product-development-challenges.js" /* webpackChunkName: "component---src-pages-resources-product-development-challenges-js" */),
  "component---src-pages-resources-product-development-team-work-js": () => import("./../../../src/pages/resources/product-development-team-work.js" /* webpackChunkName: "component---src-pages-resources-product-development-team-work-js" */),
  "component---src-pages-resources-product-discovery-handbook-js": () => import("./../../../src/pages/resources/product-discovery-handbook.js" /* webpackChunkName: "component---src-pages-resources-product-discovery-handbook-js" */),
  "component---src-pages-resources-product-discovery-workshop-js": () => import("./../../../src/pages/resources/product-discovery-workshop.js" /* webpackChunkName: "component---src-pages-resources-product-discovery-workshop-js" */),
  "component---src-pages-resources-product-discovery-workshops-js": () => import("./../../../src/pages/resources/product-discovery-workshops.js" /* webpackChunkName: "component---src-pages-resources-product-discovery-workshops-js" */),
  "component---src-pages-resources-product-market-fit-workbook-js": () => import("./../../../src/pages/resources/product-market-fit-workbook.js" /* webpackChunkName: "component---src-pages-resources-product-market-fit-workbook-js" */),
  "component---src-pages-resources-product-owner-checklist-js": () => import("./../../../src/pages/resources/product-owner-checklist.js" /* webpackChunkName: "component---src-pages-resources-product-owner-checklist-js" */),
  "component---src-pages-resources-product-strategy-workbook-js": () => import("./../../../src/pages/resources/product-strategy-workbook.js" /* webpackChunkName: "component---src-pages-resources-product-strategy-workbook-js" */),
  "component---src-pages-resources-remote-work-pdf-js": () => import("./../../../src/pages/resources/remote-work-pdf.js" /* webpackChunkName: "component---src-pages-resources-remote-work-pdf-js" */),
  "component---src-pages-resources-ultimate-scrum-master-toolkit-js": () => import("./../../../src/pages/resources/ultimate-scrum-master-toolkit.js" /* webpackChunkName: "component---src-pages-resources-ultimate-scrum-master-toolkit-js" */),
  "component---src-pages-resources-web-mobile-apps-prototyping-js": () => import("./../../../src/pages/resources/web-mobile-apps-prototyping.js" /* webpackChunkName: "component---src-pages-resources-web-mobile-apps-prototyping-js" */),
  "component---src-pages-resources-what-after-mvp-checklist-js": () => import("./../../../src/pages/resources/what-after-mvp-checklist.js" /* webpackChunkName: "component---src-pages-resources-what-after-mvp-checklist-js" */),
  "component---src-pages-scale-your-ai-solution-meetup-js": () => import("./../../../src/pages/scale-your-AI-solution-meetup.js" /* webpackChunkName: "component---src-pages-scale-your-ai-solution-meetup-js" */),
  "component---src-pages-service-standards-js": () => import("./../../../src/pages/service-standards.js" /* webpackChunkName: "component---src-pages-service-standards-js" */),
  "component---src-pages-services-ai-software-development-consulting-js": () => import("./../../../src/pages/services/ai-software-development-consulting.js" /* webpackChunkName: "component---src-pages-services-ai-software-development-consulting-js" */),
  "component---src-pages-services-code-audit-js": () => import("./../../../src/pages/services/code-audit.js" /* webpackChunkName: "component---src-pages-services-code-audit-js" */),
  "component---src-pages-services-development-teams-js": () => import("./../../../src/pages/services/development-teams.js" /* webpackChunkName: "component---src-pages-services-development-teams-js" */),
  "component---src-pages-services-digital-transformation-js": () => import("./../../../src/pages/services/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-digital-transformation-js" */),
  "component---src-pages-services-full-cycle-product-development-index-js": () => import("./../../../src/pages/services/full-cycle-product-development/index.js" /* webpackChunkName: "component---src-pages-services-full-cycle-product-development-index-js" */),
  "component---src-pages-services-full-cycle-product-development-prototyping-js": () => import("./../../../src/pages/services/full-cycle-product-development/prototyping.js" /* webpackChunkName: "component---src-pages-services-full-cycle-product-development-prototyping-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-machine-learning-js": () => import("./../../../src/pages/services/machine-learning.js" /* webpackChunkName: "component---src-pages-services-machine-learning-js" */),
  "component---src-pages-services-mvp-development-js": () => import("./../../../src/pages/services/mvp-development.js" /* webpackChunkName: "component---src-pages-services-mvp-development-js" */),
  "component---src-pages-services-product-design-and-development-js": () => import("./../../../src/pages/services/product-design-and-development.js" /* webpackChunkName: "component---src-pages-services-product-design-and-development-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-software-development-outsourcing-js": () => import("./../../../src/pages/services/software-development-outsourcing.js" /* webpackChunkName: "component---src-pages-services-software-development-outsourcing-js" */),
  "component---src-pages-services-systemic-digital-transformation-js": () => import("./../../../src/pages/services/systemic-digital-transformation.js" /* webpackChunkName: "component---src-pages-services-systemic-digital-transformation-js" */),
  "component---src-pages-services-your-awwwesome-designers-js": () => import("./../../../src/pages/services/your-awwwesome-designers.js" /* webpackChunkName: "component---src-pages-services-your-awwwesome-designers-js" */),
  "component---src-pages-services-your-development-partner-js": () => import("./../../../src/pages/services/your-development-partner.js" /* webpackChunkName: "component---src-pages-services-your-development-partner-js" */),
  "component---src-pages-software-development-design-saudi-arabia-js": () => import("./../../../src/pages/software-development-design-saudi-arabia.js" /* webpackChunkName: "component---src-pages-software-development-design-saudi-arabia-js" */),
  "component---src-pages-sprint-retrospective-tool-js": () => import("./../../../src/pages/sprint-retrospective-tool.js" /* webpackChunkName: "component---src-pages-sprint-retrospective-tool-js" */),
  "component---src-pages-transform-gaps-into-opportunities-workshop-event-confirmation-form-js": () => import("./../../../src/pages/transform-gaps-into-opportunities-workshop/event-confirmation-form.js" /* webpackChunkName: "component---src-pages-transform-gaps-into-opportunities-workshop-event-confirmation-form-js" */),
  "component---src-pages-transform-gaps-into-opportunities-workshop-js": () => import("./../../../src/pages/transform-gaps-into-opportunities-workshop.js" /* webpackChunkName: "component---src-pages-transform-gaps-into-opportunities-workshop-js" */),
  "component---src-pages-ux-ui-design-consulting-services-js": () => import("./../../../src/pages/ux-ui-design-consulting-services.js" /* webpackChunkName: "component---src-pages-ux-ui-design-consulting-services-js" */),
  "component---src-pages-webinar-accelerate-digitization-in-your-company-js": () => import("./../../../src/pages/webinar/accelerate-digitization-in-your-company.js" /* webpackChunkName: "component---src-pages-webinar-accelerate-digitization-in-your-company-js" */),
  "component---src-pages-webinar-agile-for-your-company-js": () => import("./../../../src/pages/webinar/agile-for-your-company.js" /* webpackChunkName: "component---src-pages-webinar-agile-for-your-company-js" */),
  "component---src-pages-webinar-code-audit-js": () => import("./../../../src/pages/webinar/code-audit.js" /* webpackChunkName: "component---src-pages-webinar-code-audit-js" */),
  "component---src-pages-webinar-improve-dev-team-communication-js": () => import("./../../../src/pages/webinar-improve-dev-team-communication.js" /* webpackChunkName: "component---src-pages-webinar-improve-dev-team-communication-js" */),
  "component---src-pages-when-to-invest-in-ux-webinar-js": () => import("./../../../src/pages/when-to-invest-in-ux-webinar.js" /* webpackChunkName: "component---src-pages-when-to-invest-in-ux-webinar-js" */),
  "component---src-pages-work-case-study-corel-js": () => import("./../../../src/pages/work/case-study-corel.js" /* webpackChunkName: "component---src-pages-work-case-study-corel-js" */),
  "component---src-pages-work-case-study-oneida-js": () => import("./../../../src/pages/work/case-study-oneida.js" /* webpackChunkName: "component---src-pages-work-case-study-oneida-js" */),
  "component---src-pages-work-case-study-pakt-js": () => import("./../../../src/pages/work/case-study-pakt.js" /* webpackChunkName: "component---src-pages-work-case-study-pakt-js" */),
  "component---src-pages-work-case-study-planet-escape-js": () => import("./../../../src/pages/work/case-study-planet-escape.js" /* webpackChunkName: "component---src-pages-work-case-study-planet-escape-js" */),
  "component---src-pages-work-case-study-practitest-js": () => import("./../../../src/pages/work/case-study-practitest.js" /* webpackChunkName: "component---src-pages-work-case-study-practitest-js" */),
  "component---src-pages-work-case-study-showbiz-js": () => import("./../../../src/pages/work/case-study-showbiz.js" /* webpackChunkName: "component---src-pages-work-case-study-showbiz-js" */),
  "component---src-pages-work-case-study-spa-monkeys-js": () => import("./../../../src/pages/work/case-study-spa-monkeys.js" /* webpackChunkName: "component---src-pages-work-case-study-spa-monkeys-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-why-design-matters-js": () => import("./../../../src/pages/work/why-design-matters.js" /* webpackChunkName: "component---src-pages-work-why-design-matters-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-case-study-page-js": () => import("./../../../src/templates/case-study-page.js" /* webpackChunkName: "component---src-templates-case-study-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-external-resource-page-js": () => import("./../../../src/templates/external-resource-page.js" /* webpackChunkName: "component---src-templates-external-resource-page-js" */),
  "component---src-templates-job-offer-page-js": () => import("./../../../src/templates/job-offer-page.js" /* webpackChunkName: "component---src-templates-job-offer-page-js" */),
  "component---src-templates-keyword-page-js": () => import("./../../../src/templates/keyword-page.js" /* webpackChunkName: "component---src-templates-keyword-page-js" */),
  "component---src-templates-review-page-js": () => import("./../../../src/templates/review-page.js" /* webpackChunkName: "component---src-templates-review-page-js" */)
}

