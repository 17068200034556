import React from 'react';
import './KeyImage.scss';
import { getCloudinaryUrl } from 'config/helpers';

const CybersixgillKeyImage = () => {
  return (
    <div className={'cybersixgill-keyimage'}>
      <img
        src={getCloudinaryUrl(
          'cybersixgill/solution_key.png',
          'f_auto,q_auto',
        )}
        className={'image'}
        alt={'Arrow'}
        loading={'lazy'}
      />
    </div>
  );
};

export default CybersixgillKeyImage;
