import clutch from 'assets/images/home/prizes/mainBanner/clutch-logo-noslogan.svg';
import collageAwards from 'assets/images/home/prizes/mainBanner/collage-awards.png';
import companyData from '../../../../../meta/companyData';

export const awardsContentA = {
  top: [
    { type: 'homepage/awards/awwwards_honorable.png' },
    { type: 'homepage/awards/lovie_awards.png' },
    { type: 'homepage/awards/webby_awards.png' },
  ],
  bottom: [
    { type: 'homepage/awards/gwa_awards.png', isBig: false },
    { type: 'homepage/awards/indigo_awards.png', isBig: false },
    { type: 'homepage/awards/css_awards.png', isBig: false },
    { type: 'homepage/awards/cssdesign_awards.png', isBig: false },
  ],
};

export const footerElements = [
  {
    icon: 'homepage/awards/inc_logo.png',
    text:
      'Europe’s Fastest-Growing Company 2018 for XSolve (one of Boldare’s founding companies)',
  },
  {
    icon: 'homepage/awards/gq_logo.png',
    text: 'Co-CEO featured in GQ interview',
  },
];

export const bannerData = {
  awards: {
    logo: 'awards',
    collage: 'collageAwards',
    number: companyData.awwwards,
    text: `${companyData.awwwards} prestigious honors thanks to our award-winning recipe`,
    link: {
      text: 'Are we really so good at web design? Find out! ',
      url: 'https://www.awwwards.com/Boldare/',
    },
  },
  clutch: {
    logo: 'clutch',
    number: null,
    text: `We are proud to hold an outstanding ${companyData.clutch_rate}/5 Clutch rating from ${companyData.clutch_reviews} verified partner reviews.`,
    textSecond: `Clutch.co is a trusted service provider review site`,
    link: {
      text: `Read our clients' reviews`,
      url: 'https://clutch.co/profile/boldare/',
    },
  },
};

export const bannerImages = {
  clutch,
  collageAwards,
};

export const statsContent = [
  {
    id: 1,
    decoration: `${companyData.products}+`,
    rotation: '-16deg',
    text: 'Digital products released',
  },
  {
    id: 2,
    decoration: '80%',
    rotation: '12deg',
    text: 'Customer retention',
  },
  {
    id: 3,
    decoration: `${companyData.employees}+`,
    rotation: '-3deg',
    text: 'Problem-solvers on board',
  },
  {
    id: 4,
    decoration: '30%',
    rotation: '5deg',
    text: 'Revenue growth year on year',
  },
  {
    id: 5,
    decoration: companyData.offices,
    rotation: '5deg',
    text: 'Offices in Poland, Germany and the Netherlands',
  },
  {
    id: 6,
    decoration: '20+',
    rotation: '-6deg',
    text: 'Technologies in house',
  },
  {
    id: 7,
    decoration: '1',
    rotation: '-9deg',
    text: 'Service standard for all clients',
  },
  {
    id: 8,
    decoration: '130+',
    rotation: '2deg',
    text: 'Clients worldwide',
  },
];
