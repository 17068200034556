import './ShowCase.scss';

import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Speaker from './Speaker';
import speakers from './speakers';

const ShowCase = () => {
  return (
    <Row className={'showcase-row'}>
      {speakers.map(speaker => {
        return (
          <Col md={12} lg={3} className={'showcase-column'}>
            <Speaker
              img={speaker.img}
              firstName={speaker.first_name}
              lastName={speaker.last_name}
              title={speaker.title}
              bio={speaker.bio}
              linkedin={speaker.linkedin}
              active={speaker.active}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ShowCase;
