import React from 'react';
import { BackgroundImage, Heading } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { DesktopSlider, TextSlider } from './subcomponents';
import { SliderAsNav, TextInteractionAnimation } from '../index';
import { data } from './data';
import './TeammatesSection.scss';

const TeammatesSection = () => {
  return (
    <section id={'f2f-teammates'} className={'f2f-teammates-section'}>
      <BackgroundImage
        src={getCloudinaryUrl('f2f-landing/f2f-teammates.png', 'f_auto,w_621')}
        alt={'Working at Boldare'}
        additionalClass={'f2f-teammates-section__background-image'}
      />
      <Heading
        additionalClass={'f2f-teammates-section__heading'}
        type={2}
        size={'extra-large'}
      >
        <p>
          Check what our
          <br />
          teammates <TextInteractionAnimation text={'say'} />
          <br />
          about work & fun!
        </p>
      </Heading>
      {/* DesktopSlider is displayed on desktop and SliderAsNav on mobile. Due to GlideJS issues
      their visibility couldn't be easily conditioned here based on window width */}
      <DesktopSlider
        id={'f2f-teammates-section__desktop-slider'}
        items={data}
      />
      <SliderAsNav
        items={data}
        sliderAsNavId={'f2f-teammates-section__slider-as-nav'}
        sliderToBeNavigatedId={'f2f-teammates-section__slider-to-be-navigated'}
        sliderToBeNavigatedOptions={{
          type: 'carousel',
          animationDuration: 700,
          animationTimingFunc: 'ease-out',
          gap: 40,
          perView: 1,
          startAt: 0,
          focusAt: 0,
        }}
        componentToBeNavigated={<TextSlider items={data} />}
      />
    </section>
  );
};

export default TeammatesSection;
