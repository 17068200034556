import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  IconSVG,
  Button,
  UnorderedList,
} from 'components';

class WhatWeUse extends Component {
  constructor() {
    super();

    this.state = {
      isFullFirstShown: false,
      isFullSecondShown: false,
    };
  }

  toggleFirstDescription = () =>
    this.setState(prevState => ({
      isFullFirstShown: !prevState.isFullFirstShown,
    }));

  toggleSecondDescription = () =>
    this.setState(prevState => ({
      isFullSecondShown: !prevState.isFullSecondShown,
    }));

  render() {
    const { isFullFirstShown, isFullSecondShown } = this.state;

    return (
      <Section
        color={'mine-shaft'}
        additionalClass={'machine-learning--no-padding'}
      >
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionHeader
              size={'big'}
              color={'white'}
              headingType={2}
              lineColor={'red'}
            >
              Machine Learning Services
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            <div className={'machine-learning__process-description'}>
              <Paragraph color={'white'} size={'medium'}>
                From consulting to solution delivery our cross-functional
                machine learning teams drive value for your business every step
                of the way, putting your data to work:
              </Paragraph>
            </div>
          </Col>
        </Row>
        <Section
          color={'gray-light'}
          additionalClass={'machine-learning__wwu'}
          fullwidth
        >
          <Row
            className={'no-gap anim-fade-in-bottom machine-learning__wwu-list'}
          >
            <Col sm={12} md={6} className={'machine-learning__wwu-item'}>
              <IconSVG
                name={'customer-relation'}
                size={'large'}
                color={'black'}
              />
              <Heading size={'big'} type={3}>
                Consulting
              </Heading>
              <Paragraph size={'medium'}>
                Boldare’s dedicated experts will work with you to define what
                troubles your company. We will dive deep into your data and
                business surroundings to get a thorough understanding of your
                entire ecosystem. We will define whether AI has an application
                for your company or digital product and if so, clearly identify
                the potential benefits
              </Paragraph>
              {isFullFirstShown && (
                <>
                  <Paragraph color={'black'} size={'medium'}>
                    <br />
                    Our analysts and solution architects will cooperate closely
                    with you every step of the way to ensure constant
                    communication and transparency. The final product of the
                    consulting service is a comprehensive report with at least
                    two possible machine learning solutions, fully personalized
                    to your business problem and specific needs.
                    <br />
                    <br />
                    How we can help:
                  </Paragraph>
                  <UnorderedList
                    additionalClass={'text--black'}
                    columns={1}
                    items={[
                      '<strong>Support digital transformation</strong> through data analysis',
                      '<strong>Boost sales</strong> with customer behaviour analysis',
                      '<strong>Identify documentation processing cost savings</strong> with Natural Language Processing',
                      '<strong>Facilitate architecture migration</strong> from local machines to cloud platforms',
                    ]}
                  />
                </>
              )}
              <div
                className={'fpdc__learn-more-button'}
                onClick={this.toggleFirstDescription}
              >
                <Button
                  order={'secondary'}
                  arrowColor={'black'}
                  ariaName={'Description button'}
                >
                  {isFullFirstShown ? 'Show less' : 'Show more'}
                </Button>
              </div>
            </Col>
            <Col sm={12} md={6} className={'machine-learning__wwu-item'}>
              <IconSVG name={'eye'} size={'large'} color={'black'} />
              <Heading size={'big'} type={3}>
                Software development
              </Heading>
              <Paragraph size={'medium'}>
                Whether you want to move forward with recommendations from our
                Consulting Services or have an idea for AI-based product or
                feature, this is the place. Our experienced delivery team will
                build a bespoke machine learning solution for your business. We
                will prepare the necessary infrastructure and data, build and
                deploy models, and integrate them with your software.
              </Paragraph>
              {isFullSecondShown && (
                <>
                  <Paragraph color={'black'} size={'medium'}>
                    <br />
                    Our experts will work closely with you throughout the
                    entire process, delivering the machine learning product in
                    increments, ensuring the best possible results. At the end
                    of every release you will get a fully working and testable
                    solution to your business problem.
                    <br />
                    <br />
                    How we can help:
                  </Paragraph>
                  <UnorderedList
                    additionalClass={'text--black'}
                    columns={1}
                    items={[
                      '<strong>Recommendation engine</strong> suggesting content to website users',
                      '<strong>Virtual Assistant</strong> helping employees navigate in company documentation and best practices',
                      '<strong>Personalized Marketing</strong> allowing personalised and targeted marketing campaigns for each user',
                      '<strong>Forecasting algorithm</strong> taking advantage of seasonality, trends and customer behaviours to suggest supply level',
                    ]}
                  />
                </>
              )}
              <div
                className={'fpdc__learn-more-button'}
                onClick={this.toggleSecondDescription}
              >
                <Button
                  order={'secondary'}
                  arrowColor={'black'}
                  ariaName={'Description button'}
                >
                  {isFullSecondShown ? 'Show less' : 'Show more'}
                </Button>
              </div>
            </Col>
          </Row>
        </Section>
      </Section>
    );
  }
}

export default WhatWeUse;
