import React from 'react';
import PropTypes from 'prop-types';

import { getCloudinaryUrl } from 'config/helpers';

import './Orbit.scss';

export const Orbit = ({ theme, hideMoon, additionalOrbit }) => (
  <div className={'orbit__container'}>
    <div className={`orbit orbit--${theme}`}>
      <img
        src={getCloudinaryUrl(
          'design-rush/orbit.svg',
          'f_auto,q_auto,w_auto'
        )}
        alt={'Orbit'}
        loading={'lazy'}
      />
    </div>
    {!hideMoon && (
      <div className={'orbit__moon-container'}>
        <div className={'orbit__moon'} />
      </div>
    )}
    {additionalOrbit && (
      <div className={'orbit__additional-orbit'} />
    )}
  </div>
)

Orbit.defaultProps = {
  theme: 'dark',
  hideMoon: false,
  additionalOrbit: false
};

Orbit.propTypes = {
  theme: PropTypes.string,
  hideMoon: PropTypes.bool,
  additionalOrbit: PropTypes.bool,
};
