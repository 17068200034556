import React, { Component } from 'react';
import { Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { data } from './logic';
import './Clients.scss';

class Clients extends Component {
  renderItems = () =>
    data.map((item, index) => (
      <div className={'code-clients__item'} key={`award-${index}`}>
        <img
          src={getCloudinaryUrl(
            `gatsby-cloudinary/${item.image}`,
            `f_auto,q_auto,${item.width}`,
          )}
          className={classNames('image', 'code-clients__image', {
            'code-clients__image--zero-brightness': item.black,
          })}
          style={{
            width: 'auto',
            height: 'auto',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          alt={item.name}
          loading={'lazy'}
        />
      </div>
    ));

  render() {
    return (
      <>
        <Section additionalClass={'code-audit__clients'} spaceless>
          <div className={'section code-clients__inner'}>
            <SectionHeader
              additionalClass={
                'section-header__description--medium code-banner__content'
              }
              color={'black'}
              lineColor={'red'}
              size={'large'}
              headingType={2}
            >
              Our Clients
            </SectionHeader>
            <div className={'code-clients__list anim-fade-in-bottom'}>
              {this.renderItems()}
            </div>
          </div>
        </Section>
      </>
    );
  }
}

export default Clients;
