export const data = [
  {
    name: 'JavaScript',
    image: 'javascript',
    img: 'logos/logo-javascript.png',
  },
  {
    name: 'CakePHP',
    image: 'cakephp',
    img: 'logos/logo-cakephp.png',
  },
  {
    name: 'HTML 5',
    image: 'html',
    img: 'logos/logo-html.png',
  },
  {
    name: 'jQuery',
    image: 'jquery',
    img: 'logos/logo-jquery.png',
  },
];
