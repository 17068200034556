import React from 'react';
import { Section, ContactForm, Heading, Paragraph } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';
import './Contact.scss';

const header = () => (
  <div className={'discoverai-contact__header'}>
    <Heading size={'large'}>Let us know what you need</Heading>
    <Paragraph size={'medium-plus-small'}>
      Describe your idea and we will be back to you in 24 hours
    </Paragraph>
  </div>
);

const Contact = React.forwardRef(({ widerDisplay }, ref) => (
  <Section
    additionalClass={'contact-banner'}
    color={'yellow'}
    spaceless
    fullwidth
    id={'contact'}
  >
    <Row className={'no-gap contact-banner__row'}>
      <Col md={9} lg={8} xl={8}>
        <>
          <div
            ref={ref}
            className={`${
              widerDisplay
                ? 'contact-banner__content--wider'
                : 'contact-banner__content'
            }`}
            style={{ backgroundColor: '#f2da3a' }}
          >
            <ContactForm.V2 id={'form'} name={'name'} header={header} />
          </div>
        </>
      </Col>
      <Col md={3} lg={4} xl={4} className={'contact-page__sidebar'}>
        <div className={'discoverai__sidebar-photo'}>
          <img
            src={getCloudinaryUrl(
              'img/Olivier.png',
              'f_auto,q_auto,w_auto',
            )}
            alt={'business-consultant'}
          />
          <div className={'discoverai__sidebar-photo-person-information'}>
            <Heading color={'white'} size={'medium-plus-small'}>
              Olivier Halupczok
            </Heading>
            <Paragraph color={'white'} size={'medium-plus-small'}>
              Generative AI Engineer
            </Paragraph>
          </div>
        </div>
      </Col>
    </Row>
  </Section>
));

Contact.propTypes = {
  enableDecorations: PropTypes.bool,
  color: PropTypes.string,
  sidebarColor: PropTypes.string,
  widerDisplay: PropTypes.bool,
};

Contact.defaultProps = {
  enableDecorations: true,
  color: 'white',
  sidebarColor: 'seashell',
  widerDisplay: true,
};

export default Contact;
