import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { pushToDataLayer } from 'utils';

const useFirstSeenTrigger = callback => {
  const [seen, setSeen] = useState(false);
  const { ref, inView } = useInView({ threshold: 0 });
  useEffect(() => {
    if (seen || !inView) return;
    setSeen(true);
    callback();
  }, [inView, callback]);
  const x = useRef();
  return ref;
};

const reportSplitTestOnce = ({ event, key }) => {
  const isWindow = typeof window !== 'undefined';
  if (!isWindow) return;
  const alreadyReported = window.sessionStorage.getItem(key);
  if (!alreadyReported) {
    window.sessionStorage.setItem(key, true);
    pushToDataLayer(event);
  }
};

export const useSplitTest = ({ event, key }) => {
  return useFirstSeenTrigger(() => reportSplitTestOnce({ event, key }));
};
