import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ContactBanner } from 'views/contact/subcomponents';
import {
  ExperiencePanel,
  Benefits,
  SpecialistInfo,
  Services,
  KeywordHero,
  RelatedArticles,
  Technologies,
  ConsultingServices,
} from '..';

import '../../SingleKeywordPage.scss';

const KeywordContent = ({ data, allArticles, slug }) => {
  const contactFormRef = useRef(null);
  const {
    hero,
    benefits,
    experience,
    services,
    technologies,
    related,
    specialists,
  } = data;

  const isSpecialistsEnabled =
    slug === 'devops-consulting-services' ||
    'ai-software-development-consulting-2';

  const handleFormScroll = () => {
    if (contactFormRef.current) {
      const topPosition =
        contactFormRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 30;
      window.scrollTo({ top: topPosition - offset, behavior: 'smooth' });
    }
  };

  return (
    <>
      <KeywordHero data={hero} onButtonClick={handleFormScroll} />
      <ExperiencePanel data={experience} />
      <ConsultingServices data={services} />
      <Benefits data={benefits} />
      {isSpecialistsEnabled && <SpecialistInfo data={specialists} />}
      <Services />
      <Technologies data={technologies} />
      {related && (
        <RelatedArticles
          id={'keyword-page-related'}
          allArticles={allArticles}
          relatedArticles={related}
        />
      )}
      <ContactBanner
        ref={contactFormRef}
        enableDecorations={false}
        color={'violet-light'}
        sidebarColor={'violet-light'}
        widerDisplay={false}
      />
    </>
  );
};

KeywordContent.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.object,
    benefits: PropTypes.object,
    experience: PropTypes.object,
    services: PropTypes.object,
    technologies: PropTypes.object,
    related: PropTypes.object,
  }).isRequired,
};

export default KeywordContent;
