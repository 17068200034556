import { BackgroundImage, Heading, Section, TitleAskCto } from 'components';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import AgendaBanner from 'assets/images/logos/askcto-agenda-banner.svg';
import './Agenda.scss';
import Table from './Table';

const Agenda = () => {
  return (
    <Section additionalClass={'cto-ask-agenda'} side={'right'} fullwidth>
      <BackgroundImage
        asset={'gatsby-cloudinary/ctoask-agenda-working-team.jpg'}
        alt={'Prototyping Digital Products'}
        additionalClass={'banner__img background-img--cloudinary--askcto'}
      />
      <Row className={'no-gap about-page__banner-row'}>
        <Col sm={12} md={3} className={'cto-ask-agenda-left'}>
          <img
            id={'agendabanner'}
            src={AgendaBanner}
            alt={'agendabanner'}
            loading={'lazy'}
          />
          <TitleAskCto title={'Event Agenda'} white line={false} />
          <Heading
            type={1}
            color={'white'}
            size={'medium'}
            additionalClass={'agenda-adress'}
          >
            14.10.2021
          </Heading>
          <Heading type={1} color={'white'} size={'medium'}>
            Gliwice, Poland
          </Heading>
        </Col>
        <Col sm={12} md={6} className={'cto-ask-agenda-right'}>
          <Table />
        </Col>
        <Col sm={12} md={1} />
      </Row>
    </Section>
  );
};

export default Agenda;
