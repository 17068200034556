import React from 'react';

import { Section } from 'components';
import { getRecommendClientImageUrl } from '../../utils';

import './Companies.scss';

const LOGOS = [
  'virgin-radio',
  'blablacar',
  'bosch',
  'corel',
  'dahler',
  'dtiq',
  'prisma',
  'sonnen',
  'tui',
];

export default function Companies() {
  return (
    <Section
      containerClass={'bring-a-qualified-lead__companies'}
      spacedTop
      spaceless
    >
      <h2
        className={
          'bring-a-qualified-lead__heading bring-a-qualified-lead__heading--more-spaced'
        }
      >
        Companies that trust us
      </h2>
      <ul>
        {LOGOS.map(logo => (
          <li key={logo}>
            <img
              src={getRecommendClientImageUrl(`logos/${logo}.png`)}
              alt={logo}
            />
          </li>
        ))}
      </ul>
    </Section>
  );
}
