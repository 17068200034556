import React from 'react';
import { Section } from 'components';
import BigNumberOrderedList from '../../../../components/BigNumberOrderedList';

import './SessionOutcomes.scss';

export default function SessionOutcomes() {
  return (
    <Section
      containerClass={'book-discovery-session__session-outcomes'}
      color={'soft-cloud'}
    >
      <h2
        className={
          'book-discovery-session__heading book-discovery-session__session-outcomes__heading'
        }
      >
        Session outcomes:
      </h2>
      <BigNumberOrderedList
        className={'book-discovery-session__session-outcomes__list'}
      >
        <BigNumberOrderedList.Item
          subtextClassName={
            'book-discovery-session__session-outcomes__list__subtext'
          }
          subtext={'on of your best course of action'}
        >
          Clarity
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={
            'book-discovery-session__session-outcomes__list__subtext'
          }
          subtext={'prepared by Boldare experts for you'}
        >
          Detailed solution plan
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={
            'book-discovery-session__session-outcomes__list__subtext'
          }
          subtext={'in your validated and empowered idea'}
        >
          Confidence
        </BigNumberOrderedList.Item>
        <BigNumberOrderedList.Item
          subtextClassName={
            'book-discovery-session__session-outcomes__list__subtext'
          }
          subtext={'to handle objections from stakeholders/investors'}
        >
          Ready-made arguments
        </BigNumberOrderedList.Item>
      </BigNumberOrderedList>
    </Section>
  );
}
