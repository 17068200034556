import React, { useState } from 'react';
import { Section, EventForm } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { Col } from 'react-flexbox-grid';
import TitleEventFpmf from '../TitleEventFpmf/index';

import './Form.scss';

const Form = ({ url }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  if (typeof window !== 'undefined' && window.location) {
    setFormSubmitted(window.location.href.includes('?thank-you'));
  }

  return (
    <Section
      id={'form-link'}
      additionalClass={'event-fpmf-amsterdam__form'}
      color={'mine-shaft'}
    >
      <Col className={'event-fpmf-amsterdam__max-width'}>
        <div className={'event-fpmf-amsterdam__form-inner'}>
          <div className={'wrapper'}>
            <img
              alt={''}
              className={`netflix ${formSubmitted &&
                'netflix-form-submitted'}`}
              src={getCloudinaryUrl(
                'event/amsterdam/Kolaz_formularz.png',
                'f_auto,q_auto,w_1000',
              )}
            />
          </div>
        </div>
        {!formSubmitted && (
          <TitleEventFpmf
            title={'Register to get your meetup recording'}
            text={`Our meetup "How can you find new users & revenue streams for your digital product?" is over.`}
            secondText={`But your journey with product-market fit is just starting.`}
            white
            equal
          />
        )}
        <EventForm
          button={'REGISTER'}
          thanksDescription={
            'You have successfully registered. Access to the event recording and materials will soon arrive in your mailbox.'
          }
          {...{
            url,
          }}
        />
      </Col>
    </Section>
  );
};

export default Form;
