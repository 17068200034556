import React from 'react';
import { Button, Heading, Link, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './SpotifySection.scss';
import { pages } from 'config/pages';

const SpotifySection = () => {
  const { spotifyUrl } = pages.remoteWork;

  return (
    <section className={'spotify-section'} id={'feel-the-vibes'}>
      <div className={'spotify-section__tile'}>
        <div className={'spotify-section__text-container'}>
          <Heading
            additionalClass={'spotify-section__heading'}
            size={'big'}
            type={3}
          >
            Enjoy the vibes with the #BoldareTeam{' '}
            <span className={'green'}>Spotify</span> playlist
          </Heading>
          <Paragraph
            additionalClass={'spotify-section__description'}
            size={'medium'}
            color={'white'}
          >
            Have a taste of the music we listen to while working remotely and
            feel the vibes that drive us every day!
          </Paragraph>
          <Link
            additionalClass={'spotify-section__button'}
            id={'ga-remote-work--open-spotify'}
            to={spotifyUrl}
            rel={'nofollow'}
          >
            <Button
              transparent
              borderColor={'white'}
              additionalClass={'ga-remote-work--check-our-playlist'}
            >
              Check our playlist
            </Button>
          </Link>
        </div>
        <img
          className={'spotify-section__image'}
          alt={"Boldare's spotify playlist"}
          src={getCloudinaryUrl(
            'remote-work-landing/boldare-product-design-development-company-spotify',
            'f_auto,q_auto,w_533',
          )}
          loading={'lazy'}
        />
      </div>
    </section>
  );
};

export default SpotifySection;
