import React from 'react';
import { Section } from 'components';
import './Numbers.scss';
import data from './logic';

const Numbers = ({ dataToDisplay = data }) => {
  return (
    <Section additionalClass={'newsroom__numbers'}>
      <ul className={'numbers__list anim-fade-in-bottom'}>
        {dataToDisplay.map(item => (
          <li className={'numbers__content'}>
            <span className={'numbers__content-text'}>{item.header}</span>
            <span className={'numbers__number'}>{item.number}</span>
            <span className={'numbers__content-text'}>{item.content}</span>
          </li>
        ))}
      </ul>
    </Section>
  );
};

export default Numbers;
