import React, { useState } from 'react';
import { BackgroundImage, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { SliderAsNav, TextInteractionAnimation } from 'views/f2f/components';
import './LocationsSection.scss';
import { data } from './data';
import { LocationSlider, LocationTile } from './subcomponents';

const LocationsSection = () => {
  const [currentCity, setCurrentCity] = useState(data[0].cityId);

  const mapPointOnClick = e => setCurrentCity(e.target.dataset.city);

  return (
    <section id={'f2f-locations'} className={'locations-section'}>
      <Heading
        additionalClass={'locations-section__heading'}
        size={'big'}
        type={2}
      >
        <TextInteractionAnimation text={'Say “hi!” to the places'} />
        <br />
        <TextInteractionAnimation text={'where we live & work'} />
      </Heading>

      {/* Due to GlideJS issues the visibility of element couldn't be easily conditioned here
      based on window width, instead we use display attr in CSS */}

      {/* DESKTOP */}
      <SvgLoader
        className={'locations-section__map'}
        path={
          'https://res.cloudinary.com/de4rvmslk/image/upload/v1646359537/remote-work-landing/locations-map-third.svg'
        }
      >
        {data.map(item => {
          return (
            <>
              <SvgProxy
                selector={`#${item.cityId}_selected_border`}
                display={'none'}
              />
              <SvgProxy
                selector={`#${item.cityId}_selected_circle`}
                display={'none'}
              />
              <SvgProxy
                selector={`#${item.cityId}_selected_planet`}
                display={'none'}
              />
              <SvgProxy
                selector={`#${item.cityId}_not_selected_outer_circle`}
                display={'inline'}
                onclick={mapPointOnClick}
              />
              <SvgProxy
                selector={`#${item.cityId}_not_selected_inner_circle`}
                display={'inline'}
                onclick={mapPointOnClick}
              />
            </>
          );
        })}

        <SvgProxy
          selector={`#${currentCity}_selected_border`}
          display={'inline'}
        />
        <SvgProxy
          selector={`#${currentCity}_selected_circle`}
          display={'inline'}
        />
        <SvgProxy
          selector={`#${currentCity}_selected_planet`}
          display={'inline'}
        />
        <SvgProxy
          selector={`#${currentCity}_not_selected_outer_circle`}
          display={'none'}
        />
        <SvgProxy
          selector={`#${currentCity}_not_selected_inner_circle`}
          display={'none'}
        />
      </SvgLoader>

      <BackgroundImage
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-product-design-development-company-offices',
          'q_auto,f_auto,w_450',
        )}
        additionalClass={'locations-section__satellite'}
        alt={'Remote work at Boldare'}
      />

      <Paragraph
        size={'medium'}
        additionalClass={'locations-section__description'}
      >
        We are spread across the globe but we form one marvelous team.
        <br />
        Click a pin on the map and meet our locations:
      </Paragraph>

      <LocationTile
        isHiddenOnMobile
        data={data.filter(item => item.cityId === currentCity)[0]}
      />

      {/* MOBILE */}
      <BackgroundImage
        src={getCloudinaryUrl(
          'remote-work-landing/mobile_locations_map.svg',
          'f_auto,w_3704',
        )}
        additionalClass={'locations-section__map--mobile'}
        alt={'Boldare - Digital Product Design Development Company'}
      />

      <SliderAsNav
        items={data}
        sliderAsNavId={'locations-section__slider-as-nav'}
        sliderToBeNavigatedId={'locations-section__slider-to-be-navigated'}
        sliderToBeNavigatedOptions={{
          type: 'carousel',
          animationDuration: 700,
          animationTimingFunc: 'ease-out',
          gap: 0,
          perView: 1,
          startAt: 0,
          focusAt: 0,
        }}
        componentToBeNavigated={<LocationSlider items={data} />}
      />
    </section>
  );
};

export default LocationsSection;
