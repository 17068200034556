import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { BackgroundContent, Heading, Paragraph, IconSVG } from 'components';
import './Steps.scss';

const Steps = () => (
  <div className={'wdm__steps-items'}>
    <Row className={'no-gap anim-fade-in-bottom'}>
      <Col sm={6} md={4}>
        <div className={'wdm__steps-item'}>
          <BackgroundContent
            height={100}
            content={'01'}
            color={'violet'}
            opacity={0.1}
          />
          <IconSVG
            name={'loupe'}
            color={'black'}
            size={'large'}
            additionalClass={'hide-mobile'}
          />
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Understand
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Our user-centered approach means understanding each product in
            depth before we begin, especially the target users of that product.
            Using product vision and product persona workshops, the Boldare
            team and the client explore the whys and wherefores of the product
            and agree on the characteristics of the ‘ideal’ user.
          </Paragraph>
        </div>
      </Col>
      <Col sm={6} md={4}>
        <div className={'wdm__steps-item'}>
          <BackgroundContent
            height={100}
            content={'02'}
            color={'violet'}
            opacity={0.1}
          />
          <IconSVG
            name={'maze'}
            color={'black'}
            size={'large'}
            additionalClass={'hide-mobile'}
          />
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Define
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Now we know who the user is, we turn our attention to what they
            need and want, exploring any issues underpinning the proposed
            product to take our understanding deeper and further.
          </Paragraph>
        </div>
      </Col>
      <Col sm={6} md={4}>
        <div className={'wdm__steps-item'}>
          <BackgroundContent
            height={100}
            content={'03'}
            color={'violet'}
            opacity={0.1}
          />
          <IconSVG
            name={'vector'}
            color={'black'}
            size={'large'}
            additionalClass={'hide-mobile'}
          />
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Ideate
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            At this stage, we come up with options, using techniques like
            wireframing and moodboards to present our innovation to the client
            and enable them to make informed choices concerning the UX and
            overall design.
          </Paragraph>
        </div>
      </Col>
      <Col sm={6} md={4}>
        <div className={'wdm__steps-item'}>
          <BackgroundContent
            height={100}
            content={'04'}
            color={'violet'}
            opacity={0.1}
          />
          <IconSVG
            name={'artboard'}
            color={'black'}
            size={'large'}
            additionalClass={'hide-mobile'}
          />
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Prototype
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            It’s time to build and the focus here is not on a finished product
            but a prototype that can be tested with a sample of users.
          </Paragraph>
        </div>
      </Col>
      <Col sm={6} md={4}>
        <div className={'wdm__steps-item'}>
          <BackgroundContent
            height={100}
            content={'05'}
            color={'violet'}
            opacity={0.1}
          />
          <IconSVG
            name={'mouse'}
            color={'black'}
            size={'large'}
            additionalClass={'hide-mobile'}
          />
          <Heading
            type={3}
            size={'medium'}
            additionalClass={'wdm__process-headline'}
          >
            Test
          </Heading>
          <Paragraph additionalClass={'wdm__process-description'}>
            Following the testing, the user feedback is collated and analyzed
            with the results feeding into the next cycle of the design process,
            creating further improvements.
          </Paragraph>
        </div>
      </Col>
    </Row>
  </div>
);

export default Steps;
