import jagodaPodrucka from 'assets/images/design-that-tells-stories/jagoda_podrucka.png';
import annaBil from 'assets/images/design-that-tells-stories/anna_bil.png';
import urszulaAugustyniak from 'assets/images/design-that-tells-stories/urszula_augustyniak.png';
import lukaszWalszewski from 'assets/images/design-that-tells-stories/lukasz_walszewski.png';
import kamilZielinski from 'assets/images/design-that-tells-stories/kamil_zielinski.png';
import katarzynaGreczka from 'assets/images/design-that-tells-stories/katarzyna_greczka.png';
import aleksyRafalowicz from 'assets/images/design-that-tells-stories/aleksy_rafalowicz.png';

// Frames
import { ReactComponent as FrameOne } from 'views/designThatTellsStories/subcomponents/Challenges/frame_1.svg';
import { ReactComponent as FrameTwo } from 'views/designThatTellsStories/subcomponents/Challenges/frame_2.svg';
import { ReactComponent as FrameThree } from 'views/designThatTellsStories/subcomponents/Challenges/frame_3.svg';
import { ReactComponent as FrameFour } from 'views/designThatTellsStories/subcomponents/Challenges/frame_4.svg';
import { ReactComponent as FrameFive } from 'views/designThatTellsStories/subcomponents/Challenges/frame_5.svg';
import { ReactComponent as FrameSix } from 'views/designThatTellsStories/subcomponents/Challenges/frame_6.svg';
import { ReactComponent as FrameSeven } from 'views/designThatTellsStories/subcomponents/Challenges/frame_7.svg';

export const CHALLENGES = [
  {
    challenge:
      'I need to ensure that users trust my app, which digitizes their daily tasks',
    frame: FrameOne,
    tags: ['Psychology in UX', 'User research', 'Usability testing'],
    solutionBold: '',
    solution:
      'During the development process, one of our tasks was to improve the platform’s trustworthiness, understanding its impact on daily user tasks.Through research, we pinpointed users’ concerns related to trust, including data security, party reliability, and data visibility within the product. We then conducted a survey to gauge interest in features like data encryption, structured onboarding, and a rating system.This led to further research on key themes.',
    authorPhoto: jagodaPodrucka,
    authorName: 'Jagoda Podrucka',
    authorPosition: 'Product Designer',
  },
  {
    challenge: 'I want to improve user retention in my mobile app',
    frame: FrameTwo,
    tags: ['Scaling', 'Data-driven design', 'Lean'],
    solutionBold: '',
    solution:
      'We observed user retention challenges, especially after consistent usage for several weeks. We conducted research using user surveys and Google Analytics. We found that our existing mechanisms didn’t strongly encourage user habits. We developed product hypotheses, tested them in live settings, and adjusted our user flows. Using a methodical approach and gathering user insights, we enhanced the product. Now, our product has around 30,000 active users, with 20,000 returning daily.',
    authorPhoto: annaBil,
    authorName: 'Anna Bil',
    authorPosition: 'Product Designer',
  },
  {
    challenge: 'I want to persuade investors with my app and business idea',
    frame: FrameThree,
    tags: ['Prototyping', 'Investor pitch', 'MVP'],
    solutionBold: '',
    solution:
      'One of our clients came to us with a validated business idea. While he was keen on pitching it to investors, he didn’t have a visual representation of his concept.Through the discovery process, we collected all the essential details about his idea and crafted a high-fidelity prototype. The outcome? He secured the funding and returned to us to develop the MVP version of the product.',
    authorPhoto: urszulaAugustyniak,
    authorName: 'Urszula Augustyniak',
    authorPosition: 'Product Designer',
  },
  {
    challenge:
      'Safety procedures on production sites are time-consuming and inefficient',
    frame: FrameFour,
    tags: ['Digitalization', 'UX/ UI', 'Strategy'],
    solutionBold: '',
    solution:
      'The client engaged us for product development relevant to their needs and industry standards. After research and interviews, we mapped user journeys and provided demos and mock-ups. We then developed a mobile-friendly app for users to handle sanitation checks digitally, reducing paperwork. This tool streamlines the process and optimizes efficiency. Our involvement encompassed various roles from inception to completion.',
    authorPhoto: lukaszWalszewski,
    authorName: 'Łukasz Walszewski',
    authorPosition: 'Product Designer',
  },
  {
    challenge:
      'Due to the lack of design uniformity, our platform seems outdated to our customers',
    frame: FrameFive,
    tags: ['Design system', 'UX', 'Sustainability'],
    solutionBold: '',
    solution:
      'To ensure consistency across the brand, we worked with the client to create a design system. This consisted of a set of principles and standards for use in product development that reflected the client’s business identity. By creating a set of reusable ‘components’ the platform’s design was aligned with the rest of the brand’s ‘digital family’. With the design system in place, future digital product development will be much faster, less error-prone and less expensive.',
    authorPhoto: kamilZielinski,
    authorName: 'Kamil Zieliński',
    authorPosition: 'Product Designer',
  },
  {
    challenge:
      'I don’t know if I should invest in an English version of our platform',
    frame: FrameSix,
    tags: ['Hypothesis validation', 'Fake door', 'Arabic design'],
    solutionBold: '',
    solution:
      'We’re developing an Arabic governmental web platform for business owners. Noting that 40% of users had English browsers, we considered adding an English version. To assess interest, we implemented the "fake door" tactic with an "English version" button. However, when clicked, users found information that it was still in progress. Only 3% of users engaged with it, effectively dispelling our initial assumption and saving our client significant time and expenses.',
    authorPhoto: katarzynaGreczka,
    authorName: 'Katarzyna Greczka',
    authorPosition: 'Product Designer',
  },
  {
    challenge:
      'Preparation of a quick prototype within a very short and strict deadline',
    frame: FrameSeven,
    tags: ['Prototyping', 'Moodboards', 'Lean startup'],
    solutionBold: '',
    solution:
      'We were approached by a client who needed a prototype of an AI voice transcription app for an upcoming industry conference. Given the strict timeline, we focused on showcasing the app’s primary features. After some initial research, we presented moodboards to guide the client in selecting an appropriate visual direction. Within two weeks we delivered a prototype that was successfully presented during the conference.',
    authorPhoto: aleksyRafalowicz,
    authorName: 'Aleksy Rafałowicz',
    authorPosition: 'Product Designer',
  },
];
