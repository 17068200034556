import React, { Component } from 'react';
import './CaseStudies.scss';
import { getCloudinaryUrl } from 'config/helpers';
import {
  Heading,
  Button,
  Section,
  SectionHeader,
  IconSVG,
  Paragraph,
} from 'components';
import Slider from 'react-slick';
import { sliderData } from './logic';

class CaseStudies extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'digtra-de-case-studies-content__slider',
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: false,
        pauseOnHover: true,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
    this.setState({ slider: this.slider, sliderNav: this.sliderNav });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  renderSlides = () => {
    return sliderData.map(item => (
      <>
        <Section
          additionalClass={'digtra-de-case-studies-content__slider'}
          spaceless
        >
          <div className={'digtra-de-case-studies-content__content-wrapper'}>
            <div
              className={'digtra-de-case-studies-content__content-col cover'}
            >
              {item.imgFront && (
                <img
                  src={getCloudinaryUrl(item.imgFront, 'f_auto,q_auto')}
                  className={`image ${item.class}`}
                  alt={item.name}
                  loading={'lazy'}
                />
              )}
            </div>
            <div
              className={
                'digtra-de-case-studies-content__content-col description'
              }
            >
              <Heading
                type={3}
                size={'medium-constant'}
                color={'mine-shaft'}
                additionalClass={'digtra-de-webinar__text-header-secondary'}
              >
                {item.name}
              </Heading>
              <Paragraph additionalClass={'text--demibold'}>
                {item.content}
              </Paragraph>
            </div>
            <div
              className={
                'digtra-de-case-studies-content__content-col navigation'
              }
            >
              <button
                className={'nav'}
                onClick={() => this.slider.slickNext()}
              >
                Nächste Fallstudie{' '}
                <IconSVG
                  name={'arrow-long'}
                  size={'huge'}
                  additionalClass={'arrow-long'}
                />
              </button>
            </div>
            <div
              className={
                'digtra-de-case-studies-content__content-col download'
              }
            >
              <a href={item.link}>
                <Button color={'white'}>Lesen Sie das</Button>
              </a>
            </div>
          </div>
        </Section>
        <img
          src={getCloudinaryUrl(item.imgBack, 'f_auto,q_auto')}
          className={'image digtra-de-case-studies-content__background'}
          alt={item.name}
          loading={'lazy'}
        />
      </>
    ));
  };

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section
          additionalClass={'digtra-de-case-studies__section'}
          id={'digtra-de-case-studies'}
          spacedTop
          spaceless
        >
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            headingType={2}
            fixed
            noLine
            additionalClass={'digtra-de-case-studies-content__text-header'}
          >
            Siehe frühere Projekte auf dem deutschen Markt
          </SectionHeader>
        </Section>
        <Section
          additionalClass={'digtra-de-case-studies-content__section'}
          id={'digtra-de-case-studies-content-content'}
          fullwidth
          spaceless
        >
          <div className={'digtra-de-case-studies-content__wrapper'}>
            <Slider
              ref={slider => {
                this.slider = slider;
              }}
              {...sliderSettings}
            >
              {this.renderSlides()}
            </Slider>
          </div>
        </Section>
      </>
    );
  }
}

export default CaseStudies;
