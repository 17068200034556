import React, { useState, useEffect } from 'react';
import './AboutTool.scss';
import { Heading, Paragraph, Button, Image } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';

const PageParagraph = ({ children, animated }) => (
  <Paragraph
    additionalClass={classNames('srt__paragraph', {
      'anim-fade-in-bottom': animated,
    })}
    size={'medium'}
    color={'black'}
    spaced
  >
    {children}
  </Paragraph>
);

const AboutTool = () => {
  const [showFullDescription, setShowFullDescription] = useState(true);
  const [
    animateAdditionalParagraphs,
    setAnimateAdditionalParagraphs,
  ] = useState(true);
  useEffect(() => {
    if (typeof window === 'undefined' || window.innerWidth < 992) {
      setShowFullDescription(false);
      setAnimateAdditionalParagraphs(false);
    }
  }, []);

  return (
    <div className={'srt-about-tool__wrapper'}>
      <section className={'srt-about-tool w-container--side-right'}>
        <Heading additionalClass={'srt-about-tool__heading'} type={2}>
          What is the Sprint Retrospective Tool by Boldare?
        </Heading>
        <PageParagraph animated>
          The Sprint Retrospective Tool is a FREE online app for conducting
          scrum retrospectives.
        </PageParagraph>
        {showFullDescription ? (
          <>
            <PageParagraph animated={animateAdditionalParagraphs}>
              The Sprint Retrospective Tool was created by Boldare as an
              internal alternative to the paid options available on the market.
              And it works great for us. So, why not share it with the world?
            </PageParagraph>
            <PageParagraph animated={animateAdditionalParagraphs}>
              We’ve designed the Sprint Retrospective Tool to be light, fast
              and get the job done. Thanks to this tool, your development team
              can learn and improve by reflecting on what worked well and what
              needs to be done, and make actionable items for the future.
            </PageParagraph>
            <PageParagraph animated={animateAdditionalParagraphs}>
              But the most important advantage of the Sprint Retrospective Tool
              is that members of the teams are engaging and sharing their
              opinions and feedback. Thanks to that, the retrospective can
              strengthen the team’s morale and efficiency.
            </PageParagraph>
          </>
        ) : (
          <Button
            color={'yellow'}
            ariaName={'Read more'}
            onClick={() => setShowFullDescription(true)}
          >
            Read more
          </Button>
        )}
      </section>
      <img
        className={'image srt-about-tool__img w-container--side-left'}
        src={getCloudinaryUrl('img/team-in-meeting', 'q_auto,f_auto,w_1000')}
        alt={'Our team during retrospective meeting'}
        loading={'lazy'}
      />
    </div>
  );
};

export default AboutTool;
