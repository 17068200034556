import { Button, Image } from 'components';
import PropTypes from 'prop-types';
import Logo from 'assets/images/blog/ai_assistant_logo.png';
import './AiAssistantBanner.scss';

import React from 'react';

const AiAssistantBanner = ({ fullWidth }) => {
  return (
    <div
      className={
        fullWidth ? 'ai-assistant-banner__fullWidth' : 'ai-assistant-banner'
      }
    >
      <div className={'ai-assistant-banner__content'}>
        <div className={'ai-assistant-banner__action'}>
          <Button
            color={'yellow'}
            onClick={() =>
              window.open(
                'https://github.com/boldare/openai-assistant',
                '_blank',
              )
            }
          >
            Download AI Assistant
          </Button>
          {/* <Image src={Hand} alt={'AI Hand'} /> */}
        </div>
        <Image src={Logo} alt={'AI Assistant Logo'} />
      </div>
    </div>
  );
};

AiAssistantBanner.propTypes = {
  fullWidth: PropTypes.bool,
};
AiAssistantBanner.defaultProps = {
  fullWidth: false,
};

export default AiAssistantBanner;
