import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Event from 'views/event';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../meta/config';

const page = pages['event-zaslepka'];
const { navColors } = page;
const url = `${config.siteUrl}/event-zaslepka/`;
const canonical = `${url}`;

const FindingPmfPage = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Event
        title={
          '<img class="pixel-font" src="https://res.cloudinary.com/de4rvmslk/image/upload/v1668426434/event/how.svg" /><span class="pixel-font-text">How</span> to find new users & revenue streams for your digital product?'
        }
        subtitle={
          'Join us live in <span class="headline--marking headline--marking-red">Amsterdam</span>&nbsp;<br />or online worldwide!'
        }
        image={getCloudinaryUrl('event/Collage.png', 'f_auto,q_auto')}
        date={'14.12.2022 (Wednesday)'}
        start={'7 pm CET'}
        series={'Finding product-market-fit'}
        itemsHeadings={`Join the <span class="headline--marking headline--marking-red--small">event</span> and learn:`}
        items={[
          `what <b>product-market fit</b> is and why achieving it is key to building and maintaining a product's position in the market`,
          `how to achieve product customization based on <b>value proposition</b> (pain points, gains, jobs to be done)`,
          `how to analyze <b>market opportunities</b> and select segments within which to develop your product`,
          `how, by matching target groups and values for each group, we achieved an increase in <b>users of up to 300%</b> (real-life case study)`,
        ]}
        url={pardotlink}
        formHeading={'Reserve your spot at our live meetup in Amsterdam*'}
        button={'RESERVE'}
        btnColor={'black'}
        trackingEvent={`EventFindingPMF`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'You have sucessfully subscribed to the event! See you soon!'
        }
      />
      <Footer />
    </Layout>
  );
};

export default FindingPmfPage;
