import React from 'react';

export const sliderData = [
  {
    background: 'career/career-slider_sonnen.jpg',
    backgroundMobile: 'career/career-slider_sonnen.jpg',
    content: 'sonnen: digital transformation',
    description: (
      <>
        sonnen’s digital transformation is one of the most interesting cases in
        Boldare portfolio. What exactly does it mean? A complex process of
        reshaping an organization’s services, products, and culture. It’s not
        about digitalization at all! So… what steps did we take to tailor
        solutions to sonnen’s needs?
        <a
          href={'/work/case-study-sonnen-digital-transformation/'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Read more
        </a>
      </>
    ),
  },
  {
    background: 'career/career-slider-blablacar.jpg',
    backgroundMobile: 'career/career-slider-blablacar.jpg',
    content: 'BlaBla Car: success in 27 countries',
    description: (
      <>
        Meet a story of Parisian unicorn BlaBlaCar. How did they win a race for
        market domination in 27 countries? With Boldare teams help. Together we
        delivered 10 products in 27 countries over 18 months employing agile
        development and lean startup thinking.
        <a
          href={'/work/case-story-blablacar/'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Read more
        </a>
      </>
    ),
  },
  {
    background: 'career/career-slider-chartipedia.jpg',
    backgroundMobile: 'career/career-slider-chartipedia.jpg',
    content: 'Chartipedia: a data visualization platform',
    description: (
      <>
        It’s inspiring travel from Instagram to data visualization platform.
        Chartipedia is the torch-bearer platform that connects designers who
        create interactive, data-backed visualizations, with those who seek
        information. Discover how did we help Chartipedia to fully understand
        data and take advantage of it.
        <a
          href={'/work/case-study-chartipedia/'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Read more
        </a>
      </>
    ),
  },
  {
    background: 'career/career-slider-workroom.jpg',
    backgroundMobile: 'career/career-slider-workroom.jpg',
    content: `Work Room: fitting into London's scene`,
    description: (
      <>
        If you live in London, you are good to go. Every five days, a new
        co-working space opens in the city, which makes it a global growth
        leader. Get to know how did we build on-demand workspace platform for
        London and beyond. And how did we provide first-class user-centered
        design based on an understanding of the specific business requirements.
        <a
          href={'/work/the-workroom-case-story/'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Read more
        </a>
      </>
    ),
  },
];
