import React from 'react';
import {
  Button,
  Paragraph,
  Section,
  SectionHeader,
  ValuesList,
} from 'components';
import companyData from '../../../../../meta/companyData';
import { getRescueFaillingProjectImageUrl } from '../../utils';

import './TrustExperts.scss';

function TrustExperts() {
  const {
    clutch_rate: clutchRate,
    clutch_reviews: clutchReviews,
  } = companyData;

  return (
    <Section
      additionalClass={'rescue-failing-project__trust-experts'}
      color={'grey-100'}
      spaceless
    >
      <div className={'rescue-failing-project__trust-experts__wrapper'}>
        <div className={'rescue-failing-project__trust-experts__left'}>
          <SectionHeader
            headingType={2}
            size={'large'}
            noLine
            additionalClass={'rescue-failing-project__trust-experts__header'}
            margin={'rescue-failing-project__trust-experts__header'}
          >
            Trust the Experts with a Proven Track Record
          </SectionHeader>
          <Paragraph size={'medium-constant'}>
            Experience matters. Our product development services have earned a
            stellar reputation in the industry. Just have a look at the most
            reliable reviews, verified by <b>Clutch</b>.
          </Paragraph>
          <div className={'rescue-failing-project__trust-experts__clutch'}>
            <ValuesList
              className={'rescue-failing-project__trust-experts__clutch__list'}
            >
              <ValuesList.Item
                term={'Average review rating'}
                value={clutchRate}
              />
              <ValuesList.Item term={'Total reviews'} value={clutchReviews} />
            </ValuesList>
            <Button
              tag={'a'}
              href={'https://clutch.co/profile/boldare#highlights'}
              additionalClass={'rescue-failing-project__trust-experts__button'}
              color={'yellow'}
            >
              GO TO CLUTCH
            </Button>
          </div>
        </div>
        <img
          className={'rescue-failing-project__trust-experts__image'}
          src={getRescueFaillingProjectImageUrl('science-woman.png')}
          alt={''}
          aria-hidden
        />
      </div>
    </Section>
  );
}

export default TrustExperts;
