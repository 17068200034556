import React from 'react';

const HighlightShape = () => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    height={'86'}
    width={'100%'}
    viewBox={'0 0 254 65'}
    preserveAspectRatio={'none'}
    fill={'none'}
  >
    <path
      fill={'#7563E7'}
      fillRule={'evenodd'}
      d={
        'm62 64-19 2 2 7 124-13h8v-3h5l50 4-1-5h3v-5h1l-1-3h5l-1-6 9-1-2-6v-1l-6-1 1-4-4-1h8v-3l1-2h7l2-6-1-1 1-1h-7l-2-6-11 1V6l-33 1-2-7-20 3V2l-69 5-16-1h-3l-1 2H68L19 5h-3l-1 4-8-1-2 7 7 1H2l-2 7h2v1l3 1 1 6h6H0l1 7h4l1 5h6l1 4v2l11-1v2L5 49l1 7h6l1 5h11l1 5 37-3v1Z'
      }
      clipRule={'evenodd'}
    />
  </svg>
);

export default HighlightShape;
