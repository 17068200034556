import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Technology.scss';

class Technology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.pakt__technology-tiles').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = loadedData =>
    data.map(item => (
      <div
        className={classNames('pakt__technology-box', {
          'pakt__technology-box--entered': this.state.entered,
        })}
        key={`pakt__technology-${item.image}`}
      >
        <img
          src={getCloudinaryUrl(item.img, 'f_auto,q_auto')}
          alt={item.name}
          className={'image pakt__technology-logotype'}
          loading={'lazy'}
        />
        <Heading type={3} size={'medium'} color={'mine-shaft'}>
          {item.name}
        </Heading>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'pakt__technology-container'}>
        <div className={'pakt__content'}>
          <Heading type={2} size={'big-spreaded'}>
            Variety of frameworks
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We used the newest technology available to be sure that our site
            works effortlessly and provides tools necessary to make our client
            pleased in 100%. App interface redesign is not only the graphic
            design changes. All flows, user paths and design improvements has
            to be delivered for the user with the high quality frontend
            development. It was a part of our job as well.
          </Paragraph>
        </div>
        <div className={'pakt__technology-tiles'}>{this.renderBoxes()}</div>
      </Section>
    );
  }
}

export default Technology;
