import { IconSVG } from 'components';
import { SectionWide } from 'components/DS';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import './Cards.scss';

import { CARDS } from './cardsList';

export default function Cards() {
  return (
    <SectionWide color={'main-blue'}>
      <div className={'ses-cards__title'}>
        <h3 className={'heading heading--medium-plus-small'}>
          One service standards
        </h3>
        <h2 className={'heading--font-bold heading--large'}>
          Turning complexity into simplicity
        </h2>
        <p className={'paragraph paragraph--medium-constant'}>
          Service Standards help turning your ideas into reality, your
          ambitions into tangible outcomes, and your bold concepts into
          impactful products. Find out how!
        </p>
      </div>
      <Row className={'ses-cards__row'}>
        {CARDS.map(card => (
          <Col className={'clients__logos-column'} md={6} xl={4}>
            <div className={'ses-cards-box'}>
              <div className={'ses-cards-box__container'}>
                <IconSVG
                  additionalClass={'ses-cards-box__container--icon'}
                  name={card.icon}
                  size={'large'}
                />
                <h3 className={'heading heading--medium-plus-small'}>
                  {card.name}
                </h3>
                <p className={'paragraph'}>{card.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </SectionWide>
  );
}
