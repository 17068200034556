export const data = [
  {
    title: 'What',
    text:
      'General web visual strategy, content planning, user workflows, target group selection and specification of its needs, as well as dedicated user interface design – these elements were combined to deliver to the Customer the final effect of re-building the website according to their goals. The aim of the project was to broaden the target group of users of the tools of this graphic software vendor and to build and support their community of users who actively participate in online webinars, improving available tutorials and at the same time binding them to the brand.',
    images: ['corel_rwd.jpg', 'corel_montaz.jpg'],
  },
  {
    title: 'How — Information Architecture, Wireframes, and Moodboards',
    text:
      "Information architecture and content planning were adapted to enhance visibility of the most frequently used content. Analysis of users' previous behaviour showed us the right direction and gave us good foundations for our design decisions and prioritisation of content and sections. We also took into consideration users who had never used some of the content that was available on the previous web page before and accordingly, we presented it differently, emphasising its values and presenting it in a new form.",
    images: ['corel_wireframe.jpg', 'corel_moodboard.jpg'],
  },
  {
    title: 'How — Final Graphic Design and Alternative Versions',
    text:
      'Two different graphic design propositions were delivered to the customer; one was based on the idea of keeping the colour pallet of the brand, the other refreshed and changed the colour scheme as well as text and photo presentation. Both versions of the Corel Discovery main page introduced a new set of fonts and handcrafted icons which created consistency for the whole website. All service pages, by paying special attention to the images, presented possibilities for using knowledge that can be acquired through learn.corel.com.',
    images: [
      'corel-element-1.jpg',
      'cst_alternate-1-1.jpg',
      'cst_alternate-2-1.jpg',
    ],
  },
  {
    title: 'Why',
    text:
      "The easiest way to learn about photography, video, graphics, and painting is to have access to an environment that will support you with top-notch content. Tips, tutorials, and tools for photographers, art designers, and video creators are your daily friends on the way to becoming more efficient and professional. We always want to learn so it's a pleasure to be a part of this community and to be able to support it with our skills.",
    images: [
      'corel_photo-1-big.jpg',
      'corel_photo-1.jpg',
      'corel-element-3.jpg',
    ],
  },
];
