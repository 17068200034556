export const data = [
  {
    title: 'Fresh and vivid',
    text:
      'When the whole background is grey you need some colours to make it more alive. We made some icons with vivid colours to make it all look more dynamic.',
    image: 'practitest-icons.png',
  },
  {
    title: 'Light with space combination',
    text:
      'We used a thin font for headlines and space between all elements in the project. This will improve reading information for the user and there is a lot of information on the website. Detailed tech specs and long lists of features - reading all that could feel frustrating. Well, not anymore.',
    image: 'practitest-list-style.png',
  },
];
