import Input from './Input';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import Textarea from './Textarea';
import Dropdown from './Dropdown';

export const widgets = {
  checkboxGroup: CheckboxGroup,
  checkbox: Checkbox,
  textarea: Textarea,
  dropdown: Dropdown,
  default: Input,
};

export const getFormInitState = fields =>
  fields.reduce((state, field) => {
    if (field.fields) {
      return { ...state, [field.key]: getFormInitState(field.fields) };
    }
    return field.value ? { ...state, [field.key]: field.value } : state;
  }, {});

export const chooseWidget = type => widgets[type] || widgets.default;

export const getWidgetProps = (field, fieldsetState, onChange) => {
  const widgetProps = {
    ...field,
    key: `widget--${field.key}`,
    name: field.key,
    id: field.key,
  };
  if (field.type === 'checkbox') {
    widgetProps.checked = fieldsetState[field.key];
    widgetProps.value = field.key;
    widgetProps.onChange = () =>
      onChange({
        ...fieldsetState,
        [field.key]: !widgetProps.checked,
      });
  } else {
    widgetProps.value = fieldsetState[field.key];
    widgetProps.onChange = value => {
      onChange({
        ...fieldsetState,
        [field.key]: value,
      });
    };
  }
  return widgetProps;
};
