import React from 'react';
import './Work.scss';
import { FullwidthImage, Image } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const Work = () => {
  return (
    <div className={'workroom-work'}>
      <FullwidthImage
        additionalClass={'workroom-work__image'}
        image={getCloudinaryUrl('img/WR_Photography_4.png', 'f_auto,q_auto')}
        alt={'Work table in a coffee shop'}
      />
      <img
        className={'image workroom-work__logo anim-fade-in-bottom'}
        src={getCloudinaryUrl('img/Ikon_App_WR.png', 'q_auto')}
        alt={'Work Room Logo'}
        loading={'lazy'}
      />
    </div>
  );
};

export default Work;
