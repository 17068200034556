import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { Paragraph, Button, Heading, SectionHeader, Link } from 'components';
import { generateHash, classNames } from 'utils';

import validateInputField from './formValidationHelper';
import { ResponseSendContactHeaderTwo as ResponseSendContactHeaderTwoHelper } from './helperComponents';

import './ContactFormBlack.scss';

function ContactFormBlack({
  name,
  header: Header,
  button,
  pardotLink,
  id,
  maxLength,
  privateDataInfo: PrivateDataInfoComponent,
  responseButtonText,
  responseSendContactHeaderTwo: ResponseSendContactHeaderTwo,
  responseSendContactHeader: ResponseSendContactHeader,
  topic,
}) {
  const [isThankYouPage, setIsThankYouPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    sessionType: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    sessionType: '',
  });

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      setIsThankYouPage(true);

      const params = new URLSearchParams(window.location.href);
      if (params['thank-you'] === name) {
        scroller.scrollTo(id, { offset: -80 });
      }
    }
  }, [id]);

  const handleChange = e => {
    const { name: targetName, value } = e.target;
    setForm(prevState => ({
      ...prevState,
      [targetName]: value,
    }));
  };

  const handleBlur = e => {
    const fieldName = e.target.name;
    const validationResult = validateInputField(fieldName, form, errors);
    setErrors(validationResult.updatedErrors);
  };

  const handleSubmitForm = e => {
    e.preventDefault();

    const nameValidationResult = validateInputField('name', form, errors);
    const emailValidationResult = validateInputField('email', form, errors);
    const sessionTypeValidationResult = validateInputField(
      'sessionType',
      form,
      errors,
    );
    const messageValidationResult = validateInputField(
      'message',
      form,
      errors,
    );

    const isValidationPositive =
      nameValidationResult.isValid &&
      emailValidationResult.isValid &&
      sessionTypeValidationResult.isValid &&
      messageValidationResult.isValid;

    if (!isValidationPositive) {
      return;
    }

    setErrors({
      ...nameValidationResult.updatedErrors,
      ...emailValidationResult.updatedErrors,
      ...sessionTypeValidationResult.updatedErrors,
      ...messageValidationResult.updatedErrors,
    });

    const dataForm = { ...form };

    if (topic) {
      dataForm.contactTopic = topic;
    }

    const body = Object.keys(dataForm)
      .map(key => `${key}=${dataForm[key]}`)
      .join('&');

    setIsLoading(true);

    // fetch is defined in globally
    // eslint-disable-next-line no-undef
    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you=${name}&connectionId=${generateHash()}`;
        }
      })
      .catch(error => console.log(error));
  };

  return (
    <div>
      {!isThankYouPage ? (
        <Header />
      ) : (
        <>
          <SectionHeader
            additionalClass={'contact-form-black__message-sent'}
            size={'medium'}
            lineColor={'violet'}
            fixed
            noLine
          >
            <ResponseSendContactHeader />
          </SectionHeader>
          <Heading
            size={'medium-plus'}
            additionalClass={'contact-form-black__description'}
          >
            <ResponseSendContactHeaderTwo />
          </Heading>
          <Link to={'/contact'}>
            <Button
              color={'blue'}
              id={'contact-biz-thank-you-button'}
              ariaName={'Contact button'}
              additionalClass={'contact-us-button'}
              borderColor={'black'}
              onClick={() => setIsThankYouPage(false)}
            >
              {responseButtonText} &rarr;
            </Button>
          </Link>
        </>
      )}

      <form
        name={name}
        className={classNames('contact-form-black', {
          'contact-form-black--hidden': isThankYouPage,
        })}
        method={'post'}
        onSubmit={handleSubmitForm}
        noValidate
      >
        <div className={'contact-form-black__personal-data'}>
          <div className={'input-container'}>
            <input
              type={'text'}
              name={'name'}
              id={'form-name'}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={50}
              placeholder={'Name'}
            />
            {errors.name && <p className={'error-message'}>{errors.name}</p>}
          </div>
          <div className={'input-container'}>
            <input
              type={'email'}
              name={'email'}
              id={'form-email'}
              className={classNames({
                'input--filled': form.email,
              })}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={50}
              placeholder={'E-mail'}
            />
            {errors.email && <p className={'error-message'}>{errors.email}</p>}
          </div>
        </div>
        <div className={'input-container contact-form-black__company-input'}>
          <input
            type={'text'}
            name={'company'}
            id={'form-company'}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={50}
            placeholder={'Company'}
          />
          {errors.company && (
            <p className={'error-message'}>{errors.company}</p>
          )}
        </div>
        <div className={'textarea-container'}>
          <textarea
            name={'message'}
            id={'form-textarea'}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={1000}
            placeholder={'Tell us about your business challenge'}
          />
          {!isThankYouPage && (
            <Paragraph
              color={'white'}
              size={'small'}
              additionalClass={'contact-form-black__phone'}
            >
              {maxLength}
            </Paragraph>
          )}
          {errors.message && (
            <p className={'error-message'}>{errors.message}</p>
          )}
        </div>
        <p className={'radio-container__heading'}>
          Your preferred session type:
        </p>
        <div className={'radio-container'}>
          <input
            type={'radio'}
            id={'online'}
            name={'sessionType'}
            value={'online'}
            onChange={handleChange}
          />
          <label className={'radio-label'} htmlFor={'online'}>
            online
          </label>
        </div>

        <div className={'radio-container'}>
          <input
            type={'radio'}
            id={'in-person'}
            name={'sessionType'}
            value={'in-person'}
            onChange={handleChange}
          />
          <label className={'radio-label'} htmlFor={'in-person'}>
            in person
          </label>
        </div>

        <div className={'radio-container'}>
          <input
            type={'radio'}
            id={'berlin'}
            name={'sessionType'}
            value={'berlin'}
            onChange={handleChange}
          />
          <label className={'radio-label'} htmlFor={'berlin'}>
            21.09 in Berlin
          </label>
        </div>
        {errors.sessionType && (
          <p className={'error-message'}>{errors.sessionType}</p>
        )}

        <div className={'contact-form-black__info'}>
          <Paragraph
            additionalClass={'contact-form-black__privacy-policy text--white'}
            size={'small-plus'}
          >
            <PrivateDataInfoComponent />
          </Paragraph>
          <Button
            id={`contact-us-form-${name}-submit`}
            additionalClass={classNames(
              'contact-button',
              'contact-form-black__button',
              { loading: isLoading },
            )}
            type={'submit'}
            value={'submit'}
            borderColor={'black'}
            color={'yellow'}
            ariaName={'Send message button'}
            disabled={isLoading}
            loading
          >
            {button} &rarr;
          </Button>
        </div>
      </form>
    </div>
  );
}

ContactFormBlack.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  header: PropTypes.func,
  button: PropTypes.string,
  pardotLink: PropTypes.string,
  lang: PropTypes.string,
  textareaContent: PropTypes.string,
  textareaShort: PropTypes.string,
  firstNameContent: PropTypes.string,
  companyEmailContent: PropTypes.string,
  maxLength: PropTypes.string,
  designMail: PropTypes.bool,
  privateDataInfo: PropTypes.func,
  responseSendContactHeader: PropTypes.func,
  responseSendContactHeaderTwo: PropTypes.func,
  responseButtonText: PropTypes.string,
  topic: PropTypes.string,
};

ContactFormBlack.defaultProps = {
  header: () => (
    <Heading
      size={'medium-plus-small'}
      additionalClass={'contact-form-black__filters-heading'}
    >
      Fill in the form and we’ll get back to you within 24 business hours.
    </Heading>
  ),
  button: 'GET OFFER',
  pardotLink: 'https://go.boldare.com/l/688533/2019-02-28/x5r',
  lang: 'en',
  textareaContent: 'Describe the challenge or idea we could help you with',
  textareaShort: 'Describe the challenge',
  firstNameContent: 'First name',
  companyEmailContent: 'Company email',
  maxLength: 'Max 1000 characters',
  designMail: false,
  privateDataInfo: () => (
    <>
      Your data are safe with us. We take your privacy seriously and we will
      never share your data with anyone. Read on our{' '}
      <Link to={'/privacy-policy/'} id={'contact-biz-privacy-policy'}>
        Privacy Policy
      </Link>{' '}
      to learn more.
    </>
  ),
  responseButtonText: 'SEND NEXT MESSAGE',
  responseSendContactHeader: () => <>Thank you! Your message is sent.</>,
  responseSendContactHeaderTwo: () => <ResponseSendContactHeaderTwoHelper />,
  topic: undefined,
};

ContactFormBlack.ResponseSendContactHeaderTwo = ResponseSendContactHeaderTwoHelper;

export default ContactFormBlack;
