import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components';
import Quiz from './subcomponents/Quiz';
import { quizQuestions } from '../../logic/quizQuestions';

import './Survey.scss';

const Survey = ({ setResult, setNextState, setFinalAnswers }) => {
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [answer, setAnswer] = React.useState('');
  const [answers, setAnswers] = React.useState({});

  const setUserAnswer = newAnswer => {
    setAnswers({
      ...answers,
      [questionIndex]: newAnswer,
    });
    setAnswer(newAnswer);
  };

  const setNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    setAnswer('');
  };

  const prepareAnswers = answers => {
    const preparedAnswers = {};

    for (let i = 0; i < 5; i += 1) {
      if (answers[i].indexOf('-') > 0) {
        preparedAnswers[i] = answers[i].substring(0, answers[i].indexOf('-'));
      } else {
        preparedAnswers[i] = answers[i];
      }
    }

    return preparedAnswers;
  };

  const getResults = () => {
    let score = {};
    const preparedAnswers = prepareAnswers(answers);

    Object.values(preparedAnswers).forEach(result => {
      score = { ...score, [result]: (score[result] || 0) + 1 };
    });

    const answersKeys = Object.keys(score);
    const answersCountValues = answersKeys.map(key => score[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    setFinalAnswers(answers);

    return answersKeys.filter(key => score[key] === maxAnswerCount);
  };

  const setResults = res => {
    setResult(res[0].toLowerCase());
  };

  const handleAnswerSelected = event => {
    setUserAnswer(event.currentTarget.value);
  };

  React.useEffect(() => {
    if (Object.keys(answers).length === 0) return;

    if (questionIndex + 1 < quizQuestions.length) {
      setTimeout(() => setNextQuestion(), 200);
    } else {
      setTimeout(() => {
        setResults(getResults());
        setNextState();
      }, 1000);
    }
  }, [answers]);

  return (
    <Container>
      <div className={'pmf-survey text--white'}>
        <div className={'pmf-survey__content'}>
          <Quiz
            answer={answer}
            answerOptions={quizQuestions[questionIndex].answers}
            questionIndex={questionIndex}
            question={quizQuestions[questionIndex].question}
            questionTotal={quizQuestions.length}
            onAnswerSelected={handleAnswerSelected}
          />
        </div>
      </div>
    </Container>
  );
};

Survey.propTypes = {
  setNextState: PropTypes.func,
  setResult: PropTypes.func,
  setFinalAnswers: PropTypes.func,
};

Survey.defaultProps = {
  setNextState: null,
  setResult: null,
  setFinalAnswers: null,
};

export default Survey;
