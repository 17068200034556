import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '..';
import './BlogBox.scss';

const BlogBox = ({ title, content, url }) => (
  <Box additionalClass={'box-block'} backgroundColor={'carrara'}>
    <h2>{title}</h2>
    <p>{content}</p>
    <iframe src={url} />
  </Box>
);

export default BlogBox;
