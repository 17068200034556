import React from 'react';
import { Heading, Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './HowWeDoIt.scss';

const HowWeDoIt = () => (
  <Section additionalClass={'ydp-how'} spaceless color={'pigeon-post'}>
    <div className={'ydp-how__content'}>
      <Heading type={2} size={'big'} color={'white'}>
        Our development process
      </Heading>
      <img
        src={getCloudinaryUrl('img/graph-process.png', 'f_auto,q_auto')}
        alt={'Our Process'}
        className={'image ydp-how__graph'}
        loading={'lazy'}
      />
    </div>
  </Section>
);

export default HowWeDoIt;
