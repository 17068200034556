import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import { Button, Heading, Link, Paragraph, Section } from 'components';
import { generateHash } from 'utils.js';
import PropTypes from 'prop-types';
import StarRating from './StarRating';

import './WorkshopForm.scss';

const WorkshopForm = ({
  url,
  trackingEvent,
  formHeading,
  workshopQuestion,
  ratingLabel,
  descriptionLabel,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    attendedWorkshop: '',
    rating: 0,
    description: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isThankYouDisplayed, setIsThankYouDisplayed] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      setIsThankYouDisplayed(window.location.href.includes('?thank-you'));
    }
  }, []);

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.email) {
      setFormData(prevState => ({
        ...prevState,
        email: queryParams.email.replace(/%/g, ''),
      }));
    }
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRatingChange = value => {
    setFormData(prevState => ({ ...prevState, rating: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const body = Object.keys(formData)
      .map(
        key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`,
      )
      .join('&');

    try {
      await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        body,
      });

      if (typeof window !== 'undefined') {
        if (window.dataLayer && trackingEvent) {
          window.dataLayer.push({
            event: trackingEvent,
          });
        }

        if (window.fbq && typeof window.fbq === 'function') {
          window.fbq('track', 'Download');
        }
      }

      if (typeof window !== 'undefined' && window.location) {
        window.location.href = `?thank-you&connectionId=${generateHash()}`;
      }

      setIsFormSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section additionalClass={'workshop-section'}>
      {!isFormSubmitted && !isThankYouDisplayed ? (
        <form className={'workshop-form'} onSubmit={handleSubmit}>
          <Heading
            color={'mine-shaft'}
            size={'big'}
            additionalClass={'workshop-form__heading'}
          >
            {formHeading}
          </Heading>
          <Paragraph size={'medium'}>Your email used to register</Paragraph>
          <input
            className={'workshop-form__input'}
            type={'email'}
            id={'email'}
            name={'email'}
            value={formData.email || ''}
            onChange={handleChange}
            required
          />
          <Paragraph
            size={'medium'}
            additionalClass={'workshop-form__question'}
          >
            {workshopQuestion}
          </Paragraph>
          <div className={'workshop-form__question-input'}>
            <input
              type={'radio'}
              name={'attendedWorkshop'}
              value={'yes'}
              checked={formData.attendedWorkshop === 'yes'}
              onChange={handleChange}
            />
            Yes
            <input
              type={'radio'}
              name={'attendedWorkshop'}
              value={'no'}
              checked={formData.attendedWorkshop === 'no'}
              onChange={handleChange}
            />
            No
          </div>
          <Paragraph size={'medium'} additionalClass={'workshop-form__rating'}>
            {ratingLabel} <br />
            (1 – beginner... 5 – master)
          </Paragraph>
          <StarRating handleRating={handleRatingChange} />
          <Paragraph
            size={'medium'}
            additionalClass={'workshop-form__description'}
          >
            {descriptionLabel}
          </Paragraph>
          <textarea
            id={'description'}
            name={'description'}
            className={'workshop-form__textarea'}
            value={formData.description || ''}
            onChange={handleChange}
          />
          <button type={'submit'} className={'workshop-form__button'}>
            Submit
          </button>
          <Paragraph size={'small'}>
            We are commited to your privacy. Boldare uses the information you
            provide to us to contact you about relevant content, products and
            services. Read our{' '}
            <Link to={'/privacy-policy/'}>Privacy Policy.</Link>{' '}
          </Paragraph>
        </form>
      ) : (
        <div className={'thank-you__section-wrapper'}>
          <Heading size={'big'} color={'black'} type={3}>
            Thank you for your answers.
          </Heading>
          <Heading size={'medium'} color={'mine-shaft'} type={4}>
            We will count this as the final confirmation of your participation
            in the workshop. If anything changes, please let us know by
            replying to any of our emails. In case the workshop trainer has any
            questions about your input, we will contact you.
          </Heading>
          <Heading size={'medium'} color={'mine-shaft'} type={4}>
            For now, watch our second lightning talk from the previous Finding
            PMF: Berlin edition.
          </Heading>
          <Button
            id={`previous-event`}
            color={'violet'}
            ariaName={'watch the recording'}
            onclick={() => {
              window.location.href =
                'https://www.youtube.com/watch?v=vm2p7LGBT2g';
            }}
          >
            WATCH THE VIDEO &rarr;
          </Button>
        </div>
      )}
    </Section>
  );
};

WorkshopForm.propTypes = {
  url: PropTypes.string.isRequired,
  trackingEvent: PropTypes.string,
  formHeading: PropTypes.string.isRequired,
  workshopQuestion: PropTypes.string.isRequired,
  ratingLabel: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
};

export default WorkshopForm;
