import React, { useEffect } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Banner,
  Specializations,
  Benefits,
  Projects,
  OurCompetitiveAdvantages,
  Reviews,
  TransformationInPractise,
} from './subcomponents';
import './renewableEnergyIndustry.scss';

const RenewableEnergyIndustry = () => {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'single-page single-page--hero renewable-energy-industry'}>
      <Banner />
      <Specializations />
      <Benefits />
      <Projects />
      <OurCompetitiveAdvantages />
      <Reviews />
      <TransformationInPractise />
      <ContactFormServices
        id={'renewable-energy-industry-contact-form'}
        name={'contact-form-biz'}
        description={'Let us know, and we will contact you promptly.'}
      />
    </div>
  );
};

export default RenewableEnergyIndustry;
