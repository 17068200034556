import React from 'react';
import PropTypes from 'prop-types';
import { FullwidthImage } from 'components';
import { classNames } from 'utils';
import './ImageMosaic.scss';

const ImageMosaic = ({ additionalClass, images }) => (
  <div className={classNames('image-mosaic', additionalClass)}>
    {images.length === 1 && (
      <FullwidthImage image={images[0].image.src} alt={images[0].image.alt} />
    )}
    {images.length === 2 && (
      <>
        <div className={'image-mosaic__double'}>
          <div className={'image-mosaic__single'}>
            <img
              src={images[0].image.src}
              alt={images[0].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
          <div className={'image-mosaic__single'}>
            <img
              src={images[1].image.src}
              alt={images[1].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
        </div>
      </>
    )}
    {images.length === 3 && (
      <>
        <FullwidthImage
          image={images[0].image.src}
          alt={images[0].image.alt}
        />
        <div className={'image-mosaic__double'}>
          <div className={'image-mosaic__single'}>
            <img
              src={images[1].image.src}
              alt={images[1].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
          <div className={'image-mosaic__single'}>
            <img
              src={images[2].image.src}
              alt={images[2].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
        </div>
      </>
    )}
    {images.length === 4 && (
      <>
        <FullwidthImage
          image={images[0].image.src}
          alt={images[0].image.alt}
        />
        <div className={'image-mosaic__double'}>
          <div className={'image-mosaic__single'}>
            <img
              src={images[1].image.src}
              alt={images[1].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
          <div className={'image-mosaic__single'}>
            <img
              src={images[2].image.src}
              alt={images[2].image.alt}
              className={'image image-mosaic__half'}
              loading={'lazy'}
            />
          </div>
        </div>
        <FullwidthImage
          image={images[3].image.src}
          alt={images[3].image.alt}
        />
      </>
    )}
  </div>
);

ImageMosaic.defaultProps = {
  additionalClass: '',
};

ImageMosaic.propTypes = {
  additionalClass: PropTypes.string,
  images: PropTypes.array.isRequired,
};

export default ImageMosaic;
