export const data = [
  {
    title: 'Wireframes',
    description:
      'Wireframes are always the part of our design process. We create the sketch for the main devices: desktop, tablet and smartphone. This is a good basis for the graphic project.',
    images: [
      'planet-escape-wireframes-2.jpg',
      'planet-escape-wireframes-1.jpg',
    ],
  },
  {
    title: 'Moodboards',
    description:
      'The idea was to build a refresh brand image based on the current corporate materials - typography, color palette and so on. The first proposition is very elegant. Two types of offers have been made on the division of color, appropriate selection of the proportions and typos. In the second version we decided to rely on sharp compositions simple geometric forms (tile system) - a modern application - to further emphasize the style.',
    images: ['planet-escape-moodboard-1.jpg', 'planet-escape-moodboard-2.jpg'],
  },
];
