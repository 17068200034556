import Slide from './Slide';
import Dots from './Dots';
import Dot from './Dot';
import Caption from './Caption';
import Cta from './Cta';
import Quote from './Quote';
import AnimatedText from './AnimatedText';
import Photo from './Photo';

export { Slide, Dot, Dots, Caption, Cta, Quote, AnimatedText, Photo };
