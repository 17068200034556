import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';

import CloseIcon from 'assets/images/work/close-icon.svg';
import { classNames } from 'utils';

import './FilterLink.scss';

const FilterLink = ({ isActive, filter, label, handleClick }) => {
  const currentFilter = isActive ? null : filter;
  const currentLabel = isActive ? null : label;

  return (
    <li
      id={`filter-${filter}`}
      className={'filter-link-a'}
      key={`filter-${filter}`}
    >
      <a
        href={`#${slugify(`${label} ${filter}`, { lower: true })}`}
        className={classNames('filter-link-a__anchor link', {
          'filter-link-a__anchor--active': isActive,
        })}
        onClick={event => {
          handleClick(event, currentLabel, currentFilter);
        }}
      >
        {filter}
        {isActive && (
          <img
            src={CloseIcon}
            alt={'close icon'}
            className={'filter-link-a__image'}
          />
        )}
      </a>
    </li>
  );
};

FilterLink.defaultProps = {
  isActive: false,
  filter: '',
  label: '',
  handleClick: () => {},
};

FilterLink.propTypes = {
  isActive: PropTypes.bool,
  filter: PropTypes.string,
  label: PropTypes.string,
  handleClick: PropTypes.func,
};

export default FilterLink;
