import React, { Fragment } from 'react';
import classnames from 'classnames';
import { SectionHeader, Paragraph, IconSVG } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import doubleQuotationMark from 'assets/images/products/double-quotation-mark.svg';

import './Slider.scss';

const containerVariants = {
  exit: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.1,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      stiffness: 100,
    },
  },
};

const Slider = ({ items, id }) => {
  const [slide, setSlide] = React.useState(0);
  const slideIndex = wrap(0, items.length, slide);

  const paginate = newDirection => {
    setSlide(slide + newDirection);
  };

  return (
    <div
      className={classnames(
        'products-testimonials__content',
        'glide',
        'slider',
      )}
      id={id}
    >
      <AnimatePresence initial={false}>
        {items
          .filter((item, index) => index === slideIndex)
          .map(item => (
            <Fragment key={`item-${item}`}>
              <motion.img
                initial={'exit'}
                animate={'show'}
                exit={'exit'}
                key={item.img}
                className={'products-testimonials__content-person-img'}
                src={item.img}
                alt={item.name}
              />
              <motion.div
                variants={containerVariants}
                initial={'exit'}
                animate={'show'}
                exit={'exit'}
                key={item.name}
                className={'products-testimonials__content-quote'}
              >
                <img
                  src={doubleQuotationMark}
                  alt={'Double quotation mark'}
                  className={'products-testimonials__content-background-img'}
                />
                <img
                  src={doubleQuotationMark}
                  alt={'Double quotation mark'}
                  className={
                    'products-testimonials__content-background-img products-testimonials__content-background-img--right'
                  }
                />
                <SectionHeader
                  background
                  backgroundColor={'white'}
                  color={'mine-shaft'}
                  headingType={3}
                  size={'big'}
                >
                  {item.quote}
                </SectionHeader>
                <Paragraph
                  additionalClass={
                    'products-testimonials__content-inscription text--demibold'
                  }
                >
                  {item.name},{' '}
                  <span className={'text--white'}>{item.role}</span>
                </Paragraph>
                <motion.div className={'slider__nav'}>
                  <button
                    className={
                      'glide__arrow glide__arrow--left slider-nav__arrow slider-nav__arrow--black slider-nav__arrow--left'
                    }
                    data-glide-dir={'<'}
                    onClick={() => paginate(-1)}
                  >
                    <IconSVG name={'arrow-left-light'} color={'black'} />
                  </button>
                  <button
                    className={
                      'glide__arrow glide__arrow--right slider-nav__arrow--black slider-nav__arrow slider-nav__arrow--right'
                    }
                    data-glide-dir={'>'}
                    onClick={() => paginate(1)}
                  >
                    <IconSVG name={'arrow-right-light'} color={'black'} />
                  </button>
                </motion.div>
              </motion.div>
            </Fragment>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default Slider;
