import React, { useRef } from 'react';
import './Cover.scss';
import { FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';

const Cover = () => {
  const firstPhone = useRef();
  const secondPhone = useRef();
  useScrollAnimation([
    {
      ref: firstPhone,
      transform: { translateX: '-10%' },
    },
    {
      ref: secondPhone,
      transform: { translateX: '100%', translateY: '50%' },
    },
  ]);

  return (
    <div className={'workroom-cover'}>
      <FullwidthImage
        image={getCloudinaryUrl('img/WR_Photography_1.png', 'f_auto,q_auto')}
        alt={'Work table in a restaurant'}
      />
      <img
        className={'image workroom-cover__phone'}
        src={getCloudinaryUrl('img/WR_iP_1.png', 'f_auto,q_auto')}
        innerRef={firstPhone}
        alt={'Workroom app'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-cover__phone'}
        src={getCloudinaryUrl('img/WR_iP_2.png', 'f_auto,q_auto')}
        innerRef={secondPhone}
        alt={'Workroom app'}
        loading={'lazy'}
      />
    </div>
  );
};

export default Cover;
