import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Heading, SectionHeader, Paragraph } from 'components';

const Problem = () => (
  <Section color={'pigeon-post'}>
    <Row className={'no-gap anim-fade-in-bottom'}>
      <Col sm={12} md={6}>
        <SectionHeader
          size={'big'}
          color={'black'}
          lineColor={'white'}
          headingType={2}
        >
          Problem
        </SectionHeader>
      </Col>
      <Col sm={12} md={6}>
        <div className={'sysdt__problem-description'}>
          <Heading color={'black'} size={'medium-plus'} type={3}>
            Lack of digital maturity in the travel sector
          </Heading>
          <Paragraph
            color={'black'}
            size={'regular'}
            additionalClass={'text--light'}
            spaced
          >
            The need for digitalization is obvious. However, many organizations
            in the travel industry rely on digital products and services alone,
            rather than on the systemic digitalization that would be beneficial
            for the whole organisation. The most common issue here is lack of
            maturity and organizational readiness for a real, digital
            transformation aligned with cultural change. Other common problems
            are inflexible and slow processes, a lack of skilled teams and a
            lack of collaboration between IT and business departments.
          </Paragraph>
        </div>
      </Col>
    </Row>
  </Section>
);

export default Problem;
