import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';
import './Articles.scss';

const Articles = ({ allArticles, related }) => (
  <>
    <Section color={'mine-shaft'} additionalClass={'sdo__articles'}>
      <SectionHeader
        size={'big'}
        lineColor={'red'}
        color={'white'}
        descriptionColor={'white'}
        description={
          'Business programming projects are all about scalability and sooner or later you’ll probably need to hire a software development team.'
        }
        fixed
      >
        Our outsourcing articles
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'sdo__learn-more-articles'}
        related={related}
        background={'mine-shaft'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
        bulletColor={'white'}
      />
    )}
  </>
);

export default Articles;
