import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { SliderArrow } from 'components';
import { classNames } from 'utils';
import './BoxLayoutSymetrical.scss';

class BoxLayoutSymetrical extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'box-layout-symetrical box-layout-symetrical--slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow id={'box-mobile-next'} />,
        prevArrow: <SliderArrow id={'box-mobile-prev'} left />,
      },
    };
  }

  render() {
    const { sliderSettings } = this.state;
    const { additionalClass, children, backdrop, mobileSlider } = this.props;

    return (
      <>
        {mobileSlider ? (
          <>
            <div className={'box-layout-symetrical__container'}>
              {backdrop && (
                <div
                  className={classNames('box-layout-symetrical__backdrop', {
                    [`box-layout-symetrical__backdrop--${backdrop}`]: backdrop,
                  })}
                />
              )}
              <div
                className={classNames(
                  'box-layout-symetrical',
                  additionalClass,
                  'hide-mobile',
                )}
              >
                {children}
              </div>
            </div>
            <div className={'hide-desktop hide-tablet'}>
              <Slider {...sliderSettings}>{children[0]}</Slider>
              {children[1]}
            </div>
          </>
        ) : (
          <div className={'box-layout-symetrical__container'}>
            {backdrop && (
              <div
                className={classNames('box-layout-symetrical__backdrop', {
                  [`box-layout-symetrical__backdrop--${backdrop}`]: backdrop,
                })}
              />
            )}
            <div
              className={classNames('box-layout-symetrical', additionalClass)}
            >
              {children}
            </div>
          </div>
        )}
      </>
    );
  }
}

BoxLayoutSymetrical.defaultProps = {
  additionalClass: '',
  backdrop: '',
  mobileSlider: '',
};

BoxLayoutSymetrical.propTypes = {
  additionalClass: PropTypes.string,
  backdrop: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mobileSlider: PropTypes.node,
};

export default BoxLayoutSymetrical;
