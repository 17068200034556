import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './BoxContent.scss';

class BoxContent extends Component {
  render() {
    const { align, type, children } = this.props;
    const typeClass = type ? `box__content--${type.toLowerCase()}` : '';
    const alignClass = align ? `box__content--${align}` : '';

    return (
      <div className={classNames('box__content', alignClass, typeClass)}>
        <div className={'box__content--wrapper'}>{children}</div>
      </div>
    );
  }
}

BoxContent.defaultProps = {
  type: null,
  align: null,
};

BoxContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
  align: PropTypes.string,
};

export default BoxContent;
