import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Box, Paragraph } from 'components';
import './BoxTextContent.scss';

class BoxTextContent extends Component {
  render() {
    const { header, content, footer } = this.props;

    return (
      <Box additionalClass={'box-text-content'}>
        {header && (
          <Paragraph
            size={'small'}
            additionalClass={'box-text-content__header'}
          >
            {ReactHtmlParser(header)}
          </Paragraph>
        )}
        <Paragraph
          size={'medium'}
          additionalClass={'box-text-content__content text--light'}
        >
          {ReactHtmlParser(content)}
        </Paragraph>
        {footer && (
          <Paragraph
            size={'small'}
            additionalClass={'text--bold box-text-content__footer'}
          >
            {footer}
          </Paragraph>
        )}
      </Box>
    );
  }
}

BoxTextContent.defaultProps = {
  header: '',
  footer: '',
};

BoxTextContent.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string.isRequired,
  footer: PropTypes.string,
};

export default BoxTextContent;
