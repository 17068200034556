import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader } from 'components';

const Banner = () => (
  <SubpageBanner
    backgroundColor={'gray-light'}
    image={getCloudinaryUrl(
      'gatsby-cloudinary/software_principles.jpg',
      'f_auto,q_auto,w_1400',
    )}
    contentBackground={'red'}
    additionalClass={'pdad__banner'}
    alt={'Planning on the wall'}
  >
    <SectionHeader
      size={'large'}
      color={'black'}
      lineColor={'violet'}
      description={
        'Boldare’s dedicated development teams build custom web and mobile apps with a focus on speed to market. We are able to deliver your digital product MVP in as little as 4-6 weeks employing the lean startup approach and agile software development.'
      }
      hiddenDescription={
        '<br />Our highly-skilled software engineers work in cross-functional teams with designers, QAs and business analysts creating human-centered web products that fulfill user needs and your business goals. Our software development processes and UX and UI standards guarantee the highest product quality in respect of both code quality and design. Moreover, our development teams will consult you along the way and pass on their know-how so you can develop your own in-house processes and team and continue to grow your product independently.'
      }
      descriptionColor={'white'}
      fixed
      additionalClass={'section-header__description--medium'}
    >
      Product Design <br />
      and Development
    </SectionHeader>
  </SubpageBanner>
);

export default Banner;
