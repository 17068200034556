import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  Paragraph,
  Container,
  Testimonial,
  Link,
} from 'components';
import { Benefits, Steps } from './subcomponents';
import './Process.scss';

const Process = () => (
  <div id={'process'}>
    <Section
      additionalClass={'wdm__process-section wdm__process-section--first'}
      fullwidth
    >
      <Container>
        <Row>
          <Col sm={12} md={4}>
            <Heading
              type={2}
              size={'large'}
              additionalClass={'wdm__process-title'}
            >
              5 reasons why design matters at Boldare
            </Heading>
          </Col>
          <Col sm={12} md={6} mdOffset={2}>
            <p
              className={'paragraph--regular text--default wdm__process-text'}
            >
              Boldare uses a rigorous 5-step design process to ensure that
              every product, every time, is the best possible product for the
              client’s business and users. That process is based on the concept
              of design thinking, in particular the classic version from
              Stanford University’s d.school, a process that enables us to take
              a client’s project from undefined broad product concept to tested
              prototype in very little time. During the design process, the
              client can clearly see rapid progress and the design team is
              motivated and tightly focused on delivering specific and agreed
              elements of the product.
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section additionalClass={'wdm__process-section'}>
      <div className={'wdm__process-quote'}>
        <Testimonial
          content={'Every $1 invested in UX equals $100 in revenue'}
          source={
            'https://www.forrester.com/report/The+Six+Steps+For+Justifying+Better+UX/-/E-RES117708'
          }
          color={'white'}
          headingSize={'medium'}
          id={'why-design-matters__quote-source-1'}
          additionalClass={'text--light'}
        />
        <Paragraph additionalClass={'wdm__process-cta'}>
          <Link
            to={'#wdm-contact-form'}
            className={'wdm__process-link'}
            id={'wdm-contact-quote1'}
          >
            <span className={'text--light'}>
              We&apos;d love to help with your product.&nbsp;
            </span>
            <span
              id={'why-design-matters-sidebar-cta-1'}
              className={'text--bold'}
            >
              Let&apos;s Talk
            </span>
          </Link>
        </Paragraph>
      </div>
      <Steps />
      <div
        className={
          'wdm__process-quote wdm__process-quote--bottom wdm__process-quote--red'
        }
      >
        <Testimonial
          content={'Good UX can increase the revenue by 400%'}
          source={
            'https://www.forrester.com/report/The+Six+Steps+For+Justifying+Better+UX/-/E-RES117708'
          }
          id={'why-design-matters__quote-source-2'}
          color={'white'}
          headingSize={'medium'}
          additionalClass={'text--light'}
        />
        <Paragraph additionalClass={'wdm__process-cta'}>
          <Link
            to={'#wdm-contact-form'}
            className={'wdm__process-link'}
            id={'wdm-contact-quote2'}
          >
            <span className={'text--light'}>
              We&apos;d love to help with your product.&nbsp;
            </span>
            <span
              id={'why-design-matters-sidebar-cta-2'}
              className={'text--bold'}
            >
              Let&apos;s Talk
            </span>
          </Link>
        </Paragraph>
      </div>
      <Benefits />
    </Section>
  </div>
);

export default Process;
