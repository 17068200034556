import React from 'react';
import { Section, SectionHeader } from 'components';
import SingleListItem from 'components/SingleListItem';
import './OurCompetitiveAdvantages.scss';

const OurCompetitiveAdvantages = () => {
  const advantages = [
    {
      index: 1,
      header: 'Lean Startup Process',
      text:
        'We use a model that lets our partners focus on the goals that are important here and now to grow their business. It allows them to work smarter not harder, using the build-measure-learn approach, feedback loops and validated learning as some of the main tools.',
    },
    {
      index: 2,
      header: 'Interdisciplinarity 3.0',
      text:
        'We build Agile teams on steroids - cross-functional, self-organizing, creative problem-solvers, taking ownership from A to Z, and backed by an organizational ecosystem of advisory chapters and strategic experts.',
    },
    {
      index: 3,
      header: 'The same service standards for all clients',
      text:
        'Service standards are rules that show the level of service every client can expect from us, no matter how big they are or how much their contract is worth. Our service standards are designed to provide consistent quality of service for every project. They are a guarantee that your investment will bring value to your business and your budget will be spent efficiently.',
    },
    {
      index: 4,
      header: 'You always get more than you expect',
      text:
        'When working with Boldare, you always get more than a dedicated development team. Your product development team will always be backed by our behind the scenes crew, including DevOps, solution architects, product strategists and new product guides. When you partner with Boldare you get the knowledge, access and experience of the entire organization.',
    },
    {
      index: 5,
      header: 'Business consulting by default',
      text:
        'Our services go way beyond the developer-days featured on the invoice. Behind the scenes there is a group of people supporting our teams to do their best job. These include communities of experts, personal growth guides, strategy advisors, client growth guides, product consultants, Agile coaches, and many more. Their combined efforts make our teams stand out from the crowd.',
    },
    {
      index: 6,
      header: 'Radical transparency',
      text:
        'Thanks to radical transparency, your in-house team will be learning throughout the entire co-creation process, so at the end of the day they will be able to continue to develop the product without our help. This continuous knowledge transfer is a guarantee that the final product will be handed over without the unnecessary stages of knowledge and documentation transfer.',
    },
  ];

  return (
    <Section
      color={'catskill-white'}
      additionalClass={'our-competitive-advantages'}
    >
      <SectionHeader
        color={'mine-shaft'}
        headingType={2}
        lineColor={'yellow'}
        size={'large'}
        description={
          'It’s hard to argue with facts: 80% of our customers return to us for help with new digital products. Here’s why:'
        }
      >
        Our competitive advantages
      </SectionHeader>
      <ul className={'our-competitive-advantages__list'}>
        {advantages.map(advantage => (
          <SingleListItem
            key={advantage.index}
            index={advantage.index}
            header={advantage.header}
            text={advantage.text}
          />
        ))}
      </ul>
    </Section>
  );
};

export default OurCompetitiveAdvantages;
