import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ContactFormServices } from 'components';
import { navigateToId } from 'utils';
import { PostList } from 'views/blog/subcomponents';
import { Banner, Experts } from './subcomponents';
import './newNormal.scss';

class NewNormal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters
        .sort((a, b) => a.order - b.order)
        .map(filter => ({ name: filter.name, isActive: false })),
      filtersActive: false,
    };
  }

  handleFilterStatus = () =>
    this.setState(prevState => ({
      filtersActive:
        prevState.filters.filter(item => item.isActive).length > 0,
    }));

  toggleFilter = filter => {
    const { filters } = this.state;
    this.setState({
      filters: filters.map(item => ({
        ...item,
        isActive: item.name === filter,
      })),
    });
    this.handleFilterStatus();
    navigateToId('blog-post-list');
  };

  render() {
    const { filters, filtersActive } = this.state;
    const { posts, authors } = this.props;

    return (
      <div className={'single-page single-page--hero nn-page'}>
        <Banner link={'blog-post-list'} />
        <PostList
          posts={posts}
          filters={filters}
          filtersActive={filtersActive}
          toggleFilter={this.toggleFilter}
          authors={authors}
          campaignID={'new-normal'}
          newNormalBanner
        />
        <Experts />
        <ContactFormServices
          id={'form'}
          name={'contact-form-biz'}
          title={'Do you have any questions?'}
          description={'Let us know, and we will contact you promptly.'}
          pardotLink={'https://go.boldare.com/l/688533/2020-07-09/8dfbs'}
          textareaContent={'My question is…'}
        />
      </div>
    );
  }
}

NewNormal.propTypes = {
  posts: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
};

export default NewNormal;
