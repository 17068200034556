export const data = [
  {
    title: 'Product Workshop and Strategy',
    description:
      'The team will help you explore your product through a product vision workshop.<br /><br />Depending on your individual needs, we can use a variety of techniques and tools to help you clarify the vision of your product, innovate and find ways to solve existing problems or explore a new business model for your idea.<br /><br />After mapping out your needs, we will lay out a plan to make your vision a reality.<br /><br />Our team will be consulting throughout the duration of the development, advising you on the best course of action for your product and business.',
  },
  {
    title: 'Agile Product Development and Knowledge Transfer',
    description:
      'Our focus is to get your product to market as quickly as possible with minimum cost and effort.<br /><br />That is why we develop web products using an agile approach to software development and the lean startup approach. We put great emphasis on testing the product with real users and improving it in iterations so you can achieve product-market fit, the stage where users flock to your product.<br /><br />At the same time, we will help you build in-house processes and a digital culture within your company so you can continue to develop the product independently after our collaboration ends.',
  },
  {
    title: 'Sustainable Growth',
    description:
      'Following a successful product-market fit phase you can scale your web or mobile product and develop a stable and secure system and infrastructure to allow sustainable growth.<br /><br />Our developers will help you build the product and offer consulting until you are ready to move on your own.<br /><br />Armed with knowledge about software development processes passed on to you by our team you can safely take over the growth of your product.',
  },
  {
    title: 'Digital Transformation Services Tools',
    description:
      '<ul class="unordered-list"> <li>Design Sprints</li><li>Innovation Workshops</li><li>User &amp; Market Research</li><li>Customer Journey Mapping</li><li>Information Architecture &amp; User Flow</li><li>Wireframing &amp; Process Flows</li> </ul>',
  },
];
