import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  UnorderedList,
  AbsoluteCTA,
  BackgroundContent,
} from 'components';
import companyData from '../../../../../meta/companyData';

const Solution = () => (
  <div className={'pdad__solution'}>
    <Section color={'mine-shaft'}>
      <Row className={'no-gap anim-fade-in-bottom'}>
        <Col sm={12} md={6}>
          <SectionHeader
            size={'big'}
            color={'white'}
            lineColor={'violet'}
            headingType={2}
          >
            Solution
          </SectionHeader>
        </Col>
        <Col sm={12} md={6}>
          <div className={'pdad__solution-description'}>
            <Heading color={'white'} size={'medium'} type={3}>
              Boldare offers a wide range of services to help you create
              user-centred digital products
            </Heading>
          </div>
        </Col>
      </Row>
    </Section>
    <Section fullwidth spaceless additionalClass={'pdad__solution-content'}>
      <div className={'w-container'}>
        <div className={'pdad__solution-items'}>
          <Row className={'no-gap anim-fade-in-bottom'}>
            <Col sm={6} md={4}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'1'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Web Design
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'Human-centered design focused on achieving user goals and business goals.',
                    'Agile, iterative approach to design with an increment of your product delivered every sprint (1-2 weeks).',
                    'Supported by user tests, real data, advanced tools and expert analysis (a lean approach based on the build-measure-learn principle).',
                    `Award-winning team of designers (${companyData.awwwards}x Awwwards Honorable Mentions, CSS Real, German Web Award, Indigo Design Award).`,
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'2'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Web Development
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    `We have over ${companyData.years_experience} years of experience and over 100 highly skilled web developers on board who have delivered over ${companyData.products} products for our international clients.`,
                    'Following agile software development principles and the lean startup approach, we can deliver a first working version of your product within a few weeks.',
                    'Our frontend and backend developers work directly with you in dedicated, self-organizing teams, alongside designers, QAs, and business analysts with the guidance of a scrum master.',
                    'We pass on to you all know-how so you can continue to grow your product sustainably.',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'3'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Mobile Development
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'We build both native mobile apps and hybrid mobile apps, always choosing a custom solution suited to your business goals.',
                    'We will design the entire architecture, user flow, UX and UI of your mobile app.',
                    'Agile development and the lean startup approach will guarantee that you receive your product quickly and that it suits your business goals and user’s needs.',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'4'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Custom Software Development
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'We follow best practice development processes and offer top-notch software quality.',
                    'We offer self-organizing, dedicated teams consisting of frontend developers, backend developers, designers, QAs, business analysts and scrum masters who work directly with you.',
                    'Our skilled specialists will not only design and code your software but also advise you on every step of the full product development cycle and beyond.',
                    'We follow agile development principles which guarantee incremental iterative delivery but also allow you to change the scope and control the budget with every sprint (every 1-2 weeks).',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'5'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Security and Quality Assurance
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'Our experienced QA engineers will guarantee the highest quality of code regarding security and stability.',
                    'Quality assurance specialists will design and conduct manual and automated tests to make sure that the software we develop is flawless.',
                    'Our standards include code reviews, tests, a definition of done, continuous integration, continuous delivery.',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className={'pdad__solution-item-wrapper'}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'6'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Infrastructure Architecture
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'Our experienced DevOps will advise you on how to create and help you set up a stable and secure infrastructure that will support your digital products.',
                    'You business strategy has an impact on your infrastructure and our DevOps specialists will take into consideration your goals, needs and future plans.',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className={'pdad__solution-item-wrapper'}>
              <div className={'pdad__solution-item'}>
                <BackgroundContent
                  height={220}
                  content={'7'}
                  color={'violet'}
                  opacity={0.1}
                />
                <SectionHeader size={'tiny-plus'} headingType={3}>
                  Tech Stack Consulting
                </SectionHeader>
                <UnorderedList
                  additionalClass={'text--light text--black'}
                  items={[
                    'Our highly skilled and experienced development specialists will propose a custom solution suited to your business needs.',
                    'We offer application and software audits that can help you identify all flows in your system and plan improvements.',
                    'Our teams have experience in modernizing legacy systems and can lay out a plan for upgrading your software.',
                    'Our teams use a variety of methods (e.g. event storming) to help them quickly and efficiently get to know your system and align your technical and business goals.',
                  ]}
                  showCount={1}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <AbsoluteCTA id={'page-pdad-solution'} />
    </Section>
  </div>
);

export default Solution;
