import React from 'react';
import { scroller } from 'react-scroll';
import { SectionHeader, IconSVG } from 'components';
import background from 'assets/videos/boldare_products.webm';
import background2 from 'assets/videos/boldare_products.mp4';
import './HeroSection.scss';

const HeroSection = () => {
  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `products-hero-${item}`,
      });
    }
  };

  return (
    <section className={'products-hero section-box'} id={'hero-section'}>
      <div className={'products-hero__content'}>
        <SectionHeader
          additionalClass={'products-hero__heading'}
          size={'extra-large'}
          color={'white'}
          headingType={1}
          noLine
        >
          Let&apos;s make{' '}
          <span className={'section-header__headline--span'}>products</span>{' '}
          you can be proud of!
        </SectionHeader>
        <div className={'products-hero__cta'}>
          <a href={'/career/'} rel={'noreferrer'} target={'_blank'}>
            <button
              type={'button'}
              className={
                'products-btn products-btn--primary products-btn--yellow'
              }
              aria-label={'button'}
              onClick={() => {
                handleClick('career-click');
              }}
            >
              Go to career
            </button>
          </a>
          <div className={'products-hero__cta-btn-group'}>
            <button
              onClick={() => {
                scroller.scrollTo('products-etudes', {
                  spy: false,
                  smooth: true,
                  duration: 700,
                  offset: -100,
                });
              }}
            >
              <div className={'products-btn products-btn--circle'}>
                <span>
                  <IconSVG
                    name={'play'}
                    color={'white'}
                    additionalClass={'play-icon'}
                  />
                </span>
                <IconSVG
                  name={'circle'}
                  color={'white'}
                  additionalClass={'circle'}
                />
                <p className={'paragraph paragraph--regular'}>
                  Watch short stories
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <>
        <video
          controls={false}
          src={background2}
          className={'video-background'}
          autoPlay
          preload={'auto'}
          loop
          muted
          playsInline
        >
          <source src={background} type={'video/webm'} />
          <source src={background2} type={'video/mp4'} />
        </video>
      </>

      <p className={'indicator'}>
        <span>scroll down for more</span>
      </p>
    </section>
  );
};

export default HeroSection;
