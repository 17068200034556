import React from 'react';
import PropTypes from 'prop-types';

import './BigNumberOrderedList.scss';

function BigNumberOrderedList({ children, className }) {
  return (
    <ul className={`big-number-ordered-list ${className}`}>{children}</ul>
  );
}

BigNumberOrderedList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

BigNumberOrderedList.defaultProps = {
  className: '',
};

function Item({ children, subtext, subtextClassName }) {
  return (
    <li>
      <div className={'big-number-ordered-list__item-content'}>
        <div className={'big-number-ordered-list__text'}>{children}</div>{' '}
        {subtext && (
          <div
            className={`big-number-ordered-list__subtext ${subtextClassName}`}
          >
            {subtext}
          </div>
        )}
      </div>
    </li>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtextClassName: PropTypes.string,
};

Item.defaultProps = {
  subtext: null,
  subtextClassName: '',
};

BigNumberOrderedList.Item = Item;

export default BigNumberOrderedList;
