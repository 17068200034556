import React, { useEffect, useState } from 'react';
import { PopUp } from 'components';
import SectionHolaspiritPopup from './SectionHolaspiritPopup';
import Holaspirit from '../Holaspirit';
import Image from '../Image';
import './SectionHolaspirit.scss';

const SectionHolaspirit = () => {
  const [date, setDate] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [data, setCircleData] = useState(undefined);

  const onCircleClick = circleData => {
    setPopupVisible(true);
    setCircleData({ ...circleData });
  };

  useEffect(() => {
    const removeInterval = setInterval(() => {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const now = new Date();
      const hours =
        now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
      const minutes =
        now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
      const seconds =
        now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
      setDate(
        `Real time status: ${now.getDate()} ${
          months[now.getMonth()]
        } ${now.getFullYear()} ${hours}:${minutes}:${seconds}`,
      );
    }, 1000);
    return () => clearInterval(removeInterval);
  }, []);

  return (
    <div className={'section-wrapper section-holaspirit'}>
      <section className={'grid-layout'}>
        <div className={'about-header'}>
          Our structure, where{' '}
          <span className={'marker-highlight-small marker-black'}>change</span>{' '}
          is the only constant
        </div>
        <div className={'section-holaspirit__description'}>
          Meet the leaders who are responsible for the strategy of particular
          circles. They set new directions and push the organization forward.
          With holacracy as an operating system, they are able to adjust to new
          conditions, quickly and anytime.
        </div>
        <div className={'section-holaspirit__timer'}>{date}</div>
        <PopUp
          visible={popupVisible}
          visibilityHandler={showPoup => setPopupVisible(showPoup)}
        >
          <SectionHolaspiritPopup circleData={data} />
        </PopUp>
        <Holaspirit
          className={'section-holaspirit__diagram'}
          onCircleClick={onCircleClick}
        />
        <Image
          className={'section-holaspirit__logo'}
          imageName={'logo-holaspirit'}
        />
      </section>
    </div>
  );
};

export default SectionHolaspirit;
