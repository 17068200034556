import { BackgroundImage, Paragraph, Section, TitleAskCto } from 'components';
import React from 'react';
import './AboutBoldare.scss';

const AboutBoldare = () => {
  return (
    <div>
      <Section fullWidth>
        <div className={'askcto-about-boldare-title'}>
          <TitleAskCto
            title={'About Boldare'}
            text={
              'Boldare is an agile-powered company with 17 years of experience in the international software development market. The company has built over 270 digital products for clients around the globe.'
            }
            secondText={
              'Boldare’s purpose is to help solve the business problems of their partners, by creating bespoke software products equipped with user-centered, award-winning UX designs focused on addressing users’ real needs. Boldare helps improve business processes and guide partners in their digital transformation efforts.'
            }
          />
          <Paragraph
            color={'black'}
            size={'ctoask'}
            additionalClass={'askcto-270'}
          >
            270
          </Paragraph>
        </div>
      </Section>
      <div className={'about-boldare-photos'}>
        <div className={'about-boldare-photos-first-row'}>
          <BackgroundImage
            asset={'gatsby-cloudinary/askcto-about-lounge.png'}
            maxWidth={600}
          />
        </div>
        <div className={'about-boldare-photos-second-row'}>
          <BackgroundImage
            asset={'gatsby-cloudinary/askcto-about-stairs.png'}
            maxWidth={400}
            additionalClass={'askcto-about-small-photos stairs'}
          />
          <BackgroundImage
            asset={'gatsby-cloudinary/askcto-about-coffe.jpg'}
            maxWidth={250}
            additionalClass={'askcto-about-small-photos coffe-table'}
          />

          <BackgroundImage
            asset={'gatsby-cloudinary/askcto-about-office.png'}
            maxWidth={800}
            additionalClass={'askcto-about-office'}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutBoldare;
