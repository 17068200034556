import React from 'react';
import PropTypes from 'prop-types';

import { Section, ContactForm } from 'components';

import './ContactUsForm.scss';

const ContactUsForm = ({ id, name }) => {
  return (
    <Section
      id={id}
      color={'yellow'}
      additionalClass={'contact-us-form'}
      spaceless
    >
      <ContactForm.V2 id={id} name={name} />
    </Section>
  );
};

ContactUsForm.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ContactUsForm;
