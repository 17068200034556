import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  Heading,
  SectionHeader,
  Paragraph,
  Button,
} from 'components';

class Problem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
    };
  }

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <Section color={'gray-light'}>
        <Row className={'no-gap anim-fade-in-bottom'}>
          <Col sm={12} md={6}>
            <SectionHeader
              size={'big'}
              color={'black'}
              lineColor={'red'}
              headingType={2}
            >
              Problem
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            <div className={'pdad__problem-description'}>
              <Heading color={'black'} size={'medium'} type={3}>
                I want to design and develop an innovative digital product and
                release it to market
              </Heading>
              <Paragraph additionalClass={'text--light'} color={'black'}>
                Companies around the world struggle with a shortage of skilled
                software developers and designers, budget constraints and a
                lack of the know-how to develop human-centered applications
                that will fit the market and fulfill business needs.
              </Paragraph>
              {isFullDescriptionShown && (
                <Paragraph
                  additionalClass={'pdad__additional-description text--light'}
                  color={'black'}
                >
                  Moreover, speed to market is increasingly important as
                  startups and corporations compete for the same user segments
                  in the global economy. Finally, there is the business need to
                  consider the quality of the web products being released to
                  market, as users are increasingly demanding a high-quality
                  digital experience with functional UX and beautiful UI, not
                  to mention a flawless and secure backend engine behind it.
                </Paragraph>
              )}
              <div
                className={'pdad__learn-more-button'}
                onClick={this.toggleHiddenDescription}
              >
                <Button
                  order={'secondary'}
                  ariaName={'Description button'}
                  arrowColor={'violet'}
                >
                  {isFullDescriptionShown ? 'Show less' : 'Learn more'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    );
  }
}

export default Problem;
