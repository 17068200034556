import React from 'react';
import { Button, Paragraph, Heading, Image } from 'components';
import './style.scss';
import PropTypes from 'prop-types';

const developmentOptimizationPeopleData = [
  {
    avatar: `img/Marek_Szyd%C5%82owski.jpg`,
    name: `Marek Szydłowski`,
    position: `Generative AI Engineer`,
  },
  {
    avatar: `img/Olivier_Halupczok.jpg`,
    name: 'Olivier Halupczok',
    position: 'Generative AI Engineer',
  },
];

const developmentOptimizationBanner = {
  button: 'Get in touch!',
  description:
    'Want to know how to use AI-powered solutions to optimize your development costs? Describe your needs, and our team will come up with a custom idea.',
};

const AiExpertsContactCtaBanner = ({ id }) => {
  const getPeopleData = () => {
    const dataMap = {
      developmentOptimizationPeopleData,
    };
    return dataMap.developmentOptimizationPeopleData || [];
  };
  return (
    <div className={'aiexpertscontactcta-banner__container'} id={id}>
      <div className={'aiexpertscontactcta-banner'}>
        <Heading color={'white'} size={'big'}>
          Take Advantage of the AI Revolution
        </Heading>
        <Paragraph color={'white'} size={'medium-constant'}>
          {developmentOptimizationBanner.description}
        </Paragraph>
        <div className={'aiexpertscontactcta-banner__bottom-container'}>
          {getPeopleData().map(item => (
            <div
              key={item.name}
              className={'aiexpertscontactcta-banner__item'}
            >
              <figure className={'aiexpertscontactcta-banner__item-avatar'}>
                <Image
                  src={item.avatar}
                  width={'172'}
                  height={'172'}
                  alt={item.name}
                />
              </figure>
              <Heading color={'white'} size={'medium-plus-small'}>
                {item.name}
              </Heading>
              <Paragraph color={'white'}>{item.position}</Paragraph>
            </div>
          ))}
        </div>
        <div className={'aiexpertscontactcta-banner__link-wrapper'}>
          <a href={'/services/ai-software-development-consulting/#contact'}>
            <Button
              id={'aiexpertscontactcta-banner-button'}
              color={'yellow'}
              ariaName={`Let's talk`}
              additionalClass={
                'aiexpertscontactcta-banner__button aiexpertscontactcta-banner__paragraph--uppercase'
              }
            >
              {developmentOptimizationBanner.button}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

AiExpertsContactCtaBanner.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AiExpertsContactCtaBanner;
