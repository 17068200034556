import React, { Component } from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import BringQualifiedLead from 'views/bringAQualifiedLead';

import config from '../../meta/config';

class BringAQualifiedLead extends Component {
  render() {
    const page = pages['bring-a-qualified-lead'];
    const { navColors } = page;
    const canonical = `${config.siteUrl}/bring-a-qualified-lead/`;

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} bannerHidden />
        <BringQualifiedLead />
      </Layout>
    );
  }
}

export default BringAQualifiedLead;
