
import React from 'react';
import { Section } from 'components';
import softwareDevelopmentClientLogo from 'views/softwareDevelopmentOutsourcing/clientsLogos';
import SliderElement from './SliderElement';
import './LogoSlider.scss';

const LogoSlider = () => (

  <Section
    id={'logo-slider'}
    color={'white'}
    fullwidth
    noPadding
  >
    <SliderElement
      logos={softwareDevelopmentClientLogo}
      header={'Some of the companies that trusted us'}
    />
  </Section>
);

export default LogoSlider;
