import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import { pushToDataLayer } from 'utils';
import { SectionHeader } from 'components';

import SingleListItem from 'components/SingleListItem';
import { competitiveAdvantagesA, competitiveAdvantagesB } from './logic';
import './CompetitiveAdvantages.scss';

const CompetitiveAdvantages = ({ variant }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { header, text, advantages } =
    variant === 'competitive-advantages-a'
      ? competitiveAdvantagesA
      : competitiveAdvantagesB;

  useEffect(() => {
    if (inView) {
      const isWindow = typeof window !== 'undefined';
      let wasCompetitiveAdvatagesSeen;

      if (isWindow) {
        wasCompetitiveAdvatagesSeen = window.sessionStorage.getItem(
          'wasCompetitiveAdvatagesSeen',
        );

        if (!wasCompetitiveAdvatagesSeen) {
          window.sessionStorage.setItem('wasCompetitiveAdvatagesSeen', true);
          pushToDataLayer(`${variant}-display`);
        }
      }
    }
  }, [inView]);

  return (
    <div className={'competitive-advantages'} ref={ref}>
      <SectionHeader description={text} lineColor={'yellow'}>
        {header}
      </SectionHeader>
      <ul className={'competitive-advantages__list'}>
        {advantages.map(advantage => (
          <SingleListItem
            key={advantage.index}
            index={advantage.index}
            header={advantage.header}
            text={advantage.text}
            variant={variant}
          />
        ))}
      </ul>
    </div>
  );
};

CompetitiveAdvantages.defaultProps = {
  variant: 'competitive-advantages-a',
};

CompetitiveAdvantages.propTypes = {
  variant: PropTypes.string,
};

export default CompetitiveAdvantages;
