import React from 'react';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import AgileProductBuilders from 'views/agileProductBuilders';
import config from '../../meta/config';

const page = pages['agile-product-builders'];
const url = `${config.siteUrl}/agile-product-builders/`;
const canonical = `${url}`;

const AgileProductBuildersPage = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <AgileProductBuilders />
    </Layout>
  );
};

export default AgileProductBuildersPage;
