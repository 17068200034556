import React, { Component } from 'react';
import * as Scroll from 'react-scroll';
import { kebabCase } from 'lodash';
import Logo from 'assets/images/logo-sidenav.svg';
import { PATHS } from 'config';
import { classNames } from 'utils';
import './SideNav.scss';

class SideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBottom: false,
      containerHeight: 0,
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      containerHeight: document.getElementsByClassName('single-case-study')[0]
        .clientHeight,
    });
  };

  handleScroll = () => {
    this.setState({
      isBottom:
        window.scrollY + window.innerHeight >= this.state.containerHeight,
      containerHeight: document.getElementsByClassName('single-case-study')[0]
        .clientHeight,
    });
  };

  renderNavItems = () =>
    PATHS.map(item => {
      const { link, name, color } = item;
      const SpyLink = Scroll.Link;

      return (
        <SpyLink
          id={`wdm__sidenav-${kebabCase(name.toLowerCase())}`}
          activeClass={'active'}
          to={link}
          spy
          smooth
          offset={0}
          duration={500}
          className={classNames('wdm__sidenav-link', {
            [`wdm__${link}`]: link,
          })}
        >
          <div
            className={classNames('wdm__sidenav-button', {
              [`wdm__sidenav-button--${color}`]: color,
            })}
          />
          <div className={'wdm__sidenav-label'}>{name}</div>
        </SpyLink>
      );
    });

  render() {
    const { isBottom } = this.state;
    return (
      <div
        className={classNames('wdm__sidenav-container', {
          'is-bottom': isBottom,
        })}
      >
        <a id={'wdm__go-home'} href={'/'} className={'link wdm__sidenav-logo'}>
          <img className={'image'} alt={'logo'} src={Logo} loading={'lazy'} />
        </a>

        <div className={'wdm__sidenav-buttons'}>
          <a
            id={'wdm__mail'}
            href={
              'mailto:design@boldare.com?subject=Why%20design%20matters%3F'
            }
            className={
              'link wdm__sidenav-contact wdm__sidenav-contact--desktop design-mail'
            }
          >
            @
          </a>
          <nav className={'wdm__sidenav-links'}>
            {typeof window !== 'undefined' &&
              window.location &&
              this.renderNavItems()}
          </nav>
        </div>

        <a
          id={'wdm__mail'}
          href={'mailto:design@boldare.com?subject=Why%20design%20matters%3F'}
          className={
            'link wdm__sidenav-contact wdm__sidenav-contact--mobile design-mail'
          }
        >
          @
        </a>
      </div>
    );
  }
}

export default SideNav;
