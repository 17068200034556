import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Heading,
  Paragraph,
  BackgroundImage,
  HashtagList,
} from 'components';
import { classNames } from 'utils';
import './BoxTestimonial.scss';

class BoxTestimonial extends Component {
  render() {
    const { additionalClass, data } = this.props;
    const { content, settings } = data;
    const { image, testimonial, caption, tags, name } = content;
    const { backgroundImage, tileColor, theme, link, id } = settings;
    const colors =
      theme === 'light'
        ? { primary: 'white', secondary: 'mine-shaft', text: 'black' }
        : { primary: 'mine-shaft', secondary: 'white', text: 'white' };

    return (
      <Box
        link={link}
        id={id}
        additionalClass={`box-testimonial ${additionalClass}`}
        backgroundColor={tileColor}
      >
        {backgroundImage && (
          <BackgroundImage
            src={backgroundImage}
            additionalClass={'box-testimonial__background'}
          />
        )}
        <div className={'box-testimonial__content'}>
          <div>
            <img
              src={image.srcWebp}
              alt={`${name} - logo`}
              className={'image box-testimonial__image'}
              loading={'lazy'}
            />
            <Heading
              type={4}
              size={'medium'}
              additionalClass={'box-testimonial__testimonial'}
              color={colors.primary}
            >
              {ReactHtmlParser(testimonial)}
            </Heading>
          </div>
          {caption && (
            <Paragraph
              color={colors.primary}
              size={'small'}
              additionalClass={'box-testimonial__caption'}
            >
              <span
                className={classNames(
                  {
                    [`text--${colors.primary}`]: colors.primary,
                  },
                  'text--default',
                )}
              >
                {caption.author}
              </span>
              , {caption.position}
            </Paragraph>
          )}
          {tags && (
            <div
              className={classNames('box-testimonial__tags', {
                [`box-testimonial__tags--${colors.primary}`]: colors.primary,
              })}
            >
              <HashtagList color={colors.primary || 'black'} data={tags} />
            </div>
          )}
        </div>
      </Box>
    );
  }
}

BoxTestimonial.defaultProps = {
  additionalClass: '',
  tileColor: 'white',
};

BoxTestimonial.propTypes = {
  data: PropTypes.shape().isRequired,
  additionalClass: PropTypes.string,
  tileColor: PropTypes.string,
};

export default BoxTestimonial;
