export default {
  title: 'Explore our <br />design-powered <br />products',
  items: [
    {
      background: 'img/awwwards/chartipedia.png',
      content: 'Chartipedia',
      description:
        'Beautiful and legible data visualization - is it even possible? Yes, it is! We helped our customer, Chartipedia, materialize this vision with an MVP. On top of that, our interdisciplinary team designed and developed a marketing landing page so delicious that it scored numerous awards, including the German Design Award and Awwwards, to name just a couple.',
      color: 'violet',
      awards: ['awwwards', 'germanDesign'],
      link: '/work/case-study-chartipedia/',
    },
    {
      background: 'img/awwwards/boldareboards.png',
      content: 'Boldare Boards',
      description:
        'Boldare Boards is our internal tool for conducting meetings online. It is so good that we couldn’t keep it to ourselves, so we’re giving it to everyone. Such a tool needs something special, so within three weeks, we designed and developed a dedicated start page, visually outstanding and packed with easter eggs to discover. And yes, it has already started to win prizes.',
      awards: ['awwwards'],
      color: 'yellow',
    },
    {
      background: 'img/workroom_banner.png',
      content: 'Workroom',
      description:
        'Creating the Workroom platform was a pure pleasure. The app allows you to book a table for work or business meetings in some of London’s most hip restaurants. We managed to bring up the most important parts of what Workroom has to offer: outstanding interiors, mouthwatering lunch offers and the flexibility of working out of the office in a vibrant city.',
      color: 'yellow',
      link: '/work/the-workroom-case-story/',
    },
    {
      background: 'img/why-design-matters/01_Virgin/main.jpg',
      content: 'Virgin Radio Oman',
      description:
        'With only four weeks to design, develop and launch web and mobile apps, Virgin Radio Oman was a hard nut to crack. Nonetheless we successfully released a fully working MVP to the market, making SABCO Media’s debut very powerful. ',
      color: 'alizarin-crimson',
      link: '/work/case-study-virgin-radio-oman/',
    },
    {
      background: 'img/why-design-matters/02_Eres/main.jpg',
      content: 'Eres Yachting',
      description:
        'Creating designs for luxury services is always challenging - the look has to be absolutely flawless and breathtaking. This is why this yacht charter agency chose Boldare to take a huge step forward and create their first online booking service. The results are delightful.',
      color: 'pacific-blue',
      link: '/work/case-study-eres-yachting-booking-platform/',
    },
  ],
};
