export const data = [
  {
    link: '/event/berlin/pmf-berlin-june-1.png',
    type: 'img',
  },
  {
    link: '/event/berlin/pmf-berlin-june-2.png',
    type: 'img',
  },
  {
    link: '/event/berlin/pmf-berlin-june-3.png',
    type: 'img',
  },
  {
    link: '/event/berlin/pmf-berlin-june-4.png',
    type: 'img',
  },
  {
    link: '/event/berlin/pmf-berlin-june-5.png',
    type: 'img',
  },
  {
    link: '/event/amsterdam/Meetup_YT_cover_1.png',
    type: 'video',
    yt: 'https://www.youtube.com/watch?v=fMcpijIVCPc',
  },
];
