import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Paragraph, Heading, SectionHeader, IconSVG } from 'components';
import { classNames } from 'utils';
import './StageTile.scss';

const StageTile = ({
  icon,
  caption,
  title,
  description,
  team,
  teamDescription,
  mainColor,
  spaced,
}) => (
  <div
    className={classNames(
      'stage-tile',
      {
        'stage-tile--spaced': spaced,
      },
      'anim-fade-in-bottom',
    )}
  >
    {caption && (
      <Paragraph
        additionalClass={'text--default text--red stage-tile__caption'}
      >
        {caption}
      </Paragraph>
    )}
    {icon && <IconSVG name={icon} size={'big'} color={'black'} />}
    <SectionHeader
      size={'small'}
      headingType={2}
      color={mainColor}
      additionalClass={'stage-tile__title'}
    >
      {title}
    </SectionHeader>
    <Paragraph additionalClass={'text--light'}>
      {ReactHtmlParser(description)}
    </Paragraph>
    {team && (
      <Heading type={3} size={'medium'} additionalClass={'stage-tile__team'}>
        {team}
      </Heading>
    )}
    {teamDescription && (
      <Paragraph additionalClass={'text--light'}>{teamDescription}</Paragraph>
    )}
  </div>
);

StageTile.defaultProps = {
  icon: '',
  caption: '',
  mainColor: 'black',
  spaced: false,
  team: '',
  teamDescription: '',
};

StageTile.propTypes = {
  icon: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string.isRequired,
  team: PropTypes.string,
  teamDescription: PropTypes.string,
  description: PropTypes.string.isRequired,
  mainColor: PropTypes.string,
  spaced: PropTypes.bool,
};

export default StageTile;
