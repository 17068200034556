import React from 'react';
import './WhoAreWe.scss';
import {
  Heading,
  Paragraph,
  Section,
  SectionHeader,
  YouTubePlayer,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { Numbers } from 'views/newsroom/subcomponents';
import companyData from '../../../../../meta/companyData';
import dataToDisplay from './logic';

const WhoAreWe = () => (
  <Section
    additionalClass={'contact-de-who-are-we__section'}
    id={'connect-de-cities'}
    spacedTop
  >
    <div className={'contact-de-who-are-we__background-color-box'} />
    <div>
      <Row>
        <Col xs={12}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            fixed
            noLine
            additionalClass={'contact-de-who-are-we__text-header'}
          >
            Boldare - wer sind wir?
          </SectionHeader>
          <Row>
            <Col sm={12} md={7} lg={6}>
              <div
                className={
                  'contact-de-who-are-we__text-header-secondary-wrapper'
                }
              >
                <div
                  className={'contact-de-who-are-__vertical-line hide-mobile'}
                />
                <Heading
                  type={2}
                  size={'medium-constant'}
                  color={'black'}
                  additionalClass={
                    'contact-de-who-are-we__text-header-secondary contact-de-who-are-we__text-header-secondary-top'
                  }
                >
                  Boldare ist ein agil arbeitendes Produktdesign- und
                  Entwicklungsunternehmen mit fast zwei Jahrzehnten Erfahrung.
                  Wir helfen DACH-Branchenführern, Produkte zu entwickeln, die
                  einen messbaren Einfluss auf ihr Geschäft haben.
                </Heading>
                <Heading
                  type={2}
                  size={'medium-constant'}
                  color={'black'}
                  additionalClass={
                    'contact-de-who-are-we__text-header-secondary'
                  }
                >
                  Mit mehr als 200 Produktentwicklungsexperten in unseren Büros
                  in Warschau, Krakau, Breslau und Gleiwitz sind unsere
                  Experten nur wenige Stunden mit dem Flugzeug oder dem Auto
                  von Ihnen entfernt. Das ist näher als Sie denken!
                </Heading>
              </div>
            </Col>
            <Col
              sm={12}
              md={5}
              lg={6}
              className={'contact-de-who-are-we__employees-number-text-col'}
            >
              <div>
                <Heading
                  size={'extra-large'}
                  color={'violet'}
                  type={4}
                  additionalClass={
                    'contact-de-who-are-we__employees-number-text'
                  }
                >
                  {companyData.employees}+
                </Heading>
                <Paragraph
                  color={'violet'}
                  size={'medium-plus'}
                  additionalClass={
                    'contact-de-who-are-we__employees-number-title-text'
                  }
                >
                  IT-Spezialisten
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row end={'md'}>
        <Col sm={12} md={10} className={'contact-de-who-are-we__video-col'}>
          <YouTubePlayer
            src={'https://www.youtube.com/watch?v=0KaG_zOd6uA'}
            light
            playing
            controls
          />
        </Col>
      </Row>
      <Numbers dataToDisplay={dataToDisplay} />
    </div>
  </Section>
);

export default WhoAreWe;
