import React from 'react';
import PropTypes from 'prop-types';
import './SectionHello.scss';
import SectionHelloDesktop from './SectionHelloDesktop';
import SectionHelloMobile from './SectionHelloMobile';

const SectionHello = ({ variant, isDesktop }) => {
  return isDesktop ? (
    <SectionHelloDesktop variant={variant} />
  ) : (
    <SectionHelloMobile variant={variant} />
  );
};

export default SectionHello;

SectionHello.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
