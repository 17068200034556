export default [
  {
    name: 'Maciej Sienkiewicz',
    role: 'Scrum master at Boldare',
    feedback:
      'A retrospective gives the team a dedicated area for change. During the retrospective, the team revisits decisions made previously and agrees on trying new things, therefore continuously improving their ways of working.',
    img: 'img/maciej_sienkiewicz.png',
  },
  {
    name: 'Jacek Zięba',
    role: 'Scrum master at Boldare',
    feedback:
      'The retrospective meeting lets us focus on things that went well during the sprint and discuss what could be improved in the future. It’s a great opportunity to create a team consensus and make our work more productive.',
    img: 'img/jacek_zieba.jpg',
  },
  {
    name: 'Aleksander Ginalski',
    role: 'Scrum master at Boldare',
    feedback:
      'Thanks to the retrospective, the whole team can work on a problem that affects each team member. The result of this discussion is usually a way out of situations that no one would be able to come up with alone, thereby achieving synergies and building stronger bonds.',
    img: 'img/aleksander_ginalski.jpg',
  },
];
