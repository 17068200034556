import bosch from 'assets/images/client-carousel/bosch.svg';
import tui from 'assets/images/client-carousel/tui.svg';
import blabla from 'assets/images/client-carousel/blabla.svg';
import corel from 'assets/images/client-carousel/corel.svg';
import decathlon from 'assets/images/client-carousel/decathlon.svg';
import sonnen from 'assets/images/client-carousel/sonnen.svg';
import prisma from 'assets/images/client-carousel/prisma.svg';
import takamol from 'assets/images/client-carousel/takamol.svg';
import undp from 'assets/images/client-carousel/undp.svg';

export const clientsSlideshowLogo = {
  decathlon,
  takamol,
  blabla,
  undp,
  bosch,
  tui,
  sonnen,
  corel,
  prisma,
};
