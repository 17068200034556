import React from 'react';
import PropTypes from 'prop-types';

import './DottedList.scss';

export const DottedList = ({ heading, itemsList, theme }) => (
  <div className={'dotted-list'}>
    {heading}
    <ul className={`dotted-list__items dotted-list__items--${theme}`}>
      {itemsList.map(item => (
        <li>{item}</li>
      ))}
    </ul>
  </div>
);

DottedList.propTypes = {
  heading: PropTypes.string,
  itemsList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ),
  theme: PropTypes.string,
};

DottedList.defaultProps = {
  heading: '',
  itemsList: [],
  theme: 'bold',
};
