import React, { Fragment } from 'react';
import {
  Section,
  BoxLayoutSymetrical,
  BoxImageContent,
  BoxHashtag,
  BoxDiscover,
  BoxCTA,
} from 'components';
import './Methodology.scss';
import { BOX_TYPES } from 'config';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';

const renderBoxes = () =>
  data.map(item => (
    <Fragment key={`box-${item.id}`}>
      {item.type === BOX_TYPES.IMAGE_CONTENT && (
        <BoxImageContent
          additionalClass={'methodology__box'}
          item={{
            ...item,
            image: getCloudinaryUrl(item.image, 'f_auto,q_auto,w_480'),
          }}
        />
      )}
      {item.type === BOX_TYPES.DISCOVER && (
        <BoxDiscover
          additionalClass={'methodology__box'}
          link={item.link}
          headline={item.headline}
          description={item.description}
          backgroundColor={'gray-light'}
          id={item.id}
        />
      )}
      {item.type === BOX_TYPES.HASHTAG && (
        <BoxHashtag
          additionalClass={'methodology__box'}
          tag={item.tag}
          description={item.description}
          colors={item.colors}
          background={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_480')}
          link={item.link}
          headingType={4}
          id={item.id}
          name={item.name}
        />
      )}
    </Fragment>
  ));

export const Methodology = () => (
  <Section additionalClass={'methodology'} spaceless fullwidth>
    <BoxLayoutSymetrical mobileSlider>
      {renderBoxes()}
      <BoxCTA name={'approach'} additionalClass={'methodology__box'} />
    </BoxLayoutSymetrical>
  </Section>
);
