import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { AbsoluteCTA, ContactFormServices } from 'components';
import {
  Banner,
  Process,
  Reasons,
  OurArticles,
  AwwwardsSlider,
} from './subcomponents';
import './WhyDesignMatters.scss';

class WhyDesignMatters extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;

    return (
      <div className={'single-case-study wdm__container'}>
        <Banner />
        <Reasons />
        <div id={'designs'} className={'wdm__section wdm__showcase'}>
          <AwwwardsSlider />
        </div>
        <Process className={'wdm__section'} />
        <ContactFormServices
          id={'awwwwards__contact'}
          name={'awwwards-contact-form'}
          pardotLink={'http://go.boldare.com/l/688533/2020-10-14/cpk7j'}
          awwwardsForm
        />
        <div className={'wdm__container--desktop'}>
          <OurArticles allArticles={allArticles} />
        </div>
        <div className={'wdm__container--mobile'} id={'wdm-contact-mobile'}>
          <AbsoluteCTA linkID={'why-design-matters'} />
        </div>
      </div>
    );
  }
}

export default WhyDesignMatters;
