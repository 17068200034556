export const data = [
  {
    logo: 'showbiz-logo-old.png',
    year: '2003',
    details: [
      {
        title: 'Old fashion UI/UX',
        text: 'the interface was unsuited for today’s standards',
      },
      {
        title: 'No responsive',
        text: 'new users did not register as often as the used to',
      },
      {
        title: 'Outdated database',
        text: 'it did not meet today’s safety requirements',
      },
    ],
  },
  {
    logo: 'showbiz-logo-new.png',
    year: '2015',
    details: [
      {
        title: 'Be global',
        text: 'redesigned interface for the best user experience',
      },
      {
        title: 'Many registered users',
        text: 'increased number of registered users',
      },
      {
        title: 'Stable, safety, scaled app',
        text: 'modern application that is attractive and intuitive',
      },
    ],
  },
];
