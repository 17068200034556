import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Heading, Paragraph, IconSVG, Box, Rating } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './ReviewBox.scss';

const ReviewBox = ({ data, id }) => {
  const { content, settings } = data;
  const { title, rating, subtitle, description } = content;
  const { link } = settings;

  return (
    <Box
      link={link}
      id={id}
      backgroundColor={'gray-light'}
      additionalClass={'review-box__wrapper'}
    >
      <div className={'review-box__content'}>
        <Heading
          type={3}
          size={'medium-plus-small'}
          color={'cello'}
          additionalClass={'review-box__title'}
        >
          {'Testimonial: '}
          {ReactHtmlParser(title)}
        </Heading>
        <Paragraph
          additionalClass={'review-box__rating'}
          size={'medium'}
          color={'cello'}
          spaced
          nested
        >
          {rating}&nbsp;
          <Rating rate={rating} red />
        </Paragraph>
        <div className={'review-box__review'}>
          <Paragraph size={'medium'} color={'cello'}>
            {subtitle}
          </Paragraph>
          <IconSVG name={'quote'} size={'big'} color={'regent-gray'} />
        </div>
        <Paragraph
          additionalClass={'review-box__author'}
          size={'regular'}
          color={'regent-gray'}
        >
          {description}
        </Paragraph>
      </div>
      <div className={'review-box__footer review-box__footer--regent-gray'}>
        <Paragraph size={'small'} color={'regent-gray'} nested>
          {'Authentic client review from '}
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/partner-clutch-logo.png',
              'f_auto,q_auto',
            )}
            className={'image'}
            alt={'Clutch logo'}
            loading={'lazy'}
          />
        </Paragraph>
      </div>
    </Box>
  );
};

ReviewBox.defaultProps = {
  additionalClass: '',
  id: '',
  data: '',
};

ReviewBox.propTypes = {
  additionalClass: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
};

export default ReviewBox;
