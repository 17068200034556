import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Paragraph, Section, SectionHeader } from 'components';
import './FCPDSection.scss';
import { ICON_TYPES } from 'config';
import Slider from 'react-slick';
import { getCloudinaryUrl } from 'config/helpers';
import { useWindowWidth } from 'hooks';
import { data } from './data';

const FCPDSection = ({
  headingSize,
  background,
  descriptionSize,
  dataSource,
}) => {
  const sliderSettings = {
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
  };
  const isTabletBig = useWindowWidth() >= 992;

  const isDataSource = dataSource || data;

  const renderFCPDStageTile = ({
    heading,
    description,
    alt,
    cloudinaryDir,
    ctaTitle,
    ctaDescription,
    ctaLink,
  }) => {
    return (
      <div className={'FCPD-tile-container'}>
        <div className={'FCPD-tile__top'}>
          <img
            className={'FCPD-tile__image'}
            alt={alt}
            src={getCloudinaryUrl(
              [cloudinaryDir],
              'f_auto,q_auto,w_522,c_fill',
            )}
          />
        </div>
        <div className={'FCPD-tile__bottom'}>
          <div className={'FCPD-tile__text-container'}>
            <Paragraph
              additionalClass={'FCPD-tile__heading text--bold'}
              size={'medium'}
            >
              {heading}
            </Paragraph>
            <Paragraph
              additionalClass={'FCPD-tile__description'}
              size={'default'}
            >
              {description}
            </Paragraph>
          </div>
          <div className={'FCPD-tile__cta-container'}>
            <div className={'FCPD-tile__cta-text'}>
              <Paragraph
                additionalClass={'FCPD-tile__cta-heading text--demibold'}
                size={'default'}
              >
                {ctaTitle}
              </Paragraph>
              <Paragraph
                additionalClass={'FCPD-tile__cta-description'}
                size={'default'}
              >
                {ctaDescription}
              </Paragraph>
            </div>
            {ctaLink && (
              <a href={ctaLink}>
                <div className={'FCPD-tile__cta-arrow'}>
                  <Icon icon={ICON_TYPES.RIGHT_ARROW} size={'small'} />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Section
      id={'fcpd-section'}
      additionalClass={'FCPD-section'}
      color={background}
    >
      <SectionHeader headingType={2} lineColor={'red'} size={headingSize}>
        This is how we work in Boldare
      </SectionHeader>
      <div className={'FCPD-section__header-description-container'}>
        <Paragraph
          additionalClass={'FCPD-section__header-description'}
          size={descriptionSize}
        >
          The Lean Startup approach is natural for us, like breathing!
          <br />
          <span className={'text--demibold'}>
            We’ve built dedicated teams for each stage of product development.
            Thanks to that we’re able to match the solutions to the product’s
            needs.
          </span>
          <br />
          <br />
          Which of these teams will be yours?
          <br />
        </Paragraph>

        {isTabletBig && (
          <a
            className={
              'FCPD-section__header-cta text--demibold paragraph--medium'
            }
            href={'/blog/new-approach-to-product-development/'}
          >
            Read more
            <Icon
              icon={ICON_TYPES.RIGHT_ARROW}
              size={'default'}
              color={'red'}
            />
          </a>
        )}
      </div>

      {/* visible on desktop */}
      <div className={'FCPD-tiles-container'}>
        {isDataSource.map(item => renderFCPDStageTile(item))}
      </div>

      {/* visible on mobile */}
      <Slider {...sliderSettings}>
        {isDataSource.map(item => renderFCPDStageTile(item))}
      </Slider>

      {isTabletBig || (
        <a
          className={
            'FCPD-section__header-cta text--demibold paragraph--medium'
          }
          href={'/services/full-cycle-product-development/'}
        >
          Read more
          <Icon icon={ICON_TYPES.RIGHT_ARROW} size={'default'} color={'red'} />
        </a>
      )}
    </Section>
  );
};

FCPDSection.defaultProps = {
  headingSize: 'large',
  background: 'white',
  descriptionSize: 'medium',
};

FCPDSection.protoType = {
  headingSize: PropTypes.string,
  background: PropTypes.string,
  descriptionSize: PropTypes.string,
};

export default FCPDSection;
