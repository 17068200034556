import React, { Component } from 'react';
import Glide from '@glidejs/glide';
import { scroller } from 'react-scroll';
import { Row, Col } from 'react-flexbox-grid';
import {
  SectionHeader,
  Section,
  Paragraph,
  UnorderedList,
  Button,
  IconSVG,
} from 'components';
import { getQueryParam, getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';
import { data } from './logic.js';
import './Teams.scss';

class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullDescriptionShown: false,
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.glide = new Glide('.dtu__tabs-slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      animationDuration: 0,
      gap: 0,
      dragThreshold: false,
      autoplay: false,
      classes: {
        activeNav: 'active',
      },
      rewind: false,
    });

    this.glide.on(['build.after', 'run.after'], () => {
      this.setState({ currentSlide: this.glide.index });

      const slideHeight = document.getElementsByClassName(
        'glide__slide--active',
      )[0].offsetHeight;

      const glideTrack = document.getElementsByClassName('teams__glide')[0]
        .offsetHeight;

      if (slideHeight !== glideTrack) {
        const newHeight = slideHeight;
        const teamTabs = document.getElementsByClassName('teams__glide')[0];

        teamTabs.style.height = `${newHeight}px`;
      }
    });

    if (document.querySelector('.dtu__tabs-slider')) {
      this.glide.mount();
    }

    const team = getQueryParam('team');
    const teamIdx = team && data.findIndex(t => t.id === team);
    if (typeof teamIdx === 'number' && teamIdx > -1) {
      scroller.scrollTo('dtu__tabs', { offset: -80 });
      this.glide.go(`=${teamIdx}`);
    }
  }

  renderTitle = () =>
    data.map((item, index) => (
      <li
        key={`tile_${item.title_short + index}`}
        className={classNames('dtu__tabs-title', 'glide__bullet', {
          active: this.state.currentSlide === index,
        })}
        onClick={() => this.glide.go(`=${index}`)}
      >
        <Paragraph size={'medium'}>{item.title_short}</Paragraph>
      </li>
    ));

  toggleHiddenDescription = () =>
    this.setState(prevState => ({
      isFullDescriptionShown: !prevState.isFullDescriptionShown,
    }));

  render() {
    const { isFullDescriptionShown } = this.state;

    return (
      <>
        <Section additionalClass={'dtu__teams'} color={'mine-shaft'}>
          <Row className={'no-gap'}>
            <Col sm={12} md={6} className={'dtu__image'}>
              <div className={'dtu__img'}>
                <img
                  src={getCloudinaryUrl(
                    'gatsby-cloudinary/holaSlider.jpg',
                    'f_auto,q_auto',
                  )}
                  className={'image'}
                  alt={'Dedicated Product Development Teams'}
                  loading={'lazy'}
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <SectionHeader
                size={'big'}
                lineColor={'red'}
                headingType={2}
                color={'white'}
                description={
                  'Boldare’s dedicated agile dev teams will help you strategize, ideate with prototypes, design and develop software for your MVP, find a product-market fit and scale your web product using a lean approach to product development. <br /><br /> Our teams have all the skills required to build the product and help you reach your business goals:'
                }
                descriptionColor={'white'}
              >
                Dedicated Product Development Teams
              </SectionHeader>
              <Button
                color={'transparent-white'}
                onClick={this.toggleHiddenDescription}
                ariaName={'Description button'}
              >
                {isFullDescriptionShown ? 'Show less' : 'Read more'}
              </Button>
            </Col>
          </Row>
          {isFullDescriptionShown && (
            <div className={'w-container'}>
              <UnorderedList
                containerClass={'dtu__team-list'}
                additionalClass={'dtu__list-item text--default text--white'}
                items={[
                  'front-end developers',
                  'back-end developers',
                  'full stack devs',
                  'Quality Engineers (QA)',
                  'Business Analysts',
                  'DevOps',
                  'SEO',
                  'Scrum Masters',
                  'Agile Coaches',
                  'Product Vision',
                  'Digital Strategists',
                  'customer experience specialists',
                  'Visual Designers',
                  'Interaction Designers',
                  'UX Designers',
                  'Product Designers',
                  'Qualitative/ Quantitative<br /> Design Researchers',
                  'Information Architects',
                ]}
              />
            </div>
          )}
        </Section>

        <Section color={'mine-shaft'} additionalClass={'dtu__tabs'} fullwidth>
          <div className={'w-container dtu__tabs-wrapper'}>
            <div className={'dtu__tabs-slider glide'}>
              <ul className={'dtu__tabs-list-nav'}>
                {this.glide && this.renderTitle()}
              </ul>
              <div
                data-glide-el={'track'}
                className={'teams__glide glide__track'}
              >
                <div className={'glide__slides'}>
                  <div id={'prototyping-team'} className={'glide__slide'}>
                    <>
                      <div className={'dtu__tabs-content'}>
                        <div className={'dtu__tabs-description'}>
                          <SectionHeader
                            size={'big'}
                            headingType={3}
                            lineColor={'violet'}
                          >
                            {data[0].title}
                          </SectionHeader>
                          <Paragraph size={'medium'}>
                            {data[0].description}
                          </Paragraph>
                        </div>

                        <div className={'hide-mobile'}>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Accountabilities
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    [
                                      'Minimizing the risk',
                                      ' of failure in the long run.',
                                    ],
                                    [
                                      'Tackling real customer needs,',
                                      ' with empathy, in an innovative way.',
                                    ],
                                    [
                                      'Conducting small experiments.',
                                      ' Because the experiments are small, the risk is low.',
                                    ],
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Knowledge
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    [
                                      'Prototyping techniques',
                                      ' (Design Thinking, Wireframing)',
                                    ],
                                    [
                                      'KISS techniques',
                                      ' (Keep it Simple, Stupid)',
                                    ],
                                    ['Business analysis'],
                                    ['Testing methods and tools'],
                                    ['Design sprints'],
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Specific attributes
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    ['Openness', ' to failure and risk.'],
                                    ['Quick decision', '-making.'],
                                    [
                                      'Balancing the measurable',
                                      ' and the unmeasurable (emotions, connotations, habits).',
                                    ],
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Culture
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    [
                                      'Design culture - a system where design can thrive - making design everyone’s job.',
                                    ],
                                    ['A culture of experimentation'],
                                    [
                                      'Openness to bold ideas',
                                      ' and sometimes, big missteps.',
                                    ],
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Approach
                                </SectionHeader>
                                <Paragraph
                                  size={'medium'}
                                  additionalClass={
                                    'text--bold accordion__description'
                                  }
                                  color={'pigeon-post-second'}
                                >
                                  Ideate, build rapidly, test, learn, throw
                                  out, build again.
                                </Paragraph>
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Technologies
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'JavaScript, Angular, React, Design tools: InVision, Flinto, Framer.js, Figma, .Net, HTML, Bootstrap, Foundation',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  </div>

                  <div id={'mvp-team'} className={'glide__slide'}>
                    <>
                      <div className={'dtu__tabs-content'}>
                        <div className={'dtu__tabs-description'}>
                          <SectionHeader
                            size={'big'}
                            headingType={3}
                            lineColor={'violet'}
                          >
                            {data[1].title}
                          </SectionHeader>
                          <Paragraph size={'medium'}>
                            {data[1].description}
                          </Paragraph>
                        </div>
                        <div className={'hide-mobile'}>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Accountabilities
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Testing a product hypothesis with minimal resources.',
                                    'Solving the problem with the most basic solution.',
                                    'Designing and developing the first version of the product.',
                                    'Gathering the first round of feedback from the customers.',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Specific attributes
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Oriented towards achieving business goals.',
                                    'Focused on delivering the product to market.',
                                    'Always looking for simplest and quickest solutions.',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Knowledge
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Business Model Canvas technique',
                                    'Product vision',
                                    'Product discovery tools and methods',
                                    'Impact mapping',
                                    'Human-centred design',
                                    'Business transformation',
                                    'Innovation strategy',
                                    'New venture design',
                                    'Design sprints',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div
                                className={'accordion__inner dtu__team-roles'}
                              >
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Team roles
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={
                                    'accordion__description--roles text--medium text--black accordion__list-item'
                                  }
                                  items={[
                                    [
                                      'Frontend<br /> Developers',
                                      'computer-screen',
                                    ],
                                    ['Backend<br /> Developers', 'network'],
                                    ['Visual<br /> Designer', 'vector'],
                                    ['Interaction<br /> Designer', 'mouse'],
                                    ['UX Designer', 'artboard'],
                                    ['Product<br /> Designer', 'cube'],
                                    ['Business<br /> Analyst', 'grid-world'],
                                    ['DevOps', 'dashboard'],
                                    [
                                      'Qualitative/ Quantitative<br /> Design Researcher',
                                      'loupe',
                                    ],
                                    ['Digital<br /> Strategist', 'rocket'],
                                    ['Scrum<br />Master', 'rocket'],
                                  ]}
                                  icon
                                  iconColor={'red'}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Culture
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Pro-risk environment.',
                                    'Agility.',
                                    'Business goals oriented.',
                                    'Perfectionism is the biggest enemy.',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Technologies
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'JavaScript, Angular, Vue.js, React, Node.js, TypeScript, .Net',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Approach
                                </SectionHeader>
                                <Paragraph
                                  size={'medium'}
                                  additionalClass={
                                    'text--bold accordion__description'
                                  }
                                  color={'pigeon-post-second'}
                                >
                                  Enter the market with maximum speed and
                                  minimum cost.
                                </Paragraph>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  </div>

                  <div id={'product-team'} className={'glide__slide'}>
                    <>
                      <div className={'dtu__tabs-content'}>
                        <div className={'dtu__tabs-description'}>
                          <SectionHeader
                            size={'big'}
                            headingType={3}
                            lineColor={'violet'}
                          >
                            {data[2].title}
                          </SectionHeader>
                          <Paragraph size={'medium'}>
                            {data[2].description}
                          </Paragraph>
                        </div>
                        <div className={'hide-mobile'}>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Accountabilities
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Validated learning: seeking hypotheses, designing and running experiments.',
                                    'Interpreting experiment results and advising on the growth engine and product strategy.',
                                    'Designing and engineering the product’s technical architecture to be quickly changeable and adaptable.',
                                    'Controlling and limiting the technical debt to ready the product for quick scaling.',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Specific attributes
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Tech knowledge prioritization.',
                                    'Focused on analytics and drawing meaningful conclusions.',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Knowledge
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Digital product development',
                                    'Software & app development',
                                    'Quality assurance',
                                    'Product-Market Fit Canvas',
                                    'Quantitative research',
                                    'Data visualization & reporting',
                                    'Data architecture',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div
                                className={'accordion__inner dtu__team-roles'}
                              >
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Team roles
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={
                                    'text--medium text--black accordion__list-item'
                                  }
                                  items={[
                                    [
                                      'Frontend<br /> Developers',
                                      'computer-screen',
                                    ],
                                    ['Backend<br /> Developers', 'network'],
                                    ['Visual<br /> Designer', 'vector'],
                                    ['Interaction<br /> Designer', 'mouse'],
                                    ['UX Designer', 'artboard'],
                                    ['Product<br /> Designer', 'cube'],
                                    ['Business<br /> Analyst', 'grid-world'],
                                    ['DevOps', 'dashboard'],
                                    ['Quality<br > Engineer', 'star'],
                                    ['Digital<br /> Strategist', 'rocket'],
                                    ['Scrum<br />Master', 'rocket'],
                                    ['Information<br /> Architect', 'maze'],
                                    ['Agile Coach', 'light-bulb'],
                                    [
                                      'Qualitative/ Quantitative<br /> Design Researcher',
                                      'loupe',
                                    ],
                                  ]}
                                  icon
                                  iconColor={'red'}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Culture
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Culture of experimentation.',
                                    'Driven by business needs.',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Technologies
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    '<span class="text--default">Web</span>: Angular, React, Vue.js, .Net, TypeScript, JavaScript, HTML',
                                    '<span class="text--default">Backend/API</span>: PHP, Java, Node.js, Express.js, Python, .Net',
                                    '<span class="text--default">Mobile</span>: React-Native, Swift, Objective-C, Android (Java), Xamarin (.NET)',
                                    '<span class="text--default">Machine learning</span>: Python',
                                    '<span class="text--default">Mobile</span>: MongoDB, Postgres',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Approach
                                </SectionHeader>
                                <Paragraph
                                  size={'medium'}
                                  additionalClass={
                                    'text--bold accordion__description'
                                  }
                                  color={'pigeon-post-second'}
                                >
                                  Data-driven and tech-savvy agile development.
                                </Paragraph>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  </div>

                  <div id={'scale-team'} className={'glide__slide'}>
                    <>
                      <div className={'dtu__tabs-content'}>
                        <div className={'dtu__tabs-description'}>
                          <SectionHeader
                            size={'big'}
                            headingType={3}
                            lineColor={'violet'}
                          >
                            {data[3].title}
                          </SectionHeader>
                          <Paragraph size={'medium'}>
                            {data[3].description}
                          </Paragraph>
                        </div>
                        <div className={'hide-mobile'}>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Accountabilities
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Horizontal and vertical expansion of the product.',
                                    'Engineering the product for scalability.',
                                    'Engineering the product for stability.',
                                    'Setting up automated regression testing.',
                                    'Polishing the existing functionalities.',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Knowledge
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Digital Product Development',
                                    '<a href="/blog/software-development-outsourcing-everything-you-should-know">Software & app development</a>',
                                    'Quality assurance',
                                    'Scrum',
                                    'Kanban',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Technologies
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    '<span class="text--default">Web</span>: Angular, React, Vue.js, JavaScript, HTML',
                                    '<span class="text--default">Backend/API</span>: PHP, Java, Node.js, Express.js, Python',
                                    '<span class="text--default">Mobile</span>: React-Native, Swift, Objective-C, Android (Java), Xamarin (.NET)',
                                    '<span class="text--default">Machine learning</span>: Python',
                                    '<span class="text--default">Mobile</span>: MongoDB, Postgres',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div
                                className={'accordion__inner dtu__team-roles'}
                              >
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Team roles
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={
                                    'text--medium text--black accordion__list-item'
                                  }
                                  items={[
                                    [
                                      'Frontend<br /> Developers',
                                      'computer-screen',
                                    ],
                                    ['Backend<br /> Developers', 'network'],
                                    ['Visual<br /> Designer', 'vector'],
                                    ['Interaction<br /> Designer', 'mouse'],
                                    ['UX Designer', 'artboard'],
                                    ['Product<br /> Designer', 'cube'],
                                    ['Business<br /> Analyst', 'grid-world'],
                                    ['DevOps', 'dashboard'],
                                    ['Quality<br > Engineer', 'star'],
                                    ['Digital<br /> Strategist', 'rocket'],
                                    ['Scrum<br />Master', 'rocket'],
                                    ['Information<br /> Architect', 'maze'],
                                    ['Agile Coach', 'light-bulb'],
                                    [
                                      'Qualitative/ Quantitative<br /> Design Researcher',
                                      'loupe',
                                    ],
                                  ]}
                                  icon
                                  iconColor={'red'}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Culture
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Quality and scalability',
                                    'Agility',
                                  ]}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Specific attributes
                                </SectionHeader>
                                <UnorderedList
                                  containerClass={'accordion__description'}
                                  additionalClass={'text--black'}
                                  items={[
                                    'Technical knowledge is a priority.',
                                  ]}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={'no-gap'}>
                            <Col sm={12} md={6}>
                              <div className={'accordion__inner'}>
                                <SectionHeader
                                  size={'small'}
                                  additionalClass={'accordion__title'}
                                  headingType={4}
                                >
                                  Approach
                                </SectionHeader>
                                <Paragraph
                                  size={'medium'}
                                  additionalClass={
                                    'text--bold accordion__description'
                                  }
                                  color={'pigeon-post-second'}
                                >
                                  Incremental, high-impact wins over short-term
                                  goals.
                                </Paragraph>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div
                className={'related-articles__nav glide__arrows'}
                data-glide-el={'controls'}
              >
                <button
                  className={
                    'related-articles__arrow glide__arrow glide__arrow--left'
                  }
                  id={'ml-prev'}
                  data-glide-dir={'<'}
                  aria-label={'Slider control button'}
                >
                  <IconSVG
                    name={'arrow-left'}
                    color={this.props.bulletColor}
                  />
                </button>
                <button
                  className={
                    'related-articles__arrow glide__arrow glide__arrow--right'
                  }
                  id={'ml-next'}
                  data-glide-dir={'>'}
                  aria-label={'Slider control button'}
                >
                  <IconSVG
                    name={'arrow-right'}
                    color={this.props.bulletColor}
                  />
                </button>
              </div>
            </div>
          </div>
        </Section>
      </>
    );
  }
}

export default Teams;
