import React, { useState } from 'react';
import { Button, IconSVG, Paragraph } from 'components';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './DownloadPost.scss';
import withSubscribe from './HOC';

const DownloadPost = ({
  settings,
  subscribed,
  isLoading,
  refForm,
  handleSubmit,
}) => {
  const [activePopup, setActivePopup] = useState(false);
  const { downloadPdfLink } = settings;

  const clickButton = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'article-download-button-clicked',
      });
    }
  };

  const clickSendFormButton = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `article-download-button-form-clicked`,
      });
    }
  };

  return (
    <div className={'article-download__container'}>
      <Button
        color={'transparent'}
        additionalClass={'article-download'}
        onClick={() => {
          setActivePopup(!activePopup);
          clickButton();
        }}
      >
        <span>Save article for later</span>
        <IconSVG
          additionalClass={'article-download__icon'}
          name={'arrow-download'}
        />
      </Button>
      {activePopup && (
        <div className={'article-download__popup text--white'}>
          <div className={'article-download__popup-wrapper'}>
            <button
              type={'button'}
              className={`article-download__close text--white`}
              onClick={() => setActivePopup(!activePopup)}
              aria-label={'Close popup'}
            >
              &#10005;
            </button>
            {subscribed ? (
              <>
                <Paragraph
                  additionalClass={'download-popup__header text--bold'}
                  color={'white'}
                  size={'medium-constant'}
                >
                  PDF should open in a second.
                </Paragraph>
                <Paragraph
                  additionalClass={'download-popup__header'}
                  color={'white'}
                  size={'medium-constant'}
                >
                  For your convenience, we also emailed you a link with the
                  article&apos;s PDF.
                </Paragraph>
                <Button
                  additionalClass={'download-popup__button'}
                  onClick={() => setActivePopup(!activePopup)}
                  color={'yellow'}
                  ariaName={'Close popup'}
                >
                  Keep reading article
                </Button>
                <a
                  id={'download-link--disabled'}
                  className={'link--disabled'}
                  href={downloadPdfLink}
                  rel={'nofollow noreferrer'}
                  target={'_blank'}
                >
                  Click here to download PDF
                </a>
              </>
            ) : (
              <>
                <Paragraph
                  additionalClass={'download-popup__header text--bold'}
                  color={'white'}
                  size={'medium-constant'}
                >
                  Download this article in PDF and read it whenever you like
                </Paragraph>
                <form
                  className={'download-popup__form'}
                  onSubmit={handleSubmit}
                  ref={refForm}
                >
                  <input
                    type={'text'}
                    name={'name'}
                    placeholder={'First name'}
                    required
                  />
                  <input
                    type={'email'}
                    name={'email'}
                    placeholder={'Company e-mail'}
                    required
                  />
                  <Button
                    id={`download-case-study-send`}
                    additionalClass={classNames('download-popup__button', {
                      loading: isLoading,
                    })}
                    type={'submit'}
                    color={'yellow'}
                    ariaName={'Download article now'}
                    disabled={isLoading}
                    onClick={() => clickSendFormButton()}
                    loading
                  >
                    Download article now
                  </Button>
                </form>
                <Paragraph
                  additionalClass={'article-download__info text--light'}
                  color={'white'}
                >
                  We`re committed to your privacy. Boldare uses the information
                  you provide to us to contact you about our relevant content,
                  products, and services. Read our privacy policy.
                </Paragraph>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DownloadPost.defaultProps = {
  additionalClass: '',
};

DownloadPost.propTypes = {
  additionalClass: PropTypes.string,
};

export default withSubscribe(DownloadPost);
