import React from 'react';
import { motion, useViewportScroll } from 'framer-motion';

import { ReactComponent as ArrowTopRight } from '../../assets/icons/arrow_top_right.svg';
import { ReactComponent as Point1 } from './point-1.svg';
import { ReactComponent as TojjarForm } from './form-tojjar.svg';

const TojjarSection = () => {
  const { scrollY } = useViewportScroll();

  return (
    <motion.div className={'dsgn-cases__section dsgn-cases__section--tojjar'}>
      <div
        className={
          'dsgn-cases__section-content dsgn-cases__section-content--tojjar'
        }
      >
        <div className={'dsgn-cases__steps'}>
          <div className={'dsgn-cases__steps-point'}>
            <Point1 />
          </div>
          <div className={'dsgn-cases__steps-description'}>
            <h4>User Experience first</h4>
            <p>
              We understand that great user experience leads to positive
              business results and brings value to users. The best proof of our
              expertise in this field is our digital products designed for
              Arabic users. Where understanding, empathy, and experience
              matter, that&apos;s where our true expertise shines.
            </p>
          </div>
        </div>
      </div>
      <div className={'dsgn-cases__content-half'}>
        <div className={'dsgn-cases__img'}>
          <div className={'dsgn-cases__img-content'}>
            <div className={'dsgn-cases__img-content--desktop'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1700053178/bdbb/tojjar-group.png'
                }
                alt={'Tojjar'}
              />
            </div>
            <div className={'dsgn-cases__img-content--mobile'}>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1700053796/bdbb/tojjar-group-mobile2.png'
                }
                alt={'Tojjar'}
              />
            </div>
            <motion.div
              style={{
                rotate: scrollY,
              }}
              className={'dsgn-cases__img-form'}
            >
              <TojjarForm />
            </motion.div>
          </div>
        </div>
      </div>
      <div className={'dsgn-cases__description'}>
        <div className={'dsgn-cases__description-content'}>
          <p>
            We teamed with Takamol to create a MENA-focused online marketplace.
            The timely prototype secured additional funding, leading to a
            successful MVP launch.
          </p>
        </div>
        <div className={'dsgn-cases__description-cta'}>
          <a
            href={'https://www.boldare.com/work/case-study-tojjar/'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <span>Read the case study <ArrowTopRight /></span>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default TojjarSection;
