import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Button, Section } from 'components';
import { getRecommendClientImageUrl } from '../../utils';

import ClutchTop from '../ClutchTop';
import { SECTION_ID } from '../Form';

import './Hero.scss';

export default function Hero() {
  return (
    <Section
      side={'right'}
      color={'violet-main'}
      spaceless
      containerClass={'bring-a-qualified-lead__hero'}
    >
      <div className={'bring-a-qualified-lead__hero--info'}>
        <ClutchTop
          className={'hide-mobile-important hide-tablet-big-important'}
        />
        <h1 className={'bring-a-qualified-lead__hero__title first'}>
          Bring us a qualified lead and{' '}
          <span className={'marker-highlight marker-black'}>get €2200</span>
        </h1>
        <h3 className={'bring-a-qualified-lead__hero__subtitle first'}>
          Profit from partnering with Boldare, a top software development &
          consulting partner
        </h3>
        <Button
          color={'yellow'}
          size={'large'}
          onClick={() => scrollTo(`#${SECTION_ID}`)}
        >
          JOIN THE REFERRAL PROGRAM
        </Button>
      </div>

      <img
        className={'bring-a-qualified-lead__hero__image'}
        src={getRecommendClientImageUrl('hero.jpg')}
        width={'760'}
        height={'1016'}
        loading={'lazy'}
        alt={'Team'}
      />
    </Section>
  );
}
