import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Consultant.scss';

class Consultant extends Component {
  render() {
    const {
      consultName,
      consultPosition,
      consultPhoto,
      consultColor,
    } = this.props;

    return (
      <Section
        additionalClass={'consult__section'}
        color={consultColor || 'yellow'}
      >
        <div className={'consult__wrapper'}>
          <Heading
            additionalClass={'consult__heading'}
            type={'2'}
            size={'big'}
          >
            All topics and articles are prepared and selected
            <br />
            by <span className={'consult__heading-name'}>{consultName}</span>
          </Heading>
          <Paragraph
            size={'medium'}
            additionalClass={'text--bold consult__paragraph'}
          >
            our {consultPosition}.
          </Paragraph>
          <button
            onClick={() => {
              scroller.scrollTo('program__heading', {
                spy: false,
                smooth: true,
                duration: 500,
                offset: -100,
              });
            }}
            className={'button button--primary button--white'}
          >
            Sign up
          </button>
        </div>
        <img
          src={getCloudinaryUrl(consultPhoto, 'f_auto,q_auto')}
          className={'image consult__image'}
          alt={consultName}
          loading={'lazy'}
        />
      </Section>
    );
  }
}

Consultant.propTypes = {
  consultName: PropTypes.string.isRequired,
  consultPosition: PropTypes.string.isRequired,
  consultColor: PropTypes.string,
  consultPhoto: PropTypes.string.isRequired,
};

Consultant.defaultProps = {
  consultColor: '',
};

export default Consultant;
