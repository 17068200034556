import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useInView } from 'react-intersection-observer';
import { pushToDataLayer } from 'utils';
import Services from '../Services';
import ServicesAndEnvironment from '../ServicesAndEnvironment';
import Number from '../Number';
import './SectionEnvironment.scss';

const SectionEnvironment = ({ isDesktop, variant }) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (isDesktop) {
      const environmentTimeline = gsap.timeline();

      environmentTimeline.to('.first-4', {
        display: 'none',
        opacity: '0',
        ease: 'linear',
      });
      const environmentTimeline2 = gsap.timeline();
      environmentTimeline2
        .from('.show-next-4', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.show-next-4', {
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        })
        .to('.show-next-4', {
          opacity: 0,
          ease: 'linear',
          display: 'none',
        });
      const environmentTimeline3 = gsap.timeline();
      environmentTimeline3
        .from('.show-next-next-4', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.show-next-next-4', {
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        })
        .to('.show-next-next-4', {
          opacity: 0,
          ease: 'linear',
          display: 'none',
        });
      const environmentTimeline4 = gsap.timeline();
      environmentTimeline4
        .from('.show-next-next-next-4', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.show-next-next-next-4', {
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        })
        .to('.show-next-next-next-4', {
          opacity: 0,
          ease: 'linear',
          display: 'none',
        });
      const environmentStep1 = gsap.timeline();
      environmentStep1
        .to('.environment .step:nth-of-type(1)', {
          color: '#f2da3a',
        })
        .to(
          '.environment .step:nth-of-type(1) path',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(2)',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(2) path',
          {
            color: 'white',
          },
          '<',
        );
      const environmentStep2 = gsap.timeline();
      environmentStep2
        .to('.environment .step:nth-of-type(2)', {
          color: '#f2da3a',
        })
        .to(
          '.environment .step:nth-of-type(2) path',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(3)',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(3) path',
          {
            color: 'white',
          },
          '<',
        );
      const environmentStep3 = gsap.timeline();
      environmentStep3
        .to('.environment .step:nth-of-type(3)', {
          color: '#f2da3a',
        })
        .to(
          '.environment .step:nth-of-type(3) path',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(4)',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.environment .step:nth-of-type(4) path',
          {
            color: 'white',
          },
          '<',
        );
      const hideEnvSteps = gsap.timeline();
      hideEnvSteps
        .to('.environment .step', {
          opacity: 0,
          ease: 'linear',
        })
        .to('.disappear', {
          opacity: 0,
          display: 'none',
        });
      const showServicesSteps = gsap.timeline();
      showServicesSteps.from('.services .step', {
        opacity: 0,
        ease: 'linear',
      });
      const starTimeline = gsap.timeline();
      starTimeline.from('.services .star', {
        opacity: 0,
        ease: 'linear',
      });
      const servicesTimeline = gsap.timeline();
      servicesTimeline
        .from('.first-3', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.first-3', {
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        })
        .to('.first-3', {
          opacity: 0,
          ease: 'linear',
          display: 'none',
        });
      const backgroundTimeline = gsap.timeline();
      backgroundTimeline.to('.animate.section-environment', {
        backgroundColor: '#B2CBDE',
      });
      const circleTimeline = gsap.timeline();
      circleTimeline.from('.services .circle', {
        opacity: 0,
      });
      const servicesTimeline2 = gsap.timeline();
      servicesTimeline2
        .from('.show-next-3', {
          display: 'none',
          opacity: 0,
          ease: 'linear',
        })
        .to('.show-next-3', {
          opacity: 1,
          ease: 'power2.out',
          display: 'block',
        });
      const servicesStep = gsap.timeline();
      servicesStep
        .to('.services .first-step', {
          color: 'white',
        })
        .to(
          '.services .first-step path',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.services .second-step',
          {
            color: 'black',
          },
          '<',
        )
        .to(
          '.services .second-step path',
          {
            color: 'white',
          },
          '<',
        );
      const first5 = gsap.timeline();
      first5.from('.first-5', {
        opacity: 0,
        display: 'none',
      });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.animate.section-environment',
            start: 'top top',
            end: '+=350%',
            pin: true,
            scrub: true,
          },
        })
        .add(environmentTimeline, '+=0.1')
        .add(environmentTimeline2, '+=0.1')
        .add(environmentStep1, '<')
        .add(environmentTimeline3, '+=0.1')
        .add(environmentStep2, '<')
        .add(environmentTimeline4, '+=0.1')
        .add(environmentStep3, '<')
        .add(hideEnvSteps)
        .add(backgroundTimeline)
        .add(first5, '<')
        .add(showServicesSteps, '<')
        .add(starTimeline, '<')
        .add(circleTimeline, '<')
        .add(servicesTimeline)
        .add(servicesStep)
        .add(servicesTimeline2, '<');
    }

    ScrollTrigger.refresh();
  }, [isDesktop]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const isWindow = typeof window !== 'undefined';
      let wasEnvironmentSectionSeen;

      if (isWindow) {
        wasEnvironmentSectionSeen = window.sessionStorage.getItem(
          'wasEnvironmentSectionSeen',
        );

        if (!wasEnvironmentSectionSeen) {
          window.sessionStorage.setItem('wasEnvironmentSectionSeen', true);
          pushToDataLayer(`${variant}-section-environment-and-services`);
        }
      }
    }
  }, [inView]);

  if (isDesktop) {
    return (
      <div className={'section-wrapper animate section-environment'} ref={ref}>
        <section className={'grid-layout'}>
          {variant === 'about-us-a' && (
            <>
              <div className={'about-header disappear'}>
                When you buy our services you purchase our{' '}
                <span className={'marker-highlight marker-black'}>
                  environment
                </span>
              </div>
              <div className={'about-header first-5'}>
                The{' '}
                <span className={'marker-highlight-small marker-black'}>
                  value
                </span>{' '}
                we provide is based on the following pillars:
              </div>
              <ServicesAndEnvironment
                className={'section-environment__elements animate'}
              />
              <div className={'section-environment__descriptions'}>
                <h2 className={'section-environment__title first-4'}>
                  Processes and structure
                </h2>
                <p className={'section-environment__description first-4'}>
                  Product life cycle stages are miles apart!
                </p>
                <p className={'section-environment__text first-4'}>
                  You need a completely different attitude, skill set and
                  process for prototyping than you do for scaling. That’s why
                  we’ve built four completely separate environments, one for
                  each product stage, tied together by one set of common values
                  and principles.
                </p>
                <h2 className={'section-environment__title show-next-4'}>
                  Decision-making
                </h2>
                <p className={'section-environment__description show-next-4'}>
                  COVID-19 pandemic, stock market crash, a new rival… our
                  clients’ ecosystems change every day
                </p>
                <p className={'section-environment__text show-next-4'}>
                  We need to be their partners, not blockers. Self-organization
                  is what allows us to be constantly agile and responsive to
                  any changes thrown in our direction. We strive to make
                  informed decisions at the pace that clients need.{' '}
                </p>
                <h2 className={'section-environment__title show-next-next-4'}>
                  ​​Education & Development
                </h2>
                <p
                  className={
                    'section-environment__description show-next-next-4'
                  }
                >
                  We believe in organic, bottom-up personal growth and
                  knowledge development
                </p>
                <p className={'section-environment__text show-next-next-4'}>
                  Education happens exactly when it’s needed for people who are
                  genuinely interested. It’s supported by chapters,
                  self-organization in knowledge sharing and radical
                  transparency. Also, thanks to transparency, our clients
                  benefit continuously.
                </p>
                <h2
                  className={
                    'section-environment__title show-next-next-next-4'
                  }
                >
                  Trust
                </h2>
                <p
                  className={
                    'section-environment__description show-next-next-next-4'
                  }
                >
                  The cornerstone of our environment
                </p>
                <p
                  className={'section-environment__text show-next-next-next-4'}
                >
                  We start collaborations without signatures on contracts, we
                  believe in simple agreements, not 500-page-long documents,
                  and we always share our product know-how with clients. Our
                  goal is to help our clients fly solo, not to lock them in.
                </p>
                <h2 className={'section-environment__title first-3'}>
                  Business and services
                </h2>
                <p className={'section-environment__description first-3'}>
                  The services shape Boldare, not the other way round!
                </p>
                <p className={'section-environment__text first-3'}>
                  The entire company is aligned with the client’s and product’s
                  business goals including our structure, culture and
                  processes. Agile DNA and Lean Startup in our bloodstream
                  allow us to spend client’s money only on what benefits their
                  business. We deliver products in quick build-measure-learn
                  iterations, for unparalleled speed to market.
                </p>
                <h2 className={'section-environment__title show-next-3'}>
                  People
                </h2>
                <p className={'section-environment__description show-next-3'}>
                  To achieve the highest quality services we have gathered some
                  awesome talents under our roof
                </p>
                <p className={'section-environment__text show-next-3'}>
                  They operate in an unrivaled environment that allows our
                  clients to hear unbiased feedback and future-oriented advice
                  daily. We recruit for both technical mastery and
                  business-savvy mindset. Every person in the company feels
                  accountable for the client's success.
                </p>
              </div>
            </>
          )}
          {variant === 'about-us-b' && (
            <>
              <div className={'about-header disappear'}>
                The{' '}
                <span className={'marker-highlight marker-black'}>
                  environment
                </span>{' '}
                is where everything starts
              </div>
              <div className={'about-header first-5'}>
                Our{' '}
                <span className={'marker-highlight-long'}>
                  business & services
                </span>{' '}
                are how we move everything forward
              </div>
              <ServicesAndEnvironment
                className={'section-environment__elements animate'}
              />
              <div className={'section-environment__descriptions'}>
                <h2 className={'section-environment__title first-4'}>
                  Processes and structure
                </h2>
                <p className={'section-environment__description first-4'}>
                  The entire company is organized according to the experience
                  and specialization of our people
                </p>
                <p className={'section-environment__text first-4'}>
                  The teams that build digital prototypes are different to
                  those scaling existing products.
                </p>
                <h2 className={'section-environment__title show-next-4'}>
                  Decision-making
                </h2>
                <p className={'section-environment__description show-next-4'}>
                  Teams that are skilled in self organization are extremely
                  agile and can react to business needs faster than teams
                  organized traditionally
                </p>
                <p className={'section-environment__text show-next-4'}>
                  Pivots? Yes, please!
                </p>
                <h2 className={'section-environment__title show-next-next-4'}>
                  Organic development
                </h2>
                <p
                  className={
                    'section-environment__description show-next-next-4'
                  }
                >
                  Our experts organize themselves in communities called
                  chapters, which provide help on request to our clients and
                  internal teams
                </p>
                <p className={'section-environment__text show-next-next-4'}>
                  They solve actual problems and share the knowledge
                  transparently, bottom-up, making our development organic.
                </p>
                <h2
                  className={
                    'section-environment__title show-next-next-next-4'
                  }
                >
                  Trust
                </h2>
                <p
                  className={
                    'section-environment__description show-next-next-next-4'
                  }
                >
                  Our contracts are plain and simple
                </p>
                <p
                  className={'section-environment__text show-next-next-next-4'}
                >
                  We start new products without waiting for all the necessary
                  signatures on paper and don’t use vendor lock-in. Our clients
                  are self-sufficient when the collaboration ends. Trust is one
                  of our currencies.
                </p>
                <h2 className={'section-environment__title first-3'}>
                  Business and services
                </h2>
                <p className={'section-environment__description first-3'}>
                  Using Lean Startup and Agile we spend your money only on what
                  brings real value to your business
                </p>
                <p className={'section-environment__text first-3'}>
                  We use iterations to validate assumptions on the market, with
                  real users. We can proudly say that we practice Agile better
                  than the competition.
                </p>
                <h2 className={'section-environment__title show-next-3'}>
                  People
                </h2>
                <p className={'section-environment__description show-next-3'}>
                  We equip our experts in the necessary tools and encourage
                  them to challenge your ideas and share their honest feedback
                </p>
                <p className={'section-environment__text show-next-3'}>
                  Their goal is to stimulate innovation in your approach, the
                  product and the business itself.
                </p>
              </div>
            </>
          )}
        </section>
      </div>
    );
  }
  return (
    <>
      {variant === 'about-us-a' && (
        <>
          <div className={'section-wrapper section-environment'}>
            <section className={'grid-layout'}>
              <div className={'about-header'}>
                The{' '}
                <span className={'marker-highlight marker-black'}>
                  environment
                </span>{' '}
                is where everything starts
              </div>
              <ServicesAndEnvironment
                className={'section-environment__elements hidden-services'}
              />
              <div className={'section-environment__descriptions'}>
                <div className={'section-environment__title'}>
                  <Number number={1} />
                  Processes and structure
                </div>
                <div className={'section-environment__text'}>
                  Product life cycle stages are miles apart! You need a
                  completely different attitude, skill set and process for
                  prototyping than you do for scaling. That’s why we’ve built
                  four completely separate environments, one for each product
                  stage, tied together by one set of common values and
                  principles.
                </div>
                <div className={'section-environment__title'}>
                  <Number number={2} />
                  Decision-making
                </div>
                <div className={'section-environment__text'}>
                  COVID-19 pandemic, stock market crash, a new rival… our
                  clients’ ecosystems change every day
                </div>
                <div className={'section-environment__title'}>
                  <Number number={3} />
                  ​​Education & Development
                </div>
                <div className={'section-environment__text'}>
                  We believe in organic, bottom-up personal growth and
                  knowledge development
                </div>
                <div className={'section-environment__title'}>
                  <Number number={4} />
                  Trust
                </div>
                <div className={'section-environment__text'}>
                  The cornerstone of our environment
                </div>
              </div>
            </section>
          </div>
          <div className={'section-wrapper section-services'}>
            <section className={'grid-layout'}>
              <div className={'about-header'}>
                Our{' '}
                <span className={'marker-highlight-long'}>
                  business & services
                </span>{' '}
                are how we move everything forward
              </div>
              <Services className={'section-environment__elements'} />
              <div className={'section-environment__descriptions'}>
                <div className={'section-environment__title'}>
                  <Number number={1} />
                  Business and services
                </div>
                <div className={'section-environment__text'}>
                  The services shape Boldare, not the other way round! The
                  entire company is aligned with the client’s and product’s
                  business goals including our structure, culture and
                  processes.
                </div>
                <div className={'section-environment__title'}>
                  <Number number={2} />
                  People
                </div>
                <div className={'section-environment__text'}>
                  To achieve the highest quality services we have gathered some
                  awesome talents under our roof
                </div>
              </div>
            </section>
          </div>
        </>
      )}
      {variant === 'about-us-b' && (
        <>
          <div className={'section-wrapper section-environment'}>
            <section className={'grid-layout'}>
              <div className={'about-header'}>
                The{' '}
                <span className={'marker-highlight marker-black'}>
                  environment
                </span>{' '}
                is where everything starts
              </div>
              <ServicesAndEnvironment
                className={'section-environment__elements hidden-services'}
              />
              <div className={'section-environment__descriptions'}>
                <div className={'section-environment__title'}>
                  <Number number={1} />
                  Processes and structure
                </div>
                <div className={'section-environment__text'}>
                  The entire company is organized according to the experience
                  and specialization of our people. The teams that build
                  digital prototypes are different to those scaling existing
                  products.
                </div>
                <div className={'section-environment__title'}>
                  <Number number={2} />
                  Decision-making
                </div>
                <div className={'section-environment__text'}>
                  Teams that are skilled in self organization are extremely
                  agile and can react to business needs faster than teams
                  organized traditionally. Pivots? Yes, please!
                </div>
                <div className={'section-environment__title'}>
                  <Number number={3} />
                  Organic development
                </div>
                <div className={'section-environment__text'}>
                  Our experts organize themselves in communities called
                  chapters, which provide help on request to our clients and
                  internal teams. They solve actual problems and share the
                  knowledge transparently, bottom-up, making our development
                  organic.
                </div>
                <div className={'section-environment__title'}>
                  <Number number={4} />
                  Trust
                </div>
                <div className={'section-environment__text'}>
                  Our contracts are plain and simple. We start new products
                  without waiting for all the necessary signatures on paper and
                  don’t use vendor-lock in. Our clients are self-sufficient
                  when the collaboration ends. Trust is one of our currencies.
                </div>
              </div>
            </section>
          </div>
          <div className={'section-wrapper section-services'}>
            <section className={'grid-layout'}>
              <div className={'about-header'}>
                Our{' '}
                <span className={'marker-highlight-long'}>
                  business & services
                </span>{' '}
                are how we move everything forward
              </div>
              <Services className={'section-environment__elements'} />
              <div className={'section-environment__descriptions'}>
                <div className={'section-environment__title'}>
                  <Number number={1} />
                  Business and services
                </div>
                <div className={'section-environment__text'}>
                  Using Lean Startup and Agile we spend your money only on what
                  brings real value to your business. We use iterations to
                  validate assumptions on the market, with real users. We can
                  proudly say that we practice Agile better than the
                  competition.
                </div>
                <div className={'section-environment__title'}>
                  <Number number={2} />
                  People
                </div>
                <div className={'section-environment__text'}>
                  We equip our experts in the necessary tools and encourage
                  them to challenge your ideas and share their honest feedback.
                  Their goal is to stimulate innovation in your approach, the
                  product and the business itself.
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default SectionEnvironment;

SectionEnvironment.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
