export const CARDS = [
  {
    name: 'Reliable delivery',
    icon: 'volunteer-activism',
    description:
      'Our Service Standards ensure consistent, value-driven product delivery with efficient team management which means that every strategic and operational aspect of your product is handled expertly.',
  },
  {
    name: 'Risk management',
    icon: 'assessment',
    description:
      'Through clear definition of roles and responsibility distribution, we ensure greater security and effective risk management.',
  },
  {
    name: 'Smooth collaboration',
    icon: 'try',
    description:
      'Regardless of location, our standards facilitate effective communication, clear points of contact, and purposeful meetings.',
  },
  {
    name: 'Quality assurance',
    icon: 'auto-awesome',
    description:
      'We adhere to high standards in coding, documentation, and delivery across all products.',
  },
  {
    name: 'Budget efficiency',
    icon: 'savings',
    description:
      'We prioritize resource allocation that progresses your business objectives and product development.',
  },
  {
    name: 'Transparent cooperation',
    icon: 'connect-without-contact',
    description:
      'Thanks to meetings and the documentation matrix, you know the purpose of each call and know where to find the information you are looking for.',
  },
];
