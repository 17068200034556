import React from 'react';
import PropTypes from 'prop-types';
import './Tag.scss';
import { classNames } from 'utils';

const Tag = ({ additionalClass, active, children, onClick }) => (
  <button
    className={classNames('tag', additionalClass, {
      'tag--active': active,
    })}
    onClick={onClick}
  >
    {children}
  </button>
);

Tag.defaultProps = {
  active: false,
  additionalClass: '',
  children: null,
  onClick: () => {},
};

Tag.propTypes = {
  active: PropTypes.bool,
  additionalClass: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Tag;
