import React, { useEffect, useState } from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import './Popup.scss';

const Popup = ({ type }) => {
  const [isPopupEnabled, setIsPopupEnabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(`careerOfferPopupEnabled-${type}`) !== 'false') {
      setTimeout(() => {
        setIsPopupEnabled(true);
      }, 6000);
    }

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `${type}Display`,
      });
    }
  }, []);

  const setPopupVisibility = isEnabled => {
    localStorage.setItem(
      `careerOfferPopupEnabled-${type}`,
      isEnabled.toString(),
    );
    setIsPopupEnabled(isEnabled);
  };

  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: item,
      });
    }
  };

  return (
    isPopupEnabled && (
      <div className={`job-offer-popup ${type}`}>
        {type === 'career-popup-fcpd' ? (
          <>
            <a
              href={'https://bldr.typeform.com/to/hNW0ElM9'}
              target={'_blank'}
              rel={'noreferrer'}
              onClick={() => {
                handleClick(type);
              }}
            >
              <img
                alt={'Boldare - Digital Product Design Development Company'}
                src={getCloudinaryUrl(
                  'career/career-popup-fcpd.png',
                  'f_auto,q_auto,h_232',
                )}
                loading={'lazy'}
              />
            </a>
            <button
              className={'job-offer-popup__close'}
              onClick={() => {
                setPopupVisibility(false);
              }}
            >
              x
            </button>
          </>
        ) : (
          <>
            <a
              href={'https://boldare.com/products'}
              target={'_blank'}
              rel={'noreferrer'}
              onClick={() => {
                handleClick(type);
              }}
            >
              <img
                alt={'Boldare - Digital Product Design Development Company'}
                src={getCloudinaryUrl(
                  'career/career-popup-products.png',
                  'f_auto,q_auto,h_500',
                )}
                loading={'lazy'}
              />
            </a>
            <button
              className={'job-offer-popup__close'}
              onClick={() => {
                setPopupVisibility(false);
              }}
            >
              x
            </button>
          </>
        )}
      </div>
    )
  );
};

export default Popup;
