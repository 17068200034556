import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import { Banner, Scopes, Benefits, Learn, Clients } from './subcomponents';
import './codeAudit.scss';

class CodeAudit extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero code-audit'}>
        <Banner
          cta={'Contact us'}
          link={'audit-contact-form'}
          id={'audit-contact-middle'}
          scroll
        />
        <Scopes />
        <Benefits />
        <Learn />
        <Clients />
        <ContactFormServices
          id={'audit-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2020-05-21/6lzty'}
        />
      </div>
    );
  }
}

export default CodeAudit;
