import Banner from './Banner';
import Teams from './Teams';
import Process from './Process';
import ClientQuotes from './ClientQuotes';
import HowWeBuild from './HowWeBuild';
import WorkWith from './WorkWith';
import OtherServices from './OtherServices';
import Contact from './Contact';

export {
  Banner,
  Teams,
  ClientQuotes,
  HowWeBuild,
  WorkWith,
  Process,
  OtherServices,
  Contact,
};
