import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { BoxArticle, NavBar, Section, SectionHeader } from 'components';
import config from '../../meta/config';

const AuthorPage = ({ data, pageContext }) => {
  const { markdownRemark, posts } = data;
  const {
    title,
    job,
    photo,
    slug,
    isExternalAuthor,
  } = markdownRemark.frontmatter;
  const page = pages.author;
  const canonical = `${config.siteUrl}/author/${slug}/`;

  return (
    <Layout page={page} pageContext={pageContext} canonical={canonical}>
      <Helmet title={`${title} - Blog Posts & Articles & Tips`}>
        <meta name={'description'} content={job} />
      </Helmet>
      <NavBar color={page.navColors} />
      <div className={'blog_container blog__author'}>
        <div
          id={'blog-post-list'}
          className={'section section--transparent post-list blog-post-list'}
        >
          <Section spaceless>
            <a
              href={`${config.siteUrl}/blog/`}
              className={'author-page__backlink'}
            >
              &#8592; Boldare Blog
            </a>
            <div className={'author-page__hero'}>
              <div className={'author-page__img'}>
                <img
                  src={getCloudinaryUrl(photo, 'f_auto,q_auto,h_180')}
                  className={'image'}
                  alt={`${title} – ${job}`}
                  loading={'lazy'}
                />
              </div>
              <div className={'author-page__content'}>
                <SectionHeader
                  additionalClass={'section-header__description--medium'}
                  description={job}
                  lineColor={'yellow'}
                  size={'extra-large'}
                  type={1}
                  fixed
                  isAuthorPage
                  isExternalAuthor={isExternalAuthor}
                >
                  {title}
                </SectionHeader>
              </div>
            </div>
          </Section>
          <div className={'w-container'}>
            <div className={'post-list__grid grid--no-ads'}>
              {posts.edges &&
                posts.edges.map(post => (
                  <BoxArticle
                    key={`blog-post-${post.node.frontmatter.title}`}
                    post={post}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

AuthorPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    posts: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default AuthorPage;

export const pageQuery = graphql`
  query authorById($id: String!, $authorTitle: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        slug
        job
        photo
        isExternalAuthor
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: {
            in: ["article-page", "case-study-page", "youtube-video"]
          }
          author: { eq: $authorTitle }
        }
      }
    ) {
      ...markdownFields
    }
  }
`;
