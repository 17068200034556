import React, { Component } from 'react';
import { classNames } from 'utils';
import { careerExperienceLevels } from 'views/career/career.utils';
import { UnorderedList } from 'components';

import './SingleJobOpenings.scss';

class SingleJobOpenings extends Component {
  render() {
    const { isActive, name, link, salary } = this.props;

    return (
      isActive && (
        <div
          className={classNames(
            'section-job-openings__single-offer',
            'text--light',
          )}
        >
          <div className={'single-offer__top'}>
            <h3 className={classNames('single-offer__title', 'text--bold')}>
              {name}
            </h3>
            <a
              className={classNames(
                `ga-remote-work--read-more-${name.replace(/ /g, '-')}`,
                'single-offer__read-more text--bold',
              )}
              href={link}
              rel={'nofollow noreferrer'}
              target={'_blank'}
            >
              Read more
            </a>
          </div>
          <div className={'single-offer__bottom'}>
            <UnorderedList
              items={Object.keys(salary).map(
                salaryKey =>
                  `<b>${careerExperienceLevels[salaryKey]}</b><br>${salary[salaryKey].min} - ${salary[salaryKey].max} PLN net (B2B)`,
              )}
              listStyleDisc={false}
            />

            <a
              href={link}
              rel={'nofollow noreferrer'}
              target={'_blank'}
              className={classNames(
                `ga-remote-work--apply-${name.replace(/ /g, '-')}`,
                'button',
                'button--primary',
                'button--white',
                'single-offer__apply',
                'text--bold',
              )}
            >
              Apply
            </a>
          </div>
        </div>
      )
    );
  }
}

export default SingleJobOpenings;
