import React from 'react';
import Slider from 'react-slick';
import { getCloudinaryUrl } from 'config/helpers';

const MobileSlides = props => {
  return (
    <>
      <div className={'wdm__mobile-slider--container'}>
        <Slider {...MobileSlides.sliderSettings}>
          <div className={'wdm__slide'}>
            <img
              className={'image'}
              alt={'Why design matters'}
              src={getCloudinaryUrl(props.photos[0], 'f_auto,q_auto,h_600')}
              loading={'lazy'}
            />
          </div>
          <div className={'wdm__slide'}>
            <img
              className={'image'}
              alt={'Why design matters'}
              src={getCloudinaryUrl(props.photos[1], 'f_auto,q_auto,h_514')}
              loading={'lazy'}
            />
          </div>
          <div className={'wdm__slide'}>
            <img
              className={'image'}
              alt={'Why design matters'}
              src={getCloudinaryUrl(props.photos[2], 'f_auto,q_auto,h_600')}
              loading={'lazy'}
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

MobileSlides.sliderSettings = {
  className: 'wdm__mobile-slider',
  dotsClass: 'slick-dots slick-pagination',
  arrows: false,
  dots: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  adaptiveHeight: false,
  pauseOnHover: false,
  pauseOnDotsHover: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default MobileSlides;
