import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactFormServices } from 'components';
import {
  Banner,
  Problem,
  Solution,
  Examples,
  HowWeBuild,
  TechnologyStack,
  LearnMore,
  OtherServices,
} from './subcomponents';
import './ProductDesignAndDevelopment.scss';

class ProductDesignAndDevelopment extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;

    return (
      <div className={'single-page single-page--hero pdad'}>
        <Banner />
        <Problem />
        <Solution />
        <Examples allArticles={allArticles} />
        <HowWeBuild />
        <TechnologyStack />
        <ContactFormServices
          id={'pdad-contact-form'}
          name={'contact-form-biz'}
          pardotLink={'https://go.boldare.com/l/688533/2019-02-28/x8y'}
        />
        <LearnMore allArticles={allArticles} />
        <OtherServices />
      </div>
    );
  }
}

export default ProductDesignAndDevelopment;
