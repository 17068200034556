import companyData from '../../../../../meta/companyData';

export default [
  {
    header: 'über',
    number: companyData.years_experience,
    content: 'langjährige Erfahrung',
    id: 'one',
  },
  {
    header: 'über',
    number: companyData.clients,
    content: 'kunden weltweit',
    id: 'two',
  },
  {
    header: 'über',
    number: companyData.products,
    content: 'digitale produkte\n',
    id: 'three',
  },
  {
    header: 'über',
    number: companyData.employees,
    content: 'kompetente problemlöser an bord',
    id: 'four',
  },
];
