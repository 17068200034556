import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StickyBox from 'react-sticky-box';
import SVG from 'react-inlinesvg';
import { Row, Col } from 'react-flexbox-grid';
import { Heading, Paragraph, ResourceForm, ResourceList } from 'components';
import './ProgramContent.scss';

class ProgramContent extends Component {
  render() {
    const {
      btnColor,
      button,
      coverAlt,
      description,
      diagram,
      formHeading,
      items,
      lead,
      redirectButton,
      redirectContent,
      redirectTitle,
      title,
      trackingEvent,
      type,
      url,
    } = this.props;

    return (
      <>
        <Row className={'no-gap'}>
          <Col md={12} lg={7} className={'program__main'}>
            <div className={'program__top'}>
              <Heading
                type={1}
                size={'large'}
                additionalClass={'program__heading'}
              >
                {title}
              </Heading>
            </div>
            <div className={'program__sidebar program__sidebar--mobile'}>
              <div className={'program__inner'}>
                <ResourceForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    redirectButton,
                    redirectContent,
                    redirectTitle,
                    trackingEvent,
                    url,
                  }}
                />
              </div>
            </div>
            <div className={'program__inner'}>
              {lead && (
                <Paragraph
                  size={'medium'}
                  additionalClass={
                    'text--default program__lead program__lead--separated'
                  }
                >
                  {lead}
                </Paragraph>
              )}
              {description[0] && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--bold program__description'}
                >
                  {description[0]}
                </Paragraph>
              )}
              {items && Array.isArray(items) && items.length && (
                <div className={'program__list-container'}>
                  <ResourceList {...{ items, coverAlt }} />
                </div>
              )}
              <div
                className={
                  'program__image-wrapper program__image-wrapper--separated'
                }
              >
                <SVG src={diagram} alt={coverAlt || 'Resource cover'} />
              </div>
              {description[1] && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--bold program__description'}
                >
                  {description[1]}
                </Paragraph>
              )}
              {description[2] && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--default program__description'}
                >
                  {description[2]}
                </Paragraph>
              )}
              {description[3] && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--default program__description'}
                >
                  {description[3]}
                </Paragraph>
              )}
              {description[4] && (
                <Paragraph
                  size={'medium'}
                  additionalClass={'text--default program__description'}
                >
                  {description[4]}
                </Paragraph>
              )}
            </div>
          </Col>
          <Col
            md={12}
            lg={5}
            className={'program__sidebar program__sidebar--desktop'}
          >
            <StickyBox offsetTop={100} style={{ zIndex: 100 }}>
              <div className={'program__inner'}>
                <ResourceForm
                  {...{
                    btnColor,
                    button,
                    formHeading,
                    redirectButton,
                    redirectContent,
                    redirectTitle,
                    trackingEvent,
                    type,
                    url,
                  }}
                />
              </div>
            </StickyBox>
          </Col>
        </Row>
      </>
    );
  }
}

ProgramContent.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  coverAlt: PropTypes.string,
  description: PropTypes.array,
  diagram: PropTypes.string,
  formHeading: PropTypes.string,
  items: PropTypes.array,
  lead: PropTypes.string,
  redirectButton: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
  redirectContent: PropTypes.string,
  redirectTitle: PropTypes.string,
  title: PropTypes.array,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ProgramContent.defaultProps = {
  btnColor: '',
  button: '',
  coverAlt: '',
  description: [],
  diagram: '',
  formHeading: '',
  items: [],
  lead: '',
  redirectButton: { path: '', label: '' },
  redirectContent: '',
  redirectTitle: '',
  title: [],
};

export default ProgramContent;
