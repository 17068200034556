import React from 'react';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import WorkshopConfirmationNavbar from 'components/WorkshopConfirmationForm/WorkshopConfirmationNavbar';
import WorkshopConfirmationForm from 'components/WorkshopConfirmationForm';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page =
  pages['transform-gaps-into-opportunities-workshop'].items[
    'event-confirmation-form'
  ];
const canonical = `${config.siteUrl}/transform-gaps-into-opportunities-workshop/event-confirmation-form/`;

const WorkshopConfirmationPage = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <WorkshopConfirmationNavbar />
      <WorkshopConfirmationForm
        url={pardotlink}
        tag={'Your action is needed'}
        lineColor={'violet'}
        title={
          "The best workshop experiences are tailored to their members' needs"
        }
        description={
          'We need a little more information from you to prepare the best possible workshop experience.'
        }
        additionalText={'It will take you no more than 5 minutes.'}
        formHeading={'Tell us more about yourself'}
        workshopQuestion={'Have you attended a similar workshop before?'}
        ratingLabel={'How much do you know about the topic?'}
        descriptionLabel={
          'What motivates you to join the workshop? What challenges do you aim to overcome with the knowledge gained?'
        }
      />
    </Layout>
  );
};

export default WorkshopConfirmationPage;
