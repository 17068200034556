import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';
import companyData from '../../../meta/companyData';

const page = pages.resources.items['code-audit-checklist'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/code-audit-checklist/`;

const CodeAuditChecklist = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={'23 things to ask a code and UX audit company: a checklist'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/code-and-ux-audit-checklist-cover.png',
          'f_auto,q_auto,w_652',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-23-things-ux-audit.png',
          'f_auto,q_auto,w_600',
        )}
        paragraphHeadings={[
          `Packed with must-ask questions and top tips the checklist will help you to identify key answers in the following areas:`,
        ]}
        items={[
          'Code & UX audit - what and how',
          'Experience and skills',
          'Workflow and results',
          'Methodology and tools ',
        ]}
        outro={`Take advantage of our ${companyData.years_experience} years of experience and download an ultimate interviewing checklist for companies conducting a business-centric code and UX audit.`}
        url={url}
        formHeading={'Get your free code audit checklist now'}
        button={'Send me checklist'}
        btnColor={'yellow'}
        trackingEvent={`CodeAuditChecklistResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free checklist is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default CodeAuditChecklist;
