import React, { Component } from 'react';
import {
  Section,
  Heading,
  Button,
  Testimonial,
  SliderArrow,
} from 'components';
import Map from 'assets/images/services/map.svg';
import Slider from 'react-slick';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic';
import companyData from '../../../../../meta/companyData';
import './Intro.scss';

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nav1: null,
      nav2: null,
      sliderSettings: {
        className: 'ydp-slider',
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        nextArrow: <SliderArrow id={'box-mobile-next'} color={'white'} />,
        prevArrow: <SliderArrow id={'box-mobile-prev'} color={'white'} left />,
      },
      navSettings: {
        className: 'ydp-navigation',
        slidesToShow: 5,
        swipeToSlide: true,
        focusOnSelect: true,
        infinite: true,
      },
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  renderNav = () =>
    data.map((item, index) => (
      <div className={'ydp-intro__nav'} key={`nav-${index}`}>
        <img
          src={getCloudinaryUrl(item.logo, 'f_auto,q_auto')}
          alt={`${item.brand} logo`}
          className={'image ydp-intro__logo'}
          loading={'lazy'}
        />
      </div>
    ));

  renderSlides = () =>
    data.map((item, index) => (
      <div className={'ydp-intro__slides'} key={`slide-${index}`}>
        <Testimonial
          author={item.client}
          content={item.quote}
          additionalClass={'ydp-intro__quote text--white'}
        />
      </div>
    ));

  render() {
    const { sliderSettings, navSettings } = this.state;

    return (
      <Section
        color={'violet'}
        additionalClass={'ydp-intro'}
        id={'ydp-testimonials'}
      >
        <div className={'ydp-intro__banner'}>
          <div>
            <Heading
              additionalClass={'text--bold'}
              color={'white'}
              type={2}
              size={'medium'}
              noLine
            >
              Over {companyData.products} digital products across{' '}
              {companyData.years_experience} years.
            </Heading>
            <a className={'link text--white text--bold'} href={'/about/'}>
              Read more about Boldare &#8594;
            </a>
          </div>
          <img
            alt={'Newsletter envelope'}
            src={Map}
            className={'image map-img'}
            loading={'lazy'}
          />
        </div>
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          {...navSettings}
        >
          {this.renderNav()}
        </Slider>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          {...sliderSettings}
        >
          {this.renderSlides()}
        </Slider>
      </Section>
    );
  }
}

export default Intro;
