import React from 'react';
import {
  Section,
  Box,
  Heading,
  Paragraph,
  Link,
  Button,
  BackgroundImage,
} from 'components';
import './NotFound.scss';

const NotFound = () => (
  <Section fullwidth>
    <div className={'not-found__container'}>
      <div className={'not-found__background'}>
        <BackgroundImage
          asset={'gatsby-cloudinary/office-4.jpg'}
          maxWidth={1300}
          maxHeight={800}
          additionalClass={
            'not-found__background-img background-img--cloudinary'
          }
        />
        <Box
          additionalClass={'box--fitted not-found__box'}
          backgroundColor={'mine-shaft'}
        >
          <Heading
            color={'white'}
            type={2}
            additionalClass={'not-found__headline'}
          >
            404
          </Heading>
          <Paragraph
            color={'gray-light'}
            size={'medium'}
            additionalClass={'not-found__description'}
          >
            We are sorry, but we are not here at the moment :(
          </Paragraph>
          <Link to={'/'}>
            <Button color={'yellow'} ariaName={'Back 404 button'}>
              Back to home
            </Button>
          </Link>
        </Box>
      </div>
    </div>
  </Section>
);

export default NotFound;
