import React from 'react';
import { SectionHeader } from 'components';

import planet1 from 'assets/images/products/Planet_1.png';
import planet2 from 'assets/images/products/Planet_2.png';
import planet3 from 'assets/images/products/Planet_3.png';
import planet4 from 'assets/images/products/Planet_4.png';
import planet5 from 'assets/images/products/Planet_5.png';

import './JoinSection.scss';

const JoinSection = () => {
  const handleClick = item => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: `products-join-${item}`,
      });
    }
  };

  return (
    <section className={'products-join section-box'} id={'join-section'}>
      <div className={'padding__container'}>
        <div className={'products-join__content'}>
          <div
            className={
              'section-header__headline-label section-header__headline-label--mine-shaft'
            }
          >
            Join Boldare Team
          </div>
          <SectionHeader
            additionalClass={'products-join__heading'}
            color={'mine-shaft'}
            headingType={2}
            label={'Join Boldare Team'}
            labelColor={'mine-shaft'}
            size={'large'}
            centered
            noLine
          >
            So... Do you want to build products that give real value to users?
          </SectionHeader>
          <a href={'/career/'} rel={'noreferrer'} target={'_blank'}>
            <button
              className={'products-btn products-btn--yellow'}
              onClick={() => {
                handleClick('career-click');
              }}
            >
              Discover our career site
            </button>
          </a>
        </div>
        <div className={'additional-planet__container'}>
          <span className={'additional-planet additional-planet--large'} />
          <span className={'additional-planet additional-planet--small'} />
        </div>
        <div className={'products-join__background'}>
          <span className={'circle--main'} />
          <div className={'box__container'}>
            <div className={'box box--bigger'}>
              <img
                className={'planet planet--1'}
                src={planet1}
                alt={'Boldare Products join us'}
              />
              <img
                className={'planet planet--5'}
                src={planet5}
                alt={'Boldare Products join us'}
              />
            </div>
            <div className={'box'}>
              <img
                className={'planet planet--2'}
                src={planet2}
                alt={'Boldare Products join us'}
              />
              <img
                className={'planet planet--3'}
                src={planet3}
                alt={'Boldare Products join us'}
              />
              <img
                className={'planet planet--4'}
                src={planet4}
                alt={'Boldare Products join us'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinSection;
