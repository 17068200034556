import { Section, ContactForm, Heading, Paragraph } from 'components';
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './KsaContactForm.scss';
import Pachnik from 'assets/images/digital-transformation-journey/pachnik.png';
import WhatsApp from 'assets/images/digital-transformation-journey/whatsapp2.png';

export const KsaContactForm = () => {
  return (
    <Section
      additionalClass={'referral-section'}
      containerClass={'referral-form-container'}
      color={'#F2DA3A'}
    >
      <Row>
        <Col xs={12} md={5} mdOffset={1}>
          <Heading
            size={'large'}
            additionalClass={'contact-form-paragraph'}
            type={'4'}
          >
            Tell us about your product
          </Heading>
          <ContactForm.V2 id={'form'} name={'ksa-contact-form'} />
        </Col>
        <Col xs={12} md={4} mdOffset={1}>
          <div className={'contact-info'}>
            <div className={'contact-person-wrapper'}>
              <img
                src={Pachnik}
                alt={'Mateusz Pachnik'}
                className={'contact-photo'}
              />
              <Heading additionalClass={'name'}>Mateusz Pachnik</Heading>
              <Heading additionalClass={'role'}>Business Development</Heading>
            </div>
            <div className={'whatsapp-wrapper'}>
              <img
                src={WhatsApp}
                alt={'WhatsApp'}
                className={'whatsapp-logo'}
              />
              <a
                href={'https://wa.me/48508592431'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                +48 508 592 431
              </a>
            </div>
            <Paragraph size={'medium'} additionalClass={'bottom-paragraph'}>
              To schedule a face-to-face appointment, <br /> reach us on
              WhatsApp, please save our <br /> contact number
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Section>
  );
};
