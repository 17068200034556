import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Slider from 'react-slick';
import { Section, SectionHeader, Paragraph, UnorderedList } from 'components';
import { data } from './logic.js';

class Process extends Component {
  constructor() {
    super();

    this.state = {
      isMobile: false,
      sliderSettings: {
        className: 'machine-learning__slider',
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 0,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
        adaptiveHeight: true,
      },
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 992 });
  };

  renderTiles = () =>
    data.map((item, index) => (
      <div className={'machine-learning__process-item'} key={item.title}>
        <SectionHeader size={'medium'} headingType={3}>
          {item.title}
        </SectionHeader>
        <UnorderedList
          additionalClass={'text--black'}
          columns={this.state.isMobile ? 1 : item.columns}
          items={item.items}
        />
      </div>
    ));

  render() {
    return (
      <div className={'machine-learning__process'}>
        <Section
          color={'gray-light'}
          additionalClass={'machine-learning__process-header'}
        >
          <Row className={'no-gap anim-fade-in-bottom'}>
            <Col sm={12} md={6}>
              <SectionHeader
                size={'big'}
                color={'black'}
                headingType={2}
                lineColor={'red'}
              >
                Our expertise
              </SectionHeader>
            </Col>
            <Col sm={12} md={6}>
              <div className={'machine-learning__process-description'}>
                <Paragraph color={'black'} size={'medium'}>
                  Your company can optimize and automate processes, personalize
                  customer-facing operations and make better, data-driven
                  decisions across all business areas thanks to our AI, machine
                  learning and advanced analytics services.
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Section>
        <Section
          fullwidth
          additionalClass={'machine-learning__process-content'}
        >
          <div className={'w-container'}>
            {this.state.isMobile ? (
              <Slider
                ref={slider => {
                  this.slider = slider;
                }}
                {...this.state.sliderSettings}
              >
                {this.renderTiles()}
              </Slider>
            ) : (
              <div className={'machine-learning__process-items'}>
                {this.renderTiles()}
              </div>
            )}
          </div>
        </Section>
      </div>
    );
  }
}

export default Process;
