import React from 'react';
import PropTypes from 'prop-types';

import { Section } from 'components';
import { getRecommendClientImageUrl } from 'views/recommendClient/utils';

import './ServicesAndTech.scss';

const LIST = [
  {
    icon: 'product-design-and-development',
    title: 'Product Design and Development',
    text: 'Web design, app development, mobile development',
  },
  {
    icon: 'product-strategist',
    title: 'Product Strategist',
    text: 'A role that transforms digital companies',
  },
  {
    icon: 'cto-as-service',
    title: 'CTO as a Service',
    text: 'How it solved a digital product company challenges',
  },
  {
    icon: 'ux-ui-design',
    title: 'UX/UI Design',
    text:
      'User centered design, UX/UI audit, user research, user testing, design systems',
  },
];

function ListItem({ icon, title, text }) {
  return (
    <li className={'recommend-client__services-and-tech__list-item'}>
      <img src={getRecommendClientImageUrl(`${icon}.png`)} alt={title} />
      <h5 className={'recommend-client__services-and-tech__list-item__title'}>
        {title}
      </h5>
      <div className={'recommend-client__services-and-tech__list-item__text'}>
        {text}
      </div>
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function ServicesAndTech() {
  return (
    <>
      <div className={'recommend-client__services-and-tech__photos'}>
        <img
          src={getRecommendClientImageUrl('referral-1.png')}
          alt={'Team at workshop'}
        />
        <img
          src={getRecommendClientImageUrl('referral-2.png')}
          alt={'Another team at workshop'}
        />
      </div>
      <Section color={'violet-light'}>
        <h2
          className={
            'recommend-client__heading recommend-client__services-and-tech__heading'
          }
        >
          Services & tech
        </h2>
        <h4
          className={
            'recommend-client__services-and-tech__subheading recommend-client__services-and-tech__heading'
          }
        >
          We offer comprehensive support for your product & 20 in-house
          technologies.
        </h4>
        <div className={'recommend-client__services-and-tech__content'}>
          <ul>
            {LIST.map(({ icon, title, text }) => (
              <ListItem key={icon} icon={icon} title={title} text={text} />
            ))}
          </ul>
        </div>
      </Section>
    </>
  );
}

export default ServicesAndTech;
