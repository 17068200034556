import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Section, SectionHeader, BackgroundImage } from 'components';

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        mainBannerImage: file(
          relativePath: { eq: "machine-learning/human-eye-violet-tone.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Section
        additionalClass={'machine-learning__banner'}
        color={'gray-lighter'}
      >
        <SectionHeader
          size={'large'}
          color={'black'}
          description={
            'Whether you’re conceptualising a new AI feature for your existing web or mobile app or looking to optimize your operations we can assist with machine learning consulting, model development, and integration services.'
          }
          hiddenDescription={
            '<br />Gain a competitive edge through process automation, smarter customer service and more accurate decision-making processes based on big data. Boldare’s AI team can help your company build a truly data-driven environment by applying machine learning models and deep neural networks across all business areas.<br /><br />Wow your users with machine-learning-powered features to boost your digital product. We are skilled at building web and mobile apps powered by machine learning models and algorithms, guiding you through the entire product development cycle. '
          }
          descriptionColor={'black'}
          fixed
          additionalClass={'section-header__description--medium'}
          buttonColor={'black'}
        >
          Machine Learning <br />
          services
        </SectionHeader>
        <div className={'machine-learning__banner-image'}>
          <BackgroundImage
            src={data.mainBannerImage.childImageSharp.fluid}
            alt={'Focused Eye'}
          />
        </div>
      </Section>
    )}
  />
);

export default Banner;
