import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Blog from 'views/blog';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

export default class BlogPage extends Component {
  render() {
    const page = pages.blog;
    const { navColors } = page;
    const url = `${config.siteUrl}/blog/`;
    const canonical = `${url}`;

    const { data } = this.props;

    const posts = data.posts.edges;
    const authors = data.authors.edges;
    const { localSearchPages } = data;

    const filters = data.filters.edges.map(item => ({
      name: item.node.frontmatter.title,
      order: item.node.frontmatter.order,
    }));

    return (
      <Layout {...{ page, canonical }}>
        <NavBar color={navColors} />
        <PopupRedirect message={'blog'} />
        <Blog
          posts={posts}
          authors={authors}
          filters={filters}
          localSearchPages={localSearchPages}
        />
      </Layout>
    );
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.shape({ edges: PropTypes.array }),
    authors: PropTypes.shape({ edges: PropTypes.array }),
    filters: PropTypes.shape({ edges: PropTypes.array }),
    localSearchPages: PropTypes.shape({
      index: PropTypes.string,
      store: PropTypes.object,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query BlogPage {
    localSearchPages: localSearchPages {
      index
      store
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: {
            in: ["article-page", "case-study-page", "youtube-video"]
          }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    authors: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "author-page" }
          settings: { category: { ne: "Case Study" } }
        }
      }
    ) {
      ...markdownFields
    }

    filters: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          settings: { category: { ne: "Case Study" } }
          isBlogFilter: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
          }
        }
      }
    }
  }
`;
