export const QuizState = {
  landing: 0,
  quiz: 1,
  contact: 2,
  result: 3,
};

export const Phase = {
  prototype: 'prototype',
  mvp: 'mvp',
  pmf: 'pmf',
  scaling: 'scaling',
};
