import React from 'react';

import './DiscussionEventContent.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { Link } from 'gatsby';

const DiscussionEventContent = () => {
  return (
    <div className={'discussion-event-content-container'}>
      <p className={'discussion-event-content-header'}>
        User Experience as a Strategic Asset: insights for C-Level Executives
      </p>

      <div className={'discussion-event-content-body'}>
        <p>
          Watch a webinar on the role of UX in business growth and innovation
          featuring industry leaders:{' '}
          <b>
            Ahmed Rawass, Senior Manager UX at Takamol Holding, and Anna
            Zarudzka, co-CEO of Boldare
          </b>
          . Designed specifically for C-level leaders, this session will focus
          on the strategic value of User Experience (UX) in digital product
          portfolios.
        </p>
        <p>Topics our speakers will discuss:</p>
        <ul className={'discussion-event-content-punctuation'}>
          <li>
            UX in Practice: demonstrating its real impact on user and customer
            engagement, and business outcomes.
          </li>
          <li>UX as a key driver in digital transformation processes.</li>
          <li>
            Practical insights from the collaboration between Takamol and
            Boldare.
          </li>
          <li>
            {`The role of UX Design in supporting Saudi Arabia’s Vision 2030 through Takamol's Digital Portfolio.`}
          </li>
        </ul>
        <p>Our speakers represent Takamol Holding and Boldare.</p>
        <p>
          Since its inception in July 2013, Takamol Holding has significantly
          influenced Saudi Arabia’s socio-economic sector, particularly in
          labor market enablement and innovative government services. Central
          to the Saudi Vision 2030, the company has launched impactful
          projects, aligning with the Ministry of Human Resource and Social
          Development’s goals.
        </p>
        <p>
          {`Boldare, with nearly 20 years in the industry, specializes in developing impactful digital products. Our approach combines reliable software, user-focused design, and strategic business insight. Our skilled product design team creates digital solutions that are not only elegantly designed but also driven by business-focused UX. Recognized for our excellence, we've won awards such as the Webby Awards, Lovie Awards, and the German Design Award, and have been acknowledged 16 times in the prestigious Awwwards competition.`}
        </p>
        <p>
          Registering for this event gives you the opportunity to sign up for
          exclusive UX Strategy consultations. In case of any questions – reach
          out to{' '}
          <i>
            <a href={'mailto:events@boldare.com'}>events@boldare.com</a>
          </i>
          .
        </p>
      </div>

      <div className={'discussion-event-content-logos'}>
        <Link to={'https://www.boldare.com/'} target={'_blank'}>
          <img
            src={getCloudinaryUrl('logos/boldare', 'f_auto,q_auto')}
            className={'logos-boldare'}
            loading={'lazy'}
            alt={'Boldare'}
          />
        </Link>
        <Link to={'https://takamolholding.com/en/'} target={'_blank'}>
          <img
            src={getCloudinaryUrl(
              'discussion-event/takamol-logo',
              'f_auto,q_auto',
            )}
            className={'logos-takamol'}
            loading={'lazy'}
            alt={'Takamol'}
          />
        </Link>
      </div>
    </div>
  );
};

export default DiscussionEventContent;
