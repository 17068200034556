import React, { Component } from 'react';
import inView from 'in-view';
import { classNames } from 'utils';
import { Section, Heading, FullwidthImage, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Goals.scss';

class Goals extends Component {
  render() {
    return (
      <>
        <Section additionalClass={'pe__goals-container'} spaceless>
          <div className={'pe__content'}>
            <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
              Design process
            </Heading>
            <Heading type={2} size={'big-spreaded'}>
              How we've built the huge application
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              We've built two huge products in regard to our standards and
              processes. At the beginning we've worked with the client together
              so close, that we created one scrum team together.
              <br />
              <br />
              We worked on very huge application, so the architecture was very
              complicated. In addition, we placed a color code for each
              subgroup (range, country etc.).
              <br />
              In crafting the readability grouped specific pages and pointed
              functional elements appearing on views (sidebar, anchor, tabs).
              Thanks to this, the division of the elements is clear and
              logical.
            </Paragraph>
          </div>
        </Section>
        <Section additionalClass={'pe__goals-images'} spaceless>
          <div className={'pe__content--left'}>
            <Paragraph
              additionalClass={'text--light'}
              size={'small-plus'}
              color={'gray-dark'}
            >
              Information architecture
            </Paragraph>
            <Heading
              type={3}
              size={'big-spreaded'}
              color={'mine-shaft'}
              additionalClass={'pe__goals-header'}
            >
              35 views
            </Heading>
            <Heading
              type={3}
              size={'default'}
              color={'mine-shaft'}
              additionalClass={'text--default pe__goals-header'}
            >
              that's the amount of all
              <br />
              pages included in the app
            </Heading>
          </div>
          <div className={'pe__goals-image'}>
            <FullwidthImage
              image={getCloudinaryUrl(
                'assets/planet-escape/planet-escape-graph.jpg',
                'f_auto,q_auto',
              )}
              alt={'Architecture'}
              additionalClass={'anim-fade-in-bottom'}
            />
          </div>
        </Section>
      </>
    );
  }
}

export default Goals;
