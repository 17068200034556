import React from "react";
import { IconSVG, Link } from 'components';

import './CustomerTile.scss';

export const CustomerTile = ({ customer: { name, face, comment, companyLogo, companyName, caseStudy } }) => {
    return (
        <div className={'customer-tile__wrapper'}>
            <span className={'customer-tile__comment'}>{comment}</span>
            <div>
                <div className={'customer-tile__customer__wrapper'}>
                    <img src={face} alt={`face of ${name}`} className={'customer-tile__customer__face'} />
                    <div className={'customer-tile__customer-name__wrapper'}>
                        <p className={'customer-tile__customer-name'}>{name}</p>
                        <p className={'customer-tile__customer-company-name'}>{companyName}</p>
                    </div>
                </div>
                <div>
                    <div className={'customer-tile__customer-detail'} />
                    <div className={'customer-tile__customer-logo'}>
                        <img src={companyLogo} alt={`logo of ${companyName}`} />
                        <Link to={caseStudy} arrow className={'customer-tile__customer-logo__link'}>
                            SEE CASE STUDY
                            <IconSVG
                                name={'arrow-right'}
                                size={'small'}
                                color={'violet'}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}