import React, { Component } from 'react';
import { Paragraph, IconSVG } from 'components';
import { classNames } from 'utils';
import ReactHtmlParser from 'react-html-parser';
import './AccordionQandA.scss';

class AccordionQandA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: 0,
    };
  }

  renderItems = data =>
    data.map((item, index) => (
      <div
        className={classNames('accordionqa__section', {
          active: index === this.state.slide,
        })}
      >
        <button className={'accordionqa'} onClick={() => this.showTile(index)}>
          <p className={'accordionqa__title'}>{item.title}</p>
          <IconSVG name={'chevron-down'} size={'big'} color={'mine-shaft'} />
        </button>
        <div className={'accordionqa__content'}>
          <Paragraph additionalClass={'accordionqa__text'}>
            {ReactHtmlParser(item.description)}
          </Paragraph>
        </div>
      </div>
    ));

  showTile(number) {
    this.setState({ slide: number });
  }

  render() {
    const { data } = this.props;

    return (
      <>
        <div className={'accordionqa__wrapper'}>{this.renderItems(data)}</div>
      </>
    );
  }
}

export default AccordionQandA;
