import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { classNames } from 'utils';
import { IconSVG } from 'components';
import { getCloudinaryUrl } from 'config/helpers';

import './SingleAward.scss';

const SingleAward = ({ type, isBig, link, isTablet }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const boxVariants = isTablet
    ? {
        opacity: 0,
        y: `50px`,
      }
    : { opacity: 1, y: '0' };

  return (
    <motion.li
      ref={ref}
      animate={inView ? { opacity: 1, y: '0' } : boxVariants}
      transition={{ duration: 0.4 }}
      className={classNames('prizes-award', {
        'prizes-award--big': isBig,
      })}
    >
      <img
        src={getCloudinaryUrl(type, 'w_288')}
        alt={`${type} logo`}
        className={classNames('prizes-award__img', {
          'prizes-award__img--big': isBig,
        })}
      />
      {link && (
        <a href={link.href} rel={'nofollow'} className={'prizes-award__link'}>
          {link.text}
          <div className={'prizes-award__icon'}>
            <IconSVG
              name={'arrow-right'}
              color={'mine-shaft'}
              size={'small'}
            />
          </div>
        </a>
      )}
    </motion.li>
  );
};

SingleAward.defaultProps = {
  isBig: false,
  link: null,
};

SingleAward.propTypes = {
  type: PropTypes.string.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isBig: PropTypes.bool,
  link: PropTypes.object,
};

export default SingleAward;
