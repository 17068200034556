import React from 'react';
import { Heading, Paragraph, Section, SectionHeader } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Image from 'components/Image';
import './Benefits.scss';

const Benefits = () => {
  const benefits = [
    {
      image: getCloudinaryUrl(
        '/img/renewable-energy-industry/money.png',
        'f_auto,q_auto,h_161',
      ),
      title: 'Save money',
      description:
        'With years of experience in the renewable sector industry, we know which solutions will improve your ROI.',
    },
    {
      image: getCloudinaryUrl(
        '/img/renewable-energy-industry/book.png',
        'f_auto,q_auto,h_188',
      ),
      title: 'Get advice',
      description:
        'Our expertise goes far beyond software development - we are partners that will help to build an antifragile strategy ready to face the dynamic environment we live in',
    },
    {
      image: getCloudinaryUrl(
        '/img/renewable-energy-industry/time.png',
        'f_auto,q_auto,h_210',
      ),
      title: 'Start quickly ',
      description:
        'We understand the challenges of the energy industry, so we will start quickly, without needing to invest time in acquiring industry knowledge.',
    },
  ];

  const SingleBenefit = ({ benefit }) => (
    <li key={benefit.title} className={'benefits-business__item'}>
      <div className={'benefits-business__item-image-wrapper'}>
        <Image src={benefit.image} alt={benefit.title} />
        <div className={'hide-mobile'}>
          <Image
            src={getCloudinaryUrl(
              '/img/renewable-energy-industry/circle-striped.svg',
              'f_auto,q_auto,h_150',
            )}
            alt={'Circle'}
            additionalClass={'benefits-business__item-decoration'}
          />
        </div>
      </div>
      <Heading
        type={3}
        size={'medium'}
        additionalClass={'benefits-business__item-title'}
      >
        {benefit.title}
      </Heading>
      <Paragraph
        additionalClass={'benefits-business__item-description text--light'}
      >
        {benefit.description}
      </Paragraph>
    </li>
  );

  return (
    <Section color={'catskill-white'} additionalClass={'benefits-business'}>
      <SectionHeader
        additionalClass={'benefits-business__heading'}
        color={'mine-shaft'}
        headingType={2}
        size={'large'}
        centered
        noLine
      >
        Use our energy industry{' '}
        <span className={'headline--marking headline--marking-violet'}>
          experience
        </span>{' '}
        for the benefit of your business
      </SectionHeader>
      <ul className={'benefits-business__list'}>
        {benefits.map(benefit => (
          <SingleBenefit benefit={benefit} />
        ))}
      </ul>
    </Section>
  );
};

export default Benefits;
