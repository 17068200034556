import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Heading, Paragraph, Testimonial, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../../../meta/config';
import './Summary.scss';

const Summary = () => (
  <>
    <Section spaceless>
      <Row className={'no-gap htw__summary-top'}>
        <Col md={12} lg={8} className={'htw__summary-main'}>
          <div className={'htw__summary-inner htw__summary-inner--mobile'}>
            <div className={'htw__summary-image-wrapper'}>
              <img
                alt={'Hack The Wind'}
                src={getCloudinaryUrl('img/hackthewind.png', 'q_auto')}
                className={'image htw__summary-image'}
                loading={'lazy'}
              />
            </div>
            <Heading
              type={2}
              size={'large'}
              additionalClass={'htw__summary-heading'}
            >
              About
              <br />
              Hack the Wind
            </Heading>
          </div>
        </Col>
        <Col md={12} lg={4} className={'htw__summary-sidebar'}>
          <div className={'htw__summary-inner'}>
            <Heading
              size={'medium'}
              type={3}
              additionalClass={'text--default htw__summary-testimonial-intro'}
            >
              Hackathon team
            </Heading>
            <Testimonial
              author={'Paweł Capaja'}
              content={
                'This is our chance to make an impact in the future of renewable and clean energy - the future of our planet.'
              }
              additionalClass={'text--light'}
            />
          </div>
        </Col>
      </Row>
      <Row className={'no-gap htw__summary-bottom'}>
        <Col md={12} lg={8} className={'htw__summary-main'}>
          <div className={'htw__summary-inner'}>
            <Paragraph
              size={'medium'}
              additionalClass={'text--light htw__summary-desc'}
            >
              Hack the Wind 2018 is a second edition of a hackathon hosted by
              InnoEnergy and Wind Europe during the Global Wind Summit. More
              than 20 development teams competed in a challenge to build a
              product that uses machine learning to forecast wind turbine
              breakdowns. It wasn’t an easy task as judges not only looked at
              the machine learning model the teams created but also the user
              experience and user interface of the product that they have
              built.
              <br />
              <br />
              The teams from around the world worked almost non-stop for two
              days and nights in a unique environment which allowed them to
              focus only on the task at hand: come up with new ideas, test
              them, and innovate at full speed.
              <br />
              <br />
              The Boldare Machine Learning Team was one of the crews taking
              part in the competition. You can follow their journey via our
              Twitter feed{' '}
              <Link
                to={config.twitter}
                additionalClass={'text--bold htw__inline-link'}
                id={'hack-the-wind-boldare'}
              >
                @boldarecom
              </Link>{' '}
              and{' '}
              <Link
                to={'https://twitter.com/hashtag/BoldareHacksTheWind?f=tweets'}
                id={'hack-the-wind-about-twitter'}
                additionalClass={'text--bold htw__inline-link'}
              >
                #BoldareHacksTheWind
              </Link>
              .
            </Paragraph>
          </div>
        </Col>
        <Col
          md={12}
          lg={4}
          className={'htw__summary-sidebar htw__summary-sidebar--list'}
        >
          <div className={'htw__summary-inner'}>
            <div className={'htw__summary-item'}>
              <Paragraph
                size={'small'}
                additionalClass={'htw__summary-item-title'}
              >
                Team
              </Paragraph>
              <Paragraph
                size={'default'}
                additionalClass={'text--default htw__summary-item-desc'}
              >
                Machine Learning Team
              </Paragraph>
            </div>
            <div className={'htw__summary-item'}>
              <Paragraph
                size={'small'}
                additionalClass={'htw__summary-item-title'}
              >
                Deliverables
              </Paragraph>
              <Paragraph
                size={'default'}
                additionalClass={'text--default htw__summary-item-desc'}
              >
                A machine learning-based solution
                <br />
                to predict wind turbine failures
              </Paragraph>
            </div>
            <div className={'htw__summary-item'}>
              <Paragraph
                size={'small'}
                additionalClass={'htw__summary-item-title'}
              >
                Timespan
              </Paragraph>
              <Paragraph
                size={'default'}
                additionalClass={'text--default htw__summary-item-desc'}
              >
                48h
              </Paragraph>
            </div>
            <div className={'htw__summary-item'}>
              <Paragraph
                size={'small'}
                additionalClass={'htw__summary-item-title'}
              >
                Cost
              </Paragraph>
              <Paragraph
                size={'default'}
                additionalClass={'text--default htw__summary-item-desc'}
              >
                Pro Bono
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
      <Row className={'no-gap htw__summary-logos'}>
        <Col sm={12} md={8}>
          <Paragraph
            size={'small'}
            color={'gray-medium'}
            additionalClass={'text--default'}
          >
            Organised by
          </Paragraph>
          <div className={'htw__summary-logo-container'}>
            <div
              className={
                'htw__summary-image-wrapper htw__summary-image-wrapper--logo'
              }
            >
              <Link
                to={'https://windeurope.org/summit2018/'}
                id={'hack-the-wind-windeurope'}
              >
                <img
                  alt={'Hack The Wind'}
                  src={getCloudinaryUrl('img/windE.png', 'q_auto')}
                  className={'image htw__summary-image'}
                  loading={'lazy'}
                />
              </Link>
            </div>
            <div
              className={
                'htw__summary-image-wrapper htw__summary-image-wrapper--logo'
              }
            >
              <Link
                to={'http://www.innoenergy.com/'}
                id={'hack-the-wind-innoenergy'}
              >
                <img
                  alt={'Hack The Wind'}
                  src={getCloudinaryUrl('img/EIT.png', 'f_auto,q_auto')}
                  className={'image htw__summary-image'}
                  loading={'lazy'}
                />
              </Link>
            </div>
          </div>
        </Col>
        <Col sm={12} md={4}>
          <Paragraph
            size={'small'}
            color={'gray-medium'}
            additionalClass={'text--default'}
          >
            Challange sponsor
          </Paragraph>
          <div
            className={
              'htw__summary-image-wrapper htw__summary-image-wrapper--logo'
            }
          >
            <Link to={'https://www.edpr.com/en'} id={'hack-the-wind-edpr'}>
              <img
                alt={'Hack The Wind'}
                src={getCloudinaryUrl('img/edpr-logo.png', 'f_auto,q_auto')}
                className={'image htw__summary-image'}
                loading={'lazy'}
              />
            </Link>
          </div>
        </Col>
      </Row>
    </Section>
  </>
);

Summary.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Summary;
