export const data = [
  {
    title: 'Sign up section changes',
    text:
      'If something is clearly visible then it’s easier to find. Simple as that. Especially when we think about such an important element as „sign up” button. Making the button eye-catching helped us to reduce time that user waste looking for it.',
    image: 'pic-2',
    subtitle: 'Stunning results',
    number: 64,
    suffix: '%',
    info: 'more new users<br/>during last 3 months time',
  },
  {
    title: 'Short forms',
    text:
      'Instead of wasting user time and making him annoyed during detailed registration process we make it a piece of cake. 4 seconds, this is all the time you need to spend for sign up.',
    image: 'pic-3',
    subtitle: 'Process boost',
    number: 4,
    suffix: ' sec',
    info: "that's how long it takes<br />to sign up",
  },
];
