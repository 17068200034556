import React from 'react';
import PropTypes from 'prop-types';

import { IconSVG } from 'components';
import { content } from 'components/MegaMenu/content';
import { MenuLink } from 'components/MegaMenu/subcomponents';

import './MenuSection.scss';

const MenuSection = ({ type, columns }) => {
  const { header, description, mainLink, links } = content[type];

  return (
    <div className={'menu-section'}>
      {header && (<h2 className={'menu-section__header'}>{header}</h2>)}
      {description && (<p className={'menu-section__description'}>{description}</p>)}
      {mainLink && (
        <a href={mainLink.url} className={'menu-section__link'}>
          {mainLink.text}
          <IconSVG name={'arrow-right'} size={'small-plus'} color={'white'} />
        </a>
      )}
      <ul className={`menu-section__list menu-section__list__columns_${columns}`}>
        {links.map(link => (
          <MenuLink
            url={link.url}
            title={link.title}
            description={link.description}
            icon={link.icon}
          />
        ))}
      </ul>
    </div>
  );
};

MenuSection.defaultProps = {
  type: '',
  columns: 1,
};

MenuSection.propTypes = {
  type: PropTypes.string,
  columns: PropTypes.number,
};

export default MenuSection;
