export const data = [
  {
    name: 'JavaScript',
    image: 'javascript',
    img: 'logos/logo-javascript.png',
  },
  {
    name: 'Angular',
    image: 'angular',
    img: 'logos/logo-angular.png',
  },
  {
    name: 'HTML 5',
    image: 'html',
    img: 'logos/logo-html.png',
  },
];
