import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import './ContactDe.scss';
import {
  Banner,
  Cities,
  ContactFormDe,
  GermanMedia,
  GermanPartners,
  SuperHeroes,
  WhoAreWe,
  ContactDeAwards,
} from 'views/contactDe/subcomponents';

class ContactDe extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero connect-de-page'}>
        <Banner />
        <SuperHeroes />
        <ContactFormDe />
        <Cities />
        <WhoAreWe />
        <GermanMedia />
        <GermanPartners />
        <ContactDeAwards />
      </div>
    );
  }
}

export default ContactDe;
