import React from 'react';
import { Section, SectionHeader, RelatedArticles } from 'components';
import { isEmpty } from 'lodash';
import './OurArticles.scss';

const OurArticles = ({ allArticles }) => (
  <>
    <Section
      color={'gray-light'}
      additionalClass={'wdm__articles-heading wdm__heading'}
    >
      <SectionHeader size={'medium'} fixed headingType={2} noLine>
        More about
        <br /> what we&apos;ve done
      </SectionHeader>
    </Section>
    {!isEmpty(allArticles) && (
      <RelatedArticles
        id={'why-design-matters__articles'}
        additionalClass={'why-design-matters__articles'}
        related={allArticles.edges.map(edge => ({
          title: edge.node.frontmatter.title,
        }))}
        background={'gray-light'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
      />
    )}
  </>
);

export default OurArticles;
