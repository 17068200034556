import React from 'react';
import './SuperHeroes.scss';
import { BoxProfile, Heading, Section, SectionHeader } from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { getCloudinaryUrl } from 'config/helpers';
import StripedCircleBig from 'assets/images/contact-de/striped-circle-big.svg';
import StripedCircleSmall from 'assets/images/contact-de/striped-circle-small.svg';
import German from 'assets/images/contact/german-flag.svg';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';

const SuperHeroes = () => (
  <Section
    additionalClass={'contact-de-super-hero__section'}
    id={'connect-de-super-heroes'}
  >
    <Row
      center={'md'}
      start={'lg'}
      className={
        'contact-de-super-hero--position-relative contact-de-super-hero__text-row'
      }
    >
      <div className={'contact-de-super-hero__small-circle-wrapper'}>
        <div
          className={
            'contact-de-super-hero__circle__line contact-de-super-hero__small-circle__line--position'
          }
        />
        <div>
          <img
            src={StripedCircleSmall}
            className={'image'}
            alt={'Striped circle'}
            loading={'lazy'}
          />
        </div>
      </div>
      <Col>
        <Col md={12} lg={6}>
          <SectionHeader
            size={'large'}
            color={'mine-shaft'}
            lineColor={'violet'}
            descriptionColor={'black'}
            buttonColor={'black'}
            fixed
            noLine
            additionalClass={'contact-de-super-hero__text-header'}
          >
            Treffen Sie <br />
            unser Team
          </SectionHeader>
        </Col>
        <Col
          sm={12}
          lg={7}
          className={'contact-de-super-hero__text-header-secondary-col'}
        >
          <Heading
            type={2}
            size={'medium-constant'}
            color={'black'}
            additionalClass={'contact-de-super-hero__text-header-secondary'}
          >
            Matt ist unser hochkarätiger Experte mit langjähriger Erfahrung in
            der Zusammenarbeit mit den erfolgreichsten Unternehmen aus der
            DACH-Region. Er ist jederzeit erreichbar, um Ihnen zu helfen und
            Sie zu beraten. Setzen Sie sich jetzt mit ihm in Verbindung.
          </Heading>
        </Col>
      </Col>
    </Row>
    <Row
      center={'md'}
      end={'lg'}
      className={'no-gap contact-de-super-hero--position-relative'}
    >
      <Col
        sm={12}
        md={6}
        lg={7}
        className={
          '' +
          'contact-de-super-hero--position-relative ' +
          'contact-de-super-hero__img-col-big'
        }
      >
        <div className={'contact-de-super-hero__big-circle-wrapper'}>
          <div
            className={
              'contact-de-super-hero__circle__line contact-de-super-hero__big-circle__line--position'
            }
          />
          <div className={'contact-de-super-hero__big-circle--position'}>
            <img
              src={StripedCircleBig}
              className={'image'}
              alt={'Striped circle'}
              loading={'lazy'}
            />
          </div>
          <div>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/contact-de-hamburg-map',
                'w_572',
              )}
              className={'image'}
              alt={'Striped circle'}
              loading={'lazy'}
            />
          </div>
        </div>
      </Col>
      <Col
        sm={12}
        md={6}
        lg={5}
        className={
          '' +
          'contact-de-super-hero--position-relative ' +
          'contact-de-super-hero__img-col '
        }
      >
        <BoxProfile
          photo={getCloudinaryUrl(
            'gatsby-cloudinary/boldare-contact-de-super-hero-matt-hallmann.png',
            'v1645992258',
          )}
          name={'Matt Hallmann'}
          job={'Relationship builder'}
          linkedin={'https://www.linkedin.com/in/matthallmann/'}
          email={'mailto:matt.hallmann@boldare.com'}
          phone={CountryContactPhoneNumbers.Germany.displayValue}
          flag={German}
          additionalClass={
            'contact-de-super-hero__profile-higher contact-de-super-hero__profile'
          }
        />
      </Col>
    </Row>
  </Section>
);

export default SuperHeroes;
