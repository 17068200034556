import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';

const page = pages.resources.items['product-strategy-workbook'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/product-strategy-workbook/`;
const canonical = `${url}`;

const ProductStrategyWorkbook = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={'How to create product strategy'}
        lineColor={'red'}
        cover={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto')}
        coverMobile={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto,w_600')}
        description={`Unlock the secrets to building a successful product strategy with our easy-to-follow workbook, "How to create product strategy. Workbook". Perfect for anyone dealing with products daily – this guide breaks down the process so you can create a winning strategy on your own.
        
        What's Inside:`}
        items={[
          `Product Vision`,
          `Product Strategy`,
          `Business Model`,
          `Product Roadmap`,
          `Risk and Validation Cycles`,
          `Success Criteria`,
        ]}
        outro={`With our workbook you're equipped to turn your product ideas into real success. No matter if you're going solo or working with a team, this workbook is your buddy on the journey to making a great product. Start creating your success story today!`}
        url={pardotlink}
        formHeading={'Get your free workbook now'}
        button={'Send me workbook'}
        btnColor={'pigeon-post'}
        trackingEvent={`ProductStrategyWorkbookResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free workbook is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default ProductStrategyWorkbook;
