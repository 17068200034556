import React from 'react';
import { Section, SectionHeader } from 'components';

const careerContactAddress = 'career@boldare.com';

const ContactUs = () => {
  return (
    <Section color={'soft-cloud'} additionalClass={'offers__contact'}>
      <SectionHeader
        color={'mine-shaft'}
        size={'medium'}
        headingType={2}
        noLine
        additionalClass={'offers__contact-header--has-offers'}
      >
        How about joining us now or soon? <br />
        Just drop us a line!
      </SectionHeader>
      <div className={'offers__contact-links'}>
        <a
          href={`mailto:${careerContactAddress}?subject=Interesuje mnie rekrutacja do Boldare - mam pytanie`}
          className={'link'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {careerContactAddress}
        </a>
        <a
          href={'https://boldare.breezy.hr/p/a691cfa5814101-contact-us'}
          className={'button button--primary button--yellow'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          Send your CV
        </a>
      </div>
    </Section>
  );
};
export default ContactUs;
