import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from './arrow_left_slim.svg';
import { ReactComponent as ArrowRight } from './arrow_right_slim.svg';

const ChallengesNav = ({ prevAction, nextAction }) => (
  <div className={'dsgn-hero__challenges-nav bldr-dsgn__row'}>
    <button
      className={'bldr-dsgn__arrow-box'}
      onClick={prevAction}
    >
      <ArrowLeft />
    </button>
    <button
      className={'bldr-dsgn__arrow-box'}
      onClick={nextAction}
    >
      <ArrowRight />
    </button>
  </div>
);

ChallengesNav.propTypes = {
  prevAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

ChallengesNav.defaultProps = {
  isMobile: false,
};

export default ChallengesNav;
