import React, { Component, Fragment } from 'react';
import { Section, Paragraph, Heading, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Solutions.scss';

class Solutions extends Component {
  renderSolution = () =>
    data.map(item => (
      <Fragment key={`sm-solutions-${item.image}`}>
        <div className={'sm__content--center'}>
          <FullwidthImage
            image={getCloudinaryUrl(
              `assets/spa-monkeys/spamonkeys-diagram-zoom-${item.image}.jpg`,
              'f_auto,q_auto',
            )}
            alt={item.title}
          />
        </div>
        <div className={'sm__content--right'}>
          <Heading type={3} size={'medium-plus'}>
            {item.title}
          </Heading>
          <Heading type={4} color={'boston-blue'} size={'medium-plus-small'}>
            {item.subtitle}
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'default'}
            color={'mine-shaft'}
          >
            {item.description}
          </Paragraph>
        </div>
      </Fragment>
    ));

  render() {
    return (
      <Section additionalClass={'sm__solutions-container'} spaceless>
        <div className={'sm__content--left'}>
          <Heading
            type={3}
            size={'medium-plus'}
            color={'mine-shaft'}
            additionalClass={'text--medium-bold sm__solutions-header'}
          >
            We made
            <span className={'text--bold text--boston-blue'}>
              {' '}
              custom and precise{' '}
            </span>
            software solutions
          </Heading>
        </div>
        {this.renderSolution()}
      </Section>
    );
  }
}

export default Solutions;
