import React, { useRef } from 'react';
import './Restaurant.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';

const Restaurant = () => {
  const background = useRef();
  const secondPhone = useRef();
  const thirdPhone = useRef();

  useScrollAnimation([
    {
      ref: background,
      transform: { translateX: '-50px' },
    },
    {
      ref: secondPhone,
      transform: { translateX: '120%' },
    },
    {
      ref: thirdPhone,
      transform: { translateX: '240%' },
    },
  ]);

  return (
    <div className={'workroom-restaurant__container'}>
      <div className={'workroom-restaurant__background'} ref={background} />
      <img
        className={'image workroom-restaurant__image'}
        src={getCloudinaryUrl('img/WR_Photography_2.png', 'f_auto,q_auto')}
        alt={'Lunch in work room'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-restaurant__phone'}
        src={getCloudinaryUrl('img/WR_iP_3.png', 'f_auto,q_auto')}
        alt={'Workroom app'}
        loading={'lazy'}
      />
      <img
        className={'image workroom-restaurant__phone'}
        src={getCloudinaryUrl('img/WR_iP_4.png', 'f_auto,q_auto')}
        alt={'Workroom app'}
        innerRef={secondPhone}
        loading={'lazy'}
      />
      <img
        className={'image workroom-restaurant__phone '}
        src={getCloudinaryUrl('img/WR_iP_5.png', 'f_auto,q_auto')}
        alt={'Workroom app'}
        innerRef={thirdPhone}
        loading={'lazy'}
      />
    </div>
  );
};

export default Restaurant;
