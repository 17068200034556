import React from 'react';
import { Section, SectionHeader, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import ReactHtmlParser from 'react-html-parser';
import { classNames } from 'utils';
import { differentData } from './logic';
import './Different.scss';

const Different = () => {
  const renderItems = () =>
    differentData.map(item => {
      const { id, color, name, title, description, link, img, small } = item;

      return (
        <div
          className={`section--${id} career-different__item career-different__item--${color}`}
        >
          <img
            src={getCloudinaryUrl(img, 'f_auto,q_auto,h_250')}
            alt={'Office meeting'}
            style={{
              objectPosition: 'top center',
            }}
            className={classNames('image', 'career-different__img', {
              'career-different__img--small': small,
            })}
            loading={'lazy'}
          />
          <div className={'career-different__item-content'}>
            <Heading color={color} additionalClass={'career-different__name'}>
              {name}
            </Heading>
            {link ? (
              <a href={link} target={'_blank'} rel={'noreferrer'}>
                <Heading
                  color={'mine-shaft'}
                  size={'big'}
                  type={3}
                  additionalClass={'career-different__title with-link'}
                >
                  {ReactHtmlParser(title)}
                </Heading>
              </a>
            ) : (
              <Heading
                color={'mine-shaft'}
                size={'big'}
                type={3}
                additionalClass={'career-different__title'}
              >
                {title}
              </Heading>
            )}
            {link ? (
              <a href={link} target={'_blank'} rel={'noreferrer'}>
                <Paragraph
                  additionalClass={'career-different__paragraph with-link'}
                  color={'mine-shaft'}
                >
                  {description}
                </Paragraph>
              </a>
            ) : (
              <Paragraph
                additionalClass={'career-different__paragraph'}
                color={'mine-shaft'}
              >
                {description}
              </Paragraph>
            )}
          </div>
          <div className={'career-different__item-content'}>
            {link && (
              <a
                href={link}
                className={'link career-different__link text--mine-shaft'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                Learn more &rarr;
              </a>
            )}
          </div>
        </div>
      );
    });

  return (
    <Section
      additionalClass={'career-different'}
      color={'mine-shaft'}
      id={'advantages'}
    >
      <Heading
        type={3}
        size={'medium'}
        color={'white'}
        additionalClass={'headline--top headline--top-red'}
      >
        Boldare Advantages
      </Heading>
      <SectionHeader
        additionalClass={'headline--centered'}
        color={'white'}
        size={'medium'}
        headingType={2}
        noLine
      >
        What else makes us
        <br />{' '}
        <span className={'headline--marking headline--marking-red'}>
          different?
        </span>
      </SectionHeader>
      <Paragraph additionalClass={'description--centered'} color={'white'}>
        These elements strengthen our Boldness.
      </Paragraph>
      <div className={'career-different__wrapper'}>{renderItems()}</div>
    </Section>
  );
};

export default Different;
