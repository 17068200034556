import React from 'react';
import { Heading, Paragraph } from 'components';
import { classNames } from 'utils';
import { useWindowWidth } from 'hooks';
import { data } from './data';
import { ImageSlider, SocialBar } from './subcomponents';
import './AboutUsSection.scss';

const AboutUsSection = () => {
  const isTabletBig = useWindowWidth() >= 992;

  return (
    <section className={'section-about-us'}>
      <div className={'about-us__text-container'}>
        <Paragraph
          additionalClass={'about-us__upper-title text--bold'}
          color={'yellow'}
        >
          We are Boldare!
        </Paragraph>
        <Heading type={2} additionalClass={'about-us__title'}>
          Digital product
          <br />
          design & development
          <br />
          company
        </Heading>
        <Paragraph additionalClass={'about-us__description'} color={'white'}>
          Boldare is a design and digital product development company. We
          create beautiful and functional web platforms, apps and custom
          software solutions.
          {isTabletBig && (
            <>
              <br />
              <br />
            </>
          )}
          Boldare emerged from a successful merger of Chilid, a web design
          company and XSolve, a software development company, and inherited
          their 17 years of experience working in global markets.
        </Paragraph>
        <a
          href={'/work/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classNames(
            'button',
            'button--transparent-white',
            'about-us__button',
            'ga-remote-work--about-us-case-studies',
          )}
        >
          Case Studies
        </a>
      </div>
      <ImageSlider id={'about-us-slider'} items={data} />
      <SocialBar additionalClass={'about-us__social-bar'} />
    </section>
  );
};

export default AboutUsSection;
