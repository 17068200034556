import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import Gitex from 'views/gitex';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['gitex-2019'];
const { navColors } = page;
const canonical = `${config.siteUrl}/gitex-2019/`;

const GitexPage = () => (
  <Layout {...{ page, canonical }}>
    <Helmet title={'Meet us at GITEX 2019'}>
      <meta
        name={'description'}
        content={`Join our video coverage from the most important technology event in the Middle East - GITEX 2019.`}
      />
      <link rel={'canonical'} href={canonical} />
    </Helmet>
    <NavBar color={navColors} />
    <Gitex />
  </Layout>
);

export default GitexPage;
