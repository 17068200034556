import React, { useRef } from 'react';
import { Heading, Section, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Cooperation.scss';
import { SectionWide } from 'components/DS';

const Cooperation = () => {
  return (
    <SectionWide>
      <div className={'pt__cooperation-container pt__column-mobile'}>
        <img
          className={'pt__cooperation-container--image anim-fade-in-left'}
          src={getCloudinaryUrl(
            'assets/practitest/israel.png',
            'f_auto,q_auto',
          )}
          alt={'Israel'}
          loading={'lazy'}
        />
        <div
          className={'pt__cooperation-container--content anim-fade-in-right'}
        >
          <Heading type={3} size={'big'} color={'mine-shaft'}>
            Remote cooperation between Poland and Israel based on a&nbsp;many
            times proven process
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We all know that the world is a global village now. We embrace that
            as we like to spread our word around the world, but meeting a
            client from Israel was a great door-opener to cooperation with
            business in that country and we must say we cherish this new
            relationship. Many new opportunities opened up to us and we gladly
            took the challenge.
          </Paragraph>
        </div>
      </div>
    </SectionWide>
  );
};

export default Cooperation;
