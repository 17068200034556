import React from 'react';
import {
  Button,
  Paragraph,
  Section,
  SectionHeader,
  UnorderedList,
} from 'components';
import './Boxes.scss';
import { scroller } from 'react-scroll';

const Boxes = () => {
  return (
    <Section spaceless fullwidth additionalClass={'ydp-boxes'}>
      <div className={'w-container--left'}>
        <SectionHeader
          additionalClass={'text--bold'}
          color={'white'}
          headingType={2}
          lineColor={'red'}
          size={'medium'}
        >
          Need to design &amp; develop a&nbsp;digital product?
        </SectionHeader>
      </div>
      <div className={'w-container--right'}>
        <SectionHeader
          additionalClass={'text--bold'}
          color={'black'}
          headingType={2}
          lineColor={'red'}
          size={'medium'}
        >
          Need a cross-functional development team?
        </SectionHeader>
      </div>
      <div className={'w-container--left'}>
        <Paragraph
          size={'medium'}
          additionalClass={'ydp-boxes__description'}
          color={'white'}
        >
          We are able to deliver your digital product in as little as 4-6 weeks
          employing the lean startup approach and agile software development.
          We are also experts at scaling existing apps, helping clients to
          shift from inefficient solutions to stable products that really grow
          alongside their user base.
        </Paragraph>
      </div>
      <div className={'w-container--right'}>
        <Paragraph size={'medium'} additionalClass={'ydp-boxes__description'}>
          Our software engineers collaborate with designers in
          cross-functional, agile teams, dedicated to fulfilling your business
          goals. The development team will advise you along the way - from
          choosing a solution, responding to changing circumstances, to passing
          on their know-how so you can grow independently.
        </Paragraph>
      </div>
      <div className={'w-container--left'}>
        <UnorderedList
          containerClass={'ydp-boxes__list'}
          additionalClass={'text--bold text--white'}
          items={[
            'web and mobile design;',
            'web and mobile development;',
            'custom software development;',
            'security and quality assurance;',
            'infrastructure architecture;',
            'tech stack consulting;',
          ]}
        />
      </div>
      <div className={'w-container--right'}>
        <UnorderedList
          containerClass={'ydp-boxes__list'}
          additionalClass={'text--bold'}
          items={[
            'hire the entire product team;',
            'extend your in-house team;',
            'complement your team’s skills;',
          ]}
        />
      </div>
      <div className={'w-container--left ydp-boxes__link'}>
        <Button
          id={'ydp-box-black'}
          color={'yellow'}
          ariaName={'Contact button'}
          additionalClass={'contact-button'}
          onClick={() => {
            scroller.scrollTo('ydp-contact-form', {
              spy: false,
              smooth: true,
              duration: 500,
              offset: -100,
            });
          }}
        >
          Contact us
        </Button>
      </div>
      <div className={'w-container--right ydp-boxes__link'}>
        <Button
          id={'ydp-box-white'}
          color={'yellow'}
          ariaName={'Contact button'}
          additionalClass={'contact-button'}
          onClick={() => {
            scroller.scrollTo('ydp-contact-form', {
              spy: false,
              smooth: true,
              duration: 500,
              offset: -100,
            });
          }}
        >
          Contact us
        </Button>
      </div>
    </Section>
  );
};

export default Boxes;
