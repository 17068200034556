import React, { Component } from 'react';
import {
  Section,
  SectionHeader,
  WayPointHandler,
  Button,
  Link,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './FeaturedIn.scss';
import Slider from 'react-slick';
import { data } from './logic';

class FeaturedIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slider: null,
      sliderSettings: {
        className: 'featured-in-slider',
        arrows: false,
        dots: false,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        draggable: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({ slider: this.slider });
  }

  renderSlides = () => {
    return data.map((item, index) => (
      <div className={'featured-in__item'} key={`featured-in-${index}`}>
        <img
          src={getCloudinaryUrl(item.image, 'f_auto,q_auto,w_288')}
          className={'featured-in__logo'}
          alt={'Press Logo'}
        />
      </div>
    ));
  };

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <WayPointHandler path={'FeaturedIn'}>
          <Section className={'featured-in__wrap'}>
            <SectionHeader
              color={'white'}
              headingType={2}
              lineColor={'yellow'}
              size={'extra-large'}
              additionalClass={'featured-in__title'}
            >
              Featured In
            </SectionHeader>
            <div className={'featured-in__logos'}>{this.renderSlides()}</div>
            <Slider
              ref={slider => {
                this.slider = slider;
              }}
              {...sliderSettings}
            >
              {this.renderSlides()}
            </Slider>
            <div className={'featured-in__button'}>
              <Link to={'/newsroom/'} rel={'nofollow'}>
                <Button
                  order={'secondary'}
                  color={'white'}
                  ariaName={'Box link'}
                >
                  Visit our newsroom section
                </Button>
              </Link>
            </div>
          </Section>
        </WayPointHandler>
      </>
    );
  }
}

export default FeaturedIn;
