import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import LearnMore from './subcomponents/LearnMore/index';
import './AiSoftwareDevelopmentConsulting.scss';
import Banner from './subcomponents/Banner';
import DiscoverAI from './subcomponents/DiscoverAI';
import FaqSection from './subcomponents/FaqSection';
import PeopleBanner from './subcomponents/PeopleBanner';
import Contact from './subcomponents/Contact';
import Clutch from './subcomponents/Clutch';
import Slider from './subcomponents/Slider';
import Culture from './subcomponents/WorksSlider';
import ExceptionalValue from './subcomponents/ExceptionalValue';
import StepsSection from './subcomponents/StepsSection';
import ClientsSlider from '../designThatTellsStories/subcomponents/Testimonials/ClientsSlider';
import softwareDevelopmentClientLogo from './clientsLogos';

const propTypes = {
  allArticles: PropTypes.array.isRequired,
};

class AiSoftwareDevelopmentConsulting extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { allArticles } = this.props;
    const colorType = 'colorful';

    return (
      <div className={'single-page single-page--hero asdc'}>
        <Banner link={'asdc-discover'} />
        <DiscoverAI />
        <Culture />
        <PeopleBanner link={'contact'} />
        <StepsSection />
        <Clutch />
        <Slider />
        <ExceptionalValue />
        <ClientsSlider
          logos={softwareDevelopmentClientLogo}
          colorType={colorType}
          withHeader
        />
        <FaqSection />
        <LearnMore allArticles={allArticles} />
        <Contact />
      </div>
    );
  }
}

AiSoftwareDevelopmentConsulting.propTypes = propTypes;

export default AiSoftwareDevelopmentConsulting;
