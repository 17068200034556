import React from 'react';
import {
  SectionHeader,
  Section,
  Heading,
  UnorderedList,
  Paragraph,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';

const MeetTeam = () => (
  <Section color={'mine-shaft'} additionalClass={'machine-learning__team'}>
    <SectionHeader
      size={'big'}
      color={'white'}
      headingType={2}
      lineColor={'red'}
    >
      The Team
    </SectionHeader>
    <div
      className={
        'machine-learning__team-box machine-learning__team-box--first'
      }
    >
      <Heading size={'big'} type={3} color={'white'}>
        Skills
      </Heading>
      <Paragraph color={'white'} size={'medium'}>
        Cross-functional team (Solution Architects, ML Engineers, Frontend Dev,
        Backend Dev, DevOps, QAE, Designers) able to tackle all problems:
      </Paragraph>
      <UnorderedList
        additionalClass={'text--white'}
        columns={1}
        items={[
          'deep learning',
          'neural network',
          'decision trees',
          'spatial networks and spatial analysis',
          'API development',
          'system integrations',
          'Python development',
        ]}
      />
    </div>
    <img
      src={getCloudinaryUrl(
        'machine-learning/team-machine.png',
        'f_auto,q_auto',
      )}
      alt={'Machine Learning Team'}
      className={'image machine-learning__team-photo--first'}
      loading={'lazy'}
    />
    <div
      className={
        'machine-learning__team-box machine-learning__team-box--second'
      }
    >
      <Heading size={'big'} type={3} color={'white'}>
        Experience
      </Heading>
      <Paragraph color={'white'} size={'medium'}>
        Customer-oriented approach, consulting and business background earned
        at companies such as McKinsey and UBS, with experience in variety of
        industries including:
      </Paragraph>
      <UnorderedList
        additionalClass={'text--white'}
        columns={1}
        items={[
          'mining',
          'renewable energy',
          'governmental',
          'e-commerce',
          'digital products',
          'NGOs',
        ]}
      />
    </div>
    <img
      src={getCloudinaryUrl(
        'machine-learning/team-meeting.jpg',
        'f_auto,q_auto',
      )}
      alt={'Machine Learning Team meeting'}
      className={'image machine-learning__team-photo--second'}
      loading={'lazy'}
    />
  </Section>
);

export default MeetTeam;
