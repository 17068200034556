import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { NavBar } from 'components';
import WebinarGate from 'views/webinar';
import Webinar from 'views/webinar/Webinar';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import {
  banner,
  firstSection,
} from '../../views/resources/Webinar/accelerate-digitization-in-your-company-logic.js';
import config from '../../../meta/config';

const page = pages.webinar.items['accelerate-digitization-in-your-company'];
const url = `${config.siteUrl}/webinar/accelerate-digitization-in-your-company/`;
const canonical = `${url}`;

const AccelerateDigitizationInYourCompanyWebinarPage = () => {
  const [isGateOpen, setIsGateOpen] = useState(false);
  const pardotLink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('webinar-gate=open_id-er34d!ty23')
    ) {
      setIsGateOpen(true);
    }
  }, []);

  return (
    <Layout {...{ page, canonical, url }}>
      <Helmet />
      <NavBar
        color={isGateOpen ? ['white', 'mine-shaft'] : ['black', 'white']}
        bannerHidden
      />
      {isGateOpen ? (
        <Webinar {...{ banner, firstSection }} />
      ) : (
        <WebinarGate
          type={'Webinar with Sonnen'}
          title={
            'Accelerating digitization of products & services in you company'
          }
          lineColor={'yellow'}
          subtitle={'A case study of Sonnen & Boldare'}
          description={`Watch a recorded video of a webinar during which <b>Michael Konder, Vice President of Digital Solutions at Sonnen</b> discussed how to boost your business results in 2021 by digitizing your products and services.<br /><br />You will find out how a German company in the renewable energy sector has successfully gone through a digital transformation.`}
          speakers={[
            {
              avatar: 'img/webinar-sonnen/Michael_Landing_Avatar.jpg',
              position: 'Vice President of Digital Solutions at Sonnen',
              name: 'Michael Konder',
            },
            {
              avatar: 'img/webinar-sonnen/Artur_Landing_Avatar.jpg',
              position: 'Customer Success at Boldare',
              name: 'Artur Belka',
            },
          ]}
          listTitle={`You will learn:`}
          learnItems={[
            `how to structure, plan and carry out the digitization of your products and services, `,
            `how to transform internal processes to keep up with change, `,
            `the role of an external agile software partner in digital transformation - how to get the maximum ROI from such a collaboration.`,
          ]}
          formHeading={`Sign up and get a free access to the video`}
          btnColor={'yellow'}
          button={'Sign up'}
          url={pardotLink}
          trackingEvent={`SonnenWebinar`}
          gateAddress={'webinar-gate=open_id-er34d!ty23'}
          gateCatalog={'webinar'}
        />
      )}
    </Layout>
  );
};

export default AccelerateDigitizationInYourCompanyWebinarPage;
