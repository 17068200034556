import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section } from 'components';
import { ServiceTile } from '..';

const OtherServices = () => (
  <Section additionalClass={'fpdc__service-tile'} fullwidth spaceless>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Development <br />Teams'}
          description={
            'Boldare’s skilled development teams can help you reach your business goals and guide you through the lean startup approach.'
          }
          textColor={'white'}
          backgroundColor={'mine-shaft'}
          link={'/services/development-teams/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Digital <br />Transformation'}
          description={
            'Whether you’re looking to disrupt your department or entire industry Boldare teams will show you how you can make it happen.'
          }
          backgroundColor={'red'}
          link={'/services/digital-transformation/'}
        />
      </Col>
    </Row>
    <Row className={'no-gap'}>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'AI Services'}
          description={
            'Together with Boldare’s experts discover how AI services can be tailored to address challenges across various industries, simplifying problem-solving and enhancing efficiency.'
          }
          backgroundColor={'gray-light'}
          link={'/services/ai-software-development-consulting/'}
          alignedLeft
        />
      </Col>
      <Col sm={12} md={6}>
        <ServiceTile
          title={'Product Design <br />and Development'}
          description={
            'Boldare’s experienced specialists will look after every aspect of your product, from design and development, to DevOps, tech stack consulting and usability testing.'
          }
          backgroundColor={'pigeon-post'}
          link={'/services/product-design-and-development/'}
        />
      </Col>
    </Row>
  </Section>
);

export default OtherServices;
