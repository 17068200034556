export const data = [
  {
    title: 'Easy Facebook login',
    text:
      'Registration and updates of data are simple and allows downloading data and photos from Facebook',
    image: 'bubl-1.jpg',
  },
  {
    title: 'Create or edit your profile in a second',
    text: 'Accounts easy to set and manage',
    image: 'tablet.png',
  },
  {
    title: 'Create professional photos',
    text: 'Our guidebook helps you to create good looking photos',
    image: 'bubl-2.jpg',
  },
  {
    title: 'Find casting and become model quickly',
    text:
      'The casting list allows you to find the castings which are the best for you',
    image: 'bubl-3.jpg',
  },
];
