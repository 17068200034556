/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Button, Checkbox, Paragraph, Heading } from 'components';
import { generateHash, classNames } from '../../utils';
import { events } from './logic.js';
import './EventForm.scss';

class EventForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isThankYouPage: false,
      form: {
        name: '',
        lastName: '',
        company: '',
        jobTitle: '',
        email: '',
        optedForMarketing: false,
        personalDataAgree: false,
      },
    };
  }

  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      this.setState({ isThankYouPage: true });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        personalDataAgree: !prevState.form.personalDataAgree,
      },
    }));
  };

  handleMarketingCheckboxChange = () => {
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        optedForMarketing: !prevState.form.optedForMarketing,
      },
    }));
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const { form } = this.state;
    const { url } = this.props;
    const body = Object.keys(form)
      .map(key => `${key}=${form[key]}`)
      .join('&');

    this.setState({ isLoading: true });

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you&connectionId=${generateHash()}`;
        }
      })
      .catch(error => console.log(error));
  };

  checkForms(filter) {
    const key = 'eventForm';

    this.setState(prevState => {
      return {
        form: {
          ...prevState.form,
          [key]: [filter],
        },
      };
    });
  }

  renderForms(data) {
    const { form } = this.state;
    const eventArray = form.eventForm;

    const filters = data.map(filter => (
      <li
        key={`filter-${filter.filterName}`}
        className={`event-form__filters-item ${
          eventArray.includes(filter.filterName)
            ? 'event-form__filters-item--active'
            : ''
        }`}
        onClick={() => {
          this.checkForms(filter.filterName);
        }}
      >
        <div className={'event-form__filters-item-content'}>
          <span className={'event-form__filters-item-heading'}>
            {filter.filterName}
          </span>
          <span className={'event-form__filters-checkbox'}>
            <span className={'event-form__filters-icon'} />
          </span>
        </div>
      </li>
    ));

    return <ul className={'event-form__filters-list'}>{filters}</ul>;
  }

  render() {
    const {
      button,
      formHeading,
      btnColor,
      thanksDescription,
      checkboxLabelWhiteColor,
    } = this.props;

    const { isThankYouPage, isLoading, form } = this.state;

    return (
      <>
        <div className={'event-form'}>
          {isThankYouPage ? (
            <div className={'event-form--sent'}>
              <Heading
                size={'medium-plus'}
                additionalClass={'text--default'}
                type={3}
              >
                Thank you!
                <br />
                <br />
                {thanksDescription ||
                  'You have sucessfully subscribed to the event! See you soon!'}
              </Heading>
            </div>
          ) : (
            <>
              <Heading
                size={'medium'}
                type={2}
                additionalClass={'event-form__headline'}
              >
                {ReactHtmlParser(formHeading)}
              </Heading>
              <form
                className={classNames('event-form__inputs', {
                  'event-form__inputs--hidden': isThankYouPage,
                })}
                method={'post'}
                onSubmit={this.handleSubmitForm}
              >
                <input
                  type={'text'}
                  name={'name'}
                  placeholder={'First name'}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type={'text'}
                  name={'lastName'}
                  placeholder={'Last name'}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type={'text'}
                  name={'company'}
                  placeholder={'Company name'}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type={'text'}
                  name={'jobTitle'}
                  placeholder={'Job title'}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type={'email'}
                  name={'email'}
                  placeholder={'Business e-mail address'}
                  onChange={this.handleChange}
                  required
                />
                {/* <div className={classNames('event-form__filters')}>
                  <span className={'event-form__filters-head'}>
                    How will you attend?
                  </span>
                  {this.renderForms(eventsData.events)}
                </div> */}

                <Checkbox
                  additionalClass={`event-form__checkbox ${
                    checkboxLabelWhiteColor ? '' : 'black-label'
                  }`}
                  checked={form.personalDataAgree}
                  label={
                    ' I agree to the processing of my personal data in accordance with Boldare’s Privacy Policy.'
                  }
                  name={'personalDataAgree'}
                  value={'personalDataAgree'}
                  onChange={this.handleCheckboxChange}
                  required
                />
                <Checkbox
                  additionalClass={`event-form__checkbox ${
                    checkboxLabelWhiteColor ? '' : 'black-label'
                  }`}
                  checked={form.optedForMarketing}
                  label={
                    ' I agree to receiving communications from Boldare, including updates on events, business insights, and promotional materials about our services.'
                  }
                  name={'optedForMarketing'}
                  value={'optedForMarketing'}
                  onChange={this.handleMarketingCheckboxChange}
                />
                <Button
                  id={`event-form-submit`}
                  type={'submit'}
                  color={btnColor}
                  additionalClass={classNames('event-form__button', {
                    loading: isLoading,
                  })}
                  ariaName={'Invite button'}
                  disabled={isLoading}
                  loading
                >
                  {button} &rarr;
                </Button>
              </form>
              <Paragraph additionalClass={'event-form__info text--light'}>
                <span className={'text--bold'}>
                  Your data is safe with us.
                </span>{' '}
                Boldare may use your personal information for internal business
                and marketing purposes, such as event registration or to
                contact you regarding your project and other topics of
                interest. The data will be hosted with Salesforce’s CRM system
                in accordance with our Privacy Policy. The information will not
                be sold or transferred to any third party. You may unsubscribe
                at any time. For more information on how to unsubscribe and our
                privacy practices please review our &nbsp;
                <a href={'/privacy-policy/'} className={'link'}>
                  Privacy Policy
                </a>
                .
              </Paragraph>
            </>
          )}
        </div>
      </>
    );
  }
}

EventForm.propTypes = {
  button: PropTypes.string,
  btnColor: PropTypes.string,
  formHeading: PropTypes.string,
  url: PropTypes.string.isRequired,
  eventsData: PropTypes.object,
  thanksDescription: PropTypes.string,
  checkboxLabelWhiteColor: PropTypes.bool,
};

EventForm.defaultProps = {
  button: 'Sign in',
  btnColor: 'white',
  formHeading: 'Join us!',
  thanksDescription: '',
  eventsData: {
    events,
  },
  checkboxLabelWhiteColor: 'true',
};

export default EventForm;
