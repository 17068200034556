import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import * as Scroll from 'react-scroll';
import {
  Section,
  Heading,
  Paragraph,
  Link,
  SliderArrow,
  Button,
  Icon,
  IconSVG,
  Container,
} from 'components';
import './awwwards-slider.scss';
import { Col, Row } from 'react-flexbox-grid';
import Slider from 'react-slick';
import { ICON_TYPES } from 'config';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import MobileSlides from './mobileSlider';

class Slides extends Component {
  static sliderSettings = {
    className: 'wdm__awards-slider',
    speed: 1000,
    arrows: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: (
      <SliderArrow id={'wdm__slider-next'} color={'black'} defaultClasses />
    ),
    prevArrow: (
      <SliderArrow
        id={'wdm__slider-prev'}
        color={'black'}
        defaultClasses
        left
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      slideExpanded: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  handleExpandClick = () => {
    this.setState({ slideExpanded: true });
    setTimeout(forceCheck, 1);
  };

  handleCloseSlide = index => {
    this.setState({ slideExpanded: false, currentSlide: index });
  };

  renderNavigation = () =>
    data.map((item, index) => {
      const { color, name } = item;

      return (
        <li
          id={`awwwards-slider-navi-${index}`}
          className={classNames('showcase-navi__item', {
            [`showcase-navi__item--${color}`]: color,
            active: this.state.currentSlide === index,
          })}
          onClick={() => this.handleCloseSlide(index)}
        >
          <p className={'showcase-navi__title'}>{ReactHtmlParser(name)}</p>
        </li>
      );
    });

  renderSlides = () =>
    data.map(item => {
      const {
        id,
        name,
        tags,
        cover,
        photo01,
        photo02,
        photo03,
        alt01,
        alt02,
        alt03,
        flag,
        color,
        description,
        caseStudyName,
        caseStudyLink,
        indigoAward,
        awwwards,
      } = item;
      const { isMobile, currentSlide, slideExpanded } = this.state;

      return (
        <StaticQuery
          query={graphql`
            query {
              # Icons
              Awwwards: file(
                relativePath: { eq: "awards/logo-awwwards.png" }
              ) {
                childImageSharp {
                  fixed(height: 15) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              Indigo: file(relativePath: { eq: "awards/logo-indigo.png" }) {
                childImageSharp {
                  fixed(width: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          `}
          render={loadedData => (
            <>
              {isMobile ? (
                <div
                  className={classNames(
                    'slide__container',
                    'slide__container--mobile',
                    {
                      active: currentSlide + 1 == id,
                      off: currentSlide + 1 != id,
                    },
                  )}
                >
                  <div className={'coverslide__wrapper'}>
                    <img
                      src={getCloudinaryUrl(cover, 'f_auto,q_auto,w_1440')}
                      className={'image coverslide__photo'}
                      style={{
                        objectPosition: 'top left',
                      }}
                      loading={'lazy'}
                    />
                    <div className={'coverslide__details'}>
                      <h3 className={'coverslide__title'}>{name}</h3>
                      <p className={'coverslide__tags'}>{tags}</p>
                      <Button
                        id={`expand-portfolio--${id}`}
                        color={'white'}
                        transparent
                        onClick={() => this.handleExpandClick(id)}
                        ariaName={'See more button'}
                      >
                        See more
                      </Button>
                    </div>
                  </div>
                  <div
                    className={classNames('expanded__wrapper', {
                      [`expanded__wrapper--${id}`]: id,
                      active: slideExpanded == true,
                      off: slideExpanded == false,
                    })}
                  >
                    <div className={'expanded__wrapper--scroll'}>
                      <div className={'expanded__description-container'}>
                        <span
                          className={classNames('expanded__id', {
                            [`expanded__id--${color}`]: color,
                          })}
                        >
                          0{id}.
                        </span>
                        <h3 className={'expanded__title anim-fade-in'}>
                          {name}
                        </h3>
                        <MobileSlides photos={[photo01, photo02, photo03]} />
                        <Paragraph
                          additionalClass={'expanded__text text--light'}
                        >
                          {description}
                        </Paragraph>
                      </div>
                      <div
                        className={
                          'slide-arrow expanded__go-back wdm__awards-slider'
                        }
                        role={'presentation'}
                        id={id}
                        onClick={() => this.handleCloseSlide(id - 1)}
                      >
                        <IconSVG name={'arrow-left'} color={'black'} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={classNames('slide__container', {
                    active: currentSlide + 1 == id,
                    off: currentSlide + 1 != id,
                  })}
                >
                  <div className={'coverslide__wrapper'}>
                    <img
                      src={getCloudinaryUrl(cover, 'f_auto,q_auto,w_1440')}
                      className={'image coverslide__photo'}
                      style={{
                        objectPosition: 'top left',
                      }}
                      loading={'lazy'}
                    />
                    <div className={'coverslide__details'}>
                      <img
                        src={getCloudinaryUrl(flag, 'w_20,h_10')}
                        alt={'Country'}
                        className={'image expanded__media-flag'}
                        loading={'lazy'}
                      />
                      <h3 className={'coverslide__title'}>{name}</h3>
                      <p className={'coverslide__tags'}>{tags}</p>
                      <Button
                        id={`expand-portfolio--${id}`}
                        color={'white'}
                        transparent
                        onClick={() => this.handleExpandClick(id)}
                        ariaName={'See more button'}
                      >
                        See more
                      </Button>
                    </div>
                  </div>
                  <div
                    className={classNames('expanded__wrapper', {
                      [`expanded__wrapper--${id}`]: id,
                      active: slideExpanded == true,
                      off: slideExpanded == false,
                    })}
                  >
                    <div className={'expanded__wrapper--scroll'}>
                      <Slider
                        ref={slider => {
                          this.slider = slider;
                        }}
                        {...this.constructor.sliderSettings}
                      >
                        <div className={'expanded__description-container'}>
                          <span
                            className={classNames('expanded__id', {
                              [`expanded__id--${color}`]: color,
                            })}
                          >
                            0{id}.
                          </span>
                          <h3 className={'expanded__title anim-fade-in'}>
                            {name}
                          </h3>
                          <div className={'expanded__text'}>
                            <p>{description}</p>
                            {caseStudyLink && (
                              <div className={'expanded__info'}>
                                Read the full case study:&nbsp;
                                <Link
                                  to={caseStudyLink}
                                  className={classNames(
                                    'expanded__cs-link',
                                    'text--bold',
                                    {
                                      [`u-color--${color}`]: color,
                                    },
                                  )}
                                  id={`case-study-${name}`}
                                >
                                  <span
                                    id={`why-design-matters__${caseStudyName}`}
                                  >
                                    {caseStudyName}
                                  </span>
                                </Link>
                              </div>
                            )}
                            {/*  TODO: Issue with graphql query
                            <div className={'expanded__cs-awwwards'}>
                              {awwwards && (
                                <div className={'expanded__info'}>
                                  <Link to={awwwards} id={`awwwards-${name}`}>
                                    <img
                                      src={
                                        loadedData.Awwwards.childImageSharp
                                          .fixed
                                      }
                                      className={'image'}
                                      loading={'lazy'}
                                    />
                                  </Link>
                                </div>
                              )}
                              {indigoAward && (
                                <div className={'expanded__info'}>
                                  <Link
                                    to={indigoAward}
                                    id={`indigo-award-${name}`}
                                  >
                                    <img
                                      src={
                                        loadedData.Indigo.childImageSharp.fixed
                                      }
                                      className={'image'}
                                      loading={'lazy'}
                                    />
                                  </Link>
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                        <img
                          src={getCloudinaryUrl(
                            photo01,
                            'f_auto,q_auto,h_600',
                          )}
                          alt={alt01}
                          className={'image expanded__media-item'}
                          loading={'lazy'}
                        />
                        <img
                          src={getCloudinaryUrl(
                            photo02,
                            'f_auto,q_auto,h_514',
                          )}
                          alt={alt02}
                          className={
                            'image expanded__media-item expanded__media-item--smaller'
                          }
                          loading={'lazy'}
                        />
                        <img
                          src={getCloudinaryUrl(
                            photo03,
                            'f_auto,q_auto,h_600',
                          )}
                          alt={alt03}
                          className={'image expanded__media-item'}
                          loading={'lazy'}
                        />
                      </Slider>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        />
      );
    });

  render() {
    const { currentSlide } = this.state;

    return (
      <div>
        <div
          className={classNames('slider__slides', [
            `slide-${currentSlide + 1}`,
          ])}
        >
          {this.renderSlides()}
        </div>
        <div className={'showcase-scroll'}>
          <ul className={'showcase-navi'}>{this.renderNavigation()}</ul>
        </div>
      </div>
    );
  }
}

class AwwwardsSlider extends Component {
  render() {
    const SpyLink = Scroll.Link;

    return (
      <Section additionalClass={'showcase showcase--no-padding'} fullwidth>
        <Container>
          <Row>
            <Col sm={12} md={4}>
              <Heading
                type={2}
                size={'large'}
                additionalClass={'showcase__title'}
              >
                5 great Boldare designs
              </Heading>
            </Col>
            <Col sm={12} md={6} mdOffset={2}>
              <p className={'showcase__text paragraph--regular text--white'}>
                The following are just five examples of Boldare’s commercial
                design work for clients around the world. From
                culture-expanding radio to chartering luxury yachts to a
                virtual place setting app for choosing tableware, no matter the
                function, we firmly believe design is crucial to a successful
                app. Here are five examples of excellent Boldare design we’ve
                produced for our clients, and the technologies we used.
              </p>
              <SpyLink
                id={'awwwards-slider-see-examples'}
                activeClass={'active'}
                to={'awwwards-slides'}
                spy
                smooth
                offset={0}
                duration={500}
                className={'showcase__go-next'}
              >
                <Paragraph color={'white'} additionalClass={'text--bold'}>
                  See examples
                </Paragraph>
                <Icon
                  icon={ICON_TYPES.RIGHT_ARROW}
                  size={'medium'}
                  color={'white'}
                />
              </SpyLink>
            </Col>
          </Row>
        </Container>
        <Section
          id={'awwwards-slides'}
          fullwidth
          additionalClass={'showcase--no-padding'}
        >
          <Slides />
        </Section>
      </Section>
    );
  }
}

export default AwwwardsSlider;
