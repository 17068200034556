import React from 'react';
import PropTypes from 'prop-types';

import './Link.scss';

const Link = ({ href }) => (
  <a href={href} className={'work-boxes-link'}>
    <span className={'work-boxes-link__text'}>{href}</span>
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Link;
