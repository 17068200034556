import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components';
import { useWindowWidth } from 'hooks';
import { content } from './logic';

import './Phase.scss';
import ClientsSlideshow from '../ClientsSlideshow/ClientsSlideshow';

const renderTableRow = (heading, value) => (
  <div className={'app-cost-result-phase__table-row'}>
    <div className={'app-cost-result-phase__table-heading'}>{heading}</div>
    <div className={'app-cost-result-phase__table-value'}>{value}</div>
  </div>
);

export const Phase = ({ activePhase }) => {
  const textContent = content[activePhase];
  const isMinimumDesktopSmall = useWindowWidth() >= 1024;

  const scrollToVideo = () => {
    const offset = 100;
    const videoElement = document.querySelector('.youtube-player__container');
    if (videoElement) {
      const topPos =
        videoElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPos - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={'app-cost-result-phase'}>
      <Container>
        <p className={'app-cost-result-phase__header'}>{textContent.header}</p>
        <p className={'app-cost-result-phase__description'}>
          {textContent.description}
        </p>
        <button
          className={'app-cost-result-phase__button-learn-more'}
          onClick={scrollToVideo}
        >
          Learn more
        </button>
        <div className={'app-cost-result-phase__table'}>
          {textContent.table.map(row =>
            renderTableRow(row.heading, row.value),
          )}
        </div>
        <div className={'app-cost-result-phase__table-row-description'}>
          <div className={'app-cost-result-phase__table-value-description'}>
            All estimates are approximate, but they will give you a good idea
            of what it will take to build your product.
          </div>
        </div>
        {isMinimumDesktopSmall && (
          <div className={'app-cost-result-phase__client-slideshow'}>
            <ClientsSlideshow />
          </div>
        )}
      </Container>
    </div>
  );
};

Phase.propTypes = {
  activePhase: PropTypes.string,
};

Phase.defaultProps = {
  activePhase: '',
};
