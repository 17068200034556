import React from 'react';
import * as Scroll from 'react-scroll';
import { Section, SectionHeader, Button } from 'components';
import './Contact.scss';

const SpyLink = Scroll.Link;

const Contact = ({ title, id, cta, link }) => (
  <Section color={'yellow'} spaceless>
    <div className={'mvp__contact-us'}>
      <SectionHeader
        additionalClass={'headline--centered'}
        color={'mine-shaft'}
        size={'medium'}
        lineColor={'yellow'}
        headingType={2}
      >
        {title}
      </SectionHeader>
      <SpyLink
        id={id}
        to={link}
        spy={false}
        smooth
        duration={500}
        className={'link link--centered'}
      >
        <Button
          id={'mvp-contact-btn'}
          color={'transparent-mine-shaft'}
          ariaName={'Contact button'}
        >
          {cta} &#8595;
        </Button>
      </SpyLink>
    </div>
  </Section>
);

export default Contact;
