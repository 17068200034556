import React from 'react';
import { isEmpty } from 'lodash';
import { Section, SectionHeader, RelatedArticles } from 'components';
import './LearnMore.scss';

const LearnMore = ({ allArticles, related }) => (
  <>
    <Section color={'white'} additionalClass={'ydp__learn-more'}>
      <SectionHeader size={'big'} lineColor={'pigeon-post'} fixed>
        Related articles
      </SectionHeader>
    </Section>
    {!(isEmpty(allArticles) || isEmpty(related)) && (
      <RelatedArticles
        id={'ydp__learn-more-articles'}
        related={related}
        background={'white'}
        allArticles={allArticles}
        sectionTitle={false}
        fixed
        bulletColor={'black'}
      />
    )}
  </>
);

export default LearnMore;
