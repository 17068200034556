import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';

import './PageDescriptionHeader.scss';

const PageDescriptionHeader = ({
  image,
  imageDescription,
  description,
  logo,
  logoLabel,
}) => {
  return (
    <div className={'page-description-header'}>
      <div className={'page-description-header__item'}>
        <img
          src={getCloudinaryUrl(image, 'w_auto')}
          className={'image page-description-header__image'}
          alt={'Agile Producl Builders Community Newsletter'}
          loading={'lazy'}
        />
        <span className={'page-description-header__text'}>
          {imageDescription}
        </span>
      </div>
      <div className={'page-description-header__item'}>
        <span className={'page-description-header__description'}>
          {description}
        </span>
        <span className={'page-description-header__logo-label'}>
          {logoLabel}
        </span>
        <img
          src={getCloudinaryUrl(logo, 'w_auto')}
          className={'page-description-header__logo'}
          alt={'Agile Producl Builders Community Newsletter'}
          loading={'lazy'}
        />
      </div>
    </div>
  );
};
export default PageDescriptionHeader;

PageDescriptionHeader.propTypes = {
  image: PropTypes.string.isRequired,
  imageDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoLabel: PropTypes.string.isRequired,
};
