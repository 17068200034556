import React, { useState } from 'react';
import {
  motion,
  useTransform,
  useViewportScroll,
  AnimatePresence,
} from 'framer-motion';
import { ReactComponent as ArrowTopRight } from '../../assets/icons/arrow_top_right.svg';

const MowaamahSection = ({ animationEnd, animationShow, animationStart }) => {
  const { scrollY } = useViewportScroll();
  const clipPath = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['circle(18% at 100% 50%)', 'circle(100% at 50% 50%)'],
  );
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  scrollY.onChange(progress => {
    if (isSectionVisible && progress < animationShow) {
      setIsSectionVisible(false);
    } else if (!isSectionVisible && progress >= animationShow) {
      setIsSectionVisible(true);
    }
  });

  return (
    <AnimatePresence>
      {isSectionVisible && (
        <motion.div
          exit={{ x: '18%' }}
          style={{ clipPath }}
          animate={{ x: '0%' }}
          initial={{ x: '18%' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className={'dsgn-cases__section dsgn-cases__section--mowaamah'}
        >
          <div
            className={
              'dsgn-cases__section-content dsgn-cases__section-content--mowaamah'
            }
          >
            <div className={'dsgn-cases__tagline'}>
              <p>
                accessible design | inclusive design | MVP
              </p>
            </div>
            <div className={'dsgn-cases__company'}>
              <span className={'dsgn-cases__company-name'}>Mowaamah</span>
              <span className={'dsgn-cases__company-spacer'}>|</span>
              <span className={'dsgn-cases__company-country'}>
                Saudi Arabia
              </span>
            </div>
          </div>
          <div className={'dsgn-cases__section-back'}>
            <div
              className={
                'dsgn-cases__description dsgn-cases__description--inverted'
              }
            >
              <div className={'dsgn-cases__description-content'}>
                <p>
                  We developed Mowaamah, an app designed to assist individuals
                  with disabilities. It offers features like live video calls
                  with volunteers or sign language interpreters and helps users
                  find disability-friendly offers and locales in their cities.
                </p>
              </div>
              <div className={'dsgn-cases__description-cta'}>
                <a
                  href={
                    'https://www.boldare.com/work/building-accessible-app/'
                  }
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <span>Read the story <ArrowTopRight /></span>
                </a>
              </div>
            </div>
            <div className={'dsgn-cases__img'}>
              <div className={'dsgn-cases__img-content bldr-dsgn__description-image'}>
                <div className={'bldr-dsgn__description-image--mobile'}>
                  <img
                    src={
                      'https://res.cloudinary.com/de4rvmslk/image/upload/v1699884870/bdbb/moamah-phones-mobile.png'
                    }
                    alt={'Mowaamah'}
                  />
                </div>
                <div className={'bldr-dsgn__description-image--desktop'}>
                  <img
                    src={
                      'https://res.cloudinary.com/de4rvmslk/image/upload/v1699525076/bdbb/moamah-phones.png'
                    }
                    alt={'Mowaamah'}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MowaamahSection;
