import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';

import './Dot.scss';

const Dot = ({ isActive, handleClick }) => (
  <button
    onClick={handleClick}
    disabled={isActive}
    className={classNames('slider-dot', {
      'slider-dot--active': isActive,
    })}
    label={'dot'}
  >
    <span className={'slider-dot__line'} />
  </button>
);

Dot.defaultProps = {
  isActive: false,
  handleClick: () => {},
};

Dot.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Dot;
