export const sliderData = [
  {
    class: 'sonnen',
    name: 'sonnen – digital transformation',
    content:
      'sonnen ist ein führendes Unternehmen im Bereich der erneuerbaren und umweltschonenden Energien. Angesichts des schnellen Wachstums vergrößerte sich die Nutzergemeinschaft von sonnen, wobei der Bedarf erkannt wurde, die digitalen Geschäftsprozesse des Unternehmens zu straffen und zu integrieren. Infolgedessen musste sonnen seine Software-Entwicklungsabteilung von 40 auf mehr als 100 Mitarbeiter aufstocken.',
    imgBack: 'de/digitale-transformation/case-study-sonnen',
    imgFront: 'de/digitale-transformation/case-study-sonnen-creation',
    link: 'https://boldare.com/assets/uploads/de/Sonnen_Digital_Trans_DE.pdf',
  },
  {
    class: 'oneida',
    name: 'Oneida',
    content:
      'Oneida ist ein weltweiter Hersteller von Designer-Edelstahlbesteck und -geschirr für die Gastronomie sowie für Privatkunden. Unsere Aufgabe lag darin eine Single-Page-Webanwendung zur Präsentation der Produkte von Oneida zu entwickeln.',
    imgBack: 'de/digitale-transformation/case-study-oneida',
    imgFront: 'de/digitale-transformation/case-study-oneida-creation',
    link: 'https://boldare.com/assets/uploads/de/Oneida_DE.pdf',
  },
  {
    class: 'sonnen',
    name: 'sonnen – customer portal',
    content:
      'Mit der sonnenBatterie können Nutzer ihren eigenen Strom produzieren, speichern und innerhalb der sonnenCommunity teilen. Das zu entwickelnde Produkt war ein Kundenportal, mit dem die Nutzer von sonnen alle Aspekte der Batterienutzung überwachen können.',
    imgBack: 'de/digitale-transformation/case-study-sonnen',
    imgFront: 'de/digitale-transformation/case-study-sonnen-creation',
    link:
      'https://boldare.com/assets/uploads/de/Sonnen_Customer_Portal_DE.pdf',
  },
  {
    class: 'blablacar',
    name: 'BlaBlacar',
    content:
      'BlaBlaCar - eine digitale Plattform für Mitfahrgelegenheiten. Unsere Aufgabe lag darin eine Erweiterung und kundenspezifische Anpassung der bestehenden Website und mobilen App in zahlreichen Märkten parallel zu entwickeln.',
    imgBack: 'de/digitale-transformation/case-study-blablacar',
    imgFront: 'de/digitale-transformation/case-study-blablacar-creation',
    link: 'https://boldare.com/assets/uploads/de/BlaBlaCar_DE.pdf',
  },
  {
    class: 'eres',
    name: 'ERES Yachting',
    content:
      'ERES Yachting, Online-Plattform zur Buchung von Yachten. ERES hatte das Anliegen, seinen Buchungsprozess online zu betreiben, indem eine maßgeschneiderte Plattform eingerichtet werden sollte, die den Nutzern eine Auswahl von mehr als 13.000 Luxusyachten an fast 500 Destinationen weltweit zur Verfügung stellt.',
    imgBack: 'de/digitale-transformation/case-study-eres',
    imgFront: 'de/digitale-transformation/case-study-eres-creation',
    link: 'https://boldare.com/assets/uploads/de/ERES_DE.pdf',
  },
  {
    class: 'sonnen',
    name: 'sonnen – charger',
    content:
      'sonnen ist ein führendes Unternehmen im Bereich der erneuerbaren und umweltschonenden Energien. Das Aushängeschild des Unternehmens war die sonnenBatterie, die es den Nutzern ermöglicht, Strom zu erzeugen, zu speichern und innerhalb der sonnenCommunity zu teilen. Die sonnenCharger-App sollte es denselben Nutzern ermöglichen, ihre Fahrzeuge mit umweltschonender, von der Community erzeugter Energie aufzuladen. Die größte Herausforderung bestand darin, die App an die Hardware des Ladegeräts anzupassen.',
    imgBack: 'de/digitale-transformation/case-study-sonnen',
    imgFront: 'de/digitale-transformation/case-study-sonnen-creation',
    link: 'https://boldare.com/assets/uploads/de/Sonnen_Charger_DE.pdf',
  },
  {
    class: 'pso',
    name: 'PSO',
    content:
      'Polskie Składy Oponiarskie (PSO), einer der größten Großhändler für Fahrzeugreifen in Polen, einschließlich fortschrittlicher Online-Lösungen für Reifengroßhändler und Werkstätten. Eine neue, aktualisierte Version der B2B- und B2C-E-Commerce-Verkaufsplattform von PSO für die Reifenindustrie, einschließlich einer neuen Web-App, aktualisiertem Design und neuen Funktionalitäten.',
    imgBack: 'de/digitale-transformation/case-study-pso',
    link: 'https://boldare.com/assets/uploads/de/PSO_DE.pdf',
  },
  {
    class: 'agnitio',
    name: 'Agnitio',
    content:
      'Agnitio ist ein dänisches Unternehmen, das Marketing- und Vertriebstechnologien für Life-Science-Unternehmen bereitstellt. Agnitio beauftragte Boldare ein besseres Verständnis für die Bedürfnisse des Kunden und designspezifische Lösungen zu entwickeln. Darüber hinaus wurde die Gesamtarchitektur verbessert , den Informationsfluss zu entwerfen, Szenarien für die Integration der datenaustauschenden Anwendungen des Systems zu planen, zu implementieren, testen und bereitzustellen.',
    imgBack: 'de/digitale-transformation/case-study-agnitio',
    link: 'https://boldare.com/assets/uploads/de/Agnitio_DE.pdf',
  },
];
