import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';

import './Satelite.scss';

export const Satelite = ({ theme, sateliteColor }) => (
  <>
    <div className={'satelite'}>
      <img
        src={getCloudinaryUrl(
          `design-rush/satelite-${sateliteColor}.png`,
          'f_auto,q_auto,w_auto'
        )}
        alt={'satelite'}
        loading={'lazy'}
      />
    </div>
    <div className={'satelite__orbit-container'}>
      <div className={`satelite__orbit satelite__orbit--${theme}`}>
        <img
          src={getCloudinaryUrl(
            'design-rush/orbit.svg',
            'f_auto,q_auto,w_auto'
          )}
          alt={'Orbit'}
          loading={'lazy'}
        />
      </div>
      <div className={'satelite__moon-container'}>
        <div className={'satelite__moon'} />
      </div>
    </div>
  </>
)

Satelite.defaultProps = {
  theme: 'dark',
  sateliteColor: 'red'
};

Satelite.propTypes = {
  theme: PropTypes.string,
  sateliteColor: PropTypes.string,
};
