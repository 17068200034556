import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Row, Col } from 'react-flexbox-grid';
import { Section, SectionHeader, Paragraph, Button, PopUp } from 'components';
import './Solution.scss';

class Solution extends Component {
  constructor() {
    super();

    this.state = {
      visibility: false,
    };
  }

  handleData = data => {
    this.showPopUp(data);
  };

  showPopUp = (visibility = true) => {
    this.setState({ visibility });
  };

  render() {
    const { visibility } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            image: file(relativePath: { eq: "sysdt/solution2.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Section additionalClass={'sysdt__solution'} color={'white'}>
              <SectionHeader
                color={'black'}
                headingType={2}
                lineColor={'pigeon-post'}
                size={'big'}
              >
                Solution
              </SectionHeader>
            </Section>
            <Section
              additionalClass={'sysdt__solution-description'}
              color={'pigeon-blue'}
              fullwidth
              side={'right'}
              spaceless
            >
              <Row className={'no-gap anim-fade-in-bottom'}>
                <Col
                  sm={12}
                  md={6}
                  lg={4}
                  className={'sysdt__solution-container'}
                >
                  <div
                    className={
                      'section w-container--right sysdt__solution-inner'
                    }
                  >
                    <SectionHeader
                      headingType={3}
                      lineColor={'black'}
                      size={'small'}
                    >
                      Digital Transformation through practice
                    </SectionHeader>
                    <Paragraph
                      additionalClass={'sysdt__paragraph text--light'}
                      spaced
                    >
                      Digitalization is not only about releasing new digital
                      products or services. Here, at Boldare, we understand
                      that transformation should be done bottom-up, across
                      organizational structures. Systemic Digital
                      Transformation is focused on delivering great digital
                      products and teaching your organization how to create new
                      ones effectively, with a lean startup and agile approach
                      and scrum toolset. At the end of the day, you gain
                      top-notch digital products and digital-ready mindset.
                    </Paragraph>
                    <Button
                      color={'black'}
                      onClick={this.showPopUp}
                      id={'sysdt-registration-button--Solution'}
                      ariaName={'Sign up button'}
                      transparent
                    >
                      Sign up for webinar
                    </Button>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={8}>
                  <div className={'sysdt__solution-image'}>
                    <img
                      className={'image'}
                      alt={'Solution'}
                      src={data.image.childImageSharp.fluid}
                      loading={'lazy'}
                    />
                  </div>
                </Col>
              </Row>
            </Section>

            <PopUp
              visible={visibility}
              id={'sysdt-registration-popup--Solution'}
              visibilityHandler={this.handleData}
              additionalClass={'sysdt__popup'}
            >
              <iframe
                frameBorder={'0'}
                src={
                  'https://app.livestorm.co/p/a70f6293-c046-4048-9677-19e9fcca328d/form'
                }
              />
            </PopUp>
          </>
        )}
      />
    );
  }
}

export default Solution;
