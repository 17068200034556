import React from 'react';
import PropTypes from 'prop-types';
import StickyBox from 'react-sticky-box';
import { Row, Col } from 'react-flexbox-grid';
import { Section, SectionHeader, Paragraph, ResourceList } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import companyData from '../../../../../meta/companyData';
import './Benefits.scss';

const Benefits = () => (
  <Section additionalClass={'code-audit__benefits'} fullwidth spaceless>
    <Row className={'no-gap'}>
      <Col md={12} lg={6} className={'audit-benefits__content'}>
        <div className={'w-container--left'}>
          <SectionHeader
            headingType={2}
            size={'large'}
            additionalClass={'audit-benefits__heading'}
            lineColor={'red'}
          >
            How would you benefit from auditing with Boldare?
          </SectionHeader>
          <Paragraph
            size={'medium'}
            additionalClass={'text--default audit-benefits__description'}
          >
            Boldare has more than {companyData.years_experience} years of
            experience in building design-driven digital products. Our
            engineers and designers have a strong focus on the business
            perspective of the products they help develop. Thanks to that, they
            conduct comprehensive code and UX audits, and you gain:
          </Paragraph>
          <ResourceList
            items={[
              `A custom code audit and UX audit, tailored for your business needs.`,
              `A deep code analysis with business value assessment.`,
              `Identification of technical risks and their impact on the business.`,
              `Backlog of improvements prioritized for technical and business needs.`,
              `Direct contact and consultation with the auditors as part of the report presentation.`,
            ]}
          />
        </div>
      </Col>
      <Col md={12} lg={6} className={'audit-benefits__tablet'}>
        <StickyBox offsetTop={100} style={{ zIndex: 100 }}>
          <div className={'w-container--right'}>
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/code-audit-ipad--mob.png',
                'f_auto,q_auto,w_800',
              )}
              className={'image audit-benefits__image image--mobile'}
              alt={'Resource cover'}
              loading={'lazy'}
            />
            <img
              src={getCloudinaryUrl(
                'gatsby-cloudinary/code-audit-ipad.png',
                'f_auto,q_auto,w_800',
              )}
              className={'image audit-benefits__image image--desktop'}
              alt={'Resource cover'}
              loading={'lazy'}
            />
          </div>
        </StickyBox>
      </Col>
    </Row>
  </Section>
);

Benefits.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  coverAlt: PropTypes.string,
  description: PropTypes.string,
  formHeading: PropTypes.string,
  itemsQuestions: PropTypes.bool,
  intro: PropTypes.string,
  items: PropTypes.array,
  outro: PropTypes.string,
  paragraphHeadings: PropTypes.string,
  redirectButton: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
  redirectContent: PropTypes.string,
  redirectTitle: PropTypes.string,
  title: PropTypes.string,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Benefits.defaultProps = {
  btnColor: '',
  button: '',
  coverAlt: '',
  description: '',
  formHeading: '',
  itemsQuestions: false,
  intro: '',
  items: [],
  outro: '',
  paragraphHeadings: '',
  redirectButton: { path: '', label: '' },
  redirectContent: '',
  redirectTitle: '',
  title: '',
};

export default Benefits;
