import React from 'react';
import { Section, SectionHeader, Paragraph, Button } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { Author } from 'views/singleArticle/subcomponents';
import { classNames } from 'utils';
import withSubscribe from './HOC';
import './Subscribe.scss';

const Subscribe = ({ subscribed, isLoading, refForm, handleSubmit }) => (
  <Section color={'yellow'} additionalClass={'machine-learning__subscribe'}>
    <div>
      <SectionHeader
        size={'medium'}
        headingType={2}
        lineColor={'white'}
        description={'Leave us your e-mail and we will contact you soon.'}
        additionalClass={'section-header__description--medium'}
      >
        Talk to our business development consultants
      </SectionHeader>
      {subscribed ? (
        <Paragraph size={'medium'} additionalClass={'text--bold'}>
          Thank you for contacting us. Our consultant will back to you within
          24 hours.
        </Paragraph>
      ) : (
        <form
          className={'newsletter-small__form'}
          onSubmit={handleSubmit}
          ref={refForm}
        >
          <input
            type={'email'}
            name={'email'}
            placeholder={'E-mail address'}
            required
          />
          <Button
            id={`machine-learning-subscribe`}
            additionalClass={classNames('newsletter-small__form-button', {
              loading: isLoading,
            })}
            type={'submit'}
            color={'yellow'}
            ariaName={'Contact button'}
            disabled={isLoading}
            loading
          >
            Get free consultation
          </Button>
        </form>
      )}
    </div>
    <Author
      avatar={getCloudinaryUrl(
        'business-crew/anna-jaskiewicz',
        'f_auto,q_auto,h_300',
      )}
      name={'Anna Jaskiewicz'}
      job={'Business Development'}
    />
  </Section>
);

export default withSubscribe(Subscribe);
