import { getCloudinaryUrl } from 'config/helpers';

export function getBookDiscoverySessionImageUrl(
  imageSrc,
  parameters = 'f_auto,q_auto',
) {
  return getCloudinaryUrl(`book-discovery-session/${imageSrc}`, parameters);
}

export function getRecommendClientImageUrl(
  imageSrc,
  parameters = 'f_auto,q_auto',
) {
  return getCloudinaryUrl(`recommend-client/${imageSrc}`, parameters);
}
