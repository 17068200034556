import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Heading, BlogSlider, Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './OurDesigns.scss';
import data from './logic';

const OurDesigns = () => {
  const [isAwwwards, setIsAwwwards] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('awwwards')
    ) {
      setIsAwwwards(true);
    }
  }, []);

  return (
    <section className={'awwwards-lp-designs'} id={'awwwwards__designs'}>
      <Section
        additionalClass={'awwwards-lp-designs__header'}
        color={'violet'}
      >
        <Heading
          additionalClass={
            'awwwards-lp__heading awwwards-lp-designs__heading anim-fade-in-left'
          }
          color={'white'}
          size={'large'}
          lineColor={'yellow'}
          type={2}
        >
          {ReactHtmlParser(data.title)}
          {isAwwwards ? '...' : ':'}
        </Heading>
      </Section>
      <BlogSlider
        theme={'light'}
        data={data.items.map(slide => ({
          ...slide,
          background: getCloudinaryUrl(
            slide.background,
            'f_auto,q_auto,w_1700',
          ),
        }))}
        showNav
        sliderNavColor={'light'}
        navColor={'mine-shaft'}
      />
    </section>
  );
};

export default OurDesigns;
