import React from 'react';
import { Section } from 'components';
import {
  Awards,
  Benefits,
  Hero,
  Reviews,
  Services,
  Technology,
  Contact,
} from './subcomponents';

export const WhoWeAre = () => (
  <Section fullwidth spaceless>
    <Hero />
    <Services />
    <Reviews />
    <Awards />
    <Benefits />
    <Technology />
    <Contact />
  </Section>
);
