import React from 'react';

import { FramedAvatar } from 'components';
import { getBookDiscoverySessionImageUrl } from '../../utils';

import './Author.scss';

const Details = () => (
  <figcaption>
    <div className={'book-discovery-session__author-name'}>Michał Trzpis</div>
    <p className={'book-discovery-session__author-description'}>
      Business Solution Architect
    </p>
    <p className={'book-discovery-session__author-email'}>
      business@boldare.com
    </p>
  </figcaption>
);

const Photo = () => (
  <FramedAvatar
    src={getBookDiscoverySessionImageUrl('michal-trzpis.png')}
    alt={'Michał Trzpis'}
    className={'book-discovery-session__author-photo'}
  />
);

const Author = () => (
  <div>
    <Photo />
    <Details />
  </div>
);

Author.Details = Details;
Author.Photo = Photo;

export default Author;
