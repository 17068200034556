import Banner from './Banner';
import Process from './Process';
import WhatWeUse from './WhatWeUse';
import HowWeWork from './HowWeWork';
import MeetTeam from './MeetTeam';
import OtherServices from './OtherServices';
import LearnMore from './LearnMore';
import Subscribe from './Subscribe';
import Approach from './Approach';

export {
  Approach,
  Banner,
  Process,
  WhatWeUse,
  HowWeWork,
  MeetTeam,
  OtherServices,
  LearnMore,
  Subscribe,
};
