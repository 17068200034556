import React, { Component, createRef } from 'react';
import { Button, Paragraph } from 'components';
import ContactFormFullwidth from '../ContactFormFullwidth';
import { generateHash, serialize, classNames } from '../../utils';
import ContactFormContactPage from './ContactFormContactPage';
import ContactFormBlack from './ContactFormBlack';

import './ContactForm.scss';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _replyto: '',
    };
    this.refForm = createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const form = this.refForm.current;
    const { pardotLink } = this.props;
    const connectionId = generateHash();

    this.setState({ isLoading: true });
    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: serialize(form),
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you&connectionId=${connectionId}`;
        }
        form.reset();
      })
      .catch(error => console.log(error));
  };

  render() {
    const { name } = this.props;

    const { _replyto, isLoading } = this.state;

    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      return (
        <div>
          <Paragraph>Message has been sent, thanks.</Paragraph>
        </div>
      );
    }

    return (
      <div>
        <form
          ref={this.refForm}
          name={name}
          onSubmit={this.handleSubmit}
          className={'contact-form'}
        >
          <input type={'text'} name={'_gotcha'} style={{ display: 'none' }} />
          <input
            type={'hidden'}
            name={'_subject'}
            value={`Boldare.com from ${_replyto} via ${typeof window !==
              'undefined' && window.location}`}
          />
          <div className={'contact-form__personal-data'}>
            <input
              type={'text'}
              name={'name'}
              placeholder={'Name'}
              onChange={this.handleChange}
              required
            />
            <input
              type={'email'}
              name={'_replyto'}
              placeholder={'E-mail address'}
              onChange={this.handleChange}
              required
            />
          </div>
          <textarea
            name={'message'}
            placeholder={'How can we help you?'}
            onChange={this.handleChange}
            required
          />
          <Button
            id={`contact-form-${name}-submit`}
            type={'submit'}
            color={'yellow'}
            additionalClass={classNames('contact-button', {
              loading: isLoading,
            })}
            ariaName={'Submit button'}
            disabled={isLoading}
            loading
          >
            Send
          </Button>
        </form>
      </div>
    );
  }
}

ContactFormFullwidth.defaultProps = {
  description:
    'Do you want to build a product, hire a development team or simply have a question? Leave us a message.',
  pardotLink: 'https://go.boldare.com/l/688533/2019-02-28/x5r',
};

ContactForm.V2 = ContactFormContactPage;
ContactForm.Black = ContactFormBlack;

export default ContactForm;
