import React from 'react';
import './Banner.scss';
import {
  BackgroundImage,
  Button,
  Heading,
  Section,
  SectionHeader,
} from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { scroller } from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import ArrowBlack from 'assets/images/digitale-transformation-de/arrow-black.svg';

const Banner = () => (
  <Section
    id={'digtra-de-banner'}
    additionalClass={
      'digtra-de-banner__section digtra-de-banner--position-relative'
    }
    color={'white'}
    side={'right'}
  >
    <BackgroundImage
      asset={'de/digitale-transformation/banner-back.jpg'}
      alt={'Digitale Transformation'}
      additionalClass={'banner__img background-img--cloudinary'}
    />
    <Row className={'no-gap digtra-de-banner--position-relative'}>
      <Col sm={12} md={9}>
        <SectionHeader
          size={'large'}
          color={'mine-shaft'}
          fixed
          noLine
          additionalClass={'digtra-de-banner__text-header'}
        >
          Wie wir Ihnen helfen Ihr Unternehmen zu <span>digitalisieren</span>
        </SectionHeader>
        <Heading
          type={2}
          size={'medium-constant'}
          color={'mine-shaft'}
          additionalClass={'digtra-de-banner__text-header-secondary'}
        >
          Boldare begleitet Sie mit mehr als 200 Experten von der ersten
          Überlegung, über die Konzeptionierung bis hin zur konkreten Umsetzung
          der digitalen Transformation inklusive der Entwicklung digitaler
          Produkte innerhalb Ihres Unternehmens.
        </Heading>
      </Col>
    </Row>
    <div className={'digtra-de-banner__contact-btn-background'}>
      <div className={'digtra-de-banner__contact-btn-wrapper'}>
        <div>
          <Button
            onClick={() => {
              scroller.scrollTo('form', {
                spy: false,
                smooth: true,
                duration: 700,
                offset: -100,
              });
            }}
            color={'white'}
            id={'contact-now-shortcut'}
            ariaName={'Contact button'}
            additionalClass={'contact-button'}
            hover
          >
            Kontaktieren Sie uns
          </Button>
        </div>
        <div>
          <div className={'digtra-de-banner__contact-btn-background__line'} />
        </div>
        <div>
          <div className={'digtra-de-banner__contact-btn-background__arrow'}>
            <img
              src={ArrowBlack}
              className={'image'}
              alt={'Arrow to bottom'}
              loading={'lazy'}
            />
          </div>
        </div>
      </div>
    </div>
    <div className={'digtra-de-banner__images-wrapper'}>
      <div>
        <div className={'digtra-de-banner__astronaut'}>
          <img
            src={getCloudinaryUrl(
              'de/digitale-transformation/banner-astronaut.png',
              'f_auto,q_auto,w_665',
            )}
            className={'image'}
            alt={'Astronaut'}
            loading={'lazy'}
          />
        </div>
        <div className={'digtra-de-banner__computer'}>
          <img
            src={getCloudinaryUrl(
              'de/digitale-transformation/banner-computer.png',
              'f_auto,q_auto,w_764',
            )}
            className={'image'}
            alt={'Computer'}
            loading={'lazy'}
          />
        </div>
        <div className={'digtra-de-banner__bulb'}>
          <img
            src={getCloudinaryUrl(
              'de/digitale-transformation/banner-bulb.png',
              'f_auto,q_auto,w_325',
            )}
            className={'image'}
            alt={'Planet'}
            loading={'lazy'}
          />
        </div>
      </div>
    </div>
  </Section>
);
export default Banner;
