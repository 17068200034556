import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { classNames } from 'utils';
import inView from 'in-view';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Technology.scss';

class Technology extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entered: false,
    };
  }

  componentDidMount() {
    inView('.oneida__technology-tiles').on('enter', () => {
      this.setState({ entered: true });
    });
  }

  renderBoxes = () =>
    data.map(item => (
      <div
        className={classNames('oneida__technology-box', {
          'oneida__technology-box--entered': this.state.entered,
        })}
        key={`oneida__technology-${item.image}`}
      >
        <img
          src={getCloudinaryUrl(item.img, 'f_auto,q_auto')}
          alt={item.name}
          className={'image oneida__technology-logotype'}
          loading={'lazy'}
        />
        <Heading type={3} size={'medium'} color={'mine-shaft'}>
          {item.name}
        </Heading>
      </div>
    ));

  render() {
    return (
      <Section additionalClass={'oneida__technology-container'} spaceless>
        <div className={'oneida__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Technology
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            How we&apos;ve built
            <br />a Single-Page Application
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            With help of Angular framework and detailed specifications we could
            create fast and stable application that not only work flawlessly
            but is also intuitive for users.
          </Paragraph>
        </div>
        <div className={'oneida__technology-tiles'}>{this.renderBoxes()}</div>
      </Section>
    );
  }
}

export default Technology;
