import React, { useState } from 'react';
import {
  motion,
  useTransform,
  useViewportScroll,
  AnimatePresence,
} from 'framer-motion';
import { ReactComponent as ArrowTopRight } from '../../assets/icons/arrow_top_right.svg';

const FutureSection = ({ animationEnd, animationShow, animationStart }) => {
  const { scrollY } = useViewportScroll();
  const clipPath = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['circle(18% at 100% 50%)', 'circle(100% at 50% 50%)'],
  );
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  scrollY.onChange(progress => {
    if (isSectionVisible && progress < animationShow) {
      setIsSectionVisible(false);
    } else if (!isSectionVisible && progress >= animationShow) {
      setIsSectionVisible(true);
    }
  });

  return (
    <AnimatePresence>
      {isSectionVisible && (
        <motion.div
          exit={{ x: '18%' }}
          style={{ clipPath }}
          animate={{ x: '0%' }}
          initial={{ x: '18%' }}
          transition={{ duration: 0.3, ease: 'linear' }}
          className={'dsgn-cases__section dsgn-cases__section--future'}
        >
          <div
            className={
              'dsgn-cases__section-content dsgn-cases__section-content--future dsgn-cases__section-content--white'
            }
          >
            <div className={'dsgn-cases__tagline'}>
              <p>e-commerce MVP | prototype | MVP</p>
            </div>
            <div className={'dsgn-cases__company'}>
              <span className={'dsgn-cases__company-name'}>Future Work</span>
              <span className={'dsgn-cases__company-spacer'}>|</span>
              <span className={'dsgn-cases__company-country'}>
                Saudi Arabia
              </span>
            </div>
          </div>
          <div className={'dsgn-cases__section-back'}>
            <div className={'dsgn-cases__description'}>
              <div className={'dsgn-cases__description-content'}>
                <p>
                  Future Work is a web platform that connects Saudi freelancers
                  and professionals with employment opportunities in the gig
                  economy, while offering insights into job market trends and
                  guidance for securing regular employment.
                </p>
              </div>
              <div className={'dsgn-cases__description-cta'}>
                <a
                  href={
                    'https://www.behance.net/gallery/137691767/Future-Work'
                  }
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <span>See more <ArrowTopRight /></span>
                </a>
              </div>
            </div>
          </div>
          <div className={'dsgn-cases__section-back'} style={{ zIndex: 0 }}>
            <div
              className={
                'dsgn-cases__img-content bldr-dsgn__description-image'
              }
            >
              <div className={'bldr-dsgn__description-image--mobile'}>
                <img
                  src={
                    'https://res.cloudinary.com/de4rvmslk/image/upload/v1699885166/bdbb/futureworkbg-mb.png'
                  }
                  alt={'Future Work'}
                  className={'dsgn-cases__background'}
                />
              </div>
              <div className={'bldr-dsgn__description-image--desktop'}>
                <img
                  src={
                    'https://res.cloudinary.com/de4rvmslk/image/upload/v1699019006/bdbb/futureworkbg.png'
                  }
                  alt={'Future Work'}
                  className={'dsgn-cases__background'}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FutureSection;
