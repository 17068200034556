import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {
  Box,
  BoxContent,
  BoxFooter,
  Heading,
  Paragraph,
  IconSVG,
  Button,
  BackgroundImage,
} from 'components';
import { classNames } from 'utils';
import './SliderWithCounter.scss';

class SliderWithCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        className: 'slider-with-counter',
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        speed: 0,
        infinite: true,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        useTransform: false,
        touchMove: true,
        fade: true,
        afterChange: current => {
          this.setState({ currentSlide: current });
        },
      },
      currentSlide: 0,
    };
  }

  renderSlides = currentSlide => {
    const { data, theme } = this.props;
    const colors =
      theme === 'light'
        ? {
            primary: 'white',
            secondary: 'mine-shaft',
            text: 'black',
            line: 'black',
          }
        : {
            primary: 'mine-shaft',
            secondary: 'white',
            text: 'white',
            line: 'golden-dream',
          };

    return data.map(item => (
      <div
        className={
          'slider-with-counter__slide-container w-container--side-left'
        }
        key={`culture-slide-${item.id}`}
      >
        <div
          className={classNames('slider-with-counter__slide-wrapper', {
            [`slider-with-counter__slide-wrapper--${item.color}`]: item.color,
          })}
        >
          <div className={'slider-with-counter__image'}>
            <BackgroundImage
              asset={item.background}
              additionalClass={'slider-with-counter__slide'}
              alt={item.caption}
            />
            {data.length > 1 && (
              <div className={classNames('slider-with-counter__navigation')}>
                <div
                  className={classNames(
                    'slider-with-counter__navigation--wrapper',
                  )}
                >
                  <Paragraph
                    size={'big'}
                    color={colors.text}
                    additionalClass={
                      'text--demibold slider-with-counter__navigation--counter'
                    }
                  >
                    {`${currentSlide + 1}/${data.length}`}
                  </Paragraph>
                  <div
                    id={'slider-with-counter-prev'}
                    role={'presentation'}
                    className={'button button--primary button--white'}
                    onClick={() => this.slider.slickPrev()}
                  >
                    <IconSVG name={'arrow-left'} color={colors.text} />
                  </div>
                  <div
                    id={'slider-with-counter-next'}
                    role={'presentation'}
                    className={'button button--primary button--white'}
                    onClick={() => this.slider.slickNext()}
                  >
                    <IconSVG name={'arrow-right'} color={colors.text} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={classNames('slider-with-counter__item', {
              [`slider-with-counter__item--${colors.primary}`]: colors.primary,
            })}
          >
            <Box
              additionalClass={'slider-with-counter__box'}
              backgroundColor={colors.primary}
            >
              <BoxContent type={'slider'}>
                <Paragraph
                  color={colors.text}
                  additionalClass={'slider-with-counter__paragraph'}
                >
                  {item.info.toUpperCase()}
                </Paragraph>
                <Heading size={'medium-plus'} color={colors.text} type={3}>
                  {item.description}
                </Heading>
              </BoxContent>
              <BoxFooter additionalClass={'slider-with-counter__box--footer'}>
                <a href={item.link} rel={'nofollow'}>
                  <Button
                    order={'primary'}
                    color={'yellow'}
                    ariaName={item.info}
                  >
                    {`Read  ${item.info}`.toUpperCase()} →
                  </Button>
                </a>
              </BoxFooter>
            </Box>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const { sliderSettings, currentSlide } = this.state;

    return (
      <>
        <Slider
          ref={slider => {
            this.slider = slider;
          }}
          {...sliderSettings}
        >
          {this.renderSlides(currentSlide)}
        </Slider>
      </>
    );
  }
}

SliderWithCounter.defaultProps = {
  data: [],
  theme: 'light',
};

SliderWithCounter.propTypes = {
  data: PropTypes.array,
  theme: PropTypes.string,
};

export default SliderWithCounter;
