import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['ultimate-scrum-master-toolkit'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/ultimate-scrum-master-toolkit/`;

const UltimateScrumMasterToolkitPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={
          'The Ultimate Scrum Master Toolkit: Cooperate with clients and teams like a pro'
        }
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/Ultimate-Scrum-Master-Toolkit-ebook-cover-photo.png',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-sm-toolkit.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'The Ultimate Scrum Master Toolkit'}
        intro={
          'Scrum is all about helping people deliver. And it’s you, who’s responsible for that. So, are you eager to boost your effectiveness with a proven set of skills, best practices and tools? Do you want to become a pro in facilitation and supporting your teams in reaching goals easily?'
        }
        description={'Download our free ebook to learn:'}
        items={[
          'Why are Product Vision Workshops, Event Storming, and Impact Mapping the TOP 3 tools to use while building software with clients?',
          'How to use these tools in practice to become the ace of Scrum: a set of tactics explained to you step by step ',
          'What are the true benefits of engaging the whole Scrum team and a client into the process of building software (instead of working separately)',
          'How to enhance your workflow with a bunch of super-handy tips and insights from our Senior Scrum Master, Patrycja',
        ]}
        outro={'Ready to gain some expert know-how? Come one, come all!'}
        formHeading={'Get your FREE copy now:'}
        url={url}
        button={'Yes, I want one!'}
        btnColor={'yellow'}
        trackingEvent={'ebookSignupUltimateScrumMasterToolkitEbook'}
      />
    </Layout>
  );
};

export default UltimateScrumMasterToolkitPage;
