import React, { PureComponent } from 'react';
import {
  Heading,
  IconSVG,
  Section,
  SectionHeader,
  Link,
  AbsoluteCTA,
} from 'components';
import { ICON_TYPES } from 'config';
import { classNames } from 'utils';
import { data } from './logic';
import './Technologies.scss';

class Technologies extends PureComponent {
  renderItem = () =>
    data.map(item => (
      <div
        className={classNames('gitex-technologies-box', {
          [`section--${item.color}`]: item.color,
        })}
        key={item.link}
      >
        <Link className={'gitex-technologies-box-item'} to={`${item.link}`}>
          <div className={'item-heading'}>
            <IconSVG
              name={`${item.icon}`}
              size={'huge'}
              color={'mine-shaft'}
            />
          </div>
          <div className={'item-description'}>
            <Heading color={'white'}>{item.title}</Heading>
          </div>
        </Link>
      </div>
    ));

  render() {
    return (
      <>
        <Section color={'white'} additionalClass={'gitex-technologies'}>
          <SectionHeader
            size={'large'}
            lineColor={'yellow'}
            color={'mine-shaft'}
            headingType={3}
          >
            Discover the most disruptive
            <br />
            technologies
          </SectionHeader>
          <div className={'gitex-technologies-items'}>{this.renderItem()}</div>
        </Section>
        <AbsoluteCTA
          description={'Interested in more data-based articles?'}
          additionalClass={'gitex-cta'}
          link={'/blog/'}
          linkText={'Visit our blog!'}
          id={'page-digital-transformation-solution'}
        />
      </>
    );
  }
}

export default Technologies;
