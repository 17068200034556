import React, { useEffect } from 'react';
import { enableAnimations } from 'config/helpers';
import Hero from './subcomponents/Hero';

import './ServiceStandards.scss';
import Description from './subcomponents/Description';
import Badges from './subcomponents/Badges';
import Cards from './subcomponents/Cards';
import Nutshell from './subcomponents/Nutshell';
import Companies from './subcomponents/Companies';
import Contact from './subcomponents/Contact';

function ServiceStandardsView() {
  useEffect(() => {
    enableAnimations();
  }, []);

  return (
    <div className={'single-page ses-container'}>
      <Hero />
      <Description />
      <Badges />
      <Cards />
      <Nutshell />
      <Companies />
      <Contact />
    </div>
  );
}

export default ServiceStandardsView;
