import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button } from 'components';
import './Banner.scss';

const SpyLink = Scroll.Link;

const Banner = ({ id, cta, link }) => (
  <>
    <SubpageBanner
      backgroundColor={'white'}
      image={getCloudinaryUrl(
        'gatsby-cloudinary/code-audit-banner.jpg',
        'f_auto,q_auto,w_1400',
      )}
      contentBackground={'mine-shaft'}
      additionalClass={'code-audit__banner'}
      alt={'Code audit & UX audit'}
      multibackground
      multibackgroundColor={'pigeon-post'}
    >
      <SectionHeader
        size={'extra-large'}
        color={'white'}
        lineColor={'pigeon-post'}
        description={
          'Whether you want to scale your current web or mobile application, find its security vulnerabilities or identify user journey flaws, you can easily do that by auditing the source code and UX of your software. At Boldare, we offer custom code audits and UX audits to help your digital product thrive. '
        }
        descriptionColor={'white'}
        fixed
        additionalClass={'section-header__description--medium'}
      >
        Code audit & <br />
        UX audit
      </SectionHeader>
      <SpyLink
        id={id}
        to={link}
        spy={false}
        smooth
        duration={500}
        className={'link'}
      >
        <Button
          id={'code-audit-contact-btn'}
          color={'yellow'}
          ariaName={'Contact button'}
          additionalClass={'contact-button'}
        >
          {cta}
        </Button>
      </SpyLink>
    </SubpageBanner>
  </>
);

export default Banner;
