import React from 'react';
import ResourcesHub from 'views/resources-hub';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages.resources;
const { navColors } = page;
const url = `${config.siteUrl}/resources/`;
const canonical = `${url}`;

const ResourcesHubPage = () => {
  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} />
      <ResourcesHub />
    </Layout>
  );
};

export default ResourcesHubPage;
