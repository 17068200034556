import React from 'react';

import WayPointContainer from '.';

export default function withWaypointsContainer(Component) {
  return function WrappedComponent(props) {
    return (
      <WayPointContainer>
        {context => <Component {...props} {...context} />}
      </WayPointContainer>
    );
  };
}
