import React from 'react';
import { graphql } from 'gatsby';
import DigitalTransformation from 'views/digitalTransformation';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['digital-transformation'];
const { navColors } = page;
const url = `${config.siteUrl}/services/digital-transformation/`;
const canonical = `${url}`;

const DigitalTransformationPage = ({ data }) => {
  const { allArticles } = data || {};

  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect />
      <DigitalTransformation allArticles={allArticles} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query DigitalTransformationPage {
    allArticles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___settings___date] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Visualising Energy"
              "How to scale a monolithic MVP application without losing business?"
              "From design to development in 4 weeks. Creating a mobile and web MVP for an iconic brand"
              "Designing a simple search experience for a complex product with a luxurious interface"
            ]
          }
        }
      }
    ) {
      ...markdownFields
      ...boxMarkdownFields
    }
  }
`;

export default DigitalTransformationPage;
