import React from 'react';

import './ReferalProgramRegulations.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { images } from './logic';

const ReferalProgramRegulations = () => {
  return (
    <div className={'referral-program'}>
      {images.map(image => (
        <img
          key={image.id}
          src={getCloudinaryUrl(image.url, 'f_auto,q_auto,w_auto')}
          alt={'Program polecen potencjalnych klientów regulamin'}
          loading={'lazy'}
        />
      ))}
    </div>
  );
};

export default ReferalProgramRegulations;
