import Banner from './Banner';
import What from './What';
import Why from './Why';
import Technologies from './Technologies';
import Problem from './Problem';
import Solution from './Solution';
import Types from './Types';
import Benefits from './Benefits';
import ProcessAndTools from './ProcessAndTools';
import OurArticles from './OurArticles';
import OtherServices from './OtherServices';

export {
  Banner,
  What,
  Why,
  Technologies,
  Problem,
  Solution,
  Types,
  Benefits,
  ProcessAndTools,
  OurArticles,
  OtherServices,
};
