export const data = [
  {
    image: 'gatsby-cloudinary/norbert-sonnen.png',
    quote:
      'We enjoy the input from each individual team member about the technology, content, and design. This feedback gives us the chance to get in touch with new ideas that you can’t see if you have been involved in the same company for years.',
    author: 'Norbert Bauman, sonnen',
  },
  {
    image: 'gatsby-cloudinary/neil-sabco.png',
    quote:
      'The team proactively developed a viable plan to deliver and implement the final product. During phase one, we needed to build a basic platform and mobile apps by our radio channel launch date. They managed minute aspects of design and development.',
    author: 'Neil DeSouza, Sabco Media, Virgin Radio Oman',
  },
  {
    image: 'gatsby-cloudinary/alex-polco.png',
    quote:
      'From a team perspective, we liked their style and design but what’s been awesome is the process that they go through. There is an unbelievable amount of transparency and organization to the point that in every instance they could save time, they have.',
    author: 'Alex, Polco',
  },
];
