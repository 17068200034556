import React, { useRef } from 'react';
import { Section } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { useScrollAnimation } from 'hooks';
import './Intro.scss';

const Intro = () => {
  const devices = useRef();
  useScrollAnimation([
    {
      ref: devices,
      transform: { translateY: '10%' },
    },
  ]);

  return (
    <Section additionalClass={'pakt__intro-container'} spaceless>
      <img
        className={'image pakt__intro-image'}
        src={getCloudinaryUrl(
          'assets/pakt/transparent-1.png',
          'f_auto,q_auto',
        )}
        innerRef={devices}
        alt={'Pakt on different devices'}
        loading={'lazy'}
      />
    </Section>
  );
};

export default Intro;
