import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { NavBar } from 'components';
import WebinarGate from 'views/webinar';
import Webinar from 'views/webinar/Webinar';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import multimedia from 'assets/images/webinar/multimedia.svg';
import {
  banner,
  firstSection,
  secondSection,
  thirdSection,
  fourthSection,
} from '../../views/resources/Webinar/code-audit-webinar-logic.js';
import config from '../../../meta/config';

const page = pages.webinar.items['code-audit'];
const { navColors } = page;
const url = `${config.siteUrl}/webinar/code-audit/`;
const canonical = `${url}`;

const CodeAuditWebinarPage = () => {
  const [isGateOpen, setIsGateOpen] = useState(false);
  const pardotLink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('webinar-gate=open_id-1deea1e0')
    ) {
      setIsGateOpen(true);
    }
  }, []);

  return (
    <Layout {...{ page, canonical, url }}>
      <Helmet />
      <NavBar color={navColors} bannerHidden />
      {true ? (
        <Webinar
          {...{
            banner,
            firstSection,
            secondSection,
            thirdSection,
            fourthSection,
          }}
        />
      ) : (
        <WebinarGate
          icon={multimedia}
          type={'pre-recorded webinar'}
          title={'What next after a&nbsp;code audit?'}
          subtitle={'Business-centered approach for CTOs & CEOs'}
          description={`This pre-recorded webinar is designed to help CTOs and CEOs to maximize the results of a code audit. Our experts, based on real-life cases, discuss the three pillars of business-centered code audits and how to prioritize outcomes and their impact on customers and the product itself.`}
          speakers={[
            {
              avatar: 'img/webinar-code-audit/Ania_Landing_Avatar.jpg',
              position: 'Senior Software Developer',
              name: 'Anna Bobola',
            },
            {
              avatar: 'img/webinar-code-audit/Dawid_Landing_Avatar.jpg',
              position: 'Senior Software Developer',
              name: 'Dawid Yerginyan',
            },
            {
              avatar: 'img/webinar-code-audit/Rosiu_Landing_Avatar.jpg',
              position: 'Senior Software Developer',
              name: 'Mateusz Rosiek',
            },
          ]}
          listTitle={`What will you learn?`}
          learnItems={[
            'Why does sustainability matter in the context of your application?',
            'How do you prepare a well-prioritized product backlog based on the audit results?',
            'Why is it important to keep your dependencies up to date?',
            'What tools will be useful for your team and allow you to reduce costs?',
          ]}
          outro={`This pre-recorded webinar is free.`}
          formHeading={`Get your free access to the pre-recorded webinar`}
          btnColor={'yellow'}
          button={'Get your access'}
          url={pardotLink}
          trackingEvent={`CodeAuditWebinar`}
          webinarTitle={'Thank you for signing up!'}
          webinarContent={
            'Check your inbox. We have sent you a confirmation email with additional information about the “What next after a code audit?” pre-recorded webinar.<br /><br />Next we will send you an email with a link to access the recording when it is available.'
          }
          webinarButton={{
            label: 'Check out Code Audit service page',
            path: '/services/code-audit/',
          }}
          gateAddress={'webinar-gate=open_id-1deea1e0'}
          gateCatalog={'webinar'}
        />
      )}
    </Layout>
  );
};

export default CodeAuditWebinarPage;
