import React from 'react';
import { SectionHeader } from 'components';
import { data } from './data';
import PortfolioContent from './subcomponents/PortfolioContent';
import './PortfolioSection.scss';

const PortfolioSection = () => {
  return (
    <section
      className={'products-portfolio section-box'}
      id={'portfolio-section'}
    >
      <div className={'padding__container'}>
        <SectionHeader
          additionalClass={'products-portfolio__heading'}
          color={'white'}
          headingType={2}
          centered
          noLine
        >
          We create technology that improves lives
        </SectionHeader>
        <PortfolioContent id={'products-portfolio__slider'} items={data} />
      </div>
    </section>
  );
};

export default PortfolioSection;
