import React, { Component } from 'react';
import { truncate } from 'lodash';
import MarkdownRenderer from 'react-markdown-renderer';
import { Col } from 'react-flexbox-grid';
import { Heading, Link, Paragraph, IconSVG } from 'components';
import {
  getArticleSlug,
  estimateReadTime,
  getCloudinaryUrl,
} from 'config/helpers';
import { classNames } from 'utils';
import './SinglePostTileBig.scss';

class SinglePostTileBig extends Component {
  handleCategoryClick = (event, category) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.toggleFilter(category);
  };

  render() {
    const { post, authors, theme, linkClass } = this.props;
    const { frontmatter } = post.node;
    const { title, cover, settings, author, lead, content } = frontmatter;
    const slug = getArticleSlug(post);

    const authorData = authors.filter(
      edge => edge.node.frontmatter.title === author,
    );

    const articlePureContent = content
      .reduce((a, b) => a.body || a + b.body, '')
      .replace(/[^a-zA-Z ]/g, ' ');

    const readTime = estimateReadTime(articlePureContent);

    return (
      <Col md={12} lg={8}>
        <Link
          to={slug}
          id={`single-post-${slug}`}
          additionalClass={linkClass}
          rel={'nofollow'}
        >
          <div
            className={classNames('single-post-tile-big', {
              [`single-post-tile-big--${theme}`]: theme,
            })}
          >
            <div className={'single-post-tile-big__top'}>
              <img
                src={getCloudinaryUrl(cover, 'f_auto,q_auto,h_500')}
                alt={title}
                className={'image'}
                loading={'lazy'}
              />
            </div>
            <div className={'single-post-tile-big__bottom'}>
              <div>
                <button
                  onClick={e => this.handleCategoryClick(e, settings.category)}
                  aria-label={'Single post tile'}
                >
                  <Paragraph
                    size={'small'}
                    color={theme === 'black' ? 'black' : 'pigeon-post'}
                    additionalClass={
                      'single-post-tile-big__category text--bold pointer'
                    }
                  >
                    {settings.category}
                  </Paragraph>
                </button>
                <Paragraph
                  size={'small'}
                  additionalClass={'single-post-tile__time'}
                >
                  {readTime} min read
                </Paragraph>
                <IconSVG name={'time'} color={'black'} />
                <Heading type={3} size={'medium'}>
                  {title}
                </Heading>
                <Paragraph
                  color={'black'}
                  additionalClass={'text--light'}
                  nested
                >
                  <MarkdownRenderer
                    markdown={truncate(lead, { length: 200 })}
                  />
                </Paragraph>
              </div>
              {authorData.length > 0 && (
                <div className={'single-post-tile-big__author'}>
                  <img
                    src={getCloudinaryUrl(
                      authorData[0]?.node.frontmatter.photo,
                      'f_auto,q_auto,w_100',
                    )}
                    alt={`${authorData[0]?.node.frontmatter.title} avatar`}
                    className={'image'}
                    loading={'lazy'}
                  />
                  <Paragraph size={'small'}>
                    {authorData[0]?.node.frontmatter.title}
                    <br />
                    <span className={'text--light'}>
                      {authorData[0]?.node.frontmatter.job}
                    </span>
                  </Paragraph>
                </div>
              )}
            </div>
          </div>
        </Link>
      </Col>
    );
  }
}

SinglePostTileBig.defaultProps = {
  theme: 'white',
};

export default SinglePostTileBig;
