import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useUserGeolocation } from 'hooks/useUserGeolocation';

export const UserGeolocationContext = createContext(null);

export const UserGeolocationContextProvider = ({ children }) => {
  const { userCountryName, userContinent } = useUserGeolocation()

  return (
    <UserGeolocationContext.Provider value={{ userCountryName, userContinent }}>
      {children}
    </UserGeolocationContext.Provider>
  );
};

UserGeolocationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
