import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';
import { Button } from 'components';
import './Nutshell.scss';

export default function Nutshell() {
  return (
    <div className={'ses-nutshell'}>
      <div className={'ses-nutshell__image'}>
        <img
          src={getCloudinaryUrl(`scrum_sprint_planning.jpg`, 'f_auto,q_auto')}
          alt={'Sprint planning'}
        />
      </div>
      <div className={'ses-nutshell__description'}>
        <h3 className={'heading'}>Service Standards in a nutshell</h3>
        <p className={'paragraph paragraph--medium'}>
          With nearly 20 years of digital product-building expertise under our
          belt, we understand that in this complex environment, delivering
          high-quality value is incredibly challenging. That’s why we
          introduced our Service Standards framework which outlines how we
          deliver our services. Read more about our approach on Boldare blog.
        </p>
        <a
          href={
            '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/'
          }
        >
          <Button id={`ses-read-more`} color={'white'} ariaName={'Read more'}>
            Read more
          </Button>
        </a>
      </div>
    </div>
  );
}
