import React from 'react';
import { Section, SectionHeader, Paragraph } from 'components';

import LetsTalk from './LetsTalk';

import './UnlockPower.scss';

function UnlockPower() {
  return (
    <>
      <Section
        color={'mine-shaft'}
        side={'right'}
        additionalClass={'rescue-failing-project__unlock-power'}
        containerClass={'rescue-failing-project__unlock-power__container'}
      >
        <div className={'rescue-failing-project__unlock-power__wrapper'}>
          <SectionHeader
            headingType={2}
            size={'large'}
            noLine
            additionalClass={'rescue-failing-project__unlock-power__header'}
            margin={'rescue-failing-project__unlock-power__header'}
          >
            Unlock the Power of Speed and Reliability
          </SectionHeader>
          <Paragraph size={'medium-constant'} color={'white'}>
            Don&apos;t let time and budget constraints hold you back. Our
            software development company is here to empower you with
            lightning-fast project delivery, backed by a <b>team of experts</b>{' '}
            and <b>fueled by innovative AI-powered solutions</b>.
          </Paragraph>
          <LetsTalk
            className={
              'rescue-failing-project__unlock-power__lets-talk-container hide-tablet-big-important hide-mobile'
            }
          />
        </div>
      </Section>
      <Section color={'soft-cloud hide-above-tablet-big'}>
        <LetsTalk />
      </Section>
    </>
  );
}

export default UnlockPower;
