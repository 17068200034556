import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';
import { FilterLink } from 'views/work/subcomponents/CaseStudies/subcomponents/FiltersBoxA/subcomponents';

import {
  Industry,
  ProductStage,
  Region,
} from 'views/work/subcomponents/CaseStudies/data/enums';

import './FiltersList.scss';

const FiltersList = ({ activeFilter, label, title, handleClick }) => {
  const isIndustry = label === 'Industry';
  const items = {
    Industry: Object.values(Industry),
    ProductStage: Object.values(ProductStage),
    Region: Object.values(Region),
  };

  return (
    <div
      className={classNames('filters-list-a', {
        'filters-list-a--short': !isIndustry,
      })}
    >
      <span className={'filters-list-a__title'}>{title}</span>
      <ul
        className={classNames('filters-list-a__container', {
          'filters-list-a__container--short': !isIndustry,
        })}
      >
        {items[label].map(filter => (
          <FilterLink
            key={filter}
            isActive={filter === activeFilter}
            filter={filter}
            label={label}
            handleClick={handleClick}
          />
        ))}
      </ul>
    </div>
  );
};

FiltersList.defaultProps = {
  activeFilter: null,
  label: 'Industry',
  title: 'Industry',
  handleClick: () => {},
};

FiltersList.propTypes = {
  activeFilter: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  handleClick: PropTypes.func,
};

export default FiltersList;
