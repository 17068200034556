import { quizQuestions } from 'views/appCostsCalculator/logic/quizQuestions';

// Prepare answers to prefill contact forms
const chosenAnswers = (finalAnswers) => {
  return quizQuestions
    .map((question, index) =>
      question.answers
        .find(answer => answer.type === finalAnswers[index])
    )
    .filter(answer => answer !== undefined)
    .map(answer => answer.content);
}

export default chosenAnswers;
