import React from 'react';
import Slider from './subcomponents/Slider';

import './EtudesSection.scss';

const EtudesSection = () => {
  return (
    <section className={'products-etudes section-box'} id={'etudes-section'}>
      <div className={'padding__container'}>
        <Slider />
      </div>
    </section>
  );
};

export default EtudesSection;
