import React from 'react';
import './Locations.scss';
import { getCloudinaryUrl } from 'config/helpers';

const Locations = () => (
  <div className={'locations__container'}>
    <div className={'location'}>
      <div className={'img-container'}>
        <img
          src={getCloudinaryUrl('gliwice_b.jpg', 'v1692823218')}
          alt={'Location 1'}
        />
        <p>Gliwice</p>
      </div>
    </div>
    <div className={'location'}>
      <div className={'img-container'} style={{ height: '100%' }}>
        <img
          src={getCloudinaryUrl('warszawa_b.png', 'v1692823208')}
          alt={'Location 2'}
        />
        <p>Warszawa</p>
      </div>
    </div>
    <div className={'location'}>
      <div className={'img-container'}>
        <img
          src={getCloudinaryUrl('krakow_b.jpg', 'v1692823212')}
          alt={'Location 4'}
        />
        <p>Kraków</p>
      </div>
    </div>
    <div className={'location'}>
      <div className={'img-container'}>
        <img
          src={getCloudinaryUrl('wroclaw_b.jpg', 'v1692823210')}
          alt={'Location 3'}
        />
        <p>Wrocław</p>
      </div>
    </div>
  </div>
);

export default Locations;
