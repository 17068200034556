import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
// import 'styles/main.scss';
import HomePage from 'views/home';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages.default;
const { navColors } = page;
const url = `${config.siteUrl}`;
const canonical = `${url}`;

const Home = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <NavBar color={navColors} />
      <PopupRedirect homepage />
      <HomePage />
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment markdownFields on MarkdownRemarkConnection {
    edges {
      node {
        excerpt(pruneLength: 400)
        fields {
          slug
        }
        frontmatter {
          title
          order
          content {
            body
          }
          job
          photo
          slug
          cover
          lead
          templateKey
          specialArticle
          isNewWork
          isNewNormal
          service
          settings {
            date
            slug
            type
            slugType
            category
            additionalCategories
            url
          }
          author
          authorAdditional
          box {
            content {
              title
              tileDescription
              coverImage
            }
            coverImage
          }
        }
        id
      }
    }
  }

  fragment boxMarkdownFields on MarkdownRemarkConnection {
    edges {
      node {
        frontmatter {
          box {
            content {
              title
              tileDescription
              tags
              coverImage
            }
            settings {
              tileColor
              textColor
              link
            }
            type
          }
        }
      }
    }
  }
`;

export default Home;
