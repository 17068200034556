export const data = [
  {
    image: 'clients/blablacar-color.png',
    link: '/work/case-story-blablacar/',
    alt: 'Digital product scaling service for BlaBlaCar',
  },
  {
    image: 'clients/sonnen-logo.png',
    link: '/work/case-study-sonnen-digital-transformation/',
    alt: 'Digital transformation services for sonnen',
  },
  {
    image: 'clients/takamol-black.png',
    vertical: true,
  },
  {
    image: 'clients/polco-white.png',
    link: '/work/case-study-polco/',
    alt: 'Digital prototyping and MVP services for Polco',
    black: true,
  },
  {
    image: 'clients/iono-white.png',
    link: '/work/case-study-ionoview/',
    alt: 'Digital product scaling services for Ionoview',
    black: true,
  },
  {
    image: 'clients/holaspirit-b_w.png',
    link: '/work/case-study-holaspirit/',
    alt: 'Product-market fit services for holaSpirit',
  },
  {
    image: 'clients/virgin-color.png',
    link: '/work/case-study-virgin-radio-oman/',
    alt: 'MVP services for Virgin Radio Oman',
  },
  {
    image: 'clients/cryo.png',
  },
  {
    image: 'clients/spa-monkeys.png',
    link: '/work/case-study-spa-monkeys/',
    alt: 'Web development services for SpaMonkeys',
  },
  {
    image: 'clients/lr-beauty.png',
  },
  {
    image: 'clients/fastr.png',
    vertical: true,
  },
  {
    image: 'clients/bw-logo.png',
  },
];
