import React from 'react';
import { Section, BoxList } from 'components';

import './Numbers.scss';
import ClutchBanner from '../ClutchBanner';
import { getRecommendClientImageUrl } from '../../utils';

export default function Numbers() {
  return (
    <Section containerClass={'recommend-client__numbers'}>
      <h2
        className={
          'recommend-client__heading recommend-client__heading--more-spaced'
        }
      >
        Numbers speak for us
      </h2>
      <ClutchBanner />
      <div className={'recommend-client__numbers__columns'}>
        <div className={'recommend-client__numbers__left'}>
          <BoxList>
            <BoxList.Item boxText={'18+'}>Years of experience</BoxList.Item>
            <BoxList.Item boxText={'130+'}>Clients worldwide</BoxList.Item>
            <BoxList.Item boxText={'300+'}>Digital products</BoxList.Item>
            <BoxList.Item boxText={'200+'}>
              Problem-solvers on board
            </BoxList.Item>
          </BoxList>
        </div>
        <div className={'recommend-client__numbers__right'}>
          <div className={'recommend-client__numbers__head'}>
            Awards for our design-powered digital products
          </div>
          <img src={getRecommendClientImageUrl('awards.png')} alt={'Awards'} />
        </div>
      </div>
    </Section>
  );
}
