import './index.scss';
import React from 'react';
import { AWARDS } from 'views/designThatTellsStories/awardsList';
import plusIcon from 'assets/images/design-that-tells-stories/plus_icon.svg';
import numberTwo from 'assets/images/design-that-tells-stories/number_2.svg';
import numberFive from 'assets/images/design-that-tells-stories/number_5.svg';
import awardIcon from 'assets/images/design-that-tells-stories/award_icon.svg';
import AwardBox from './AwardBox';

const Awards = () => {
  return (
    <div className={'bldr-dsgn__section'}>
      <div className={'bldr-dsgn__awards-grid bldr-dsgn__border-box'}>
        <div
          className={
            'bldr-dsgn__border-box bldr-dsgn__heading vertically-centered'
          }
        >
          <div>
            <h2>25 design awards, and still counting!</h2>
            <p>
              Behind each trophy on our shelf there’s a unique story we&apos;ve
              co-created.
            </p>
          </div>
        </div>
        <div
          className={
            'bldr-dsgn__border-box bldr-dsgn__blue-square bldr-dsgn__blue-square--big bldr-dsgn__awards-numbers'
          }
        >
          <img
            src={numberTwo}
            alt={'2'}
            className={'bldr-dsgn__number bldr-dsgn__number--top'}
          />
          <img
            src={numberFive}
            alt={'5'}
            className={'bldr-dsgn__number bldr-dsgn__number--bottom'}
          />
        </div>
        <div className={'bldr-dsgn__elements'}>
          <div
            className={
              'bldr-dsgn__border-box bldr-dsgn__blue-square bldr-dsgn__blue-square--small bldr-dsgn__medal'
            }
          >
            <img src={awardIcon} alt={'MEDAL'} />
          </div>
          <div
            className={
              'bldr-dsgn__border-box bldr-dsgn__blue-square bldr-dsgn__blue-square--small'
            }
          >
            <img src={plusIcon} alt={'PLUS'} />
          </div>
        </div>
      </div>
      <div className={'bldr-dsgn__row bldr-dsgn__awards'}>
        {AWARDS?.map(award => (
          <AwardBox
            name={award.name}
            logo={award.logo}
            color={award.color}
            number={award.number}
            className={award.className}
          />
        ))}
      </div>
    </div>
  );
};

export default Awards;
