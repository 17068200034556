import React from 'react';
import {
  Container,
  Heading,
  Section,
  Paragraph,
  Link,
  IconSVG,
} from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Star from './Star';
import './Clutch.scss';

const getStars = () => {
  const stars = Array.from({ length: 4 }, (_, i) => <Star filled key={i} />);
  stars.push(<Star key={4} />);
  return stars;
};

const Clutch = () => {
  return (
    <Section additionalClass={'clutch-section'} color={'pigeon-post'}>
      <Heading size={'large'}>Curious about our reliability?</Heading>
      <Container additionalClass={'clutch-section__container'}>
        <div className={'clutch-section__container--logo'}>
          <img
            src={getCloudinaryUrl(
              'gatsby-cloudinary/clutch-logo.png',
              'f_auto,q_auto,w_auto',
            )}
            alt={'Clutch.co platform logo'}
          />
        </div>
        <div>
          <Paragraph
            size={'medium-plus-small'}
            color={'white'}
            additionalClass={'clutch-section__container--text'}
          >
            53 Clutch reviews from verified clients worldwide
          </Paragraph>
          <Paragraph
            size={'medium-plus-small'}
            color={'white'}
            additionalClass={'clutch-section__container--text'}
          >
            <span>4.8</span> average referral rating
            <div>{getStars()}</div>
          </Paragraph>
          <Paragraph
            size={'medium'}
            color={'white'}
            additionalClass={'clutch-link'}
          >
            <Link
              to={'https://clutch.co/profile/boldare'}
              arrow
              id={'ai-clutch-section-link'}
            >
              Read our outstanding reviews on Clutch
              <IconSVG name={'arrow-right'} color={'white'} />
            </Link>
          </Paragraph>
        </div>
        <div className={'clutch-section__container--image'}>
          <img
            src={getCloudinaryUrl(
              'assets/ai-software/clutch-badges.png',
              'f_auto,q_auto,w_auto',
            )}
            alt={'Clutch global 2019 top 1000 companies badge and Clutch top developers Poland 2020 and 2021 badges'}
          />
        </div>
      </Container>
      <Paragraph size={'medium'} color={'white'}>
        Our client retention rate says it all: 80% choose to work with us again
        on their new digital projects.
      </Paragraph>
    </Section>
  );
};

export default Clutch;
