import './index.scss';
import React from 'react';

const Subheading = ({ style }) => {
  return (
    <span className={'dsgn-hero__animated-subheading'}>
      <span style={style}>Design</span> <span style={style}>that</span>
    </span>
  );
};

export default Subheading;
