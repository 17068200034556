import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Paragraph, Heading } from 'components';
import { Col } from 'react-flexbox-grid';

import './RedirectBannerTile.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { classNames } from 'utils';

const RedirectBannerTile = props => {
  const {
    imgSrc,
    title,
    description,
    category,
    url,
    id,
    additionalClass,
  } = props;

  return (
    <Col
      className={classNames('redirect-banner', additionalClass)}
      sm={12}
      md={6}
      lg={4}
    >
      <img
        src={getCloudinaryUrl(imgSrc, 'f_auto,q_100')}
        alt={`${title} banner`}
        className={'image redirect-banner__image'}
        loading={'lazy'}
      />
      <div className={'redirect-banner__info'}>
        <div className={'redirect-banner__top'}>
          <Paragraph
            size={'small'}
            color={'red'}
            additionalClass={'redirect-banner__category'}
          >
            {category}
          </Paragraph>
          <Heading
            type={2}
            size={'big'}
            additionalClass={'redirect-banner__title'}
          >
            {title}
          </Heading>
          <Paragraph
            size={'medium'}
            additionalClass={'redirect-banner__description'}
          >
            {description}
          </Paragraph>
        </div>
        <div className={'redirect-banner__bottom'}>
          <a className={'link'} href={url} id={id}>
            <Button
              additionalClass={'redirect-banner__button'}
              color={'transparent'}
              ariaName={`${title} button`}
            >
              Watch now
            </Button>
          </a>
        </div>
      </div>
    </Col>
  );
};

RedirectBannerTile.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  additionalClass: PropTypes.string,
};

RedirectBannerTile.defaultProps = {
  title: '',
  description: '',
  category: '',
  additionalClass: '',
};

export default memo(RedirectBannerTile);
