import React from 'react';
import PropTypes from 'prop-types';

import './FramedAvatar.scss';

function FramedAvatar({ src, alt, className }) {
  return <img src={src} alt={alt} className={`framed-avatar ${className}`} />;
}

FramedAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FramedAvatar.defaultProps = {
  className: '',
};

export default FramedAvatar;
