import norbertBaumannSonnen from 'assets/images/products/norbert-baumann-sonnen.png';
import teamAlert from 'assets/images/products/team-alert.png';
import katarzynaGreczkaBoldare from 'assets/images/products/katarzyna-greczka-boldare.png';

export const data = [
  {
    name: 'Norbert Baumann',
    role: 'VP Research & Development at sonnen',
    quote:
      'Boldare stood out as a company who really wanted to understand our problem and fulfill our needs. If you see that the potential partner is on the same page as you, speaking the same language, trying to achieve something in a partnership and not just sign a contract, that is a winner.',
    img: norbertBaumannSonnen,
  },
  {
    name: 'Team Alert',
    role: 'CEO of Team Alert',
    quote:
      'A partnership is something more long term and I consider Boldare as partners with us, I could not be more thrilled.',
    img: teamAlert,
  },
  {
    name: 'Katarzyna Greczka',
    role: 'Product Designer at Boldare',
    quote:
      'Working on an accessible app is a challenge that requires a permanent focus on both users needs and business goals. What is precious, this product is not just another commercial solution — it gives the real value that the whole team appreciate.',
    img: katarzynaGreczkaBoldare,
  },
];
