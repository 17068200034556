export const circlesData = [
  {
    image: 'img01.jpg',
    title: 'Target analysis',
  },
  {
    image: 'img02.jpg',
    title: 'Competitors analysis',
  },
  {
    image: 'img03.jpg',
    title: 'Business values',
  },
  {
    image: 'img04.jpg',
    title: 'Business weakness',
  },
];

export const tilesData = [
  {
    image: 'models.jpg',
    title: 'Potential models',
    text: 'Who are already the hostess or would like to become the models',
  },
  {
    image: 'customers.jpg',
    title: 'Potential customers',
    text:
      'Who can visit and look at the models’ catalog, filtering be several chosen parameters',
  },
];

export const imagesData = [
  {
    title: 'Product canvas workshop',
    images: ['mood-1.jpg', 'mood-2.jpg'],
  },
  {
    title: 'App architecture',
    images: ['mood-3.jpg', 'mood-4.jpg'],
  },
  {
    title: 'Moodboards',
    images: ['mood-5.jpg'],
  },
  {
    title: 'Wireframes',
    images: ['mood-6.jpg'],
  },
];
