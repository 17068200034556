export const data = [
  {
    title: 'Prototype',
    description:
      'Presented within a few days, a prototype gives you an idea of the possible capabilities of the product. This way we can test first assumptions and plan for further development.',
    icon: 'prototype',
    img: 'machine-learning/ml-step-1.png',
  },
  {
    title: 'Minimum Viable Product',
    description:
      'This is the first version of your machine learning solution. It has limited features and accuracy, but is usable for testing.',
    icon: 'mvp',
    img: 'machine-learning/ml-step-2.png',
  },
  {
    title: 'Product-Market Fit',
    description:
      'In this stage, models and algorithms are tested and tweaked to achieve the best fit for the market. You also put pressure on accuracy.',
    icon: 'fit',
    img: 'machine-learning/ml-step-3.png',
  },
  {
    title: 'Product Scaling',
    description:
      'Scaling is all about performance and stability. In this stage, we also focus on the solution architecture (eg. microservices).',
    icon: 'scale',
    img: 'machine-learning/ml-step-4.png',
  },
];
