import React from 'react';
import Layout from 'components/layout';
import { NavBar, FooterMini } from 'components';
import { pages } from 'config/pages';
import EventRegistrationPage from 'views/eventRegistrationPage';
import config from '../../meta/config';
import { events } from '../data/eventsData';

const page = pages['practical-ai-application'];
const canonical = `${config.siteUrl}/practical-ai-application/`;

const AiWebinar = () => {
  const { navColors, button, siteCover } = page;
  const formHandler = page.formHandlers.default;
  const eventData = events['practical-ai-application'];

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} bannerHidden />
      <EventRegistrationPage
        button={button}
        url={formHandler}
        eventData={eventData}
        cover={siteCover}
      />
      <FooterMini />
    </Layout>
  );
};

export default AiWebinar;
