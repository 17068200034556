import React, { PureComponent } from 'react';
import { Section, Paragraph, Link } from 'components';
import Logo from 'assets/images/logo-signet.svg';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';
import './FooterMini.scss';

const Clutch = 'gatsby-cloudinary/Clutch_Badge.png';
const Goodfirms = 'gatsby-cloudinary/Goodfirms_Badge.png';
const Awwwards = 'gatsby-cloudinary/awwwards-badge-16.png';
const GermanDesignAward = 'gatsby-cloudinary/german-design-award_2x.png';

class FooterMini extends PureComponent {
  render = () => {
    return (
      <Section color={'mine-shaft'} fullwidth spaceless>
        <div className={'mini-footer'}>
          <div className={'mini-footer__top'}>
            <div className={'mini-footer__top-logo'}>
              <Link to={'/'}>
                <img
                  src={Logo}
                  alt={'Digital product design company Boldare'}
                  loading={'lazy'}
                />
              </Link>
              <Paragraph size={'medium'} color={'white'}>
                Digital product creators &amp; consultants
              </Paragraph>
            </div>
            <div className={'mini-footer__top-badges'}>
              <Link to={config.clutch} id={'footer-clutch'} rel={'nofollow'}>
                <img
                  src={getCloudinaryUrl(Clutch, 'f_auto,q_auto,h_70')}
                  alt={'Clutch Badge'}
                  loading={'lazy'}
                />
              </Link>
              <Link
                to={config.goodfirms}
                id={'footer-goodfirms'}
                rel={'nofollow'}
              >
                <img
                  src={getCloudinaryUrl(Goodfirms, 'f_auto,q_auto,h_70')}
                  alt={'GoodFirms Badge'}
                  loading={'lazy'}
                />
              </Link>
              <Link
                to={config.awwwards}
                id={'footer-awwwards'}
                rel={'nofollow'}
              >
                <img
                  src={getCloudinaryUrl(Awwwards, 'f_auto,q_auto,h_70')}
                  alt={'Awwwards Badge'}
                  loading={'lazy'}
                />
              </Link>
              <Link to={config.gda} id={'footer-gda'} rel={'nofollow'}>
                <img
                  src={getCloudinaryUrl(
                    GermanDesignAward,
                    'f_auto,q_auto,h_70',
                  )}
                  alt={'German Design Awards Badge'}
                  loading={'lazy'}
                />
              </Link>
            </div>
          </div>
          <div className={'mini-footer__bottom'}>
            <Paragraph size={'small'} color={'white'}>
              &#169; {new Date().getFullYear()} Boldare. All rights reserved.
            </Paragraph>
          </div>
        </div>
      </Section>
    );
  };
}

export default FooterMini;
