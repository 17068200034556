import React from 'react';
import { Section, Heading, Paragraph, FullwidthImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Organism.scss';

const Organism = () => {
  return (
    <Section additionalClass={'sm__organism-container'} spaceless>
      <div className={'sm__organism-background--green'} />
      <div className={'sm__organism-background--blue'} />
      <div className={'sm__content'}>
        <Heading type={2} size={'big-spreaded'}>
          Whole organism
        </Heading>
        <Paragraph
          additionalClass={'text--light'}
          size={'medium-constant'}
          color={'mine-shaft'}
        >
          Spa Monkeys delivers complete solution in spa, beauty and wellness
          industry in Hong Kong. It was achieved with a help of business tools
          such as online shop and places catalogue joint with blog, main
          marketing tool. Our role was to gradually implement those
          comprehensive solutions in a way to guarantee the highest comfort for
          users and the best place for business owners.
        </Paragraph>
      </div>
      <div className={'sm__organism-images'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-rwd-bg-width.png',
            'f_auto,q_auto',
          )}
          alt={'Apps'}
        />
      </div>
      <div className={'sm__organism-images'}>
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-long-screen.jpg',
            'f_auto,q_auto',
          )}
          alt={'Blog view'}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-long-screen2.jpg',
            'f_auto,q_auto',
          )}
          alt={'Reviews views'}
        />
        <FullwidthImage
          image={getCloudinaryUrl(
            'assets/spa-monkeys/spamonkeys-long-screen3.jpg',
            'f_auto,q_auto',
          )}
          alt={'Shop view'}
        />
      </div>
    </Section>
  );
};

export default Organism;
