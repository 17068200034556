import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { IconSVG, Link } from 'components';
import Image from '../Image';
import './SectionFounders.scss';

const SectionFounders = ({ isDesktop }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const handleModalVisibility = e => {
      if (e.key === 'Escape') setIsModalVisible(false);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleModalVisibility);
    }
    return () => {
      window.removeEventListener('keydown', handleModalVisibility);
    };
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isModalVisible]);
  const toggleModal = () => {
    setIsModalVisible(prevIsVisible => !prevIsVisible);
  };

  return (
    <div className={'section-wrapper section-founders'}>
      <div
        className={classNames('section-founders__modal', {
          'section-founders__modal--hidden': !isModalVisible,
          'section-founders__modal--visible': isModalVisible,
        })}
      >
        <button onClick={toggleModal}>
          <IconSVG
            additionalClass={'section-founders__modal__close'}
            size={'large'}
            name={'close'}
            color={'black'}
          />
        </button>
        <section className={'grid-layout'}>
          <h3 className={'section-founders__modal__title'}>About</h3>
          <p className={'section-founders__modal__text'}>
            Anna Zarudzka and Piotr Majchrzak are co-CEO’s of Boldare, an Agile
            and Lean Startup-powered product design and development company.
          </p>
          <p className={'section-founders__modal__text'}>
            Anna Zarudzka is an acknowledged speaker and an ambassador of New
            Work, Self-Management and so called "Next Generation"
            Organizations, driven by purpose not shareholders and empowering
            employees to make decisions and impact company direction. Piotr
            Majchrzak is an entrepreneur with a software development
            background, with numerous companies in his business portfolio
          </p>
          <p className={'section-founders__modal__text'}>
            While Piotr, a thoughtful rebel, looks after vision, constantly
            pushing towards new cutting-edge technologies and management
            practices, Anna is a spirited perfectionist, looking after
            processes and high service standards. Together they question the
            status quo in their industry and surround themselves with equally
            disruptive people.
          </p>
          <h3 className={'section-founders__modal__title'}>
            Anna Zarudzka in media
          </h3>
          <Link
            to={'https://www.linkedin.com/in/annazarudzka/'}
            additionalClass={'article-link'}
            blank
          >
            Linkedin
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={
              'https://medium.com/authority-magazine/preparing-for-the-future-of-work-anna-zarudzka-of-boldare-on-the-top-five-trends-to-watch-in-the-7ef6fc10656'
            }
            additionalClass={'article-link'}
            blank
          >
            Preparing For The Future Of Work: Anna Zarudzka of Boldare On The
            Top Five Trends To Watch In The Future Of Work
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={'https://open.spotify.com/episode/7iB1yifvHjcIJatuHLCL4t'}
            additionalClass={'article-link'}
            blank
          >
            Future Candy - das neue Normal – Mit Anna Zarudzka (Podcast in
            English)
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={
              'https://t3n.de/news/silicon-polska-tech-hotspot-polen-1369122'
            }
            additionalClass={'article-link'}
            blank
          >
            Silicon Polska - the tech hotspot in the European neighborhood
            (article in German)
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={
              'https://www.gq-magazin.de/lifestyle/artikel/gquestionnaire-anna-zarudzka-von-boldare-beantwortet-den-gq-business-fragebogen'
            }
            additionalClass={'article-link'}
            blank
          >
            “The best career decision? Being a business hippie” – Boldare's
            Anna Zarudzka answers the GQ business questionnaire (article in
            German)
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <h3 className={'section-founders__modal__title'}>
            Piotr Majchrzak in media
          </h3>
          <Link
            to={'https://www.linkedin.com/in/piotrmajchrzak/'}
            additionalClass={'article-link'}
            blank
          >
            Linkedin
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={'https://ownersmag.com/boldare-shakes-the-workplace-up/'}
            additionalClass={'article-link'}
            blank
          >
            Boldare Shakes The Workplace Up. For Good
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
          <Link
            to={
              'https://www.cmm360.ch/holakratie-in-unternehmen-aufbau-funktionsweise-und-gehaltsverhandlungen'
            }
            additionalClass={'article-link'}
            blank
          >
            Holacracy in companies: structure, functioning - and salary
            negotiations
            <IconSVG
              additionalClass={'arrow-svg'}
              name={'arrow-right'}
              color={'mine-shaft'}
            />
          </Link>
        </section>
      </div>
      <section className={'grid-layout'}>
        <h2 className={'about-header'}>
          Meet our{' '}
          <span className={'marker-highlight marker-black'}>founders</span>
        </h2>
        {isDesktop && (
          <div
            onMouseEnter={() => {
              setIsShown(true);
            }}
            onMouseLeave={() => {
              setIsShown(false);
            }}
            onClick={() => {
              setIsModalVisible(true);
            }}
            className={'section-founders__wrapper'}
          >
            <Image
              imageName={'about-us-founders-normal'}
              className={`section-founders__image`}
            />
            <Image
              imageName={'about-us-founders-hover-2'}
              className={`section-founders__image--hover ${!isShown &&
                'hide'}`}
            />
          </div>
        )}
        {!isDesktop && (
          <Image
            imageName={'about-us-founders-normal'}
            className={`section-founders__image`}
          />
        )}
        <div
          onClick={() => {
            setIsModalVisible(true);
          }}
          className={classNames('section-founders__title', {
            'section-founders__title--underscored': isShown,
          })}
        >
          Piotr Majchrzak and Anna Zarudzka
        </div>
        <p
          onClick={() => {
            setIsModalVisible(true);
          }}
          className={classNames('section-founders__description', {
            'section-founders__description--underscored': isShown,
          })}
        >
          Boldare's co-CEOs are a textbook example of a power couple...{' '}
          <button
            onClick={toggleModal}
            onMouseEnter={() => {
              setIsShown(true);
            }}
            onMouseLeave={() => {
              setIsShown(false);
            }}
            className={'section-founders__link'}
          >
            read more
          </button>
        </p>
      </section>
    </div>
  );
};

export default SectionFounders;

SectionFounders.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};
