import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['machine-learning-for-business'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/machine-learning-for-business/`;

const MachineLearningForBusinessPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={`Machine Learning for Business. A practical guide`}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/Machine-Learning-for-Business-ebook-cover-photo.png',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-ml.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Machine Learning for Business Practical Guide'}
        description={
          'Download our experience-backed machine learning ebook to get tips on:'
        }
        items={[
          'How ML can boost your business',
          'How to avoid ML obstacles and limitations',
          'How much it can cost',
          'How you can use ML in real-life cases',
        ]}
        url={url}
        button={'Send me the ebook'}
        trackingEvent={`ebookSignupMachineLearningForBusiness`}
      />
    </Layout>
  );
};

export default MachineLearningForBusinessPage;
