import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, BoxContent, BoxFooter, Paragraph, Button } from 'components';
import './BoxCTA.scss';

class BoxCTA extends Component {
  render() {
    const { additionalClass, name, fixed } = this.props;

    return (
      <Box
        id={`contact-${name}`}
        backgroundColor={'yellow'}
        additionalClass={`box--imageless ${name === 'app-costs-calculator' &&
          'box-cta--app-costs-calculator'} ${
          fixed ? '' : 'anim-fade-in-bottom'
        } ${additionalClass}`}
      >
        {name === 'app-costs-calculator' ? (
          <>
            <BoxContent>
              <Paragraph
                size={'big'}
                color={'black'}
                additionalClass={'text--bold box-cta__heading'}
              >
                Curious about your&nbsp;app costs?
              </Paragraph>
              <Paragraph additionalClass={'box-cta__description'}>
                Get a rough estimate, team size, and time frame to build your
                app, in just a few clicks
              </Paragraph>
              <a href={'/resources/app-costs-calculator/'}>
                <Button
                  id={`contact-${name}`}
                  additionalClass={'contact-button'}
                  color={'white'}
                  ariaName={'Contact button'}
                >
                  Get estimate
                </Button>
              </a>
            </BoxContent>
            <BoxFooter>
              <img
                src={
                  'https://res.cloudinary.com/de4rvmslk/image/upload/v1700842647/app-costs-calculator/app-cost-image.png'
                }
                alt={'App costs calculator'}
              />
            </BoxFooter>
          </>
        ) : (
          <>
            <BoxContent>
              <Paragraph
                size={'big'}
                color={'black'}
                additionalClass={'text--bold'}
              >
                Let&apos;s talk
              </Paragraph>
              <Paragraph additionalClass={'box-cta__description'}>
                We&apos;d love to help with your product. Get in touch.
              </Paragraph>
            </BoxContent>
            <BoxFooter>
              <a href={'/contact/#form'}>
                <Button
                  id={`contact-${name}`}
                  additionalClass={'contact-button'}
                  color={'black'}
                  transparent
                  ariaName={'Contact button'}
                >
                  Contact
                </Button>
              </a>
            </BoxFooter>
          </>
        )}
      </Box>
    );
  }
}

BoxCTA.defaultProps = {
  additionalClass: '',
  fixed: false,
};

BoxCTA.propTypes = {
  additionalClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
};

export default BoxCTA;
