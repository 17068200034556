import React from 'react';
import PropTypes from 'prop-types';

const ScrollAnchor = ({ children, id }) => {
  let isAnchor = false;
  if (typeof window !== 'undefined' && window.location) {
    isAnchor = id && window.location.pathname !== '/admin/';
  }

  return isAnchor ? <div id={id}>{children}</div> : <>{children}</>;
};

ScrollAnchor.defaultProps = {
  id: '',
  children: null,
};

ScrollAnchor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string,
};

export default ScrollAnchor;
