import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './InputWithButton.scss';

const InputWithButton = ({ additionalClass, buttonText, placeholder }) => (
  <div className={classNames('input-with-button', additionalClass)}>
    <input
      className={'input-with-button__field'}
      name={'input'}
      autoComplete={'off'}
      placeholder={placeholder}
    />
    <button
      type={'submit'}
      className={'input-with-button__button'}
      aria-label={'Input Button'}
    >
      {buttonText}
    </button>
  </div>
);

InputWithButton.defaultProps = {
  additionalClass: '',
};

InputWithButton.propTypes = {
  additionalClass: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputWithButton;
