import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { pages } from 'config/pages';
import { NavBar } from 'components';
import Assets from 'views/assets';

const AssetsPage = () => {
  const page = pages.assets;

  return (
    <Layout {...{ page }}>
      <Helmet title={'Assets'}>
        <meta name={'robots'} content={'noindex,nofollow'} />
      </Helmet>
      <NavBar color={page.navColors} />
      <Assets />
    </Layout>
  );
};

export default AssetsPage;
