import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from 'components';
import { getArticleByName } from 'config/helpers';
import { navigateToId } from 'utils';
import { SinglePostTile, SinglePostTileBig, PostList } from './subcomponents';
import NewWorkContact from './subcomponents/BlogContact/new-work-contact';
import './Blog.scss';
import config from '../../../meta/config';

class NewWorkBlog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters
        .sort((a, b) => a.order - b.order)
        .map(filter => ({ name: filter.name, isActive: false })),
      filtersActive: false,
    };
  }

  handleFilterStatus = () =>
    this.setState(prevState => ({
      filtersActive:
        prevState.filters.filter(item => item.isActive).length > 0,
    }));

  toggleFilter = filter => {
    const { filters } = this.state;
    this.setState({
      filters: filters.map(item => ({
        ...item,
        isActive: item.name === filter,
      })),
    });
    this.handleFilterStatus();
    navigateToId('blog-post-list');
  };

  render() {
    const { filters, filtersActive } = this.state;
    const { posts, authors, toggleFilter } = this.props;
    const { editorsPick } = config.blog.newWork;
    const { leftTile, rightTile } = editorsPick;

    const firstArticle = getArticleByName(leftTile, posts);
    const secondArticle = getArticleByName(rightTile, posts);

    return (
      <div className={'blog__container new-work'}>
        <Section
          color={'pigeon-post'}
          additionalClass={'editor-intro__wrapper'}
        >
          <SectionHeader
            lineColor={'yellow'}
            size={'big'}
            color={'black'}
            description={`Exploring the future of work`}
            descriptionColor={'black'}
            fixed
          >
            New Work
          </SectionHeader>
          <Row className={'editor-intro__row'}>
            <SinglePostTileBig
              post={firstArticle}
              authors={authors}
              toggleFilter={toggleFilter}
              theme={'black'}
              linkClass={'link--full-height'}
            />

            <SinglePostTile
              post={secondArticle}
              authors={authors}
              toggleFilter={toggleFilter}
              theme={'black'}
            />
          </Row>
        </Section>

        <PostList
          posts={posts}
          filters={filters}
          filtersActive={filtersActive}
          toggleFilter={this.toggleFilter}
          authors={authors}
        />
        <NewWorkContact />
      </div>
    );
  }
}

NewWorkBlog.propTypes = {
  posts: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
};

export default NewWorkBlog;
