import React from 'react';

// Component used for A/B tests to prevent Gatsby
// from generating static html with one chosen version of component

export const ClientSideComponent = ({ children }) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? null : children;
};
