import React from 'react';

import { Section } from 'components';

import { getRecommendClientImageUrl } from '../../utils';
import ContactForm from '../ContactForm';

import './BottomForm.scss';

const SECTION_ID = 'bottom-form';

function Form() {
  return (
    <Section
      color={'yellow'}
      id={SECTION_ID}
      fullwidth
      spaceless
      containerClass={'recommend-client__bottom-form'}
    >
      <img
        src={getRecommendClientImageUrl('refferal-3.png')}
        alt={'Workshop - audiance'}
      />

      <div className={'recommend-client__bottom-form__form'}>
        <ContactForm id={SECTION_ID} name={'join-refferal-program-bottom'} />
      </div>
    </Section>
  );
}

export default Form;
