import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Button, Paragraph } from 'components';
import './CookieInfo.scss';

class CookieInfo extends Component {
  static COOKIES_ACCEPTED_COOKIE = 'cookies-accepted';

  constructor(props) {
    super(props);

    this.state = {
      areCookiesAccepted:
        cookie.load(this.constructor.COOKIES_ACCEPTED_COOKIE) === 'true',
    };
  }

  componentDidMount() {
    if (cookie.load(this.constructor.COOKIES_ACCEPTED_COOKIE) === 'true') {
      document.body.classList.add('cookie-bar-accepted');
    }
  }

  handleCookiesAccept = () => {
    this.setState({ areCookiesAccepted: true });
    cookie.save(this.constructor.COOKIES_ACCEPTED_COOKIE, true, { path: '/' });

    document.body.classList.add('cookie-bar-accepted');
  };

  render() {
    const { areCookiesAccepted } = this.state;

    return areCookiesAccepted ? null : (
      <div className={'cookie-info'} id={'cookies-bar'}>
        <div className={'cookie-info__container'}>
          <Paragraph
            color={'white'}
            additionalClass={'cookie-info__description text--default'}
          >
            We use cookies. By continuing to browse the site you are agreeing
            to&nbsp;
            <a className={'link'} href={'/privacy-policy/'}>
              our privacy policy
            </a>
            .
          </Paragraph>
          <Button
            color={'white'}
            onClick={() => this.handleCookiesAccept()}
            additionalClass={'cookie-info__button'}
            ariaName={'Cookie info button'}
            transparent
          >
            Accept and close
          </Button>
        </div>
      </div>
    );
  }
}

export default CookieInfo;
