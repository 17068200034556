import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link, Button, Paragraph, Heading, Checkbox } from 'components';
import { serialize, classNames } from '../../utils';
import './ResourceForm.scss';

class ResourceForm extends Component {
  constructor() {
    super();

    this.refForm = createRef();

    this.state = {
      subscribed: false,
      optedForMarketing: false,
    };
  }

  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      window.location.href.indexOf('downloadedResource=true') !== -1
    ) {
      this.setState({ subscribed: true });
    }
  }

  setSubscribe = () => {
    const url = window.location.href;
    const joinChar = url.indexOf('?') > -1 ? '&' : '?';

    window.location.href = `${url + joinChar}downloadedResource=true`;
  };

  handleCheckboxChange = () => {
    const { optedForMarketing } = this.state;

    this.setState(prevState => ({
      ...prevState,
      optedForMarketing: !optedForMarketing,
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    const { url, trackingEvent } = this.props;
    const form = this.refForm.current;

    this.setState({ isLoading: true });

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: serialize(form),
    })
      .then(() => {
        if (typeof window !== 'undefined') {
          if (window.datalayer && trackingEvent) {
            window.dataLayer.push({
              event: trackingEvent,
            });
          }

          if (window.fbq && typeof window.fbq === 'function') {
            window.fbq('track', 'Download');
          }
        }

        if (window.location.pathname.indexOf('/resources/') !== -1) {
          this.setSubscribe();
        } else {
          this.setState({ subscribed: true });
        }
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      type,
      button,
      formHeading,
      itemsQuestions,
      btnColor,
      isMarketingCheckbox,
    } = this.props;
    const { subscribed, isLoading, optedForMarketing } = this.state;

    return (
      <div className={'resource-form'}>
        {subscribed ? (
          <div className={'resource-form--sent'}>
            {isMarketingCheckbox ? (
              <>
                <Heading size={'big'} type={2}>
                  We’ve emailed you a download link. <br /> Check your inbox
                  and enjoy!
                </Heading>
                <Button
                  tag={'a'}
                  style={{
                    backgroundColor: '#EEEEEE',
                    marginTop: '2em',
                    maxWidth: '320px',
                  }}
                  href={'/about'}
                  target={'_blank'}
                  transparent
                  color={'black'}
                >
                  LEARN MORE ABOUT BOLDARE →
                </Button>
              </>
            ) : (
              <Heading
                size={'medium-plus'}
                additionalClass={'text--default'}
                type={3}
              >
                Thank you!
                <br />
                <br />
                We’ve emailed you a download link. Check your inbox and enjoy!
              </Heading>
            )}
          </div>
        ) : (
          <>
            <Heading
              size={'medium'}
              type={2}
              additionalClass={'resource-form__headline'}
            >
              {ReactHtmlParser(formHeading || `Get your free ${type} now`)}
            </Heading>
            <form
              className={'resource-form__inputs'}
              onSubmit={this.handleSubmit}
              ref={this.refForm}
            >
              <input
                type={'text'}
                name={'name'}
                placeholder={'First name'}
                required
              />
              <input
                type={'text'}
                name={'lastName'}
                placeholder={'Last name'}
                required
              />
              <input
                type={'email'}
                name={'email'}
                placeholder={'E-mail address'}
                required
              />
              <input
                type={'text'}
                name={'company'}
                placeholder={'Company name'}
                required
              />
              {itemsQuestions && (
                <input
                  type={'text'}
                  name={'job'}
                  placeholder={'Job title'}
                  required
                />
              )}
              {isMarketingCheckbox ? (
                <Checkbox
                  required
                  additionalClass={'resource-form__checkbox'}
                  checked={optedForMarketing}
                  name={'optedForMarketing'}
                  value={'optedForMarketing'}
                  onChange={this.handleCheckboxChange}
                  label={
                    ' I agree to receive communications from Boldare. Communications may include updates on upcoming events, useful business insights and promotional materials about our services.'
                  }
                />
              ) : (
                ''
              )}

              <Button
                id={'resource-form-submit'}
                type={'submit'}
                color={btnColor}
                additionalClass={classNames('resource-form__button', {
                  loading: isLoading,
                })}
                ariaName={'Form button'}
                disabled={isLoading}
                loading
              >
                {button}
              </Button>
            </form>
            {isMarketingCheckbox ? (
              <Paragraph additionalClass={'resource-form__info text--light'}>
                Boldare may use your personal information for internal business
                and marketing purposes, including but not limited to event
                registration or in order to contact you and talk about your
                project, as well as other matter that may be of interest to
                you. The data will be hosted with Salesforce’s CRM system in
                accordance with our&nbsp;
                <a href={'/privacy-policy/'} className={'link'}>
                  Privacy Policy
                </a>
                . The information will not be sold to any third party. You may
                unsubscribe at any time. For more information on how to
                unsubscribe, our privacy practices please review our&nbsp;
                <a href={'/privacy-policy/'} className={'link'}>
                  Privacy Policy
                </a>
                .
              </Paragraph>
            ) : (
              <Paragraph additionalClass={'resource-form__info text--light'}>
                {
                  "We're committed to your privacy. Boldare uses the information you provide to us to contact you about our relevant content, products, and services."
                }
                {' Read our '}
                <Link
                  to={'/privacy-policy/'}
                  id={'resource-form-privacy-policy'}
                >
                  privacy policy.
                </Link>
              </Paragraph>
            )}
          </>
        )}
      </div>
    );
  }
}

ResourceForm.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string.isRequired,
  formHeading: PropTypes.string,
  itemsQuestions: PropTypes.string,
  redirectButton: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
  }),
  short: PropTypes.bool,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isMarketingCheckbox: PropTypes.bool,
};

ResourceForm.defaultProps = {
  short: false,
  formHeading: '',
  btnColor: 'white',
  itemsQuestions: false,
  redirectButton: { path: '', label: '' },
  isMarketingCheckbox: false,
};

export default ResourceForm;
