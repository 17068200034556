export const data = [
  {
    name: 'Timur Karimov',
    position: 'Java Developer',
    quote:
      '“After working here for a few months now, I see people here are open-minded, ready for challenges, working in a friendly environment, and implementing real scrum.”',
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-timur.png',
    alt: 'Working at Boldare - Timur',
  },
  {
    name: 'Paulina Siemieniuk',
    position: 'Junior Candidate Care Specialist',
    quote:
      "“An amazingly tailored and transparent recruitment process made Boldare stand out from other companies I came into contact with. Hearing about transparency, open communication, and autonomy over one's tasks made me want to join the team.”",
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-paulina.png',
    alt: 'Working at Boldare - Paulina',
  },
  {
    name: 'Aleksander Ujek',
    position: '.NET Developer',
    quote:
      '“The idea of working in a holacracy, which is totally new to me, was thrilling. I really like the idea of not being only a .NET developer, but a part of the team, where I can take the role beyond my position.”',
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-aleksander.png',
    alt: 'Working at Boldare - Aleksander',
  },
  {
    name: 'Karol Witkowski',
    position: 'Product Designer',
    quote:
      '“I was encouraged by the structured design process, the scale of the business, its portfolio level, and the different stages of product development for clients, which allows you to work in different contexts and gives room for development.”',
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-karol.png',
    alt: 'Working at Boldare - Karol',
  },
  {
    name: 'Aneta Orszewska',
    position: 'New Products Guide',
    quote:
      '“I joined you because of the perfect mix of product design and development excellence, organizational culture, global reach, future-looking drive, and unparalleled openness in finding the best role for me.”',
    cloudinaryDir:
      'remote-work-landing/boldare-product-design-development-company-aneta.png',
    alt: 'Working at Boldare - Aneta',
  },
];
