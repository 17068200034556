import React from 'react';

import { Section, BigNumberOrderedList } from 'components';
import { getRecommendClientImageUrl } from '../../utils';

import './WhyWorthRecommendation.scss';

export default function WhyWorthRecommendation() {
  return (
    <Section
      side={'right'}
      spaceless
      containerClass={'recommend-client__why-worth-recommendation'}
    >
      <h2
        className={
          'recommend-client__heading recommend-client__heading--more-spaced'
        }
      >
        4 reasons to recommend Boldare:
      </h2>
      <div className={'recommend-client__why-worth-recommendation__wrapper'}>
        <BigNumberOrderedList
          className={'recommend-client__why-worth-recommendation__list'}
        >
          <BigNumberOrderedList.Item
            subtext={
              "Boldare's 67% NPS score reflects high customer satisfaction and willingness to recommend us."
            }
          >
            Our NPS score is 67%
          </BigNumberOrderedList.Item>
          <BigNumberOrderedList.Item
            subtext={
              'Boldare helps companies at each stage of the product development cycle: from ideating with a prototype, testing a product with an MVP, to finding a product-market fit and finally, scaling.'
            }
          >
            Full Cycle Product Development
          </BigNumberOrderedList.Item>
          <BigNumberOrderedList.Item
            subtext={
              'Our dedicated teams work with both frontend and backend technologies and meet the highest standards of the software development process. Our developers will recommend the best technologies and programming languages to suit the business needs.'
            }
          >
            Technology stack
          </BigNumberOrderedList.Item>
          <BigNumberOrderedList.Item
            subtext={
              <>
                Using Lean Startup and Agile we spend our client&apos;s money
                only on what brings real value to their businesses. We use
                iterations to validate assumptions on the market, with real
                users.
                <br /> <br /> We can proudly say that we practice Agile better
                than the competition.
              </>
            }
          >
            Agile & lean startup approach
          </BigNumberOrderedList.Item>
        </BigNumberOrderedList>

        <img
          className={'recommend-client__why-worth-recommendation__image'}
          src={getRecommendClientImageUrl('why-worth-recommendation-hero.png')}
          width={'714'}
          height={'746'}
          loading={'lazy'}
          alt={'Team'}
        />
      </div>
    </Section>
  );
}
