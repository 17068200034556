import React, { useMemo } from 'react';
import {
  Paragraph,
  RelatedArticles,
  Section,
  SectionHeader,
} from 'components';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import './ArticlesSection.scss';

const ArticlesSection = ({ title, description, articles }) => {
  const related = useMemo(() => {
    const { edges } = articles;

    return edges.map(({ node }) => {
      const { frontmatter } = node;
      const { title: articleTitle } = frontmatter;

      return { title: articleTitle };
    });
  }, [articles]);

  return (
    <>
      <Section color={'white'} additionalClass={'articles-section'}>
        <SectionHeader
          color={'mine-shaft'}
          headingType={1}
          size={'large'}
          noLine
          hiddenDescription
        >
          {title}
        </SectionHeader>
        <Paragraph
          additionalClass={'hero__description'}
          size={'medium-constant'}
        >
          {description}
        </Paragraph>
      </Section>
      {!(isEmpty(articles) || isEmpty(related)) && (
        <RelatedArticles
          id={'articles-section-articles'}
          additionalClass={'articles-section__articles'}
          related={related}
          background={'white'}
          allArticles={articles}
          sectionTitle={false}
          fixed
        />
      )}
    </>
  );
};

ArticlesSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  articles: PropTypes.shape({ edges: PropTypes.array }).isRequired,
  related: PropTypes.array.isRequired,
};

export default ArticlesSection;
