export const data = {
  title:
    'Discover how our AI software development services process turns ideas into reality',
  description:
    'We are natural-born problem solvers, with a strong emphasis on listening. We prioritize understanding both your needs and those of your customers and users. This approach guides our AI product development, turning ideas into reality.',
  image: 'img/ai-lp-steps-image.png',
  steps: [
    {
      title: 'Initial communication and understanding',
      description:
        'When you first get in touch, we aim to grasp your specific needs or broad ideas. Sharing your existing views or knowledge about your product or concept helps in refining our approach. We stand ready to assist you in defining clear and actionable objectives for your business.',
    },
    {
      title: 'In-depth needs analysis',
      description:
        'In this step, we really look into what you say you need and what you might not say directly. By asking a lot of questions and checking if what we think is right, we aim to understand your business goals. This helps us get ready for the next steps.',
    },
    {
      title: 'Proposal creation and POC development',
      description:
        'Based on what you tell us, we draft a solution proposal. For AI-powered products, we typically recommend a Proof of Concept (POC) to see if it works well technically and for your business.',
    },
    {
      title: 'Solution recommendations',
      description:
        'We suggest two different solutions that have different costs and sizes. After sharing our projected timeline and implementation estimates, we await your feedback to make sure we are on the same page.',
    },
    {
      title: 'Refinement and kick-off',
      description:
        'We work together to improve the plan. If necessary, we set up an additional meeting dedicated to the technical aspects of the product. We decide on important things like agreement details and when to start, getting ready to build the product.',
    },
  ],
};
