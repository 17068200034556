export const data = [
  {
    question: 'How do you work – remotely or from an office?',
    answer:
      'Boldare members work both remotely and on premises. Everyone who wants to use our offices is warmly welcome. If you prefer to stay home, you’ve got it - but don’t forget about your teammates! Team meetings from time to time are a valued tradition in Boldare. We organize workshops, trips to clients, retreats, collaborative code sessions, and even workations - everything that allows us to know each other better.',
  },
  {
    question: 'If I joined Boldare, how often do I meet f2f with my team?',
    answer:
      `Working remotely at Boldare doesn't mean that you will never meet your team in real life – we believe it's a huge benefit to do so! ` +
      `Each team in our organization has its own rules, and the team members decide how often they want to meet f2f to spend some time together. Some adore workations and traveling together to beautiful places once a quarter. Others have a common day of collaborative work per month. There are also teams which regularly conduct workshops in our offices. ` +
      `We can’t tell you how often you will meet your teammates in person, it depends on you and your colleagues, but you bet your life – it will fit your needs!`,
  },
  {
    question: 'Workations and trips to clients – how do they work?',
    answer:
      `Working face-to-face has evolved – we know that and are betting on alternative solutions like trips to clients or workations. These ideas emphasize our team spirit. How do we organize them? ` +
      `As we work in a holacracy, each team is self-organized and has its own rules. Team members decide how regularly they want to meet with clients. Some repeat this ritual once per month, others prefer to visit foreign partners once a quarter – so it depends on you and your colleagues. It’s also your choice where to stay and how to get together with the partner during such a trip. The organization refunds the costs connected with trips to clients. ` +
      `How about workations? This format is a grassroots initiative, and each time it looks different. Sometimes, the whole team decides to go on workation together. Other times, various people from different places in the organization catch up to travel and live for a while in an exotic country. On workation, there is time to work, relax, have fun, and socialize. Costs are shared between the team members taking the workation.`,
  },
  {
    question: 'Where are your locations?',
    answer:
      `We have four Agile offices in Warszawa, Kraków, Wrocław, and Gliwice. Not long ago, we opened new spots in Hamburg and Amsterdam (and we’re excited about them!). ` +
      `Our Agile offices in Poland reflect our way of working: they're collaborative spaces supporting flat structure and change. According to the pandemic situation, we adhere strictly to the recommended precautions and restrictions during meetings in the office.`,
  },
  {
    question: 'How is the atmosphere at work?',
    answer:
      `Our cultural mantra is: “We don’t fake our vibes”. That’s what our culture is based upon. We believe in transparency, freedom of choice, and independence. Our teams are self-organized and work without managers, using Scrum techniques. Boldare is full of purpose, trust, and teamwork. Above all, we are humans that communicate a lot and respect each other. ` +
      `We meet each other in person as often as possible and draw from various face-to-face ideas. Meetings are fun and help us to achieve goals more efficiently. But still, we also like remote engagement, such as short slots before a daily meeting, magic questions asked by our scrum masters, online lunches and coffees, etc. ` +
      `We work with people who are resident elsewhere; e.g., in Toruń, Gdańsk, Łódź, Kołobrzeg, or even Sweden or Austria! And everyone gets along thanks to how we organize our work, the team “rituals” we follow and the communication and work tools we use (e.g., Slack, Google Workspace, Asana). We especially value our remote versions of knowledge-sharing initiatives, including: #architecture-coffee for developers, #agile-lean-coffee for non-devs, #chapter-designers, #holameetings, #englishbreakfast or #scrumathon.`,
  },
];
