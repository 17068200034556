import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['development-partner-guide'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/development-partner-guide/`;

const DevelopmentPartnerGuidePage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={`Choosing your development partner. A practical guide.`}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/Development-Partner-Guide-ebook-cover-photo.jpg',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-ydp.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Development Partner Guide'}
        description={
          'An experience-fueled ebook that will answer the most common challenges that face decision-makers when looking for a suitable digital product development partner. Learn about:'
        }
        items={[
          'The practical aspects of choosing and working with an external development team',
          'The costs perspective',
          'Best practices when working with remote teams',
          'Pro tips regarding the formal aspects of the partnership',
        ]}
        url={url}
        button={'Send me the ebook'}
        btnColor={'yellow'}
        trackingEvent={`ebookSignupDevelopmentPartnerGuide`}
      />
    </Layout>
  );
};

export default DevelopmentPartnerGuidePage;
