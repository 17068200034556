import React from 'react';

import { Section } from 'components';
import { useIsMobile } from 'hooks/useIsMobile';
import {
  getRecommendClientImageUrl,
  getBookDiscoverySessionImageUrl,
} from '../../utils';

import './Companies.scss';

const LOGOS = [
  'sonnen',
  'dtiq',
  'blablacar',
  'corel',
  'tui',
  'bosch',
  'virgin-radio',
  'prisma',
];

export default function Companies() {
  const isMobile = useIsMobile();

  return (
    <Section containerClass={'book-discovery-session__companies'}>
      <h2
        className={
          'book-discovery-session__heading book-discovery-session__heading--more-spaced'
        }
      >
        Companies that benefited from our strategic expertise:
      </h2>
      <ul>
        {LOGOS.map(logo => (
          <li key={logo}>
            {isMobile ? (
              <img
                src={getBookDiscoverySessionImageUrl(
                  `logos/${logo}.svg`,
                  'f_auto,h_150',
                )}
                alt={logo}
              />
            ) : (
              <img
                src={getRecommendClientImageUrl(`logos/${logo}.png`)}
                alt={logo}
              />
            )}
          </li>
        ))}
      </ul>
    </Section>
  );
}
