import { SectionHeader } from 'components';
import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import './HeroSection.scss';

const HeroSection = () => {
  return (
    <section id={'f2f-hero'} className={'f2f-section-hero'}>
      <div className={'section-hero__label'}>Not &quot;just a job&quot;</div>
      <SectionHeader
        additionalClass={'section-hero__heading'}
        size={'extra-large'}
        color={'black'}
        descriptionColor={'black'}
        headingType={1}
        description={
          'We’ve entered another dimension of teamwork.<br />Workation, team workshops or a trip to the client?<br />Choose your favorite way to merge work & fun!'
        }
        fixed
        noLine
      >
        A place full of <br />
        people, not avatars.
      </SectionHeader>
      <button
        onClick={() => scrollTo('#f2f-types')}
        id={'ga-f2f--types'}
        className={classNames(
          'button',
          'button--primary',
          'button--yellow',
          'section-hero__button',
          'text--bold',
        )}
      >
        More teamwork ideas
      </button>
      <img
        src={getCloudinaryUrl(
          'f2f-landing/f2f-landing-satellite',
          'f_auto,q_auto,w_402',
        )}
        alt={'Boldare - Digital Product Design Development Company'}
        className={'image__satellite'}
        width={402}
        height={240}
        loading={'lazy'}
      />
    </section>
  );
};

export default HeroSection;
