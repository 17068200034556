import React from 'react';
import { Heading, Section } from 'components';
import { TextInteractionAnimation } from 'views/f2f/components';
import { TypesIdeasSlider } from './subcomponents';
import { data } from './data';
import './IdeasSlider.scss';

const IdeasSliderSection = () => (
  <Section color={'mine-shaft'} additionalClass={'ideas-slider-types'}>
    <Heading
      size={'big'}
      color={'white'}
      additionalClass={'section-types__heading'}
      type={2}
    >
      <span>How do we spend time</span>
      <br />
      <TextInteractionAnimation text={'face-to-face?'} />
    </Heading>
    <TypesIdeasSlider id={'ideas-types-slider'} items={data} />
  </Section>
);

export default IdeasSliderSection;
