import React, { Component } from 'react';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import Arrow from 'assets/images/practitest/white-arrow.svg';
import { data } from './logic.js';
import './Design.scss';

class Design extends Component {
  renderBoxes = () =>
    data.map(item => (
      <div className={'pt__design-images--item'} key={item.image}>
        <div>
          <img
            src={getCloudinaryUrl(
              `assets/practitest/${item.image}`,
              'f_auto,q_auto',
            )}
            alt={item.title}
            className={'anim-fade-in-bottom'}
          />
        </div>
        <div className={'pt__design-images--paragraph'}>
          <Heading type={3} size={'medium'}>
            {item.title}
          </Heading>
          <Paragraph size={'medium-constant'} color={'mine-shaft'}>
            {item.text}
          </Paragraph>
        </div>
      </div>
    ));

  render() {
    return (
      <>
        <Section additionalClass={'pt__design-container'}>
          <div
            className={'pt__design-arrow anim-fade-in-left pt__hide-mobile'}
          >
            <img src={Arrow} alt={`Bulb`} loading={'lazy'} />
          </div>
          <div className={'pt__design-content'}>
            <Heading type={2} size={'large'}>
              <span
                className={
                  'marker-highlight marker-highlight--yellow pt__marker'
                }
              >
                ‘Good enough’
              </span>{' '}
              is not an option in the case of UI and UX design
            </Heading>
            <Paragraph
              additionalClass={'text--light'}
              size={'medium-constant'}
              color={'mine-shaft'}
            >
              PractiTest was very demanding, business-oriented and focused.
              This kind of partner pushes us to keep our Scrum process at the
              highest level: regular meetings, constant transparency, and full
              trust are the best ways to create a design geared to both user
              and business needs.
            </Paragraph>
          </div>
        </Section>
        <Section spaceless>
          <div className={'pt__design-images'}>{this.renderBoxes()}</div>
        </Section>
      </>
    );
  }
}

export default Design;
