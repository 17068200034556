import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import cookie from 'react-cookies';
import {
  Checkbox,
  Paragraph,
  Section,
  Button,
  Heading,
  SectionHeader,
  Link,
} from 'components';
import Envelope from 'assets/images/contact/envelope.svg';
import withNewsletter from 'components/Newsletter/HOC';
import { scroller } from 'react-scroll';
import { generateHash, serialize, classNames } from '../../utils';

import './ContactFormFullwidth.scss';

class ContactFormFullwidth extends Component {
  constructor(props) {
    super(props);
    this.state = { _replyto: '', isThankYouPage: false };
  }

  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      this.setState({ isThankYouPage: true });
      scroller.scrollTo(this.props.id, { offset: -80 });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const { pardotLink, refForm } = this.props;
    const form = refForm.current;

    this.setState({ isLoading: true });

    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: serialize(form),
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you&connectionId=${generateHash()}`;
        }
      })
      .catch(error => console.log(error));
  };

  render() {
    const {
      name,
      title,
      description,
      timeline,
      id,
      designMail,
      refForm,
      subscribed,
      handleSubmit: handleSubmitNewsletter,
    } = this.props;

    const { _replyto, isThankYouPage, isLoading } = this.state;

    return (
      <Section id={id} color={'yellow'}>
        <Row className={'no-gap'}>
          <Col sm={12} md={6}>
            <SectionHeader size={'big'} lineColor={'mine-shaft'} fixed>
              {title}
            </SectionHeader>
          </Col>
          <Col sm={12} md={6}>
            {isThankYouPage ? (
              <SectionHeader
                additionalClass={'contact-form-fullwidth__message-sent'}
                size={'big'}
                lineColor={'mine-shaft'}
                fixed
              >
                Your message has been sent!
              </SectionHeader>
            ) : (
              <Heading
                size={'medium-plus'}
                additionalClass={'contact-form-fullwidth__description'}
              >
                {description}
              </Heading>
            )}
          </Col>
        </Row>
        <Row className={'contact-form-fullwidth__row no-gap'}>
          {timeline ? (
            <Col
              sm={12}
              md={6}
              className={'contact-form-fullwidth__contact-data'}
            >
              <ul className={'timeline'}>
                <li className={'timeline-item'}>
                  <div className={'timeline-marker'} />
                  <div className={'timeline-content'}>
                    <Paragraph color={'mine-shaft'} size={'medium'}>
                      Schedule a call with our experts.
                    </Paragraph>
                  </div>
                </li>
                <li className={'timeline-item'}>
                  <div className={'timeline-marker'} />
                  <div className={'timeline-content'}>
                    <Paragraph color={'mine-shaft'} size={'medium'}>
                      We will work on data you’ll provide us to create the most
                      suitable solution for you.
                    </Paragraph>
                  </div>
                </li>
                <li className={'timeline-item'}>
                  <div className={'timeline-marker'} />
                  <div className={'timeline-content'}>
                    <Paragraph color={'mine-shaft'} size={'medium'}>
                      In the next step, you’ll discuss the details of your app
                      with assigned specialists.
                    </Paragraph>
                  </div>
                </li>
                <li className={'timeline-item'}>
                  <div className={'timeline-marker'} />
                  <div className={'timeline-content'}>
                    <Paragraph color={'mine-shaft'} size={'medium'}>
                      You will receive a contract proposal and invitation to
                      product discovery workshops, where you can meet your
                      dedicated team and start working on your idea.
                    </Paragraph>
                  </div>
                </li>
                <li className={'timeline-item'}>
                  <div className={'timeline-marker'} />
                  <div className={'timeline-content'}>
                    <Paragraph color={'mine-shaft'} size={'medium'}>
                      After signing the contract, we are ready to go!
                    </Paragraph>
                  </div>
                </li>
              </ul>
            </Col>
          ) : (
            <Col
              sm={12}
              md={6}
              className={'contact-form-fullwidth__contact-data'}
            >
              <Paragraph
                color={'black'}
                size={'medium'}
                additionalClass={'contact-form-fullwidth__phone'}
              >
                Phone:
              </Paragraph>
              <Link to={'tel:+48327390900'}>
                <Paragraph size={'medium-plus'} additionalClass={'text--bold'}>
                  +48 32 739 09 00
                </Paragraph>
              </Link>

              <Paragraph
                color={'black'}
                size={'medium'}
                additionalClass={'contact-form-fullwidth__email'}
              >
                Got a digital product to discuss?
              </Paragraph>
              {designMail ? (
                <Link
                  to={'mailto:design@boldare.com?subject=Why design matters?'}
                  additionalClass={'design-mail'}
                  id={'contact-form-fullwidth-design-boldare'}
                >
                  <Paragraph
                    size={'medium-plus'}
                    additionalClass={'text--bold'}
                  >
                    design@boldare.com
                  </Paragraph>
                </Link>
              ) : (
                <Link
                  to={'mailto:hello@boldare.com'}
                  additionalClass={'contact-mail'}
                  id={'contact-form-fullwidth-hello-boldare'}
                >
                  <Paragraph
                    size={'medium-plus'}
                    additionalClass={'text--bold'}
                  >
                    hello@boldare.com
                  </Paragraph>
                </Link>
              )}

              <Paragraph
                color={'black'}
                size={'medium'}
                additionalClass={'contact-form-fullwidth__email'}
              >
                Would you like to join our crew?
              </Paragraph>
              <Link
                to={'mailto:career@boldare.com'}
                additionalClass={'career-mail'}
                id={'contact-form-fullwidth-career-boldare'}
              >
                <Paragraph size={'medium-plus'} additionalClass={'text--bold'}>
                  career@boldare.com
                </Paragraph>
              </Link>
            </Col>
          )}
          <Col sm={12} md={6}>
            {isThankYouPage && (
              <>
                {subscribed && (
                  <Paragraph
                    color={'black'}
                    size={'medium'}
                    additionalClass={'contact-form-fullwidth__subscribed'}
                  >
                    You have sucessfully subscribed to the newsletter!
                  </Paragraph>
                )}
                <Checkbox
                  checked={subscribed}
                  value={'subscribe'}
                  onChange={handleSubmitNewsletter}
                  disabled={subscribed}
                  label={' Check me to join the newsletter'}
                />
                <img
                  className={'contact-form-fullwidth__envelope'}
                  src={Envelope}
                  alt={'Newsletter envelope'}
                  loading={'lazy'}
                />
              </>
            )}
            <form
              ref={refForm}
              name={name}
              className={classNames('contact-form-fullwidth', {
                'contact-form-fullwidth--hidden': isThankYouPage,
              })}
              method={'post'}
              onSubmit={this.handleSubmitForm}
            >
              <input
                type={'text'}
                name={'_gotcha'}
                style={{ display: 'none' }}
              />
              <input
                type={'hidden'}
                name={'_subject'}
                value={`Boldare.com from ${_replyto} via ${typeof window !==
                  'undefined' && window.location}`}
              />
              <input type={'hidden'} name={'email'} value={_replyto} />
              <div className={'contact-form-fullwidth__personal-data'}>
                <input
                  type={'text'}
                  name={'name'}
                  placeholder={'Name'}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type={'email'}
                  name={'_replyto'}
                  placeholder={'E-mail address'}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <textarea
                name={'message'}
                placeholder={'How can we help you?'}
                onChange={this.handleChange}
                required
              />
              <Button
                id={`contact-form-fullwidth-${name}-submit`}
                additionalClass={classNames(
                  'contact-button',
                  'fullform-contact-btn',
                  {
                    loading: isLoading,
                  },
                )}
                type={'submit'}
                value={'submit'}
                color={'yellow'}
                ariaName={'Send message button'}
                disabled={isLoading}
                loading
              >
                Send message
              </Button>
            </form>
          </Col>
        </Row>
      </Section>
    );
  }
}

ContactFormFullwidth.propTypes = {
  subscribed: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  description: PropTypes.string,
  pardotLink: PropTypes.string,
  refForm: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  designMail: PropTypes.bool,
  timeline: PropTypes.bool,
};

ContactFormFullwidth.defaultProps = {
  description:
    'Do you want to build a product, hire a development team or simply have a question? Leave us a message.',
  pardotLink: 'https://go.boldare.com/l/688533/2019-02-28/x5r',
  title: "Let's talk",
  timeline: false,
  designMail: false,
};

export default withNewsletter(ContactFormFullwidth);
