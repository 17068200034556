import React from 'react';

import { Section } from 'components';
import { useIsMobile } from 'hooks/useIsMobile';
import { getBookDiscoverySessionImageUrl } from '../../utils';
import LocalAtm from '../../icons/LocalAtm.svg';
import LocalMall from '../../icons/LocalMall.svg';
import Palette from '../../icons/Palette.svg';
import EmojiEvents from '../../icons/EmojiEvents.svg';
import SportsBasketball from '../../icons/SportsBasketball.svg';

import './SessionDeliverables.scss';

const LIST = [
  {
    title: 'Scope proposal',
    icon: LocalMall,
  },
  {
    title: 'Product development timeline',
    icon: EmojiEvents,
  },
  {
    title: 'Technology recommendations',
    icon: SportsBasketball,
  },
  {
    title: 'Optional C4 visualizations',
    icon: Palette,
  },
  {
    title: 'Budget estimates for effective planning',
    icon: LocalAtm,
  },
];

export default function SessionDeliverables() {
  const isMobile = useIsMobile();

  return (
    <Section
      fullwidth
      containerClass={'book-discovery-session__session-deliverables'}
      color={'white'}
      spaceless
    >
      <div className={'book-discovery-session__session-deliverables__wrapper'}>
        {isMobile ? (
          <img
            className={'book-discovery-session__session-deliverables__image'}
            src={getBookDiscoverySessionImageUrl(
              'session-deliverables-hero-mobile.png',
            )}
            width={'573'}
            height={'225'}
            loading={'lazy'}
            alt={'Team'}
          />
        ) : (
          <img
            className={'book-discovery-session__session-deliverables__image'}
            src={getBookDiscoverySessionImageUrl(
              'session-deliverables-hero.png',
            )}
            width={'714'}
            height={'746'}
            loading={'lazy'}
            alt={'Team'}
          />
        )}
        <div
          className={
            'book-discovery-session__session-deliverables__text-contents'
          }
        >
          <h3 className={'book-discovery-session__heading'}>
            Session deliverables:{' '}
          </h3>
          <ul className={'book-discovery-session__session-deliverables__list'}>
            {LIST.map(item => (
              <li>
                <img src={item.icon} alt={item.title} />
                {item.title}
              </li>
            ))}
          </ul>
          <p
            className={
              'book-discovery-session__session-deliverables__paragraph'
            }
          >
            Based on your needs and the development phase of your company, the
            Boldare Solution Architect will provide you with all or part of the
            listed materials.
            <br />
            <br />
            The Business Solution Architect will prepare and send all the
            materials a few days after the meeting.
          </p>
        </div>
      </div>
    </Section>
  );
}
