import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import companyData from '../../../meta/companyData';
import config from '../../../meta/config';

const page = pages.resources.items['development-partner-checklist'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/development-partner-checklist/`;

const ChecklistPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'checklist'}
        title={'33 things to ask your digital product development partner'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/checklist_v2.jpg',
          'f_auto,q_auto,w_640',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-33-things-checklist.png',
          'f_auto,q_auto,w_600',
        )}
        description={`Drawing from ${companyData.years_experience} years of experience, we present to you an ultimate checklist to guide you through the development partner interviews. Packed with must-ask questions and top tips the checklist will help you to identify key answers in the following areas:`}
        items={[
          'General (USP, experience, contracts)',
          'Workflow',
          `Methodology &amp; tools`,
          'Costs',
        ]}
        url={url}
        button={'Download now'}
        btnColor={'yellow'}
        trackingEvent={`ebookSignupDevelopmentPartnerChecklist`}
      />
    </Layout>
  );
};

export default ChecklistPage;
