export const data = [
  {
    title: 'Building New Products',
    description:
      'When conceptualizing a new digital product you want to make sure that it will resolve users’ true pain points. Releasing to market a full web or mobile app is a long process and requires considerable resources. Digital prototypes are cheap and quick to design and develop, allowing you test your software idea with users and gather the initial data in no time.',
  },
  {
    title: 'Designing or Redesigning Apps',
    description:
      'Digital prototyping can be great help in testing whether the product design will convey the right message about your product and fulfil your business needs. One way to do it is to prepare two versions of a design moodboard and test them with users to gather data about design connotations.',
  },
  {
    title: 'Pitching to Investors',
    description:
      'When approaching investors or pitching internally to the board it is critical to present your concept clearly and have data to back it up. With the ability to show visually the basic software functionalities thanks to the digital prototype, as well as gathering initial data through user feedback, you will be able to make a stronger case for funding.',
  },
  {
    title: 'Scaling Products',
    description:
      'When proposing a change for a growing or mature app you may want to build a digital prototype first before going into the product development process. This will help you to see and test how the change will affect the rest of the software and make informed decisions about the scope of the product design and development work ahead.',
  },
  {
    title: 'Developing a New Features',
    description:
      'Changes to an existing digital product are always necessary. But a new feature of an existing web or mobile app must fulfil your business requirements and provide a return on investment. The digital prototyping process allows you to test quickly and approve or reject the concept before investing considerable budget into software design and development.',
  },
];
