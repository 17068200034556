import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';
import companyData from '../../../meta/companyData';

const page = pages.resources.items['product-owner-checklist'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/product-owner-checklist/`;
const canonical = `${url}`;

const ProductOwnerChecklist = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={
          '24 Tools & Tips for remote collaboration on a digital product: a checklist'
        }
        lineColor={'red'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/product-owner-checklist-cover.png',
          'f_auto,q_auto',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-24-tips-remote.png',
          'f_auto,q_auto,w_600',
        )}
        paragraphHeadings={[
          `Boost collaboration with a remote team with this product development-oriented checklist, packed with top tips and hacks in the following areas:`,
        ]}
        items={[
          `Collaboration & communication tools`,
          `Product leadership skills`,
          `Team communication flow`,
        ]}
        outro={`Take advantage of our ${companyData.years_experience} years of experience and download the ultimate checklist for successful remote collaboration on a digital product.`}
        url={pardotlink}
        formHeading={'Get your free checklist now'}
        button={'Send me checklist'}
        btnColor={'pigeon-post'}
        trackingEvent={`ProductOwnerChecklistResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free checklist is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default ProductOwnerChecklist;
