import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Section, SectionHeader, Heading } from 'components';
import { code, ux } from './logic.js';
import './Learn.scss';

const Learn = () => (
  <>
    <Section color={'transparent'} additionalClass={'code-audit__learn'}>
      <div className={'code-audit__learn-content'}>
        <SectionHeader
          size={'large'}
          color={'mine-shaft'}
          lineColor={'white'}
          description={
            'To ensure the highest quality of code and UX auditing, we follow a process that combines strict guidelines and best practices with an agile approach.'
          }
          descriptionColor={'mine-shaft'}
          headingType={2}
          fixed
          additionalClass={'section-header__description--medium'}
        >
          Learn about our code and
          <br />
          UX auditing process
        </SectionHeader>

        <div className={'learn__list'}>
          <ul className={'learn__list-column timeline'}>
            <Heading size={'big'} additionalClass={'learn__list-heading'}>
              Code audit process
            </Heading>
            {code.map(item => (
              <li className={'learn__list-item'}>
                <div className={'learn__list-item-marker'} />
                <div className={'learn__list-item-text'}>
                  {ReactHtmlParser(item)}
                </div>
              </li>
            ))}
          </ul>
          <ul className={'learn__list-column timeline'}>
            <Heading size={'big'} additionalClass={'learn__list-heading'}>
              UX audit process
            </Heading>
            {ux.map(item => (
              <li className={'learn__list-item'}>
                <div className={'learn__list-item-marker'} />
                <div className={'learn__list-item-text'}>
                  {ReactHtmlParser(item)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  </>
);

export default Learn;
