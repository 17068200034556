import React, { useState } from 'react';
import { classNames } from 'utils';
import { Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { TextInteractionAnimation } from 'views/remoteWork/components';
import { data } from './data';
import './FaqSection.scss';

const FaqSection = () => {
  const [activeItem, setActiveItem] = useState(null);

  const clickHandler = index => {
    if (activeItem === index) {
      setActiveItem(-1);
      return;
    }
    setActiveItem(index);
  };

  return (
    <section className={'section--faq'}>
      <Heading additionalClass={'section__heading--faq'} size={'big'} type={2}>
        <TextInteractionAnimation text={'Any questions?'} />
      </Heading>
      <Paragraph size={'medium'} additionalClass={'section--faq__description'}>
        Here, we&apos;ve gathered the most frequently asked questions regarding
        our work style.
      </Paragraph>

      <div className={'section-faq__questions'} id={'faq'}>
        {data.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'section-faq__question',
              index === activeItem ? 'active' : null,
            )}
            onClick={() => clickHandler(index)}
          >
            <div className={'question__header'}>
              <p className={'text--bold'}>{item.question}</p>
              <img
                src={getCloudinaryUrl(
                  'remote-work-landing/arrow.svg',
                  'f_auto,q_auto,w_18',
                )}
                alt={'Boldare - Digital Product Design Development Company'}
                className={'question__arrow'}
                width={18}
                height={13}
                loading={'lazy'}
              />
            </div>
            <div className={'question__answer'}>{item.answer}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
