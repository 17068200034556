import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  SectionHeader,
  BoxProfile,
  Paragraph,
  Heading,
  Button,
  PopUp,
  OrderedList,
} from 'components';
import './Webinar.scss';

class Webinar extends Component {
  static MEMBERS = [
    {
      name: 'Anna Zarudzka',
      job: 'Co-CEO of Boldare',
      photo: 'annaZarudzkaPhoto',
    },
  ];

  constructor() {
    super();

    this.state = {
      visibility: false,
    };
  }

  handleData = data => {
    this.showPopUp(data);
  };

  showPopUp = (visibility = true) => {
    this.setState({ visibility });
  };

  render() {
    const { visibility } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            annaZarudzkaPhoto: file(
              relativePath: { eq: "sysdt/anna_zarudzka.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Section
              color={'white'}
              additionalClass={'sysdt__webinar-section'}
            >
              <Row className={'no-gap anim-fade-in-bottom'}>
                <Col
                  sm={12}
                  md={7}
                  xl={7}
                  className={'sysdt__webinar-description--centered'}
                >
                  <SectionHeader
                    size={'big'}
                    lineColor={'red'}
                    fixed
                    additionalClass={'section-header__description--medium'}
                  >
                    Webinar
                  </SectionHeader>
                  <div className={'sysdt__webinar-description'}>
                    <Heading color={'black'} size={'medium-plus'} type={3}>
                      Transforming Travel with Systemic Digital Transformation.
                      Webinar
                    </Heading>
                    <Paragraph
                      color={'black'}
                      additionalClass={
                        'sysdt__webinar-description text--light'
                      }
                      spaced
                    >
                      Sign up for our experience-fuelled webinar, and see how
                      your organization can thrive in a VUCA world thanks to
                      Systemic Digital Transformation. Anna Zarudzka, the
                      Co-CEO at Boldare will share with you case studies of our
                      clients and reveal our practical approach to digital
                      transformation based on product development and systems
                      theory. Learn:
                    </Paragraph>
                    <OrderedList
                      additionalClass={'text--black text--light'}
                      items={[
                        'What is "digital readiness" and why companies also need "cultural readiness" to become successful in their digital transformation.',
                        'Why alignment between tech departments and the rest of your business is critical and how to achieve it.',
                        'What is systems theory and how it can be applied in practice to your organization.',
                        'How to carry out the transformation while supporting your growth and accelerating time-to-market.',
                        'Examples of successful transformations through digital product development combined with a systemic approach to change.',
                      ]}
                    />
                    <Button
                      color={'black'}
                      onClick={this.showPopUp}
                      id={'sysdt-registration-button--Webinar'}
                      transparent
                      ariaName={'Sign up button'}
                    >
                      Sign up for webinar
                    </Button>
                  </div>
                </Col>
                <Col sm={12} md={5} xl={5}>
                  <BoxProfile
                    backgroundColor={'pigeon-post'}
                    photo={
                      data[Webinar.MEMBERS[0].photo].childImageSharp.fluid
                    }
                    name={Webinar.MEMBERS[0].name}
                    job={Webinar.MEMBERS[0].job}
                    textColor={'black'}
                    additionalClass={'sysdt__webinar-people'}
                  />
                </Col>
              </Row>
            </Section>

            <PopUp
              visible={visibility}
              id={'sysdt-registration-popup--Webinar'}
              visibilityHandler={this.handleData}
              additionalClass={'sysdt__popup'}
            >
              <iframe
                frameBorder={'0'}
                src={
                  'https://app.livestorm.co/p/a70f6293-c046-4048-9677-19e9fcca328d/form'
                }
              />
            </PopUp>
          </>
        )}
      />
    );
  }
}

export default Webinar;
