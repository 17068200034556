export default [
  // SECTIONS
  {
    name: 'Why design matters',
    link: 'design-matters',
    color: 'violet',
  },
  {
    name: 'Why design matters at Boldare',
    link: 'matters-at-boldare',
    color: 'blue',
  },
  {
    name: 'Boldare designs',
    link: 'designs',
    color: 'pink',
  },
  {
    name: 'Design process',
    link: 'process',
    color: 'black',
  },
];
