import React from 'react';
import PropTypes from 'prop-types';
import background from 'assets/images/about-us/background.svg';
import Image from '../Image';
import './SectionDisrupters.scss';

const SectionDisrupters = ({ isDesktop }) => {
  return (
    <div
      className={`section-wrapper yellow section-disrupters ${
        isDesktop ? 'pinned-section' : ''
      }`}
    >
      <section className={'grid-layout'}>
        <p className={'about-header'}>
          Inspired by those who{' '}
          <span className={'marker-highlight marker-black'}>challenged</span>{' '}
          the status quo
        </p>
        <Image
          imageName={'disrupters.png'}
          className={'section-disrupters__image'}
        />
        <img
          className={'section-disrupters__background'}
          src={background}
          alt={'background'}
        />
      </section>
    </div>
  );
};

export default SectionDisrupters;

SectionDisrupters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};
