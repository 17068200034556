import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Loader.scss';

const { Loader } = require('react-loaders');

const LoaderComponent = ({ isActive, absolute }) =>
  isActive ? (
    <div
      className={classNames('loader__container', {
        'loader__container--absolute': absolute,
      })}
    >
      <Loader type={'ball-scale'} />
    </div>
  ) : null;

LoaderComponent.defaultProps = {
  absolute: false,
};

LoaderComponent.propTypes = {
  absolute: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
};

export default LoaderComponent;
