import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { getCloudinaryUrl } from 'config/helpers';
import { BackgroundImage, Heading } from 'components';
import { TextInteractionAnimation } from 'views/remoteWork/components';
import { classNames } from 'utils';
import './ConnectionSection.scss';

const ConnectionSection = () => {
  return (
    <section className={'section--connection'}>
      {/* todo: performance improvement: for mobile/tablet use only cropped versions of this img */}
      <BackgroundImage
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-remote-map',
          'f_auto,q_auto:low,w_2668',
        )}
        additionalClass={'section__map'}
        alt={'Boldare - Digital Product Design Development Company'}
      />

      <img
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-remote-connection-1.png',
          'f_auto,w_161',
        )}
        alt={'Boldare - Digital Product Design Development Company'}
        className={'section--connection__image section--connection__image--1'}
        width={161}
        height={195}
        loading={'lazy'}
      />

      <img
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-remote-connection-2.png',
          'f_auto,w_386',
        )}
        alt={'Boldare - Digital Product Design Development Company'}
        className={'section--connection__image section--connection__image--2'}
        width={386}
        height={534}
        loading={'lazy'}
      />

      <img
        src={getCloudinaryUrl(
          'remote-work-landing/boldare-remote-connection-3.png',
          'f_auto,w_604',
        )}
        alt={'Boldare - Digital Product Design Development Company'}
        className={'section--connection__image section--connection__image--3'}
        width={604}
        height={591}
        loading={'lazy'}
      />

      <i className={'section--connection__hexagon'} />

      <Heading
        size={'big'}
        additionalClass={'section-social__heading'}
        type={2}
      >
        <span>Feel the connection yet?</span>
        <span>We bet you do!</span>
        <TextInteractionAnimation text={'All you have to do now is...'} />
      </Heading>
      <button
        onClick={() => scrollTo('#job-offers')}
        id={'ga-remote-work--join-our-remote-team-connection'}
        className={classNames(
          'button',
          'button--primary',
          'button--yellow',
          'section-hero__button',
          'text--bold',
        )}
      >
        Join our team!
      </button>
    </section>
  );
};

export default ConnectionSection;
