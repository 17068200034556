import React, { Component } from 'react';
import { Section, Heading, Paragraph, BackgroundImage } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { data } from './logic.js';
import './Features.scss';

class Features extends Component {
  renderSection = () =>
    data.map((item, index) => (
      <div
        className={`pe__features pe__features-${index + 1}`}
        key={`section-${index + 1}`}
      >
        <div
          className={'pe__features-container'}
          key={`pe-feature-${item.number}`}
        >
          {item.background && (
            <BackgroundImage
              asset={`assets/planet-escape/${item.background}`}
              alt={item.title}
              additionalClass={'pe__features-background'}
            />
          )}
          <Heading type={2} size={'large'}>
            {item.title}
          </Heading>
          <Heading
            type={4}
            size={'medium-constant'}
            additionalClass={'text--default'}
          >
            {item.subtitle}
          </Heading>
          <Paragraph size={'default'}>{item.description}</Paragraph>
        </div>
        <div className={'pe__features-images'}>
          {this.renderImages(item.images)}
        </div>
      </div>
    ));

  renderImages = data =>
    data.map(image => (
      <img
        key={image.link}
        src={getCloudinaryUrl(
          `assets/planet-escape/${image.link}`,
          'f_auto,q_auto',
        )}
        className={`image ${image.classes}`}
        alt={'Planet Escape'}
        loading={'lazy'}
      />
    ));

  render() {
    return (
      <Section additionalClass={'pe__features'} spaceless>
        {this.renderSection()}
      </Section>
    );
  }
}

export default Features;
