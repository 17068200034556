import React, { useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { SectionHeader, Section, Paragraph, Link } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import * as Scroll from 'react-scroll';

import './Bonus.scss';

const SpyLink = Scroll.Link;

const How = () => {
  const [article, setArticle] = useState(false);
  const [quiz, setQuiz] = useState(true);
  const [workbook, setWorkbook] = useState(false);

  return (
    <>
      <Section
        additionalClass={'event-fpmf-amsterdam__bonus'}
        color={'mine-shaft'}
      >
        <Row className={'event-fpmf-amsterdam__max-width'}>
          <Col
            sm={12}
            md={6}
            className={'event-fpmf-amsterdam__bonus-description'}
          >
            <SectionHeader
              headingType={2}
              additionalClass={'event-fpmf-amsterdam__bonus-heading'}
              color={'white'}
              noLine
            >
              Bonus <span className={'pixel-font-text'}>materials</span>
              <img
                alt={''}
                className={'pixel-font'}
                src={getCloudinaryUrl(
                  'event/amsterdam/materials.svg',
                  'v1674477141',
                )}
              />
            </SectionHeader>
          </Col>
        </Row>
        <Row className={'event-fpmf-amsterdam__max-width'}>
          <Col sm={12} className={'event-fpmf-amsterdam__bonus-description'}>
            <Row>
              <Col
                sm={12}
                md={4}
                className={`event-fpmf-amsterdam__bonus-column`}
                onClick={() => setArticle(true)}
              >
                <Link to={'/blog/product-market-fit-metrics/'} blank>
                  <Paragraph
                    color={'white'}
                    additionalClass={'event-fpmf-amsterdam__bonus-paragraph'}
                  >
                    <img
                      id={'location'}
                      src={getCloudinaryUrl(
                        'event/amsterdam/Group_216.png',
                        'v1674477118',
                      )}
                      alt={'location'}
                      loading={'lazy'}
                      className={`${article &&
                        'event-fpmf-amsterdam__bonus-visited'}`}
                    />
                    {article && (
                      <div
                        className={
                          'event-fpmf-amsterdam__bonus-visited-icon--wrapper'
                        }
                      >
                        <img
                          src={
                            'https://res.cloudinary.com/de4rvmslk/image/upload/v1674485736/event/amsterdam/Icon_visited.svg'
                          }
                          alt={'visited'}
                          className={
                            'event-fpmf-amsterdam__bonus-visited-icon'
                          }
                        />
                      </div>
                    )}
                    <p>PMF article</p>
                  </Paragraph>
                </Link>
              </Col>

              <Col
                sm={12}
                md={4}
                className={'event-fpmf-amsterdam__bonus-column'}
              >
                <SpyLink
                  id={'scroll-down-to-see-more'}
                  to={'form-link'}
                  spy={false}
                  smooth
                  duration={500}
                  className={'link'}
                  rel={'nofollow'}
                  onClick={() => setWorkbook(true)}
                >
                  <Paragraph
                    color={'white'}
                    additionalClass={'event-fpmf-amsterdam__bonus-paragraph'}
                  >
                    <div
                      className={'event-fpmf-amsterdam__bonus-pulse--wrapper'}
                    >
                      <img
                        id={'metro'}
                        src={getCloudinaryUrl(
                          'event/amsterdam/Group_218.png',
                          'v1674570586',
                        )}
                        alt={'metro'}
                        loading={'lazy'}
                        className={`${workbook &&
                          'event-fpmf-amsterdam__bonus-visited'}`}
                      />
                      {workbook && (
                        <div
                          className={
                            'event-fpmf-amsterdam__bonus-visited-icon--wrapper'
                          }
                        >
                          <img
                            src={
                              'https://res.cloudinary.com/de4rvmslk/image/upload/v1674510078/event/amsterdam/Icon_arrow.svg'
                            }
                            alt={'visited'}
                            className={
                              'event-fpmf-amsterdam__bonus-visited-arrow'
                            }
                          />
                          <span
                            className={
                              'event-fpmf-amsterdam__bonus-visited-text'
                            }
                          >
                            Register to record to get the PMF workbook
                          </span>
                        </div>
                      )}
                      {!workbook && (
                        <div className={'event-fpmf-amsterdam__bonus-pulse'} />
                      )}
                    </div>
                    <p>PMF workbook</p>
                  </Paragraph>
                </SpyLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default How;
