import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Section, Paragraph, Link } from 'components';
import './Companies.scss';

import blablacarLogo from 'assets/images/sysdt/blablacar.svg';
import sonnenLogo from 'assets/images/sysdt/sonnen.svg';
import maticLogo from 'assets/images/sysdt/matic.png';

const Companies = () => (
  <Section
    color={'white'}
    additionalClass={'sysdt__companies-section'}
    spaceless
  >
    <Row className={'no-gap sysdt__companies-logos'}>
      <Col sm={12} md={8}>
        <Paragraph
          size={'small'}
          color={'gray-medium'}
          additionalClass={'text--default'}
        >
          Companies that trusted us in Systemic Digital Transformation
        </Paragraph>
        <div className={'sysdt__companies-logo-container'}>
          <div
            className={
              'sysdt__companies-image-wrapper sysdt__companies-image-wrapper--logo'
            }
          >
            <Link
              to={'https://blablacar.com/'}
              id={'systemic-digital-transformation-blablacar'}
            >
              <img src={blablacarLogo} alt={'BlaBlaCar'} loading={'lazy'} />
            </Link>
          </div>
          <div
            className={
              'sysdt__companies-image-wrapper sysdt__companies-image-wrapper--logo'
            }
          >
            <Link
              to={'https://sonnengroup.com/'}
              id={'systemic-digital-transformation-sonnen'}
            >
              <img src={sonnenLogo} alt={'Sonnen'} loading={'lazy'} />
            </Link>
          </div>
          <div
            className={
              'sysdt__companies-image-wrapper sysdt__companies-image-wrapper--logo'
            }
          >
            <Link
              to={'https://maticservices.com/'}
              id={'systemic-digital-transformation-matic'}
            >
              <img src={maticLogo} alt={'Matic'} loading={'lazy'} />
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  </Section>
);

export default Companies;
