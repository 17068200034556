export const bonus = {
  bonusHeading: 'Claim discount!',
  bonusDescription:
    'Get 50% discount on consulting for building remote and dispersed teams using Agile',
  bonusFormUrl: 'https://go.boldare.com/l/688533/2020-05-04/63g8s',
};

export const firstSection = {
  id: '1',
  color: 'white',
  isFinished: true,
  isSpeakers: true,
  isArticle: true,
  isDedicatedMaterial: true,
  isBonus: false,
  title:
    'Crisis strategies for building software, web products and online services',
  description: `Learn how you can benefit from implementing the lean startup approach to building web products, and reduce the risks for your business in a crisis. The video includes a real-life case discussed by Jeff Ko - Co-founder of Chartipedia.`,
  videoUrl: 'https://youtube.com/watch?v=Zs3J_p6MONs',
  pointsTitle: 'Crash Course 1/3 - you will learn:',
  points: [
    `What is the lean startup approach?`,
    `How to develop web products strategically and how to secure the future of your business.`,
    `How to implement and benefit from the build-measure-learn framework.`,
    `How to test your business idea, using the example of the Chartipedia MVP case study.`,
  ],
  speakers: [
    {
      avatar: 'img/webinar1/web1_1.jpg',
      position: 'Co-CEO of Boldare',
      name: 'Anna Zarudzka',
    },
    {
      avatar: 'img/webinar1/web1_2.jpg',
      position: 'Co-founder of Chartipedia',
      name: 'Jeff Ko',
    },
    {
      avatar: 'img/webinar1/web1_3.jpg',
      position: 'Product strategist at Boldare',
      name: 'Tyberiusz Piankowski',
    },
    {
      avatar: 'img/webinar1/web1_4.jpg',
      position: 'Product Designer at Boldare',
      name: 'Urszula Augustyniak',
    },
  ],
  relatedArticle: {
    type: 'ARTICLE',
    title:
      'Risk management strategies for building software in a complex and chaotic environment',
    lead: `The crisis is here. Nobody knows how long it will stay with us. The only thing we know for certain is that you can either face it on your own terms or passively wait until it’s gone. The more agile and conscious of upcoming changes your company is, the more likely it is to emerge from the crisis stronger. But how to embrace this opportunity? Read the first of three articles based on Boldare’s three-part Crash Course webinar series, “Risk Management In Software And Digital Product Development” to learn how and get inspired.`,
    id: 'article-risk-management-strategies-for-software',
    link: '/blog/risk-management-strategies-for-software/',
    photo:
      'Crisis_strategies_for_building_software_web_products_and_online_services_miniatura.png',
  },
  dedicated: [
    {
      dedicatedTitle: 'Free Test Card',
      dedicatedDescription:
        'Improve your process with <b>Build-Measure-Learn Test Card</b>',
      dedicatedButton: 'Get free Test Card',
      dedicatedUrl:
        'https://docs.google.com/document/d/1WgdNBMljLW9zQcd4X8vTza-uFgnpKBLM0pwsIjEP0Ac/edit?usp=sharing',
    },
  ],
};

export const secondSection = {
  id: '2',
  color: 'carrara',
  isFinished: true,
  isSpeakers: true,
  isArticle: true,
  isDedicatedMaterial: false,
  isBonus: false,
  title: 'Risk management in software and digital product development',
  description: `Learn how to identify and manage risks in software development before a crisis hits your business. Get to know the six major risks to your web product and how to deal with them. Watch the video with Allan Wilson – Founder of Community Response Systems, an American company - discussing real-life scenarios.`,
  videoUrl: 'https://youtube.com/watch?v=cJqcW05_sFw',
  pointsTitle: 'Crash Course 2/3 - you will learn:',
  points: [
    `What are the six major risks in software development that companies are facing in a crisis?`,
    `How to reduce risks regarding internal development teams.`,
    `How to reduce risks regarding external development teams.`,
    `How to reduce risks regarding remote work.`,
    `Which tools and processes will help you with these issues.`,
  ],
  speakers: [
    {
      avatar: 'img/webinar2/web2_1.jpg',
      position: 'Founder of Community Response Systems',
      name: 'Allan Wilson',
    },
    {
      avatar: 'img/webinar2/web2_2.jpg',
      position: 'Head of Product Design & Development',
      name: 'Beata Sumera',
    },
    {
      avatar: 'img/webinar2/web2_3.jpg',
      position: 'Chief Technology Officer',
      name: 'Jarosław Kroczek',
    },
    {
      avatar: 'img/webinar2/web2_4.jpg',
      position: 'Product Development Lead',
      name: 'Romuald Członkowski',
    },
    {
      avatar: 'img/webinar2/web2_5.jpg',
      position: 'Chief Marketing Officer',
      name: 'Karolina Kołodziej',
    },
  ],
  relatedArticle: {
    type: 'ARTICLE',
    title: '6 risk management strategies for software development',
    lead: `In the previous article in the series – Crisis strategies for building software, web products and online services – we identified eight organizational risk management strategies for complex and chaotic times, aimed at not only keeping your business afloat during a crisis but positioning yourself to emerge all the stronger. These broad strategies included aspects of digital transformation and working in more agile and lean ways`,
    id: 'article-risk-management-strategies-for-software-development',
    link: '/blog/risk-management-strategies-for-software-development/',
    photo:
      'Risk_management_in_software_and_digital_product_development_miniatura.png',
  },
};

export const thirdSection = {
  id: '3',
  color: 'white',
  isFinished: true,
  isSpeakers: true,
  isArticle: true,
  isDedicatedMaterial: true,
  isBonus: true,
  videoUrl: 'https://www.youtube.com/watch?v=IoRa-vIjS2w',
  title:
    'Managing distributed software development teams: lessons from the last 15 years',
  description: `Learn how to build efficient dev teams fast, based on an agile approach. Watch the video and discover the recipe for a distributed software development culture,based on a real-case scenario discussed by Issam Najm, CTO of Matic, a Saudi startup.`,
  pointsTitle: 'Crash Course 3/3 - lessons learned:',
  points: [
    `How to build efficient teams fast.`,
    `What method supports a fast “learn on the fly” mode.`,
    `How to use an agile approach on a daily basis.`,
    `Practices that support fast-paced distributed teams.`,
    `How to build a distributed software development culture.`,
  ],
  speakers: [
    {
      avatar: 'img/webinar3/web3_4.png',
      position: 'Chief Technology Officer at Matic',
      name: 'Issam Najm',
    },
    {
      avatar: 'img/webinar3/web3_1.jpg',
      position: 'Co-Founder of Boldare',
      name: 'Piotr Majchrzak',
    },
    {
      avatar: 'img/webinar3/web3_2.png',
      position: 'Agile Transformation Guide',
      name: 'Patrycja Wala',
    },
    {
      avatar: 'img/webinar3/web3_3.png',
      position: 'Head of People',
      name: 'Weronika Otrębska',
    },
    {
      avatar: 'img/webinar2/web2_5.jpg',
      position: 'Chief Marketing Officer',
      name: 'Karolina Kołodziej',
    },
  ],
  relatedArticle: {
    type: 'ARTICLE',
    title:
      'How to build an efficient software development team during a crisis?',
    lead: `As the pandemic crisis continues, the only certainty is… the lack of certainty. Businesses seem to be adopting one of three basic strategies. First, do nothing, wait and hope (risky!). Second, cut everything you can and aim to rebuild later (more likely to survive but at what cost?) And third, actively plan for the new world as it emerges (the most likely to see you thrive but let’s not underestimate the difficulty). It’s with this last strategy in mind that Boldare put together our three-part Crash Course webinar series, “How to manage risks, web products & software teams in a recession” to help businesses get inspired and get the tools they need to shape their future.`,
    id: 'article-building-software-development-teams-during-crisis',
    link: '/blog/building-software-development-teams-during-crisis/',
    photo: 'Agile_team_building.png',
  },
  dedicated: [
    {
      dedicatedTitle: 'Team Retrospective Agenda',
      dedicatedDescription: 'Explore strengths and weaknesses of your team',
      dedicatedButton: 'Get free Agenda',
      dedicatedUrl:
        'https://res.cloudinary.com/de4rvmslk/image/upload/v1681893473/assets/blog-ebook/Team-Retrospective-Light.pdf',
    },
    {
      dedicatedTitle: 'Feedback Workshop Agenda',
      dedicatedDescription: 'Strengthen your team through a feedback workshop',
      dedicatedButton: 'Get free Agenda',
      dedicatedUrl:
        'https://res.cloudinary.com/de4rvmslk/image/upload/v1681893474/assets/blog-ebook/Feedback-Workshop-Agenda-Light.pdf',
    },
  ],
};
