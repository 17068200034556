import { getArticleSlug } from 'config/helpers';
import config from '../../../meta/config';

export const CAREER_STATE_KEY = 'careerState';
const { numberOfPromotedOffers } = config.career;
const jobOffersOrder = config.career.jobOffersOrder.map(({ title }) => title);

const offersComparator = (firstOffer, secondOffer) => {
  const firstOfferTalentPool = getTalentPool(firstOffer);
  const secondOfferTalentPool = getTalentPool(secondOffer);

  if (firstOfferTalentPool !== secondOfferTalentPool) {
    return firstOfferTalentPool ? 1 : -1;
  }

  const indexOfFirstOffer = jobOffersOrder.indexOf(
    firstOffer.node.frontmatter.title,
  );
  const indexOfSecondOffer = jobOffersOrder.indexOf(
    secondOffer.node.frontmatter.title,
  );

  return (
    (-1) ** (indexOfFirstOffer === -1 || indexOfSecondOffer === -1) *
    (indexOfFirstOffer - indexOfSecondOffer)
  );
};

export const getPreparedOffers = offers => {
  const sortedOffers = offers.sort(offersComparator);
  return sortedOffers.map((offer, index) => ({
    ...offer,
    promoted: index < numberOfPromotedOffers,
  }));
};

export const careerCities = {
  orRemote: 'or remote',
  remote: 'Remote',
  gliwice: 'Gliwice',
  krakow: 'Kraków',
  warszawa: 'Warszawa',
  wroclaw: 'Wrocław',
  hamburg: 'Hamburg',
  amsterdam: 'Amsterdam',
};

export const careerContractTypes = {
  uop: 'Employment Contract',
  b2b: 'B2B Contract',
  mandate: 'Mandate Contract (Umowa zlecenie)',
};

export const careerCategories = {
  development: 'Development',
  design: 'Design',
  qualityAssurance: 'QA',
  hr: 'HR',
  marketing: 'Marketing',
  business: 'Business',
  product: 'Product',
};

export const careerExperienceLevels = {
  trainee: 'Trainee',
  medior: 'Medior',
  junior: 'Junior',
  senior: 'Senior',
  expert: 'Expert',
};

export const getOfferCities = offer =>
  offer.node.frontmatter.mainContent.cities.map(city => city);

export const getOfferCitiesLabels = (offer, activeCities) => {
  const offerCities = getOfferCities(offer);
  let activeOfferCities =
    activeCities && activeCities.length
      ? offerCities.filter(city => activeCities.includes(city))
      : offerCities;
  activeOfferCities = activeOfferCities
    .map(cityKey => careerCities[cityKey])
    .sort();

  let isRemoteActive = false;
  if (activeOfferCities.includes(careerCities.remote)) {
    activeOfferCities = activeOfferCities.filter(
      city => city !== careerCities.remote,
    );
    isRemoteActive = true;
  }

  activeOfferCities = activeOfferCities
    .reduce((acc, current) => `${acc}${current}, `, '')
    .replace(/, +$/g, '');
  return {
    cities: activeOfferCities,
    isRemoteActive: isRemoteActive
      ? activeOfferCities
        ? careerCities.orRemote
        : careerCities.remote
      : '',
  };
};

export const getOfferCategory = offer => {
  return offer.node.frontmatter.settings.category;
};

export const getOfferName = offer => offer.node.frontmatter.mainContent.name;

export const getOfferLink = offer =>
  offer.node.frontmatter.settings.redirect || getArticleSlug(offer);

export const getTalentPool = offer =>
  offer.node.frontmatter.mainContent.talentPool;

// export const getOfferSalaryRange = (offer, activeCities) => {
//   const offerActiveCities =
//     activeCities && activeCities.length
//       ? offer.node.frontmatter.mainContent.contractType.filter(() =>
//           activeCities.find(c => c.contract),
//         )
//       : offer.node.frontmatter.mainContent.contractType;
//   return offerActiveCities.reduce((salariesByLevel, contract) => {
//     const current = { ...salariesByLevel };
//     contract.salaries.forEach(salary => {
//       if (current[salary.level]) {
//         const { min, max } = current[salary.level];
//         current[salary.level] = {
//           min: salary.min < min ? salary.min : min,
//           max: salary.max > max ? salary.max : max,
//         };
//       } else {
//         current[salary.level] = { min: salary.min, max: salary.max };
//       }
//     });
//     return current;
//   }, {});
// };

export const getOfferSalaryRange = offer => {
  const offerActiveCities = offer.node.frontmatter.mainContent.contractType[0];
  return offerActiveCities.salaries.reduce(salariesByLevel => {
    const current = { ...salariesByLevel };
    offerActiveCities.salaries.forEach(salary => {
      if (current[salary.level]) {
        const { min, max } = current[salary.level];
        current[salary.level] = {
          min: salary.min < min ? salary.min : min,
          max: salary.max > max ? salary.max : max,
        };
      } else {
        current[salary.level] = { min: salary.min, max: salary.max };
      }
    });
    return current;
  }, {});
};

export const groupOffersByPromotedFlag = offers =>
  offers.reduce(
    (currentOffers, offer) => {
      currentOffers[offer.promoted ? 'promoted' : 'notPromoted'].push(offer);
      return currentOffers;
    },
    { promoted: [], notPromoted: [] },
  );

export const countOffersByCategory = (offers, category, activeCities) =>
  offers.filter(offer => {
    const offerCities = getOfferCities(offer);
    return (
      (!activeCities.length ||
        activeCities.some(city => offerCities.includes(city))) &&
      getOfferCategory(offer) === category
    );
  }).length;

export const countOffersByCities = (offers, activeCities) =>
  activeCities.length === 0
    ? offers.length
    : offers.filter(offer => {
        const offerCities = getOfferCities(offer);
        return activeCities.some(city => offerCities.includes(city));
      }).length;

export const filterOffers = (data, categoryFilter, cityFilter) => {
  if (!categoryFilter.length && !cityFilter.length) {
    return data;
  }
  return data.filter(item => {
    const offerCategory = getOfferCategory(item);
    const offerCities = getOfferCities(item);
    return (
      (!categoryFilter.length || categoryFilter.includes(offerCategory)) &&
      (!cityFilter.length ||
        cityFilter.some(city => offerCities.includes(city)))
    );
  });
};

export const getActiveCityFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const careerState = JSON.parse(
      window.localStorage.getItem(CAREER_STATE_KEY),
    );

    return careerState && careerState.cityFilter
      ? careerState.cityFilter[0]
      : null;
  }
  return null;
};
