import React from 'react';
import * as Scroll from 'react-scroll';
import { getCloudinaryUrl } from 'config/helpers';
import { SubpageBanner, SectionHeader, Button, Paragraph } from 'components';
import './style.scss';

const SpyLink = Scroll.Link;

const Banner = ({ id, link }) => (
  <>
    <SubpageBanner
      contentBackground={'violet'}
      additionalClass={'asdc__banner'}
    >
      <figure className={'asdc__banner-background-image'}>
        <img
          src={getCloudinaryUrl(
            'services/ai-software-development-consulting/ai-hero-bg-2.png',
            'f_auto,q_auto,w_1000',
          )}
          alt={'AI product development & consulting services'}
        />
      </figure>
      <figure className={'asdc__banner-image'}>
        <img
          src={getCloudinaryUrl(
            'services/ai-software-development-consulting/ai-hero-bg.png',
            'f_auto,q_auto,w_1000',
          )}
          alt={'AI product development & consulting services'}
        />
      </figure>
      <SectionHeader
        size={'extra-large'}
        color={'white'}
        description={
          <Paragraph
            additionalClass={'text--light'}
            color={'white'}
            size={'big'}
          >
            We get you: Count on our AI solutions, built from the ground up
            just for you, and backed by our experience in creating smart,
            efficient products
          </Paragraph>
        }
        descriptionColor={'white'}
        fixed
        additionalClass={'section-header__description--medium'}
        noLine
      >
        AI product development & consulting services
      </SectionHeader>
      <SpyLink
        id={id}
        to={link}
        spy={false}
        smooth
        duration={500}
        offset={-100}
        className={'link'}
      >
        <Button
          id={'ai-hero-contact-btn'}
          color={'yellow'}
          ariaName={'Contact button'}
          additionalClass={'contact-button text--bold'}
        >
          LET’S INNOVATE!
        </Button>
      </SpyLink>
    </SubpageBanner>
  </>
);

export default Banner;
