import React from 'react';
import PropTypes from 'prop-types';
import { EventForm } from 'components';
import Decoration from './Decoration/Decoration';

import './DiscussionEventForm.scss';

const DiscussionEventForm = ({ url }) => {
  return (
    <div className={'discussion-event-form-container'} id={'join-us'}>
      <div className={'discussion-event-decoration'}>
        <Decoration />
        <Decoration />
      </div>
      <div className={'discussion-event-form-content'}>
        <EventForm
          checkboxLabelWhiteColor={false}
          url={url}
          btnColor={'yellow'}
          thanksDescription={
            'Check your inbox for the webinar recording.'
          }
          button={'Get the video'}
        />
      </div>
    </div>
  );
};

DiscussionEventForm.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DiscussionEventForm;
