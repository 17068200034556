import React from 'react';
import { Section, SectionHeader, RelatedArticles } from 'components';
import { isEmpty } from 'lodash';
import { data } from './logic.js';
import companyData from '../../../../../meta/companyData';

const Examples = ({ allArticles }) => (
  <>
    <Section color={'violet'}>
      <SectionHeader
        size={'big'}
        color={'white'}
        lineColor={'red'}
        descriptionColor={'white'}
        description={`We have developed over ${companyData.products} products across industries such as automotive, energy, finance, tavel, pharmaceutical, media, healthcare, education, B2B services, beauty, fashion, e-commerce, and many more. Here are some examples of our products:`}
        headingType={2}
        fixed
      >
        Examples of products we’ve built
      </SectionHeader>
    </Section>
    <Section fullwidth spaceless additionalClass={'pdad__examples-related'}>
      {!(isEmpty(allArticles) || isEmpty(data)) && (
        <RelatedArticles
          id={'pdad-examples'}
          related={data}
          allArticles={allArticles}
          sectionTitle={false}
          background={'transparent'}
          bulletColor={'violet'}
          fixed
        />
      )}
    </Section>
  </>
);

export default Examples;
