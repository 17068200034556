import React, { Component } from 'react';
import Slider from 'react-slick';
import {
  Section,
  SectionHeader,
  Heading,
  Button,
  SliderArrow,
} from 'components';
import { data } from './logic';
import './News.scss';

class News extends Component {
  constructor() {
    super();

    this.state = {
      sliderSettings: {
        className: 'news__slider',
        dotsClass: 'slick-dots slick-pagination',
        arrows: true,
        dots: true,
        autoplay: false,
        adaptiveHeight: false,
        infinite: true,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <SliderArrow id={'news__slider-next'} />,
        prevArrow: <SliderArrow id={'news__slider-prev'} left />,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  }

  renderItems = () =>
    data.map(item => (
      <article className={'news__article'} key={`news-${item}`}>
        <div className={'news__article-link'}>
          <a href={item.link} title={item.title}>
            <Heading type={3} size={'big'}>
              {item.title}
            </Heading>
          </a>
          <a href={item.link} title={item.title} rel={'nofollow'}>
            <Button order={'secondary'}>Read more</Button>
          </a>
        </div>
      </article>
    ));

  render() {
    const { sliderSettings } = this.state;

    return (
      <>
        <Section additionalClass={'newsroom__news'} spaceless>
          <div className={'section news__inner'}>
            <SectionHeader
              additionalClass={'section-header__description--medium'}
              description={'Get know us and read what’s going on in Boldare'}
              color={'black'}
              lineColor={'pigeon-post'}
              size={'large'}
              headingType={2}
            >
              Latest News
            </SectionHeader>
            <div className={'news__list anim-fade-in-bottom'}>
              <Slider {...sliderSettings}>{this.renderItems()}</Slider>
            </div>
          </div>
        </Section>
      </>
    );
  }
}

export default News;
