import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { ContactBanner, ContactOffices } from './subcomponents';
import './Connect.scss';

class Contact extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero connect-page'}>
        <ContactBanner
          enableDecorations
          color={'white'}
          sidebarColor={'#F1F1F1'}
          widerDisplay
        />
        <ContactOffices />
      </div>
    );
  }
}

export default Contact;
