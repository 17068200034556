import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  SectionHeader,
  Testimonial,
  BoxLayoutSymetrical,
  Box,
  Button,
  Link,
} from 'components';
import { classNames } from 'utils';
import './Fame.scss';

const Fame = ({ awards, media }) => (
  <StaticQuery
    query={graphql`
      query {
        honorsImage: file(relativePath: { eq: "awards/honors-box.png" }) {
          childImageSharp {
            fixed(width: 626) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        mobileImage: file(relativePath: { eq: "awards/mobile-box.png" }) {
          childImageSharp {
            fixed(width: 626) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Section
        color={'mine-shaft'}
        fullwidth
        additionalClass={classNames('case-study-content__section--distanced', {
          'fame__section--awards': awards,
        })}
      >
        {media && (
          <Section>
            <Row className={'no-gap'}>
              <Col sm={12} md={6}>
                <SectionHeader
                  color={'white'}
                  lineColor={'white'}
                  size={'small'}
                >
                  {media.headline || 'Our product in media'}
                </SectionHeader>
              </Col>
              <Col sm={12} md={6}>
                {media.image && (
                  <div className={'fame__image'}>
                    <img
                      src={media.image}
                      alt={''}
                      className={'image'}
                      loading={'lazy'}
                    />
                  </div>
                )}
                <Testimonial
                  color={'white'}
                  content={media.testimonial}
                  author={media.author}
                />
              </Col>
            </Row>
          </Section>
        )}
        {awards && (
          <Section>
            <Row className={'no-gap'}>
              <Col sm={12} md={6} lg={4}>
                <Link
                  additionalClass={'fame__link'}
                  to={'https://www.awwwards.com/Boldare/'}
                  id={'cs-boldare-awwwards'}
                  arrow
                >
                  <SectionHeader
                    color={'white'}
                    lineColor={'white'}
                    size={'small'}
                    descriptionColor={'white'}
                    description={
                      '<strong>Honors</strong> is recognition of great talent and effort invested in its creation.<br /><strong>Mobile Excellence</strong> is award for achieving the necessary scores in terms of Mobile Friendlliness, Performance, Best Practices / PWA, and Usability.'
                    }
                  >
                    Our product on Awwwards
                  </SectionHeader>
                  <Button
                    order={'secondary'}
                    color={'white'}
                    ariaName={'Visit our awwwards profile'}
                  >
                    Visit our awwwwards profile
                  </Button>
                </Link>
              </Col>
              <Col sm={12} md={6} lg={8} className={'fame__column-boxes'}>
                <BoxLayoutSymetrical additionalClass={'fame__boxes'}>
                  <Box
                    backgroundColor={'transparent'}
                    additionalClass={'fame__box'}
                  >
                    <img
                      src={data.honorsImage.childImageSharp.fixed}
                      className={'image'}
                      alt={'awwwards-honors'}
                      loading={'lazy'}
                    />
                  </Box>
                  <Box
                    backgroundColor={'transparent'}
                    additionalClass={'fame__box'}
                  >
                    <img
                      src={data.mobileImage.childImageSharp.fixed}
                      className={'image'}
                      alt={'mobile-honors'}
                      loading={'lazy'}
                    />
                  </Box>
                </BoxLayoutSymetrical>
              </Col>
            </Row>
          </Section>
        )}
      </Section>
    )}
  />
);

Fame.defaultProps = {
  awards: false,
  media: null,
};

Fame.propTypes = {
  awards: PropTypes.bool,
  media: PropTypes.shape(),
};

export default Fame;
