import React from 'react';
import Layout from 'components/layout';
import { NavBar } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { getCloudinaryUrl } from 'config/helpers';
import { useValueDependOnQueryParam } from 'hooks';
import config from '../../../meta/config';

const page = pages.resources.items['web-mobile-apps-prototyping'];
const { navColors } = page;
const canonical = `${config.siteUrl}/resources/web-mobile-apps-prototyping/`;

const WebMobileAppsPrototypingPage = () => {
  const url = useValueDependOnQueryParam('from', page.formHandlers.default, {
    ...page.formHandlers.campaigns,
  });

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        type={'ebook'}
        title={`Web & Mobile apps prototyping - how to make it smart?`}
        lineColor={'red'}
        cover={getCloudinaryUrl(
          'gatsby-cloudinary/Web-mobile-apps-prototyping-ebook-cover-photo.png',
          'f_auto,q_auto,h_445',
        )}
        coverMobile={getCloudinaryUrl(
          'resources/cover-prototyping.png',
          'f_auto,q_auto,w_600',
        )}
        coverAlt={'Web & Mobile apps prototyping'}
        description={
          'Download our experience-backed prototyping ebook to see:'
        }
        items={[
          'What digital product prototyping really is?',
          'Why design is so important in prototyping?',
          'What technologies can be used for a prototype?',
          'How a skilled team can create an app prototype from scratch in less than 48 hours?',
        ]}
        url={url}
        btnColor={'red'}
        button={'Send me the ebook'}
        trackingEvent={`ebookSignupWebMobileAppsPrototyping`}
      />
    </Layout>
  );
};

export default WebMobileAppsPrototypingPage;
