import React from 'react';
import {
  Awards,
  Button,
  Link,
  Paragraph,
  Rating,
  Section,
  SectionHeader,
} from 'components';
import './ContactDeAwards.scss';
import { getCloudinaryUrl } from 'config/helpers';
import { companiesData } from './logic';
import companyData from '../../../../../meta/companyData';

const ContactDeAwards = () => (
  <Section
    additionalClass={'contact-de-awards__section'}
    color={'violet'}
    id={'contact-de-awards'}
  >
    <SectionHeader
      description={
        'Boldare steht für Qualität. Unsere preisgekrönten digitalen Produkte sind international anerkannt für schöne Interfaces, herausragende User Experience, unseren innovativen Ansatz und tadellose Code-Qualität'
      }
      color={'white'}
      lineColor={'yellow'}
      descriptionColor={'white'}
      size={'large'}
      headingType={2}
      additionalClass={'contact-de-awards__text-header'}
    >
      Auszeichnungen
    </SectionHeader>
    <Awards theme={'light'} />
    <Section
      additionalClass={`contact-de-awards__companies-section`}
      containerClass={'contact-de-awards--position-relative'}
      spaceless
      fullwidth
    >
      <div className={'contact-de-awards__companies-section__items'}>
        {companiesData.map(item => (
          <div className={'contact-de-awards__companies-section__item'}>
            <div className={'contact-de-awards__companies-section__item-img'}>
              <img
                src={getCloudinaryUrl(item.image, 'f_auto,q_auto')}
                alt={''}
                loading={'lazy'}
              />
              {item.rate && (
                <Rating
                  rate={companyData.clutch_rate}
                  size={20}
                  color={'yellow'}
                />
              )}
            </div>
            <Paragraph
              additionalClass={
                'contact-de-awards__companies-section__item-description'
              }
              color={'white'}
              size={'medium'}
            >
              {item.description}
            </Paragraph>
            {item.link && (
              <Link to={item.link}>
                <Button
                  color={'white'}
                  arrowColor={'white'}
                  order={'secondary'}
                >
                  {item.linkText}
                </Button>
              </Link>
            )}
          </div>
        ))}
      </div>
    </Section>
  </Section>
);

export default ContactDeAwards;
