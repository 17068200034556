export const banner = {
  theme: 'dark',
  type: 'Webinar with Sonnen',
  title: [
    `How to accelerate`,
    `digitization of products`,
    `and services in`,
    `your company?`,
  ],
  subtitle: 'A case study of Sonnen & Boldare',
  img: 'img/webinar-sonnen/webinar_banner_sonnen.png',
  description: [
    '2020 was a reality check for most companies around the world. The question is, how to make the most of 2021? How to boost your business results right now?',
  ],
};

export const firstSection = {
  id: 1,
  color: 'white',
  isSingle: true,
  isFinished: true,
  isSpeakers: true,
  isArticle: true,
  isDedicatedMaterial: false,
  isBonus: false,
  title:
    'Crisis strategies for building software, web products and online services',
  description: `Learn how you can benefit from implementing the lean startup approach to building web products, and reduce the risks for your business in a crisis. The video includes a real-life case discussed by Jeff Ko - Co-founder of Chartipedia.`,
  videoUrl: 'https://youtube.com/watch?v=OooiPy07qyw',
  pointsDescription:
    'Michael Konder, Vice President of Digital Solutions at Sonnen, a German company in the renewable energy sector, discusses how to successfully go through digital transformation and cooperate with an agile software company to digitize products and services.<br /><br />Watch the video, learn about the real-life case study of Sonnen’s collaboration with Boldare and get exclusive insights from the front line of change.',
  pointsTitle: 'You will learn:',
  points: [
    `how to structure, plan and carry out the digitization of your products and services, `,
    `how to transform internal processes to keep up with change, `,
    `the role of an external agile software partner in digital transformation - how to get the maximum ROI from such a collaboration.`,
  ],
  speakers: [
    {
      avatar: 'img/webinar-sonnen/Michael_Landing_Avatar.jpg',
      position: 'Vice President of Digital Solutions at Sonnen',
      name: 'Michael Konder',
    },
    {
      avatar: 'img/webinar-sonnen/Artur_Landing_Avatar.jpg',
      position: 'Customer Success at Boldare',
      name: 'Artur Belka',
    },
  ],
  relatedArticle: [
    {
      type: 'ARTICLE',
      title:
        'Product discovery workshops - practical insights on how we do it',
      lead: `If you have ever built a digital product before, you know perfectly well that the product discovery phase is absolutely crucial to the creation of either a new product or development of an existing one. If you haven’t yet, this is something you will learn very quickly. In both cases, it’s important to know what product discovery workshops are and how to conduct them in an effective way. We are very proud from our customer oriented approach, so I would like to share some of the practical insights with you. This is how we are doing it here, at Boldare!`,
      id: 'article-how-do-you-make-first-product-discovery-workshops',
      link: '/blog/how-do-you-make-first-product-discovery-workshops/',
      photo: 'team_work_during_product_discovery_workshops.jpg',
    },
  ],
};
