import React from 'react';
import Awwwards from 'views/awwwards';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const page = pages['your-awwwesome-designers'];
const { navColors } = page;
const url = `${config.siteUrl}/services/your-awwwesome-designers/`;
const canonical = `${url}`;

const AwwwardsPage = () => (
  <Layout {...{ page, canonical, url }}>
    <NavBar color={navColors} />
    <Awwwards />
  </Layout>
);

export default AwwwardsPage;
