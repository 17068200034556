import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

import './SectionWide.scss';

export const SectionWide = ({ heading, color, id, children, noOverflow, backgroundImage }) => (
  <div
    className={classNames(`section-wide section-wide--${color}`, {
      'section-wide--no-overflow': noOverflow
    })}
    >
    <div
      className={'section-wide__content'}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
      }}
    >
      {heading}
      {children}
    </div>
  </div>
);

SectionWide.defaultProps = {
  heading: null,
  color: 'white',
  noOverflow: false,
  backgroundImage: '',
};

SectionWide.propTypes = {
  heading: PropTypes.node,
  color: PropTypes.string,
  noOverflow: PropTypes.bool,
  backgroundImage: PropTypes.string,
};
