import Banner from './Banner';
import Why from './Why';
import Speaker from './Speaker';
import Questions from './Questions';
import History from './History';
import About from './About';
import Bonus from './Bonus';
import Form from './Form';

export { Banner, Why, Speaker, Questions, About, Bonus, History, Form };
