import React from 'react';
import { useInView } from 'react-intersection-observer';

import QuoteMark from 'assets/images/home/hero/quote.svg';

import { HeroSlider } from 'components';
import { opinions } from 'data/clientsOptions';

import { Dictionary } from './subcomponents';

import './Hero.scss';

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section className={'hero w-container'} ref={ref} id={'hero'}>
      <div className={'hero__container'}>
        <img
          src={QuoteMark}
          alt={'quotation mark'}
          className={'hero__image'}
        />
        <HeroSlider inView={inView} key={inView} slides={opinions} />
      </div>
      <Dictionary />
    </section>
  );
};

export default Hero;
