import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, OldCaseStudyButtons } from 'components';
import { classNames } from 'utils';
import './OldCaseStudyTile.scss';

class OldCaseStudyTile extends Component {
  render() {
    const {
      additionalClass,
      image,
      subtitle,
      description,
      link,
      title,
      alternative,
      cover,
    } = this.props;

    return (
      <div className={'old-case-study-tile'}>
        {alternative ? (
          <Heading type={2} size={'big'}>
            {title}
          </Heading>
        ) : (
          <img
            alt={'title'}
            className={'image old-case-study-tile__logo'}
            src={image}
            loading={'lazy'}
          />
        )}
        {subtitle && (
          <Paragraph
            color={'white'}
            additionalClass={'old-case-study-tile__subtitle'}
          >
            {subtitle}
          </Paragraph>
        )}
        <Paragraph
          additionalClass={classNames('old-case-study-tile__description', {
            'old-case-study-tile__description--space': alternative,
          })}
        >
          {description}
        </Paragraph>
        {!alternative && (
          <OldCaseStudyButtons {...{ link, additionalClass, title, cover }} />
        )}
        {alternative && (
          <img
            alt={'title'}
            className={'image old-case-study-tile__logo'}
            src={image}
            loading={'lazy'}
          />
        )}
      </div>
    );
  }
}

OldCaseStudyTile.defaultProps = {
  additionalClass: '',
  image: null,
  cover: null,
  subtitle: '',
  title: '',
  description: '',
  link: null,
  alternative: false,
};

OldCaseStudyTile.propTypes = {
  additionalClass: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  alternative: PropTypes.bool,
};

export default OldCaseStudyTile;
