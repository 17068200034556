import React, { useState } from 'react';
import { classNames } from 'utils';
import { Heading, Paragraph } from 'components';
import { toHtml } from 'config/helpers';
import { data } from './data';
import './FaqSection.scss';

const FaqSection = () => {
  const [activeItem, setActiveItem] = useState(0);

  const clickHandler = index => {
    if (activeItem === index) {
      setActiveItem(-1);
      return;
    }
    setActiveItem(index);
  };

  return (
    <section id={'asdc-faq'} className={'asdc-section-faq'}>
      <Heading
        additionalClass={'section__heading--faq'}
        size={'large'}
        type={2}
      >
        FAQ
      </Heading>
      <Paragraph
        color={'white'}
        size={'medium'}
        additionalClass={'asdc-section__description'}
      >
        Solve your doubts
      </Paragraph>

      <div className={'section-faq__questions'} id={'faq'}>
        {data.map((item, index) => (
          <div
            key={item.question}
            role={'button'}
            tabIndex={0}
            className={classNames(
              'section-faq__question',
              activeItem === index ? 'active' : null,
            )}
            onClick={() => clickHandler(index)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                clickHandler(index);
              }
            }}
          >
            <div className={'question__header'}>
              <p className={'text--bold'}>
                Q{index + 1}: {item.question}
                <svg
                  xmlns={'http://www.w3.org/2000/svg'}
                  width={'17'}
                  height={'11'}
                  viewBox={'0 0 17 11'}
                  fill={'none'}
                  className={'question__arrow'}
                >
                  <path
                    d={
                      'M8.5009 3.99972L1.90125 10.5994L0.015625 8.71385L8.5009 0.228516L16.9862 8.71385L15.1006 10.5994L8.5009 3.99972Z'
                    }
                    fill={'white'}
                  />
                </svg>
              </p>
            </div>
            <div className={'question__answer'}>{toHtml(item.answer)}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
