import React from 'react';
import PropTypes from 'prop-types';

const QuestionCount = props => {
  const { counter, total } = props;

  return (
    <div className={'app-cost-calc__question-counter'}>
      <span className={'app-cost-calc__question-counter-current-step'}>
        {counter + 1}
      </span>
      /
      <span className={'app-cost-calc__question-counter-last-step'}>
        {total}
      </span>
    </div>
  );
};

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

QuestionCount.defaultProps = {};

export default QuestionCount;
