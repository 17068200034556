import React from 'react';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';

const Image = ({ imageName, styles, className }) => {
  return (
    <img
      src={getCloudinaryUrl(
        `gatsby-cloudinary/${imageName}`,
        'f_auto,q_auto,w_auto',
      )}
      style={{ ...styles }}
      className={className}
    />
  );
};

export default Image;

Image.defaultProps = {
  styles: {},
  className: '',
};

Image.propTypes = {
  imageName: PropTypes.string.isRequired,
  styles: PropTypes.object,
  className: PropTypes.string,
};
