import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { Heading } from 'components';
import {
  getOfferSalaryRange,
  getPreparedOffers,
} from 'views/career/career.utils';
import { TextInteractionAnimation } from 'views/f2f/components';
import { SingleJobOpenings } from './subcomponents';
import './JobOpenigsSection.scss';

const getOfferName = offer => offer.node.frontmatter.mainContent.name;
const getOfferLink = offer => {
  const offerLinkParts = offer.node.frontmatter.mainContent.apply.split('/');
  offerLinkParts.pop();
  const jobOfferLink = offerLinkParts.join('/');
  return jobOfferLink.substr(0, jobOfferLink.lastIndexOf('/'));
};

const JobOpeningsSection = data => {
  const { jobs: offers } = data;
  const preparedOffers = getPreparedOffers(offers);
  const { cityFilter } = 'remote';
  const [counter, setCounter] = useState(4);

  return (
    <section id={'f2f-job-offers'} className={'section-job-openings'}>
      <Heading
        size={'big'}
        additionalClass={'section-job-openings__heading'}
        type={2}
      >
        <TextInteractionAnimation text={'Check our job openings'} />
      </Heading>
      <div className={'section-job-openings__offers-list'}>
        {[
          ...preparedOffers.map((offer, index) => (
            <SingleJobOpenings
              isActive={index <= counter - 1}
              name={getOfferName(offer)}
              link={getOfferLink(offer)}
              salary={getOfferSalaryRange(offer, cityFilter)}
            />
          )),
        ]}
      </div>
      {counter < preparedOffers.length && (
        <button
          onClick={() => setCounter(counter + 4)}
          className={classNames(
            'button',
            'button--primary',
            'button--white',
            'offers-list__show_more',
            'ga-f2f--show-more',
          )}
        >
          Show more
        </button>
      )}
    </section>
  );
};

JobOpeningsSection.propTypes = {
  jobs: PropTypes.array.isRequired,
};

export default JobOpeningsSection;
