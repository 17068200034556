import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Heading } from 'components';
import { classNames } from 'utils';
import './HeadingWithIcon.scss';

const HeadingWithIcon = ({
  icon,
  iconColor,
  children,
  size,
  additionalClass,
  ...other
}) => (
  <div className={classNames('heading-with-icon', additionalClass)}>
    <Icon size={size} color={iconColor} icon={icon} />
    <Heading size={size} {...other}>
      {children}
    </Heading>
  </div>
);

HeadingWithIcon.defaultProps = {
  iconColor: 'black',
  additionalClass: '',
  size: 'default',
};

HeadingWithIcon.propTypes = {
  additionalClass: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeadingWithIcon;
