import React, { Component } from 'react';
import { IconSVG, Paragraph, Section, SectionHeader } from 'components';
import { data } from './logic.js';
import './Offer.scss';

class Offer extends Component {
  renderItem = () =>
    data.map(item => (
      <div className={'mvp__offer-item'}>
        <IconSVG name={`${item.icon}`} size={'huge'} color={'white'} />
        <Paragraph color={'white'}>{item.description}</Paragraph>
      </div>
    ));

  render() {
    return (
      <Section color={'mine-shaft'} additionalClass={'mvp__offer'}>
        <SectionHeader
          size={'big'}
          lineColor={'yellow'}
          color={'white'}
          description={
            'We understand that companies like yours are struggling with many challenges, including a lack of skilled in-house development teams, limited budgets, pressure from stakeholders and a need to validate business ideas quickly. This is why when we create our offer we aim to meet your exact expectations, covering the areas you indicate. This is what can you expect from us:'
          }
          descriptionColor={'white'}
          headingType={2}
          additionalClass={'mvp__offer-header'}
        >
          Our MVP offer
        </SectionHeader>
        <div className={'mvp__offer-items'}>{this.renderItem()}</div>
      </Section>
    );
  }
}

export default Offer;
