import React, { Component } from 'react';
import { FullwidthImage, Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import { circlesData, imagesData, tilesData } from './logic.js';
import './Design.scss';

class Design extends Component {
  renderCircle = (item, index = 'normal') => (
    <div
      className={`showbiz__design-circle showbiz__design-circle--${index}`}
      key={`circle--${index}`}
    >
      <img
        src={getCloudinaryUrl(`assets/showbiz/${item.image}`, 'f_auto,q_auto')}
        alt={item.title}
        className={'image anim-fade-in'}
        loading={'lazy'}
      />

      <Paragraph
        additionalClass={'text--default'}
        size={'medium-constant'}
        color={'mine-shaft'}
      >
        {item.title}
      </Paragraph>
    </div>
  );

  renderCircles = () =>
    circlesData.map((item, index) => {
      return this.renderCircle(item, index);
    });

  renderImages = () =>
    imagesData.map((item, index) => (
      <div className={'showbiz__design-image'} key={`images--${index}`}>
        <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
          {item.title}
        </Heading>
        <div className={'showbiz__design-pics'}>
          {this.renderPics(item.images)}
        </div>
      </div>
    ));

  renderPics = images =>
    images.map(item => (
      <FullwidthImage
        image={getCloudinaryUrl(`assets/showbiz/${item}`, 'f_auto,q_auto')}
        alt={item}
        key={item}
        additionalClass={'showbiz__design-pic anim-fade-in'}
      />
    ));

  renderTiles = () =>
    tilesData.map((item, index) => (
      <div
        className={'showbiz__design-tile anim-fade-in-bottom'}
        key={`tile--${index}`}
      >
        <FullwidthImage
          image={getCloudinaryUrl(
            `assets/showbiz/${item.image}`,
            'f_auto,q_auto',
          )}
          alt={item.title}
        />
        <div className={'showbiz__design-tile-content'}>
          <Paragraph
            additionalClass={'text--default showbiz__design-tile-title'}
            size={'medium-constant'}
            color={'white'}
          >
            {item.title}
          </Paragraph>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'white'}
          >
            {item.text}
          </Paragraph>
        </div>
      </div>
    ));

  render() {
    const circle = {
      image: 'img05.jpg',
      title: 'Product vision',
    };

    return (
      <Section additionalClass={'showbiz__design'}>
        <div className={'showbiz__content'}>
          <Heading type={3} afterline size={'smallest'} color={'mine-shaft'}>
            Design process
          </Heading>
          <Heading type={2} size={'big-spreaded'}>
            Co-op with a partner that knew exactly which functions are useful
            and used most often
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'mine-shaft'}
          >
            We always put user in the first place during the design process to
            guarantee the best experience. That also helps us better understand
            his needs and build the app in a way to achieve all of the business
            goals that our client is looking for.
          </Paragraph>
        </div>
        <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
          Product vision
        </Heading>
        <div className={'showbiz__design-circles'}>
          <div className={'showbiz__design-circles--overlaped'}>
            {this.renderCircles()}
          </div>
          <div className={'showbiz__design-separator'} />
          {this.renderCircle(circle)}
        </div>
        <Heading type={3} size={'medium-plus'} color={'mine-shaft'}>
          Target users
        </Heading>
        <div className={'showbiz__design-tiles'}>{this.renderTiles()}</div>
        <div className={'showbiz__design-images'}>{this.renderImages()}</div>
      </Section>
    );
  }
}

export default Design;
