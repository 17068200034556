import React from 'react';

import './Arrow.scss';

export const Arrow = ({ fill, active }) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'62'}
    height={'16'}
    fill={'none'}
    className={`${active && 'arrow--active'}`}
  >
    <path
      fill={fill || 'white'}
      d={'M61.7 8.7a1 1 0 0 0 0-1.4L55.35.92a1 1 0 1 0-1.41 1.41L59.59 8l-5.66 5.66a1 1 0 0 0 1.41 1.41l6.37-6.36ZM0 9h61V7H0v2Z'}
    />
  </svg>
);
