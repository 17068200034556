import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import { Section } from 'components';
import WebinarGateContent from './WebinarGateContent';
import './WebinarGate.scss';

class WebinarGate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-asset-page webinar'}>
        <Section additionalClass={'webinar-section--first'} spaceless>
          <WebinarGateContent {...this.props} />
        </Section>
      </div>
    );
  }
}

export default WebinarGate;
