import AboutUs from './AboutUs';
import Banner from './Banner';
import DesignProcess from './DesignProcess';
import OurAwards from './OurAwards';
import OurDesigns from './OurDesigns';
import Related from './Related';
import Subscribe from './Subscribe';

export {
  AboutUs,
  Banner,
  DesignProcess,
  OurAwards,
  OurDesigns,
  Related,
  Subscribe,
};
