import React from 'react';
import Layout from 'components/layout';
import { NavBar, Footer } from 'components';
import Asset from 'views/resources/Asset';
import { pages } from 'config/pages';
import { useValueDependOnQueryParam } from 'hooks';
import { getCloudinaryUrl } from 'config/helpers';
import config from '../../../meta/config';

const page = pages.resources.items['product-discovery-handbook'];
const { navColors } = page;
const url = `${config.siteUrl}/resources/product-discovery-handbook/`;
const canonical = `${url}`;

const ProductOwnerChecklist = () => {
  const pardotlink = useValueDependOnQueryParam(
    'from',
    page.formHandlers.default,
    {
      ...page.formHandlers.campaigns,
    },
  );

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} banner={false} />
      <Asset
        title={
          'Discover your best digital product idea step by step: Product Discovery Handbook'
        }
        lineColor={'red'}
        cover={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto')}
        coverMobile={getCloudinaryUrl(page.siteCover, 'f_auto,q_auto,w_600')}
        description={`You want your project to succeed. No matter how big it is, the challenge is gaining understanding, approval, and budget from your company.

Use this Product Discovery Handbook to distill your vision into a structured plan. Dive in, tackle the tough questions, write your answers, and build your case.

By answering 15 questions prepared by our expert, Michał Trzpis, you will explore the following areas of your project:`}
        items={[
          `Business objectives`,
          `Project implementation schedule`,
          `Project scope`,
          `Project constraints`,
        ]}
        outro={`To use the ebook, fill in the form on the right. Your copy will arrive in your email shortly.`}
        url={pardotlink}
        formHeading={'Get your free handbook now'}
        button={'Send me handbook'}
        btnColor={'pigeon-post'}
        trackingEvent={`ProductOwnerHandbookResource`}
        redirectTitle={'Thank you!'}
        redirectContent={
          'We’ve emailed you a download link! Your free handbook is waiting in your inbox.'
        }
        redirectButton={{ label: 'Visit our blog', path: '/blog/' }}
      />
      <Footer />
    </Layout>
  );
};

export default ProductOwnerChecklist;
