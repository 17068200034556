import React from 'react';
import { getCloudinaryUrl } from 'config/helpers';

import './OrbitCircleLines.scss';

export const OrbitCircleLines = () => (
  <div className={'orbit-circle-lines'}>
    <div className={'orbit-circle-lines__img'}>
      <img
        src={getCloudinaryUrl(
          `career/circle.svg`,
          'f_auto,q_auto,w_auto'
        )}
        alt={'circle'}
        loading={'lazy'}
      />
    </div>
    <div className={'orbit-circle-lines__orbit-container'}>
      <div className={'orbit-circle-lines__orbit'}>
        <img
          src={getCloudinaryUrl(
            'design-rush/orbit-linear.svg',
            'f_auto,q_auto,w_auto'
          )}
          alt={'Orbit'}
          loading={'lazy'}
        />
      </div>
      <div className={'orbit-circle-lines__moon-container'}>
        <div className={'orbit-circle-lines__moon'} />
      </div>
    </div>
  </div>
)
