import tuiLogo from 'assets/images/design-that-tells-stories/tui_logo.svg';
import blinkLogo from 'assets/images/design-that-tells-stories/blink_logo.svg';
import corelLogo from 'assets/images/design-that-tells-stories/corel_logo.svg';
import boschLogo from 'assets/images/design-that-tells-stories/bosch_logo.svg';
import akuityLogo from 'assets/images/design-that-tells-stories/akuity_logo.svg';
import maxeonLogo from 'assets/images/design-that-tells-stories/maxeon_logo.svg';
import prismaLogo from 'assets/images/design-that-tells-stories/prisma_logo.svg';
import sonnenLogo from 'assets/images/design-that-tells-stories/sonnen_logo.svg';
import takamolLogo from 'assets/images/design-that-tells-stories/takamol_logo.svg';
import novolyzeLogo from 'assets/images/design-that-tells-stories/novolyze_logo.svg';
import blablacarLogo from 'assets/images/design-that-tells-stories/blablacar_logo.svg';
import teamalertLogo from 'assets/images/design-that-tells-stories/teamalert_logo.svg';
import decathlonLogo from 'assets/images/design-that-tells-stories/decathlon_logo.svg';
import maxioLogo from 'assets/images/design-that-tells-stories/maxio_logo.png';
import planetEscape from 'assets/images/design-that-tells-stories/planet_escape_logo.png';
import humanitasLogo from 'assets/images/design-that-tells-stories/humanitas-logo.svg';

export const designThatTellStoriesClientLogo = {
  tuiLogo,
  blinkLogo,
  akuityLogo,
  boschLogo,
  corelLogo,
  maxeonLogo,
  prismaLogo,
  sonnenLogo,
  takamolLogo,
  novolyzeLogo,
  blablacarLogo,
  teamalertLogo,
  decathlonLogo,
  maxioLogo,
  planetEscape,
  humanitasLogo,
};
