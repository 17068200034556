import React, { useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import { Row } from 'react-flexbox-grid';
import {
  Section,
  BackgroundImage,
  Paragraph,
  IconSVG,
  TitleAskCto,
} from 'components';
import './Banner.scss';
import DottedPoland from 'assets/images/logos/dotted-poland.svg';
import ScrollDownArrow from 'assets/images/logos/scroll-down-arrow.svg';

const SpyLink = Scroll.Link;

const Banner = ({ id, link }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Section additionalClass={'ask-cto__banner'} side={'right'} fullwidth>
      <BackgroundImage
        asset={'gatsby-cloudinary/ctoask-banner.jpg'}
        alt={'Prototyping Digital Products'}
        additionalClass={'banner__img background-img--cloudinary--askcto'}
      />
      <Row
        className={'no-gap about-page__banner-row askcto-banner-row-padding'}
      >
        <span className={'ctoask-header'}> CTO asks </span>
        <div className={'title-paragraph'}>
          <TitleAskCto
            title={
              'A unique digital transformation practices meetup for CTO & CEOs'
            }
            text={'Welcome to the 4th edition of the CTO asks meetup! '}
            secondText={
              'This is a small and exclusive event for C-levels involved in software development to talk about the best digital transformation practices in a relaxed atmosphere.'
            }
            white
            line={false}
          />
        </div>
      </Row>
      {windowSize.width > 776 ? (
        <div className={'yellow-box-desktop'}>
          <img
            id={'scrolldownarrow'}
            src={ScrollDownArrow}
            alt={'scrolldownarrow'}
            loading={'lazy'}
          />
          <SpyLink
            id={'scroll-down-to-see-more'}
            to={'why-link'}
            spy={false}
            smooth
            duration={500}
            className={'link'}
            rel={'nofollow'}
          >
            <span
              className={'button button--primary button--white'}
              id={'contact-now-shortcut'}
            >
              Scroll down to learn more
            </span>
          </SpyLink>
          <div className={'box-adress'}>
            <IconSVG
              name={'localization'}
              size={'medium'}
              color={'black'}
              additionalClass={'icon-localization'}
            />

            <Paragraph color={'black'} size={'ctoask'}>
              14.10.2021
            </Paragraph>
            <Paragraph color={'black'} size={'ctoask'}>
              Gliwice, Poland
            </Paragraph>
          </div>
          <img
            id={'dottedpoland'}
            src={DottedPoland}
            alt={'dottedpoland'}
            loading={'lazy'}
          />
        </div>
      ) : (
        <div className={'yellow-box-mobile'}>
          <div className={'box-adress-mobile'}>
            <IconSVG
              name={'localization'}
              size={'medium'}
              color={'black'}
              additionalClass={'icon-localization'}
            />

            <Paragraph color={'black'} size={'ctoask'}>
              14.10.2021
            </Paragraph>
            <Paragraph color={'black'} size={'ctoask'}>
              Gliwice, Poland
            </Paragraph>
          </div>
          <div className={'arrow-and-dottedpoland-mobile'}>
            <img
              id={'scrolldownarrow-mobile'}
              src={ScrollDownArrow}
              alt={'scrolldownarrow'}
              loading={'lazy'}
            />
            <img
              id={'dottedpoland-mobile'}
              src={DottedPoland}
              alt={'dottedpoland'}
              loading={'lazy'}
            />
          </div>
        </div>
      )}
    </Section>
  );
};

export default Banner;
