/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

import {
  Paragraph,
  Section,
  Button,
  Heading,
  SectionHeader,
  Link,
  IconSVG,
} from 'components';
import German from 'assets/images/contact/german-flag.svg';
import Netherlands from 'assets/images/contact/netherlands-flag.svg';
import USA from 'assets/images/contact/usa-flag.svg';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';
import { scheduleMeetingUrl } from 'config/constants/links';
import {
  topics,
  topicsAwwwards,
  topicsFcpd,
  topicsCodeAudit,
  topicsNewNormal,
} from './logic.js';
import { generateHash, classNames } from '../../utils';

import './ContactFormBiz.scss';

const phoneNumbers = {
  en: [
    {
      tel: CountryContactPhoneNumbers.USA.value,
      country: 'USA',
      flag: USA,
    },
    {
      tel: CountryContactPhoneNumbers.Germany.value,
      country: 'Germany',
      flag: German,
    },
  ],
  de: [
    {
      tel: CountryContactPhoneNumbers.Germany.value,
      country: 'Germany',
      flag: German,
    },
  ],
  nl: [
    {
      tel: CountryContactPhoneNumbers.Netherlands.value,
      country: 'Netherlands',
      flag: Netherlands,
    },
  ],
};

const PhoneNumber = ({ tel, country, flag }) => (
  <Link to={`tel:${tel}`} className={'contact-form-biz__data--phone'}>
    <Paragraph
      id={'contact-form-biz-usa'}
      size={'medium-plus-small'}
      additionalClass={'text--bold'}
    >
      <img
        src={flag}
        alt={`${country} Flag`}
        className={'mini-flag'}
        loading={'lazy'}
      />
      {tel}
    </Paragraph>
  </Link>
);

class ContactFormBiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isThankYouPage: false,
      form: {
        name: '',
        email: '',
        message: '',
        contactTopic: [],
      },
    };
  }

  componentDidMount() {
    const { id } = this.props;

    if (
      typeof window !== 'undefined' &&
      window.location.href &&
      window.location.href.includes('?thank-you')
    ) {
      this.setState({ isThankYouPage: true });
      scroller.scrollTo(id, { offset: -80 });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const { form } = this.state;
    const { pardotLink } = this.props;
    const body = Object.keys(form)
      .map(key => `${key}=${form[key]}`)
      .join('&');

    this.setState({ isLoading: true });

    fetch(pardotLink, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
      .then(() => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = `?thank-you&connectionId=${generateHash()}`;
        }
      })
      .catch(error => console.log(error));
  };

  checkTopic(filter) {
    const key = 'contactTopic';

    this.setState(prevState => {
      if (prevState.form[key].includes(filter)) {
        return {
          form: {
            ...prevState.form,
            [key]: prevState.form[key].filter(value => value !== filter),
          },
        };
      }
      return {
        form: {
          ...prevState.form,
          [key]: filter ? [...prevState.form[key], filter] : [],
        },
      };
    });
  }

  renderTopics(data) {
    const { form } = this.state;
    const topicArray = form.contactTopic;

    const filters = data.map(filter => (
      <li
        key={`filter-${filter.filterName}`}
        className={`contact-form-biz__filters-item ${
          topicArray.includes(filter.filterName)
            ? 'contact-form-biz__filters-item--active'
            : ''
        }
          ${this.props.theme === 'pink' &&
            'contact-form-biz__filters-item--pink'}`}
        onClick={() => {
          this.checkTopic(filter.filterName);
        }}
      >
        <div
          className={classNames('contact-form-biz__filters-item-content', {
            'contact-form-biz__filters-item-content--pink':
              this.props.theme === 'pink',
          })}
        >
          <IconSVG
            additionalClass={'contact-form-biz__filters-item-icon'}
            name={filter.icon}
          />
          <span className={'contact-form-biz__filters-item-heading'}>
            {filter.filterName}
          </span>
          <span className={'contact-form-biz__filters-checkbox'}>
            <IconSVG
              name={'checkmark'}
              color={'white'}
              additionalClass={'contact-form-biz__filters-icon'}
            />
          </span>
        </div>
      </li>
    ));

    return <ul className={'contact-form-biz__filters-list'}>{filters}</ul>;
  }

  render() {
    const {
      name,
      title,
      description,
      descriptionTwo,
      button,
      textareaContent,
      id,
      lang,
      awwwardsForm,
      fcpdForm,
      codeAudit,
      newNormal,
      meetButton,
      firstNameContent,
      companyEmailContent,
      phoneText,
      emailText,
      privateDataInfo: PrivateDataInfoComponent,
      topicsData,
      responseButtonText,
      responseSendContactHeaderTwo: ResponseSendContactHeaderTwo,
      responseSendContactHeader: ResponseSendContactHeader,
      theme,
    } = this.props;

    const { form, isThankYouPage, isLoading } = this.state;

    return (
      <Section
        id={id}
        color={theme === 'pink' ? 'cinderella' : 'yellow'}
        additionalClass={'contact-form-biz'}
      >
        {!isThankYouPage ? (
          <>
            <SectionHeader
              size={'big'}
              lineColor={'mine-shaft'}
              additionalClass={'contact-form-biz__heading'}
              fixed
            >
              {title}
            </SectionHeader>
            <Heading
              size={'medium-plus'}
              additionalClass={'contact-form-biz__description'}
            >
              {description}
            </Heading>
          </>
        ) : (
          <>
            <SectionHeader
              additionalClass={'contact-form-biz__message-sent'}
              size={'big'}
              lineColor={'violet'}
              fixed
              noLine
            >
              <ResponseSendContactHeader />
            </SectionHeader>
            <Heading
              size={'medium-plus'}
              additionalClass={'contact-form-biz__description'}
            >
              <ResponseSendContactHeaderTwo />
            </Heading>
            {meetButton ? (
              <Button
                onClick={() => {
                  scroller.scrollTo('connect-page__consultants', {
                    spy: false,
                    smooth: true,
                    duration: 700,
                    offset: -40,
                  });
                }}
                color={'black'}
                id={'contact-biz-thank-you-button'}
                ariaName={'Contact button'}
                additionalClass={'contact-biz-button'}
                transparent
              >
                {responseButtonText}
              </Button>
            ) : (
              <Link to={'/contact#connect-page__consultants'}>
                <Button
                  color={'black'}
                  id={'contact-biz-thank-you-button'}
                  ariaName={'Contact button'}
                  additionalClass={'contact-biz-button'}
                  transparent
                >
                  {responseButtonText}
                </Button>
              </Link>
            )}
          </>
        )}

        <>
          <form
            name={name}
            className={classNames('contact-form-biz', {
              'contact-form-biz--hidden': isThankYouPage,
            })}
            method={'post'}
            onSubmit={this.handleSubmitForm}
          >
            <div className={classNames('contact-form-biz__filters')}>
              <Heading
                size={'medium-plus-small'}
                additionalClass={'contact-form-biz__filters-heading'}
              >
                {descriptionTwo}
              </Heading>
              {awwwardsForm && this.renderTopics(topicsData.topicsAwwwards)}
              {fcpdForm && this.renderTopics(topicsData.topicsFcpd)}
              {codeAudit && this.renderTopics(topicsData.topicsCodeAudit)}
              {newNormal && this.renderTopics(topicsData.topicsNewNormal)}
              {!awwwardsForm &&
                !fcpdForm &&
                !codeAudit &&
                !newNormal &&
                this.renderTopics(topicsData.topics)}
            </div>
            <div className={'contact-form-biz__personal-data'}>
              <div className={'input-container'}>
                <input
                  type={'text'}
                  name={'name'}
                  id={'form-name'}
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor={'form-name'}>{firstNameContent}</label>
              </div>
              <div className={'input-container'}>
                <input
                  type={'email'}
                  name={'email'}
                  id={'form-email'}
                  className={classNames({
                    'input--filled': form.email,
                  })}
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor={'form-email'}>{companyEmailContent}</label>
              </div>
            </div>
            <div className={'textarea-container'}>
              <textarea
                name={'message'}
                id={'form-textarea'}
                onChange={this.handleChange}
                required
              />
              <label htmlFor={'form-textarea'}>{textareaContent}</label>
            </div>
            <div className={'contact-form-biz__info'}>
              <Paragraph additionalClass={''} size={'small-plus'}>
                <PrivateDataInfoComponent />
              </Paragraph>
              <Button
                id={`contact-form-biz-${name}-submit`}
                additionalClass={classNames(
                  'contact-button',
                  'contact-form-biz__button',
                  { loading: isLoading },
                )}
                type={'submit'}
                value={'submit'}
                color={'yellow'}
                ariaName={'Send message button'}
                disabled={isLoading}
                loading
              >
                {button}
              </Button>
            </div>
          </form>
        </>

        {!isThankYouPage && (
          <div className={'contact-form-biz__data'}>
            <div className={'contact-form-biz__data-wrapper'}>
              <Paragraph
                color={'black'}
                size={'medium'}
                additionalClass={'contact-form-biz__phone'}
              >
                {phoneText}
              </Paragraph>
              {phoneNumbers[lang].map(el => (
                <PhoneNumber
                  country={el.country}
                  tel={el.tel}
                  flag={el.flag}
                  key={`phone-number-${el.country}`}
                />
              ))}
            </div>
            <div className={'contact-form-biz__data-wrapper'}>
              <Paragraph
                color={'black'}
                size={'medium'}
                additionalClass={'contact-form-biz__phone'}
              >
                {emailText}
              </Paragraph>
              <Link
                to={'mailto:hello@boldare.com'}
                additionalClass={'contact-mail'}
                id={'contact-form-biz__hello-boldare'}
              >
                <Paragraph
                  size={'medium-plus-small'}
                  additionalClass={'text--bold'}
                >
                  hello@boldare.com
                </Paragraph>
              </Link>
            </div>
          </div>
        )}
      </Section>
    );
  }
}

ContactFormBiz.propTypes = {
  description: PropTypes.string,
  descriptionTwo: PropTypes.string,
  button: PropTypes.string,
  pardotLink: PropTypes.string,
  lang: PropTypes.string,
  textareaContent: PropTypes.string,
  firstNameContent: PropTypes.string,
  companyEmailContent: PropTypes.string,
  phoneText: PropTypes.string,
  emailText: PropTypes.string,
  awwwardsForm: PropTypes.string,
  fcpdForm: PropTypes.string,
  codeAudit: PropTypes.string,
  newNormal: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  designMail: PropTypes.bool,
  timeline: PropTypes.bool,
  meetButton: PropTypes.bool,
  privateDataInfo: PropTypes.func,
  topicsData: PropTypes.object,
  responseSendContactHeader: PropTypes.func,
  responseSendContactHeaderTwo: PropTypes.func,
  responseButtonText: PropTypes.string,
};

ContactFormBiz.defaultProps = {
  title: 'How can we help you?',
  description:
    'Fill out and send a form. Our Sales Team will contact you promptly',
  descriptionTwo: "I'm interested in...",
  button: 'Send',
  pardotLink: 'https://go.boldare.com/l/688533/2019-02-28/x5r',
  lang: 'en',
  textareaContent: 'Project description',
  firstNameContent: 'First Name',
  companyEmailContent: 'Company email',
  phoneText: 'Phone:',
  emailText: 'Email:',
  awwwardsForm: null,
  fcpdForm: null,
  codeAudit: null,
  newNormal: null,
  timeline: false,
  meetButton: false,
  designMail: false,
  privateDataInfo: () => (
    <>
      Your data are safe with us. We take your privacy seriously and we will
      never share your data with anyone. Read on our{' '}
      <Link to={'/privacy-policy/'} id={'contact-biz-privacy-policy'}>
        Privacy Policy
      </Link>{' '}
      to learn more.
    </>
  ),
  topicsData: {
    topics,
    topicsAwwwards,
    topicsFcpd,
    topicsCodeAudit,
    topicsNewNormal,
  },
  responseButtonText: 'Meet our team',
  responseSendContactHeader: () => (
    <>
      Thank you!
      <br />
      Your message has been sent
    </>
  ),
  responseSendContactHeaderTwo: () => (
    <>
      We will get to know your idea and get back to you within 24&nbsp;business
      hours.
      <br />
      If you need immediate assistance, call us on{' '}
      <a href={`tel:${CountryContactPhoneNumbers.USA.value}`} className={'link__underline'}>
        {CountryContactPhoneNumbers.USA.displayValue}
      </a>{' '}
      or&nbsp;schedule a&nbsp;meeting{' '}
      <a
        href={scheduleMeetingUrl}
        className={'link__underline'}
      >
        here
      </a>
      !
    </>
  ),
};

export default ContactFormBiz;
