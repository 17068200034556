import React from 'react';
import './Results.scss';
import { getCloudinaryUrl } from 'config/helpers';

const CybersixgillResults = () => {
  return (
    <div className={'cybersixgill-results'}>
      <img
        src={getCloudinaryUrl('cybersixgill/arrow_up.png', 'f_auto,q_auto')}
        className={'arrow-image'}
        alt={'Arrow'}
        loading={'lazy'}
      />
      <div className={'cybersixgill-results-container'}>
        <div className={'cybersixgill-results-container__box'}>
          <div className={'cybersixgill-results-container__box--shadow'}>
            <h3 className={'heading heading--box'}>45%</h3>
            <p className={'paragraph paragraph-box'}>Increase in users</p>
          </div>
        </div>
        <div className={'cybersixgill-results-container__box'}>
          <div className={'cybersixgill-results-container__box--shadow'}>
            <h3 className={'heading heading--box'}>44%</h3>
            <p className={'paragraph paragraph-box'}>Increase in sessions</p>
          </div>
        </div>
        <div className={'cybersixgill-results-container__box'}>
          <div className={'cybersixgill-results-container__box--shadow'}>
            <h3 className={'heading heading--box'}>46%</h3>
            <p className={'paragraph paragraph-box'}>Increase in page views</p>
          </div>
        </div>
        <div className={'cybersixgill-results-container__box'}>
          <div className={'cybersixgill-results-container__box--shadow'}>
            <h3 className={'heading heading--box'}>28%</h3>
            <p className={'paragraph paragraph-box'}>
              Increase in time spent on-page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CybersixgillResults;
