import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Icon.scss';

const Icon = ({ additionalClass, background, color, size, icon }) => {
  const backgroundClass = background
    ? `icon--background icon--background-${background}`
    : '';

  return (
    <svg
      className={classNames(
        'icon',
        {
          [`icon--color-${color}`]: color,
          [`icon--size-${size}`]: size,
        },
        backgroundClass,
        additionalClass,
      )}
      width={50}
      height={50}
      viewBox={'0 0 50 50'}
    >
      <path d={icon} />
    </svg>
  );
};

Icon.propTypes = {
  additionalClass: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
};

Icon.defaultProps = {
  additionalClass: '',
  size: 'default',
  color: 'default',
  background: '',
};

export default Icon;
