import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import './Container.scss';

const Container = ({ additionalClass, children, fullwidth, stick, side }) => (
  <div
    className={classNames('w-container', additionalClass, {
      'w-container--fullwidth': fullwidth,
      'w-container--stick': stick,
      [`w-container--side-${side}`]: side,
    })}
  >
    {children}
  </div>
);

Container.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullwidth: PropTypes.bool,
  stick: PropTypes.bool,
  side: PropTypes.string,
};

Container.defaultProps = {
  additionalClass: '',
  children: null,
  fullwidth: false,
  side: '',
  stick: false,
};

export default Container;
