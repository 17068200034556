import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  SectionHeader,
  Button,
  Paragraph,
  IconSVG,
  Heading,
  Link,
} from 'components';
import './Accordion.scss';

class Accordion extends Component {
  render() {
    const {
      backgroundColor,
      children,
      icon,
      headline,
      headingType,
      lead,
      description,
      lineColor,
      fontColors,
      additionalClass,
      index,
      id,
    } = this.props;

    return (
      <Section
        id={id}
        color={backgroundColor}
        additionalClass={`accordion__single ${additionalClass}`}
        fullwidth
      >
        <AccordionItem>
          <div className={'accordion__wrapper w-container'}>
            <Row className={'accordion__row no-gap'}>
              <Col sm={12} md={6}>
                {icon && (
                  <IconSVG
                    name={icon}
                    size={'big'}
                    color={'black'}
                    background={'white'}
                  />
                )}
                <Row className={'accordion__row no-gap'}>
                  {index && (
                    <Paragraph
                      additionalClass={'accordion__item-order text--bold'}
                      color={fontColors[0]}
                    >
                      {index}.
                    </Paragraph>
                  )}
                  <SectionHeader
                    size={'small'}
                    lineColor={lineColor}
                    fixed
                    headingType={headingType}
                    color={fontColors[0]}
                    additionalClass={'accordion__headline'}
                  >
                    {headline}
                  </SectionHeader>
                  <AccordionItemHeading
                    className={
                      'accordion__read-more--mobile hide-desktop hide-tablet'
                    }
                  >
                    <AccordionItemButton>
                      <IconSVG name={'arrow-down'} color={lineColor} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <div className={'accordion__intro-inner hide-mobile'}>
                  {lead && (
                    <Heading
                      type={3}
                      size={'medium'}
                      color={fontColors[1]}
                      additionalClass={'accordion__title'}
                    >
                      {lead}
                    </Heading>
                  )}
                  <Paragraph
                    color={fontColors[2]}
                    additionalClass={
                      'text--light accordion__description--header'
                    }
                    nested
                  >
                    {description}
                  </Paragraph>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Link to={`#${id}`} rel={'nofollow'}>
                        <Button
                          id={`accordion-${id}`}
                          transparent
                          color={fontColors[0]}
                          additionalClass={'accordion__read-more'}
                          ariaName={'Read more'}
                        >
                          Read more
                        </Button>
                      </Link>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                </div>
              </Col>
            </Row>
          </div>

          <AccordionItemPanel className={'anim-slide-in-top'}>
            <div className={'accordion__more'}>
              <div className={'w-container hide-desktop hide-tablet'}>
                {lead && (
                  <Heading
                    type={4}
                    size={'medium'}
                    color={fontColors[1]}
                    additionalClass={'accordion__title'}
                  >
                    {lead}
                  </Heading>
                )}
                <Paragraph
                  color={fontColors[2]}
                  additionalClass={
                    'text--light accordion__description--header'
                  }
                  nested
                >
                  {description}
                </Paragraph>
              </div>
              <div className={'hide-mobile'}>{children}</div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Section>
    );
  }
}

Accordion.defaultProps = {
  additionalClass: '',
  backgroundColor: 'white',
  children: null,
  description: null,
  expandFirst: false,
  fontColors: ['black', 'black', 'black'],
  headingType: '',
  icon: '',
  index: '',
  lead: '',
  lineColor: '',
};

Accordion.propTypes = {
  additionalClass: PropTypes.string,
  headingType: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  expandFirst: PropTypes.bool,
  fontColors: PropTypes.array,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  index: PropTypes.string,
  lead: PropTypes.string,
  lineColor: PropTypes.string,
};

export default Accordion;
