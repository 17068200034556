import React from 'react';
import PropTypes from 'prop-types';

import { getCloudinaryUrl } from 'config/helpers';
import { Button, Heading, Image, Paragraph } from 'components';

import './Landing.scss';

const Landing = ({ setNextState }) => (
  <div className={'pmf-survey__landing w-container'}>
    <div className={'pmf-survey__text-content'}>
      <Heading
        type={1}
        size={'large'}
        color={'white'}
        additionalClass={'pmf-survey__header'}
      >
        Product-Market Fit Survey - is your{' '}
        <span className={'pmf-survey__marker-highlight-long'}>
          product ready
        </span>
        ?
      </Heading>

      <Paragraph color={'white'} additionalClass={'pmf-survey__description'}>
        This survey has been created with our product strategists to help you
        better understand the development phase that your product is in.
        <br />
        <span className={'pmf-survey__description--second-line'}>
          Answer these 5 questions to see if your product could be ready for
          product- market fit.
        </span>
      </Paragraph>

      <Heading type={4} additionalClass={'pmf-survey__subheader'}>
        How to use this product-market fit survey
      </Heading>

      <Paragraph color={'white'} additionalClass={'pmf-survey__clue'}>
        Answer every question by ticking only one answer. You don’t have to
        submit any personal information to use this survey - our recommendation
        will be generated instantly.
      </Paragraph>

      <Button
        color={'white'}
        id={'app-cost-button'}
        onClick={setNextState}
        additionalClass={'pmf-survey__button'}
      >
        Start here &#8594;
      </Button>
    </div>
    <div className={'pmf-survey__img'}>
      <Image
        src={getCloudinaryUrl('/pmf-survey/moon.png', 'f_auto,q_auto,w_428')}
        alt={'Moon'}
        loading={'lazy'}
        additionalClass={'pmf-survey__landing-img-moon'}
      />
      <Image
        src={getCloudinaryUrl(
          '/pmf-survey/ellipse.png',
          'f_auto,q_auto,w_500',
        )}
        additionalClass={'pmf-survey__landing-img-ellipse'}
        alt={'Ellipse'}
        loading={'lazy'}
      />

      <Image
        src={getCloudinaryUrl('/pmf-survey/people.png', 'f_auto,q_auto,w_661')}
        additionalClass={'pmf-survey__landing-img-people'}
        alt={'People'}
        loading={'lazy'}
      />
    </div>
  </div>
);

Landing.propTypes = {
  setNextState: PropTypes.func,
};

Landing.defaultProps = {
  setNextState: null,
};

export default Landing;
