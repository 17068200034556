import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StickyBox from 'react-sticky-box';
import { enableAnimations } from 'config/helpers';
import { Row, Col } from 'react-flexbox-grid';
import {
  Section,
  SectionHeader,
  Paragraph,
  GateForm,
  ResourceList,
} from 'components';
import './Gate.scss';
import '../Resources.scss';

class Gate extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const {
      type,
      title,
      description,
      paragraphHeadings,
      speakers,
      items,
      url,
      formHeading,
      button,
      btnColor,
      trackingEvent,
      gateCatalog,
      gateAddress,
    } = this.props;

    return (
      <div className={'single-asset-page gate'}>
        <Section additionalClass={'gate-section--first'} spaceless>
          <Row className={'no-gap'}>
            <Col md={12} lg={7} className={'gate__main'}>
              <div className={'gate__inner gate__top'}>
                {type && <p className={'article-categories'}>{type}</p>}
                {title && (
                  <SectionHeader
                    type={1}
                    size={'large'}
                    additionalClass={'gate__heading'}
                    lineColor={'yellow'}
                  >
                    {title}
                  </SectionHeader>
                )}
              </div>
              <div className={'gate__sidebar gate__sidebar--mobile'}>
                <div className={'gate__inner'}>
                  <GateForm
                    {...{
                      btnColor,
                      button,
                      gateCatalog,
                      gateAddress,
                      formHeading,
                      trackingEvent,
                      type,
                      url,
                    }}
                  />
                </div>
              </div>
              <div className={'gate__inner'}>
                {description && (
                  <Paragraph
                    size={'medium'}
                    additionalClass={'text--bold gate__description'}
                  >
                    {description}
                    <br />
                    <br />
                  </Paragraph>
                )}
                {speakers && Array.isArray(speakers) && speakers.length && (
                  <div className={'gate__list-container'}>
                    {paragraphHeadings && (
                      <Paragraph
                        size={'medium'}
                        additionalClass={'text--bold gate__description'}
                      >
                        {paragraphHeadings[0]}
                      </Paragraph>
                    )}
                    <ResourceList {...{ items: speakers }} />
                  </div>
                )}
                {items && Array.isArray(items) && items.length && (
                  <div className={'gate__list-container'}>
                    {paragraphHeadings && (
                      <Paragraph
                        size={'medium'}
                        additionalClass={'text--bold gate__description'}
                      >
                        {paragraphHeadings[1]}
                      </Paragraph>
                    )}
                    <ResourceList {...{ items }} />
                  </div>
                )}
                {paragraphHeadings && (
                  <Paragraph
                    size={'medium'}
                    additionalClass={'text--bold gate__description'}
                  >
                    {paragraphHeadings[2]}
                  </Paragraph>
                )}
              </div>
            </Col>
            <Col
              md={12}
              lg={5}
              className={'gate__sidebar gate__sidebar--desktop'}
            >
              <StickyBox offsetTop={100} style={{ zIndex: 100 }}>
                <div className={'gate__inner'}>
                  <GateForm
                    {...{
                      btnColor,
                      button,
                      formHeading,
                      gateCatalog,
                      gateAddress,
                      trackingEvent,
                      type,
                      url,
                    }}
                  />
                </div>
              </StickyBox>
            </Col>
          </Row>
        </Section>
      </div>
    );
  }
}

Gate.propTypes = {
  btnColor: PropTypes.string,
  button: PropTypes.string,
  description: PropTypes.string,
  formHeading: PropTypes.string,
  gateAddress: PropTypes.string,
  gateCatalog: PropTypes.string,
  items: PropTypes.array,
  paragraphHeadings: PropTypes.array,
  speakers: PropTypes.array,
  title: PropTypes.string,
  trackingEvent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Gate.defaultProps = {
  btnColor: '',
  button: '',
  description: '',
  formHeading: '',
  gateAddress: '',
  gateCatalog: '',
  items: [],
  paragraphHeadings: [],
  speakers: [],
  title: '',
};

export default Gate;
